import { ButtonModal, Root, VCPChart } from "#components";
import { rootElement, wrapCallbackWithAnalyticsEventHandler } from "#helpers";
import { useTranslation } from "#hooks";
import { bp, Color, fadeIn, fadeOut, IconKeys } from "#shared/theme";
import { ButtonAnimationType, ButtonCircle, Icon } from "#ui";
import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { VCPIntro } from "./VCPIntro";
import { AnalyticsEventTypes } from "#interfaces";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  padding: 96px 0;
  position: relative;
`;

const PageContainer = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)};
`;

const InfoButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 34px;
  margin: 18px auto;
  z-index: 1;
  @media ${bp.m} {
    margin: 23px auto;
  }
`;

export const VCPPage: React.FC = () => {
  const { t } = useTranslation("VCP");
  const [isVCPActive, setVCPActive] = useState(false);
  const headerProps = {
    transparent: true,
    withNav: true,
  };
  const openVCPChart = () => {
    gsap.to(rootElement, {
      duration: 0.3,
      scrollTo: { y: 0 },
      ease: Power3.easeInOut,
      onComplete: () => {
        setVCPActive(true);
      },
    });
  };
  return (
    <Root style={{ backgroundColor: Color.BlueBold }} withHeader headerProps={headerProps}>
      <Main>
        <PageContainer isVisible={!isVCPActive}>
          <VCPIntro openVCP={openVCPChart} />
        </PageContainer>
        {isVCPActive && (
          <PageContainer isVisible={true}>
            <VCPChart />
          </PageContainer>
        )}
        <InfoButtonContainer>
          {!isVCPActive ? (
            <ButtonCircle onClick={openVCPChart} animation={ButtonAnimationType.circleButtonVcp_70x70}>
              <Icon iconKey={IconKeys.SvgArrowRight} />
            </ButtonCircle>
          ) : (
            <ButtonModal list={t("info")}>
              {({ onClick }) => (
                <ButtonCircle
                  onClick={wrapCallbackWithAnalyticsEventHandler(onClick, AnalyticsEventTypes.VCPInfoOpenInfo)}
                >
                  <Icon iconKey={IconKeys.SvgInfo} />
                </ButtonCircle>
              )}
            </ButtonModal>
          )}
        </InfoButtonContainer>
      </Main>
    </Root>
  );
};
