import { useTranslation } from "#hooks";
import { bp, Color, font, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid, PreCaption, Wrapper } from "#ui";
import htmr from "htmr";
import React from "react";
import { ThemeKeys } from "#constants";
import styled, { css, ThemeProvider } from "styled-components/macro";
import theme from "styled-theming";

enum ResultsHeadlineSize {
  Default = "Default",
  Large = "Large",
}

const Container = styled(Wrapper)``;

const Columns = styled.div`
  text-align: center;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    ${gridColumnWidth(24)}
  }
  @media ${bp.p} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
  }
`;
const Col = styled.div`
  margin: 60px 0 0;
  padding: 0;
  @media ${bp.m} {
    margin-top: 0;
    width: ${(7 / 24) * 100}%;
  }
  @media ${bp.p} {
    width: ${(6 / 20) * 100}%;
  }
`;
const Headline = styled.h3<{ size?: ResultsHeadlineSize }>`
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${Color.BlueBold};
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  @media ${bp.d} {
    font-size: 45px;
    line-height: 50px;
  }
  ${theme(ThemeKeys.resultsHeadlineSize, {
    [ResultsHeadlineSize.Large]: css`
      font-size: 50px;
      line-height: 50px;
      @media ${bp.d} {
        font-size: 60px;
        line-height: 60px;
      }
    `,
  })}
`;
const List = styled.ul`
  margin: 30px 0 0;
  padding: 0;
  @media ${bp.m} {
    margin-top: 50px;
  }
`;
const ListItem = styled.li`
  display: block;
  & + & {
    margin-top: 20px;
    @media ${bp.d} {
      margin-top: 35px;
    }
  }
`;
const Result = styled.div`
  font-family: ${font.condensed};
  font-size: 16px;
  line-height: 23px;
  @media ${bp.d} {
    font-size: 20px;
    line-height: 30px;
  }
`;
const Value = styled.h4`
  font-size: 25px;
  font-weight: bold;
  @media ${bp.d} {
    font-size: 35px;
  }
`;

const Caption = styled.div`
  max-width: 75%;
  margin: 5px auto 0;
  ${fontCondensed}
  @media ${bp.m} {
    max-width: none;
  }
`;

interface ItemProps {
  value: string;
  meter: string;
  caption: string;
}
interface ColumnProps {
  headline: { size: ResultsHeadlineSize; copytext: string };
  list: ItemProps[];
}

export const Results: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { head, list } = tHome("results");
  const renderList = ({ value, caption }: ItemProps, key: number) => (
    <ListItem key={key}>
      <Result>
        <Value>{value}</Value>
        <Caption dangerouslySetInnerHTML={{ __html: caption }} />
      </Result>
    </ListItem>
  );
  const renderColumn = ({ headline, list }: ColumnProps, key: number) => (
    <Col key={key}>
      <ThemeProvider
        theme={{
          [ThemeKeys.resultsHeadlineSize]: headline.size,
        }}
      >
        <Headline>{htmr(headline.copytext)}</Headline>
      </ThemeProvider>
      <List>{list.map(renderList)}</List>
    </Col>
  );
  return (
    <Container>
      <PreCaption {...head} />
      <Grid>
        <Columns>{list.map(renderColumn)}</Columns>
      </Grid>
    </Container>
  );
};
