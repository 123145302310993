import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import { Languages } from "#constants";
import LanguageDetector from "i18next-browser-languagedetector";
import * as bericht from "./locales/bericht";
import * as report from "./locales/report";

const myDetector = {
  name: "customSubdomain",
  lookup() {
    let found;
    if (typeof window !== "undefined") {
      found = window.location.host.split(".")[0];
    }
    return found;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      bericht,
      report,
    },
    lng: process.env.REACT_APP_LANG,
    initImmediate: true,
    whitelist: [Languages.en, Languages.de],
    fallbackLng: Languages.en,
    detection: {
      order: ["customSubdomain"],
    },
    react: {
      useSuspense: false,
    },
    returnObjects: true,
  });

export default i18next;
