import React, { useEffect, useState } from "react";
import { Grid, Wrapper, VCPTurbo, VCPContainer, VCPItems } from "#ui";
import { SlideshowProvider } from "#providers";
import { Item1 } from "./Item1";
import { Item2 } from "./Item2";
import { Item3 } from "./Item3";
import { Item4 } from "./Item4";
import { Item5 } from "./Item5";
import { Item6 } from "./Item6";
import { VCPItemProps } from "#interfaces";

export const VCPChartDesktop: React.FC<Pick<VCPItemProps, "labels">> = ({ labels }) => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      <Wrapper>
        <Grid fullHeight>
          <VCPContainer isVisible={isMounted}>
            <VCPTurbo viewBox="0 0 409 406" xmlns="http://www.w3.org/2000/svg">
              <g>
                <text transform="matrix(1 0 0 1 1.5 401.0005)" fontSize="18">
                  Input
                </text>
                <text transform="matrix(1 0 0 1 358.5708 16.0005)" fontSize="18" fill="#FFFFFF">
                  Output
                </text>
              </g>
              <g>
                <circle
                  id="circle-basis"
                  fill="none"
                  stroke="#FFFFFF"
                  strokeMiterlimit="10"
                  cx="204.5"
                  cy="203"
                  r="172"
                />
              </g>
              <g xmlns="http://www.w3.org/2000/svg" id="white">
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter"
                    filterUnits="userSpaceOnUse"
                    x="40"
                    y="38"
                    width="368"
                    height="330"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0"
                      colorInterpolationFilters="sRGB"
                      result="source"
                    />
                  </filter>
                </defs>
                <mask maskUnits="userSpaceOnUse" x="40" y="38" width="368" height="330" id="_x36_-pathes_1_">
                  <g filter="url(#Adobe_OpacityMaskFilter)">
                    <polygon points="408,62 403,57 403,48 408,43 403,38 40,38 40,358 205,358 150.9,339 205,339 154.6,320 205,320 158.6,301      205,301 163,282 205,282 167.8,263 205,263 403,263 403,143 408,138 403,133 403,124 408,119 403,114 403,105 408,100 403,95      403,86 408,81 403,76 403,67    " />
                  </g>
                </mask>
              </g>
            </VCPTurbo>
            <SlideshowProvider length={6} defaultIndex={-1}>
              <VCPItems>
                <Item1 slideIndex={1} labels={labels?.item1} />
                <Item2 slideIndex={2} labels={labels?.item2} />
                <Item3 slideIndex={3} labels={labels?.item3} />
                <Item4 slideIndex={4} labels={labels?.item4} />
                <Item5 slideIndex={5} labels={labels?.item5} />
                <Item6 slideIndex={6} labels={labels?.item6} />
              </VCPItems>
            </SlideshowProvider>
            <VCPTurbo viewBox="0 0 409 406" xmlns="http://www.w3.org/2000/svg">
              <g xmlns="http://www.w3.org/2000/svg" id="mask">
                <polygon
                  id="mask_black"
                  fill="#1EBEE4"
                  points="2,262 2,263 7,268 2,273 2,282 7,287 2,292 2,301 7,306 2,311 2,320 7,325 2,330 2,339 7,344    2,349 2,358 7,363 2,368 2,369 0,369 0,262  "
                />
              </g>
            </VCPTurbo>
          </VCPContainer>
        </Grid>
      </Wrapper>
    </>
  );
};
