import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";

const transition = {
  duration: 0.4,
  ease: "easeInOut",
};

const variants = {
  exit: {
    opacity: 0,
    transition,
  },
  enter: {
    opacity: 1,
    transition,
  },
};

const Container = styled(motion.div)`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const TransitionContainer: React.FC = (props) => {
  return <Container variants={variants} {...props} />;
};
