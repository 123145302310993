import { bp, fontCondensed } from "#shared/theme";
import htmr from "htmr";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  ${fontCondensed}
  line-height: 18px;
  @media ${bp.d} {
    line-height: 22px;
  }
`;
const Headline = styled.div`
  font-size: 15px;
  font-weight: bold;
  @media ${bp.d} {
    font-size: 18px;
  }
`;
const Desc = styled.div`
  font-size: 13px;
  @media ${bp.d} {
    font-size: 16px;
  }
`;

export interface ImageAuthorProps {
  headline: string;
  desc: string;
}

export const ImageAuthor: React.FC<ImageAuthorProps> = ({ headline, desc }) => {
  return (
    <Container>
      <Headline>{headline}</Headline>
      <Desc>{htmr(desc)}</Desc>
    </Container>
  );
};
