import { HEADER_HEIGHT } from "#constants";
import { wrapCallbackWithAnalyticsEventHandler } from "#helpers";
import { AnalyticsEventTypes, Translations } from "#interfaces";
import { useTour } from "#providers";
import { bp, bpTypes, clearBtn, Color, defTransition, gridColumnStart, gridColumnWidth, IconKeys } from "#shared/theme";
import { ButtonCircle, Dropdown, Grid, Icon, Wrapper } from "#ui";
import React, { useRef } from "react";
import styled from "styled-components/macro";
import { ButtonModal } from "../ButtonModal/ButtonModal";
import { InfoItemProps } from "../Info/Info";

export const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 112px 0 88px;
  @media ${bp.m} {
    padding: 124px 0;
  }
  @media ${bp.t} {
    padding: 136px 0;
  }
`;

const InfoButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 34px;
  margin: 18px auto;
  z-index: 1;
  @media ${bp.m} {
    margin: 23px auto;
  }
`;

const ChartTitleWrapper = styled.div`
  width: 100%;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  top: ${HEADER_HEIGHT[bpTypes.i]}px;
  max-width: 70%;
  @media ${bp.m} {
    max-width: none;
    top: ${HEADER_HEIGHT[bpTypes.m]}px;
  }
`;

const ChartTitleContainer = styled.div<{ withTooltip?: boolean }>`
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: ${({ withTooltip }): string => (withTooltip ? "15px" : "0")};
`;

const ChartTitle = styled.h2`
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.0175em;
  line-height: 1.2;
  @media ${bp.s} {
    font-size: 16px;
  }
  @media ${bp.m} {
    font-size: 26px;
  }
  @media ${bp.t} {
    font-size: 36px;
  }
`;

const InfoHint = styled.div`
  margin-left: 10px;
`;

const InfoHintBtn = styled.button`
  ${clearBtn}
  border-radius: 50%;
  background-color: ${Color.Green};
  height: 20px;
  width: 20px;
`;

const BottomContentContainer = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
`;

const BottomContent = styled.div`
  ${gridColumnStart(3)}
  ${gridColumnWidth(6)}
`;

const LinkLabel = styled.span`
  color: ${Color.Black};
  font-size: 14px;
  border-bottom: 2px solid ${Color.Green};
  transition: color ${defTransition};
  &:hover {
    color: ${Color.Green};
  }
  @media ${bp.m} {
    font-size: 16px;
  }
`;

interface LinkProps {
  to: string;
  text: string;
}

interface ChartContainerProps {
  title?: Translations;
  tooltipContent?: Translations;
  bottomLink?: LinkProps;
  list?: InfoItemProps[];
}

export const ChartContainer: React.FC<ChartContainerProps> = ({
  children,
  title,
  tooltipContent,
  bottomLink,
  list,
}) => {
  const hintRef = useRef<HTMLButtonElement>(null);
  const { isPlaying, progress } = useTour();
  const isAutoplay = isPlaying || progress !== 0;

  return (
    <ChartWrapper>
      {title && (
        <ChartTitleWrapper>
          <ChartTitleContainer withTooltip={Boolean(tooltipContent)}>
            <ChartTitle>{title}</ChartTitle>
            {tooltipContent && (
              <InfoHint>
                <Dropdown content={tooltipContent.toString()} buttonRef={hintRef}>
                  <InfoHintBtn ref={hintRef}>
                    <Icon iconKey={IconKeys.SvgTooltip} />
                  </InfoHintBtn>
                </Dropdown>
              </InfoHint>
            )}
          </ChartTitleContainer>
        </ChartTitleWrapper>
      )}
      {children}
      {!isAutoplay && list && (
        <InfoButtonContainer>
          <ButtonModal list={list}>
            {({ onClick }) => (
              <ButtonCircle
                onClick={wrapCallbackWithAnalyticsEventHandler(onClick, AnalyticsEventTypes.TourInfo, {
                  index: document.title,
                })}
              >
                <Icon iconKey={IconKeys.SvgInfo} />
              </ButtonCircle>
            )}
          </ButtonModal>
        </InfoButtonContainer>
      )}
      {bottomLink && (
        <BottomContentContainer>
          <Wrapper>
            <Grid>
              <BottomContent>
                <a href={bottomLink.to} target="_blank" rel="noreferrer">
                  <LinkLabel>{bottomLink.text}</LinkLabel>
                </a>
              </BottomContent>
            </Grid>
          </Wrapper>
        </BottomContentContainer>
      )}
    </ChartWrapper>
  );
};
