import * as THREE from "three";
import defaults from "defaults";
import config from "./config";
import { clamp, mix } from "./utils";
function linearstep(edge0, edge1, x) {
  let t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
  return t;
}
function smoothstep(edge0, edge1, x) {
  let t = linearstep(edge0, edge1, x);
  return t * t * (3.0 - 2.0 * t);
}
// This has to line up with shader curve
let E = {
  // no easing, no acceleration
  linear: (t) => t,
  // accelerating from zero velocity
  easeInQuad: (t) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
  // accelerating from zero velocity
  easeInQuart: (t) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
  // accelerating from zero velocity
  easeInQuint: (t) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
};
function getCurve(z) {
  let fadeInOut =
    smoothstep(config.travelLength * 0, config.travelLength * 0.5, -z) *
    smoothstep(config.travelLength * 0.8, config.travelLength * 0.6, -z);

  let x = Math.sin(z * config.curveFrequency + Math.PI / 2) * config.curveAmplitude * fadeInOut;

  let y = Math.cos(z * 0.05) * 3 * fadeInOut;
  //   let goDownPeriod = 0.2 * config.travelLength;
  let height = 5;
  // Camera from top
  let s = linearstep(config.travelLength * 0.0, config.travelLength * 0.2, -z);
  y += height - height * E.easeInOutQuad(s);
  // y += 10;

  // y +=
  // 	15 *
  // 	smoothstep(0, config.travelLength * 0.05, -z) *
  // 	smoothstep(config.travelLength * 0.1, config.travelLength * 0.05, -z);
  // y = 0;
  // y *= 0;
  return [x, y];
}

// https://threejs.org/examples/#webgl_geometry_extrude_splines
export class CameraDolly {
  constructor(camera, opts = {}) {
    this.camera = camera;

    opts = defaults(opts, {
      length: 100,
      totalPoints: 100,
      // padding: 20
    });
    this.opts = opts;
    // if(config.poi)
    // config.

    this.position = new THREE.Vector3();
    this.createCurve();
    this.helper = null;
    this.progress = 0;
    this.setProgress(this.progress);

    this.isDisposed = false;
  }
  updateOptions(opts) {
    if (opts.length === this.opts.length && opts.totalPoints === this.opts.totalPoints) return;

    opts = defaults(opts, {
      length: 100,
      totalPoints: 100,
    });
    this.opts = opts;
    this.createCurve();
  }
  createCurve() {
    let opts = this.opts;
    let points = [];
    // console.log(opts.length, opts);
    for (let i = 0; i < opts.totalPoints; i++) {
      let iPercent = i / (opts.totalPoints - 1);
      let z = -iPercent * opts.length;
      let xy = getCurve(z, 0);
      points.push(new THREE.Vector3(xy[0], xy[1] + 2, z));
    }
    // let padding = 0;
    // for (let i = 0; i < opts.totalPoints; i++) {
    // 	let iPercent = i / (opts.totalPoints - 1);
    // 	let z = -iPercent * opts.length;

    // 	let xy = getCurve(z, 0);
    // 	points.push(new THREE.Vector3(xy[0], xy[1] + 2, z));
    // }
    this.points = points;
    this.curve = new THREE.CatmullRomCurve3(points);

    // Last post for final look at calculation.
    let iPercent = (opts.totalPoints + 1) / (opts.totalPoints - 1);
    let z = -iPercent * opts.length;
    let xy = getCurve(z, 0);

    this.finalPoint = new THREE.Vector3(xy[0], xy[1] + 2, z);
    this.finalPoint = new THREE.Vector3(xy[0], xy[1] + 2, z);
  }
  //   createHelper() {
  //     if (this.helper) {
  //       return this.helper;
  //     }
  //     const points = this.curve.getPoints(100);
  //     const geometry = new THREE.BufferGeometry().setFromPoints(points);
  //     const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
  //     // Create the final object to add to the scene
  //     const curveObject = new THREE.Line(geometry, material);
  //     this.helper = curveObject;
  //     curveObject.position.y = -1;
  //     curveObject.name = "cameraDollyHelper";
  //     return curveObject;
  //   }
  setProgress(progress) {
    if (this.isDisposed) return;
    // progress = 0.3;
    let p = this.curve.getPoint(progress);
    this.camera.position.copy(p);
    let lookAtProgress = progress + 0.05;
    let lookAt = this.curve.getPoint(lookAtProgress);
    if (lookAtProgress > 1) {
      lookAt = this.finalPoint;
    }

    this.camera.lookAt(lookAt);
    this.camera.rotation.x = mix(0.35, this.camera.rotation.x, linearstep(0.05, 0.13, progress));
    this.camera.position.add(this.position);
    // console.log(this.position.y);
    // this.camera.rotation.x = 1;
    this.progress = progress;
  }
  dispose() {
    this.isDisposed = true;

    this.finalPoint = null;
    this.curve = null;
    this.points = null;
  }
  //   tick() {}
}
