import { HEADER_HEIGHT } from "#constants";
import { bp, bpTypes, Color, ColorName, defTransition, font, gridColumnStart, gridColumnWidth } from "#shared/theme";
import htmr from "htmr";
import React from "react";
import styled, { css } from "styled-components/macro";
import { FootnoteProps, Footnotes } from "../Footnotes/Footnotes";
import { Grid } from "../Grid/Grid";
import { Scrollbars } from "../Scrollbars/Scrollbars";

export interface TdProps {
  type?: "td" | "th";
  accented?: boolean;
  bold?: boolean;
  thickLine?: boolean;
  bgColor: ColorName;
}
export interface TableCellProps {
  props?: TdProps;
  children: string;
}

export interface TableProps {
  inGrid?: boolean;
  bgColor?: ColorName;
  head: TableCellProps[][];
  data: TableCellProps[][];
  footnotes?: FootnoteProps[];
}

const Container = styled.div`
  margin-top: 60px;
`;
const Content = styled.div`
  margin: 34px 0;
  z-index: 1;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    ${gridColumnStart(1)}
    ${gridColumnWidth(24)}
  }
  @media ${bp.p} {
    ${gridColumnStart(6)}
    ${gridColumnWidth(14)}
  }
`;
const StickyContainer = styled.div`
  position: sticky;
  z-index: 2;
  display: none;
  top: ${HEADER_HEIGHT[bpTypes.i] - 3}px;
  @media ${bp.m} {
    top: ${HEADER_HEIGHT[bpTypes.m] - 3}px;
  }
  @media ${bp.t} {
    display: block;
  }
`;

interface TableStickyProp {
  isSticky?: boolean;
  transparent?: boolean;
}

const TableTag = styled.table<TableStickyProp>`
  table-layout: fixed;
  border-collapse: separate;
  min-width: 100%;
  font-family: ${font.condensed};
  line-height: 1.25;
  border-spacing: 8px 0;
  overflow-wrap: normal;
  margin-bottom: 15px;
  color: ${Color.Black};
  font-size: 15px;
  @media ${bp.l} {
    font-size: 17px;
  }
  ${({ isSticky = false }) =>
    isSticky &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    `}
`;
const Td = styled.td<TdProps>`
  font-weight: ${({ bold = false }) => (bold ? "bold" : "normal")};
  white-space: ${({ type = "td" }): string => (type === "th" ? "normal" : "nowrap")};
  position: relative;
  padding: 30px 8px 15px;
  height: 100%;
  min-width: 60px;
  width: 80px;
  text-align: right;
  vertical-align: bottom;
  background-color: ${({ bgColor }) => Color[bgColor]};
  border-bottom: ${({ thickLine }) => (!thickLine ? 1 : 3)}px solid ${Color.Black};
  transition: background-color ${defTransition};
  ${({ accented = false }) =>
    accented &&
    css`
      color: ${Color.BlueAccent};
      border-bottom-color: ${Color.BlueAccent};
    `}
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    min-width: 200px;
    width: 320px;
    text-align: left;
  }
`;
const Inner = styled.div``;
const Tr = styled.tr`
  &:hover ${Td} {
    background-color: ${Color.White};
  }
`;
const THead = styled.thead`
  font-family: ${font.serif};
  line-height: 1.34;
  font-weight: bold;
  font-size: 13px;
  @media ${bp.l} {
    font-size: 14px;
  }
  ${Td} {
    border-bottom-width: 3px;
    white-space: normal;
    padding-top: 15px;
    font-weight: bold;
  }
  ${Tr}:first-child ${Td} {
    border-top-width: 3px;
  }
`;
const TBody = styled.tbody<TableStickyProp>`
  ${Tr}:last-child ${Td} {
    border-bottom-width: 3px;
  }
  ${({ isSticky }) =>
    isSticky &&
    css`
      ${Td} {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
      }
      ${Tr}:last-child ${Td} {
        border: 0;
      }
      ${Inner} {
        height: 0;
        overflow: hidden;
      }
    `}
`;

const TableComponent: React.FC<TableProps & TableStickyProp> = ({
  isSticky,
  data,
  head,
  bgColor = ColorName.GrayLightest,
}) => {
  const renderRow = (tr: TableCellProps[], y: number): JSX.Element => {
    const renderCell = ({ children, props }: TableCellProps, x: number): JSX.Element => {
      return (
        <Td key={`${y}.${x}`} as={props?.type || "td"} bgColor={bgColor} {...props}>
          <Inner>{htmr(children)}</Inner>
        </Td>
      );
    };
    return <Tr key={y}>{tr.map(renderCell)}</Tr>;
  };
  return (
    <TableTag isSticky={isSticky}>
      <THead>{head.map(renderRow)}</THead>
      <TBody isSticky={isSticky}>{data.map(renderRow)}</TBody>
    </TableTag>
  );
};
export const Table: React.FC<TableProps> = ({ inGrid, bgColor = ColorName.GrayLightest, footnotes, ...tableData }) => {
  const content = (
    <>
      <Scrollbars withMask maskColor={bgColor}>
        <TableComponent bgColor={bgColor} {...tableData} />
      </Scrollbars>
    </>
  );
  return inGrid ? (
    <Grid>
      <Content>
        {content}
        {footnotes && <Footnotes list={footnotes} />}
      </Content>
    </Grid>
  ) : (
    <Container>
      <StickyContainer>
        <TableComponent isSticky bgColor={bgColor} {...tableData} />
      </StickyContainer>
      {content}
    </Container>
  );
};
