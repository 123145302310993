import { pages } from "./pages";
import { AnalyticsEventTypes } from "#interfaces";

export const home = {
  start: {
    headline: "On the BMW Group’s economic performance and its ecological and social contributions",
    title: "THE BMW GROUP<br>REPORT 2020",
    slideshow: [
      {
        theme: "BlueBold",
        headline: "Because credibility comes from transparency.",
        image: {
          src: "/data/teaser-1.png",
        },
        link: {
          href: "/value-creation/",
          label: "How the BMW Group<br/>creates value",
        },
      },
      {
        theme: "Blue",
        headline: "Selected key figures from our financial year 2020",
        image: {
          src: "/data/teaser-2.png",
        },
        link: {
          href: "/home/intro/#guided-tour-2020",
          label: "Guided Tour 2020",
        },
      },
      {
        theme: "GrayLightest",
        headline: "The BMW Group Report 2020 as PDF",
        image: {
          src: "",
        },
        bgImage: {
          srcMobile: "/data/teaser-4-portrait.jpg",
          src: "/data/teaser-4-portrait.jpg",
        },
        link: {
          href: "/data/pdf/en/BMW-Group-Bericht-2020-EN.pdf",
          label: "Download",
          download: true,
          analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
        },
      },
    ],
  },
  hero: {
    headline: "<small>SUSTAINABILITY ISN’T JUST<br/> SOMETHING WE DO AT BMW:</small>WE ARE MAKING BMW SUSTAINABLE.",
    author: "OLIVER ZIPSE",
  },
  entry: {
    copytext:
      "<p>At the BMW Group, our ability to combine driving pleasure with sustainable development is a clear differentiating factor.</p><p>Sustainability is firmly anchored at the core of our corporate strategy. We consider the environmental and social impacts, as well as economic aspects, in our activities and planning from the beginning.</p>",
    image: {
      src: "/data/zipse.jpg",
    },
    caption: {
      headline: "OLIVER ZIPSE",
      desc: "CHAIRMAN OF THE BOARD OF MANAGEMENT",
    },
  },
  values: {
    copytext:
      '<p>We take an <a href="/value-creation/" data-line="external" target="_blank" rel="noreferrer">INTEGRATED</a> approach to decision-making. Our customers are sure to experience highly innovative and emotional products. This is what the BMW Group stands for, as a company that thinks and acts in a sustainable manner.</p>',
    image: {
      src: "/data/teaser-1.png",
    },
    caption:
      '<p>Discover more about</p><p><a href="/value-creation/" target="_blank">How the BMW group creates value</a></p>',
    link: {
      type: "external",
      to: pages.list.interactiveValueCreationProcess.path,
      analyticsEvent: AnalyticsEventTypes.GoToVCPFromIntro,
    },
    heading: {
      title: "BMW Group",
      headline: "Der Wertschöpfungsprozess der BMW Group",
    },
    footing: {
      copytext: "Wie die BMW Group Werte schafft",
    },
    closeBtn: {
      analyticsEvent: AnalyticsEventTypes.CloseVCPModalFromIntro,
    },
  },
  quote1: {
    headline:
      "WE BELIEVE TECHNOLOGY IS THE KEY TO MEETING OUR ENVIRONMENTAL AND SOCIAL <strong>RESPONSIBILITY</strong> AND BEING SUCCESSFUL AND PROFITABLE AS A COMPANY.",
    author: "OLIVER ZIPSE",
  },
  goals: {
    copytext:
      '<p>Our consistent sustainable development gives us a competitive edge.</p><p>In 2012, we set ourselves ten strategic <a href="#" data-line="pdf">SUSTAINABILITY GOALS UP TO 2020</a>. Today, we are able to say: mission accomplished. Between 1995 and 2020, we reduced the CO2 emissions of our European new vehicle fleet by more than half. Our plants worldwide source only green power for production. The percentage of women in management at the company is within our target range.</p>',
    link: {
      label: "<strong>PDF-REPORT</strong>Goals 2020",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewGoals2020,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Combined Management Report",
            headline: "General Information and Group Profile",
            page: 11,
            src: "/data/pdf/en/General-Information-and-Group-Profile.pdf",
          },
        },
      ],
    },
  },
  strategy: {
    copytext:
      '<p>We are committed to the goals of the Paris Climate Agreement and have set ourselves concrete, science-based <a href="#" data-line="pdf">TARGETS FOR 2030</a>:</p><p>The aim is to reduce CO2 emissions per vehicle by at least a third throughout their lifecycle – through innovation, rather than offsets.</p><p>Specifically, we will be lowering CO2 emissions from production by 80%, during the use phase by more than 40% and in the supply chain by at least 20%. Without corrective measures, growth in e-mobility would, in fact, increase supply chain CO2 emissions by about a third by 2030. We are reversing this trend and lowering emissions.</p>',
    link: {
      label: "<strong>PDF-REPORT</strong>Strategic goals",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewStrategicGoals,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Combined Management Report",
            headline: "General Information and Group Profile",
            page: 15,
            src: "/data/pdf/en/General-Information-and-Group-Profile.pdf",
          },
        },
      ],
    },
  },
  audio_ceo: {
    photo: { src: "/data/zipse-audio.jpg" },
    src: "/data/audio/oliver_zipse_EN.mp3",
    name: "OLIVER ZIPSE",
    copytext: "ON THE BMW GROUP’S STRATEGIC DIRECTION",
    analyticsEventPlay: AnalyticsEventTypes.AudioCEOPlay,
    analyticsEventPause: AnalyticsEventTypes.AudioCEOPause,
    analyticsEventFinish: AnalyticsEventTypes.AudioCEOFinish,
  },
  results: {
    head: {
      headline: "THE MOST IMPORTANT GOALS<br><strong>OF THE BMW GROUP</strong>",
    },
    list: [
      {
        headline: {
          size: "Default",
          copytext: "Strategic targets",
        },
        list: [
          {
            value: "> 10%",
            caption: "EBT Margin Group",
          },
          {
            value: "↑ 8-10%",
            caption: "EBIT Margin Automotive Segment",
          },
          {
            value: "≥ 40%",
            caption: "Return on Capital Employed Automotive Segment",
          },
        ],
      },
      {
        headline: {
          size: "Large",
          copytext: "2025",
        },
        list: [
          {
            value: "↑ ≥ 25%",
            caption: "Minimum proportion of electrified automobiles to total deliveries",
          },
          {
            value: "↑ 22%",
            caption: "Proportion of women in management positions in the bmw group",
          },
        ],
      },
      {
        headline: {
          size: "Large",
          copytext: "2030",
        },
        list: [
          {
            value: "↓ 80%",
            caption: "Reduction of CO2 emissions per vehicle in production (base year 2019)",
          },
          {
            value: "↓ > 40%",
            caption: "Reduction of co2 emissions in vehicle use phase per kilometre driven (base year 2019)",
          },
          {
            value: "↓ ≥ 20%",
            caption: "REDUCTION OF CO2 EMISSIONS IN THE SUPPLY CHAIN (BASE YEAR 2019)",
          },
        ],
      },
    ],
  },
  dummy2: {
    copytext:
      "<p>Thinking far into the future and the ability to master the complexity of our industry – that is what sets the BMW Group apart. That is what keeps us successful.</p><p><cite>Your Oliver Zipse<small>CHAIRMAN OF THE BOARD OF MANAGEMENT</small></cite></p>",
  },
  quote2: {
    headline:
      "WE PROVED DURING THE LAST FINANCIAL YEAR THAT WE ARE CAPABLE OF MASTERING CHALLENGING SITUATIONS AND, AT THE SAME TIME, SETTING THE <strong> RIGHT COURSE FOR THE FUTURE</strong>.",
    author: "NICOLAS PETER",
  },
  about: {
    copytext:
      "<p>We have integrated sustainability even more closely into our corporate decisions and are taking transparency for our stakeholders a step further. Our first Integrated BMW Group Report combines the financial report with our Sustainable Value Report.</p>",
    image: {
      src: "/data/peter.jpg",
    },
    caption: {
      headline: "NICOLAS PETER",
      desc: "MEMBER OF THE BOARD OF MANAGEMENT OF BMW AG, FINANCE",
    },
  },
  dummy3: {
    copytext:
      '<p>Here, we set out in a clear and transparent manner how sustainability activities influence our  <a href="#" data-line="pdf">VALUE CREATION</a>  in accordance with internationally recognised standards.</p>',
    link: {
      label: "<strong>PDF-REPORT</strong>How the BMW Group creates value",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewHowTheBMWGroupCreatesValue,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Combined Management Report",
            headline: "General Information and Group Profile",
            page: 21,
            src: "/data/pdf/en/General-Information-and-Group-Profile.pdf",
          },
        },
      ],
    },
  },
  audio_cfo: {
    photo: { src: "/data/peter-audio.jpg" },
    src: "/data/audio/nicolas_peter_EN.mp3",
    name: "NICOLAS PETER",
    copytext: "ON THE INTEGRATED REPORT",
    analyticsEventPlay: AnalyticsEventTypes.AudioCFOPlay,
    analyticsEventPause: AnalyticsEventTypes.AudioCFOPause,
    analyticsEventFinish: AnalyticsEventTypes.AudioCFOFinish,
  },
  cfo_text1: {
    copytext:
      "In 2020, we demonstrated our ability to act quickly and flexibly under highly volatile conditions. A consistent cost management in the first half of the year created the necessary conditions for us to capitalize on the market recovery in the second half of the year.  We increased earnings – proving to our stakeholders that we are a reliable partner.",
  },
  cfo_text2: {
    copytext:
      "<p>Our perspective is always long-term, with decisions aimed at creating sustainable value based on profitability and through growth.<p>Despite continued volatility and an environment impacted by the pandemic, we expect to see an upward trend in business development in the financial year 2021.</p></p><p><cite>Your Nicolas Peter<small>MEMBER OF THE BOARD OF MANAGEMENT OF BMW AG, FINANCE</small></cite><p>",
  },
  guidedtour: {
    headline: "Guided Tour",
    subheadline: "Selected<br>key figures 2020",
  },
  gesagtgetan: {
    head: {
      headline: "SUSTAINABILITY TARGETS 2020 ACHIEVED.<br><strong>THREE EXAMPLES:</strong>",
      // link: {
      //   label: "<strong>ZIELE-GRAFIK</strong> <small>AUS DEM PDF-BERICHT</small>",
      //   href: "#",
      // },
    },
  },
};
