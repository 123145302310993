import React, { FC, useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components/macro";
import { bp, Color } from "#shared/theme";
import gsap from "gsap";
import { rootElement } from "#helpers";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div<{ align: ParallaxAlign }>`
  position: relative;
  ${({ align }) =>
    align == ParallaxAlign.Top &&
    css`
      margin-top: 100px;

      @media ${bp.p} {
        margin-top: 200px;
      }
      @media ${bp.d} {
        margin-top: 250px;
      }
    `}
`;

const ParallaxElement = styled.div<Parallax>`
  display: block;
  height: 100%;
  background-color: ${({ parallaxColor }) => Color[parallaxColor]};
  position: absolute;
  top: 140px;
  left: 0;
  right: calc(50% + 100% / 6);
  @media ${bp.m} {
    top: 240px;
  }
  @media ${bp.d} {
    width: 500px;
    left: calc(50% - (1920px / 2));
  }
  ${({ align }) =>
    align === ParallaxAlign.Top &&
    css`
      height: initial;
      padding-top: 250px;
      top: -100px;
      left: 70px;
      right: 0;
      @media ${bp.m} {
        top: -100px;
        left: 200px;
        right: 70px;
      }
      @media ${bp.p} {
        padding-top: 300px;
        top: -200px;
        left: 300px;
        right: 100px;
      }
      @media ${bp.t} {
        left: 400px;
        right: 140px;
      }
      @media ${bp.d} {
        padding-top: 400px;
        width: 880px;
        top: -250px;
        left: calc(50% - (1440px / 6));
      }
    `}
  ${({ align }) =>
    align === ParallaxAlign.Right &&
    css`
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% + 100% / 6);
      height: calc(100% + 120px);
      @media ${bp.m} {
        top: 0;
        min-height: 400px;
        height: calc(100% + 200px);
      }
      @media ${bp.t} {
        min-height: 500px;
      }
      @media ${bp.d} {
        left: calc(50% - 500px + (100% / 2));
        height: calc(100% + 200px);
      }
      @media ${bp.h} {
        left: calc(50% - 500px + (1920px / 2));
      }
    `}
`;

export enum ParallaxAlign {
  Top = "Top",
  Right = "Right",
}
export enum ParallaxColor {
  Orange = "Orange",
  BlueBold = "BlueBold",
  GreenLight = "GreenLight",
}

interface Parallax {
  align: ParallaxAlign;
  height?: number;
  parallaxColor: ParallaxColor;
}

export const Parallax: FC<Parallax> = ({ align, height, parallaxColor, children }) => {
  const parallaxRef = useRef<HTMLDivElement>(null);
  const parallaxContainerRef = useRef<HTMLDivElement>(null);
  const tl = useMemo(() => gsap.timeline(), []);
  const isDesktop = useMediaQuery({ query: bp.m });

  useEffect(() => {
    setTimeout(() => {
      if (!parallaxContainerRef.current) return;
      tl.fromTo(
        parallaxRef.current,
        { y: 0 },
        {
          y: isDesktop ? "-200px" : "-100px",
          scrollTrigger: {
            trigger: parallaxContainerRef.current,
            start: align === ParallaxAlign.Top ? `${isDesktop ? "-200px" : "-100px"} top` : "top bottom",
            end: "bottom top",
            scrub: 1,
            scroller: rootElement,
          },
        }
      );
    }, 800);
  }, [parallaxContainerRef, tl, isDesktop, align]);

  return (
    <Container align={align} ref={parallaxContainerRef}>
      <ParallaxElement
        align={align}
        ref={parallaxRef}
        parallaxColor={parallaxColor}
        style={{ paddingBottom: `${height}px` }}
      />
      {children}
    </Container>
  );
};
