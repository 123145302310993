import React from "react";
import { createContext, useContext } from "react";
import { OverlayContextProps, OVERLAY_DEFAULT_VALUE, OverlayProviderProps, OverlayProvider } from "../Overlay/Overlay";

const DarkOverlayContext = createContext<OverlayContextProps>(OVERLAY_DEFAULT_VALUE);
export const useDarkOverlay = (): OverlayContextProps => useContext<OverlayContextProps>(DarkOverlayContext);

export const DarkOverlayProvider: React.FC<OverlayProviderProps> = (props) => (
  <OverlayProvider ContextProvider={DarkOverlayContext.Provider} {...props} />
);
