import { triggerAnalyticsEvent } from "#helpers";
import { AnalyticsEventTypes } from "#interfaces";
import { bp, Color, fontCondensed, IconKeys } from "#shared/theme";
import { ButtonCircle, Icon, ScrollbarsContext } from "#ui";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player";
import styled from "styled-components/macro";
import { Bars } from "./Bars";

const Container = styled.div``;

const Player = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Image = styled.div`
  width: 75px;
  height: 86px;
  border-radius: 10px;
  overflow: hidden;
  @media ${bp.t} {
    width: 95px;
    height: 108px;
  }
  @media ${bp.d} {
    width: 120px;
    height: 135px;
  }
`;
const Photo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const Svg = styled.svg`
  width: 100%;
  height: 60px;
  @media ${bp.t} {
    height: 88px;
  }
  @media ${bp.d} {
    height: 125px;
  }
`;
const Wrapper = styled.div`
  border-bottom: 4px solid ${Color.Black};
  padding-bottom: 16px;
`;
const Author = styled(Wrapper)`
  padding-right: 12px;
  @media ${bp.t} {
    padding-right: 27px;
  }
`;
const Progress = styled(Wrapper)`
  padding-left: 12px;
  display: flex;
  align-items: flex-end;
  flex: 1 0 0%;
  @media ${bp.t} {
    padding-left: 27px;
  }
`;
const Control = styled.div`
  position: relative;
  bottom: -15px;
  padding: 0 8px;
  height: 34px;
`;
const Content = styled.div`
  ${fontCondensed}
  margin-top: 45px;
  max-width: 460px;
  @media ${bp.p} {
    margin-top: 55px;
  }
  @media ${bp.t} {
    max-width: 580px;
    margin-top: 75px;
  }
`;
const Name = styled.h4`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Green};
`;
const Copytext = styled.p`
  margin-top: 10px;
  font-size: 13px;
  line-height: 18px;
  color: ${Color.White};
`;

interface MaskProps {
  width: number;
  x?: number;
  id: string;
}
const Mask: React.FC<MaskProps> = ({ width, x = 0, id }) => {
  return (
    <mask id={id}>
      <rect x={x} y="0" width={width} height="100" fill="white" />
    </mask>
  );
};

export interface AudioPlayerProgressProps {
  percentComplete: number;
}

export const AudioPlayerProgress: React.FC<AudioPlayerProgressProps> = ({ percentComplete }) => {
  const isDesktop = useMediaQuery({ query: bp.m });
  const maskProgressId = "barsBaskProgress" + nanoid();
  const maskRestId = "barsMaskRest" + nanoid();
  const viewBox = [
    [147, 35],
    [577, 63],
  ];
  const svgViewBoxWidth = viewBox[Number(isDesktop)][0];
  const svgProgressWidth = Math.round((percentComplete * svgViewBoxWidth) / 100);
  return (
    <Svg viewBox={`0 0 ${svgViewBoxWidth} ${viewBox[Number(isDesktop)][1]}`} preserveAspectRatio="none">
      <Mask id={maskProgressId} width={svgProgressWidth} />
      <Bars maskId={maskProgressId} color={Color.Green} />
      <Mask id={maskRestId} x={svgProgressWidth} width={svgViewBoxWidth - svgProgressWidth} />
      <Bars maskId={maskRestId} color={Color.BlueGray} />
    </Svg>
  );
};

export interface AudioPlayerProps {
  src: string;
  name: string;
  copytext: string;
  photo: {
    src: string;
  };
  analyticsEventPlay?: AnalyticsEventTypes;
  analyticsEventPause?: AnalyticsEventTypes;
  analyticsEventFinish?: AnalyticsEventTypes;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src,
  name,
  copytext,
  photo,
  analyticsEventPlay,
  analyticsEventPause,
  analyticsEventFinish,
}) => {
  const { togglePlayPause, ready, loading, playing, ended, pause } = useAudioPlayer({
    src,
    format: "mp3",
    autoplay: false,
    html5: true,
  });
  const prevPlayingRef = useRef(playing);
  const { percentComplete } = useAudioPosition({ highRefreshRate: true });
  const { scrollbarRef } = useContext(ScrollbarsContext);
  const [containerRef, inView] = useInView({
    root: scrollbarRef?.current?.osTarget(),
  });

  if (!ready && !loading) return <div>No audio to play</div>;

  useEffect(() => {
    if (!playing || inView) return;
    pause();
  }, [playing, inView, pause]);

  useEffect(() => {
    if (ended && analyticsEventFinish) {
      triggerAnalyticsEvent(analyticsEventFinish);
    }
  }, [ended, analyticsEventFinish, playing]);

  useEffect(() => {
    const analyticsEvent = playing ? analyticsEventPlay : analyticsEventPause;

    if (prevPlayingRef.current !== playing && analyticsEvent) {
      triggerAnalyticsEvent(analyticsEvent, {
        progress: Math.ceil(percentComplete / 10) * 10,
      });
    }

    prevPlayingRef.current = playing;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, analyticsEventPlay, analyticsEventPause, prevPlayingRef]);

  return (
    <Container ref={containerRef}>
      <Player>
        <Author>
          <Image>
            <Photo src={photo.src} />
          </Image>
        </Author>
        <Control>
          <ButtonCircle onClick={!loading ? togglePlayPause : undefined}>
            {loading ? "..." : <Icon iconKey={playing ? IconKeys.SvgPause : IconKeys.SvgPlay} />}
          </ButtonCircle>
        </Control>
        <Progress>
          <AudioPlayerProgress percentComplete={percentComplete} />
        </Progress>
      </Player>
      <Content>
        <Name>{name}</Name>
        <Copytext>{copytext}</Copytext>
      </Content>
    </Container>
  );
};
