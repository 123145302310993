import React from "react";
import { SvgContainer } from "#ui";
import { MainKPI } from "./MainKPI";
import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { ChartProps } from "#interfaces";

const CHART_COUNT = 1;

export const ShareEarningsChartDesktop: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 896 544" xmlns="http://www.w3.org/2000/svg">
      <MainKPI labels={labels} {...chartProps} />
    </SvgContainer>
  );
};
