import React, { createContext, RefObject, useContext, useState } from "react";

interface ModalContextProps {
  modalContainerRef: RefObject<HTMLDivElement> | null;
  isOpenCompleted: boolean;
  setOpenCompleted: (n: boolean) => void;
}
const DEFAULT_VALUE = {
  modalContainerRef: null,
  isOpenCompleted: false,
  setOpenCompleted: () => undefined,
};

const ModalContainer = createContext<ModalContextProps>(DEFAULT_VALUE);
export const useModalContainer = (): ModalContextProps => useContext<ModalContextProps>(ModalContainer);

export const ModalProvider: React.FC<Pick<ModalContextProps, "modalContainerRef">> = ({
  children,
  modalContainerRef,
}) => {
  const [isOpenCompleted, setOpenCompleted] = useState<boolean>(false);
  return (
    <ModalContainer.Provider value={{ modalContainerRef, isOpenCompleted, setOpenCompleted }}>
      {children}
    </ModalContainer.Provider>
  );
};
