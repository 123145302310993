import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ogLocales, htmlLocales } from "#constants";
import { useTranslation } from "#hooks";
import i18n from "src/i18n";
import { PageMeta, PageName } from "#interfaces";

export const Head: React.FC = () => {
  const { pathname, hash } = useLocation();
  const { t: tPages } = useTranslation("pages");
  const pagesList: { [k: string]: PageMeta } = tPages("list");
  const foundPage = Object.values(pagesList).find((item) => {
    return item.path === `${pathname}${hash}`;
  });
  const { title: pageTitle, description, image } = foundPage || pagesList[PageName.index];
  const { title: indexPageTitle } = pagesList[PageName.index];
  const title = `${pageTitle} — ${indexPageTitle}`;
  return (
    <Helmet>
      <html lang={htmlLocales[i18n.language]} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:locale" content={ogLocales[i18n.language]} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};
