import { useRouting } from "#providers";
import React, { MouseEvent } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

export type { LinkProps };

export const Link: React.FC<LinkProps> = ({ to, className, children, onClick, innerRef }) => {
  const { set } = useRouting();
  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    onClick?.(e);
    set(to as string);
  };
  return (
    <RouterLink to={to} className={className} onClick={handleClick} innerRef={innerRef}>
      {children}
    </RouterLink>
  );
};
