import React, { createContext, useContext, useReducer } from "react";

export enum SlideshowActionType {
  prev = "prev",
  next = "next",
  set = "set",
  setReady = "setReady",
  prevReady = "prevReady",
  nextReady = "nextReady",
}
interface SlideshowContextProps {
  index: number;
  indexReady: number;
  next: () => void;
  prev: () => void;
  set: (n: number) => void;
  setReady: (n: number) => void;
  prevReady: () => void;
  nextReady: () => void;
}
interface SlideshowState {
  index: number;
  indexReady: number;
}
type SlideshowAction = { type: SlideshowActionType; payload: { length: number; index?: number; indexReady?: number } };

type SlideshowReducerType = (state: SlideshowState, action: SlideshowAction) => SlideshowState;

const SlideshowReducer: SlideshowReducerType = (state, { type, payload }) => {
  const lastIndex = payload.length - 1;
  switch (type) {
    case SlideshowActionType.next:
      return { ...state, index: state.index >= lastIndex ? 0 : state.index + 1 };
    case SlideshowActionType.prev:
      return { ...state, index: state.index < 1 ? lastIndex : state.index - 1 };
    case SlideshowActionType.set:
      return { ...state, ...payload };
    case SlideshowActionType.nextReady:
      return { ...state, indexReady: state.indexReady >= lastIndex ? 0 : state.indexReady + 1 };
    case SlideshowActionType.prevReady:
      return { ...state, indexReady: state.indexReady < 1 ? lastIndex : state.indexReady - 1 };
    case SlideshowActionType.setReady:
      return { ...state, indexReady: payload.indexReady || 0 };
    default:
      return state;
  }
};

const DEFAULT_VALUE = {
  index: 0,
  indexReady: 0,
  next: (): void => undefined,
  prev: (): void => undefined,
  set: (): void => undefined,
  nextReady: (): void => undefined,
  prevReady: (): void => undefined,
  setReady: (): void => undefined,
};

const SlideshowContext = createContext<SlideshowContextProps>(DEFAULT_VALUE);
export const useSlideshow = (): SlideshowContextProps => useContext<SlideshowContextProps>(SlideshowContext);

interface SlideshowProviderProps {
  defaultIndex?: number;
  length: number;
}

export const SlideshowProvider: React.FC<SlideshowProviderProps> = ({ children, defaultIndex = 0, length }) => {
  const [{ index, indexReady }, dispatch] = useReducer(SlideshowReducer, {
    index: defaultIndex,
    indexReady: defaultIndex,
  });
  const payload = {
    length,
    index,
    indexReady,
  };
  const value = {
    index,
    indexReady,
    prev: (): void => dispatch({ type: SlideshowActionType.prev, payload }),
    next: (): void => dispatch({ type: SlideshowActionType.next, payload }),
    set: (n: number): void =>
      dispatch({ type: SlideshowActionType.set, payload: { ...payload, index: n, indexReady: n } }),
    prevReady: (): void => dispatch({ type: SlideshowActionType.prevReady, payload }),
    nextReady: (): void => dispatch({ type: SlideshowActionType.nextReady, payload }),
    setReady: (n: number): void =>
      dispatch({ type: SlideshowActionType.setReady, payload: { ...payload, indexReady: n } }),
  };
  return <SlideshowContext.Provider value={value}>{children}</SlideshowContext.Provider>;
};
