import {
  ContractsChart,
  DeliveriesChart,
  EBITMarginChart,
  ElectricChart,
  EmissionsChart,
  GlobalMarketsChart,
  KeyFiguresChart,
  MotoChart,
  ResearchChart,
  ShareEarningsChart,
  WomenChart,
  TourEnd,
} from "#components";
import { usePopover, useTranslation } from "#hooks";
import { SlideshowItemProps, TourComponents } from "#interfaces";
import { TourSlideContext, useTour } from "#providers";
import { bp, clearBtn, Color, expander, fadeIn, fadeOut, IconKeys } from "#shared/theme";
import React, { TransitionEvent, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { ButtonCircle, Icon, IconSize } from "#ui";
import { gsap } from "gsap";
import { rootElement } from "#helpers";
import { isIos, isSafari } from "#constants";

export type TourSlidesType = {
  [k: string]: React.ReactChild;
};

export const tourSlides: TourSlidesType = {
  [TourComponents.EbitMargin]: <EBITMarginChart />,
  [TourComponents.ElectricVehicles]: <ElectricChart />,
  [TourComponents.Markets]: <GlobalMarketsChart />,
  [TourComponents.Keyfigures]: <KeyFiguresChart />,
  [TourComponents.Contracts]: <ContractsChart />,
  [TourComponents.Deliveries]: <DeliveriesChart />,
  [TourComponents.Emissions]: <EmissionsChart />,
  [TourComponents.Research]: <ResearchChart />,
  [TourComponents.Shares]: <ShareEarningsChart />,
  [TourComponents.Moto]: <MotoChart />,
  [TourComponents.Women]: <WomenChart />,
  [TourComponents.TourEnd]: <TourEnd />,
};

interface ItemProps {
  isActive: boolean;
  isOpen: boolean;
}

const Item = styled.div<ItemProps>`
  ${expander}
  ${({ isActive }) => (isActive ? fadeIn : fadeOut)}
  z-index: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;
const ItemContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TourShowItem: React.FC<Pick<ItemProps, "isActive">> = ({ isActive, children }) => {
  const itemRef = useRef(null);
  const { isOpen, open, close, reset } = usePopover();
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== itemRef.current) return;
    reset();
    rootElement.scrollTo(0, 0);
  };
  useEffect(() => {
    if (isOpen) {
      if (!isActive) {
        close();
      }
    } else {
      if (isActive) {
        open();
      }
    }
  }, [isOpen, isActive, open, close]);
  return (
    <Item onTransitionEnd={handleTransitionEnd} isActive={isActive} isOpen={isOpen}>
      <ItemContainer>{children}</ItemContainer>
    </Item>
  );
};

const Container = styled.div`
  ${expander}
  z-index: 1;
  top: 0;
  background-color: ${Color.BlueSaturated};
  box-shadow: 0 100px 0 0 ${Color.BlueSaturated};
`;

const ControlsButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 34px;
  margin: 18px auto;
  z-index: 1;
  @media ${bp.m} {
    margin: 23px auto;
  }
`;

const Button = styled.button`
  ${clearBtn}
  flex: 0 0 34px;
  height: 34px;
  margin-left: 32px;
  fill: ${Color.Green};
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
`;

export const Tour: React.FC = () => {
  const [muted, setMuted] = useState<boolean>(false);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const { set, index, isPlaying, play, pause, progress, isStopped } = useTour();
  const { t } = useTranslation("menu");
  const { t: tTour } = useTranslation("tour");
  const sound = tTour("sound");
  const audio = useMemo(() => new Audio(sound), [sound]);
  const { list: tourMenuList } = t("tour");
  const tourList = tourMenuList[0].list;
  const isAutoplay = !isStopped;
  const notSupportedOS = isIos || isSafari;
  const handleClickMute = () => {
    setMuted(!muted);
  };
  const handleAutoplay = () => {
    if (isPlaying) {
      pause();
      gsap.to(audio, { volume: 0, duration: 1 });
    } else {
      play();
      if (!muted) {
        gsap.to(audio, { volume: 1, duration: 1 });
      }
    }
  };
  const renderSlides = (item: SlideshowItemProps, key: number) => {
    return (
      <TourShowItem key={key} isActive={key === index} {...item}>
        <TourSlideContext.Provider value={{ key }}>{tourSlides[item.slide]}</TourSlideContext.Provider>
      </TourShowItem>
    );
  };

  // Navigate to slide on browser history change
  useEffect(() => {
    const { pathname, hash } = location;
    const foundIndex = tourList.findIndex((item: SlideshowItemProps) => item.to === `${pathname}${hash}`);
    if (foundIndex === index) return;
    set(foundIndex);
  }, [index, tourList, set]);

  useEffect(() => {
    if (!isLoaded || notSupportedOS) return;
    if (muted) {
      gsap.to(audio, { volume: 0, duration: 0.5 });
    } else {
      gsap.to(audio, { volume: 1, duration: 0.5 });
    }
  }, [muted, isLoaded, audio, notSupportedOS]);

  useEffect(() => {
    if (!isLoaded || notSupportedOS) return;
    if (isAutoplay) {
      audio.volume = 1;
      audio.play();
    } else {
      gsap.to(audio, {
        volume: 0,
        duration: 1,
        onComplete: () => {
          audio.pause();
          audio.currentTime = 0;
        },
      });
    }
  }, [audio, isAutoplay, isLoaded, notSupportedOS]);

  useEffect(() => {
    audio.addEventListener("loadedmetadata", function () {
      setLoaded(true);
    });
  }, [audio]);
  return (
    <Container>
      {tourList.map(renderSlides)}
      {isAutoplay && (
        <ControlsButtonContainer>
          <ButtonCircle onClick={handleAutoplay} progress={progress}>
            <Icon iconKey={isPlaying ? IconKeys.SvgPause : IconKeys.SvgPlay} />
          </ButtonCircle>
          {!notSupportedOS && (
            <Button onClick={handleClickMute}>
              <Icon size={IconSize.Middle} iconKey={muted ? IconKeys.SvgSoundOffCircle : IconKeys.SvgSoundOnCircle} />
            </Button>
          )}
        </ControlsButtonContainer>
      )}
    </Container>
  );
};
