import * as svgIcons from "./svg";

export type IconType = keyof typeof svgIcons;

// Make Icon List iterable
export const iconsList = svgIcons as {
  [type: string]: IconType;
};

export const IconKeys = Object.assign(
  {},
  ...Object.keys(iconsList).map((key) => ({
    [key]: key,
  }))
);
