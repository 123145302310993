import { clamp } from "#helpers";
import { useSlideshow } from "#providers";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { animated, useSprings } from "react-spring";
import { useDrag } from "react-use-gesture";
import useMeasure from "react-use-measure";
import styled from "styled-components/macro";

const Container = styled.div`
  flex: 1 0 0%;
  overscroll-behavior-y: contain;
  position: relative;
  width: 100%;
  display: flex;
  user-select: none;
  overflow: hidden;
`;
const Item = styled(animated.div)`
  flex: 0 0 100%;
  max-width: 100%;
  margin-right: -100%;
  will-change: transform;
  user-select: none;
`;

export interface SwiperProps<T> {
  list: T[];
  renderItem: (props: T, key: number) => React.ReactChild;
  disableDrag?: boolean;
}

export const Swiper = <T extends unknown>({
  list,
  renderItem,
  disableDrag = false,
}: PropsWithChildren<SwiperProps<T>>): JSX.Element => {
  const [containerRef, { width: containerWidth }] = useMeasure();
  const { index, set: setIndex } = useSlideshow();
  const [items, set] = useSprings(list.length, (i) => ({
    x: i * containerWidth,
    display: "block",
    zIndex: index === i ? 1 : 0,
  }));
  const setPosition = useCallback(
    (active: boolean, mx: number) => {
      set((i) => {
        const outOfRange = i < index - 1 || i > index + 1;
        const x = ((i - index) * containerWidth) / 2 + (active ? mx : 0);
        return { x, display: outOfRange ? "none" : "block", zIndex: index === i ? 1 : 0 };
      });
    },
    [containerWidth, set, index]
  );
  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], distance, cancel, event }) => {
      const nextIndex = index + (xDir > 0 ? -1 : 1);
      const isOutOfRange = nextIndex > list.length - 1 || nextIndex < 0;
      if (!isOutOfRange) {
        event.stopPropagation();
      }
      if (active && distance > containerWidth / 4) {
        setIndex(clamp(nextIndex, 0, list.length - 1));
        cancel();
      }
      setPosition(active, mx);
    },
    { filterTaps: true, enabled: !disableDrag }
  );
  useEffect(() => {
    setPosition(true, 0);
  }, [index, setPosition]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderItems = (item: any, key: number) => (
    <Item {...bind()} key={key} style={{ ...item, transform: item.x?.to((x: number) => `translateX(${x}px)`) }}>
      {renderItem(list[key], key)}
    </Item>
  );
  return <Container ref={containerRef}>{items.map(renderItems)}</Container>;
};
