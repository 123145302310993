import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";

interface OffsetReturn {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const getElementOffset = (element: HTMLElement, parent: HTMLElement): OffsetReturn => {
  const offset = {
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  };
  let offsetParent = element;

  while (offsetParent && offsetParent !== parent) {
    offset.top += offsetParent.offsetTop;
    offset.left += offsetParent.offsetLeft;
    offsetParent = offsetParent.offsetParent as HTMLElement;
  }
  offset.width = element.offsetWidth;
  offset.height = element.offsetHeight;

  return offset;
};

export const rootElement = document.getElementById("root") as HTMLElement;

export const scrollToElement = (hash: string): void => {
  if (!hash) return;
  const element = document.querySelector(hash) as HTMLElement;
  if (!element) return;
  const distance = Math.abs(element.offsetTop - rootElement.scrollTop);
  gsap.to(rootElement, { duration: (4 * distance) / 15000, scrollTo: { y: hash }, ease: Power3.easeInOut });
};
