import { chartMainItemDuration, chartSideItemValuesDuration } from "#constants";
import { useChartAnimation, useNumberFormat } from "#hooks";
import { ChartProps } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";

export const RightKPI2: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const currentPathRef = useRef<SVGPathElement>(null);
  const prevPathRef = useRef<SVGPathElement>(null);
  const negativePathRef = useRef<SVGPathElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  const outerLineRef = useRef<SVGPathElement>(null);
  useChartAnimation({
    ...props,
    maxValue: Number(labels?.maxValue),
    currentValue: Number(labels?.currentValue),
    prevValue: Number(labels?.prevValue),
    minDegree: 35.2,
    maxDegree: 33.5,
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    negativePathRef,
    outerLineRef,
    duration: chartSideItemValuesDuration,
    delay: chartMainItemDuration,
    sideItemDelay: 0.3,
    left: true,
  });
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="402" x="448" y="180.549">
        <path d="m448 573.049c-52.916 0-104.253-10.366-152.587-30.81-46.68-19.744-88.601-48.007-124.599-84.004-35.997-35.998-64.26-77.918-84.004-124.599-20.444-48.334-30.81-99.672-30.81-152.587s10.366-104.253 30.81-152.588c19.744-46.68 48.007-88.601 84.004-124.599 35.998-35.997 77.918-64.26 124.599-84.004 48.334-20.444 99.672-30.81 152.587-30.81s104.253 10.366 152.587 30.81c46.68 19.744 88.601 48.007 124.599 84.004 35.997 35.998 64.26 77.918 84.004 124.599 20.444 48.335 30.81 99.672 30.81 152.588s-10.366 104.253-30.81 152.587c-19.744 46.68-48.007 88.601-84.004 124.599-35.998 35.997-77.918 64.26-124.599 84.004-48.334 20.444-99.671 30.81-152.587 30.81z" />
        <path
          d="m448-200.951c210.973 0 382 171.027 382 382s-171.027 382-382 382-382-171.027-382-382 171.027-382 382-382m0-20c-54.261 0-106.909 10.632-156.483 31.599-47.872 20.248-90.862 49.231-127.774 86.144s-65.895 79.902-86.144 127.774c-20.967 49.574-31.599 102.222-31.599 156.483s10.632 106.909 31.599 156.483c20.248 47.872 49.231 90.862 86.144 127.774 36.912 36.913 79.902 65.896 127.774 86.144 49.574 20.968 102.222 31.599 156.483 31.599s106.909-10.632 156.483-31.599c47.872-20.248 90.862-49.231 127.774-86.144 36.913-36.912 65.896-79.902 86.144-127.774 20.967-49.574 31.599-102.222 31.599-156.483s-10.632-106.909-31.599-156.483c-20.248-47.872-49.231-90.862-86.144-127.774-36.912-36.913-79.902-65.896-127.774-86.144-49.574-20.967-102.222-31.599-156.483-31.599z"
          fill="#fff"
        />
      </mask>
      <g ref={outerLineRef}>
        <path
          ref={negativePathRef}
          opacity=".3"
          d="M779.5,399.6c41.4-62.7,65.5-137.8,65.5-218.5c0-4-0.1-8.1-0.2-12.1"
          stroke={Color.BlackLight}
          strokeWidth="10"
        />
        <path
          d="M779.5,399.6c41.4-62.7,65.5-137.8,65.5-218.5c0-4-0.1-8.1-0.2-12.1"
          stroke={Color.White}
          ref={currentPathRef}
          strokeWidth="10"
        />
        <path
          d="M779.5,399.6c41.4-62.7,65.5-137.8,65.5-218.5c0-4-0.1-8.1-0.2-12.1"
          stroke={Color.BlackLight}
          ref={prevPathRef}
          strokeWidth="10"
        />
        <path stroke={Color.BlackLight} d="M792.5,409c43.3-65.3,68.5-143.7,68.5-227.9c0-4-0.1-8.1-0.2-12.1" />
        <path ref={lineRef} d="m448 181.049h402" mask={`url(#${maskID})`} stroke={Color.White} />
      </g>

      <SvgText ref={contentRef}>
        <SvgTextSmall>
          <text opacity=".5" fontSize="14" transform="matrix(1 0 0 1 796.1865 520.2772)">
            {labels?.diff}
          </text>
          <text fontSize="14" fill={Color.BlackLight} transform="matrix(1 0 0 1 796.1758 446.2772)">
            <tspan x="0" y="0">
              {labels?.label1}
            </tspan>
            <tspan x="0" y="16.8">
              {labels?.label2}
            </tspan>
          </text>
        </SvgTextSmall>
        <text fontSize="30" transform="matrix(1 0 0 1 795.7524 499.3563)">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
