import { LanguageLabels, Languages, LanguagesSubdomains } from "#constants";
import { Color, defTransition, font } from "#shared/theme";
import React from "react";
// import { useLocation } from "react-router";
// import { useTranslation } from "#hooks";
// import { PageMeta, PageName } from "#interfaces";
import styled from "styled-components/macro";
import i18n from "../../i18n";

interface LangItemProps {
  label: LanguageLabels;
  key: Languages;
  to: string;
}

const { protocol, host } = location;
const subdomain = host.split(".")[0];

const sudomain_de = LanguagesSubdomains.de;
const sudomain_en = LanguagesSubdomains.en;

const languages: LangItemProps[] = [
  { key: Languages.de, label: LanguageLabels.de, to: `${protocol}//${host.replace(subdomain, sudomain_de)}` },
  { key: Languages.en, label: LanguageLabels.en, to: `${protocol}//${host.replace(subdomain, sudomain_en)}` },
];

const lang = i18n.language;

const Container = styled.div`
  display: flex;
  margin: 0 -4px;
`;

interface BtnProps {
  isActive: boolean;
}

const StyledLink = styled.a<BtnProps>`
  line-height: 1.3;
  color: ${Color.Black};
  pointer-events: ${({ isActive }) => (isActive ? "none" : "auto")};
  font-family: ${font.condensed};
  font-size: 13px;
  padding: 4px;
  transition: color ${defTransition};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border-bottom: ${({ isActive }) => (isActive ? "none" : `1px solid ${Color.Black}`)};
  }
  & + & {
    margin-left: 1px;
  }
`;

export const LangSwitcher: React.FC = () => {
  // const { pathname, hash } = useLocation();
  // const { t: tPages } = useTranslation("pages");
  // const pagesList: { [k: string]: PageMeta } = tPages("list");
  // const foundPage = Object.entries(pagesList).find((item) => {
  //   return item[1].path === `${pathname}${hash}`;
  // });
  // const pageName = foundPage?.[0] || PageName.index;
  const renderLangLinks = ({ to, label, key }: LangItemProps) => {
    const isActive = key === lang;
    let href = location.href;
    if (!isActive) {
      // const pagesList: { [k: string]: PageMeta } = tPages("list", { lng: key });
      href = `${to}/home/start/index.html`;
      // if (pagesList[pageName].path.includes("index.html")) {
      //   href = `${to}${pagesList[pageName].path}`;
      // } else {
      //   href = `${to}${pagesList[pageName].path}index.html`;
      // }
    }
    return (
      <StyledLink href={href} key={key} isActive={isActive}>
        {label}
      </StyledLink>
    );
  };
  return <Container>{languages.map(renderLangLinks)}</Container>;
};
