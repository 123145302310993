import { GeneralPageLayout } from "#components";
import { disableAnalytics, enableAnalytics, getIsAnalyticsEnabled } from "#helpers";
import { useTranslation } from "#hooks";
import { Color, fontCondensed } from "#shared/theme";
import { ScrollTopButton, Spacer, Checkbox, Text } from "#ui";
import React from "react";
import styled from "styled-components/macro";

const Title = styled.h1`
  ${fontCondensed}
  font-size: 30px;
  line-height: 1.13;
  margin-bottom: 56px;
  font-weight: bold;
`;
const Form = styled.div`
  border: 1px solid ${Color.GrayLight};
  border-width: 1px 0;
  padding: 16px 0 20px;
  margin-bottom: 40px;
`;
const Legend = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Cookies: React.FC = () => {
  const { t } = useTranslation("cookies");
  const isGaEnabled = getIsAnalyticsEnabled();
  const handleChange = (checked: boolean) => {
    checked ? enableAnalytics() : disableAnalytics();
  };
  return (
    <GeneralPageLayout>
      <Title>{t("title")}</Title>
      <Form>
        <Legend>{t("legend")}</Legend>
        <Checkbox
          onChange={handleChange}
          defaultChecked={isGaEnabled}
          labelEnabled={t("checkbox_enabled")}
          labelDisabled={t("checkbox_disabled")}
        />
      </Form>
      <Text text={t("copytext")} />
      <Spacer size={50} page={"interview"} />
      <ScrollTopButton />
      <Spacer size={20} page={"interview"} />
    </GeneralPageLayout>
  );
};
