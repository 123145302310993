import { useResize, useOnClickOutside } from "#hooks";
import { useDarkOverlay, useModalContainer, useScrollLock } from "#providers";
import { FixedContainer, ModalCloseButton, Scrollbars, ScrollbarsContext } from "#ui";
import { motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import { useDrag } from "react-use-gesture";
import styled from "styled-components/macro";

const Container = styled(FixedContainer)`
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 999;
`;

const transition = {
  duration: 0.4,
  ease: "easeInOut",
};

const variants = {
  enter: {
    opacity: 1,
    transition,
  },
  exit: {
    opacity: 0,
    transition,
  },
};

const Box = styled(motion.div)<{ isScrollable: boolean }>`
  position: relative;
  display: flex;
  margin: auto;
  height: 100%;
  max-height: 832px;
  padding: 10px;
  user-select: none;
  cursor: ${({ isScrollable }) => (isScrollable ? "grab" : "unset")};
`;

interface ModalBox {
  delta: number;
  isOpen: boolean;
  onExit: () => void;
  close: () => void;
}
const ModalBox: React.FC<ModalBox> = ({ children, delta, isOpen, onExit, close }) => {
  const boxRef = useRef(null);
  const { scrollbarRef } = useContext(ScrollbarsContext);
  const { setOpenCompleted } = useModalContainer();
  const [isScrollable, setScrollable] = useState(false);
  const osInstance = scrollbarRef?.current?.osInstance();
  const size = useResize();
  const setOsScrollable = useCallback(() => {
    const { hasOverflow } = osInstance?.getState() || { hasOverflow: { x: false } };
    setScrollable(hasOverflow.x);
  }, [osInstance]);
  const handleAnimationComplete = (definition: string) => {
    if (definition === "enter") {
      setOsScrollable();
      setOpenCompleted(true);
    }
    if (definition === "exit") {
      onExit();
    }
  };
  useEffect(() => {
    setOsScrollable();
  }, [size, setOsScrollable]);
  useEffect(() => {
    if (delta === 0 || !osInstance) return;
    osInstance.scroll(`${delta > 0 ? "-" : "+"}=${Math.abs(delta)}`);
  }, [osInstance, delta, scrollbarRef]);
  useEffect(() => {
    return () => setOpenCompleted(false);
  }, [setOpenCompleted]);
  useOnClickOutside(boxRef, () => {
    close();
  });
  return (
    <Box
      ref={boxRef}
      isScrollable={isScrollable}
      initial="exit"
      animate={isOpen ? "enter" : "exit"}
      variants={variants}
      onAnimationComplete={handleAnimationComplete}
    >
      {children}
    </Box>
  );
};

export interface ModalProps {
  close: () => void;
  reset: () => void;
  isOpen: boolean;
}
export const Modal: React.FC<ModalProps> = ({ children, close, reset, isOpen }) => {
  const { modalContainerRef } = useModalContainer();
  const [delta, setDelta] = useState(0);
  const { open, close: closeOverlay, reset: resetOverlay } = useDarkOverlay();
  const [isMount, setMount] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleCloseClick = () => {
    close();
    closeOverlay();
  };
  const onExit = () => {
    reset();
    resetOverlay();
  };
  const { disableScrolling, enableScrolling } = useScrollLock();
  useEffect(() => {
    if (isMount) return;
    setMount(true);
    open();
  }, [isMount, open]);
  useEffect(() => {
    disableScrolling();
    return () => {
      enableScrolling();
    };
  }, [disableScrolling, enableScrolling]);
  const bind = useDrag(
    ({ down, delta }) => {
      setDelta(down ? delta[0] : 0);
    },
    { axis: "x", experimental_preventWindowScrollY: true, filterTaps: true }
  );
  return (
    <Portal node={modalContainerRef?.current}>
      <Container ref={containerRef} {...bind()}>
        <Scrollbars centerContent>
          <ModalBox delta={delta} isOpen={isOpen} onExit={onExit} close={close}>
            {children}
          </ModalBox>
        </Scrollbars>
        <ModalCloseButton onClick={handleCloseClick} />
      </Container>
    </Portal>
  );
};
