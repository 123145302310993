import React, { useRef } from "react";
import { Color } from "#shared/theme";
import { useResearchAnimation } from "#hooks";
import { SvgTextSmall } from "#ui";
import { chartMainItemDuration } from "#constants";
import { ChartProps } from "#interfaces";

export const Frameworks: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const minLineRef = useRef<SVGPathElement>(null);
  const maxLineRef = useRef<SVGPathElement>(null);
  const minTextRef = useRef<SVGGElement>(null);
  const maxTextRef = useRef<SVGGElement>(null);
  useResearchAnimation({
    ...props,
    maxValue: Number(labels?.maxValue),
    maxMargin: Number(labels?.frameworkMax),
    minMargin: Number(labels?.frameworkMin),
    minDegree: -38.9,
    maxDegree: 50.1,
    minLineRef,
    maxLineRef,
    minTextRef,
    maxTextRef,
    duration: chartMainItemDuration,
  });
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="273" x="175" y="220.5">
        <path d="m448 474c-67.6 0-131.1-26.3-178.9-74.1-47.8-47.7-74.1-111.3-74.1-178.9s26.3-131.1 74.1-178.9 111.3-74.1 178.9-74.1 131.1 26.4 178.9 74.2c47.8 47.7 74.1 111.3 74.1 178.8s-26.3 131.1-74.1 178.9-111.3 74.1-178.9 74.1z" />
        <path
          d="m448-22c134.2 0 243 108.8 243 243s-108.8 243-243 243-243-108.7-243-243 108.8-243 243-243m0-20c-35.5 0-69.9 7-102.4 20.7-31.3 13.3-59.4 32.2-83.6 56.4s-43.1 52.3-56.4 83.6c-13.7 32.4-20.6 66.9-20.6 102.3s7 69.9 20.7 102.4c13.2 31.3 32.2 59.4 56.4 83.6s52.3 43.1 83.6 56.4c32.3 13.7 66.8 20.6 102.3 20.6s69.9-7 102.4-20.7c31.3-13.2 59.4-32.2 83.6-56.4 24.1-24.1 43.1-52.3 56.4-83.6 13.6-32.3 20.6-66.8 20.6-102.3s-7-69.9-20.7-102.4c-13.2-31.3-32.2-59.4-56.4-83.6s-52.2-43-83.5-56.3c-32.5-13.7-66.9-20.7-102.4-20.7z"
          fill="#fff"
        />
      </mask>
      <path ref={minLineRef} d="m448 221h-273" mask={`url(#${maskID})`} stroke={Color.BlackLight} />
      <path ref={maxLineRef} d="m448 221h-273" mask={`url(#${maskID})`} stroke={Color.BlackLight} />
      <SvgTextSmall ref={minTextRef}>
        <path d="m448 221h-273" opacity="0" />
        <text fill={Color.BlackLight} fontSize="12" transform="matrix(1 0 0 1 218.7843 225.5236)">
          {Number(labels?.frameworkMin).toFixed(1)}
        </text>
      </SvgTextSmall>
      <SvgTextSmall ref={maxTextRef}>
        <path d="m448 221h-273" opacity="0" />
        <text fill={Color.BlackLight} fontSize="12" transform="matrix(1 0 0 1 218.7843 225.5236)">
          {Number(labels?.frameworkMax).toFixed(1)}
        </text>
      </SvgTextSmall>
    </g>
  );
};
