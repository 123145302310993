import { PageName } from "#interfaces";

export const pages = {
  list: {
    [PageName.comingSoon]: {
      path: "/coming-soon",
      title: "Der BMW Group Bericht 2020",
      topTitle: "BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
    },
    [PageName.index]: {
      path: "/",
      title: "Der BMW Group Bericht 2020",
    },
    [PageName.home]: {
      path: "/home/",
    },
    [PageName.start]: {
      path: "/home/start/",
      title: "Home",
      topTitle: "Der BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.startindex]: {
      path: "/home/start/index.html",
      title: "Home",
      topTitle: "Der BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.intro]: {
      path: "/home/intro/",
      title: "Intro",
      topTitle: "BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.intro_ceo]: {
      path: "/home/intro/#ceo",
      title: "Intro CEO",
      topTitle: "BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.intro_cfo]: {
      path: "/home/intro/#cfo",
      title: "Prolog CFO",
      topTitle: "BMW Group Bericht 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.guidedTour2020]: {
      path: "/home/intro/#guided-tour-2020",
      title: "Guided Tour 2020",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020Slides]: {
      path: "/home/guided-tour-2020/",
      title: "Guided Tour 2020",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_automobile_deliveries]: {
      path: "/home/guided-tour-2020/#auslieferungen-automobile",
      title: "Auslieferungen Automobile",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_ebit_margin_automotive_segment]: {
      path: "/home/guided-tour-2020/#ebit-marge-segment-automobile",
      title: "EBIT-Marge Segment Automobile",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_motorcycles_deliveries]: {
      path: "/home/guided-tour-2020/#auslieferungen-motorraeder",
      title: "Auslieferungen Motorräder",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services]: {
      path: "/home/guided-tour-2020/#neuvertraege-finanzdienstleistungen",
      title: "Neuverträge Finanzdienstleistungen",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_group_key_figures]: {
      path: "/home/guided-tour-2020/#konzernkennzahlen",
      title: "Konzernkennzahlen",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_earnings_per_share]: {
      path: "/home/guided-tour-2020/#ergebnis-je-aktie",
      title: "Ergebnis je Aktie",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_research_and_development_expenditure]: {
      path: "/home/guided-tour-2020/#f-e-leistungen",
      title: "F&E Leistungen",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_key_automobile_markets]: {
      path: "/home/guided-tour-2020/#die-wichtigsten-automobilmaerkte",
      title: "Die wichtigsten Automobilmärkte",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_deliveries_of_electrified_vehicles]: {
      path: "/home/guided-tour-2020/#auslieferungen-elektrifizierte-fahrzeuge",
      title: "Auslieferungen elektrifizierte Fahrzeuge",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value]: {
      path: "/home/guided-tour-2020/#CO2-emissionen",
      title: "CO2-Emissionen",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag]: {
      path: "/home/guided-tour-2020/#frauenanteil-in-fuehrungspositionen",
      title: "Frauenanteil in Führungsfunktionen",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.guidedTour2020_tour_end]: {
      path: "/home/guided-tour-2020/#guidedTour2020_tour_end",
      title: "Guided Tour 2020 Ende",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_guidedtour-de.jpg",
    },
    [PageName.interactiveValueCreationProcess]: {
      path: "/wertschoepfung/",
      title: "Wie die BMW Group Werte schafft",
      topTitle: "Wie die BMW Group Werte schafft",
      description: "Entdecken Sie den Wertschöpfungsprozess der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_vcp-de.jpg",
    },
    [PageName.interview]: {
      path: "/interview/",
      title: "Interview",
      topTitle: "",
      description: "",
      image: "https://bericht2020.bmwgroup.com/assets/images/",
    },
    [PageName.interviewDiversity]: {
      path: "/interview/diversity/",
      title: "Interview Diversity",
      topTitle: "Diversity",
      description:
        "Finden Sie heraus, warum die BMW Group überzeugt ist, dass Vielfalt die Leistungsfähigkeit steigert.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_diversity-de.jpg",
    },
    [PageName.interviewFleetEmissions]: {
      path: "/interview/flottenemissionen/",
      title: "Interview Flottenemissionen",
      topTitle: "Flottenemissionen",
      description:
        "Erfahren Sie, wie die BMW Group die Ziele zur Reduktion der CO2-Flottenemissionen sogar übererfüllt hat.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_co2emissions-de.jpg",
    },
    [PageName.interviewCleanProduction]: {
      path: "/interview/clean-production/",
      title: "Interview Clean Production",
      topTitle: "Clean Production",
      description:
        "Finden Sie heraus, was die BMW Group für mehr Nachhaltigkeit in der Produktion und im Lieferantennetzwerk tut.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_cleanproduction-de.jpg",
    },
    [PageName.reportOfTheSupervisoryBoard]: {
      path: "/bericht-des-aufsichtsrats/",
      title: "Bericht des Aufsichtsrats",
      topTitle: "Bericht des Aufsichtsrats",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_bericht-de.jpg",
    },
    [PageName.statementOfTheChairmanOfTheBoardOfManagement]: {
      path: "/vorwort-des-vorstandsvorsitzenden/",
      title: "Vorwort des Vorstandsvorsitzenden",
      topTitle: "Vorwort des Vorstandsvorsitzenden",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_vorwort-de.jpg",
    },
    [PageName.financialStatements]: {
      path: "/konzernabschluss/",
      title: "Konzernabschluss",
      topTitle: "Konzernabschluss",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_konzernabschluss-de.jpg",
    },
    [PageName.downloads]: {
      path: "/downloads/",
      title: "Downloads",
      topTitle: "Downloads",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.glossary]: {
      path: "/glossar/",
      title: "Glossar",
      topTitle: "Glossar",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.legalDisclaimer]: {
      path: "/rechtliche-hinweise/",
      title: "Rechtliche Hinweise",
      topTitle: "Rechtliche Hinweise",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.cookies]: {
      path: "/cookies/",
      title: "Cookies",
      topTitle: "Cookies",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.imprint]: {
      path: "/impressum/",
      title: "Impressum",
      topTitle: "Impressum",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.contact]: {
      path: "/kontakt/",
      title: "Kontakt",
      topTitle: "Kontakt",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.dataProtection]: {
      path: "/datenschutz/",
      title: "Datenschutz",
      topTitle: "Datenschutz",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
    [PageName.note]: {
      path: "/hinweis/",
      title: "Hinweis",
      topTitle: "Hinweis",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://bericht2020.bmwgroup.com/assets/images/og_home-de.jpg",
    },
  },
};
