import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { gsap, Linear } from "gsap";
import { ChartProps } from "#interfaces";
import { Power3 } from "gsap/gsap-core";
import { chartReverseDuration } from "#constants";

interface MarketsAnimationProps extends ChartProps {
  currentValue: number;
  prevValue: number;
  currentPathRef: RefObject<SVGCircleElement>;
  contentRef: RefObject<SVGGElement>;
  duration: number;
}

type useMarketsAnimation = (chartArguments: MarketsAnimationProps) => void;

const getCurrentLength = (pathLength: number, percent: number): number => {
  return (pathLength / 100) * percent;
};

export const useMarketsAnimation: useMarketsAnimation = ({
  playIn,
  playOut,
  onPlayOutEnd,
  currentValue,
  prevValue,
  contentRef,
  currentPathRef,
  duration,
}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pathLength, setPathLength] = useState(0);
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(() => gsap.timeline({ defaults: { ease: Linear.easeInOut, force3D: true }, paused: true }), []);
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setPathLength(Math.round(currentPathRef.current?.getTotalLength() || 0));
      setLoaded(true);
    }, 200);
  }, [currentPathRef]);

  useEffect(() => {
    const prevPathLength = getCurrentLength(pathLength, prevValue) - 1;
    const currentPathLength = getCurrentLength(pathLength, currentValue) - 2;
    if (!isLoaded) return;
    tl.set(currentPathRef.current, {
      strokeDashoffset: getCurrentLength(pathLength, 0),
      transformOrigin: "50%",
      rotate: -120,
    })
      .fromTo(
        currentPathRef.current,
        { strokeDasharray: `.001 ${pathLength}` },
        { duration, strokeDashoffset: -prevPathLength, strokeDasharray: `${currentPathLength} ${pathLength}` },
        0.1
      )
      .to(currentPathRef.current, { duration: duration * 2, rotate: -90 }, 0)
      .to(contentRef.current, { opacity: 1, x: 0, duration: 0.3 }, duration);
    tlReverse
      .to(currentPathRef.current, { rotate: -180, opacity: 0 }, 0)
      .to(contentRef.current, { opacity: 0, x: 10 }, 0);
  }, [isLoaded]); // eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
  }, [playIn, playOut]); // eslint-disable-line
};
