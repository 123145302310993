import React from "react";
import styled from "styled-components/macro";
import { maxWrapperWidth, bp } from "#shared/theme";

const Container = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex: 1 0 0%;
  padding-left: 10px;
  padding-right: 10px;
  max-width: ${maxWrapperWidth}px;
  @media ${bp.m} {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${bp.t} {
    padding-left: 42px;
    padding-right: 42px;
  }
`;

interface WrapperProps {
  className?: string;
  id?: string;
}

export const Wrapper: React.FC<WrapperProps> = ({ children, className, id }) => {
  return (
    <Container className={className} id={id}>
      {children}
    </Container>
  );
};
