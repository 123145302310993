import { bp, Color, ColorName, font, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import htmr from "htmr";
import React, { FC } from "react";
import styled, { css } from "styled-components/macro";
import { Grid } from "../Grid/Grid";
import { Wrapper } from "../Wrapper/Wrapper";
import { Table, TableProps } from "../Table/Table";

export const ChapterContainer = styled.div`
  z-index: 1;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(6)}
    ${gridColumnWidth(14)}
  }
`;

const ChapterHeadline = styled.h2`
  font-size: 18px;
  line-height: 1.45;
  position: relative;
  margin-bottom: 70px;
  ${fontCondensed}
  @media ${bp.p} {
    font-size: 24px;
    line-height: 1.5;
  }
  @media ${bp.d} {
    font-size: 28px;
    line-height: 1.43;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -35px;
    height: 3px;
    width: 140px;
    background-color: ${Color.Black};
  }
`;

export const ChapterText = styled.div<{ isFootnote: boolean }>`
  p {
    font-size: 18px;
    line-height: 1.55;
    @media ${bp.p} {
      font-size: 24px;
      line-height: 1.5;
    }
    @media ${bp.d} {
      font-size: 28px;
      line-height: 1.43;
    }
  }
  p + p {
    margin-top: 34px;
  }
  span {
    font-size: 18px;
  }

  ${({ isFootnote }) =>
    isFootnote &&
    css`
      ul,
      ol {
        list-style: none;
        font-size: 13px;
        line-height: 1.46;
        font-family: ${font.condensed};
        padding-left: 30px;
        li {
          position: relative;
          span {
            font-size: 13px;
            position: absolute;
            left: -30px;
            top: 4px;
            width: 25px;
            word-break: break-all;
            line-height: 0.7;
          }
        }
        li + li {
          margin-top: 20px;
        }
      }

      ul {
        padding-left: 0;
      }
    `}
`;

interface Chapter {
  isTitle?: boolean;
  isFootnote?: boolean;
  headline: string;
  copytext: string;
  table?: TableProps;
  copytext2?: string;
}

export const Chapter: FC<Chapter> = ({ isTitle = true, isFootnote = false, headline, copytext, table, copytext2 }) => {
  return (
    <Wrapper>
      <Grid>
        <ChapterContainer>
          {isTitle && <ChapterHeadline>{headline}</ChapterHeadline>}
          <ChapterText isFootnote={isFootnote}>{htmr(copytext)}</ChapterText>
        </ChapterContainer>
      </Grid>
      {table && <Table inGrid bgColor={ColorName.White} {...table} />}
      <Grid>
        {copytext2 && (
          <ChapterContainer>
            <ChapterText isFootnote={isFootnote}>{htmr(copytext2)}</ChapterText>
          </ChapterContainer>
        )}
      </Grid>
    </Wrapper>
  );
};
