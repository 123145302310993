import { bp, Color } from "#shared/theme";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components/macro";
import { InfoCard, InfoCardProps } from "./InfoCard";
import { CollageLine, CollageContainer, CollageCopytext, CollageHeadline, CollageContent } from "./InfoCardCollage";

const Container = styled(CollageContainer)``;
const Content = styled(CollageContent)``;
const Copytext = styled(CollageCopytext)`
  padding-right: 50px;
`;

const Headline = styled(CollageHeadline)`
  text-align: right;
  padding-right: 120px;
  @media ${bp.m} {
    padding-right: 160px;
  }
`;

const Visual = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 276px;
  height: 113px;
  background-color: #eee;
  @media ${bp.m} {
    width: 356px;
    height: 158px;
  }
`;
const Line = styled(CollageLine)`
  margin-right: -15px;
  margin-left: auto;
  width: 230px;
  @media ${bp.s} {
    margin-right: -50px;
  }
  @media ${bp.m} {
    width: 335px;
  }
`;
const Rect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${rgba("#CEFB5F", 0.5)};
  width: 106px;
  height: 390px;
  @media ${bp.m} {
    width: 140px;
    height: 478px;
  }
`;
const Image = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 205px;
  top: 380px;
  display: flex;
  flex-direction: column;
  background-color: #ddd;
  @media (orientation: landscape) {
    width: 35%;
    height: 70%;
  }
  @media ${bp.m} {
    width: 277px;
    top: 480px;
  }
`;
const Img = styled.img`
  background-color: ${Color.Green};
  margin-top: auto;
  height: 300px;
  width: 100%;
  border: 0;
`;

interface InfoCardGesagtProps extends InfoCardProps {
  headline: string;
  copytext: string;
}

export const InfoCardGesagt: React.FC<InfoCardGesagtProps> = ({ title, category, headline, copytext }) => {
  return (
    <Container>
      <InfoCard title={title} category={category}>
        <Visual />
        <Content>
          <Headline>{headline}</Headline>
          <Line />
          <Copytext dangerouslySetInnerHTML={{ __html: copytext }} />
        </Content>
        <Image>
          <Img src="" />
        </Image>
        <Rect />
      </InfoCard>
    </Container>
  );
};
