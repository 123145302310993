import * as THREE from "three";
import vertexShader from "./shaders/js/mountainsVert";
import fragmentShader from "./shaders/js/mountainsFrag";
// import gui from "./gui";
import config from "./config";

let lightDirection = new THREE.Vector3(0.5, 2.3, -0.4).normalize();

let uOffset0 = new THREE.Uniform(-0.437);
let uOffset1 = new THREE.Uniform(-0.127);
let uOffset2 = new THREE.Uniform(3);
export default class Mountain extends THREE.Mesh {
  constructor(Core) {
    super();
    this.Core = Core;
    this.lightDirection = lightDirection;
    let segments = 200;
    this.geometry = this.createGeometry(1, 1, segments, segments);
    // this.renderOrder = 1;
    this.material = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader,
      //   wireframe: true,
      // depthTest: false,
      // side: THREE.DoubleSide,
      fog: true,
      transparent: true,
      uniforms: {
        ...THREE.UniformsUtils.clone(THREE.UniformsLib["fog"]),
        uLight: new THREE.Uniform(lightDirection),
        uTime: Core.globalUniforms.uTime,
        uWorldZInUV: new THREE.Uniform(0),
        uHeightAct: new THREE.Uniform(1),
        uColorSwap: new THREE.Uniform(1),
        uTravelLength: Core.globalUniforms.uTravelLength,
        uCurveAmplitude: Core.globalUniforms.uCurveAmplitude,
        uCurveFrequency: Core.globalUniforms.uCurveFrequency,
        uStepSize: { value: [1 / segments, 1 / segments] },
        uAspect: { value: config.mountLength / config.mountWidth },
        uOpacity: { value: 1 },
        uOffset0,
        uOffset1,
        uOffset2,
        // uSimpleDistortion: {value: 0}
      },
    });

    this.isDisposed = false;
    // console.log(this.material.uniforms.uAspect);
  }
  dispose() {
    if (this.isDisposed) return;
    this.geometry.dispose();
    this.material.dispose();
    this.geometry = null;
    this.material = null;
    this.Core = null;
    this.isDisposed = true;
  }
  createGeometry(width, height, widthSegments, heightSegments) {
    let geometry = new THREE.BufferGeometry();
    // let position
    const width_half = width / 2;
    const height_half = height / 2;

    const gridX = Math.floor(widthSegments);
    const gridY = Math.floor(heightSegments);

    const gridX1 = gridX + 1;
    const gridY1 = gridY + 1;

    const segment_width = width / gridX;
    const segment_height = height / gridY;

    //

    const indices = [];
    const vertices = [];
    const normals = [];
    const uvs = [];

    for (let iy = 0; iy < gridY1; iy++) {
      const y = iy * segment_height - height_half;

      for (let ix = 0; ix < gridX1; ix++) {
        const x = ix * segment_width - width_half;

        vertices.push(x, -y, 0);

        normals.push(0, 0, 1);

        uvs.push(ix / gridX);
        uvs.push(1 - iy / gridY);
      }
    }

    for (let iy = 0; iy < gridY; iy++) {
      for (let ix = 0; ix < gridX; ix++) {
        const a = ix + gridX1 * iy;
        const b = ix + gridX1 * (iy + 1);
        const c = ix + 1 + gridX1 * (iy + 1);
        const d = ix + 1 + gridX1 * iy;

        indices.push(a, b, d);
        indices.push(b, c, d);
      }
    }

    geometry.setIndex(indices);
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
    geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));
    return geometry;
  }
  debugLight() {
    const origin = new THREE.Vector3(0, 0, 0);
    let length = 100;
    let hex = 0xffff00;

    const arrowHelper = new THREE.ArrowHelper(lightDirection, origin, length, hex);
    this.Core.scene.add(arrowHelper);

    // Core.updates.push(() => {
    // 	arrowHelper.setDirection(lightDirection.normalize());
    // });
  }
}
