import { MenuLinkProps } from "./menu";

export enum TourComponents {
  EbitMargin = "EbitMargin",
  ElectricVehicles = "ElectricVehicles",
  Markets = "Markets",
  Keyfigures = "Keyfigures",
  Contracts = "Contracts",
  Deliveries = "Deliveries",
  Emissions = "Emissions",
  Research = "Research",
  Shares = "Shares",
  Moto = "Moto",
  Women = "Women",
  TourEnd = "TourEnd",
}

export interface SlideshowItemProps extends MenuLinkProps {
  slide: TourComponents;
}
