import getHeight from "./getHeight";
const mountainFrag = `varying vec2 vUv;
varying vec3 vWPos;
varying vec3 vWNormal;
uniform vec2 uStepSize;
uniform vec3 uLight;
uniform float uTime;
uniform sampler2D uHeightMap;
uniform float uWorldZInUV;
uniform float uColorSwap;
uniform float uAspect;
uniform float uOpacity;


uniform float uOffset0;
uniform float uOffset1;
uniform float uOffset2;

#include <common>
#include <fog_pars_fragment>
${getHeight}
mat2 rotate2d(float _angle){
    return mat2(cos(_angle),-sin(_angle),
                sin(_angle),cos(_angle));
}

void main(){
	// Calculate normals
	vec2 uv = vUv;
	// uv.x += sin(uv.y) * 0.2;
	float height = getHeight(uv, uTime);

	vec2 stepSize = vec2(0.01);
    float right = getHeight(uv + stepSize * vec2(1.,0.), uTime);
    float left = getHeight(uv + stepSize * vec2(-1.,0.), uTime);
	// textureOffset(unit_wave, tex_coord, off.zy).x;
	// or this can be up, dunno wihc is negative

	// or this can be up, dunno wihc is negative
    float down = getHeight(uv + stepSize * vec2(0,-1), uTime);
    float up = getHeight(uv + stepSize * vec2(0,1), uTime);
	//
	vec2 size = vec2(1.,0.0);
	float res = mountHeight;
	size = vec2(2. * 2. / (res + 1.), 0.);

	float xx = left-right;
	float yy = up-down;
	vec3 va = (vec3(size.xy,xx ));
    vec3 vb = (vec3(size.yx,yy ));
	vec3 normal = normalize(cross(va, vb));
	// normal.yz = 1.- normal.yz;
	normal.xy = - normal.xy;


	vec3 lightPos = vec3(0., 10., 10.);
	vec3 lightDir = normalize(lightPos - vWPos);
	float diffuse = max(0., dot(normalize(vWNormal), lightDir));
	// diffuse = step(p0.2, diffuse);
	vec3 color = vec3(diffuse) ;
	vec3 light = vec3(0.9, 0.7,0.7);
	// 
	light = vec3(76.,	111.,	138.		)/255.;
	float nHeight = height / mountHeight;
	
	// 76	111	138	
	vec3 dark = vec3(0.7, 0.3,0.3);
	dark = vec3(18.,	39.,	55.	)/255.;
	
	//
	// light = vec3(30.,	154.,	192.	)/255.;
	dark = vec3(31.,	61.,	85.	)/255.;
	dark = vec3(16., 32., 44.)/255.;
	dark *= 1. + (nHeight) * (nHeight) * 1.5;
	// light *= 1. - (1.-nHeight) * (1.-nHeight)* (1.-nHeight) * 0.2;
	
	// dark = vec3(16.,32.,44.)/255.;
	// 18	39	55	
	float diff = dot(normal, normalize(uLight));
	// diff = step(0.5,diff) * 0.8 + 0.2;
	// color = vWNormal;
	color = vWNormal;
	color = normal;
	color = color* 0.5 + 0.5;
	color = vec3(diff);
	// color = mix(dark, light, diffuse);

	uv += -0.5;
	uv = rotate2d(height * 0.2) * uv;
	uv += 0.5;
	
	// color = normal;
	// color = vec3(diff);
	// - [] rotate uv based on height
	color = mix(dark, light, smoothstep(-0.005, 0.005 , diff) );
	// color = mix(dark * 0.2, color, step(-0.2 + sin(height * 3.) *0.05, diff) );
	// color = vec3(vUv.x);
	// color = vec3(1.);
	// color = vec3(1.);
	vec2 uv2 = vUv;
	uv2.y *= uAspect;
	float gridSize = 24.;
	float grid = fract(uv2.x * gridSize) + fract(uv2.y * gridSize);
	vec2 grid2 = fract(vWPos.xz * gridSize * vec2(0.01));
	// grid2.y += uTime;
	vec2 borderSize = vec2(0.005,0.005);
	// borderSize = vec2(0.01,0.01);
// 888 892 7180
	float borders = 
		step(1. -  borderSize.x, grid2.x) 
		+ step( grid2.x, borderSize.x)
		 + step(1. -  borderSize.y, grid2.y) 
		 + step( grid2.y, borderSize.y);

		 vec3 borderDark = vec3(35.,85.,112.) /255.;
		vec3 borderLight = vec3(64.,122.,162.)/255.;
		 vec3 borderColor = mix(borderLight, borderDark, borders);
	color = mix(color, vec3(borderColor), 1.-uColorSwap);
	gl_FragColor = vec4(color,uOpacity);
	// Custom fog fragment to work with alpha instead
	#ifdef USE_FOG
		#ifdef FOG_EXP2
			float fogFactor = 1.0 - exp( - fogDensity * fogDensity * fogDepth * fogDepth );
		#else
			float fogFactor = smoothstep( fogNear, fogFar, fogDepth );
		#endif
		// gl_FragColor.a = mix( gl_FragColor.a, 0., fogFactor );
	#endif
	if(gl_FragColor.a < 0.02) {
		discard;
	}
}`;

export default mountainFrag;
