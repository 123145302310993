import { useOnClickOutside, usePopover, useTranslation } from "#hooks";
import { useDarkOverlay } from "#providers";
import {
  bp,
  clearBtn,
  Color,
  defTransition,
  expander,
  fadeIn,
  fadeOut,
  font,
  IconKeys,
  slideRight,
} from "#shared/theme";
import { Burger, FixedContainer, Icon } from "#ui";
import React, { TransitionEvent, useRef } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { LangSwitcher } from "../LangSwitcher/LangSwitcher";
import { Menu } from "../Menu/Menu";

interface ContainerProps {
  isClose: boolean;
}

const Container = styled(FixedContainer)<ContainerProps>`
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${({ isClose }): number => (isClose ? 10 : 105)};
  pointer-events: ${({ isClose }): string => (isClose ? "none" : "auto")};
  @media ${bp.m} {
    bottom: auto;
    left: auto;
    width: 100%;
    max-width: 618px;
  }
`;
interface BoxProps {
  isOpen: boolean;
}
const Box = styled.div<BoxProps>`
  ${expander}
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${Color.White};
  color: ${Color.Black};
  font-family: ${font.condensed};
  font-size: 14px;
  letter-spacing: 0.018em;
  display: flex;
  flex-direction: column;
  transition: clip-path ${defTransition};
  clip-path: polygon(calc(100% - 44px) 0, 100% 0, 100% 70px, calc(100% - 44px) 70px);
  @media ${bp.m} {
    bottom: auto;
    flex-direction: row;
    height: auto;
    clip-path: polygon(calc(100% - 61px) 0, 100% 0, 100% 90px, calc(100% - 61px) 90px);
  }
  @media ${bp.t} {
    clip-path: polygon(calc(100% - 90px) 0, 100% 0, 100% 90px, calc(100% - 90px) 90px);
  }
  ${({ isOpen }): FlattenSimpleInterpolation | false =>
    isOpen &&
    css`
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      @media ${bp.m} {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    `};
`;

const SlideRight = styled.div<{ animate: boolean }>`
  ${({ animate }) => slideRight(animate)}
`;

const Controls = styled.div`
  pointer-events: auto;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  height: 70px;
  width: 44px;
  @media ${bp.m} {
    height: 90px;
    width: 136px;
  }
  @media ${bp.t} {
    width: 160px;
  }
`;
interface MenuBtnProps {
  isVisible: boolean;
}

const MenuBtn = styled.button<MenuBtnProps>`
  ${clearBtn}
  background-color: ${Color.Green};
  flex: 0 0 44px;
  position: relative;
  @media ${bp.m} {
    flex: 0 0 60px;
  }
  @media ${bp.t} {
    flex: 0 0 90px;
  }
  ${({ isVisible }): FlattenSimpleInterpolation => (isVisible ? fadeIn : fadeOut)}
`;

const Logos = styled(Icon)`
  width: 192px;
  height: 32px;
  display: block;
`;
const LogosContainer = styled.div`
  height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding: 0 36px;
  @media ${bp.s} {
    padding: 0 40px;
  }
  @media ${bp.m} {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px;
    padding: 0 30px;
  }
`;

const LangSwitcherContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  @media ${bp.m} {
    margin-bottom: 0;
    position: relative;
    margin-right: 18px;
  }
`;
const LangSwitcherContainerMobile = styled(LangSwitcherContainer)`
  display: flex;
  @media ${bp.m} {
    display: none;
  }
`;
const LangSwitcherContainerDesktop = styled(LangSwitcherContainer)`
  display: none;
  @media ${bp.m} {
    display: flex;
  }
`;

interface MenuSectionProps {
  coloredBg?: boolean;
}

const MenuSection = styled.div<MenuSectionProps>`
  flex-grow: ${({ coloredBg }): string => (coloredBg ? "1" : "auto")};
  background-color: ${({ coloredBg }): string => (coloredBg ? Color.Green : Color.White)};
  padding: 30px 36px 40px;
  @media ${bp.s} {
    padding: 20px 40px 40px;
  }
  @media ${bp.m} {
    flex: 1 0 0%;
    padding: 136px 30px 80px;
  }
`;

export interface NavProps {
  withNav?: boolean;
}

export const Nav: React.FC<NavProps> = ({ withNav = true }) => {
  const { t } = useTranslation("nav");
  const { list: topItemsList } = t("topItems");
  const { list: bottomItemsList } = t("bottomItems");
  const containerRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const { open, close, reset } = useDarkOverlay();
  const { isOpen, isClose, isClosing, open: openMenu, close: closeMenu, reset: resetMenu } = usePopover();
  const toggleClick = () => {
    if (isOpen) {
      closeMenu();
      close();
    } else {
      openMenu();
      open();
    }
  };
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== boxRef.current || !isClosing) return;
    resetMenu();
    reset();
  };
  useOnClickOutside(containerRef, () => {
    if (!isOpen) return;
    closeMenu();
    close();
  });
  return (
    <Container ref={containerRef} isClose={isClose}>
      <Controls>
        <LangSwitcherContainerDesktop>
          <LangSwitcher />
        </LangSwitcherContainerDesktop>
        <MenuBtn onClick={toggleClick} isVisible={withNav}>
          <Burger isActive={isOpen} />
        </MenuBtn>
      </Controls>
      {withNav && (
        <Box isOpen={isOpen} ref={boxRef} onTransitionEnd={handleTransitionEnd}>
          <LogosContainer>
            <SlideRight animate={isOpen}>
              <Logos iconKey={IconKeys.SvgBMWGroupLogos} />
            </SlideRight>
          </LogosContainer>
          <MenuSection>
            <LangSwitcherContainerMobile>
              <SlideRight animate={isOpen}>
                <LangSwitcher />
              </SlideRight>
            </LangSwitcherContainerMobile>
            {topItemsList?.length > 0 && (
              <SlideRight animate={isOpen}>
                <Menu list={topItemsList} withLastRowUnderline onClick={toggleClick} />
              </SlideRight>
            )}
          </MenuSection>
          {bottomItemsList?.length > 0 && (
            <MenuSection coloredBg>
              <SlideRight animate={isOpen}>
                <Menu list={bottomItemsList} onClick={toggleClick} />
              </SlideRight>
            </MenuSection>
          )}
        </Box>
      )}
    </Container>
  );
};
