import { Color, expander, font } from "#shared/theme";
import React from "react";
import styled from "styled-components/macro";
import { SvgRotatePhone } from "#shared/theme";
import { ReactSVG } from "react-svg";
import { useTranslation } from "#hooks";

const Container = styled.div`
  ${expander}
  position: fixed;
  z-index: 10000;
  display: none;
  background-color: ${Color.BlueLoading};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-height: 479px) and (orientation: landscape) {
    display: flex;
  }
`;

const Image = styled(ReactSVG)`
  width: 125px;
  height: 125px;
  fill: ${Color.White};
`;
const Headline = styled.div`
  margin-top: 0.5em;
  font-family: ${font.condensed};
  font-size: 28px;
  line-height: 32px;
  color: ${Color.White};
`;

export const MobileLandscapeBlocker: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <Container>
      <Image src={SvgRotatePhone} />
      <Headline>{t("rotatePhone")}</Headline>
    </Container>
  );
};
