import * as THREE from "three";
import fragmentShader from "./shaders/js/lightsFrag.js";
import vertexShader from "./shaders/js/lightsVert.js";
import { InstancedBufferAttribute } from "three";

import config from "./config";

// This is an omega obscure api decision
// But isntancedBufferGeometries without
// https://github.com/mrdoob/three.js/issues/19430

export class Lights extends THREE.Object3D {
  constructor(count = 10, Core) {
    super();

    this.Core = Core;

    let geometry = new THREE.CylinderBufferGeometry(
      config.lightRadius,
      config.lightRadius,
      config.lightLength,
      config.lightLengthDetail,
      // It goes so fast there isn't a ned for this.
      config.lineTubeDetail
    );
    this.baseGeometry = geometry;
    let instanced = new THREE.InstancedBufferGeometry().copy(geometry);
    let instanceCount = count;
    instanced.instanceCount = instanceCount;
    let material = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader,
      fog: true,
      transparent: true,
      depthTest: true,
      // side: THREE.DoubleSide,
      uniforms: {
        ...THREE.UniformsUtils.clone(THREE.UniformsLib["fog"]),
        uOpacity: new THREE.Uniform(1),
        uTime: Core.globalUniforms.uTime,
        uLoopZ: new THREE.Uniform(config.mountLength * 2),
        uTravelLength: Core.globalUniforms.uTravelLength,
        uCurveAmplitude: Core.globalUniforms.uCurveAmplitude,
        uCurveFrequency: Core.globalUniforms.uCurveFrequency,
        uDissipateProgress: new THREE.Uniform(0),
        uPosZ: new THREE.Uniform(0),
        // uTime: new THREE.Uniform(0),
      },
    });
    // console.log(Core.globalUniforms.uTime);
    this.material = material;
    let rows = 7;

    let aLight = new Float32Array(instanceCount * 3);
    let aC0 = new Float32Array(instanceCount * 3);
    let aC1 = new Float32Array(instanceCount * 3);
    let aEnd = new Float32Array(instanceCount * 3);

    let space = config.roadWidth;
    let rowWidth = space / rows;
    for (let i = 0; i < instanceCount; i++) {
      let row = Math.floor(Math.random() * rows);
      let x = -space / 2 + space * Math.random();
      x = -space / 2 + rowWidth * row;
      let z = Math.random() * 100;
      // z = 0;
      let speed = Math.random() * 0.5 + 0.5;
      // x = 0;
      // z = 10;
      aLight[i * 4] = x;
      aLight[i * 4 + 1] = z;
      aLight[i * 4 + 2] = speed;
      aLight[i * 4 + 3] = 1 + Math.random() * 0.8 - 0.2;

      aEnd[i * 3] = Math.random() * 200 - 100;
      aEnd[i * 3 + 1] = Math.random() * 50;
      aEnd[i * 3 + 2] = Math.random() * 10;

      aC0[i * 3] = Math.random() * 10 - 5;
      aC0[i * 3 + 1] = Math.random() * 10 - 5;
      aC0[i * 3 + 2] = Math.random() * 5;

      aC1[i * 3] = Math.random() * 10 - 5;
      aC1[i * 3 + 1] = Math.random() * 5 + 10;
      aC1[i * 3 + 2] = Math.random() * -5;
      // aLight.push(x, z, speed);
      // let mesh = new THREE.Mesh(geometry, material);
      // // mesh.position.x +=
      // this.add(mesh);
    }
    // let aLightFloat = new Float32Array(aLight);

    instanced.setAttribute("aLight", new InstancedBufferAttribute(aLight, 4, false));

    instanced.setAttribute("aEnd", new InstancedBufferAttribute(aEnd, 3, false));
    instanced.setAttribute("aC0", new InstancedBufferAttribute(aC0, 3, false));
    instanced.setAttribute("aC1", new InstancedBufferAttribute(aC1, 3, false));
    let mesh = new THREE.Mesh(instanced, material);
    mesh.frustumCulled = false;
    this.lightMesh = mesh;
    this.add(mesh);
    mesh.renderOrder = 1;
    // mesh.renderOrder = 0;
    // console.log(this);
    // this.add(instanced);

    // mesh.customDepthMaterial;
    this.rotation.x = Math.PI / 2;
    this.position.y += 0.5;
    this.position.z += config.lightLength;
    // this.posiit
  }
  dispose() {
    if (this.isDisposed) return;

    this.baseGeometry.dispose();
    this.lightMesh.geometry.dispose();
    this.lightMesh.material.dispose();

    this.lightMesh = null;
    this.baseGeometry = null;
    this.material = null;

    this.isDisposed = true;
  }
}

export default Lights;
