import { useSlideshow } from "#providers";
import { bp, clearBtn, Color, defTransition, expander } from "#shared/theme";
import { NavButton } from "#ui";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media ${bp.m}, (orientation: landscape) {
    padding-bottom: 53.198127925117%;
    height: 0;
    position: relative;
  }
`;
const List = styled.div`
  @media ${bp.m} {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 40px;
    align-items: flex-end;
  }
`;
const ListInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Item = styled.button<{ isActive: boolean }>`
  ${clearBtn}
  height: 40px;
  flex: 0 0 40px;
  min-width: 40px;
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transform: scale(${({ isActive }) => (isActive ? 1 : 0.5)});
    background-color: ${Color.Green};
    transition: transform ${defTransition};
  }
`;
const NavArrow = styled.div`
  @media ${bp.m} {
    ${expander}
    z-index: 1;
    height: 50px;
    margin: auto;
  }
`;
const NavPrev = styled(NavArrow)`
  transform: rotate(180deg);
  @media ${bp.m} {
    right: auto;
    left: -40px;
  }
`;
const NavNext = styled(NavArrow)`
  @media ${bp.m} {
    left: auto;
    right: -40px;
  }
`;

export interface GalleryNavProps {
  length: number;
}
export const GalleryNav: React.FC<GalleryNavProps> = ({ length }) => {
  const { index, set, prev, next } = useSlideshow();
  const renderItem = (i: number, key: number) => {
    const onClick = () => {
      set(key);
    };
    return (
      <Item key={key} isActive={key === index} onClick={onClick}>
        {i}
      </Item>
    );
  };
  return (
    <Container>
      <NavPrev>
        <NavButton onClick={prev} />
      </NavPrev>
      <List>
        <ListInner>{Array(length).fill("").map(renderItem)}</ListInner>
      </List>
      <NavNext>
        <NavButton onClick={next} />
      </NavNext>
    </Container>
  );
};
