import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Core from "./webgl/index.js";
import { useResize } from "#hooks";

// import styled from "styled-components/macro";

// const WrapperWithGradient = styled.div`
//  background-image: radial-gradient(circle at 60% 80%,rgb(33, 51, 71),rgb(33, 51, 71));,
// `;

export const Webgl: React.FC<{ startPathname: string }> = ({ startPathname }) => {
  const coreRef = useRef<Core | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [ready, setReady] = useState(false);
  const { width } = useResize();
  const handleInit = () => {
    setReady(true);
  };
  useEffect(() => {
    const webglCore = new Core(wrapperRef.current, canvasRef.current);
    coreRef.current = webglCore;
    webglCore.loadAssets().then(() => {
      webglCore.init(handleInit);
    });
    // if (wrapperRef.current != null) {
    //   let rect = wrapperRef.current.getBoundingClientRect();
    // }

    return () => {
      webglCore.dispose();
    };
  }, []);

  useEffect(() => {
    if (pathname !== startPathname && coreRef.current != null) {
      coreRef.current.disposeScrollTriggerAnims();
    }
    if (coreRef.current === null || pathname !== startPathname || !ready) return;

    coreRef.current.startAnimation();
  }, [pathname, ready, startPathname]);

  useEffect(() => {
    if (coreRef.current == null) return;

    // coreRef.current.pathname = pathname;
    const shouldBeActive = pathname == "/home/start/" || pathname == "/home/intro/";
    if (!shouldBeActive) {
      coreRef.current.disposeScrollTriggerAnims();
      //   coreRef.current.fadeOut();
    }
    // coreRef.current.core.enabled = pathname == "/home/start/" || pathname == "/home/intro/";

    if (pathname == "/home/start/") {
      coreRef.current.fadeIn(true);
    } else if (pathname != "/home/intro/") {
      coreRef.current.fadeOut();
    }
    // coreRef.current.core.enabled = pathname == "/home/start/" || pathname == "/home/intro/";
  }, [pathname]);
  //   useEffect(() => {
  //     if (coreRef.current == null || !isComplete) return;
  //     if (coreRef.current.isInitiated) coreRef.current.fadeIn();
  //     else coreRef.current.shouldPlayIntro = true;
  //   }, [isComplete]);

  //   useEffect(() => {
  //   }, [wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      className="gradient-wrapper"
      style={{
        pointerEvents: "none",
        position: "fixed",
        left: 0,
        height: "100%",
        width: width,
      }}
    >
      <canvas
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        ref={canvasRef}
      />
    </div>
  );
};
