import { useSlideshow } from "#providers";
import { clearBtn, Color, defTransition, expander } from "#shared/theme";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div``;
const Item = styled.button<{ isActive: boolean }>`
  ${clearBtn}
  height: 40px;
  flex: 0 0 40px;
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transform: scale(${({ isActive }) => (isActive ? 1 : 0.5)});
    background-color: ${Color.Green};
    transition: transform ${defTransition};
  }
`;

const NavDots = styled.div`
  ${expander}
  top: 100%;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface StartSlideshowNavProps {
  length: number;
}
export const StartSlideshowNav: React.FC<StartSlideshowNavProps> = ({ length }) => {
  const { index, set } = useSlideshow();
  const renderItem = (i: number, key: number) => {
    const onClick = () => {
      set(key);
    };
    return (
      <Item key={key} isActive={key === index} onClick={onClick}>
        {i}
      </Item>
    );
  };
  return (
    <Container>
      <NavDots>{Array(length).fill("").map(renderItem)}</NavDots>
    </Container>
  );
};
