import { usePopover } from "#hooks";
import React from "react";
import styled from "styled-components/macro";
import { Info, InfoItemProps } from "../Info/Info";
import { Modal } from "../Modal/Modal";

const Container = styled.span``;

export interface ButtonModalProps {
  list: InfoItemProps[];
  children: (args: { onClick: () => void }) => React.ReactNode;
}

export const ButtonModal: React.FC<ButtonModalProps> = ({ list, children }) => {
  const { isClose, isOpen, close, open, reset } = usePopover();
  return (
    <Container>
      {children({ onClick: open })}
      {!isClose && (
        <Modal close={close} reset={reset} isOpen={isOpen}>
          <Info list={list} />
        </Modal>
      )}
    </Container>
  );
};
