export const chartSideItemTimeDelay = 1;
export const chartSideItemDuration = 1.2;
export const chartSideItemValuesDuration = 1;
export const chartMainItemDuration = 1;

export const chartGlobalMarketsDuration = 1.1;

export const chartMobileItemDuration = 0.5;
export const chartMobileGlobalMarketsDuration = 1;

export const chartReverseDuration = 1.4;
