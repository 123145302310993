import { bp } from "#shared/theme";
import React from "react";
import i18next from "src/i18n";
import { ComparisonChart, comparisonItems } from "#ui";
import styled from "styled-components/macro";
import { InfoCard, InfoCardProps } from "./InfoCard";
import { TourComponents } from "#interfaces";

const Container = styled.div`
  position: relative;
  min-width: 260px;
  display: flex;
  width: calc(100vw - 50px);
  max-width: 390px;
  margin: 0 20px 20px 10px;
  @media ${bp.m} {
    margin: 20px 35px;
    width: 45vw;
  }
  @media ${bp.l} {
    width: 30vw;
  }
`;

interface InfoCardDiagramProps extends InfoCardProps {
  copytext: string;
  component: TourComponents;
}

export const InfoCardDiagram: React.FC<InfoCardDiagramProps> = ({ title, category, component }) => {
  return (
    <InfoCard title={title} category={category}>
      <Container>
        <ComparisonChart svg={comparisonItems[i18next.language][component]} />
      </Container>
    </InfoCard>
  );
};
