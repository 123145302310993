import { useNumberFormat } from "#hooks";
import { useSlideshow } from "#providers";
import { VCPContent, VCPLeftContent, VCPLink, RightContent, VCPTurbo, VCPValue, VCPValueLabel, VCPValues } from "#ui";
import React, { useRef } from "react";
import { SvgLines } from "./SvgLines";
import { VCPItemProps } from "#interfaces";

export const Item6: React.FC<VCPItemProps> = ({ slideIndex, labels }) => {
  const { index, set } = useSlideshow();
  const turboSvgRef = useRef<SVGSVGElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const valuesInputRef = useRef<HTMLDivElement>(null);
  const valuesOutputRef = useRef<HTMLDivElement>(null);
  const inputDefaultRef = useRef<SVGLineElement>(null);
  const inputFillRef1 = useRef<SVGLineElement>(null);
  const inputFillRef2 = useRef<SVGLineElement>(null);
  const outputDefaultRef = useRef<SVGLineElement>(null);
  const outputFillRef1 = useRef<SVGLineElement>(null);
  const outputFillRef2 = useRef<SVGLineElement>(null);
  const isActive = index === slideIndex;
  const handleClick = () => {
    set(slideIndex === index ? -1 : slideIndex);
  };

  return (
    <SvgLines
      {...{
        turboSvgRef,
        contentRef,
        inputDefaultRef,
        inputFillRef1,
        inputFillRef2,
        outputDefaultRef,
        outputFillRef1,
        outputFillRef2,
        valuesInputRef,
        valuesOutputRef,
        isActive,
      }}
    >
      {({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
      }) => (
        <>
          <VCPContent ref={contentRef}>
            <VCPLeftContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title1}
              </VCPLink>
              <VCPValues ref={valuesInputRef}>
                <VCPValue ref={valueInputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue1))}
                </VCPValue>
                <VCPValue ref={valueInputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue2))}
                </VCPValue>
              </VCPValues>
            </VCPLeftContent>
            <RightContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title2}
              </VCPLink>
              <VCPValues ref={valuesOutputRef}>
                <VCPValue ref={valueOutputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue1))}
                </VCPValue>
                <VCPValue ref={valueOutputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue2))}
                </VCPValue>
              </VCPValues>
            </RightContent>
          </VCPContent>
          <VCPTurbo ref={turboSvgRef} viewBox="0 0 40 272" xmlns="http://www.w3.org/2000/svg" opacity="0">
            <g>
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_30_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="141"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="141" width="15" height="10" id="_x30_6-input-black_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_30_)">
                  <polygon
                    id="_x30_1-input-black_18_"
                    fill="#FFFFFF"
                    points="0,141 15,141 15,151 0,151 5.083,146    "
                  />
                </g>
              </mask>
              <line ref={turboInputRef} fill="none" stroke="#000000" strokeWidth="0" x1="0" y1="146" x2="15" y2="146" />
              <line
                ref={inputDefaultRef}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_6-input-black_2_)"
                fill="none"
                stroke="#000000"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="146"
                x2="15"
                y2="146"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_31_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="141"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="141" width="15" height="10" id="_x30_6-input-green_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_31_)">
                  <polygon
                    id="_x30_1-input-black_17_"
                    fill="#FFFFFF"
                    points="0,141 15,141 15,151 0,151 5.083,146    "
                  />
                </g>
              </mask>

              <line
                ref={inputFillRef1}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_6-input-green_2_)"
                fill="none"
                stroke="#346C64"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="146"
                x2="15"
                y2="146"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_32_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="145"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="145" width="15" height="2" id="_x30_6-input-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_32_)">
                  <polygon
                    id="_x30_1-input-black_16_"
                    fill="#FFFFFF"
                    points="0,141 15,141 15,151 0,151 5.083,146    "
                  />
                </g>
              </mask>

              <line
                ref={inputFillRef2}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_6-input-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="0"
                y1="146"
                x2="15"
                y2="146"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_33_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="121"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="121" width="15" height="10" id="_x30_6-output-white_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_33_)">
                  <polygon
                    id="_x30_1-output-white_18_"
                    fill="#FFFFFF"
                    points="25,121 35,121 40,126 35,131 25,131    "
                  />
                </g>
              </mask>
              <line
                ref={turboOutputRef}
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="0"
                x1="25"
                y1="126"
                x2="40"
                y2="126"
              />
              <line
                ref={outputDefaultRef}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_6-output-white_2_)"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="126"
                x2="40"
                y2="126"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_34_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="121"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="121" width="15" height="10" id="_x30_6-output-yellow_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_34_)">
                  <polygon
                    id="_x30_1-output-white_17_"
                    fill="#FFFFFF"
                    points="25,121 35,121 40,126 35,131 25,131    "
                  />
                </g>
              </mask>

              <line
                ref={outputFillRef1}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_6-output-yellow_2_)"
                fill="none"
                stroke="#CEFB5F"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="126"
                x2="40"
                y2="126"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_35_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="125"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="125" width="15" height="2" id="_x30_6-output-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_35_)">
                  <polygon
                    id="_x30_1-output-white_16_"
                    fill="#FFFFFF"
                    points="25,121 35,121 40,126 35,131 25,131    "
                  />
                </g>
              </mask>

              <line
                ref={outputFillRef2}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_6-output-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="25"
                y1="126"
                x2="40"
                y2="126"
              />
            </g>
          </VCPTurbo>
        </>
      )}
    </SvgLines>
  );
};
