export const diversity = {
  title: {
    headline:
      "Der Frauenanteil in Führungsfunktionen nimmt zu. Wir haben nachgefragt, wie dieses Ziel erreicht wurde und wie es weitergeht. Im Interview: Sabine Distler und Gabriele von Stetten. Sie verantworten gemeinsam die Diversity-Strategie.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        page: "Diversity",
        src: "/data/interview/diversity/image-1.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Welchen Stellenwert hat Diversity für die BMW Group?",
        copytext:
          "<p><strong>Gabriele von Stetten </strong>Wir sind davon überzeugt, dass Vielfalt unsere Attraktivität als Arbeitgeber, unsere Wettbewerbsfähigkeit und unsere Leistungsfähigkeit steigert. Vielfältige Teams sind innovativer – das belegen auch Studien. Eigentlich klar: jeder bringt schließlich einen unterschiedlichen Blickwinkel mit.</p><p><strong>Sabine Distler</strong> Dazu kommt noch etwas. Wir haben vielfältige Kunden auf der ganzen Welt. Und wenn wir diese Kundinnen und Kunden verstehen, überzeugen und begeistern wollen, dann brauchen wir diese Vielfalt auch bei unseren Mitarbeitern.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "BlueBold",
        copytext:
          "<p>Seit <strong>2011</strong> haben wir die Zahl der Frauen in Führungsfunktionen in der BMW AG mehr als <strong>VERDOPPELT.</strong> Und bis <strong>2025</strong> soll der Anteil noch höher werden.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Die BMW Group misst Diversität unter anderem durch den Frauenanteil in Führungsfunktionen. Wie stehen Sie zum Thema „Frauenquote“?",
        copytext:
          "<p><strong>Gabriele von Stetten</strong> Wie gesagt: Wir sind davon überzeugt, dass wir Diversität brauchen, weil sie uns innovativer und damit wettbewerbsfähiger macht. Das gilt auch für Gender-Diversität. Deswegen setzen wir uns anspruchsvolle Ziele, die wir natürlich verfolgen und erreichen wollen. Eine gesetzlich definierte Frauenquote wie wir sie in Deutschland haben, kann das flankieren, aber es ist unser ureigenes Interesse, dass in der gesamten Belegschaft und auf Führungsebene Frauen und Männer arbeiten. Wir setzen uns auch ohne Vorgabe mit diesem Thema auseinander. Aber messbare Ziele helfen.</p>",
      },
    },
    {
      type: "Image",
      props: {
        align: "Center",
        src: "/data/interview/diversity/image-2.jpg",
        caption: {
          isCaptionFullWidth: true,
          desc:
            "<strong>Gabriele</strong> und <strong>Sabine</strong> arbeiten seit mehreren Jahren im Tandem. Ihre verschiedenen Hintergründe und Fähigkeiten ergänzen sich und machen sie als Team erfolgreicher und kreativer.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Wie geht man mit weniger gut messbaren Diversitätsfaktoren um?",
        copytext:
          "<p><strong>Sabine Distler</strong> In unserer Diversitätsstrategie haben wir bisher die Aspekte Geschlecht, kultureller Hintergrund, sowie Alter und Erfahrung betrachtet. Jetzt haben wir sie um sexuelle Orientierung und Identität, sowie um Behinderung ergänzt. Während für einige Dimensionen quantitative Ziele sinnvoll sind, braucht es für alle Dimensionen qualitative Ziele. Da geht es nicht um Anteile oder Quoten, sondern darum, eine offene und wertschätzende Unternehmenskultur zu entwickeln, sowie ein inklusives Arbeitsumfeld für alle Mitarbeiterinnen und Mitarbeiter zu schaffen. Wie können wir beispielsweise internationale oder generationsübergreifende Zusammenarbeit verbessern? Wie stellen wir barrierefreie Arbeitsplätze sicher? Wie stärken wir das Bewusstsein für alle Dimensionen von Vielfalt?</p><p><strong>Gabriele von Stetten</strong> Das macht es natürlich schwieriger, in diesen Dimensionen Erfolge zu messen. Wir haben aber auch dafür ein Barometer, nämlich unsere internationale Mitarbeiterbefragung, in der die Themen Chancengleichheit und Diversität explizit abgefragt werden.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/diversity/image-3.jpg",
        quote:
          "Vielfältige Teams sind innovativer – das belegen auch Studien. Eigentlich klar: jeder bringt schließlich einen unterschiedlichen Blickwinkel mit.",
        author: "Gabriele von Stetten",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Zurück zum Thema Frauen. Die Ziele 2020 hat die BMW Group sogar übertroffen und sich jetzt neue ambitionierte Ziele gesetzt. Wo sehen Sie die Herausforderungen?",
        copytext:
          "<p><strong>Sabine Distler</strong> Wir haben in den vergangenen Jahren viel erreicht und unter anderem die Zahl der weiblichen Führungskräfte mehr als verdoppelt. Und wir haben uns ambitionierte Ziele für den Zeitraum bis 2025 gesetzt. Um diese zu erreichen, ist es zum einen von entscheidender Bedeutung, unsere weiblichen Talente frühzeitig zu identifizieren und gezielt zu entwickeln. Zum anderen ist es wichtig, qualifizierte Frauen ins Unternehmen zu bringen. Wir haben mehrere Nachwuchsprogramme und schon dort achten wir auf einen entsprechenden Frauenanteil.</p><p><strong>Gabriele von Stetten</strong> Um unsere Ziele 2025 zu erreichen, haben wir die strategische Neuausrichtung 2020 unter anderem dafür genutzt, gerade das Thema Gender Diversity umfassend zu analysieren. In diesem Zusammenhang haben wir beispielsweise Frauen im Unternehmen befragt, welche Gründe sie von Führungsaufgaben abhalten. Aus dieser Analyse haben wir mehrere Handlungsfelder abgeleitet und konkrete Aktivitäten auf den Weg gebracht.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/diversity/image-4.jpg",
        quote:
          "Wir haben vielfältige Kunden auf der ganzen Welt. Wenn wir sie verstehen, überzeugen und begeistern wollen, dann brauchen wir diese Vielfalt auch bei unseren Mitarbeitern.",
        author: "Sabine Distler",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Haben Sie ein Beispiel?",
        copytext:
          "<p><strong>Sabine Distler</strong> Es gibt Lebensphasen oder -situationen, in denen sich Menschen aus verschiedenen Gründen bewusst dafür entscheiden, nicht in Vollzeit zu arbeiten. Familie, Weiterbildung, Pflege sind nur einige bekannte Beispiele. Reduzierte Arbeitszeit muss aber nicht heißen, auf eine Führungsaufgabe zu verzichten. Aus diesem Grund haben wir im letzten Jahr das Programm Joint Leadership eingeführt. Das bedeutet, dass sich zwei Mitarbeiter als Tandem eine disziplinarische Führungsaufgabe teilen.</p>",
      },
    },
    {
      type: "Video",
      props: {
        align: "Center",
        poster: "/data/interview/diversity/diversity_05_videoposter.jpg",
        src: "/data/interview/diversity/diversity.mp4",
        caption: {
          desc:
            "Die BMW Group setzt sich innerhalb und außerhalb des Unternehmens für Toleranz, Offenheit und Vielfalt ein.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Sie selbst arbeiten auch im Tandem. Was sind die Vorteile?",
        copytext:
          "<p><strong>Sabine Distler</strong> Bei uns kommen unterschiedliche Ausbildungen und Hintergründe, sowie ein unterschiedlicher Werdegang zusammen. Damit decken wir ein breites Spektrum an Know-how ab und verfügen über ein umfangreiches Netzwerk.</p><p><strong>Gabriele von Stetten</strong> Ein Tandem zeigt die Vorteile von Diversität in der kleinsten Einheit. Zusammen entstehen kreativere Ideen, da wir Themen aus unterschiedlichen Blickwinkeln betrachten. Das bringt uns nicht nur zu besseren Lösungen, sondern bereichert auch unseren Arbeitsalltag.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Sabine, Gabriele, vielen Dank für das Gespräch.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        parallaxColor: "BlueBold",
        items: [
          {
            src: "/data/interview/diversity/image-5.jpg",
          },
          {
            src: "/data/interview/diversity/image-6.jpg",
          },
        ],
        caption: {
          headline: "Sabine Distler und Gabriele von Stetten",
          desc: "Im Personalwesen entwickeln sie die Diversity Strategie der BMW Group.",
        },
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 1,
            link: "/interview/flottenemissionen/",
          },
          {
            storyId: 2,
            link: "/interview/clean-production/",
          },
        ],
      },
    },
  ],
};
