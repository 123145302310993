import { AnalyticsEventTypes } from "#interfaces";

export const footer = {
  topLinks: [
    {
      label: "PDF Report",
      href: "/data/pdf/en/BMW-Group-Bericht-2020-EN.pdf",
      download: true,
      analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
    },
    {
      label: "Downloads",
      href: "/downloads/",
    },
    {
      label: "Glossary",
      href: "/glossary/",
    },
    {
      label: "IR Reminder",
      href: "https://www.bmwgroup.com/en/services/newslettersubscribe.html",
      external: true,
    },
  ],
  bottomLinks: [
    {
      label: "Legal disclaimer",
      href: "/legal-disclaimer/",
    },
    {
      label: "Cookies",
      href: "/cookies/",
    },
    {
      label: "Imprint",
      href: "/imprint/",
    },
    {
      label: "Contact",
      href: "https://www.bmwgroup.com/en/general/contact.html",
      external: true,
    },
    {
      label: "Data Protection",
      href: "/data-protection/",
    },
  ],
};
