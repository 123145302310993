import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { ChartProps } from "#interfaces";
import { America } from "./America";
import { Asia } from "./Asia";
import { Europe } from "./Europe";
import { Other } from "./Other";

const CHART_COUNT = 4;

export const GlobalMarketsChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const maxValue = Math.max.apply(null, Object.values((labels?.charts as unknown) as Record<string, number>));
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <Other maxValue={maxValue} currentValue={Number(labels?.charts.other)} {...chartProps} labels={labels} />
      <Asia maxValue={maxValue} currentValue={Number(labels?.charts.asia)} {...chartProps} labels={labels} />
      <Europe maxValue={maxValue} currentValue={Number(labels?.charts.europe)} {...chartProps} labels={labels} />
      <America maxValue={maxValue} currentValue={Number(labels?.charts.america)} {...chartProps} labels={labels} />
      <line fill="none" stroke="#000000" x1="127" y1="328.5" x2="193" y2="328.5" />
    </SvgContainer>
  );
};
