import { HEADER_HEIGHT, TextColorThemeTypes, ThemeKeys } from "#constants";
import { useTranslation } from "#hooks";
import { PageName, PageMeta } from "#interfaces";
import { bp, bpTypes, Color, defTransition, expander, fontCondensed, IconKeys } from "#shared/theme";
import { FixedContainer, Icon, Link, Wrapper } from "#ui";
import { rgba } from "polished";
import React from "react";
import { useLocation } from "react-router";
import styled, { css, ThemeProvider } from "styled-components/macro";
import theme from "styled-theming";
import { Nav } from "../Nav/Nav";

const Logo = styled(Icon)`
  width: 100%;
  height: 100%;
  display: block;
  transition: fill ${defTransition} 0.3s;
  ${theme(ThemeKeys.textColor, {
    [TextColorThemeTypes.Default]: css`
      fill: ${Color.Black};
    `,
    [TextColorThemeTypes.Light]: css`
      fill: ${Color.White};
    `,
  })}
`;

const BrandLink = styled(Link)`
  pointer-events: auto;
  width: 54px;
  height: 24px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media ${bp.s} {
    width: 63px;
    height: 28px;
  }
  @media ${bp.m} {
    width: 72px;
    height: 32px;
  }
`;

interface HeaderContainerProps {
  transparent: boolean;
}

const HeaderContainer = styled(FixedContainer)<HeaderContainerProps>`
  pointer-events: none;
  z-index: 9;
  top: 0;
  left: 0;
  transition: background-color ${defTransition} 0.3s, color ${defTransition} 0.3s;
  background-color: ${({ transparent }): string => (transparent ? rgba(Color.White, 0) : rgba(Color.White, 1))};
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT[bpTypes.i]}px;
  @media ${bp.m} {
    height: ${HEADER_HEIGHT[bpTypes.m]}px;
  }
  ${theme(ThemeKeys.textColor, {
    [TextColorThemeTypes.Default]: css`
      color: ${Color.Black};
    `,
    [TextColorThemeTypes.Light]: css`
      color: ${Color.White};
    `,
  })}
`;

const HeaderTitle = styled.div`
  z-index: -1;
  ${expander}
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontCondensed}
  font-weight: bold;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.067em;
  @media ${bp.m} {
    font-size: 13px;
  }
`;

export interface HeaderProps {
  transparent?: boolean;
  withNav?: boolean;
  theme?: TextColorThemeTypes;
}

export const Header: React.FC<HeaderProps> = ({
  transparent = false,
  withNav = false,
  theme = TextColorThemeTypes.Default,
}) => {
  const { pathname, hash } = useLocation();
  const { t: tPages } = useTranslation("pages");
  const pagesList: { [k: string]: PageMeta } = tPages("list");
  const { path: startPagePath } = pagesList[PageName.start];
  const foundPage = Object.values(pagesList).find((item) => {
    return item.path === `${pathname}${hash}`;
  });
  const { topTitle } = foundPage || pagesList[PageName.index];
  return (
    <ThemeProvider
      theme={{
        [ThemeKeys.textColor]: theme,
      }}
    >
      <HeaderContainer transparent={transparent}>
        <Wrapper>
          <BrandLink to={startPagePath}>
            <Logo iconKey={IconKeys.SvgLogo} />
          </BrandLink>
          <HeaderTitle>{topTitle}</HeaderTitle>
        </Wrapper>
      </HeaderContainer>
      <Nav withNav={withNav} />
    </ThemeProvider>
  );
};
