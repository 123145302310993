import { PageName } from "#interfaces";
import { pages } from "./pages";

export const nav = {
  topItems: {
    list: [
      {
        list: [
          {
            pageName: PageName.intro_ceo,
            to: pages.list[PageName.intro_ceo].path,
            title: "We are making BMW sustainable",
            text: "Oliver Zipse on targets achieved and the strategic positioning of the BMW Group",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.intro_cfo,
            to: pages.list[PageName.intro_cfo].path,
            title: "Integrated Report 2020",
            text: "Nicolas Peter on the new way of reporting and the financial year 2020",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.guidedTour2020,
            to: pages.list[PageName.guidedTour2020].path,
            title: "Guided Tour 2020",
            text: "Selected key figures",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.interactiveValueCreationProcess,
            to: pages.list[PageName.interactiveValueCreationProcess].path,
            title: "How the BMW Group creates value",
            text: "The value creation process of the BMW Group",
          },
        ],
      },
    ],
  },
  bottomItems: {
    list: [
      {
        title: "Report",
        list: [
          { to: pages.list[PageName.reportOfTheSupervisoryBoard].path, text: "Report of the Supervisory Board" },
          {
            to: pages.list[PageName.statementOfTheChairmanOfTheBoardOfManagement].path,
            text: "Statement of the Chairman of the Board of Management",
          },
          { to: pages.list[PageName.financialStatements].path, text: "Financial Statements" },
        ],
      },
      {
        title: "Achievements",
        list: [
          {
            to: pages.list[PageName.interviewFleetEmissions].path,
            text: "Target exceeded – Interview on CO2 fleet emissions",
          },
          {
            to: pages.list[PageName.interviewCleanProduction].path,
            text: "Ambitious – Interview on sustainability in production and the supplier network",
          },
          {
            to: pages.list[PageName.interviewDiversity].path,
            text: "Diversity is a strength – Interview on this topic",
          },
        ],
      },
    ],
  },
};
