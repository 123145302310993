import React, { ReactComponentElement } from "react";
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";
import { Link } from "../Link/Link";
import { Languages } from "#constants";
import { storyTeaserListDe } from "./SVG_DE/StoryTeaserListDe";
import { storyTeaserListEn } from "./SVG_EN/StoryTeaserListEn";
import i18next from "src/i18n";

const Teaser = styled.div``;
const StyledLink = styled(Link)`
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  overflow: hidden;
  & + & {
    margin-top: 70px;
  }
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    & + & {
      margin-top: 100px;
    }
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.p} {
    ${gridColumnStart(6)}
    ${gridColumnWidth(14)}
  }
  @media ${bp.t} {
    ${gridColumnStart(1)}
    ${gridColumnWidth(11)}
    & + & {
      margin-top: 0;
      ${gridColumnStart(14)}
      ${gridColumnWidth(11)}
    }
  }

  svg {
    display: block;
    font-weight: bold;
  }
`;

interface TeaserProps {
  storyId: number;
  link: string;
}

export interface StoryTeaser {
  items: TeaserProps[];
}

export interface storyTeaserListProps {
  mobile: ReactComponentElement<"svg">;
  desktop: ReactComponentElement<"svg">;
}

const storyTeaserList: { [key: string]: storyTeaserListProps[] } = {
  [Languages.de]: storyTeaserListDe,
  [Languages.en]: storyTeaserListEn,
};

export const StoryTeaser: React.FC<StoryTeaser> = ({ items }) => {
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <Wrapper>
      <Grid>
        {items.map((teaser: TeaserProps, key: number) => (
          <StyledLink key={key} to={teaser.link}>
            <Teaser>
              {isDesktop
                ? storyTeaserList[i18next.language][teaser.storyId].desktop
                : storyTeaserList[i18next.language][teaser.storyId].mobile}
            </Teaser>
          </StyledLink>
        ))}
      </Grid>
    </Wrapper>
  );
};
