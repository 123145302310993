import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card22M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect x="256" fill="#1EBEE4" width="99" height="377" />
        <rect y="85" width="290.68" height="7" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="windpower_2_">
          <g>
            <path
              fill="none"
              d="M294.54,268.43C294.54,268.43,294.54,268.43,294.54,268.43C294.54,268.42,294.54,268.42,294.54,268.43z"
            />
            <path
              fill="#659896"
              d="M353.59,276.04c-0.1,0-0.21-0.01-0.31-0.02C353.73,276.12,353.86,276.1,353.59,276.04z"
            />
            <path
              fill="#659896"
              d="M323.92,262.63c0,0.01,0,0.01,0,0.02c0,0-0.35,0.17-0.94,0.17c-0.59,0-0.66-0.17-0.66-0.17
				c-9.46,0.31-18.4,8.68-18.4,8.68s-4.28-2.76-9.37-2.89c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c-0.04,0.03-0.33,0.42-1.75,0.42c-0.76,0-1.38-0.19-1.48-0.23c-7.24,1.08-11.95,6.14-16.49,7.56c0,0,4.22-1.33,11.74-1.86
				c7.29-0.52,14.26,0.53,12.96-0.08c-1.3-0.61-3.99-2.18,2.02-1.3c6.07,0.89,9.9,3.46,14.65,3.96c17.83,1.91,16.49-3.56,12.85-6.23
				c-7.58-5.56,2.67-4.37,10.57,0c5.45,3.02,10.7,5.08,13.67,5.35c-1.32-0.27-5.4-1.44-10.6-5.24
				C336.1,265.99,331.77,262.77,323.92,262.63z"
            />
            <path
              fill="#DFD7C6"
              d="M294.54,268.43C294.54,268.42,294.54,268.42,294.54,268.43C294.54,268.43,294.54,268.42,294.54,268.43
				C294.54,268.43,294.54,268.43,294.54,268.43c-0.11-2.01-0.81-27.13-0.98-33.26c1.64,1.88,5.12,5.87,7.61,8.72l1.69-1.48
				c-1.66-2.66-3.68-5.89-4.28-6.92c-0.97-1.67-1.17-1.11-2.19-1.29c-0.82-0.14-1.52-0.44-2.06-0.88c-0.12,0.77-0.79,1.37-1.6,1.37
				c-0.35,0-0.67-0.11-0.93-0.3l-0.51,34.23c0,0,0.01,0,0.02,0.01c0.11,0.04,0.72,0.23,1.48,0.23
				C294.21,268.85,294.5,268.45,294.54,268.43z"
            />
            <path
              fill="#DFD7C6"
              d="M293.99,232.05c0.63-1.84,2.61-7.68,3.98-11.72l-2.13-0.72c-1.47,2.78-3.25,6.14-3.84,7.18
				c-0.95,1.68-0.38,1.57-0.01,2.54c0.28,0.76,0.38,1.5,0.28,2.18c0.14-0.04,0.3-0.07,0.45-0.07
				C293.24,231.44,293.69,231.67,293.99,232.05z"
            />
            <path
              fill="#C16032"
              d="M299.3,216.4c0.44-1.28-0.76-1.86-1.45-0.58c-0.31,0.57-1.1,2.06-2.01,3.78l2.13,0.72
				C298.62,218.39,299.14,216.87,299.3,216.4z"
            />
            <path
              fill="#C16032"
              d="M303.89,247.01c0.89,1.02,1.99,0.28,1.23-0.96c-0.34-0.56-1.23-1.99-2.26-3.63l-1.69,1.48
				C302.51,245.42,303.56,246.63,303.89,247.01z"
            />
            <path
              fill="#DFD7C6"
              d="M291.1,233.06c0-0.22,0.05-0.44,0.13-0.63c-2.02,0.41-7.92,1.61-12.02,2.45l0.45,2.2
				c3.14-0.13,6.94-0.29,8.14-0.3c1.93-0.02,1.54-0.46,2.2-1.27c0.47-0.58,1.02-1.02,1.61-1.28
				C291.29,233.93,291.1,233.52,291.1,233.06z"
            />
            <path
              fill="#C16032"
              d="M275.15,235.71c-1.33,0.27-1.23,1.59,0.23,1.54c0.65-0.02,2.34-0.09,4.28-0.17l-0.45-2.2
				C277.21,235.29,275.64,235.61,275.15,235.71z"
            />
            <path
              fill="#C16032"
              d="M294.32,233.31c0.01-0.08,0.02-0.17,0.02-0.26c0-0.38-0.13-0.73-0.35-1.01c-0.3-0.37-0.75-0.61-1.27-0.61
				c-0.16,0-0.31,0.02-0.45,0.07c-0.47,0.14-0.85,0.48-1.04,0.93c-0.08,0.19-0.13,0.41-0.13,0.63c0,0.46,0.19,0.87,0.5,1.17
				c0.06,0.06,0.12,0.11,0.19,0.16c0.26,0.19,0.58,0.3,0.93,0.3C293.53,234.68,294.2,234.09,294.32,233.31z"
            />
            <path
              fill="#DFD7C6"
              d="M322.98,262.81c0.59,0,0.94-0.17,0.94-0.17c0-0.01,0-0.01,0-0.02c-0.05-1.06-0.46-17.28-0.54-20.35
				c-0.32,0.2-0.75,0.19-1.06-0.06c-0.11-0.09-0.19-0.2-0.25-0.32c-0.15,0.36-0.44,0.67-0.83,0.91c-0.43,0.26-0.61,0.05-0.74,0.97
				c-0.08,0.57-0.4,2.37-0.66,3.86l1.02,0.35c0.48-1.39,1.12-3.23,1.56-4.48l-0.08,19.14C322.32,262.64,322.39,262.81,322.98,262.81
				z"
            />
            <path
              fill="#C16032"
              d="M319.47,249.66c-0.11,0.69,0.51,0.82,0.72,0.21c0.08-0.23,0.33-0.96,0.65-1.88l-1.02-0.35
				C319.66,248.55,319.52,249.35,319.47,249.66z"
            />
            <path
              fill="#DFD7C6"
              d="M323.66,242c0.23,0.04,0.46,0.12,0.68,0.25c0.44,0.24,0.35,0.5,1.21,0.16c0.53-0.21,2.25-0.84,3.67-1.36
				l-0.21-1.06c-1.73,0.34-4.13,0.8-5.35,1.04C323.85,241.32,323.86,241.7,323.66,242z"
            />
            <path
              fill="#C16032"
              d="M331.16,240.33c0.66-0.25,0.46-0.85-0.18-0.73c-0.24,0.05-0.99,0.19-1.96,0.38l0.21,1.06
				C330.1,240.72,330.87,240.44,331.16,240.33z"
            />
            <path
              fill="#DFD7C6"
              d="M322.17,240.92c0.08-0.1,0.18-0.18,0.28-0.23c-0.08-0.26-0.11-0.54-0.09-0.85
				c0.04-0.5,0.32-0.52-0.35-1.17c-0.41-0.4-1.69-1.71-2.74-2.8l-0.88,0.62c1.23,1.75,3.11,4.45,3.59,5.14
				C321.95,241.39,322.01,241.13,322.17,240.92z"
            />
            <path
              fill="#C16032"
              d="M317.84,234.39c-0.49-0.5-0.97-0.07-0.59,0.46c0.14,0.2,0.58,0.83,1.15,1.63l0.88-0.62
				C318.62,235.2,318.06,234.62,317.84,234.39z"
            />
            <path
              fill="#C16032"
              d="M322.31,242.22c0.31,0.25,0.74,0.26,1.06,0.06c0.09-0.05,0.17-0.12,0.23-0.2
				c0.02-0.03,0.03-0.05,0.05-0.08c0.2-0.3,0.2-0.68,0.01-0.98c-0.05-0.09-0.12-0.17-0.2-0.23c-0.3-0.24-0.7-0.26-1.01-0.1
				c-0.11,0.06-0.2,0.13-0.28,0.23c-0.17,0.21-0.23,0.47-0.19,0.71c0.01,0.09,0.04,0.18,0.08,0.26
				C322.12,242.01,322.2,242.13,322.31,242.22z"
            />
          </g>
          <path
            opacity="0.3"
            d="M320.53,270.43c-2.21-4.22,2.91-4.72,5.95-5.34c2.31-0.47,2.91-1.39,1.16-1.87
			c-1.25-0.34-2.5-0.52-3.73-0.57c-0.01,0.01-0.03,0.01-0.06,0.02c0.05-0.02,0.08-0.03,0.08-0.03l0-0.02l-0.54-20.35
			c-0.18,0.11-0.39,0.16-0.6,0.14c-0.01,0.04-0.03,0.09-0.05,0.14c0.12,0.28,0.22,0.66,0.24,1.19c0.05,1.41,0.44,18.73,0.45,19.04
			c-0.13,0.02-0.28,0.03-0.45,0.03c-0.47,0-0.61-0.11-0.65-0.15c-3.13,0.15-6.13,1.07-8.77,2.27c-5.67,2.57-9.65,6.39-9.65,6.39
			c5.79,4.74,12.27,5.59,12.27,5.59C320.89,276.83,321.8,272.87,320.53,270.43z"
          />
          <path
            opacity="0.3"
            d="M292.38,271.16c0.94-2.23,4.42-2.36,5.18-2.36c0.09,0,0.15,0,0.15,0c-0.58-0.12-1.14-0.2-1.68-0.26
			c-0.06-0.01-0.11-0.01-0.17-0.02c-0.03,0-0.06-0.01-0.09-0.01c-0.42-0.04-0.84-0.07-1.24-0.08c0,0,0,0,0,0v0l0,0l-0.52-16.65
			l-0.54-17.28c-0.23,0.12-0.48,0.19-0.76,0.19c-0.23,0-0.44-0.05-0.64-0.13c0.34,0.31,0.87,1.02,0.93,2.49
			c0.08,2.15,0.57,31.57,0.57,31.74c0.07,0,0.13-0.01,0.19-0.02c-0.25,0.05-0.57,0.09-0.99,0.09c-0.72,0-1.31-0.17-1.46-0.22
			c-0.9,0.14-1.77,0.34-2.6,0.59c-1.64,0.48-3.17,1.14-4.65,1.92c-1.02,0.53-2.02,1.12-3.03,1.75c-3.76,2.34-5.52,3.06-6.05,3.24
			c-0.05,0.02-0.09,0.03-0.11,0.04c-0.04,0.01-0.06,0.02-0.06,0.02l5.05-1.09l0.94-0.2C286.88,273.91,291.23,273.9,292.38,271.16z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="ix3">
          <g>
            <path
              fill="#1EBEE4"
              d="M276.95,319.85c-6.92-7.1-14.37-15.49-34.53-16.98c-1.07-0.08-2.12-0.15-3.15-0.21l11.42,27.25
				c0.09,0.01,0.17,0.02,0.25,0.02c3.73,0.34,29.72,3.33,32.71,3.53c2.99,0.2,3.49-1.98,3.49-1.98S283.87,326.96,276.95,319.85z"
            />
            <path
              fill="#191C1C"
              d="M128.27,395.25c0,0-9.98,0.55-17.11,1.01c-7.51,0.48-30.19,2.21-14.31,5.98
				c2.33,0.55,16.98,4.03,33.32,6.71c21.08,3.46,31.39,1.82,34.88,1.41c19.67-2.29,37-4.27,54.6-6.6
				c24.08-3.18,86.48-10,93.63-11.22c7.15-1.21-4.47-3.43-23.94-5.08c-14.24-1.21-38.53-3.43-47.48-1.93
				C224.39,388.48,128.27,395.25,128.27,395.25z"
            />
            <path
              fill="#191C1C"
              d="M298.07,382.43c1-0.42,25.09-7.65,25.09-7.65s4.52-26.54-8.03-25.69
				C302.59,349.94,298.07,382.43,298.07,382.43z"
            />
            <path
              fill="#191C1C"
              d="M194.7,389.23c0,0,34.79-0.34,40.48-1.19c5.69-0.85,6.52-36.4-14.72-36.74S194.7,389.23,194.7,389.23z"
            />
            <g>
              <g>
                <path
                  fill="#191C1C"
                  d="M221.27,359.07c4.83,0,13.57,5.44,12.62,23.4c-0.88,16.62-10.39,21.39-14.6,21.39
						c-3.56,0-20.66-0.35-27.13-13.89c0,0,5.38-0.38,6.52-5.28C199.93,379.29,204.73,359.07,221.27,359.07z"
                />
                <path
                  fill="#5E5D5B"
                  d="M206.56,383.53c-0.33-4.09-1.78-8.46-2.62-11.4c0,0,5.61-14.25,17.66-12.26
						c13.89,2.3,12.49,24.58,11.68,28.03c-1.03,4.37-3.74,15.01-13.64,15.87C209.74,404.63,206.94,388.19,206.56,383.53z"
                />

                <ellipse
                  transform="matrix(0.0288 -0.9996 0.9996 0.0288 -167.054 590.5656)"
                  fill="#FFFFFF"
                  cx="220.39"
                  cy="381.25"
                  rx="18.44"
                  ry="11.45"
                />
                <polygon
                  fill="#191C1C"
                  points="223.26,364.09 224.35,371.03 229.87,372.16 231.06,379.62 226.82,384.02 228.28,392.45 
						225.86,393.23 224.21,397.09 220.69,392.3 215.79,397.29 214.46,393.18 211.96,392.58 214.14,384.02 209.65,379.75 
						211.58,376.15 211.37,371.75 216.61,371.36 218.44,363.96 220.59,364.94"
                />
                <linearGradient
                  id="Card22MSVGID1"
                  gradientUnits="userSpaceOnUse"
                  x1="220.088"
                  y1="397.9479"
                  x2="220.5881"
                  y2="363.1276"
                >
                  <stop offset="0.6685" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.6968" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
                  <stop offset="0.7171" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
                  <stop offset="0.735" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
                  <stop offset="0.7515" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
                  <stop offset="0.7669" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
                  <stop offset="0.7817" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
                  <stop offset="0.7959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
                  <stop offset="0.8093" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
                  <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                </linearGradient>
                <path
                  fill="none"
                  stroke="url(#Card22MSVGID1)"
                  strokeMiterlimit="10"
                  d="M218.44,363.96l-2.11,6.05l-4.96,1.75
						c0,0-1.52,4.91-1.72,8l3.17,5.38l-0.86,7.46c0,0,1.39,3.16,3.83,4.7l4.43-3.02l3.96,3.02c0,0,2.84-2.49,4.1-4.84l-0.26-7.26
						l3.04-5.58c0,0,0-4.1-1.19-7.46l-4.43-1.48l-2.18-6.59C223.26,364.09,221.67,363.15,218.44,363.96z"
                />
                <polygon fill="#5E5D5B" points="223.26,364.09 222.4,373.7 223.85,375.25 229.87,372.16 225.44,370.68" />
                <polygon
                  fill="#5E5D5B"
                  points="218.44,363.96 217.71,373.37 216.32,375.11 211.37,371.75 216.32,370.01"
                />
                <path
                  fill="#5E5D5B"
                  d="M211.37,371.75l1.19,2.96l-1.12,4.3l-1.78,0.74C209.65,379.75,209.98,375.32,211.37,371.75z"
                />
                <path
                  fill="#5E5D5B"
                  d="M229.87,372.16l-1.45,2.62l0.66,4.03l1.98,0.81C231.06,379.62,231.06,375.65,229.87,372.16z"
                />
                <polygon
                  fill="#5E5D5B"
                  points="231.06,379.62 225.11,382.44 224.45,385.13 228.28,392.45 228.02,385.19"
                />
                <path
                  fill="#5E5D5B"
                  d="M228.28,392.45l-1.98-1.28l-2.05,2.42l-0.07,3.7C224.19,397.29,227.42,394.6,228.28,392.45z"
                />
                <polygon
                  fill="#5E5D5B"
                  points="224.19,397.29 220.42,389.43 218.97,389.09 215.79,397.29 220.22,394.26"
                />
                <path
                  fill="#5E5D5B"
                  d="M215.79,397.29l0.53-3.43c0,0-2.44-2.69-2.51-2.49c-0.07,0.2-1.85,1.21-1.85,1.21
						S213.75,396.08,215.79,397.29z"
                />
                <polygon
                  fill="#5E5D5B"
                  points="211.96,392.58 215.13,384.72 214.74,382.17 209.65,379.75 212.82,385.13"
                />
                <g>
                  <line fill="#191C1C" x1="228.02" y1="385.19" x2="230.73" y2="386.6" />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="225.44"
                    y1="370.68"
                    x2="227.22"
                    y2="367.05"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="220.22"
                    y1="394.26"
                    x2="220.22"
                    y2="398.9"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="212.82"
                    y1="385.13"
                    x2="210.05"
                    y2="386.47"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="216.32"
                    y1="370.01"
                    x2="214.27"
                    y2="366.98"
                  />
                </g>
                <ellipse fill="#FFFFFF" cx="218.46" cy="381.06" rx="1.08" ry="1.85" />
                <ellipse fill="#1EBEE4" cx="218.46" cy="381.06" rx="0.7" ry="1.21" />
              </g>
              <path
                fill="#191C1C"
                d="M118.67,379.74c0,0-1.04,18.52,22.05,18.52c16.54,0,16.86-17.25,16.86-17.25L118.67,379.74z"
              />
              <path
                fill="#5E5D5B"
                d="M118.76,381.86l9.97,2.66c0,0,0.63,10.22,8.7,13.6C137.44,398.12,120.73,397.93,118.76,381.86z"
              />
              <linearGradient
                id="Card22MSVGID2"
                gradientUnits="userSpaceOnUse"
                x1="118.7621"
                y1="389.9923"
                x2="137.4351"
                y2="389.9923"
              >
                <stop offset="0.281" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4606" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
                <stop offset="0.6335" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
                <stop offset="0.7516" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID2)"
                d="M118.76,381.86l9.97,2.66c0,0,0.63,10.22,8.7,13.6C137.44,398.12,120.73,397.93,118.76,381.86z"
              />
            </g>
            <path
              fill="#5E5D5B"
              d="M197.04,389.4c0,0-9.38,1.52-28.84,0.8c-16.15-0.6-65.27-6.3-67.17-14.87l77.15,4.1L197.04,389.4z"
            />
            <linearGradient
              id="Card22MSVGID3"
              gradientUnits="userSpaceOnUse"
              x1="155.5103"
              y1="318.145"
              x2="146.3529"
              y2="408.9658"
            >
              <stop offset="0.6051" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.661" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
              <stop offset="0.7148" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
              <stop offset="0.7516" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID3)"
              d="M197.04,389.4c0,0-9.38,1.52-28.84,0.8c-16.15-0.6-65.27-6.3-67.17-14.87l77.15,4.1L197.04,389.4z
				"
            />
            <polygon fill="#1EBEE4" points="215.11,302.46 244.47,303.79 253.45,330.54 218.82,327.42 215.2,325.19 			" />
            <polygon
              opacity="0.63"
              fill="#A1C8D9"
              points="215.11,302.46 244.47,303.79 253.45,330.54 218.82,327.42 215.2,325.19 			"
            />
            <polygon fill="#A59B91" points="280.72,332.83 278.01,329.38 257.73,329.08 258.75,330.96 280.87,333.52 			" />
            <path
              fill="#5E5D5B"
              d="M280.38,332.96c0,0-8.67-11.91-14.86-17.17s-20.04-10.42-51.9-8.7l-0.2-4.78c0,0,17.88-0.6,31.27,0.65
				c13.4,1.26,23.19,6.07,34.23,18.66s10.25,11.91,10.25,11.91S282.97,335.48,280.38,332.96z"
            />
            <path
              fill="#FFFFFF"
              d="M112.74,321.11c-0.61,0.66-2.28,1.98,0.8,2.17c3.08,0.19,20.38,0.1,20.38,0.1s15.95,0,22.39-0.32
				c6.44-0.32,17.44-0.32,19.57-3.2c2.12-2.88,4.64-7.51,4.64-7.51s-0.1-0.19-0.32-0.49c-0.97-1.34-4.26-4.94-11.62-4.94
				c-9.04,0-33.94,0-36.54,0c-2.53,0-6.67,0.46-11.64,5.75c-0.13,0.14-0.26,0.28-0.39,0.42
				C114.87,318.79,113.35,320.46,112.74,321.11z"
            />
            <linearGradient
              id="Card22MSVGID4"
              gradientUnits="userSpaceOnUse"
              x1="319.9608"
              y1="342.8281"
              x2="325.8885"
              y2="348.7558"
            >
              <stop offset="0.2138" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.3653" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
              <stop offset="0.4743" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
              <stop offset="0.5701" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
              <stop offset="0.6582" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
              <stop offset="0.741" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
              <stop offset="0.82" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
              <stop offset="0.8959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
              <stop offset="0.9675" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID4)"
              d="M324.61,350.04c0,0,0.69-5.98-3.38-8.16c0,0-1.79,0.32-0.43,2.36
				C322.16,346.28,324.61,350.04,324.61,350.04z"
            />
            <path
              fill="#FFFFFF"
              d="M325.57,353.36c-0.52-3.01-1.13-3.81-1.13-3.81s-1.39-2.66-2.79-4.52s-1.66-2.84-0.35-3.19
				c0,0-4.27-5.94-28.6-11.26c0,0-0.76,0.86-2.88,1.24c0,0-0.16,0.14-0.43,0.31c-0.31,0.19-0.76,0.43-1.31,0.57
				c0,0-1.74,1.51-4.36,1.42s-34.61-3.72-34.61-3.72s-16.22-1.33-20.75-1.77c-4.53-0.44-13.51-3.01-17.87-4.34
				c-4.36-1.33-12.73-4.43-14.73-5.05c-2.01-0.62-2.79-1.86-2.27-4.88c0.52-3.01,2.35-8.16,3.05-9.84s6.19-2.84,9.94-3.1
				c3.75-0.27,23.45-1.42,36.36,0.18c12.9,1.6,20.96,4.46,29.06,12.42s12.91,13.48,12.91,13.48l0.18-0.5l0.44-1.03
				c0,0-16.29-18.47-25.55-21.91c-9.26-3.44-24.57-6.25-37.48-6.88c-12.91-0.63-32.93,0.97-32.93,0.97l-8.52,2.52l-13.17,27.74
				l26.19,22.7l13.33,2.05l13.95-5.36l12.71,5.36l4.49,15.92l60.44,0.63l8.99-20.33l8.68-2.52l4.96,3.15l2.94,6.93l0.46,10.72
				l0.55-1.52C325.49,366.13,326.1,356.38,325.57,353.36z"
            />
            <linearGradient
              id="Card22MSVGID5"
              gradientUnits="userSpaceOnUse"
              x1="136.3576"
              y1="298.6992"
              x2="166.612"
              y2="298.6992"
            >
              <stop offset="0.1913" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.2783" style={{ stopColor: "#FBFBFB", stopOpacity: 0.9172 }} />
              <stop offset="0.3622" style={{ stopColor: "#F0F0F0", stopOpacity: 0.8372 }} />
              <stop offset="0.445" style={{ stopColor: "#DCDCDC", stopOpacity: 0.7584 }} />
              <stop offset="0.527" style={{ stopColor: "#C1C1C1", stopOpacity: 0.6803 }} />
              <stop offset="0.6085" style={{ stopColor: "#9E9E9E", stopOpacity: 0.6026 }} />
              <stop offset="0.6896" style={{ stopColor: "#737372", stopOpacity: 0.5253 }} />
              <stop offset="0.769" style={{ stopColor: "#414140", stopOpacity: 0.4498 }} />
              <stop offset="0.8212" style={{ stopColor: "#1C1C1B", stopOpacity: 0.4 }} />
            </linearGradient>
            <path fill="url(#Card22MSVGID5)" d="M136.36,300.44c0,0,6.91-4.19,30.25-3.37" />
            <polyline fill="#FFFFFF" points="166.61,297.07 138.94,300.68 136.36,300.44 			" />
            <path
              fill="#DFD7C6"
              d="M174.36,350.32c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25c-1.97,0.64-9.86,2.25-12.85,2.33
				c-2.22,0.06-26.16-0.85-40.58-1.33c-5.01-0.16-8.87-0.28-10.27-0.28c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98
				c0,1.9,0,3.7,0,4.28c0,1.3-0.17,3.7-0.17,4.88c0,1.18,0.25,3.7,0.25,3.7s0.47,1.09,4.21,1.45c1.46,0.14,9.31,0.63,18.64,1.17
				c14.52,0.84,32.64,1.82,35.83,1.82c5.25,0,19.23-0.72,19.23-0.72s1.38-8.6,1.6-12.67C179.63,354.84,180.14,350.68,174.36,350.32z
				"
            />
            <g>
              <line
                fill="none"
                stroke="#5E5D5B"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="189.92"
                y1="377.18"
                x2="178.23"
                y2="376.07"
              />
              <path
                fill="#5E5D5B"
                d="M178.67,372.46l-0.87-0.04c-0.05,0.32-0.08,0.51-0.08,0.51s-13.98,0.72-19.23,0.72
					c-3.2,0-21.31-0.97-35.83-1.82c-9.33-0.54-17.18-1.03-18.64-1.17c-3.43-0.33-4.11-1.27-4.2-1.43l1.32,7.07
					c0,0,5.98,2.15,17.62,2.77c11.65,0.62,45.53,2.77,51.51,2.77c5.98,0,7.41-1.38,7.41-1.38l0.54-4.39L178.67,372.46z"
              />
              <path
                fill="#77514D"
                d="M102.15,370.45l-0.81,1.87c-0.02,0.05-0.08,0.06-0.12,0.03l-0.94-0.8c-0.02-0.01-0.03-0.04-0.03-0.06
					c0,0-0.48-2.3-0.42-2.26c0.23,0.17,0.94,0.63,2.28,1.11C102.15,370.35,102.17,370.4,102.15,370.45z"
              />
              <linearGradient
                id="Card22MSVGID6"
                gradientUnits="userSpaceOnUse"
                x1="99.8267"
                y1="375.5371"
                x2="177.7291"
                y2="375.5371"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
                <stop offset="0.0648" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID6)"
                d="M177.73,372.92c0,0-13.98,0.72-19.23,0.72c-3.2,0-21.31-0.97-35.83-1.82
					c-9.33-0.54-17.18-1.03-18.64-1.17c-3.43-0.33-4.11-1.27-4.2-1.43l1.32,7.07c0,0,5.98,2.15,17.62,2.77
					c11.65,0.62,45.53,2.77,51.51,2.77c5.98,0,6.93-1.15,6.93-1.15L177.73,372.92z"
              />
              <path
                fill="#FFFFFF"
                d="M104.02,370.66c1.46,0.14,9.31,0.63,18.64,1.17c14.52,0.84,32.64,1.82,35.83,1.82
					c5.25,0,19.23-0.72,19.23-0.72s0.03-0.18,0.08-0.51c0.3-1.9,1.33-8.68,1.52-12.17c0.3-5.4,0.81-9.57-4.97-9.93
					c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25c-1.97,0.64-9.86,2.25-12.85,2.33c-2.22,0.06-26.16-0.85-40.58-1.33
					c-5.01-0.16-8.87-0.28-10.27-0.28c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98c0,1.9,0,3.7,0,4.28
					c0,1.3-0.17,3.7-0.17,4.88c0,0.82,0.12,2.29,0.19,3.11c0.02,0.23,0.04,0.41,0.05,0.51c0.01,0.05,0.01,0.08,0.01,0.08
					s0,0.01,0.01,0.02C99.92,369.39,100.6,370.33,104.02,370.66z"
              />
            </g>
            <g>
              <path
                fill="none"
                d="M105.49,350.53l-0.06-7.89c0,0,0,4.83,0,6.54C105.43,349.58,105.45,350.04,105.49,350.53z"
              />
              <path
                fill="#FFFFFF"
                d="M106.6,355.53c0.78,1.45,1.17,3.9,4.94,4.16c3.65,0.26,42.83,1.69,49.4,1.78
					c3.02-2.72,3.93-8.08,4.51-10.91c0.8-3.89,1.96-6.52,1.96-6.52l1.59-0.02l0.04-0.2l-1.21-10.35c0,0-14.42,0.62-19.75,0.62
					c-5.33,0-29.67-0.42-29.67-0.42l-3.61,4.57c0,0-6.26,1.25-6.12,1.25c0.13,0-3.24,3.16-3.24,3.16l0.06,7.89
					C105.62,352.25,106,354.41,106.6,355.53z"
              />
              <path
                fill="#FFFFFF"
                d="M111.54,359.69c-3.77-0.26-4.16-2.71-4.94-4.16c-0.6-1.13-0.98-3.29-1.11-5
					c-0.04-0.5-0.06-0.95-0.06-1.34c0-1.72,0-6.54,0-6.54s-1.64,4.96-1.64,5.64c0,0.68,0.06,3,0.24,7.07
					c0.18,4.07,0.71,5.34,2.85,5.61c2.14,0.27,42.99,2.17,49.67,2.17c1.4,0,2.58-0.39,3.56-1.03c0.29-0.19,0.57-0.41,0.83-0.64
					C154.36,361.38,115.18,359.95,111.54,359.69z"
              />
              <path
                fill="#FFFFFF"
                d="M169,344.02l-1.59,0.02c0,0-1.16,2.63-1.96,6.52c-0.58,2.83-1.49,8.19-4.51,10.91
					c-0.26,0.24-0.54,0.45-0.83,0.64c-0.98,0.64-2.15,1.03-3.56,1.03c-6.68,0-47.53-1.9-49.67-2.17c-2.14-0.27-2.67-1.54-2.85-5.61
					c-0.18-4.07-0.24-6.4-0.24-7.07c0-0.68,1.64-5.64,1.64-5.64l-2.39-0.04c0,0-2.19,4.59-2.65,5.64c-0.46,1.05-0.66,1.81-0.66,2.65
					c0,0.47,0,3.04,0,5.45c0.14,0.69,0.51,2.57,0.89,3.98c0.47,1.76,1.03,3.61,6.47,3.61s47.86,1.68,50.86,1.6
					c3-0.08,10.88-1.68,12.85-2.33c1.97-0.64,2.22-3.31,2.6-6.25c0.47-3.69,0.95-6.64,0.95-6.64s3.37-4.83,7.73-6.46L169,344.02z"
              />
            </g>
            <g>
              <path
                fill="#D25959"
                d="M188.04,332.14c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
					c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
					c1.35,0.46,5.29,3.2,7.19,3.2s13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
					c-3.05-0.11-4.55-0.89-6.25-1.42c-1.7-0.53-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
					l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.44c2.75-0.41,19.33-2.69,35.84-1.58C189.04,333.06,188.8,332.59,188.04,332.14z"
              />
              <path
                fill="#FFFFFF"
                d="M161.59,336.76c-0.17,0-0.23,0.24-0.08,0.33c0.65,0.36,1.77,0.92,2.67,1.01
					c0.77,0.08,2.1,0.09,2.57,0.09c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L161.59,336.76z"
              />
            </g>
            <g>
              <path
                fill="#77514D"
                d="M105.71,339.64c0.1,0.01,0.19-0.02,0.27-0.08l2.9-2.23c0.06-0.05,0.13-0.07,0.21-0.08l3.5-0.29
					c0.15-0.01,0.29-0.11,0.34-0.26l0.52-1.41c0.09-0.24-0.07-0.51-0.32-0.54l-9.07-1.24c0.06,0.04,0.09,0.11,0.09,0.18l-0.11,4.32
					c0,0.05-0.02,0.1-0.06,0.14l-1.17,1.18c-0.04,0.04-0.06,0.08-0.06,0.14l0,0.06c0.07-0.05,0.16-0.08,0.25-0.07L105.71,339.64z
					 M105.72,336.09c0-0.09,0.08-0.16,0.16-0.15l2.24,0.23c0.13,0.01,0.18,0.19,0.07,0.27l-1.15,0.87
					c-0.03,0.02-0.06,0.03-0.09,0.03l-1.09-0.05c-0.08,0-0.14-0.07-0.14-0.15V336.09z"
              />
              <g>
                <path
                  fill="none"
                  d="M188.04,332.14c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
						c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
						c1.35,0.46,5.29,3.2,7.19,3.2c1.9,0,13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
						c-3.05-0.11-4.55-0.89-6.25-1.42s-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
						l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.43c2.75-0.41,19.33-2.69,35.84-1.58C189.04,333.06,188.8,332.59,188.04,332.14z"
                />
                <path
                  fill="none"
                  d="M161.59,336.76c-0.17,0-0.23,0.24-0.08,0.33c0.65,0.36,1.77,0.92,2.67,1.01c0.77,0.08,2.1,0.09,2.57,0.09
						c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L161.59,336.76z"
                />
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M106.79,327.19c-0.47,0.3-0.63,0.6-0.63,0.89c0,0.4,0,0.76,0,1.07c0.01,0.02,0.02,0.05,0.02,0.08v1
							c0,0.09,0.06,0.17,0.14,0.2l9.71,3.14c0.02,0.01,0.04,0.01,0.06,0.01l3.18,0.1c0.06,0,0.11,0.03,0.14,0.06
							c0.07,0.07,0.08,0.18,0.02,0.27l-0.27,0.37l30.37-0.11c0.16-0.19,0.44-0.33,0.78-0.44c0.13-0.04,0.27-0.09,0.42-0.12
							c4.49-1.17,14.31-2.46,16.77-2.8c0.35-0.05,0.67-0.22,0.9-0.49c0.78-0.9,1.96-1.94,2.65-2.53c0.06-0.05,0.11-0.1,0.16-0.14
							c0.24-0.2,0.53-0.32,0.84-0.34c0.04,0,0.07,0,0.11,0c0,0-0.03,0-0.09-0.01c-1.38-0.19-17.81-2.41-39.44-2.41
							C114.71,324.98,108.57,326.06,106.79,327.19z"
                  />
                </g>
                <path
                  fill="#77514D"
                  d="M191.69,331.24c-3.57-2.34-9.84-3.78-19.54-3.83c-0.34,0-0.68,0.12-0.94,0.34
						c-0.66,0.56-1.98,1.7-2.82,2.67c-0.23,0.27-0.55,0.45-0.9,0.49c-2.46,0.34-12.28,1.63-16.77,2.8
						c-0.15,0.04-0.29,0.08-0.42,0.12c-0.34,0.11-0.62,0.25-0.78,0.44c-0.19,0.22-0.22,0.51,0.01,0.9l2.81,4.49
						c0.27,0.45,0.76,0.72,1.27,0.71c0.34-0.01,0.75,0,1.18,0.02c0.23,0.01,0.46,0.03,0.69,0.05c0.29,0.01,0.5,0.04,0.64,0.08
						c0.38,0.06,0.74,0.15,1.01,0.28c1.3,0.59,4.68,3.17,7.53,3.17c2.86,0,17.79,0,19.74,0s4.29-1.85,6.04-4.69
						C192.21,336.45,195.32,333.61,191.69,331.24z M188.94,333.65c-16.51-1.11-33.09,1.18-35.84,1.58c-0.2,0.03-0.32,0.24-0.24,0.43
						l0.47,1.12c0.05,0.12,0.17,0.2,0.3,0.19c0.59-0.03,2.25-0.1,3.5-0.15c1.55-0.05,2.17,0.11,3.87,0.63s3.2,1.31,6.25,1.42
						c2.79,0.1,16.54-0.33,18.88-0.41c-1.53,2.03-3.66,4.03-5.24,4.03c-1.25,0-13.07,0-14.97,0c-1.9,0-5.84-2.74-7.19-3.2
						c-1.11-0.38-4.58-0.51-5.78-0.55c-0.25-0.01-0.49-0.15-0.61-0.38l-1.7-3.12c-0.23-0.42,0-0.95,0.46-1.07
						c1.84-0.46,6.5-1.5,14.26-2.45c10.33-1.27,20.81-0.66,22.66,0.41C188.8,332.59,189.04,333.06,188.94,333.65z M166.92,338.01
						c0,0.1-0.08,0.17-0.17,0.17c-0.47,0-1.81-0.01-2.57-0.09c-0.9-0.09-2.02-0.65-2.67-1.01c-0.15-0.09-0.1-0.32,0.08-0.33
						l5.16-0.12c0.1,0,0.17,0.08,0.17,0.17"
                />
                <path
                  fill="#DFD7C6"
                  d="M161.59,336.78c-0.17,0-0.23,0.24-0.08,0.32c0.65,0.36,1.77,0.91,2.67,1c0.77,0.08,2.11,0.09,2.58,0.09
						c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L161.59,336.78z"
                />
                <path
                  fill="#77514D"
                  d="M119.41,333.75c-0.03-0.04-0.08-0.06-0.14-0.06l-3.18-0.1c-0.02,0-0.04,0-0.06-0.01l-9.71-3.14
						c-0.09-0.03-0.14-0.11-0.14-0.2v-1c0-0.03-0.01-0.06-0.02-0.08c-0.06-0.15-0.27-0.18-0.37-0.03l-0.93,1.52
						c-0.02,0.03-0.04,0.05-0.06,0.06l-0.94,0.64c0,0,0,0,0,0c0.01,0,0.03,0,0.04,0.01l10.73,2.55c0.23,0.06,0.37,0.31,0.28,0.54
						l-1.23,3.33c-0.06,0.16-0.21,0.26-0.37,0.26h-3.61c-0.1,0-0.19,0.04-0.26,0.1l-3.17,2.84c-0.07,0.07-0.17,0.1-0.26,0.1h-2.99
						c-0.15,0-0.28-0.08-0.35-0.21l-0.03,0.42c0,0.03,0,0.05,0.01,0.08l0.35,1.11c0.03,0.09,0.11,0.15,0.2,0.15h4.27
						c0.04,0,0.07-0.01,0.1-0.03l4.45-2.54c0.03-0.02,0.06-0.02,0.09-0.03l2.67-0.2l0.33-0.02c0.06,0,0.11-0.03,0.15-0.08l3.91-5.32
						l0.27-0.37C119.49,333.93,119.47,333.82,119.41,333.75z"
                />
              </g>
              <path
                fill="#D25959"
                d="M103.01,341.07H106c0.1,0,0.19-0.04,0.26-0.1l3.17-2.84c0.07-0.07,0.17-0.1,0.26-0.1h3.61
					c0.17,0,0.31-0.1,0.37-0.26l1.23-3.33c0.08-0.23-0.05-0.48-0.28-0.54l-10.73-2.55c-0.01,0-0.03,0-0.04-0.01
					c-0.04,0.03-0.07,0.07-0.08,0.13l-0.35,1.57c-0.02,0.1,0.03,0.2,0.13,0.24l0.45,0.18c0.01,0.01,0.03,0.01,0.04,0.02l9.07,1.24
					c0.25,0.03,0.41,0.3,0.32,0.54l-0.52,1.41c-0.05,0.15-0.19,0.25-0.34,0.26l-3.5,0.29c-0.08,0.01-0.15,0.03-0.21,0.08l-2.9,2.23
					c-0.08,0.06-0.17,0.09-0.27,0.08l-2.71-0.19c-0.09-0.01-0.18,0.02-0.25,0.07l-0.08,1.34
					C102.73,340.98,102.86,341.07,103.01,341.07z"
              />
              <path
                fill="#D1CAB7"
                d="M105.86,337.28l1.09,0.05c0.03,0,0.07-0.01,0.09-0.03l1.15-0.87c0.11-0.08,0.06-0.26-0.07-0.27
					l-2.24-0.23c-0.09-0.01-0.16,0.06-0.16,0.15v1.05C105.72,337.21,105.78,337.28,105.86,337.28z"
              />
            </g>
            <g>
              <ellipse
                transform="matrix(0.9992 -0.0398 0.0398 0.9992 -13.0376 5.4976)"
                fill="#1EBEE4"
                cx="131.4"
                cy="329.82"
                rx="2.29"
                ry="3.12"
              />

              <ellipse
                transform="matrix(0.9992 -0.0401 0.0401 0.9992 -13.1373 5.5333)"
                fill="#141616"
                cx="131.2"
                cy="329.87"
                rx="1.87"
                ry="2.68"
              />

              <ellipse
                transform="matrix(0.9992 -0.0401 0.0401 0.9992 -13.1373 5.5333)"
                fill="#FFFFFF"
                cx="131.2"
                cy="329.87"
                rx="1.16"
                ry="1.66"
              />
              <path fill="#1EBEE4" d="M131.13,328.2v1.64h-1.09C130.04,329.84,129.99,328.34,131.13,328.2z" />
              <path fill="#1EBEE4" d="M131.13,329.84v1.69c0,0,1.2,0.12,1.23-1.69L131.13,329.84z" />
            </g>
            <g>
              <path
                fill="#DFD7C6"
                d="M108.46,352.5l0.71-0.02c0,0-0.47,1.98-0.49,2.08c-0.02,0.1-0.07,0.18-0.29,0.16
					c-0.22-0.02-0.52-0.07-0.52-0.07s0.39-1.83,0.44-1.99S108.46,352.5,108.46,352.5z"
              />
              <path
                fill="#1EBEE4"
                d="M108.63,353.1c0,0,0.26,0.05,0.31-0.08c0.05-0.14,0.08-0.3,0.08-0.3l-0.3,0.01L108.63,353.1z"
              />
              <path
                fill="#1EBEE4"
                d="M108.88,353.22c0,0-0.11,0-0.19,0c-0.09,0-0.12,0.07-0.14,0.16c-0.02,0.08-0.26,1.19-0.26,1.19
					s0.15,0.01,0.21,0.01s0.1-0.07,0.14-0.23S108.88,353.22,108.88,353.22z"
              />
              <path
                fill="#DFD7C6"
                d="M109.39,352.75l0.25-0.01c0.01,0,0.03,0.01,0.03,0.02l0.22,0.5c0.01,0.02,0.04,0.03,0.05,0.01l0.55-0.53
					c0.01-0.01,0.01-0.01,0.02-0.01l0.4,0c0.03,0,0.05,0.04,0.02,0.06l-0.84,0.86c-0.01,0.01-0.01,0.03-0.01,0.04l0.45,0.91
					c0.01,0.02-0.01,0.05-0.03,0.05l-0.36-0.02c-0.01,0-0.02-0.01-0.03-0.02l-0.31-0.63c-0.01-0.02-0.04-0.03-0.05-0.01
					c-0.11,0.12-0.48,0.49-0.52,0.53c-0.05,0.04-0.06,0.04-0.15,0.03c-0.05,0-0.13-0.01-0.18-0.02c-0.03,0-0.04-0.04-0.02-0.06
					l0.74-0.85c0.01-0.01,0.01-0.02,0.01-0.04l-0.3-0.8C109.35,352.77,109.37,352.75,109.39,352.75z"
              />
              <path
                fill="#DFD7C6"
                d="M111.32,353.06l1.22,0.01c0.02,0,0.03,0.02,0.03,0.03l-0.13,0.45c0,0.01-0.01,0.02-0.03,0.02
					c-0.11,0-0.62-0.01-0.72-0.01c-0.1,0-0.15,0.13-0.17,0.26c0,0.02,0.01,0.03,0.03,0.03l0.75,0.04c0.02,0,0.03,0.02,0.03,0.03
					c-0.02,0.09-0.08,0.36-0.1,0.42c-0.03,0.07-0.05,0.05-0.14,0.04c-0.09-0.01-0.82-0.03-0.97-0.03c-0.14,0-0.22,0.14-0.26,0.3
					c0,0.02,0.01,0.03,0.02,0.03c0.2,0.01,1.33,0.09,1.48,0.1c0.17,0.01,0.29-0.07,0.35-0.33c0.06-0.26,0.47-1.53,0.51-1.67
					c0.04-0.15-0.14-0.1-0.21-0.11c-0.07-0.01-1.3,0.02-1.44,0.02c-0.13,0.01-0.24,0.14-0.28,0.34
					C111.29,353.05,111.3,353.06,111.32,353.06z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M180.21,311.86c0.22,0.3,0.32,0.49,0.32,0.49s-2.51,4.63-4.64,7.51c-2.12,2.88-13.12,2.88-19.57,3.2
					c-6.44,0.32-22.39,0.32-22.39,0.32s-17.3,0.09-20.38-0.1c-3.08-0.19-1.41-1.51-0.8-2.17c0.61-0.66,2.13-2.32,7.27-8.02
					c0.13-0.14,0.26-0.28,0.39-0.42l-0.51,0.01c0,0-4.82,5.32-9.23,10.12c-2.15,2.34-3.28,3.66-3.86,4.39
					c1.78-1.14,7.91-2.21,25.82-2.21c21.63,0,38.06,2.22,39.44,2.41c0.77-1.17,2.66-4.04,3.86-6.15c1.54-2.71,5.34-9.39,5.34-9.39
					L180.21,311.86z"
              />
            </g>
            <g>
              <path fill="#F1F3F1" d="M199.55,296.04c0.31,0,0.49,0,0.53,0C199.9,296.04,199.73,296.04,199.55,296.04z" />
              <path
                fill="#F1F3F1"
                d="M200.08,296.04C200.08,296.04,200.08,296.04,200.08,296.04C200.08,296.04,200.08,296.04,200.08,296.04z"
              />
              <path
                fill="#191C1C"
                d="M183.59,306.55c-1.03-0.59-5.81-3.27-10.12-4c-4.86-0.82-10.66-0.82-10.93-0.89
					c-0.25-0.06-2.17,0.52-2.46,0.61c-0.02,0.01-0.05,0.01-0.08,0.01c-0.95-0.03-19.07-0.51-28.73,0.68c-0.02,0-0.05,0-0.07,0
					l-1.89-0.33c-0.02,0-0.05,0-0.07,0c-0.44,0.05-4.76,0.59-6.04,1.51c-0.29,0.21-0.55,0.47-0.76,0.76
					c-0.78,1.04-1.09,2.42-1.19,3.01c-0.11,0.63,0.01,1.51,0.05,1.77c0.01,0.05,0,0.1-0.02,0.15l-1.21,2.49
					c-0.08,0.17,0.04,0.36,0.22,0.36c0.07,0,0.13-0.03,0.17-0.08c4.94-5.22,9.05-5.68,11.56-5.68c2.59,0,27.5,0,36.54,0
					c7.18,0,10.49,3.43,11.55,4.84c0.05,0.06,0.12,0.1,0.2,0.1l0.79-0.01c0.09,0,0.17-0.05,0.22-0.14l2.36-4.84
					C183.74,306.76,183.7,306.61,183.59,306.55z"
              />
            </g>
            <g>
              <path
                fill="#464648"
                d="M146.68,319.71c0,0,0.07,0.31,0.87,0.39c0.6,0.06,4.69,0.06,6.64,0.1c0.87-0.15,1.39-0.5,0.98-0.71
					c-0.55-0.28-6.77,0-7.67-0.49c-0.9-0.49-9.68-0.77-11.61-0.7c-1.94,0.07-3.35,0.31-2.95,1.67c0.01,0.05,0.1-0.07,0.12-0.02
					c0.7-0.29,1.83-0.57,4.61-0.57C141.75,319.38,146.68,319.71,146.68,319.71z"
              />
              <path
                fill="#191C1C"
                d="M136.63,321.5c0.12-0.07,0.2-0.2,0.21-0.34c0.02-0.21,0.17-0.52,0.84-0.59
					c2.07-0.21,7.81-0.21,9.68-0.21c0.91,0,4.42-0.21,5.88-0.14c0.39,0.02,0.77-0.01,1.09-0.06c-1.95-0.04-5.89-0.12-6.49-0.18
					c-0.46-0.05-0.66-0.2-0.74-0.33c-0.07-0.11-0.19-0.17-0.32-0.18c-1.1-0.08-5.44-0.4-8.99-0.4c-2.26,0-3.71,0.11-4.54,0.3
					c-0.26,0.06-0.41,0.35-0.3,0.6c0.23,0.57,0.48,1.15,0.6,1.43c0.05,0.11,0.13,0.19,0.24,0.23
					C135.16,322.19,136.19,321.76,136.63,321.5z"
              />
            </g>
            <path
              fill="#D25959"
              d="M136.32,303.59c-0.14,0-0.26-0.11-0.27-0.25c-0.01-0.15,0.1-0.28,0.25-0.29c0.07,0,6.96-0.49,15.3-0.27
				c0.15,0,0.26,0.13,0.26,0.28c0,0.15-0.12,0.27-0.27,0.27c-8.32-0.22-15.18,0.26-15.25,0.27
				C136.33,303.59,136.33,303.59,136.32,303.59z"
            />
            <path
              fill="#FFFFFF"
              d="M182.99,299.66l2.2,0.75c0,0,41.27-8.37,76,4.23c0,0-1.13-3.16-10.03-5.4
				c-7.41-1.87-24.84-6.21-53.23-3.26C192.85,296.5,187.85,297.65,182.99,299.66z"
            />
            <linearGradient
              id="Card22MSVGID7"
              gradientUnits="userSpaceOnUse"
              x1="182.9948"
              y1="299.8046"
              x2="261.1964"
              y2="299.8046"
            >
              <stop offset="0.202" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.9641" style={{ stopColor: "#1C1C1A", stopOpacity: 0.15 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID7)"
              d="M182.99,299.66l2.2,0.75c0,0,41.27-8.37,76,4.23c0,0-1.13-3.16-10.03-5.4
				c-7.41-1.87-24.84-6.21-53.23-3.26C192.85,296.5,187.85,297.65,182.99,299.66z"
            />
            <path
              fill="#FFFFFF"
              d="M181.8,343.97c-4.21,1.73-7.43,6.34-7.43,6.34c5.79,0.36,5.27,4.53,4.97,9.93
				c-0.04,0.72-0.12,1.59-0.22,2.53l0.15-1.1c0,0,12.41,1.55,13.18,1.55c0.76,0,3.96,1.08,6.02-2.01c1.08-1.63,3.84-6.45,8.35-10.33
				c-2.65-0.71-8.64-2.33-12.83-3.65c-3.48-1.1-5.55-3.1-6.6-4.43c-1.01,0.76-2.03,1.19-2.97,1.19
				C184.02,343.97,183.08,343.97,181.8,343.97z"
            />
            <path
              fill="#C7C9C6"
              d="M285.08,327.06l-0.18,0.42c0,0-4.81-5.43-12.91-13.4c-8.1-7.97-16.16-10.83-29.06-12.42
				c-12.9-1.6-32.61-0.44-36.36-0.18c-3.75,0.27-9.24,1.42-9.94,3.1c-0.7,1.68-2.53,6.83-3.05,9.84c-0.52,3.01,0.26,4.26,2.27,4.88
				c2.01,0.62,10.37,3.72,14.73,5.05c4.36,1.33,13.34,3.9,17.87,4.34c4.53,0.44,20.75,1.77,20.75,1.77s32,3.64,34.61,3.72
				c2.62,0.09,4.36-1.42,4.36-1.42c0.55-0.14,0.94-0.47,1.24-0.67L285.08,327.06z M283.65,333.47c-2.99-0.2-28.99-3.19-32.71-3.53
				c-3.73-0.34-21.16-2.03-23.75-2.44c-2.59-0.41-12.31-2.57-15.9-3.79c-3.59-1.22-11.58-4.06-13.44-4.74
				c-1.86-0.68-1.66-3.11-1.33-4.6c0.33-1.49,1.6-6.36,1.6-6.36c0.53-1.56,1.4-3.52,5.99-4.4c4.59-0.88,18.16-2.23,38.32-0.74
				c20.16,1.49,27.61,9.88,34.53,16.98c6.92,7.1,10.19,11.92,10.19,11.92S286.65,333.67,283.65,333.47z"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M204.1,303.61c-4.59,0.88-5.46,2.84-5.99,4.4c0,0-1.26,4.87-1.6,6.36c-0.33,1.49-0.53,3.92,1.33,4.6
					c1.86,0.68,9.85,3.52,13.44,4.74c1.49,0.51,4.04,1.18,6.7,1.81c-0.25-4.63-1.03-15.76-2.88-23.06
					C209.85,302.75,206.11,303.23,204.1,303.61z"
              />
              <linearGradient
                id="Card22MSVGID8"
                gradientUnits="userSpaceOnUse"
                x1="198.6176"
                y1="321.3326"
                x2="219.1543"
                y2="309.4757"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
                <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
                <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
                <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
                <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
                <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
                <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
                <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
                <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
                <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
                <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
                <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
                <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
                <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
                <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
                <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
                <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
                <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
                <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
                <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
                <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID8)"
                d="M204.1,303.61c-4.59,0.88-5.46,2.84-5.99,4.4c0,0-1.26,4.87-1.6,6.36
					c-0.33,1.49-0.53,3.92,1.33,4.6c1.86,0.68,9.85,3.52,13.44,4.74c1.49,0.51,4.04,1.18,6.7,1.81c-0.25-4.63-1.03-15.76-2.88-23.06
					C209.85,302.75,206.11,303.23,204.1,303.61z"
              />
              <path
                opacity="0.63"
                fill="#A1C8D9"
                d="M276.95,319.85c-6.92-7.1-14.37-15.49-34.53-16.98c-1.07-0.08-2.12-0.15-3.15-0.21
					l11.42,27.25c0.09,0.01,0.17,0.02,0.25,0.02c3.73,0.34,29.72,3.33,32.71,3.53c2.99,0.2,3.49-1.98,3.49-1.98
					S283.87,326.96,276.95,319.85z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M215.11,302.46c0,0,2.3,14.25,2.39,22.9l0.48,0.16c0,0,0.58-8-2.28-23.1L215.11,302.46z"
            />
            <path
              fill="#191C1C"
              d="M239.27,302.66l11.42,27.25l8.19,0.89l-13.36-27.56C245.52,303.24,243.05,302.84,239.27,302.66z"
            />
            <polygon fill="#FFFFFF" points="240.93,302.77 253.27,330.16 258.88,330.8 245.52,303.24 			" />
            <path fill="#FFFFFF" d="M278.78,321.78l4.87,11.69c0,0,1.78,0.27,2.96-0.87S283.92,327.27,278.78,321.78z" />
            <path
              fill="#77514D"
              d="M161.63,373.59l3.89,2.25c0.19,0.11,0.4,0.16,0.62,0.15c1.47-0.08,7.44-0.41,9.37-0.41
				c2.22,0,2.22-2.65,2.22-2.65S168.28,373.48,161.63,373.59"
            />
            <linearGradient
              id="Card22MSVGID9"
              gradientUnits="userSpaceOnUse"
              x1="161.629"
              y1="374.454"
              x2="177.7291"
              y2="374.454"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID9)"
              d="M161.63,373.59l3.89,2.25c0.19,0.11,0.4,0.16,0.62,0.15c1.47-0.08,7.44-0.41,9.37-0.41
				c2.22,0,2.22-2.65,2.22-2.65S168.28,373.48,161.63,373.59"
            />
            <g>
              <path
                fill="#5E5D5B"
                d="M316.34,348.14c-12.13-2.39-16.21,25.54-16.21,25.54c-17.73,0.5-62.53,2.25-62.97,2.64
					c0,0.01,0,0.01,0,0.01c0,0,0,0,0-0.01c0.12-0.53,3.12-23.95-14.6-26.42c-18.06-2.52-25.33,25.37-25.33,25.37l-19.49-2.35
					c0,0-0.53,3.78-0.87,6.19c-0.15,1.04,0.31,2.61,1.37,3.05c4.82,2.02,18.81,7.24,18.81,7.24s2.12-12.45,4.91-20.16
					s8.13-15.3,15.34-15.99c6.58-0.63,19.1,0.31,18.14,19.62c-0.17,3.45-0.52,11.08-0.78,12.59c-0.26,1.51-1.39,2.66-1.39,2.66
					s52.75-5.85,65.82-6.12c0,0,0.44-0.09,0.96-1.06c0.52-0.98,1.92-10.64,3.49-16.49c1.57-5.85,4.97-15.34,13.08-13.65
					c8.11,1.68,7.67,13.3,6.8,20.57l-0.61,2.57l-0.09,2.57c0,0,2.38-1.16,2.54-4.97l-1.24,0.13
					C324.01,371.67,328.46,350.54,316.34,348.14z"
              />
              <path
                fill="#FFFFFF"
                d="M316.21,345.38c-11.66-1.92-14.83,17.04-17.35,20.92c-0.87,1.34-0.96,1.76-3.37,1.65
					c-2.25-0.11-5.95-0.07-5.95-0.07c-1.49,0-47.04-1.47-48.82-1.71c-1.78-0.24-1.52-0.46-1.82-2.48
					c-0.3-2.01-0.69-15.65-15.46-17.97c-14.78-2.32-22.93,12.39-24.98,15.49c-2.06,3.1-5.26,2.01-6.02,2.01
					c-0.76,0-13.18-1.55-13.18-1.55l-1.54,11.25l19.49,2.35c0,0,7.27-27.89,25.33-25.37c17.72,2.47,14.72,25.89,14.6,26.42
					c0.44-0.39,45.24-2.14,62.97-2.64c0,0,4.08-27.93,16.21-25.54c12.13,2.39,7.67,23.53,7.67,23.53l1.24-0.13
					C325.25,371.55,328.58,347.41,316.21,345.38z"
              />
              <path
                fill="#FFFFFF"
                d="M237.15,376.33C237.15,376.34,237.15,376.33,237.15,376.33C237.15,376.32,237.15,376.33,237.15,376.33z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M250.98,381.78l2.2,0.04c0.19,0,0.3,0.21,0.21,0.38l-0.13,0.23c-0.1,0.17,0.03,0.39,0.23,0.38l3.8-0.23
				c0.04,0,0.07-0.01,0.11-0.03l1.82-1.05c0.04-0.02,0.08-0.03,0.13-0.03l1.82,0.04c0.19,0,0.31,0.23,0.2,0.39l-0.01,0.01
				c-0.11,0.17,0.01,0.39,0.21,0.39l3.93-0.09c0.04,0,0.09-0.01,0.13-0.04l1.96-1.25c0.04-0.03,0.09-0.04,0.14-0.04l2,0.04
				c0.21,0,0.32,0.26,0.18,0.42l0,0c-0.14,0.17-0.02,0.43,0.2,0.42l3.02-0.13c0.03,0,0.06-0.01,0.09-0.02l2.05-0.96
				c0.03-0.01,0.06-0.02,0.09-0.02l2.09-0.12c0.21-0.01,0.34,0.24,0.2,0.41l-0.02,0.02c-0.14,0.17-0.01,0.42,0.2,0.41l2.82-0.13
				c0.04,0,0.08-0.01,0.11-0.03l1.97-1.15c0.04-0.02,0.08-0.03,0.12-0.03l2.09-0.04c0.19,0,0.32,0.21,0.22,0.38l-0.09,0.16
				c-0.1,0.17,0.02,0.39,0.22,0.38l2.7-0.13c0.04,0,0.08-0.01,0.12-0.04l2.02-1.25c0.04-0.02,0.08-0.04,0.13-0.04h1.69
				c0.2,0,0.32,0.23,0.2,0.4c-0.23,0.33-0.49,0.73-0.4,0.66c0.13-0.09,1.44-0.1,1.82-0.11c0.07,0,0.13-0.03,0.18-0.08l1.73-1.8
				c0.16-0.16,0.04-0.44-0.19-0.43c-5.28,0.26-42.25,2.05-43.69,2.24c-1.56,0.21-3.95-1.01-6.14-0.69s-1.25,1.48-0.83,1.85
				c0.42,0.37,1.92,1.43,2.34,1.69c0.42,0.26,1.61,0.16,2.5-0.53c0.75-0.58,1.32-0.79,1.47-0.83
				C250.93,381.78,250.96,381.78,250.98,381.78z"
            />
            <path
              fill="#1EBEE4"
              d="M251.08,381.45c-1.33,0.58-1.36,1.29-2.8,0.6c-1.44-0.69-0.95-0.42-1.44-0.69
				c-0.49-0.27-0.91-1.04,0.87-1.08c1.78-0.04,38.71-1.68,46.9-2.23l-1.37,0.98c0,0-11.93,0.77-24.92,1.37
				C255.32,381.01,251.87,381.1,251.08,381.45z"
            />
            <linearGradient
              id="Card22MSVGID10"
              gradientUnits="userSpaceOnUse"
              x1="246.4126"
              y1="380.1914"
              x2="294.6076"
              y2="380.1914"
            >
              <stop offset="0.3466" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.5959" style={{ stopColor: "#040404", stopOpacity: 0.2289 }} />
              <stop offset="0.836" style={{ stopColor: "#0F0F0F", stopOpacity: 0.4494 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.6 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID10)"
              d="M251.08,381.45c-1.33,0.58-1.36,1.29-2.8,0.6c-1.44-0.69-0.95-0.42-1.44-0.69
				c-0.49-0.27-0.91-1.04,0.87-1.08c1.78-0.04,38.71-1.68,46.9-2.23l-1.37,0.98c0,0-11.93,0.77-24.92,1.37
				C255.32,381.01,251.87,381.1,251.08,381.45z"
            />
            <g>
              <path
                fill="#1EBEE4"
                d="M151.72,383.26c0.26,0.04,0,1.14,0,1.14l-6.82-1.23l-1.12-7.28
					C143.78,375.89,144.39,382.12,151.72,383.26z"
              />
              <path
                fill="#1EBEE4"
                d="M143.78,375.89l10.87,0.61c0,0,7.77,4.65,8.71,5.44c0.95,0.79,0.86,2.72,0.86,2.72l-12.51-0.26v-1.14
					c0,0-1.81-0.35-3.8-1.14C145.94,381.33,143.95,377.99,143.78,375.89z"
              />
              <linearGradient
                id="Card22MSVGID11"
                gradientUnits="userSpaceOnUse"
                x1="154.0074"
                y1="375.8877"
                x2="154.0074"
                y2="384.6624"
              >
                <stop offset="0.1917" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID11)"
                d="M143.78,375.89l10.87,0.61c0,0,7.77,4.65,8.71,5.44c0.95,0.79,0.86,2.72,0.86,2.72l-12.51-0.26
					v-1.14c0,0-1.9-0.25-3.79-1.25C145.7,380.82,143.95,377.99,143.78,375.89z"
              />
              <path
                fill="#1EBEE4"
                d="M169.67,385.19l-5.44-0.53c0,0,0.3-1.9-1.13-2.77c-1.42-0.87-8.45-5.39-8.45-5.39l0.65-0.98
					c0,0,7,4.8,8.31,5.73c1.31,0.93,1.54,2.78,1.54,2.78l4.5,0.81L169.67,385.19z"
              />
              <path
                fill="#1EBEE4"
                d="M143.69,375.29l0.09,0.6l1.12,7.28l-0.87-0.01C144.04,383.16,142.84,375.46,143.69,375.29z"
              />
              <path fill="#1EBEE4" d="M143.69,375.29c0,0,9.05,0.06,11.61,0.23l-0.65,0.98l-10.87-0.61L143.69,375.29z" />
              <path
                fill="#1EBEE4"
                d="M155.31,375.52l14.35,9.32l-4.78-0.69c0,0-0.17-1.62-0.97-2.37c-0.8-0.75-8.88-5.73-8.88-5.73
					L155.31,375.52z"
              />
              <linearGradient
                id="Card22MSVGID12"
                gradientUnits="userSpaceOnUse"
                x1="155.0222"
                y1="380.1812"
                x2="169.6524"
                y2="380.1812"
              >
                <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0.1 }} />
                <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.1 }} />
                <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID12)"
                d="M155.31,375.52l14.35,9.32l-4.78-0.69c0,0-0.17-1.62-0.97-2.37c-0.8-0.75-8.88-5.73-8.88-5.73
					L155.31,375.52z"
              />
              <path
                fill="#1EBEE4"
                d="M101.77,380.03l-0.47-2c0,0,1.66-3.9,2.08-4.71c0.13-0.25,0.12-0.48,0.12-0.48s-0.69,0.54-1.27,1.62
					c-0.84,1.57-1.27,2.62-1.31,3.24s0.38,2.24,0.38,2.24L101.77,380.03z"
              />
              <path
                fill="#1EBEE4"
                d="M103.51,372.83c0,0,6.47,0.24,7.5,0.52c1.03,0.29,1.17,0.67,1.5,2.48c0.33,1.81,0.75,3.29,0.75,3.29
					l1.97,2.19l-3.14-0.29l-0.14-0.52l-2.91-0.14l-0.09,0.62l-7.17-0.95l-0.47-2L103.51,372.83z"
              />
              <path
                fill="#1EBEE4"
                d="M103.51,372.83c0,0-0.98,2.1-1.27,3.19c-0.28,1.1,0.42,1,1.27,1.14c0.84,0.14,2.16,2.34,5.53,3.19
					l2.91,0.14c0,0-0.89-4.91-0.98-5.67s-0.28-1.43,0.05-1.48C111.01,373.35,105.61,372.87,103.51,372.83z"
              />
            </g>
            <path
              fill="#1EBEE4"
              d="M112.08,381.03h0.61c0,0-1.08-6.2-1.27-6.87c-0.19-0.67-0.7-0.57-0.56,0.14
				C111.01,375.02,112.08,381.03,112.08,381.03z"
            />
            <path
              fill="#5E5D5B"
              d="M218.44,363.96c0.3,0.43,1.31,1.91,1.31,1.91l2.41-0.04l1.1-1.73
				C223.26,364.09,221.73,363.14,218.44,363.96z"
            />
            <g>
              <path
                fill="#191C1C"
                d="M314.32,353.17c3.24,0,9.11,4.79,8.47,20.61c-0.59,14.64-6.97,18.84-9.8,18.84
					c-2.39,0-14.84,2.77-17.82-10.58c0,0,4.97,1.23,5.96-5.16C301.87,372.12,303.22,353.17,314.32,353.17z"
              />
              <path
                fill="#5E5D5B"
                d="M304.45,374.71c-0.22-3.6-0.66-6.8-1.23-9.4c0,0,3.23-13.2,11.32-11.44c9.32,2.03,8.38,21.65,7.84,24.69
					c-0.69,3.85-2.51,13.22-9.16,13.98C306.59,393.29,304.7,378.81,304.45,374.71z"
              />

              <ellipse
                transform="matrix(0.0174 -0.9998 0.9998 0.0174 -64.3702 679.9109)"
                fill="#FFFFFF"
                cx="313.73"
                cy="372.7"
                rx="16.24"
                ry="7.68"
              />
              <polygon
                fill="#191C1C"
                points="315.66,357.59 316.39,363.7 320.09,364.7 320.89,371.26 318.05,375.14 319.03,382.57 
					317.4,383.26 316.3,386.66 313.93,382.44 310.65,386.83 309.75,383.21 308.08,382.69 309.54,375.14 306.52,371.38 
					307.82,368.21 307.68,364.34 311.2,363.99 312.42,357.47 313.87,358.33 				"
              />
              <linearGradient
                id="Card22MSVGID13"
                gradientUnits="userSpaceOnUse"
                x1="313.4737"
                y1="387.5304"
                x2="313.9168"
                y2="356.6842"
              >
                <stop offset="0.6685" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.6968" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
                <stop offset="0.7171" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
                <stop offset="0.735" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
                <stop offset="0.7515" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
                <stop offset="0.7669" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
                <stop offset="0.7817" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
                <stop offset="0.7959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
                <stop offset="0.8093" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
                <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="none"
                stroke="url(#Card22MSVGID13)"
                strokeMiterlimit="10"
                d="M312.42,357.47L311,362.8l-3.33,1.54
					c0,0-1.02,4.32-1.15,7.04l2.13,4.73l-0.58,6.57c0,0,0.93,2.78,2.57,4.14l2.97-2.66l2.66,2.66c0,0,1.91-2.19,2.75-4.26
					l-0.18-6.39l2.04-4.91c0,0,0-3.61-0.8-6.57l-2.97-1.3l-1.46-5.8C315.66,357.59,314.59,356.76,312.42,357.47z"
              />
              <polygon fill="#5E5D5B" points="315.66,357.59 315.08,366.06 316.06,367.42 320.09,364.7 317.12,363.39 				" />
              <polygon fill="#5E5D5B" points="312.42,357.47 311.93,365.76 311,367.3 307.68,364.34 311,362.8 				" />
              <path
                fill="#5E5D5B"
                d="M307.68,364.34l0.8,2.6l-0.75,3.79l-1.2,0.65C306.52,371.38,306.75,367.48,307.68,364.34z"
              />
              <path
                fill="#5E5D5B"
                d="M320.09,364.7l-0.98,2.31l0.44,3.55l1.33,0.71C320.89,371.26,320.89,367.77,320.09,364.7z"
              />
              <polygon fill="#5E5D5B" points="320.89,371.26 316.9,373.75 316.46,376.12 319.03,382.57 318.85,376.18 				" />
              <path
                fill="#5E5D5B"
                d="M319.03,382.57l-1.33-1.12l-1.37,2.13l-0.04,3.26C316.28,386.83,318.45,384.46,319.03,382.57z"
              />
              <polygon fill="#5E5D5B" points="316.28,386.83 313.75,379.91 312.78,379.61 310.65,386.83 313.62,384.17 				" />
              <path
                fill="#5E5D5B"
                d="M310.65,386.83l0.35-3.02c0,0-1.64-2.37-1.68-2.19s-1.24,1.07-1.24,1.07S309.27,385.76,310.65,386.83z"
              />
              <polygon fill="#5E5D5B" points="308.08,382.69 310.2,375.76 309.94,373.51 306.52,371.38 308.65,376.12 				" />
              <g>
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="317.12"
                  y1="363.39"
                  x2="318.32"
                  y2="360.2"
                />

                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="318.85"
                  y1="376.18"
                  x2="320.67"
                  y2="377.42"
                />

                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="313.62"
                  y1="384.17"
                  x2="313.62"
                  y2="388.25"
                />
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="308.65"
                  y1="376.12"
                  x2="306.79"
                  y2="377.3"
                />
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="311"
                  y1="362.8"
                  x2="309.63"
                  y2="360.14"
                />
              </g>
              <ellipse fill="#FFFFFF" cx="312.43" cy="372.54" rx="0.72" ry="1.63" />
              <ellipse fill="#1EBEE4" cx="312.43" cy="372.54" rx="0.47" ry="1.06" />
            </g>
            <linearGradient
              id="Card22MSVGID14"
              gradientUnits="userSpaceOnUse"
              x1="99.5668"
              y1="361.9825"
              x2="179.4945"
              y2="361.9825"
            >
              <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.0648" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.8896" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.9319" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9911" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="1" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID14)"
              d="M174.36,350.32c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25
				c-1.97,0.64-9.86,2.25-12.85,2.33c-2.22,0.06-26.16-0.85-40.58-1.33c-5.01-0.16-8.87-0.28-10.27-0.28
				c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98c0,1.9,0,3.7,0,4.28c0,1.3-0.17,3.7-0.17,4.88
				c0,1.18,0.26,3.73,0.26,3.73s0.46,1.06,4.2,1.43c1.46,0.14,9.31,0.63,18.64,1.17c14.52,0.84,32.64,1.82,35.83,1.82
				c5.25,0,19.23-0.72,19.23-0.72s1.38-8.6,1.6-12.67C179.63,354.84,180.14,350.68,174.36,350.32z"
            />
            <linearGradient
              id="Card22MSVGID15"
              gradientUnits="userSpaceOnUse"
              x1="137.2339"
              y1="333.475"
              x2="137.2339"
              y2="361.4723"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.548" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.788" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.9984" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID15)"
              d="M106.6,355.53c0.78,1.45,1.17,3.9,4.94,4.16c3.65,0.26,42.83,1.69,49.4,1.78
				c3.02-2.72,3.93-8.08,4.51-10.91c0.8-3.89,1.96-6.58,1.96-6.58l1.57,0l0.06-0.15l-1.21-10.35c0,0-14.42,0.62-19.75,0.62
				c-5.33,0-29.67-0.42-29.67-0.42l-3.61,4.57c0,0-6.26,1.25-6.12,1.25c0.13,0-3.24,3.16-3.24,3.16l0.06,7.89
				C105.62,352.25,106,354.41,106.6,355.53z"
            />
            <linearGradient
              id="Card22MSVGID16"
              gradientUnits="userSpaceOnUse"
              x1="99.7323"
              y1="354.0785"
              x2="182.0909"
              y2="354.0785"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID16)"
              d="M168.98,343.98l-1.59,0c0,0-1.14,2.69-1.94,6.58c-0.58,2.83-1.49,8.19-4.51,10.91
				c-0.26,0.24-0.54,0.45-0.83,0.64c-0.98,0.64-2.15,1.03-3.56,1.03c-6.68,0-47.53-1.9-49.67-2.17c-2.14-0.27-2.67-1.54-2.85-5.61
				c-0.18-4.07-0.24-6.4-0.24-7.07c0-0.68,1.64-5.64,1.64-5.64l-2.39-0.04c0,0-2.19,4.59-2.65,5.64c-0.46,1.05-0.66,1.81-0.66,2.65
				c0,0.47,0,3.04,0,5.45c0.14,0.69,0.51,2.57,0.89,3.98c0.47,1.76,1.03,3.61,6.47,3.61s47.86,1.68,50.86,1.6
				c3-0.08,10.88-1.68,12.85-2.33c1.97-0.64,2.22-3.31,2.6-6.25c0.47-3.69,0.95-6.64,0.95-6.64s3.37-4.83,7.73-6.46L168.98,343.98z"
            />
            <linearGradient
              id="Card22MSVGID17"
              gradientUnits="userSpaceOnUse"
              x1="102.7396"
              y1="336.5654"
              x2="113.466"
              y2="336.5654"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID17)"
              d="M102.99,339.45l2.71,0.19c0.1,0.01,0.19-0.02,0.27-0.08l2.9-2.23c0.06-0.05,0.13-0.07,0.21-0.08
				l3.5-0.29c0.15-0.01,0.29-0.11,0.34-0.26l0.52-1.41c0.09-0.24-0.07-0.51-0.32-0.54l-9.07-1.24c0.06,0.04,0.09,0.11,0.09,0.18
				l-0.11,4.32c0,0.05-0.02,0.1-0.06,0.14l-1.17,1.18c-0.04,0.04-0.06,0.08-0.06,0.14l0,0.06
				C102.81,339.47,102.9,339.44,102.99,339.45z"
            />
            <linearGradient
              id="Card22MSVGID18"
              gradientUnits="userSpaceOnUse"
              x1="102.6339"
              y1="335.817"
              x2="119.4658"
              y2="335.817"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID18)"
              d="M119.27,333.69l-3.18-0.1c-0.02,0-0.04,0-0.06-0.01l-9.71-3.14c-0.09-0.03-0.14-0.11-0.14-0.2v-1
				c0-0.21-0.27-0.29-0.38-0.11l-0.93,1.52c-0.02,0.03-0.04,0.05-0.06,0.06l-0.94,0.64c0,0,0,0,0,0c0.01,0,0.03,0,0.04,0.01
				l10.73,2.55c0.23,0.06,0.37,0.31,0.28,0.54l-1.23,3.33c-0.06,0.16-0.21,0.26-0.37,0.26h-3.61c-0.1,0-0.19,0.04-0.26,0.1
				l-3.17,2.84c-0.07,0.07-0.17,0.1-0.26,0.1h-2.99c-0.15,0-0.28-0.08-0.35-0.21l-0.03,0.42c0,0.03,0,0.05,0.01,0.08l0.35,1.11
				c0.03,0.09,0.11,0.15,0.2,0.15h4.27c0.04,0,0.07-0.01,0.1-0.03l4.45-2.54c0.03-0.02,0.06-0.02,0.09-0.03l3-0.23
				c0.06,0,0.11-0.03,0.15-0.08l4.18-5.69C119.52,333.89,119.43,333.69,119.27,333.69z"
            />
            <linearGradient
              id="Card22MSVGID19"
              gradientUnits="userSpaceOnUse"
              x1="102.6595"
              y1="336.2092"
              x2="114.9352"
              y2="336.2092"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID19)"
              d="M103.01,341.07H106c0.1,0,0.19-0.04,0.26-0.1l3.17-2.84c0.07-0.07,0.17-0.1,0.26-0.1h3.61
				c0.17,0,0.31-0.1,0.37-0.26l1.23-3.33c0.08-0.23-0.05-0.48-0.28-0.54l-10.73-2.55c-0.01,0-0.03,0-0.04-0.01
				c-0.04,0.03-0.07,0.07-0.08,0.13l-0.35,1.57c-0.02,0.1,0.03,0.2,0.13,0.24l0.45,0.18c0.01,0.01,0.03,0.01,0.04,0.02l9.07,1.24
				c0.25,0.03,0.41,0.3,0.32,0.54l-0.52,1.41c-0.05,0.15-0.19,0.25-0.34,0.26l-3.5,0.29c-0.08,0.01-0.15,0.03-0.21,0.08l-2.9,2.23
				c-0.08,0.06-0.17,0.09-0.27,0.08l-2.71-0.19c-0.09-0.01-0.18,0.02-0.25,0.07l-0.08,1.34
				C102.73,340.98,102.86,341.07,103.01,341.07z"
            />
            <linearGradient
              id="Card22MSVGID20"
              gradientUnits="userSpaceOnUse"
              x1="103.7853"
              y1="352.8966"
              x2="160.9333"
              y2="352.8966"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID20)"
              d="M111.54,359.69c-3.77-0.26-4.16-2.71-4.94-4.16c-0.6-1.13-0.98-3.29-1.11-5
				c-0.04-0.5-0.06-0.95-0.06-1.34c0-1.72,0-6.54,0-6.54s-1.64,4.96-1.64,5.64c0,0.68,0.06,3,0.24,7.07
				c0.18,4.07,0.71,5.34,2.85,5.61c2.14,0.27,42.99,2.17,49.67,2.17c1.4,0,2.58-0.39,3.56-1.03c0.29-0.19,0.57-0.41,0.83-0.64
				C154.36,361.38,115.18,359.95,111.54,359.69z"
            />
            <linearGradient
              id="Card22MSVGID21"
              gradientUnits="userSpaceOnUse"
              x1="149.3678"
              y1="335.6935"
              x2="193.3743"
              y2="335.6935"
            >
              <stop offset="0.1719" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.2965" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.4706" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.4968" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID21)"
              d="M191.69,331.24c-3.57-2.34-9.84-3.78-19.54-3.83c-0.34,0-0.68,0.12-0.94,0.34
				c-0.66,0.56-1.98,1.7-2.82,2.67c-0.23,0.27-0.55,0.45-0.9,0.49c-2.46,0.34-12.28,1.63-16.77,2.8c-0.15,0.04-0.29,0.08-0.42,0.12
				c-0.73,0.25-1.2,0.62-0.77,1.34l2.81,4.49c0.27,0.45,0.76,0.72,1.27,0.71c0.34-0.01,0.75,0,1.18,0.02
				c0.23,0.01,0.46,0.03,0.69,0.05c0.29,0.01,0.5,0.04,0.64,0.08c0.38,0.06,0.74,0.15,1.01,0.28c1.3,0.59,4.68,3.17,7.53,3.17
				s17.79,0,19.74,0s4.29-1.85,6.04-4.69C192.21,336.45,195.32,333.61,191.69,331.24z M188.94,333.64
				c-16.51-1.11-33.09,1.18-35.84,1.58c-0.2,0.03-0.32,0.24-0.24,0.44l0.47,1.12c0.05,0.12,0.17,0.2,0.3,0.19
				c0.59-0.03,2.25-0.1,3.5-0.15c1.55-0.05,2.17,0.11,3.87,0.63c1.7,0.53,3.2,1.31,6.25,1.42c2.79,0.1,16.54-0.33,18.88-0.41
				c-1.53,2.03-3.66,4.03-5.24,4.03c-1.25,0-13.07,0-14.97,0s-5.84-2.74-7.19-3.2c-1.11-0.38-4.58-0.51-5.78-0.55
				c-0.25-0.01-0.49-0.15-0.61-0.38l-1.7-3.12c-0.23-0.42,0-0.95,0.46-1.07c1.84-0.46,6.5-1.5,14.26-2.45
				c10.33-1.27,20.81-0.66,22.66,0.41C188.8,332.58,189.04,333.06,188.94,333.64z"
            />
            <linearGradient
              id="Card22MSVGID22"
              gradientUnits="userSpaceOnUse"
              x1="150.5708"
              y1="336.76"
              x2="188.9625"
              y2="336.76"
            >
              <stop offset="0.1719" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.2963" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.4703" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.4964" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.5444" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID22)"
              d="M188.04,332.14c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
				c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
				c1.35,0.46,5.29,3.2,7.19,3.2s13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
				c-3.05-0.11-4.55-0.89-6.25-1.42c-1.7-0.53-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
				l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.44c2.75-0.41,19.33-2.69,35.84-1.58C189.04,333.06,188.8,332.59,188.04,332.14z"
            />
            <linearGradient
              id="Card22MSVGID23"
              gradientUnits="userSpaceOnUse"
              x1="134.9804"
              y1="333.6098"
              x2="134.9804"
              y2="348.6574"
            >
              <stop offset="0.6474" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7973" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.9049" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.9993" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID23)"
              d="M156,340.46c-0.21-0.03-0.42-0.06-0.64-0.08c-0.1,0-0.21-0.01-0.32-0.01
				c-0.12,0-0.24-0.01-0.36-0.04c-0.43-0.02-0.85-0.02-1.18-0.02c-0.52,0.01-1-0.26-1.27-0.71l-2.81-4.49
				c-0.43-0.72,0.04-1.09,0.77-1.34c-0.04-0.11-0.06-0.16-0.06-0.16h-30.59l-0.12,0.14c0.07,0.07,0.08,0.18,0.02,0.27l-4.18,5.69
				c-0.04,0.05-0.09,0.08-0.15,0.08l-0.33,0.02l-0.83,0.11c0,0-1.97,8.7,6.26,8.7c5.46,0,22.49,0.03,25.42,0.03s6.79-3,8.25-5.17
				C155.06,341.7,156.89,340.74,156,340.46z"
            />
            <linearGradient
              id="Card22MSVGID24"
              gradientUnits="userSpaceOnUse"
              x1="106.1611"
              y1="329.6875"
              x2="172.1511"
              y2="329.6875"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4317" style={{ stopColor: "#040404", stopOpacity: 0.1526 }} />
              <stop offset="0.6631" style={{ stopColor: "#0F0F0F", stopOpacity: 0.2996 }} />
              <stop offset="0.8212" style={{ stopColor: "#1C1C1B", stopOpacity: 0.4 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID24)"
              d="M150.3,333.84c0.13-0.04,0.27-0.09,0.42-0.12c4.49-1.17,14.31-2.46,16.77-2.8
				c0.35-0.05,0.67-0.22,0.9-0.49c0.84-0.97,2.15-2.11,2.82-2.67c0.27-0.22,0.6-0.35,0.94-0.34c0,0-16.93-2.42-39.54-2.42
				s-26.45,1.71-26.45,3.1c0,0.4,0,0.76,0,1.07c0.01,0.02,0.02,0.05,0.02,0.08v1c0,0.09,0.06,0.17,0.14,0.2l9.71,3.14
				c0.02,0.01,0.04,0.01,0.06,0.01l3.18,0.1c0.06,0,0.11,0.03,0.14,0.06c0.07,0.07,0.08,0.18,0.02,0.27l-0.27,0.37l30.37-0.11
				C149.68,334.1,149.96,333.96,150.3,333.84z"
            />
            <linearGradient
              id="Card22MSVGID25"
              gradientUnits="userSpaceOnUse"
              x1="122.4427"
              y1="301.4578"
              x2="199.5503"
              y2="301.4578"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6704" style={{ stopColor: "#020202", stopOpacity: 0.1742 }} />
              <stop offset="0.8349" style={{ stopColor: "#090908", stopOpacity: 0.237 }} />
              <stop offset="0.9517" style={{ stopColor: "#141414", stopOpacity: 0.2816 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID25)"
              d="M183.44,299.56c0,0,6.81-3.4,16.11-3.52c-3.68,0.03-25.72,0.28-42.26,1.76
				c-18.1,1.62-30.52,3.95-32.6,4.87c-1.64,0.72-2.12,1.8-2.25,2.22c0.22-0.29,0.47-0.55,0.76-0.76c1.28-0.91,5.6-1.45,6.04-1.51
				c0.02,0,0.05,0,0.07,0l1.89,0.33c0.02,0,0.05,0,0.07,0c9.67-1.2,27.79-0.71,28.73-0.68c0.03,0,0.05,0,0.08-0.01
				c0.29-0.09,2.21-0.67,2.46-0.61c0.27,0.07,6.07,0.07,10.93,0.89c4.31,0.73,9.1,3.41,10.12,4c0.11,0.07,0.16,0.21,0.1,0.33
				C183.69,306.87,188.59,299.48,183.44,299.56z"
            />
            <g>
              <linearGradient
                id="Card22MSVGID26"
                gradientUnits="userSpaceOnUse"
                x1="106.8056"
                y1="319.6189"
                x2="181.2598"
                y2="319.6189"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0843" style={{ stopColor: "#161615", stopOpacity: 0.0617 }} />
                <stop offset="0.202" stopColor="#030303" stopOpacity="8.066498e-03" />
                <stop offset="0.2198" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.256" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.8928" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID26)"
                d="M180.21,311.86c0.22,0.3,0.32,0.49,0.32,0.49s-2.51,4.63-4.64,7.51
					c-2.12,2.88-13.12,2.88-19.57,3.2c-6.44,0.32-22.39,0.32-22.39,0.32s-17.3,0.09-20.38-0.1c-3.08-0.19-1.41-1.51-0.8-2.17
					c0.61-0.66,2.13-2.32,7.27-8.02c0.13-0.14,0.26-0.28,0.39-0.42l-0.51,0.01c0,0-4.82,5.32-9.23,10.12
					c-2.15,2.34-3.28,3.66-3.86,4.39c1.78-1.14,7.91-2.21,25.82-2.21c21.63,0,38.06,2.22,39.44,2.41c0.77-1.17,2.66-4.04,3.86-6.15
					c1.54-2.71,5.34-9.39,5.34-9.39L180.21,311.86z"
              />
            </g>
            <linearGradient
              id="Card22MSVGID27"
              gradientUnits="userSpaceOnUse"
              x1="177.7291"
              y1="360.7868"
              x2="325.6555"
              y2="360.7868"
            >
              <stop offset="0" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.2017" style={{ stopColor: "#161819", stopOpacity: 0.0631 }} />
              <stop offset="0.4078" style={{ stopColor: "#0B0C0D", stopOpacity: 0.0253 }} />
              <stop offset="0.5458" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6405" style={{ stopColor: "#0A0A09", stopOpacity: 0.0208 }} />
              <stop offset="0.8316" style={{ stopColor: "#171716", stopOpacity: 0.0629 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID27)"
              d="M177.73,372.92l19.49,2.35c0,0,7.27-27.89,25.33-25.37c18.06,2.52,14.6,26.8,14.6,26.42
				c0-0.38,45.16-2.14,62.98-2.64c0,0,4.08-27.93,16.21-25.54s7.67,23.53,7.67,23.53l1.24-0.13c0,0,3.34-24.14-9.03-26.17
				c-11.66-1.92-14.83,17.04-17.35,20.92c-0.87,1.34-0.96,1.76-3.37,1.65c-2.25-0.11-5.95-0.07-5.95-0.07
				c-1.49,0-47.04-1.47-48.82-1.71c-1.78-0.24-1.52-0.46-1.82-2.48c-0.3-2.01-0.69-15.65-15.46-17.97
				c-14.78-2.32-22.93,12.39-24.98,15.49s-5.26,2.01-6.02,2.01c-0.76,0-13.18-1.55-13.18-1.55L177.73,372.92z"
            />
            <linearGradient
              id="Card22MSVGID28"
              gradientUnits="userSpaceOnUse"
              x1="269.8778"
              y1="347.1611"
              x2="269.8778"
              y2="364.348"
            >
              <stop offset="1.784581e-04" style={{ stopColor: "#F6F6F6", stopOpacity: 0.9026 }} />
              <stop offset="0.1228" style={{ stopColor: "#F2F2F2", stopOpacity: 0.8041 }} />
              <stop offset="0.2576" style={{ stopColor: "#E4E4E4", stopOpacity: 0.696 }} />
              <stop offset="0.398" style={{ stopColor: "#CFCFCE", stopOpacity: 0.5832 }} />
              <stop offset="0.5424" style={{ stopColor: "#B0B0B0", stopOpacity: 0.4673 }} />
              <stop offset="0.6899" style={{ stopColor: "#888888", stopOpacity: 0.3489 }} />
              <stop offset="0.8399" style={{ stopColor: "#585858", stopOpacity: 0.2285 }} />
              <stop offset="0.9894" style={{ stopColor: "#20201F", stopOpacity: 0.1085 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID28)"
              d="M240.31,364.35c0,0,5.69-3.33,24.75-3.58c19.06-0.26,35.39,1.51,35.39,1.51l5.36-11.84
				c0,0-50.65-2.73-59.56-2.99c-2.76-0.08-13.81-0.94-12.13,0.77C241.79,356.02,240.31,364.35,240.31,364.35z"
            />
            <linearGradient
              id="Card22MSVGID29"
              gradientUnits="userSpaceOnUse"
              x1="201.6333"
              y1="330.7884"
              x2="196.6087"
              y2="339.4912"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID29)"
              d="M193.1,332.7c0,0,6.57,0.32,12.94,0.63l0.58,1.69c-1.64,0.14-3.41,0.2-4.68,0.36
				c-4.94,0.62-9.48,1.46-9.83,1.52C191.98,336.91,194.07,334.41,193.1,332.7"
            />
            <g>
              <linearGradient
                id="Card22MSVGID30"
                gradientUnits="userSpaceOnUse"
                x1="227.5067"
                y1="336.7276"
                x2="234.3735"
                y2="336.7276"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID30)"
                d="M232.07,335.67l2.3-0.14c0,0-0.72,1.89-2.51,2.24c-1.74,0.34-2.87,0.11-4.36-0.79
					c0,0,0.07-0.03,0.3-0.26c0.23-0.23,0,0,0,0s0.45,0.48,1.76,0.46C230.94,337.16,232.07,336.82,232.07,335.67L232.07,335.67"
              />
              <linearGradient
                id="Card22MSVGID31"
                gradientUnits="userSpaceOnUse"
                x1="225.1009"
                y1="333.2605"
                x2="233.3265"
                y2="333.2605"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID31)"
                d="M233.28,334.04c0,0-7.46-1.69-7.7,0l-0.46-0.05c0,0-0.28-1.39,1.63-1.51
					S233.9,333.84,233.28,334.04z"
              />
              <linearGradient
                id="Card22MSVGID32"
                gradientUnits="userSpaceOnUse"
                x1="225.5271"
                y1="335.6888"
                x2="234.3735"
                y2="335.6888"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0795" style={{ stopColor: "#222222", stopOpacity: 0.1786 }} />
                <stop offset="0.1909" style={{ stopColor: "#353534", stopOpacity: 0.2887 }} />
                <stop offset="0.3212" style={{ stopColor: "#525252", stopOpacity: 0.4174 }} />
                <stop offset="0.4658" style={{ stopColor: "#7C7C7B", stopOpacity: 0.5603 }} />
                <stop offset="0.6222" style={{ stopColor: "#B1B1B1", stopOpacity: 0.7148 }} />
                <stop offset="0.7862" style={{ stopColor: "#F1F1F1", stopOpacity: 0.8769 }} />
                <stop offset="0.7903" style={{ stopColor: "#F3F3F3", stopOpacity: 0.881 }} />
                <stop offset="0.8928" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID32)"
                d="M226.33,334.4c0,0,1.11,1.07,3.4,1.13c2.29,0.06,4.64,0,4.64,0s-4.45,0.19-5.51,0.57
					s-1.36,0.88-1.36,0.88s-1.61-1.01-1.98-2.45L226.33,334.4z"
              />
            </g>
            <linearGradient
              id="Card22MSVGID33"
              gradientUnits="userSpaceOnUse"
              x1="280.0074"
              y1="356.3039"
              x2="259.8927"
              y2="321.4642"
            >
              <stop offset="0.0127" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.2536" style={{ stopColor: "#818283", stopOpacity: 0.4996 }} />
              <stop offset="0.5553" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.6818" style={{ stopColor: "#B7B7B6", stopOpacity: 0.7439 }} />
              <stop offset="0.8367" style={{ stopColor: "#646463", stopOpacity: 0.4305 }} />
              <stop offset="0.9465" style={{ stopColor: "#30302F", stopOpacity: 0.2083 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID33)"
              d="M235.68,335.44l68.54,6.88c0,0-5.34,1.81-15.84,1.09c-10.5-0.72-34.36-2.9-39.35-3.62
				C244.05,339.07,235.68,335.44,235.68,335.44z"
            />
            <g>
              <linearGradient
                id="Card22MSVGID34"
                gradientUnits="userSpaceOnUse"
                x1="265.5524"
                y1="340.5244"
                x2="271.0797"
                y2="340.5244"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID34)"
                d="M269.23,339.31l1.85-0.16c0,0-0.58,2.18-2.02,2.59c-1.4,0.4-2.31,0.13-3.51-0.92
					c0,0,0.06-0.04,0.24-0.3c0.18-0.26,0,0,0,0s0.36,0.55,1.42,0.53C268.32,341.02,269.23,340.63,269.23,339.31L269.23,339.31"
              />
              <linearGradient
                id="Card22MSVGID35"
                gradientUnits="userSpaceOnUse"
                x1="263.6158"
                y1="336.5208"
                x2="270.237"
                y2="336.5208"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID35)"
                d="M270.2,337.42c0,0-6.01-1.95-6.2,0l-0.37-0.05c0,0-0.23-1.6,1.31-1.74
					C266.48,335.49,270.7,337.2,270.2,337.42z"
              />
              <linearGradient
                id="Card22MSVGID36"
                gradientUnits="userSpaceOnUse"
                x1="263.9589"
                y1="339.3249"
                x2="271.0797"
                y2="339.3249"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0795" style={{ stopColor: "#222222", stopOpacity: 0.1786 }} />
                <stop offset="0.1909" style={{ stopColor: "#353534", stopOpacity: 0.2887 }} />
                <stop offset="0.3212" style={{ stopColor: "#525252", stopOpacity: 0.4174 }} />
                <stop offset="0.4658" style={{ stopColor: "#7C7C7B", stopOpacity: 0.5603 }} />
                <stop offset="0.6222" style={{ stopColor: "#B1B1B1", stopOpacity: 0.7148 }} />
                <stop offset="0.7862" style={{ stopColor: "#F1F1F1", stopOpacity: 0.8769 }} />
                <stop offset="0.7903" style={{ stopColor: "#F3F3F3", stopOpacity: 0.881 }} />
                <stop offset="0.8928" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22MSVGID36)"
                d="M264.61,337.84c0,0,0.9,1.23,2.74,1.31c1.84,0.07,3.73,0,3.73,0s-3.59,0.22-4.43,0.65
					c-0.85,0.44-1.1,1.02-1.1,1.02s-1.29-1.16-1.59-2.83L264.61,337.84z"
              />
            </g>
            <linearGradient
              id="Card22MSVGID37"
              gradientUnits="userSpaceOnUse"
              x1="240.9316"
              y1="316.7855"
              x2="258.8798"
              y2="316.7855"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <polygon fill="url(#Card22MSVGID37)" points="240.93,302.77 253.27,330.16 258.88,330.8 245.52,303.24 			" />
            <linearGradient
              id="Card22MSVGID38"
              gradientUnits="userSpaceOnUse"
              x1="182.4527"
              y1="364.6808"
              x2="194.5297"
              y2="343.7628"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2364" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2393" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.504" style={{ stopColor: "#030303", stopOpacity: 0.1275 }} />
              <stop offset="0.6941" style={{ stopColor: "#0C0C0C", stopOpacity: 0.2191 }} />
              <stop offset="0.8608" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2994 }} />
              <stop offset="0.862" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.9081" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9726" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID38)"
              d="M181.8,343.97c-4.21,1.73-7.43,6.34-7.43,6.34c5.79,0.36,5.27,4.53,4.97,9.93
				c-0.04,0.72-0.12,1.59-0.22,2.53l0.15-1.1c0,0,12.41,1.55,13.18,1.55c0.76,0,3.96,1.08,6.02-2.01c1.08-1.63,3.84-6.45,8.35-10.33
				c-2.65-0.71-8.64-2.33-12.83-3.65c-3.48-1.1-5.55-3.1-6.6-4.43c-1.01,0.76-2.03,1.19-2.97,1.19
				C184.02,343.97,183.08,343.97,181.8,343.97z"
            />
            <path
              fill="#5E5D5B"
              d="M299.26,355.21c0,0-0.15,2.73-0.38,5.26s-0.98,6.13-1.08,6.63c-0.09,0.51,0.18,0.69,0.82-0.09
				c0.63-0.78,2.58-5.43,3.4-7.41c0.82-1.98,1.36-3.59,1.63-4.33c0.27-0.74-0.18-0.82-1.04-0.83c-0.68-0.01-1.37-0.03-2.5-0.01
				C299.67,354.44,299.26,354.49,299.26,355.21z"
            />
            <path
              fill="#4A4A4B"
              d="M299.26,354.91c0,0-0.98,2.32-1.22,5.64c-0.24,3.32-0.18,6.7-0.18,6.7s1.1-4.77,1.31-7.51
				S299.26,354.91,299.26,354.91z"
            />
            <path
              fill="#FFFFFF"
              d="M176.41,296.92c0,0-2.24-3.51-8.17-3.57l-1.09,2.4c0,0-0.81,0.7-0.81,1.58
				C166.34,297.33,172.21,298.5,176.41,296.92z"
            />
            <linearGradient
              id="Card22MSVGID39"
              gradientUnits="userSpaceOnUse"
              x1="171.3744"
              y1="293.3562"
              x2="171.3744"
              y2="297.7508"
            >
              <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              <stop offset="0.1379" style={{ stopColor: "#151514", stopOpacity: 0.0837 }} />
              <stop offset="0.532" style={{ stopColor: "#050505", stopOpacity: 0.0372 }} />
              <stop offset="0.847" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.9784" style={{ stopColor: "#000000", stopOpacity: 0.1717 }} />
              <stop offset="1" style={{ stopColor: "#000000", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID39)"
              d="M176.41,296.92c0,0-2.24-3.51-8.17-3.57l-1.09,2.4c0,0-0.81,0.7-0.81,1.58
				C166.34,297.33,172.21,298.5,176.41,296.92z"
            />
            <linearGradient
              id="Card22MSVGID40"
              gradientUnits="userSpaceOnUse"
              x1="278.7832"
              y1="327.6346"
              x2="286.83"
              y2="327.6346"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID40)"
              d="M278.78,321.78l4.87,11.69c0,0,1.78,0.27,2.96-0.87S283.92,327.27,278.78,321.78z"
            />
            <linearGradient
              id="Card22MSVGID41"
              gradientUnits="userSpaceOnUse"
              x1="285.4335"
              y1="326.1583"
              x2="293.0381"
              y2="326.1583"
            >
              <stop offset="0" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.0143" style={{ stopColor: "#393A3C", stopOpacity: 0.1258 }} />
              <stop offset="0.0364" style={{ stopColor: "#626364", stopOpacity: 0.1657 }} />
              <stop offset="0.0612" style={{ stopColor: "#888989", stopOpacity: 0.2106 }} />
              <stop offset="0.0883" style={{ stopColor: "#A8A9A9", stopOpacity: 0.2595 }} />
              <stop offset="0.1182" style={{ stopColor: "#C4C4C4", stopOpacity: 0.3135 }} />
              <stop offset="0.152" style={{ stopColor: "#DADADA", stopOpacity: 0.3745 }} />
              <stop offset="0.1914" style={{ stopColor: "#EAEBEB", stopOpacity: 0.4456 }} />
              <stop offset="0.2402" style={{ stopColor: "#F6F6F6", stopOpacity: 0.5337 }} />
              <stop offset="0.3085" style={{ stopColor: "#FDFDFD", stopOpacity: 0.6572 }} />
              <stop offset="0.4984" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.6895" style={{ stopColor: "#FDFDFD", stopOpacity: 0.657 }} />
              <stop offset="0.7584" style={{ stopColor: "#F6F6F6", stopOpacity: 0.5334 }} />
              <stop offset="0.8075" style={{ stopColor: "#EBEBEA", stopOpacity: 0.4453 }} />
              <stop offset="0.8472" style={{ stopColor: "#DADADA", stopOpacity: 0.3741 }} />
              <stop offset="0.8812" style={{ stopColor: "#C4C4C4", stopOpacity: 0.3131 }} />
              <stop offset="0.9114" style={{ stopColor: "#A9A9A8", stopOpacity: 0.259 }} />
              <stop offset="0.9386" style={{ stopColor: "#888888", stopOpacity: 0.2101 }} />
              <stop offset="0.9637" style={{ stopColor: "#636362", stopOpacity: 0.1651 }} />
              <stop offset="0.986" style={{ stopColor: "#3A3A39", stopOpacity: 0.1252 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID41)"
              d="M285.45,323.27c-0.17-0.53,1.27-0.6,1.81-0.51c0.29,0.05,0.65,0.14,1.06,0.31
				c1.13,0.45,2.29,1.12,2.75,1.4c0.14,0.08,0.25,0.21,0.31,0.37l1.64,4.32c0.2,0.5-1.95,0.43-2.48,0.41l-1.98-0.79
				c-0.31-0.01-0.57-0.22-0.67-0.52L285.45,323.27z"
            />
            <path
              fill="#191C1C"
              d="M283.52,329.72h1.19c0,0,0-4.55,0-5.22c0-0.67,0.4-2.54,3.09-1.14s2.96,4.02,3.03,5.62H293
				c0,0,0.13,2.75-0.99,2.75c-1.12,0-1.98-0.13-2.83,0.54c-0.86,0.67-1.65,0.33-2.57,0.33s-1.12,0.87-2.96,0.87L283.52,329.72z"
            />
            <path
              opacity="0.63"
              fill="#A1C8D9"
              d="M286.17,324.36c0-0.32,0.3-0.55,0.61-0.45c1.05,0.34,3.29,1.51,3.29,5.58
				c0,1.44-2.45,2.2-3.46,2.06c-0.23-0.03-0.4-0.23-0.4-0.47L286.17,324.36z"
            />
            <linearGradient
              id="Card22MSVGID42"
              gradientUnits="userSpaceOnUse"
              x1="286.1685"
              y1="327.7245"
              x2="290.0594"
              y2="327.7245"
            >
              <stop offset="0.1909" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.9838" style={{ stopColor: "#FFFFFF", stopOpacity: 0.02 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID42)"
              d="M286.17,324.36c0-0.32,0.3-0.55,0.61-0.45c1.05,0.34,3.29,1.51,3.29,5.58
				c0,1.44-2.45,2.2-3.46,2.06c-0.23-0.03-0.4-0.23-0.4-0.47L286.17,324.36z"
            />
            <path
              fill="#FFFFFF"
              d="M284.73,325.66c0,0,0,3.29,0,3.71s-0.39,0.5-1.1,0.5v-0.19c0,0,0.95,0.08,0.95-0.7
				c0-1.12,0.17-3.31,0-3.14"
            />
            <linearGradient
              id="Card22MSVGID43"
              gradientUnits="userSpaceOnUse"
              x1="143.7816"
              y1="380.1434"
              x2="151.7198"
              y2="380.1434"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID43)"
              d="M151.72,383.26v1.14l-6.82-1.23l-1.12-7.28C143.78,375.89,144.39,382.12,151.72,383.26z"
            />
            <linearGradient
              id="Card22MSVGID44"
              gradientUnits="userSpaceOnUse"
              x1="107.0602"
              y1="372.8269"
              x2="107.0602"
              y2="380.503"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID44)"
              d="M103.51,372.83c0,0-0.98,2.1-1.27,3.19c-0.28,1.1,0.42,1,1.27,1.14
				c0.84,0.14,2.16,2.34,5.53,3.19l2.91,0.14c0,0-0.89-4.91-0.98-5.67s-0.28-1.43,0.05-1.48
				C111.01,373.35,105.61,372.87,103.51,372.83z"
            />
            <linearGradient
              id="Card22MSVGID45"
              gradientUnits="userSpaceOnUse"
              x1="149.3681"
              y1="384.6148"
              x2="149.5357"
              y2="372.9444"
            >
              <stop offset="0.6685" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7309" style={{ stopColor: "#030303", stopOpacity: 0.085 }} />
              <stop offset="0.7757" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1461 }} />
              <stop offset="0.815" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1996 }} />
              <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID45)"
              d="M143.69,375.29c0,0,9.05,0.06,11.61,0.23l-0.51,0.97l-11.01-0.6L143.69,375.29z"
            />
            <linearGradient
              id="Card22MSVGID46"
              gradientUnits="userSpaceOnUse"
              x1="303.2254"
              y1="373.0612"
              x2="322.8182"
              y2="373.0612"
            >
              <stop offset="0.4595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6657" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
              <stop offset="0.8643" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID46)"
              d="M304.44,374.64c-0.22-3.62-0.65-6.72-1.22-9.33c0,0,3.24-13.38,11.38-11.61
				c9.39,2.04,8.44,21.75,7.89,24.81c-0.69,3.87-2.53,13.29-9.22,14.05C306.59,393.31,304.69,378.76,304.44,374.64z"
            />
            <linearGradient
              id="Card22MSVGID47"
              gradientUnits="userSpaceOnUse"
              x1="194.1525"
              y1="351.336"
              x2="172.3765"
              y2="354.3971"
            >
              <stop offset="0.5619" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7054" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1561 }} />
              <stop offset="0.8377" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.8853" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9519" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.9619" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID47)"
              d="M178.53,352.24c0,0,4.31-6.46,5.89-8.28c1.58-1.82-2.46,13.12-2.66,17.97l-2.49-0.28
				C179.28,361.66,180.75,357.09,178.53,352.24z"
            />
            <path
              fill="#FFFFFF"
              d="M218,325.06c0.05-0.02,30.79,0.2,30.79,0.2l1.9,4.65c0,0-23.46-2.42-26.78-3.1s-5.92-1.29-5.92-1.29
				L218,325.06z"
            />
            <linearGradient
              id="Card22MSVGID48"
              gradientUnits="userSpaceOnUse"
              x1="229.4338"
              y1="318.4607"
              x2="239.7072"
              y2="336.2546"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="5.137858e-03" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0133" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0225" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.0325" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.0435" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.056" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.0705" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.0885" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.1138" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.1838" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.8846" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9343 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.94 }} />
              <stop offset="1" style={{ stopColor: "#282827", stopOpacity: 0.9457 }} />
              <stop offset="1" style={{ stopColor: "#464645", stopOpacity: 0.9559 }} />
              <stop offset="1" style={{ stopColor: "#787877", stopOpacity: 0.9694 }} />
              <stop offset="1" style={{ stopColor: "#BCBCBC", stopOpacity: 0.9855 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID48)"
              d="M218,325.06c0.05-0.02,30.83,0.09,30.83,0.09l1.87,4.76c0,0-22.11-1.7-27.08-3.03
				c-3.28-0.88-5.63-1.36-5.63-1.36L218,325.06z"
            />
            <g>
              <g>
                <path
                  fill="#5E5D5B"
                  d="M125.03,341.36c0.14-0.68,0.28-1.36,0.42-2.03c0.02-0.1,0.12-0.21-0.01-0.27
						c-0.14-0.06-0.2,0.07-0.25,0.18c-0.08,0.19-0.15,0.38-0.23,0.57c-0.04,0.08-0.1,0.16-0.2,0.15c-0.11-0.02-0.1-0.13-0.11-0.21
						c0-0.15,0.01-0.3-0.01-0.44c-0.01-0.1,0.05-0.27-0.13-0.27c-0.14,0-0.17,0.13-0.19,0.26c-0.11,0.61-0.17,1.22-0.34,1.82
						c-0.06,0.21-0.12,0.34-0.35,0.28c-0.23-0.06-0.12-0.22-0.09-0.36c0.19-1,0.39-1.99,0.58-2.99c0.08-0.3,0.08-0.61,0.17-0.91l0,0
						c0.28-0.15,0.51-0.01,0.52,0.17c0.05,0.37,0.07,0.75,0.1,1.13c0.02,0.22,0.08,0.32,0.28,0.12l0,0.01
						c0.19-0.34,0.23-0.54,0.36-0.8c0,0,0,0,0,0c0.13-0.24,0.16-0.39,0.28-0.59c0.07-0.1,0.19-0.15,0.32-0.11
						c0.14,0.05,0.16,0.12,0.13,0.24c-0.25,1.28-0.5,2.56-0.75,3.85C125.48,341.5,125.24,341.39,125.03,341.36z"
                />
                <path
                  fill="#5E5D5B"
                  d="M141.22,341.65c-0.35,0.02-0.28-0.19-0.21-0.48c0.26-1.21,0.57-2.41,0.71-3.64
						c0.03-0.28,0.16-0.42,0.46-0.4c0.41,0.02,0.73,0,1.14,0.02c0.13,0.01,0.48-0.05,0.45,0.24c-0.02,0.21-0.05,0.41-0.38,0.39
						c-0.28-0.01-0.57,0.03-0.84-0.02c-0.33-0.06-0.34,0.27-0.4,0.71c-0.01,0.11,0.06,0.21,0.17,0.22c0.28,0.02,0.74-0.14,0.68,0.29
						c-0.07,0.4-0.27,0.31-0.5,0.32c-0.64,0-0.53,0.02-0.63,0.65c-0.16,0.97-0.32,0.99,0.69,0.99c0.28,0,0.64-0.11,0.55,0.41
						c-0.04,0.25-0.18,0.31-0.4,0.31c-0.24,0-0.47,0-0.71,0C142,341.63,141.44,341.63,141.22,341.65z"
                />
                <path
                  fill="#5E5D5B"
                  d="M136.91,341.56c-0.07,0-0.15,0.02-0.22,0c-0.35-0.01-0.6,0.06-0.69-0.25c-0.09-0.3-0.01-0.55,0.2-0.82
						c0.1-0.13,0.27-0.33,0.4-0.43c0.43-0.52,0.78-0.82,1.15-1.26c0.14-0.12,0.22-0.36,0.24-0.56c0.03-0.21,0.03-0.41-0.21-0.46
						c-0.23-0.05-0.5-0.03-0.59,0.22c-0.03,0.08-0.04,0.17-0.08,0.25c-0.05,0.11-0.16,0.12-0.27,0.11
						c-0.13-0.01-0.16-0.11-0.16-0.22c0.01-0.38,0.07-0.76,0.43-0.96c0.38-0.21,0.91-0.22,1.19,0c0.28,0.17,0.27,0.51,0.27,0.76
						c-0.02,0.85-0.46,1.42-1.04,2c-0.22,0.22-0.45,0.42-0.67,0.64c-0.06,0.06-0.15,0.19-0.12,0.29c0.04,0.11,0.15,0.07,0.25,0.07
						c0.21-0.01,0.41-0.03,0.62-0.03c0.15,0,0.33-0.01,0.32,0.2c-0.01,0.18-0.03,0.41-0.29,0.43
						C137.42,341.58,137.16,341.56,136.91,341.56C136.91,341.56,136.91,341.56,136.91,341.56z"
                />
                <path
                  fill="#5E5D5B"
                  d="M139.3,341.56c-0.07,0-0.15,0.02-0.22,0c-0.35-0.01-0.6,0.06-0.69-0.25c-0.09-0.3-0.01-0.55,0.2-0.82
						c0.1-0.13,0.27-0.33,0.4-0.43c0.43-0.52,0.78-0.82,1.15-1.26c0.14-0.12,0.22-0.36,0.24-0.56c0.03-0.21,0.03-0.41-0.21-0.46
						c-0.23-0.05-0.5-0.03-0.59,0.22c-0.03,0.08-0.04,0.17-0.08,0.25c-0.05,0.11-0.16,0.12-0.27,0.11
						c-0.13-0.01-0.16-0.11-0.16-0.22c0.01-0.38,0.07-0.76,0.43-0.96c0.38-0.21,0.91-0.22,1.19,0c0.28,0.17,0.27,0.51,0.27,0.76
						c-0.02,0.85-0.46,1.42-1.04,2c-0.22,0.22-0.45,0.42-0.67,0.64c-0.06,0.06-0.15,0.19-0.12,0.29c0.04,0.11,0.15,0.07,0.25,0.07
						c0.21-0.01,0.41-0.03,0.62-0.03c0.15,0,0.33-0.01,0.32,0.2c-0.01,0.18-0.03,0.41-0.29,0.43
						C139.8,341.58,139.55,341.56,139.3,341.56C139.3,341.56,139.3,341.56,139.3,341.56z"
                />
                <path
                  fill="#5E5D5B"
                  d="M132.34,337.02c0.2,0.06,0.51-0.05,0.59,0.31c0.07,0.36-0.03,0.64-0.2,0.96
						c-0.05,0.09-0.19,0.07-0.29,0.05c-0.13-0.04-0.13-0.14-0.11-0.25c0.06-0.23,0.13-0.46-0.22-0.49c-0.3-0.03-0.52,0.05-0.59,0.39
						c-0.15,0.74-0.31,1.47-0.45,2.21c-0.05,0.24,0.03,0.46,0.29,0.53c0.3,0.08,0.57-0.03,0.71-0.32c0.11-0.22,0.17-0.58,0.49-0.39
						c0.25,0.15,0.07,0.46-0.04,0.67c-0.2,0.41-0.47,0.75-0.98,0.79c-0.7,0.06-1.08-0.32-0.96-1.01c0.11-0.7,0.26-1.39,0.4-2.08
						c0.02-0.1,0.06-0.2,0.07-0.3C131.18,337.24,131.44,337.02,132.34,337.02z"
                />
                <path
                  fill="#5E5D5B"
                  d="M129.28,340.04c-0.03-0.21-0.18-0.01-0.23,0.09c-0.18,0.37-0.37,0.73-0.56,1.09
						c-0.08,0.15-0.18,0.3-0.37,0.2c-0.22-0.11-0.07-0.27,0-0.41c0.26-0.56,0.56-1.03,0.81-1.65c0.14-0.26,0.15-0.4,0.12-0.61
						c-0.05-0.45-0.12-0.91-0.18-1.36c-0.03-0.19-0.01-0.37,0.22-0.4c0.24-0.03,0.3,0.14,0.32,0.33c0.02,0.25,0.03,0.5,0.04,0.75
						c0,0.09,0,0.19,0.12,0.21c0.1,0.02,0.17-0.05,0.21-0.14c0.14-0.29,0.27-0.59,0.41-0.88c0.08-0.16,0.2-0.35,0.4-0.24
						c0.22,0.12,0.08,0.29-0.01,0.45c-0.22,0.4-0.4,0.82-0.63,1.21c-0.2,0.33-0.19,0.67-0.15,1.03c0.06,0.47,0.13,0.94,0.18,1.41
						c0.02,0.16,0.03,0.34-0.21,0.35c-0.22,0.01-0.31-0.09-0.33-0.32C129.41,340.78,129.37,340.52,129.28,340.04z"
                />
                <path
                  fill="#5E5D5B"
                  d="M134.05,341.21c0.06-0.75,0.39-1.38,0.79-1.99c0.22-0.33,0.42-0.68,0.61-1.02
						c0.09-0.15,0.32-0.54-0.01-0.56c-0.42-0.01-0.53-0.03-0.64,0.3c-0.02,0.07-0.02,0.15-0.04,0.22c-0.06,0.17-0.2,0.15-0.33,0.13
						c-0.16-0.02-0.18-0.15-0.15-0.27c0.07-0.23,0.12-0.45,0.14-0.69c0.02-0.22,0.18-0.29,0.38-0.29c0.43,0,0.86,0,1.28,0.01
						c0.25,0,0.32,0.12,0.28,0.37c-0.08,0.66-0.52,1.14-0.82,1.69c-0.38,0.69-0.94,1.29-0.97,2.14c-0.01,0.19-0.17,0.27-0.35,0.24
						C134.05,341.48,134.04,341.35,134.05,341.21z"
                />
                <g>
                  <g>
                    <path
                      fill="#1EBEE4"
                      d="M124.15,336.21c-0.59,0-1.07,0-1.4,0c-0.32,0-0.6,0.23-0.67,0.54l-1.13,5.05
								c-0.1,0.43,0.23,0.84,0.67,0.84l0.97,0.01l0.25-0.07L124.15,336.21z"
                    />
                    <path
                      fill="#5E5D5B"
                      d="M122.42,342.54l-0.3-0.01l21.1,0.09c0.33,0,0.62-0.15,0.69-0.36c0,0-0.29,0.65-1.36,0.65
								s-18.92,0-20.03,0c-1.58,0-1.44-0.45-1.44-0.45l1.26,0.07l0.19,0.02"
                    />
                  </g>

                  <ellipse
                    transform="matrix(0.0978 -0.9952 0.9952 0.0978 -224.6756 433.6377)"
                    fill="#5E5D5B"
                    cx="126.84"
                    cy="340.74"
                    rx="1.14"
                    ry="0.74"
                  />

                  <ellipse
                    transform="matrix(0.1278 -0.9918 0.9918 0.1278 -224.0538 420.951)"
                    fill="#D25959"
                    cx="127.31"
                    cy="337.86"
                    rx="1.02"
                    ry="0.66"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#5E5D5B"
              d="M205.47,331.37c0,0,0.24,0.8,0.57,1.96c0.33,1.16,1.24,4.07,1.58,4.81s1.16,1.49,2.65,1.49
				s6.21-0.17,7.3-0.17c1.31,0,1.18-1.9,0.18-4.23c-1-2.32-1.42-4.18-4.66-4.48c-2.74-0.26-5.35-0.36-6.34-0.36
				S205.27,330.53,205.47,331.37z"
            />
            <path
              fill="#FFFFFF"
              d="M218.31,332.2c0.42,1.14,1.12,3.05,1.6,4.45c0.25,0.72,1.07,1.05,1.75,0.7l7.39-3.86
				c0.53-0.27,0.78-0.88,0.62-1.45c0,0-0.36-2.47-1.3-4.46c-0.58-1.24-1.65-1.41-2.34-1.02l-7.16,4.12
				C218.33,330.99,218.1,331.63,218.31,332.2z"
            />
            <linearGradient
              id="Card22MSVGID49"
              gradientUnits="userSpaceOnUse"
              x1="218.2335"
              y1="331.9413"
              x2="229.7188"
              y2="331.9413"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0382 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.0749 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID49)"
              d="M218.31,332.2c0.42,1.14,1.12,3.05,1.6,4.45c0.25,0.72,1.07,1.05,1.75,0.7l7.39-3.86
				c0.53-0.27,0.78-0.88,0.62-1.45c0,0-0.36-2.47-1.3-4.46c-0.58-1.24-1.65-1.41-2.34-1.02l-7.16,4.12
				C218.33,330.99,218.1,331.63,218.31,332.2z"
            />
            <path
              fill="#C7C9C6"
              d="M217.04,333.76l2.35-0.97c0,0,0-0.66,1.21-1.21c1.21-0.54,4.95-2.84,5.8-3.2s2.41,2.41,1.69,3.86
				l-7.06,3.92l-0.97-1.27l-2.35,0.97L217.04,333.76z"
            />
            <path
              fill="#191C1C"
              d="M208.06,334.05c0,0-0.32-0.77-0.61-1.83s3.05-0.67,4.78-0.58c1.73,0.1,2.05,0.55,2.31,1.03L208.06,334.05
				z"
            />
            <g>
              <path
                fill="#5E5D5B"
                d="M208.06,334.05l1.3,3.38c0,0,1.41-0.81,2.19-1.37c0.78-0.56,0.74-1.16,0.88-1.62
					c0.1-0.33,0.37-0.99,0.86-1.38c-1.16-0.25-2.87-0.29-3.6-0.29C208.58,333.26,208.06,334.05,208.06,334.05z"
              />
              <path
                fill="#5E5D5B"
                d="M208.06,334.05l1.3,3.38c0,0,1.41-0.81,2.19-1.37c0.78-0.56,0.74-1.16,0.88-1.62
					c0.1-0.33,0.37-0.99,0.86-1.38c-1.16-0.25-2.87-0.29-3.6-0.29C208.58,333.26,208.06,334.05,208.06,334.05z"
              />
              <path
                fill="#FBFFFB"
                d="M215.18,333.69c-0.14-1.37-1.76-1.45-3.95-1.3c-0.61,0.04-1.12,0.19-1.54,0.37
					c0.74,0,2.44,0.04,3.6,0.29c0.19-0.14,0.4-0.25,0.65-0.28C214.87,332.67,215.18,333.69,215.18,333.69z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M215.18,333.69l4.28,0.12c0,0-1.23,1.84,0.03,2.87l-4.82-0.31c0,0-0.99,1.5-2.12,2.32
				c0,0-1.23,0.14-2.02,0.03c-0.79-0.1-1.02-0.87-1.18-1.3l3.3-4.26c0,0,0.65-0.61,1.43-0.48
				C214.88,332.82,215.18,333.69,215.18,333.69z"
            />
            <path
              fill="#FBFFFB"
              d="M215.18,333.69c0,0-0.37-0.84-1.05-0.7c-0.68,0.14-1.13,1.2,1.06,1.2c2.19,0,4.03,0.07,4.03,0.07
				s-0.07-0.31,0.41-0.48L215.18,333.69z"
            />
            <linearGradient
              id="Card22MSVGID50"
              gradientUnits="userSpaceOnUse"
              x1="209.6935"
              y1="333.042"
              x2="215.1835"
              y2="333.042"
            >
              <stop offset="0.2213" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4937" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.6893" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.8607" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="0.862" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              <stop offset="0.9081" style={{ stopColor: "#161615", stopOpacity: 0.0617 }} />
              <stop offset="0.9726" stopColor="#030303" stopOpacity="8.066460e-03" />
              <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID50)"
              d="M211.24,332.39c-0.61,0.04-1.12,0.19-1.54,0.37c0.74,0,2.44,0.04,3.6,0.29
				c0.19-0.14,0.4-0.25,0.65-0.28c0.92-0.11,1.23,0.92,1.23,0.92"
            />
            <path
              fill="#5E5D5B"
              d="M219.89,337.05c-0.61,0-1.1-0.78-1.1-1.74c0-0.96,0.49-1.74,1.1-1.74h0.76v3.48H219.89z"
            />
            <linearGradient
              id="Card22MSVGID51"
              gradientUnits="userSpaceOnUse"
              x1="218.7835"
              y1="335.3142"
              x2="220.6523"
              y2="335.3142"
            >
              <stop offset="0.1725" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID51)"
              d="M219.89,337.05c-0.61,0-1.1-0.78-1.1-1.74c0-0.96,0.49-1.74,1.1-1.74h0.76v3.48H219.89z"
            />
            <ellipse fill="#5E5D5B" cx="220.65" cy="335.31" rx="1.1" ry="1.74" />
            <path fill="#191C1C" d="M208.03,333.96l1.08,2.75c0,0-0.79-2.55-0.72-3.11" />
          </g>
          <linearGradient
            id="Card22MSVGID52"
            gradientUnits="userSpaceOnUse"
            x1="101.3017"
            y1="377.0702"
            x2="115.226"
            y2="377.0702"
          >
            <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
          </linearGradient>
          <path
            fill="url(#Card22MSVGID52)"
            d="M103.51,372.83c0,0,6.47,0.24,7.5,0.52c1.03,0.29,1.17,0.67,1.5,2.48
			c0.33,1.81,0.75,3.29,0.75,3.29l1.97,2.19l-3.14-0.29l-0.14-0.52l-2.91-0.14l-0.09,0.62l-7.17-0.95l-0.47-2L103.51,372.83z"
          />
          <linearGradient
            id="Card22MSVGID53"
            gradientUnits="userSpaceOnUse"
            x1="117.8892"
            y1="333.2957"
            x2="173.9257"
            y2="300.943"
          >
            <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
            <stop offset="8.980082e-03" style={{ stopColor: "#F2F2F1", stopOpacity: 0.9 }} />
            <stop offset="0.042" style={{ stopColor: "#C5C5C5", stopOpacity: 0.9 }} />
            <stop offset="0.0782" style={{ stopColor: "#9D9D9C", stopOpacity: 0.9 }} />
            <stop offset="0.1171" style={{ stopColor: "#7A7A79", stopOpacity: 0.9 }} />
            <stop offset="0.1592" style={{ stopColor: "#5D5D5C", stopOpacity: 0.9 }} />
            <stop offset="0.2056" style={{ stopColor: "#454544", stopOpacity: 0.9 }} />
            <stop offset="0.2581" style={{ stopColor: "#323232", stopOpacity: 0.9 }} />
            <stop offset="0.3204" style={{ stopColor: "#262625", stopOpacity: 0.9 }} />
            <stop offset="0.4017" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
            <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
            <stop offset="0.7091" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9317 }} />
            <stop offset="0.7692" style={{ stopColor: "#252524", stopOpacity: 0.9458 }} />
            <stop offset="0.8144" style={{ stopColor: "#323231", stopOpacity: 0.9564 }} />
            <stop offset="0.8523" style={{ stopColor: "#444443", stopOpacity: 0.9653 }} />
            <stop offset="0.8855" style={{ stopColor: "#5B5B5B", stopOpacity: 0.9731 }} />
            <stop offset="0.9155" style={{ stopColor: "#787877", stopOpacity: 0.9801 }} />
            <stop offset="0.943" style={{ stopColor: "#9A9A9A", stopOpacity: 0.9866 }} />
            <stop offset="0.9686" style={{ stopColor: "#C2C2C2", stopOpacity: 0.9926 }} />
            <stop offset="0.9918" style={{ stopColor: "#EEEEEE", stopOpacity: 0.9981 }} />
            <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
          </linearGradient>
          <path
            fill="url(#Card22MSVGID53)"
            d="M112.74,321.11c-0.61,0.66-2.28,1.98,0.8,2.17c3.08,0.19,20.38,0.1,20.38,0.1s15.95,0,22.39-0.32
			c6.44-0.32,17.44-0.32,19.57-3.2c2.12-2.88,4.64-7.51,4.64-7.51s-0.1-0.19-0.32-0.49c-0.97-1.34-4.26-4.94-11.62-4.94
			c-9.04,0-33.94,0-36.54,0c-2.53,0-6.67,0.46-11.64,5.75c-0.13,0.14-0.26,0.28-0.39,0.42
			C114.87,318.79,113.35,320.46,112.74,321.11z"
          />
          <linearGradient
            id="Card22MSVGID54"
            gradientUnits="userSpaceOnUse"
            x1="121.2379"
            y1="339.4017"
            x2="144.9154"
            y2="339.4017"
          >
            <stop offset="0.2213" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.2586" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.2634" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.518" style={{ stopColor: "#030303", stopOpacity: 0.085 }} />
            <stop offset="0.7009" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1461 }} />
            <stop offset="0.8612" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1996 }} />
            <stop offset="0.8624" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            <stop offset="0.8971" style={{ stopColor: "#161615", stopOpacity: 0.1233 }} />
            <stop offset="0.9457" style={{ stopColor: "#030303", stopOpacity: 0.0161 }} />
            <stop offset="0.953" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
          </linearGradient>
          <path
            fill="url(#Card22MSVGID54)"
            d="M144.26,336.18c-3.78-0.1-15.3-0.09-19.88-0.08c-0.58,0-1.04,0-1.37,0
			c-0.32,0-0.59,0.23-0.66,0.54l-1.1,5.07c-0.09,0.43,0.22,0.84,0.65,0.85l0.94,0.01l20.36,0.14c0.32,0,0.6-0.23,0.67-0.55
			l1.02-5.14C144.99,336.59,144.68,336.19,144.26,336.18z"
          />
          <linearGradient
            id="Card22MSVGID55"
            gradientUnits="userSpaceOnUse"
            x1="205.7446"
            y1="381.8423"
            x2="235.56"
            y2="381.8423"
          >
            <stop offset="0.4595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.6657" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
            <stop offset="0.8643" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
          </linearGradient>
          <path
            fill="url(#Card22MSVGID55)"
            d="M208.36,383.63c-0.33-4.09-1.78-8.46-2.62-11.4c0,0,5.61-14.25,17.66-12.26
			c13.89,2.3,12.49,24.58,11.68,28.03c-1.03,4.37-3.74,15.01-13.64,15.87C211.54,404.73,208.73,388.29,208.36,383.63z"
          />
          <g>
            <path
              fill="none"
              d="M221.74,315.66l0.16-3.63c-0.03,0.01-0.05,0.02-0.08,0.03C221.76,312.57,221.7,313.87,221.74,315.66z"
            />
            <path
              fill="none"
              d="M233.88,310.18c-0.01,0.13-0.01,0.76-0.01,0.89c-0.07,1.28-0.15,2.14-0.17,3.11
				c-0.09,4.59-0.4,7.63-0.85,9.51c-0.03,0.12-0.06,0.22-0.08,0.29l-0.02,0.27c0.28-0.16,0.45-0.22,0.78-0.27
				c0.41-0.07,0.63-0.21,0.99-0.41l0.02,0.01c0.82-2.5,0.65-5.84,0.43-6.74c-0.31-1.28-0.5-3.07-0.61-4.56
				c-0.13-0.08-0.2-0.15-0.2-0.15L233.88,310.18z"
            />
            <path
              fill="#EFC4AD"
              d="M237.69,311.62c-0.44,0.27-1.24,0.76-1.53,0.84c-0.89,0.26-1.51-0.01-1.8-0.19
				c0.11,1.5,0.31,3.28,0.61,4.56c0.22,0.91,0.39,4.25-0.43,6.74l-0.02-0.01c-0.36,0.2-0.58,0.35-0.99,0.41
				c-0.33,0.05-0.5,0.12-0.78,0.27c-0.07,0.04-0.15,0.09-0.24,0.14c-0.45,0.26-0.88-0.1-0.73,0.43c0.08,0.27,0.49,0.33,0.9,0.3
				c0.39-0.03,0.77-0.12,0.9-0.19c0.26-0.13,0.36-0.05,0.3,0.2c-0.07,0.25-0.38,0.69-0.66,1.14s-0.35,0.88-0.4,1.52
				c-0.05,0.64-0.12,0.89,0.25,0.91c0.36,0.02,0.55-0.88,0.73-1.41c0.18-0.53,0.61-1.17,0.61-1.17s-0.05,0.18-0.13,0.45
				s-0.26,0.86-0.17,1.22c0.1,0.36,0.21,0.69,0.53,0.76c0.31,0.07,0.43-0.55,0.43-0.55c0.07,0.28,0.28,0.55,0.6,0.53
				c0.31-0.02,0.5-0.68,0.6-1.04c0.1-0.36,0.3-0.68,0.3-0.68c-0.05,0.15,0,0.48-0.07,0.69c-0.07,0.21-0.13,0.56,0.1,0.58
				s0.23,0.02,0.63-0.36c0.4-0.38,0.17-0.56,0.2-1.17c0.03-0.48-0.5-1.41-0.59-1.92c0.47-3.07,0.91-2.81,1.27-5.97
				C238.34,316.57,238.02,313.71,237.69,311.62z"
            />
            <path
              fill="#EFC4AD"
              d="M225.93,324.33c0,0-0.67-0.04-1.01-0.14c-0.66-0.19-1.22-0.43-1.62-0.33c-0.66,0.16-0.74-0.45-0.8-0.81
				c-0.46-2.78-0.63-5.42-0.68-7.44c-0.04-1.79,0.15-3.26,0.21-3.77c-0.83,0.26-2.31,0.51-3.42-0.73c-0.06,0.97-0.23,5.07,0.46,9.16
				c0.4,2.36,1.24,5.25,1.24,5.25s0.17,0.35,0.3,0.91s0.43,0.91,0.63,1.4s0.6,0.99,0.98,0.74c0,0,0.38,0.77,0.79,0.68
				c0.4-0.1,0.33-0.39,0.33-0.39s0.35,0.27,0.64,0.14c0.29-0.14,0.24-0.75,0.24-0.75s0.38,0.08,0.51-0.15
				c0.13-0.23,0.04-1.47,0.02-1.68c-0.02-0.21-0.4-1.35-0.4-1.35s1.01,0.07,1.22,0.02c0.07-0.02,0.13-0.04,0.19-0.07
				C226.18,324.81,225.93,324.33,225.93,324.33z"
            />
            <path
              fill="#C16032"
              d="M225.23,305.06c-0.05-0.02-0.09-0.03-0.14-0.05C225.16,305.04,225.21,305.05,225.23,305.06z"
            />
            <path
              fill="#C16032"
              d="M237.2,307.25c-0.13-0.88-0.48-2.75-1.32-3.3c-0.82-0.53-2.34-0.48-3.69-0.32
				c-0.78,0.1-1.56,0.22-2.04,0.31c-0.08,1.07-0.07,1.28-0.15,1.79c-0.29,1.97-0.88,2.67-2.16,2.67c-2.36,0-2.51-3.32-2.51-3.37
				c0,0-0.07,0.02-0.08,0.02c-0.13-0.12-0.75-0.8-0.78-0.85c-0.08-0.12-0.24-0.34-0.42-0.65c-0.01,0-1.14-0.16-1.22-0.17
				c-0.01-0.01-0.01-0.01-0.01-0.02c-1.49-0.24-3.51-0.4-3.89,0.84c-0.69,2.25-0.69,6.62-0.69,6.62c0.09,0.13,0.19,0.25,0.29,0.37
				c1.1,1.24,2.46,1.1,3.29,0.84c0.03-0.01,0.05-0.02,0.08-0.03l-0.16,3.63c0.05,2.02,0.22,4.66,0.68,7.44
				c0.06,0.36,0.13,1.02,0.79,0.86c0.41-0.1,0.96,0.13,1.63,0.32c0.34,0.1,1.03,0.16,1.03,0.16s0.16,0.44-0.27,0.65l7.07,0.11
				l0.01-0.08c-0.41,0.03-0.82-0.03-0.9-0.3c-0.15-0.53,0.28-0.17,0.73-0.43c0.09-0.05,0.17-0.1,0.24-0.14l0.02-0.27
				c0.03-0.07,0.05-0.17,0.08-0.29c0.45-1.88,0.76-4.93,0.85-9.51c0.02-0.97,0.1-1.84,0.17-3.11c0.01-0.13,0-0.75,0.01-0.89
				l0.28,1.94c0,0,0.07,0.07,0.2,0.15c0.29,0.19,0.9,0.45,1.8,0.19c0.28-0.08,1.09-0.57,1.53-0.84c0.18-0.11,0.29-0.18,0.29-0.18
				S237.42,308.78,237.2,307.25z"
            />
            <path
              fill="#EFC4AD"
              d="M230.69,302.08c0,0,0-0.19,0.03-0.53c0.03-0.01,0.04-0.01,0.07-0.03c0.55-0.18,0.94-1.01,1.09-1.44
				c0.15-0.43,0.2-1.14-0.43-1.15c-0.19,0-2.7-3.85-2.7-3.85c-0.44,0.35-3.09,0.02-3.08-0.59c-0.1,0.61-2.68,0.89-2.41,0.3
				c0,0-0.03,0.07-0.08,0.19c-0.09,0.26-0.31,0.75-0.28,1.3c-0.07,0.79-0.16,1.34-0.08,1.64c0,0.07,0.01,0.05,0.01,0.19
				c-0.01,0.25-0.04,0.4-0.02,0.64c0.15,1.19-0.08,2.19,0.17,3.06c0.03,0.1,0.05,0.19,0.09,0.3c0,0.01,0.03,0.05,0.04,0.06
				c0.19,0.49,0.51,0.99,0.76,1.42c0.17,0.31,0.32,0.57,0.4,0.69c0.03,0.04,0.08,0.1,0.11,0.15c0.3,0.38,0.52,0.6,0.69,0.64
				c0.04,0.02,0.11-0.02,0.16,0c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0c0,0.05,0.23,3.44,2.59,3.44c1.28,0,1.94-0.8,2.23-2.76
				c0.08-0.52,0.06-0.7,0.14-1.77c0.02-0.3,0.17-0.63,0.21-1.09C230.73,302.43,230.69,302.08,230.69,302.08z"
            />
            <path
              fill="#EFC4AD"
              d="M223.38,298.81c-0.19,0.11-0.21,0.28-0.21,0.28s0.06,0,0.23-0.01c0.02,0,0.05,0,0.08,0
				C223.47,299.08,223.32,299,223.38,298.81z"
            />
            <path
              fill="#4D2C1A"
              d="M225.12,301.8c-0.21-0.01-0.4,0.02-0.51,0.11c0.06-0.03,0.21-0.09,0.48-0.11
				C225.1,301.8,225.11,301.8,225.12,301.8z"
            />
            <path
              fill="#4D2C1A"
              d="M226.22,302.5c0-0.01,0.01-0.01,0.01-0.02C226.22,302.49,226.22,302.49,226.22,302.5
				C226.22,302.5,226.22,302.5,226.22,302.5z"
            />
            <path
              fill="#4D2C1A"
              d="M224.87,302.98c0.17,0.16,0.39,0.23,0.67,0.13c-0.06,0.02-0.12,0.03-0.19,0.03
				C225.12,303.14,224.97,303.06,224.87,302.98z"
            />
            <path
              fill="#4D2C1A"
              d="M224.41,302.05l0.03-0.01c0,0,0.08,0.4,0.26,0.73c0,0-0.01-0.41,0.5-0.49c0.51-0.08,0.84-0.07,1.05,0.04
				c0,0,0.05,0.04,0,0.11c0.09-0.13,0.15-0.25,0.18-0.31l-0.08-0.07c-0.12-0.03-0.36-0.15-0.57-0.19c-0.62,0.25-1.04,0.05-1.1,0.03
				c0,0-0.12,0.05-0.12,0.05C224.52,301.99,224.46,302.03,224.41,302.05z"
            />
            <path
              fill="#AD7D62"
              d="M225.77,300.24c-0.04-0.08-0.07-0.12-0.07-0.12C225.71,300.15,225.74,300.19,225.77,300.24z"
            />
            <path
              fill="#DFD7C6"
              d="M225.84,301.91c-0.14-0.03-0.24-0.14-0.35-0.16c-0.11-0.02-0.26,0-0.37,0c-0.01,0-0.01,0-0.02,0
				c-0.27,0.02-0.43,0.07-0.49,0.1c-0.01,0.01-0.02,0.07-0.02,0.07C224.64,301.95,225.22,302.16,225.84,301.91z"
            />
            <path
              fill="#DFD7C6"
              d="M225.43,302.87c-0.44,0.01-0.72-0.1-0.72-0.1s0,0.15,0.12,0.25c0.1,0.08,0.28,0.19,0.51,0.19
				c0.07,0,0.18-0.01,0.25-0.02c0.39-0.11,0.6-0.6,0.64-0.68c0,0,0,0,0,0C226.08,302.68,225.87,302.86,225.43,302.87z"
            />
            <path
              fill="#D25959"
              d="M225.18,302.19c-0.51,0.08-0.48,0.58-0.48,0.58s0.24,0.18,0.68,0.16c0.44-0.01,0.7-0.25,0.83-0.43
				c0-0.01,0.01-0.01,0.01-0.01c0.01-0.02,0.02-0.04,0.04-0.05c0.05-0.07,0.07-0.13,0.07-0.13
				C226.12,302.19,225.69,302.11,225.18,302.19z"
            />
            <path fill="#4D2C1A" d="M231.31,296.04L231.31,296.04c0.01-0.01,0.01-0.03,0.02-0.05L231.31,296.04z" />
            <path
              fill="#AD7D62"
              d="M224.2,302.37c-0.01-0.03-0.02-0.06-0.02-0.09C224.19,302.39,224.24,302.49,224.2,302.37z"
            />
          </g>
          <path
            opacity="0.27"
            d="M221.41,326.13c0,0,0.31,1.02,0.42,1.6c0.08,0.43,0.14,0.79,0.17,0.95c0.01,0.05,0.01,0.08,0.01,0.08
			l0.15-0.08c0,0,0-0.01,0-0.04c-0.02-0.15-0.1-0.7-0.27-1.22C221.69,326.83,221.41,326.13,221.41,326.13z"
          />
          <path
            opacity="0.27"
            d="M222.67,327c0.05,0.33,0.46,1.84,0.53,2.14c0.01,0.03,0.01,0.05,0.01,0.05l0.05-0.21
			c0,0-0.01-0.02-0.01-0.06c-0.09-0.39-0.63-2.6-0.83-3.15C222.44,325.77,222.62,326.64,222.67,327z"
          />
          <path
            opacity="0.27"
            d="M223.56,325.84c-0.09-0.32-0.15-0.54-0.14-0.64c0,0-0.06,0.04,0.01,0.42c0.07,0.38,0.29,1.12,0.34,1.43
			c0.04,0.3,0.32,1.23,0.35,1.6c0,0.02,0,0.03,0,0.04l0.03-0.34c0,0,0-0.02-0.01-0.06c-0.04-0.18-0.15-0.75-0.32-1.45
			C223.73,326.46,223.63,326.12,223.56,325.84z"
          />
          <path
            opacity="0.27"
            d="M224.79,299.2c0-0.04,0-0.08,0-0.12c0-0.06-0.01-0.13-0.01-0.19c-0.03-0.34-0.12-0.62-0.12-0.62
			c0.01,0.01,0.01,0.02,0.02,0.03c-0.12-0.28-0.32-0.41-0.32-0.41c-0.67-0.42-1.08,0.05-1.08,0.05c0.02,0,0.04,0,0.07,0
			C224.49,297.92,224.74,298.77,224.79,299.2z"
          />
          <path
            opacity="0.27"
            d="M224.43,324.92c-0.15-0.07-0.65-0.29-0.78-0.35c0.55,0.28,0.69,0.52,0.69,0.52l0.44,0.03
			c0,0,0.04,0.01-0.03-0.03C224.7,325.04,224.61,325,224.43,324.92z"
          />
          <path
            opacity="0.27"
            d="M221.36,317.06c-0.05,1.24-0.71,1.47-0.88,1.51c0.19-0.03,1.06-0.1,1.16,1.17
			c0.11,1.42,0.4,2.85,0.46,3.59c0.06,0.74,0.81,0.63,0.81,0.63s0,0,0,0c-0.01-0.04-0.32-0.65-0.47-1.45
			c0.03-0.64,0.07-1.75,0.13-3.03c0.01-0.01,0.02-0.02,0.03-0.05c0.3-0.53,1.41,0.17,2.06,0.66c-1.21-1.13-3.14-3.84,0.4-1.76
			c3.76,2.2,5.12,2.07,3.7,1.56c-1.43-0.52-3.7-1.56-4.6-3.24c-0.91-1.69-1.36-3.11-1.11-5.53c0,0-0.03,0.3-0.08,0.78
			c0.03-0.39,0.06-0.67,0.08-0.78c0.18-0.88,0.57-1.37,0.18-0.97c-0.4,0.4-0.71,0.71-0.79-0.04c-0.09-0.75-0.33-2.73-0.33-2.73
			s0.02,1.32-0.03,2.47c-0.03,0.81-0.18,1.98-0.27,2.21c-0.13,0.05-0.64,0.14-0.64,0.15C221.19,312.46,221.42,315.69,221.36,317.06z
			"
          />
          <path
            opacity="0.27"
            d="M236.87,324.34c0.02-0.1,0.04-0.23,0.07-0.36c0.11-0.52,0.27-1.25,0.48-2.02c0.25-0.9,0.38-1.36,0.56-2.39
			c0.1-0.57,0.2-1.08,0.21-1.45c0.07-1.57,0.11-3.4-0.49-6.51c0-0.01,0,0-0.01-0.03c-1.32,1-2.3,0.98-2.3,0.98l-0.07,0.03
			c1.09,2.34,1.61,3.55,1.27,4.97c-0.22,0.9-0.24,1.66-0.05,2.63c0.31,1.56-0.16,3.28-0.19,3.99c0,0,0,0,0,0
			c-0.15,1.35,0,1.49-0.31,2.74c-0.08,0.31-0.15,0.53-0.21,0.67c-0.22,0.02-0.62,0.04-0.63-0.15c-0.02-0.26,0.32-1.37,0.15-1.11
			c-0.17,0.26-0.28,1.22-0.49,1.22c-0.19,0-0.49,0-0.79-0.03c-0.02,0,0.02,0,0,0c-0.02,0.45,0.27,1.14,0.66,1.02
			c0.28-0.09,0.32-0.53,0.32-0.53c-0.01,0.09,0.11,0.27,0.19,0.36c0.11,0.11,0.16,0.16,0.29,0.18c0.23,0.04,0.34-0.05,0.53-0.43
			c0,0,0.07-0.22,0.07-0.2c0.03-0.09,0.04-0.16,0.12-0.41c0.04-0.13,0.18-0.47,0.28-0.62c0,0.17,0,0.43-0.05,0.65
			c-0.01,0.07-0.04,0.14-0.05,0.21c-0.02,0.21,0.01,0.36,0.21,0.36c0.29-0.01,0.22-0.06,0.32-0.13c0.06-0.05,0.12-0.1,0.16-0.14
			c0.29-0.24,0.38-0.43,0.3-0.89c-0.02-0.09-0.01-0.34-0.02-0.45c-0.02-0.3-0.09-0.49-0.21-0.82c-0.04-0.14-0.09-0.18-0.12-0.28
			c-0.05-0.17-0.18-0.47-0.21-0.59c-0.08-0.25-0.01-0.24-0.01-0.29c0-0.02,0-0.03,0.01-0.05
			C236.86,324.42,236.86,324.38,236.87,324.34z M236.69,326.78c0-0.01,0-0.02,0-0.03c0,0,0,0,0,0
			C236.69,326.76,236.69,326.77,236.69,326.78z"
          />
          <path
            opacity="0.27"
            d="M234.37,325.83c0.02-0.2-0.18,0.16-0.47,0.67c-0.28,0.51-0.45,0.73-0.53,1.26
			c-0.08,0.5-0.26,0.83-0.54,0.86c-0.02,0,0.02,0,0,0c0,0,0.2,0.19,0.38,0.07c0.19-0.13,0.34-0.58,0.42-0.89s0.28-0.86,0.42-1.1
			c0.14-0.24,0.34-0.57,0.34-0.57S234.35,326.03,234.37,325.83z"
          />
          <path
            fill="#4D2C1A"
            d="M227.64,299.08c0.02,0,0.03,0,0.03,0l-0.07-0.03c-0.14-0.13-0.54-0.47-0.93-0.44
			c-0.48,0.03-0.69,0.47-0.69,0.47s0.21-0.29,0.53-0.37c-0.03,0.01-0.06,0.02-0.09,0.04c0,0-0.07,0.18,0.05,0.33l0.46,0.01
			c0,0,0.16-0.08,0.11-0.31c0.21,0.08,0.41,0.21,0.52,0.28c-0.06,0-0.27,0.02-0.55,0.02C226.73,299.06,227.45,299.08,227.64,299.08
			c0.01,0.01,0.02,0.02,0.02,0.02S227.65,299.09,227.64,299.08z"
          />
          <path
            fill="#4D2C1A"
            d="M223.95,298.77c0.27,0.08,0.47,0.25,0.47,0.25s-0.26-0.41-0.82-0.33c-0.39,0.06-0.51,0.42-0.51,0.42
			s0.5,0,0.72-0.03c0.04,0,0.05-0.01,0.06-0.01c0,0,0,0-0.01,0l0.06,0C224,298.98,223.96,298.82,223.95,298.77z M223.17,299.09
			c0,0,0.02-0.18,0.21-0.28c-0.06,0.19,0.09,0.27,0.1,0.27c-0.03,0-0.06,0-0.08,0C223.23,299.09,223.17,299.09,223.17,299.09z"
          />
          <path
            fill="#AD7D62"
            d="M226.94,298.55c0.16,0.03,0.32,0.09,0.47,0.15c0.15,0.06,0.31,0.13,0.46,0.21
			c-0.26-0.2-0.57-0.36-0.91-0.44c-0.17-0.04-0.35-0.05-0.52-0.01c-0.17,0.04-0.33,0.16-0.38,0.32c0.08-0.14,0.24-0.23,0.39-0.26
			C226.62,298.49,226.78,298.51,226.94,298.55z"
          />
          <path
            fill="#AD7D62"
            d="M228.12,298.23c0,0-0.52-0.7-1.2-0.68c-0.68,0.01-1.02,0.46-1.02,0.46s0.44-0.13,1.03-0.14
			C227.51,297.85,228.12,298.23,228.12,298.23z"
          />
          <path
            fill="#AD7D62"
            d="M224.21,300.85c0.04-0.1,0.08-0.16,0.11-0.22c0,0.04,0.02,0.09,0.06,0.15c0,0,0,0,0,0.01
			c-0.01,0.04,0.02,0.05,0.06,0.06c0.01,0.01,0.01,0.02,0.02,0.02c-0.01-0.01-0.01-0.02-0.02-0.02c0.06,0.01,0.16-0.01,0.2-0.02
			c0.06,0.05,0.14,0.08,0.26,0.04c0,0-0.35,0.03-0.32-0.39c0.01-0.22,0.17-0.66,0.22-1.04c0.05-0.38,0.06-0.82-0.14-1.17
			c0,0,0.2,0.59,0.11,1.08c-0.08,0.49-0.22,1.09-0.23,1.19c-0.01,0.05,0.01,0.16,0.07,0.25c-0.1-0.05-0.18-0.09-0.22-0.04
			c-0.04-0.11-0.02-0.26,0.17-0.35c0,0-0.1,0.03-0.17,0.1c0.02-0.05,0.03-0.12,0.03-0.22c0,0-0.21,0.43-0.33,0.85
			c-0.04,0.14-0.04,0.3-0.03,0.45C224.04,301.29,224.12,301.03,224.21,300.85z"
          />
          <path
            fill="#AD7D62"
            d="M225.65,300.67c-0.02-0.12-0.44,0.14-0.44,0.14C225.55,300.85,225.67,300.79,225.65,300.67z"
          />
          <path
            fill="#AD7D62"
            d="M225.8,300.53c-0.04-0.22-0.26-0.23-0.26-0.23c0.08-0.01,0.18,0.09,0.21,0.24
			c0.02,0.08-0.02,0.15-0.07,0.2C225.76,300.7,225.82,300.63,225.8,300.53z"
          />
          <path fill="#AD7D62" d="M225.69,300.75c-0.08,0.06-0.18,0.08-0.18,0.08" />
          <path fill="#AD7D62" d="M225.69,300.75c-0.05,0.05-0.11,0.08-0.18,0.08" />
          <path
            fill="#AD7D62"
            d="M223.2,298.64c0.11-0.08,0.25-0.11,0.39-0.12c0.14-0.01,0.28,0.01,0.42,0.04c0.13,0.04,0.27,0.1,0.37,0.18
			c-0.17-0.22-0.49-0.31-0.79-0.3c-0.15,0.01-0.31,0.05-0.42,0.14c-0.11,0.09-0.18,0.21-0.2,0.33
			C223,298.81,223.09,298.71,223.2,298.64z"
          />
          <path
            fill="#AD7D62"
            d="M224.18,302.27c-0.01-0.09,0-0.19,0.09-0.18c0.08,0.15,0.23,0.65,0.39,0.9c0.16,0.26,0.31,0.49,0.86,0.4
			c0.55-0.09,0.56-0.46,0.74-0.71c0.14-0.2,0.19-0.45,0.21-0.56c0.1,0.02,0.14,0.34,0.14,0.33c0-0.01-0.03-0.29-0.05-0.45
			c0-0.09-0.01-0.35-0.13-0.66c-0.02-0.07-0.06-0.14-0.1-0.22c-0.16-0.3-0.42-0.67-0.56-0.9c0.12,0.21,0.36,0.65,0.51,0.98
			c0.14,0.3,0.21,0.56,0.25,0.71c-0.06,0.1-0.16,0.07-0.19,0.09l0.01,0.01c-0.27-0.11-0.87-0.36-1.01-0.4
			c-0.18-0.06-0.25-0.01-0.38,0.03c-0.13,0.04-0.11-0.2-0.43,0c-0.21,0.14-0.26,0.3-0.27,0.38c-0.03,0.05-0.09,0.02-0.15-0.13
			c-0.01-0.03-0.02-0.04-0.02-0.04c-0.02-0.08-0.04-0.18-0.05-0.28c0,0.1,0.02,0.2,0.04,0.3
			C224.1,301.95,224.14,302.13,224.18,302.27z M224.59,301.92c0.01-0.01,0.02-0.01,0.02-0.02c0.11-0.08,0.29-0.11,0.51-0.11
			c0.1,0,0.21,0.01,0.32,0.03c0.11,0.02,0.25,0.05,0.4,0.08c0.21,0.05,0.41,0.1,0.53,0.14l0.08,0.07c-0.03,0.06-0.1,0.18-0.18,0.31
			c-0.01,0.02-0.02,0.03-0.04,0.05c0,0.01-0.01,0.01-0.01,0.02c-0.04,0.08-0.28,0.49-0.67,0.61c-0.28,0.1-0.51,0.03-0.67-0.13
			c-0.12-0.1-0.16-0.21-0.16-0.21c-0.19-0.32-0.26-0.73-0.26-0.73l-0.03,0.01C224.46,302.03,224.52,301.99,224.59,301.92
			C224.58,301.93,224.59,301.93,224.59,301.92z"
          />
          <path
            fill="#AD7D62"
            d="M230.8,300.63c-0.13,0.18-0.09,0.31,0.01,0.21c0.1-0.1,0.32-0.37,0.29-0.46
			c-0.03-0.09-0.15-0.06-0.19-0.24c0,0-0.05,0.02-0.04,0.14C230.89,300.39,230.93,300.45,230.8,300.63z"
          />
          <path
            fill="#AD7D62"
            d="M231.27,300.22c-0.01,0.13-0.02,0.24-0.02,0.24s0.11-0.11,0.12-0.26c0.01-0.14-0.01-0.23-0.07-0.43
			c-0.06-0.2,0.08-0.39,0.2-0.4c0.18-0.01,0.2,0.44,0.2,0.44c0.01-0.2,0.05-0.48-0.11-0.58c-0.16-0.1-0.29,0.04-0.36,0.14
			s-0.26,0.51-0.19,0.69C231.11,300.24,231.28,300.09,231.27,300.22z"
          />
          <path
            fill="#AD7D62"
            d="M230.84,301.21c0.43-0.11,0.71-0.99,0.71-0.99C231.38,300.72,230.84,301.21,230.84,301.21z"
          />
          <path
            fill="#EFC4AD"
            d="M223.85,294.23c0,0,0.46-0.39,1.18-0.49c0.77-0.11,1.42-0.17,2.54,0.11c1.28,0.32,1.8,1.06,1.8,1.06
			s0.8,0.77,1.2,1.68c0.25,0.56,0.66,1.64,0.81,2.39s-3.04-3.66-3.04-3.66l-4.05-0.09l-1.14-0.07l0.1-0.36L223.85,294.23z"
          />
          <path
            fill="#AD7D62"
            d="M222.81,303.73c-0.1,1.19,0.09,2.13,0.22,2.82c0.09,0.97,0.26,1.94,0.5,2.88
			c-0.1-0.58-0.11-1.18-0.04-1.77c0.13,0.44,0.27,0.87,0.4,1.31c0.06,0.18,0.2,0.41,0.37,0.33c-0.34-0.46,0.07-0.37,0.21,0.26
			c0.08-0.7,0.39-1.03,0.59-0.53c-0.04-0.64-0.02-1.28,0.06-1.91c0.01,0,0.02-0.01,0.03-0.01c0.07,0.62,0.18,1.24,0.33,1.84
			c0.09-0.69-0.22-3.4-0.23-3.71c0-0.06,0-0.12,0-0.19c0,0,0,0,0,0c0,0-0.01,0-0.02,0c-0.02-0.01-0.07-0.02-0.14-0.05
			c-0.14-0.07-0.4-0.22-0.68-0.61c-0.16-0.22-0.27-0.42-0.47-0.77c0.02,0-0.57-1.04-0.76-1.53c0-0.01-0.01-0.02-0.01-0.03
			c-0.04-0.11-0.08-0.22-0.11-0.33c-0.25-0.86,0-1.79-0.15-2.98c-0.03-0.24-0.04-0.37-0.02-0.62c0.09-0.08,0.24-0.17,0.48-0.21
			c0.49-0.08,0.97-0.04,0.97-0.04s-0.42-0.31-0.95-0.26c-0.02,0-0.05,0-0.07,0.01c-0.21,0.03-0.34,0.12-0.42,0.22
			c0.02-0.31,0.05-0.78,0.08-1.57c-0.05-0.81,0.28-1.49,0.28-1.49c-0.28,0.59-0.22,1.33-0.22,1.33l0.21,0.79
			c0.01,0.01,0.02,0.03,0.03,0.04l0.53,0.04c0,0,0.28-0.08,0.28-0.01c0,0.02,0.06,0.04,0.15,0.06c0-0.07,0.04-0.14,0.11-0.16
			c0-0.07,0-0.13,0.01-0.2c0.04,0.07,0.07,0.15,0.08,0.22c0-0.11,0.01-0.23,0.01-0.34c0.06,0.18,0.13,0.35,0.19,0.53
			c0,0,0.01,0,0.01,0c-0.09-0.38-0.07-0.54-0.1-0.96c-0.01-0.16-0.07-0.22-0.01-0.37c0.03,0.59,0.11,0.83,0.27,1.34
			c0.02,0,0.03,0,0.04,0c0-0.12,0-0.23,0-0.35c0.04,0.07,0.06,0.15,0.07,0.23c-0.04-0.14-0.05-0.29-0.04-0.44
			c0.04,0.13,0.09,0.27,0.13,0.4c0.01,0,0.01,0,0.02,0c-0.06-0.25-0.11-0.51-0.14-0.77c0.09,0.3,0.19,0.59,0.3,0.87c0,0,0,0,0,0
			l0.01,0c-0.15-0.6-0.3-1.22-0.2-1.83c-0.01,0.61,0.13,1.23,0.4,1.78l0.03,0.04l0.62-0.04l0.39,0.08l1.59,0.05
			c0,0-0.05-0.96,0.4-1.31c0,0,0,0.07,0.13,0.84c0.11,0.64,1,1.22,1.19,1.66c0.26,0.64,0.69,0.64,0.79,0.77
			c0.02,0.19,0.05,0.39,0.1,0.57l0.27-0.54c0.13-0.1,0.29-0.16,0.48-0.16c0.63,0.01,0.55,0.64,0.4,1.07
			c-0.15,0.43-0.55,1.25-1.1,1.43c-0.03,0.01-0.06,0.02-0.09,0.03c-0.03,0.34-0.05,0.55-0.05,0.55s0.05,0.34-0.27,0.79
			c-0.04,0.47-0.07,0.83-0.09,1.13c0.49-0.09,1.2-0.23,1.98-0.33c0.27-0.75,0.44-1.44,0.34-1.83c-0.31-1.2-0.5-1.49-0.25-3.41
			c0.22-1.73-0.26-2.92-0.97-4.29c-0.26-0.75-1-1.35-2.68-1.72c-2.16-0.47-3.68-0.3-4.71,0.54c-1.33,0.88-1.73,1.51-1.91,2.96
			c-0.23,1.89,0.72,2.95,0.41,4.08c-0.05,0.2-0.09,0.37-0.11,0.54l-0.02,0c-0.01,0.09,0,0.17,0,0.25c-0.02,0.65,0.2,1.1,0.4,1.65
			c0.02,0.05,0.04,0.11,0.05,0.16c0.05,0.24,0.08,0.5,0.08,0.8C222.82,303.49,222.82,303.6,222.81,303.73z M231.31,296.02l0.02-0.05
			C231.33,295.99,231.32,296.01,231.31,296.02L231.31,296.02z"
          />
          <path
            opacity="0.27"
            d="M226.34,293.37c-0.79,0.82-0.54,2.4-0.49,3.56c0.03,0.01,0.11,0.04,0.19,0.09c0.22,0.11,0.54,0.3,0.54,0.3
			s0.64,0,1.27,0.03c0.58,0.03,1.29,0.8,1.37,1.14c0.22,1.03-0.51,1.66-0.91,2.76c-0.27,0.76-0.22,2.04-0.9,3.27
			c-0.27,0.31-0.7,0.47-0.94,0.53c-0.71,0.17-1.22,0.01-1.22,0.01c0,0.06,0,0.13,0,0.19c0,0.12,0.01,0.29,0.03,0.5
			c-0.04-0.4-0.04-0.61-0.03-0.69c0-0.02,0-0.03,0-0.03s-0.51-0.1-0.93-0.81c-0.3-0.49-0.69-1.2-0.9-1.58
			c-0.09-0.16-0.15-0.27-0.15-0.27c0.3,0.63,0.49,1.19,0.4,2.37c-0.09,1.17,0.07,1.55,0.26,2.18c0.19,0.63,0.28,2.11,0.28,2.11
			s0.06,0.07,0.12,0.17c0,0,0,0,0,0.01c0.06,0.09,0.11,0.21,0.14,0.33c0-0.02,0.01-0.05,0.02-0.08c0.01-0.03,0.01-0.06,0.02-0.1
			l-0.04,0.22c0,0,1.46-0.43,2.09-1.49c-0.2-0.16-0.37-0.35-0.51-0.55c0.1-0.29,0.49-1.36,1.13-2.03c0.56-0.58,1.52-1.33,1.97-1.67
			c0.35-0.22,0.68-0.46,0.93-0.7c0,0,0.14-0.16,0.26-0.35c0,0,0.01-0.01,0.03-0.04c-0.01,0.02-0.02,0.03-0.03,0.05l0,0.03l0-0.03
			c-0.01,0.02-0.02,0.03-0.04,0.05c-0.04,0.46-0.07,0.83-0.09,1.13c0.02,0-0.01,0.09,0.01,0.09l-0.06,0.81
			c0.22-0.07,2.37-1.23,3.91-1.34c0.05,0,0.09-0.01,0.14-0.01c-0.52-0.03-1.17,0-2.01,0.09c0.27-0.74,0.43-1.42,0.33-1.8
			c-0.31-1.2-0.5-1.49-0.25-3.41c0.15-1.15-0.02-2.06-0.35-2.94c-0.14-0.64-0.4-1.25-0.81-1.74c-0.01-0.02-0.03-0.04-0.04-0.05
			C230.28,292.76,227.23,292.45,226.34,293.37z M231.9,299.43c0.02,0.2-0.03,0.43-0.1,0.62c-0.06,0.16-0.15,0.39-0.27,0.6
			C231.73,300.24,231.93,299.79,231.9,299.43z M231.89,299.37c0,0,0-0.01,0-0.01C231.89,299.36,231.89,299.36,231.89,299.37z
			 M224.89,308.81C224.89,308.81,224.89,308.81,224.89,308.81C224.89,308.81,224.89,308.81,224.89,308.81z M225.63,306.71
			c0.02,0.04,0.03,0.08,0.05,0.12C225.66,306.79,225.64,306.75,225.63,306.71z"
          />
          <path
            fill="#D4BDAF"
            d="M237.49,285.17l0.52-2.55c0,0,1.55-1.92,2.4-2.31c0.85-0.39,2.58-1.19,3.59-1.06s2.79,0.07,4.2,1.03
			c1.41,0.96,1.83,0.93,2.13,2.1s0.39,1.41,0.33,2.19c-0.06,0.78-1.18,7.04-1.18,7.04s-4.53,0.84-4.62,0.84
			c-0.05,0-1.27-0.77-2.07-1.95c-0.75-1.1-1.08-2.62-1.08-2.62l-0.56-1.28l-1.76,0.45L237.49,285.17"
          />
          <path
            opacity="0.3"
            d="M259.66,406.11c0,0,0.84,1.1-1.45,2.63c-2.28,1.53-2.73,1.26-3.67,2.06c-0.94,0.8-7.79,2.07-19.19,3.59
			c-11.77,1.57-13.95,0.81-19.59-1.72c-5.69-2.55-0.43-1.58,21.21-4.94"
          />
          <g>
            <path
              fill="none"
              d="M256.41,334.52l-0.01,0.03c0.69,0,1.39,0.05,2.08,0.14C258.49,334.69,257.83,334.5,256.41,334.52z"
            />
            <path
              fill="none"
              d="M242.98,297.02c0.02-0.06,0.05-0.12,0.08-0.17c-0.03,0.01-0.06,0.03-0.09,0.04
				C242.98,296.93,242.98,296.97,242.98,297.02z"
            />
            <path
              fill="#D4BDAF"
              d="M258.98,313.75c-0.46,1.75-0.75,8.63-1.12,11.01c-0.1,0.65-0.19,0.92-0.08,1.12
				c0.12,0.2,0.43,0.32,0.26,1.14c-0.11,0.57-0.18,0.97-0.08,1.3c0.88-1.45,2.89-6.48,2.88-7.55c-0.01-1.76,0.06-4.57,0.13-7.32
				C260.83,313.56,260.21,313.89,258.98,313.75z"
            />
            <path
              fill="#D4BDAF"
              d="M256.41,334.54c0,0-0.42-0.02-0.65-0.01C255.75,334.54,256.19,334.54,256.41,334.54z"
            />
            <path
              fill="#D4BDAF"
              d="M236.37,316.92c-1.36-0.6-3.43-1.48-4.03-2.92c-0.45,1.44-1.2,3.84-1.9,6.67c0,0-2.78,3.09-5.68,5.58
				c-2.84,2.44-5.08,3.93-5.87,4.44c-0.26,0.08-0.49,0.14-0.71,0.19c-0.83,0.15-1.77,0.34-1.99,0.44c-0.41,0.18-1.24,1.22-1.43,1.52
				c0,0,0.08,0.08,0.25,0.34c-0.02,0.06-0.04,0.11-0.06,0.15c-0.18,0.37-0.44,2.69-0.24,3.52c0.11,0.45,0.03,0.13,0.39,0.85
				c0.36,0.72,0.81-0.08,0.97-0.14c0,0,0.34,0.39,0.59,0.56c0.25,0.17,0.99,0.08,0.99,0.08s0.53,0.32,0.84,0.17
				c0.31-0.15,0.67-0.43,0.67-0.43s0.15,0.09,0.64-0.04c0.37-0.1,0.65-0.76,0.69-0.84l-0.38-0.23c-0.6,0-0.54-0.68-0.54-1.62
				c0-0.94,0.21-1.26,0.81-1.26l0.45-0.3c0.13,0,0.26,0.04,0.38,0.11c0.22-0.19,0.52-0.39,0.94-0.57c1.73-0.7,5.56-2.94,7.74-4.27
				c0.2-0.12,0.38-0.16,0.56-0.28c4.35-2.87,5.49-5.03,6.69-7.35c0.89-1.72,1.6-3.32,2.15-4.68
				C238.46,317.43,237.78,317.41,236.37,316.92z"
            />
            <path
              fill="#191C1C"
              d="M254.32,402.44c0.37,0.59,1.4,2.09,2.4,1.85c1.24-0.31,2.52-1,2.52-1s0,0.17,0.04,0.43
				c-0.07-0.71-0.01-1.37,0.02-1.67c-0.64,0.38-1.55,0.8-2.68,1.01C255.69,403.23,254.91,402.89,254.32,402.44z"
            />
            <path
              fill="#191C1C"
              d="M254.12,402.27c0,0.12,0,0.24,0,0.36l0.05-0.32C254.16,402.3,254.14,402.28,254.12,402.27z"
            />
            <path
              fill="#1EBEE4"
              d="M256.72,404.28c-1,0.25-2.03-1.25-2.4-1.85c-0.05-0.04-0.1-0.08-0.15-0.13l-0.05,0.32
				c0.01,1.97,0.02,3.24,0.02,3.24c-0.17,0.06-0.34,0.12-0.51,0.17c0,0.03,0.01,0.06,0.01,0.09c0.17,0.07,1.31,0.59,2.3,0.95
				c0.95,0.21,1.79,0.33,2.22,0.25c0.87-0.16,1.3-0.59,1.5-0.86c0.06-0.17,0.09-0.35,0.09-0.52c0.03-0.62-0.21-1.22-0.31-1.54
				c-0.08-0.26-0.13-0.5-0.16-0.69c-0.04-0.26-0.04-0.43-0.04-0.43S257.96,403.97,256.72,404.28z"
            />
            <path
              fill="#FFFFFF"
              d="M258.17,408.4c0.79-0.07,1.18-0.41,1.37-0.78C259.3,408,258.89,408.34,258.17,408.4z"
            />
            <path
              fill="#191C1C"
              d="M258.17,407.32c-0.43,0.08-1.27-0.05-2.22-0.25c-0.99-0.36-2.14-0.87-2.3-0.95
				c0-0.03-0.01-0.06-0.01-0.09c-2.73,0.84-5.08,0.36-6.86-0.42c0.2,0.62,0.66,1.48,1.77,1.8c1.27,0.37,2.45,0.3,3.36,0.07
				c1.6-0.39,1.82-0.66,1.82-0.66l0.07,0.35c1.17,0.45,3.01,1.34,4.38,1.22c0.73-0.06,1.14-0.4,1.37-0.78
				c0.37-0.63,0.27-1.4,0.27-1.4s-0.01,0.04-0.05,0.1c0.01-0.12,0.01-0.25,0-0.37c-0.01,0.18-0.04,0.35-0.09,0.52
				C259.47,406.74,259.03,407.16,258.17,407.32z"
            />
            <path
              fill="#191C1C"
              d="M251.54,410.63c-1.82-0.07-4.18-0.2-10.08-0.93c-5.91-0.74-6.36-2.58-6.36-2.58
				c-0.06-0.19-0.08-0.36-0.09-0.51c-0.03,0-0.04,0-0.04,0s-0.3,1.25,0.88,2.21c1.82,1.48,4.43,1.48,7.34,2
				c2.91,0.52,7.9,0.79,9.54,0.56c1.64-0.22,1.91-0.59,1.82-1.7C254.36,410.41,253.36,410.71,251.54,410.63z"
            />
            <path
              fill="#1EBEE4"
              d="M253.72,406.82c0,0-0.21,0.27-1.82,0.66c-0.91,0.22-2.09,0.29-3.36-0.07c-1.1-0.32-1.57-1.17-1.77-1.8
				c-0.71-0.31-1.32-0.67-1.83-1.02c-0.24,0.08-0.52,0.2-0.86,0.37c0,0-0.83,0.29-3.91,0.38c-2.11,0.06-5.27-0.55-5.18,1.26
				c0.01,0.15,0.04,0.32,0.09,0.51c0,0,0.45,1.84,6.36,2.58c5.91,0.74,8.27,0.86,10.08,0.93c1.82,0.07,2.82-0.22,3-0.96
				c0.18-0.74-0.55-1.45-0.55-1.45l-0.2-1.05L253.72,406.82z"
            />
            <path
              fill="#659896"
              d="M256.42,334.76c0.7-0.01,1.21,0.03,1.56,0.08c0.32,0.04,0.61-0.17,0.68-0.48
				c0.12-0.52,0.21-1.29-0.06-1.95c-0.44-1.1-0.44-1.86-0.11-2.52c0.22-0.43-0.18-0.72-0.39-1.33c-0.11-0.32-0.17-0.73-0.05-1.3
				c0.33-1.64-0.42-0.96-0.19-2.26c0.38-2.14,0.66-8.08,1.02-10.44c0.05-0.31,0.32-0.54,0.63-0.53c0.88,0.01,1.34-0.24,1.48-0.33
				c0.03-0.02,0.05-0.03,0.05-0.03s0.18-3.68,0.09-6.21c0-0.04,0-0.08,0.01-0.12c0.03-0.18,0.05-0.33,0.05-0.46
				c0-1.31-2.19-8.55-4.13-9.06c-3.3-0.87-3.24-1.25-4.26-1.59c-0.33-0.11-0.67-0.22-1.01-0.34c-0.09-0.03-0.17-0.08-0.24-0.14
				c-0.18-0.16-0.29-0.33-0.3-0.5c-0.04-0.5-0.8-1.02-1.52-1.01c-0.57,0.01-1.19-0.07-2.01,0.27c-1.74,0.72-4.02,0.89-4.73,2.16
				c-0.03,0.06-0.08,0.49-0.11,0.55c-0.01,0.03-0.04,0.1-0.05,0.14c-0.11,0.34-0.08,0.4-0.15,0.67c-0.02,0.07-0.03,0.22-0.05,0.35
				c-0.02,0.16-0.09,0.3-0.21,0.4c-0.12,0.1-0.24,0.21-0.35,0.31c-0.05,0.04-0.1,0.08-0.16,0.1c-2.66,1.17-6.24,2.74-7.02,5.47
				c-1.28,4.47-2.03,5.93-2.71,8.02c-0.22,0.68-0.17,1.28,0.05,1.81c0.6,1.44,2.46,2.39,3.82,2.99c0.02,0.01,0.04,0.02,0.06,0.02
				c1.39,0.48,2.38,0.15,3.19-0.67c0.07-0.08,0.15-0.15,0.22-0.24c-0.23,1.83-1.05,8.69-1.05,10.96c0,2.41,0.07,5.9,0.1,9.14
				c0,0.42,0.42,0.72,0.82,0.59c6.42-2.07,16.38-2.57,16.38-2.57"
            />
            <path
              fill="#4D2C1A"
              d="M255.75,334.54c0,0-10.85,0.48-17.2,2.78c0,0,0,0.05-0.01,0.15l0.01-0.15c0,0-0.52,0.35-0.45,3.36
				c0.06,3.01,0,4.1,0,4.1l0.04,0.03c-0.12,2.14-0.24,4.5-0.35,6.76c-0.33,6.68,2.3,21.14,2.96,25.63
				c0.66,4.49,3.08,17.78,2.65,21.51s-0.09,4.54-0.09,4.54s0.59,0.65,1.65,1.37c0.51,0.34,1.12,0.7,1.83,1.02
				c1.77,0.78,4.13,1.25,6.86,0.42c0.17-0.05,0.34-0.11,0.51-0.17c0,0,0-1.27-0.02-3.24c0-0.12,0-0.24,0-0.36
				c0.02,0.01,0.03,0.03,0.05,0.04c0.05,0.04,0.1,0.09,0.15,0.13c0.59,0.46,1.36,0.79,2.3,0.61c1.12-0.21,2.03-0.64,2.68-1.01
				c0.67-0.4,1.05-0.74,1.05-0.74c-0.55-5.15-4.49-32.43-4.49-32.43s0.44-11.17,1.75-19.28c1.31-8.11,0.88-14.9,0.88-14.9
				c-0.69-0.1-1.39-0.14-2.08-0.14C256.19,334.54,255.75,334.54,255.75,334.54z"
            />
            <g>
              <path
                fill="none"
                d="M241.08,288.31c0.15,0.82-0.21,1.93-0.21,1.93C241.35,289.77,241.08,288.31,241.08,288.31z"
              />
              <path
                fill="#D4BDAF"
                d="M249.49,291.61c-1.68-0.98-4.36,0.36-4.36,0.36s-2.96-1.21-3.02-3.56c-0.06-2.33-1.62-2.5-2.15-1.71
					c-0.44,0.66-0.25-0.26-0.63-0.51c-0.88-0.57-1.63-1.03-1.85-1.03c-0.09,0.92-0.02,1.86,0.24,2.79c0.05,0.18,0.07,0.37,0.07,0.54
					c0,0.22-0.01,0.53,0.01,0.67c0.02,0.17,0.07,0.33,0.18,0.48c0.36,0.45-0.11,1.24,0.41,2.48c0.28,0.66,0.41,1.29,0.48,1.77
					c0.02-0.2,4.09,3.22,4.08,2.98c0.03-0.01,0.06-0.03,0.09-0.04c0.72-1.27,3.1-1.33,4.84-2.05c0.82-0.34,1.55-0.51,2.12-0.52
					C249.59,293.41,249.45,292.51,249.49,291.61z M241.08,288.31c0,0,0.28,1.46-0.21,1.93
					C240.87,290.24,241.23,289.13,241.08,288.31z"
              />
            </g>
            <path
              fill="#4D2C1A"
              d="M240.87,290.24c0.49-0.48,0.21-1.93,0.21-1.93C241.23,289.13,240.87,290.24,240.87,290.24z"
            />
            <path
              fill="#5E5D5B"
              d="M219.84,335.34c0-0.94,0.49-1.71,1.08-1.71h-0.75c-0.6,0-1.08,0.76-1.08,1.71s0.49,1.71,1.08,1.71h0.41
				h0.34C220.33,337.05,219.84,336.28,219.84,335.34z"
            />
            <path
              fill="#5E5D5B"
              d="M220.83,333.63c-0.6,0-1.08,0.76-1.08,1.71s0.49,1.71,1.08,1.71c0.6,0,1.08-0.76,1.08-1.71
				c0-0.73-0.29-1.36-0.71-1.6C221.09,333.67,220.96,333.63,220.83,333.63z M220.87,336.97c-0.79,0.27-0.66-1.71-0.66-1.71
				l1.03,0.52C221.24,335.78,221.36,336.8,220.87,336.97z"
            />
            <path
              fill="#191C1C"
              d="M220.21,335.26c0,0-0.13,1.98,0.66,1.71c0.49-0.17,0.37-1.19,0.37-1.19L220.21,335.26z"
            />
          </g>
          <path
            opacity="0.3"
            d="M215.67,332.21c-0.35,0.07-0.8,0.73-0.81,0.75c0,0,0,0,0,0c0.06,0.09,0.06,0.1,0.15,0.22c0,0,0,0,0,0
			C215.01,333.18,215.22,332.63,215.67,332.21z"
          />
          <path
            opacity="0.3"
            d="M256.72,404.28c-1,0.25-2.03-1.25-2.4-1.85c-0.05-0.04-0.1-0.08-0.15-0.13l-0.05,0.32
			c0.01,1.97,0.02,3.24,0.02,3.24c-0.17,0.06-0.34,0.12-0.51,0.17c0,0.03,0.01,0.06,0.01,0.09c0.17,0.07,1.31,0.59,2.3,0.95
			c0.95,0.21,1.79,0.33,2.22,0.25c0.87-0.16,1.3-0.59,1.5-0.86c0.06-0.17,0.09-0.35,0.09-0.52c0.03-0.62-0.21-1.22-0.31-1.54
			c-0.08-0.26-0.13-0.5-0.16-0.69c-0.04-0.26-0.04-0.43-0.04-0.43S257.96,403.97,256.72,404.28z"
          />
          <path
            opacity="0.3"
            d="M253.72,406.82c0,0-1.83,1-3.79,0.74c-1.28-0.16-2.23-1.13-2.73-1.78c-0.84-0.33-1.57-0.74-2.16-1.13
			c-0.54,0.4-2.14,1.49-4.15,1.69c-2.42,0.25-5.73,0.05-5.88,0.04c0,0-0.01,0-0.01,0s-0.04,0.12-0.06,0.31
			c-0.06,0.61,0.07,1.96,2.23,2.88c0.3,0.13,0.6,0.24,0.9,0.33c2.21,0.72,4.49,0.76,6.44,1.13c2.22,0.42,6.4,0.56,7.48,0.43
			c1.08-0.13,2.53,0,2.63-1.22c0.1-1.22-0.17-1.52-0.54-2.1c-0.08-0.13-0.15-0.33-0.21-0.53
			C253.77,407.24,253.72,406.82,253.72,406.82z"
          />
          <path
            opacity="0.3"
            d="M241.06,320.64c2.72-2.76,5.53-6.07,3.93-5.23c-1.61,0.84-6.06,6.42-4.17,2.28
			c1.89-4.14,6.15-6.97,3.8-5.66s-4.07,4.46-4.07,4.46s0.75-2.67,1.92-5.9c1.17-3.23,1.27-5.76-0.1-2.19
			c-0.87,2.27-1.61,3.85-2.04,4.69c0.51-2.49,1.12-5.58,0.96-5.08c-0.27,0.82-1.09,3.23-1.02,2.18c0.07-1.06-0.34-5.24-0.34-4.22
			c0,1.02-0.53,2.33-1.49,4.77c-1.24,3.16-2.05,4.9-3.08,6.43c-0.25-0.12-0.55-0.29-0.81-0.43c-0.57,0.87-1.12,1.9-1.8,3.44
			c-0.8,2.08-12.27,10.2-13.22,10.44c-0.95,0.24-0.93,0.48-3.11,1.43c-0.96,0.41-1.37,1.57-1.55,2.01
			c-0.06,0.16-0.11,0.34-0.15,0.55c-0.07,0.39-0.08,0.88-0.08,1.4c0,0.52,0.01,0.94,0.41,1.56c0.13,0.2,0.15,0.38,0.42,0.43
			c0.18,0.03,0.62-0.45,0.62-0.45c0.41,0.44,0.49,0.53,0.71,0.62c0.23,0.1,0.87,0.03,0.87,0.03s0.44,0.29,0.78,0.18
			c0.34-0.1,0.73-0.45,0.73-0.45s0.37,0.14,0.81-0.13c0.27-0.17,0.53-0.75,0.53-0.75s-0.18,0.03-0.42,0.01
			c-0.4-0.04-0.89-0.53-0.96-1.43c-0.11-1.46,0.7-1.96,0.95-1.97c0.32-0.01,0.66-0.01,0.8-0.01c0.04,0,0.08,0.01,0.12,0.02
			l0.23,0.09c0,0,0.33-0.27,0.6-0.41c0.17-0.09,0.21-0.1,1.33-0.61c0.43-0.2,0.92-0.48,1.54-0.8c0.07-0.04,0.14-0.07,0.21-0.11
			c1.22-0.63,3.6-2,5.29-3.05c0.11-0.07,0.22-0.13,0.32-0.2c0.36-0.23,0.66-0.43,0.91-0.6c0.38-0.26,0.65-0.47,0.88-0.66
			c0.29-0.24,0.5-0.42,0.77-0.62c0.87-0.63,1.84-1.82,2.77-3.12c0.22-0.3,0.41-0.57,0.53-0.8c0.14-0.28,0.29-0.57,0.45-0.87
			c0.33-0.64,0.67-1.3,0.97-1.91c0.22-0.43,0.41-0.84,0.57-1.18c0.15-0.34,0.34-0.76,0.51-1.18c0.13-0.32,0.14-0.32,0.25-0.59
			c0,0,0.23-0.22,0.23-0.23c0.06-0.07,0.12-0.14,0.17-0.2c-0.16,1.41-0.43,2.98-0.59,4.8c-0.15,1.72-0.36,3.48-0.44,5.37
			c-0.04,0.88,0.01,1.86-0.01,2.74c-0.04,1.66-0.03,3.31,0.03,4.89c0.02,0.37,0.03,0.69,0.04,0.97
			C238.85,342.48,238.44,323.29,241.06,320.64z"
          />
          <path opacity="0.3" d="M242.26,327.97C241.63,328.41,241.55,328.58,242.26,327.97L242.26,327.97z" />
          <path
            opacity="0.3"
            d="M255.04,329.49c-3.07-0.66-8.54-1.75-11.94,0.99c0,0,7.56-1.97,11.17-0.11c3.4,1.75,3.96,1.16,4.02,1.08
			c0-0.01,0,0,0,0s0.03-0.11,0.02-0.15c-0.02-0.08-0.02-0.22-0.03-0.41c-0.02-0.3,0.04-0.61,0.28-1.12
			c0.03-0.06,0.02-0.36-0.07-0.43C258.34,329.23,257.4,330,255.04,329.49z"
          />
          <path
            opacity="0.3"
            d="M256.44,334.54l-0.03,0l-0.64,0c0,0-6.28,0.22-12.1,1.42c-1.74,0.36-3.63,0.78-5.11,1.35
			c0,0,7.57,0.37,10.27,1.21c3.63,1.14,4.52,6.58,4.73,10.43c-0.07,0.41-0.15,0.86-0.23,1.36c-0.4,2.43-0.97,9.89-1.6,11.83
			c-0.45,1.39-0.66,2.53-0.72,3.46c-0.21,0.83-1.01,3.9-1.8,6.37c-0.93,2.88,0.93-0.93,1.76-1.76c0.83-0.83,0.74,2.13,1.02,4.54
			c0.28,2.41,1.39,3.62,1.39,3.62s0-0.17-0.01-0.45c0,0,0,0,0,0.01c0.49,3.52,0.44,13.92,0.58,16.54c0.15,2.61,0.26,7.8,0.26,7.8
			s0.07,0.14,0.24,0.31c0.46,0.47,1.55,0.96,4.08-0.06c0.57-0.23,1.14-0.73,1.83-1.23c0,0-0.59-4.58-1.36-10.4
			c-1.13-8.48-2.62-19.43-3.11-21.96c-0.04-0.19,0.02-1.22,0.13-2.7c0.31-3.92,0.66-11.08,1.29-14.43
			c0.94-5.03,1.55-13.46,1.19-17.13l-1.41-0.13l-0.25-0.01L256.44,334.54z"
          />
          <path
            opacity="0.3"
            d="M240.09,343.22c-1.46-3.59-1.55-5.37-1.54-5.86c0-0.01,0-0.03,0-0.04c0,0.01,0,0.03,0,0.04
			C238.18,342.87,241.83,347.5,240.09,343.22z"
          />
          <path
            opacity="0.3"
            d="M261.2,306.88c0-0.13-0.01-0.14-0.01-0.14c0,0,0.01-0.13,0-0.1c-0.02-0.32-0.08-0.45-0.15-0.77
			c-0.26-1.33-0.8-2.81-1.31-4.12c-0.33-0.86-0.62-1.42-0.7-1.57c-0.28-0.52-0.19,2.01-0.08,5c0.3,8.28-7.04,10.5-4.61,18.95
			c0.1,0.36,0.08,0.63-0.03,0.83c-2.62-0.51-7.3-1.5-11.08,2.08c-0.46,0.43-0.8,0.74-1.05,0.96c1.22-0.85,4.47-2.69,7.95-1.73
			c5.92,1.64,8.1,2.63,8.1,2.63s-0.06-0.06-0.12-0.32c-0.01-0.06-0.09-0.25-0.1-0.33c0.04-0.08,0.17-0.26,0.39-0.76
			c0.15-0.34,0.39-0.82,0.59-1.28c0.78-1.8,1.83-4.49,1.84-5.42c0.02-1.53,0.05-4.13,0.13-7.06c0-0.02,0.06-0.05,0.06-0.05
			c0-0.04,0.02-0.29,0.02-0.33c0.05-2.13,0.15-4.04,0.1-5.32c0-0.1-0.02-0.53-0.02-0.62C261.18,307.13,261.2,307.16,261.2,306.88z"
          />
          <polygon opacity="0.3" points="261.17,306.63 261.17,306.63 261.17,306.63 		" />
          <path
            fill="#4D2C1A"
            d="M240.83,288.43c-0.09-0.21-0.18-0.32-0.41-0.56c-0.23-0.24-0.18-0.62-0.03-0.72
			c0.22-0.15,0.59,0.48,0.59,0.48c-0.13-0.3-0.29-0.73-0.57-0.75c-0.28-0.02-0.34,0.27-0.37,0.46c-0.03,0.18,0.03,0.92,0.25,1.12
			c0.22,0.21,0.33-0.13,0.41,0.07c0.09,0.2,0.15,0.36,0.15,0.36S240.92,288.64,240.83,288.43z"
          />
          <path
            fill="#4D2C1A"
            d="M240.2,288.64C240.2,288.64,240.1,288.48,240.2,288.64c0.1,0.16,0.24,0.44,0.2,0.8
			c-0.04,0.36,0.11,0.51,0.17,0.29s0.15-0.77,0.05-0.88S240.38,288.88,240.2,288.64z"
          />
          <path
            fill="#4D2C1A"
            d="M237.91,289.3c0.25,0.13,0.45,0.3,0.36-0.05c-0.07-0.3-0.38-0.64-0.47-0.74
			c-0.02-0.02-0.03-0.03-0.03-0.03c-0.09,0.23-0.04,0.54,0.04,0.71C237.85,289.24,237.88,289.29,237.91,289.3z"
          />
          <path
            fill="#4D2C1A"
            d="M242.98,297.02c0-0.04-0.01-0.09-0.01-0.13c-0.02-0.14-0.07-0.27-0.13-0.36
			c-0.73-1.09-1.43-3.59-1.98-4.51s-0.97-2.78-0.9-3.26c0.07-0.49-0.43-1.38,0.01-2.04c0.53-0.79,2.08-0.62,2.15,1.71
			c0.06,2.34,3.02,3.56,3.02,3.56s2.67-1.34,4.36-0.36c0,0,0,0,0,0c0,0,1.42-5.51,1.85-6.82c0.43-1.32,0.27-3.98,0.27-3.98
			s-0.2,0.87-0.38,1.31c-0.18,0.44-0.35-2.38-0.35-2.38s-0.02,0.38-0.41,0.94c-0.38,0.57-0.27-0.08-2.4-1.3
			c-1.4-0.8-2.92-1.27-4.3-1.38c-0.72-0.06-1.4-0.02-2,0.12c0,0,1.42,0.1,2.19,0.25c-0.24-0.04-2.65-0.37-5.79,2.77
			c0,0-1.15,1.59-1.8,3.57c0,0,0.56-0.95,1.02-1.31c0,0-0.51,1.64-0.51,2.98c0,0,0.31-0.83,0.55-1.17c0.02-0.03,0.04-0.04,0.06-0.04
			c0.23-0.01,0.51,1.55,1.39,2.12c0.96,0.62-0.27,2.59-0.32,3.34c-0.04,0.72,0.42,2.29,0.34,3.28c0,0.04-0.01,0.08-0.01,0.12
			c-0.14,0.97,0.31,1.26,0.66,2.19c0.34,0.93,0.32,3.03,1.87,2.63c0.61-0.16,1.01-0.47,1.26-0.81c0.07-0.27,0.16-0.58,0.28-0.92
			C242.96,297.08,242.97,297.05,242.98,297.02z"
          />
          <path
            opacity="0.3"
            d="M240.84,280.33c0,0,1.9-0.15,3.64,0.09c0.02,0,0.03,0,0.05,0.01c-0.87-0.02-2.92,0.14-4.85,1.48
			c0,0,2.5,1.33,2.47,3.29c-0.03,1.96,4.01,5.23,4.61,6.13c0.02,0.03,0.03,0.05,0.05,0.08c0.56,0.87,0.71,1.58,0.71,1.58
			s0.1,0.93-0.54,2.13c-0.46,0.87-1.31,1.87-2.86,2.77c-0.49,0.29,3.58-1.58,7.56-1.56c1.63,0.01,2.83,0.66,2.83,0.66
			c-0.67-0.27-1.11-0.61-1.94-0.84c-1.08-0.3-1.15-0.5-1.37-1.05c0,0-0.11-0.57-1.01-0.82c-0.08-0.02-0.15-0.04-0.19-0.08
			c-0.02-0.02-0.04-0.05-0.05-0.08c-0.01-0.03-0.02-0.05-0.02-0.08c-0.13-0.43-0.31-1.01-0.42-1.78c-0.03-0.2,0.01-0.41,0-0.64
			c0,0,0.03-0.02,0.04-0.08c0.03-0.3,0.28-1.03,0.52-1.95c0.07-0.28,0.17-0.74,0.29-1.19c0.38-1.42,0.89-3.12,1.13-4.11
			c0.29-1.2,0.14-3.47,0.14-3.47l-0.36,1.23c-0.04,0.18-0.11,0.13-0.12,0.04l-0.24-2.33c0,0,0.02,0.05,0.01,0.11
			c-0.05,0.2-0.11,0.48-0.41,0.83c-0.14,0.17-0.11,0.44-0.62-0.05c-0.48-0.45-1.41-0.82-1.85-0.98c-0.14-0.05-0.28-0.12-0.42-0.19
			C247,279.18,244.91,278.44,240.84,280.33z"
          />
          <linearGradient
            id="Card22MSVGID56"
            gradientUnits="userSpaceOnUse"
            x1="219.0799"
            y1="335.346"
            x2="220.9136"
            y2="335.346"
          >
            <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
          </linearGradient>
          <path
            fill="url(#Card22MSVGID56)"
            d="M219.83,335.35c0-0.94,0.49-1.71,1.08-1.71h-0.75c-0.6,0-1.08,0.76-1.08,1.71
			c0,0.94,0.49,1.71,1.08,1.71h0.41h0.34C220.31,337.05,219.83,336.29,219.83,335.35z"
          />
          <g>
            <path
              fill="none"
              d="M183.38,418.21c0.01,0.02,0.03,0.07,0.08,0.12c-0.02-0.09-0.04-0.18-0.04-0.27
				C183.38,418.14,183.38,418.19,183.38,418.21z"
            />
            <path
              opacity="0.3"
              d="M230.82,430.95c0.18-0.45-0.08-1.01-0.77-1.67c-3.69-3.53-12.28-4.77-21.5-5.83
				c-2.75-0.32-5.69-0.58-8.53-0.84c-1.97-0.18-3.76-0.35-5.38-0.51c5.49,0.71,17.48,2.34,24.49,3.87c4.93,1.08,12,3.79,10.95,5.8
				C230.55,431.43,230.74,431.14,230.82,430.95z"
            />
            <path
              opacity="0.3"
              d="M201.84,411.84c-6.84,1.34-14.59,2.86-17.94,5.69c-0.28,0.24-0.42,0.42-0.48,0.53
				c0,0.09,0.01,0.18,0.04,0.27c0.22,0.25,1,0.78,3.87,1.33c-1.3-1.8,2.09-4.06,8.66-5.78c7.92-2.07,22.11-4.96,26.06-5.65
				c5.28-0.93,2.01-1.8-1.23-1.17c-1.5,0.29-3.32,0.57-5.49,1c-1.61,0.78-3.45,1.47-5.55,2.05
				C207.58,410.71,204.79,411.26,201.84,411.84z"
            />
            <path
              opacity="0.3"
              d="M162.66,449.76c-0.03,0.07-2.49-1.17-2.67-1.32c-0.18-0.15-0.39-0.3-0.61-0.44
				c-0.44-0.29-0.93-0.55-1.44-0.79c-1.02-0.49-2.1-0.91-3.19-1.29c-2.2-0.75-4.47-1.38-6.74-1.82c-1.78-0.34-3.57-0.71-5.35-1.15
				c4.6,1.71,13.23,5.12,14.93,7.22c0.14-0.04,1.06,2.16,0.77,2.24c-0.61,0.16-1.21,0.29-1.82,0.41c-2.42,0.46-4.82,0.72-7.22,0.92
				c-2.4,0.2-4.8,0.34-7.19,0.43c-4.79,0.2-9.58,0.25-14.35,0.26l-14.3,0.06l-14.3,0.06l-1.79,0.01l-0.89,0
				c-0.31,0-0.62,0-0.93-0.01c-1.25-0.03-2.54-0.13-3.86-0.45c-0.66-0.16-1.32-0.38-1.96-0.69c-0.62-0.3-1.23-0.68-1.77-1.14
				c-5.82,0.72-8.58,2.79-2.22,3.71c8.79,1.28,49.78,1.81,70.3-0.38c6.07-0.65,7.84-2.11,8-3.66
				C164.15,450.81,163.48,450.05,162.66,449.76z"
            />
            <path opacity="0.3" d="M160.48,449.01C160.48,449.01,160.48,449.01,160.48,449.01L160.48,449.01z" />
            <path opacity="0.3" d="M136.03,438.28C136.03,438.28,136.03,438.28,136.03,438.28L136.03,438.28z" />
            <path
              opacity="0.3"
              d="M232.29,431.31c0,0-0.03,0.02-0.08,0.05c-0.01,0.04-0.03,0.08-0.05,0.12c-0.46,1.14-1.86,2.08-4.28,2.86
				c-1.88,0.61-4.4,1.12-7.48,1.54c-1.75,0.24-3.54,0.42-5.26,0.56c-0.75,0.06-1.51,0.13-2.26,0.18c-2.39,0.16-4.78,0.27-7.19,0.29
				l-7.15,0.03l-28.61,0.11l-28.61,0.11c-1.14,0-2.27,0.1-3.34,0.32c-0.53,0.11-1.05,0.27-1.47,0.46c-0.16,0.07-0.3,0.16-0.39,0.23
				c0.03,0.03,0.06,0.06,0.09,0.09c0.08,0.08,0.19,0.16,0.31,0.24c0.87-0.13,2.25-0.29,4.45-0.45c3.82-0.28,35.87,0.46,42.81,0.46
				c6.94,0,27.55,1.38,43.93-2.1C237.8,434.28,232.29,431.31,232.29,431.31z"
            />
            <path
              fill="#191C1C"
              d="M136.13,438.18c0.09-0.07,0.23-0.16,0.39-0.23c0.42-0.2,0.94-0.35,1.47-0.46
				c1.07-0.23,2.21-0.32,3.34-0.32l28.61-0.11l28.61-0.11l7.15-0.03c2.41-0.02,4.8-0.13,7.19-0.29c0.75-0.05,1.51-0.11,2.26-0.18
				c1.72-0.14,3.51-0.32,5.26-0.56c3.08-0.41,5.59-0.93,7.48-1.54c2.42-0.78,3.82-1.71,4.28-2.86c0.02-0.04,0.03-0.08,0.05-0.12
				c0.25-0.73,0.21-1.83-1.15-3.13c-4.02-3.84-12.86-5.14-22.33-6.22c-2.77-0.32-5.72-0.59-8.56-0.84c-4.39-0.4-8.54-0.78-11.69-1.3
				c-0.41-0.07-0.78-0.13-1.13-0.2c-2.87-0.56-3.66-1.08-3.87-1.33c-0.05-0.06-0.07-0.1-0.08-0.12c-0.01-0.02,0-0.07,0.04-0.15
				c0.06-0.12,0.19-0.29,0.48-0.53c3.35-2.83,11.1-4.35,17.94-5.69c2.95-0.58,5.74-1.13,7.95-1.74c2.1-0.58,3.93-1.27,5.55-2.05
				c11.81-5.69,11.51-16.28,11.24-25.76c-0.04-1.42-0.08-2.88-0.08-4.28c0-1.56-0.23-12.84-0.43-22.8
				c-0.15-7.64-0.29-14.25-0.29-14.94c0-2.13-0.57-3.71-1.7-4.7c-1.42-1.23-3.17-1.01-3.24-1c-0.39,0.05-0.67,0.42-0.61,0.81
				c0.05,0.39,0.42,0.67,0.81,0.62c0.01,0,1.2-0.14,2.11,0.67c0.79,0.7,1.19,1.91,1.19,3.59c0,0.71,0.13,7.32,0.29,14.97
				c0.2,9.95,0.43,21.22,0.43,22.77c0,1.42,0.04,2.89,0.08,4.32c0.15,5.35,0.31,10.88-1.62,15.57c-2.16,5.24-6.77,8.77-14.11,10.8
				c-2.15,0.6-4.92,1.14-7.84,1.71c-7.01,1.37-14.96,2.93-18.59,6c-0.87,0.74-1.2,1.49-0.97,2.22c0.57,1.81,4.39,2.6,12.64,3.44
				c1.62,0.17,3.41,0.34,5.38,0.51c2.84,0.26,5.77,0.52,8.53,0.84c9.22,1.06,17.8,2.3,21.5,5.83c0.69,0.66,0.95,1.22,0.77,1.67
				c-0.08,0.2-0.27,0.48-0.74,0.81c-1.1,0.79-3.7,1.85-9.87,2.69c-1.36,0.18-2.75,0.34-4.11,0.46c-1.1,0.1-2.21,0.18-3.31,0.25
				c-2.37,0.14-4.74,0.23-7.1,0.23l-7.15-0.03l-28.61-0.11l-28.61-0.11c-1.25-0.01-2.5,0.08-3.77,0.34c-0.63,0.13-1.27,0.3-1.93,0.6
				c-0.33,0.16-0.68,0.34-1.03,0.68c-0.17,0.17-0.36,0.39-0.49,0.7c-0.13,0.3-0.18,0.7-0.11,1.04c0.06,0.34,0.2,0.6,0.34,0.8
				c0.14,0.21,0.29,0.37,0.44,0.51c0.3,0.28,0.59,0.48,0.89,0.66c0.59,0.36,1.17,0.62,1.75,0.86c1.17,0.48,2.34,0.84,3.51,1.17
				c0.57,0.16,1.14,0.31,1.71,0.45c1.78,0.44,3.57,0.81,5.35,1.15c2.27,0.45,4.55,1.07,6.74,1.82c1.1,0.38,2.18,0.8,3.19,1.29
				c0.51,0.24,1,0.51,1.44,0.79c0.22,0.14,0.43,0.29,0.61,0.44c0.18,0.15,0.33,0.3,0.42,0.43c0.03,0.04,0.04,0.07,0.06,0.1
				c-0.01,0.01-0.02,0.02-0.03,0.04c-0.08,0.09-0.25,0.21-0.45,0.32c-0.2,0.11-0.43,0.21-0.67,0.31c-0.54,0.21-1.13,0.38-1.74,0.53
				c-0.49,0.12-0.99,0.22-1.49,0.32c-2.27,0.41-4.62,0.65-6.97,0.83c-2.35,0.18-4.72,0.29-7.09,0.37
				c-4.74,0.16-9.49,0.17-14.25,0.15l-14.3-0.06l-14.3-0.06l-1.79-0.01l-0.89,0c-0.29,0-0.57-0.01-0.86-0.01
				c-1.13-0.04-2.23-0.14-3.23-0.39c-1-0.25-1.87-0.67-2.57-1.28c-0.7-0.61-1.25-1.43-1.68-2.38c-0.42-0.95-0.72-2.01-0.95-3.11
				c-0.23-1.1-0.38-2.23-0.49-3.38c-0.22-2.3-0.28-4.64-0.27-7l0.06-14.3l0.11-28.61l0.06-14.3l0-0.9c0-0.31,0-0.63-0.01-0.95
				c-0.02-0.64-0.06-1.29-0.15-1.95c-0.09-0.66-0.21-1.34-0.43-2.05c-0.22-0.7-0.53-1.44-1.05-2.13c-0.51-0.69-1.24-1.3-2-1.66
				c-0.76-0.38-1.52-0.56-2.21-0.68c-0.7-0.11-1.36-0.16-2-0.19c-0.64-0.03-1.25-0.03-1.85-0.03h-0.01c-1,0-1.81,0.81-1.8,1.81
				c0,1,0.81,1.81,1.81,1.8h0c1.17,0,2.32,0,3.28,0.15c0.48,0.07,0.89,0.19,1.2,0.34c0.31,0.15,0.53,0.33,0.73,0.6
				c0.41,0.52,0.69,1.47,0.82,2.51c0.07,0.52,0.11,1.06,0.13,1.61c0.01,0.28,0.02,0.56,0.02,0.84l0,0.89l0.06,14.3l0.11,28.61
				l0.06,14.31c0.02,2.41,0.09,4.84,0.34,7.29c0.13,1.22,0.3,2.45,0.57,3.69c0.27,1.23,0.62,2.48,1.17,3.71
				c0.28,0.61,0.61,1.22,1.02,1.79c0.4,0.57,0.89,1.12,1.44,1.59c0.01,0.01,0.03,0.02,0.05,0.04c0.54,0.46,1.14,0.84,1.77,1.14
				c0.64,0.31,1.31,0.53,1.96,0.69c1.32,0.32,2.6,0.42,3.86,0.45c0.31,0.01,0.63,0.01,0.93,0.01l0.89,0l1.79-0.01l14.3-0.06
				l14.3-0.06c4.77-0.01,9.56-0.06,14.35-0.26c2.39-0.1,4.79-0.23,7.19-0.43c2.4-0.21,4.8-0.46,7.22-0.92
				c0.6-0.12,1.21-0.25,1.82-0.41c0.61-0.16,1.22-0.34,1.84-0.6c0.31-0.13,0.63-0.27,0.95-0.45c0.32-0.18,0.65-0.4,0.98-0.74
				c0.16-0.17,0.33-0.38,0.46-0.65c0.03-0.06,0.06-0.13,0.09-0.2c0.08-0.22,0.14-0.47,0.14-0.72c0.01-0.33-0.06-0.63-0.16-0.89
				c-0.1-0.26-0.23-0.47-0.36-0.65c-0.26-0.36-0.55-0.62-0.82-0.85c-0.28-0.23-0.56-0.42-0.84-0.6c-0.56-0.36-1.13-0.65-1.7-0.92
				c-1.14-0.53-2.29-0.97-3.44-1.36c-2.32-0.77-4.66-1.4-7.07-1.85c-2.33-0.43-4.64-0.9-6.9-1.5c-1.12-0.31-2.24-0.65-3.28-1.06
				c-0.52-0.21-1.02-0.44-1.46-0.7c-0.08-0.05-0.16-0.1-0.24-0.15c-0.12-0.08-0.23-0.16-0.31-0.24
				C136.18,438.24,136.15,438.21,136.13,438.18z M160.48,449.01C160.48,449.01,160.48,449.01,160.48,449.01L160.48,449.01z
				 M136.03,438.28C136.03,438.28,136.03,438.28,136.03,438.28L136.03,438.28z"
            />
          </g>
          <g>
            <path
              fill="none"
              d="M64.29,292.05c1.49-0.39,12.09-3.52,12.09-3.52s-18.92-2.3-19.49-2.39c-0.57-0.09-1.81,0.07-1.68,1
				c0.04,0.3,0.15,0.97,0.26,1.71l0.01,0l0.42,2.7c1.19,0.18,2.22,0.31,3.1,0.41C61.05,292.18,63.36,292.29,64.29,292.05z"
            />
            <path
              fill="none"
              d="M35.12,290.57l1.87,1.85l2.59,2.57c0,0,2.12-0.67,3.31-1.03c1.19-0.35,1.9-0.54,1.9-1.41
				c0-0.1-0.02-0.25-0.07-0.44L35.12,290.57z"
            />
            <path
              fill="none"
              d="M42.85,286.51c-1.93,0.47-6.08,1.35-8.27,1.81c0,0,4.52,0.67,9.41,1.41
				C43.46,288.14,42.85,286.51,42.85,286.51z"
            />
            <polygon fill="none" points="33.15,288.62 33.15,288.62 33.16,288.62 			" />
            <path
              fill="#1EBEE4"
              d="M86.44,284.73c-2.55-0.41-35.76-5.47-40.12-5.65c-4.35-0.17-7.16,1.09-8.47,1.51
				c-1.31,0.42-12.94,3.66-13.82,4.11c-1.31,0.67-1.8,2.14-1.9,2.74c0,0,0,0,0,0c0.01-0.13,0.14-0.25,0.38-0.37
				c1.32-0.65,11.46-3.33,13.58-4.02c2.12-0.7,4.58-2.13,9.97-1.82c5.39,0.3,37.93,4.84,40.74,5.32c1.68,0.29,2.23,0.67,2.14,1.04
				c0,0,0.01,0.01,0.01,0.01C88.96,287.6,89.39,285.2,86.44,284.73z"
            />
            <path
              opacity="0.3"
              d="M44.31,460.64l-1.32-0.13c-0.23-0.01-0.45-0.03-0.68-0.06l-7.41-0.71c-1.77-0.17-3.1-1.29-3.1-2.62v-0.95
				c-15.77,2.2-31.67,4.42-32.49,4.51c-1.65,0.19-4.76,0.61-2.15,2.18c3.03,1.82,8.76,5.15,10.51,5.92
				c1.74,0.77,2.84,0.88,4.56,0.59c11.17-1.93,53.68-9.01,61.94-10.61l-29.1,1.89C44.82,460.66,44.56,460.66,44.31,460.64z"
            />
            <path
              fill="#B0B0B0"
              d="M76.86,457.99c0.46-0.24,0.69-0.51,0.75-0.77C77.43,457.5,77.17,457.77,76.86,457.99z"
            />
            <path
              fill="#5E5D5B"
              d="M86.81,286.55c-2.81-0.48-35.35-5.01-40.74-5.32c-5.39-0.3-7.85,1.13-9.97,1.82
				c-2.12,0.7-12.26,3.37-13.58,4.02c-0.24,0.12-0.36,0.24-0.38,0.37c-0.06,0.57,2.15,1.27,7.47,2.18
				c1.41,1.46,8.5,10.39,10.5,12.91l2.66-0.27c0.67-0.08,1.74-0.08,1.91-0.12l0.58,0.04c0.22,0,0.42,0.01,0.57,0.03l11.82,0.84
				l0.01,0.01l0.01,0l0,0l0-0.01l0.04,0l-1.52-9.58c0,0,0.24,0.03,0.64,0.08c0.68,0.09,1.94,0.26,3.88,0.58
				c1.05,0.17,4.15,0.64,5.91,0.39c1.7-0.24,4.43-0.94,6.84-1.67c2.41-0.74,13.41-4.04,14.61-4.56c0.48-0.21,0.82-0.45,0.88-0.7
				C89.04,287.22,88.49,286.84,86.81,286.55z M44.59,291.62L44.59,291.62l-9.9-1.47l-0.04-0.01l-1.5-1.52h0c0,0,0,0,0.01,0l1.42-0.3
				l0,0c2.19-0.46,6.34-1.34,8.27-1.81c0,0,0.61,1.63,1.14,3.22c0.02,0,0.04,0.01,0.06,0.01L44.59,291.62z M42.87,293.96
				c-1.19,0.35-3.31,1.03-3.31,1.03l-2.59-2.57l-1.87-1.85l9.59,1.55c0.04,0.19,0.07,0.34,0.07,0.44
				C44.77,293.43,44.06,293.61,42.87,293.96z M78.32,289.66c-0.92,0.48-7.33,2.17-11.4,3.43c-4.07,1.26-5.27,0.87-7.33,0.61
				c-0.42-0.05-1.68-0.22-3.44-0.47l-0.26-1.68c0.71,0.13,1.86,0.29,3.1,0.41c-0.88-0.1-1.91-0.23-3.1-0.41l-0.42-2.7l-0.01,0
				c-0.11-0.74-0.22-1.41-0.26-1.71c-0.13-0.93,1.11-1.09,1.68-1c0.57,0.09,19.49,2.39,19.49,2.39
				C78.09,288.83,79.24,289.18,78.32,289.66z"
            />
            <path
              fill="#1EBEE4"
              d="M45.28,302.19l-0.58-0.04c-0.17,0.04-1.25,0.04-1.91,0.12C43.59,302.26,44.57,302.19,45.28,302.19z"
            />
            <path
              fill="#5E5D5B"
              d="M57.67,303.06l-11.82-0.84c-0.15-0.02-0.35-0.03-0.57-0.03L57.67,303.06L57.67,303.06z"
            />

            <rect
              x="57.7"
              y="303.04"
              transform="matrix(0.0703 -0.9975 0.9975 0.0703 -248.6742 339.3028)"
              fill="#5E5D5B"
              width="0.01"
              height="0.04"
            />
            <path
              fill="#1EBEE4"
              d="M57.68,303.07L57.68,303.07L57.68,303.07l-12.4-0.88c-0.7,0-1.68,0.07-2.5,0.08l-2.66,0.27l-6.01,0.6
				l7.53-0.73c1.29-0.11,1.92-0.11,2.48,0.14c1.4,0.62,1.52,1.43,1.52,3.46c0,2.03-0.08,150.36-0.08,151.47
				c0,2.71-1.23,3.09-2.57,3.03l1.32,0.13c0.25,0.02,0.51,0.03,0.76,0.01l29.1-1.89l0.54-0.04c0.84-0.05,1.59-0.33,2.16-0.73
				c0.31-0.22,0.56-0.48,0.75-0.77c0.22-0.34,0.34-0.72,0.34-1.12v-149.2c0-1.27-1.21-2.36-2.89-2.59l-17.34-1.22L57.68,303.07z"
            />
            <linearGradient
              id="Card22MSVGID57"
              gradientUnits="userSpaceOnUse"
              x1="31.8001"
              y1="381.4068"
              x2="77.9514"
              y2="381.4068"
            >
              <stop offset="0.1019" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2683" style={{ stopColor: "#222221", stopOpacity: 0.2268 }} />
              <stop offset="0.3384" style={{ stopColor: "#161616", stopOpacity: 0.2038 }} />
              <stop offset="0.4571" style={{ stopColor: "#090909", stopOpacity: 0.1648 }} />
              <stop offset="0.6143" style={{ stopColor: "#020202", stopOpacity: 0.1132 }} />
              <stop offset="0.9595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID57)"
              d="M57.68,303.07L57.68,303.07L57.68,303.07l-12.4-0.88c-0.79-0.08-1.65-0.02-2.5,0.08l-2.66,0.27
				l-6.01,0.6l7.53-0.73c1.29-0.11,1.88-0.18,2.44,0.07c1.4,0.62,1.56,1.51,1.56,3.53c0,2.03-0.08,150.36-0.08,151.47
				c0,2.71-1.23,3.09-2.57,3.03l1.32,0.13c0.25,0.02,0.51,0.03,0.76,0.01l29.1-1.89l0.54-0.04c0.84-0.05,1.59-0.33,2.16-0.73
				c0.31-0.22,0.56-0.48,0.75-0.77c0.22-0.34,0.34-0.72,0.34-1.12v-149.2c0-1.27-1.21-2.36-2.89-2.59l-17.34-1.22L57.68,303.07z"
            />
            <path fill="#B0B0B0" d="M42.31,460.45c0.23,0.03,0.45,0.05,0.68,0.06l-8.09-0.78L42.31,460.45z" />
            <path
              fill="#1EBEE4"
              d="M34.9,459.73l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				c0-2.03-0.12-2.84-1.52-3.46c-0.56-0.25-1.19-0.25-2.48-0.14l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C31.8,458.44,33.13,459.56,34.9,459.73z"
            />
            <linearGradient
              id="Card22MSVGID58"
              gradientUnits="userSpaceOnUse"
              x1="31.8001"
              y1="381.4269"
              x2="77.9514"
              y2="381.4269"
            >
              <stop offset="0.1019" style={{ stopColor: "#FFFFFF", stopOpacity: 0.1 }} />
              <stop offset="0.2683" style={{ stopColor: "#222221", stopOpacity: 0.2268 }} />
              <stop offset="0.2761" style={{ stopColor: "#2C2C2B", stopOpacity: 0.2355 }} />
              <stop offset="0.3148" style={{ stopColor: "#585857", stopOpacity: 0.2788 }} />
              <stop offset="0.3575" style={{ stopColor: "#80807F", stopOpacity: 0.3266 }} />
              <stop offset="0.4033" style={{ stopColor: "#A2A2A2", stopOpacity: 0.3778 }} />
              <stop offset="0.4531" style={{ stopColor: "#BFBFBF", stopOpacity: 0.4335 }} />
              <stop offset="0.5081" style={{ stopColor: "#D7D7D7", stopOpacity: 0.4951 }} />
              <stop offset="0.5707" style={{ stopColor: "#E9E9E9", stopOpacity: 0.565 }} />
              <stop offset="0.6452" style={{ stopColor: "#F6F6F5", stopOpacity: 0.6484 }} />
              <stop offset="0.7436" style={{ stopColor: "#FDFDFD", stopOpacity: 0.7585 }} />
              <stop offset="0.9595" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID58)"
              d="M34.9,459.73l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				c0-2.03-0.12-2.84-1.52-3.46c-0.56-0.25-1.19-0.25-2.48-0.14l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C31.8,458.44,33.13,459.56,34.9,459.73z"
            />
            <path
              opacity="0.3"
              d="M34.9,459.73l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				c0-2.03-0.05-2.91-1.46-3.53c-0.56-0.25-1.26-0.18-2.55-0.07l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C31.8,458.44,33.13,459.56,34.9,459.73z"
            />
            <linearGradient
              id="Card22MSVGID59"
              gradientUnits="userSpaceOnUse"
              x1="33.1545"
              y1="290.3705"
              x2="78.6474"
              y2="290.3705"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID59)"
              d="M65.3,291.8c0.97-0.22,11.08-3.27,11.08-3.27s-10.6,3.13-12.09,3.52
				c-0.93,0.24-3.23,0.13-5.3-0.08C63.9,292.52,64.47,291.98,65.3,291.8z"
            />
            <linearGradient
              id="Card22MSVGID60"
              gradientUnits="userSpaceOnUse"
              x1="33.1544"
              y1="289.972"
              x2="78.6473"
              y2="289.972"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID60)"
              d="M34.58,288.32c-0.82,0.17-1.36,0.29-1.42,0.3l-0.01,0l1.54,1.53l9.89,1.47
				c-0.15-0.53-0.36-1.21-0.59-1.9C39.1,288.99,34.59,288.32,34.58,288.32z"
            />
            <polygon fill="#5E5D5B" points="34.65,290.14 34.7,290.15 33.15,288.62 			" />
            <linearGradient
              id="Card22MSVGID61"
              gradientUnits="userSpaceOnUse"
              x1="33.1544"
              y1="289.3857"
              x2="78.6473"
              y2="289.3857"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <polygon fill="url(#Card22MSVGID61)" points="34.65,290.14 34.7,290.15 33.15,288.62 			" />
            <path
              fill="#5E5D5B"
              d="M76.38,288.53c0,0-10.1,3.05-11.08,3.27c-0.82,0.18-1.4,0.72-6.31,0.17c-1.24-0.12-2.39-0.28-3.1-0.41
				l0.26,1.68c1.75,0.24,3.02,0.41,3.44,0.47c2.06,0.26,3.27,0.65,7.33-0.61c4.07-1.26,10.49-2.95,11.4-3.43
				S78.09,288.83,76.38,288.53z"
            />
            <linearGradient
              id="Card22MSVGID62"
              gradientUnits="userSpaceOnUse"
              x1="33.1545"
              y1="291.2502"
              x2="78.6474"
              y2="291.2502"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID62)"
              d="M76.38,288.53c0,0-10.1,3.05-11.08,3.27c-0.82,0.18-1.4,0.72-6.31,0.17
				c-1.24-0.12-2.39-0.28-3.1-0.41l0.26,1.68c1.75,0.24,3.02,0.41,3.44,0.47c2.06,0.26,3.27,0.65,7.33-0.61
				c4.07-1.26,10.49-2.95,11.4-3.43S78.09,288.83,76.38,288.53z"
            />
            <path
              fill="#5E5D5B"
              d="M44.58,291.62L44.58,291.62l-0.54-1.89c-0.02,0-0.04-0.01-0.06-0.01
				C44.22,290.41,44.44,291.09,44.58,291.62z"
            />
            <linearGradient
              id="Card22MSVGID63"
              gradientUnits="userSpaceOnUse"
              x1="33.1545"
              y1="290.6759"
              x2="78.6474"
              y2="290.6759"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID63)"
              d="M44.58,291.62L44.58,291.62l-0.54-1.89c-0.02,0-0.04-0.01-0.06-0.01
				C44.22,290.41,44.44,291.09,44.58,291.62z"
            />
            <path fill="#5E5D5B" d="M34.58,288.32l-1.42,0.3C33.22,288.61,33.77,288.49,34.58,288.32L34.58,288.32z" />
            <linearGradient
              id="Card22MSVGID64"
              gradientUnits="userSpaceOnUse"
              x1="33.1545"
              y1="288.4698"
              x2="78.6474"
              y2="288.4698"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22MSVGID64)"
              d="M34.58,288.32l-1.42,0.3C33.22,288.61,33.77,288.49,34.58,288.32L34.58,288.32z"
            />
          </g>
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="10" y="122.01" fill="none" width="280.05" height="62.23" />
        <text transform="matrix(1 0 0 1 101.5082 135.79)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="19px">
            Mission accomplished.{" "}
          </tspan>
          <tspan x="60.95" y="29" fontFamily={font.condensed} fontSize="24px">
            MINUS 53%.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 110.8162 72)" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
          delivered
        </text>
      </motion.g>
    </svg>
  );
};
