export const production = {
  title: {
    headline:
      "Um wirklich nachhaltig zu handeln, betrachtet die BMW Group die gesamte Wertschöpfungskette. Wir sprachen mit Patricia Perez Szmak und Jury Witschnig, was das für das Lieferantennetzwerk und die Produktion bedeutet.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        src: "/data/interview/production/image-production-author.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Herr Witschnig, Umweltschutz in der Produktion klingt nach vielen Faktoren, richtig?",
        copytext:
          "<p><strong>Jury Witschnig</strong> Ja, dazu gehören alle wesentlichen Ressourcen für die Produktion – und das hinsichtlich Input und Output. Zum Beispiel Wasser: Da geht es natürlich darum, den Wasserverbrauch zu reduzieren. Aber auch das Abwasser lassen wir nicht außer Acht. Was kann im Kreislauf noch einmal eingesetzt werden? Und welche Umweltwirkung hat das Abwasser, das wir nicht vermeiden können – sprich wie sauber ist es? Ähnlich umfassend betrachten wir auch die Themen Abfall, Energie, Lösemittel usw. Unser Ziel ist eine saubere Produktion – Clean Production –, mit minimalen Auswirkungen auf die Umwelt.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "Orange",
        copytext:
          "<p>Der wohl beeindruckendste Aspekt ist die <strong>ABFALLREDUZIERUNG</strong> in der Produktion mit <strong>MINUS 80%</strong>.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Was haben wir in der Produktion schon erreicht?",
        copytext:
          "<p><strong>Jury Witschnig</strong> In unserem letzten Zielzeitraum – 2006 bis 2020 – schon einiges. Betrachtet man rein die Kennzahlen ist der wohl beeindruckendste Aspekt der, dass wir den Abfall in der Produktion um 80% verringern konnten. Auch bei Wasser, Energie und Lösemitteln haben wir sehr gute Fortschritte gemacht.</p><p>Ein Meilenstein ist, dass wir seit 2020 den Strom für die Werke der BMW Group weltweit zu 100% aus erneuerbaren Energien beziehen, also aus Wasser-, Wind-, Sonnenkraft oder Biokraftwerken. Grünstrom ist ein sehr großer Stellhebel um den CO2-Fußabdruck unserer Werke zu reduzieren.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1440,
        items: [
          { width: 50, height: 38, src: "/data/interview/production/image-gallery-production-1.jpg" },
          { width: 50, height: 38, src: "/data/interview/production/image-gallery-production-2.jpg" },
        ],
        caption: {
          desc:
            "<p>Jury Witschnig verantwortet das Thema Umweltschutz für die Produktion. Patricia Perez Szmak leitet das Projekt Nachhaltigkeit in der Lieferkette.</p>",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Das Ziel bis 2030 ist es, in der Produktion die CO2-Emissionen je Fahrzeug um weitere 80% zu reduzieren. Wie geht das?",
        copytext:
          "<p><strong>Jury Witschnig</strong> Für die bisherigen Ziele sind wir in mehreren Schritten vorgegangen. Zunächst haben wir uns in unserem eigenen Netzwerk nach guten Beispielen für Technologieeffizienz, sogenannten Best-Practice-Lösungen, umgeschaut. Wir haben uns die Produktionsprozesse im Detail vorgenommen und Technologien weiterentwickelt. Beispielsweise haben wir in den Lackierereien einen neuen Prozess etabliert. Einzelne Prozessschritte sind entfallen, andere wurden neu entwickelt – Wasser- und Energieverbrauch sind dadurch deutlich gesunken. Das war ein richtiggehender Technologiesprung, der sich in der Anlagentechnik durchgesetzt hat. Und nicht zuletzt blicken wir auf die Gestaltung unserer Produkte. Wir wollen verstehen, wo wir schon in der Entwicklung ansetzen können, um die Fahrzeuge später noch nachhaltiger zu produzieren.</p><p>Damit haben wir schon viel erreicht und wir wollen den nächsten Schritt gehen. Diese Konzepte übertragen wir auf unser weltweites Produktionsnetzwerk, entwickeln sie weiter und machen sie zum eigenen Standard. Wir wissen, wo technologisch die Potenziale liegen. Das stimmt mich positiv, dass wir unsere hoch gesetzten Ziele erreichen.</p><p>Gerade arbeiten wir am nächsten großen Thema, dem Gasverbrauch. Wir suchen gezielt weitere Effizienzen und prüfen, wo wir Erdgas durch Biogas, Wasserstoff oder regenerativen Strom ersetzen können.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/production/image-quote-production-1.jpg",
        quote:
          "Wir wissen, wo technologisch die Potenziale liegen. Das stimmt mich positiv, dass wir unsere hoch gesetzten Ziele, ein sauberes Unternehmen zu sein, erreichen.",
        author: "Jury Witschnig",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Ein weiteres strategisches Ziel ist es, die CO2-Emissionen im Lieferantennetzwerk bis 2030 um mindestens 20% zu reduzieren. Frau Perez Szmak, wie geht das?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Dafür muss man zunächst verstehen, was 20% CO2-Reduzierung wirklich bedeuten: Durch den Ausbau der Elektromobilität steigen die CO2-Emissionen in der Lieferkette. In unserem Fall würde der Anstieg bei rund einem Drittel liegen, wenn wir nichts dagegen unternehmen. Diesen Anstieg wollen wir nicht nur stoppen, sondern sogar umkehren. Statt rund ein Drittel CO2 mehr, wollen wir um 20% weniger. Das Ziel, bis 2030 die CO2-Emissionen im Lieferantennetzwerk um mindestens 20% zu senken, ist also ein deutlich ambitionierteres Ziel, als es auf den ersten Blick scheint.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Und wie erreichen wir dieses Ziel?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Wir haben einen konkreten Zehnjahresplan. Zusammen mit unseren Partnern im Lieferantenetzwerk  geht es maßgeblich um zwei Themen: Wir wollen den Anteil von erneuerbaren Energien erhöhen und gleichzeitig mehr Sekundärmaterial, also hochwertiges, wiederverwendetes Material einsetzen. Das ist ein weiterer wichtiger Schritt in die Kreislaufwirtschaft.</p><p>Den Einsatz von erneuerbaren Energien in unserem weltweiten Lieferantennetzwerk auszubauen, ist durchaus anspruchsvoll. Und zugleich ist es der wichtigste Hebel zur CO2-Reduzierung.</p><p>Mit unseren Batteriezell-Lieferanten haben wir schon vor einiger Zeit die entsprechenden vertraglichen Vereinbarungen getroffen. Deshalb sind schon heute die Batteriezellen unserer 5. Generation eDrive, wie sie beispielsweise im BMW iX3 stecken, zu 100% mit erneuerbaren Energien gefertigt. Für unsere Leichtmetallgießerei in Landshut beziehen wir seit Anfang 2021 Aluminium, das mit Solarenergie hergestellt wird. Die CO2-Reduzierung, die diese Maßnahmen bringen, sind enorm. Deshalb erweitern wir diese Anforderung nach und nach auf all unsere Einkaufsumfänge und machen sie zu einem wichtigen Entscheidungskriterium. Damit erreichen wir zusammen mit unseren Partnern über 50% des CO2-Ziels.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1345,
        items: [
          { width: 65, height: 41, src: "/data/interview/production/image-gallery-1.jpg" },
          { width: 35, height: 41, src: "/data/interview/production/image-gallery-2.jpg" },
        ],
        caption: {
          desc:
            "<p>Ein wichtiger Stellhebel ist die Verwendung von regenerativen Energien. Neue Technologien und die Verwendung von wiederverwerteten Materialien sind weitere zentrale Themen.</p>",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Und das Thema Sekundärmaterialien?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Bei Sekundärmaterialien sind zwei Aspekte wichtig: Zum einen wollen wir den Anteil von recyceltem Material in unseren Fahrzeugen erhöhen und zum anderen Schrotte, die bei bestimmten Prozessen entstehen, in Kreisläufe zurückführen. Das schont die natürlichen Ressourcen und reduziert auch den Energieaufwand bei der Herstellung.</p><p><strong>Jury Witschnig</strong> Das Schließen von Kreisläufen ist übrigens ein gemeinsames Thema: Im Presswerk praktizieren wir das bereits – Die Metall-„Abfälle“ dort gehen direkt an unsere Lieferanten zurück, um im Wertstoffkreislauf wieder eingesetzt zu werden.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/production/image-quote-production-2.jpg",
        quote: "Wir bekommen eine sehr positive Resonanz aus dem Lieferantennetzwerk.",
        author: "Patricia Perez Szmak",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Frau Perez Szmak, und welchen Herausforderungen stellen Sie sich mit dem Lieferantennetzwerk gerade?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Die Einhaltung von Menschenrechten und Umweltstandards hat für uns eine große Bedeutung. Wir haben schon seit vielen Jahren unsere Anforderungen dazu in unseren Einkaufsprozessen verankert. Lieferantennetzwerke sind jedoch global, komplex und vor allem dynamisch. Es ist wichtig, aber nicht immer einfach, für diese weltweiten und komplexen Warenströme Transparenz zu schaffen und Komponenten oder Rohstoffe zurückverfolgen zu können – das ist eine gemeinsame Aufgabe aller Beteiligten. Wir arbeiten bereits an einer Vielzahl von Standards und Technologien, um diese Datentransparenz herzustellen. Mit der Gründung der Allianz Catena-X Automotive Networks sind wir unserer Vorstellung einer unternehmensübergreifenden Datenplattform ein großes Stück nähergekommen.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Zum Abschluss eine Frage für Sie beide: Was hat Sie in letzter Zeit positiv überrascht?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Wir bekommen eine sehr positive Resonanz aus dem Lieferantennetzwerk – die Bereitschaft, die Themen anzugehen, ist riesig. Genau wie die Leidenschaft und der Einsatz unserer Teams. Themen aktiv anzugehen und etwas zu bewirken, das motiviert und freut mich! </p><p><strong>Jury Witschnig</strong> Ja, die Begeisterungsfähigkeit aller Beteiligten ist auch das, was mich positiv überrascht. Wenn Kollegen mit Ideen ankommen, die man so nicht erwartet hat. Und ich spreche da über Ideen für technische Neuerungen, die Effizienzsprünge im zweistelligen Prozentbereich bringen. Da zeigt Ingenieurskunst ihr wahres Genie.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Vielen Dank für das Gespräch.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        isDoubleCaption: true,
        parallaxColor: "Orange",
        items: [
          {
            src: "/data/interview/production/image-parallax-1.jpg",
            caption: {
              headline: "Patricia Perez Szmak",
            },
          },
          {
            src: "/data/interview/production/image-parallax-2.jpg",
            caption: {
              headline: "Jury Witschnig",
            },
          },
        ],
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 1,
            link: "/interview/flottenemissionen/",
          },
          {
            storyId: 0,
            link: "/interview/diversity/",
          },
        ],
      },
    },
  ],
};
