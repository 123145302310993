import { AnalyticsEventTypes } from "#interfaces";

export const footer = {
  topLinks: [
    {
      label: "PDF Bericht",
      href: "/data/pdf/de/BMW-Group-Bericht-2020-DE.pdf",
      download: true,
      analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
    },
    {
      label: "Downloads",
      href: "/downloads/",
    },
    {
      label: "Glossar",
      href: "/glossar/",
    },
    {
      label: "IR-Reminder",
      href: "https://www.bmwgroup.com/de/services/newslettersubscribe.html",
      external: true,
    },
  ],
  bottomLinks: [
    {
      label: "Rechtliche Hinweise",
      href: "/rechtliche-hinweise/",
    },
    {
      label: "Cookies",
      href: "/cookies/",
    },
    {
      label: "Impressum",
      href: "/impressum/",
    },
    {
      label: "Kontakt",
      href: "https://www.bmwgroup.com/de/general/kontakt.html",
      external: true,
    },
    {
      label: "Datenschutz",
      href: "/datenschutz/",
    },
  ],
};
