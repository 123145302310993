import { MenuItemProps, MenuLinkProps } from "#interfaces";
import { MenuStyle } from "#providers";
import { bp, defTransition } from "#shared/theme";
import { MenuAnchorProps, MenuLink } from "#ui";
import React from "react";
import { useLocation } from "react-router";
import styled, { css } from "styled-components/macro";

const List = styled.div``;

const LinkItem = styled.div`
  & + & {
    margin-top: 10px;
  }
`;
interface ItemProps {
  animatable?: boolean;
  animated?: boolean;
}

const Container = styled.div`
  & + & {
    margin-top: 20px;
    @media ${bp.m} {
      margin-top: 30px;
    }
  }
`;
const Item = styled(Container)<ItemProps>`
  ${({ animatable = false, animated = false }) =>
    animatable &&
    css`
      transform: translateX(${animated ? 0 : "-8px"});
      transition: transform ${defTransition};
    `}
`;
const Title = styled.b`
  display: block;
  margin-bottom: 10px;
`;
export interface MenuProps extends MenuAnchorProps {
  list: MenuItemProps[];
  index?: number;
  onClick?: () => void;
  onLinkClick?: (n: number) => void;
}

export const Menu: React.FC<MenuProps> = ({ list, index, withLastRowUnderline = false, onClick, onLinkClick }) => {
  const { pathname, hash } = useLocation();
  const renderLinks = (item: MenuLinkProps, key: number) => {
    const handleClick = () => {
      onClick?.();
      onLinkClick?.(key);
    };
    const isActive = key === index || `${pathname}${hash}` === item.to;
    return (
      <LinkItem key={key}>
        <MenuLink isActive={isActive} {...item} onClick={handleClick} />
      </LinkItem>
    );
  };
  const renderList = (item: MenuItemProps, key: number) => (
    <Item key={key}>
      {item.title && <Title>{item.title}</Title>}
      <List>{item.list.map(renderLinks)}</List>
    </Item>
  );
  return (
    <MenuStyle.Provider value={{ withLastRowUnderline }}>
      <Container>{list.map(renderList)}</Container>
    </MenuStyle.Provider>
  );
};
