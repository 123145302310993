import getCurve from "./getCurve";
const roadVert = `
uniform float uTravelLength;
uniform float uCurveAmplitude;
uniform float uCurveFrequency;

${getCurve}

varying vec2 vUv;
uniform float uTime;

	#include <fog_pars_vertex>
varying float vWorldZ;
void main() {
	vec3 transformed = position;
	vec4 mPosition = modelMatrix* vec4(position, 1.);
	mPosition.xy += getCurve(mPosition.z, uTime);
	vec4 mvPosition = viewMatrix * mPosition;
	
	gl_Position = projectionMatrix * mvPosition;
	vWorldZ = mPosition.z;
	vUv = uv;
		#include <fog_vertex>

}`;

export default roadVert;
