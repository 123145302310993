export const diversity = {
  title: {
    headline:
      "The percentage of women in management is increasing. We talked to Sabine Distler and Gabriele von Stetten to find out how this goal was achieved and what comes next. They’re responsible for diversity strategy.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        page: "Diversity",
        src: "/data/interview/diversity/image-1.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "How important is diversity to the BMW Group?",
        copytext:
          "<p><strong>Gabriele von Stetten</strong> We firmly believe diversity makes us more attractive as an employer, more competitive, and enhances our performance capabilities. Studies show that diverse teams are more innovative. It shouldn’t come as a surprise: After all, everyone brings a different perspective.</p><p><strong>Sabine Distler</strong> There's another aspect: Our customers around the world are diverse, too. If we want to understand these customers, win them over and engage them, then we also need this kind of diversity among our employees.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "BlueBold",
        copytext:
          "<p>We have more than <strong>DOUBLED</strong> the number of women in management positions since <strong>2011 at BMW AG</strong> – and aim to increase that percentage even further by <strong>2025</strong>.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "One of the ways the BMW Group measures diversity is through the percentage of women in management. What do you think about having a “quota” for women?",
        copytext:
          "<p><strong>Gabriele von Stetten</strong> Like I said: We strongly believe that we need diversity, because it makes us more innovative and more competitive. The same applies to gender diversity. That’s why we are working towards and intend to achieve our targets. A quota for women, as defined by law in Germany, can support that, but it’s in our own interest for men and women to work side by side in the workforce in general and at management level. We are addressing this, even without stipulations – but measurable goals do help.</p>",
      },
    },
    {
      type: "Image",
      props: {
        align: "Center",
        src: "/data/interview/diversity/image-2.jpg",
        caption: {
          desc:
            "<strong>Gabriele</strong> and <strong>Sabine</strong> have been working in tandem for several years. They have different backgrounds and skills that complement each other and make them more successful and more creative as a team.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "How do you handle less measurable diversity factors?",
        copytext:
          "<p><strong>Sabine Distler</strong> Our diversity strategy has so far considered the aspects gender and cultural background, plus age and experience. Now, we are expanding it to include sexual orientation, identity and physical and mental ability. For certain dimensions, quantitative targets make sense; for all, we need qualitative goals. It’s not about percentages or quotas; it's about things like creating an inclusive working environment for all employees. How can we improve international or intergenerational cooperation, for example? How can we make sure our workplaces are accessible? How can we raise awareness for all dimensions of diversity?</p><p><strong>Gabriele von Stetten</strong> It is, naturally, harder to measure success in these dimensions. But we do actually have a barometer for this with our international Employee Survey, which explicitly asks about equal opportunities and diversity.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/diversity/image-3.jpg",
        quote:
          "Studies show that diverse teams are more innovative. It shouldn’t come as a surprise: After all, everyone brings a different perspective.",
        author: "Gabriele von Stetten",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Let’s get back to talking about women. The BMW Group overfulfilled its goals for 2020 and has now set itself ambitious new targets. Where do you see the challenges?",
        copytext:
          "<p><strong>Sabine Distler</strong> We have achieved a great deal in recent years – including more than doubling the number of female managers. And we have set ourselves ambitious goals for the years up to 2025. One aspect that will be crucial to meeting these targets is identifying female talents early and developing them selectively. In the long run, it will also be important to bring qualified women into the company. We have several diverse talent development programmes where we already make sure we have a corresponding percentage of women.</p><p><strong>Gabriele von Stetten</strong> One of the things we did to meet our goals for 2025 was to take advantage of last year's new strategic direction to comprehensively analyse the topic of gender diversity, specifically. For example, in connection with this, we asked women at the company what prevents them from taking management positions. We were able to identify several action areas from this analysis and set specific activities in motion.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/diversity/image-4.jpg",
        quote:
          "Our customers around the world are diverse. If we want to understand them, win them over and engage them, then we also need this kind of diversity among our employees.",
        author: "Sabine Distler",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Can you give us an example?",
        copytext:
          "<p><strong>Sabine Distler</strong> There are phases and situations in life when, for whatever reason, people make a conscious decision not to work full-time. Family, continuing education and caring for others are just a few of examples we are familiar with. But reducing your work hours doesn’t mean you have to give up on being a manager. That's why we introduced our Joint Leadership programme last year, which allows two employees to work in tandem and share a managerial task with disciplinary responsibility.</p>",
      },
    },
    {
      type: "Video",
      props: {
        align: "Center",
        poster: "/data/interview/diversity/diversity_05_videoposter.jpg",
        src: "/data/interview/diversity/diversity.mp4",
        caption: {
          desc: "The BMW Group is committed to tolerance, openness and diversity both within and outside the company.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "The two of you also work in tandem. What are the benefits?",
        copytext:
          "<p><strong>Sabine Distler</strong> In our case, we have different educations and backgrounds, and took a different career path. This means we have a wide range of knowledge and an extensive network.</p><p><strong>Gabriele von Stetten</strong> A tandem demonstrates the advantages of diversity in the smallest possible unit. We come up with more creative ideas together, because we approach topics from different angles. This leads to better solutions – and makes our day-to-day work more enjoyable, too.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Sabine, Gabriele, thank you for talking to us.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        parallaxColor: "BlueBold",
        items: [
          {
            src: "/data/interview/diversity/image-5.jpg",
          },
          {
            src: "/data/interview/diversity/image-6.jpg",
          },
        ],
        caption: {
          headline: "Sabine Distler and Gabriele von Stetten,",
          desc: "responsible for the BMW Group diversity strategy within Human Resources.",
        },
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 1,
            link: "/interview/fleet-emissions/",
          },
          {
            storyId: 2,
            link: "/interview/clean-production/",
          },
        ],
      },
    },
  ],
};
