import { useTranslation } from "#hooks";
import { TextAside } from "#ui";
import React from "react";
import htmr from "htmr";

export const Dummy: React.FC<{ source: string }> = ({ source }) => {
  const { t: tHome } = useTranslation("home");
  const { copytext } = tHome(source);
  return <TextAside content={htmr(copytext)} />;
};
