const getCurve = `
vec2 getCurve(float z, float time){
	vec2 val = vec2(
		sin(z * uCurveFrequency + 3.14/2.) * uCurveAmplitude , 
		cos(z * 0.05) * 3.
		);
	val *= smoothstep( uTravelLength * 0., uTravelLength * 0.5, -z);
	val *= smoothstep(uTravelLength * 0.8, uTravelLength * 0.6, -z);
	// val *= 0.;
	return val;
}`;

export default getCurve;
