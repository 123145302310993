import React from "react";
import { SvgContainer } from "#ui";
import { MainKPI } from "./MainKPI";
import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { ChartProps } from "#interfaces";

const CHART_COUNT = 1;

export const SingleChartMobile: React.FC<Pick<ChartProps, "labels">> = (props) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <MainKPI {...props} {...chartProps} />
      <line fill="none" stroke="#000000" x1="149" y1="328.5" x2="171" y2="328.5" />
    </SvgContainer>
  );
};
