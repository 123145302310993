import React from "react";
import { useMediaQuery } from "react-responsive";
import { Languages } from "#constants";
import i18next from "src/i18n";
import { bp } from "#shared/theme";
import styled from "styled-components/macro";

const Path = styled.path``;

interface LangPaths {
  [k: string]: string;
}

const paths: LangPaths[] = [
  {
    [Languages.de]:
      "M1.445 29v6m1.782-18.45V35M5.01 7.997V35m1.782-17.665V35m1.782-24.111V35m1.782-9.121V35m1.781-3.673V35m1.782-17.665V35M15.7 13.44V35m1.782-15.34V35m1.782-12.222V35m1.781-2.1V35m1.782-10.671V35m1.782-14.56V35m1.782-24.67V35m1.782-14.56V35m1.782-12.23V35m1.781-2.889V35m1.782-10.671V35M35.3 10.889V35m1.782-30.114V35m1.782-27.003V35m1.781-18.45V35m1.782-7.562V35M44.21.219V35m1.782-15.333V35m1.782-28.556V35m1.782-15.333V35m1.781-17.665V35m1.782-15.34V35M54.9 22.77V35m1.782-3.673V35m1.782-27.003V35m1.781-21.56V35m1.782-17.665V35m1.782-24.111V35m1.782-30.892V35m1.782-25.45V35m1.782-27.781V35m1.781-18.45V35m1.782-20.007V35M74.5 24.338V35m1.782-19.228V35m1.782-17.665V35m1.781-29.335V35m1.782-12.222V35m1.782-9.121V35m1.782-25.45V35m1.782-10.662V35m1.782-27.003V35m1.781-21.556V35m1.782-28.556V35M94.1 14.993V35m1.782-17.665V35m1.782-13.002V35m1.781-2.1V35m1.782-2.1V35m1.782-28.556V35m1.782-15.333V35m1.782-16.118V35m1.782-30.892V35m1.781-16.118V35m1.782-20.007V35m1.782-16.118V35m1.782-14.554V35m1.782-9.111V35m1.781-5.235V35m1.782-2.1V35m10.691-4.455V35m10.691-15.333V35m-19.6-15.333V35M133.3 5.665V35m10.69-8.33V35m-19.6-5.235V35m10.692-22.334V35m10.69-2.139V35m-19.6-2.1V35m10.692-28.556V35m10.69-1.296V35m-19.6-19.228V35m10.691-14.56V35m-8.909-9.891V35m10.691-24.667V35",
    [Languages.en]:
      "M.5 27.379V35M2.28 8.185V35M4.06 3.67V35m1.78-22.298V35m1.78-3.67V35M9.4 12.702V35m1.78-13.83V35m1.78-18.911V35m1.78-8.185V35m1.78-20.04V35m1.78-2.54V35m1.78-24.556V35m1.78-17.218V35m1.78-7.056V35m1.78-33.589V35m1.78-23.992V35m1.78-11.008V35m1.78-3.67V35m1.78-1.411V35m1.78-18.911V35M36.1 8.185V35m1.78-13.266V35m1.78-23.992V35m1.78-9.879V35m1.78-2.54V35M45 .847V35m1.78-29.637V35m1.78-6.492V35m1.78-24.556V35m1.78-12.137V35m1.78-18.911V35m1.78-.847V35m1.78-.847V35m1.78-22.298V35m1.78-20.04V35m1.78-20.04V35m1.78-15.524V35m1.78-22.298V35m1.78-21.083V35m1.78-30.766V35m1.78-17.218V35m1.78-31.33V35m1.78-14.96V35m1.78-18.911V35m1.78-13.266V35m1.78-3.67V35m1.78-.847V35m1.78-19.476V35m1.78-23.427V35M87.72.282V35M89.5 7.621V35m1.78-20.605V35m1.78-12.702V35m1.78-9.315V35m1.78-.847V35m1.78-2.54V35m1.78-20.605V35m1.78-27.379V35m1.78-24.556V35m1.78-33.589V35m1.78-16.089V35m1.78-22.863V35m1.78-16.089V35m1.78-10.444V35m1.78-11.573V35m1.78-1.411V35m1.78-17.218V35m1.78-2.54V35m10.68-14.395V35m10.68-8.185V35m-19.58-20.605V35m10.68-30.766V35m10.68-1.976V35m-19.58-12.137V35M134 6.492V35m10.68-.847V35m-19.58-8.185V35m10.68-23.427V35m10.68-.847V35m-19.58-2.54V35m10.68-15.524V35m-8.9-.847V35m10.68-15.524V35",
  },
  {
    [Languages.de]:
      "M1.5 52.369V63m7-33.469V63m7-48.431V63m7-31.106V63m7-43.313V63m7-16.931V63m7-6.694V63m7-31.106V63m7-38.981V63m7-27.956V63m7-21.656V63m7-3.544V63m7-19.294V63m7-26.381V63m7-44.494V63m7-26.381V63m7-22.444V63m7-5.119V63m7-19.294V63m7-43.313V63m7-53.944V63m7-48.431V63m7-33.469V63m7-13.781V63m7-62.606V63m7-27.169V63m7-50.794V63m7-27.169V63m7-31.106V63m7-27.956V63m7-22.444V63m7-6.694V63m7-48.431V63m7-38.981V63m7-31.106V63m7-43.313V63m7-55.519V63m7-46.069V63m7-50.006V63m7-33.469V63m7-36.619V63m7-18.506V63m7-35.044V63m7-31.106V63m7-52.369V63m7-21.656V63m7-16.931V63m7-46.069V63m7-18.506V63m7-48.431V63m7-38.194V63m7-50.794V63m7-36.619V63m7-31.106V63m7-23.231V63m7-3.544V63m7-3.544V63m7-50.794V63m7-27.169V63m7-29.531V63m7-55.519V63m7-29.531V63m7-36.619V63m7-29.531V63m7-25.594V63m7-16.144V63m7-9.844V63m7-3.544V63m42-8.269V63m42-27.169V63m-77-27.169V63m42-52.369V63m42-14.569V63m-77-9.844V63m42-39.769V63m42-4.331V63m-77-3.544V63m42-50.794V63m42-1.969V63m-77-35.044V63m42-26.381V63m-35-17.719V63m42-43.706V63",
    [Languages.en]:
      "M1.5 48.5V62m7-47.5V62m7-55.5V62m7-39.5V62m7-6.5V62m7-39.5V62m7-24.5V62m7-33.5V62m7-14.5V62m7-35.5V62m7-4.5V62m7-43.5V62m7-30.5V62m7-12.5V62m7-59.5V62m7-42.5V62m7-19.5V62m7-6.5V62m7-2.5V62m7-33.5V62m7-47.5V62m7-23.5V62m7-42.5V62m7-17.5V62m7-4.5V62m7-60.5V62m7-52.5V62m7-11.5V62m7-43.5V62m7-21.5V62m7-33.5V62m7-1.5V62m7-1v1.5m7-40V62m7-35.5V62m7-35.5V62m7-27.5V62m7-39.5V62m7-37.5V62m7-54.5V62m7-30.5V62m7-55.5V62m7-26.5V62m7-33.5V62m7-23.5V62m7-6.5V62m7-1.5V62m7-34.5V62m7-41.5V62m7-61.5V62m7-48.5V62m7-36.5V62m7-22.5V62m7-16.5V62m7-1.5V62m7-4.5V62m7-36.5V62m7-48.5V62m7-43.5V62m7-59.5V62m7-28.5V62m7-40.5V62m7-28.5V62m7-18.5V62m7-20.5V62m7-2.5V62m7-30.5V62m7-4.5V62m42-25.5V62m42-14.5V62m-77-36.5V62m42-54.5V62m42-3.5V62m-77-21.5V62m42-50.5V62m42-1.5V62m-77-14.5V62m42-41.5V62m42-1.5V62m-77-4.5V62m42-27.5V62m-35-1.5V62m42-27.5V62",
  },
];
interface BarsProps {
  maskId: string;
  color: string;
}
export const Bars: React.FC<BarsProps> = ({ maskId, color }) => {
  const mask = `url(#${maskId})`;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <g mask={mask} stroke={color} strokeWidth={isDesktop ? 3 : 1}>
      <Path d={paths[Number(isDesktop)][i18next.language]} />
    </g>
  );
};
