import { ChartProps } from "#interfaces";
import { useTour, useTourSlideContext } from "#providers";
import { useEffect, useReducer } from "react";

export enum AnimationAction {
  Increment = "Increment",
  Reset = "Reset",
}
interface AnimationCounterState {
  count: number;
}

const initialState = { count: 0 };

type AnimationCounterAction = { type: AnimationAction };

type AnimationCounterReducerType = (
  state: AnimationCounterState,
  action: AnimationCounterAction
) => AnimationCounterState;

const AnimationCounterReducer: AnimationCounterReducerType = (state, action) => {
  switch (action.type) {
    case AnimationAction.Increment:
      return { count: state.count + 1 };
    case AnimationAction.Reset:
      return { count: 0 };
    default:
      return state;
  }
};

type UseChartSwitcher = (animationCount: number) => ChartProps;

export const useChartSwitcher: UseChartSwitcher = (animationCount) => {
  const [{ count }, dispatch] = useReducer(AnimationCounterReducer, initialState);
  const { set, index, indexReady } = useTour();
  const { key } = useTourSlideContext();
  const onPlayOutEnd = () => {
    dispatch({ type: AnimationAction.Increment });
  };
  useEffect(() => {
    if (index === indexReady) return;
    if (count >= animationCount) {
      set(indexReady);
      dispatch({ type: AnimationAction.Reset });
    }
  }, [count, set, index, indexReady, dispatch, animationCount]);
  const playIn = index === key && indexReady === key;
  const playOut = index === key && indexReady !== key;
  return {
    playIn,
    playOut,
    onPlayOutEnd,
  };
};
