import { Color, ColorName, defTransition, expander } from "#shared/theme";
import { ScrollEventCallback } from "overlayscrollbars";
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentProps } from "overlayscrollbars-react";
import { rgba } from "polished";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { ThemeKeys } from "#constants";
import styled, { css, ThemeProvider } from "styled-components/macro";
import theme from "styled-theming";
import { useResize } from "#hooks";

export enum ScrollbarsTheme {
  Default = "Default",
  Inversed = "Inversed",
}
interface ScrollbarsContextProps {
  scrollbarRef?: React.MutableRefObject<OverlayScrollbarsComponent | null>;
}

export const ScrollbarsContext = React.createContext<ScrollbarsContextProps>({});

interface OverlayScrollbarsForwarderProps extends OverlayScrollbarsComponentProps {
  showMask: boolean;
  maskColor: ColorName;
  thickOffset: boolean;
  centerContent: boolean;
  scrollbarRef?: React.MutableRefObject<OverlayScrollbarsComponent | null>;
}
const OverlayScrollbarsForwarder: React.FC<OverlayScrollbarsForwarderProps> = ({
  showMask,
  maskColor,
  scrollbarRef,
  thickOffset,
  centerContent,
  ...restProps
}) => <OverlayScrollbarsComponent ref={scrollbarRef} {...restProps} />;

const ScrollBarsContainer = styled(OverlayScrollbarsForwarder)`
  width: 100%;
  flex: 1 0 0%;
  &::after {
    content: "";
    ${expander}
    left: auto;
    width: 50px;
    pointer-events: none;
    touch-action: none;
    transition: opacity ${defTransition};
    will-change: opacity;
    opacity: ${({ showMask }) => (showMask ? 1 : 0)};
    background-image: linear-gradient(
      to right,
      ${({ maskColor }) => `${rgba(Color[maskColor], 0)}, ${rgba(Color[maskColor], 1)}`}
    );
  }
  .os-scrollbar {
    background-color: ${rgba(Color.Black, 0.3)};
    border-radius: 2px;
    padding: 0;
  }
  .os-scrollbar-vertical {
    width: 3px;
    margin: ${({ thickOffset }) => (thickOffset ? "4% 12px" : "4px")};
  }
  .os-scrollbar-horizontal {
    height: 3px;
    margin: 4px;
  }
  .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
  .os-scrollbar-handle {
    border-radius: 2px;
    background-color: ${Color.Green};
    ${theme(ThemeKeys.scrollbarColor, {
      [ScrollbarsTheme.Inversed]: css`
        background-color: ${Color.BlackLight};
      `,
    })}
  }
  .os-padding {
    ${({ thickOffset }) =>
      thickOffset &&
      css`
        margin-right: 30px;
      `}
  }
  .os-content {
    visibility: inherit;
    display: flex;
    flex-direction: column;
  }
`;

export interface ScrollbarsProps {
  style?: CSSProperties;
  withMask?: boolean;
  maxHeight?: string;
  thickOffset?: boolean;
  centerContent?: boolean;
  theme?: ScrollbarsTheme;
  maskColor?: ColorName;
}

export const Scrollbars: React.FC<ScrollbarsProps> = ({
  children,
  style,
  thickOffset = false,
  centerContent = false,
  theme = ScrollbarsTheme.Default,
  withMask = false,
  maskColor = ColorName.GrayLightest,
}) => {
  const scrollbarRef = useRef<OverlayScrollbarsComponent | null>(null);
  const [showMask, setShowMask] = useState(false);
  const { width } = useResize();
  const setPositionAndOverflow = () => {
    const osInstance = scrollbarRef?.current?.osInstance();
    if (!osInstance) return;
    setShowMask(osInstance.getState().overflowAmount.x > osInstance.scroll().position.x);
  };
  const onScroll: ScrollEventCallback = () => {
    setPositionAndOverflow();
  };
  useEffect(() => {
    setPositionAndOverflow();
    // TODO Dirty solution to add attr which will allow to scroll inside scrollbars when body is locked
    scrollbarRef?.current?.osInstance()?.getElements().viewport.setAttribute("data-scroll-lock-scrollable", "");
  }, [scrollbarRef]);
  useEffect(() => {
    setPositionAndOverflow();
  }, [width]);
  return (
    <ThemeProvider theme={{ [ThemeKeys.scrollbarColor]: theme }}>
      <ScrollBarsContainer
        maskColor={maskColor}
        showMask={withMask && showMask}
        thickOffset={thickOffset}
        style={style}
        centerContent={centerContent}
        options={{ scrollbars: { autoHide: "never" }, callbacks: { onScroll }, autoUpdate: true }}
        scrollbarRef={scrollbarRef}
      >
        <ScrollbarsContext.Provider value={{ scrollbarRef }}>{children}</ScrollbarsContext.Provider>
      </ScrollBarsContainer>
    </ThemeProvider>
  );
};
