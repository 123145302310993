import React from "react";
import styled, { StyledComponentPropsWithRef } from "styled-components/macro";
import { useResize } from "#hooks";

const Container = styled.div``;

export const WindowHeightContainer: React.FC<StyledComponentPropsWithRef<"div">> = React.forwardRef((props, ref) => {
  const { height } = useResize();
  return <Container {...props} style={{ height: `${height}px` }} ref={ref} />;
});
