import * as THREE from "three";
import { bindAll } from "./utils";
import debounce from "debounce";
// import Stats from "stats-js";
// import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
// import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
// import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import {
  BloomEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
  SMAAEffect,
  SMAAImageLoader,
  SMAAPreset,
  EdgeDetectionMode,
  PredicationMode,
  // BlendFunction,
  // TextureEffect,
} from "postprocessing";
import { rootElement } from "#helpers";
import config from "./config";
export class BasicThreeDemo {
  constructor() {
    this.container = null;
    this.canvas = null;

    // this.stats = new Stats();
    // document.body.appendChild(this.stats.dom);

    // Set on mount
    this.renderer = null;

    this.vp = {
      width: 0,
      height: 0,
      dpr: Math.min(2, window.devicePixelRatio),
    };
    this.enabled = true;
    // console.log(window.devicePixelRatio);
    // this.renderer = new THREE.WebGLRenderer({
    // 	antialias: true,
    // 	canvas,
    // });
    // this.renderer.setSize(container.offsetWidth, container.offsetHeight, false);
    // this.renderer.setPixelRatio(1);

    // if (canvas == null) {
    // 	container.append(this.renderer.domElement);
    // 	canvas = this.renderer.domElement;
    // }
    // this.canvas = canvas;

    this.camera = null;
    this.camera = new THREE.PerspectiveCamera(
      45,
      // set on mount
      //   null,
      1,
      0.1,
      10000
    );
    this.camera.position.z = 50;
    this.scene = new THREE.Scene();

    this.clock = new THREE.Clock();
    this.assets = {};
    this.disposed = false;
    this.isMounted = false;

    this.assets = new Map();

    bindAll(this, ["tick", "init", "resize"]);
    // binds
    // this.tick = this.tick.bind(this);
    // this.init = this.init.bind(this);
    // this.setSize = this.setSize.bind(this);

    // this.resize();
  }
  //   load() {}
  mount(container, canvas) {
    // console.log(container, canvas);
    this.container = container;
    this.canvas = canvas;
    // console.log(canvas);
    this.renderer = new THREE.WebGLRenderer({
      antialias: config.disablePostProcessing,
      canvas: this.canvas,
      alpha: true,
      stencil: false,
    });
    // this.renderer.setClearColor(0x00000000);
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.camera = new THREE.PerspectiveCamera(
    // 	45,
    // 	// set on mount
    // 	null,
    // 	1,
    // 	0.1,
    // 	10000
    //   );
    //   this.camer

    this.vp = {
      width: container.offsetWidth,
      height: container.offsetHeight,
      dpr: Math.min(2, window.devicePixelRatio),
    };
    // this.vp.dpr = ;

    this.renderer.setPixelRatio(this.vp.dpr);

    const renderPass = new RenderPass(this.scene, this.camera);
    this.renderPass = renderPass;

    const params = {
      intensity: 2,
      luminanceThreshold: 0.45,
      luminanceSmoothing: 0.05,
    };
    let bloomEffect = new BloomEffect(params);
    let smaaEffect = new SMAAEffect(
      this.assets.get("smaa-search"),
      this.assets.get("smaa-area"),
      SMAAPreset.ULTRA,
      EdgeDetectionMode.COLOR
    );

    this.smaaEffect = smaaEffect;
    this.bloomEffect = bloomEffect;

    smaaEffect.edgeDetectionMaterial.setEdgeDetectionThreshold(0.01);
    smaaEffect.edgeDetectionMaterial.setPredicationMode(PredicationMode.DEPTH);
    smaaEffect.edgeDetectionMaterial.setLocalContrastAdaptationFactor(3);
    smaaEffect.edgeDetectionMaterial.setPredicationThreshold(0.002);
    smaaEffect.edgeDetectionMaterial.setPredicationScale(0.2);

    bloomEffect.blurPass.scale = 1.2;
    // console.log(edgesTextureEffect);

    let bloomPass = new EffectPass(this.camera, smaaEffect, bloomEffect);

    this.bloomPass = bloomPass;

    let composer = new EffectComposer(this.renderer);
    composer.addPass(renderPass);
    renderPass.renderToScreen = false;
    composer.addPass(bloomPass);
    bloomPass.renderToScreen = true;
    this.composer = composer;

    this.resize();
    this.isMounted = true;
  }
  addEvents() {
    // console.log("addEvents");
    let debouncedResize = debounce(this.resize, 200);
    this.debouncedResize = debouncedResize;
    // function setHeightVar() {
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty("--vh", `${vh}px`);
    //   debouncedResize();
    // }

    // setHeightVar();
    window.addEventListener("resize", debouncedResize);
    // window.addEventListener("resize", this.debouncedResize);
  }
  removeEvents() {
    if (this.debouncedResize) window.removeEventListener("resize", this.debouncedResize);
  }
  loadAssets() {
    return new Promise((resolve) => {
      const manager = new THREE.LoadingManager(resolve);
      // this.text.load(manager);
      manager.itemStart("s");
      const smaaImageLoader = new SMAAImageLoader(manager);

      smaaImageLoader.load(([search, area]) => {
        this.assets.set("smaa-search", search);
        this.assets.set("smaa-area", area);
      });
      manager.itemEnd("s");
    });
  }
  init() {
    this.tick();
  }
  getViewSizeAtDepth(depth = 0) {
    const fovInRadians = (this.camera.fov * Math.PI) / 180;
    const height = Math.abs((this.camera.position.z - depth) * Math.tan(fovInRadians / 2) * 2);
    return { width: height * this.camera.aspect, height };
  }
  //   setSize(width, height) {
  //     this.renderer.setSize(width, height, false);
  //   }
  //   onResize() {}
  dispose() {
    this.disposed = true;

    this.removeEvents();

    if (this.renderer) {
      this.renderer.dispose();
      this.renderer = null;
    }
    // these are images, not textures
    // Just remove reference.
    this.assets.set("smaa-search", null);
    this.assets.set("smaa-area", null);

    if (this.smaaEffect) {
      this.smaaEffect.dispose();
      this.smaaEffect = null;
    }
    if (this.bloomEffect) {
      this.bloomEffect.dispose();
      this.bloomEffect = null;
    }
    if (this.bloomPass) {
      this.bloomPass.dispose();
      this.bloomPass = null;
    }
    if (this.renderPass) {
      this.renderPass.dispose();
      this.renderPass = null;
    }
    if (this.composer) {
      this.composer.dispose();
      this.composer = null;
    }
    this.camera = null;
    this.scene = null;
    this.canvas = null;
    this.container = null;
  }
  resize() {
    // let rect = this.container.getBoundingClientRect();
    // console.log(this.container.offsetWidth, window.innerHeight, rect.height);

    this.vp.width = this.container.offsetWidth;
    this.vp.height = this.container.offsetHeight;

    if (this.vp.height == 0) {
      this.vp.width = rootElement.clientWidth;
      this.vp.height = rootElement.clientHeight;
    }

    this.vp.dpr = Math.min(2, window.devicePixelRatio);
    // this.vp = {
    // 	width:
    // 	height: ,
    // };

    // console.log("resizign canvas", this.canvas);

    this.renderer.setSize(this.vp.width, this.vp.height, false);
    this.composer.setSize(this.vp.width, this.vp.height);
    this.camera.aspect = this.vp.width / this.vp.height;
    // console.log(this.camera);

    this.camera.updateProjectionMatrix();
    this.render();
  }
  //   update() {}
  render(delta) {
    if (config.disablePostProcessing) {
      this.renderer.render(this.scene, this.camera);
      return;
    }
    this.composer.render(delta);
    //
  }
  tick() {
    let delta = this.clock.getDelta();
    if (this.disposed) return;
    // if (resizeRendererToDisplaySize(this.renderer, this.setSize)) {
    // 	const canvas = this.renderer.domElement;
    // 	this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
    // 	this.camera.updateProjectionMatrix();
    // 	this.onResize();
    // }

    // this.stats.begin();
    if (this.enabled) {
      this.update(delta);
      this.render(delta);
    }
    // this.stats.end();
    requestAnimationFrame(this.tick);
  }
}

// function resizeRendererToDisplaySize(renderer, setSize) {
// 	const canvas = renderer.domElement;
// 	const width = canvas.clientWidth;
// 	const height = canvas.clientHeight;
// 	const needResize = canvas.width !== width || canvas.height !== height;
// 	if (needResize) {
// 		setSize(width, height, false);
// 	}
// 	return needResize;
// }
