import React, { useRef } from "react";
import { useNumberFormat } from "#hooks";
import { useSlideshow } from "#providers";
import { SvgLines } from "./SvgLines";
import { VCPContent, VCPLeftContent, VCPValue, VCPLink, RightContent, VCPTurbo, VCPValueLabel, VCPValues } from "#ui";
import { VCPItemProps } from "#interfaces";

export const Item3: React.FC<VCPItemProps> = ({ slideIndex, labels }) => {
  const { index, set } = useSlideshow();
  const turboSvgRef = useRef<SVGSVGElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const valuesInputRef = useRef<HTMLDivElement>(null);
  const valuesOutputRef = useRef<HTMLDivElement>(null);
  const inputDefaultRef = useRef<SVGLineElement>(null);
  const inputFillRef1 = useRef<SVGLineElement>(null);
  const inputFillRef2 = useRef<SVGLineElement>(null);
  const outputDefaultRef = useRef<SVGLineElement>(null);
  const outputFillRef1 = useRef<SVGLineElement>(null);
  const outputFillRef2 = useRef<SVGLineElement>(null);
  const isActive = index === slideIndex;
  const handleClick = () => {
    set(slideIndex === index ? -1 : slideIndex);
  };

  return (
    <SvgLines
      {...{
        turboSvgRef,
        contentRef,
        inputDefaultRef,
        inputFillRef1,
        inputFillRef2,
        outputDefaultRef,
        outputFillRef1,
        outputFillRef2,
        valuesInputRef,
        valuesOutputRef,
        isActive,
      }}
    >
      {({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
      }) => (
        <>
          <VCPContent ref={contentRef}>
            <VCPLeftContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title1}
              </VCPLink>
              <VCPValues ref={valuesInputRef}>
                <VCPValue ref={valueInputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue1))}
                </VCPValue>
                <VCPValue ref={valueInputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue2))}
                </VCPValue>
              </VCPValues>
            </VCPLeftContent>
            <RightContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title2}
              </VCPLink>
              <VCPValues ref={valuesOutputRef}>
                <VCPValue ref={valueOutputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue1))}
                </VCPValue>
                <VCPValue ref={valueOutputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue2))}
                </VCPValue>
              </VCPValues>
            </RightContent>
          </VCPContent>
          <VCPTurbo ref={turboSvgRef} viewBox="0 0 40 272" xmlns="http://www.w3.org/2000/svg" opacity="0">
            <g>
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_12_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="198"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="198" width="15" height="10" id="_x30_3-input-black_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_12_)">
                  <polygon id="_x30_1-input-black_9_" fill="#FFFFFF" points="0,198 15,198 15,208 0,208 5.083,203    " />
                </g>
              </mask>
              <line ref={turboInputRef} fill="none" stroke="#000000" strokeWidth="0" x1="0" y1="203" x2="15" y2="203" />
              <line
                ref={inputDefaultRef}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_3-input-black_1_)"
                fill="none"
                stroke="#000000"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="203"
                x2="15"
                y2="203"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_13_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="198"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="198" width="15" height="10" id="_x30_3-input-green_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_13_)">
                  <polygon id="_x30_1-input-black_8_" fill="#FFFFFF" points="0,198 15,198 15,208 0,208 5.083,203    " />
                </g>
              </mask>

              <line
                ref={inputFillRef1}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_3-input-green_1_)"
                fill="none"
                stroke="#346C64"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="203"
                x2="15"
                y2="203"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_14_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="202"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="202" width="15" height="2" id="_x30_3-input-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_14_)">
                  <polygon id="_x30_1-input-black_7_" fill="#FFFFFF" points="0,198 15,198 15,208 0,208 5.083,203    " />
                </g>
              </mask>

              <line
                ref={inputFillRef2}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_3-input-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="0"
                y1="203"
                x2="15"
                y2="203"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_15_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="64"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="64" width="15" height="10" id="_x30_3-output-white_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_15_)">
                  <polygon id="_x30_1-output-white_9_" fill="#FFFFFF" points="25,64 35,64 40,69 35,74 25,74    " />
                </g>
              </mask>
              <line ref={turboOutputRef} fill="none" stroke="#FFFFFF" strokeWidth="0" x1="25" y1="69" x2="40" y2="69" />
              <line
                ref={outputDefaultRef}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_3-output-white_2_)"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="69"
                x2="40"
                y2="69"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_16_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="64"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="64" width="15" height="10" id="_x30_3-output-yellow_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_16_)">
                  <polygon id="_x30_1-output-white_8_" fill="#FFFFFF" points="25,64 35,64 40,69 35,74 25,74    " />
                </g>
              </mask>

              <line
                ref={outputFillRef1}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_3-output-yellow_2_)"
                fill="none"
                stroke="#CEFB5F"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="69"
                x2="40"
                y2="69"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_17_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="68"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="68" width="15" height="2" id="_x30_3-output-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_17_)">
                  <polygon id="_x30_1-output-white_7_" fill="#FFFFFF" points="25,64 35,64 40,69 35,74 25,74    " />
                </g>
              </mask>

              <line
                ref={outputFillRef2}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_3-output-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="25"
                y1="69"
                x2="40"
                y2="69"
              />
            </g>
          </VCPTurbo>
        </>
      )}
    </SvgLines>
  );
};
