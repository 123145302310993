import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { Translations } from "#interfaces";
import { LeftKPI } from "./LeftKPI";
import { MainKPI } from "./MainKPI";
import { RightKPI } from "./RightKPI";

const CHART_COUNT = 3;

export const ContractsChartMobile: React.FC<{ labels?: Translations }> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <MainKPI labels={labels?.mainKPI} {...chartProps} />
      <LeftKPI
        labels={labels?.leftKPI}
        {...chartProps}
        currentRate={Number(labels?.leftKPI?.rate)}
        prevRate={Number(labels?.rightKPI_1?.rate)}
      />
      <RightKPI labels={labels?.rightKPI_1} {...chartProps} currentRate={Number(labels?.rightKPI_1?.rate)} />
      <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" d="M142,328.5h36" />
    </SvgContainer>
  );
};
