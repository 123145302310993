import React, { ReactComponentElement, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";
import { Languages } from "#constants";
import { ComparisonItemsProps } from "#interfaces";
import { comparisonItemsDe } from "./itemsDe/itemsDe";
import { comparisonItemsEn } from "./itemsEn/itemsEn";

export const comparisonItems: { [key: string]: ComparisonItemsProps } = {
  [Languages.de]: comparisonItemsDe,
  [Languages.en]: comparisonItemsEn,
};

const Container = styled.div`
  width: fit-content;
  opacity: 0;
  display: flex;
  flex: 0 0 100%;
  svg {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const getLength = (element: SVGPolylineElement): number => {
  return element.getTotalLength();
};

interface ComparisonChartProps {
  svg: ReactComponentElement<"svg">;
}

export const ComparisonChart: React.FC<ComparisonChartProps> = ({ svg }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    const lines = containerRef.current.querySelectorAll("mask rect");
    const polyLines = containerRef.current.querySelectorAll("polyline");
    const tl = gsap
      .timeline({ defaults: { transformOrigin: "bottom", force3D: true } })
      .set(lines, { scaleY: 0 })
      .set(polyLines, {
        strokeDashoffset: (i) => getLength(polyLines[i]),
        strokeDasharray: (i) => getLength(polyLines[i]),
      })
      .set("#text", { y: 10, opacity: 0 })
      .to(containerRef.current, { opacity: 1, duration: 0.3 })
      .to(lines, { scaleY: 1, duration: 1, stagger: 0.1, ease: Power3.easeInOut })
      .to(polyLines, { strokeDashoffset: 0, strokeDasharray: (i) => getLength(polyLines[i]), duration: 0.3 })
      .to("#text", { y: 0, opacity: 1, duration: 0.3 });
    return () => {
      tl.kill();
    };
  }, [containerRef]);
  return <Container ref={containerRef}>{svg}</Container>;
};
