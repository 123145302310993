// import React from "react";
import { PageName, RouteProps } from "#interfaces";
import { Cookies } from "./Cookies/Cookies";
import { Home } from "./Home/Home";
import { Intro } from "./Home/Intro";
import { Start } from "./Home/Start";
import { Tour } from "./Home/Tour";
import { VCPPage } from "./VCPPage/VCPPage";
import { DataProtection } from "./DataProtection/DataProtection";
import { FinancialStatements } from "./FinancialStatements/FinancialStatements";
import { Diversity, Emissions, Interview, Production, Foreword, Report } from "./Interview/Interview";
import { Disclaimer } from "./Disclaimer/Disclaimer";
import { Imprint } from "./Imprint/Imprint";
import { Glossar } from "./Glossar/Glossar";
import { Downloads } from "./Downloads/Downloads";
import { Note } from "./Note/Note";
import { ComingSoonPage } from "./ComingSoonPage/ComingSoonPage";

export const routes: RouteProps[] = [
  {
    pageName: PageName.comingSoon,
    component: ComingSoonPage,
  },
  {
    pageName: PageName.index,
    redirect: PageName.home,
  },
  {
    pageName: PageName.home,
    redirect: PageName.start,
  },
  {
    pageName: PageName.home,
    exact: false,
    component: Home,
    routes: [
      {
        pageName: PageName.start,
        component: Start,
      },
      {
        pageName: PageName.startindex,
        component: Start,
      },
      {
        pageName: PageName.intro,
        component: Intro,
      },
      {
        pageName: PageName.guidedTour2020Slides,
        component: Tour,
      },
    ],
  },
  {
    pageName: PageName.interactiveValueCreationProcess,
    component: VCPPage,
  },
  {
    pageName: PageName.interview,
    redirect: PageName.interviewDiversity,
  },
  {
    pageName: PageName.interview,
    exact: false,
    component: Interview,
    routes: [
      {
        pageName: PageName.interviewDiversity,
        component: Diversity,
      },
      {
        pageName: PageName.interviewFleetEmissions,
        component: Emissions,
      },
      {
        pageName: PageName.interviewCleanProduction,
        component: Production,
      },
    ],
  },
  {
    pageName: PageName.financialStatements,
    component: FinancialStatements,
  },
  {
    pageName: PageName.reportOfTheSupervisoryBoard,
    component: Report,
  },
  {
    pageName: PageName.statementOfTheChairmanOfTheBoardOfManagement,
    component: Foreword,
  },
  {
    pageName: PageName.cookies,
    component: Cookies,
  },
  {
    pageName: PageName.dataProtection,
    component: DataProtection,
  },
  {
    pageName: PageName.legalDisclaimer,
    component: Disclaimer,
  },
  {
    pageName: PageName.imprint,
    component: Imprint,
  },
  {
    pageName: PageName.glossary,
    component: Glossar,
  },
  {
    pageName: PageName.downloads,
    component: Downloads,
  },
  {
    pageName: PageName.note,
    component: Note,
  },
];
