import {
  Card11M,
  Card11,
  Card12M,
  Card12,
  Card21M,
  Card21,
  Card22M,
  Card22,
  Card31M,
  Card31,
  Card32M,
  Card32,
} from "./svg/bericht";

export const cardListDe = [
  [
    {
      thumb: { src: "/data/gallery/thmb-story-co2emissions1.jpg" },
      images: [Card21M, Card21],
    },
    {
      thumb: { src: "/data/gallery/thmb-story-co2emissions2.jpg" },
      images: [Card22M, Card22],
    },
  ],
  [
    {
      thumb: { src: "/data/gallery/thmb-story-cleanproduction1.jpg" },
      images: [Card31M, Card31],
    },
    {
      thumb: { src: "/data/gallery/thmb-story-cleanproduction2.jpg" },
      images: [Card32M, Card32],
    },
  ],
  [
    {
      thumb: { src: "/data/gallery/thmb-story-diversity1.jpg" },
      images: [Card11M, Card11],
    },
    {
      thumb: { src: "/data/gallery/thmb-story-diversity2.jpg" },
      images: [Card12M, Card12],
    },
  ],
];
