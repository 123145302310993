import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card21M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect x="282" y="53.67" fill="#659896" width="73" height="476.33" />
        <rect x="64.32" y="85" width="290.68" height="7" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="clouds">
          <path
            fill="#E1D9C8"
            d="M316.02,22.67c-4.88-7.18-15.38-15.71-27.3-10.25c-2.25,1.12-4.75,2.72-7.39,5.01
			c0.21,0.05,0.41,0.11,0.62,0.17c3.29,1.03,4.82,3.26,4.82,3.26s10.23-0.82,13.43,7.86c0,0-5.14-8.13-14.04-6.54
			c0,0-3.95-6.38-14.4-2.79c0,0,4.33-3.06,9.26-2.03c0.93-1.26,3-3.69,6.45-5.86c-5.67-7.33-12.38-9.55-17.16-10.3
			c-12.93-2.04-26.78,2.33-38.09,9c-22.63,13.37-43.02,14.62-67.21,21.93l182.64-2.44C330.08,29.36,322.88,18.49,316.02,22.67z"
          />
          <path
            fill="#E1D9C8"
            d="M159.14,48.42c-12.86-1.43-14.54-11.1-27.36-8.23c-10.17,2.28-14.85-5.12-23.63,0.05
			c-1.48,0.87-2.61,1.92-3.19,2.51c1.95,0.65,3.03,1.89,3.56,2.69c2.33-0.39,4.37,0.35,4.37,0.35c2.05-2.16,4.97-1.95,4.97-1.95
			c-4,0.65-4.86,3.13-4.86,3.13c-5.4-2.49-9.51,2.05-9.51,2.05c1.23-2.16,2.93-3.12,4.55-3.5c-3.24-2.27-7.09-3.42-12.52-1.97
			c-2.27,0.77-5.19,2.08-9.05,4.22c-6.41,3.56-24.73,5.51-38.21,5.88L178.2,51.8C178.2,51.8,178.96,50.63,159.14,48.42z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g>
          <g>
            <g>
              <path
                fill="none"
                d="M73.5,301.26c-0.24,0.38-0.51,0.73-0.82,1.05c-0.35,0.37-0.68,0.68-0.99,0.94
					c0.19,0.47,0.37,0.95,0.54,1.44C72.72,303.91,73.25,302.76,73.5,301.26z"
              />
              <path
                fill="none"
                d="M57.82,373.47c0.02,0,0.04,0.01,0.05,0.01c-0.1-0.18-0.19-0.36-0.28-0.54c-0.85-1.1-1.4-2.43-1.54-3.81
					c0.04,0.69,0.08,1.38,0.12,2.07c-0.33-1.6-0.42-3.24-0.27-4.86c-0.05,0.59-0.17,1.17-0.34,1.74c0.16-1.46,0.14-2.93-0.05-4.39
					c-0.01,0.56-0.01,1.11-0.02,1.67c0-0.18-0.08-0.39-0.11-0.57c-0.03-0.2-0.06-0.39-0.09-0.59c-0.05-0.39-0.08-0.78-0.1-1.17
					c-1.38,0.07-2.62,0.72-3.74,1.73c-0.14,3.3-0.01,6.37,0.69,8.03L52,372.81c0.07,0,0.15,0.01,0.22,0.01
					c1.58-0.05,3.39,0.13,5.56,0.65c-0.05-0.07-0.11-0.15-0.16-0.22C57.69,373.32,57.75,373.39,57.82,373.47z"
              />
              <path
                fill="none"
                d="M46.41,373.16c0.1-0.01,0.21-0.03,0.31-0.04l-0.3,0.02C46.42,373.15,46.42,373.16,46.41,373.16z"
              />
              <path
                fill="#D7BFB1"
                d="M72.47,340.79c0,0.86,0.02,2.95,0.41,3.44c0.19-1.01,0.61-2.06,0.61-2.53c0-0.37-0.19-0.61-0.31-0.73
					c0.01-0.04,0.01-0.07,0.02-0.11c0.74-0.15,0.51-0.65,0.26-1.45c0.03-0.18,0.06-0.36,0.09-0.54c-0.07,0.02-0.14,0.05-0.21,0.08
					C72.69,339.23,72.47,339.01,72.47,340.79z"
              />
              <path
                fill="#D7BFB1"
                d="M72.02,318.94c-0.15,0.1-0.28,0.21-0.37,0.31C71.77,319.13,71.9,319.02,72.02,318.94z"
              />
              <path
                fill="#D7BFB1"
                d="M71.26,305.97c0,0,0.46-0.43,0.98-1.27c-0.18-0.49-0.36-0.97-0.54-1.44c-4.35,3.65-5.01-2.75-7.22,7.08
					c-0.57,2.55-0.7,5.09-0.65,7.48c0.18,1.01,0.35,2.25,0.5,3.79c1.03,10.28,6.72,15.36,9.59,13.94c0.01,0,0.03,0.01,0.04,0.02
					c0.01-0.06,0.01-0.12,0.01-0.18c0.29-0.23,0.59-0.48,0.8-0.63c-0.47,0.3-0.75,0.54-0.75,0.54c0.07-3.06-0.02-4.64-1.06-7.75
					c-1.04-3.11-0.83-4.35-0.86-5.72c0.31,2.6,1.01,3.99,1.46,5.6c0.45,1.6,0.38,3.94,1.11,5.07c0.73,1.13,3.57,1,2.16,1.37
					c-0.76,0.2-1.41,0.51-1.89,0.79c0.02-0.01,0.05-0.02,0.07-0.03c3.01-0.84,3.79-1.44,1.82-4.96c-1.56-2.79-3.64-7.33-4.55-9.36
					c0.15-0.64,0.39-1.19,0.66-1.74c-0.19,0.04-0.35,0.09-0.5,0.15c0.03,0.57-0.22,1.38-0.22,1.38c-0.31,0.04-0.78-0.33-0.89-0.41
					c-0.01,0.01-0.01,0.03-0.02,0.04c0,0,0.01-0.02,0.02-0.04c-0.01-0.01-0.02-0.02-0.02-0.02c0.03-0.05,0.06-0.1,0.09-0.14
					c0.54-1.11,1.52-2.04,1.52-2.04s-0.06,0.8,0.05,0.73c0.02-0.02,0.08-0.03,0.16-0.03c0.42-0.85,0.85-1.75,1.04-3.05
					c-0.09,0.16-0.2,0.31-0.32,0.43c-0.9,0.83-1.89,1.71-1.89,1.71s0.65-2.42,1.18-3.19c0.52-0.77,1.06-0.67,1.06-0.67
					c0.02-0.02,0.04-0.03,0.06-0.03c-0.05-1.85-0.53-4.37-1.79-8.03c-0.04-0.12-0.08-0.23-0.12-0.34
					C71.66,305.77,71.26,305.97,71.26,305.97z M70.43,320.15c0,0,0.55,0.05,0.8-0.38C70.82,320.92,69.87,319.78,70.43,320.15z
					 M70.62,320.59l0.63-0.69C71.25,319.9,71.21,320.37,70.62,320.59z"
              />
              <path
                fill="#AD7D62"
                d="M74.68,332.49c-0.73-1.13-0.66-3.46-1.11-5.07c-0.45-1.6-1.15-3-1.46-5.6c0.04,1.37-0.18,2.61,0.86,5.72
					c1.04,3.11,1.13,4.69,1.06,7.75c0,0,0.27-0.24,0.75-0.54c0.06-0.04,0.12-0.07,0.18-0.11c0.48-0.28,1.12-0.59,1.89-0.79
					C78.25,333.49,75.41,333.62,74.68,332.49z"
              />
              <path
                fill="#AD7D62"
                d="M72.92,317.49c0,0-0.97,0.93-1.52,2.04c-0.03,0.05-0.07,0.09-0.09,0.14c0,0,0.01,0.01,0.02,0.02
					c-0.01,0.03-0.02,0.04-0.02,0.04c0.01-0.01,0.01-0.03,0.02-0.04c0.1,0.08,0.58,0.45,0.89,0.41c0,0,0.25-0.81,0.22-1.38
					c0.15-0.06,0.32-0.11,0.5-0.15c0.01,0,0.02-0.01,0.03-0.01c1.3-0.25,1.07-0.36,1.07-0.36s-0.6-0.04-0.91-0.02
					c-0.08,0.01-0.14,0.02-0.16,0.03C72.86,318.29,72.92,317.49,72.92,317.49z M71.65,319.25c0.1-0.1,0.22-0.21,0.37-0.31
					C71.9,319.02,71.77,319.13,71.65,319.25z"
              />
              <path
                fill="#AD7D62"
                d="M73.14,314.08c-0.52,0.77-1.18,3.19-1.18,3.19s0.99-0.88,1.89-1.71c0.13-0.12,0.23-0.27,0.32-0.43
					c0.42-0.77,0.44-1.88,0.09-1.75c-0.02,0.01-0.04,0.02-0.06,0.03C74.2,313.42,73.66,313.31,73.14,314.08z"
              />
              <path
                fill="#4D2C1A"
                d="M56.23,356.46c-0.02,0.03-0.03,0.06-0.05,0.09c-0.06,0.16-0.13,0.32-0.19,0.47c0,0.03,0,0.06-0.01,0.08
					c0.07-0.19,0.14-0.37,0.22-0.56C56.21,356.52,56.22,356.49,56.23,356.46z"
              />
              <path
                fill="#AD7D62"
                d="M52.2,319.82c1.47,0.3,2.57,2.25,1.17,3.95c-1.4,1.7-1.89,2.43-2.2,3.74c-0.31,1.31,0.63,2.51,0.63,2.51
					s0.19-1,0.51-2.22c0.32-1.21,1.79,0.44,3.2-1.06c1.41-1.5,0.44-5.57-0.13-6.51c-0.57-0.94-1.68-2.43-3.87-1.85
					c-2.19,0.58-2.67,3.19-3.15,5.01C48.37,323.41,50.13,319.4,52.2,319.82z"
              />
              <path
                fill="#AD7D62"
                d="M53.71,329.47c-0.64,0.75,1.14,3.56,2.08,4.66s1.82,0.04,0.82-1.85c-1-1.89-0.34-2.34,0.13-3.37
					s-0.04-1.28-0.04-1.28C55.72,329.21,54.35,328.73,53.71,329.47z"
              />
              <path fill="#AD7D62" d="M54.39,337.42c0,0-5.01-5.4-5.41-10.11C48.98,327.31,49.55,335.66,54.39,337.42z" />
              <path
                fill="#D7BFB1"
                d="M102.8,353.8c4.01-3.02,8.53-5.32,11.22-6.72c2.76-1.44,6.24-2.08,8.39-2.99
					c2.15-0.91,6.09,0.15,7.41-1.21c0.32-0.33,0.49,0.17,0.75,0.49c0.33,0.42,0.71,0.76,0.69,0.95c-0.1,0.79-0.11,1.85,0.64,1.89
					c0.76,0.04,1.06-0.45,0.91-1.25c0.13,0.51,0.11,0.91,0.01,1.21c0.22,0.17,0.43,0.33,0.61,0.47c0.63-0.43,0.64-1.56,0.06-2.17
					c-0.6-0.64-2.04-2.08-3.52-2.99c-1.47-0.91-3.55-2.65-4.8-3.06c-0.79-0.26-2.62-0.99-4.74-1.74c2.01,1.55,4.1,3.15,6.02,4.62
					c-0.36,0.07-0.73,0.16-1.09,0.26c-0.84,0.22-1.29,0.3-2.08,0.37c-0.01-0.01-0.03-0.02-0.04-0.03c-0.36,0.03-1,0.09-1.59,0.16
					c-0.24,0.02-0.5,0.04-0.79,0.06c-1.09,0.09-1.99,0.32-2.73,0.57c-0.08,0.01-0.17,0.03-0.24,0.03c-0.8,0.11,0.9-0.53,1.33-0.64
					c0.42-0.11-0.53-1.11-0.8-2.07c-0.27-0.96,0-1.65,1.54-0.69c0.08,0.05,0.16,0.1,0.24,0.15c-1.92-1.53-3.85-3.06-5.55-4.42
					c-1.83-0.02-2.34,1.18-3.16,2.45c-1.06,1.63-2.8,4.23-4.99,6.16c-2.19,1.93-3.86,4.08-5.03,5.25c-0.48,0.48-1.32,1.2-2.16,1.89
					C99.93,351.17,101.41,352.1,102.8,353.8z"
              />
              <path
                fill="#AD7D62"
                d="M119.96,339.33c-1.54-0.96-1.81-0.27-1.54,0.69c0.27,0.96,1.22,1.96,0.8,2.07
					c-0.42,0.11-2.12,0.74-1.33,0.64c0.07-0.01,0.15-0.02,0.24-0.03c0.74-0.25,1.64-0.48,2.73-0.57c0.29-0.02,0.55-0.04,0.79-0.06
					c0.58-0.08,1.23-0.14,1.59-0.16c-0.99-0.79-2.01-1.6-3.03-2.42C120.13,339.43,120.04,339.38,119.96,339.33z"
              />
              <path
                fill="#161616"
                d="M123.24,341.9c0.01,0.01,0.03,0.02,0.04,0.03c0.79-0.07,1.24-0.15,2.08-0.37
					c0.36-0.1,0.73-0.19,1.09-0.26c-1.92-1.48-4.01-3.08-6.02-4.62c-5.29-4.06-10.1-7.76-10.26-7.88c-0.36-0.27-0.33,0.11-0.44,0
					c-0.11-0.11-1.15-0.99-1.73-0.38c-0.58,0.6,0.82,1.56,0.93,1.67c0.11,0.11-0.3,0.24-0.08,0.38c0,0,2.48,1.97,5.79,4.6
					c1.7,1.36,3.63,2.89,5.55,4.42C121.23,340.29,122.25,341.11,123.24,341.9z"
              />
              <path
                fill="#161616"
                d="M129.83,347.12c0.8,0.64,1.34,1.07,1.51,1.21c3.37,2.9,5.52,3.28,5.8,3.37
					c0.06,0.02,0.12,0.02,0.18,0.01c0.06,0.05,0.13,0.11,0.2,0.15c0.2,0.11,0.25-0.05,0.16-0.16c-0.06-0.08-0.11-0.15-0.15-0.2
					c0.02-0.12-0.03-0.29-0.17-0.52c-0.38-0.63-1.03-1.73-3.92-4.36c-0.17-0.15-0.39-0.3-0.61-0.47
					C132.62,346.75,130.25,347.19,129.83,347.12z"
              />
              <path
                fill="#AD7D62"
                d="M131.41,347.16c0.63-0.12,1.2-0.39,1.41-0.99c0.11-0.31,0.12-0.7-0.01-1.21
					c0.15,0.79-0.15,1.29-0.91,1.25c-0.76-0.04-0.74-1.1-0.64-1.89c0.02-0.19-0.36-0.53-0.69-0.95c-0.26-0.32-0.42-0.82-0.75-0.49
					c-1.32,1.36-5.25,0.3-7.41,1.21c-2.15,0.91-5.63,1.55-8.39,2.99c-2.69,1.4-7.21,3.7-11.22,6.72c1.53,1.87,2.96,4.66,3.01,8.59
					c0.96-1.01,1.36-1.22,1.94-1.48c0.76-0.34,0.72-0.34,1.17-0.79c0.45-0.45,2.46-0.04,3.97-1.32c1.51-1.29,3.18-3.59,3.33-3.7
					c0.15-0.11,0.91-0.3,1.13-0.45c0.23-0.15,1.51,0.11,2.42,0.49c0.91,0.38,1.78,0.57,2.38,0.79c0.6,0.23,1.44,0.42,2.23-0.15
					s0.95-1.36,0.3-2c-0.64-0.64-1.36-1.29-1.74-1.7s-1.78-0.72-2.65-1.21c0,0,0,0,0,0c-0.99,0.8-1.96,1.55-2.65,1.97
					c-2.04,1.25-6.12,2.5-6.12,2.5c4.05-1.7,6.58-3.4,8.32-4.65c1.74-1.25,3.74-2.84,4.39-3.18c0,0-1.56,1.41-3.26,2.82
					c0.17,0.07,0.4,0.2,0.74,0.32c0.64,0.23,1.55,0.26,2.34,0.91c0.79,0.64,1.02,0.68,1.44,1.44s1.21,1.78,2.19,1.17
					c0.98-0.6,1.02-1.47,0.79-2.27c-0.23-0.79-0.26-1.36-0.38-1.55c-0.11-0.19-0.72-0.72-1.1-0.98s-1.29-1.1-1.29-1.1
					s1.36,0.72,1.7,1.13c0.34,0.42,0.95,1.32,1.47,1.63c0.53,0.3,1.47,0.15,1.85-0.6c0.38-0.76,0.11-2-0.45-2.61
					c-0.06-0.06-0.11-0.11-0.16-0.16c-0.02-0.01-0.06-0.03-0.13-0.07c-0.52-0.34-2.75-0.86-3.37-0.95c-0.66-0.1-1.38,0.36-1.38,0.36
					s0.75-0.73,1.55-0.49c0.71,0.21,2.12,0.34,2.97,0.65c0.02,0,0.04,0.01,0.06,0.01C130.24,347.21,130.85,347.27,131.41,347.16z"
              />
              <path
                fill="#D7BFB1"
                d="M119.84,350.67c-1.74,1.25-4.27,2.95-8.32,4.65c0,0,4.08-1.25,6.12-2.5c0.69-0.42,1.66-1.17,2.65-1.97
					c0.22-0.18,0.44-0.36,0.66-0.54c1.7-1.41,3.26-2.82,3.26-2.82C123.58,347.83,121.58,349.42,119.84,350.67z"
              />
              <path
                fill="#659896"
                d="M91.61,360.54c1.79,3.25,1.32,4,0.76,4.41c2.16,2.79,2.29,3.32,2.84,5.47c0.06,0.24,0.11,0.49,0.15,0.75
					c0.31,0.76,0.73,1.3,2.34,1.38c0.83-0.18,1.7-0.23,2.63-0.2c0.48-0.05,0.98-0.07,1.5-0.05c2.03-5.51,3.99-9.22,3.99-9.22
					c0.01-0.23,0.01-0.46,0-0.68c-0.06-3.94-1.48-6.73-3.01-8.59c-1.39-1.7-2.87-2.63-3.48-2.98c-0.19-0.11-0.3-0.16-0.3-0.16
					c-2.43,0.22-4.24,0.86-10.76,6.27C89.01,356.88,90.24,358.06,91.61,360.54z"
              />
              <path
                fill="#659896"
                d="M87.08,385.86c-0.13,0.06-0.26,0.12-0.39,0.18c0.04,0.03,0.08,0.06,0.12,0.09
					C86.9,386.04,86.99,385.95,87.08,385.86z"
              />
              <path
                fill="#659896"
                d="M87.69,357.41c-0.09,0.07-0.18,0.15-0.27,0.22c0.04,0.06,0.09,0.11,0.13,0.17
					C87.59,357.69,87.63,357.56,87.69,357.41z"
              />
              <path
                fill="#659896"
                d="M57.94,373.63c-0.03-0.05-0.05-0.09-0.08-0.14c-0.02,0-0.04-0.01-0.05-0.01
					C57.86,373.52,57.9,373.57,57.94,373.63z"
              />
              <path
                fill="#D7BFB1"
                d="M24.36,375.25c10.43,0,16.8-1.4,22.06-2.09c0-0.01,0.01-0.01,0.01-0.02l0.3-0.02l5.28-0.32l0.14-0.01
					c-0.7-1.66-0.82-4.73-0.69-8.03c1.11-1.01,2.35-1.66,3.74-1.73c0-0.01,0-0.02,0-0.02c-0.03-0.71-0.02-1.41,0.05-2.12
					c0.03-0.35,0.08-0.69,0.14-1.04c0.02-0.12,0.03-0.26,0.06-0.4c0.1-0.8,0.28-1.58,0.55-2.34c0-0.03,0-0.06,0.01-0.08
					c-0.18,0.44-0.36,0.88-0.54,1.32c0.16-0.47,0.35-0.92,0.55-1.38c0.46-5.34,2.25-11.31,2.45-14.84c0.08-1.41,0.14-2.69,0.18-3.88
					c0.21-0.31,0.37-0.73,0.46-1.29c0.37-2.15-0.08-3.75-0.47-6.94c-0.08-1.45-0.2-2.68-0.36-3.82c-0.02-0.16-0.04-0.31-0.04-0.47
					c-0.62-4.07-2.42-10.69-7.45-9.03c-5.6,1.84-4.34,9.03-6.45,22.65c-2.29,14.8-17.2,21.5-30.41,13.94c0,0-0.07-0.37-0.2-1.01
					c0.03,2.34-0.22,4.4-0.64,6.2c-0.83,2.41-2.45,4.99-3.95,6.69c-0.01,0.02-0.03,0.03-0.04,0.05c-0.98,1.13-1.46,2.15-1.47,3.11
					C8.41,370.09,11.85,375.25,24.36,375.25z M48.98,327.31c0.4,4.71,5.41,10.11,5.41,10.11C49.55,335.66,48.98,327.31,48.98,327.31
					z M56.71,327.64c0,0,0.51,0.25,0.04,1.28s-1.14,1.48-0.13,3.37c1,1.89,0.11,2.95-0.82,1.85s-2.71-3.92-2.08-4.66
					C54.35,328.73,55.72,329.21,56.71,327.64z M51.51,318.4c2.19-0.58,3.3,0.91,3.87,1.85c0.57,0.94,1.54,5.01,0.13,6.51
					c-1.41,1.5-2.88-0.15-3.2,1.06c-0.32,1.21-0.51,2.22-0.51,2.22s-0.94-1.2-0.63-2.51c0.31-1.31,0.81-2.04,2.2-3.74
					c1.4-1.7,0.3-3.65-1.17-3.95c-2.07-0.42-3.83,3.58-3.83,3.58C48.84,321.59,49.32,318.98,51.51,318.4z"
              />
              <path
                fill="#AD7D62"
                d="M79.61,369.6c0.02,0.05,0.03,0.1,0.05,0.15c0.03-0.09,0.05-0.19,0.08-0.28c0.04,0.04,0.08,0.08,0.1,0.14
					c0.01-0.1,0.04-0.19,0.09-0.28c0.02,0.04,0.06,0.07,0.1,0.1c0-0.09,0.02-0.18,0.07-0.26c0.04,0.09,0.12,0.18,0.2,0.23
					c0-0.12,0.02-0.25,0.05-0.37c0.06,0.06,0.11,0.13,0.17,0.19c0-0.11,0.01-0.23,0.01-0.34c0.05,0.07,0.11,0.14,0.16,0.2
					c-0.01-0.11-0.02-0.23-0.03-0.34c0.08,0.11,0.17,0.21,0.27,0.29c-0.03-0.19-0.06-0.38-0.07-0.57c0.2,0.09,0.39,0.26,0.54,0.41
					c-0.14-0.19-0.26-0.39-0.35-0.62c0,0,0.01-0.01,0.01-0.01c0.18,0.14,0.37,0.28,0.55,0.42c-0.14-0.18-0.25-0.4-0.31-0.63
					c0,0,0,0,0.01-0.01c0.15,0.09,0.3,0.17,0.45,0.26c-0.09-0.14-0.16-0.3-0.22-0.46c0.02-0.02,0.04-0.03,0.05-0.05
					c0.12,0.06,0.26,0.1,0.39,0.12c-0.09-0.08-0.16-0.19-0.2-0.3c0.03-0.03,0.05-0.06,0.08-0.09c0.1-0.01,0.2-0.03,0.3-0.04
					c-0.05-0.04-0.1-0.09-0.15-0.13c0.01-0.02,0.03-0.03,0.04-0.05c0.25,0.03,0.33,0.07,0.61-0.02c-0.25-0.04-0.38-0.09-0.48-0.16
					c0,0,0,0,0,0c0.28-0.01,0.57-0.07,0.83-0.19c-0.22-0.02-0.45-0.03-0.67-0.05c0.01-0.01,0.02-0.03,0.03-0.04
					c0.23-0.05,0.46-0.12,0.67-0.22c-0.18,0.02-0.35,0.01-0.53-0.02c0.04-0.08,0.09-0.16,0.13-0.25c0.11-0.06,0.22-0.12,0.32-0.19
					c-0.07,0.01-0.15,0.01-0.22,0c0.02-0.05,0.05-0.1,0.07-0.15c0.16-0.07,0.31-0.15,0.46-0.24c-0.12,0-0.24,0.01-0.36,0.01
					c0.01-0.03,0.02-0.06,0.04-0.09c0.08-0.04,0.15-0.1,0.22-0.17c-0.05,0-0.1,0-0.15,0c0.02-0.06,0.05-0.11,0.07-0.17
					c0.04-0.03,0.07-0.05,0.11-0.08c-0.02-0.01-0.04-0.02-0.06-0.03c0.01-0.02,0.01-0.03,0.02-0.05c0.01-0.01,0.03-0.01,0.05-0.02
					c0.08-0.03,0.17-0.06,0.25-0.09c-0.07,0.01-0.14,0.03-0.21,0.05c0.06-0.01,0.11-0.04,0.14-0.09c-0.04,0-0.08,0.01-0.12,0.01
					c0.12-0.08,0.22-0.18,0.3-0.3c-0.08,0.04-0.17,0.08-0.25,0.12c0.09-0.07,0.17-0.14,0.25-0.22c-0.07,0.01-0.13,0.04-0.19,0.08
					c0.09-0.07,0.17-0.15,0.23-0.25c-0.05,0.01-0.1,0.03-0.15,0.04c0.08-0.07,0.16-0.15,0.22-0.24c-0.04,0-0.09,0.02-0.12,0.04
					c0.05-0.06,0.09-0.11,0.14-0.17c-0.02-0.02-0.06-0.01-0.08,0.01c0.03-0.04,0.07-0.09,0.1-0.13c-0.02-0.02-0.07-0.01-0.08,0.02
					c0.1-0.18,0.15-0.38,0.16-0.59c0-0.04-0.01-0.09-0.05-0.09c0,0-0.01,0-0.01,0c0.01-0.06,0.01-0.1,0-0.1
					c-0.08-0.03-0.52,1.19-1.05,2.25c-0.54,1.06-1.04,1.78-2,2.7s-1.29,1.43-1.25,1.46c0.01,0,0.03-0.01,0.07-0.03
					c0,0.08,0.04,0.17,0.04,0.24C79.57,369.75,79.59,369.68,79.61,369.6z"
              />
              <path
                fill="#AD7D62"
                d="M80.59,369.66l-0.39,0.21c2.62-0.82,3.77-3.31,3.77-3.31C82.68,368.54,80.59,369.66,80.59,369.66z"
              />
              <path
                fill="#AD7D62"
                d="M74.61,376.29c-0.03-0.01-0.07-0.01-0.1,0c0.02-0.06,0.02-0.11,0.01-0.14c0,0-0.38,0.28-0.66,0.82
					c-0.15,0.28-0.54,0.87-1.03,1.49c0.1,0.12,0.21,0.24,0.31,0.36c0-0.06,0.01-0.11,0.01-0.17c0.03,0.07,0.05,0.13,0.08,0.2
					c-0.01-0.12-0.01-0.24,0.01-0.35c0.06,0.07,0.11,0.15,0.15,0.23c0-0.13-0.01-0.25-0.01-0.38c0.03,0.04,0.07,0.09,0.1,0.13
					c0-0.08,0-0.16,0-0.25c0.05,0.04,0.1,0.08,0.14,0.12c-0.01-0.1-0.02-0.21-0.03-0.31c0.06,0.04,0.11,0.09,0.17,0.13
					c-0.02-0.09-0.04-0.19-0.05-0.28c0.06,0.05,0.11,0.11,0.17,0.16c-0.01-0.09-0.02-0.19-0.04-0.28c0.05,0.04,0.1,0.08,0.16,0.1
					c-0.02-0.11-0.03-0.23-0.05-0.34c0.06,0.04,0.13,0.07,0.2,0.08c-0.05-0.07-0.1-0.14-0.16-0.21c0.08,0.02,0.16,0.06,0.24,0.11
					c-0.04-0.06-0.07-0.13-0.11-0.19c0.05,0.03,0.1,0.06,0.15,0.09c-0.03-0.06-0.06-0.11-0.09-0.17c0.06,0.04,0.12,0.07,0.19,0.09
					c-0.04-0.07-0.06-0.14-0.09-0.21c0.04,0.01,0.09,0.01,0.13,0.02c-0.04-0.06-0.1-0.11-0.16-0.15c0.08,0,0.15-0.01,0.23-0.03
					c-0.04-0.02-0.08-0.04-0.13-0.04c0.05-0.06,0.13-0.09,0.2-0.08c-0.06-0.04-0.12-0.07-0.18-0.11c0.12-0.01,0.23-0.05,0.33-0.12
					c-0.06-0.05-0.15-0.07-0.22-0.06c0.08-0.05,0.17-0.08,0.27-0.08c-0.09-0.02-0.18-0.03-0.27-0.03c0,0,0,0,0,0
					c0.08-0.02,0.16-0.05,0.24-0.09c-0.06-0.01-0.12-0.01-0.18-0.02c0.1,0,0.2-0.01,0.3-0.01
					C74.74,376.33,74.68,376.31,74.61,376.29z"
              />
              <path
                fill="#AD7D62"
                d="M80.97,377.45c-0.5,0.11-0.96,0.28-1.3,0.79c-0.37,0.56,0.03,0.42,0.41,0.11
					c0.28-0.23,0.72-0.63,0.93-0.82C81.04,377.5,81.01,377.44,80.97,377.45z"
              />
              <path
                fill="#AD7D62"
                d="M82.42,374.87c-0.45,0.28-0.74,1.01-0.72,1.58c0,0.04,0.05,0.05,0.07,0.01c0.11-0.23,0.39-0.76,0.71-1.1
					C82.9,374.91,82.9,374.56,82.42,374.87z"
              />
              <path
                fill="#E99794"
                d="M87.63,375.4c-0.24,0.64-0.49,0.76-1.12,1.15c-0.63,0.39-1.11,0.66-1.57,1.07
					c-0.46,0.41-0.19,0.92-0.86,1.31c-0.67,0.39-0.72,0.56-0.99,1.27c-0.28,0.71-0.3,0.91-0.85,1.24c-0.55,0.33-0.77,0.38-0.88,0.86
					c0.39-0.46,0.52-0.45,0.81-0.62c0.3-0.17,0.52-0.16,0.96-0.43c0.44-0.27,1.38-0.06,1.96-0.47c1.35-0.96,2.14-1.95,2.55-2.72
					c0.4-0.74,0.25-1.8,0.26-2.25c0.01-0.45,0.42-1.43,0.42-1.43C88.03,374.57,87.87,374.76,87.63,375.4z"
              />
              <path
                fill="#AD7D62"
                d="M78.67,379.2c-0.24-0.1-0.41-0.32-0.49-0.58c-0.04-0.11-0.06-0.29-0.12-0.44
					c-0.06-0.15-0.14-0.3-0.24-0.43c-0.21-0.26-0.46-0.43-0.73-0.59c0.18,0.25,0.39,0.48,0.53,0.73c0.07,0.12,0.13,0.25,0.16,0.39
					c0.04,0.14,0.05,0.27,0.1,0.44c0.1,0.32,0.36,0.63,0.71,0.71c0.17,0.04,0.35,0.02,0.51-0.04c0.15-0.06,0.28-0.16,0.39-0.28
					c-0.14,0.06-0.28,0.11-0.43,0.13C78.93,379.27,78.79,379.25,78.67,379.2z"
              />
              <path
                fill="#AD7D62"
                d="M82.21,373.9c0.26,0,0.53,0.1,0.7,0.29c0.08,0.1,0.14,0.22,0.17,0.36c0.03,0.14,0.02,0.29,0,0.45
					c0.08-0.14,0.13-0.29,0.14-0.45c0.01-0.16-0.03-0.34-0.12-0.49c-0.19-0.31-0.55-0.46-0.89-0.46c-0.19,0.01-0.31,0.04-0.45,0.04
					c-0.14,0.01-0.28-0.01-0.42-0.04c-0.28-0.06-0.56-0.19-0.85-0.29c0.23,0.2,0.48,0.39,0.79,0.51c0.15,0.06,0.32,0.09,0.48,0.1
					C81.92,373.93,82.09,373.9,82.21,373.9z"
              />
              <path
                fill="#AD7D62"
                d="M80.78,363.16c1.06-0.84,1.47-1.38,1.47-1.38s-1-0.02-3.1,1.94c-2.1,1.95-2.9,4.5-2.38,3.76
					C77.51,366.42,79.11,364.49,80.78,363.16z"
              />
              <path
                fill="#AD7D62"
                d="M69.62,375.88c0.19,0.11,0.38,0.22,0.57,0.35c0.83-1.16,1.71-2.17,2.29-2.77
					C73.07,372.87,71.14,373.86,69.62,375.88z"
              />
              <path
                fill="#F1C6B0"
                d="M58.42,378.04c-0.02-0.04-0.09-0.15-0.2-0.4C58.28,377.78,58.34,377.92,58.42,378.04z"
              />
              <path
                fill="#F1C6B0"
                d="M87.65,357.92c0.24,0.32,0.49,0.64,0.73,0.97c0-0.29,0.08-0.58,0.41-0.64c0.49-0.09,1.03,0.64,1.43,1.13
					c0,0-1.01-1-1.3-0.61c-0.16,0.2-0.12,0.64,0.12,0.99c0.03,0.04,0.06,0.08,0.09,0.12c0.07,0.08,0.16,0.16,0.26,0.22
					c0.54,0.34,0.76,0.5,1.04,0.86c0.28,0.36,0.32,0.84,0.32,0.84s-0.21-0.28-0.47-0.61s-0.24,0.35-0.76,0.07
					c-0.39-0.22-0.79-1.06-0.99-1.6c-0.24-0.27-0.45-0.57-0.67-0.88c0.08,0.29,0.16,0.57,0.24,0.86c-0.54-0.65-1.07-1.3-1.61-1.95
					c0.21,0.6,0.41,1.2,0.62,1.8c-0.72-0.89-1.47-1.76-2.25-2.61c0.12,0.47,0.33,0.91,0.63,1.3c-0.6-0.58-1.2-1.17-1.8-1.75
					c0.16,0.47,0.43,0.9,0.77,1.26c-0.64-0.63-1.27-1.25-1.91-1.88c0.41,0.51,0.73,1.08,0.96,1.69c-1.05-1.46-2.45-2.67-4.06-3.48
					l0.26-0.59c-0.71,0.01-1.47,0.13-2.65-0.02c-0.47-0.06-1.37-0.32-2.14-0.64c0.9,0.57,1.98,1.27,1.98,1.27s-1.45-0.39-2.8-0.76
					c-0.85-0.24-1.06-0.23-1.79-0.53c0.4,0.23,1.2,1,1.28,1.22c-2.04-0.94-4.01-0.63-5.56-0.94c0.19,0.54,2.13,0.51,2.66,0.71
					c-0.93,0.48-3.56,1.04-4.55,0.7c0.28,0.13,0.04,2.03-0.04,2.33c-0.2-1.06-0.84-1.38-1.37-1.69c0.23,0.15-0.13,2.43-0.06,2.69
					c-0.31-0.92-0.57-2.04-1.01-2.35c0.12,0.48-0.54,2.43-0.43,2.91c-0.48,0.14-0.26-1.69-0.33-2.19c-0.16,0.46,0.07-0.05-0.09,0.41
					c-0.01,0.56-0.41,2.09-0.42,2.65c-0.19-0.21-0.33-0.45-0.42-0.71c0.13,0.5,0.2,1.02,0.22,1.54c-0.19-0.18-0.34-0.4-0.47-0.63
					c0.16,0.54,0.24,1.1,0.24,1.67c-0.47-0.81-0.89-1.65-1.26-2.52c0.42,1.54,0.85,3.07,1.27,4.61c-0.62-1.15-1.23-2.3-1.85-3.45
					c0.65,1.17,1.01,2.49,1.05,3.83c-0.29-0.43-0.58-0.87-0.87-1.3c0.12,0.55,0.24,1.11,0.36,1.66c-0.38-0.47-0.69-0.98-0.95-1.53
					c0.3,0.82,0.38,2.04,1.3,4.37c-0.77-1.22-1.96-4.6-2.46-5.95c0.58,2.21,1.23,4.4,1.94,6.58c-1.07-1.89-1.14-1.76-1.99-3.76
					c0.65,2.94,0.69,3.75,1.89,6.51c-1.14-1.97-2.06-4.07-2.75-6.24c0.09,2.38,0.6,4.74,1.49,6.96c-0.22-0.38-0.45-0.76-0.67-1.14
					c0.2,0.54,0.39,1.08,0.59,1.62c-0.47-0.68-0.89-1.4-1.25-2.14c0.52,1.46,1.09,2.9,1.71,4.31c-0.88-1.33-1.65-2.72-2.32-4.16
					c0.68,1.95,1.37,3.92,2.43,5.68c-0.02,0.01-0.03,0.03-0.05,0.04c-0.76-1.07-1.45-2.19-2.06-3.36c0,0,0,0.01,0,0.01
					c0.59,1.17,1.2,2.35,1.92,3.46c-0.1,0.07-0.19,0.15-0.29,0.22c-0.05,0.04-0.1,0.08-0.15,0.11c-0.78,0.59-1,1.45-0.75,2.17
					c0.02,0.02,0.29,0.3,0.69,0.06c0.41-0.25,1.32-0.83,1.73-1.08c0.41-0.25,1.42-0.27,1.94-0.3l1.83,1.2
					c-0.36-0.19-1.68-0.61-2.28-0.75c-0.45-0.11-1.07-0.22-2.38,0.53c-0.84,0.48-1.33,0.76-1.33,0.76s-1.32,1.09-0.8,2.13
					c0.47,0.54,1.03,0.12,1.71-0.19c0.74-0.34,1.19-0.22,1.69-0.18l2.02,0.34c-0.29,0-0.64,0.04-1.67-0.05
					c-1.03-0.09-1.26-0.15-1.68,0.02c-0.42,0.18-1,0.44-1.39,0.67c0,0-0.94,0.7-0.39,1.69c0.08,0.14,0.15,0.25,0.22,0.34
					c-0.01-0.03-0.02-0.04-0.02-0.04c0.33,0.22,0.55,0.19,0.76,0.07c0.21-0.11-0.42-0.41-0.62-1s0.39,0.82,1.11,0.78l2.58,0.41
					c0.5,0.01,0.56,0.36,0.26,0.53c-0.03,0.04-0.15,0.08-0.36,0.08c-0.55,0-1.68-0.15-2.43,0.05c-0.75,0.2-1.06,0.87-1.1,1.43
					c-0.04,0.57,0.65,0.84,1.11,1.24c0.47,0.4,0.57,0.48,1.47,0.63c0.9,0.14,1.57,0.11,1.99,0.25c0.42,0.14,1.03,0.88,1.74,1.04
					c0.71,0.16,1.38,1.16,2.42,1.54c1.05,0.37,1.97,0.62,3.11,0.45c0,0,0.54,0.64,1.65,2.06c0.27,0.35,0.56,0.68,0.82,0.97
					c1.29-0.83,5.63-6.79,6.26-9.11c0,0,1.1-1.02,2.78-0.22c0.93,0.44,1.47,0.93,2.44,0.76c0.37-0.07,1.3,0.42,2.53,1.28
					c0.13-0.06,0.26-0.12,0.39-0.18c0.83-0.83,1.64-1.91,2.41-3.75c2.76-6.64,5.25-8.93,8.2-9.56c-1.6-0.08-2.03-0.63-2.34-1.38
					c-0.04-0.26-0.09-0.51-0.15-0.75c-0.55-2.14-0.68-2.68-2.84-5.47c0.56-0.41,1.03-1.16-0.76-4.41c-1.37-2.48-2.6-3.66-3.34-3.61
					c-0.25,0.02-0.45,0.18-0.57,0.47c0,0,0,0,0,0c-0.06,0.15-0.1,0.28-0.13,0.4C87.59,357.85,87.62,357.88,87.65,357.92z
					 M72.49,373.46c-0.58,0.6-1.47,1.62-2.29,2.77c-0.19-0.13-0.38-0.24-0.57-0.35C71.14,373.86,73.07,372.87,72.49,373.46z
					 M74.7,376.36c-0.08,0.04-0.16,0.06-0.24,0.09c0,0,0,0,0,0c0.09,0,0.18,0.01,0.27,0.03c-0.1,0-0.19,0.03-0.27,0.08
					c0.08-0.01,0.16,0.01,0.22,0.06c-0.1,0.07-0.21,0.11-0.33,0.12c0.06,0.04,0.12,0.07,0.18,0.11c-0.07-0.01-0.15,0.02-0.2,0.08
					c0.04,0.01,0.09,0.02,0.13,0.04c-0.07,0.02-0.15,0.04-0.23,0.03c0.06,0.04,0.12,0.09,0.16,0.15c-0.04-0.01-0.09-0.01-0.13-0.02
					c0.02,0.07,0.05,0.14,0.09,0.21c-0.07-0.02-0.13-0.05-0.19-0.09c0.03,0.06,0.06,0.11,0.09,0.17c-0.05-0.03-0.1-0.06-0.15-0.09
					c0.04,0.06,0.07,0.13,0.11,0.19c-0.07-0.05-0.15-0.08-0.24-0.11c0.05,0.07,0.1,0.14,0.16,0.21c-0.07-0.01-0.14-0.04-0.2-0.08
					c0.02,0.11,0.03,0.23,0.05,0.34c-0.06-0.02-0.11-0.06-0.16-0.1c0.01,0.09,0.02,0.19,0.04,0.28c-0.06-0.05-0.11-0.11-0.17-0.16
					c0.02,0.09,0.04,0.19,0.05,0.28c-0.06-0.04-0.11-0.09-0.17-0.13c0.01,0.1,0.02,0.21,0.03,0.31c-0.05-0.04-0.1-0.08-0.14-0.12
					c0,0.08,0,0.16,0,0.25c-0.03-0.04-0.07-0.09-0.1-0.13c0,0.13,0.01,0.25,0.01,0.38c-0.04-0.08-0.09-0.16-0.15-0.23
					c-0.02,0.12-0.02,0.23-0.01,0.35c-0.03-0.07-0.05-0.13-0.08-0.2c0,0.06,0,0.11-0.01,0.17c-0.1-0.12-0.21-0.24-0.31-0.36
					c0.49-0.62,0.89-1.21,1.03-1.49c0.28-0.54,0.66-0.82,0.66-0.82c0.01,0.03,0.01,0.08-0.01,0.14c0.03-0.01,0.07-0.01,0.1,0
					c0.07,0.02,0.13,0.04,0.2,0.03c-0.1,0-0.2,0.01-0.3,0.01C74.57,376.34,74.63,376.35,74.7,376.36z M79.15,363.71
					c2.1-1.95,3.1-1.94,3.1-1.94s-0.41,0.54-1.47,1.38c-1.67,1.34-3.27,3.26-4.01,4.31C76.24,368.21,77.04,365.66,79.15,363.71z
					 M79.11,379.39c-0.15,0.06-0.33,0.08-0.51,0.04c-0.35-0.08-0.61-0.38-0.71-0.71c-0.05-0.18-0.06-0.31-0.1-0.44
					c-0.04-0.14-0.09-0.26-0.16-0.39c-0.14-0.25-0.35-0.47-0.53-0.73c0.27,0.16,0.52,0.34,0.73,0.59c0.1,0.13,0.18,0.28,0.24,0.43
					c0.06,0.15,0.08,0.33,0.12,0.44c0.08,0.25,0.25,0.48,0.49,0.58c0.12,0.05,0.26,0.07,0.4,0.05c0.14-0.02,0.28-0.07,0.43-0.13
					C79.39,379.23,79.26,379.33,79.11,379.39z M79.44,369.62c-0.04-0.03,0.29-0.54,1.25-1.46s1.47-1.64,2-2.7
					c0.54-1.06,0.97-2.28,1.05-2.25c0.01,0,0.01,0.04,0,0.1c0,0,0.01,0,0.01,0c0.04,0.01,0.05,0.06,0.05,0.09
					c0,0.2-0.06,0.41-0.16,0.59c0.01-0.03,0.06-0.04,0.08-0.02c-0.03,0.04-0.07,0.09-0.1,0.13c0.02-0.02,0.05-0.02,0.08-0.01
					c-0.05,0.06-0.09,0.11-0.14,0.17c0.03-0.03,0.08-0.04,0.12-0.04c-0.07,0.09-0.14,0.17-0.22,0.24c0.05-0.01,0.1-0.03,0.15-0.04
					c-0.06,0.1-0.14,0.18-0.23,0.25c0.06-0.04,0.12-0.06,0.19-0.08c-0.08,0.08-0.16,0.15-0.25,0.22c0.08-0.04,0.17-0.08,0.25-0.12
					c-0.08,0.12-0.19,0.22-0.3,0.3c0.04,0,0.08-0.01,0.12-0.01c-0.03,0.05-0.09,0.08-0.14,0.09c0.07-0.02,0.14-0.04,0.21-0.05
					c-0.08,0.03-0.17,0.06-0.25,0.09c-0.02,0.01-0.03,0.01-0.05,0.02c-0.01,0.02-0.01,0.03-0.02,0.05c0.02,0.01,0.04,0.02,0.06,0.03
					c-0.03,0.03-0.07,0.06-0.11,0.08c-0.02,0.06-0.05,0.11-0.07,0.17c0.05,0,0.1,0,0.15,0c-0.06,0.07-0.14,0.13-0.22,0.17
					c-0.01,0.03-0.02,0.06-0.04,0.09c0.12,0,0.24-0.01,0.36-0.01c-0.15,0.1-0.3,0.18-0.46,0.24c-0.02,0.05-0.05,0.1-0.07,0.15
					c0.07,0.01,0.15,0.01,0.22,0c-0.1,0.07-0.21,0.14-0.32,0.19c-0.04,0.09-0.09,0.17-0.13,0.25c0.17,0.04,0.35,0.05,0.53,0.02
					c-0.22,0.1-0.44,0.18-0.67,0.22c-0.01,0.01-0.02,0.03-0.03,0.04c0.22,0.02,0.45,0.03,0.67,0.05c-0.26,0.12-0.55,0.19-0.83,0.19
					c0,0,0,0,0,0c0.1,0.06,0.23,0.12,0.48,0.16c-0.29,0.09-0.36,0.05-0.61,0.02c-0.01,0.02-0.03,0.03-0.04,0.05
					c0.05,0.05,0.09,0.1,0.15,0.13c-0.1,0.01-0.2,0.03-0.3,0.04c-0.03,0.03-0.05,0.06-0.08,0.09c0.04,0.12,0.11,0.22,0.2,0.3
					c-0.13-0.02-0.27-0.06-0.39-0.12c-0.02,0.02-0.03,0.03-0.05,0.05c0.05,0.16,0.12,0.32,0.22,0.46c-0.15-0.09-0.3-0.17-0.45-0.26
					c0,0,0,0-0.01,0.01c0.06,0.23,0.17,0.45,0.31,0.63c-0.18-0.14-0.37-0.28-0.55-0.42c0,0-0.01,0.01-0.01,0.01
					c0.09,0.22,0.2,0.43,0.35,0.62c-0.15-0.15-0.34-0.32-0.54-0.41c0.02,0.19,0.04,0.38,0.07,0.57c-0.1-0.09-0.19-0.19-0.27-0.29
					c0.01,0.11,0.02,0.23,0.03,0.34c-0.05-0.07-0.11-0.14-0.16-0.2c0,0.11-0.01,0.23-0.01,0.34c-0.06-0.06-0.11-0.13-0.17-0.19
					c-0.03,0.12-0.05,0.25-0.05,0.37c-0.09-0.06-0.16-0.14-0.2-0.23c-0.05,0.08-0.07,0.17-0.07,0.26c-0.04-0.02-0.08-0.06-0.1-0.1
					c-0.05,0.08-0.08,0.18-0.09,0.28c-0.02-0.05-0.06-0.1-0.1-0.14c-0.03,0.09-0.05,0.19-0.08,0.28c-0.02-0.05-0.03-0.1-0.05-0.15
					c-0.02,0.08-0.04,0.15-0.07,0.23c-0.01-0.07-0.04-0.16-0.04-0.24C79.47,369.61,79.44,369.62,79.44,369.62z M83.97,366.56
					c0,0-1.15,2.49-3.77,3.31l0.39-0.21C80.59,369.66,82.68,368.54,83.97,366.56z M81.01,377.53c-0.21,0.19-0.65,0.59-0.93,0.82
					c-0.38,0.31-0.78,0.45-0.41-0.11c0.33-0.5,0.8-0.68,1.3-0.79C81.01,377.44,81.04,377.5,81.01,377.53z M80.49,373.31
					c0.3,0.1,0.58,0.23,0.85,0.29c0.14,0.03,0.28,0.05,0.42,0.04c0.14,0,0.27-0.03,0.45-0.04c0.34,0,0.71,0.15,0.89,0.46
					c0.09,0.15,0.13,0.33,0.12,0.49c-0.01,0.17-0.07,0.32-0.14,0.45c0.01-0.16,0.02-0.31,0-0.45c-0.03-0.14-0.08-0.26-0.17-0.36
					c-0.17-0.2-0.43-0.3-0.7-0.29c-0.12,0-0.3,0.03-0.46,0.02c-0.16-0.01-0.33-0.04-0.48-0.1
					C80.97,373.71,80.72,373.51,80.49,373.31z M82.47,375.36c-0.32,0.34-0.6,0.86-0.71,1.1c-0.02,0.03-0.06,0.02-0.07-0.01
					c-0.02-0.57,0.27-1.3,0.72-1.58C82.9,374.56,82.9,374.91,82.47,375.36z M87.65,378.06c-0.41,0.76-1.2,1.76-2.55,2.72
					c-0.58,0.41-1.52,0.2-1.96,0.47c-0.44,0.27-0.66,0.26-0.96,0.43c-0.3,0.17-0.43,0.16-0.81,0.62c0.12-0.47,0.33-0.53,0.88-0.86
					c0.55-0.33,0.57-0.54,0.85-1.24c0.28-0.71,0.33-0.88,0.99-1.27c0.67-0.39,0.4-0.9,0.86-1.31c0.46-0.41,0.94-0.68,1.57-1.07
					c0.63-0.39,0.89-0.51,1.12-1.15c0.24-0.64,0.39-0.83,0.69-1.02c0,0-0.41,0.97-0.42,1.43C87.9,376.26,88.05,377.32,87.65,378.06z
					 M90.27,363.08c-0.15-0.7-0.34-0.75-0.61-1c-0.26-0.25-0.21-0.39-0.21-0.39c0.45,0.35,0.61,0.04,0.85,0.19
					c0.24,0.15,0.41,1.22,0.43,1.67C90.76,363.99,90.42,363.78,90.27,363.08z M91.56,364.36c0,0-0.03-2.25-0.77-3.73
					C90.78,360.63,92.12,363.23,91.56,364.36z"
              />
              <path
                fill="#AD7D62"
                d="M71.72,347.99c-0.96,0.04-1.91,0.24-2.8,0.6c0.21-0.4,0.42-0.8,0.63-1.21
					c-0.61,0.48-1.23,0.96-1.84,1.44c0.2-0.37,0.39-0.74,0.59-1.11c-0.66,0.4-1.33,0.79-2,1.18c0.13-0.2,0.22-0.42,0.2-0.66
					c-0.21,0.31-0.5,0.57-0.83,0.76c0.23-0.34,0.38-0.73,0.44-1.13c-0.27,0.73-0.83,1.34-1.53,1.68c0.33-0.47,0.66-0.95,0.98-1.42
					c-0.71,0.77-1.43,1.55-2.2,2.26c0.32-0.46,0.54-0.98,0.64-1.53c-0.17,0.5-0.43,0.97-0.76,1.39c0-0.03-0.01-0.06-0.01-0.09
					c-0.02,0.02-0.04,0.04-0.07,0.07c0.01-0.05,0.02-0.11,0.02-0.16c-0.05,0.09-0.1,0.19-0.15,0.28c-0.05,0.05-0.09,0.09-0.14,0.14
					c0.06-0.24,0.12-0.48,0.19-0.72c-0.17,0.23-0.37,0.44-0.6,0.62c-0.01-0.02-0.01-0.05-0.02-0.08c0.02-0.11,0.04-0.22,0.06-0.34
					c-0.02,0.1-0.05,0.2-0.07,0.3c-0.03-0.13-0.03-0.27-0.01-0.4c-0.14,0.26-0.3,0.52-0.47,0.77c0.05-0.44,0.07-0.88,0.06-1.32
					c-0.09,0.6-0.22,1.19-0.39,1.77c-0.02,0.02-0.03,0.04-0.05,0.06c0.08-0.37,0.1-0.75,0.07-1.12c-0.15,0.29-0.29,0.57-0.44,0.86
					c-0.03-0.15-0.06-0.31-0.09-0.46c0.04-0.23,0.07-0.47,0.11-0.7c-0.06,0.12-0.12,0.24-0.18,0.36c-0.07-0.3-0.13-0.6-0.21-0.9
					c-0.01,0.56-0.06,1.11-0.12,1.67c-0.07,0.18-0.12,0.37-0.18,0.55c0-0.03-0.01-0.07-0.01-0.1c0.05-0.27,0.1-0.53,0.15-0.8
					c-0.06,0.14-0.12,0.28-0.19,0.43c-0.03-0.29-0.06-0.59-0.09-0.88c0.03-0.14,0.05-0.28,0.08-0.42c-0.03,0.12-0.05,0.24-0.09,0.35
					c-0.05-0.52-0.11-1.05-0.16-1.57c-0.01,0.16-0.02,0.32-0.02,0.49c-0.03-0.21-0.06-0.43-0.09-0.64
					c-0.05,0.48-0.13,0.96-0.21,1.43c0.01-0.27,0.01-0.54,0-0.81c-0.09,0.57-0.21,1.14-0.35,1.7c-0.01-1.09-0.03-2.17-0.04-3.26
					c-0.17,1.35-0.4,2.7-0.68,4.03c-0.03-0.1-0.05-0.2-0.08-0.3c0.01,0.18,0.02,0.37,0.03,0.55c-0.04,0.17-0.07,0.35-0.11,0.52
					c-0.05-0.18-0.1-0.37-0.15-0.55c-0.01,0.43-0.15,0.86-0.41,1.2c-0.09-0.37-0.11-0.77-0.04-1.14c-0.2,0.47-0.31,0.98-0.33,1.49
					c0.09-0.98,0.03-1.98-0.17-2.95c-0.03,1.85-0.45,3.53-0.88,5.35c-0.01,0.02-0.03,0.04-0.04,0.07l0.01-0.05
					c-0.11,0.21-0.22,0.42-0.32,0.63c-0.05,0.1-0.1,0.21-0.15,0.32c-0.01,0.03-0.02,0.06-0.03,0.09c-0.08,0.18-0.15,0.37-0.22,0.56
					c-0.27,0.76-0.45,1.54-0.55,2.34c-0.03,0.14-0.04,0.28-0.06,0.4c-0.06,0.34-0.1,0.69-0.14,1.04c-0.07,0.7-0.08,1.41-0.05,2.12
					c0,0.01,0,0.02,0,0.02c0.02,0.39,0.05,0.78,0.1,1.17c0.03,0.2,0.05,0.4,0.09,0.59c0.03,0.18,0.11,0.39,0.11,0.57
					c0.01-0.56,0.01-1.11,0.02-1.67c0.2,1.45,0.21,2.93,0.05,4.39c0.17-0.57,0.28-1.15,0.34-1.74c-0.15,1.62-0.06,3.27,0.27,4.86
					c-0.04-0.69-0.08-1.38-0.12-2.07c0.15,1.38,0.69,2.71,1.54,3.81c0.09,0.18,0.18,0.36,0.28,0.54c0.03,0.05,0.05,0.1,0.08,0.14
					c-0.04-0.05-0.09-0.1-0.13-0.16c-0.06-0.08-0.13-0.15-0.19-0.23c0.05,0.07,0.11,0.15,0.16,0.22c0.45,0.63,0.9,1.26,1.34,1.9
					c0.09-0.07,0.19-0.14,0.29-0.22c-0.71-1.1-1.33-2.29-1.92-3.46c0,0,0-0.01,0-0.01c0.61,1.16,1.3,2.29,2.06,3.36
					c0.02-0.01,0.03-0.03,0.05-0.04c-1.06-1.76-1.75-3.73-2.43-5.68c0.66,1.44,1.44,2.84,2.32,4.16c-0.62-1.42-1.19-2.85-1.71-4.31
					c0.36,0.75,0.78,1.46,1.25,2.14c-0.2-0.54-0.39-1.08-0.59-1.62c0.22,0.38,0.45,0.76,0.67,1.14c-0.89-2.21-1.39-4.57-1.49-6.96
					c0.69,2.17,1.61,4.27,2.75,6.24c-1.21-2.76-1.25-3.57-1.89-6.51c0.85,2,0.92,1.87,1.99,3.76c-0.71-2.17-1.35-4.37-1.94-6.58
					c0.5,1.35,1.69,4.73,2.46,5.95c-0.91-2.32-1-3.54-1.3-4.37c0.26,0.54,0.58,1.06,0.95,1.53c-0.12-0.55-0.24-1.11-0.36-1.66
					c0.29,0.43,0.58,0.87,0.87,1.3c-0.04-1.33-0.4-2.66-1.05-3.83c0.62,1.15,1.23,2.3,1.85,3.45c-0.42-1.54-0.85-3.07-1.27-4.61
					c0.37,0.86,0.79,1.71,1.26,2.52c0-0.56-0.08-1.13-0.24-1.67c0.12,0.23,0.28,0.44,0.47,0.63c-0.01-0.52-0.09-1.04-0.22-1.54
					c0.09,0.26,0.24,0.51,0.42,0.71c0.01-0.56,0.41-2.09,0.42-2.65c0.16-0.46-0.07,0.05,0.09-0.41c0.07,0.49-0.15,2.33,0.33,2.19
					c-0.12-0.48,0.54-2.43,0.43-2.91c0.44,0.32,0.7,1.43,1.01,2.35c-0.07-0.26,0.29-2.55,0.06-2.69c0.53,0.31,1.18,0.63,1.37,1.69
					c0.08-0.3,0.32-2.19,0.04-2.33c0.99,0.34,3.62-0.22,4.55-0.7c-0.53-0.21-2.47-0.18-2.66-0.71c1.55,0.31,3.52,0,5.56,0.94
					c-0.08-0.22-0.88-0.99-1.28-1.22c0.73,0.29,0.94,0.29,1.79,0.53c1.35,0.38,2.8,0.76,2.8,0.76s-1.08-0.7-1.98-1.27
					c0.77,0.32,1.67,0.58,2.14,0.64c1.18,0.16,1.93,0.03,2.65,0.02l-0.26,0.59c1.61,0.81,3.01,2.02,4.06,3.48
					c-0.23-0.61-0.56-1.18-0.96-1.69c0.64,0.63,1.27,1.25,1.91,1.88c-0.34-0.36-0.61-0.79-0.77-1.26c0.6,0.58,1.2,1.17,1.8,1.75
					c-0.29-0.38-0.51-0.83-0.63-1.3c0.78,0.85,1.53,1.72,2.25,2.61c-0.21-0.6-0.41-1.2-0.62-1.8c0.54,0.65,1.07,1.3,1.61,1.95
					c-0.08-0.29-0.16-0.57-0.24-0.86c0.22,0.32,0.43,0.61,0.67,0.88c0.19,0.54,0.59,1.39,0.99,1.6c0.51,0.28,0.49-0.4,0.76-0.07
					s0.47,0.61,0.47,0.61s-0.04-0.48-0.32-0.84c-0.28-0.36-0.5-0.52-1.04-0.86c-0.1-0.06-0.19-0.14-0.26-0.22
					c-0.03-0.04-0.06-0.08-0.09-0.12c-0.24-0.36-0.27-0.8-0.12-0.99c0.3-0.38,1.3,0.61,1.3,0.61c-0.4-0.49-0.93-1.22-1.43-1.13
					c-0.33,0.06-0.41,0.35-0.41,0.64c-0.25-0.33-0.49-0.65-0.73-0.97c-0.03-0.04-0.06-0.07-0.09-0.11
					c-0.04-0.06-0.09-0.12-0.13-0.17c-0.69-0.89-1.35-1.65-2.21-2.43c-0.49-0.45-1.03-0.84-1.59-1.2c-1.31-1.72-2.77-3.36-4.72-4.22
					c0.28,0.21,0.55,0.42,0.83,0.63c-1.13-0.63-1.25-0.74-1.68-0.87c0.27,0.09-1.46-0.71-1.27-0.5c1.04,0.75-0.71-0.11-2.08,0.19
					c0.35-0.09,1.14-0.48,1.42-0.52c-0.91,0.07-1.83,0.14-2.74,0.2c0.16-0.24,0.35-0.45,0.58-0.63c-0.43,0.05-0.86,0.09-1.29,0.14
					c-0.48,0.05-0.97,0.1-1.45,0.15C71.38,348.38,71.55,348.19,71.72,347.99z"
              />
              <path
                fill="#AD7D62"
                d="M56.25,356.39c-0.02,0.05-0.05,0.11-0.07,0.16c0.01-0.03,0.03-0.06,0.05-0.09
					C56.24,356.44,56.25,356.41,56.25,356.39z"
              />
              <path
                fill="#AD7D62"
                d="M56,356.97c-0.2,0.45-0.38,0.91-0.55,1.38c0.18-0.44,0.36-0.88,0.54-1.32c0.06-0.16,0.13-0.32,0.19-0.47
					C56.12,356.69,56.06,356.83,56,356.97z"
              />
              <path
                fill="#AD7D62"
                d="M90.31,361.87c-0.24-0.15-0.41,0.17-0.85-0.19c0,0-0.05,0.14,0.21,0.39c0.26,0.25,0.46,0.31,0.61,1
					c0.15,0.7,0.49,0.91,0.47,0.46C90.72,363.09,90.55,362.02,90.31,361.87z"
              />
              <path
                fill="#AD7D62"
                d="M90.78,360.63c0.74,1.48,0.77,3.73,0.77,3.73C92.12,363.23,90.78,360.63,90.78,360.63z"
              />
              <path
                fill="#C16032"
                d="M100.33,372.34c-0.93-0.02-1.8,0.03-2.63,0.2c-2.96,0.63-5.44,2.92-8.2,9.56
					c-0.77,1.84-1.58,2.92-2.41,3.75c-0.09,0.09-0.18,0.18-0.27,0.26c-0.04-0.03-0.08-0.06-0.12-0.09
					c-1.23-0.85-2.16-1.34-2.53-1.28c-0.97,0.18-1.51-0.32-2.44-0.76c-1.68-0.8-2.78,0.22-2.78,0.22c-0.64,2.32-4.98,8.28-6.26,9.11
					c-0.04,0.03-0.09,0.05-0.12,0.07c-0.15,1.64,1.92,2.91,1.72,3.92c-0.2,1.02,1.58,2.5,3.84,5.99c1.01,1.56,3.79,4.54,6.89,7.31
					c-0.06-0.39-0.02-0.49,0.15-0.25c0.27,0.38,0.54,0.75,0.81,1.09c2.49,2.14,5.09,4.04,7.04,4.87c2.26,0.57,3.84,0.19,3.84,0.19
					s-0.45,1.29,1.15,4c5.58,9.5,12.45,24.89,15.68,32.33l0.31-0.02c4.62-10.42,11.49-11.18,13.29-14.81
					c1.8-3.63,3.83-10.2,6.26-11.82c0.15-0.1,0.31-0.19,0.46-0.27c0-0.09-0.01-0.18-0.01-0.26c-0.12-0.85-0.29-2.25-0.22-2.99
					c0.1-1.11,0.82-1.75,1.31-2.41c0.49-0.67,0.51-1.66,0.61-2.94c0.1-1.27,0.5-1.79,0.85-2.35c0.01-0.01,0.01-0.02,0.02-0.03
					c-1.37-3.31-3.05-6.54-4.57-9.77c-3.06-6.54-8.58-13.09-11.84-17.67c-2.43-3.42-3.45-6.17-5.63-8.74
					c-3.85-4.54-8.79-6.36-12.67-6.46C101.3,372.28,100.8,372.3,100.33,372.34z"
              />
              <path
                fill="#F1C6B0"
                d="M58.32,380.81c-0.08-0.02-0.16-0.04-0.23-0.07C58.22,380.81,58.32,380.81,58.32,380.81z"
              />
              <path
                fill="#AD7D62"
                d="M61.98,383.07l-2.58-0.41c-0.72,0.04-1.31-1.37-1.11-0.78s0.83,0.88,0.62,1
					c-0.21,0.11-0.44,0.15-0.76-0.07c0,0,0,0.02,0.02,0.04c0.04,0.11,0.19,0.39,0.57,0.37c0.3-0.02,0.39-0.18,0.86-0.08
					c0.44,0.09,1.46,0.17,2.05,0.25c0.05,0.01,0.09,0.01,0.13,0.02c0.56,0.08,0.41,0.25,0.41,0.25c0.02-0.01,0.04-0.02,0.06-0.03
					C62.54,383.42,62.48,383.08,61.98,383.07z"
              />
              <path
                fill="#AD7D62"
                d="M61.39,380.11c1.03,0.09,1.37,0.05,1.67,0.05l-2.02-0.34c-0.5-0.04-0.95-0.16-1.69,0.18
					c-0.67,0.31-1.24,0.73-1.71,0.19c0,0,0.11,0.37,0.46,0.54c0.07,0.03,0.14,0.06,0.23,0.07c0.39-0.23,0.97-0.5,1.39-0.67
					C60.12,379.96,60.36,380.02,61.39,380.11z"
              />
              <path
                fill="#AD7D62"
                d="M62.14,376.78c0.59,0.14,1.92,0.56,2.28,0.75l-1.83-1.2c-0.52,0.03-1.53,0.05-1.94,0.3
					c-0.41,0.25-1.32,0.83-1.73,1.08c-0.4,0.24-0.67-0.05-0.69-0.06c0,0,0,0.01,0,0.01c0.11,0.24,0.17,0.36,0.2,0.4
					c0.01,0.01,0.01,0.02,0.01,0.02s0.49-0.28,1.33-0.76C61.07,376.56,61.69,376.67,62.14,376.78z"
              />
              <path
                fill="#D7BFB1"
                d="M148.31,435.81c0.05-0.14,0.1-0.28,0.15-0.4c0.22-0.53,0.36-0.99,0.44-1.39
					c-0.15,0.5-0.38,1.07-0.61,1.72C148.29,435.76,148.3,435.78,148.31,435.81z"
              />
              <path
                fill="#D7BFB1"
                d="M147.58,425.51c-0.14-0.23-0.25-0.44-0.3-0.66c0,0.02,0,0.05,0,0.07
					C147.33,425.12,147.44,425.32,147.58,425.51z"
              />
              <path
                fill="#D7BFB1"
                d="M148.56,431.59c0.08,0.16,0.18,0.36,0.28,0.57C148.76,431.94,148.66,431.76,148.56,431.59z"
              />
              <path
                fill="#D7BFB1"
                d="M140.43,413.96c0,0-1.12-0.45-2.26,0c-1.13,0.45-1.3,0.95-1.17,1.53c0.09,0.38,0.24,3.68,1.52,7.51
					c0.36,1.07,1.17,2.28,1.84,3.42c3.7,0.79,6.3,4.36,7.67,8.47c0.2-1.51-0.11-3.06,0.1-4.11c0,0,0,0.01,0.01,0.02
					c-0.01-0.06-0.01-0.12,0-0.19c0.18-1.2,0.71-2.34,0.35-3.44c-0.11-0.34-0.27-0.64-0.43-0.91c0,0.03,0,0.06-0.01,0.09
					c-0.11,0.35-0.63,2.11-0.7,0.93c-0.04-0.68-0.05-1.76-0.06-2.36c-0.02-0.07-0.02-0.14-0.02-0.21c-0.07-0.71,0.43-1.43-0.42-3.04
					c-0.26-0.5-0.79-0.8-1.16-1.17c0.31,0.43,0.68,0.62,0.68,0.62c-0.22,0.64-1.02,2.23-1.15,1.05c-0.13-1.18-0.06-2.03,0.04-2.84
					c0.18-1.2-0.65-2.72-2.12-3.47c-0.45-0.23-1.06-0.46-1.7-0.66c-0.23-2.55-1.78-3.59-3.24-2.88c-0.29,0.14-0.51,0.29-0.66,0.44
					c-0.28,0.41-0.08,0.46-0.25,1.16C137.1,414.64,137.79,412.6,140.43,413.96z"
              />
              <path
                fill="#D7BFB1"
                d="M133.98,425.91c0.01,0,0.02-0.01,0.02-0.01c-0.01-0.08-0.02-0.16-0.04-0.25
					C133.97,425.73,133.98,425.82,133.98,425.91z"
              />
              <path
                fill="#AD7D62"
                d="M135.67,417.31c-0.1,1.27-0.12,2.27-0.61,2.94c-0.49,0.67-1.21,1.3-1.31,2.41
					c-0.07,0.74,0.1,2.14,0.22,2.99c0.01,0.09,0.02,0.17,0.04,0.25c2.27-1.15,4.55,0.16,6.24,0.5c0.04,0.01,0.07,0.02,0.11,0.02
					c-0.67-1.14-1.48-2.34-1.84-3.42c-1.27-3.83-1.43-7.13-1.52-7.51c-0.13-0.58,0.04-1.08,1.17-1.53c1.13-0.45,2.26,0,2.26,0
					c-2.64-1.36-3.33,0.68-3.16-0.04c0.17-0.69-0.03-0.75,0.25-1.16c0.01-0.01,0.02-0.03,0.03-0.04c0,0-0.34,0.21-0.47,0.67
					c-0.13,0.46-0.22,0.98-0.55,1.53c-0.01,0.01-0.01,0.02-0.02,0.03C136.17,415.51,135.77,416.03,135.67,417.31z"
              />
              <path
                fill="#659896"
                d="M148.31,435.81c-0.01-0.02-0.01-0.04-0.02-0.07c-0.01,0.02-0.01,0.03-0.02,0.04
					c0-0.1-0.25-0.87-0.25-0.87c0-0.01,0-0.01,0-0.02c-1.37-4.11-3.96-7.67-7.67-8.47c-0.04-0.01-0.07-0.02-0.11-0.02
					c-1.69-0.33-3.97-1.64-6.24-0.5c-0.01,0-0.02,0.01-0.02,0.01c-0.15,0.08-0.31,0.17-0.46,0.27c-2.43,1.62-4.46,8.19-6.26,11.82
					c-1.8,3.63-8.66,4.4-13.29,14.81l-0.31,0.02c-3.23-7.44-10.09-22.83-15.68-32.33c-1.6-2.72-1.15-4-1.15-4s-1.59,0.38-3.84-0.19
					c-1.96-0.83-4.55-2.72-7.04-4.87c-0.27-0.34-0.54-0.7-0.81-1.09c-0.17-0.24-0.21-0.14-0.15,0.25c-3.1-2.76-5.88-5.75-6.89-7.31
					c-2.25-3.49-4.04-4.98-3.84-5.99c0.2-1.02-1.87-2.29-1.72-3.92c0.04-0.02,0.08-0.04,0.12-0.07c-0.27-0.29-0.55-0.61-0.82-0.97
					c-1.11-1.42-1.65-2.06-1.65-2.06c-1.13,0.16-2.06-0.08-3.11-0.45c-1.05-0.37-1.71-1.38-2.42-1.54c-0.71-0.16-1.33-0.9-1.74-1.04
					c-0.42-0.14-1.09-0.11-1.99-0.25c-0.9-0.14-1-0.23-1.47-0.63c-0.47-0.4-1.16-0.67-1.11-1.24c0.04-0.57,0.36-1.23,1.1-1.43
					c0.75-0.2,1.87-0.05,2.43-0.05c0.21,0,0.33-0.03,0.36-0.08c-0.02,0.01-0.04,0.02-0.06,0.03c0,0,0.15-0.17-0.41-0.25
					c-0.04-0.01-0.09-0.01-0.13-0.02c-0.59-0.07-1.61-0.15-2.05-0.25c-0.47-0.1-0.57,0.06-0.86,0.08c-0.38,0.02-0.53-0.25-0.57-0.37
					c-0.07-0.09-0.14-0.2-0.22-0.34c-0.56-0.99,0.39-1.69,0.39-1.69s-0.09,0-0.23-0.07c-0.35-0.17-0.46-0.54-0.46-0.54
					c-0.52-1.04,0.8-2.13,0.8-2.13s0-0.01-0.01-0.02c-0.08-0.13-0.15-0.26-0.2-0.4c0,0,0-0.01,0-0.01
					c-0.25-0.72-0.02-1.58,0.75-2.17c0.05-0.04,0.1-0.07,0.15-0.11c-0.45-0.63-0.9-1.26-1.34-1.9c-2.17-0.52-3.98-0.7-5.56-0.65
					c-0.07,0-0.15-0.01-0.22-0.01l-5.28,0.32c-0.1,0.01-0.21,0.03-0.31,0.04c-5.26,0.69-11.63,2.09-22.06,2.09
					c-12.5,0-15.94-5.17-16.75-6.92c0.01-0.95,0.49-1.98,1.47-3.11c-4.23-0.5-9.08,0.36-14.48,4.18
					c-12.91,9.14-13.47,34.99-9.88,52.93c2.94,14.71,5.59,8.37,14.25,76.24c1.99,15.62,0,31.87,0,31.87s56.41,10.76,88.65,1.45
					c0,0-3.89-32.14-1.99-55.46c7.83,10.41,10.79,14.03,17.93,15.73c0.02,0.01,0.03,0.01,0.05,0.02c2.29,0.9,4.81,1.08,7.43,0.69
					c3.39-0.27,6.52-1.39,8.85-3.16c9.83-5.39,18.83-16.28,19.76-25.08c0.39-3.69,9.04-17.26,9.43-21.38
					C149.28,440.96,149.02,438.32,148.31,435.81z"
              />
              <path
                fill="#AD7D62"
                d="M145.21,422.18c0.13,1.18,0.93-0.41,1.15-1.05c0,0-0.37-0.19-0.68-0.62c-0.21-0.28-0.39-0.67-0.43-1.17
					C145.15,420.15,145.08,421,145.21,422.18z"
              />
              <path
                fill="#AD7D62"
                d="M147.34,427.27c0.07,1.17,0.58-0.59,0.7-0.93c0.01-0.03,0.01-0.06,0.01-0.09
					c-0.02-0.2-0.26-0.45-0.47-0.74c-0.13-0.18-0.25-0.38-0.3-0.59C147.29,425.51,147.3,426.59,147.34,427.27z"
              />
              <path
                fill="#AD7D62"
                d="M147.28,424.84c-0.01-0.37-0.01-0.51-0.02-0.13c0,0.07,0.01,0.14,0.02,0.21
					C147.28,424.89,147.28,424.86,147.28,424.84z"
              />
              <path
                fill="#AD7D62"
                d="M148.02,434.89c0,0.01,0,0.01,0,0.02c0,0,0.25,0.77,0.25,0.87c0.01-0.02,0.01-0.03,0.02-0.04
					c0.23-0.66,0.46-1.22,0.61-1.72c0.19-0.64,0.26-1.18,0.02-1.68c-0.03-0.06-0.05-0.11-0.08-0.17c-0.1-0.22-0.2-0.41-0.28-0.57
					c-0.24-0.48-0.39-0.73-0.42-0.79c-0.01-0.01-0.01-0.02-0.01-0.02C147.91,431.83,148.23,433.38,148.02,434.89z"
              />
              <path
                fill="#4D2C1A"
                d="M44.31,339.36c2.11-13.61,0.85-20.8,6.45-22.65c5.03-1.66,6.83,4.96,7.45,9.03
					c0.01,0.15,0.02,0.31,0.04,0.47c0.16,1.14,0.28,2.37,0.36,3.82c0.39,3.18,0.84,4.79,0.47,6.94c-0.1,0.56-0.26,0.98-0.46,1.29
					c-0.04,1.19-0.1,2.47-0.18,3.88c-0.2,3.53-1.99,9.51-2.45,14.84c0.06-0.14,0.12-0.28,0.19-0.41c0.02-0.05,0.05-0.11,0.07-0.16
					c-0.01,0.02-0.01,0.05-0.02,0.07c0.05-0.11,0.1-0.21,0.15-0.32c0.1-0.21,0.21-0.42,0.32-0.63l-0.01,0.05
					c0.01-0.02,0.03-0.04,0.04-0.07c0.43-1.82,0.85-3.5,0.88-5.35c0.2,0.97,0.26,1.97,0.17,2.95c0.02-0.51,0.14-1.02,0.33-1.49
					c-0.07,0.38-0.05,0.77,0.04,1.14c0.26-0.34,0.4-0.77,0.41-1.2c0.05,0.18,0.1,0.37,0.15,0.55c0.04-0.17,0.07-0.35,0.11-0.52
					c-0.01-0.18-0.02-0.37-0.03-0.55c0.03,0.1,0.06,0.2,0.08,0.3c0.28-1.33,0.51-2.68,0.68-4.03c0.01,1.09,0.03,2.17,0.04,3.26
					c0.15-0.56,0.27-1.13,0.35-1.7c0.01,0.27,0.01,0.54,0,0.81c0.09-0.47,0.16-0.95,0.21-1.43c0.03,0.21,0.06,0.43,0.09,0.64
					c0.01-0.16,0.02-0.32,0.02-0.49c0.05,0.52,0.11,1.05,0.16,1.57c0.03-0.12,0.06-0.23,0.09-0.35c-0.03,0.14-0.05,0.28-0.08,0.42
					c0.03,0.29,0.06,0.59,0.09,0.88c0.06-0.14,0.12-0.28,0.19-0.43c-0.05,0.27-0.1,0.53-0.15,0.8c0,0.03,0.01,0.07,0.01,0.1
					c0.05-0.19,0.11-0.37,0.18-0.55c0.06-0.55,0.11-1.11,0.12-1.67c0.07,0.3,0.14,0.6,0.21,0.9c0.06-0.12,0.12-0.24,0.18-0.36
					c-0.04,0.23-0.07,0.47-0.11,0.7c0.03,0.15,0.06,0.31,0.09,0.46c0.15-0.29,0.29-0.57,0.44-0.86c0.03,0.37,0.01,0.75-0.07,1.12
					c0.02-0.02,0.03-0.04,0.05-0.06c0.17-0.58,0.3-1.18,0.39-1.77c0.02,0.44,0,0.88-0.06,1.32c0.17-0.25,0.33-0.5,0.47-0.77
					c-0.02,0.13-0.02,0.27,0.01,0.4c0.03-0.1,0.05-0.2,0.07-0.3c-0.02,0.11-0.04,0.22-0.06,0.34c0.01,0.03,0.01,0.05,0.02,0.08
					c0.23-0.18,0.43-0.39,0.6-0.62c-0.06,0.24-0.12,0.48-0.19,0.72c0.05-0.05,0.09-0.09,0.14-0.14c0.05-0.1,0.09-0.19,0.15-0.28
					c-0.01,0.05-0.02,0.11-0.02,0.16c0.02-0.02,0.04-0.04,0.07-0.07c0.01,0.03,0.01,0.06,0.01,0.09c0.32-0.42,0.58-0.89,0.76-1.39
					c-0.1,0.55-0.32,1.07-0.64,1.53c0.77-0.71,1.49-1.49,2.2-2.26c-0.33,0.47-0.66,0.95-0.98,1.42c0.7-0.34,1.26-0.95,1.53-1.68
					c-0.06,0.4-0.22,0.79-0.44,1.13c0.33-0.19,0.61-0.45,0.83-0.76c0.02,0.24-0.07,0.46-0.2,0.66c0.67-0.38,1.34-0.77,2-1.18
					c-0.2,0.37-0.39,0.74-0.59,1.11c0.61-0.48,1.23-0.96,1.84-1.44c-0.21,0.4-0.42,0.8-0.63,1.21c0.89-0.35,1.84-0.55,2.8-0.6
					c-0.17,0.19-0.34,0.38-0.5,0.58c0.48-0.05,0.97-0.1,1.45-0.15c0.02-0.53,0.01-0.89-0.06-1.13c0.16-0.32,0.3-0.78,0.4-1.38
					c0.03-0.18,0.09-0.47,0.18-0.83c0.07-0.29-0.04-0.6-0.27-0.79c-0.02-0.01-0.03-0.03-0.05-0.05c-0.39-0.48-0.41-2.57-0.41-3.44
					c0-1.78,0.22-1.57,0.86-1.84c0.08-0.03,0.15-0.06,0.21-0.08c0.5-0.16,0.77-0.11,1.01-0.83c0.01-0.04,0.03-0.07,0.04-0.08
					c0.08-1.23-0.07-2.17-0.64-2.4c-0.01-0.01-0.03-0.01-0.04-0.02c-2.87,1.42-8.56-3.66-9.59-13.94c-0.15-1.54-0.33-2.79-0.5-3.79
					c-0.05-2.39,0.08-4.93,0.65-7.48c2.21-9.83,2.87-3.43,7.22-7.08c0.31-0.26,0.64-0.57,0.99-0.94c0.31-0.33,0.58-0.68,0.82-1.05
					c-0.25,1.5-0.78,2.65-1.26,3.44c-0.52,0.85-0.98,1.27-0.98,1.27s0.39-0.2,1.09-0.96c0.53-0.57,1.23-1.47,2.08-2.84
					c1.56-2.53,1.63-5.38,0.8-7.66c0.76,1.11,1.18,1.94,1.18,1.94c-0.47-3.32-1.85-5.6-3.22-7.09c-0.21-0.39-0.43-0.76-0.67-1.12
					c1.81,0.98,2.35,1.83,2.35,1.83c-1.54-3-4.07-4.96-7.19-5.89c-0.81-0.31-1.68-0.5-2.6-0.54c-0.12-0.01-0.24-0.01-0.36-0.02
					c-0.06-0.01-0.13-0.01-0.19-0.01c-1.9-0.15-3.3-0.65-4.57-1.38c3.47,0.54,6.47,0.51,3.81-0.05c-3.85-0.81-6.94-2.57-6.94-2.57
					l7.38,1.28c0,0-2.91-0.76-6.92-3.47c-4-2.71-10.41-4.18-13.35-3.96c-5.64-0.32-11.22,2.66-20.22,2.97
					c-5.34,0.18-10.14,2.5-13.68,6.1c-2.71,2.41-5.75,5.89-7.24,10.91c-1.97,6.63-2.69,10.04-2.69,10.04s2.06-5.29,3.35-8.24
					s-0.46,2.3-1.66,7.37c-1.2,5.07,0.46,15.21,0.46,15.21s-1.01-7.87,1.99-15.75c-0.79,4.19-2.29,10.66-1.35,19.31
					c0.25,2.33,0.73,4.46,1.32,6.39c0.17,0.56,0.35,1.1,0.53,1.63c1.62,4.64,3.75,8.15,4.63,10.95c-0.64-2.26-2.63-9.31-3.91-14.01
					c-0.17-0.63-0.27-1.34-0.32-2.08c1.26,4.93,2.9,9.38,4.39,13.07c2.04,5.06,3.61,12.32,4.14,14.96c0.13,0.64,0.2,1.01,0.2,1.01
					C27.11,360.85,42.02,354.16,44.31,339.36z"
              />
              <path fill="#4D2C1A" d="M9.4,340.36c0.13,0.46,0.21,0.73,0.21,0.73C9.55,340.85,9.47,340.6,9.4,340.36z" />
              <path
                fill="#D7BFB1"
                d="M125.24,346.97c0,0,0.72-0.46,1.38-0.36c0.62,0.09,2.85,0.61,3.37,0.95c0.06,0.04,0.1,0.06,0.13,0.07
					c0.16,0.05-0.33-0.49-0.33-0.49c-0.01,0-0.02-0.01-0.02-0.01c-0.85-0.31-2.26-0.44-2.97-0.65
					C125.99,346.25,125.24,346.97,125.24,346.97z"
              />
              <path
                fill="#AD7D62"
                d="M71.23,319.78c-0.26,0.43-0.8,0.38-0.8,0.38C69.87,319.78,70.82,320.92,71.23,319.78z"
              />
              <path fill="#AD7D62" d="M71.25,319.9l-0.63,0.69C71.21,320.37,71.25,319.9,71.25,319.9z" />
            </g>
            <path
              opacity="0.3"
              d="M53.16,357.74c-0.26-2.45-0.43-3.84-0.55-4.61c-0.17,1.13-0.4,2.81-0.62,4.74
				c-0.14,1.85-0.31,3.86-0.5,5.5c-0.03,0.48-0.05,0.96-0.07,1.43c0.2-0.21,0.65-0.63,1.37-0.98c0.6-0.29,1.39-0.54,2.37-0.6
				c0-0.05-0.01-0.1-0.01-0.14c0-0.1-0.01-0.19-0.01-0.29C53.42,361.98,53.48,360.8,53.16,357.74z"
            />
            <path
              opacity="0.3"
              d="M88.01,374.88c0.03-0.09,0.16-0.26,0.23-0.32c0.32-0.28,0.92-0.23,0.92-0.23
				c-0.86-0.33-1.12-0.78-0.99-0.49c0.13,0.29,0.11,0.61-0.07,0.7l0,0.04L88.01,374.88z"
            />
            <path
              opacity="0.3"
              d="M84.93,380.92c-0.29,0.21-0.56,0.38-0.78,0.46c0,0,1.3-0.52,1.19-0.36c-0.27,0.41-0.34,0.81-0.12,0.63
				c0.22-0.18,0.43-0.46,1.36-1.5c0.98-1.09,2.47-1.9,1.93-1.84c-0.54,0.06-0.76,0.49-0.73,0.18c0.03-0.31,0.07-0.75-0.07-0.51
				c-0.11,0.18-0.18,0.35-0.47,0.71c-0.09,0.12-0.21,0.25-0.35,0.42C86.47,379.59,85.64,380.4,84.93,380.92z"
            />
            <path
              fill="#EB9996"
              d="M81.51,381.99l0.05,0.12l-0.02,0.02c0,0,0-0.03,0.6-0.42c0.7-0.45,0.96-0.4,1.11-0.79
				c0.15-0.39,0.17-0.5,1.02-1.13c0.85-0.62,1.03-0.91,1.52-1.84s1.16-0.99,1.65-1.59c0.47-0.57,0.52-1.3,0.65-1.78
				c0-0.02,0.01-0.04,0.01-0.05c0,0-0.02,0.04-0.06,0.09c-0.08,0.13-0.24,0.38-0.34,0.57c-0.02,0.04-0.05,0.09-0.07,0.14
				c-0.1,0.22-0.17,0.47-0.33,0.66c-0.08,0.1-0.3,0.22-0.59,0.42c-0.39,0.27-0.85,0.55-1.21,0.77c-0.09,0.06-0.2,0.13-0.27,0.17
				c-0.34,0.23-0.5,0.44-0.58,0.66c-0.02,0.05-0.03,0.1-0.04,0.15c-0.04,0.19-0.09,0.31-0.18,0.42c-0.08,0.1-0.2,0.2-0.36,0.33
				c-0.07,0.06-0.22,0.13-0.29,0.19c-0.22,0.17-0.38,0.34-0.54,0.64c-0.04,0.07-0.08,0.15-0.11,0.24c-0.01,0.02-0.02,0.05-0.02,0.07
				c-0.18,0.53-0.25,0.72-0.41,0.97c-0.1,0.16-0.72,0.51-1,0.72C81.52,381.91,81.51,382.01,81.51,381.99z"
            />
            <path
              opacity="0.3"
              d="M59.3,375.26c0.89-0.68,2.01-1.49,2.87-1.46c0.98,0.03,2.53,0.12,3.22,0.39c0.7,0.28,0.94,1.05,1.79,1.11
				c2.07,0.13,2.93,0.96,4.39,2.06c1.47,1.1,2.9,3.68,4.3,4.47c0.58,0.33,1.27,0.9,1.85,1.43l2.22,0.56
				c0.46-0.09,1.05-0.06,1.74,0.26c0.24,0.11,0.46,0.23,0.66,0.34l0.2,0.05c0,0-0.27-0.33-0.68-0.92c-0.4-0.59-0.55-1.17-0.29-1.4
				l-0.03-0.01l-0.02-0.14l-0.04,0c0,0-0.27,0.52-0.03,1.22c0.06,0.16,0.15,0.32,0.25,0.46c-0.59-0.54-1.35-1.2-1.84-1.46
				c-0.88-0.48-2.71-0.16-3.98-1.12c-0.91-0.68-1.7-2.33-2.07-3.21c-0.09-0.21-0.15-0.38-0.19-0.48c-0.03-0.07-0.04-0.11-0.04-0.11
				c-2.7,0.5-2.95-1.46-2.95-1.46s0.24,0.14-0.02-0.11c-0.09-0.09-0.24-0.22-0.47-0.41c-0.16-0.14-0.37-0.3-0.62-0.51
				c-3.78-3.04-6.17-2.97-9.52-3.85c-0.37-0.1-0.7-0.2-1.01-0.32c-0.03-0.01-0.05-0.02-0.08-0.03c-0.08-0.03-0.15-0.06-0.23-0.09
				c-0.12-0.05-0.23-0.1-0.34-0.16c-0.08-0.04-0.15-0.08-0.23-0.12c-0.33-0.18-0.61-0.39-0.86-0.61c-0.01-0.01-0.03-0.02-0.04-0.04
				c-0.63-0.6-0.99-1.36-1.17-2.32c-0.11-0.6-0.18-1.19-0.24-1.71l-0.13,2.07l-0.03,0.55l0.09-0.82l0.04-0.36
				c-0.01,0.07-0.01,0.15-0.02,0.24c-0.02,0.41,0.01,0.97,0.06,1.53c0.11,1.24,0.32,2.53,0.32,2.53l0.06-0.31l-0.16-1.35
				c0.03,0.24,0.11,0.55,0.23,0.88c0.18,0.52,0.44,1.1,0.71,1.65c0.25,0.52,0.51,1.01,0.7,1.37c0.21,0.39,0.35,0.64,0.35,0.64
				l0.4,0.35L59.3,375.26z"
            />
            <path
              opacity="0.3"
              d="M134.59,413.86c-0.26,1.36,0.39,1.87-0.21,3.07c-0.92,1.84-0.43,2.59-0.69,3.44s-1.39,1.87-1.22,3.23
				c0.05,0.39,0.18,0.83,0.3,1.32c-0.39,1.29-1.85,4.16-3.52,5.99c-1.9,2.08-1.78,3.2-2.6,6.19c-0.57,2.07-2.24,3.52-3.75,4.73
				c-0.24-0.23-0.47-0.46-0.7-0.69l0.04-0.03c0,0-4.39-6.06-5.1-7.16c-1.38-2.12-1.09-3.27-1.38-6.35
				c-0.22-2.31-0.74,1.55-0.33,3.57c0.41,2.03-0.54-0.12-1.3-3.42s-0.88-0.09-0.06,1.91c0.83,2,0.94,1.65-0.46,1.19
				c-2.7-0.9,0.43,0.18,2.19,2.22c0.43,0.6,1.19,1.67,2.04,2.93c1.3,2,2.7,4.32,3.29,5.29c0.13,0.24,0.25,0.47,0.37,0.7
				c-0.67,0.38-3.58,2.12-5.67,5.12c-3.41-0.58-3.77-4.91-4.65-6.56c-1.88-3.5-4.17-4.57-3.63-7.26c0.54-2.69-4.3-8.2-5.65-11.83
				c-1.34-3.63,1.27-13.17,0.29-10.37c-0.21,0.59-0.47,1.12-0.76,1.6c0.58-1.13,0.73-2.05,0.73-2.05c-4.51,5.15-5.85-0.18-6.76-2.76
				c-0.92-2.6-1.53-4.43-1.53-2.45s1.22,6.27-0.92,2.9c-2.14-3.36-7.49-8.41-10.7-12.84c-3.04-4.2-4.94-7.5-5.13-7.84
				c-0.01-0.02-0.02-0.03-0.02-0.03c-0.11,0.19-0.22,0.38-0.33,0.57c-0.41,0.68-0.85,1.34-1.3,1.96l0.61-0.94l0.93-1.44
				c-1.01,0.64-1.75,0.73-3.74-0.72c-1.84-1.33-5.39-0.61-7.18-1.38c-1.87-0.8-5.55,0.03-7.4-0.41c-0.22-0.05-0.33-0.13-0.39-0.22
				c0.09-0.52,0.41-1.08,1.08-1.26c0.75-0.2,1.87-0.05,2.43-0.05c0.21,0,0.33-0.03,0.36-0.08c-0.02,0.01-0.04,0.02-0.06,0.03
				c0,0,0.15-0.17-0.41-0.25c-0.04,0-0.08-0.01-0.13-0.02c-0.59-0.07-1.61-0.15-2.05-0.25c-0.47-0.1-0.57,0.06-0.86,0.08
				c-0.38,0.02-0.53-0.25-0.57-0.37c-0.07-0.09-0.14-0.2-0.22-0.34c-0.56-0.99,0.39-1.69,0.39-1.69s-0.09,0-0.23-0.07
				c-0.35-0.17-0.46-0.54-0.46-0.54c-0.52-1.04,0.8-2.13,0.8-2.13s0-0.01-0.01-0.02c-0.31-0.47-0.38-1.05-0.19-1.59h0
				c0,0-0.5,1.08-0.42,1.67s-0.92,1.33-0.58,2.17c0.33,0.83-0.25,1.42,0.17,2.33c0.42,0.92,0.67,2,0.5,2.75
				c-0.03,0.13-0.02,0.31,0.04,0.52c0.02,0.11,0.05,0.23,0.11,0.33c0.39,0.94,1.47,2.15,3.18,2.15c2.33,0,3.25,0.92,4.08,1.58
				c0.83,0.67,3,1.33,4.17,1.08c1.17-0.25,2.25,2.25,3,3.33c0.12,0.17,0.29,0.38,0.5,0.61l1.28,2.34c0,0,0.5,1.09,1.48,2.7
				c0.47,0.79,1.06,1.7,1.76,2.67c1.86,3.21,4.05,6.66,6.2,9.31c5.4,6.66,10.37,8.48,14.18,8.51c0.23,0.46,0.48,0.95,0.77,1.46
				c1.87,4.04,4.47,9.12,8.87,18.61c4.11,8.88,6.1,15.52,5.98,22.12c0,0,1.67-4.28,0.2-9.49c0,0,0,0.01,0.01,0.01l1.5,3.27
				c0.85,2.47,1.62,6.23,1,10.98c-1.13,8.66,1.15,1.95,1.92-6.09c0.52-5.45-0.72-7.47-1.57-8.21c1.34,0.85,4.12,3.11,4.84,7.43
				c0.96,5.74,3.53-7.02-3.17-8.36c-6.7-1.34,0.1-3.89,5.46-1.59c0,0,1.15-2.3-4.02-2.11c-5.17,0.19,2.68-4.6,5.94-6.13
				c3.09-1.46-3.55-0.34-7.19,3.5c0.37-0.44,0.76-0.86,1.17-1.27c0.54-0.55,1.11-1.07,1.71-1.53c-0.05,0.04-0.09,0.08-0.14,0.13
				c-0.06,0.05-0.1,0.1-0.14,0.14c-1.12,1.19,3.86-1.66,5.04-3.49c0.14-0.21,0.31-0.51,0.51-0.86c0.42-0.74,0.96-1.74,1.52-2.81
				c1.28-2.43,2.69-5.23,3.16-6.16c1.56-1.89,2.99-2.21,4.15-2.58c1.99-0.63,6.23-0.5,6.23-0.5s-0.52-0.27-1.2-0.56
				c-0.02-0.01-0.03-0.01-0.05-0.02c-0.74-0.32-1.67-0.65-2.32-0.69c-1.28-0.09-1.02-1.11-3.81,0.08c-0.07,0.03-0.14,0.07-0.21,0.1
				c-0.01,0-0.01,0.01-0.02,0.01c0-0.09-0.01-0.17-0.01-0.26c-0.12-0.85-0.29-2.25-0.22-2.99c0.1-1.11,0.82-1.75,1.31-2.41
				c0.49-0.67,0.51-1.66,0.61-2.94c0.1-1.27,0.5-1.79,0.85-2.35c0.01-0.02,0.02-0.04,0.04-0.07l-0.9-2.05c0,0-0.01-0.01-0.03-0.01
				C135.42,412.83,134.81,412.67,134.59,413.86z M72.59,393.77c-0.03,0.03-0.05,0.06-0.08,0.08c-0.01-0.07-0.01-0.13-0.01-0.2
				C72.53,393.69,72.56,393.73,72.59,393.77z M95.4,416.76c0.15-0.02,0.3-0.04,0.44-0.06c0.66-0.11,1.25-0.27,1.78-0.48
				c-0.5,0.26-0.83,0.37-0.83,0.37S96.35,416.82,95.4,416.76z M114.2,454.27c0.25,0.49,0.55,1.15,0.85,1.98
				C114.84,455.75,114.55,455.08,114.2,454.27z M133.79,426.06c-0.3,0.18-0.58,0.4-0.86,0.67l0.03-0.06l0.08-0.15
				c0,0.03,0,0.05,0,0.08c0.15-0.13,0.29-0.25,0.44-0.35C133.58,426.18,133.68,426.12,133.79,426.06z"
            />
            <path
              opacity="0.3"
              d="M108.39,401.3c0.4-1.73,0.76-11.6,0.23-13.71c-0.54,1.43-1.19,2.97-1.95,4.63
				c-0.15,0.32-0.28,0.65-0.4,0.99c-0.1,0.3-0.19,0.61-0.29,0.92c-1.3,4.53-0.98,10.34-2.63,14.71c6.57-4.55,13.45,1.2,18.73,4.74
				c5.4,3.62,7.33,3.67,6.21,3.24c-1.12-0.43,1.89,1.32-6.74-5.24c-9.81-7.46-10.25-6.18-10.17-8.85c0.01-0.44,0.18-3.29,1.37-6
				C113.6,394.77,107.89,403.51,108.39,401.3z"
            />
            <path
              opacity="0.3"
              d="M81.96,495.5c-0.73-17.62,2.74-20.41,2.74-20.41c-5.86-11.06-27.79-46.53-27.47-56.94
				c0.33-10.41-2.6,6.51-2.28,14.97c0.33,8.46-3.9-5.21-5.21-13.02c-1.3-7.81,1.63,9.76,1.95,13.34c0.33,3.58-4.23-4.23-8.46-5.21
				c-4.23-0.98,5.29,8.38,8.22,18.8c2.93,10.41,4.26,13.42,3.28,26.44c-0.98,13.02,25.13,22.02,14.07,28.53
				c-11.06,6.51-35.93,9.38-46.66,9.38s14.92,9.21,31.84,3.67c16.92-5.53,2.46,7.77-17.71,8.75c-18.84,0.91,16.51,7.78,3.26,8.95
				c-0.5-0.12-1.08-0.25-1.73-0.38c-16.32-3.35-37.03-9.01-30.55-20.09c5.42-9.27,2.79-19.06-3.87-38.39
				c-0.6-1.75-1.25-3.58-1.92-5.47l0.15,0.15c-0.23-0.54-0.46-1.1-0.69-1.66c-0.83-2.31-1.7-4.7-2.6-7.14
				c-3.58-10.92-6.21-23.63-8.21-35.26c-2.45-14.27-2.24-13.98-1.15,1.67c0.3,4.28-0.94,4.31-2.28,2.02
				c-0.12-0.35-0.25-0.69-0.37-1.03c-1.32-5.57-2.3-9.41-2.39-8.89c-0.02,0.11,0,0.41,0.07,0.94c0.22,1.79,0.59,3.46,1.03,4.93
				c0.23,0.76,0.47,1.46,0.72,2.09c0.58,2.08,1.35,4.87,2.19,8.08c0.09,0.36,0.19,0.71,0.28,1.08c1.46,6.39,3,13.3,4.11,18.32
				c0.64,2.9,1.14,5.17,1.4,6.34c0.22,1.36,0.41,2.67,0.55,3.88c1.55,13.07,3.63,23.34,4.88,36.87c0.14,1.51,0.27,3.06,0.39,4.66
				c0.06,0.78,0.11,1.57,0.16,2.37c1.01,15.87-0.8,22.7-0.8,22.7s3.17,0.42,8.08,1.35c1.11,0.21,2.3,0.45,3.56,0.71
				c3.61,0.75,9.68,1.35,15.54,1.69c1.72,0.1,3.41,0.18,5.03,0.23c2.29,0.31,5.39,0.6,8.61,0.84c4.07,0.3,8.33,0.5,11.41,0.5
				c1.79,0,3.63-0.04,5.45-0.1c9.44-0.32,18.55-1.29,18.61-1.3C75.92,532.8,82.82,516.19,81.96,495.5z"
            />
            <path
              opacity="0.3"
              d="M95.35,393.92c2.09,2.43,2.66,3.82,2.9,4.75c0.23,0.93,0.33-0.73-0.81-2.32
				c-1.14-1.58,0.72-0.12,1.65,1.02c0.93,1.14,1.43-0.64-0.86-1.78c-1.13-0.56-3.23-2.33-5.07-3.98
				C93.94,392.37,94.72,393.18,95.35,393.92z"
            />
            <path opacity="0.3" d="M93.17,391.6c-1.76-1.74-3.49-3.25-3.49-3.25S91.29,389.92,93.17,391.6z" />
            <path
              opacity="0.3"
              d="M9.95,376.7c9.07,2.72,30.27-1.26,33.3-3.01c0.03-0.02,0.06-0.03,0.08-0.05
				c-0.69-13.82,4.45-33.7,4.45-33.7c0.51-2.75,0.68-4.24,0.63-5c0.03-0.65-0.05-1.28-0.26-1.89c-1.69-4.82-1.7-8.46-1.45-11.49
				c0.3-0.98,0.62-1.77,0.96-2.4c1.47-2.77,3.12-2.56,3.12-2.56s1.32-0.32,2.23-0.22c1.5,0.17,1.38-0.71,0.67-3.21
				c-0.39-1.36-0.24-2.53,0.23-3.61l0.01,0.15c0,0,2.93-5.67,1.01,1.42c2.43-4.45,7.23-11.66,4.86-6.48
				c-2.37,5.19,0.44,9.82-0.3,7.75c-0.74-2.07,0.5-6.23,2.67-10.52c1.5-2.98,1.48-6.16,1.15-8.38c0.01-0.23,0.02-0.45,0.02-0.67
				c1.53,4.41,0.47,8.01,0.47,8.01s1.33-3.56,0.15-9.19c-1.19-5.63-8.16-7.28-12.57-7.51l0.01,0.12c-7.06-1.05-12.17,1.06-7.78-1.14
				c6.09-3.04,12.79-1.42,3.28-2.56c-6.98-0.84-14.45,3.15-14.74,3.3c0.09-0.05,0.96-0.53,2.88-1.75c4.5-2.85,7.8-3.63,9.21-3.81
				c-0.18,0-0.39-0.01-0.65-0.03c-3.77-0.27-9.71,0.76-15.8-0.36c-5.37-0.99-9.78-0.2-13.69,1.46c-0.23,0.1-0.45,0.2-0.68,0.3
				c-1.2,0.8-2.32,1.72-3.33,2.75c-2.56,2.27-5.41,5.51-6.98,10.1c-0.09,0.27-0.18,0.54-0.26,0.82c-1.97,6.63-2.69,10.04-2.69,10.04
				s2.06-5.29,3.35-8.24c1.29-2.95-0.46,2.3-1.66,7.37c-0.95,4.03-0.1,11.26,0.29,14.06c0.1,0.72,0.17,1.15,0.17,1.15
				s-0.92-7.15,1.59-14.64c0.12-0.37,0.26-0.74,0.4-1.11c-0.79,4.19-2.29,10.66-1.35,19.31c0.25,2.33,0.73,4.46,1.32,6.39
				c0.17,0.56,0.35,1.1,0.53,1.63c1.37,3.93,3.11,7.04,4.14,9.61c0.19,0.47,0.36,0.92,0.49,1.35c-0.64-2.26-2.63-9.31-3.91-14.01
				c-0.12-0.45-0.21-0.94-0.27-1.46c-0.02-0.2-0.04-0.41-0.06-0.62c1.26,4.93,2.9,9.38,4.39,13.07c2.04,5.05,3.61,12.32,4.14,14.96
				c0,0.16,0,0.32,0,0.48c0,1.94-0.2,3.69-0.53,5.25c-0.03,0.13-0.07,0.25-0.11,0.38c-0.68,2.32-2.46,5.09-3.95,6.78
				c-0.05,0.06-0.1,0.12-0.15,0.17c-0.25-0.01-0.49-0.02-0.74-0.02c-2.69-0.05-5.1,0.28-7.26,0.92c-3.46,1.03-6.29,2.84-8.6,5.14
				c-1.74,1.73-3.18,3.73-4.37,5.87c-5.84,10.48-5.73,24.28-5.25,25.72c0.01,0.02,0.01,0.04,0.02,0.05
				C-14.8,394.04-11.57,370.24,9.95,376.7z M46.05,324.49c-0.08,0.46-0.16,0.93-0.24,1.39c0.12-0.95,0.27-1.79,0.43-2.56
				c0.05-0.24,0.11-0.48,0.16-0.71C46.3,323.17,46.18,323.82,46.05,324.49z"
            />
            <path
              opacity="0.3"
              d="M86.69,386.27l2.99,2.09c0,0,0.16-1.57,0.37-3.77s2.26-7.97,2.1-7.67c-0.39,0.72-1.13,1.99-1.92,3.49
				c-0.48,0.92-0.99,1.91-1.44,2.92c-0.27,0.6-0.53,1.08-0.77,1.47C87.25,386.02,86.69,386.27,86.69,386.27z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#27BAE1"
            d="M250.45,362.74l-0.89,0.08l-0.45,0.04l-0.23,0.02c-0.04,0.01-1.38,0.08-1.44,0.08l0.09,0.31l0.01,0.5
			c0.04-0.01,1.36-0.26,1.44-0.28l0.21-0.04l0.44-0.08l0.88-0.16l1.76-0.3l3.52-0.59l-3.55,0.27L250.45,362.74z"
          />
          <path
            fill="#27BAE1"
            d="M181.3,348.6c-0.09,0.32-0.21,0.66-0.33,0.95l-0.02,0.06l-0.01,0.04c-0.31,1.14-0.72,2.3-1.14,3.44
			c-0.43,1.14-0.89,2.27-1.42,3.35c-0.27,0.54-0.54,1.06-0.87,1.54c-0.33,0.49-0.69,0.93-1.12,1.27c-0.84,0.68-2.03,0.84-3.23,0.87
			l-7.42,0.08l-1.86,0.02l-0.23,0c-0.06,0-0.1-0.01-0.16-0.01c-0.05-0.01-0.1-0.03-0.16-0.05c-0.05-0.02-0.11-0.06-0.16-0.08
			c-0.21-0.13-0.42-0.32-0.61-0.53c-0.76-0.88-1.31-1.99-1.86-3.08l-0.1-0.19l-0.2,0.01c-0.57,0.04-1.11,0.1-1.67,0.21
			c-0.14,0.03-0.28,0.06-0.42,0.1c-0.03,0.01-0.08,0.02-0.13,0.04c-0.05,0.02-0.09,0.04-0.13,0.07c-0.08,0.05-0.15,0.1-0.21,0.16
			c-0.23,0.22-0.39,0.45-0.55,0.68c-0.31,0.45-0.61,0.89-0.96,1.26c-0.35,0.38-0.76,0.69-1.23,0.86c-0.23,0.09-0.48,0.14-0.73,0.16
			l-0.19,0.01l-0.2,0l-0.41,0c-0.54,0-1.09,0-1.63-0.01c-1.08-0.02-2.17-0.09-3.23-0.27c-0.53-0.09-1.05-0.21-1.56-0.38
			c-0.5-0.17-1-0.38-1.42-0.69c-0.42-0.3-0.77-0.71-1.02-1.17c-0.26-0.46-0.43-0.96-0.55-1.48c-0.12-0.52-0.2-1.05-0.27-1.59
			l-0.22-1.61c-0.13-1.07-0.2-2.15-0.23-3.23l0-0.11l-0.09,0.06c-0.46,0.29-0.94,0.57-1.42,0.84c-0.48,0.26-0.97,0.52-1.49,0.69
			c-0.26,0.08-0.54,0.05-0.81,0.05c-0.28-0.01-0.55-0.03-0.82-0.06c-0.54-0.06-1.04-0.13-1.6-0.37c0.51,0.21,1.06,0.32,1.6,0.4
			c0.27,0.03,0.55,0.06,0.83,0.07c0.14,0.01,0.28,0.01,0.41,0.01c0.14,0.01,0.28-0.01,0.42-0.05c0.54-0.16,1.03-0.41,1.52-0.66
			c0.46-0.24,0.9-0.49,1.34-0.76c0.02,1.05,0.07,2.09,0.17,3.14l0.19,1.61c0.06,0.54,0.13,1.08,0.24,1.62
			c0.12,0.54,0.29,1.07,0.55,1.56c0.26,0.49,0.63,0.94,1.09,1.28c0.46,0.34,0.97,0.57,1.49,0.76c0.52,0.19,1.06,0.32,1.61,0.42
			c1.09,0.21,2.19,0.29,3.28,0.34c0.55,0.02,1.1,0.03,1.64,0.04l0.41,0l0.2,0l0.22-0.01c0.29-0.02,0.59-0.07,0.87-0.17
			c0.56-0.2,1.05-0.56,1.44-0.97c0.4-0.41,0.72-0.87,1.04-1.3c0.16-0.21,0.32-0.43,0.48-0.58c0.04-0.04,0.08-0.07,0.12-0.09
			c0.02-0.01,0.04-0.02,0.05-0.02c0.01,0,0.03-0.01,0.06-0.02c0.12-0.03,0.25-0.06,0.38-0.08c0.45-0.08,0.91-0.13,1.37-0.16
			c0.51,1.05,1.02,2.12,1.8,3.07c0.22,0.25,0.46,0.49,0.77,0.69c0.08,0.04,0.16,0.09,0.25,0.13c0.09,0.03,0.19,0.07,0.29,0.09
			c0.1,0.01,0.21,0.03,0.31,0.03l0.23,0l1.86,0.02l7.44,0.07c0.64-0.01,1.28-0.05,1.94-0.18c0.65-0.13,1.32-0.4,1.88-0.82
			c0.56-0.42,0.99-0.94,1.36-1.46c0.38-0.53,0.69-1.09,0.98-1.65c0.57-1.12,1.06-2.26,1.53-3.41c0.45-1.14,0.88-2.28,1.23-3.48
			c0.16-0.37,0.28-0.71,0.39-1.07c0.11-0.38,0.2-0.76,0.26-1.16c0.05-0.4,0.08-0.81,0.03-1.24c-0.01-0.05-0.01-0.09-0.02-0.14
			l0.3-0.06l0.69-0.13l2.76-0.52c1.84-0.34,3.68-0.68,5.52-1c3.68-0.66,7.37-1.29,11.07-1.86l2.77-0.42
			c0.85-0.13,1.7-0.24,2.58-0.41l0,0.04c2.27,0.16,4.55,0.34,6.8,0.6c0.28,0.03,0.56,0.07,0.84,0.11l0.42,0.06l0.21,0.03l0.18,0.03
			c0.49,0.1,0.95,0.31,1.38,0.59c0.43,0.28,0.82,0.64,1.17,1.04c0.25,0.29,0.49,0.61,0.7,0.93c-2.44,1.65-4.97,3.21-7.57,4.6
			c-0.68,0.36-1.38,0.71-2.07,1.04c-0.69,0.31-1.42,0.56-2.16,0.77c-1.48,0.42-3.02,0.67-4.56,0.85c-1.54,0.17-3.1,0.26-4.65,0.27
			c-1.56,0.01-3.12-0.05-4.66-0.24c-1.53-0.18-3.01-0.72-4.41-1.38c-0.7-0.33-1.39-0.7-2.07-1.08c-0.34-0.19-0.67-0.39-1-0.6
			l-0.5-0.31c-0.15-0.09-0.29-0.19-0.46-0.27l1.5-2.87l-1.62,2.87l-0.03,0.05l0.06,0.03c0.32,0.17,0.65,0.41,0.98,0.61
			c0.33,0.21,0.66,0.42,0.99,0.62c0.67,0.4,1.35,0.79,2.05,1.14c1.4,0.69,2.89,1.28,4.46,1.51c1.56,0.23,3.13,0.32,4.7,0.35
			c1.57,0.03,3.14-0.02,4.71-0.16c1.57-0.14,3.13-0.37,4.67-0.76c0.77-0.2,1.53-0.44,2.27-0.75c0.73-0.32,1.44-0.65,2.14-1.01
			c2.81-1.42,5.5-3.01,8.14-4.72l0.35-0.23l-0.19-0.38c-0.15-0.3-0.3-0.54-0.47-0.8c-0.17-0.25-0.35-0.49-0.54-0.73
			c-0.39-0.46-0.84-0.89-1.35-1.25c-0.51-0.36-1.1-0.63-1.73-0.78l-0.24-0.05l-0.22-0.04l-0.43-0.07c-0.29-0.05-0.57-0.09-0.86-0.13
			c-1.27-0.18-2.54-0.33-3.81-0.46l2.5-0.89c1.77-0.62,3.52-1.28,5.29-1.84c1.77-0.56,3.57-1.05,5.38-1.49
			c3.62-0.91,7.3-1.61,10.97-2.36c1.84-0.37,3.68-0.74,5.5-1.21c0.91-0.23,1.82-0.48,2.7-0.8c0.44-0.16,0.88-0.32,1.31-0.52
			c0.21-0.1,0.42-0.2,0.63-0.32c0.2-0.12,0.41-0.24,0.56-0.42c-0.16,0.17-0.37,0.28-0.58,0.39c-0.21,0.1-0.42,0.2-0.64,0.28
			c-0.43,0.17-0.88,0.31-1.33,0.44c-0.9,0.26-1.81,0.47-2.72,0.66c-1.83,0.37-3.68,0.67-5.53,0.98c-3.7,0.6-7.4,1.22-11.08,2.02
			c-1.84,0.41-3.66,0.86-5.47,1.39c-1.82,0.53-3.59,1.15-5.36,1.75l-2.66,0.9c-0.83,0.27-1.65,0.54-2.47,0.72c-0.02,0-0.03,0-0.05,0
			l0,0.02c-0.04,0.01-0.07,0.02-0.11,0.03c-0.87,0.17-1.81,0.27-2.74,0.41c-0.93,0.12-1.86,0.26-2.79,0.4
			c-3.71,0.56-7.41,1.17-11.11,1.82c-1.85,0.33-3.69,0.67-5.53,1.03l-2.76,0.53l-0.69,0.14l-0.34,0.07l-0.13,0.02l-0.1-0.15
			c-0.14-0.21-0.29-0.42-0.46-0.62c-0.34-0.39-0.73-0.76-1.22-1.04c-0.24-0.14-0.52-0.25-0.8-0.33c-0.28-0.08-0.61-0.09-0.82-0.1
			c-0.94-0.04-1.88-0.06-2.82-0.09c-1.88-0.04-3.76-0.07-5.64-0.05c-0.94,0.01-1.88,0.02-2.83,0.06c-0.94,0.04-1.88,0.07-2.85,0.2
			c-0.96,0.13-1.92,0.37-2.83,0.74c-0.45,0.19-0.9,0.42-1.32,0.69c-0.21,0.14-0.41,0.29-0.61,0.46c-0.05,0.04-0.1,0.08-0.15,0.13
			l-0.09,0.09c-0.01,0.01-0.04,0.02-0.07,0.03l-1.15-0.04l-0.03,0l-0.01,0c-0.06,0.01-0.12-0.03-0.15-0.08
			c0,0.01-0.05-0.15-0.08-0.26c-0.04-0.12-0.08-0.23-0.13-0.35c-0.2-0.45-0.52-0.92-1.03-1.19c-0.25-0.14-0.55-0.2-0.77-0.22
			c-0.24-0.03-0.46-0.05-0.69-0.07c-0.45-0.04-0.9-0.06-1.36-0.08c-1.8-0.08-3.6-0.09-5.4-0.07c-0.45,0.01-0.9,0.02-1.35,0.04
			c-0.45,0.01-0.94,0.07-1.38,0.21c-0.45,0.14-0.87,0.36-1.25,0.62c-0.19,0.13-0.37,0.28-0.54,0.44c-0.08,0.08-0.16,0.16-0.24,0.25
			c-0.09,0.09-0.13,0.16-0.21,0.19c-0.15,0.08-0.34,0.07-0.5-0.05c-0.19-0.12-0.38-0.24-0.58-0.35c-0.39-0.23-0.79-0.43-1.2-0.62
			c-0.76-0.34-1.55-0.61-2.36-0.77c0.12-0.15,0.24-0.3,0.37-0.44c0.86-0.9,1.91-1.61,3.02-2.22c1.11-0.6,2.27-1.1,3.46-1.55
			c2.37-0.9,4.82-1.59,7.29-2.21c2.47-0.62,4.96-1.15,7.47-1.62c5.01-0.96,10.05-1.73,15.11-2.42c1.26-0.18,2.53-0.34,3.8-0.51
			l1.9-0.24l0.95-0.12c0.34-0.05,0.68-0.11,1.01-0.19c0.67-0.17,1.32-0.42,1.93-0.74c0.61-0.32,1.17-0.7,1.69-1.11
			c0.52-0.4,1.01-0.84,1.49-1.26l1.43-1.27c1.9-1.69,3.83-3.34,5.81-4.92c1.99-1.57,4.03-3.07,6.16-4.43
			c2.13-1.36,4.34-2.59,6.65-3.61c1.15-0.52,2.33-0.96,3.53-1.36c1.2-0.41,2.42-0.76,3.64-1.08c2.46-0.64,4.95-1.15,7.46-1.59
			c2.51-0.42,5.04-0.76,7.58-1.05c2.54-0.29,5.08-0.51,7.63-0.68c2.55-0.17,5.1-0.31,7.66-0.42c-5.12-0.02-10.24,0.14-15.34,0.54
			c-2.56,0.2-5.1,0.49-7.64,0.85c-0.36,0.05-0.72,0.11-1.09,0.17c-0.62,0.08-1.23,0.15-1.85,0.24c-0.87,0.13-1.74,0.26-2.6,0.41
			c-0.52,0.08-1.04,0.17-1.55,0.27c0.16-0.08,0.32-0.16,0.48-0.23l8.14-3.9l4.08-1.94c1.35-0.66,2.73-1.29,4.07-1.99
			c-2.9,0.87-5.75,1.88-8.56,3.01c-2.81,1.11-5.57,2.35-8.3,3.66c-1.54,0.74-3.06,1.52-4.57,2.32c-1.37,0.31-2.74,0.62-4.1,0.96
			c-1.7,0.42-3.39,0.89-5.08,1.35c-0.85,0.23-1.68,0.49-2.52,0.74c-0.84,0.25-1.68,0.51-2.51,0.77c-1.67,0.53-3.33,1.1-4.99,1.67
			c-1.65,0.6-3.29,1.21-4.92,1.86c1.71-0.39,3.41-0.83,5.1-1.26l2.54-0.65l2.54-0.67c1.7-0.41,3.38-0.89,5.08-1.3
			c1.47-0.37,2.94-0.76,4.42-1.12c-1.93,0.89-3.8,1.93-5.6,3.05c-2.19,1.36-4.29,2.86-6.3,4.44c-2.01,1.59-3.96,3.25-5.88,4.94
			c-1.95,1.65-3.69,3.56-6.01,4.09c-0.28,0.07-0.58,0.13-0.87,0.17l-0.95,0.12l-1.9,0.25c-1.27,0.17-2.54,0.34-3.81,0.52
			c-2.54,0.35-5.07,0.77-7.59,1.19c-2.53,0.43-5.05,0.89-7.55,1.43c-5.01,1.08-10.02,2.29-14.79,4.24
			c-1.19,0.49-2.35,1.06-3.46,1.72c-1.11,0.67-2.16,1.44-3.03,2.42c-0.85,1-1.58,2.49-1.87,3.73c-0.28,1.25-0.54,3.69-0.02,3.93
			c-0.02-1.27,0.1-2.94,0.44-4.15c0.28-1.02,0.71-2,1.35-2.84c0.8,0.2,1.57,0.52,2.31,0.89c0.4,0.2,0.78,0.43,1.15,0.67
			c0.19,0.12,0.37,0.25,0.55,0.37c0.19,0.16,0.51,0.22,0.76,0.1c0.06-0.03,0.12-0.07,0.17-0.12l0.12-0.12
			c0.08-0.08,0.16-0.15,0.24-0.22c0.16-0.14,0.34-0.27,0.52-0.39c0.36-0.23,0.76-0.41,1.17-0.52c0.42-0.11,0.83-0.15,1.28-0.15
			c0.44,0,0.89,0,1.34,0.01c1.79,0.04,3.58,0.13,5.35,0.26c0.44,0.03,0.89,0.07,1.33,0.12c0.22,0.02,0.44,0.05,0.65,0.09
			c0.22,0.03,0.36,0.07,0.51,0.16c0.29,0.16,0.51,0.47,0.65,0.83c0.04,0.09,0.07,0.18,0.1,0.28c0.04,0.1,0.03,0.16,0.12,0.37
			c0.16,0.35,0.55,0.58,0.94,0.57l-0.04,0l1.19,0.08l0.02,0l0.02,0c0.2-0.01,0.41-0.08,0.58-0.2l0.28-0.25
			c0.16-0.13,0.34-0.26,0.52-0.37c0.37-0.23,0.77-0.42,1.18-0.59c0.41-0.16,0.84-0.29,1.28-0.39c0.44-0.1,0.88-0.18,1.33-0.23
			c0.89-0.1,1.83-0.11,2.76-0.14c0.93-0.01,1.86-0.02,2.8-0.01c1.87,0.01,3.74,0.06,5.6,0.13c0.93,0.03,1.87,0.07,2.8,0.12
			c0.26,0.01,0.4,0.02,0.57,0.07c0.17,0.05,0.33,0.11,0.49,0.21c0.32,0.19,0.62,0.46,0.89,0.77c0.13,0.16,0.26,0.33,0.37,0.5
			l0.08,0.13l-0.02,0.01c0.13,0.23,0.21,0.5,0.25,0.8c0.04,0.3,0.03,0.62-0.01,0.95C181.47,347.94,181.39,348.27,181.3,348.6z"
          />
          <path
            fill="#27BAE1"
            d="M223.71,376.38c-0.11,4.18,0.72,8.15,2.35,11.18c1.69,3.13,4.03,4.9,6.61,4.97c0.05,0,0.11,0,0.16,0
			c2.51,0,4.89-1.63,6.71-4.6c1.79-2.93,2.84-6.85,2.95-11.03c0.11-4.19-0.72-8.15-2.35-11.18c-1.69-3.13-4.03-4.9-6.61-4.97
			c-2.57-0.07-5.01,1.56-6.86,4.6C224.87,368.28,223.82,372.2,223.71,376.38z M225.94,369.19l0.57,3.99l-1.08,0.05l0.98,0.68
			l-1.71,2.96c0-0.15,0-0.3,0.01-0.45C224.78,373.83,225.2,371.37,225.94,369.19z M226.97,387.29c-0.03-0.06-0.06-0.12-0.09-0.18
			l1.51,0.32l-0.34,1.16l0.78-0.61l0.85,2.63C228.67,389.86,227.75,388.74,226.97,387.29z M236.49,390.23l0.91-3.32l0.92,0.56
			l-0.52-1.19l1.98-0.96C238.93,387.4,237.8,389.1,236.49,390.23z M240.27,372.29l0.3-2.93c0.5,1.71,0.8,3.59,0.89,5.55l-1.07-1.95
			l0.78-0.56L240.27,372.29z M233.5,361.54c0.91,0.02,1.79,0.32,2.63,0.86l-2.23,1.88l-0.4-1.92l-0.41,1.99l-2.57-1.9
			c0.92-0.59,1.88-0.91,2.86-0.91C233.42,361.54,233.46,361.54,233.5,361.54z M229.47,363.27l4.06,3l3.63-3.06
			c0.75,0.71,1.45,1.64,2.05,2.77c0.12,0.22,0.23,0.44,0.34,0.67l-0.64,6.29l2.54,4.62c-0.12,2.21-0.49,4.28-1.07,6.13l-0.04-0.07
			l-3.88,1.88l-1.59,5.78c-0.66,0.3-1.35,0.46-2.05,0.46c-0.04,0-0.09,0-0.13,0c-0.46-0.01-0.92-0.1-1.37-0.25l-1.64-5.09
			l-3.46-0.74c-0.76-1.89-1.24-4.05-1.43-6.36l3.14-5.42l-1.01-7.02c0.2-0.41,0.42-0.81,0.65-1.18
			C228.14,364.71,228.79,363.91,229.47,363.27z"
          />
          <path
            fill="#27BAE1"
            d="M268.54,399.9l-4.51-0.46l-9.02-0.92c-5.78-0.57-11.57-1.14-17.36-1.68c2.36-0.42,4.73-0.84,7.08-1.28
			c5.37-0.99,10.74-2.03,16.08-3.2c-5.43,0.64-10.84,1.4-16.25,2.21c-2.31,0.34-4.62,0.7-6.92,1.06c1.26-1,2.44-2.36,3.47-4.05
			c2.3-3.77,3.67-8.79,3.87-14.17l1.04-0.38l-1.02-0.33c0.1-5.39-1-10.49-3.09-14.38c-2.2-4.09-5.26-6.4-8.6-6.49
			c-0.2-0.01-0.41,0-0.61,0.01l0-0.04l-8.31,0.13c0.98-1.2,2.31-2.06,3.77-2.66c1.51-0.62,3.14-0.98,4.78-1.25
			c0.83-0.1,1.66-0.23,2.5-0.29c0.42-0.03,0.84-0.08,1.25-0.1l0.61-0.02c0.2,0,0.4,0.01,0.61,0.02c0.8,0.05,1.6,0.21,2.34,0.5
			c0.75,0.28,1.45,0.69,2.07,1.2c1.25,1.02,2.19,2.41,2.88,3.91c0.69,1.5,1.16,3.12,1.49,4.76c0.17,0.82,0.31,1.65,0.41,2.48
			c0.1,0.83,0.17,1.67,0.22,2.51c0.1,1.68,0.09,3.37-0.02,5.05c-0.23,3.36-0.9,6.7-2.16,9.84l-0.09,0.22l0.23-0.04l8.51-1.6
			l-8.33,1.31c1.29-3.07,2.03-6.36,2.34-9.68c0.16-1.7,0.2-3.41,0.14-5.12c-0.02-0.85-0.09-1.71-0.18-2.56
			c-0.09-0.85-0.23-1.7-0.38-2.54c-0.32-1.68-0.77-3.36-1.47-4.95c-0.7-1.58-1.67-3.1-3.04-4.25c-0.68-0.57-1.46-1.04-2.29-1.37
			c-0.83-0.33-1.72-0.52-2.6-0.59c-0.22-0.01-0.44-0.03-0.66-0.03l-0.66,0.01c-0.43,0.02-0.85,0.06-1.28,0.09
			c-0.85,0.04-1.71,0.16-2.56,0.26c-1.7,0.25-3.41,0.59-5.05,1.24l-0.61,0.25c-0.2,0.09-0.39,0.2-0.59,0.3
			c-0.2,0.11-0.4,0.2-0.59,0.32l-0.56,0.37l-0.28,0.19c-0.09,0.07-0.17,0.14-0.26,0.21l-0.52,0.43c-0.16,0.15-0.31,0.32-0.47,0.48
			l-0.23,0.24c-0.08,0.08-0.14,0.17-0.21,0.26l-0.42,0.52c-0.13,0.18-0.25,0.37-0.37,0.55l-0.18,0.28
			c-0.06,0.09-0.11,0.19-0.17,0.28l-0.33,0.57c-0.41,0.77-0.78,1.55-1.12,2.33l-0.26,0.59l-0.25,0.6c-0.17,0.4-0.32,0.8-0.47,1.2
			c-0.3,0.8-0.57,1.61-0.85,2.42c-1.07,3.23-2.03,6.49-2.96,9.76c-0.89,3.12-1.74,6.24-2.57,9.37c-1.99,0.13-3.98,0.23-5.97,0.32
			c-2.14,0.09-4.28,0.16-6.42,0.18c-1.07,0.01-2.14,0-3.2-0.03c-1.06-0.04-2.13-0.1-3.2-0.17c-2.14-0.14-4.28-0.32-6.42-0.5
			c-8.56-0.72-17.12-1.54-25.68-2.31l-6.42-0.56c-2.14-0.19-4.28-0.35-6.41-0.59c-2.13-0.24-4.25-0.54-6.34-0.97
			c-1.04-0.22-2.08-0.47-3.09-0.78c-1.01-0.31-2-0.69-2.91-1.2c-0.45-0.26-0.87-0.55-1.23-0.9c-0.36-0.35-0.65-0.75-0.8-1.21
			c-0.08-0.23-0.13-0.47-0.13-0.71c-0.01-0.25-0.01-0.53-0.01-0.8l-0.02-1.61l-0.08-6.45l-0.08-6.45c-0.03-1.07,0.02-2.14,0.22-3.2
			c0.2-1.05,0.5-2.09,1.01-3.04c-0.54,0.93-0.88,1.97-1.11,3.02c-0.24,1.05-0.32,2.14-0.33,3.22l-0.08,6.45l-0.08,6.45l-0.02,1.61
			c0,0.27-0.01,0.53-0.01,0.81c0,0.29,0.05,0.59,0.14,0.87c0.17,0.57,0.52,1.07,0.92,1.47c0.4,0.41,0.87,0.74,1.34,1.02
			c0.96,0.56,1.99,0.96,3.02,1.3c0.56,0.18,1.12,0.34,1.69,0.49c1.26,5.21,3.09,8.24,5.99,9.7c-0.38-0.03-0.76-0.06-1.14-0.09
			l-3.25-0.19l-4.57-0.28l-4.91-0.17l4.51,0.49l4.51,0.46l3.67,0.32c3.82,0.38,7.64,0.75,11.46,1.12c-1.3,0.13-2.6,0.27-3.9,0.42
			c-1.77,0.2-3.53,0.42-5.3,0.66c-1.76,0.25-3.53,0.48-5.28,0.81c3.56-0.09,7.12-0.24,10.67-0.47c1.78-0.1,3.55-0.24,5.32-0.36
			l5.24-0.42c5.96,0.57,11.92,1.1,17.89,1.65l18.05,1.6l16.98,1.44c0.2,0.04,0.4,0.07,0.6,0.1l9.61,0.87l-7.64,1.32
			c-2.69,0.49-5.38,0.95-8.06,1.47c-2.69,0.5-5.37,1.02-8.05,1.55c-2.68,0.55-5.36,1.08-8.02,1.7c5.42-0.69,10.84-1.44,16.24-2.26
			c2.7-0.4,5.4-0.84,8.1-1.25l8.09-1.32l5.1-0.88c1.06,0.08,2.12,0.17,3.18,0.25c6.02,0.48,12.05,0.93,18.07,1.37l9.04,0.64
			l4.52,0.32l4.52,0.29L268.54,399.9z M233.28,356.88c6.12,0.17,10.85,9.19,10.56,20.12c-0.15,5.36-1.47,10.35-3.74,14.06
			c-2.19,3.59-4.99,5.54-7.88,5.46l-0.74-0.07c-5.76-0.83-10.11-9.56-9.83-20.06c0.15-5.36,1.47-10.35,3.74-14.06
			c2.15-3.53,4.89-5.46,7.73-5.46C233.17,356.88,233.23,356.88,233.28,356.88z M214.48,385.01l0.12-0.44
			c1.73-6.54,3.52-13.09,5.56-19.53c0.53-1.61,1.06-3.19,1.74-4.71c0.34-0.78,0.69-1.53,1.07-2.26l0.3-0.53
			c0.05-0.09,0.1-0.18,0.15-0.27l0.03-0.05l5.31-0.08c-1.62,1.02-3.11,2.6-4.39,4.7c-2.35,3.86-3.73,9.02-3.88,14.53
			s0.95,10.74,3.09,14.72c1.11,2.06,2.44,3.67,3.91,4.77l-8.98-0.82c-4.17-0.6-6.38-3.29-7.93-9.79c1.15-0.06,2.3-0.13,3.44-0.21
			L214.48,385.01z M162.51,382.31c3.58,0.38,7.16,0.73,10.74,1.09c-2.2,4.34-5.35,6.86-8.55,6.77l-0.81-0.08
			c-3.24-0.51-6.13-3.59-7.91-8.45c0.04,0,0.08,0.01,0.12,0.01L162.51,382.31z M143.24,379.87c0.01,0,0.03,0.01,0.04,0.01
			c2.12,0.47,4.25,0.8,6.39,1.08c1.63,0.21,3.26,0.37,4.88,0.54c1.27,3.72,3.15,6.55,5.39,8.22l-8.86-0.83
			C147.01,388.31,144.77,385.75,143.24,379.87z M200.87,393.5l-18.06-1.52c-4.05-0.33-8.1-0.66-12.16-0.99
			c1.62-0.17,3.24-0.33,4.86-0.52c3.54-0.4,7.07-0.84,10.59-1.42c-3.57,0.04-7.12,0.2-10.67,0.42c-1.78,0.09-3.55,0.24-5.32,0.35
			l-0.53,0.04c1.94-1.35,3.69-3.51,5.04-6.33c0.24,0.02,0.48,0.05,0.72,0.07c4.28,0.42,8.56,0.85,12.84,1.22
			c2.14,0.18,4.29,0.37,6.44,0.52c1.08,0.07,2.15,0.14,3.24,0.18c1.09,0.04,2.17,0.05,3.25,0.04c2.73-0.02,5.45-0.11,8.17-0.24
			c0.97,4.19,2.31,7.46,4.85,9.35L200.87,393.5z"
          />
          <path
            fill="#27BAE1"
            d="M142.68,374.56c-0.24,0.17-0.54,0.29-0.84,0.4c-0.6,0.22-1.23,0.4-1.86,0.58
			c-0.61,0.2-1.26,0.18-1.89,0.01c-0.62-0.17-1.22-0.45-1.75-0.83c0.51,0.4,1.09,0.73,1.72,0.93c0.62,0.21,1.32,0.27,1.97,0.09
			c0.63-0.15,1.27-0.3,1.9-0.5c0.32-0.1,0.63-0.21,0.94-0.4c0.15-0.1,0.31-0.21,0.4-0.44c0.04-0.11,0.04-0.25,0.01-0.36
			c-0.03-0.11-0.1-0.2-0.16-0.28l-0.01-0.01l-0.03-0.02l-4.7-3.49l0,0l-0.02-0.01c-0.13-0.08-0.21-0.23-0.22-0.38l-0.01-0.74
			l-0.01-0.76c0-0.14,0.09-0.29,0.2-0.36c0.06-0.04,0.12-0.06,0.18-0.06c0.03,0,0.06,0,0.09,0.01l0.19,0.04
			c0.25,0.05,0.5,0.1,0.76,0.14c0.51,0.09,1.01,0.17,1.52,0.24c2.03,0.29,4.06,0.49,6.1,0.68c2.03,0.18,4.07,0.35,6.1,0.5
			c2.03,0.14,4.07,0.28,6.1,0.4c2.04,0.12,4.07,0.25,6.11,0.36l6.11,0.31l6.11,0.29l6.11,0.25l6.11,0.23l1.53,0.05l0.19,0.01
			c0.04,0,0.04,0,0.06,0.01c0.03,0.01,0.06,0.02,0.08,0.04c0.02,0.02,0.01,0.01,0.04,0.05l0.11,0.16l0.22,0.31l0.44,0.62l0.11,0.16
			l0.06,0.08c0.02,0.03,0.02,0.04,0.03,0.04c0.02,0.03,0.02,0.05,0.03,0.07c0.01,0.04-0.02,0.12-0.11,0.19
			c-0.02,0.02-0.04,0.03-0.07,0.05c-0.01,0.01-0.03,0.01-0.04,0.02l-0.02,0.01l-0.04,0.02l-0.35,0.13c-1.91,0.66-3.86,1.26-5.8,1.89
			c-0.97,0.31-1.94,0.62-2.91,0.96c-0.24,0.08-0.49,0.17-0.74,0.27l-0.19,0.08c-0.08,0.03-0.15,0.07-0.24,0.13
			c-0.04,0.02-0.09,0.07-0.13,0.1c-0.05,0.05-0.1,0.09-0.15,0.18c-0.05,0.07-0.1,0.19-0.11,0.3c-0.01,0.12,0.01,0.23,0.05,0.32
			c0.08,0.18,0.16,0.25,0.24,0.32c0.07,0.07,0.15,0.12,0.21,0.16c0.14,0.09,0.26,0.15,0.39,0.21c0.25,0.12,0.5,0.21,0.74,0.31
			l0.72,0.27l0.34,0.14c0.12,0.05,0.27,0.11,0.4,0.14c0.55,0.17,1.11,0.2,1.64,0.19c0.53-0.01,1.04-0.05,1.54-0.08
			c0.49-0.02,1.01-0.04,1.52-0.06l6.11-0.3c4.07-0.21,8.15-0.42,12.22-0.68c-4.08,0.01-8.15,0.05-12.22,0.11l-6.11,0.1
			c-0.51,0.01-1.01,0.02-1.54,0.02c-0.52,0.01-1.03,0.04-1.51,0.03c-0.49-0.01-0.95-0.05-1.38-0.19c-0.11-0.03-0.2-0.07-0.32-0.12
			l-0.36-0.15l-0.71-0.28c-0.23-0.09-0.46-0.19-0.67-0.29c-0.03-0.02-0.06-0.03-0.09-0.05c0.21-0.08,0.44-0.15,0.67-0.23
			c0.96-0.31,1.94-0.6,2.91-0.89c1.95-0.58,3.9-1.16,5.86-1.81l0.37-0.12l0.05-0.02l0.07-0.03c0.05-0.02,0.09-0.04,0.13-0.06
			c0.09-0.04,0.17-0.1,0.25-0.16c0.31-0.24,0.57-0.64,0.56-1.12c0-0.23-0.08-0.47-0.19-0.65c-0.07-0.11-0.09-0.14-0.13-0.19
			l-0.11-0.16l-0.43-0.63l-0.22-0.31l-0.11-0.16c-0.05-0.07-0.13-0.17-0.21-0.24c-0.16-0.15-0.37-0.26-0.58-0.32
			c-0.1-0.03-0.23-0.05-0.32-0.05l-0.19-0.01l-1.53-0.07l-6.11-0.26l-2.9-0.13l1.72-6.16c2.92-1.37,5.89-2.69,8.91-3.8
			c0.78-0.28,1.56-0.55,2.35-0.78c0.39-0.12,0.79-0.22,1.18-0.31c0.39-0.09,0.77-0.16,1.19-0.21c1.63-0.24,3.27-0.36,4.92-0.37
			c0.83-0.01,1.65,0.01,2.48,0.06c0.41,0.02,0.83,0.05,1.24,0.08c0.21,0.01,0.36,0.03,0.43,0.05c0,0.01,0.01,0.02,0.01,0.04
			c0.02,0.08,0.05,0.23,0.12,0.38c0.07,0.16,0.18,0.33,0.31,0.46c0.27,0.26,0.54,0.37,0.79,0.45c0.5,0.16,0.95,0.2,1.39,0.23
			c0.44,0.03,0.88,0.03,1.31,0.02l1.26-0.02c0.83-0.01,1.67-0.01,2.54-0.06c0.86-0.05,1.76-0.15,2.64-0.47
			c0.88-0.31,1.66-0.85,2.31-1.47c0.65-0.62,1.19-1.31,1.69-2.01c0.25-0.35,0.48-0.71,0.71-1.07c0.22-0.34,0.45-0.69,0.69-1.02
			c0.48-0.66,0.99-1.3,1.54-1.91c1.1-1.21,2.36-2.27,3.74-3.13c2.78-1.74,6.04-2.62,9.33-2.91c1.64-0.14,3.31-0.1,4.85,0.41
			c0.77,0.25,1.49,0.64,2.15,1.12c0.66,0.48,1.26,1.05,1.84,1.65c0.56,0.6,1.12,1.27,1.82,1.78c0.35,0.26,0.73,0.47,1.15,0.61
			c0.42,0.13,0.85,0.18,1.28,0.18c0.85-0.01,1.68-0.15,2.52-0.23c0.83-0.09,1.67-0.15,2.51-0.17c-0.84-0.02-1.68,0-2.52,0.05
			c-0.84,0.04-1.68,0.14-2.5,0.12c-0.41-0.01-0.82-0.07-1.2-0.21c-0.38-0.14-0.73-0.35-1.04-0.6c-0.65-0.51-1.15-1.16-1.71-1.8
			c-0.56-0.63-1.16-1.24-1.84-1.78c-0.68-0.53-1.44-0.98-2.25-1.28c-0.81-0.31-1.67-0.49-2.53-0.56c-0.86-0.08-1.72-0.07-2.57-0.03
			c-1.7,0.11-3.39,0.36-5.04,0.81c-1.65,0.46-3.25,1.12-4.73,2.01c-1.48,0.88-2.83,1.98-4.02,3.23c-0.59,0.63-1.14,1.29-1.65,1.98
			c-0.26,0.35-0.5,0.7-0.73,1.06c-0.23,0.35-0.45,0.69-0.69,1.02c-0.95,1.32-2.05,2.54-3.48,3.02c-0.71,0.25-1.49,0.34-2.3,0.38
			c-0.81,0.04-1.65,0.02-2.49,0.03c-0.85,0-1.68,0.05-2.47-0.01c-0.39-0.03-0.78-0.08-1.09-0.17c-0.15-0.05-0.27-0.11-0.3-0.15
			c-0.03-0.04-0.04-0.03-0.1-0.28c-0.03-0.11-0.08-0.31-0.24-0.51c-0.15-0.2-0.37-0.32-0.54-0.38c-0.17-0.06-0.32-0.09-0.45-0.1
			l-0.1-0.01l-0.08-0.01l-0.16-0.01c-0.42-0.03-0.84-0.06-1.27-0.09c-0.85-0.05-1.7-0.07-2.55-0.07c-1.7,0.01-3.41,0.12-5.11,0.37
			c-0.41,0.06-0.87,0.14-1.29,0.24c-0.42,0.1-0.84,0.21-1.25,0.34c-0.82,0.25-1.63,0.53-2.42,0.82c-1.59,0.59-3.15,1.24-4.69,1.91
			c-1.5,0.66-2.98,1.33-4.46,2.05l-10.49-0.46l-10.65-0.43l-10.65-0.39l-10.59-0.32c-0.26-0.35-0.51-0.71-0.76-1.08l-0.78-1.15
			c-0.52-0.77-1.02-1.55-1.53-2.33c0.46,0.81,0.92,1.61,1.4,2.41c0.24,0.4,0.47,0.8,0.72,1.19c0.25,0.4,0.49,0.79,0.76,1.18
			l0.03,0.05l0.08,0l10.63,0.7l10.63,0.65l10.64,0.62l9.98,0.55l-0.48,6.01l-9.31-0.41l-12.21-0.57c-2.03-0.1-4.07-0.21-6.1-0.32
			c-2.03-0.11-4.06-0.23-6.09-0.37c-2.03-0.14-4.06-0.3-6.07-0.52c-0.5-0.05-1-0.12-1.5-0.19c-0.25-0.04-0.5-0.07-0.75-0.12
			l-0.19-0.03c-0.1-0.01-0.19-0.02-0.29-0.01c-0.19,0.02-0.38,0.09-0.53,0.2c-0.3,0.22-0.48,0.57-0.48,0.94l0.03,0.76l0.03,0.79
			c0.02,0.36,0.24,0.7,0.54,0.87l-0.02-0.01l4.81,3.29c0.08,0.09,0.11,0.17,0.09,0.24C142.9,374.37,142.8,374.48,142.68,374.56z"
          />
          <path
            fill="#27BAE1"
            d="M176.81,377.19c-0.46-0.15-0.94-0.29-1.45-0.34l-1.39-0.15c-1.86-0.2-3.72-0.36-5.58-0.53l-5.59-0.44
			c-3.73-0.25-7.46-0.48-11.2-0.58c-1.87-0.05-3.74-0.1-5.63-0.02c-0.24,0.02-0.46,0.02-0.75,0.08c-0.1,0.02-0.14,0.05-0.2,0.07
			l-0.16,0.06l-0.33,0.13c-0.44,0.18-0.87,0.36-1.29,0.56c-0.85,0.39-1.69,0.82-2.5,1.29c0.86-0.36,1.74-0.68,2.62-0.98
			c0.44-0.15,0.88-0.29,1.33-0.43l0.33-0.1l0.17-0.05c0.05-0.02,0.12-0.04,0.14-0.03c0.17-0.02,0.42-0.01,0.65,0
			c1.84,0.08,3.71,0.26,5.56,0.4l5.58,0.46l5.58,0.42l5.59,0.38c1.86,0.1,3.73,0.23,5.59,0.32l1.4,0.07
			c0.42,0.02,0.85,0.11,1.28,0.22c0.87,0.23,1.75,0.54,2.61,0.88c1.73,0.67,3.43,1.45,5.13,2.23c-1.56-1.04-3.19-1.97-4.87-2.82
			C178.59,377.89,177.73,377.5,176.81,377.19z"
          />
          <path
            fill="#27BAE1"
            d="M204.06,359.4c-0.13,0-0.26,0-0.41,0c-0.95,0-0.95,0.85-0.95,1.26c0,0.23,0.01,1.34,0.03,2.75
			c0.03,2.32,0.07,5.51,0.07,6.6c0,1.85,0.25,2.24,0.94,3.29c0.21,0.32,0.35,0.57,0.45,0.75c0.23,0.41,0.4,0.71,0.9,0.71
			c0.04,0,0.07,0,0.1,0c0.03,0,0.06,0,0.09,0c0.15,0,0.32-0.02,0.47-0.16c0.21-0.21,0.24-0.54,0.24-1.19v-11.71
			c0-1.12-0.12-1.66-0.45-1.98C205.17,359.38,204.68,359.39,204.06,359.4z M205.2,373.42c0,0.3-0.01,0.48-0.02,0.58
			c-0.04,0-0.11,0-0.13,0c0,0,0,0-0.01,0c-0.04-0.04-0.12-0.18-0.19-0.32c-0.11-0.19-0.25-0.45-0.48-0.81c-0.65-1-0.8-1.23-0.8-2.86
			c0-1.1-0.04-4.28-0.07-6.61c-0.02-1.4-0.03-2.51-0.03-2.74c0-0.37,0.04-0.46,0.05-0.47c0,0,0.03-0.02,0.13-0.02
			c0.15,0,0.29,0,0.42,0c0.46-0.01,0.79-0.01,0.91,0.1c0.22,0.21,0.22,1.13,0.22,1.43V373.42z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="64.32" y="122.01" fill="none" width="280.03" height="182.74" />
        <text transform="matrix(1 0 0 1 64.3243 135.7889)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="19px">
            Wir wollen die{" "}
          </tspan>
          <tspan x="0" y="29" fontFamily={font.condensed} fontSize="24px">
            CO2-EMISSIONEN
          </tspan>
          <tspan x="185.34" y="29" fontFamily={font.serif} fontSize="19px">
            {" "}
            in der{" "}
          </tspan>
          <tspan x="0" y="58" fontFamily={font.serif} fontSize="19px">
            europäischen Neufahrzeugflotte{" "}
          </tspan>
          <tspan x="0" y="87" fontFamily={font.serif} fontSize="19px">
            zwischen 1995 und 2020{" "}
          </tspan>
          <tspan x="0" y="116" fontFamily={font.condensed} fontSize="24px">
            HALBIEREN.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 29.0416 72)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            g
          </tspan>
          <tspan x="24.95" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            e
          </tspan>
          <tspan x="48.48" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            sag
          </tspan>
          <tspan x="118.23" y="0" fontFamily={font.condensed} fontSize="50px">
            t
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
