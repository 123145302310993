import { useTranslation } from "#hooks";
import { Grid, Quotation, QuotationProps, Spacer, Wrapper } from "#ui";
import React from "react";

export const Quote2: React.FC<{ id?: string }> = ({ id }) => {
  const { t: tHome } = useTranslation("home");
  const quotation = tHome("quote2") as unknown;
  return (
    <Wrapper id={id}>
      <Spacer size={75} />
      <Grid>
        <Quotation {...(quotation as QuotationProps)} />
      </Grid>
    </Wrapper>
  );
};
