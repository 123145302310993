import React from "react";
import styled from "styled-components/macro";
import { gridColumnWidth, gridColumnStart, bp } from "#shared/theme";
import { MainContent } from "#ui";
import { Wrapper } from "#ui";
import { Grid } from "#ui";
import { Root } from "../Root/Root";

const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(23)}
  margin-bottom: 60px;
  @media ${bp.m} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(16)}
    margin-bottom: 60px;
  }
`;

export const GeneralPageLayout: React.FC = ({ children }) => {
  const headerProps = {
    transparent: true,
    withNav: true,
  };
  return (
    <Root withHeader headerProps={headerProps}>
      <MainContent>
        <Wrapper>
          <Grid>
            <Content>{children}</Content>
          </Grid>
        </Wrapper>
      </MainContent>
    </Root>
  );
};
