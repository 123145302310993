import { chartMainItemDuration, chartSideItemValuesDuration } from "#constants";
import { useChartAnimation, useNumberFormat } from "#hooks";
import { ChartProps } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";

export const LeftKPI: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const currentPathRef = useRef<SVGPathElement>(null);
  const prevPathRef = useRef<SVGPathElement>(null);
  const negativePathRef = useRef<SVGPathElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  const outerLineRef = useRef<SVGPathElement>(null);
  useChartAnimation({
    ...props,
    maxValue: Number(labels?.maxValue),
    currentValue: Number(labels?.currentValue),
    prevValue: Number(labels?.prevValue),
    minDegree: -38.9,
    maxDegree: 50.1,
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    outerLineRef,
    negativePathRef,
    duration: chartSideItemValuesDuration,
    delay: chartMainItemDuration,
  });
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="273" x="175" y="220.548">
        <path d="m448 484.048c-70.25 0-136.295-27.357-185.969-77.031s-77.031-115.719-77.031-185.969 27.357-136.295 77.031-185.969 115.719-77.031 185.969-77.031 136.295 27.357 185.969 77.031 77.031 115.719 77.031 185.969-27.357 136.295-77.031 185.969-115.719 77.031-185.969 77.031z" />
        <path
          d="m448-31.952c139.728 0 253 113.272 253 253s-113.272 253-253 253-253-113.272-253-253 113.272-253 253-253m0-20c-36.846 0-72.6 7.221-106.27 21.462-32.511 13.751-61.705 33.433-86.77 58.498s-44.747 54.259-58.498 86.77c-14.241 33.67-21.462 69.424-21.462 106.27s7.221 72.6 21.462 106.27c13.751 32.511 33.433 61.705 58.498 86.77s54.259 44.747 86.77 58.498c33.67 14.241 69.424 21.462 106.27 21.462s72.6-7.221 106.27-21.462c32.511-13.751 61.705-33.433 86.77-58.498s44.747-54.259 58.498-86.77c14.241-33.669 21.462-69.424 21.462-106.27s-7.221-72.6-21.462-106.27c-13.751-32.511-33.432-61.705-58.498-86.77-25.065-25.065-54.259-44.747-86.77-58.498-33.67-14.241-69.424-21.462-106.27-21.462z"
          fill="#fff"
        />
      </mask>
      <g ref={outerLineRef}>
        <path
          ref={negativePathRef}
          opacity=".3"
          d="M239.6,389.6c-37.3-46-59.6-104.6-59.6-168.5c0-17.8,1.8-35.2,5.1-52.1"
          stroke={Color.BlackLight}
          strokeWidth="10"
        />
        <path
          d="M239.6,389.6c-37.3-46-59.6-104.6-59.6-168.5c0-17.8,1.8-35.2,5.1-52.1"
          stroke={Color.White}
          ref={currentPathRef}
          strokeWidth="10"
        />
        <path
          d="M239.6,389.6c-37.3-46-59.6-104.6-59.6-168.5c0-17.8,1.8-35.2,5.1-52.1"
          stroke={Color.BlackLight}
          ref={prevPathRef}
          strokeWidth="10"
        />
        <path stroke={Color.BlackLight} d="M170.8,169c-3.2,16.9-4.8,34.3-4.8,52.1c0,66.9,23.3,128.4,62.3,176.7" />
        <path ref={lineRef} d="m448 221.048h-273" mask={`url(#${maskID})`} stroke={Color.White} />
      </g>

      <SvgText ref={contentRef}>
        <SvgTextSmall>
          <text opacity=".5" fontSize="14" transform="matrix(1 0 0 1 235.1753 520.2775)">
            {labels?.diff}
          </text>
          <text fontSize="14" fill={Color.BlackLight} transform="matrix(1 0 0 1 235.1755 446.2772)">
            <tspan x="0" y="0">
              {labels?.label1}
            </tspan>
            <tspan x="0" y="16.8">
              {labels?.label2}
            </tspan>
          </text>
        </SvgTextSmall>
        <text fontSize="30" transform="matrix(1 0 0 1 235.1997 499.3563)">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
