import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card11M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect x="218.11" fill="#1EBEE4" width="136.89" height="364.19" />
        <rect x="64.32" y="85" width="290.68" height="7" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="man_2_">
          <path
            fill="#42637C"
            d="M154.59,380.87c0,0-0.46-1.28-1.6-1.45c0.16,0.16-9.71,0.37-9.71,0.08c0-0.06,0.02-0.12,0.04-0.17
			c0.06-0.13,0.2-0.23,0.37-0.29c-0.49-0.01-0.93-0.02-1.32-0.03c0,0,0,0-0.01,0l-6.99,0c-0.59,0.23-0.88,0.46-1.22,0.74
			c-0.04,0.03-0.06,0.06-0.05,0.07c-1.01,0.91-1.51,2.57-0.93,5.56c0.39,1.97-0.11,0.6,0.75,5.89c0.09,0.53,0.12,0.99,0.13,1.37
			c-0.43-0.01-0.71-0.01-0.71-0.01s-0.05,1.25-0.02,2.34c0.03-0.07,0.06-0.13,0.09-0.2l0.14,0.78l0.82,0.18l0.97,0.33
			c-0.06,0.08-0.12,0.16-0.18,0.24c-0.02,0.02-0.04,0.04-0.05,0.07c0,0,3.32,0.39,3.33,0.39l0-0.31l0.75-0.14
			c0.33-0.33,0.38-0.88,0.38-1.37c0-0.43,0-0.86,0.01-1.29c0-0.02,0-0.04,0-0.06c0-0.16-0.01-0.33-0.06-0.48
			c-0.02-0.04-0.03-0.08-0.06-0.12c-0.07-0.11-0.17-0.2-0.27-0.29l-0.75-0.51l0-1.02h-0.64l0.04-0.76h0.6h0v-1.74l0.76-0.26l0.2-0.7
			c3.9-0.35,12.21-1.07,13.38-1l0.76-0.64c0.15-0.17,0.25-0.39,0.34-0.6c0.18-0.47,0.34-0.95,0.47-1.44
			C155.19,382.39,154.56,381.15,154.59,380.87z"
          />
          <path
            fill="none"
            d="M158.77,398.37h-11.19c1.51,1.29,3.46,2.08,5.6,2.08C155.3,400.45,157.26,399.67,158.77,398.37z"
          />
          <path
            fill="none"
            d="M142.29,396.49c-0.33-1.08-0.51-2.22-0.51-3.41c0-0.65,0.06-1.28,0.16-1.9h-2.2v5.31H142.29z"
          />
          <path
            fill="none"
            d="M134.89,400.83c0.16,0,0.33,0,0.5,0l0.48-0.47c-0.14-0.08-0.29-0.14-0.45-0.17c-0.1-0.02-0.21-0.03-0.32-0.03
			c-0.03,0-0.07,0-0.1,0.01c-0.08,0.16-0.13,0.34-0.13,0.54C134.87,400.74,134.88,400.78,134.89,400.83z"
          />
          <path fill="none" d="M139.2,397.2v0.42h3.5c-0.06-0.14-0.11-0.28-0.17-0.42H139.2z" />
          <path
            fill="none"
            d="M146.39,378.84c-0.75-0.13-1.42-0.27-1.84-0.4c-0.08-0.02-0.16-0.05-0.24-0.07
			c-0.73-0.18-1.46-0.38-2.13-0.59c-0.11-0.03-0.21-0.07-0.31-0.1c-0.04-0.01-0.09-0.03-0.13-0.04c-0.05-0.02-0.1-0.04-0.15-0.06
			c-0.23,0.55-0.8,0.55-1.33,0.8c-0.55,0.25-0.23-0.23,0.09-0.46c0.4-0.28,0.16-0.28-0.21-0.12c-0.07,0.03-0.54,0.28-1,0.51
			c-0.08,0.12-0.15,0.22-0.21,0.26c-0.11,0.07-0.19,0.07-0.2-0.06c-0.08,0.04-0.16,0.07-0.22,0.09c-0.02,0.01-0.04,0.02-0.07,0.02
			h3.94c0.04,0,0.07,0.03,0.08,0.07l0,0.25c0.01,0.05-0.03,0.08-0.08,0.08c0,0,0,0,0.01,0c0.39,0.01,0.83,0.02,1.32,0.03
			c0.1-0.04,0.22-0.06,0.34-0.06h2.34C146.38,378.93,146.38,378.89,146.39,378.84z"
          />
          <path
            fill="none"
            d="M161.65,398.37h-1.12c-0.85,0.95-1.87,1.74-3.02,2.31c0.79,0,1.53-0.01,2.21-0.01
			C160.47,400.01,161.12,399.23,161.65,398.37z"
          />
          <path
            fill="none"
            d="M145.81,398.37h-1.12c0.55,0.89,1.23,1.7,2.02,2.38c0.74,0,1.48-0.01,2.22-0.01
			C147.74,400.16,146.68,399.36,145.81,398.37z"
          />
          <path
            fill="none"
            d="M145.92,396.49h6.86v-1.81c-0.71-0.18-1.23-0.82-1.23-1.59c0-0.28,0.07-0.54,0.19-0.77
			c-0.01-0.06-0.01-0.13-0.01-0.19c0-0.36,0.13-0.7,0.35-0.95h-7.55c-0.01,0.17-0.02,0.35-0.02,0.52
			C144.51,393.47,145.03,395.12,145.92,396.49z"
          />
          <path
            fill="none"
            d="M144.72,389.78h8.06v-0.96c-2.16-0.08-4.99-0.24-7.64-0.4C144.97,388.86,144.83,389.31,144.72,389.78z"
          />
          <path
            fill="none"
            d="M143.28,391.81c-0.05,0.42-0.08,0.84-0.08,1.27c0,1.2,0.21,2.35,0.59,3.41h0.7
			C143.73,395.1,143.3,393.51,143.28,391.81z"
          />
          <path fill="none" d="M144.07,397.2c0.06,0.14,0.13,0.28,0.2,0.42h0.93c-0.1-0.14-0.2-0.28-0.29-0.42H144.07z" />
          <path
            fill="none"
            d="M154.31,384.66l-0.77,1.66v0.04c0.44,0.02,0.69,0.04,0.69,0.04S154.23,385.95,154.31,384.66z"
          />
          <path
            fill="none"
            d="M161.83,391.7c0-3.6-2.16-6.7-5.24-8.04c-0.17,1.98-0.5,5.21-1.46,5.21c-0.44,0-0.98-0.01-1.6-0.03v1.88
			c0.62,0.16,1.08,0.73,1.08,1.41c0,0.06-0.01,0.13-0.01,0.19c0.12,0.23,0.19,0.49,0.19,0.77c0,0.78-0.54,1.43-1.25,1.6v1.8h6.88
			C161.31,395.12,161.83,393.47,161.83,391.7z"
          />
          <path
            fill="none"
            d="M134.01,400.83c0-0.05-0.01-0.1-0.01-0.14c0-0.03,0-0.05,0-0.08c-0.07,0.07-0.13,0.14-0.18,0.23
			C133.89,400.83,133.95,400.83,134.01,400.83z"
          />
          <path fill="none" d="M146.43,397.2c0.12,0.14,0.24,0.28,0.36,0.42h5.99v-0.42H146.43z" />
          <path
            fill="none"
            d="M139.21,389.78h3c0.11-0.42,0.25-0.95,0.4-1.52c-1.23-0.08-2.35-0.16-3.22-0.22
			c-0.06,0.33-0.12,0.68-0.18,1.05V389.78z"
          />
          <path fill="none" d="M138.45,396.77v-0.15c-0.01,0-0.02,0-0.03,0C138.43,396.67,138.44,396.72,138.45,396.77z" />
          <path
            fill="none"
            d="M134.39,396.25c-0.11,0.01-0.44,0.01-0.7-0.2c-0.07,0.16-0.14,0.32-0.22,0.49c0.28-0.07,0.55-0.15,0.9-0.28
			C134.38,396.26,134.38,396.25,134.39,396.25z"
          />
          <path
            fill="none"
            d="M133.38,399.58l-1.26-0.03c-0.13,0.29-0.24,0.54-0.33,0.73l0.86,0.26
			C132.82,400.17,133.07,399.84,133.38,399.58z"
          />
          <path fill="none" d="M153.54,397.2v0.42h6.01c0.12-0.14,0.25-0.28,0.36-0.42H153.54z" />
          <path
            fill="none"
            d="M137.91,378.42c0.41-0.17,0.7-0.53,1.06-0.83c0.14-0.33,0.25-0.45,0.4-0.5c0.37-0.12,0.72-0.1,1.04-0.16
			c0,0,0.01,0,0.01,0c-0.59-0.36-0.73-0.22-0.73-0.22s-0.08-0.2-0.11-0.49c0,0-0.01,0-0.01,0c-0.95,0.7-1.44,1.36-1.85,1.48
			c-0.41,0.12,0.1-0.79,0.63-1.56c-0.26-0.02-0.46-0.01-0.48,0.1c-0.05,0.2-0.25,0.67-0.45,1.13c0,0.04,0,0.08,0,0.12
			c-0.02,0.35-0.16,0.49-0.27,0.54c-0.02,0.06-0.05,0.12-0.07,0.17c-0.08,0.21-0.26,0.36-0.42,0.4h1.01
			C137.68,378.55,137.75,378.48,137.91,378.42z"
          />
          <path
            fill="none"
            d="M161.14,397.62h0.93c0.68-1.36,1.07-2.91,1.07-4.54c0-0.43-0.03-0.85-0.08-1.26
			C163.03,393.99,162.33,395.99,161.14,397.62z"
          />
          <path
            fill="none"
            d="M138.98,398.37l-0.58,0.57c0,0,0,0,0,0s0,0-0.01,0.01l-0.44,0.43l-0.01,0.29l-0.16,0
			c0.17,0.3,0.27,0.65,0.27,1.02c0,0.04,0,0.08-0.01,0.12c1.94-0.01,4.24-0.03,6.65-0.04c-0.64-0.72-1.19-1.52-1.63-2.39H138.98z"
          />
          <path
            fill="#D7BDB0"
            d="M146.46,363.69c0.03,0.06,0.04,0.11,0.04,0.11l0.02-0.01c0,0,0,0,0,0
			C146.5,363.75,146.48,363.72,146.46,363.69z"
          />
          <path
            fill="#D7BDB0"
            d="M143.07,359.68c-0.03,0.02-0.09,0.04-0.15,0.07l0.11,0.16c0.04-0.1,0.12-0.25,0.13-0.31
			C143.14,359.63,143.11,359.67,143.07,359.68z"
          />
          <path
            fill="#D7BDB0"
            d="M145.66,363.14c0.36-0.29,0.29-0.25,0.47-0.82c0.07-0.23,0.22,0.23,0.38,0.55
			c0.04,0.09,0.12,0.28,0.21,0.53c0.4-0.66,0.87-1.05,1.28-1.19c0.43-0.15,0.81-0.25,1.18-0.3c-0.62-0.59-1.03-1.03-1.26-1.55
			c-0.54,0.16-1.24,0.22-1.36-0.4c-0.21-1.05,0.09-1.78-0.83-1.71c-0.98,0.06-0.49,1.55-0.49,1.55l-0.43,0.08
			c-0.82-0.47-0.63-1.31-0.78-1.72s-0.69-1.51-1.3-0.75c-0.3,0.37-0.71,0.4-0.96,0.37c-0.07,0.38,0.09,0.83,0.11,1.31
			c0.01,0.49,0.04,0.52,0.26,0.71c0.26,0.23,0.25,0.77,0.17,1.2c-0.08,0.44-0.19,0.61-0.05,0.69c0.27,0.16,0.48-0.14,0.62,0.09
			c0.13,0.22,0.11,0.42,0.31,0.5c-0.02-0.01-0.04-0.02-0.05-0.04c-0.08-0.1-0.13-0.18,0.06-0.22c0.17-0.04,0.5-0.15,0.63-0.2
			c-0.01-0.01-0.02-0.02-0.03-0.02c-0.32-0.18-0.47-0.62-0.47-0.62s-0.03,0.09-0.11,0.21c-0.09,0.12-0.43,0.27-0.43,0.27
			s0.16-0.14,0.29-0.26c0.13-0.12,0.14-0.24,0.07-0.37c-0.06-0.12-0.13-0.15-0.25-0.13c-0.02,0-0.03,0-0.03,0c0.01,0,0.02,0,0.03,0
			c0.06-0.01,0.21-0.04,0.19-0.11c-0.04-0.11-0.03-0.3-0.03-0.3s0.28,0.61,0.41,0.83s0.24,0.32,0.47,0.48s0.39,0.63,0.34,0.58
			c-0.04-0.05-0.14-0.33-0.39-0.52c-0.03,0.11-0.19,0.38-0.3,0.51c-0.14,0.18-0.27,0.19-0.27,0.19s0.07-0.16-0.01-0.21
			c0.05,0.08-0.09,0.2,0.09,0.3c0.22,0.13,0.26,0.44,0.42,0.76c0.16,0.31,0.47,0.36,0.76,0.3c0.18-0.04,0.41-0.14,0.62-0.26
			c-0.19,0.08-0.33,0.16-0.33,0.16C145.24,363.35,145.32,363.42,145.66,363.14z M145.73,360.07c-0.07,0.13-0.19,0.05-0.13-0.15
			c0.06-0.2-0.02-0.23-0.1-0.31c-0.07-0.08-0.03-0.12-0.03-0.12c0.14,0.12,0.27,0.04,0.36,0.1
			C145.91,359.63,145.8,359.94,145.73,360.07z M145.95,360.34c0,0,0.39-0.63,0.32-1.08C146.27,359.26,146.42,360.05,145.95,360.34z
			 M145.43,358.76c0.04-0.1,0.12-0.27,0.36-0.27c0.24,0,0.36,0.23,0.45,0.39c0,0-0.28-0.33-0.49-0.24c-0.15,0.07-0.21,0.28-0.03,0.4
			c0.19,0.12,0.26,0.18,0.32,0.29c0.06,0.11,0,0.25,0,0.25s-0.05-0.09-0.11-0.19c-0.06-0.11-0.18,0.09-0.36-0.02
			C145.39,359.27,145.39,358.86,145.43,358.76z M142.26,359.48c-0.3-0.21-0.13-0.42,0.33-0.61c0.46-0.18,0.62,0.11,0.62,0.11
			C142.44,358.86,142.28,359.5,142.26,359.48z M142.86,361.5c-0.11,0.07-0.42,0.11-0.42,0.11c-0.02-0.06,0.12-0.1,0.32-0.14
			C142.96,361.43,142.97,361.43,142.86,361.5z M143.04,359.97c-0.02,0.02-0.03,0.02-0.03,0.01l-0.01,0.02
			c-0.13-0.1-0.17-0.17-0.19-0.2c-0.1,0.04-0.19,0.07-0.19,0.07l0.56-0.36l0.09-0.06C143.28,359.45,143.14,359.86,143.04,359.97z
			 M142.68,359.72c0,0-0.08-0.15,0.14-0.23c0.22-0.09,0.6-0.15,0.6-0.15S142.72,359.5,142.68,359.72z"
          />
          <path
            fill="#AD7D62"
            d="M142.44,361.61c0,0,0.32-0.05,0.42-0.11c0.11-0.07,0.1-0.06-0.1-0.02
			C142.56,361.51,142.42,361.55,142.44,361.61z"
          />
          <path
            fill="#AD7D62"
            d="M143.3,362.56c0,0,0.12-0.02,0.27-0.19c0.11-0.14,0.26-0.4,0.3-0.51c0.25,0.19,0.35,0.48,0.39,0.52
			c0.05,0.05-0.11-0.42-0.34-0.58s-0.34-0.26-0.47-0.48s-0.41-0.83-0.41-0.83s-0.01,0.19,0.03,0.3c0.02,0.07-0.13,0.1-0.19,0.11
			c0.12-0.02,0.18,0.01,0.25,0.13c0.07,0.13,0.07,0.24-0.07,0.37c-0.13,0.12-0.29,0.26-0.29,0.26s0.34-0.15,0.43-0.27
			c0.09-0.12,0.11-0.21,0.11-0.21s0.16,0.43,0.47,0.62c0.01,0.01,0.02,0.02,0.03,0.02c-0.13,0.04-0.46,0.16-0.63,0.2
			c-0.19,0.04-0.15,0.12-0.06,0.22c0.02,0.02,0.03,0.03,0.05,0.04c0.03,0.02,0.06,0.03,0.12,0.06c0,0,0,0,0,0
			C143.37,362.41,143.3,362.56,143.3,362.56z"
          />
          <path fill="#AD7D62" d="M142.88,360.91c-0.01,0-0.02,0-0.03,0C142.85,360.91,142.86,360.91,142.88,360.91z" />
          <path
            fill="#AD7D62"
            d="M142.68,359.72c0.03-0.21,0.74-0.38,0.74-0.38s-0.38,0.06-0.6,0.15
			C142.6,359.57,142.68,359.72,142.68,359.72z"
          />
          <path
            fill="#AD7D62"
            d="M142.63,359.87c0,0,0.09-0.03,0.19-0.07c0.01,0.03,0.05,0.11,0.19,0.2l0.01-0.02
			c0,0.01,0.01,0.01,0.03-0.01c0.1-0.11,0.23-0.51,0.23-0.51l-0.09,0.06L142.63,359.87z M143.04,359.91l-0.11-0.16
			c0.06-0.03,0.11-0.05,0.15-0.07c0.03-0.02,0.07-0.05,0.1-0.08C143.16,359.66,143.08,359.82,143.04,359.91z"
          />
          <path
            fill="#AD7D62"
            d="M143.22,358.99c0,0-0.17-0.29-0.62-0.11c-0.46,0.18-0.62,0.39-0.33,0.61
			C142.28,359.5,142.44,358.86,143.22,358.99z"
          />
          <path
            opacity="0.3"
            d="M166.26,404.24l-2.5-3.26c-0.16-0.21-0.42-0.33-0.71-0.33c-0.34,0-0.78,0-1.32,0.01
			c-2.09,2.4-5.15,3.92-8.56,3.92c-3.37,0-6.39-1.48-8.47-3.82c-2.41,0.02-4.71,0.03-6.65,0.04c0-0.04,0.01-0.08,0.01-0.12
			c0-0.37-0.1-0.72-0.27-1.02l-0.12,0l-0.37,0.36c0.35,0.46,0.56,1.04,0.56,1.67c0,1.52-1.22,2.76-2.73,2.76
			c-1.51,0-2.73-1.23-2.73-2.76c0-0.15,0.01-0.29,0.04-0.43l-0.29-0.09l-2.9,3c-0.46,0.47-0.07,1.19,0.65,1.19h35.66
			C166.22,405.37,166.63,404.73,166.26,404.24z"
          />
          <path
            opacity="0.3"
            d="M134.01,400.83c-0.06,0-0.13,0-0.19,0c-0.01,0.02-0.03,0.04-0.04,0.06l1.16,0.36l0.44-0.43
			c-0.17,0-0.34,0-0.5,0c-0.01-0.05-0.01-0.09-0.01-0.14c0-0.19,0.05-0.38,0.13-0.54c-0.39,0.03-0.74,0.19-1,0.46
			c0,0.03,0,0.05,0,0.08C134.01,400.74,134.01,400.78,134.01,400.83z"
          />
          <path opacity="0.3" d="M139.21,390.42h2.84c0.04-0.17,0.1-0.39,0.17-0.64h-3V390.42z" />
          <path
            opacity="0.3"
            d="M160.53,398.37h-1.77c-1.51,1.29-3.46,2.08-5.6,2.08c-2.14,0-4.09-0.78-5.6-2.08h-1.76
			c0.87,0.98,1.93,1.79,3.12,2.36c-0.74,0-1.48,0.01-2.22,0.01c1.74,1.5,3.99,2.4,6.46,2.4c2.51,0,4.8-0.94,6.55-2.48
			c-0.68,0-1.42,0.01-2.21,0.01C158.66,400.11,159.68,399.33,160.53,398.37z"
          />
          <path
            opacity="0.3"
            d="M135.15,402.11l-1.59-0.49c0,0.03,0,0.05,0,0.08c0,0.86,0.69,1.56,1.54,1.56c0.11,0,0.22-0.01,0.32-0.04
			c0.7-0.14,1.23-0.77,1.23-1.52c0-0.31-0.09-0.59-0.24-0.83L135.15,402.11z"
          />
          <path
            opacity="0.3"
            d="M142.29,396.49h-2.55v-5.31h-0.54v0.5h0v1.03c0.1,0.09,0.2,0.18,0.27,0.29c0.02,0.04,0.04,0.08,0.06,0.12
			c0.07,0.12,0.08,0.27,0.06,0.48c0,0.02,0,0.04,0,0.06c0,0.43,0,0.86-0.01,1.29c0,0.49-0.04,1.04-0.38,1.37v0.88h3.34
			C142.45,396.97,142.37,396.73,142.29,396.49z"
          />
          <path
            opacity="0.3"
            d="M145.14,388.42c-0.26-0.02-0.53-0.03-0.79-0.05c-0.34,0.65-0.61,1.33-0.8,2.05h9.23v-0.64h-8.06
			C144.83,389.31,144.97,388.86,145.14,388.42z"
          />
          <path
            opacity="0.3"
            d="M152.78,397.2v-0.71h-6.86c-0.89-1.38-1.41-3.02-1.41-4.79c0-0.18,0.01-0.35,0.02-0.52h-1.15
			c-0.04,0.21-0.07,0.42-0.1,0.63c0.02,1.7,0.45,3.29,1.21,4.68h-0.7c0.09,0.24,0.18,0.47,0.28,0.71h0.84
			c0.09,0.14,0.19,0.28,0.29,0.42h1.59c-0.12-0.14-0.25-0.28-0.36-0.42H152.78z"
          />
          <path
            opacity="0.3"
            d="M156.6,383.62c0,0.02,0,0.03,0,0.05c3.08,1.34,5.24,4.43,5.24,8.04c0,1.77-0.52,3.41-1.41,4.79h-6.88v0.71
			h6.37c-0.12,0.14-0.24,0.28-0.36,0.42h1.59c1.18-1.63,1.89-3.63,1.92-5.8C162.59,388.03,160.04,384.89,156.6,383.62z"
          />
          <path
            d="M153.54,390.72v0.78c0.46,0.11,0.85,0.41,1.06,0.82c0.01-0.06,0.01-0.13,0.01-0.19
			C154.61,391.46,154.16,390.89,153.54,390.72z"
          />
          <path
            d="M151.74,392.32c0.21-0.4,0.59-0.71,1.04-0.82v-0.33h-0.7c-0.22,0.26-0.35,0.59-0.35,0.95
			C151.72,392.2,151.73,392.26,151.74,392.32z"
          />
          <path
            fill="#4D2C1A"
            d="M138.4,398.94L138.4,398.94C138.39,398.95,138.39,398.95,138.4,398.94
			C138.39,398.95,138.4,398.94,138.4,398.94z"
          />
          <path
            fill="#4D2C1A"
            d="M138.41,396.63l0.04,0.15v0C138.44,396.72,138.43,396.67,138.41,396.63
			C138.42,396.62,138.41,396.62,138.41,396.63z"
          />
          <path
            fill="#4D2C1A"
            d="M136.31,396.59c-0.39-0.04-1.18-0.21-1.18-0.21c0.02-0.02,0.04-0.04,0.05-0.07l-0.75-0.06
			c0,0-0.01,0-0.04,0c-0.01,0-0.01,0.01-0.02,0.01c-0.35,0.12-0.62,0.21-0.9,0.28c-0.36,0.1-0.59,0.16-0.89,0.15
			c-0.18,0.02-0.38,0.04-0.61,0.06c-0.81,0.06-1.9,0.1-2.15,0.51c-0.32,0.52-0.09,1.5-0.09,1.5s0.28,0.01,0.72,0.05l6.91,0.11
			l1.09-1.07v-0.65C138.03,397.01,137.16,396.75,136.31,396.59z"
          />
          <path
            fill="#1EBEE4"
            d="M134.39,396.25c0.02,0,0.04,0,0.04,0l0.75,0.06c0.38-0.49,0.76-1.02,0.76-1.63c0-0.34-0.1-0.68-0.08-1.01
			c0.02-0.34,0.25-0.71,0.59-0.71c-0.47-0.06-0.93-0.09-1.4-0.09c-0.07,0.17-0.15,0.35-0.23,0.55c-0.23,0.57-0.66,1.56-1.13,2.64
			C133.95,396.26,134.28,396.26,134.39,396.25z"
          />
          <path fill="#1EBEE4" d="M139.59,393.6c0.01-0.21,0-0.36-0.06-0.48C139.58,393.27,139.59,393.44,139.59,393.6z" />
          <path
            fill="#1EBEE4"
            d="M139.39,388.04c-0.06,0-0.12-0.01-0.18-0.01v1.06C139.27,388.72,139.33,388.37,139.39,388.04z"
          />
          <path
            fill="#1EBEE4"
            d="M134.12,393.13c0.35-0.88,0.69-1.56,1.09-2.01c0.15-0.86,0.31-1.72,0.46-2.57c0.04-0.2,0.07-0.42,0-0.62
			c-0.07-0.19-0.84-1.71-0.95-2.64c-0.11-0.92,0.81-1.23,0.64-1.88c-0.09-0.35-0.43-0.57-0.66-0.85c-0.63-0.77-0.34-2.02,0.42-2.67
			c0.1-0.08,0.2-0.16,0.3-0.23c-0.84,0.14-1.3,0.23-1.31,0.16c-1.01,0.91-1.51,2.57-0.93,5.56c0.39,1.97-0.11,0.6,0.75,5.89
			c0.09,0.53,0.12,0.99,0.13,1.37c-0.43-0.01-0.71-0.01-0.71-0.01s-0.05,1.25-0.02,2.34C133.66,394.22,133.95,393.55,134.12,393.13z
			"
          />
          <path
            fill="#D7BDB0"
            d="M138.71,378.48c0.01,0.14,0.09,0.13,0.2,0.06c0.07-0.04,0.14-0.14,0.21-0.26c0.46-0.22,0.94-0.48,1-0.51
			c0.37-0.16,0.61-0.16,0.21,0.12c-0.32,0.23-0.64,0.71-0.09,0.46c0.54-0.25,1.11-0.25,1.33-0.8c0,0,0,0,0,0
			c-0.32-0.12-0.6-0.26-0.85-0.44c-0.12-0.08-0.22-0.15-0.31-0.21c0,0-0.01,0-0.01,0c-0.32,0.05-0.67,0.04-1.04,0.16
			c-0.16,0.05-0.27,0.17-0.4,0.5c-0.36,0.3-0.64,0.66-1.06,0.83c-0.16,0.07-0.23,0.13-0.24,0.18h0.75c0.02-0.01,0.04-0.01,0.07-0.02
			C138.56,378.55,138.63,378.52,138.71,378.48z"
          />
          <path
            fill="#AD7D62"
            d="M146.38,378.98h0.86c-0.29-0.04-0.57-0.09-0.85-0.14C146.38,378.89,146.38,378.93,146.38,378.98z"
          />
          <path
            fill="#4D2C1A"
            d="M151.95,365.3c0.14,1.15,0.67,4.6,0.45,6.54c-0.36,3.26-1.05,5.35-1.69,6.56c-0.23,0.09-0.5,0.13-0.8,0.18
			c-1,0.17-4.59-0.33-6.53-0.81c-1.95-0.47-2.45-0.78-2.64-1.08c-0.19-0.31-0.22-1.45-0.22-1.11c0,0.33-0.06,0.89-0.44,0.78
			c-0.37-0.11-0.48-0.27-0.49-0.29c0,0,0,0,0,0c0,0.05,0,0.11,0.01,0.16c0.02,0.28,0.11,0.49,0.11,0.49s0.14-0.14,0.73,0.22
			c0.09,0.06,0.2,0.12,0.31,0.21c0.24,0.17,0.53,0.31,0.85,0.44c0.09,0.04,0.19,0.07,0.28,0.1c0.1,0.03,0.2,0.07,0.31,0.1
			c0.64,0.2,1.37,0.37,2.13,0.59c0.08,0.02,0.16,0.05,0.24,0.07c0.42,0.13,1.09,0.27,1.84,0.4c0.28,0.05,0.56,0.09,0.85,0.14h5.02
			C152.73,376.71,153.78,370.33,151.95,365.3z"
          />
          <path
            fill="#111413"
            d="M132.39,401.7c0,1.52,1.22,2.76,2.73,2.76c1.51,0,2.73-1.23,2.73-2.76c0-0.63-0.21-1.21-0.56-1.67
			l0.37-0.36l0.12,0l0.16,0l0.01-0.29l0.44-0.43l0.01-0.01l0.58-0.57h4.08c0.45,0.87,0.99,1.67,1.63,2.39
			c2.08,2.35,5.11,3.82,8.47,3.82c3.41,0,6.47-1.52,8.56-3.92c1.76-2.02,2.83-4.68,2.83-7.58c0-5.09-3.28-9.41-7.82-10.92
			c1-7.02,1.29-9.32,1.29-9.32s0,0-0.51-0.26c0.13-0.36,0.32-0.89,0.5-1.32c0.26-0.64,0.46-0.74,1.07-0.55
			c0.13,0.04,0.27,0.08,0.42,0.13c0,0.15,0.1,0.28,0.24,0.32l3.67,1.09c0.18,0.05,0.37-0.05,0.42-0.23l0.12-0.42
			c0.05-0.18-0.05-0.37-0.23-0.42l-3.67-1.09c-0.12-0.04-0.25,0-0.34,0.09c-0.33-0.1-0.64-0.2-0.89-0.28
			c-0.5-0.17-1.01-0.06-1.24,0.47c-0.27,0.6-0.59,1.4-0.79,1.88c-0.04-0.02-0.08-0.04-0.12-0.06c0,0-0.72,1.82-1.33,4.27
			c-0.34,1.35-0.64,2.89-0.77,4.42c-0.02,0.28-0.04,0.54-0.06,0.79c-0.41-0.05-0.84-0.08-1.26-0.08c-0.15-0.41-0.36-0.75-0.62-1.03
			c-0.22-0.23-0.49-0.41-0.78-0.53h1.44c0.42,0,0.76-0.23,0.76-0.52c0-0.29-0.34-0.52-0.76-0.52h-1.04h-5.02h-0.86h-2.34
			c-0.12,0-0.24,0.02-0.34,0.06c-0.17,0.06-0.3,0.17-0.37,0.29c-0.03,0.05-0.04,0.11-0.04,0.17c0,0.29,0.34,0.52,0.76,0.52h1.71
			c-0.94,0.37-1.3,1.27-1.49,1.98c-0.08,0.29-0.26,0.99-0.49,1.87h-2.81c-1.17,0-2.01,0.63-2.34,1.67
			c-1.59,0.41-1.22,2.36-1.22,2.36s0.39,0.03,1.06,0.07v2.45h0h-1.75c-0.63,0-1.1,0.24-1.5,0.69c-0.4,0.46-0.73,1.13-1.09,2.01
			c-0.17,0.42-0.45,1.09-0.78,1.84c-0.24,0.54-0.5,1.13-0.76,1.71c-0.33,0.75-0.66,1.48-0.94,2.1l-1.19-0.03l0,0.04l-0.02,0.72
			l0.87,0.02c-0.22,0.5-0.37,0.81-0.37,0.83l-0.18,0.39l1.4,0.43l0.29,0.09C132.41,401.41,132.39,401.56,132.39,401.7z
			 M135.01,400.15c0.03,0,0.07-0.01,0.1-0.01c0.11,0,0.21,0.01,0.32,0.03c0.16,0.03,0.31,0.09,0.45,0.17l-0.48,0.47l-0.44,0.43
			l-1.16-0.36c0.01-0.02,0.02-0.04,0.04-0.06c0.05-0.08,0.12-0.16,0.18-0.23C134.27,400.35,134.62,400.18,135.01,400.15z
			 M135.43,403.23c-0.1,0.02-0.21,0.04-0.32,0.04c-0.85,0-1.54-0.7-1.54-1.56c0-0.03,0-0.05,0-0.08l1.59,0.49l1.27-1.24
			c0.15,0.24,0.24,0.52,0.24,0.83C136.66,402.46,136.13,403.08,135.43,403.23z M146.79,397.62h-1.59h-0.93
			c-0.07-0.14-0.14-0.28-0.2-0.42c-0.1-0.23-0.2-0.47-0.28-0.71c-0.38-1.07-0.59-2.21-0.59-3.41c0-0.43,0.03-0.85,0.08-1.27
			c0.03-0.21,0.06-0.42,0.1-0.63h1.15h7.55h0.7v0.33c-0.45,0.11-0.83,0.41-1.04,0.82c-0.12,0.23-0.19,0.49-0.19,0.77
			c0,0.77,0.53,1.41,1.23,1.59v1.81v0.71v0.42H146.79z M152.77,383.02c0.01,0.11,0.01,0.23,0.01,0.35v2.95
			c-1.53-0.08-4.08-0.22-6.64-0.38C147.85,384.22,150.19,383.12,152.77,383.02z M152.78,389.78v0.64h-9.23
			c0.19-0.72,0.47-1.41,0.8-2.05c0.26,0.02,0.52,0.03,0.79,0.05c2.65,0.16,5.48,0.32,7.64,0.4V389.78z M153.54,386.31v-0.24v-2.71
			c0-0.12,0-0.24-0.01-0.35c0.3,0.01,0.59,0.03,0.89,0.07c-0.03,0.35-0.05,0.67-0.07,0.95c-0.02,0.23-0.03,0.43-0.04,0.62
			c-0.08,1.29-0.08,1.74-0.08,1.74s-0.25-0.01-0.69-0.04V386.31z M139.39,388.04c0.88,0.06,1.99,0.14,3.22,0.22
			c-0.15,0.58-0.29,1.1-0.4,1.52c-0.07,0.25-0.12,0.47-0.17,0.64h-2.84v-0.64v-0.69v-1.06
			C139.27,388.03,139.33,388.04,139.39,388.04z M139.2,397.62v-0.42v-0.88v-3.61v-1.03h0v-0.5h0.54h2.2
			c-0.1,0.62-0.16,1.25-0.16,1.9c0,1.19,0.18,2.33,0.51,3.41c0.07,0.24,0.16,0.47,0.24,0.71c0.05,0.14,0.11,0.28,0.17,0.42H139.2z
			 M153.17,403.15c-2.46,0-4.72-0.91-6.46-2.4c-0.79-0.68-1.47-1.48-2.02-2.38h1.12h1.76h11.19h1.77h1.12
			c-0.53,0.86-1.18,1.63-1.93,2.3C157.97,402.21,155.68,403.15,153.17,403.15z M162.07,397.62h-0.93h-1.59h-6.01v-0.42v-0.71v-1.8
			c0.72-0.17,1.25-0.82,1.25-1.6c0-0.28-0.07-0.54-0.19-0.77c-0.22-0.41-0.6-0.72-1.06-0.82v-0.78v-1.88
			c0.62,0.02,1.16,0.03,1.6,0.03c0.95,0,1.29-3.23,1.46-5.21c0-0.02,0-0.03,0-0.05c3.44,1.27,5.99,4.41,6.46,8.2
			c0.05,0.41,0.08,0.83,0.08,1.26C163.14,394.71,162.75,396.25,162.07,397.62z M144.98,382.2c0.31-1.14,0.82-1.6,1.76-1.6
			c0.59,0,1.21-0.01,1.86-0.01c0.7-0.01,1.43-0.01,2.17-0.01c0.61,0,1.29,0.21,1.69,1.02c-3.27,0.21-6.17,1.81-8.12,4.23
			c-0.1-0.01-0.2-0.01-0.3-0.02C144.46,384.17,144.85,382.67,144.98,382.2z M140.95,384.63h2.61c-0.1,0.36-0.19,0.74-0.3,1.13
			c-1.43-0.1-2.73-0.19-3.69-0.28c-0.05,0-0.09-0.01-0.14-0.01C139.82,384.74,140.57,384.63,140.95,384.63z M133.47,396.53
			c0.07-0.16,0.14-0.33,0.22-0.49c0.47-1.07,0.9-2.07,1.13-2.64c0.08-0.2,0.16-0.38,0.23-0.55c0.56-1.28,0.98-1.69,1.65-1.69h1.75
			v5.44l-0.04,0l-0.01-0.05l-3.27-0.2l-0.52-0.03c0,0,0.19,0.03,0.48,0.08c0.3,0.05,0.71,0.12,1.15,0.21
			c0.85,0.16,1.79,0.37,2.21,0.55v0.65l-1.09,1.07l-4.9-0.1C132.76,398.12,133.12,397.32,133.47,396.53z M132.12,399.55l1.26,0.03
			c-0.31,0.26-0.56,0.59-0.74,0.97l-0.86-0.26C131.88,400.09,131.99,399.84,132.12,399.55z"
          />
          <path
            fill="#111413"
            d="M142.37,379.01c0.05,0,0.08-0.04,0.08-0.08l0-0.25c0-0.04-0.04-0.07-0.08-0.07h-3.94h-0.75h-1.01
			c-0.05,0.01-0.09,0.01-0.13,0h-1.74c-0.03,0-0.06-0.02-0.07-0.05l-2.29-7.31c-0.01-0.04-0.06-0.06-0.1-0.05
			c-0.19,0.07-0.3,0.28-0.24,0.48l2.29,7.29c0.01,0.03,0.04,0.05,0.07,0.05l0.92,0L142.37,379.01z"
          />
          <path
            fill="#D7BDB0"
            d="M136.66,378.6c0.16-0.04,0.34-0.19,0.42-0.4c0.02-0.05,0.04-0.11,0.07-0.17c0.12-0.05,0.26-0.18,0.27-0.54
			c0-0.04,0-0.08,0-0.12c0.2-0.46,0.4-0.93,0.45-1.13c0.03-0.11,0.22-0.12,0.48-0.1c-0.53,0.77-1.04,1.68-0.63,1.56
			c0.41-0.12,0.9-0.78,1.85-1.48c0,0,0.01,0,0.01,0c0-0.05-0.01-0.1-0.01-0.16c0,0,0,0,0,0c-0.04-0.52,0.01-1.25,0.16-1.63
			c0,0,0-0.01,0.01-0.01c-0.66,0.14-1.35-0.28-2.16,0.33c-0.04,0.03-0.07,0.06-0.1,0.1c-0.81,0.73-0.93,0.66-1.29,1.07
			c-0.37,0.41,0.41,0.74,0.41,1.27c0,0.14,0.03,0.21,0.08,0.22c-0.03,0.11-0.05,0.22-0.08,0.34c-0.07,0.28-0.21,0.43-0.21,0.56
			c-0.01,0.17,0.06,0.25,0.14,0.28C136.57,378.61,136.61,378.61,136.66,378.6z"
          />
          <path
            fill="#AD7D62"
            d="M142.17,377.78c0.67,0.21,1.4,0.42,2.13,0.59C143.54,378.14,142.81,377.97,142.17,377.78z"
          />
          <path
            fill="#AD7D62"
            d="M141.73,377.63c0.04,0.01,0.09,0.03,0.13,0.04c-0.1-0.03-0.19-0.07-0.28-0.1c0,0,0,0,0,0
			C141.63,377.59,141.69,377.61,141.73,377.63z"
          />
          <path
            fill="#AD7D62"
            d="M139.58,376.07c0.01,0.01,0.11,0.18,0.49,0.29c0.39,0.11,0.44-0.44,0.44-0.78c0-0.33,0.03,0.81,0.22,1.11
			c0.19,0.31,0.69,0.61,2.64,1.08c1.95,0.47,5.53,0.97,6.53,0.81c0.3-0.05,0.57-0.1,0.8-0.18c0.64-1.2,1.33-3.3,1.69-6.56
			c0.22-1.94-0.32-5.39-0.45-6.54c1.83,5.02,0.78,11.41,0.31,13.67h1.04c0.42,0,0.76,0.23,0.76,0.52c0,0.29-0.34,0.52-0.76,0.52
			h-1.44c0.3,0.12,0.56,0.3,0.78,0.53c1.14,0.18,1.95,0.32,1.95,0.32c0.13-1.53,0.43-3.07,0.77-4.42c0.04-1.27-0.01-2.83-0.25-4.7
			c-0.18-1.41,0.19-4.26-1.25-6.82c-0.48-0.85-1.05-1.61-2.05-2.35c-0.67-0.5-1.35-0.73-2.16-0.71c-0.37-0.27-0.97-0.84-1.08-1.75
			l-0.39,0.19c0,0,0-0.01,0-0.03c-0.08,0.03-0.17,0.06-0.26,0.08c0.23,0.52,0.64,0.96,1.26,1.55c-0.36,0.05-0.75,0.15-1.18,0.3
			c-0.41,0.14-0.89,0.54-1.28,1.19c-0.09-0.25-0.17-0.45-0.21-0.53c-0.16-0.32-0.31-0.78-0.38-0.55c-0.17,0.56-0.1,0.52-0.47,0.82
			c-0.34,0.28-0.42,0.21-0.8,0.47c0,0,0.14-0.07,0.33-0.16c0.21-0.09,0.48-0.2,0.7-0.24c0.14-0.03,0.35,0.18,0.57,0.47
			c0.02,0.03,0.04,0.06,0.06,0.09c0,0,0,0,0,0c-0.65,1.35-0.99,3.56-0.2,6.77c0.2,0.81,0.33,1.56,0.41,2.25
			c0.04,0.94-0.33,1.18-0.48,1.24c-0.02,0-0.03,0-0.05,0c-1.51-0.14-6.17-0.22-6.45,0.39c0,0,0,0.01-0.01,0.01
			C139.59,374.82,139.54,375.55,139.58,376.07z"
          />
          <path
            fill="#111413"
            d="M148.44,357.47c0.26,1.15,0.24,1.92,0.2,2.32c0.07-0.58,0.14-1.89-0.4-3.19c-0.7-1.69-1.61-2.41-2.91-2.07
			c0.04-0.01,0.08-0.02,0.12-0.02C147.08,354.2,148.05,355.72,148.44,357.47z"
          />
          <path
            fill="#111413"
            d="M148.17,360.28c0,0.02,0,0.03,0,0.03l0.39-0.19l0.03-0.01C148.55,360.13,148.39,360.2,148.17,360.28z"
          />
          <path
            fill="#4D2C1A"
            d="M142.72,357.41c0.61-0.76,1.15,0.34,1.3,0.75s-0.04,1.25,0.78,1.72l0.43-0.08c0,0-0.48-1.49,0.49-1.55
			c0.92-0.06,0.62,0.66,0.83,1.71c0.13,0.62,0.82,0.56,1.36,0.4c0.09-0.03,0.18-0.06,0.26-0.08c0.22-0.08,0.38-0.15,0.41-0.17
			c0.01,0,0.01,0,0.01,0s0.03-0.11,0.05-0.32c0.04-0.4,0.05-1.17-0.2-2.32c-0.39-1.75-1.36-3.27-2.98-2.96
			c-0.04,0.01-0.08,0.02-0.12,0.02c-1.56,0.3-2.79,0.6-3.46,1.19c-0.65,0.57-0.78,1.51-0.33,2.02c0,0,0.09,0.03,0.22,0.04
			C142.01,357.81,142.43,357.78,142.72,357.41z"
          />
          <path
            fill="#AD7D62"
            d="M145.57,359.37c0.18,0.1,0.3-0.09,0.36,0.02c0.06,0.11,0.11,0.19,0.11,0.19s0.07-0.14,0-0.25
			c-0.06-0.11-0.13-0.17-0.32-0.29c-0.19-0.12-0.12-0.33,0.03-0.4c0.21-0.09,0.49,0.24,0.49,0.24c-0.09-0.16-0.21-0.39-0.45-0.39
			c-0.24,0-0.32,0.17-0.36,0.27C145.39,358.86,145.39,359.27,145.57,359.37z"
          />
          <path
            fill="#AD7D62"
            d="M145.47,359.48c0,0-0.05,0.04,0.03,0.12c0.07,0.08,0.15,0.11,0.1,0.31c-0.06,0.2,0.06,0.28,0.13,0.15
			c0.07-0.13,0.19-0.44,0.1-0.49C145.74,359.53,145.61,359.61,145.47,359.48z"
          />
          <path
            fill="#AD7D62"
            d="M145.95,360.34c0.46-0.29,0.32-1.08,0.32-1.08C146.34,359.71,145.95,360.34,145.95,360.34z"
          />
        </g>
        <g id="woman-small_2_">
          <path
            fill="none"
            d="M187.5,389.21l-0.22-1.33l-0.18,0.19c-0.3,0.38-0.41,0.46-0.59,0.61l-0.59,0.62c0,0,0,0,0,0
			c-1.62,1.75-2.93,2.91-2.39,4.21c1.19,2.85,3.1,5.39,3.48,5.9c0.27-0.02,0.56-0.1,0.82-0.2l-0.05,0.39c0,0,0.51-3.19,0.57-4.7
			c0.04-0.99-0.13-2.2-0.29-3.12c-0.17-0.81-0.38-1.65-0.58-2.74C187.49,389.1,187.49,389.16,187.5,389.21z"
          />
          <path
            fill="none"
            d="M187.82,399.59c0.02-0.08,0.03-0.16,0.05-0.25c-0.06,0.17-0.07,0.26-0.07,0.26S187.8,399.59,187.82,399.59z"
          />
          <path
            fill="none"
            d="M190.16,373.44c0.01-0.05,0.01-0.1-0.01-0.14C190.16,373.34,190.16,373.39,190.16,373.44z"
          />
          <path
            fill="none"
            d="M201.2,449.83c0.07-0.44,0.2-0.75,0.27-0.9c-0.04-0.22-0.08-0.44-0.11-0.66c-0.05-0.01-0.1-0.02-0.14-0.03
			c-0.11-0.02-0.18-0.05-0.19-0.07c-0.01-0.04-0.02-0.09-0.03-0.13l-0.46-1.92c0,0,0,0,0,0c-2.63-11.31-2.4-12.67-3.82-20.09
			c-1.43-7.49-2.19-13.05-2.6-16.78c-0.32,4.39-2.46,16.55-2.59,23.61c-0.1,5.49-0.59,10.74-1.23,13.26c0,0,0,0,0,0
			c-0.07,0.53-0.12,1.71-0.12,1.71c-0.15,0.06-0.33,0.09-0.52,0.11c-0.01,0.3-0.02,0.61-0.04,0.91c0.02-0.02,0.03-0.03,0.03-0.03
			s0.22,0.37,0.32,1.01c0,0,0.1,0.45-0.12,0.76c-0.03,0.04-0.07,0.08-0.11,0.11c1.71,0.41,4.48,1.55,6.67,1.58
			c3.33,0.04,3.59-1.4,4.89-1.72C201.11,450.26,201.2,449.83,201.2,449.83z"
          />
          <path fill="none" d="M192.72,373.47c0,0-0.07,0.05-0.16,0.1C192.65,373.52,192.72,373.47,192.72,373.47z" />
          <path
            fill="none"
            d="M199.61,389.64c-0.01,0.09-0.01,0.19-0.01,0.29c0.01-0.04,0.01-0.08,0.01-0.11
			C199.61,389.76,199.61,389.7,199.61,389.64z"
          />
          <path
            opacity="0.3"
            d="M242.17,454.83c-7.13-0.83-26.22-3.33-35.81-4.1c0.9,0.45,2.15,0.74,2.1,1.31
			c0.07,0.69-0.17,1.29-1.81,1.31c-1.71,0.02-3.29-1.29-3.89-1.82s-1.23-0.64-1.45-0.94c-0.01-0.01-0.01-0.02-0.01-0.02
			c-1.3,0.32-1.56,1.76-4.89,1.72c-2.19-0.03-4.96-1.17-6.67-1.58c-0.28,0.23-0.82,0.37-1.34,0.83c-0.6,0.53-2.29,1.81-4,1.79
			c-0.25,0-0.45-0.02-0.64-0.05c4.5,1.92,26.36,4.81,42.9,7.87C248.73,465.22,267.64,457.78,242.17,454.83z"
          />
          <path
            fill="#111413"
            d="M185.92,389.3C185.92,389.3,185.92,389.3,185.92,389.3l0.59-0.62c-0.02,0.02-0.05,0.04-0.07,0.07
			c0,0-0.59-0.1-0.92-0.36c0.01,0,0.01,0,0.02-0.01c-1.15-0.61-1.94-1.59-2.3-2.1C183.32,386.65,183.83,388.37,185.92,389.3z"
          />
          <path
            fill="#111413"
            d="M202.03,388.94c0.08,0.08,0.04,0.02,0.04,0.02c1.66-1.38,2.28-2.75,2.45-3.21
			c-0.49,0.69-1.58,2.1-2.82,2.85C201.89,388.79,201.98,388.89,202.03,388.94z"
          />
          <path
            fill="#664033"
            d="M188.57,449.34c0.59-0.03,0.95-0.38,1.05-0.49c0.03-0.3,0.04-0.61,0.04-0.91
			c-0.87,0.07-2.13-0.18-2.7-0.31c0,0.43,0.01,0.85,0.01,1.25c0.03-0.03,0.03-0.03,0.03-0.03S187.87,449.38,188.57,449.34z"
          />
          <path
            fill="#664033"
            d="M201.36,448.27c0.03,0.22,0.07,0.44,0.11,0.66c0.03-0.07,0.05-0.11,0.05-0.11s0.38,0.49,1.08,0.52
			c0.61,0.03,1.35-0.37,1.52-0.46c-0.02-0.26-0.05-0.54-0.08-0.82c-0.24,0.11-0.57,0.19-1.03,0.22
			C202.14,448.34,201.64,448.31,201.36,448.27z"
          />
          <path fill="#AD7D62" d="M204.72,446.71l-0.06,0.62C204.66,447.33,204.7,447.16,204.72,446.71z" />
          <path
            fill="#AD7D62"
            d="M201.23,448.24c-0.15-0.04-0.19-0.07-0.19-0.07l-0.03-0.13c0.01,0.04,0.02,0.08,0.03,0.13
			C201.05,448.19,201.12,448.22,201.23,448.24z"
          />
          <path
            fill="#42637C"
            d="M187.52,402.54c0.18,0.07,0.49,0.23,0.77,0.3c0.21,0.05,0.49,0.12,0.63,0.16
			c-0.19-0.07-0.43-0.27-0.79-0.39c-0.41-0.15-0.36-0.24-0.78-0.34c-0.11-0.02-0.2-0.05-0.3-0.08l-0.07,0.26l-0.01,0.05
			C186.97,402.5,187.34,402.47,187.52,402.54z"
          />
          <path
            fill="#42637C"
            d="M190.24,402.19c-0.19-0.05-0.51-0.24-0.51-0.24s0.18,0.14,0.3,0.28c0.16,0.01,0.26-0.01,0.33-0.05
			C190.33,402.2,190.28,402.2,190.24,402.19z"
          />
          <path
            fill="#42637C"
            d="M189.81,401.22c0,0,0.07,0.05,0.16,0.13c0.26-0.05,0.32-0.24,0.31-0.34l0,0
			C190.25,401.17,190.08,401.32,189.81,401.22z"
          />
          <path
            fill="#42637C"
            d="M188.3,402.02c-0.13-0.05,0.23,0.15,0.5,0.32c0.19,0.08,0.36,0.16,0.46,0.21
			c0.19,0.09,0.42,0.09,0.56,0.08c-0.04-0.01-0.07-0.01-0.11-0.03C189.47,402.51,188.47,402.09,188.3,402.02z"
          />
          <path
            fill="#D7BFB1"
            d="M189.66,447.94c0.2-0.02,0.38-0.05,0.52-0.11c0,0,0.05-1.18,0.12-1.71c0,0,0,0,0,0
			c-0.03,0-2.31,0.21-3.6-0.2c-0.02-0.01-0.04-0.01-0.06-0.02l0.03,1.65c0,0,0.11,0.03,0.29,0.07
			C187.53,447.76,188.79,448.02,189.66,447.94z"
          />
          <path
            fill="#D7BFB1"
            d="M200.55,446.12C200.55,446.12,200.55,446.12,200.55,446.12l0.45,1.92l0.03,0.13c0,0,0.04,0.04,0.19,0.07
			c0.04,0.01,0.08,0.02,0.14,0.03c0.28,0.05,0.78,0.08,1.65,0.02c0.46-0.03,0.79-0.11,1.03-0.22c0.65-0.29,0.62-0.74,0.62-0.74
			l0.06-0.62l0.06-0.65c0,0-0.01,0-0.04,0c-0.16,0-0.85,0.01-2.03,0.24C201.38,446.56,200.6,446.14,200.55,446.12z"
          />
          <path
            fill="#1EBEE4"
            d="M200.47,387.6c0.05,0.04,0.09,0.09,0.14,0.15C200.57,387.68,200.52,387.62,200.47,387.6z"
          />
          <path
            fill="#1EBEE4"
            d="M201.38,388.78c0.01-0.01,0.02-0.01,0.04-0.02c-0.16-0.22-0.28-0.36-0.39-0.49
			C201.23,388.55,201.38,388.78,201.38,388.78z"
          />
          <path
            fill="#1EBEE4"
            d="M187.1,388.07l-0.66,0.68c0.03-0.03,0.05-0.05,0.07-0.07C186.69,388.52,186.79,388.45,187.1,388.07z"
          />
          <path
            fill="#1EBEE4"
            d="M200.26,387.75c-0.01,0.01-0.01,0.02-0.02,0.03c0.04-0.04,0.07-0.08,0.11-0.12
			C200.31,387.68,200.27,387.71,200.26,387.75z"
          />
          <path
            fill="#1EBEE4"
            d="M199.66,393.09c-0.01,0.2-0.04,0.42-0.06,0.64C199.62,393.55,199.64,393.34,199.66,393.09z"
          />
          <path
            fill="#1EBEE4"
            d="M199.54,395.96c-0.05-0.39-0.09-0.77-0.09-1.1C199.43,395.27,199.48,395.63,199.54,395.96z"
          />
          <path
            fill="#664033"
            d="M188.13,402.6c0.36,0.13,0.6,0.32,0.79,0.39c0.03,0.01,0.06,0.02,0.08,0.02c0.19,0.02,0.22-0.34,0.02-0.51
			c-0.05-0.04-0.13-0.1-0.22-0.16c-0.27-0.18-0.63-0.38-0.5-0.32c0.17,0.07,1.16,0.49,1.41,0.58c0.04,0.02,0.08,0.02,0.11,0.03
			c0.19,0.03,0.32-0.06,0.3-0.22c-0.01-0.06-0.04-0.12-0.09-0.18c-0.11-0.15-0.3-0.28-0.3-0.28s0.32,0.19,0.51,0.24
			c0.04,0.01,0.08,0.01,0.12-0.01c0.15-0.05,0.26-0.22,0.14-0.36c-0.1-0.11-0.37-0.34-0.54-0.48c-0.09-0.07-0.16-0.13-0.16-0.13
			c0.27,0.1,0.44-0.05,0.46-0.21c0.01-0.07-0.01-0.14-0.07-0.18c-0.19-0.15-0.85-0.61-1.6-0.97c-0.55-0.27-0.72-0.29-0.78-0.28
			c-0.02,0-0.02,0.01-0.02,0.01l0.05-0.39c-0.26,0.09-0.55,0.17-0.82,0.2c-0.39-0.5-2.29-3.05-3.48-5.9
			c-0.54-1.3,0.76-2.45,2.39-4.21c-2.09-0.93-2.6-2.65-2.69-3.02c0-0.01-0.01-0.01-0.01-0.02c-1.62,2.39-2.09,3.25-2.94,4.47
			c-1.96,2.8-0.79,3.03,2.7,7.46c0.47,0.59,0.88,1.02,1.24,1.32c0.3,0.3,0.56,0.54,0.77,0.66c0.78,0.46,0.89,1.32,1.28,1.61
			c0.29,0.22,0.52,0.32,0.79,0.4c0.09,0.03,0.19,0.05,0.3,0.08C187.77,402.36,187.72,402.46,188.13,402.6z"
          />
          <path
            fill="#42637C"
            d="M199.62,389.54c0-0.03,0-0.07-0.01-0.1c0,0,0,0.07,0,0.2c0,0.05,0,0.11,0,0.18
			C199.62,389.72,199.63,389.62,199.62,389.54z"
          />
          <path
            fill="#42637C"
            d="M199.82,397.61c-0.01-0.18-0.02-0.35-0.04-0.52c-0.05-0.37-0.16-0.73-0.24-1.13
			c-0.06-0.33-0.11-0.69-0.09-1.1c0.01-0.18,0.03-0.38,0.06-0.59c0.03-0.19,0.06-0.36,0.08-0.54c0.03-0.22,0.05-0.44,0.06-0.64
			c0.05-0.68,0.03-1.27,0-1.88c-0.03-0.57-0.04-1.06-0.04-1.38c0,0.04-0.01,0.07-0.01,0.11c-0.1,0.76-0.57,1.9-1.71,2.33
			c0,0,0.97,0.81,1.18,1.81s-0.13,0.54-0.08,2.08c0.04,1.19,0.29,1.12,0.58,1.97c0.12,0.37,0.17,0.58,0.19,0.69
			C199.79,398.59,199.84,398.11,199.82,397.61z"
          />
          <path
            fill="#42637C"
            d="M188.36,394.89c-0.06,1.52-0.57,4.7-0.57,4.7s0.01-0.09,0.07-0.26c0.07-0.2,0.2-0.51,0.45-0.94
			c0.47-0.81,0.95-1.61,0.76-2.8c-0.19-1.18-0.38-1.33,0.09-2.04s0.57-1.33,0.57-1.33c-1.47-0.09-1.66-1.09-1.94-1.85
			c0,0,0.14,0.58,0.28,1.39C188.23,392.69,188.4,393.9,188.36,394.89z"
          />
          <path
            fill="#111413"
            d="M188.4,451.53c0.52-0.46,1.06-0.6,1.34-0.83c0.04-0.04,0.08-0.07,0.11-0.11c0.22-0.31,0.12-0.76,0.12-0.76
			s0.02,0.35-0.59,0.67c-1.29,0.68-2.11,1.64-3.45,2.09c-0.79,0.27-2.58,0.49-3.12-0.3c-0.06-0.09-0.1-0.18-0.1-0.26
			c-0.06,0.58,0.03,1.09,1.06,1.24c0.18,0.03,0.39,0.04,0.64,0.05C186.11,453.34,187.8,452.06,188.4,451.53z"
          />
          <path
            fill="#AD7D62"
            d="M185.93,452.59c1.33-0.45,2.15-1.41,3.45-2.09c0.61-0.32,0.59-0.67,0.59-0.67
			c-0.11-0.64-0.32-1.01-0.32-1.01s-0.01,0.01-0.03,0.03c-0.1,0.11-0.46,0.46-1.05,0.49c-0.7,0.04-1.56-0.49-1.56-0.49
			s0,0-0.03,0.03c-0.05,0.04-0.19,0.16-0.56,0.46c-0.59,0.49-0.43,0.75-1.45,1.31c-0.9,0.49-2.3,0.78-2.26,1.38
			c0.01,0.08,0.04,0.17,0.1,0.26C183.35,453.08,185.14,452.86,185.93,452.59z"
          />
          <path
            fill="#111413"
            d="M205.24,452.59c-1.33-0.45-2.15-1.41-3.45-2.09c-0.61-0.32-0.59-0.67-0.59-0.67s-0.1,0.43,0.1,0.73
			c0.01,0.01,0.01,0.02,0.01,0.02c0.22,0.31,0.85,0.42,1.45,0.94s2.18,1.84,3.89,1.82c1.63-0.02,1.87-0.62,1.81-1.31
			c-0.01,0.08-0.04,0.16-0.1,0.26C207.83,453.08,206.03,452.86,205.24,452.59z"
          />
          <path
            fill="#AD7D62"
            d="M202.6,449.34c-0.7-0.04-1.08-0.52-1.08-0.52s-0.02,0.04-0.05,0.11c-0.07,0.15-0.2,0.46-0.27,0.9
			c0,0-0.02,0.35,0.59,0.67c1.29,0.68,2.11,1.64,3.45,2.09c0.79,0.27,2.58,0.49,3.12-0.3c0.06-0.09,0.09-0.18,0.1-0.26
			c0.05-0.57-1.2-0.86-2.1-1.31c-0.05-0.03-0.1-0.05-0.15-0.08c-1.02-0.56-0.86-0.82-1.45-1.31c-0.59-0.49-0.59-0.49-0.59-0.49
			s-0.01,0.01-0.04,0.02C203.96,448.98,203.22,449.38,202.6,449.34z"
          />
          <path
            fill="#42637C"
            d="M186.44,388.75l0.66-0.68l0.18-0.19l0.22,1.33c-0.01-0.05-0.01-0.11-0.01-0.17
			c0.03-0.59,0.7-1.69,0.5-1.58c-0.23,0.13-0.49,0.52-0.45,0.16c0.03-0.36,0.52-1.29,0.36-1.16c-0.16,0.13,0,0.26-0.65,0.87
			c-0.64,0.6-1.35,0.97-1.7,1.05c-0.01,0-0.02,0-0.02,0.01C185.84,388.65,186.44,388.75,186.44,388.75z"
          />
          <path
            fill="#42637C"
            d="M200.26,387.75c0.02-0.03,0.05-0.07,0.09-0.1c0.02-0.02,0.05-0.04,0.07-0.04c0.02-0.01,0.03-0.01,0.05,0
			c0.05,0.02,0.1,0.08,0.14,0.15c0.03,0.05,0.06,0.11,0.09,0.14c0.13,0.15,0.22,0.26,0.32,0.38c0.11,0.13,0.23,0.28,0.39,0.49
			c0,0,0,0,0,0c0,0,0,0,0,0c-0.45-0.69-0.76-1.39-1.06-2.16c0.03,0.25,0.03,0.51,0,0.77c-0.06-0.27-0.24-0.02-0.38-0.26
			c-0.06,0.56,0.23,0.61-0.06,1.13c0.02-0.03,0.04-0.06,0.06-0.08c0.11-0.16,0.19-0.26,0.27-0.39
			C200.25,387.76,200.25,387.76,200.26,387.75z"
          />
          <path
            opacity="0.3"
            d="M202.75,416.86c0.03,0.31,0.06,0.61,0.09,0.92c0-0.03,0-0.06-0.01-0.09c-0.01-0.06-0.02-0.18-0.03-0.33
			C202.79,417.22,202.77,417.05,202.75,416.86z"
          />
          <path
            opacity="0.3"
            d="M202.65,415.87c0.06,0.63,0.12,1.15,0.15,1.48c0.02,0.15,0.03,0.26,0.03,0.32
			c-0.07-1.02-0.23-3.08-0.53-5.54c0.06,0.56,0.11,1.14,0.17,1.76C202.53,414.59,202.59,415.24,202.65,415.87z"
          />
          <path
            fill="#AD7D62"
            d="M201.86,408.81c0.2,1.41,0.57,4.89,0.79,7.06c-0.06-0.63-0.12-1.28-0.18-1.97
			c-0.05-0.62-0.11-1.2-0.17-1.76c-0.27-2.55-0.58-4.48-1.01-6.27c-0.23-1-0.5-1.96-0.81-2.97c0,0,0,0,0,0h0
			C200.49,402.89,201.52,406.38,201.86,408.81z"
          />
          <path
            fill="#AD7D62"
            d="M190.31,446.12c0.64-2.52,1.13-7.78,1.23-13.26c0.13-7.06,2.27-19.22,2.59-23.61
			c0.41,3.72,1.17,9.28,2.6,16.78c1.42,7.42,1.19,8.78,3.82,20.09c0.05,0.03,0.83,0.44,2.16,0.19c1.19-0.23,1.87-0.24,2.03-0.24
			c0.03-1.82-0.11-5.96-1.25-14.52c0-6.83-0.31-10.25-0.66-13.77c-0.03-0.3-0.06-0.61-0.09-0.92c-0.13-1.2-0.41-3.55-0.87-6.56
			c-0.68-4.45-3.54-5.43-4.78-5.49l0.07-0.02c-2.59,0.09-6.02-0.07-8.86-1.15c-0.55-0.14-0.97-0.3-1.22-0.47
			c-1.06,3.28-1.65,7.1-1.65,11.81c0,5.17,0.65,13.41,1.07,17.6c0.42,4.19-0.14,9.08,0,11.59c0.04,0.7,0.11,1.28,0.19,1.75
			C188,446.33,190.28,446.13,190.31,446.12z"
          />
          <path
            fill="#1EBEE4"
            d="M198.68,403.45c0.22-0.06,0.45-0.12,0.67-0.19C199.13,403.33,198.91,403.39,198.68,403.45z"
          />
          <path
            opacity="0.3"
            d="M198.68,403.45c0.22-0.06,0.45-0.12,0.67-0.19C199.13,403.33,198.91,403.39,198.68,403.45z"
          />
          <path
            fill="#1EBEE4"
            d="M198.13,403.59c0.03-0.01,0.05-0.01,0.08-0.02C198.18,403.58,198.16,403.58,198.13,403.59z"
          />
          <path
            opacity="0.3"
            d="M198.13,403.59c0.03-0.01,0.05-0.01,0.08-0.02C198.18,403.58,198.16,403.58,198.13,403.59z"
          />
          <path
            opacity="0.3"
            d="M200.49,402.89c-0.2,0.07-0.4,0.14-0.61,0.21C200.09,403.04,200.29,402.97,200.49,402.89L200.49,402.89z"
          />
          <path
            fill="#664033"
            d="M189.68,373.65L189.68,373.65c-0.14,0.43-0.39,0.61,0.09,1.78c0.15,0.36,0.38,1.09,0.35,1.04
			c0.37,1.36,0.3,1.96,2.29,1.78c-0.08,0.46-0.48,0.91-0.66,1.04c0,0,0,0,0,0c-0.07,0.05-0.11,0.08-0.11,0.08h0
			c0.02,0,0.03,0.01,0.05,0.01c1.19,0.27,2.23,1.29,2.23,1.29l0.35,1.64l0.29-1.7c0.64-0.44,2.01-0.84,2.39-0.95c0,0,0.01,0,0.01,0
			c-0.04-0.03-0.08-0.06-0.12-0.09c-0.09-0.07-0.18-0.15-0.27-0.23c-0.01-0.01-0.02-0.02-0.03-0.03c-0.23-0.25-0.51-1.86-0.12-3.05
			c0.26-0.55,1.53-3.3,1.31-4.94c-0.23-1.76-1.08-3.07-3.25-3.26c-0.01,0-0.01,0-0.02-0.01c-0.32-0.1-0.68-0.2-0.98-0.04
			c-0.03,0.01-0.06,0.03-0.08,0.04c-0.02,0-0.03,0-0.05,0c-0.03,0-0.05-0.01-0.08-0.02c-0.32-0.1-0.66-0.08-0.9,0.16
			c-0.17-0.07-0.42-0.06-0.57,0.06c-0.04,0.03-0.07,0.08-0.12,0.11c-0.13,0.1-0.34,0.04-0.47,0.13c-0.1,0.07-0.14,0.2-0.25,0.23
			c-0.05,0.02-0.11,0.01-0.16,0.02c-0.13,0.03-0.25,0.13-0.3,0.27c-0.04,0.12-0.2,0.16-0.29,0.25c-0.05,0.06-0.14,0.26,0,0.42
			c0,0,0.01,0,0.01,0c-0.51,0.78-0.72,1.8-0.54,2.85c0.1-0.02,0.04,0.33,0.03,0.33c-0.02,0.01-0.03,0.01-0.04,0.01l0.04,0.26
			L189.68,373.65z"
          />
          <path
            fill="#111413"
            d="M191.64,379.37c2.47-0.79,3.97-3.61,3.12-2.47c-0.95,1.27-1.96,1.23-2.27,1.33
			c-0.05,0.02-0.07-0.01-0.07,0.02c0,0,0,0,0,0.01c-0.08,0.46-0.48,0.91-0.66,1.04c0,0,0,0,0,0
			C191.68,379.34,191.64,379.37,191.64,379.37L191.64,379.37z"
          />
          <path
            fill="#111413"
            d="M190.45,369.64c0.99-0.74,3.11,0.12,3.51,0.56c0.92,1.01,0.99,3.43,0.95,3.73
			c-0.04,0.3,0.38-1.55,1.19-1.36c0.62,0.15,0.59,1.03,0.26,1.54c-0.5,0.77-0.3,1.46,0.01,2.25c0,0,0.02-0.04,0.05-0.11
			c0.26-0.55,1.53-3.3,1.31-4.94c-0.23-1.76-1.08-3.07-3.25-3.26c-0.01,0-0.01,0-0.02-0.01c-0.32-0.1-0.68-0.2-0.98-0.04
			c-0.03,0.01-0.06,0.03-0.08,0.04c-0.02,0-0.03,0-0.05,0c-0.03,0-0.05-0.01-0.08-0.02c-0.32-0.1-0.66-0.08-0.9,0.16
			c-0.17-0.07-0.42-0.06-0.57,0.06c-0.04,0.03-0.07,0.08-0.12,0.11c-0.13,0.1-0.34,0.04-0.47,0.13c-0.1,0.07-0.14,0.2-0.25,0.23
			c-0.05,0.02-0.11,0.01-0.16,0.02c-0.13,0.03-0.25,0.13-0.3,0.27c-0.04,0.12-0.2,0.16-0.29,0.25c-0.05,0.06-0.14,0.26,0,0.42
			c0,0,0.01,0,0.01,0c0.01,0,0.01,0,0.02,0C190.3,369.72,190.39,369.69,190.45,369.64z"
          />
          <path
            fill="#111413"
            d="M192.94,372.74c0,0-0.01-0.22-0.44-0.3c-0.14-0.03-0.79-0.05-1.11,0.07c-0.32,0.12-0.42,0.44-0.16,0.46
			c0,0,0.28-0.14,0.72-0.25C192.38,372.6,192.94,372.74,192.94,372.74z"
          />
          <path
            fill="#111413"
            d="M192.72,373.47c0,0-0.55-0.42-1.39-0.01c0,0,0.2-0.05,0.49-0.07c-0.01,0.05-0.02,0.16,0.04,0.29
			c-0.16,0.01-0.25,0.01-0.25,0.01c0.15,0.04,0.35,0.15,0.69,0.03c0.06-0.02,0.17-0.08,0.26-0.14
			C192.65,373.52,192.72,373.47,192.72,373.47z M192.29,373.62c-0.04,0.01-0.08,0.02-0.12,0.02c0.02-0.04,0.07-0.17,0.03-0.25
			c0.12,0.01,0.25,0.02,0.38,0.05C192.55,373.48,192.42,373.59,192.29,373.62z"
          />
          <path
            fill="#111413"
            d="M192.68,373.29c-0.48-0.4-1.13-0.24-1.34-0.08c0.03-0.01,0.13-0.03,0.44-0.07
			C192.29,373.07,192.68,373.29,192.68,373.29z"
          />
          <path fill="#111413" d="M191.33,373.22c0,0,0.01,0,0.01-0.01C191.32,373.22,191.33,373.22,191.33,373.22z" />
          <path
            fill="#111413"
            d="M190.41,375.41c-0.03,0.05,0.08,0.04,0.35,0.09C190.96,375.54,190.44,375.36,190.41,375.41z"
          />
          <path
            fill="#111413"
            d="M190.77,376.88c0.37,0.01,0.62-0.15,0.86-0.34c0.06-0.05,0.11-0.12,0.15-0.18
			c0.2-0.17,0.43,0.12,0.43,0.12c-0.26-0.34-0.32-0.71-0.32-0.57c0,0.1,0,0.15-0.05,0.22c-0.06,0.04-0.18,0.11-0.38,0.04
			c-0.23-0.08-0.4-0.23-0.64-0.19c-0.21,0.04-0.18,0.13-0.23,0.11c-0.01,0-0.02-0.01-0.03-0.02c-0.1-0.07-0.18-0.14-0.35,0.02
			c0,0-0.04,0.07-0.09,0.16c-0.03,0.06-0.04,0.13-0.03,0.17c0,0.02,0.01,0.04,0.02,0.06c0,0,0,0,0,0c0.03,0.05,0.07,0.09,0.11,0.14
			C190.27,376.65,190.4,376.87,190.77,376.88z"
          />
          <path
            fill="#111413"
            d="M190.87,375.08c0.16,0.11,0.22,0.2,0.17,0.36c-0.05,0.17-0.32,0.11-0.32,0.11s0.24,0.1,0.39-0.07
			C191.26,375.31,190.87,375.08,190.87,375.08z"
          />
          <path
            fill="#111413"
            d="M189.72,372.89c0.01,0,0.02,0,0.02,0c0.27-0.04,0.52,0.11,0.6,0.17c0.08,0.07,0.17-0.3-0.08-0.44
			c-0.2-0.11-0.46-0.08-0.56-0.06c0,0-0.09,0.27-0.01,0.34C189.69,372.9,189.71,372.89,189.72,372.89z"
          />
          <path
            fill="#111413"
            d="M190.04,375.07c0.01-0.3,0.38-0.46,0.41-0.88c0.01-0.23-0.03-0.4-0.03-0.4c0.01,0.2-0.03,0.51-0.27,0.82
			c-0.27,0.34-0.47,0.83,0.22,0.88C190.36,375.48,190.03,375.38,190.04,375.07z"
          />
          <path
            fill="#111413"
            d="M190.21,373.6c-0.04,0-0.08,0.01-0.12,0.01l0.04,0c0,0,0.02-0.08,0.03-0.17c0-0.05,0.01-0.1-0.01-0.14
			c0.08,0.02,0.13,0.04,0.13,0.04c-0.26-0.22-0.56-0.19-0.56-0.19l0.01,0.12c0.04-0.01,0.08-0.01,0.11-0.01c0,0-0.02,0.17,0.06,0.34
			c-0.1-0.01-0.17-0.02-0.2-0.02c-0.01,0-0.01,0-0.01,0l-0.01,0.07l0,0C189.96,373.65,190.21,373.6,190.21,373.6z"
          />
          <path
            fill="#141616"
            d="M195.92,373.71c0.03,0.1,0.05,0.19,0.05,0.19s0.09-0.1,0.06-0.21c-0.03-0.11-0.08-0.18-0.22-0.32
			c-0.14-0.15-0.03-0.31,0.12-0.33c0.21-0.03,0.39,0.32,0.39,0.32c-0.05-0.16-0.1-0.38-0.32-0.43c-0.22-0.06-0.33,0.07-0.39,0.15
			c-0.06,0.08-0.15,0.42-0.01,0.55C195.74,373.74,195.89,373.61,195.92,373.71z"
          />
          <path
            fill="#141616"
            d="M195.57,374.24c0.09-0.09,0.27-0.33,0.21-0.39c-0.06-0.07-0.2-0.03-0.3-0.16c0,0-0.05,0.02,0,0.11
			c0.05,0.09,0.12,0.13,0.01,0.29C195.39,374.24,195.48,374.33,195.57,374.24z"
          />
          <path
            fill="#141616"
            d="M195.72,374.52c0.49-0.14,0.55-0.84,0.55-0.84C196.22,374.08,195.72,374.52,195.72,374.52z"
          />
          <path
            fill="#111413"
            d="M192.79,373.5c0.2,0.18,0.42-0.03,0.42-0.03C193.1,373.56,192.85,373.46,192.79,373.5z"
          />
          <path
            opacity="0.3"
            d="M202.84,417.75c0,0,0-0.03-0.01-0.07c0,0,0,0.01,0,0.01C202.84,417.72,202.84,417.75,202.84,417.75z"
          />
          <path
            fill="#1EBEE4"
            d="M196.19,403.95c0.63-0.09,1.28-0.21,1.93-0.36c-5.35,1.22-9.96,0-9.96,0c0.05,0.02,0.1,0.04,0.15,0.06
			C190.08,404.09,193.15,404.33,196.19,403.95z"
          />
          <path
            opacity="0.3"
            d="M196.19,403.95c0.63-0.09,1.28-0.21,1.93-0.36c-5.35,1.22-9.96,0-9.96,0c0.05,0.02,0.1,0.04,0.15,0.06
			C190.08,404.09,193.15,404.33,196.19,403.95z"
          />
          <path
            fill="#1EBEE4"
            d="M199.37,403.26c0,0-0.01,0-0.01,0C199.36,403.26,199.37,403.26,199.37,403.26
			C199.37,403.26,199.37,403.26,199.37,403.26z"
          />
          <path
            opacity="0.3"
            d="M199.37,403.26c0,0-0.01,0-0.01,0C199.36,403.26,199.37,403.26,199.37,403.26
			C199.37,403.26,199.37,403.26,199.37,403.26z"
          />
          <path
            fill="#1EBEE4"
            d="M198.21,403.57c0.16-0.04,0.32-0.08,0.48-0.12C198.52,403.49,198.36,403.53,198.21,403.57z"
          />
          <path
            opacity="0.3"
            d="M198.21,403.57c0.16-0.04,0.32-0.08,0.48-0.12C198.52,403.49,198.36,403.53,198.21,403.57z"
          />
          <path fill="#1EBEE4" d="M199.88,403.1c-0.01,0-0.01,0-0.02,0.01C199.86,403.11,199.88,403.1,199.88,403.1z" />
          <path
            opacity="0.3"
            d="M199.88,403.1c-0.01,0-0.01,0-0.02,0.01c0,0,0,0,0,0C199.87,403.11,199.88,403.1,199.88,403.1z"
          />
          <path
            opacity="0.3"
            d="M199.86,403.11C199.86,403.11,199.86,403.11,199.86,403.11c-0.08,0.03-0.16,0.05-0.25,0.08c0,0,0,0,0,0
			C199.7,403.16,199.78,403.14,199.86,403.11z"
          />
          <path
            opacity="0.3"
            d="M202.83,417.68C202.83,417.68,202.83,417.68,202.83,417.68c-0.01-0.07-0.02-0.18-0.03-0.33
			C202.82,417.5,202.83,417.62,202.83,417.68z"
          />
          <path
            opacity="0.3"
            d="M202.83,417.68C202.83,417.68,202.83,417.68,202.83,417.68c-0.01-0.07-0.02-0.18-0.03-0.33
			C202.82,417.5,202.83,417.62,202.83,417.68z"
          />
          <path
            opacity="0.3"
            d="M202.65,415.87c0.03,0.33,0.06,0.66,0.1,0.99c0.02,0.2,0.04,0.36,0.05,0.49
			C202.77,417.02,202.72,416.5,202.65,415.87z"
          />
          <path
            opacity="0.3"
            d="M202.65,415.87c0.03,0.33,0.06,0.66,0.1,0.99c0.02,0.2,0.04,0.36,0.05,0.49
			C202.77,417.02,202.72,416.5,202.65,415.87z"
          />
          <path
            fill="#AD7D62"
            d="M201.86,408.81c-0.35-2.43-1.37-5.91-1.37-5.91c-0.23,0.09-0.46,0.18-0.69,0.26
			c0.02-0.01,0.04-0.03,0.07-0.04c-0.08,0.03-0.17,0.05-0.25,0.08c-0.04,0.03-0.08,0.06-0.11,0.07c-0.02,0.01-0.04,0.01-0.06,0.01
			c-1.03,0.32-2.13,0.54-3.24,0.68c-3.04,0.38-6.11,0.14-7.88-0.31c2.83,1.08,6.27,1.24,8.86,1.15l-0.07,0.02
			c1.24,0.06,4.1,1.03,4.78,5.49c0.46,3.01,0.74,5.36,0.87,6.56c-0.03-0.32-0.06-0.65-0.1-0.99
			C202.43,413.69,202.06,410.22,201.86,408.81z"
          />
          <path
            opacity="0.3"
            d="M201.86,408.81c-0.35-2.43-1.37-5.91-1.37-5.91c-0.23,0.09-0.46,0.18-0.69,0.26
			c0.02-0.01,0.04-0.03,0.07-0.04c-0.08,0.03-0.17,0.05-0.25,0.08c-0.04,0.03-0.08,0.06-0.11,0.07c-0.02,0.01-0.04,0.01-0.06,0.01
			c-1.03,0.32-2.13,0.54-3.24,0.68c-3.04,0.38-6.11,0.14-7.88-0.31c2.83,1.08,6.27,1.24,8.86,1.15l-0.07,0.02
			c1.24,0.06,4.1,1.03,4.78,5.49c0.46,3.01,0.74,5.36,0.87,6.56c-0.03-0.32-0.06-0.65-0.1-0.99
			C202.43,413.69,202.06,410.22,201.86,408.81z"
          />
          <path
            fill="#1EBEE4"
            d="M199.43,403.27c-0.02,0-0.04,0-0.06-0.01c0,0-0.01,0-0.01,0c-0.23,0.07-0.45,0.13-0.67,0.19
			c-0.16,0.04-0.32,0.08-0.48,0.12c-0.03,0.01-0.05,0.01-0.08,0.02c-0.66,0.15-1.3,0.27-1.93,0.36
			C197.3,403.81,198.4,403.59,199.43,403.27z"
          />
          <path
            opacity="0.3"
            d="M199.43,403.27c-0.02,0-0.04,0-0.06-0.01c0,0-0.01,0-0.01,0c-0.23,0.07-0.45,0.13-0.67,0.19
			c-0.16,0.04-0.32,0.08-0.48,0.12c-0.03,0.01-0.05,0.01-0.08,0.02c-0.66,0.15-1.3,0.27-1.93,0.36
			C197.3,403.81,198.4,403.59,199.43,403.27z"
          />
          <path
            opacity="0.3"
            d="M199.43,403.27c-0.02,0-0.04,0-0.06-0.01c0,0-0.01,0-0.01,0c-0.23,0.07-0.45,0.13-0.67,0.19
			c-0.16,0.04-0.32,0.08-0.48,0.12c-0.03,0.01-0.05,0.01-0.08,0.02c-0.66,0.15-1.3,0.27-1.93,0.36
			C197.3,403.81,198.4,403.59,199.43,403.27z"
          />
          <path
            fill="#1EBEE4"
            d="M199.8,403.15c0.24-0.08,0.47-0.17,0.69-0.26c-0.2,0.07-0.4,0.14-0.61,0.21c-0.01,0-0.01,0.01-0.02,0.01
			C199.84,403.12,199.82,403.14,199.8,403.15z"
          />
          <path
            opacity="0.3"
            d="M199.8,403.15c0.24-0.08,0.47-0.17,0.69-0.26c-0.2,0.07-0.4,0.14-0.61,0.21c-0.01,0-0.01,0.01-0.02,0.01
			C199.84,403.12,199.82,403.14,199.8,403.15z"
          />
          <path
            opacity="0.3"
            d="M199.8,403.15c0.24-0.08,0.47-0.17,0.69-0.26c-0.2,0.07-0.4,0.14-0.61,0.21c-0.01,0-0.01,0.01-0.02,0.01
			C199.84,403.12,199.82,403.14,199.8,403.15z"
          />
          <path
            fill="#1EBEE4"
            d="M203.14,383.31c-0.82-1.03-1.22-2.07-2.61-2.6c-0.37-0.16-0.88-0.29-1.56-0.43l-2.09-0.73
			c-0.01,0-0.02,0.01-0.04,0.01c0.04,0.03,0.08,0.06,0.12,0.09c0,0-0.01,0-0.01,0c-0.39,0.11-1.76,0.51-2.39,0.95l-0.29,1.7
			l-0.35-1.64c0,0-1.04-1.01-2.23-1.29c-0.02,0-0.03-0.01-0.05-0.01h0c0,0,0.05-0.03,0.11-0.08c-0.01,0-0.02-0.01-0.03-0.01
			c-0.23,0.06-1.01,0.32-1.72,0.5c-2.21,0.31-2.89,0.73-4.07,2.01c-2.24,2.41-2.92,4.15-2.92,4.15s0.07,0.12,0.21,0.32
			c0,0.01,0.01,0.01,0.01,0.02c0.36,0.51,1.15,1.49,2.3,2.1c0.35-0.08,1.06-0.44,1.7-1.05c0.65-0.62,0.49-0.74,0.65-0.87
			c0.16-0.13-0.32,0.81-0.36,1.16c-0.03,0.36,0.23-0.03,0.45-0.16c0.2-0.12-0.46,0.98-0.5,1.58c0.2,1.09,0.41,1.93,0.58,2.74
			c-0.14-0.81-0.28-1.39-0.28-1.39c0.28,0.76,0.47,1.75,1.94,1.85c0,0-0.09,0.62-0.57,1.33c-0.47,0.71-0.28,0.85-0.09,2.04
			c0.19,1.18-0.29,1.99-0.76,2.8c-0.25,0.43-0.38,0.74-0.45,0.94c-0.02,0.08-0.03,0.17-0.05,0.25c0.05-0.01,0.23,0.01,0.78,0.28
			c0.75,0.36,1.41,0.82,1.6,0.97c0.06,0.04,0.08,0.11,0.07,0.18h0c0.01,0.1-0.04,0.28-0.31,0.34c0.18,0.14,0.45,0.37,0.54,0.48
			c0.11,0.13,0,0.31-0.14,0.36c-0.07,0.04-0.18,0.06-0.33,0.05c0.05,0.06,0.08,0.12,0.09,0.18c0.02,0.16-0.11,0.26-0.3,0.22
			c-0.14,0.01-0.36,0-0.56-0.08c-0.1-0.04-0.27-0.12-0.46-0.21c0.09,0.06,0.17,0.12,0.22,0.16c0.19,0.17,0.17,0.53-0.02,0.51
			c-0.03,0-0.05-0.01-0.08-0.02c-0.14-0.04-0.42-0.11-0.63-0.16c-0.28-0.07-0.59-0.23-0.77-0.3c-0.18-0.07-0.55-0.03-0.55-0.03
			l0.01-0.05c-0.1,0.19-0.15,0.26-0.15,0.26c-0.07,0.16,0.03,0.31,0.26,0.47c0.26,0.17,0.68,0.33,1.22,0.47
			c-0.05-0.02-0.1-0.04-0.15-0.06c0,0,4.61,1.22,9.96,0c0.03-0.01,0.05-0.01,0.08-0.02c0.16-0.04,0.32-0.08,0.48-0.12
			c0.22-0.06,0.45-0.12,0.67-0.19c0,0,0.57-0.2,0.51-0.16c0.01,0,0.01,0,0.02-0.01c0.2-0.07,0.4-0.14,0.61-0.21h0
			c0,0-0.02-0.07-0.05-0.22c-0.05,0.05-0.36-1.8-0.59-3.03c-0.03-0.22-0.07-0.45-0.1-0.69c0-0.02,0.01-0.03,0.01-0.05
			c0-0.02,0.01-0.05,0.01-0.08c-0.02-0.11-0.06-0.32-0.19-0.69c-0.29-0.86-0.54-0.79-0.58-1.98c-0.05-1.54,0.29-1.08,0.08-2.08
			c-0.21-1-1.18-1.81-1.18-1.81c1.14-0.44,1.61-1.57,1.71-2.33c0-0.1,0.01-0.2,0.01-0.29c0-0.13,0-0.2,0-0.2
			c0,0.03,0.01,0.06,0.01,0.1c0.05-0.63,0.18-1.05,0.35-1.37c-0.02,0.03-0.04,0.05-0.06,0.08c0.29-0.52-0.01-0.57,0.06-1.13
			c0.14,0.24,0.33-0.01,0.38,0.26c0.04-0.25,0.04-0.51,0-0.77c0.29,0.77,0.61,1.47,1.06,2.16c0.1-0.05,0.2-0.11,0.29-0.16
			c1.24-0.76,2.32-2.16,2.82-2.85c0.02-0.03,0.05-0.07,0.07-0.1c0.13-0.19,0.21-0.31,0.21-0.31S204.01,384.4,203.14,383.31z"
          />
          <path
            fill="#42637C"
            d="M200.65,401.93c0-0.02-0.08-1.08-0.08-1.08l-0.96-0.69l-1.28,0.28l0.05,0.35
			c-0.16,0.08-0.3,0.16-0.41,0.21c0.06,0.01,0.15,0.01,0.31,0c0.05-0.02,0.08-0.04,0.13-0.06l0.04,0.23l-0.85,0.63l0.06,0.18
			c-0.09,0.02-0.19,0.05-0.25,0.03c-0.07,0.08-0.03,0.18,0.2,0.18c0.33,0,0.36,0.36,1.06,0.22c0.5-0.1,1.04-0.26,1.34-0.36
			c0.02-0.03,0,0.45,0,0.45l-0.21,0.55c-0.06,0.05-0.12,0.08-0.18,0.13c0,0,0,0-0.01,0.01c0.08-0.03,0.17-0.05,0.25-0.08
			c0.16-0.1,0.36-0.24,0.57-0.43c0.07-0.06,0.15-0.13,0.22-0.21C200.63,402.47,200.65,401.95,200.65,401.93z"
          />
          <path
            fill="#664033"
            d="M208.42,391.48c-0.89-1.37-2.35-3.69-3.82-5.84c-0.02,0.03-0.04,0.06-0.07,0.1
			c-0.17,0.46-0.79,1.83-2.45,3.21c0,0,0.04,0.05-0.04-0.02c1.99,2.29,3.96,2.54,2.51,4.79c-1.01,1.57-3.26,5.12-3.8,5.98
			c-0.21,0.08-0.33,0.09-0.59,0.03c-0.1-0.02-0.2-0.05-0.3-0.08c-0.26-0.08-0.57-0.16-0.94-0.11c-0.53,0.07-0.86,0.74-1.25,1.01
			c-0.38,0.26,0.02,0.6,0.31,0.46c0,0,0,0,0.01,0c0.13-0.06,0.31-0.16,0.5-0.25c0.24-0.12,0.49-0.25,0.62-0.32
			c0.24-0.12,0.26,0.12,0.26,0.12s-0.22,0.02-0.6,0.22c-0.12,0.06-0.29,0.15-0.47,0.23c-0.39,0.18-0.82,0.39-0.96,0.51
			c-0.15,0.13-0.15,0.44,0.09,0.5c0.08,0.02,0.19,0.01,0.33-0.04c0,0,0.14,0.42,0.54,0.34s0.85-0.09,1.17-0.2
			c0.32-0.11,0.43-0.2,0.58-0.26c0.1-0.04,0.09,0.08-0.02,0.21c-0.05,0.06-0.11,0.12-0.2,0.16c-0.26,0.14-0.55,0.55-0.62,0.68
			c-0.04,0.09,0.02,0.33,0.17,0.37c0.03-0.01,0.03-0.01,0,0c0.02,0.01,0.04,0.01,0.06,0.01c0.02,0,0.04,0,0.06-0.01
			c0.04-0.01,0.07-0.03,0.11-0.07c-0.03,0.01-0.03,0.01,0,0c0,0,0,0,0.01-0.01c0.32-0.28,0.68-0.46,0.94-0.66
			c0.03-0.03,0.06-0.04,0.09-0.05c0.21-0.08,0.35,0.12,0.63-0.08c0.32-0.23,0.52-0.74,0.89-1.15c0.37-0.42,0.49-0.48,0.6-0.72
			c0,0,0.42-0.53,2.26-2.57C206.88,395.9,209.69,393.45,208.42,391.48z"
          />
        </g>
        <g>
          <defs>
            <rect id="Card11MSVGID1" width="355" height="530" />
          </defs>
          <clipPath id="Card11MSVGID2">
            <use xlinkHref="#Card11MSVGID1" overflow="visible" />
          </clipPath>
        </g>
        <g>
          <defs>
            <rect id="Card11MSVGID3" width="355" height="530" />
          </defs>
          <clipPath id="Card11MSVGID4">
            <use xlinkHref="#Card11MSVGID3" overflow="visible" />
          </clipPath>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID5" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID6">
              <use xlinkHref="#Card11MSVGID5" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID7" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID8">
              <use xlinkHref="#Card11MSVGID7" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID9" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID10">
              <use xlinkHref="#Card11MSVGID9" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID11" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID12">
              <use xlinkHref="#Card11MSVGID11" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID13" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID14">
              <use xlinkHref="#Card11MSVGID13" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID15" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID16">
              <use xlinkHref="#Card11MSVGID15" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID17" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID18">
              <use xlinkHref="#Card11MSVGID17" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID19" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID20">
              <use xlinkHref="#Card11MSVGID19" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID21" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID22">
              <use xlinkHref="#Card11MSVGID21" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID23" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID24">
              <use xlinkHref="#Card11MSVGID23" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID25" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID26">
              <use xlinkHref="#Card11MSVGID25" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID27" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID28">
              <use xlinkHref="#Card11MSVGID27" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID29" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID30">
              <use xlinkHref="#Card11MSVGID29" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID31" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID32">
              <use xlinkHref="#Card11MSVGID31" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID33" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID34">
              <use xlinkHref="#Card11MSVGID33" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID35" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID36">
              <use xlinkHref="#Card11MSVGID35" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID37" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID38">
              <use xlinkHref="#Card11MSVGID37" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID39" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID40">
              <use xlinkHref="#Card11MSVGID39" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID41" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID42">
              <use xlinkHref="#Card11MSVGID41" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID43" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID44">
              <use xlinkHref="#Card11MSVGID43" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID45" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID46">
              <use xlinkHref="#Card11MSVGID45" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID47" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID48">
              <use xlinkHref="#Card11MSVGID47" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID49" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID50">
              <use xlinkHref="#Card11MSVGID49" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID51" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID52">
              <use xlinkHref="#Card11MSVGID51" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID53" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID54">
              <use xlinkHref="#Card11MSVGID53" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID55" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID56">
              <use xlinkHref="#Card11MSVGID55" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID57" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID58">
              <use xlinkHref="#Card11MSVGID57" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID59" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID60">
              <use xlinkHref="#Card11MSVGID59" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID61" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID62">
              <use xlinkHref="#Card11MSVGID61" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID63" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID64">
              <use xlinkHref="#Card11MSVGID63" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID65" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID66">
              <use xlinkHref="#Card11MSVGID65" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <text transform="matrix(1 0 0 1 206.2412 194)" fontFamily={font.serif} fontSize="35.568px">
          {" "}
        </text>
        <rect fill="none" width="355" height="530" />
        <g>
          <g>
            <defs>
              <rect id="Card11MSVGID67" width="355" height="530" />
            </defs>
            <clipPath id="Card11MSVGID68">
              <use xlinkHref="#Card11MSVGID67" overflow="visible" />
            </clipPath>
          </g>
        </g>
        <g>
          <path
            fill="none"
            d="M241.29,323.26c-0.01,0.02-0.01,0.04-0.02,0.06l-0.33-6.04c-0.03-0.4-0.06-0.73-0.08-1
			c-0.05,0.21-0.1,0.44-0.17,0.72c-0.11,0.46,0.05,2.55-0.07,3.5c-0.16,1.22-0.11,1.84-0.05,2.15c0.06,0.16,0.13,0.41,0.15,0.49
			c0.03,0.14,0.1,0.15,0.25,0.32c0.07,0.08,0.16,0.15,0.25,0.22l0.07-0.32C241.29,323.33,241.29,323.29,241.29,323.26z"
          />
          <path
            fill="none"
            d="M243.86,303.46c0-0.01,0-0.02-0.01-0.03c-0.02-0.07-0.05-0.13-0.07-0.18c0.04,0.1,0.07,0.19,0.08,0.23
			C243.86,303.48,243.86,303.47,243.86,303.46z"
          />
          <polygon fill="none" points="247.71,303.21 247.71,303.21 247.71,303.2 		" />
          <path
            fill="none"
            d="M247.26,350.09c0,0,0.01,0.04,0.04,0.09c-0.02-0.12-0.04-0.25-0.06-0.37c-0.25-0.08-0.42-0.16-0.42-0.16
			s-0.37-6.2-0.37-8.76s-0.67-11.1-0.67-11.1s-0.33,4.44-0.44,6.99c-0.11,2.55-0.33,12.92-0.33,12.92
			c-0.09,0.05-0.19,0.09-0.28,0.12c0,0.08-0.01,0.17-0.02,0.25c0.01-0.01,0.02-0.02,0.02-0.02s0.14,0.31,0.22,0.85
			c0,0,0,0.01,0,0.01c0,0.01,0,0.01,0,0.02c0.03-0.01,0.06-0.01,0.09-0.02c0,0,1.08-0.02,2.12-0.03c0,0,0-0.01,0-0.01
			C247.23,350.45,247.26,350.09,247.26,350.09z"
          />
          <path
            opacity="0.3"
            d="M270.45,351.7c-5.83-0.3-14.9-0.65-20.77-0.81c0.2,0.26,0.19,0.37,0.82,0.65c0.9,0.39,1.43,0.35,1.43,0.92
			c0,0.06-0.01,0.11-0.03,0.15c-0.04,0.44-0.34,0.72-1.25,0.67c-0.76-0.04-1.89-0.63-2.41-0.92c-0.61-0.34-0.86-0.35-1.01-0.61
			c-0.15-0.26-0.08-0.64-0.08-0.64c0.01-0.07,0.02-0.14,0.03-0.21c-1.04,0.02-2.12,0.03-2.12,0.03c-0.03,0.01-0.06,0.01-0.09,0.02
			c0.01,0.1,0.04,0.39-0.08,0.6c-0.15,0.26-0.32,0.26-1.14,0.6c-0.55,0.23-1.28,0.75-2.42,0.74c-0.17,0-0.31-0.01-0.43-0.02
			c1.19,0.73,11.79,2.45,25.11,3.2C284.32,357.09,284.09,352.41,270.45,351.7z"
          />
          <path
            fill="#151616"
            d="M244.72,350.08c0.01-0.08,0.01-0.17,0.02-0.25c0.1-0.04,0.19-0.07,0.28-0.12c0,0,0.22-10.37,0.33-12.92
			c0.11-2.55,0.44-6.99,0.44-6.99s0.67,8.55,0.67,11.1s0.37,8.76,0.37,8.76s0.17,0.08,0.42,0.16c0.02,0.12,0.04,0.25,0.06,0.37
			c0.08,0.15,0.3,0.44,0.72,0.28c0.66-0.25,0.95-0.3,1.07-0.29c-0.01-0.13-0.03-0.26-0.04-0.39c0.12-0.06,0.23-0.14,0.33-0.24
			c0.1-0.76,0.43-4.71,0.66-8.15c0.22-3.44,0.34-7.24,0.43-10.85c0.05-2.05-0.24-4.9-0.41-6.31c-0.31,0.76-2.71,0.71-3.28,0.71
			c0.01,0,0.01-0.01,0.02-0.01c-1.48,0-3.17-0.13-4.33-0.24c0.03,0.06,0.07,0.12,0.14,0.18c0.2,0.2,0.21,0.29,0.28,0.46
			c0.07,0.17,0.22,0.41,0.15,0.48s-0.23,0.1-0.43-0.11c-0.2-0.2-0.25-0.31-0.41-0.46c-0.1-0.09-0.25-0.22-0.3-0.23
			c-0.08-0.01-0.06,0.11-0.06,0.12c0,0.01,0-0.01-0.01-0.04c0,0.04,0.01,0.1,0.03,0.15c0.06,0.12,0.15,0.54,0.15,0.54
			s0.26,0.38,0.39,0.56c0.12,0.18,0.12,0.18,0.12,0.18s0.27,0.1,0.25,0.24c0.02,0.07-0.01,0.12-0.1,0.16
			c-0.09,0.04-0.13,0.02-0.13,0.02s-0.02,0.04-0.1,0.05c-0.08,0.01-0.18-0.09-0.18-0.09l-0.15-0.04c0,0-0.02,0.02-0.09,0.04
			c-0.07,0.02-0.13,0.04-0.26,0.01c-0.13-0.03-0.23-0.06-0.32-0.04c-0.1,0.02-0.17,0.04-0.22,0.02c-0.05-0.03-0.11-0.08-0.19-0.1
			c-0.03,2.02-0.06,4.9,0.02,7.42c0.12,3.82,0.44,5.91,0.66,8.58c0.22,2.66,0.63,6.87,0.63,6.87s0.25,0.13,0.64,0.23
			c0,0.07,0,0.15,0,0.22c0.14,0,0.48,0.02,0.84,0.31C244.13,350.66,244.62,350.18,244.72,350.08z"
          />
          <path
            fill="#151616"
            d="M243.73,352.16c0.83-0.34,1-0.34,1.14-0.6c0.12-0.21,0.1-0.51,0.08-0.6c0-0.01,0-0.02,0-0.02
			c0-0.01,0-0.01,0-0.01s0.01,0.3-0.4,0.57c-0.86,0.57-1.41,0.65-2.3,1.03c-0.53,0.23-2.05,0.3-2.1-0.45
			c-0.05,0.5-0.01,0.72,0.62,0.8c0.03,0,0.07,0.01,0.1,0.01c0.12,0.01,0.26,0.02,0.43,0.02
			C242.45,352.91,243.18,352.38,243.73,352.16z"
          />
          <path
            fill="none"
            stroke="#DFD8C6"
            strokeWidth="0"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M242.9,350.09
			C242.89,350.09,242.89,350.09,242.9,350.09"
          />
          <path
            fill="#DFD8C6"
            d="M242.26,352.51c0.89-0.38,1.44-0.46,2.3-1.03c0.41-0.27,0.4-0.57,0.4-0.57c-0.07-0.54-0.22-0.85-0.22-0.85
			s-0.01,0.01-0.02,0.02c-0.1,0.1-0.59,0.58-0.92,0.32c-0.36-0.29-0.71-0.31-0.84-0.31c-0.03,0-0.05,0-0.06,0
			c0.01,0.01,0.01,0.07-0.32,0.41c-0.4,0.41-0.4,0.56-1.18,0.85c-0.64,0.25-1.26,0.51-1.24,0.7
			C240.21,352.81,241.73,352.74,242.26,352.51z"
          />
          <path
            fill="#151616"
            d="M249.58,352.68c-1.58-0.8-1.5-0.77-2.04-0.99c-0.45-0.19-0.4-0.57-0.4-0.57s-0.07,0.38,0.08,0.64
			c0.15,0.26,0.39,0.28,1.01,0.61c0.52,0.29,1.65,0.88,2.41,0.92c0.92,0.05,1.21-0.24,1.25-0.67
			C251.69,353.18,250.19,352.98,249.58,352.68z"
          />
          <path
            fill="#DFD8C6"
            d="M248.02,350.46c-0.42,0.16-0.64-0.13-0.72-0.28c-0.03-0.05-0.04-0.09-0.04-0.09s-0.03,0.36-0.08,0.8
			c0,0,0,0.01,0,0.01c-0.01,0.07-0.02,0.14-0.03,0.21c0,0-0.05,0.38,0.4,0.57c0.54,0.23,0.46,0.19,2.04,0.99
			c0.61,0.31,2.11,0.5,2.32-0.07c0.02-0.05,0.03-0.09,0.03-0.15c0-0.57-0.53-0.52-1.43-0.92c-0.63-0.28-0.62-0.39-0.82-0.65
			c0,0,0,0,0-0.01c-0.04-0.05-0.09-0.11-0.16-0.18c-0.4-0.41-0.4-0.41-0.4-0.41c0.03-0.04,0.07-0.11-0.04-0.12
			C248.97,350.16,248.69,350.22,248.02,350.46z"
          />
          <path
            fill="#D7BFB1"
            d="M243.39,307.06c-0.04,0.02-0.08,0.05-0.13,0.07l0.25-0.11C243.43,307.04,243.39,307.06,243.39,307.06z"
          />
          <path
            fill="#AD7D62"
            d="M248.04,307.36c0,0-0.32-0.09-0.6-0.37l0.01-0.08c-0.27,0.43-0.79,0.52-1.55,0.45
			c-0.92-0.09-1.28-0.52-1.57-1.35c-0.02,0.35-0.11,0.6-0.41,0.82l-0.41,0.19l-0.25,0.11l-0.02,0.01l2.65,2.16L248.04,307.36z"
          />
          <path
            fill="#AD7D62"
            d="M243.89,303.63c-0.01-0.05-0.02-0.1-0.03-0.15c-0.01-0.04-0.04-0.14-0.08-0.23
			c-0.15-0.24-0.36-0.14-0.36-0.14c0-0.01,0-0.01,0-0.02c-0.02,0.05-0.03,0.11-0.04,0.18c-0.1,0.64,0.62,1.32,0.62,1.32l0.04,0.2
			c-0.05-0.4-0.07-0.77-0.13-1.11C243.9,303.68,243.89,303.66,243.89,303.63z"
          />
          <path
            fill="#AD7D62"
            d="M246.27,302.07c-1.01,0-1.16-0.33-1.51-0.36c-0.3-0.02-0.59,0.28-0.63,0.8c0,0.02,0,0.03,0,0.05
			c0,0.06-0.01,0.13,0,0.2c0,0.01,0,0.01,0,0.02c0.01,0.36,0.08,0.75,0.16,1.06c0.21,0.84,0.65,1.08,1.08,0.9
			c0.43-0.19,0.71-0.15,1.05-0.06c0.34,0.09,0.4-0.19,0.74-0.06c0.3,0.11,0.43,0.12,0.46,0.12c0.14-0.44,0.05-0.72,0.08-1.46
			c0-0.02,0-0.04,0-0.06c0,0-0.01,0.01-0.01,0.01s0-0.01,0.01-0.01c0,0,0-0.01,0-0.01c-0.01-0.01-0.04-0.03-0.16-0.04
			c-0.21-0.02-0.44,0-0.49,0.01l0,0c-0.15,0.05-0.33,0.18-0.28,0.45c0.04,0.24,0.08,0.42,0.11,0.53c0.02,0.08,0.04,0.18-0.05,0.24
			c0.04,0,0.07,0.02,0.09,0.03c0.03,0.01-0.06,0.01-0.1,0.02c-0.03,0.01-0.05,0-0.06-0.02c-0.01,0-0.03,0.01-0.05,0.01
			c-0.21,0.04-0.06,0.21-0.06,0.21s-0.15-0.09-0.06-0.21s0.25-0.1,0.21-0.27c-0.04-0.17-0.15-0.43-0.15-0.63
			c0-0.23,0.11-0.35,0.23-0.41c0,0-0.01,0-0.01,0s0.17-0.1,0.25-0.12c0.08-0.02,0.21-0.01,0.38-0.02c0.16-0.01,0.13-0.02,0.19,0.05
			c0.02-0.59-0.04-1.06-0.16-1.43c-0.01,0.02-0.02,0.04-0.04,0.05C247.17,302.02,246.83,302.07,246.27,302.07z M246.06,303.58
			c-0.17,0.05-0.21,0.09-0.41,0.03c-0.13-0.04-0.36-0.08-0.27-0.08c0.12-0.01,0.33,0.04,0.52,0.01c0.23-0.03,0.35-0.01,0.35-0.01
			S246.23,303.53,246.06,303.58z M246.01,303.07c-0.15-0.02-0.21,0.02-0.5,0.02c-0.21,0-0.34,0.1-0.34,0.1s0.1-0.18,0.22-0.2
			c0.12-0.02,0.46-0.04,0.66-0.02c0.21,0.02,0.26,0.18,0.21,0.19C246.23,303.15,246.17,303.09,246.01,303.07z M246.39,304.44
			c-0.07,0-0.11-0.01-0.11-0.01c0.09-0.03,0.13-0.03,0.15-0.03C246.46,304.42,246.46,304.44,246.39,304.44z M247.17,303.6
			c0.06,0.01,0.17,0,0.22-0.01c0.08-0.02,0.11-0.03,0.17-0.04c0,0-0.06,0.04-0.14,0.08c-0.08,0.04-0.14,0.05-0.21,0.04
			s-0.2-0.06-0.28-0.07C246.82,303.58,247.06,303.58,247.17,303.6z"
          />
          <path
            fill="#AD7D62"
            d="M243.85,303.44C243.85,303.44,243.85,303.43,243.85,303.44c0,0,0,0.02,0,0.03
			C243.85,303.45,243.85,303.45,243.85,303.44z"
          />
          <path
            fill="#151616"
            d="M245.89,307.36c0.76,0.07,1.28-0.02,1.55-0.45c0.08-0.13,0.14-0.28,0.18-0.48c0.14-0.75,0-1.64,0.01-1.71
			c0,0,0,0-0.01,0c-0.03,0-0.17-0.01-0.46-0.12c-0.34-0.12-0.4,0.15-0.74,0.06c-0.34-0.09-0.62-0.12-1.05,0.06
			c-0.43,0.19-0.87-0.06-1.08-0.9c-0.08-0.31-0.15-0.71-0.16-1.06c0-0.01,0-0.01,0-0.02c0-0.07,0-0.13,0-0.2c0-0.02,0-0.03,0-0.05
			c0.05-0.52,0.33-0.83,0.63-0.8c0.35,0.03,0.5,0.36,1.51,0.36c0.56,0,0.9-0.05,1.25-0.41c0.01-0.01,0.03-0.03,0.04-0.05
			c0.22-0.28,0.26-0.81,0.15-0.9c-0.14-0.11-0.27-0.26-0.82-0.38c-0.56-0.12-1.24-0.42-2.14,0.02c-0.89,0.43-1.47,0.57-1.34,2.75
			c0,0.01,0,0.01,0,0.02c0,0,0.22-0.1,0.36,0.14c0.03,0.05,0.05,0.11,0.07,0.18c0,0,0,0.01,0,0.01c0,0.01,0,0.02,0,0.03
			c0,0.01,0,0.01,0,0.02c0.01,0.05,0.02,0.1,0.03,0.15c0,0.02,0.01,0.04,0.01,0.06c0.06,0.35,0.08,0.71,0.13,1.11
			c0.04,0.31,0.1,0.64,0.22,1.01c0.02,0.07,0.04,0.13,0.07,0.2C244.61,306.84,244.97,307.27,245.89,307.36z M245.83,305.02
			c0.38-0.12,0.97-0.06,1.33-0.01c0.36,0.04,0.14,0.51-0.41,0.56C246.24,305.62,245.44,305.14,245.83,305.02z"
          />
          <path
            fill="#AD7D62"
            d="M246.75,305.57c0.54-0.06,0.77-0.52,0.41-0.56c-0.36-0.04-0.95-0.11-1.33,0.01
			C245.44,305.14,246.24,305.62,246.75,305.57z M246.54,305.11c0.24,0.04,0.22-0.01,0.43,0.03c0.21,0.04,0.2,0.05,0.2,0.05
			s-0.11,0.17-0.43,0.22c-0.32,0.04-0.83-0.25-0.83-0.25C246.24,305.1,246.3,305.06,246.54,305.11z"
          />
          <path
            fill="#D7BFB1"
            d="M249.59,318.62c0,0-0.05-0.02-0.13-0.04l-0.01,0.04C249.55,318.63,249.62,318.63,249.59,318.62z"
          />
          <path
            fill="#AD7D62"
            d="M240.79,326.41c-0.09-0.16-0.35-0.34-0.37-0.42c-0.02-0.08-0.3-0.3-0.06-0.15
			c0.25,0.15,0.48,0.56,0.54,0.68c0.06,0.12,0.12,0.21,0.2,0.18c0.07-0.03,0.21-0.09,0.18-0.16c-0.02-0.08-0.16-0.26-0.28-0.41
			c-0.12-0.15-0.21-0.38-0.2-0.37c0.01,0.02,0.18,0.23,0.28,0.36c0.1,0.13,0.22,0.35,0.32,0.42s0.36,0.17,0.3,0.13
			c-0.06-0.04-0.2-0.12-0.15-0.18c0.06-0.06,0.25-0.04,0.14-0.14c-0.11-0.1-0.25-0.25-0.29-0.34c-0.11-0.21-0.31-0.81-0.31-0.81
			s0.32,0.7,0.35,0.78c0.03,0.08,0.09,0.13,0.22,0.26c0.07,0.07,0.11,0.12,0.15,0.16c0.04,0.04,0.07,0.09,0.13,0.16
			c0.12,0.15,0.31,0.34,0.31,0.34s0.1,0.1,0.18,0.09c0.08-0.01,0.1-0.05,0.1-0.05c0.09-0.11,0.01-0.29,0.01-0.29s0.2,0.05,0.22,0.12
			c0.02-0.13-0.25-0.24-0.25-0.24s0,0-0.12-0.18c-0.12-0.18-0.39-0.56-0.39-0.56s-0.1-0.42-0.15-0.54c-0.02-0.05-0.03-0.1-0.03-0.15
			c-0.01-0.06-0.03-0.19-0.03-0.3c0.01-0.17-0.12-0.61-0.04-0.47c0.08,0.14,0.15,0.6,0.15,0.52c0-0.07,0.04-0.29,0.04-0.35
			c0-0.01,0-0.02,0-0.02c0,0,0,0.01,0,0.02c0,0.07,0.03,0.31,0.08,0.43c0.06,0.14,0.14,0.32,0.27,0.42c0,0-0.04-0.04-0.1-0.09
			c0.15,0.15,0.21,0.26,0.41,0.46c0.2,0.2,0.36,0.18,0.43,0.11s-0.09-0.31-0.15-0.48c-0.07-0.17-0.08-0.26-0.28-0.46
			c-0.06-0.07-0.1-0.13-0.14-0.18c-0.07-0.12-0.1-0.22-0.2-0.31c-0.15-0.14-0.48-0.31-0.71-0.46c-0.12-0.08-0.24-0.16-0.34-0.23
			c-0.1-0.07-0.18-0.14-0.25-0.22c-0.14-0.17-0.22-0.18-0.25-0.32c-0.02-0.09-0.1-0.33-0.15-0.49c0.04,0.2,0.09,0.27,0.09,0.27
			c-1.31,0.23-1.15,0.5-1.15,0.5l0.12,0.33c0,0-0.01,0-0.01,0c0.04,0.12,0.08,0.23,0.08,0.29c0.01,0.21,0.15,0.28,0.24,0.45
			c0.09,0.17,0.12,0.56,0.17,0.76c0.04,0.2,0.04,0.52,0.11,0.61c0.07,0.09,0.27,0.41,0.37,0.54c0.09,0.11,0.13,0.2,0.21,0.25
			c0.02,0,0.04-0.01,0.07-0.02C240.91,326.61,240.88,326.56,240.79,326.41z"
          />
          <path
            fill="#4D2C1A"
            d="M242.04,324.92c-0.05-0.12-0.08-0.36-0.08-0.43c-0.01,0.07-0.04,0.28-0.04,0.35
			c0,0.08-0.07-0.38-0.15-0.52c-0.08-0.14,0.05,0.3,0.04,0.47c0,0.11,0.01,0.24,0.03,0.3c0.01,0.03,0.01,0.05,0.01,0.04
			c0-0.02-0.02-0.13,0.06-0.12c0.05,0.01,0.2,0.14,0.3,0.23c0.06,0.05,0.1,0.09,0.1,0.09C242.18,325.24,242.1,325.06,242.04,324.92z
			"
          />
          <path fill="#4D2C1A" d="M241.96,324.49c0-0.01,0-0.02,0-0.02C241.96,324.47,241.96,324.48,241.96,324.49z" />
          <path
            fill="#4D2C1A"
            d="M242.55,326.64c0,0,0.08,0.18-0.01,0.29c0,0,0.04,0.02,0.13-0.02c0.09-0.04,0.12-0.09,0.1-0.16
			C242.75,326.69,242.55,326.64,242.55,326.64z"
          />
          <path
            fill="#4D2C1A"
            d="M241.42,326.87c0.1-0.02,0.2,0.02,0.32,0.04c0.13,0.03,0.19,0.01,0.26-0.01c0.07-0.02,0.09-0.04,0.09-0.04
			l0.15,0.04c0,0-0.2-0.19-0.31-0.34c-0.06-0.07-0.09-0.11-0.13-0.16c-0.04-0.05-0.08-0.09-0.15-0.16
			c-0.13-0.13-0.19-0.19-0.22-0.26c-0.03-0.08-0.35-0.78-0.35-0.78s0.2,0.6,0.31,0.81c0.05,0.09,0.18,0.24,0.29,0.34
			c0.11,0.1-0.09,0.08-0.14,0.14c-0.06,0.06,0.09,0.14,0.15,0.18c0.06,0.04-0.2-0.07-0.3-0.13s-0.21-0.29-0.32-0.42
			c-0.1-0.13-0.27-0.34-0.28-0.36c-0.01-0.02,0.09,0.21,0.2,0.37c0.12,0.15,0.25,0.34,0.28,0.41c0.02,0.08-0.11,0.13-0.18,0.16
			c-0.07,0.03-0.13-0.06-0.2-0.18c-0.06-0.12-0.29-0.53-0.54-0.68c-0.25-0.15,0.04,0.07,0.06,0.15c0.02,0.08,0.28,0.27,0.37,0.42
			s0.12,0.2,0.07,0.22c-0.03,0.01-0.05,0.02-0.07,0.02c-0.01,0-0.01,0-0.01,0s0.13,0.1,0.22,0.12c0,0,0.01,0,0.01,0
			c0.09,0.02,0.14,0.08,0.19,0.1C241.26,326.91,241.33,326.89,241.42,326.87z"
          />
          <path
            fill="#151616"
            d="M239.51,323.42c0,0-0.16-0.27,1.15-0.5c-1.29-0.14-1.6,0.8-1.6,0.8c0.05,0.13,0.49,0.05,0.56,0.03
			c0.01,0,0.01,0,0.01,0L239.51,323.42z"
          />
          <path
            fill="#AD7D62"
            d="M241.28,323.32c0.01-0.02,0.01-0.04,0.02-0.06c0.14-0.47,0.21-0.95,0.2-1.44
			c-0.01-0.67-0.16-1.34-0.08-2.01c0.1-0.79,0.51-1.54,0.44-2.33c-0.05-0.52-0.31-0.99-0.39-1.5c-0.15-0.92,0.26-1.84,0.22-2.77
			c-0.01,0.02-0.01,0.04-0.02,0.06c-0.02,0.3-0.09,0.71-0.29,1.22c-0.29,0.76-0.35,1.13-0.5,1.8c0.02,0.27,0.05,0.61,0.08,1
			L241.28,323.32z"
          />
          <path
            fill="#D7BFB1"
            d="M240.66,322.92c0,0-0.05-0.06-0.09-0.27c-0.06-0.3-0.1-0.93,0.05-2.15c0.12-0.95-0.05-3.04,0.07-3.5
			c0.07-0.28,0.12-0.52,0.17-0.72c0.15-0.67,0.21-1.03,0.5-1.8c0.19-0.51,0.26-0.92,0.29-1.22c0.01-0.02,0.01-0.04,0.02-0.06
			c0.04,0.93-0.37,1.85-0.22,2.77c0.08,0.51,0.34,0.98,0.39,1.5c0.08,0.79-0.34,1.54-0.44,2.33c-0.08,0.67,0.07,1.34,0.08,2.01
			c0.01,0.49-0.06,0.97-0.2,1.44c0,0.04,0,0.07,0,0.11l-0.07,0.32c0.1,0.07,0.22,0.15,0.34,0.23c0.23,0.16,0.56,0.33,0.71,0.46
			c0.1,0.09,0.13,0.19,0.2,0.31c1.16,0.11,2.85,0.24,4.33,0.24c2.12-0.74,2.95-2.86,2.65-2.57c-0.29,0.29-1.25,1.08-0.52,0.17
			c0.57-0.7,0.87-2.08,0.97-2.38c0.01-0.18,0.02-0.37,0.04-0.57c-0.06-0.05-0.12-0.09-0.18-0.13c-0.07,0.08-0.19,0.13-0.33,0.1
			c-0.07-0.01-0.18-0.05-0.3-0.09l-0.37,1.04c-0.04,0.1-0.13,0.16-0.24,0.15l-3.4-0.62c-0.14-0.01-0.23-0.13-0.21-0.27l1.46-5.79
			c0.02-0.13,0.15-0.22,0.28-0.19l3.92,0.91c0.13,0.03,0.21,0.17,0.16,0.3l-0.84,2.33c0.27,0.12,0.5,0.22,0.5,0.22
			c0.19,0.1,0.23,0.22,0.25,0.31c0.03,0.09-0.02,0.23-0.1,0.35c-0.01,0.02-0.02,0.04-0.03,0.07c0.05,0.04,0.11,0.08,0.18,0.11
			c0.17,0,0.38,0.1,0.36,0.53c-0.02,0.43-0.25,1.04-0.82,0.82c-0.01,0-0.01-0.01-0.02-0.01c-0.05-0.01-0.11-0.03-0.16-0.06
			c0.1,0.19,0.23,0.35,0.39,0.43c2.36,1.15,3.18-0.63,2.35-5.08c-0.56-3.03-0.65-4.06-1.01-5.17l-0.01,0.02
			c-0.03-0.48-0.23-0.8-1.43-1.38c-0.64-0.31-1.2-0.54-1.69-0.76l-2.71,2.37l-3.21-2.72c-0.75,0.35-1.81,0.82-2.68,1.44
			c-0.27,0.2-0.43,0.44-0.51,0.72l-0.01-0.01c-0.07,0.51-0.38,1.31-0.99,3.24c-0.59,1.87-0.84,3.06-0.77,3.86
			c0.07,0.76,1.14,6.44,1.25,7.04c0.01,0.03,0.01,0.05,0.01,0.05S239.37,322.78,240.66,322.92z"
          />
          <path
            fill="#151616"
            d="M250.54,319.65c-0.12,0.08-0.22,0.09-0.31,0.08c0.01,0,0.01,0.01,0.02,0.01c0.57,0.22,0.81-0.4,0.82-0.82
			c0.02-0.43-0.19-0.52-0.36-0.53C250.93,318.5,250.95,319.36,250.54,319.65z"
          />
          <path
            fill="#151616"
            d="M248.17,319.03c-0.18-0.12-0.27-0.16-0.25-0.27c0.01-0.11,0.12-0.12,0.12-0.12s-0.15-0.04-0.28-0.11
			c-0.23-0.12-0.18-0.39,0.02-0.37c0.2,0.01,1.01,0.29,1.24,0.36c0.13,0.04,0.3,0.07,0.43,0.09l0.01-0.04
			c-0.03-0.01-0.07-0.02-0.11-0.03c-0.36-0.11-0.96-0.29-1.15-0.34c-0.26-0.08-0.49-0.19-0.43-0.34c0.06-0.15,0.26-0.15,0.64-0.06
			c0.38,0.09,1.19,0.25,1.19,0.25l0.09-0.15c0,0-0.34-0.21-0.63-0.38c-0.29-0.16-0.66-0.26-0.59-0.45s0.43-0.2,0.73-0.06
			c0.15,0.07,0.44,0.2,0.71,0.32l0.84-2.33c0.05-0.13-0.03-0.27-0.16-0.3l-3.92-0.91c-0.13-0.03-0.26,0.06-0.28,0.19l-1.46,5.79
			c-0.02,0.13,0.08,0.26,0.21,0.27l3.4,0.62c0.1,0.01,0.2-0.05,0.24-0.15l0.37-1.04c-0.14-0.05-0.3-0.11-0.39-0.16
			C248.57,319.21,248.35,319.15,248.17,319.03z"
          />
          <path
            fill="#4D2C1A"
            d="M249.82,317.99c-0.1-0.07-0.13-0.09-0.13-0.09l-0.09,0.15C249.87,318.09,249.95,318.08,249.82,317.99z"
          />
          <path
            fill="#151616"
            d="M248.66,318.93c0.14,0.04,0.47,0.16,0.64,0.21c0.18,0.05,0.16,0.13,0.16,0.11c0-0.03-0.06-0.11-0.18-0.15
			c-0.12-0.04-0.54-0.17-0.72-0.25c-0.18-0.08-0.53-0.2-0.53-0.2l-0.01,0.01C248.02,318.65,248.52,318.89,248.66,318.93z"
          />
          <path
            fill="#AD7D62"
            d="M250.54,319.65c0.41-0.28,0.39-1.15,0.17-1.26c-0.07-0.04-0.13-0.07-0.18-0.11
			c0.01-0.02,0.02-0.05,0.03-0.07c0.08-0.12,0.13-0.25,0.1-0.35c-0.03-0.09-0.06-0.22-0.25-0.31c0,0-0.23-0.1-0.5-0.22
			c-0.26-0.12-0.56-0.25-0.71-0.32c-0.3-0.14-0.65-0.13-0.73,0.06s0.3,0.29,0.59,0.45c0.29,0.16,0.63,0.38,0.63,0.38
			s0.03,0.02,0.13,0.09c0.13,0.09,0.05,0.1-0.21,0.06c0,0-0.81-0.16-1.19-0.25c-0.38-0.09-0.58-0.09-0.64,0.06
			c-0.06,0.15,0.16,0.26,0.43,0.34c0.19,0.05,0.79,0.23,1.15,0.34c0.04,0.01,0.08,0.02,0.11,0.03c0.08,0.02,0.13,0.04,0.13,0.04
			c0.03,0.02-0.04,0.01-0.14,0c-0.13-0.02-0.3-0.05-0.43-0.09c-0.23-0.07-1.04-0.35-1.24-0.36c-0.2-0.01-0.25,0.25-0.02,0.37
			c0.13,0.07,0.28,0.11,0.28,0.11s0.34,0.12,0.53,0.2c0.18,0.08,0.6,0.21,0.72,0.25c0.12,0.04,0.19,0.12,0.18,0.15
			c0,0.03,0.01-0.05-0.16-0.11c-0.18-0.05-0.51-0.17-0.64-0.21c-0.14-0.04-0.63-0.28-0.63-0.28l0.01-0.01c0,0-0.11,0.01-0.12,0.12
			c-0.01,0.11,0.07,0.15,0.25,0.27c0.18,0.12,0.41,0.19,0.58,0.28c0.1,0.05,0.25,0.11,0.39,0.16c0.12,0.04,0.23,0.07,0.3,0.09
			c0.14,0.03,0.26-0.03,0.33-0.1c0.06,0.04,0.12,0.08,0.18,0.13c0.04,0.03,0.08,0.06,0.13,0.09c0.05,0.03,0.1,0.05,0.16,0.06
			C250.32,319.74,250.42,319.73,250.54,319.65z"
          />
          <polygon
            fill="#1EBEE4"
            points="248.67,307.72 248.7,307.69 248.04,307.36 245.88,309.3 243.24,307.14 243.21,307.11 
			242.69,307.33 242.74,307.38 245.96,310.09 		"
          />
          <path
            fill="#AD7D62"
            d="M249.46,322.36c0.29-0.29-0.53,1.82-2.65,2.57c-0.01,0-0.01,0.01-0.02,0.01c0.57,0,2.97,0.05,3.28-0.71
			c0.02-0.05,0.03-0.11,0.03-0.17c0-0.91-0.17-2.94-0.15-3.83c0-0.15-0.01-0.16-0.04-0.07c-0.1,0.3-0.4,1.68-0.97,2.38
			C248.21,323.43,249.16,322.65,249.46,322.36z"
          />
          <path
            fill="#4D2C1A"
            d="M246.75,305.41c0.32-0.04,0.43-0.22,0.43-0.22s0.01-0.01-0.2-0.05c-0.21-0.04-0.2,0.01-0.43-0.03
			c-0.24-0.04-0.3,0-0.62,0.06C245.92,305.17,246.43,305.46,246.75,305.41z"
          />
          <path
            fill="#151616"
            d="M246.06,302.95c-0.21-0.02-0.54,0-0.66,0.02c-0.12,0.02-0.22,0.2-0.22,0.2s0.13-0.1,0.34-0.1
			c0.29,0,0.35-0.04,0.5-0.02c0.15,0.02,0.22,0.09,0.26,0.08C246.33,303.13,246.27,302.97,246.06,302.95z"
          />
          <path
            fill="#151616"
            d="M247.52,302.99c-0.17,0.01-0.3-0.01-0.38,0.02c-0.08,0.02-0.25,0.12-0.25,0.12s0,0,0.01,0
			c-0.12,0.06-0.23,0.18-0.23,0.41c0,0.2,0.11,0.46,0.15,0.63c0.04,0.17-0.12,0.15-0.21,0.27s0.06,0.21,0.06,0.21
			s-0.15-0.18,0.06-0.21c0.02,0,0.03-0.01,0.05-0.01c0.01,0.01,0.03,0.02,0.06,0.02c0.04-0.01,0.13-0.01,0.1-0.02
			c-0.02-0.01-0.05-0.02-0.09-0.03c0.09-0.06,0.07-0.15,0.05-0.24c-0.03-0.11-0.07-0.29-0.11-0.53c-0.05-0.28,0.13-0.41,0.28-0.45
			l0,0c0.06-0.01,0.28-0.03,0.49-0.01c0.12,0.01,0.15,0.03,0.16,0.04c0,0,0,0,0,0.01c0.02-0.02,0.06-0.09,0.01-0.15
			c0,0,0-0.01-0.01-0.01C247.66,302.97,247.69,302.99,247.52,302.99z"
          />
          <polygon fill="#151616" points="247.7,303.22 247.71,303.21 247.71,303.21 247.71,303.21 		" />
          <path
            fill="#151616"
            d="M245.91,303.53c-0.2,0.02-0.4-0.02-0.52-0.01c-0.09,0,0.14,0.04,0.27,0.08c0.19,0.06,0.23,0.03,0.41-0.03
			c0.17-0.05,0.2-0.06,0.2-0.06S246.14,303.51,245.91,303.53z"
          />
          <path
            fill="#151616"
            d="M247.22,303.67c0.08,0.01,0.14,0.01,0.21-0.04c0.08-0.04,0.14-0.08,0.14-0.08
			c-0.07,0.01-0.1,0.02-0.17,0.04c-0.06,0.01-0.17,0.02-0.22,0.01c-0.12-0.02-0.35-0.01-0.23,0
			C247.02,303.6,247.14,303.65,247.22,303.67z"
          />
          <path
            fill="#151616"
            d="M246.43,304.41c-0.02-0.01-0.06-0.01-0.15,0.03c0,0,0.04,0.01,0.11,0.01
			C246.46,304.44,246.46,304.42,246.43,304.41z"
          />
        </g>
        <path fill="#AD7D62" d="M256.69,366.2c0-0.06,0.01-0.11,0.01-0.15C256.69,366.09,256.69,366.15,256.69,366.2z" />
        <path
          fill="#AD7D62"
          d="M256.6,367.37c0,0.04,0.01,0.06,0.02,0.09c-0.01-0.06-0.01-0.14-0.02-0.25
		C256.6,367.26,256.6,367.32,256.6,367.37z"
        />
        <path
          opacity="0.3"
          d="M375.47,422.43c-4.83-0.69-45.76-6.85-69.37-10.32c-6.68-0.98-11.31-1.58-14.53-1.72
		c-0.35,0.35-0.84,0.59-1.61,0.62c-1.14,0.04-3.58-0.1-3.58-0.1s9.58,2.95,2.82,3.2c-3.28,0.12-14-1.02-20.05-1.44
		c-2.26-0.16-7.61-0.45-8.79,0.04c-2.04,0.84-1.55,0.83,29.13,6.04c23.01,3.91,62.5,11.81,77.7,12.59
		C383.71,432.16,389.58,424.44,375.47,422.43z"
        />
        <path
          opacity="0.3"
          d="M347.33,397.15c-3.57-0.3-19.38-1.98-26.5-2.41c0.39,0.32,0.85,0.66,1.28,0.9c1.09,0.6,1.13,1.05,0.97,1.49
		c-0.16,0.44-1.29,0.64-3.02,0.24c-1.73-0.4-1.81-0.97-2.3-0.93c-0.48,0.04-1.61-0.36-1.89-1.01c0,0,0-0.03-0.01-0.08
		c-0.5,0.31-0.95,0.62-1.43,0.71c-0.46,0.08-0.89,0.14-1.3,0.18c-0.06,0.5-0.35,0.96-1.18,1.01c-1.28,0.08-2.11,0.26-2.64-0.29
		c-1.85,2.29,41.75,6.7,56.95,8.53C395.2,408.97,417.74,403.13,347.33,397.15z"
        />
        <path
          fill="#AD7D62"
          d="M258.39,402.54c-0.22,0.86-0.59,2.01-1.14,2.83c0.03-0.01,0.05-0.02,0.05-0.02s-0.05,3.2,3.36,1.43
		c0.22-0.93,0.59-2.43,1.03-3.66C260.66,403.21,260.16,401.49,258.39,402.54z"
        />
        <path
          fill="#AD7D62"
          d="M291.42,400.98c-0.58-0.66-1.63-2.03-2.43-3.95c-0.18,0.52-1.51,0.01-1.99,0.46
		c-0.49,0.46-0.13,1.88-0.7,2.23l2.87,4.38C292.23,405.02,291.4,401.3,291.42,400.98z"
        />
        <path
          fill="#D7BFB1"
          d="M285.37,388.34c0.16,0.32,0.27,0.51,0.27,0.51s-3.31-7-3.92-12.26c-0.01-0.09-0.02-0.17-0.03-0.26
		c-0.01-0.1-0.02-0.19-0.03-0.29c0-0.02,0-0.05-0.01-0.07c-0.01-0.17-0.02-0.34-0.03-0.5c-0.01-0.14-0.01-0.16-0.02-0.1
		c-0.24-3.18-0.1-5.99-0.14-7.37c-0.08-3.09-0.15-4.92-0.54-6.38c0.36-0.27,0.67-0.56,0.87-0.83c0,0-0.62-2.09-1.28-4.54
		c-0.48,0.09-0.92,0.17-1.3,0.25l0.01,0.03l0.04,0.08c0,0-0.01,0-0.01,0l0.18,0.5l-7.23,3.39c0,0-4.93,2.88-5.94,2.41
		c-1.16,3.27-2.57,7.4-3.77,11.34c-3.21,10.51-5.84,27.59-5.84,27.59s0.75,0.37,1.76,0.71c1.01,0.35,2.27,0.67,3.31,0.58
		c0.31-0.03,0.59-0.09,0.85-0.2c0.49-3.06,2.97-6.46,5-13.77c0.42-1.52,0.81-2.74,1.22-3.88c-0.13,0.69-0.22,1.07-0.22,1.07
		c1.77-4.99,2.99-7.36,2.99-7.36s-0.02-0.13-0.07-0.4c0-0.01,0.01-0.01,0.01-0.02c1.56,3.89,3.5,7.61,4.56,10.07
		c1.43,3.33,3.47,5.27,5.49,8.59c0.51,0.84,0.27,0.45,2.39,3.71c0,0,1.15-0.47,2.37-1.22c1.13-0.7,2.32-1.64,2.69-2.69
		c0.06-0.16,0.09-0.31,0.11-0.47C289.1,396.55,287.14,392.37,285.37,388.34z"
        />
        <path fill="none" d="M264.93,337l0.04,0.25C264.95,337.09,264.93,337,264.93,337z" />
        <path
          fill="none"
          d="M269.62,329.45c-0.25-0.13-0.52-0.14-0.73-0.09C269.09,329.31,269.35,329.32,269.62,329.45z"
        />
        <path
          fill="none"
          d="M278.54,346.34c0,0.39,0.44,3.42,0.44,3.42c0.12,0.79,0.36,1.9,0.66,3.12l2.04-1.2c-1.19-2.26-1.4-2.7-1.4-2.7
		c0.01,0,0.02,0,0.03-0.01c0.06-0.01,0.19-0.04,0.36-0.09c-0.3,0.08-0.62,0.15-0.96,0.21c0,0-0.01-0.01-0.01-0.01
		c0.03,0.08,0.05,0.12,0.05,0.12C279.25,348.69,278.54,346.34,278.54,346.34z"
        />
        <path
          fill="#AD7D62"
          d="M273.74,331.99c0.09,0.26,0.18,0.5,0.28,0.73c1.09,2.44,3.58,1.09,3.58,1.09s-1.87-1.45-0.99-4.36
		c0.88-2.9,0.55-6.15-1.45-6.85c-1.09-0.38-2.03-0.16-3.12,0.3c-0.61-0.16-1.28-0.2-1.98-0.04c-0.02,0-0.03,0-0.05,0.01
		c-0.01,0-0.01,0-0.02,0c-0.31,0.04-0.63,0.13-0.96,0.26c-1.12,0.34-1.67,0.66-1.67,0.66s-1.72,0.87-1.9,2.9
		c-0.15,1.68,0.49,1.97,0.72,2.02c0.05,0.15,0.11,0.28,0.16,0.39c0.05,0.11,0.08,0.23,0.1,0.36c-0.02,0.13-0.02,0.19-0.01,0.25
		c0-0.01-0.01-0.02-0.01-0.03l0.01,0.03c0,0,0,0.01,0,0.01c0.01,0.08-0.07,0.15-0.28,0.13c-0.26-0.02,0.03,0.02,0.16,0.05
		c0.06,0.01,0.11,0.04,0.16,0.05c0,0.06,0,0.12,0,0.17c-0.01,0.22-0.03,0.46-0.04,0.7c-0.02,0.26-0.04,0.56,0.05,0.88c0,0,0,0,0,0
		c0.06,0.34,0.19,0.69,0.42,1.02c0.06,0.08,0.11,0.17,0.16,0.25c0.01,0.04,0.03,0.11,0.05,0.15c0.03,0.06,0.06,0.12,0.07,0.16
		c0,0.03,0.01,0.16,0.13,0.23c0.34,0.9,0.38,1.65,1.66,1.44c-0.23,0.21-0.59,0.46-1.14,0.74c-0.01,0.01-0.03,0.02-0.04,0.02
		c-0.02,0.57,0.07,2.8,2.46,2.34c2.47-0.47,4.19-2.52,4.51-2.93c-0.07-0.03-0.14-0.06-0.22-0.09c-1.02-1.04-1.14-1.98-0.93-2.84
		C273.65,332.16,273.69,332.08,273.74,331.99z"
        />
        <path
          fill="#AD7D62"
          d="M270.03,374.46c-0.07-0.25-0.14-0.51-0.19-0.77c-0.03,5.36-0.71,9.67-1.07,11.58
		c-0.13,0.69-0.22,1.07-0.22,1.07c1.77-4.99,2.99-7.36,2.99-7.36s-0.02-0.13-0.07-0.4c0-0.03-0.01-0.06-0.02-0.08
		C270.92,377.15,270.42,375.79,270.03,374.46z"
        />
        <path
          fill="#AD7D62"
          d="M266.82,352.2c0.14,0.86,0.24,1.63,0.24,1.63s-0.59-1.49-0.96-2.98c-0.51-2.04-1.37-2.77-1.87-3.25
		c0.16,0.07,0.32,0.12,0.48,0.17c-0.31-0.11-0.58-0.24-0.81-0.36c0.14,1.07,0.27,2.27,0.27,2.99c0,0.39-0.02,0.71-0.07,1.01
		c-0.14,0.87-0.52,1.57-1.39,3.48c-0.13,0.29-0.26,0.61-0.38,0.94c-0.66,1.77-1.27,4.05-2.86,6.77c0,0,0,0,0,0l-0.19,0.2
		c-0.13,0.14-0.31,0.33-0.48,0.48c-0.1,0.08-0.23,0.17-0.39,0.25c-0.18,0.09-0.33,0.16-0.51,0.25c-0.11,0.06-0.23,0.12-0.35,0.21
		c0,0,0,0-0.01,0c-0.18,0.13-0.27,0.23-0.36,0.34c-0.26,0.25-0.41,0.38-0.51,0.45c-0.02,0.01-0.04,0.04-0.06,0.07
		c-0.09,0.1-0.09,0.17-0.16,0.27c-0.11,0.16-0.19,0.38-0.38,0.56c-0.04,0.03-0.06,0.06-0.08,0.09c-0.06,0.09-0.04,0.13-0.03,0.14
		c0.02,0.11,0.14,0.19,0.28,0.18c0.13-0.01,0.3-0.08,0.46-0.17c0.14-0.08,0.28-0.18,0.37-0.26c0.18-0.18,0.24-0.32,0.41-0.38
		c0,0,0,0,0,0.01c0.21-0.2,0.32-0.28,0.88-0.31c0.7-0.03,1.15-0.29,1.51-0.7c0,0-0.41,0.8-1.23,1.12c-0.93,0.37-1.2,0.22-1.4,0.42
		c-0.04,0.08-0.09,0.15-0.14,0.23c-0.07,0.1-0.14,0.23-0.19,0.37l0,0.01c-0.01,0.03-0.02,0.06-0.03,0.09c0-0.01,0-0.01,0-0.02
		c-0.01,0.03-0.02,0.06-0.03,0.09c0,0,0,0,0,0c0,0.15-0.16,0.61,0.01,0.73c0.17,0.13,0.11,0.17,0.38,0.29
		c0.18,0.08,0.34,0.12,0.49,0.09c0.09-0.03,0.16-0.06,0.19-0.08c0.02-0.01,0.04-0.03,0.06-0.04l0.31,0.27l0.03,0.02
		c0,0,0.2-0.04,0.46-0.14c0.26-0.1,0.5-0.32,0.73-0.49c0.23-0.17,0.21-0.08,0.21-0.08s-0.01,0.06-0.13,0.16
		c-0.12,0.1-0.71,0.53-0.71,0.53s0.07,0.28,0.24,0.25c0.16-0.03,0.35-0.08,0.54-0.22s0.52-0.53,0.52-0.53l-0.01-0.15
		c0,0,0.08-0.26,0.16-0.44c0.08-0.19,0.35-0.69,0.44-1.25c0.09-0.52,0.33-1.36,0.53-1.7c0.91-1,2.65-3.03,4.11-5.44
		c0.56-0.93,0.97-1.68,1.27-2.3c0.23-0.48,0.39-0.89,0.5-1.25C267.09,353.89,266.97,353,266.82,352.2z M257.43,367.3
		C257.43,367.31,257.43,367.31,257.43,367.3c0-0.13,0.01-0.28,0.01-0.28c0.02-0.08,0.05-0.16,0.11-0.21
		c0.15-0.12,0.3-0.08,0.66-0.29c0.36-0.21,0.48-0.29,0.67-0.14c0,0-0.43,0.01-0.66,0.29C257.99,366.95,257.51,367.12,257.43,367.3z
		 M259.57,366.84c-0.05-0.05-0.18-0.14-0.48,0.02c-0.4,0.22-0.54,0.42-0.79,0.49c-0.02,0-0.03,0.01-0.05,0.01
		c0.21-0.17,0.41-0.36,0.54-0.52c0.09-0.06,0.2-0.12,0.3-0.15C259.31,366.63,259.47,366.7,259.57,366.84
		c0.02,0.02,0.02,0.03,0.02,0.03C259.59,366.86,259.58,366.85,259.57,366.84z"
        />
        <path
          fill="#4D2C1A"
          d="M257.31,405.34c0,0-0.02,0.01-0.05,0.02c-0.29,0.12-1.82,0.72-2.66,0.77c-0.88,0.05-2.76,0.03-4.33-0.18
		c-1.48-0.2-1.15,1.56-1.15,1.56c0.57,0.3,0.8,0.37,2.83,1.01c1.76,0.56,6.48,1.88,7.68,2.22c0.16,0.05,0.34-0.03,0.42-0.18
		c0.87-1.75,0.74-3.79,0.74-3.79c-0.04,0-0.07,0-0.11,0C258.13,406.78,257.31,405.34,257.31,405.34z"
        />
        <path
          fill="#4D2C1A"
          d="M294.37,403.83c-0.86-1.05-1.25-1.71-2.95-2.9c0,0,0,0.02,0,0.05c-0.01,0.32-0.19,2.29-2.25,3.11
		c-0.02,0.01-0.03,0.01-0.05,0.02c0,0,0.94,1.67-0.64,4.17c-0.99,1.57-2.63,0.17-2.1,2.62c0,0,2.44,0.14,3.58,0.1
		c0.76-0.03,1.26-0.27,1.61-0.62c0.46-0.46,0.67-1.12,0.9-1.75C293.25,406.46,294.8,404.35,294.37,403.83z"
        />
        <path fill="#AD7D62" d="M259.32,368.22c0,0-0.01,0-0.02,0.01C259.31,368.22,259.32,368.22,259.32,368.22z" />
        <path fill="#AD7D62" d="M258.29,368.23c0-0.04-0.02-0.11-0.04-0.16C258.26,368.13,258.27,368.19,258.29,368.23z" />
        <path
          fill="#AD7D62"
          d="M257.6,367.81c0.03,0,0.06-0.01,0.1-0.03c0.06-0.03,0.12-0.07,0.19-0.12c-0.06,0.03-0.12,0.06-0.18,0.07
		c-0.07,0.03-0.14,0.05-0.21,0.07C257.52,367.8,257.56,367.81,257.6,367.81c0.09,0.09,0.23,0.15,0.32,0.21c0,0,0.01,0,0.01,0
		c-0.03-0.02-0.06-0.04-0.1-0.06C257.77,367.91,257.67,367.87,257.6,367.81z"
        />
        <path
          fill="#AD7D62"
          d="M258.04,368.03c0.06-0.02,0.11-0.05,0.15-0.08c0,0,0,0,0,0S258.12,367.99,258.04,368.03z"
        />
        <path
          fill="#AD7D62"
          d="M259.69,367.97c0,0,0.1,0.04,0.18,0.15c0.07,0.11,0.07,0.2,0.24,0.21c0.14-0.03,0.28-0.34,0.38-0.46
		c0.07-0.08,0.11-0.22,0.09-0.34c0.02,0.03,0.03,0.05,0.03,0.05s0.07-0.09-0.1-0.21c-0.05-0.05-0.11-0.06-0.17-0.05
		c-0.11,0-0.19,0.06-0.19,0.06s-0.02,0.1-0.12,0.2C259.93,367.69,259.69,367.97,259.69,367.97z"
        />
        <path
          fill="#AD7D62"
          d="M259.57,368.37c0.03,0,0.05,0,0.08,0c0.01,0,0.01,0,0.02,0c-0.03,0-0.06,0-0.09,0.01
		C259.58,368.37,259.58,368.37,259.57,368.37z"
        />
        <path fill="#4D2C1A" d="M259.6,366.87c0,0-0.01-0.02-0.02-0.03C259.58,366.85,259.59,366.86,259.6,366.87z" />
        <path
          fill="#4D2C1A"
          d="M259.1,366.69c-0.1,0.03-0.21,0.09-0.3,0.15c-0.14,0.16-0.34,0.35-0.54,0.52c0.02,0,0.03-0.01,0.05-0.01
		c0.25-0.06,0.39-0.27,0.79-0.49c0.3-0.17,0.43-0.08,0.48-0.02C259.47,366.7,259.31,366.63,259.1,366.69z"
        />
        <path
          fill="#4D2C1A"
          d="M259.62,367.91c-0.19,0.14-0.38,0.19-0.54,0.22c-0.16,0.03-0.24-0.25-0.24-0.25s0.59-0.43,0.71-0.53
		c0.12-0.1,0.13-0.16,0.13-0.16s0.02-0.1-0.21,0.08c-0.23,0.17-0.47,0.39-0.73,0.49c-0.26,0.1-0.46,0.14-0.46,0.14l-0.03-0.02
		l-0.31-0.27c-0.02,0.01-0.04,0.03-0.06,0.04c-0.03,0.01-0.11,0.04-0.19,0.08c0.06-0.01,0.12-0.03,0.18-0.07
		c-0.07,0.05-0.14,0.09-0.19,0.12c-0.04,0.02-0.07,0.03-0.1,0.03c0.08,0.07,0.18,0.11,0.23,0.15c0.04,0.03,0.07,0.05,0.1,0.06
		c0.01,0.01,0.03,0.01,0.04,0.01c0,0,0.01,0,0.01,0c0.01,0,0.02,0,0.04,0c0.01,0,0.02,0,0.03-0.01c0,0,0,0,0,0
		c0.08-0.03,0.14-0.08,0.14-0.08s0,0,0,0c0.01,0.01,0.03,0.06,0.06,0.13c0.02,0.06,0.05,0.12,0.04,0.16
		c-0.01,0.09,0.19,0.16,0.33,0.16c0.14,0,0.35-0.03,0.45-0.08c0.07-0.04,0.17-0.07,0.22-0.09c0.02-0.01,0.02-0.01,0.02-0.01
		s0.1,0.16,0.25,0.15c0,0,0.01,0,0.01,0c0.03,0,0.06-0.01,0.09-0.01c0.12-0.01,0.21-0.01,0.21-0.01l0.22-0.02
		c-0.17-0.01-0.17-0.1-0.24-0.21c-0.07-0.11-0.18-0.15-0.18-0.15s0.24-0.28,0.34-0.39c0.1-0.11,0.12-0.2,0.12-0.2
		S259.81,367.76,259.62,367.91z"
        />
        <path
          fill="#FFFFFF"
          d="M283.49,346.94c0,0.01-0.01,0.02-0.02,0.04c-0.01,0.03-0.03,0.06-0.04,0.1c-0.01,0.03-0.03,0.07-0.05,0.11
		C283.43,347.11,283.46,347.02,283.49,346.94z"
        />
        <path
          fill="#FFFFFF"
          d="M278.25,351.81c-0.23,0.17-0.41,0.33-0.54,0.46l0.2,0.55C278.41,352.01,278.65,351.52,278.25,351.81z"
        />
        <path
          fill="#FFFFFF"
          d="M278.13,362.99c0.16-0.21,0.41-0.52,0.76-0.98c1.12-1.43,1.51-2.47,1.43-3.59
		c-0.01-0.19-0.04-0.43-0.08-0.7l-7.59,3.81l-0.47-1.05l-0.01,0l-0.34-0.77c-0.15,0.06-0.34,0.13-0.52,0.13
		c-0.23,0-0.36-0.12-0.42-0.2c-0.06-0.03-0.12-0.08-0.17-0.16c-0.1-0.18-0.07-0.33-0.03-0.42c0,0-0.01,0.01-0.01,0.01l0.06-0.11
		c-0.09-0.01-0.61-0.05-0.76-0.27c-0.16-0.24,0.12-0.47,0.12-0.47s0,0,0,0c0,0,0,0,0,0l-0.01,0c-0.06-0.01-0.34-0.04-0.49-0.29
		c-0.12-0.2-0.02-0.42,0.05-0.53c0,0,0,0,0,0l0.01-0.01c0.03-0.04,0.05-0.07,0.05-0.07s-0.31-0.11-0.42-0.34s0.12-0.71,0.58-1.01
		c0.08-0.05,0.17-0.11,0.25-0.16l-2.91-6.62l-0.14-0.27c-0.04-0.1,0.01-0.21,0.1-0.25l8.13-3.23l0.03,0.1
		c-1.53,0.62-7.62,3.08-7.91,3.2c-0.28,0.12-0.14,0.33,0.1,0.23c0.21-0.08,6.3-2.56,7.89-3.21l2.26,6.41
		c0.1-0.17,0.25-0.41,0.45-0.76c0.96-1.67-0.04-2.25-0.2-4.24c-0.16-1.99-0.24-4.15,0.16-2.63c0.35,1.34,1.38,3.95,1.62,4.53
		c0,0,0.01,0.01,0.01,0.01c0.34-0.06,0.66-0.13,0.96-0.21c0.73-0.21,2.26-0.76,2.76-1.81c0.02-0.03,0.03-0.07,0.04-0.1
		c0.01-0.02,0.02-0.04,0.03-0.07c0.15-0.42,0.1-0.73,0.1-0.73s0,0.01,0,0.01c-0.02-0.19-0.05-0.39-0.11-0.6
		c-1.92-4.04-1.7-7.2-4.31-8.38c-2.61-1.17-4.37-2.13-4.37-2.13s-0.02,0.03-0.05,0.07c-0.32,0.41-2.04,2.46-4.51,2.93
		c-2.39,0.46-2.47-1.77-2.46-2.34c0-0.08,0.01-0.13,0.01-0.13l-2.87,1.4l0,0c-2.06,0.52-1.18,7.29-1.76,9.91c0,0,0.26,0.24,0.73,0.5
		c0.22,0.13,0.49,0.25,0.81,0.36c0.05,0.02,0.1,0.03,0.15,0.05c0.55-0.76,0.74-3.1,0.74-3.1c-0.08,1.91,0.29,3.32,0.29,3.32
		s0.25,1.28,0.57,2.4c0.14,0.47,0.26,1.14,0.37,1.77c0.15,0.8,0.27,1.69,0.33,2.64c0.02,0.41,0.03,0.84,0.02,1.28
		c-0.01,0.61-0.05,1.23-0.14,1.87c-0.57,4.1-1.1,4.64-0.96,4.81c0,0,0.06,0.03,0.18,0.09C267.25,363.36,272.49,365.52,278.13,362.99
		z"
        />
        <path
          fill="#D7BFB1"
          d="M271.5,359.6c-0.18,0.03-0.42,0.12-0.6,0.05c0.06,0.08,0.19,0.2,0.42,0.2c0.18,0,0.37-0.06,0.52-0.13
		l-0.1-0.22C271.66,359.55,271.59,359.59,271.5,359.6z"
        />
        <path
          fill="#D7BFB1"
          d="M278.25,351.81c0.39-0.29,0.16,0.2-0.34,1.02l0.29,0.84l0.75-0.37l0.68-0.4c-0.3-1.22-0.54-2.34-0.66-3.12
		c0,0-0.44-3.03-0.44-3.42c0,0,0.71,2.35,1.22,2.86c0,0-0.02-0.04-0.05-0.12c-0.23-0.58-1.26-3.19-1.62-4.53
		c-0.4-1.51-0.32,0.64-0.16,2.63c0.16,1.99,1.16,2.57,0.2,4.24c-0.2,0.35-0.35,0.59-0.45,0.76l0.03,0.08
		C277.84,352.14,278.02,351.98,278.25,351.81z"
        />
        <path
          fill="#AD7D62"
          d="M270.07,322.85c-0.33,0.04-0.67,0.13-1.03,0.27c0.29-0.09,0.61-0.18,0.98-0.26
		C270.04,322.85,270.05,322.85,270.07,322.85z"
        />
        <path
          fill="#AD7D62"
          d="M266.47,330.13c-0.01,0.22-0.03,0.46-0.04,0.7c0.01-0.15,0.03-0.28,0.03-0.41
		C266.46,330.34,266.47,330.24,266.47,330.13z"
        />
        <path
          fill="#4D2C1A"
          d="M266.34,325.66c0,0-0.25,1.86,0.84,3.01c0,0,0.59,0.12,1.3,0.2c-0.18,0.1-0.19,0.24,0,0.26
		c0,0,0.22-0.11,0.57-0.21c0.32,0.02,0.63,0.02,0.9-0.01c0.02,0,0.04,0.01,0.04,0.01s0,0-0.01-0.01c0.17-0.02,0.32-0.05,0.45-0.09
		c0,0,0.16-0.31,0.21-0.66c0.12,0.44,0.32,1.02,0.58,1.47c0.57,0.95,0.65-1.11,1.45-1.27c0.8-0.16,1.17,0.25,0.7,1.56
		c-0.45,1.24,0.18,2.42,0.18,2.42s0.02-0.04,0.06-0.11c0.03-0.06,0.07-0.14,0.12-0.24c0.09,0.26,0.18,0.5,0.28,0.73
		c1.09,2.44,3.58,1.09,3.58,1.09s-1.87-1.45-0.99-4.36c0.88-2.9,0.55-6.15-1.45-6.85c-1.09-0.38-2.03-0.16-3.12,0.3
		c-0.61-0.16-1.28-0.2-1.98-0.04c-0.02,0-0.03,0-0.05,0.01c-0.37,0.08-0.69,0.17-0.98,0.26c-1.12,0.34-1.67,0.66-1.67,0.66
		s-1.72,0.87-1.9,2.9c-0.15,1.68,0.49,1.97,0.72,2.02c0.05,0.01,0.08,0.01,0.08,0.01S265.86,326.37,266.34,325.66z"
        />
        <path
          fill="#4D2C1A"
          d="M258.88,366.39c-0.19-0.15-0.32-0.07-0.67,0.14c-0.36,0.21-0.51,0.17-0.66,0.29
		c-0.06,0.05-0.09,0.13-0.11,0.21c0,0-0.01,0.15-0.01,0.29c0,0,0,0,0.01-0.01c0.08-0.18,0.56-0.35,0.79-0.63
		C258.46,366.4,258.88,366.39,258.88,366.39z"
        />
        <path
          fill="#4D2C1A"
          d="M257.21,367.63c-0.27-0.12-0.2-0.16-0.38-0.29c-0.17-0.13-0.01-0.58-0.01-0.73c0,0,0,0,0,0
		c0.01-0.03,0.02-0.06,0.03-0.09c0,0.01,0,0.01,0,0.02c0.01-0.03,0.02-0.06,0.03-0.09l0-0.01c0.06-0.14,0.12-0.27,0.19-0.37
		c0.05-0.07,0.1-0.15,0.14-0.23c0.2-0.21,0.47-0.05,1.4-0.42c0.83-0.33,1.23-1.12,1.23-1.12c-0.35,0.4-0.81,0.67-1.51,0.7
		c-0.56,0.02-0.67,0.11-0.88,0.31c0,0,0-0.01,0-0.01c-0.17,0.06-0.22,0.2-0.41,0.38c-0.09,0.08-0.22,0.18-0.37,0.26
		c0,0.03,0,0.06,0,0.1c0,0.04-0.01,0.1-0.01,0.15c-0.01,0.18-0.03,0.41-0.06,0.62c-0.02,0.15-0.02,0.28-0.02,0.39
		c0,0.11,0.01,0.19,0.02,0.25c0.01,0.06,0.01,0.09,0.01,0.09s0.05,0.04,0.29,0.13c0.24,0.09,0.26,0.15,0.43,0.14
		c0.04,0,0.08-0.01,0.13-0.03c0.07-0.02,0.14-0.04,0.21-0.07C257.55,367.75,257.4,367.71,257.21,367.63z"
        />
        <path
          fill="#4D2C1A"
          d="M270.06,335.1c1.38-0.72,1.44-1.87,1.44-1.87s-0.43,1.02-0.94,1.27c-0.51,0.25-1.48,0.44-1.48,0.44
		l-1.07,0.73C268.02,335.67,268.68,335.82,270.06,335.1z"
        />
        <path
          fill="#D7BFB1"
          d="M265.6,344.71c0,0-0.19,2.35-0.74,3.1c0.55,0.16,1.02,0.21,1.02,0.21S265.51,346.62,265.6,344.71z"
        />
        <path
          fill="#4D2C1A"
          d="M266.1,350.85c0.37,1.49,0.96,2.98,0.96,2.98s-0.09-0.77-0.24-1.63c-0.1-0.62-0.23-1.3-0.37-1.77
		c-0.33-1.12-0.57-2.4-0.57-2.4s-0.47-0.05-1.02-0.21c-0.05-0.01-0.1-0.03-0.15-0.05c-0.16-0.05-0.32-0.11-0.48-0.17
		C264.73,348.08,265.59,348.81,266.1,350.85z"
        />
        <path
          fill="#1EBEE4"
          d="M271.49,354.98L271.49,354.98c0,0,0.1-0.11-0.2-0.19c-0.3-0.08-0.73-0.33-1.03-0.43
		c-0.06-0.01-0.12-0.04-0.16-0.06c-0.03-0.02-0.05-0.02-0.04-0.03c-0.26-0.19-0.13-0.56-0.01-0.56c0.21,0.01,0.25,0.09,0.86,0.19
		c0.61,0.1,0.65,0.04,1.17,0.16c0.57,0.13,0.48,0.05,1.02,0.26c0.5,0.2,0.72,0.1,1.4,0.56c0.22,0.15,1.64-0.26,1.9-0.34l1.8-0.88
		l-0.29-0.84l-0.2-0.55l-0.03-0.08l-2.26-6.41c-1.59,0.64-7.68,3.12-7.89,3.21c-0.24,0.1-0.38-0.11-0.1-0.23
		c0.29-0.12,6.38-2.58,7.91-3.2l-0.03-0.1l-8.13,3.23c-0.1,0.04-0.14,0.15-0.1,0.25l0.14,0.27l2.91,6.62
		c0.38-0.24,0.77-0.47,1.13-0.67C271.38,355.08,271.45,355.03,271.49,354.98z"
        />
        <polygon fill="#1EBEE4" points="279.21,356.52 279.2,356.48 279.2,356.49 	" />
        <path
          fill="#1EBEE4"
          d="M274.16,358.45c-0.57,0.41-0.68,0.53-1.34,0.89s-0.96,0.41-0.96,0.41l-0.11-0.24c0,0,0.01-0.01,0.02-0.03
		c-0.01,0.01-0.02,0.01-0.03,0.02l0.1,0.22l0.34,0.77l0.01,0l7.23-3.39l-0.18-0.5c-0.08,0.03-2.33,0.8-3.15,0.91
		C275.25,357.61,274.73,358.04,274.16,358.45z"
        />
        <path
          fill="#42637C"
          d="M277,357c-0.05,0.02-0.11,0.04-0.16,0.07c-0.49,0.2-1.6,0.28-2.36,0.8s-1.66,1.15-2.1,1.29
		c-0.28,0.09-0.45,0.22-0.6,0.32c-0.01,0.02-0.02,0.03-0.02,0.03l0.11,0.24c0,0,0.3-0.05,0.96-0.41s0.77-0.48,1.34-0.89
		c0.57-0.41,1.09-0.84,1.93-0.95c0.82-0.11,3.07-0.88,3.15-0.91c0,0,0.01,0,0.01,0l-0.04-0.08l-0.01-0.03
		C278.14,356.7,277.42,356.89,277,357z"
        />
        <path
          fill="#AD7D62"
          d="M270.27,354.36c-0.07-0.03-0.13-0.05-0.16-0.06C270.15,354.32,270.2,354.34,270.27,354.36z"
        />
        <path
          fill="#4D2C1A"
          d="M270.1,354.3c0.04,0.02,0.09,0.04,0.16,0.06c0.3,0.1,0.73,0.35,1.03,0.43c0.3,0.08,0.2,0.19,0.2,0.19l0,0
		c0,0,0.3-0.18,0.57-0.29c0.27-0.11,0.4-0.1,0.4-0.1c-0.3-0.07-0.56,0.14-1.02,0.06c-0.46-0.08-0.77-0.23-1.14-0.33
		c-0.14-0.04-0.21-0.06-0.22-0.05C270.05,354.27,270.07,354.28,270.1,354.3z"
        />
        <path
          fill="#4D2C1A"
          d="M270.58,356.48c-0.03,0.27-0.17,0.4-0.45,0.58c-0.28,0.18-0.41,0.26-0.48,0.23l0.08,0.03l-0.05,0.07
		l-0.01,0.01c0,0,0,0,0,0c0.01,0,0.35-0.22,0.66-0.42C270.64,356.79,270.58,356.48,270.58,356.48z"
        />
        <path
          fill="#4D2C1A"
          d="M271.03,357.72c0,0-0.58,0.29-0.71,0.37c-0.13,0.08-0.26,0.13-0.32,0.12l0.12,0.02l0.01,0c0,0,0,0,0,0
		c0,0,0.1-0.05,0.22-0.12C270.45,358.04,271.03,357.72,271.03,357.72z"
        />
        <path
          fill="#4D2C1A"
          d="M271.33,358.73c0.25-0.1,0.31-0.06,0.31-0.06s0.02-0.01-0.09-0.04c-0.11-0.02-0.41,0.15-0.54,0.21
		c-0.14,0.06-0.24,0.12-0.32,0.11l0.07,0l0,0l-0.06,0.11c0,0,0.01-0.01,0.01-0.01C270.78,358.95,271.09,358.83,271.33,358.73z"
        />
        <path fill="#AD7D62" d="M280.28,348.99c0,0,0.01,0,0.03-0.01C280.3,348.99,280.29,348.99,280.28,348.99z" />
        <path
          fill="#AD7D62"
          d="M283.5,346.91C283.5,346.91,283.5,346.91,283.5,346.91C283.5,346.91,283.5,346.91,283.5,346.91
		c0,0.01-0.01,0.02-0.01,0.03c-0.03,0.08-0.07,0.17-0.11,0.25c-0.28,0.59-1.07,1.96-2.59,2.74l-0.51-0.94c0,0,0.21,0.44,1.4,2.7
		l-2.04,1.2l-0.68,0.4l-0.75,0.37l-1.8,0.88c-0.25,0.08-1.68,0.49-1.9,0.34c-0.68-0.46-0.9-0.36-1.4-0.56
		c-0.54-0.21-0.44-0.13-1.02-0.26c-0.52-0.12-0.56-0.06-1.17-0.16c-0.61-0.1-0.66-0.18-0.86-0.19c-0.13,0-0.26,0.37,0.01,0.56
		c0.01,0,0.08,0.01,0.22,0.05c0.37,0.11,0.68,0.26,1.14,0.33c0.46,0.08,0.73-0.13,1.02-0.06c0,0-0.13-0.01-0.4,0.1
		c-0.27,0.11-0.57,0.29-0.57,0.29c-0.04,0.05-0.11,0.1-0.23,0.16c-0.37,0.2-0.75,0.43-1.13,0.67c-0.08,0.05-0.17,0.11-0.25,0.16
		c-0.46,0.3-0.69,0.78-0.58,1.01s0.42,0.34,0.42,0.34s-0.02,0.03-0.05,0.07l0.05-0.07l-0.08-0.03c0.08,0.03,0.21-0.05,0.48-0.23
		c0.28-0.18,0.42-0.31,0.45-0.58c0,0,0.06,0.31-0.25,0.51c-0.31,0.2-0.65,0.41-0.66,0.42c-0.07,0.11-0.17,0.34-0.05,0.53
		c0.15,0.25,0.43,0.28,0.49,0.29l-0.12-0.02c0.06,0.01,0.18-0.04,0.32-0.12c0.13-0.08,0.71-0.37,0.71-0.37s-0.57,0.33-0.69,0.39
		c-0.11,0.07-0.21,0.12-0.22,0.12c0,0,0,0,0,0s-0.29,0.22-0.12,0.47c0.15,0.22,0.67,0.26,0.76,0.27l0,0l-0.07,0
		c0.09,0.01,0.19-0.05,0.32-0.11c0.14-0.06,0.44-0.23,0.54-0.21c0.11,0.02,0.09,0.04,0.09,0.04s-0.06-0.04-0.31,0.06
		c-0.25,0.09-0.56,0.22-0.63,0.33c-0.04,0.09-0.07,0.24,0.03,0.42c0.04,0.08,0.1,0.13,0.17,0.16c0.18,0.07,0.41-0.02,0.6-0.05
		c0.09-0.01,0.16-0.05,0.24-0.1c0.01-0.01,0.02-0.01,0.03-0.02c0.15-0.1,0.32-0.23,0.6-0.32c0.45-0.14,1.34-0.77,2.1-1.29
		s1.87-0.59,2.36-0.8c0.06-0.02,0.11-0.04,0.16-0.07c0.42-0.12,1.14-0.3,2.2-0.52c0,0,0,0,0,0c0.39-0.08,0.83-0.16,1.3-0.25
		c0.54-0.1,1.14-0.2,1.8-0.29c3.82-0.57,2.74-3.91,2.44-5.23c-0.56-2.53-1.09-4.37-1.14-4.52c0-0.01,0-0.01,0-0.01
		S283.65,346.49,283.5,346.91z"
        />
        <path
          fill="#4D2C1A"
          d="M283.43,347.08c0.02-0.04,0.03-0.07,0.04-0.1C283.46,347.01,283.45,347.05,283.43,347.08z"
        />
        <path
          fill="#4D2C1A"
          d="M280.28,348.99l0.51,0.94c1.53-0.78,2.31-2.15,2.59-2.74c0.02-0.04,0.04-0.08,0.05-0.11
		c-0.5,1.05-2.03,1.6-2.76,1.81c-0.18,0.05-0.31,0.08-0.36,0.09C280.29,348.99,280.28,348.99,280.28,348.99z"
        />
        <path
          fill="#4D2C1A"
          d="M283.5,346.91C283.5,346.91,283.5,346.91,283.5,346.91C283.5,346.91,283.5,346.91,283.5,346.91
		c-0.01,0.02-0.02,0.04-0.03,0.06c0.01-0.02,0.01-0.03,0.02-0.04C283.5,346.93,283.5,346.92,283.5,346.91z"
        />
        <path
          fill="#AD7D62"
          d="M281.89,383.33c0.37,1.94,0.15-1.36,0.3-2.07c0.15-0.71-0.05-0.15,0.56,1.57c0.48,1.36,2,4.33,2.62,5.52
		c0.16,0.32,0.27,0.51,0.27,0.51s-3.31-7-3.92-12.26c-0.01-0.09-0.02-0.17-0.03-0.26c-0.01-0.1-0.02-0.19-0.03-0.29
		c0-0.02,0-0.05-0.01-0.07c-0.01-0.17-0.02-0.34-0.03-0.5c-0.01-0.14-0.01-0.16-0.02-0.1C281.53,375.8,281.42,380.83,281.89,383.33z
		"
        />
        <path
          fill="#4D2C1A"
          d="M269.07,329.41c0.42-0.01,0.87,0.27,0.87,0.27c-0.11-0.1-0.22-0.17-0.33-0.23
		c-0.27-0.13-0.53-0.13-0.73-0.09c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0.01-0.04,0.01c-0.01,0-0.03,0.01-0.04,0.01
		c0,0-0.01,0-0.01,0c-0.06,0.02-0.11,0.04-0.15,0.07c-0.01,0.01-0.03,0.02-0.04,0.03c0.03-0.01,0.11-0.03,0.39-0.06
		C269.01,329.41,269.04,329.41,269.07,329.41z"
        />
        <path fill="#4D2C1A" d="M268.58,329.48c0,0,0.01,0,0.01-0.01C268.57,329.48,268.58,329.48,268.58,329.48z" />
        <path
          fill="#4D2C1A"
          d="M267,332.69c0.09-0.19,0.35-0.4-0.14-0.75c-0.49-0.35-0.3-0.65-0.16-0.89c0.14-0.24,0.46-0.62,0.51-1.13
		c0.05-0.51-0.25-0.77-0.54-0.84c-0.19-0.05-0.44-0.25-0.29,0.08c0.04,0.08,0.06,0.18,0.07,0.29c0,0,0,0,0,0.01
		c-0.02,0.13-0.02,0.19-0.01,0.25c0-0.01-0.01-0.02-0.01-0.03l0.01,0.03c0,0,0,0.01,0,0.01c0.01,0.08-0.07,0.15-0.28,0.13
		c-0.26-0.02,0.03,0.02,0.16,0.05c0.06,0.01,0.11,0.04,0.16,0.05c0,0.06,0,0.12,0,0.17c0,0.11-0.01,0.22-0.01,0.29
		c0,0.12-0.01,0.26-0.03,0.41c-0.02,0.26-0.04,0.56,0.05,0.88c0,0,0,0,0,0C266.62,332.22,266.91,332.87,267,332.69z"
        />
        <path
          fill="#4D2C1A"
          d="M267.12,331.87c-0.03,0.05,0.09,0.04,0.36,0.09C267.68,332,267.15,331.82,267.12,331.87z"
        />
        <path
          fill="#4D2C1A"
          d="M268.2,333.34c0.1-0.1,0.16-0.22,0.2-0.31c0.11-0.08,0.27,0.05,0.34,0.13c0,0,0,0-0.01-0.01l-0.34-0.47
		c0.04,0.1,0.04,0.17,0.01,0.22c-0.07,0.06-0.21,0.18-0.38,0.14c-0.21-0.05-0.3-0.18-0.48-0.15c-0.18,0.03-0.15,0.12-0.23,0.06
		c-0.08-0.06-0.14-0.1-0.26,0.02c0,0,0,0.01,0.01,0.02c0.01,0.04,0.03,0.11,0.05,0.15c0.03,0.06,0.06,0.12,0.07,0.16
		c0,0.03,0.01,0.16,0.13,0.23c0.04,0.02,0.09,0.04,0.17,0.04C267.76,333.58,268.04,333.51,268.2,333.34z"
        />
        <path
          fill="#4D2C1A"
          d="M272.9,329.52c0.04,0.11,0.07,0.2,0.07,0.2s0.09-0.12,0.04-0.24c-0.04-0.12-0.1-0.19-0.26-0.33
		c-0.16-0.15-0.06-0.34,0.09-0.38c0.21-0.06,0.43,0.31,0.43,0.31c-0.07-0.17-0.14-0.41-0.37-0.44c-0.23-0.04-0.34,0.11-0.39,0.2
		c-0.05,0.09-0.12,0.48,0.04,0.61C272.71,329.57,272.85,329.4,272.9,329.52z"
        />
        <path
          fill="#4D2C1A"
          d="M272.49,329.97c-0.09,0.19,0.01,0.28,0.1,0.17c0.09-0.11,0.25-0.39,0.18-0.45
		c-0.07-0.06-0.21-0.01-0.33-0.15c0,0-0.05,0.03,0.01,0.12C272.5,329.75,272.58,329.78,272.49,329.97z"
        />
        <path
          fill="#4D2C1A"
          d="M272.77,330.43c0.49-0.2,0.47-0.99,0.47-0.99C273.24,329.89,272.77,330.43,272.77,330.43z"
        />
        <path fill="#4D2C1A" d="M268.39,332.68c0,0,0-0.01-0.01-0.01C268.39,332.67,268.39,332.67,268.39,332.68z" />
        <path fill="#4D2C1A" d="M268.38,332.64C268.37,332.64,268.37,332.64,268.38,332.64L268.38,332.64z" />
        <path fill="#4D2C1A" d="M268.74,333.16C268.79,333.22,268.78,333.2,268.74,333.16L268.74,333.16z" />
        <path
          fill="#4D2C1A"
          d="M268.39,332.67C268.39,332.67,268.39,332.67,268.39,332.67C268.39,332.67,268.39,332.67,268.39,332.67z"
        />
        <path fill="#4D2C1A" d="M268.39,332.67c0-0.01-0.01-0.02-0.01-0.02C268.38,332.65,268.38,332.66,268.39,332.67z" />
        <path fill="#AD7D62" d="M269.8,329.88c0,0-0.01,0-0.03-0.01L269.8,329.88z" />
        <path
          fill="#4D2C1A"
          d="M268.52,329.83c0.14-0.04,0.28-0.06,0.41-0.06c-0.02,0.04-0.04,0.09-0.04,0.14c0,0.1,0.05,0.18,0.13,0.22
		c-0.09,0.01-0.15,0.02-0.15,0.02c0.57,0.06,0.93-0.26,0.93-0.26l-0.03-0.01c0.02,0.01,0.03,0.01,0.03,0.01
		C269.15,329.54,268.52,329.83,268.52,329.83z M269.21,330.09c0.05-0.04,0.08-0.11,0.08-0.18c0-0.05-0.02-0.1-0.04-0.14
		c0.22,0.02,0.39,0.06,0.49,0.09C269.62,329.97,269.4,330.04,269.21,330.09z"
        />
        <g>
          <path
            fill="none"
            d="M313.83,334.34c0.03,0.02,0.05,0.03,0.08,0.05l0.22-0.3l-0.22,0.29
			C313.88,334.36,313.86,334.35,313.83,334.34z"
          />
          <path
            fill="none"
            d="M314.43,327.81C314.44,327.81,314.44,327.81,314.43,327.81C314.44,327.81,314.43,327.81,314.43,327.81z"
          />
          <path
            fill="none"
            d="M308.86,334.23L308.86,334.23c0.01-0.02,0.02-0.05,0.02-0.07C308.88,334.19,308.87,334.21,308.86,334.23z"
          />
          <path
            fill="none"
            d="M314.47,334.6c0.01-0.02,0.01-0.04,0.01-0.04l-0.02,0.03C314.46,334.59,314.47,334.59,314.47,334.6z"
          />
          <path fill="none" d="M314.2,330.51c0.01,0,0.03-0.01,0.04-0.01C314.22,330.5,314.21,330.51,314.2,330.51z" />
          <path
            fill="#D7BFB1"
            d="M316.3,392.87c-0.02,0.12-0.05,0.24-0.08,0.37c0.02-0.03,0.87,2,2,2.04s1.66-1.39,1.75-1.3
			c-0.2-0.28-0.35-0.56-0.46-0.8L316.3,392.87z"
          />
          <path
            fill="#151616"
            d="M318.21,394.4c-1.13-0.04-1.94-1.25-1.94-1.25c-0.02,0.03-0.04,0.06-0.06,0.09
			c-0.42,0.68-0.37,1.83-0.35,2.13c0,0.05,0.01,0.08,0.01,0.08c0.28,0.65,1.41,1.05,1.89,1.01c0.48-0.04,0.56,0.52,2.3,0.93
			c1.73,0.4,2.86,0.2,3.02-0.24c0.16-0.44,0.12-0.89-0.97-1.49c-0.44-0.24-0.89-0.58-1.28-0.9c-0.38-0.31-0.69-0.6-0.87-0.77
			c-0.09-0.09-0.15-0.14-0.15-0.14C318.73,393.75,319.34,394.44,318.21,394.4z"
          />
          <path
            fill="#D7BFB1"
            d="M309.42,393.43c0,0,0.15,1.66,1.62,0.97c0.48-0.23,1-0.27,1.17-0.98c-0.08-0.29-0.22-0.59-0.32-0.79
			c-0.51,0.04-1.7-2.15-2.48,0.71C309.41,393.36,309.41,393.39,309.42,393.43z"
          />
          <path
            fill="#D7BFB1"
            d="M309.03,395.59c0.02-0.07,0.04-0.14,0.07-0.22C309.05,395.44,309.03,395.52,309.03,395.59z"
          />
          <path
            fill="#151616"
            d="M311.92,393.55c-0.2,0.08-0.4-0.44-0.93-0.36c-0.52,0.08-0.73,0.36-0.97,0.65c-0.24,0.28-0.6-0.4-0.6-0.4
			s-0.16,1.37-0.32,1.94c-0.02,0.08-0.05,0.15-0.07,0.22c-0.14,0.44-0.22,0.75,0.23,1.31c0.02,0.02,0.04,0.05,0.06,0.07
			c0.53,0.56,1.36,0.37,2.64,0.29c0.83-0.05,1.12-0.51,1.18-1.01c0.04-0.31-0.02-0.63-0.09-0.89c-0.13-0.44-0.55-1.35-0.84-1.95
			c-0.17-0.34-0.29-0.59-0.29-0.59S312.12,393.47,311.92,393.55z"
          />
          <path fill="#D7BFB1" d="M314.48,334.55c0,0-0.01,0-0.03,0c0.01,0.01,0.01,0.02,0.02,0.03L314.48,334.55z" />
          <path
            fill="#D7BFB1"
            d="M308.86,334.23l2.7,2.58l0.27,1.39l0.35-1.41l1.73-2.41c-0.03-0.02-0.05-0.03-0.08-0.05
			c-0.17-0.09-0.28-0.23-0.35-0.35c-0.09-0.05-0.84-0.44-1.69-1.03c-0.9-0.62-0.84-1.55-0.84-1.55s0.22,1,0.62,1.16
			c1.12,0.45,1.65,0.31,1.65,0.31s0.42,0.01,0.75-0.04c0.06-0.01,0.11-0.02,0.16-0.03c0.21-0.05,0.32-0.18,0.4-0.37
			c0.09-0.3,0.14-0.46-0.02-0.68c-0.17-0.23-0.24-0.29-0.24-0.29s0.34-0.05,0.44-0.24c0.05-0.1,0.02-0.19,0.01-0.26
			c-0.07,0.04-0.16,0.06-0.27,0.06c-0.22,0-0.48-0.13-0.61-0.23c-0.1-0.02-0.18,0.14-0.18,0.14s0.1-0.5,0.1-0.44
			c0,0.05,0,0.17,0.11,0.19c0.2,0.01,0.24-0.15,0.34-0.19c0.01-0.01,0.02-0.01,0.04-0.01c0.15,0,0.21,0.2,0.38,0.05
			c0.12-0.1,0.17-0.11,0.23-0.01c-0.01-0.06-0.03-0.11-0.09-0.14c-0.16-0.07-0.45-0.31-0.11-0.37c0.34-0.05,0.45-0.17,0.41-0.31
			s-0.14-0.45-0.35-0.91c-0.25-0.53-0.38-0.74-0.27-1c0,0,0,0,0-0.01c0.01-0.03,0.03-0.06,0.05-0.09c0.03-0.04,0.05-0.08,0.08-0.11
			c-0.01,0.01-0.02,0.01-0.02,0.01s-0.02-0.04,0.1-0.19c0.12-0.15,0.27-0.09,0.48-0.02c0.11-0.44,0.06-1.72-0.5-2.64
			c-0.02-0.02-0.03-0.04-0.05-0.06c0,0,0,0,0,0c-0.31-0.34-0.7-0.48-1-0.53c-0.38,0.49-0.69,1.85-1.19,3.16
			c-0.07,0.24-0.19,0.52-0.38,0.85c-0.41,0.72-1.4,1.27-1.44,1.3c-0.04,0.04,0.63-1.96-0.6-1.83c-0.92,0.1-0.66,1.67-0.06,2.49
			c0.11,0.15,0.17,0.44,0.11,0.62c-0.24,0.8-0.66,1.06-0.77,1.12c0.01,0.18,0,0.36-0.03,0.56c-0.08,0.55-0.17,1-0.26,1.37
			c-0.02,0.1-0.06,0.26-0.09,0.36C308.88,334.18,308.87,334.21,308.86,334.23z M314.58,329.84c0.14-0.03-0.02,0.11-0.28,0.11
			l-0.03-0.02C314.28,329.93,314.44,329.87,314.58,329.84z M312.73,328.13c0.28-0.3,0.59-0.31,0.76-0.28
			c0.09,0.01,0.23-0.01,0.29-0.05c0,0-0.02,0.06-0.13,0.09c0,0,0,0,0,0c0,0,0,0-0.01,0c-0.04,0.01-0.09,0.02-0.16,0.02
			c0.01,0.05,0.02,0.2-0.09,0.27c0.07,0.01,0.14,0.01,0.2,0.02c0.17,0.03,0.01,0.01-0.2-0.02c0,0-0.01,0.01-0.01,0.01l-0.19-0.02
			C312.92,328.17,312.68,328.18,312.73,328.13z M312.66,328c0,0,0.51-0.67,0.98-0.2C313.63,327.8,313.16,327.44,312.66,328z
			 M313.5,330.36c-0.3,0.39-0.37,0.72-0.45,1.09c-0.09,0.37,0.09-0.54-0.03-0.83c-0.12-0.29,0.26-0.12,0.59-0.56
			c0.33-0.45,0.4-0.78,0.4-0.78C314,329.7,313.8,329.97,313.5,330.36z M313.26,327.31c0.37-0.15,0.6,0,0.64,0.2
			c0,0-0.2-0.05-0.45-0.07c-0.25-0.01-0.93,0.2-0.93,0.2S312.9,327.46,313.26,327.31z M312.69,328.17c-0.15,0.25-0.4,0.12-0.4,0.12
			C312.57,328.3,312.69,328.17,312.69,328.17z M310.44,328.89c-0.06,0.09-0.13,0.13-0.04,0.31c0.09,0.19-0.01,0.28-0.1,0.17
			c-0.09-0.11-0.25-0.39-0.18-0.45c0.07-0.06,0.21-0.01,0.33-0.15C310.45,328.77,310.5,328.8,310.44,328.89z M309.99,327.86
			c0.23-0.04,0.34,0.11,0.39,0.2c0.05,0.09,0.12,0.48-0.04,0.61c-0.16,0.13-0.3-0.04-0.34,0.07c-0.04,0.11-0.07,0.2-0.07,0.2
			s-0.09-0.12-0.04-0.24c0.04-0.12,0.1-0.19,0.26-0.33c0.16-0.15,0.06-0.34-0.09-0.38c-0.21-0.06-0.43,0.31-0.43,0.31
			C309.68,328.14,309.75,327.9,309.99,327.86z M309.65,328.68c0,0.44,0.47,0.99,0.47,0.99
			C309.63,329.46,309.65,328.68,309.65,328.68z"
          />
          <path
            fill="#D7BFB1"
            d="M312.86,328.12c0,0,0.12,0.02,0.27,0.04c0-0.03-0.01-0.12,0.02-0.19
			C312.96,328.03,312.86,328.12,312.86,328.12z"
          />
          <polygon fill="#D7BFB1" points="315.11,327.52 315.11,327.52 315.11,327.52 		" />
          <path
            fill="#4D2C1A"
            d="M314.07,354.5l-2.34,0.21c-0.04,0.65-4.76,0.64-5.76-0.22c-0.57,1.97-1.31,5.48-0.83,9.19
			c0.74,5.65,1.48,12.9,1.48,12.9s-0.32,3.11,0.42,6.22c0.74,3.11,1.48,10.49,1.48,10.49l0.74,0.12c0.05-0.03,0.1-0.05,0.15-0.08
			c0.96-0.52,1.98-0.67,2.48-0.71c0.2-0.02,0.32-0.02,0.32-0.02s0.21-4.49,0.11-7.6c-0.11-3.11-0.24-8.51,0.39-13.35
			c0.23-1.75,0.31-4.53,0.32-7.36c0.77,3.94,2.32,12.76,2.32,12.76l-0.08,15.63l1.04,0.19l2.93,0.53l0.28-0.22l0.74-0.57
			c0,0-0.06-8.1,0.4-19.96c0.39-9.94-0.92-15.76-1.62-18.11C318.38,355.15,314.27,355.16,314.07,354.5z"
          />
          <path
            fill="#FFFFFF"
            d="M313.43,351.12c-0.25-1.35-0.38-2.75-0.41-4.14c-0.35,0.12-0.72,0.24-1.11,0.37
			c0.11,2.85-0.03,5.21-0.18,7.36l2.34-0.21C313.87,353.84,313.68,352.9,313.43,351.12z"
          />
          <path
            fill="#FFFFFF"
            d="M311.6,343.36c0.02,0.14,0.03,0.27,0.04,0.41c0.37-0.07,0.7-0.13,0.94-0.19c0.17-0.04,0.34-0.08,0.5-0.11
			c0.14-2.18,0.43-4.18,0.71-5.74c0,0,0,0,0,0c0.26-1.54,0.6-2.88,0.66-3.12c0,0,0,0,0,0c0,0,0-0.01-0.01-0.01
			c-0.01-0.01-0.01-0.02-0.02-0.03c-0.17-0.29-0.31-0.47-0.31-0.47l-0.22,0.3l-1.73,2.41l-0.35,1.41l-0.27-1.39l-2.7-2.58l0,0
			c-0.01,0.02-0.01,0.04-0.01,0.04l-0.3-0.33l-0.68,0.73C307.87,334.66,310.94,337.58,311.6,343.36z"
          />
          <path fill="#FFFFFF" d="M307.58,334.73l0.29-0.08C307.87,334.66,307.66,334.61,307.58,334.73z" />
          <path
            fill="#151616"
            d="M321.61,347.73c-0.03,0.01-0.06,0.03-0.09,0.04c0,0-0.01,0-0.01,0.01c-0.25,0.09-0.57,0.14-1.02,0.09
			c-0.15-0.02-0.27-0.06-0.37-0.11c-0.62-0.31-0.31-1.39-0.31-1.39s0,0,0.01,0c0.04-0.31,0.16-0.69,0.35-0.82
			c0.23-0.16,0.36-0.14,0.91-0.37c0.55-0.23,0.46,0.04,0.41,0.16c-0.05,0.12,0.26-0.23,0.47-0.63c0.12-0.24,0.28-0.31,0.39-0.31
			c-0.01,0-0.01-0.01-0.02-0.01c-0.18-0.04-0.41,0.12-0.54,0.35c-0.07,0.14-0.14,0.27-0.18,0.35l-4.08-0.09c0,0-0.01-0.01-0.01-0.01
			l-0.06,0.01l-0.04,0l-5.7,0.59c0,0-0.06-0.24,0.42-0.3c0.44-0.05,4.28-0.53,5.05-0.62l-0.02-0.02c0-0.02-0.03-0.06-0.1-0.11
			c-0.14-0.06-0.49-0.21-0.72-0.22c-0.29-0.02-0.28,0.18-0.53,0.33c-0.26,0.16-0.78,0.24-1.01,0.26c-0.26,0.05-0.46,0.08-0.46,0.08
			s-0.32-0.5-0.18-1.7c0,0,1.45,0.08,2.05-0.12c0.56-0.19,1.4-0.28,1.52-0.29l1.79-5.54c-0.43-2.48-2.94-1.92-5.06-2.77
			c0,0,0,0.02-0.01,0.04c0,0,0,0,0,0c-0.06,0.24-0.4,1.58-0.66,3.12c0,0,0,0,0,0c-0.28,1.55-0.57,3.56-0.71,5.74
			c-0.16,0.03-0.33,0.07-0.5,0.11c-0.25,0.06-0.58,0.13-0.94,0.19c-0.01-0.14-0.03-0.27-0.04-0.41c-0.66-5.78-3.73-8.7-3.73-8.7
			l-0.29,0.08l-3.35,0.89c-0.03,0-0.06,0-0.09,0.01c-1,0.3-2.12,0.41-2.22,1.11c-0.1,0.7-0.05,2.26-0.24,5.04
			c-0.1,1.43,0.45,3.78,1.54,6.07c0.72,1.51,1.64,1.59,2.68,1.34c-0.32,1.6-0.82,4.4-0.82,4.4s0.29,0.39,0.88,0.89
			c1,0.86,2.87,2.03,5.64,2.11c0.04-0.62,0.08-1.25,0.13-1.89c0.15-2.14,0.29-4.5,0.18-7.36c0.39-0.13,0.76-0.25,1.11-0.37
			c0.04,1.39,0.16,2.79,0.41,4.14c0.25,1.78,0.44,2.72,0.64,3.38c0.2,0.66,0.4,1.04,0.68,1.71c2.26-0.09,3.63-1.05,4.28-1.66
			c0.31-0.29,0.46-0.5,0.46-0.5s0.13-1.47-0.32-4.08c0.24,0.48,0.52,0.81,0.88,0.85c1.47,0.17,2.05-1.58,2.1-2.33
			c0.05-0.6,0.06-1.19,0.06-1.39c-0.05,0.13-0.12,0.28-0.26,0.43C321.87,347.59,321.75,347.67,321.61,347.73z"
          />
          <path
            fill="#4D2C1A"
            d="M332.26,336.2l-11.75-0.57c-0.16-0.01-0.31,0.09-0.36,0.25l-2.81,8.68c-0.01,0.04-0.02,0.09-0.02,0.14
			c0.01,0.12,0.08,0.22,0.18,0.28c0,0,0.01,0.01,0.01,0.01l4.08,0.09c0.04-0.09,0.11-0.22,0.18-0.35c0.13-0.23,0.36-0.39,0.54-0.35
			c0.01,0,0.01,0.01,0.02,0.01c0.08,0,0.14,0.05,0.13,0.12c0,0.04,0,0.12,0.01,0.21c0.06-0.21,0.17-0.6,0.23-0.8
			c0.06-0.22,0.24-0.29,0.39-0.29c0,0,0.01,0,0.01,0c0.03,0,0.07,0,0.09,0.01c0.3,0.07,0.15,0.71,0.1,0.96
			c-0.03,0.12-0.07,0.32-0.12,0.52l0.21,0c0.03-0.08,0.06-0.15,0.09-0.23c0.1-0.23,0.2-0.46,0.3-0.69c0.04-0.1,0.11-0.2,0.13-0.31
			c0.05-0.22,0.2-0.3,0.26-0.29c0.01,0,0.02,0.01,0.02,0.01c0.04-0.01,0.08-0.01,0.13,0.01c0.25,0.07,0.28,0.41,0.16,0.94
			c-0.05,0.22-0.11,0.41-0.16,0.58l1.97,0.04l3.1,0.05c0.15,0,0.29-0.09,0.34-0.24l2.86-8.31
			C332.66,336.45,332.5,336.22,332.26,336.2z"
          />
          <path
            fill="#151616"
            d="M322.8,344.04c0.06-0.21,0.08-0.41,0.28-0.42c-0.15,0-0.33,0.07-0.39,0.29c-0.06,0.2-0.17,0.59-0.23,0.8
			c0.01,0.11,0.01,0.22,0.01,0.22S322.71,344.37,322.8,344.04z"
          />
          <path
            fill="#151616"
            d="M323,345.67c0.1-0.07,0.32-0.23,0.41-0.39c0.13-0.23,0.41-0.84,0.52-1.19c0.08-0.28,0.12-0.46,0.26-0.49
			c-0.01-0.01-0.01-0.01-0.02-0.01c-0.07,0-0.21,0.08-0.26,0.29c-0.03,0.11-0.09,0.2-0.13,0.31c-0.1,0.23-0.2,0.46-0.3,0.69
			c-0.03,0.07-0.06,0.15-0.09,0.23l-0.21,0c-0.05,0.21-0.11,0.41-0.15,0.52C323.01,345.64,323.01,345.66,323,345.67z"
          />
          <path
            fill="#151616"
            d="M324.23,345.37c0.06-0.04,0.34-0.23,0.74-0.19c0,0,0,0,0,0c0.2,0.01,0.26,0.21,0.18,0.29
			c-0.04,0.04-0.08,0.09-0.12,0.14c2.2-0.16,4.46-0.37,4.46-0.37l-3.21-0.07l-1.97-0.04C324.28,345.22,324.26,345.3,324.23,345.37z"
          />
          <path
            fill="#D7BFB1"
            d="M321.5,347.77c-0.32,0.11-0.76,0.16-1.26,0.02c-0.05-0.01-0.09-0.03-0.13-0.05
			c0.1,0.05,0.22,0.1,0.37,0.11C320.93,347.91,321.26,347.87,321.5,347.77z"
          />
          <path
            fill="#D7BFB1"
            d="M316.23,343.15c-0.6,0.2-2.05,0.12-2.05,0.12c-0.14,1.2,0.18,1.7,0.18,1.7s0.21-0.03,0.46-0.08
			c-0.08,0.01-0.12,0.01-0.12,0.01c0.36-0.22,0.59-0.55,0.92-0.82c0.33-0.27,0.65-0.18,0.97,0.02c0.35,0.22,0.57,0.46,0.57,0.46
			s-0.03-0.01-0.07-0.03c0.07,0.05,0.1,0.08,0.1,0.11l0.02,0.02c-0.77,0.09-4.62,0.57-5.05,0.62c-0.49,0.06-0.42,0.3-0.42,0.3
			l5.7-0.59l0.04,0l0.06-0.01c-0.1-0.06-0.17-0.16-0.18-0.28c0-0.05,0-0.09,0.02-0.14l2.81-8.68c0.05-0.15,0.2-0.25,0.36-0.25
			l-0.42-0.01l-0.55,1.7l-1.79,5.54C317.63,342.87,316.79,342.96,316.23,343.15z M317.37,344.08c0,0-0.79-0.34-0.74-0.36
			c0.31-0.15,0.9-0.13,0.9-0.13L317.37,344.08z"
          />
          <path
            fill="#AD7D62"
            d="M316.58,344.1c-0.31-0.2-0.64-0.29-0.97-0.02c-0.33,0.27-0.55,0.6-0.92,0.82c0,0,0.05,0,0.12-0.01
			c0.24-0.03,0.76-0.1,1.01-0.26c0.24-0.15,0.24-0.35,0.53-0.33c0.23,0.01,0.58,0.16,0.72,0.22c0.04,0.02,0.07,0.03,0.07,0.03
			S316.92,344.32,316.58,344.1z"
          />
          <path
            fill="#AD7D62"
            d="M316.63,343.72c-0.05,0.02,0.74,0.36,0.74,0.36l0.16-0.49C317.53,343.59,316.94,343.57,316.63,343.72z"
          />
          <path
            fill="#E8E7D9"
            d="M310.04,330.75c0.05-0.18,0-0.47-0.11-0.62c-0.59-0.82-0.86-2.39,0.06-2.49c1.23-0.13,0.57,1.87,0.6,1.83
			c0.04-0.04,1.03-0.59,1.44-1.3c0.19-0.33,0.3-0.61,0.38-0.85c0.5-1.31,0.82-2.67,1.19-3.16c0.09-0.11,0.18-0.18,0.28-0.19
			c0.56-0.03,0.61,0.26,0.84,0.86c-0.02-0.03-0.04-0.06-0.07-0.08c0.56,0.92,0.61,2.2,0.5,2.64c-0.21-0.07-0.36-0.12-0.48,0.02
			c-0.12,0.15-0.1,0.19-0.1,0.19s0.01,0,0.02-0.01c0.07-0.04,0.38-0.19,0.54-0.09c0,0,0,0,0,0c0,0,0,0,0,0
			c0.09,0.12,0.08,0.31,0.04,0.48c0,0,0.08,0.02,0.18,0.02c0.07,0-0.07,0.06-0.17,0.1c-0.01,0.05-0.01,0.13-0.02,0.18
			c0.01,0.17,0.05,0.38,0.09,0.61c0.08,0.38,0.07,0.85-0.04,1.18c-0.11,0.33-0.12,0.85-0.28,1.32c-0.16,0.47-0.51,1.48-0.39,1.09
			c0.01-0.02,0.01-0.04,0.02-0.06c-0.08,0.19-0.19,0.32-0.4,0.37c-0.05,0.01-0.1,0.02-0.16,0.03c1.09,0.05,2-0.25,2-0.25
			s-0.58-0.23-0.4-3.05c0.2-3.11,0.05-4.85-0.79-5.27c-0.44-2.57-5.56-1.7-6.68,0.3c-1.36,2.43-0.37,3.82-0.37,3.82
			c0.69,2.82,1.52,3.49,1.52,3.49s0.01,0,0.03-0.01C309.38,331.81,309.79,331.56,310.04,330.75z"
          />
          <path
            fill="#151616"
            d="M313.48,333.99c0.07,0.12,0.18,0.26,0.35,0.35c0.02,0.01,0.05,0.03,0.08,0.04l0.22-0.29
			c0,0-0.17-0.19-0.72-0.27C313.46,333.93,313.49,333.99,313.48,333.99C313.49,333.99,313.49,333.99,313.48,333.99z"
          />
          <path
            fill="#151616"
            d="M308.86,334.23c0.01-0.02,0.01-0.04,0.02-0.07c0.03-0.1,0.08-0.26,0.09-0.36c0-0.02,0.01-0.04,0.01-0.05
			c0,0-0.18-0.02-0.43,0.19l0.3,0.33C308.85,334.26,308.86,334.25,308.86,334.23z"
          />
          <path
            fill="#AD7D62"
            d="M311.57,332.57c-0.4-0.16-0.62-1.16-0.62-1.16s-0.06,0.93,0.84,1.55c0.85,0.59,1.6,0.98,1.69,1.03
			c0.01,0,0.01,0,0.01,0s-0.03-0.07-0.08-0.19c-0.07-0.2-0.17-0.53-0.19-0.92C313.22,332.89,312.69,333.03,311.57,332.57z"
          />
          <path
            fill="#AD7D62"
            d="M313.87,323.98c-0.1,0-0.19,0.07-0.28,0.19c0.29,0.05,0.68,0.19,1,0.53c0,0,0,0,0,0
			c0.02,0.02,0.03,0.04,0.05,0.06c0.02,0.03,0.05,0.05,0.07,0.08C314.48,324.24,314.43,323.95,313.87,323.98z"
          />
          <path
            fill="#AD7D62"
            d="M323.26,346.55c-0.18,0.03-0.29,0.01-0.4-0.03c-0.08-0.14,0.1-0.68,0.1-0.68s-0.03,0.08-0.11,0.23
			c-0.08,0.15-0.18,0.37-0.32,0.44c-0.17,0.05-0.38,0.14-0.59,0.08c-0.12-0.03-0.17-0.08-0.22-0.12c-0.02-0.04-0.02-0.08,0.03-0.13
			c0.13-0.14,0.38-0.32,0.47-0.53c0.07-0.17,0.14-0.42,0.17-0.51c-0.02,0.09-0.1,0.33-0.26,0.5c0,0.01-0.01,0.01-0.01,0.01
			c-0.19,0.21-0.39,0.5-0.65,0.57c-0.05,0-0.11,0.01-0.18,0.03c-0.12,0.03-0.2,0.03-0.26,0.02c-0.01-0.01-0.02-0.02-0.04-0.02
			c-0.19-0.06,0.05-0.39,0.17-0.53c-0.14,0.15-0.31,0.3-0.53,0.41c-0.26,0.05-0.76,0.06-0.82,0.06c0,0-0.01,0-0.01,0
			s-0.31,1.08,0.31,1.39c0.04,0.02,0.08,0.04,0.13,0.05c0.49,0.13,0.94,0.09,1.26-0.02c0.01,0,0.01,0,0.01-0.01
			c0.03-0.01,0.06-0.02,0.09-0.04c0.14-0.06,0.26-0.14,0.33-0.21c0.14-0.15,0.21-0.3,0.26-0.43c0.03-0.08,0.06-0.15,0.1-0.2
			c0.02-0.02,0.04-0.04,0.06-0.06c0.02-0.01,0.03-0.02,0.05-0.03C322.6,346.75,323.11,346.6,323.26,346.55
			C323.25,346.56,323.25,346.56,323.26,346.55L323.26,346.55z"
          />
          <path
            fill="#AD7D62"
            d="M321.16,345.89c0.01-0.01,0.02-0.02,0.03-0.03C321.24,345.79,321.21,345.82,321.16,345.89z"
          />
          <path fill="#AD7D62" d="M322.39,345.3c0.01-0.02,0.01-0.04,0.01-0.04S322.4,345.28,322.39,345.3z" />
          <path
            fill="#D7BFB1"
            d="M324.97,345.18c-0.12,0.05-0.42,0.27-0.49,0.43c-0.07,0.17,0.08,0.23,0.08,0.23s-0.33,0.03-0.35,0.08
			c-0.03,0.05-0.02,0.1-0.02,0.1s-0.45,0.15-0.56,0.23c-0.11,0.08,0.46-0.45,0.46-0.45l0.13-0.41c0,0,0.01,0,0.01-0.01
			c0.02-0.07,0.05-0.15,0.08-0.23c0.05-0.16,0.11-0.36,0.16-0.58c0.12-0.53,0.09-0.87-0.16-0.94c-0.05-0.01-0.09-0.02-0.13-0.01
			c-0.14,0.03-0.18,0.2-0.26,0.49c-0.11,0.36-0.39,0.96-0.52,1.19c-0.09,0.16-0.31,0.32-0.41,0.39c0.01-0.01,0.01-0.03,0.02-0.05
			c0.04-0.1,0.09-0.31,0.15-0.52c0.05-0.2,0.1-0.39,0.12-0.52c0.05-0.25,0.21-0.89-0.1-0.96c-0.03-0.01-0.06-0.01-0.09-0.01
			c0,0-0.01,0-0.01,0c-0.2,0-0.22,0.21-0.28,0.42c-0.09,0.33-0.32,0.9-0.32,0.9s-0.01-0.11-0.01-0.22c0-0.08-0.01-0.17-0.01-0.21
			c0-0.07-0.05-0.12-0.13-0.12c-0.11-0.01-0.27,0.07-0.39,0.31c-0.21,0.41-0.52,0.76-0.47,0.63c0.05-0.12,0.14-0.39-0.41-0.16
			c-0.55,0.23-0.68,0.21-0.91,0.37c-0.19,0.13-0.31,0.51-0.35,0.82c0.06,0,0.56-0.01,0.82-0.06c0.21-0.12,0.38-0.26,0.53-0.41
			c0.06-0.07,0.09-0.09,0.03-0.03c-0.01,0.01-0.02,0.02-0.03,0.03c-0.12,0.14-0.36,0.46-0.17,0.53c0.02,0.01,0.03,0.01,0.04,0.02
			c0.06,0.01,0.14,0.01,0.26-0.02c0.07-0.02,0.13-0.03,0.18-0.03c0.26-0.08,0.46-0.37,0.65-0.57c0-0.01,0.01-0.01,0.01-0.01
			c0.15-0.17,0.23-0.41,0.26-0.5c0.01-0.02,0.01-0.04,0.01-0.04s0,0.01-0.01,0.04c-0.02,0.09-0.09,0.34-0.17,0.51
			c-0.09,0.21-0.34,0.39-0.47,0.53c-0.05,0.05-0.05,0.09-0.03,0.13c0.05,0.04,0.1,0.09,0.22,0.12c0.21,0.05,0.42-0.03,0.59-0.08
			c0.13-0.07,0.24-0.29,0.32-0.44c0.08-0.15,0.11-0.23,0.11-0.23s-0.18,0.54-0.1,0.68c0.11,0.04,0.23,0.06,0.4,0.03l-0.01,0.01
			c0,0,0,0,0,0c0.01,0,0.15,0,0.46-0.12c0.32-0.13,0.33-0.28,0.55-0.34c0.22-0.07,0.59-0.05,0.65-0.23c0.03-0.1,0.07-0.18,0.12-0.24
			c0.04-0.05,0.08-0.1,0.12-0.14C325.23,345.39,325.17,345.19,324.97,345.18z"
          />
          <path
            fill="#AD7D62"
            d="M324.09,345.79c0,0-0.57,0.53-0.46,0.45c0.11-0.08,0.56-0.23,0.56-0.23s-0.01-0.05,0.02-0.1
			c0.03-0.05,0.35-0.08,0.35-0.08s-0.15-0.06-0.08-0.23c0.07-0.17,0.37-0.38,0.49-0.43c0,0,0,0,0,0c-0.4-0.04-0.69,0.15-0.74,0.19
			c-0.01,0-0.01,0.01-0.01,0.01L324.09,345.79z"
          />
          <path
            fill="#AD7D62"
            d="M314.58,329.84c-0.14,0.03-0.3,0.09-0.3,0.09l0.03,0.02C314.57,329.95,314.72,329.81,314.58,329.84z"
          />
          <path
            fill="#E8E7D9"
            d="M313.91,327.52c-0.04-0.2-0.27-0.36-0.64-0.2c-0.37,0.15-0.74,0.34-0.74,0.34s0.68-0.22,0.93-0.2
			C313.7,327.46,313.91,327.52,313.91,327.52z"
          />
          <path
            fill="#AD7D62"
            d="M313.38,328.2C313.38,328.2,313.38,328.2,313.38,328.2c0.22,0.02,0.39,0.04,0.21,0.01
			c-0.06-0.01-0.13-0.02-0.2-0.02c0.11-0.07,0.1-0.22,0.09-0.27c0.07,0,0.12-0.01,0.16-0.02c0,0,0,0,0.01,0c0,0,0,0,0,0
			c0.12-0.03,0.13-0.09,0.13-0.09c-0.06,0.04-0.19,0.06-0.29,0.05c-0.18-0.03-0.48-0.02-0.76,0.28c-0.04,0.05,0.19,0.04,0.46,0.05
			L313.38,328.2z M313.13,328.16c-0.15-0.02-0.27-0.04-0.27-0.04s0.1-0.09,0.29-0.15C313.12,328.04,313.13,328.13,313.13,328.16z"
          />
          <path
            fill="#AD7D62"
            d="M313.01,330.61c0.12,0.29-0.05,1.2,0.03,0.83c0.09-0.37,0.16-0.7,0.45-1.09c0.3-0.39,0.5-0.66,0.5-1.09
			c0,0-0.07,0.33-0.4,0.78C313.27,330.49,312.89,330.32,313.01,330.61z"
          />
          <path
            fill="#AD7D62"
            d="M314.9,331.41c0.16-0.47,0.17-0.99,0.28-1.32c0.11-0.33,0.12-0.8,0.04-1.18
			c-0.05-0.23-0.08-0.44-0.09-0.61c0.02-0.05,0.02-0.13,0.02-0.18c0.1-0.04,0.25-0.1,0.17-0.1c-0.1,0-0.18-0.02-0.18-0.02
			c0.04-0.17,0.05-0.35-0.04-0.48c0,0,0,0,0,0c-0.16-0.1-0.47,0.05-0.54,0.09c-0.03,0.04-0.06,0.07-0.08,0.11
			c-0.02,0.03-0.04,0.06-0.05,0.09c0,0,0,0,0,0.01c-0.12,0.26,0.02,0.47,0.27,1c0.21,0.46,0.31,0.77,0.35,0.91s-0.06,0.26-0.41,0.31
			c-0.34,0.05-0.05,0.3,0.11,0.37c0.06,0.03,0.09,0.08,0.09,0.14c-0.06-0.1-0.11-0.09-0.23,0.01c-0.18,0.15-0.23-0.05-0.38-0.05
			c-0.02,0-0.03,0-0.04,0.01c-0.1,0.04-0.14,0.2-0.34,0.19c-0.11-0.02-0.11-0.14-0.11-0.19c0-0.06-0.1,0.44-0.1,0.44
			s0.08-0.17,0.18-0.14c0.13,0.1,0.39,0.23,0.61,0.23c0.11,0,0.2-0.03,0.27-0.06c0.02,0.07,0.04,0.15-0.01,0.26
			c-0.09,0.19-0.44,0.24-0.44,0.24s0.07,0.06,0.24,0.29c0.16,0.21,0.11,0.38,0.02,0.68c-0.01,0.02-0.01,0.04-0.02,0.06
			C314.4,332.89,314.75,331.88,314.9,331.41z"
          />
          <path
            fill="#AD7D62"
            d="M310.05,328c0.15,0.04,0.25,0.23,0.09,0.38c-0.16,0.15-0.22,0.21-0.26,0.33
			c-0.04,0.12,0.04,0.24,0.04,0.24s0.03-0.09,0.07-0.2c0.04-0.11,0.18,0.06,0.34-0.07c0.16-0.13,0.09-0.52,0.04-0.61
			c-0.05-0.09-0.15-0.24-0.39-0.2c-0.23,0.04-0.31,0.28-0.37,0.44C309.62,328.31,309.83,327.94,310.05,328z"
          />
          <path
            fill="#AD7D62"
            d="M310.29,329.37c0.09,0.11,0.19,0.02,0.1-0.17c-0.09-0.19-0.01-0.22,0.04-0.31
			c0.06-0.09,0.01-0.12,0.01-0.12c-0.12,0.14-0.26,0.08-0.33,0.15C310.05,328.98,310.21,329.26,310.29,329.37z"
          />
          <path fill="#AD7D62" d="M309.65,328.68c0,0-0.01,0.79,0.47,0.99C310.12,329.67,309.65,329.12,309.65,328.68z" />
          <path fill="#AD7D62" d="M312.69,328.17c0,0-0.12,0.13-0.4,0.12C312.29,328.29,312.54,328.42,312.69,328.17z" />
          <path
            fill="#AD7D62"
            d="M313.63,327.8c-0.46-0.47-0.98,0.2-0.98,0.2C313.16,327.44,313.63,327.8,313.63,327.8z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="woman_1_">
          <path
            fill="#D7BFB1"
            d="M209.12,481.5c0.01,0.01,0.01,0.01,0.02,0.02c-1.13-0.44-2.16,0.25-2.16,0.25l0.03-0.98
			c0,0-0.01,0.01-0.01,0.01c0.02-0.7,0.07-1.6,0.16-2.14c0.16-0.96,0.08-1.98-0.85-2.32c-0.93-0.35-1.58,0.12-1.58,0.12
			c0,0,0,0,0-0.01c0.01-0.05,0.06-0.29-0.02-0.57c-0.01-0.07-0.02-0.12-0.02-0.12c-0.01,0-0.01,0.01-0.02,0.01
			c-0.11-0.26-0.35-0.53-0.87-0.65c-2.02-0.47-2.79,2.9-2.79,2.9c-1.58,0.41-4.61,3.98-4.61,3.98c-1.09,0.59-2.29,1.61-3.02,2.27
			c-0.23,0.05-0.47,0.09-0.73,0.14c-2.37,0.43-3.56-0.61-8.13-0.54c-3.12,0.04-4.93,1.47-9.7,2.12c0.14,0.42-3.1,12.11-0.61,16.35
			c4.04-0.14,17.63,1.65,19.9,2.06c2.27,0.41,3.55-2.41,5.82-4.2c2.27-1.79,4.53-3.92,5.08-4.54c0.55-0.62,1.71-4.4,2.51-6.05
			c0.56-1.17,1.25-2.96,1.8-4.4c0.07-0.17,0.13-0.32,0.18-0.45c0.17-0.37,0.29-0.69,0.37-0.98c0.01-0.03,0.02-0.06,0.03-0.09
			C210.46,482.33,209.97,481.78,209.12,481.5z"
          />
          <path
            fill="#AD7D62"
            d="M153.51,481.06c-0.07-0.49-0.27-1.09-0.4-1.67c-0.03-0.13-0.05-0.25-0.07-0.38
			c-0.02-0.14-0.07-0.25-0.14-0.34c-0.07-0.09-0.17-0.16-0.28-0.22c-0.11-0.06-0.24-0.1-0.37-0.14c-0.14-0.04-0.28-0.06-0.43-0.09
			c-0.15-0.03-0.3-0.05-0.44-0.08c-0.22-0.04-0.43-0.09-0.61-0.18s-0.33-0.19-0.43-0.35c-0.16-0.27-0.21-0.55-0.2-0.81
			c0-0.05,0.01-0.1,0.01-0.15c0.01-0.1,0.03-0.19,0.05-0.28c0.02-0.09,0.05-0.17,0.08-0.24c0.03-0.07,0.06-0.14,0.08-0.19
			c0.05-0.11,0.09-0.17,0.09-0.17s-0.16,0.1-0.34,0.34c-0.06,0.08-0.12,0.18-0.18,0.3c-0.09,0.18-0.16,0.4-0.21,0.67
			c-0.03,0.18-0.05,0.38-0.06,0.61c0,0.23,0.01,0.48,0.05,0.75c0.02,0.14,0.04,0.28,0.08,0.43c0.11-0.08,0.27-0.15,0.44-0.21
			c0.13-0.04,0.28-0.08,0.43-0.1c0.1-0.02,0.2-0.03,0.31-0.03c0.1-0.01,0.21-0.01,0.31,0c0.2,0.01,0.4,0.05,0.57,0.11
			c0.04,0.02,0.08,0.03,0.12,0.06c0.28,0.14,0.5,0.32,0.66,0.57c0.05,0.07,0.09,0.15,0.12,0.24c0.07,0.17,0.11,0.37,0.13,0.6
			c0,0.06,0.01,0.12,0.01,0.18c0,0.13-0.01,0.26-0.02,0.41c-0.01,0.15-0.03,0.29-0.05,0.42c-0.05,0.4-0.12,0.74-0.18,0.99
			c-0.08,0.34-0.14,0.53-0.14,0.54c0,0,0.02-0.01,0.04-0.02c0.15-0.09,0.69-0.43,0.93-0.9c0.05-0.1,0.07-0.23,0.08-0.37
			C153.53,481.27,153.52,481.17,153.51,481.06z"
          />
          <path
            fill="#D7BFB1"
            d="M156.12,473.29c-0.3-1.73-2.63-0.56-5.4-0.19c-2.78,0.37-6.03-0.22-9.81-0.69
			c-1.45-0.18-2.86-0.4-4.04-0.61c0.05-0.02,0.09-0.03,0.11-0.02l-2.62-0.59c0.35-0.48,1.16-1.27,2.92-1.77
			c2.66-0.75,2.29-0.53,5.29-1.84c3-1.31,2.95-3.79-0.02-3.29c-2.97,0.51-1.38-0.56-4.67,0.62c-3.29,1.18-6.27,1.03-7.36,1.81
			c-2.16,1.54-4.65,2.45-7.28,4.45c-0.51,0.39-0.96,0.78-1.37,1.16c1.09,6.82-6.65,20.61-5.35,24.3
			c3.43,0.36,17.35-9.51,30.98-10.51c0.61-0.04,1.23-0.09,1.86-0.13c0.05-0.03,0.1-0.08,0.15-0.11c0.15-0.11,0.3-0.24,0.44-0.37
			c0.6-0.55,1.11-1.21,1.45-1.66c0.47-0.55,0.89-1.05,0.89-1.05s0.1-0.07,0.24-0.16c0.15-0.09,0.69-0.43,0.93-0.9
			c0.28-0.49,0.52-1.71,0.4-3.25c-0.07-0.89-0.22-2.64-0.22-2.64s1.37-0.36,2.03-1.18C156,474.29,156.22,473.84,156.12,473.29z"
          />
          <path
            fill="#AD7D62"
            d="M152.58,474.28c-2.61-0.16-3.01,1.44-3.95,2.12c-0.94,0.68-2.81,0.83-4.37,0.66
			c-1.05-0.12-1.05-2.17-1.05-2.17c-0.28,1.26,0.37,3.11,1.38,3.61c0,0,2.42-0.18,3.83-0.22c-0.69,0.82-0.6,2.22-1.01,2.5
			c-0.54,0.37-0.6,0.68-2.92,0.81c-2.85,0.17-6.32,0.49-3.06,0.56c1.12,0.02,1.22,0.36,2.4,0.13c1.18-0.23,2.99-0.21,2.99-0.21
			c0.4-0.39,1.61-1.44,1.61-1.44c0.12-1.47,0.92-2.91,0.92-2.91c0.21-0.14,0.53-3.45,2.72-3.23c0,0,1.14-0.03,1.56,1.35
			c0,0,1.37-0.36,2.03-1.18c0.07-0.08,0.13-0.17,0.18-0.26C155.84,474.4,154.33,474.39,152.58,474.28z"
          />
          <path
            fill="#AD7D62"
            d="M150.53,480.87c-0.05-1.03,0.6-2.27,0.6-2.27s-1.39,0.37-2.14,1.51c-0.75,1.14-0.54,2.68-0.54,2.68
			s-0.31-0.67-0.14-1.06c0.17-0.39-1.47,1.16-1.96,1.49c-0.5,0.33-1.59,0.38-2.49,0.65c-0.73,0.22-1.17,0.77-1.22,1.38
			c-0.82,0.16-0.99,0.55-2.92,0.55c-1.98,0-3.95,0.12-4.77,0.04c-0.82-0.08,0.79,0.25,1.91,0.21c1.12-0.04,2.12,0.05,3.51-0.02
			c1.17-0.06,2.09-0.06,2.56-0.09c0.05,0,0.11,0,0.17-0.01c0.02,0,0.04,0,0.05,0c0.03,0,0.05-0.01,0.06-0.03
			c0.56-0.18,1.41-0.85,2.06-0.96c0.97-0.17,1.81,0.09,2.06,0.58c0.09,0.18,0.14,0.41,0.17,0.63c0.61-0.04,1.23-0.09,1.86-0.13h0
			c0.05-0.03,0.1-0.08,0.15-0.11c0.15-0.11,0.3-0.24,0.44-0.37c0.6-0.55,1.11-1.21,1.45-1.66c0.1-0.13,0.19-0.26,0.25-0.34
			C151.99,483.06,150.58,481.91,150.53,480.87z"
          />
          <path fill="#AD7D62" d="M136.97,471.78l0.03,0.01C137,471.78,136.99,471.78,136.97,471.78z" />
          <path
            fill="#AD7D62"
            d="M130.07,477.01c0,0,3.63-1.77,5.14-3.61c0.99-1.2,1.45-1.53,1.65-1.6c0.05-0.02,0.09-0.03,0.11-0.02
			l-2.62-0.59l-0.02,0C133.76,472.19,133.58,475.26,130.07,477.01z"
          />
          <path
            fill="#42637C"
            d="M176.27,493.58c-0.07-2.61-0.61-4.75-0.89-5.73c-0.02-0.07-0.04-0.14-0.06-0.21
			c-0.03-0.1-0.06-0.18-0.08-0.24c-0.16-0.62-0.32-1.13-0.45-1.52c-0.1-0.31-0.19-0.54-0.23-0.66c-0.03-0.08-0.05-0.12-0.05-0.12
			c-14.95-0.08-16.92,0.34-25.12,0.89c-0.01,0-0.03,0-0.04,0c-0.64,0.04-1.26,0.09-1.86,0.13c-13.63,1-18.99,2.31-22.42,1.95
			c-1.3-3.69-2.12-8.92-3.21-15.74c-0.22-1.39-0.46-2.85-0.71-4.37c-1.93-11.7-1.68-13.27-3.09-21.89
			c-1.33-16.93-6.58-29.04-32.47-37.7c-1.33-0.33-2.71-0.66-4.14-0.97c-0.14-0.05-0.28-0.09-0.42-0.14
			c0.01,0.03,0.02,0.05,0.02,0.05c-2.75,0.39-5.46,0.65-8.88,0.5c0,0,0,0-0.01,0c0,0.01,0.01,0.01,0.01,0.01S31.17,392.86,31.96,392
			c-4.65,0.85-21.2,5.17-20.94,26.4c0.01,0.76,0,1.45-0.04,2.07c0,24.04,5.41,38.22,9.79,54.69c1.66,6.24,3.17,12.8,4.18,20.36
			c0.32,2.36,0.58,4.81,0.79,7.37c0.14,1.78,0.25,3.55,0.34,5.3c0.51,10.76-0.06,20.75-0.81,28.35c-0.38,3.81-0.8,7.02-1.16,9.43
			c-0.09,0.63-0.19,1.22-0.27,1.74c-0.03,0.17-0.06,0.34-0.08,0.5c-0.24,0.5-0.47,1.03-0.71,1.56c-0.14,0.31-0.27,0.63-0.41,0.95
			c-0.12,0.28-0.24,0.56-0.35,0.85c0,0,0,0,0,0c-0.04,0.1-0.08,0.2-0.13,0.31c-4.17,10.32-8.35,27.03-10.18,53.9
			c-2.5,36.77,3.47,97.04,2.91,117.68c-0.89,33.31-2.01,46.77-2.94,56.65c-0.42,3.76-0.72,6.44-0.85,7.6
			c-0.12,1.17-0.33,3.55-0.31,5.84c0.01,0.87,0.05,1.73,0.15,2.51c0.69,3.06,3.3,5.26,6.88,6.74c0.69,0.29,1.42,0.55,2.18,0.78
			c3.28,0.88,9.56,2.16,16.04,1.29c0,0-0.45,0.07-1.16,0.25c0.36-0.02,0.73-0.04,1.08-0.07c2.48-0.18,4.85-0.54,6.96-1.05
			c0.27-4.83,4.86-86.54,7.32-108.21c2.78-24.53,5.42-58.57,10.52-65.96c0.38,4.76,1.8,17.6,2.97,27.23l-0.02,4.85
			c0.03,0.22,0.06,0.5,0.1,0.85c0.05-0.51,0.09-1.01,0.14-1.52c2.89,25.11,4.1,56.66,4.85,90.24c0.3,13.33,0.48,25.65,0.67,35.88
			c0.61,15.45,1.01,25.92,1.01,25.92c0.08,0.13,0.17,0.25,0.25,0.38c2.53,3.62,7.17,5.74,11.73,6.98
			c-0.15,3.88-2.49,9.99-2.91,11.05c0.02-0.02,0.04-0.03,0.06-0.04c0.01-0.01,0.02-0.01,0.02-0.01l0,0c0,0,2.33,0.11,5.4,1.58
			c0.15,0.07,0.29,0.14,0.44,0.22c0.3,0.15,0.6,0.32,0.91,0.5c0.31,0.18,0.62,0.37,0.94,0.58c0.32,0.21,0.64,0.43,0.96,0.67
			c0.32,0.24,0.65,0.5,0.97,0.77c0.16,0.14,0.33,0.28,0.49,0.42c0.16,0.15,0.33,0.3,0.49,0.45c0,0,0.03,0.02,0.08,0.07
			c0.02,0.02,0.04,0.03,0.06,0.05c0.16,0.14,0.45,0.42,0.85,0.86c0.28,0.31,0.62,0.7,1,1.18c0.15,0.19,0.31,0.4,0.48,0.62
			c0.5,0.66,1.05,1.45,1.64,2.39c0.3,0.47,0.6,0.97,0.92,1.52c0.31,0.54,0.63,1.13,0.96,1.75c0.22,0.41,0.44,0.85,0.66,1.3
			c1.21,2.47,2.71,4.78,4.39,6.94c0.56,0.72,1.14,1.43,1.74,2.11c0,0,0,0,0,0c-0.64-1.55-1.43-3.6-2.22-5.85
			c-0.09-0.25-0.17-0.5-0.26-0.76c-0.17-0.51-0.35-1.03-0.52-1.55c-0.6-1.83-1.16-3.72-1.63-5.53c-0.13-0.52-0.26-1.03-0.38-1.53
			c-0.23-1.01-0.43-1.97-0.57-2.88c-0.18-1.13-0.28-2.16-0.27-3.04c0-0.35,0.02-0.68,0.06-0.98c0.08-0.62,0.17-1.2,0.27-1.75
			c0.03-0.18,0.07-0.36,0.11-0.54c0.22-1.06,0.49-1.99,0.78-2.8c0.52-1.49,1.11-2.58,1.6-3.33c0.18-0.27,0.34-0.5,0.48-0.69
			c0.14-0.19,0.27-0.33,0.36-0.43c0.07-0.08,0.13-0.13,0.16-0.17c0.03-0.03,0.05-0.05,0.05-0.05l0.04-1.43
			c4.25-0.44,7.3-1.85,7.65-4.55c1.89-14.77,10.02-145.75,9.31-197.26c-0.41-30.08-5.58-56.6-9.66-69.72
			c0.29-0.1,0.56-0.19,0.82-0.29c-0.01-0.21-0.48-3.07-0.51-3.27c-1.06-6.91-1.42-11.42-1.43-15.01c0.16-2.03,0.36-8.42,0.58-10.34
			c-0.13-0.01-0.27-0.03-0.4-0.05c0.13,0.02,0.27,0.04,0.4,0.05c1.46,0.13,2.88-0.02,4.62-0.04c1.68-0.01,3.7-0.14,6.2-0.33
			c0.01,0,0.03,0,0.04,0c1.11,0.02,2.31-0.06,3.58-0.21c0.07-0.01,0.14-0.02,0.21-0.02c0.03,0,0.06-0.01,0.09-0.01c0,0,0,0,0,0
			c0.36-0.05,0.72-0.1,1.09-0.16c0.02,0,0.03,0,0.05,0c0.02,0,0.03,0,0.05,0h0l0,0l0,0c1.33-0.22,2.72-0.51,4.16-0.86c0,0,0,0,0,0
			c0.07-0.02,0.14-0.04,0.22-0.05c0,0,0.01,0,0.01,0c5.53-1.38,11.65-3.61,17.15-5.76c0.13-0.05,0.26-0.09,0.41-0.15
			c12.38-4.66,28.47-11.91,28.47-11.91C175.93,499.46,176.35,496.28,176.27,493.58z"
          />
          <path
            fill="#D7BFB1"
            d="M103.97,366.25c-0.67-1.33-3.01-5.87-3.84-7.9c-0.19-0.46-0.31-0.84-0.38-1.17
			c-0.01,0.04-0.03,0.08-0.04,0.11c0,0,0.14,0.16,0.17,0.51c0.03,0.35-0.34-0.96-0.85-0.96c-0.09,0-0.12-0.03-0.12-0.07
			c-0.18,0.19-0.38,0.45-0.62,0.8c-0.05,0.08,0.72-1.62,1.68-2.32c0.19-0.48,0.44-1.18,0.58-1.88c0.01-0.07,0.32-0.47,0.52-0.73
			c0.12-0.11,0.39-0.49,0.39-0.49c0.21-0.38,0.35-0.79,0.24-1.23c-0.03-0.69-0.13-1.66-0.16-2.01c-0.07-0.87-0.11-1.59-2.8-11.57
			c-0.13-0.47-0.29-0.94-0.46-1.4c-2.09-2.74-6.27-6.01-6.27-6.01s-59.06,63.34-57.35,65.91c1.71,2.57,26.85,12.13,37.53,11.99
			c0.04,0,0.08-0.01,0.13-0.02c3.34,0.14,6.01-0.12,8.72-0.5c0,0-0.01-0.02-0.02-0.05c-0.17-0.5-1.51-4.62-1.51-8.37
			c0-1.83,0.03-3.15,0.08-4.15c0.29-1.54,1.14-4.57,3.57-6.13c1.92-1.23,3.23-1.19,6.82-0.4c2.5,0.54,4.17,0.74,3.98,0.6
			c2.64-0.09,3.31-2.5,3.17-4.02c-0.15-1.68,0-2.35,1.33-4.1c0,0,0.53-0.45,0.43-1.52c-0.1-1.06-1.25-2.33-1.25-2.33
			s0.03-0.05,0.07-0.15c0.37-0.05,1.09,0.27,1.56,0.08c1.13-0.48,1.23-1.36,1.05-1.91c-0.3-1.1-0.89-3.41-0.89-3.41
			s0.03-0.05,0.09-0.12c1.04-0.8,2.61-1.8,3.53-2.39c0.59-0.38,0.8-0.85,0.85-0.98C104.25,367.48,104.31,366.92,103.97,366.25z
			 M77.14,376.84c-0.24,3.23-0.62,6.01-1.12,8.51c-0.69,3.44-1.61,6.36-2.7,9.17c-0.08-0.25-0.12-0.4-0.12-0.4l3.79-24
			C77.19,372.27,77.31,374.54,77.14,376.84z"
          />
          <path fill="none" d="M98.86,375.64c0.08-0.03,0.16-0.06,0.23-0.13C99.01,375.56,98.94,375.6,98.86,375.64z" />
          <path
            fill="none"
            d="M117.97,468.38c-0.15-0.96-0.3-1.93-0.46-2.9C117.66,466.45,117.82,467.42,117.97,468.38z"
          />
          <path fill="none" d="M88.31,835.19c0.32,0.24,0.65,0.5,0.97,0.77C88.96,835.69,88.63,835.43,88.31,835.19z" />
          <path
            fill="none"
            d="M17.34,702.35c0.22,15.33-1.43,36.89-3.04,54.84C15.95,739.56,17.56,717.99,17.34,702.35z"
          />
          <path fill="none" d="M96.25,845.26c0.22,0.41,0.44,0.85,0.66,1.3C96.69,846.11,96.47,845.68,96.25,845.26z" />
          <path fill="none" d="M97.3,376.28c0.24-0.51,0.39-0.91,0.53-1.5C97.69,375.27,97.52,375.78,97.3,376.28z" />
          <path fill="none" d="M102.98,533.32c0.16-0.6,0.31-1.21,0.45-1.84C103.29,532.1,103.14,532.72,102.98,533.32z" />
          <path fill="none" d="M119.23,500.56c-0.01,0-0.01,0-0.02-0.01C119.22,500.56,119.22,500.56,119.23,500.56z" />
          <path
            fill="none"
            d="M96.67,377.79c0.06-0.23,0.13-0.44,0.21-0.63c-0.32,0.74-0.44,1.71-0.5,2.39c0.02-0.23,0.05-0.47,0.09-0.71
			C96.51,378.5,96.58,378.14,96.67,377.79z"
          />
          <path
            fill="none"
            d="M98.17,372.76c0-0.01,0-0.02,0-0.03c0,0.02,0,0.04,0,0.06c0-0.01,0-0.01,0-0.01
			C98.17,372.77,98.17,372.76,98.17,372.76z"
          />
          <g>
            <path
              fill="#AD7D62"
              d="M26.99,810.22c-1.18,3.03,0.13,7.11,0.92,9.08c3.23,6.5,3.85,12.44,6.51,21.3
				c2.67,8.9,4.14,10.66,6.51,10.07c0.45-0.11,0.96-0.19,1.5-0.22c-1.46-1.81-5.27-7.16-6.38-14.98
				c-1.38-9.67-3.18-22.79-0.07-30.43c-0.36,0.03-0.72,0.05-1.08,0.07C32.74,805.64,28.21,807.11,26.99,810.22z"
            />
            <path
              fill="#FFFFFF"
              d="M26.99,810.22c-1.18,3.03,0.13,7.11,0.92,9.08c3.23,6.5,3.85,12.44,6.51,21.3
				c2.67,8.9,4.14,10.66,6.51,10.07c0.45-0.11,0.96-0.19,1.5-0.22c-1.46-1.81-5.27-7.16-6.38-14.98
				c-1.38-9.67-3.18-22.79-0.07-30.43c-0.36,0.03-0.72,0.05-1.08,0.07C32.74,805.64,28.21,807.11,26.99,810.22z"
            />
            <path
              fill="#D7BFB1"
              d="M26.99,810.22c-1.18,3.03,0.13,7.11,0.92,9.08c3.23,6.5,3.85,12.44,6.51,21.3
				c2.67,8.9,4.14,10.66,6.51,10.07c0.45-0.11,0.96-0.19,1.5-0.22c-1.46-1.81-5.27-7.16-6.38-14.98
				c-1.38-9.67-3.18-22.79-0.07-30.43c-0.36,0.03-0.72,0.05-1.08,0.07C32.74,805.64,28.21,807.11,26.99,810.22z"
            />
          </g>
          <g>
            <path
              fill="#AD7D62"
              d="M17.82,802.79c0,0.04,0,0.08,0,0.12c0.32,0.11,1.08,0.36,2.19,0.66
				C19.25,803.34,18.52,803.08,17.82,802.79z"
            />
            <path
              fill="#FFFFFF"
              d="M17.82,802.79c0,0.04,0,0.08,0,0.12c0.32,0.11,1.08,0.36,2.19,0.66
				C19.25,803.34,18.52,803.08,17.82,802.79z"
            />
            <path
              fill="#D7BFB1"
              d="M17.82,802.79c0,0.04,0,0.08,0,0.12c0.32,0.11,1.08,0.36,2.19,0.66
				C19.25,803.34,18.52,803.08,17.82,802.79z"
            />
          </g>
          <g>
            <path
              fill="#AD7D62"
              d="M119.91,861.14c-0.08,0.01-0.15,0.01-0.23,0.02c0.34,0.02,0.7,0.05,1.08,0.1
				C120.52,861.15,120.24,861.11,119.91,861.14z"
            />
            <path
              fill="#FFFFFF"
              d="M119.91,861.14c-0.08,0.01-0.15,0.01-0.23,0.02c0.34,0.02,0.7,0.05,1.08,0.1
				C120.52,861.15,120.24,861.11,119.91,861.14z"
            />
            <path
              fill="#D7BFB1"
              d="M119.91,861.14c-0.08,0.01-0.15,0.01-0.23,0.02c0.34,0.02,0.7,0.05,1.08,0.1
				C120.52,861.15,120.24,861.11,119.91,861.14z"
            />
          </g>
          <g>
            <path
              fill="#AD7D62"
              d="M100.51,824.38c-0.14,0.19-0.31,0.41-0.48,0.69c-0.49,0.75-1.07,1.84-1.6,3.33
				c-0.29,0.81-0.55,1.74-0.78,2.8c-0.04,0.18-0.07,0.36-0.11,0.54c-0.1,0.55-0.2,1.14-0.27,1.75c-0.04,0.3-0.06,0.63-0.06,0.98
				c-0.01,0.88,0.09,1.91,0.27,3.04c0.14,0.9,0.34,1.87,0.57,2.88c0.12,0.5,0.24,1.02,0.38,1.53c0.47,1.81,1.03,3.7,1.63,5.53
				c0.17,0.52,0.34,1.04,0.52,1.55c0.09,0.25,0.17,0.51,0.26,0.76c0.78,2.25,1.57,4.3,2.22,5.85c2.53,2.9,5.37,5.54,8.29,7.94
				c0.06,0.05,0.12,0.09,0.18,0.13c0.89,0.43,1.79,0.68,2.75,0.77c0.73,0,1.51-0.07,2.37-0.08c0.7-0.1,1.44-0.25,2.24-0.46
				c0,0-0.27-2.28-1.58-2.76c0,0,0.18-0.02,0.5-0.03c-2.93-0.35-5.95-2.12-7.63-6.59c-2.09-5.56-3.48-8.35-6.26-14.96
				c-1.48-3.53-2.67-8.44-2.88-15.79c-0.04,0.04-0.09,0.09-0.16,0.17C100.77,824.06,100.65,824.2,100.51,824.38z"
            />
            <path
              fill="#FFFFFF"
              d="M100.51,824.38c-0.14,0.19-0.31,0.41-0.48,0.69c-0.49,0.75-1.07,1.84-1.6,3.33
				c-0.29,0.81-0.55,1.74-0.78,2.8c-0.04,0.18-0.07,0.36-0.11,0.54c-0.1,0.55-0.2,1.14-0.27,1.75c-0.04,0.3-0.06,0.63-0.06,0.98
				c-0.01,0.88,0.09,1.91,0.27,3.04c0.14,0.9,0.34,1.87,0.57,2.88c0.12,0.5,0.24,1.02,0.38,1.53c0.47,1.81,1.03,3.7,1.63,5.53
				c0.17,0.52,0.34,1.04,0.52,1.55c0.09,0.25,0.17,0.51,0.26,0.76c0.78,2.25,1.57,4.3,2.22,5.85c2.53,2.9,5.37,5.54,8.29,7.94
				c0.06,0.05,0.12,0.09,0.18,0.13c0.89,0.43,1.79,0.68,2.75,0.77c0.73,0,1.51-0.07,2.37-0.08c0.7-0.1,1.44-0.25,2.24-0.46
				c0,0-0.27-2.28-1.58-2.76c0,0,0.18-0.02,0.5-0.03c-2.93-0.35-5.95-2.12-7.63-6.59c-2.09-5.56-3.48-8.35-6.26-14.96
				c-1.48-3.53-2.67-8.44-2.88-15.79c-0.04,0.04-0.09,0.09-0.16,0.17C100.77,824.06,100.65,824.2,100.51,824.38z"
            />
            <path
              fill="#D7BFB1"
              d="M100.51,824.38c-0.14,0.19-0.31,0.41-0.48,0.69c-0.49,0.75-1.07,1.84-1.6,3.33
				c-0.29,0.81-0.55,1.74-0.78,2.8c-0.04,0.18-0.07,0.36-0.11,0.54c-0.1,0.55-0.2,1.14-0.27,1.75c-0.04,0.3-0.06,0.63-0.06,0.98
				c-0.01,0.88,0.09,1.91,0.27,3.04c0.14,0.9,0.34,1.87,0.57,2.88c0.12,0.5,0.24,1.02,0.38,1.53c0.47,1.81,1.03,3.7,1.63,5.53
				c0.17,0.52,0.34,1.04,0.52,1.55c0.09,0.25,0.17,0.51,0.26,0.76c0.78,2.25,1.57,4.3,2.22,5.85c2.53,2.9,5.37,5.54,8.29,7.94
				c0.06,0.05,0.12,0.09,0.18,0.13c0.89,0.43,1.79,0.68,2.75,0.77c0.73,0,1.51-0.07,2.37-0.08c0.7-0.1,1.44-0.25,2.24-0.46
				c0,0-0.27-2.28-1.58-2.76c0,0,0.18-0.02,0.5-0.03c-2.93-0.35-5.95-2.12-7.63-6.59c-2.09-5.56-3.48-8.35-6.26-14.96
				c-1.48-3.53-2.67-8.44-2.88-15.79c-0.04,0.04-0.09,0.09-0.16,0.17C100.77,824.06,100.65,824.2,100.51,824.38z"
            />
          </g>
          <path
            fill="#D7BFB1"
            d="M149.35,486c0.01,0,0.03,0,0.04,0c0.2-0.15,0.39-0.32,0.56-0.48c-0.14,0.13-0.29,0.26-0.44,0.37
			C149.45,485.92,149.4,485.96,149.35,486z"
          />
          <path
            fill="#D7BFB1"
            d="M204.74,476.45c0.01-0.05,0.06-0.29-0.02-0.57C204.73,476.03,204.76,476.27,204.74,476.45z"
          />
          <path
            fill="#AD7D62"
            d="M202.51,482.57c0.07,0,0.14,0.01,0.21,0.02c0.43-1.64,1.01-4.13,1.31-4.97c0.23-0.62,0.42-0.96,0.62-1.11
			c0.06-0.04,0.08-0.06,0.08-0.06c0,0,0,0,0,0c0.01,0,0,0,0,0c0,0,0,0,0-0.01c0.02-0.18,0-0.42-0.02-0.57
			c-0.01-0.07-0.02-0.12-0.02-0.12c-0.01,0-0.01,0.01-0.02,0.01C203.68,476.35,203.15,480.34,202.51,482.57z"
          />
          <path
            fill="#AD7D62"
            d="M198.75,483.15c0.16-0.39,1.01-1.42,1.01-1.42c0.16-0.96,0.73-2.38,0.73-2.38
			c-0.51,0.85-1.01,2.31-1.01,2.31c-0.45,0.45-1.44,1.33-1.92,1.74c0.32-0.06,0.66-0.13,1.02-0.19L198.75,483.15z"
          />
          <path
            fill="#D7BFB1"
            d="M209.5,484.77c0.17-0.37,0.29-0.69,0.37-0.98c-0.15,0.39-0.34,0.88-0.55,1.43
			C209.39,485.05,209.45,484.9,209.5,484.77z"
          />
          <path
            fill="#AD7D62"
            d="M209.12,481.5c0.01,0.01,0.01,0.01,0.02,0.02c0.54,0.42,0.21,1.86-0.7,3.49c-0.92,1.65-2.82,5.64-3.8,7.02
			c-0.98,1.38-2.51,1.79-4.47,2.89c-1.52,0.85-3.37,0.12-5.14-0.68c0-0.03-0.02-0.05-0.06-0.07c-1.04-0.44-2.65-1.52-2.65-1.52
			s-0.29-0.18,1.11-1.11c1.4-0.92,4.32-1.66,5.21-2.03c0.26-0.11,0.54-0.24,0.84-0.38c-1.22,1.05-2.18,2.23-2.55,2.72
			c1.25-1.61,3.4-3.15,3.4-3.15s0.01-0.01,0.01-0.02c0.58-0.32,1.25-0.73,2.05-1.29c0.32-0.22,0.58-0.46,0.79-0.69
			c-0.97,1.85-1.94,3.71-1.94,3.71c-1.15,0.56-3.34,2.36-3.34,2.36c-1.99-1.33-4.03-0.45-4.03-0.45c1.68-0.27,1,0.4,2.12,0.3
			s1.71,0.8,2.48,0.07c0.77-0.73,2.86-2.09,2.86-2.09s1.53-2.39,2.04-3.28c0.5-0.89,1.33-2.59,2.18-3.84
			c0.86-1.26,1.42-1.69,1.42-1.69l0.03-0.98c0,0-0.01,0-0.01,0.01c-0.08,0.07-1.47,1.42-2.27,2.93c-0.01,0.02-0.02,0.04-0.03,0.06
			c0.01,0.05,0.03,0.09,0.04,0.15c0.26,1.69-2.73,2.72-3.33,2.9c-1.02,0.51-2.37,1.09-4.06,1.74c-1.58,0.61-3.3,1.56-4.71,2.43
			c-1.78,0.94-13.02,2.3-16.38,2.56c0.07,2.69-0.35,5.88-2.05,8.76c4.04-0.14,17.63,1.65,19.9,2.06c2.27,0.41,3.55-2.41,5.82-4.2
			c2.27-1.79,4.53-3.92,5.08-4.54c0.55-0.62,1.71-4.4,2.51-6.05c0.56-1.17,1.25-2.96,1.8-4.4c0.21-0.55,0.4-1.04,0.55-1.43
			c0.01-0.03,0.02-0.06,0.03-0.09C210.46,482.33,209.97,481.78,209.12,481.5z"
          />
          <path
            fill="#E8E7D9"
            d="M200.12,483.6c0,0-0.36,2.29,1.2,3.27c0,0,0.04-0.01,0.1-0.03c0.6-0.19,3.59-1.21,3.33-2.9
			c-0.01-0.06-0.03-0.1-0.04-0.15C204.22,481.77,200.86,483.34,200.12,483.6z"
          />
          <path
            fill="#42637C"
            d="M10.77,793.86c-0.05,0.77,0.01,1.5,0.17,2.19c-0.1-0.77-0.14-1.63-0.15-2.51
			C10.79,793.65,10.78,793.76,10.77,793.86z"
          />
          <path
            fill="#42637C"
            d="M63.75,657.05c-1.17-9.63-2.59-22.47-2.97-27.23c-5.1,7.39-7.74,41.43-10.52,65.96
			c-2.45,21.68-7.05,103.39-7.32,108.21c4.76-1.15,8.18-3.08,8.41-5.56c0.45-4.92,6.89-74.96,12.48-135.68
			c-0.03-0.35-0.07-0.63-0.1-0.85L63.75,657.05z"
          />
          <g>
            <path
              fill="#85BED3"
              d="M16.43,857.35c0.11,0.11,0.23,0.12,0.31-0.15c-0.05,0.07-0.11,0.11-0.17,0.12
				C16.52,857.33,16.47,857.34,16.43,857.35z"
            />
            <path
              fill="#FFFFFF"
              d="M16.43,857.35c0.11,0.11,0.23,0.12,0.31-0.15c-0.05,0.07-0.11,0.11-0.17,0.12
				C16.52,857.33,16.47,857.34,16.43,857.35z"
            />
            <path
              fill="#41647D"
              d="M16.43,857.35c0.11,0.11,0.23,0.12,0.31-0.15c-0.05,0.07-0.11,0.11-0.17,0.12
				C16.52,857.33,16.47,857.34,16.43,857.35z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M13.39,849.44c-0.2-4.49-0.63-10.37-1.31-13.33C12.62,839.19,13.08,844.79,13.39,849.44z"
            />
            <path
              fill="#FFFFFF"
              d="M13.39,849.44c-0.2-4.49-0.63-10.37-1.31-13.33C12.62,839.19,13.08,844.79,13.39,849.44z"
            />
            <path
              fill="#41647D"
              d="M13.39,849.44c-0.2-4.49-0.63-10.37-1.31-13.33C12.62,839.19,13.08,844.79,13.39,849.44z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M19.52,840.03c0.09-0.06,0.16-0.11,0.23-0.15C19.65,839.85,19.57,839.9,19.52,840.03z"
            />
            <path
              fill="#FFFFFF"
              d="M19.52,840.03c0.09-0.06,0.16-0.11,0.23-0.15C19.65,839.85,19.57,839.9,19.52,840.03z"
            />
            <path
              fill="#41647D"
              d="M19.52,840.03c0.09-0.06,0.16-0.11,0.23-0.15C19.65,839.85,19.57,839.9,19.52,840.03z"
            />
          </g>
          <g>
            <path fill="#85BED3" d="M9.31,824.86c0.12-0.91,0.32-1.77,0.57-2.58C9.55,823.17,9.34,824.04,9.31,824.86z" />
            <path fill="#FFFFFF" d="M9.31,824.86c0.12-0.91,0.32-1.77,0.57-2.58C9.55,823.17,9.34,824.04,9.31,824.86z" />
            <path fill="#41647D" d="M9.31,824.86c0.12-0.91,0.32-1.77,0.57-2.58C9.55,823.17,9.34,824.04,9.31,824.86z" />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M13.84,857.22l0.1-0.02c-0.04-0.05-0.07-0.11-0.11-0.18C13.84,857.15,13.84,857.22,13.84,857.22z"
            />
            <path
              fill="#FFFFFF"
              d="M13.84,857.22l0.1-0.02c-0.04-0.05-0.07-0.11-0.11-0.18C13.84,857.15,13.84,857.22,13.84,857.22z"
            />
            <path
              fill="#41647D"
              d="M13.84,857.22l0.1-0.02c-0.04-0.05-0.07-0.11-0.11-0.18C13.84,857.15,13.84,857.22,13.84,857.22z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M45.7,855.99c-0.41,0.12-1.16,0.33-2.17,0.54C44.43,856.37,45.14,856.18,45.7,855.99z"
            />
            <path
              fill="#FFFFFF"
              d="M45.7,855.99c-0.41,0.12-1.16,0.33-2.17,0.54C44.43,856.37,45.14,856.18,45.7,855.99z"
            />
            <path
              fill="#41647D"
              d="M45.7,855.99c-0.41,0.12-1.16,0.33-2.17,0.54C44.43,856.37,45.14,856.18,45.7,855.99z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M34.42,840.61c-2.66-8.87-3.28-14.8-6.51-21.3c0.29,0.72,0.51,1.16,0.51,1.16s-0.77-1.2-2.05-2.6
				c1.6,3.62,0.41,6.99,2.96,14.47c3.06,8.99,4.74,17.36,5.59,19.65c2.2,5.92,4.82,4.09,11.07,3.9c2.01-0.76,1.77-1.56,1.77-1.56
				c-0.24-3.16-3.03-3.99-5.33-3.87c-0.54,0.03-1.05,0.1-1.5,0.22C38.56,851.26,37.09,849.5,34.42,840.61z"
            />
            <path
              fill="#FFFFFF"
              d="M34.42,840.61c-2.66-8.87-3.28-14.8-6.51-21.3c0.29,0.72,0.51,1.16,0.51,1.16s-0.77-1.2-2.05-2.6
				c1.6,3.62,0.41,6.99,2.96,14.47c3.06,8.99,4.74,17.36,5.59,19.65c2.2,5.92,4.82,4.09,11.07,3.9c2.01-0.76,1.77-1.56,1.77-1.56
				c-0.24-3.16-3.03-3.99-5.33-3.87c-0.54,0.03-1.05,0.1-1.5,0.22C38.56,851.26,37.09,849.5,34.42,840.61z"
            />
            <path
              fill="#41647D"
              d="M34.42,840.61c-2.66-8.87-3.28-14.8-6.51-21.3c0.29,0.72,0.51,1.16,0.51,1.16s-0.77-1.2-2.05-2.6
				c1.6,3.62,0.41,6.99,2.96,14.47c3.06,8.99,4.74,17.36,5.59,19.65c2.2,5.92,4.82,4.09,11.07,3.9c2.01-0.76,1.77-1.56,1.77-1.56
				c-0.24-3.16-3.03-3.99-5.33-3.87c-0.54,0.03-1.05,0.1-1.5,0.22C38.56,851.26,37.09,849.5,34.42,840.61z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M91.05,858.48c0.04,0.05,0.08,0.09,0.12,0.14c1.17,1.43,2.47,3.6,4.15,5.8
				c-0.36-0.56-0.72-1.14-1.07-1.74C93.14,860.78,92.05,859.41,91.05,858.48z"
            />
            <path
              fill="#FFFFFF"
              d="M91.05,858.48c0.04,0.05,0.08,0.09,0.12,0.14c1.17,1.43,2.47,3.6,4.15,5.8
				c-0.36-0.56-0.72-1.14-1.07-1.74C93.14,860.78,92.05,859.41,91.05,858.48z"
            />
            <path
              fill="#41647D"
              d="M91.05,858.48c0.04,0.05,0.08,0.09,0.12,0.14c1.17,1.43,2.47,3.6,4.15,5.8
				c-0.36-0.56-0.72-1.14-1.07-1.74C93.14,860.78,92.05,859.41,91.05,858.48z"
            />
          </g>
          <g>
            <path fill="#85BED3" d="M86.19,857.83c0.38-0.4,1-0.88,1.79-0.97C87.17,856.81,86.54,857.17,86.19,857.83z" />
            <path fill="#FFFFFF" d="M86.19,857.83c0.38-0.4,1-0.88,1.79-0.97C87.17,856.81,86.54,857.17,86.19,857.83z" />
            <path fill="#41647D" d="M86.19,857.83c0.38-0.4,1-0.88,1.79-0.97C87.17,856.81,86.54,857.17,86.19,857.83z" />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M79.64,831.63c0,0-0.01,0-0.02,0.01c-0.01,0.01-0.03,0.02-0.06,0.04c-0.36,0.26-1.92,1.5-3.05,4.38
				C77.75,833.37,79.64,831.63,79.64,831.63z"
            />
            <path
              fill="#FFFFFF"
              d="M79.64,831.63c0,0-0.01,0-0.02,0.01c-0.01,0.01-0.03,0.02-0.06,0.04c-0.36,0.26-1.92,1.5-3.05,4.38
				C77.75,833.37,79.64,831.63,79.64,831.63z"
            />
            <path
              fill="#41647D"
              d="M79.64,831.63c0,0-0.01,0-0.02,0.01c-0.01,0.01-0.03,0.02-0.06,0.04c-0.36,0.26-1.92,1.5-3.05,4.38
				C77.75,833.37,79.64,831.63,79.64,831.63z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M75.37,841.21c-0.38,4.94,1.49,8.38,3.48,15.72c0.06,0.22,0.11,0.43,0.17,0.65
				c-0.41-2.01-0.9-3.93-1.49-5.54C75.72,847.1,75.29,843.64,75.37,841.21z"
            />
            <path
              fill="#FFFFFF"
              d="M75.37,841.21c-0.38,4.94,1.49,8.38,3.48,15.72c0.06,0.22,0.11,0.43,0.17,0.65
				c-0.41-2.01-0.9-3.93-1.49-5.54C75.72,847.1,75.29,843.64,75.37,841.21z"
            />
            <path
              fill="#41647D"
              d="M75.37,841.21c-0.38,4.94,1.49,8.38,3.48,15.72c0.06,0.22,0.11,0.43,0.17,0.65
				c-0.41-2.01-0.9-3.93-1.49-5.54C75.72,847.1,75.29,843.64,75.37,841.21z"
            />
          </g>
          <g>
            <path fill="#85BED3" d="M85.29,861.26c-0.94,4.78-1.9,12.22-1.9,12.22L85.29,861.26z" />
            <path fill="#FFFFFF" d="M85.29,861.26c-0.94,4.78-1.9,12.22-1.9,12.22L85.29,861.26z" />
            <path fill="#41647D" d="M85.29,861.26c-0.94,4.78-1.9,12.22-1.9,12.22L85.29,861.26z" />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M127.8,869.89c1.53-0.66,2.26-1.41,2.55-2.12C130.13,868.2,129.5,869.04,127.8,869.89z"
            />
            <path
              fill="#FFFFFF"
              d="M127.8,869.89c1.53-0.66,2.26-1.41,2.55-2.12C130.13,868.2,129.5,869.04,127.8,869.89z"
            />
            <path
              fill="#41647D"
              d="M127.8,869.89c1.53-0.66,2.26-1.41,2.55-2.12C130.13,868.2,129.5,869.04,127.8,869.89z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M118.88,863.91c-0.81,0.21-1.55,0.37-2.24,0.46c2.09-0.02,4.63,0.36,7.91,3.16
				c2.53,2.16,5.6-2.76,5.9-0.12c0.28-1.28-0.72-2.35-0.72-2.35c-2.78-2.59-6.37-3.51-8.98-3.82c-0.38-0.05-0.74-0.08-1.08-0.1
				c-0.81-0.05-1.46-0.05-1.88-0.04c-0.32,0.01-0.5,0.03-0.5,0.03C118.61,861.63,118.88,863.91,118.88,863.91z"
            />
            <path
              fill="#FFFFFF"
              d="M118.88,863.91c-0.81,0.21-1.55,0.37-2.24,0.46c2.09-0.02,4.63,0.36,7.91,3.16
				c2.53,2.16,5.6-2.76,5.9-0.12c0.28-1.28-0.72-2.35-0.72-2.35c-2.78-2.59-6.37-3.51-8.98-3.82c-0.38-0.05-0.74-0.08-1.08-0.1
				c-0.81-0.05-1.46-0.05-1.88-0.04c-0.32,0.01-0.5,0.03-0.5,0.03C118.61,861.63,118.88,863.91,118.88,863.91z"
            />
            <path
              fill="#41647D"
              d="M118.88,863.91c-0.81,0.21-1.55,0.37-2.24,0.46c2.09-0.02,4.63,0.36,7.91,3.16
				c2.53,2.16,5.6-2.76,5.9-0.12c0.28-1.28-0.72-2.35-0.72-2.35c-2.78-2.59-6.37-3.51-8.98-3.82c-0.38-0.05-0.74-0.08-1.08-0.1
				c-0.81-0.05-1.46-0.05-1.88-0.04c-0.32,0.01-0.5,0.03-0.5,0.03C118.61,861.63,118.88,863.91,118.88,863.91z"
            />
          </g>
          <g>
            <path
              fill="#85BED3"
              d="M111.51,863.69c0.85,0.63,1.75,0.77,2.75,0.77C113.3,864.37,112.4,864.12,111.51,863.69z"
            />
            <path
              fill="#FFFFFF"
              d="M111.51,863.69c0.85,0.63,1.75,0.77,2.75,0.77C113.3,864.37,112.4,864.12,111.51,863.69z"
            />
            <path
              fill="#41647D"
              d="M111.51,863.69c0.85,0.63,1.75,0.77,2.75,0.77C113.3,864.37,112.4,864.12,111.51,863.69z"
            />
          </g>
          <path
            fill="#141616"
            d="M124.55,867.54c-3.29-2.81-5.82-3.18-7.91-3.16c-0.86,0.01-1.64,0.08-2.37,0.08c-1,0-1.89-0.14-2.75-0.77
			c-0.06-0.04-0.12-0.08-0.18-0.13c-2.92-2.4-5.77-5.04-8.29-7.94c0,0,0,0,0,0c-0.6-0.69-1.18-1.39-1.74-2.11
			c-1.68-2.16-3.18-4.47-4.39-6.94c-0.22-0.45-0.44-0.88-0.66-1.3c-0.33-0.62-0.65-1.2-0.96-1.75c-0.31-0.54-0.62-1.05-0.92-1.52
			c-0.59-0.94-1.15-1.73-1.64-2.39c-0.17-0.22-0.33-0.42-0.48-0.62c-0.38-0.48-0.72-0.87-1-1.18c-0.4-0.43-0.69-0.71-0.85-0.86
			c-0.02-0.02-0.04-0.04-0.06-0.05c-0.05-0.05-0.08-0.07-0.08-0.07c-0.16-0.15-0.33-0.31-0.49-0.45c-0.16-0.15-0.33-0.29-0.49-0.42
			c-0.33-0.27-0.65-0.53-0.97-0.77c-0.32-0.24-0.64-0.47-0.96-0.67c-0.32-0.21-0.63-0.4-0.94-0.58s-0.61-0.35-0.91-0.5
			c-0.15-0.08-0.3-0.15-0.44-0.22c-3.07-1.48-5.4-1.58-5.4-1.58l0,0c0,0-1.9,1.74-3.13,4.44c-0.31,0.67-0.57,1.39-0.76,2.17
			c-0.18,0.76-0.35,1.74-0.39,2.98c-0.08,2.43,0.35,5.89,2.16,10.83c0.59,1.61,1.08,3.53,1.49,5.54c1.48,7.27,1.87,15.81,1.87,15.81
			l2.5,0.09c0,0,0.96-7.44,1.9-12.22c0.25-1.25,0.49-2.32,0.72-3.02c0.05-0.15,0.11-0.29,0.18-0.42c0.35-0.65,0.98-1.02,1.79-0.97
			c0.85,0.05,1.91,0.54,3.07,1.62c1.01,0.93,2.09,2.3,3.19,4.2c0.35,0.61,0.71,1.18,1.07,1.74c3.06,4.71,6.55,7.74,11.47,7.92
			c12.22,0.46,18.15-1.01,21.02-2.44c1.7-0.85,2.33-1.7,2.55-2.12c0.09-0.18,0.11-0.29,0.11-0.29c0-0.02-0.01-0.04-0.01-0.06
			C130.16,864.78,127.08,869.7,124.55,867.54z"
          />
          <path
            fill="#141616"
            d="M34.92,851.99c-0.85-2.29-2.53-10.67-5.59-19.65c-2.54-7.48-1.35-10.85-2.96-14.47
			c-2.59-2.83-7.27-6.46-11.96-2.61c0,0,0.02-0.03,0.05-0.09c-1.51,1.41-3.53,3.78-4.58,7.11c-0.25,0.8-0.45,1.66-0.57,2.58
			c-0.52,4.01,1.5,6.94,2.58,10.54c0.06,0.21,0.13,0.45,0.19,0.71c0.68,2.96,1.12,8.85,1.31,13.33c0.08,1.81,0.12,3.4,0.12,4.47
			c0,1.61,0.07,2.57,0.32,3.11c0.03,0.07,0.07,0.13,0.11,0.18c0.4,0.56,1.14,0.46,2.49,0.15c0.05-0.01,0.09-0.02,0.14-0.03
			c0.07-0.01,0.12-0.05,0.17-0.12c0.37-0.49,0.44-2.49,1.01-7.55c0.53-4.78,1.19-7.99,1.77-9.62c0.05-0.14,0.13-0.18,0.23-0.15
			c0.8,0.24,3.13,5.45,4.73,10.25c1.8,5.39,0.97,6.37,8.16,7.09c4.39,0.44,8.42-0.16,10.89-0.69c1.01-0.22,1.76-0.42,2.17-0.54
			c0.22-0.07,0.35-0.11,0.35-0.11c-0.02,0-0.04,0-0.06,0C39.74,856.07,37.12,857.91,34.92,851.99z"
          />
          <path
            fill="#42637C"
            d="M26.37,817.87c1.28,1.4,2.05,2.6,2.05,2.6s-0.22-0.44-0.51-1.16c-0.79-1.97-2.1-6.05-0.92-9.08
			c1.22-3.12,5.75-4.58,7.9-5.11c0.7-0.17,1.16-0.25,1.16-0.25c-6.48,0.87-12.75-0.4-16.04-1.29c-1.1-0.3-1.86-0.55-2.19-0.66
			c-0.11-0.04-0.18-0.06-0.18-0.06c0.17,6.5-2.76,11.62-3.18,12.32c-0.03,0.05-0.05,0.09-0.05,0.09
			C19.1,811.41,23.79,815.04,26.37,817.87z"
          />
          <path
            opacity="0.3"
            d="M23.53,551.89c-0.01,0.02-0.01,0.03-0.02,0.04c-0.01,0.03-0.03,0.07-0.04,0.1
			c0.07,0.11,0.15,0.22,0.23,0.33C23.64,552.19,23.58,552.03,23.53,551.89z"
          />
          <path
            opacity="0.3"
            d="M22.27,551.57c0.11-0.3,0.22-0.59,0.34-0.88c0.01,0.01,0.01,0.02,0.02,0.03c0.14-0.32,0.27-0.64,0.41-0.95
			c0.24-0.54,0.48-1.06,0.71-1.56c0.03-0.16,0.05-0.33,0.08-0.5c0.08-0.52,0.17-1.1,0.27-1.74c-0.96,4.36-2.11,5.8-2.11,5.8
			s0.06,0.04,0.16,0.11C22.19,551.77,22.23,551.67,22.27,551.57C22.27,551.57,22.27,551.57,22.27,551.57z"
          />
          <path
            fill="#141616"
            d="M22.27,551.57c0.12-0.29,0.24-0.57,0.35-0.85c-0.01-0.01-0.01-0.02-0.02-0.03
			C22.5,550.97,22.38,551.27,22.27,551.57z"
          />
          <path
            opacity="0.3"
            d="M22.27,551.57c0.12-0.29,0.24-0.57,0.35-0.85c-0.01-0.01-0.01-0.02-0.02-0.03
			C22.5,550.97,22.38,551.27,22.27,551.57z"
          />
          <path
            fill="#141616"
            d="M23.91,552.85c-0.08-0.17-0.15-0.33-0.2-0.48c-0.08-0.11-0.16-0.23-0.23-0.33
			c-0.07,0.18-0.14,0.37-0.21,0.56c0.22,0.13,0.46,0.28,0.72,0.43C23.96,552.96,23.93,552.91,23.91,552.85z"
          />
          <path
            opacity="0.3"
            d="M23.91,552.85c-0.08-0.17-0.15-0.33-0.2-0.48c-0.08-0.11-0.16-0.23-0.23-0.33
			c-0.07,0.18-0.14,0.37-0.21,0.56c0.22,0.13,0.46,0.28,0.72,0.43C23.96,552.96,23.93,552.91,23.91,552.85z"
          />
          <path
            fill="#141616"
            d="M59.76,563.81c-1.62,0.32-2.57,0.51-2.57,0.51C58.05,564.18,58.91,564.01,59.76,563.81z"
          />
          <path
            opacity="0.3"
            d="M59.76,563.81c-1.62,0.32-2.57,0.51-2.57,0.51C58.05,564.18,58.91,564.01,59.76,563.81z"
          />
          <path
            fill="#141616"
            d="M65.4,562.66c-0.13,0.02-0.27,0.04-0.4,0.06c0,0,0,0-0.01,0c-0.33,0.05-0.67,0.1-0.99,0.14
			c-0.04,0.01-0.08,0.01-0.13,0.02c0.19-0.06,0.37-0.12,0.55-0.18c-0.04,0.01-0.07,0.01-0.11,0.02c0,0,0,0-0.01,0
			c-0.38,0.06-0.75,0.11-1.12,0.16c0,0,0,0,0,0c-1.13,0.35-2.27,0.66-3.44,0.93C61.19,563.53,63.14,563.13,65.4,562.66z"
          />
          <path
            opacity="0.3"
            d="M65.4,562.66c-0.13,0.02-0.27,0.04-0.4,0.06c0,0,0,0-0.01,0c-0.33,0.05-0.67,0.1-0.99,0.14
			c-0.04,0.01-0.08,0.01-0.13,0.02c0.19-0.06,0.37-0.12,0.55-0.18c-0.04,0.01-0.07,0.01-0.11,0.02c0,0,0,0-0.01,0
			c-0.38,0.06-0.75,0.11-1.12,0.16c0,0,0,0,0,0c-1.13,0.35-2.27,0.66-3.44,0.93C61.19,563.53,63.14,563.13,65.4,562.66z"
          />
          <path
            fill="#4D2C1A"
            d="M98.29,357.57c0.24-0.35,0.44-0.61,0.62-0.8c0,0.05,0.03,0.07,0.12,0.07c0.51-0.01,0.88,1.3,0.85,0.96
			c-0.03-0.35-0.17-0.51-0.17-0.51c0.02-0.04,0.03-0.08,0.04-0.11c0.12-0.34,0.19-0.71,0.22-0.92c0.1,0.01,0.2,0.02,0.31-0.01
			c0.86-0.28,1.58-2.65,1.04-1.89c-0.54,0.76-1.07,0.72-1.07,0.72c-0.09,0.05-0.18,0.1-0.27,0.17
			C99.02,355.95,98.24,357.65,98.29,357.57z"
          />
          <path
            fill="#4D2C1A"
            d="M96.85,327.7c0,0-3.86-6.72-15.01-10.24c-4.01-1.26-8.96-2.11-15.02-2.09
			c-25.87,0.12-31.86,27.65-28.23,48.99c1.99,11.71-0.56,20.82-6.39,27.36c-0.08,0.09-0.16,0.18-0.24,0.26
			c0.09,0.01,0.18,0.02-0.01,0.01c-0.79,0.86-1.63,1.68-2.53,2.44c-7.99,6.82-8.31,13.9-8.31,13.9
			c6.18,15.09,22.28,20.29,32.75,22.08c1.53,0.26,2.94,0.45,4.18,0.59c-0.1-11.28,3.68-17.89,3.68-17.89
			c-2.64,7.5-2.73,15.6-2.69,17.99c2.73,0.26,4.43,0.25,4.43,0.25s-0.14-2.47,0.1-6.14c0.65,4,1.95,5.82,1.95,5.82
			c-1.44-5.95,0.42-15.12,2.22-21.74c0.03-0.1,0.05-0.2,0.08-0.3c0.14-0.53,0.29-1.03,0.43-1.52c0,0,0,0,0-0.01
			c0.96-3.32,1.81-5.71,1.99-6.22c-0.65,2.22-1.15,4.31-1.54,6.28c0,0,0,0,0,0c-2.28,11.57-0.47,18.46-0.47,18.46
			s-1.07-7.75,0.98-17.81c0.04-0.2,0.08-0.39,0.12-0.59c0,0,0,0,0,0c1.13-5.37,2.33-8.79,3.38-11.57c1.36-3.29,2.49-6.61,3.3-10.67
			c0.5-2.5,0.88-5.28,1.12-8.51c0.32-4.37-0.38-8.65-0.84-12.12c-0.06-0.42-0.11-0.84-0.16-1.24c-0.58-0.96-1.02-1.96-1.34-2.96
			c-0.02-0.05-0.03-0.1-0.05-0.15c-0.03-0.09-0.06-0.19-0.09-0.28c-0.93-3.34-0.35-6.55,2.14-7.77c1.61-0.79,3.2-0.67,4.37,0.27
			c0.68,0.54,1.21,1.37,1.52,2.44c0.16,0.54,0.26,1.13,0.3,1.8c5.07-1.36,7.56-6.71,10.52-11.94c2.21-3.91,3.94-6.6,4.78-8.95
			C99.28,333.18,99.03,330.9,96.85,327.7z"
          />
          <path
            fill="#D7BFB1"
            d="M76.13,363.49c0.05,0.4,0.1,0.81,0.16,1.24c-0.02-0.35-0.04-0.7-0.03-1.04
			C76.22,363.62,76.17,363.55,76.13,363.49z"
          />
          <path
            fill="#A4A2A2"
            d="M76.13,363.49c0.05,0.4,0.1,0.81,0.16,1.24c-0.02-0.35-0.04-0.7-0.03-1.04
			C76.22,363.62,76.17,363.55,76.13,363.49z"
          />
          <path
            fill="#AD7D62"
            d="M78.86,354.38c1.01,0.2,1.76,1.54,0.81,2.71c-0.96,1.16-1.3,1.67-1.51,2.57c-0.21,0.9,0.43,1.72,0.43,1.72
			s0.13-0.69,0.35-1.52c0.22-0.83,1.23,0.3,2.2-0.73c0.97-1.03,0.3-3.82-0.09-4.46c-0.39-0.64-1.15-1.66-2.65-1.27
			c-1.5,0.4-1.83,2.19-2.16,3.43C76.24,356.83,77.45,354.09,78.86,354.38z"
          />
          <path
            fill="#AD7D62"
            d="M81.99,360.62c0.32-0.71-0.03-0.88-0.03-0.88c-0.68,1.07-1.62,0.75-2.05,1.26
			c-0.44,0.51,0.78,2.44,1.42,3.2c0.64,0.76,1.25,0.03,0.56-1.27C81.21,361.63,81.66,361.32,81.99,360.62z"
          />
          <path
            fill="#1EBEE4"
            d="M105.81,521.47c-0.43-0.13-0.89-0.29-1.36-0.5c0.02,0.45,0.03,0.9,0.03,1.35
			c0.01,1.12-0.04,2.24-0.14,3.35c-0.06,0.66-0.13,1.32-0.22,1.98c-0.06,0.44-0.12,0.87-0.2,1.3c-0.14,0.86-0.31,1.71-0.5,2.54
			c-0.14,0.62-0.29,1.24-0.45,1.84c-0.11,0.4-0.22,0.8-0.34,1.19c-0.12,0.39-0.24,0.77-0.36,1.15c-0.12,0.38-0.25,0.75-0.38,1.11
			s-0.26,0.72-0.4,1.07c-0.34,0.88-0.69,1.71-1.05,2.48c-2.83,6.12,1.51,12.04,2.27,12.29c1.55-0.54,3.88-1.23,5.69-1.85
			c0.29-0.1,0.56-0.19,0.82-0.29c-0.01-0.21-0.48-3.07-0.51-3.27c-1.06-6.91-1.42-11.42-1.43-15.01c0.16-2.03,0.36-8.42,0.58-10.34
			c-0.27-0.02-0.54-0.06-0.81-0.1C106.66,521.69,106.24,521.6,105.81,521.47z"
          />
          <path
            opacity="0.3"
            d="M26.08,508.18c0.26,8.48-0.46,15.93-0.72,26.06c-0.02,0.8-0.05,1.56-0.09,2.29
			C26.02,528.93,26.59,518.94,26.08,508.18z"
          />
          <path
            opacity="0.3"
            d="M24.95,495.51c-1.01-7.55-2.52-14.12-4.18-20.36C22.14,480.77,23.92,488.64,24.95,495.51z"
          />
          <path
            fill="#141616"
            d="M63.32,562.86c-0.04,0.01-0.08,0.01-0.13,0.02h0c0,0,0,0,0,0c0.37-0.05,0.74-0.11,1.12-0.16
			C63.98,562.77,63.65,562.82,63.32,562.86z"
          />
          <path
            opacity="0.3"
            d="M63.32,562.86c-0.04,0.01-0.08,0.01-0.13,0.02h0c0,0,0,0,0,0c0.37-0.05,0.74-0.11,1.12-0.16
			C63.98,562.77,63.65,562.82,63.32,562.86z"
          />
          <path
            opacity="0.3"
            d="M63.32,562.86c-0.04,0.01-0.08,0.01-0.13,0.02h0c0,0,0,0,0,0c0.37-0.05,0.74-0.11,1.12-0.16
			C63.98,562.77,63.65,562.82,63.32,562.86z"
          />
          <path
            fill="#D7BFB1"
            d="M68.25,407.47C68.25,407.47,68.24,407.47,68.25,407.47c0.15,0.02,0.3,0.04,0.45,0.06c0,0,0,0,0,0
			C68.54,407.51,68.39,407.49,68.25,407.47z"
          />
          <path
            fill="#AD7D62"
            d="M71.55,402.49c0.7-3.1,4.05-13.72,4.46-17.14c-0.81,4.06-1.94,7.38-3.3,10.67
			c-1.05,2.79-2.27,6.29-3.4,11.66c0.34,0.03,0.82,0.07,1.14,0.09c0.7,0.06,1.72,0.05,1.72,0.05s-0.01-0.02-0.02-0.02
			C72.07,407.66,70.86,405.54,71.55,402.49z"
          />
          <path
            fill="#AD7D62"
            d="M70.24,401.25c-0.18,0.51-1.05,2.98-2.01,6.3c0.15,0.02,0.3,0.04,0.46,0.06
			C69.07,405.64,69.59,403.47,70.24,401.25z"
          />
          <path
            fill="#AD7D62"
            d="M99.09,375.51c-0.07,0.06-0.15,0.1-0.23,0.13c-0.27,0.15-0.54,0.29-0.87,0.32c0.27-0.73,0.3-4.03,0.3-4.03
			c-0.04,0.3-0.08,0.58-0.12,0.84c0,0,0-0.01,0-0.01c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.01c-0.13,0.89-0.23,1.5-0.34,1.98
			c-0.14,0.59-0.29,0.99-0.53,1.5c-0.09,0.19-0.19,0.39-0.3,0.62c-0.04,0.07-0.07,0.15-0.11,0.22c0,0.01-0.01,0.02-0.01,0.02
			c0,0,0,0.01,0,0.01c-0.08,0.19-0.15,0.41-0.21,0.63c-0.09,0.35-0.16,0.71-0.2,1.04c-0.03,0.24-0.06,0.48-0.09,0.71
			c-0.04,0.49-0.04,0.83-0.04,0.83s0.01-0.15,0.04-0.38c-0.01,0.39,0.01,0.64,0.01,0.64c0-0.87,0.19-1.82,0.35-2.41
			c0.06-0.24,0.23-0.52,0.42-0.83l1.3,3.29c0,0,0.53-0.45,0.43-1.52c-0.1-1.06-1.25-2.33-1.25-2.33s0.03-0.05,0.07-0.15
			c0.37-0.05,1.09,0.27,1.56,0.08c1.13-0.48,1.23-1.36,1.05-1.91c-0.12-0.35-0.17-0.41-0.17-0.41S99.79,375.13,99.09,375.51z"
          />
          <path
            fill="#AD7D62"
            d="M82.07,384.47c-2.59-2.12-2.17-12.27-2.34-11.78c-0.17,0.49-0.37,14.5-0.28,22.99c0,0,0.03-0.37,0.13-0.95
			c0.29-1.54,1.14-4.57,3.57-6.13c1.92-1.23,3.23-1.19,6.82-0.4c2.5,0.54,4.17,0.74,3.98,0.6c-0.1-0.07-0.66-0.23-1.83-0.47
			C88.07,387.49,84.83,386.72,82.07,384.47z"
          />
          <path
            fill="#AD7D62"
            d="M103.28,368.36c-0.31,0.19-0.76,0.41-1.38,0.73c-1.77,0.9-2.12,0-2.31-1.03c-0.59-3.21-1.32-5.8-1.32-5.8
			c0.91,3.23,0.58,6.96,1.04,9.25c0.07-0.06,0.15-0.12,0.23-0.18c1.04-0.8,2.61-1.8,3.53-2.39c0.59-0.38,0.8-0.85,0.85-0.98
			c0.01-0.02,0.01-0.03,0.01-0.03S103.65,368.12,103.28,368.36z"
          />
          <path
            fill="#141616"
            d="M29.43,394.45c-7.99,6.82-8.31,13.9-8.31,13.9c6.18,15.09,22.28,20.29,32.75,22.08
			c-0.03-0.08-0.06-0.18-0.09-0.3c-0.09-0.32-0.2-0.78-0.3-1.39c-0.01-0.08-0.02-0.15-0.04-0.23c-0.05-0.32-0.09-0.68-0.12-1.08
			c-0.03-0.39-0.06-0.83-0.07-1.29c0-0.12,0-0.24,0-0.36c-0.03-5.21,1.67-14.62,10.67-28.25c0.18-0.27,0.36-0.54,0.54-0.81
			c-1.13,2.39-2.14,4.74-2.77,6.72c-2.35,7.32-3.45,14.7-3.45,14.7s2.28-10.33,5.68-18.17c1.61-3.72,3.87-7.98,5.97-11.89
			c1.01-1.71,1.95-3.38,2.79-4.99c0.44-0.84,0.85-1.66,1.23-2.47c0.19-0.4,0.37-0.79,0.54-1.18c0.34-0.7,0.62-1.33,0.83-1.86
			l-0.14,0.23c0.94-2.37,1.53-4.53,1.65-6.41c0.16-2.37-0.34-4.61-0.48-6.65c-0.06-0.42-0.11-0.84-0.16-1.24
			c-0.58-0.96-1.02-1.96-1.34-2.96c-0.02-0.05-0.03-0.1-0.05-0.15c-0.03-0.09-0.06-0.19-0.09-0.28c-0.93-3.34-0.35-6.55,2.14-7.77
			c1.61-0.79,3.2-0.67,4.37,0.27c13.28-17.87-4.63-33.6,1.11-34.7c0.1-0.02,0.19-0.03,0.3-0.04c-0.25-0.14-0.49-0.26-0.73-0.39
			c-4.01-1.26-8.96-2.11-15.02-2.09c-25.87,0.12-31.86,27.65-28.23,48.99c1.99,11.71-0.56,20.82-6.39,27.36
			c-0.08,0.09-0.16,0.18-0.24,0.26c0.09,0.01,0.18,0.02-0.01,0.01C31.17,392.86,30.33,393.68,29.43,394.45z"
          />
          <path fill="#AD7D62" d="M80.37,366.44c0,0-3.43-3.71-3.71-6.93C76.66,359.51,77.05,365.23,80.37,366.44z" />
          <path fill="#42637C" d="M31.96,392C31.88,391.99,31.79,391.99,31.96,392C32.14,392.01,32.05,392,31.96,392z" />
          <path
            fill="#141616"
            d="M66.52,562.48c0.13-0.02,0.26-0.04,0.39-0.06c0.52-0.09,1.04-0.17,1.57-0.27c0.39-0.08,0.8-0.17,1.2-0.26
			c0.13-0.03,0.25-0.05,0.38-0.08c0.29-0.06,0.58-0.13,0.88-0.19c0.14-0.03,0.29-0.06,0.43-0.09c0.3-0.07,0.6-0.13,0.9-0.2
			c0.13-0.03,0.26-0.06,0.39-0.09c0.43-0.1,0.87-0.19,1.31-0.29c0.76-0.17,1.54-0.34,2.31-0.52c0.19-0.04,0.39-0.09,0.58-0.13
			c0.19-0.04,0.39-0.09,0.58-0.13c2.34-0.54,4.7-1.1,6.96-1.67c0.75-0.19,1.5-0.38,2.22-0.57c0.36-0.1,0.73-0.21,1.09-0.31
			c0.73-0.21,1.47-0.43,2.22-0.65c0.38-0.11,0.75-0.22,1.13-0.34c0.76-0.23,1.53-0.46,2.3-0.7c0.78-0.24,1.56-0.49,2.35-0.74
			c0.19-0.07,0.38-0.14,0.56-0.21c0.18-0.07,0.35-0.14,0.52-0.21c0.23-0.1,0.43-0.2,0.6-0.3c0-0.01,0-0.02-0.01-0.04
			c-0.4,0.13-0.79,0.26-1.18,0.39c-0.39,0.13-0.78,0.25-1.17,0.38c-2.31,0.86-5.52,1.8-9.11,2.74c-6.75,1.9-12.78,3.28-18.16,4.23
			c-0.83,0.18-1.63,0.35-2.4,0.51C65.78,562.6,66.15,562.54,66.52,562.48z"
          />
          <path
            opacity="0.3"
            d="M66.52,562.48c0.13-0.02,0.26-0.04,0.39-0.06c0.52-0.09,1.04-0.17,1.57-0.27c0.39-0.08,0.8-0.17,1.2-0.26
			c0.13-0.03,0.25-0.05,0.38-0.08c0.29-0.06,0.58-0.13,0.88-0.19c0.14-0.03,0.29-0.06,0.43-0.09c0.3-0.07,0.6-0.13,0.9-0.2
			c0.13-0.03,0.26-0.06,0.39-0.09c0.43-0.1,0.87-0.19,1.31-0.29c0.76-0.17,1.54-0.34,2.31-0.52c0.19-0.04,0.39-0.09,0.58-0.13
			c0.19-0.04,0.39-0.09,0.58-0.13c2.34-0.54,4.7-1.1,6.96-1.67c0.75-0.19,1.5-0.38,2.22-0.57c0.36-0.1,0.73-0.21,1.09-0.31
			c0.73-0.21,1.47-0.43,2.22-0.65c0.38-0.11,0.75-0.22,1.13-0.34c0.76-0.23,1.53-0.46,2.3-0.7c0.78-0.24,1.56-0.49,2.35-0.74
			c0.19-0.07,0.38-0.14,0.56-0.21c0.18-0.07,0.35-0.14,0.52-0.21c0.23-0.1,0.43-0.2,0.6-0.3c0-0.01,0-0.02-0.01-0.04
			c-0.4,0.13-0.79,0.26-1.18,0.39c-0.39,0.13-0.78,0.25-1.17,0.38c-2.31,0.86-5.52,1.8-9.11,2.74c-6.75,1.9-12.78,3.28-18.16,4.23
			c-0.83,0.18-1.63,0.35-2.4,0.51C65.78,562.6,66.15,562.54,66.52,562.48z"
          />
          <path
            fill="#141616"
            d="M64.01,562.86c0.33-0.05,0.66-0.09,0.99-0.14c0,0,0,0,0.01,0c0.13-0.02,0.27-0.04,0.4-0.06
			c0.77-0.16,1.57-0.33,2.4-0.51c-0.53,0.09-1.05,0.18-1.57,0.27c-0.13,0.02-0.26,0.04-0.39,0.06c-0.47,0.08-0.94,0.15-1.41,0.22
			c-0.18,0.06-0.37,0.12-0.55,0.18C63.92,562.88,63.96,562.87,64.01,562.86z"
          />
          <path
            opacity="0.3"
            d="M64.01,562.86c0.33-0.05,0.66-0.09,0.99-0.14c0,0,0,0,0.01,0c0.13-0.02,0.27-0.04,0.4-0.06
			c0.77-0.16,1.57-0.33,2.4-0.51c-0.53,0.09-1.05,0.18-1.57,0.27c-0.13,0.02-0.26,0.04-0.39,0.06c-0.47,0.08-0.94,0.15-1.41,0.22
			c-0.18,0.06-0.37,0.12-0.55,0.18C63.92,562.88,63.96,562.87,64.01,562.86z"
          />
          <path
            opacity="0.3"
            d="M64.01,562.86c0.33-0.05,0.66-0.09,0.99-0.14c0,0,0,0,0.01,0c0.13-0.02,0.27-0.04,0.4-0.06
			c0.77-0.16,1.57-0.33,2.4-0.51c-0.53,0.09-1.05,0.18-1.57,0.27c-0.13,0.02-0.26,0.04-0.39,0.06c-0.47,0.08-0.94,0.15-1.41,0.22
			c-0.18,0.06-0.37,0.12-0.55,0.18C63.92,562.88,63.96,562.87,64.01,562.86z"
          />
          <path
            fill="#141616"
            d="M92.71,555.92c-0.44,0.14-0.88,0.27-1.32,0.41c-0.14,0.04-0.28,0.09-0.42,0.13
			c-0.19,0.06-0.38,0.11-0.56,0.17c-0.11,0.03-0.22,0.07-0.33,0.1c-0.16,0.05-0.31,0.09-0.47,0.14c-0.11,0.03-0.22,0.07-0.33,0.1
			c-0.08,0.02-0.16,0.05-0.23,0.07c-0.67,0.2-1.33,0.39-1.99,0.58c-0.37,0.11-0.73,0.21-1.09,0.31c3.59-0.94,6.8-1.88,9.11-2.74
			C94.28,555.43,93.49,555.67,92.71,555.92z"
          />
          <path
            opacity="0.3"
            d="M92.71,555.92c-0.44,0.14-0.88,0.27-1.32,0.41c-0.14,0.04-0.28,0.09-0.42,0.13
			c-0.19,0.06-0.38,0.11-0.56,0.17c-0.11,0.03-0.22,0.07-0.33,0.1c-0.16,0.05-0.31,0.09-0.47,0.14c-0.11,0.03-0.22,0.07-0.33,0.1
			c-0.08,0.02-0.16,0.05-0.23,0.07c-0.67,0.2-1.33,0.39-1.99,0.58c-0.37,0.11-0.73,0.21-1.09,0.31c3.59-0.94,6.8-1.88,9.11-2.74
			C94.28,555.43,93.49,555.67,92.71,555.92z"
          />
          <path
            opacity="0.3"
            d="M92.71,555.92c-0.44,0.14-0.88,0.27-1.32,0.41c-0.14,0.04-0.28,0.09-0.42,0.13
			c-0.19,0.06-0.38,0.11-0.56,0.17c-0.11,0.03-0.22,0.07-0.33,0.1c-0.16,0.05-0.31,0.09-0.47,0.14c-0.11,0.03-0.22,0.07-0.33,0.1
			c-0.08,0.02-0.16,0.05-0.23,0.07c-0.67,0.2-1.33,0.39-1.99,0.58c-0.37,0.11-0.73,0.21-1.09,0.31c3.59-0.94,6.8-1.88,9.11-2.74
			C94.28,555.43,93.49,555.67,92.71,555.92z"
          />
          <path
            fill="#AD7D62"
            d="M98.17,357.74c-0.33,0.55-1.04,0.49-1.04,0.49C96.7,358.01,97.75,358.86,98.17,357.74z"
          />
          <path
            fill="#AD7D62"
            d="M97.63,358.6c-0.08,0.06-0.16,0.12-0.25,0.2c0.22-0.01,0.43-0.12,0.59-0.28c0.08-0.08,0.14-0.18,0.18-0.28
			c0.04-0.1,0.06-0.22,0.04-0.33c-0.1,0.2-0.21,0.35-0.35,0.49C97.78,358.47,97.71,358.54,97.63,358.6z"
          />
          <path
            fill="#4D2C1A"
            d="M100.94,349.95c-1.22-0.31-2.66,1-3.62,2.61c0,0,1.78-1.31,2.78-1.23c0.66,0.06,1.18,0.6,0.94,1.31
			c-0.02,0.05,0,0.05,0.05,0.01c0.12-0.11,0.39-0.49,0.39-0.49c0.21-0.38,0.35-0.79,0.24-1.23c-0.06-0.24-0.19-0.49-0.43-0.75
			C101.19,350.08,101.08,349.98,100.94,349.95z"
          />
          <path
            opacity="0.3"
            d="M22.58,552.16c0.19,0.12,0.42,0.27,0.68,0.43c0.07-0.19,0.14-0.37,0.21-0.56c0.01-0.03,0.03-0.07,0.04-0.1
			c0.01-0.02,0.01-0.03,0.02-0.04c0.05,0.15,0.1,0.31,0.17,0.48c0.06,0.15,0.13,0.32,0.2,0.48c0.03,0.06,0.05,0.11,0.08,0.17h0
			c2.04,1.18,5.43,2.89,9.12,3.66c9.35,1.95,36.76,1.09,22.64-1.81c-14.11-2.89-30.04-6.88-26.42-17.37
			c3.62-10.49,11.58-17.01,7.96-26.78c-3.62-9.77-14.11-30.4-18.46-43.06c0,0,0.84,3.04,1.93,7.5c1.66,6.24,3.17,12.8,4.18,20.36
			c0.15,1.02,0.29,2.02,0.4,2.99c0.41,3.47,0.63,6.64,0.73,9.68c0.51,10.76-0.06,20.75-0.81,28.35c-0.21,4.08-0.66,7.15-1.16,9.43
			c0,0,0,0,0,0c-0.09,0.63-0.19,1.22-0.27,1.74c-0.03,0.17-0.06,0.34-0.08,0.5c-0.24,0.5-0.47,1.03-0.71,1.56
			c-0.14,0.31-0.27,0.63-0.41,0.95c-0.12,0.28-0.24,0.56-0.35,0.85c0,0,0,0,0,0c-0.04,0.1-0.08,0.2-0.13,0.31
			c0.07,0.05,0.17,0.12,0.3,0.2C22.49,552.1,22.53,552.13,22.58,552.16z"
          />
          <path
            opacity="0.3"
            d="M66.23,562.42c0.52-0.09,1.04-0.17,1.57-0.27c5.38-0.95,11.41-2.33,18.16-4.23
			c0.36-0.1,0.73-0.21,1.09-0.31c0.66-0.19,1.32-0.38,1.99-0.58c0.08-0.02,0.16-0.05,0.23-0.07c0.11-0.03,0.22-0.07,0.33-0.1
			c0.16-0.05,0.31-0.09,0.47-0.14c0.11-0.03,0.22-0.07,0.33-0.1c0.19-0.06,0.37-0.11,0.56-0.17c0.14-0.04,0.28-0.08,0.42-0.13
			c0.44-0.13,0.88-0.27,1.32-0.41c0.78-0.24,1.56-0.49,2.35-0.74c0.39-0.12,0.78-0.25,1.17-0.38c0.39-0.13,0.79-0.25,1.18-0.39
			c0,0.01,0,0.02,0.01,0.04c0.08-0.05,0.17-0.1,0.24-0.16c0.09-0.07,0.16-0.13,0.23-0.2c0.03-0.03,0.07-0.07,0.1-0.11
			c0.12-0.14,0.2-0.29,0.26-0.45c0.01-0.04,0.03-0.08,0.04-0.12c0.27-1.06-0.64-2.5-1.4-4.57c-0.06-0.16-0.12-0.32-0.17-0.49
			c-0.06-0.17-0.11-0.34-0.16-0.51c-0.08-0.26-0.15-0.53-0.22-0.81c-0.07-0.28-0.13-0.57-0.18-0.87c-0.07-0.4-0.13-0.81-0.16-1.25
			c-0.02-0.22-0.03-0.44-0.04-0.66c-0.01-0.23-0.01-0.46,0-0.69c0-0.23,0.02-0.47,0.03-0.72c0.01-0.12,0.02-0.25,0.03-0.37
			c0.05-0.5,0.13-1.02,0.24-1.56c0.43-2.07,1.17-3.71,2-5.09c0.39-0.64,0.79-1.22,1.2-1.76c0.2-0.27,0.41-0.53,0.61-0.79
			c2.85-3.57,5.32-10.21,0.65-16.86c-1.26-1.79-3.01-4.35-3.61-7.64c-0.09-0.49-0.18-0.99-0.26-1.5c-0.05-0.34-0.11-0.68-0.16-1.03
			c-0.07-0.52-0.15-1.04-0.21-1.57c-0.02-0.18-0.04-0.35-0.07-0.53c-0.13-1.06-0.24-2.13-0.35-3.19c-0.05-0.53-0.1-1.06-0.15-1.58
			c-0.13-1.4-0.25-2.77-0.36-4.07c-0.03-0.33-0.06-0.65-0.09-0.96c-0.04-0.47-0.09-0.93-0.13-1.38c-0.04-0.45-0.09-0.88-0.14-1.3
			c-0.06-0.56-0.13-1.09-0.2-1.58c-0.1-0.74-0.22-1.41-0.35-1.99c-0.37-1.66-1.9-17.84-1.95-18.2c-0.1-0.72-0.14-1.71-0.24-2.42
			c0.01,0.09,0.02,0.16,0-0.01c-0.14-1.06-0.28-2.09-0.41-3.09c-0.07-0.5-0.13-0.99-0.19-1.46c-0.02-0.16-0.04-0.32-0.06-0.47
			c-0.06-0.47-0.12,3.58-0.18,3.14c-0.02-0.15-0.04-0.29-0.06-0.44c-0.09-0.72-0.18-1.39-0.26-2.02c0-0.36,0.01-0.72,0.02-1.06
			c0.01-0.17,0.01-0.34,0.02-0.51c0.02-0.34,0.04-0.67,0.06-1c0.13-1.63,0.4-3.09,0.84-4.5c0.18-0.56,0.38-1.12,0.62-1.66
			c0.35-0.82,0.78-1.64,1.28-2.47c0.17-0.28,0.34-0.56,0.53-0.84c0.46-0.7,0.98-1.42,1.55-2.16c5.16-6.66-6.02,3.62-7.66,12.85
			c-0.03,0.14-0.05,0.28-0.07,0.41c-0.07,0.38-0.13,0.7-0.18,0.95c-0.02,0.08-0.03,0.16-0.05,0.23c-0.06,0.27-0.1,0.44-0.13,0.5
			c-0.01,0.02-0.01,0.02-0.02,0.03c-0.02,0.01-0.03-0.07-0.02-0.24c0-0.17,0.02-0.42,0.05-0.75c0.05-0.57,0.13-1.35,0.23-2.29
			c0.03-0.27,0.06-0.55,0.09-0.84c0.05-0.44,0.1-0.89,0.15-1.37c0.04-0.32,0.07-0.64,0.11-0.98c0.08-0.67,0.16-1.36,0.24-2.06
			c0.06-0.53,0.12-1.07,0.19-1.6c0.08-0.72,0.17-1.44,0.25-2.15c0.43-3.74,0.83-7.24,0.91-8.62c0.02-0.26,0.01-0.46-0.01-0.59
			c-0.01-0.07-0.03-0.12-0.05-0.15c-0.1-0.18-0.3,0.01-0.57,0.49c-0.11,0.19-0.22,0.43-0.35,0.71c-0.12,0.28-0.26,0.6-0.39,0.95
			c-0.07,0.18-0.14,0.36-0.21,0.56c-0.04,0.1-0.07,0.2-0.11,0.3c-0.07,0.2-0.14,0.41-0.22,0.63c-0.04,0.11-0.07,0.22-0.11,0.33
			c-0.07,0.22-0.15,0.45-0.22,0.69c-0.15,0.47-0.3,0.97-0.44,1.48c-0.11,0.38-0.22,0.78-0.32,1.18c-0.07,0.27-0.14,0.54-0.21,0.82
			c-0.3,1.24-0.58,2.54-0.8,3.83c-0.05,0.29-0.09,0.57-0.14,0.86c-0.11,0.72-0.19,1.43-0.25,2.12c-0.01,0.14-0.02,0.28-0.03,0.41
			c-0.02,0.27-0.04,0.55-0.05,0.81c-0.02,0.54-0.03,1.06-0.01,1.56c0.24,8.03-2.38-2.99-4.39-5.99c-0.24-0.36-0.32-0.47-0.27-0.31
			c0.01,0.04,0.03,0.09,0.05,0.17c0.06,0.18,0.17,0.47,0.31,0.86c0.06,0.16,0.12,0.33,0.18,0.52c0.07,0.19,0.14,0.4,0.21,0.63
			c0.07,0.23,0.15,0.47,0.23,0.73c0.12,0.39,0.25,0.82,0.39,1.3c0.14,0.47,0.28,0.99,0.42,1.54c0.14,0.55,0.29,1.15,0.44,1.79
			c1.14,4.89,2.34,12.24,2.63,22.34c0.09,3.08,0.09,6.41-0.01,10c-0.06,2.05-0.16,4.19-0.3,6.42c-0.09,0.39-0.18,0.77-0.27,1.15
			c-0.45,1.91-0.91,3.75-1.39,5.51c-0.96,3.51-1.97,6.66-3,9.28c-0.88,2.23-1.25,3.95-1.26,5.31c0,0.37,0.02,0.71,0.06,1.03
			c0.03,0.21,0.07,0.41,0.12,0.6c0.07,0.29,0.16,0.55,0.27,0.79c0.11,0.24,0.24,0.47,0.37,0.67c0.19,0.27,0.4,0.52,0.62,0.74
			c0.11,0.11,0.23,0.21,0.35,0.31c0.06,0.05,0.12,0.1,0.18,0.14c0.25,0.19,0.5,0.35,0.76,0.5c0.13,0.08,0.26,0.15,0.39,0.22
			c0.13,0.07,0.26,0.14,0.38,0.21c1.71,0.9,3.15,1.5,1.85,3.81c-0.48,0.85-1.34,1.82-2.52,2.86c-0.24,0.21-0.48,0.42-0.74,0.63
			c-1.17,0.96-2.58,1.97-4.18,2.99c-0.36,0.23-0.72,0.46-1.1,0.68c-0.94,0.57-1.93,1.14-2.96,1.71c-0.41,0.23-0.84,0.45-1.27,0.68
			c-1.07,0.57-2.19,1.12-3.34,1.67c-1.61,0.77-3.29,1.51-5.01,2.21c-0.74,0.3-1.48,0.6-2.23,0.88c-0.5,0.19-1,0.38-1.51,0.56
			c-1.01,0.37-2.03,0.71-3.06,1.04c-2.3,0.74-4.63,1.38-6.92,1.89c-1.02,0.23-2.03,0.43-3.03,0.6c-4.64,0.79-5.51,1.29-3.9,1.4
			c0.09,0.01,0.18,0.01,0.28,0.01c0.05,0,0.1,0,0.16,0c0.11,0,0.22,0,0.34,0c0.49,0,1.08-0.02,1.77-0.06
			c0.17-0.01,0.35-0.02,0.53-0.03c0.55-0.04,1.16-0.08,1.8-0.14c0.22-0.02,0.44-0.04,0.66-0.06c1.36-0.14,2.89-0.32,4.56-0.55
			c0.69-0.1,1.41-0.21,2.15-0.32c3.84-0.61,8.21-1.46,12.67-2.61c0.34-0.09,0.69-0.18,1.03-0.27c0.69-0.18,1.38-0.37,2.07-0.57
			c1.38-0.39,2.76-0.82,4.12-1.27c0.17-0.06,0.34-0.11,0.51-0.17c1.7-0.57,3.38-1.19,5-1.86c9.85-4.04,10.76-0.2,6.34,5.39
			c-0.13,0.17-0.27,0.34-0.42,0.51c-0.29,0.34-0.59,0.7-0.92,1.05c-0.32,0.36-0.67,0.71-1.03,1.08c-1.44,1.45-3.16,2.95-5.11,4.41
			c-0.49,0.37-0.99,0.73-1.51,1.09c-0.52,0.36-1.04,0.71-1.59,1.07c-2.44,1.58-5.13,3.05-8.01,4.3c-1.43,0.62-2.9,1.17-4.4,1.67
			c0.46-0.07,0.93-0.14,1.41-0.22C65.97,562.46,66.1,562.44,66.23,562.42z"
          />
          <path
            fill="#1EBEE4"
            d="M72.16,407.8c1.83,2.99,6.91,4.7,6.91,4.7s14.18,4.32,20.19,7.94l0-0.02c0,0,12.8,5.2,15.26,25.22
			c0.23,1.87,0.54,4.06,0.9,6.44c0.06,0.43,0.13,0.87,0.2,1.32c0.2,1.33,0.42,2.72,0.64,4.13c0.07,0.47,0.15,0.95,0.23,1.42
			c0.19,1.19,0.38,2.4,0.58,3.61c0.12,0.73,0.23,1.46,0.35,2.19c0.04,0.24,0.08,0.49,0.12,0.73c0.15,0.97,0.31,1.94,0.46,2.9
			s0.3,1.91,0.44,2.84c0.04,0.23,0.07,0.47,0.11,0.7c0.87,5.79,1.56,10.94,1.67,13.99c0.04,1.22,0.06,2.35,0.06,3.39
			c0,5.64-0.57,8.83-0.97,10.84c-0.04,0.19-0.05,0.31-0.06,0.37c0,0.02,0,0.03,0,0.04c0,0.01,0.01,0.01,0.02,0.01
			c0.01,0,0.02-0.02,0.03-0.03c0.01-0.02,0.02-0.04,0.04-0.07c0,0,0-0.01,0.01-0.01c0,0,0,0,0,0c0.04-0.07,0.09-0.18,0.14-0.32
			c0.04-0.09,0.08-0.19,0.13-0.3c0.02-0.04,0.03-0.08,0.05-0.12c0.04-0.09,0.07-0.18,0.11-0.28c0.07-0.18,0.15-0.38,0.23-0.6
			c0.05-0.13,0.1-0.26,0.15-0.4c0.15-0.38,0.3-0.8,0.47-1.23c0.05-0.14,0.11-0.29,0.17-0.43c0.3-0.78,0.62-1.58,0.92-2.31
			c0.04-0.1,0.08-0.2,0.13-0.3c0.28-0.65,0.54-1.23,0.78-1.67c0.09-0.16,0.17-0.3,0.25-0.42c0.04-0.07,0.09-0.13,0.12-0.18
			c0.03-0.04,0.06-0.07,0.09-0.09c0.05-0.05,0.1-0.07,0.13-0.07c0.09,0.01,0.14,0.17,0.15,0.45c0,0.03,0,0.07,0,0.11
			c0.01,1.68-0.97,6.66-0.97,8.58c0,2.62,3.09-7.14,5-9.04c0.03-0.03,0.06-0.06,0.1-0.09c0.16-0.12,0.39-0.23,0.69-0.34
			c0.07-0.03,0.15-0.05,0.24-0.08c0.17-0.05,0.35-0.11,0.54-0.16c0.49-0.13,1.08-0.26,1.75-0.38c0.13-0.02,0.27-0.05,0.41-0.07
			c0.28-0.05,0.57-0.09,0.88-0.14c0.15-0.02,0.31-0.05,0.46-0.07c0.31-0.04,0.64-0.09,0.98-0.13c4.53-0.58,10.86-0.99,16.6-1.21
			c1.27-0.05,2.52-0.09,3.71-0.12c0.2-0.01,0.39-0.01,0.59-0.01c0.39-0.01,0.77-0.02,1.15-0.02c0.56-0.01,1.11-0.02,1.63-0.02
			c1.05-0.01,2.02-0.01,2.87,0c1.17,0.01,2.26,0.01,3.27-0.01c0.34-0.01,0.66-0.01,0.98-0.02c0.32-0.01,0.63-0.02,0.93-0.03
			c1.35-0.05,2.54-0.13,3.57-0.23c0.46-0.04,0.89-0.09,1.29-0.14c0.3-0.04,0.58-0.07,0.85-0.11c0.09-0.01,0.18-0.03,0.26-0.04
			c0.43-0.06,0.82-0.13,1.17-0.2c0.14-0.03,0.28-0.05,0.4-0.08c0.29-0.06,0.55-0.12,0.78-0.18c0.08-0.02,0.15-0.04,0.22-0.06
			c0.14-0.04,0.27-0.08,0.39-0.11c0.84-0.25,1.13-0.45,1.13-0.45l0,0c0,0,0.01,0.04,0.04,0.11c-0.16-0.62-0.32-1.13-0.45-1.52
			c-0.1-0.31-0.19-0.54-0.23-0.66c-0.03-0.08-0.05-0.12-0.05-0.12c-14.95-0.08-16.92,0.34-25.12,0.89c-0.01,0-0.03,0-0.04,0h0
			c-0.64,0.04-1.26,0.09-1.86,0.13c-13.63,1-18.99,2.31-22.42,1.95c-1.3-3.69-2.12-8.92-3.21-15.74c-0.22-1.39-0.46-2.85-0.71-4.37
			c-1.93-11.7-1.68-13.27-3.09-21.89c-1.33-16.93-6.58-29.04-32.47-37.7c-1.33-0.33-2.71-0.66-4.14-0.97
			c-0.14-0.05-0.28-0.09-0.42-0.14c0.01,0.03,0.02,0.05,0.02,0.05C78.29,407.7,75.58,407.96,72.16,407.8z"
          />
          <path
            fill="#141616"
            d="M83.16,820.37c0.15,0.02,0.31,0.05,0.5,0.08c0.09,0.01,0.19,0.03,0.29,0.04c0.2,0.03,0.43,0.06,0.67,0.1
			c0.24,0.04,0.49,0.07,0.76,0.11c0.27,0.04,0.55,0.08,0.85,0.12c0.3,0.04,0.61,0.09,0.93,0.13c0.32,0.04,0.65,0.09,0.99,0.13
			c0.34,0.05,0.69,0.09,1.04,0.14c1.06,0.14,2.19,0.27,3.31,0.4c0.94,0.1,1.89,0.2,2.81,0.28c0.1-0.96,0.35-3.33,0.71-6.71
			c0.11-1.04,0.23-2.18,0.36-3.39c0.06-0.61,0.13-1.24,0.2-1.89c0.14-1.3,0.28-2.68,0.43-4.12c1.12-10.83,2.6-25.36,3.85-38.63
			c0.12-1.33,0.25-2.64,0.37-3.94c0.08-0.86,0.16-1.72,0.24-2.57c0.12-1.27,0.23-2.52,0.34-3.74c0.07-0.82,0.14-1.62,0.21-2.41
			c0.03-0.4,0.07-0.79,0.1-1.18c0.14-1.66,0.29-3.42,0.45-5.28c0.08-0.93,0.16-1.88,0.24-2.86c0.04-0.49,0.08-0.98,0.12-1.48
			c0.24-2.99,0.49-6.19,0.74-9.56c0.67-8.99,1.34-19.21,1.88-30.16c0.17-3.42,0.33-6.91,0.47-10.46c0.06-1.42,0.11-2.85,0.16-4.28
			c0.05-1.44,0.1-2.88,0.14-4.33c0.02-0.73,0.04-1.45,0.06-2.18c0.04-1.46,0.08-2.92,0.11-4.39c0.14-5.87,0.21-11.83,0.22-17.81
			c0-2.24-0.01-4.49-0.03-6.73c-0.01-1.5-0.03-2.99-0.06-4.49c-0.04-2.24-0.08-4.48-0.14-6.71s-0.13-4.46-0.22-6.68
			c-0.12-2.96-0.26-5.89-0.42-8.81c-0.16-2.81-0.33-5.55-0.51-8.21c-0.07-1.06-0.14-2.11-0.22-3.15c-0.07-1.04-0.15-2.06-0.22-3.08
			c-0.19-2.53-0.39-4.99-0.59-7.37c-0.08-0.95-0.16-1.89-0.25-2.82c-0.04-0.46-0.08-0.93-0.12-1.38c-0.08-0.92-0.17-1.82-0.25-2.71
			c-0.04-0.45-0.09-0.89-0.13-1.33c-0.09-0.88-0.17-1.75-0.26-2.61c-0.09-0.86-0.18-1.7-0.26-2.54c-0.09-0.83-0.18-1.66-0.27-2.47
			c-0.04-0.41-0.09-0.81-0.13-1.21c-0.09-0.8-0.18-1.59-0.27-2.37c-0.18-1.56-0.37-3.07-0.55-4.53c-0.09-0.73-0.19-1.46-0.28-2.17
			c-1.35-10.32-2.75-18.35-4.01-24.45c-0.02-0.1-0.04-0.2-0.06-0.3c-0.17,0.1-0.37,0.21-0.6,0.3c-0.17,0.07-0.34,0.14-0.52,0.21
			c-0.18,0.07-0.37,0.14-0.56,0.21c-0.79,0.25-1.58,0.5-2.35,0.74c-0.78,0.24-1.54,0.48-2.3,0.7c-0.38,0.11-0.76,0.23-1.13,0.34
			c-0.75,0.22-1.49,0.44-2.22,0.65c-0.37,0.11-0.73,0.21-1.09,0.31c-0.73,0.19-1.47,0.38-2.22,0.57c-2.26,0.57-4.62,1.13-6.96,1.67
			c-0.2,0.04-0.39,0.09-0.58,0.13c-0.19,0.04-0.39,0.09-0.58,0.13c-0.78,0.18-1.55,0.35-2.31,0.52c-0.44,0.1-0.87,0.19-1.31,0.29
			c-0.13,0.03-0.26,0.06-0.39,0.09c-0.3,0.07-0.61,0.13-0.9,0.2c-0.15,0.03-0.29,0.06-0.43,0.09c-0.29,0.06-0.59,0.13-0.88,0.19
			c-0.13,0.03-0.25,0.05-0.38,0.08c-0.41,0.09-0.81,0.17-1.2,0.26c-0.53,0.09-1.05,0.18-1.57,0.27c-0.13,0.02-0.26,0.04-0.39,0.06
			c-0.38,0.06-0.75,0.12-1.12,0.18c-0.4,0.06-1.4,0.21-2.21,0.23c-5.48,0.77-10.22,1.07-14.3,1c-4.43-0.07-8.09-0.56-11.12-1.3
			c-0.93-0.23-1.81-0.49-2.63-0.76c-0.41-0.14-0.8-0.28-1.19-0.43c-0.38-0.15-0.75-0.3-1.1-0.45c-0.35-0.16-0.7-0.32-1.03-0.48
			c-0.33-0.16-0.65-0.33-0.95-0.5c-0.15-0.08-0.3-0.17-0.45-0.26c-0.29-0.17-0.57-0.35-0.84-0.52c-0.13-0.09-0.27-0.18-0.4-0.27
			c-0.26-0.18-0.51-0.36-0.74-0.54c-0.35-0.27-0.69-0.54-0.99-0.82c-0.21-0.18-0.4-0.36-0.59-0.55c-0.19-0.18-0.36-0.36-0.53-0.54
			c-0.34-0.36-0.63-0.72-0.9-1.07c-0.07-0.09-0.13-0.17-0.19-0.26c-0.56-0.78-0.97-1.5-1.26-2.13h0c-0.26-0.15-0.51-0.3-0.72-0.43
			c-3.68,9.62-7.28,23.95-8.52,44.6c-1.64,27.23,2.18,75.94,2.6,105.16c0.22,15.64-1.39,37.21-3.04,54.84
			c-0.86,9.17-1.73,17.28-2.35,22.92c-0.42,3.76-0.72,6.44-0.85,7.6c-0.12,1.17-0.33,3.55-0.31,5.84c0.01,0.87,0.05,1.73,0.15,2.51
			c0.69,3.06,3.3,5.26,6.88,6.74c0.69,0.29,1.42,0.55,2.18,0.78c3.28,0.88,9.56,2.16,16.04,1.29c0,0-0.45,0.07-1.16,0.25
			c0.36-0.02,0.73-0.04,1.08-0.07c2.48-0.18,4.85-0.54,6.96-1.05c0.27-4.83,4.86-86.54,7.32-108.21
			c2.78-24.53,5.42-58.57,10.52-65.96c0.38,4.76,1.8,17.6,2.97,27.23l-0.02,4.85c0.03,0.22,0.06,0.5,0.1,0.85
			c0.05-0.51,0.09-1.01,0.14-1.52c2.89,25.11,4.1,56.65,4.85,90.24c0.3,13.33,0.48,25.65,0.67,35.88
			c0.61,15.45,1.01,25.92,1.01,25.92c0.08,0.13,0.17,0.25,0.25,0.38c2.53,3.62,7.17,5.74,11.73,6.98c0-0.13,0.01-0.25,0.01-0.38
			C82.48,820.26,82.72,820.3,83.16,820.37z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="64.32" y="122.01" fill="none" width="280.03" height="182.74" />
        <text transform="matrix(1 0 0 1 64.3243 140.1053)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="24px">
            THE BMW GROUP SEES{" "}
          </tspan>
          <tspan x="0" y="29" fontFamily={font.condensed} fontSize="24px">
            DIVERSITY
          </tspan>
          <tspan x="109.45" y="29" fontFamily={font.condensed} fontSize="23.7186px">
            {" "}
          </tspan>
          <tspan x="113.74" y="29" fontFamily={font.serif} fontSize="19px">
            as a
          </tspan>
          <tspan x="144.99" y="29" fontFamily={font.condensed} fontSize="23.7186px">
            {" "}
          </tspan>
          <tspan x="149.28" y="29" fontFamily={font.condensed} fontSize="24px">
            STRENGTH.
          </tspan>
          <tspan x="0" y="58" fontFamily={font.serif} fontSize="19px">
            That is why it has set targets for
          </tspan>
          <tspan x="0" y="87" fontFamily={font.serif} fontSize="19px">
            the long-term advancement of{" "}
          </tspan>
          <tspan x="0" y="116" fontFamily={font.serif} fontSize="19px">
            women at all levels of the{" "}
          </tspan>
          <tspan x="0" y="145" fontFamily={font.serif} fontSize="19px">
            company.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 29.0416 72)" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
          promised
        </text>
      </motion.g>
    </svg>
  );
};
