const roadFrag = `
varying vec2 vUv;

uniform float uColorSwap;
uniform float uOpacity;
varying float vWorldZ;

#include <common>
#include <fog_pars_fragment>

void main(){
	vec3 color = vec3(27.	,57.,	80.	)/255.;
	vec3 baseColor = color;
	float grid = fract(vWorldZ * 20. * 0.01);
	float borderSize = 0.04;
	float lines = step(1. - borderSize, grid);
	vec3 lightC = vec3(79.,	140.,	177.	)/255.;
	lightC *= 0.9;
	// lightC = vec3(76.,	111.,	138.		)/255.;

	vec3 l =  mix(color, lightC, lines);
	// color = mix(l,color,  uColorSwap);
	// color = mix(color,baseColor,  smoothstep(0.15,0.35,vUv.y));
	// color = vec3(vUv.y);
	gl_FragColor = vec4(color, 1. * uOpacity);
#ifdef USE_FOG
		#ifdef FOG_EXP2
			float fogFactor = 1.0 - exp( - fogDensity * fogDensity * fogDepth * fogDepth );
		#else
			float fogFactor = smoothstep( fogNear, fogFar, fogDepth );
		#endif
		gl_FragColor.a = mix( gl_FragColor.a, 0., fogFactor );
	#endif

}`;

export default roadFrag;
