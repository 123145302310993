import { RefObject, useEffect, useMemo, useRef } from "react";
import { gsap, Linear } from "gsap";
import { ChartProps } from "#interfaces";
import { Power3 } from "gsap/gsap-core";
import { chartReverseDuration } from "#constants";

interface ResearchAnimationProps extends ChartProps {
  maxValue: number;
  maxMargin: number;
  minMargin: number;
  minDegree: number;
  maxDegree: number;
  minLineRef: RefObject<SVGPathElement>;
  maxLineRef: RefObject<SVGPathElement>;
  minTextRef: RefObject<SVGGElement>;
  maxTextRef: RefObject<SVGGElement>;
  duration: number;
}

type useResearchAnimation = (chartArguments: ResearchAnimationProps) => void;

export const useResearchAnimation: useResearchAnimation = ({
  playIn,
  playOut,
  onPlayOutEnd,
  maxValue,
  maxMargin,
  minMargin,
  minDegree,
  maxDegree,
  minLineRef,
  maxLineRef,
  minTextRef,
  maxTextRef,
  duration,
}) => {
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(() => gsap.timeline({ defaults: { ease: Linear.easeInOut, force3D: true }, paused: true }), []);
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );
  const maxMarginPercent = Number(((100 / maxValue) * maxMargin).toFixed(1));
  const minMarginPercent = Number(((100 / maxValue) * minMargin).toFixed(1));
  useEffect(() => {
    const percentageDiff = Math.abs(maxMarginPercent - minMarginPercent);
    const prevLineDuration = duration * (1 - percentageDiff / maxMarginPercent);
    const currentLineDuration = duration * (percentageDiff / maxMarginPercent);
    const minMarginLineRotation = minDegree + (maxDegree * minMarginPercent) / 100;
    const maxMarginLineRotation = minDegree + (maxDegree * maxMarginPercent) / 100;
    tl.fromTo(
      minLineRef.current,
      { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
      { duration: currentLineDuration, opacity: 1 },
      prevLineDuration
    )
      .fromTo(
        maxLineRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, rotate: maxMarginLineRotation, opacity: 1 },
        prevLineDuration
      )
      .fromTo(
        minTextRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, opacity: 1 },
        prevLineDuration + currentLineDuration
      )
      .fromTo(
        maxTextRef.current,
        { rotate: maxMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, opacity: 1 },
        prevLineDuration + currentLineDuration
      );

    tlReverse.to([maxTextRef.current, minTextRef.current, maxLineRef.current, minLineRef.current], { opacity: 0 }, 0);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
  }, [playIn, playOut]); // eslint-disable-line
};
