import { AnalyticsEventTypes } from "#interfaces";
import { InfoCardTheme, InfoCardType, TourComponents } from "#interfaces";

export const tour = {
  sound: "/data/autopilot.mp3",
  ContractsChart: {
    title: "New contracts, Financial Services",
    mainKPI: {
      label1: "",
      label2: "in units",
      maxValue: 3000000,
      currentValue: 1845271,
      prevValue: 2003782,
      diff: "− 7.9%",
    },
    leftKPI: {
      label1: "Credit Financing",
      label2: "in %",
      maxValue: 100,
      currentValue: 67.1,
      prevValue: 67.1,
      diff: "",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Leasing",
      label2: "in %",
      maxValue: 100,
      currentValue: 32.9,
      prevValue: 32.9,
      diff: "",
      rate: 0.4,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "New contracts, Financial Services",
          component: TourComponents.Contracts,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "New contracts, Financial Services",
          content: [
            {
              copytext:
                "<p>In a difficult environment dominated by the global impact of the coronavirus pandemic, business developed significantly less favourably for the Financial Services segment in 2020 compared to one year earlier, a fact reflected in a 24.1 % drop in profit before tax to € 1,725 million (2019: € 2,272 million). As reported in previous quarters, this development was primarily due to the recognition of risk provisioning expenses for credit and residual value risks, thereby reducing reported earnings over the course of the twelve-month period. Due to the impact of the coronavirus pandemic, the segment’s total business volume in balance sheet terms decreased moderately by 6.8 % to € 133,093 million (2019: € 142,834 million). The contract portfolio under management at 31 December 2020 comprised 5,981,928 contracts and was therefore at a similar level to one year earlier (2019: 5,973,682 contracts; + 0.1 %).</p><p>A total of 1,845,271 new credit financing and leasing contracts was signed with retail customers during 2020, a moderate decrease of 7.9 % on the previous year (2019: 2,003,782 contracts).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 29,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  DeliveriesChart: {
    title: "Automobile deliveries",
    tooltip:
      "A new or used vehicle will be recorded as a delivery once handed over to the end user. End users also include leaseholders under lease contracts with BMW Financial Services and – in the US and Canada – dealers when they designate a vehicle as a service loaner or demonstrator vehicle. In the case of used vehicles, end users include dealers and other third parties when they purchase a vehicle at auction or directly from BMW Group. Vehicles designated for the end user and suffering total loss in transit will also be recorded as deliveries. Deliveries may be made by BMW AG, one of its international subsidiaries, a BMW Group retail outlet, or independent third party dealers. The vast majority of deliveries – and hence the reporting to BMW Group of deliveries – is made by independent third party dealers. Retail vehicle deliveries during a given reporting period do not correlate directly to the revenue that BMW Group recognises in respect of such reporting period.",
    bottomLink: {
      text: "Disclosure",
      to: "/note/",
    },
    mainKPI: {
      label1: "Total",
      label2: "in units",
      maxValue: 3000000,
      currentValue: 2325179,
      prevValue: 2538367,
      diff: "− 8.4%",
    },
    leftKPI: {
      label1: "BMW",
      label2: "in units",
      maxValue: 3000000,
      currentValue: 2028841,
      prevValue: 2185793,
      diff: "− 7.1%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Rolls-Royce",
      label2: "in units",
      maxValue: 100000,
      currentValue: 3756,
      prevValue: 5100,
      diff: "− 26.4%",
      rate: 0.2,
    },
    rightKPI_2: {
      label1: "MINI",
      label2: "in units",
      maxValue: 3000000,
      currentValue: 292582,
      prevValue: 347474,
      diff: "− 15.8%",
      rate: 0.05,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          theme: InfoCardTheme.Default,
          title: "Five-year-comparison",
          category: "Automobile deliveries",
          component: TourComponents.Deliveries,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Automobile deliveries",
          content: [
            {
              copytext:
                "<p>BMW Group asserts its position as leading premium manufacturer.</p><p>The worldwide restrictions placed on public and private life due to the coronavirus pandemic also affected the BMW Group and its dealership organisation. Against this backdrop, deliveries were extremely volatile in 2020. After a promising start to the year, sales figures slumped in the spring, before recovering from the third quarter onwards. During the final months of the year, the BMW Group returned to its successful course.</p><p>In 2020, the BMW Group delivered a total of 2,325,179 BMW, MINI and Rolls-Royce brand automobiles to customers (2019: 2,537,504 units; – 8.4 %), once again leading the premium segment worldwide.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          category: "Automobile Deliveries",
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_Automobile.jpg'><br/><br/><p>The coronavirus pandemic has had a huge impact on our retail outlets worldwide. Pieter Nota, member of the Board of Management responsible for Customer, Brands, Sales, explains how we realised a sales process that is as contact-free and as convenient as possible for our customers: “We enabled sales representatives at our retail partners in more than 60 markets to advise customers and sell vehicles from home without restrictions, from any location, back in April. So, for example, they can configure a customer's ideal car together with them on the phone using a shared screen or even give them a live tour of the cars they have in stock.”</p><p>“The second step for us was to take the sales process for retail partners largely online within a short space of time via our high-traffic websites in key markets. We plan to continue the rollout in other markets this year and are working with retailers to give our customers the option of buying their individually configured custom vehicle entirely online in the future and having it delivered to their front door – creating an entire online customer journey that is completely seamless: from configuration to ordering and throughout the use phase.” </p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 21,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  EBITMarginChart: {
    title: "EBIT margin automotive segment",
    tooltip: "Profit/loss before financial result as a percentage of revenues.",
    margin: {
      label1: "EBIT margin",
      label2: "in %",
      maxValue: 10,
      maxMobileValue: 5,
      currentValue: 2.7,
      prevValue: 4.9,
      diff: "− 44.9%",
      maxMargin: 3,
      minMargin: 0,
    },
    earnings: {
      label1: "Profit before financial",
      label2: "result (EBIT) in € million",
      maxValue: 10000,
      currentValue: 2162,
      prevValue: 4499,
      diff: "− 51.9%",
    },
    sales: {
      label1: "Revenues",
      label2: "in € million",
      maxValue: 100000,
      currentValue: 80853,
      prevValue: 91682,
      diff: "− 11.8%",
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "EBIT margin automotive segment",
          component: TourComponents.EbitMargin,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "EBIT margin automotive segment",
          content: [
            {
              copytext:
                "<p>The segment EBIT margin (profit before financial result divided by revenues) came in at 2.7 % (2019: 4.9 %; – 2,2 percentage points). As forecast in the quarterly statement to 30 September 2020, the EBIT margin was within the target range of between 0 and 3 % and therefore in line with the revised outlook. In the 2019 Annual Report, a segment EBIT margin within a target range of between 2 and 4 % was forecast.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "EBIT margin automotive segment",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_EBIT_Marge.jpg'><br/><br/><p>BMW M GmbH not only defended its market dominance of the segment in 2020 – with its range of performance and high-performance cars, it expanded it.</p><p>“The fact that we were able to conclude this exceptionally challenging year with yet another record is great validation of our consistent growth strategy,” according to Markus Flasch, Chairman of the Board of Management of BMW M GmbH.</p><p>The fascination of BMW M vehicles stems from technology transferred from motor racing, as well as precisely coordinated features in the fields of drive train, suspension, aerodynamics and interior design.</p><p>The future looks bright: Not only will the new generation of BMW M3 and M4 models be released onto the market in 2021 – BMW M GmbH will also be unveiling its first performance automobile with an electrified drive train.</p><p>BMW M3 Competition Sedan (fuel consumption combined: 10.2 l/100 km [27.7 mpg imp]; CO2 emissions combined: 234 g/km). BMW M4 Competition Coupé (fuel consumption combined: 10.2 l/100 km [27.7 mpg imp]; CO2 emissions combined: 234 g/km).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 25,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  ElectricVehiclesChart: {
    title: "Deliveries of electrified vehicles",
    mainKPI: {
      label1: "Electrified vehicles",
      label2: "in units",
      maxValue: 200000,
      currentValue: 192662,
      prevValue: 146178,
      diff: "+ 31.8%",
    },
    leftKPI: {
      label1: "Fully electric vehicles",
      label2: "in units",
      maxValue: 200000,
      currentValue: 44541,
      prevValue: 39522,
      diff: "+ 12.7%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Plug-in hybrids",
      label2: "in units",
      maxValue: 200000,
      currentValue: 148121,
      prevValue: 106639,
      diff: "+ 38.9%",
      rate: 0.25,
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Deliveries of electrified vehicles",
          content: [
            {
              copytext:
                "<p>The growing number of electrified models offered by BMW and MINI brands was again reflected in the sharply rising delivery figures recorded in 2020. Over the twelve-month period, the BMW Group sold a total of 192,662 electrified BMWs and MINIs worldwide, around one-third (31.8 %) up on the previous year (2019: 146,158 units). Accordingly, electric mobility has become a significant growth driver for business. In the meantime, the BMW Group has expanded its portfolio of electrified vehicles to 14 models, which are available in a total of 95 countries worldwide. Based on its existing plans, the BMW Group will have 25 electrified models on the road by 2023.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "Deliveries of electrified vehicles",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_elektrifizierte_Fahrzeuge.jpg'><br/><br/><p>Despite the coronavirus pandemic, we delivered about a third more electrified BMW and MINI vehicles to customers than the previous year. As well as fully-electric vehicles, our plug-in hybrids are also proving very popular with customers.</p><p>We want to actively increase the amount of electric driving by plug-in hybrids through the use of innovative digital services. That is why we developed BMW eDrive Zones. This service enables a BMW plug-in hybrid to recognise when it is about to enter a pre-defined zone, such as a green zone or an inner-city area, so it can automatically switch to locally emission-free, pure electric mode.</p><p>With the BMW Points programme, PHEV customers also earn bonus points for every kilometre driven on electric power. These points can then be redeemed for useful rewards, such as BMW Charging credits.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 21,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  EmissionsChart: {
    title: "CO2 emissions",
    tooltip:
      "The calculation of average CO2 fleet emissions of a manufacturer is reported through the weighted average of CO2 emissions by all vehicles newly registered in the reporting period. The calculation is based on the volume of new registrations of the manufacturer in the EU in the calendar year as well as the individual vehicle-specific CO2 emissions, which have been calculated based on the WLTP test cycle and adapted to the New European Driving Cycle (NEDC). Additional effects from the recognition of eco-innovations are not taken into consideration in the disclosure of fleet emissions for 2019. For the 2020 forecast, the disclosure relates purely to a reduction of the CO2 fleet emissions and not the CO2 fleet limit, which the BMW Group needs to achieve.This means that the recognition of super credits, phase-in and eco-innovations is not part of the forecast.",
    value1: {
      label1: "CO2 emissions",
      label2: "per vehicle produced in t",
      maxValue: 1,
      currentValue: 0.23,
      prevValue: 0.3,
      diff: "– 23.3%",
    },
    value2: {
      label1: "CO2 emissions",
      label2: "EU new vehicle fleet",
      label3: "in g CO2/km",
      labelMobile1: "CO2 emissions",
      labelMobile2: "EU new vehicle fleet",
      labelMobile3: "in g CO2/km",
      labelMobile4: "",
      maxValue: 650,
      currentValue: 99,
      prevValue: 127,
      diff: "– 22.0%",
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "CO2 emissions",
          content: [
            {
              copytext:
                "<p>Carbon emissions per vehicle produced fell by around 23 % to 0.23 t compared with the previous year. The reduction is a result of completely switching the BMW Group’s production network to green electricity generated from renewable sources. The BMW Group also managed to reduce the absolute level of carbon emissions generated by the production network to 486,630 t (2019: 697,025 t).</p><p>Measures taken by the BMW Group to reduce fleet-wide carbon emissions are having a marked effect. The increased share of electrified vehicles delivered and the rigorous use of Efficient Dynamics technologies have enabled the BMW Group to achieve the stipulated fleet CO2 limit for 2020, based on regulatory requirements. Fleet carbon emissions of vehicles delivered in Europe at the end of the reporting period were 99 g CO2 / km (2019: 127 g CO2 / km; – 22.0 %).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "CO2 emissions",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_CO2Emissionen.jpg'><br/><br/><p>In 2012, the BMW Group set itself ten sustainability goals extending all the way to 2020. We had questions: for example, for Christian Miedaner and Andreas Helfmann. One of the goals was to reduce the CO2 emissions of our European new vehicle fleet by half between 1995 and 2020. We accomplished this – and even managed to achieve minus 53 percent.</p><p>We also had questions for Patricia Perez Szmak and Jury Witschnig, who are responsible for CO2 emissions in the supply chain and production, respectively. There are successes to celebrate here, too.</p><p>You can read more about this in the interviews.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Production, Purchasing and Supplier Network",
          page: 9,
          src: "/data/pdf/en/Production-Purchasing-Suppliernetwork.pdf",
        },
      },
    ],
  },
  GlobalMarketsChart: {
    title: "Key automobile markets",
    labels: {
      america: "America in %",
      europe: "Europe in %",
      asia: "Asia in %",
      other: "Others in %",
      legend1: "* in %",
      legend2: "",
    },
    charts: {
      america: 16.3,
      europe: 39.3,
      asia: 42.4,
      other: 2.0,
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Key automobile markets",
          content: [
            {
              copytext:
                "<p>Deliveries up in Asia and down in Europe and America.</p><p>BMW Group sales in Asia grew solidly in 2020, with deliveries to customers rising by 6.0 % to a total of 986,464 BMW, MINI and Rolls-Royce brand vehicles (2019: 930,767 units). In China in particular, the BMW Group surpassed the previous year’s figure by 7.4 %, with 778,412 units delivered to customers (2019: 724,711 units).</p><p>By contrast, sales performance in Europe was affected more severely by the coronavirus crisis. In an unprecedented year marked by restrictions strongly affecting retail business, the BMW Group delivered a total of 913,642 units across the region (2019: 1,081,649; – 15.5 %). The number of vehicles delivered in Germany fell by 13.8 % to 285,019 units (2019: 330,507 units).</p><p>Figures for the UK deteriorated significantly, with deliveries falling to 163,174 units (2019: 233,771 units; – 30.2 %), not least due to uncertainty over reaching a trade deal with the EU and the course of the pandemic.</p><p>Sales of the BMW Group’s three brands on the American continent totalled 379,714 units (2019: 472,879 units; – 19.7 %). A total of 307,876 units were delivered to customers in the USA (2019: 375,726 units; – 18.1 %). Despite a challenging environment due to the coronavirus pandemic, the final quarter here finished strongly on the back of rising demand.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 22,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  KeyFiguresChart: {
    title: "Group key figures",
    tooltip: "",
    mainKPI: {
      label1: "Group profit before",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 5222,
      prevValue: 7118,
      diff: "− 26.6%",
      rate: 1,
    },
    leftKPI: {
      label1: "Group revenues",
      label2: "in € million",
      maxValue: 120000,
      currentValue: 98990,
      prevValue: 104210,
      diff: "− 5.0%",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Group net",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 3857,
      prevValue: 5022,
      diff: "− 23.2%",
      rate: 0.6,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "Group key figures",
          component: TourComponents.Keyfigures,
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 9,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  MotoChart: {
    title: "Motorcycles deliveries",
    tooltip: "",
    label1: "",
    label2: "in units",
    maxValue: 200000,
    currentValue: 169272,
    prevValue: 175162,
    diff: "− 3.4%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "Motorcycles deliveries",
          component: TourComponents.Moto,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Motorcycles deliveries",
          content: [
            {
              copytext:
                "<p>Motorcycles segment deliveries dropped slightly to 169,272 units (2019: 175,162 units; – 3.4 %) due to the global pandemic. Favourable market developments in the fourth quarter, however, caused the total number of motorcycles delivered over the twelve-month period to exceed the revised outlook announced in the quarterly statement to 30 September 2020.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "Motorcycles deliveries",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_Motorraeder.jpg'><br/><br/><p>Even with the restrictions from the coronavirus pandemic, BMW Motorrad posted the second-best sales figures in its history last year and introduced many new and updated motorcycle models.</p><p>A particular highlight was the virtual presentation of the BMW Definition CE04 at the #NEXTGen: This scooter, with an electric drive train and innovative connectivity solutions, provides a glimpse of the future. It represents an especially convenient and sustainable way to get around the city.</p><p>BMW Motorrad's first M model celebrated its premiere in September. The M 1000 RR will round out the upper end of the product line-up from 2021.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 27,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  ResearchChart: {
    title: "Research and development expenditure",
    tooltip:
      "The sum of research and noncapitalised development cost and capitalised development cost (not including the associated scheduled amortisation).",
    mainKPI: {
      label1: "Research and development expenditure",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 6140,
      prevValue: 6279,
      diff: "− 2.2%",
    },
    leftKPI: {
      label1_1: "Research and development",
      label1_2: "quote in %",
      label2_1: "Target framework",
      label2_2: "in %",
      maxValue: 8,
      maxMobileValue: 6.3,
      currentValue: 6.3,
      prevValue: 6.3,
      diff: "+ 0.1% points",
      frameworkMin: 5,
      frameworkMax: 5.5,
      frameworkValuesLabel: "5.0 – 5.5",
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Research and development expenditure",
          content: [
            {
              copytext:
                "<p>Research and development expenses were slightly lower than in the previous year. Higher expenses were incurred in 2019 including those relating to the BMW iX* for instance. By contrast, the amount capitalised for future vehicle model start-ups increased in the financial year under report. The higher level of capitalised development costs mainly related to new models, the electrification of the vehicle fleet and automated driving.</p<p>*BMW iX: Power consumption combined: < 21 kWh/100km in the WLTP test cycle; CO2-emissions combined: 0 g/km. Figures are provisional and based on forecasts</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "Research and development expenditure",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_F_E_Leistungen.jpg'><br/><br/><p>Developing a new vehicle requires a lot of cooperation between experts. With its FIZ Future programme, the BMW Group is investing in a digital and fully connected future. At the newly completed FIZ Nord expansion of the existing Research and Innovation Centre, experts from different disciplines and functions conduct research together in the focus areas of vehicle electrification, digitalisation and connectivity.</p><p>“We are developing the technologies that will shape the mobility of tomorrow and ensure our premium vehicles deliver sustainable driving pleasure,” said Oliver Zipse, Chairman of the Board of Management of BMW AG. “This reinforces both our role as a high-tech company and Germany’s status as a research location.”</p><p>The FIZ Nord building complex provides developers with a total floor space of over 150,000 square metres, 100 state-of-the-art test benches, 200 fully equipped laboratories and enormous data processing capacities.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Report on Economic Position",
          page: 11,
          src: "/data/pdf/en/Report-on-Economic-Position.pdf",
        },
      },
    ],
  },
  ShareEarningsChart: {
    title: "Earnings per share",
    tooltip:
      "Basic earnings per share are calculated for common and preferred stock by dividing the net profit after minority interests, as attributable to each category of stock, by the average number of shares in circulation. Earnings per share of preferred stock are computed on the basis of the number of preferred stock shares entitled to receive a dividend in each of the relevant financial years.",
    label1_1: "per share of",
    label1_2: "common stock in €",
    label2_1: "per share of",
    label2_2: "preferred stock in €",
    maxValue: 10,
    currentValue1: 5.73,
    currentValue2: 5.75,
    prevValue: 7.49,
    valueLabel1: "5.73",
    valueLabel2: "5.75",
    diff1: "− 23.3%",
    diff2: "− 23.2%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "Earnings per share",
          component: TourComponents.Shares,
        },
      },
    ],
  },
  WomenChart: {
    title: "Share of women in management functions",
    label1: "Share of women",
    label2: "in %",
    maxValue: 50,
    currentValue: 17.8,
    prevValue: 17.2,
    diff: "+ 3.5%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Five-year-comparison",
          category: "Share of women in management functions",
          component: TourComponents.Women,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Share of women in management functions",
          content: [
            {
              copytext:
                "<p>The percentage of women in management positions within the BMW Group has been rising steadily for many years. Globally, the proportion of female managers in the BMW Group stood at 17.8 % at the end of 2020 (2019: new definition; 17.2 %). At BMW AG, the number of women in management positions has more than doubled between 2011 and the present day. Expressed as a percentage, the proportion of female managers at BMW AG was 16.2 % (2019: new definition; 15.5 %) at the end of 2020.</p><p>The percentage of women employed in the BMW Group’s total workforce reached 19.5 % during the period under report (2019: new definition; 19.3 %), exceeding the internally agreed target range of 15 % to 17 %. At 15.9 %, the proportion of women working for BMW AG was within the target range of 15 % to 17 % (2019: new definition; 15.7 %).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Key figures in context",
          theme: InfoCardTheme.Default,
          category: "Share of women in management functions",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Frauenquote.jpg'><br/><br/><p>In 2012, the BMW Group set itself ten sustainability goals extending all the way to 2020. We had questions: for example, for Gabriele von Stetten and Sabine Distler, who are jointly responsible for diversity strategy in our Human Resources division. We wanted to know what the percentage of women in management looks like and how the other dimensions of diversity are doing.</p><p>You can read more about this in the interview.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Combined Management Report",
          headline: "Employees and Society",
          page: 13,
          src: "/data/pdf/en/Employees-Society.pdf",
        },
      },
    ],
  },
  TourEnd: [
    {
      type: "Tour",
      theme: "BlueBold",
      color: "Black",
      hideMobileHeadline: true,
      headline: "Because credibility comes from transparency.",
      image: {
        src: "/data/teaser-1.png",
      },
      link: {
        href: "/value-creation/",
        label: "How the BMW Group<br/> creates value",
        analyticsEvent: AnalyticsEventTypes.TourEndGoToVCP,
      },
      mobileOrder: 2,
    },
    {
      type: "Tour",
      theme: "BlueSaturated",
      color: "White",
      hideMobileHeadline: true,
      headline: "Selected key figures from our financial year 2020",
      image: {
        src: "",
      },
      link: {
        href: "#",
        label: "",
      },
      controls: [
        {
          label: "Replay",
          analyticsEvent: AnalyticsEventTypes.TourEndReplay,
        },
        {
          label: "Restart",
          analyticsEvent: AnalyticsEventTypes.TourEndRestart,
        },
      ],
      mobileOrder: 1,
    },
    {
      type: "Tour",
      theme: "GrayLightest",
      color: "Black",
      headline: "The BMW Group Report 2020 as PDF",
      image: {
        src: "",
      },
      bgImage: {
        srcMobile: "/data/teaser-4.jpg",
        src: "/data/teaser-4-portrait.jpg",
      },
      link: {
        icon: "",
        href: "/data/pdf/en/BMW-Group-Bericht-2020-EN.pdf",
        label: "Download",
        download: true,
        analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
      },
      mobileOrder: 3,
    },
  ],
};
