import { AnalyticsPageHandler, CookiesModal, Footer, Routes } from "#components";
import { useFontLoader } from "#hooks";
import { routes } from "#pages/routes";
import { DarkOverlayProvider, ModalProvider, RoutingProvider, ScrollLockProvider } from "#providers";
import { DarkOverlay, GlobalStyle, Loader, MobileLandscapeBlocker } from "#ui";
import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
  min-height: 100%;
  display: flex;
`;

export const App: React.FC = () => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const { isLoaded: isFontLoaded } = useFontLoader();
  return (
    <ScrollLockProvider>
      <BrowserRouter>
        <RoutingProvider>
          <DarkOverlayProvider>
            <ModalProvider modalContainerRef={modalContainerRef}>
              <Container ref={modalContainerRef} id="app-container">
                <GlobalStyle />
                <AnalyticsPageHandler />
                {isFontLoaded && <Routes list={routes} level={0} />}
                <CookiesModal />
              </Container>
              <Footer />
              <DarkOverlay />
            </ModalProvider>
          </DarkOverlayProvider>
        </RoutingProvider>
      </BrowserRouter>
      <Loader isFontLoaded={isFontLoaded} />
      <MobileLandscapeBlocker />
    </ScrollLockProvider>
  );
};
