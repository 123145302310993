import { RefObject, useEffect, useMemo, useState, useRef } from "react";
import { gsap } from "gsap";
import { chartReverseDuration, chartSideItemDuration, chartSideItemTimeDelay } from "#constants";
import { Power3 } from "gsap/gsap-core";
import { ChartProps } from "#interfaces";

interface ChartAnimationProps extends ChartProps {
  maxValue: number;
  currentValue: number;
  prevValue: number;
  minDegree: number;
  maxDegree: number;
  currentPathRef: RefObject<SVGPathElement>;
  prevPathRef: RefObject<SVGPathElement>;
  negativePathRef: RefObject<SVGPathElement>;
  lineRef: RefObject<SVGPathElement>;
  contentRef: RefObject<SVGGElement>;
  outerLineRef?: RefObject<SVGGElement>;
  duration: number;
  delay?: number;
  sideItemDelay?: number;
  left?: boolean;
}

type useChartAnimation = (chartArguments: ChartAnimationProps) => void;

const getCurrentLength = (pathLength: number, percent: number): number => {
  return (pathLength / 100) * percent;
};

export const useChartAnimation: useChartAnimation = ({
  playIn,
  playOut,
  onPlayOutEnd,
  maxValue,
  currentValue,
  prevValue,
  minDegree,
  maxDegree,
  contentRef,
  currentPathRef,
  prevPathRef,
  negativePathRef,
  lineRef,
  outerLineRef,
  duration,
  sideItemDelay = 0,
  delay: animationDelay = 0,
  left = false,
}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pathLength, setPathLength] = useState(0);
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(() => gsap.timeline({ defaults: { ease: Power3.easeOut, force3D: true }, paused: true }), []);
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );
  const currentPercent = Number(((100 / maxValue) * currentValue).toFixed(1));
  const prevPercent = Number(((100 / maxValue) * prevValue).toFixed(1));
  const delay = animationDelay + chartSideItemTimeDelay + sideItemDelay;

  useEffect(() => {
    setPathLength(Math.round(currentPathRef.current?.getTotalLength() || 0));
    setLoaded(true);
  }, [currentPathRef]);

  useEffect(() => {
    const isPositive = currentPercent >= prevPercent;
    const percentageDiff = Math.abs(currentPercent - prevPercent);
    const prevLineDuration = isPositive ? duration * (1 - percentageDiff / currentPercent) : duration;
    const currentLineDuration = isPositive
      ? duration * (percentageDiff / currentPercent) + 0.5
      : duration * (percentageDiff / prevPercent) + 0.5;
    const prevPathLength = getCurrentLength(pathLength, prevPercent);
    const currentPathLength = getCurrentLength(pathLength, currentPercent);
    const prevValueLineRotation = minDegree + (maxDegree * prevPercent) / 100;
    const prevValueLineRotationLeft = maxDegree - (minDegree * prevPercent) / 100;
    const currentValueLineRotation = minDegree + (maxDegree * currentPercent) / 100;
    const currentValueLineRotationLeft = maxDegree - (minDegree * currentPercent) / 100;
    if (!isLoaded) return;
    tl.set(lineRef.current, {
      rotate: left ? maxDegree : minDegree,
      transformOrigin: left ? "left center" : "right center",
      opacity: 0,
    })
      .set([prevPathRef.current, negativePathRef.current, currentPathRef.current], {
        strokeDasharray: `.001 ${pathLength}`,
      })
      .to([prevPathRef.current, negativePathRef.current], {
        duration: prevLineDuration,
        delay,
        strokeDasharray: `${prevPathLength} ${pathLength}`,
      })
      .to(contentRef.current, { opacity: 1, y: 0, duration: 0.3 })
      .to(lineRef.current, { opacity: 1, duration: 0.3, delay }, 0)
      .to(
        lineRef.current,
        { duration: prevLineDuration, delay, rotate: left ? prevValueLineRotationLeft : prevValueLineRotation },
        0
      )
      .to(
        lineRef.current,
        { duration: currentLineDuration, rotate: left ? currentValueLineRotationLeft : currentValueLineRotation },
        delay + prevLineDuration
      );

    if (!isPositive) {
      tl.to(
        prevPathRef.current,
        { duration: currentLineDuration, strokeDasharray: `${currentPathLength} ${pathLength}` },
        delay + prevLineDuration
      );
      tlReverse.to(prevPathRef.current, { strokeDasharray: `${prevPathLength} ${pathLength}` }, 0);
    } else {
      tl.set(currentPathRef.current, { opacity: 1, strokeDashoffset: -prevPathLength }, delay + prevLineDuration).to(
        currentPathRef.current,
        {
          duration: currentLineDuration,
          strokeDashoffset: -prevPathLength,
          strokeDasharray: `${currentPathLength - prevPathLength} ${pathLength}`,
        },
        delay + prevLineDuration
      );
      tlReverse.to(currentPathRef.current, { strokeDasharray: `.001 ${pathLength}` }, 0);
    }
    if (outerLineRef) {
      tl.fromTo(
        outerLineRef.current,
        { opacity: 0, x: left ? -80 : 80 },
        { opacity: 1, x: 0, duration: chartSideItemDuration, ease: Power3.easeOut, delay: sideItemDelay },
        0
      );
      tlReverse.to(outerLineRef.current, { opacity: 0, x: left ? -80 : 80 }, 0);
    }

    tlReverse
      .to(contentRef.current, { opacity: 0, y: 10 }, 0)
      .to([negativePathRef.current, prevPathRef.current, currentPathRef.current], { opacity: 0 }, 0)
      .to(lineRef.current, { rotate: left ? prevValueLineRotationLeft : prevValueLineRotation, opacity: 0 }, 0);
  }, [isLoaded]); // eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
  }, [playIn, playOut]); // eslint-disable-line
};
