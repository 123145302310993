import { useTranslation } from "#hooks";
import { bp, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid, NavButton, WindowHeightContainer, Wrapper } from "#ui";
import React from "react";
import styled from "styled-components/macro";

const Container = styled(WindowHeightContainer)`
  padding-top: 155px;
  position: relative;
  @media ${bp.m} {
    padding-top: 185px;
  }
  @media ${bp.p} {
    padding-top: 235px;
  }
`;
const Content = styled.div`
  ${fontCondensed}
  ${gridColumnStart(2)}
  ${gridColumnWidth(11)}
  @media ${bp.m} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(16)}
  }
`;
const Headline = styled.h1`
  position: relative;
  font-size: 29px;
  line-height: 29px;
  @media ${bp.m} {
    font-size: 52px;
    line-height: 52px;
  }
  @media ${bp.t} {
    font-size: 67px;
    line-height: 67px;
  }
  @media ${bp.d} {
    font-size: 80px;
    line-height: 80px;
  }
  &::before {
    content: "”";
    [lang="en"] & {
      content: "“";
    }
    position: absolute;
    right: 100%;
    margin-right: 0.1em;
    top: -0.2em;
  }
  &::after {
    content: "“";
    [lang="en"] & {
      content: "”";
    }
    margin-left: 0.1em;
  }
  small {
    font-weight: normal;
    display: block;
    font-size: 21px;
    line-height: 1;
    margin-bottom: 0.5em;
    @media ${bp.m} {
      font-size: 42px;
    }
    @media ${bp.d} {
      font-size: 50px;
    }
  }
`;
const Author = styled.div`
  font-size: 21px;
  line-height: 26px;
  margin-top: 0.5em;
  @media ${bp.m} {
    font-size: 42px;
    line-height: 52px;
  }
  @media ${bp.d} {
    font-size: 50px;
    line-height: 62px;
  }
`;

const ScrollLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
`;
const Label = styled.div`
  transform-origin: 50% 50%;
  transform: rotate(90deg);
`;

export const Hero: React.FC<{ id: string }> = ({ id }) => {
  const { t: tHome } = useTranslation("home");
  const { headline, author } = tHome("hero");
  const { t } = useTranslation("common");
  return (
    <Container id={id}>
      <Wrapper>
        <Grid>
          <Content>
            <Headline dangerouslySetInnerHTML={{ __html: headline }} />
            <Author>{author}</Author>
          </Content>
        </Grid>
      </Wrapper>
      <ScrollLabel>
        <Label>
          <NavButton>{t("Scroll")}</NavButton>
        </Label>
      </ScrollLabel>
    </Container>
  );
};
