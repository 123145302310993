import React, { FC } from "react";
import styled, { css } from "styled-components/macro";
import { Grid } from "../Grid/Grid";
import { Wrapper } from "../Wrapper/Wrapper";
import { ImageCaption } from "../ImageGallery/ImageGallery";
import { ImageAuthorProps } from "../Image/ImageAuthor";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";
import htmr from "htmr";

export const ImageWrapper = styled.div<{ align: ImageWrapperAlign; page: ImageWrapperPage }>`
  ${({ align, page }) =>
    align === ImageWrapperAlign.Left &&
    css`
      ${gridColumnStart(1)}
      ${gridColumnWidth(12)}
  @media ${bp.s} {
        ${gridColumnStart(2)}
        ${gridColumnWidth(10)}
      }
      @media ${bp.m} {
        ${gridColumnStart(3)}
        ${gridColumnWidth(9)}
      }
      @media ${bp.t} {
        ${gridColumnStart(page === ImageWrapperPage.Diversity ? 0 : 3)}
        ${gridColumnWidth(page === ImageWrapperPage.Diversity ? 11 : 7)}
      }
    `}
  ${({ align }) =>
    align === ImageWrapperAlign.Center &&
    css`
      ${gridColumnStart(1)}
      ${gridColumnWidth(12)}
      @media ${bp.s} {
        ${gridColumnStart(2)}
        ${gridColumnWidth(10)}
      }
      @media ${bp.m} {
        ${gridColumnStart(6)}
        ${gridColumnWidth(14)}
      }
    `}
`;

const ImageElement = styled.img`
  width: 100%;
  display: block;
`;

export enum ImageWrapperAlign {
  Left = "Left",
  Center = "Center",
}
export enum ImageWrapperPage {
  Diversity = "Diversity",
}

interface InterviewImage {
  src: string;
  isCaptionFullWidth: boolean;
  align: ImageWrapperAlign;
  page: ImageWrapperPage;
  caption: ImageAuthorProps;
}

export const InterviewImage: FC<InterviewImage> = ({ align, page, src, caption, isCaptionFullWidth }) => (
  <Wrapper>
    <Grid>
      <ImageWrapper align={align} page={page}>
        <ImageElement src={src} />
        <ImageCaption isCaptionFullWidth={isCaptionFullWidth}>{htmr(caption.desc)}</ImageCaption>
      </ImageWrapper>
    </Grid>
  </Wrapper>
);
