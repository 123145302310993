import { PageName } from "#interfaces";
import { pages } from "./pages";

export const nav = {
  topItems: {
    list: [
      {
        list: [
          {
            pageName: PageName.intro_ceo,
            to: pages.list[PageName.intro_ceo].path,
            title: "Wir machen BMW nachhaltig",
            text: "Oliver Zipse über erreichte Ziele und die strategische Ausrichtung der BMW Group",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.intro_cfo,
            to: pages.list[PageName.intro_cfo].path,
            title: "Integrierter Bericht 2020",
            text: "Nicolas Peter über die neue Art der Berichterstattung und das Geschäftsjahr 2020",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.guidedTour2020,
            to: pages.list[PageName.guidedTour2020].path,
            title: "Guided Tour 2020",
            text: "Ausgewählte Kennzahlen",
          },
        ],
      },
      {
        list: [
          {
            pageName: PageName.interactiveValueCreationProcess,
            to: pages.list[PageName.interactiveValueCreationProcess].path,
            title: "Wie die BMW Group Werte schafft",
            text: "Der Wertschöpfungsprozess der BMW Group",
          },
        ],
      },
    ],
  },
  bottomItems: {
    list: [
      {
        title: "Bericht",
        list: [
          { to: pages.list[PageName.reportOfTheSupervisoryBoard].path, text: "Bericht des Aufsichtsrats" },
          {
            to: pages.list[PageName.statementOfTheChairmanOfTheBoardOfManagement].path,
            text: "Vorwort des Vorstandsvorsitzenden",
          },
          { to: pages.list[PageName.financialStatements].path, text: "Konzernabschluss" },
        ],
      },
      {
        title: "Erfolge",
        list: [
          {
            to: pages.list[PageName.interviewFleetEmissions].path,
            text: "Ziel übererfüllt – Interview zum Thema CO2 Flottenemissionen",
          },
          {
            to: pages.list[PageName.interviewCleanProduction].path,
            text: "Ambitioniert – Interview zu Nachhaltigkeit in der Produktion und im Lieferantennetzwerk",
          },
          {
            to: pages.list[PageName.interviewDiversity].path,
            text: "Vielfalt ist eine Stärke – Interview zum Thema Diversität",
          },
        ],
      },
    ],
  },
};
