import React from "react";
import styled, { css } from "styled-components/macro";
import { Color } from "#shared/theme";

interface BtnProps {
  primary: boolean;
}

const Btn = styled.button<BtnProps>`
  ${({ primary }) =>
    primary &&
    css`
      background-color: ${Color.Green};
    `};
`;

export interface ButtonProps {
  primary?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ primary = false, children, ...props }) => {
  return (
    <Btn type="button" primary={primary} {...props}>
      {children}
    </Btn>
  );
};
