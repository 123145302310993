import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

const Border = styled.svg`
  position: absolute;
  display: block;
  height: calc(100% + 16px);
  width: calc(100% + 16px);
  left: -8px;
  top: -8px;
  bottom: 8px;
  right: 8px;
`;

export interface ProgressCircleProps {
  onClick?: () => void;
  progress?: number;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress }) => {
  const borderRef = useRef<SVGCircleElement>(null);
  const [circleSizes, setCircleSizes] = useState({ length: 0, currentLength: 0 });
  useEffect(() => {
    if (progress !== undefined && borderRef.current) {
      const length = borderRef.current?.getTotalLength();
      const currentLength = length - (length * progress) / 100;
      setCircleSizes({ length, currentLength });
    }
  }, [borderRef, progress]);
  return (
    <Border viewBox="0 0 50 50">
      <circle
        strokeDasharray={circleSizes.length}
        strokeDashoffset={circleSizes.currentLength}
        ref={borderRef}
        cx="25"
        cy="25"
        r="23"
        strokeWidth="4"
        fill="none"
        stroke="#000"
        vectorEffect="non-scaling-stroke"
      />
    </Border>
  );
};
