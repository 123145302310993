import { chartMobileItemDuration } from "#constants";
import { useChartAnimationMobile, useNumberFormat } from "#hooks";
import { ChartPropsMobile } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";

const maxHeight = 48;
export const Frameworks: React.FC<ChartPropsMobile> = ({ labels, ...props }) => {
  const currentPathRef = useRef<SVGLineElement>(null);
  const prevPathRef = useRef<SVGLineElement>(null);
  const negativePathRef = useRef<SVGLineElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPolylineElement>(null);
  const topLineRef = useRef<SVGLineElement>(null);
  const rectHeight =
    (maxHeight * (Number(labels?.frameworkMax) - Number(labels?.frameworkMin))) / Number(labels?.maxMobileValue);
  const rectOffset = (maxHeight * Number(labels?.frameworkMin)) / Number(labels?.maxMobileValue);
  const rectY = 322 - rectOffset - rectHeight;
  const pathLength = useChartAnimationMobile({
    ...props,
    maxManualValue: Number(labels?.maxMobileValue),
    currentValue: Number(labels?.currentValue),
    prevValue: Number(labels?.prevValue),
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    negativePathRef,
    topLineRef,
    animationDuration: chartMobileItemDuration,
    fullHeight: maxHeight,
  });
  return (
    <g fill="none">
      <rect x="148" y={rectY} opacity="0.2" fill="#FFFFFF" width="30" height={rectHeight} />

      <line
        ref={negativePathRef}
        opacity=".3"
        stroke={Color.BlackLight}
        strokeWidth="9"
        x1="159.5"
        y1="322"
        x2="159.5"
        y2="322"
      />
      <line ref={prevPathRef} stroke={Color.BlackLight} strokeWidth="9" x1="159.5" y1="322" x2="159.5" y2="322" />
      <line ref={currentPathRef} stroke={Color.White} strokeWidth="9" x1="159.5" y1="322" x2="159.5" y2="322" />
      <line ref={topLineRef} stroke={Color.White} strokeWidth="9" x1="159.5" y1="322" x2="159.5" y2="322" />
      <polyline
        ref={lineRef}
        stroke={Color.White}
        strokeMiterlimit="9"
        points="284,273 210,273 164,322 155,322"
        strokeDashoffset={pathLength}
        strokeDasharray={pathLength}
      />

      <SvgText ref={contentRef} alignEnd>
        <SvgTextSmall>
          <text fill={Color.BlackLight} transform={`matrix(1 0 0 1 146 ${326 - rectOffset})`} fontSize="6">
            {labels?.frameworkMin}
          </text>
          <text fill={Color.BlackLight} transform={`matrix(1 0 0 1 146 ${rectY})`} fontSize="6">
            {labels?.frameworkMax}
          </text>
          <text transform="matrix(1 0 0 1 284 261.7695)" opacity="0.5" fontSize="10">
            {labels?.diff}
          </text>
          <text fill={Color.BlackLight} transform="matrix(1 0 0 1 283.9998 200.7695)" fontSize="10">
            <tspan x="0" y="0">
              {labels?.label1_1}
            </tspan>
            <tspan x="0" y="12">
              {labels?.label1_2}
            </tspan>
          </text>
          <text fill={Color.BlackLight} transform="matrix(1 0 0 1 284 120.7695)" fontSize="10">
            <tspan x="0" y="0">
              {labels?.label2_1}
            </tspan>
            <tspan x="0" y="12">
              {labels?.label2_2}
            </tspan>
          </text>
        </SvgTextSmall>
        <text transform="matrix(1 0 0 1 284 160.666)" fontSize="28">
          {labels?.frameworkValuesLabel}
        </text>
        <text transform="matrix(1 0 0 1 283.9998 245.666)" fontSize="28">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
