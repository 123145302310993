import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { ChartProps } from "#interfaces";
import { MainKPI } from "./MainKPI";

const CHART_COUNT = 1;

export const SingleChartDesktop: React.FC<Pick<ChartProps, "labels">> = (props) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 896 544" xmlns="http://www.w3.org/2000/svg">
      <MainKPI {...props} {...chartProps} />
    </SvgContainer>
  );
};
