export const emissions = {
  title: {
    headline:
      "Christian Miedaner is delighted the BMW Group overfulfilled the European Union’s CO2 fleet limit for 2020, but not surprised. Since he’s the one responsible for CO2 reduction during the product use phase, we asked him why.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        src: "/data/interview/emissions/image-emissions-1.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "CO2 strategy for the product use phase – what does that mean?",
        copytext:
          "<p><strong>Christian Miedaner</strong> The task is to ensure our products meet all market-specific regulatory requirements for fleet consumption and CO2 fleet emissions – not just in the short term, but also medium and long-term. That’s where it gets really interesting, because then we’re no longer talking about existing laws and requirements; we’re talking about assessing and anticipating developments in legislation and translating them into our own targets. A lot of markets still haven’t even passed legislation for the period after 2025. But we have to make sure the vehicles we are currently developing will comfortably meet requirements at that time. A complex task: This includes technical measures, decisions on future drive trains, as well as assessing the business cases.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "GreenLight",
        copytext:
          "<p>The percentage of electrified vehicles in Europe <strong>INCREASED</strong> by over 80% last year.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "You mentioned market-specific requirements. The key figures usually refer to CO2 fleet emissions for new vehicles in the EU. Why is that?",
        copytext:
          "<p><strong>Christian Miedaner</strong> The European Union's CO2 limits have been among the strictest for years worldwide. And its targets will remain most ambitious in the future. In many respects, meeting their requirements is the biggest challenge. That’s why we make fulfilling EU targets our main focus. Think of it this way: Once you’ve met European requirements, it’s a lot easier to fulfil those in other markets and regions. Aside from that, Europe is also our biggest sales region.</p><p>But, like I said, my job is to make sure we meet requirements in all markets. That’s why we always monitor our compliance with regulatory requirements in other markets and regions very closely.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1406,
        items: [
          { width: 48, height: 34, src: "/data/interview/emissions/image-emissions-gallery-1.jpg" },
          { width: 26, height: 34, src: "/data/interview/emissions/image-emissions-gallery-2.jpg" },
          { width: 26, height: 34, src: "/data/interview/emissions/image-emissions-gallery-3.jpg" },
        ],
        caption: {
          headline: "",
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Mr Helfmann, what is unique about the situation in Switzerland?",
        copytext:
          "<p>Quite a few things, actually. The CO2 targets for our market are just as tough as in the European Union – but we are coming at them from a different place. Most customers in Switzerland have never even considered an electrified vehicle until now, because there are barely any political incentives in our market – and because the charging situation is far from ideal. Many people in our country opt for all-wheel-drive vehicles with powerful engines because of the Alps. So we were especially proud that we were able to exceed our targets in Switzerland. Our retailers had a lot of convincing to do. We invested a great deal of energy in advising both private and corporate customers on charging options.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        quote:
          "We ultimately overfulfilled the 2020 targets. And that’s a good thing, because we’re not interested in the goals themselves – it’s about contributing to environmental responsibility.",
        author: "Christian Miedaner",
      },
    },
    {
      type: "Image",
      props: {
        align: "Center",
        src: "/data/interview/emissions/image-auto.jpg",
        caption: {
          desc:
            "The BMW Group’s sustainability goals call for us to have more than seven million vehicles with electrified drive trains on the roads by 2030.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Let’s come back to the EU fleet targets for 2020. The BMW Group was always confident it could meet those goals. How did it do it? ",
        copytext:
          "<p><strong>Christian Miedaner</strong> There was obviously a lot of work involved. We succeeded because we always took a forward-looking approach and made the right decisions – important decisions – proactively: for instance, the decision to offer electrified vehicles in every segment today. With this wide range of options, we can engage and win over our customers. At the same time, we also decided to continue making our conventional drive trains more efficient. Our Efficient Dynamics technologies have been in use since 2007 – and, today, aerodynamics, lightweight construction, energy management and the integration of innovations are a priority in every one of our new vehicles. Another important step was wide-scale use of 48-volt-systems.</p><p>We ultimately overfulfilled the 2020 targets. And that’s a good thing, because we’re not interested in the goals themselves – it’s about contributing to environmental responsibility.</p>",
      },
    },
    {
      type: "Video",
      props: {
        align: "Center",
        poster: "/data/interview/emissions/co2emmissions_06_videoposter.jpg",
        src: "/data/interview/emissions/co2emissions.mp4",
        proportions: (800 / 1920) * 100,
      },
    },
    {
      type: "ImageQuote",
      props: {
        quote:
          "I don’t think you can take a consistent solution-oriented approach like this for granted at a big company like ours.",
        author: "Christian Miedaner",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "The targets for the future are ambitious as well. What are the biggest challenges?",
        copytext:
          "<p><strong>Christian Miedaner</strong> We are right on track for the targets for 2021 – but the goals for 2030 are a whole other dimension. I’m confident our product portfolio puts us in a good position. By 2023, we will have 25 electrified models on the street. But even when the right products are available, you still need enough customers to ultimately choose electrified models. For that, the conditions also have to be right – and that means significantly expanding charging infrastructure. Policymakers and charging post operators also need to contribute by keeping up with the growth in electromobility.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1406,
        items: [
          { width: 21, height: 39, src: "/data/interview/emissions/image-emissions-gallery-4.jpg" },
          { width: 51, height: 39, src: "/data/interview/emissions/image-emissions-gallery-5.jpg" },
          { width: 28, height: 39, src: "/data/interview/emissions/image-emissions-gallery-6.jpg" },
        ],
        caption: {
          headline: "",
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "What do you enjoy most about your job?",
        copytext:
          "<p><strong>Christian Miedaner</strong>  I like that we at the BMW Group are able to implement big decisions quickly. This is true at all levels, in the markets, at headquarters and in senior management. I don’t think you can take a solution-oriented approach like this for granted at a big company like ours.</p><p>I’m especially pleased with how positively customers are responding to our electrified cars. The percentage of electrified vehicles in Europe increased by over 80% last year – that’s quite significant, don’t you think?</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Thank you for talking to us.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        isDoubleCaption: true,
        parallaxColor: "GreenLight",
        items: [
          {
            src: "/data/interview/emissions/image-emissions-2.jpg",
            caption: {
              headline: "Christian Miedaner",
              desc: "is a believer - he currently drives a BMW 330e plug-in hybrid.",
            },
          },
          {
            src: "/data/interview/emissions/image-emissions-3.jpg",
            caption: {
              headline: "",
              desc:
                "Andreas Helfmann is responsible for strategy and planning at BMW Switzerland. This includes coordinating the CO2 management.",
            },
          },
        ],
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 2,
            link: "/interview/clean-production/",
          },
          {
            storyId: 0,
            link: "/interview/diversity/",
          },
        ],
      },
    },
  ],
};
