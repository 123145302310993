import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card22: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect y="133.5" width="306.5" height="8" />
        <rect x="556" fill="#1EBEE4" width="85" height="228" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="windpower_2_">
          <g>
            <path
              fill="none"
              d="M593.07,139.35C593.07,139.35,593.07,139.35,593.07,139.35C593.07,139.35,593.07,139.35,593.07,139.35z"
            />
            <path
              fill="#659896"
              d="M652.12,146.97c-0.1,0-0.21-0.01-0.31-0.02C652.26,147.04,652.39,147.03,652.12,146.97z"
            />
            <path
              fill="#659896"
              d="M622.45,133.55c0,0.01,0,0.01,0,0.02c0,0-0.35,0.17-0.94,0.17c-0.59,0-0.66-0.17-0.66-0.17
				c-9.46,0.31-18.4,8.68-18.4,8.68s-4.28-2.76-9.37-2.89c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c-0.04,0.03-0.33,0.42-1.75,0.42c-0.76,0-1.38-0.19-1.48-0.23c-7.24,1.08-11.95,6.14-16.49,7.56c0,0,4.22-1.33,11.74-1.86
				c7.29-0.52,14.26,0.53,12.96-0.08s-3.99-2.18,2.02-1.3c6.07,0.89,9.9,3.46,14.65,3.96c17.83,1.91,16.49-3.56,12.85-6.23
				c-7.58-5.56,2.67-4.37,10.57,0c5.45,3.02,10.7,5.08,13.67,5.35c-1.32-0.27-5.4-1.44-10.6-5.24
				C634.63,136.91,630.3,133.69,622.45,133.55z"
            />
            <path
              fill="#DFD7C6"
              d="M593.07,139.35C593.07,139.35,593.07,139.35,593.07,139.35C593.07,139.35,593.07,139.35,593.07,139.35
				C593.07,139.35,593.07,139.35,593.07,139.35c-0.11-2.01-0.81-27.13-0.98-33.26c1.64,1.88,5.12,5.87,7.61,8.72l1.69-1.48
				c-1.66-2.66-3.68-5.89-4.28-6.92c-0.97-1.67-1.17-1.11-2.19-1.29c-0.82-0.14-1.52-0.44-2.06-0.88c-0.12,0.77-0.79,1.37-1.6,1.37
				c-0.35,0-0.67-0.11-0.93-0.3l-0.51,34.23c0,0,0.01,0,0.02,0.01c0.11,0.04,0.72,0.23,1.48,0.23
				C592.74,139.77,593.03,139.38,593.07,139.35z"
            />
            <path
              fill="#DFD7C6"
              d="M592.52,102.97c0.63-1.84,2.61-7.68,3.98-11.72l-2.13-0.72c-1.47,2.78-3.25,6.14-3.84,7.18
				c-0.95,1.68-0.38,1.57-0.01,2.54c0.28,0.76,0.38,1.5,0.28,2.18c0.14-0.04,0.3-0.07,0.45-0.07
				C591.77,102.36,592.23,102.6,592.52,102.97z"
            />
            <path
              fill="#C16032"
              d="M597.83,87.33c0.44-1.28-0.76-1.86-1.45-0.58c-0.31,0.57-1.1,2.06-2.01,3.78l2.13,0.72
				C597.16,89.32,597.67,87.8,597.83,87.33z"
            />
            <path
              fill="#C16032"
              d="M602.42,117.93c0.89,1.02,1.99,0.28,1.23-0.96c-0.34-0.56-1.23-1.99-2.26-3.63l-1.69,1.48
				C601.04,116.35,602.1,117.56,602.42,117.93z"
            />
            <path
              fill="#DFD7C6"
              d="M589.63,103.98c0-0.22,0.05-0.44,0.13-0.63c-2.02,0.41-7.92,1.61-12.02,2.45l0.45,2.2
				c3.14-0.13,6.94-0.29,8.14-0.3c1.93-0.02,1.54-0.46,2.2-1.27c0.47-0.58,1.02-1.02,1.61-1.28
				C589.83,104.86,589.63,104.44,589.63,103.98z"
            />
            <path
              fill="#C16032"
              d="M573.68,106.63c-1.33,0.27-1.23,1.59,0.23,1.54c0.65-0.02,2.34-0.09,4.28-0.17l-0.45-2.2
				C575.74,106.21,574.17,106.53,573.68,106.63z"
            />
            <path
              fill="#C16032"
              d="M592.86,104.24c0.01-0.08,0.02-0.17,0.02-0.26c0-0.38-0.13-0.73-0.35-1.01c-0.3-0.37-0.75-0.61-1.27-0.61
				c-0.16,0-0.31,0.02-0.45,0.07c-0.47,0.14-0.85,0.48-1.04,0.93c-0.08,0.19-0.13,0.41-0.13,0.63c0,0.46,0.19,0.87,0.5,1.17
				c0.06,0.06,0.12,0.11,0.19,0.16c0.26,0.19,0.58,0.3,0.93,0.3C592.06,105.61,592.73,105.01,592.86,104.24z"
            />
            <path
              fill="#DFD7C6"
              d="M621.51,133.74c0.59,0,0.94-0.17,0.94-0.17c0-0.01,0-0.01,0-0.02c-0.05-1.06-0.46-17.28-0.54-20.35
				c-0.32,0.2-0.75,0.19-1.06-0.06c-0.11-0.09-0.19-0.2-0.25-0.32c-0.15,0.36-0.44,0.67-0.83,0.91c-0.43,0.26-0.61,0.05-0.74,0.97
				c-0.08,0.57-0.4,2.37-0.66,3.86l1.02,0.35c0.48-1.39,1.12-3.23,1.56-4.48l-0.08,19.14C620.85,133.56,620.92,133.74,621.51,133.74
				z"
            />
            <path
              fill="#C16032"
              d="M618,120.58c-0.11,0.69,0.51,0.82,0.72,0.21c0.08-0.23,0.33-0.96,0.65-1.88l-1.02-0.35
				C618.19,119.47,618.05,120.27,618,120.58z"
            />
            <path
              fill="#DFD7C6"
              d="M622.19,112.92c0.23,0.04,0.46,0.12,0.68,0.25c0.44,0.24,0.35,0.5,1.21,0.16
				c0.53-0.21,2.25-0.84,3.67-1.36l-0.21-1.06c-1.73,0.34-4.13,0.8-5.35,1.04C622.39,112.24,622.39,112.62,622.19,112.92z"
            />
            <path
              fill="#C16032"
              d="M629.69,111.26c0.66-0.25,0.46-0.85-0.18-0.73c-0.24,0.05-0.99,0.19-1.96,0.38l0.21,1.06
				C628.64,111.65,629.4,111.37,629.69,111.26z"
            />
            <path
              fill="#DFD7C6"
              d="M620.71,111.85c0.08-0.1,0.18-0.18,0.28-0.23c-0.08-0.26-0.11-0.55-0.09-0.85
				c0.04-0.5,0.32-0.52-0.35-1.17c-0.41-0.4-1.69-1.71-2.74-2.8l-0.88,0.62c1.23,1.75,3.11,4.45,3.59,5.14
				C620.48,112.31,620.54,112.06,620.71,111.85z"
            />
            <path
              fill="#C16032"
              d="M616.37,105.32c-0.49-0.5-0.97-0.07-0.59,0.46c0.14,0.2,0.58,0.83,1.15,1.63l0.88-0.62
				C617.15,106.12,616.59,105.54,616.37,105.32z"
            />
            <path
              fill="#C16032"
              d="M620.84,113.14c0.31,0.25,0.74,0.26,1.06,0.06c0.09-0.05,0.17-0.12,0.23-0.2
				c0.02-0.03,0.03-0.05,0.05-0.08c0.2-0.3,0.2-0.68,0.01-0.98c-0.05-0.09-0.12-0.17-0.2-0.23c-0.3-0.24-0.7-0.26-1.01-0.1
				c-0.11,0.06-0.2,0.13-0.28,0.23c-0.17,0.21-0.23,0.47-0.19,0.71c0.01,0.09,0.04,0.18,0.08,0.26
				C620.65,112.94,620.73,113.05,620.84,113.14z"
            />
          </g>
          <path
            opacity="0.3"
            d="M619.06,141.35c-2.21-4.22,2.91-4.72,5.95-5.34c2.31-0.47,2.91-1.39,1.16-1.87
			c-1.25-0.34-2.5-0.52-3.73-0.57c-0.01,0.01-0.03,0.01-0.06,0.02c0.05-0.02,0.08-0.03,0.08-0.03l0-0.02l-0.54-20.35
			c-0.18,0.11-0.39,0.16-0.6,0.14c-0.01,0.04-0.03,0.09-0.05,0.14c0.12,0.28,0.22,0.66,0.24,1.19c0.05,1.41,0.44,18.73,0.45,19.04
			c-0.13,0.02-0.28,0.03-0.45,0.03c-0.47,0-0.61-0.11-0.65-0.15c-3.13,0.15-6.13,1.07-8.77,2.27c-5.67,2.57-9.65,6.39-9.65,6.39
			c5.79,4.74,12.27,5.59,12.27,5.59C619.42,147.75,620.34,143.79,619.06,141.35z"
          />
          <path
            opacity="0.3"
            d="M590.92,142.08c0.94-2.23,4.42-2.36,5.18-2.36c0.09,0,0.15,0,0.15,0c-0.58-0.12-1.14-0.2-1.68-0.26
			c-0.06-0.01-0.11-0.01-0.17-0.02c-0.03,0-0.06-0.01-0.09-0.01c-0.42-0.04-0.84-0.07-1.24-0.08c0,0,0,0,0,0v0l0,0l-0.52-16.65
			l-0.54-17.28c-0.23,0.12-0.48,0.19-0.76,0.19c-0.23,0-0.44-0.05-0.64-0.13c0.34,0.31,0.87,1.02,0.93,2.49
			c0.08,2.15,0.57,31.57,0.57,31.74c0.07,0,0.13-0.01,0.19-0.02c-0.25,0.05-0.57,0.09-0.99,0.09c-0.72,0-1.31-0.17-1.46-0.22
			c-0.9,0.14-1.77,0.34-2.6,0.59c-1.64,0.48-3.17,1.14-4.65,1.92c-1.02,0.53-2.02,1.12-3.03,1.75c-3.76,2.34-5.52,3.06-6.05,3.24
			c-0.05,0.02-0.09,0.03-0.11,0.04c-0.04,0.01-0.06,0.02-0.06,0.02l5.05-1.09l0.94-0.2C585.41,144.84,589.77,144.83,590.92,142.08z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="ix3">
          <g>
            <path
              fill="#1EBEE4"
              d="M575.48,170.4c-6.92-7.1-14.37-15.49-34.53-16.98c-1.07-0.08-2.12-0.15-3.15-0.21l11.42,27.25
				c0.09,0.01,0.17,0.02,0.25,0.02c3.73,0.34,29.72,3.33,32.71,3.53c2.99,0.2,3.49-1.98,3.49-1.98S582.4,177.51,575.48,170.4z"
            />
            <path
              fill="#191C1C"
              d="M426.8,245.8c0,0-9.98,0.55-17.11,1.01c-7.51,0.48-30.19,2.21-14.31,5.98
				c2.33,0.55,16.98,4.03,33.32,6.71c21.08,3.46,31.39,1.82,34.88,1.41c19.67-2.29,37-4.27,54.6-6.6
				c24.08-3.18,86.48-10,93.63-11.22c7.15-1.21-4.47-3.43-23.94-5.08c-14.24-1.21-38.53-3.43-47.48-1.93
				C522.92,239.03,426.8,245.8,426.8,245.8z"
            />
            <path
              fill="#191C1C"
              d="M596.61,232.98c1-0.42,25.09-7.65,25.09-7.65s4.52-26.54-8.03-25.69
				C601.12,200.49,596.61,232.98,596.61,232.98z"
            />
            <path
              fill="#191C1C"
              d="M493.23,239.78c0,0,34.79-0.34,40.48-1.19c5.69-0.85,6.52-36.4-14.72-36.74
				C497.75,201.51,493.23,239.78,493.23,239.78z"
            />
            <g>
              <g>
                <path
                  fill="#191C1C"
                  d="M519.8,209.62c4.83,0,13.57,5.44,12.62,23.4c-0.88,16.62-10.39,21.39-14.6,21.39
						c-3.56,0-20.66-0.35-27.13-13.89c0,0,5.38-0.38,6.52-5.28C498.46,229.84,503.26,209.62,519.8,209.62z"
                />
                <path
                  fill="#5E5D5B"
                  d="M505.1,234.08c-0.33-4.09-1.78-8.46-2.62-11.4c0,0,5.61-14.25,17.66-12.26
						c13.89,2.3,12.49,24.58,11.68,28.03c-1.03,4.37-3.74,15.01-13.64,15.87C508.27,255.18,505.47,238.74,505.1,234.08z"
                />

                <ellipse
                  transform="matrix(0.0288 -0.9996 0.9996 0.0288 272.2682 743.8274)"
                  fill="#FFFFFF"
                  cx="518.92"
                  cy="231.8"
                  rx="18.44"
                  ry="11.45"
                />
                <polygon
                  fill="#191C1C"
                  points="521.79,214.64 522.88,221.58 528.4,222.71 529.59,230.17 525.35,234.56 526.81,243 
						524.39,243.78 522.74,247.64 519.22,242.85 514.33,247.84 512.99,243.73 510.49,243.13 512.67,234.56 508.18,230.3 
						510.11,226.7 509.9,222.3 515.15,221.91 516.97,214.51 519.13,215.49"
                />
                <linearGradient
                  id="Card22SVGID1"
                  gradientUnits="userSpaceOnUse"
                  x1="518.6199"
                  y1="248.4972"
                  x2="519.12"
                  y2="213.6769"
                >
                  <stop offset="0.6685" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.6968" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
                  <stop offset="0.7171" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
                  <stop offset="0.735" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
                  <stop offset="0.7515" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
                  <stop offset="0.7669" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
                  <stop offset="0.7817" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
                  <stop offset="0.7959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
                  <stop offset="0.8093" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
                  <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                </linearGradient>
                <path
                  fill="none"
                  stroke="url(#Card22SVGID1)"
                  strokeMiterlimit="10"
                  d="M516.97,214.51l-2.11,6.05l-4.96,1.75
						c0,0-1.52,4.91-1.72,8l3.17,5.38l-0.86,7.46c0,0,1.39,3.16,3.83,4.7l4.43-3.02l3.96,3.02c0,0,2.84-2.49,4.1-4.84l-0.26-7.26
						l3.04-5.58c0,0,0-4.1-1.19-7.46l-4.43-1.48l-2.18-6.59C521.79,214.64,520.21,213.7,516.97,214.51z"
                />
                <polygon fill="#5E5D5B" points="521.79,214.64 520.93,224.25 522.39,225.8 528.4,222.71 523.97,221.23" />
                <polygon fill="#5E5D5B" points="516.97,214.51 516.24,223.92 514.85,225.66 509.9,222.3 514.85,220.56" />
                <path
                  fill="#5E5D5B"
                  d="M509.9,222.3l1.19,2.96l-1.12,4.3l-1.78,0.74C508.18,230.3,508.51,225.86,509.9,222.3z"
                />
                <path
                  fill="#5E5D5B"
                  d="M528.4,222.71l-1.45,2.62l0.66,4.03l1.98,0.81C529.59,230.17,529.59,226.2,528.4,222.71z"
                />
                <polygon fill="#5E5D5B" points="529.59,230.17 523.64,232.99 522.98,235.68 526.81,243 526.55,235.74" />
                <path
                  fill="#5E5D5B"
                  d="M526.81,243l-1.98-1.28l-2.05,2.42l-0.07,3.7C522.72,247.84,525.95,245.15,526.81,243z"
                />
                <polygon fill="#5E5D5B" points="522.72,247.84 518.95,239.98 517.5,239.64 514.33,247.84 518.75,244.81" />
                <path
                  fill="#5E5D5B"
                  d="M514.33,247.84l0.53-3.43c0,0-2.44-2.69-2.51-2.49s-1.85,1.21-1.85,1.21S512.28,246.63,514.33,247.84z"
                />
                <polygon fill="#5E5D5B" points="510.49,243.13 513.66,235.27 513.27,232.72 508.18,230.3 511.35,235.68" />
                <g>
                  <line fill="#191C1C" x1="526.55" y1="235.74" x2="529.26" y2="237.15" />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="523.97"
                    y1="221.23"
                    x2="525.76"
                    y2="217.6"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="518.75"
                    y1="244.81"
                    x2="518.75"
                    y2="249.45"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="511.35"
                    y1="235.68"
                    x2="508.58"
                    y2="237.02"
                  />

                  <line
                    fill="none"
                    stroke="#5E5D5B"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="514.85"
                    y1="220.56"
                    x2="512.81"
                    y2="217.53"
                  />
                </g>
                <ellipse fill="#FFFFFF" cx="516.99" cy="231.61" rx="1.08" ry="1.85" />
                <ellipse fill="#1EBEE4" cx="516.99" cy="231.61" rx="0.7" ry="1.21" />
              </g>
              <path
                fill="#191C1C"
                d="M417.2,230.29c0,0-1.04,18.52,22.05,18.52c16.54,0,16.86-17.25,16.86-17.25L417.2,230.29z"
              />
              <path
                fill="#5E5D5B"
                d="M417.29,232.41l9.97,2.66c0,0,0.63,10.22,8.7,13.6C435.97,248.67,419.26,248.48,417.29,232.41z"
              />
              <linearGradient
                id="Card22SVGID2"
                gradientUnits="userSpaceOnUse"
                x1="417.294"
                y1="240.5416"
                x2="435.967"
                y2="240.5416"
              >
                <stop offset="0.281" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4606" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
                <stop offset="0.6335" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
                <stop offset="0.7516" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID2)"
                d="M417.29,232.41l9.97,2.66c0,0,0.63,10.22,8.7,13.6C435.97,248.67,419.26,248.48,417.29,232.41z"
              />
            </g>
            <path
              fill="#5E5D5B"
              d="M495.57,239.95c0,0-9.38,1.52-28.84,0.8c-16.15-0.6-65.27-6.3-67.17-14.87l77.15,4.1L495.57,239.95z"
            />
            <linearGradient
              id="Card22SVGID3"
              gradientUnits="userSpaceOnUse"
              x1="454.0421"
              y1="168.6944"
              x2="444.8848"
              y2="259.5151"
            >
              <stop offset="0.6051" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.661" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
              <stop offset="0.7148" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
              <stop offset="0.7516" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID3)"
              d="M495.57,239.95c0,0-9.38,1.52-28.84,0.8c-16.15-0.6-65.27-6.3-67.17-14.87l77.15,4.1
				L495.57,239.95z"
            />
            <polygon fill="#1EBEE4" points="513.64,153.01 543,154.34 551.98,181.09 517.35,177.97 513.74,175.74 			" />
            <polygon
              opacity="0.63"
              fill="#A1C8D9"
              points="513.64,153.01 543,154.34 551.98,181.09 517.35,177.97 513.74,175.74 			"
            />
            <polygon fill="#A59B91" points="579.25,183.38 576.54,179.93 556.26,179.62 557.29,181.51 579.4,184.07 			" />
            <path
              fill="#5E5D5B"
              d="M578.91,183.51c0,0-8.67-11.91-14.86-17.17c-6.19-5.27-20.04-10.42-51.9-8.7l-0.2-4.78
				c0,0,17.88-0.6,31.27,0.65c13.4,1.26,23.19,6.07,34.23,18.66c11.03,12.59,10.25,11.91,10.25,11.91S581.5,186.03,578.91,183.51z"
            />
            <path
              fill="#FFFFFF"
              d="M411.27,171.66c-0.61,0.66-2.28,1.98,0.8,2.17c3.08,0.19,20.38,0.1,20.38,0.1s15.95,0,22.39-0.32
				c6.44-0.32,17.44-0.32,19.57-3.2c2.12-2.88,4.64-7.51,4.64-7.51s-0.1-0.19-0.32-0.49c-0.97-1.34-4.26-4.94-11.62-4.94
				c-9.04,0-33.94,0-36.54,0c-2.53,0-6.67,0.46-11.64,5.75c-0.13,0.14-0.26,0.28-0.39,0.42
				C413.4,169.34,411.88,171.01,411.27,171.66z"
            />
            <linearGradient
              id="Card22SVGID4"
              gradientUnits="userSpaceOnUse"
              x1="618.4926"
              y1="193.3774"
              x2="624.4203"
              y2="199.3051"
            >
              <stop offset="0.2138" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.3653" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
              <stop offset="0.4743" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
              <stop offset="0.5701" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
              <stop offset="0.6582" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
              <stop offset="0.741" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
              <stop offset="0.82" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
              <stop offset="0.8959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
              <stop offset="0.9675" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID4)"
              d="M623.14,200.59c0,0,0.69-5.98-3.38-8.16c0,0-1.79,0.32-0.43,2.36
				C620.69,196.83,623.14,200.59,623.14,200.59z"
            />
            <path
              fill="#FFFFFF"
              d="M624.11,203.91c-0.52-3.01-1.13-3.81-1.13-3.81s-1.39-2.66-2.79-4.52c-1.39-1.86-1.66-2.84-0.35-3.19
				c0,0-4.27-5.94-28.6-11.26c0,0-0.76,0.86-2.88,1.24c0,0-0.16,0.14-0.43,0.31c-0.31,0.19-0.76,0.43-1.31,0.57
				c0,0-1.74,1.51-4.36,1.42c-2.62-0.09-34.61-3.72-34.61-3.72s-16.22-1.33-20.75-1.77c-4.53-0.44-13.51-3.01-17.87-4.34
				c-4.36-1.33-12.73-4.43-14.73-5.05c-2.01-0.62-2.79-1.86-2.27-4.88c0.52-3.01,2.35-8.16,3.05-9.84c0.7-1.68,6.19-2.84,9.94-3.1
				c3.75-0.27,23.45-1.42,36.36,0.18c12.9,1.6,20.96,4.46,29.06,12.42c8.1,7.97,12.91,13.48,12.91,13.48l0.18-0.5l0.44-1.03
				c0,0-16.29-18.47-25.55-21.91c-9.26-3.44-24.57-6.25-37.48-6.88c-12.91-0.63-32.93,0.97-32.93,0.97l-8.52,2.52l-13.17,27.74
				l26.19,22.7l13.33,2.05l13.95-5.36l12.71,5.36l4.49,15.92l60.44,0.63l8.99-20.33l8.68-2.52l4.96,3.15l2.94,6.93l0.46,10.72
				l0.55-1.52C624.02,216.68,624.63,206.93,624.11,203.91z"
            />
            <linearGradient
              id="Card22SVGID5"
              gradientUnits="userSpaceOnUse"
              x1="434.8895"
              y1="149.2485"
              x2="465.1439"
              y2="149.2485"
            >
              <stop offset="0.1913" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.2783" style={{ stopColor: "#FBFBFB", stopOpacity: 0.9172 }} />
              <stop offset="0.3622" style={{ stopColor: "#F0F0F0", stopOpacity: 0.8372 }} />
              <stop offset="0.445" style={{ stopColor: "#DCDCDC", stopOpacity: 0.7584 }} />
              <stop offset="0.527" style={{ stopColor: "#C1C1C1", stopOpacity: 0.6803 }} />
              <stop offset="0.6085" style={{ stopColor: "#9E9E9E", stopOpacity: 0.6026 }} />
              <stop offset="0.6896" style={{ stopColor: "#737372", stopOpacity: 0.5253 }} />
              <stop offset="0.769" style={{ stopColor: "#414140", stopOpacity: 0.4498 }} />
              <stop offset="0.8212" style={{ stopColor: "#1C1C1B", stopOpacity: 0.4 }} />
            </linearGradient>
            <path fill="url(#Card22SVGID5)" d="M434.89,150.99c0,0,6.91-4.19,30.25-3.37" />
            <polyline fill="#FFFFFF" points="465.14,147.62 437.47,151.23 434.89,150.99 			" />
            <path
              fill="#DFD7C6"
              d="M472.89,200.87c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25c-1.97,0.64-9.86,2.25-12.85,2.33
				c-2.22,0.06-26.16-0.85-40.58-1.33c-5.01-0.16-8.87-0.28-10.27-0.28c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98
				c0,1.9,0,3.7,0,4.28c0,1.3-0.17,3.7-0.17,4.88c0,1.18,0.25,3.7,0.25,3.7s0.47,1.09,4.21,1.45c1.46,0.14,9.31,0.63,18.64,1.17
				c14.52,0.84,32.64,1.82,35.83,1.82c5.25,0,19.23-0.72,19.23-0.72s1.38-8.6,1.6-12.67C478.16,205.39,478.68,201.23,472.89,200.87z
				"
            />
            <g>
              <line
                fill="none"
                stroke="#5E5D5B"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="488.46"
                y1="227.73"
                x2="476.76"
                y2="226.62"
              />
              <path
                fill="#5E5D5B"
                d="M477.21,223.01l-0.87-0.04c-0.05,0.32-0.08,0.51-0.08,0.51s-13.98,0.72-19.23,0.72
					c-3.2,0-21.31-0.97-35.83-1.82c-9.33-0.54-17.18-1.03-18.64-1.17c-3.43-0.33-4.11-1.27-4.2-1.43l1.32,7.07
					c0,0,5.98,2.15,17.62,2.77c11.65,0.62,45.53,2.77,51.51,2.77c5.98,0,7.41-1.38,7.41-1.38l0.54-4.39L477.21,223.01z"
              />
              <path
                fill="#77514D"
                d="M400.68,220.99l-0.81,1.87c-0.02,0.05-0.08,0.06-0.12,0.03l-0.94-0.8c-0.02-0.01-0.03-0.04-0.03-0.06
					c0,0-0.48-2.3-0.42-2.26c0.23,0.17,0.94,0.63,2.28,1.11C400.68,220.9,400.7,220.95,400.68,220.99z"
              />
              <linearGradient
                id="Card22SVGID6"
                gradientUnits="userSpaceOnUse"
                x1="398.3586"
                y1="226.0864"
                x2="476.261"
                y2="226.0864"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
                <stop offset="0.0648" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID6)"
                d="M476.26,223.47c0,0-13.98,0.72-19.23,0.72c-3.2,0-21.31-0.97-35.83-1.82
					c-9.33-0.54-17.18-1.03-18.64-1.17c-3.43-0.33-4.11-1.27-4.2-1.43l1.32,7.07c0,0,5.98,2.15,17.62,2.77
					c11.65,0.62,45.53,2.77,51.51,2.77c5.98,0,6.93-1.15,6.93-1.15L476.26,223.47z"
              />
              <path
                fill="#FFFFFF"
                d="M402.56,221.21c1.46,0.14,9.31,0.63,18.64,1.17c14.52,0.84,32.64,1.82,35.83,1.82
					c5.25,0,19.23-0.72,19.23-0.72s0.03-0.18,0.08-0.51c0.3-1.9,1.33-8.68,1.52-12.17c0.3-5.4,0.81-9.57-4.97-9.93
					c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25c-1.97,0.64-9.86,2.25-12.85,2.33c-2.22,0.06-26.16-0.85-40.58-1.33
					c-5.01-0.16-8.87-0.28-10.27-0.28c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98c0,1.9,0,3.7,0,4.28
					c0,1.3-0.17,3.7-0.17,4.88c0,0.82,0.12,2.29,0.19,3.11c0.02,0.23,0.04,0.41,0.05,0.51c0.01,0.05,0.01,0.08,0.01,0.08
					s0,0.01,0.01,0.02C398.45,219.94,399.13,220.88,402.56,221.21z"
              />
            </g>
            <g>
              <path
                fill="none"
                d="M404.02,201.08l-0.06-7.89c0,0,0,4.83,0,6.54C403.96,200.13,403.98,200.59,404.02,201.08z"
              />
              <path
                fill="#FFFFFF"
                d="M405.13,206.08c0.78,1.45,1.17,3.9,4.94,4.16c3.65,0.26,42.83,1.69,49.4,1.78
					c3.02-2.72,3.93-8.08,4.51-10.91c0.8-3.89,1.96-6.52,1.96-6.52l1.59-0.02l0.04-0.2l-1.21-10.35c0,0-14.42,0.62-19.75,0.62
					s-29.67-0.42-29.67-0.42l-3.61,4.57c0,0-6.26,1.25-6.12,1.25c0.13,0-3.24,3.16-3.24,3.16l0.06,7.89
					C404.16,202.8,404.53,204.96,405.13,206.08z"
              />
              <path
                fill="#FFFFFF"
                d="M410.07,210.24c-3.77-0.26-4.16-2.71-4.94-4.16c-0.6-1.13-0.98-3.29-1.11-5
					c-0.04-0.5-0.06-0.95-0.06-1.34c0-1.72,0-6.54,0-6.54s-1.64,4.96-1.64,5.64c0,0.68,0.06,3,0.24,7.07s0.71,5.34,2.85,5.61
					c2.14,0.27,42.99,2.17,49.67,2.17c1.4,0,2.58-0.39,3.56-1.03c0.29-0.19,0.57-0.41,0.83-0.64
					C452.9,211.93,413.72,210.5,410.07,210.24z"
              />
              <path
                fill="#FFFFFF"
                d="M467.53,194.57l-1.59,0.02c0,0-1.16,2.63-1.96,6.52c-0.58,2.83-1.49,8.19-4.51,10.91
					c-0.26,0.24-0.54,0.45-0.83,0.64c-0.98,0.64-2.15,1.03-3.56,1.03c-6.68,0-47.53-1.9-49.67-2.17c-2.14-0.27-2.67-1.54-2.85-5.61
					s-0.24-6.4-0.24-7.07c0-0.68,1.64-5.64,1.64-5.64l-2.39-0.04c0,0-2.19,4.59-2.65,5.64s-0.66,1.81-0.66,2.65
					c0,0.47,0,3.04,0,5.45c0.14,0.69,0.51,2.57,0.89,3.98c0.47,1.76,1.03,3.61,6.47,3.61s47.86,1.68,50.86,1.6
					c3-0.08,10.88-1.68,12.85-2.33c1.97-0.64,2.22-3.31,2.6-6.25c0.47-3.69,0.95-6.64,0.95-6.64s3.37-4.83,7.73-6.46L467.53,194.57z
					"
              />
            </g>
            <g>
              <path
                fill="#D25959"
                d="M486.57,182.69c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
					c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
					c1.35,0.46,5.29,3.2,7.19,3.2s13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
					c-3.05-0.11-4.55-0.89-6.25-1.42c-1.7-0.53-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
					l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.44c2.75-0.41,19.33-2.69,35.84-1.58C487.57,183.61,487.34,183.14,486.57,182.69z"
              />
              <path
                fill="#FFFFFF"
                d="M460.12,187.31c-0.17,0-0.23,0.24-0.08,0.33c0.65,0.36,1.77,0.92,2.67,1.01
					c0.77,0.08,2.1,0.09,2.57,0.09c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L460.12,187.31z"
              />
            </g>
            <g>
              <path
                fill="#77514D"
                d="M404.24,190.19c0.1,0.01,0.19-0.02,0.27-0.08l2.9-2.23c0.06-0.05,0.13-0.07,0.21-0.08l3.5-0.29
					c0.15-0.01,0.29-0.11,0.34-0.26l0.52-1.41c0.09-0.24-0.07-0.51-0.32-0.54l-9.07-1.24c0.06,0.04,0.09,0.11,0.09,0.18l-0.11,4.32
					c0,0.05-0.02,0.1-0.06,0.14l-1.17,1.18c-0.04,0.04-0.06,0.08-0.06,0.14l0,0.06c0.07-0.05,0.16-0.08,0.25-0.07L404.24,190.19z
					 M404.25,186.64c0-0.09,0.08-0.16,0.16-0.15l2.24,0.23c0.13,0.01,0.18,0.19,0.07,0.27l-1.15,0.87
					c-0.03,0.02-0.06,0.03-0.09,0.03l-1.09-0.05c-0.08,0-0.14-0.07-0.14-0.15V186.64z"
              />
              <g>
                <path
                  fill="none"
                  d="M486.57,182.69c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
						c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
						c1.35,0.46,5.29,3.2,7.19,3.2s13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
						c-3.05-0.11-4.55-0.89-6.25-1.42c-1.7-0.53-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
						l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.43c2.75-0.41,19.33-2.69,35.84-1.58C487.57,183.61,487.34,183.14,486.57,182.69z"
                />
                <path
                  fill="none"
                  d="M460.12,187.31c-0.17,0-0.23,0.24-0.08,0.33c0.65,0.36,1.77,0.92,2.67,1.01c0.77,0.08,2.1,0.09,2.57,0.09
						c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L460.12,187.31z"
                />
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M405.33,177.74c-0.47,0.3-0.63,0.6-0.63,0.89c0,0.4,0,0.76,0,1.07c0.01,0.02,0.02,0.05,0.02,0.08v1
							c0,0.09,0.06,0.17,0.14,0.2l9.71,3.14c0.02,0.01,0.04,0.01,0.06,0.01l3.18,0.1c0.06,0,0.11,0.03,0.14,0.06
							c0.07,0.07,0.08,0.18,0.02,0.27l-0.27,0.37l30.37-0.11c0.16-0.19,0.44-0.33,0.78-0.44c0.13-0.04,0.27-0.09,0.42-0.12
							c4.49-1.17,14.31-2.46,16.77-2.8c0.35-0.05,0.67-0.22,0.9-0.49c0.78-0.9,1.96-1.94,2.65-2.53c0.06-0.05,0.11-0.1,0.16-0.14
							c0.24-0.2,0.53-0.32,0.84-0.34c0.04,0,0.07,0,0.11,0c0,0-0.03,0-0.09-0.01c-1.38-0.19-17.81-2.41-39.44-2.41
							C413.24,175.53,407.1,176.61,405.33,177.74z"
                  />
                </g>
                <path
                  fill="#77514D"
                  d="M490.22,181.79c-3.57-2.34-9.84-3.78-19.54-3.83c-0.34,0-0.68,0.12-0.94,0.34
						c-0.66,0.56-1.98,1.7-2.82,2.67c-0.23,0.27-0.55,0.45-0.9,0.49c-2.46,0.34-12.28,1.63-16.77,2.8
						c-0.15,0.04-0.29,0.08-0.42,0.12c-0.34,0.11-0.62,0.25-0.78,0.44c-0.19,0.22-0.22,0.51,0.01,0.9l2.81,4.49
						c0.27,0.45,0.76,0.72,1.27,0.71c0.34-0.01,0.75,0,1.18,0.02c0.23,0.01,0.46,0.03,0.69,0.05c0.29,0.01,0.5,0.04,0.64,0.08
						c0.38,0.06,0.74,0.15,1.01,0.28c1.3,0.59,4.68,3.17,7.53,3.17c2.86,0,17.79,0,19.74,0s4.29-1.85,6.04-4.69
						C490.74,187,493.86,184.16,490.22,181.79z M487.47,184.2c-16.51-1.11-33.09,1.18-35.84,1.58c-0.2,0.03-0.32,0.24-0.24,0.43
						l0.47,1.12c0.05,0.12,0.17,0.2,0.3,0.19c0.59-0.03,2.25-0.1,3.5-0.15c1.55-0.05,2.17,0.11,3.87,0.63
						c1.7,0.53,3.2,1.31,6.25,1.42c2.79,0.1,16.54-0.33,18.88-0.41c-1.53,2.03-3.66,4.03-5.24,4.03c-1.25,0-13.07,0-14.97,0
						s-5.84-2.74-7.19-3.2c-1.11-0.38-4.58-0.51-5.78-0.55c-0.25-0.01-0.49-0.15-0.61-0.38l-1.7-3.12c-0.23-0.42,0-0.95,0.46-1.07
						c1.84-0.46,6.5-1.5,14.26-2.45c10.33-1.27,20.81-0.66,22.66,0.41C487.34,183.14,487.57,183.61,487.47,184.2z M465.45,188.55
						c0,0.1-0.08,0.17-0.17,0.17c-0.47,0-1.81-0.01-2.57-0.09c-0.9-0.09-2.02-0.65-2.67-1.01c-0.15-0.09-0.1-0.32,0.08-0.33
						l5.16-0.12c0.1,0,0.17,0.08,0.17,0.17"
                />
                <path
                  fill="#DFD7C6"
                  d="M460.12,187.32c-0.17,0-0.23,0.24-0.08,0.32c0.65,0.36,1.77,0.91,2.67,1c0.77,0.08,2.11,0.09,2.58,0.09
						c0.09,0,0.17-0.08,0.17-0.17v-1.19c0-0.1-0.08-0.18-0.17-0.17L460.12,187.32z"
                />
                <path
                  fill="#77514D"
                  d="M417.94,184.3c-0.03-0.04-0.08-0.06-0.14-0.06l-3.18-0.1c-0.02,0-0.04,0-0.06-0.01l-9.71-3.14
						c-0.09-0.03-0.14-0.11-0.14-0.2v-1c0-0.03-0.01-0.06-0.02-0.08c-0.06-0.15-0.27-0.18-0.37-0.03l-0.93,1.52
						c-0.02,0.03-0.04,0.05-0.06,0.06l-0.94,0.64c0,0,0,0,0,0c0.01,0,0.03,0,0.04,0.01l10.73,2.55c0.23,0.06,0.37,0.31,0.28,0.54
						l-1.23,3.33c-0.06,0.16-0.21,0.26-0.37,0.26h-3.61c-0.1,0-0.19,0.04-0.26,0.1l-3.17,2.84c-0.07,0.07-0.17,0.1-0.26,0.1h-2.99
						c-0.15,0-0.28-0.08-0.35-0.21l-0.03,0.42c0,0.03,0,0.05,0.01,0.08l0.35,1.11c0.03,0.09,0.11,0.15,0.2,0.15h4.27
						c0.04,0,0.07-0.01,0.1-0.03l4.45-2.54c0.03-0.02,0.06-0.02,0.09-0.03l2.67-0.2l0.33-0.02c0.06,0,0.11-0.03,0.15-0.08l3.91-5.32
						l0.27-0.37C418.02,184.48,418,184.37,417.94,184.3z"
                />
              </g>
              <path
                fill="#D25959"
                d="M401.54,191.62h2.99c0.1,0,0.19-0.04,0.26-0.1l3.17-2.84c0.07-0.07,0.17-0.1,0.26-0.1h3.61
					c0.17,0,0.31-0.1,0.37-0.26l1.23-3.33c0.08-0.23-0.05-0.48-0.28-0.54l-10.73-2.55c-0.01,0-0.03,0-0.04-0.01
					c-0.04,0.03-0.07,0.07-0.08,0.13l-0.35,1.57c-0.02,0.1,0.03,0.2,0.13,0.24l0.45,0.18c0.01,0.01,0.03,0.01,0.04,0.02l9.07,1.24
					c0.25,0.03,0.41,0.3,0.32,0.54l-0.52,1.41c-0.05,0.15-0.19,0.25-0.34,0.26l-3.5,0.29c-0.08,0.01-0.15,0.03-0.21,0.08l-2.9,2.23
					c-0.08,0.06-0.17,0.09-0.27,0.08l-2.71-0.19c-0.09-0.01-0.18,0.02-0.25,0.07l-0.08,1.34
					C401.26,191.53,401.39,191.62,401.54,191.62z"
              />
              <path
                fill="#D1CAB7"
                d="M404.39,187.83l1.09,0.05c0.03,0,0.07-0.01,0.09-0.03l1.15-0.87c0.11-0.08,0.06-0.26-0.07-0.27
					l-2.24-0.23c-0.09-0.01-0.16,0.06-0.16,0.15v1.05C404.25,187.76,404.32,187.83,404.39,187.83z"
              />
            </g>
            <g>
              <ellipse
                transform="matrix(0.9992 -0.0398 0.0398 0.9992 -6.8455 17.2743)"
                fill="#1EBEE4"
                cx="429.93"
                cy="180.37"
                rx="2.29"
                ry="3.12"
              />

              <ellipse
                transform="matrix(0.9992 -0.0401 0.0401 0.9992 -6.8967 17.398)"
                fill="#141616"
                cx="429.74"
                cy="180.42"
                rx="1.87"
                ry="2.68"
              />

              <ellipse
                transform="matrix(0.9992 -0.0401 0.0401 0.9992 -6.8967 17.398)"
                fill="#FFFFFF"
                cx="429.74"
                cy="180.42"
                rx="1.16"
                ry="1.66"
              />
              <path fill="#1EBEE4" d="M429.67,178.75v1.64h-1.09C428.57,180.39,428.53,178.89,429.67,178.75z" />
              <path fill="#1EBEE4" d="M429.67,180.39v1.69c0,0,1.2,0.12,1.23-1.69L429.67,180.39z" />
            </g>
            <g>
              <path
                fill="#DFD7C6"
                d="M407,203.05l0.71-0.02c0,0-0.47,1.98-0.49,2.08c-0.02,0.1-0.07,0.18-0.29,0.16
					c-0.22-0.02-0.52-0.07-0.52-0.07s0.39-1.83,0.44-1.99C406.9,203.04,407,203.05,407,203.05z"
              />
              <path
                fill="#1EBEE4"
                d="M407.16,203.65c0,0,0.26,0.05,0.31-0.08s0.08-0.3,0.08-0.3l-0.3,0.01L407.16,203.65z"
              />
              <path
                fill="#1EBEE4"
                d="M407.41,203.77c0,0-0.11,0-0.19,0c-0.09,0-0.12,0.07-0.14,0.16s-0.26,1.19-0.26,1.19
					s0.15,0.01,0.21,0.01c0.06,0,0.1-0.07,0.14-0.23C407.21,204.74,407.41,203.77,407.41,203.77z"
              />
              <path
                fill="#DFD7C6"
                d="M407.92,203.3l0.25-0.01c0.01,0,0.03,0.01,0.03,0.02l0.22,0.5c0.01,0.02,0.04,0.03,0.05,0.01l0.55-0.53
					c0.01-0.01,0.01-0.01,0.02-0.01l0.4,0c0.03,0,0.05,0.04,0.02,0.06l-0.84,0.86c-0.01,0.01-0.01,0.03-0.01,0.04l0.45,0.91
					c0.01,0.02-0.01,0.05-0.03,0.05l-0.36-0.02c-0.01,0-0.02-0.01-0.03-0.02l-0.31-0.63c-0.01-0.02-0.04-0.03-0.05-0.01
					c-0.11,0.12-0.48,0.49-0.52,0.53c-0.05,0.04-0.06,0.04-0.15,0.03c-0.05,0-0.13-0.01-0.18-0.02c-0.03,0-0.04-0.04-0.02-0.06
					l0.74-0.85c0.01-0.01,0.01-0.02,0.01-0.04l-0.3-0.8C407.88,203.32,407.9,203.3,407.92,203.3z"
              />
              <path
                fill="#DFD7C6"
                d="M409.85,203.61l1.22,0.01c0.02,0,0.03,0.02,0.03,0.03l-0.13,0.45c0,0.01-0.01,0.02-0.03,0.02
					c-0.11,0-0.62-0.01-0.72-0.01c-0.1,0-0.15,0.13-0.17,0.26c0,0.02,0.01,0.03,0.03,0.03l0.75,0.04c0.02,0,0.03,0.02,0.03,0.03
					c-0.02,0.09-0.08,0.36-0.1,0.42c-0.03,0.07-0.05,0.05-0.14,0.04s-0.82-0.03-0.97-0.03c-0.14,0-0.22,0.14-0.26,0.3
					c0,0.02,0.01,0.03,0.02,0.03c0.2,0.01,1.33,0.09,1.48,0.1c0.17,0.01,0.29-0.07,0.35-0.33c0.06-0.26,0.47-1.53,0.51-1.67
					s-0.14-0.1-0.21-0.11c-0.07-0.01-1.3,0.02-1.44,0.02c-0.13,0.01-0.24,0.14-0.28,0.34C409.82,203.6,409.83,203.61,409.85,203.61z
					"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M478.74,162.41c0.22,0.3,0.32,0.49,0.32,0.49s-2.51,4.63-4.64,7.51c-2.12,2.88-13.12,2.88-19.57,3.2
					c-6.44,0.32-22.39,0.32-22.39,0.32s-17.3,0.09-20.38-0.1c-3.08-0.19-1.41-1.51-0.8-2.17c0.61-0.66,2.13-2.32,7.27-8.02
					c0.13-0.14,0.26-0.28,0.39-0.42l-0.51,0.01c0,0-4.82,5.32-9.23,10.12c-2.15,2.34-3.28,3.66-3.86,4.39
					c1.78-1.14,7.91-2.21,25.82-2.21c21.63,0,38.06,2.22,39.44,2.41c0.77-1.17,2.66-4.04,3.86-6.15c1.54-2.71,5.34-9.39,5.34-9.39
					L478.74,162.41z"
              />
            </g>
            <g>
              <path fill="#F1F3F1" d="M498.08,146.59c0.31,0,0.49,0,0.53,0C498.43,146.59,498.26,146.59,498.08,146.59z" />
              <path
                fill="#F1F3F1"
                d="M498.61,146.59C498.61,146.59,498.61,146.59,498.61,146.59C498.61,146.59,498.61,146.59,498.61,146.59z"
              />
              <path
                fill="#191C1C"
                d="M482.12,157.1c-1.02-0.59-5.81-3.27-10.12-4c-4.86-0.82-10.66-0.82-10.93-0.89
					c-0.25-0.06-2.17,0.52-2.46,0.61c-0.02,0.01-0.05,0.01-0.08,0.01c-0.95-0.03-19.07-0.51-28.73,0.68c-0.02,0-0.05,0-0.07,0
					l-1.89-0.33c-0.02,0-0.05,0-0.07,0c-0.44,0.05-4.76,0.59-6.04,1.51c-0.29,0.21-0.55,0.47-0.76,0.76
					c-0.78,1.04-1.09,2.42-1.19,3.01c-0.11,0.63,0.01,1.51,0.05,1.77c0.01,0.05,0,0.1-0.02,0.15l-1.21,2.49
					c-0.08,0.17,0.04,0.36,0.22,0.36c0.07,0,0.13-0.03,0.17-0.08c4.94-5.22,9.05-5.68,11.56-5.68c2.59,0,27.5,0,36.54,0
					c7.18,0,10.49,3.43,11.55,4.84c0.05,0.06,0.12,0.1,0.2,0.1l0.79-0.01c0.09,0,0.17-0.05,0.22-0.14l2.36-4.84
					C482.28,157.31,482.23,157.16,482.12,157.1z"
              />
            </g>
            <g>
              <path
                fill="#464648"
                d="M445.21,170.26c0,0,0.07,0.31,0.87,0.39c0.6,0.06,4.69,0.06,6.64,0.1c0.87-0.15,1.39-0.5,0.98-0.71
					c-0.55-0.28-6.77,0-7.67-0.49c-0.9-0.49-9.68-0.77-11.61-0.7c-1.94,0.07-3.35,0.31-2.95,1.67c0.01,0.05,0.1-0.07,0.12-0.02
					c0.7-0.29,1.83-0.57,4.61-0.57C440.28,169.93,445.21,170.26,445.21,170.26z"
              />
              <path
                fill="#191C1C"
                d="M435.16,172.05c0.12-0.07,0.2-0.2,0.21-0.34c0.02-0.21,0.17-0.52,0.84-0.59
					c2.07-0.21,7.81-0.21,9.68-0.21c0.91,0,4.42-0.21,5.88-0.14c0.39,0.02,0.77-0.01,1.09-0.06c-1.95-0.04-5.89-0.12-6.49-0.18
					c-0.46-0.05-0.66-0.2-0.74-0.33c-0.07-0.11-0.19-0.17-0.32-0.18c-1.1-0.08-5.44-0.4-8.99-0.4c-2.26,0-3.71,0.11-4.54,0.3
					c-0.26,0.06-0.41,0.35-0.3,0.6c0.23,0.57,0.48,1.15,0.6,1.43c0.05,0.11,0.13,0.19,0.24,0.23
					C433.69,172.74,434.72,172.3,435.16,172.05z"
              />
            </g>
            <path
              fill="#D25959"
              d="M434.85,154.14c-0.14,0-0.26-0.11-0.27-0.25c-0.01-0.15,0.1-0.28,0.25-0.29c0.07,0,6.96-0.49,15.3-0.27
				c0.15,0,0.26,0.13,0.26,0.28c0,0.15-0.12,0.27-0.27,0.27c-8.32-0.22-15.18,0.26-15.25,0.27
				C434.87,154.14,434.86,154.14,434.85,154.14z"
            />
            <path
              fill="#FFFFFF"
              d="M481.53,150.21l2.2,0.75c0,0,41.27-8.37,76,4.23c0,0-1.13-3.16-10.03-5.4
				c-7.41-1.87-24.84-6.21-53.23-3.26C491.38,147.05,486.38,148.2,481.53,150.21z"
            />
            <linearGradient
              id="Card22SVGID7"
              gradientUnits="userSpaceOnUse"
              x1="481.5267"
              y1="150.3539"
              x2="559.7283"
              y2="150.3539"
            >
              <stop offset="0.202" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.9641" style={{ stopColor: "#1C1C1A", stopOpacity: 0.15 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID7)"
              d="M481.53,150.21l2.2,0.75c0,0,41.27-8.37,76,4.23c0,0-1.13-3.16-10.03-5.4
				c-7.41-1.87-24.84-6.21-53.23-3.26C491.38,147.05,486.38,148.2,481.53,150.21z"
            />
            <path
              fill="#FFFFFF"
              d="M480.33,194.52c-4.21,1.73-7.43,6.34-7.43,6.34c5.79,0.36,5.27,4.53,4.97,9.93
				c-0.04,0.72-0.12,1.59-0.22,2.53l0.15-1.1c0,0,12.41,1.55,13.18,1.55c0.76,0,3.96,1.08,6.02-2.01c1.08-1.63,3.84-6.45,8.35-10.33
				c-2.65-0.71-8.64-2.33-12.83-3.65c-3.48-1.1-5.55-3.1-6.6-4.43c-1.01,0.76-2.03,1.19-2.97,1.19
				C482.56,194.52,481.61,194.52,480.33,194.52z"
            />
            <path
              fill="#C7C9C6"
              d="M583.62,177.61l-0.18,0.42c0,0-4.81-5.43-12.91-13.4c-8.1-7.97-16.16-10.83-29.06-12.42
				c-12.9-1.6-32.61-0.44-36.36-0.18c-3.75,0.27-9.24,1.42-9.94,3.1c-0.7,1.68-2.53,6.83-3.05,9.84c-0.52,3.01,0.26,4.26,2.27,4.88
				c2.01,0.62,10.37,3.72,14.73,5.05c4.36,1.33,13.34,3.9,17.87,4.34c4.53,0.44,20.75,1.77,20.75,1.77s32,3.64,34.61,3.72
				c2.62,0.09,4.36-1.42,4.36-1.42c0.55-0.14,0.94-0.47,1.24-0.67L583.62,177.61z M582.18,184.02c-2.99-0.2-28.99-3.19-32.71-3.53
				c-3.73-0.34-21.16-2.03-23.75-2.44c-2.59-0.41-12.31-2.57-15.9-3.79c-3.59-1.22-11.58-4.06-13.44-4.74
				c-1.86-0.68-1.66-3.11-1.33-4.6c0.33-1.49,1.6-6.36,1.6-6.36c0.53-1.56,1.4-3.52,5.99-4.4c4.59-0.88,18.16-2.23,38.32-0.74
				c20.16,1.49,27.61,9.88,34.53,16.98c6.92,7.1,10.19,11.92,10.19,11.92S585.18,184.22,582.18,184.02z"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M502.63,154.16c-4.59,0.88-5.46,2.84-5.99,4.4c0,0-1.26,4.87-1.6,6.36c-0.33,1.49-0.53,3.92,1.33,4.6
					c1.86,0.68,9.85,3.52,13.44,4.74c1.49,0.51,4.04,1.18,6.7,1.81c-0.25-4.63-1.03-15.76-2.88-23.06
					C508.38,153.3,504.64,153.78,502.63,154.16z"
              />
              <linearGradient
                id="Card22SVGID8"
                gradientUnits="userSpaceOnUse"
                x1="497.1495"
                y1="171.8819"
                x2="517.6862"
                y2="160.0251"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
                <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
                <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
                <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
                <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
                <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
                <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
                <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
                <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
                <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
                <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
                <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
                <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
                <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
                <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
                <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
                <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
                <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
                <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
                <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
                <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID8)"
                d="M502.63,154.16c-4.59,0.88-5.46,2.84-5.99,4.4c0,0-1.26,4.87-1.6,6.36
					c-0.33,1.49-0.53,3.92,1.33,4.6c1.86,0.68,9.85,3.52,13.44,4.74c1.49,0.51,4.04,1.18,6.7,1.81c-0.25-4.63-1.03-15.76-2.88-23.06
					C508.38,153.3,504.64,153.78,502.63,154.16z"
              />
              <path
                opacity="0.63"
                fill="#A1C8D9"
                d="M575.48,170.4c-6.92-7.1-14.37-15.49-34.53-16.98c-1.07-0.08-2.12-0.15-3.15-0.21
					l11.42,27.25c0.09,0.01,0.17,0.02,0.25,0.02c3.73,0.34,29.72,3.33,32.71,3.53c2.99,0.2,3.49-1.98,3.49-1.98
					S582.4,177.51,575.48,170.4z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M513.64,153.01c0,0,2.3,14.25,2.39,22.9l0.48,0.16c0,0,0.58-8-2.28-23.1L513.64,153.01z"
            />
            <path
              fill="#191C1C"
              d="M537.8,153.21l11.42,27.25l8.19,0.89l-13.36-27.56C544.05,153.79,541.58,153.39,537.8,153.21z"
            />
            <polygon fill="#FFFFFF" points="539.46,153.32 551.8,180.71 557.41,181.35 544.05,153.79 			" />
            <path
              fill="#FFFFFF"
              d="M577.32,172.33l4.87,11.69c0,0,1.78,0.27,2.96-0.87C586.33,182.01,582.45,177.82,577.32,172.33z"
            />
            <path
              fill="#77514D"
              d="M460.16,224.14l3.89,2.25c0.19,0.11,0.4,0.16,0.62,0.15c1.47-0.08,7.44-0.41,9.37-0.41
				c2.22,0,2.22-2.65,2.22-2.65S466.81,224.03,460.16,224.14"
            />
            <linearGradient
              id="Card22SVGID9"
              gradientUnits="userSpaceOnUse"
              x1="460.1609"
              y1="225.0033"
              x2="476.261"
              y2="225.0033"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID9)"
              d="M460.16,224.14l3.89,2.25c0.19,0.11,0.4,0.16,0.62,0.15c1.47-0.08,7.44-0.41,9.37-0.41
				c2.22,0,2.22-2.65,2.22-2.65S466.81,224.03,460.16,224.14"
            />
            <g>
              <path
                fill="#5E5D5B"
                d="M614.87,198.69c-12.13-2.39-16.21,25.54-16.21,25.54c-17.73,0.5-62.53,2.25-62.97,2.64
					c0,0.01,0,0.01,0,0.01c0,0,0,0,0-0.01c0.12-0.53,3.12-23.95-14.6-26.42c-18.06-2.52-25.33,25.37-25.33,25.37l-19.49-2.35
					c0,0-0.53,3.78-0.87,6.19c-0.15,1.04,0.31,2.61,1.37,3.05c4.82,2.02,18.81,7.24,18.81,7.24s2.12-12.45,4.91-20.16
					c2.79-7.71,8.13-15.3,15.34-15.99c6.58-0.63,19.1,0.31,18.14,19.62c-0.17,3.45-0.52,11.08-0.78,12.59
					c-0.26,1.51-1.39,2.66-1.39,2.66s52.75-5.85,65.82-6.12c0,0,0.44-0.09,0.96-1.06c0.52-0.98,1.92-10.64,3.49-16.49
					s4.97-15.34,13.08-13.65c8.11,1.68,7.67,13.3,6.8,20.57l-0.61,2.57l-0.09,2.57c0,0,2.38-1.16,2.54-4.97l-1.24,0.13
					C622.54,222.22,626.99,201.08,614.87,198.69z"
              />
              <path
                fill="#FFFFFF"
                d="M614.75,195.93c-11.66-1.92-14.83,17.04-17.35,20.92c-0.87,1.34-0.96,1.76-3.37,1.65
					c-2.25-0.11-5.95-0.07-5.95-0.07c-1.49,0-47.04-1.47-48.82-1.71c-1.78-0.24-1.52-0.46-1.82-2.48
					c-0.3-2.01-0.69-15.65-15.46-17.97c-14.78-2.32-22.93,12.39-24.98,15.49c-2.06,3.1-5.26,2.01-6.02,2.01
					c-0.76,0-13.18-1.55-13.18-1.55l-1.54,11.25l19.49,2.35c0,0,7.27-27.89,25.33-25.37c17.72,2.47,14.72,25.89,14.6,26.42
					c0.44-0.39,45.24-2.14,62.97-2.64c0,0,4.08-27.93,16.21-25.54c12.13,2.39,7.67,23.53,7.67,23.53l1.24-0.13
					C623.78,222.1,627.11,197.96,614.75,195.93z"
              />
              <path
                fill="#FFFFFF"
                d="M535.68,226.88C535.68,226.88,535.69,226.88,535.68,226.88C535.69,226.87,535.68,226.88,535.68,226.88z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M549.52,232.33l2.2,0.04c0.19,0,0.3,0.21,0.21,0.38l-0.13,0.23c-0.1,0.17,0.03,0.39,0.23,0.38l3.8-0.23
				c0.04,0,0.07-0.01,0.11-0.03l1.82-1.05c0.04-0.02,0.08-0.03,0.13-0.03l1.82,0.04c0.19,0,0.31,0.23,0.2,0.39l-0.01,0.01
				c-0.11,0.17,0.01,0.39,0.21,0.39l3.93-0.09c0.04,0,0.09-0.01,0.13-0.04l1.96-1.25c0.04-0.03,0.09-0.04,0.14-0.04l2,0.04
				c0.21,0,0.32,0.26,0.18,0.42l0,0c-0.14,0.17-0.02,0.43,0.2,0.42l3.02-0.13c0.03,0,0.06-0.01,0.09-0.02l2.05-0.96
				c0.03-0.01,0.06-0.02,0.09-0.02l2.09-0.12c0.21-0.01,0.34,0.24,0.2,0.41l-0.02,0.02c-0.14,0.17-0.01,0.42,0.2,0.41l2.82-0.13
				c0.04,0,0.08-0.01,0.11-0.03l1.97-1.15c0.04-0.02,0.08-0.03,0.12-0.03l2.09-0.04c0.19,0,0.32,0.21,0.22,0.38l-0.09,0.16
				c-0.1,0.17,0.02,0.39,0.22,0.38l2.7-0.13c0.04,0,0.08-0.01,0.12-0.04l2.02-1.25c0.04-0.02,0.08-0.04,0.13-0.04h1.69
				c0.2,0,0.32,0.23,0.2,0.4c-0.23,0.33-0.49,0.73-0.4,0.66c0.13-0.09,1.44-0.1,1.82-0.11c0.07,0,0.13-0.03,0.18-0.08l1.73-1.8
				c0.16-0.16,0.04-0.44-0.19-0.43c-5.28,0.26-42.25,2.05-43.69,2.24c-1.56,0.21-3.95-1.01-6.14-0.69
				c-2.18,0.32-1.25,1.48-0.83,1.85c0.42,0.37,1.92,1.43,2.34,1.69c0.42,0.26,1.61,0.16,2.5-0.53c0.75-0.58,1.32-0.79,1.47-0.83
				C549.46,232.33,549.49,232.33,549.52,232.33z"
            />
            <path
              fill="#1EBEE4"
              d="M549.61,231.99c-1.33,0.58-1.36,1.29-2.8,0.6s-0.95-0.42-1.44-0.69c-0.49-0.27-0.91-1.04,0.87-1.08
				c1.78-0.04,38.71-1.68,46.9-2.23l-1.37,0.98c0,0-11.93,0.77-24.92,1.37C553.85,231.56,550.41,231.65,549.61,231.99z"
            />
            <linearGradient
              id="Card22SVGID10"
              gradientUnits="userSpaceOnUse"
              x1="544.9445"
              y1="230.7408"
              x2="593.1395"
              y2="230.7408"
            >
              <stop offset="0.3466" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.5959" style={{ stopColor: "#040404", stopOpacity: 0.2289 }} />
              <stop offset="0.836" style={{ stopColor: "#0F0F0F", stopOpacity: 0.4494 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.6 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID10)"
              d="M549.61,231.99c-1.33,0.58-1.36,1.29-2.8,0.6s-0.95-0.42-1.44-0.69
				c-0.49-0.27-0.91-1.04,0.87-1.08c1.78-0.04,38.71-1.68,46.9-2.23l-1.37,0.98c0,0-11.93,0.77-24.92,1.37
				C553.85,231.56,550.41,231.65,549.61,231.99z"
            />
            <g>
              <path
                fill="#1EBEE4"
                d="M450.25,233.81c0.26,0.04,0,1.14,0,1.14l-6.82-1.23l-1.12-7.28
					C442.31,226.44,442.92,232.67,450.25,233.81z"
              />
              <path
                fill="#1EBEE4"
                d="M442.31,226.44l10.87,0.61c0,0,7.77,4.65,8.71,5.44s0.86,2.72,0.86,2.72l-12.51-0.26v-1.14
					c0,0-1.81-0.35-3.8-1.14C444.47,231.88,442.49,228.54,442.31,226.44z"
              />
              <linearGradient
                id="Card22SVGID11"
                gradientUnits="userSpaceOnUse"
                x1="452.5392"
                y1="226.437"
                x2="452.5392"
                y2="235.2117"
              >
                <stop offset="0.1917" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID11)"
                d="M442.31,226.44l10.87,0.61c0,0,7.77,4.65,8.71,5.44s0.86,2.72,0.86,2.72l-12.51-0.26v-1.14
					c0,0-1.9-0.25-3.79-1.25C444.23,231.37,442.49,228.54,442.31,226.44z"
              />
              <path
                fill="#1EBEE4"
                d="M468.2,235.74l-5.44-0.53c0,0,0.3-1.9-1.13-2.77c-1.42-0.87-8.45-5.39-8.45-5.39l0.65-0.98
					c0,0,7,4.8,8.31,5.73s1.54,2.78,1.54,2.78l4.5,0.81L468.2,235.74z"
              />
              <path
                fill="#1EBEE4"
                d="M442.23,225.84l0.09,0.6l1.12,7.28l-0.87-0.01C442.57,233.71,441.37,226.01,442.23,225.84z"
              />
              <path fill="#1EBEE4" d="M442.23,225.84c0,0,9.05,0.06,11.61,0.23l-0.65,0.98l-10.87-0.61L442.23,225.84z" />
              <path
                fill="#1EBEE4"
                d="M453.84,226.07l14.35,9.32l-4.78-0.69c0,0-0.17-1.62-0.97-2.37c-0.8-0.75-8.88-5.73-8.88-5.73
					L453.84,226.07z"
              />
              <linearGradient
                id="Card22SVGID12"
                gradientUnits="userSpaceOnUse"
                x1="453.5541"
                y1="230.7306"
                x2="468.1843"
                y2="230.7306"
              >
                <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0.1 }} />
                <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.1 }} />
                <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID12)"
                d="M453.84,226.07l14.35,9.32l-4.78-0.69c0,0-0.17-1.62-0.97-2.37c-0.8-0.75-8.88-5.73-8.88-5.73
					L453.84,226.07z"
              />
              <path
                fill="#1EBEE4"
                d="M400.3,230.58l-0.47-2c0,0,1.66-3.9,2.08-4.71c0.13-0.25,0.12-0.48,0.12-0.48s-0.69,0.54-1.27,1.62
					c-0.84,1.57-1.27,2.62-1.31,3.24c-0.05,0.62,0.38,2.24,0.38,2.24L400.3,230.58z"
              />
              <path
                fill="#1EBEE4"
                d="M402.04,223.38c0,0,6.47,0.24,7.5,0.52c1.03,0.29,1.17,0.67,1.5,2.48c0.33,1.81,0.75,3.29,0.75,3.29
					l1.97,2.19l-3.14-0.29l-0.14-0.52l-2.91-0.14l-0.09,0.62l-7.17-0.95l-0.47-2L402.04,223.38z"
              />
              <path
                fill="#1EBEE4"
                d="M402.04,223.38c0,0-0.98,2.1-1.27,3.19c-0.28,1.1,0.42,1,1.27,1.14c0.84,0.14,2.16,2.34,5.53,3.19
					l2.91,0.14c0,0-0.89-4.91-0.98-5.67c-0.09-0.76-0.28-1.43,0.05-1.48C409.54,223.9,404.15,223.42,402.04,223.38z"
              />
            </g>
            <path
              fill="#1EBEE4"
              d="M410.62,231.58h0.61c0,0-1.08-6.2-1.27-6.87c-0.19-0.67-0.7-0.57-0.56,0.14
				C409.54,225.57,410.62,231.58,410.62,231.58z"
            />
            <path
              fill="#5E5D5B"
              d="M516.97,214.51c0.3,0.43,1.31,1.91,1.31,1.91l2.41-0.04l1.1-1.73
				C521.79,214.64,520.27,213.69,516.97,214.51z"
            />
            <g>
              <path
                fill="#191C1C"
                d="M612.85,203.72c3.24,0,9.11,4.79,8.47,20.61c-0.59,14.64-6.97,18.84-9.8,18.84
					c-2.39,0-14.84,2.77-17.82-10.58c0,0,4.97,1.23,5.96-5.16C600.4,222.67,601.75,203.72,612.85,203.72z"
              />
              <path
                fill="#5E5D5B"
                d="M602.99,225.26c-0.22-3.6-0.66-6.8-1.23-9.4c0,0,3.23-13.2,11.32-11.44c9.32,2.03,8.38,21.65,7.84,24.69
					c-0.69,3.85-2.51,13.22-9.16,13.98C605.12,243.84,603.24,229.36,602.99,225.26z"
              />

              <ellipse
                transform="matrix(0.0174 -0.9998 0.9998 0.0174 378.4019 831.5441)"
                fill="#FFFFFF"
                cx="612.26"
                cy="223.25"
                rx="16.24"
                ry="7.68"
              />
              <polygon
                fill="#191C1C"
                points="614.19,208.14 614.92,214.25 618.62,215.24 619.42,221.81 616.58,225.69 617.56,233.12 
					615.93,233.8 614.83,237.21 612.46,232.98 609.18,237.38 608.29,233.76 606.61,233.24 608.07,225.69 605.06,221.93 
					606.35,218.76 606.21,214.89 609.73,214.54 610.95,208.02 612.4,208.88 				"
              />
              <linearGradient
                id="Card22SVGID13"
                gradientUnits="userSpaceOnUse"
                x1="612.0056"
                y1="238.0798"
                x2="612.4487"
                y2="207.2335"
              >
                <stop offset="0.6685" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.6968" style={{ stopColor: "#FCFCFC", stopOpacity: 0.8265 }} />
                <stop offset="0.7171" style={{ stopColor: "#F3F3F3", stopOpacity: 0.7017 }} />
                <stop offset="0.735" style={{ stopColor: "#E3E3E3", stopOpacity: 0.5921 }} />
                <stop offset="0.7515" style={{ stopColor: "#CDCDCD", stopOpacity: 0.4913 }} />
                <stop offset="0.7669" style={{ stopColor: "#B0B0B0", stopOpacity: 0.3965 }} />
                <stop offset="0.7817" style={{ stopColor: "#8D8D8D", stopOpacity: 0.3061 }} />
                <stop offset="0.7959" style={{ stopColor: "#636363", stopOpacity: 0.2192 }} />
                <stop offset="0.8093" style={{ stopColor: "#343433", stopOpacity: 0.1372 }} />
                <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="none"
                stroke="url(#Card22SVGID13)"
                strokeMiterlimit="10"
                d="M610.95,208.02l-1.42,5.33l-3.33,1.54
					c0,0-1.02,4.32-1.15,7.04l2.13,4.73l-0.58,6.57c0,0,0.93,2.78,2.57,4.14l2.97-2.66l2.66,2.66c0,0,1.91-2.19,2.75-4.26
					l-0.18-6.39l2.04-4.91c0,0,0-3.61-0.8-6.57l-2.97-1.3l-1.46-5.8C614.19,208.14,613.13,207.31,610.95,208.02z"
              />
              <polygon fill="#5E5D5B" points="614.19,208.14 613.61,216.61 614.59,217.97 618.62,215.24 615.65,213.94 				" />
              <polygon fill="#5E5D5B" points="610.95,208.02 610.47,216.31 609.53,217.85 606.21,214.89 609.53,213.35 				" />
              <path
                fill="#5E5D5B"
                d="M606.21,214.89l0.8,2.6l-0.75,3.79l-1.2,0.65C605.06,221.93,605.28,218.03,606.21,214.89z"
              />
              <path
                fill="#5E5D5B"
                d="M618.62,215.24l-0.98,2.31l0.44,3.55l1.33,0.71C619.42,221.81,619.42,218.32,618.62,215.24z"
              />
              <polygon fill="#5E5D5B" points="619.42,221.81 615.43,224.3 614.99,226.67 617.56,233.12 617.38,226.73 				" />
              <path
                fill="#5E5D5B"
                d="M617.56,233.12l-1.33-1.12l-1.37,2.13l-0.04,3.26C614.81,237.38,616.98,235.01,617.56,233.12z"
              />
              <polygon fill="#5E5D5B" points="614.81,237.38 612.28,230.45 611.31,230.16 609.18,237.38 612.15,234.72 				" />
              <path
                fill="#5E5D5B"
                d="M609.18,237.38l0.35-3.02c0,0-1.64-2.37-1.68-2.19c-0.04,0.18-1.24,1.07-1.24,1.07
					S607.81,236.31,609.18,237.38z"
              />
              <polygon fill="#5E5D5B" points="606.61,233.24 608.74,226.31 608.47,224.06 605.06,221.93 607.18,226.67 				" />
              <g>
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="615.65"
                  y1="213.94"
                  x2="616.85"
                  y2="210.75"
                />
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="617.38"
                  y1="226.73"
                  x2="619.2"
                  y2="227.97"
                />
                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="612.15"
                  y1="234.72"
                  x2="612.15"
                  y2="238.8"
                />

                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="607.18"
                  y1="226.67"
                  x2="605.32"
                  y2="227.85"
                />

                <line
                  fill="none"
                  stroke="#5E5D5B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  x1="609.53"
                  y1="213.35"
                  x2="608.16"
                  y2="210.69"
                />
              </g>
              <ellipse fill="#FFFFFF" cx="610.97" cy="223.08" rx="0.72" ry="1.63" />
              <ellipse fill="#1EBEE4" cx="610.97" cy="223.08" rx="0.47" ry="1.06" />
            </g>
            <linearGradient
              id="Card22SVGID14"
              gradientUnits="userSpaceOnUse"
              x1="398.0987"
              y1="212.5318"
              x2="478.0263"
              y2="212.5318"
            >
              <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.0648" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.8896" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.9319" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9911" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="1" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID14)"
              d="M472.89,200.87c0,0-0.48,2.95-0.95,6.64c-0.38,2.95-0.63,5.61-2.6,6.25
				c-1.97,0.64-9.86,2.25-12.85,2.33c-2.22,0.06-26.16-0.85-40.58-1.33c-5.01-0.16-8.87-0.28-10.27-0.28
				c-5.44,0-5.99-1.84-6.47-3.61c-0.38-1.42-0.76-3.3-0.89-3.98c0,1.9,0,3.7,0,4.28c0,1.3-0.17,3.7-0.17,4.88
				c0,1.18,0.26,3.73,0.26,3.73s0.46,1.06,4.2,1.43c1.46,0.14,9.31,0.63,18.64,1.17c14.52,0.84,32.64,1.82,35.83,1.82
				c5.25,0,19.23-0.72,19.23-0.72s1.38-8.6,1.6-12.67C478.16,205.39,478.68,201.23,472.89,200.87z"
            />
            <linearGradient
              id="Card22SVGID15"
              gradientUnits="userSpaceOnUse"
              x1="435.7658"
              y1="184.0243"
              x2="435.7658"
              y2="212.0217"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.548" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.788" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.9984" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID15)"
              d="M405.13,206.08c0.78,1.45,1.17,3.9,4.94,4.16c3.65,0.26,42.83,1.69,49.4,1.78
				c3.02-2.72,3.93-8.08,4.51-10.91c0.8-3.89,1.96-6.58,1.96-6.58l1.57,0l0.06-0.15l-1.21-10.35c0,0-14.42,0.62-19.75,0.62
				s-29.67-0.42-29.67-0.42l-3.61,4.57c0,0-6.26,1.25-6.12,1.25c0.13,0-3.24,3.16-3.24,3.16l0.06,7.89
				C404.16,202.8,404.53,204.96,405.13,206.08z"
            />
            <linearGradient
              id="Card22SVGID16"
              gradientUnits="userSpaceOnUse"
              x1="398.2642"
              y1="204.6278"
              x2="480.6228"
              y2="204.6278"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID16)"
              d="M467.51,194.53l-1.59,0c0,0-1.14,2.69-1.94,6.58c-0.58,2.83-1.49,8.19-4.51,10.91
				c-0.26,0.24-0.54,0.45-0.83,0.64c-0.98,0.64-2.15,1.03-3.56,1.03c-6.68,0-47.53-1.9-49.67-2.17c-2.14-0.27-2.67-1.54-2.85-5.61
				s-0.24-6.4-0.24-7.07c0-0.68,1.64-5.64,1.64-5.64l-2.39-0.04c0,0-2.19,4.59-2.65,5.64s-0.66,1.81-0.66,2.65c0,0.47,0,3.04,0,5.45
				c0.14,0.69,0.51,2.57,0.89,3.98c0.47,1.76,1.03,3.61,6.47,3.61s47.86,1.68,50.86,1.6c3-0.08,10.88-1.68,12.85-2.33
				c1.97-0.64,2.22-3.31,2.6-6.25c0.47-3.69,0.95-6.64,0.95-6.64s3.37-4.83,7.73-6.46L467.51,194.53z"
            />
            <linearGradient
              id="Card22SVGID17"
              gradientUnits="userSpaceOnUse"
              x1="401.2715"
              y1="187.1147"
              x2="411.9979"
              y2="187.1147"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID17)"
              d="M401.52,189.99l2.71,0.19c0.1,0.01,0.19-0.02,0.27-0.08l2.9-2.23c0.06-0.05,0.13-0.07,0.21-0.08
				l3.5-0.29c0.15-0.01,0.29-0.11,0.34-0.26l0.52-1.41c0.09-0.24-0.07-0.51-0.32-0.54l-9.07-1.24c0.06,0.04,0.09,0.11,0.09,0.18
				l-0.11,4.32c0,0.05-0.02,0.1-0.06,0.14l-1.17,1.18c-0.04,0.04-0.06,0.08-0.06,0.14l0,0.06
				C401.34,190.02,401.43,189.99,401.52,189.99z"
            />
            <linearGradient
              id="Card22SVGID18"
              gradientUnits="userSpaceOnUse"
              x1="401.1658"
              y1="186.3663"
              x2="417.9977"
              y2="186.3663"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID18)"
              d="M417.8,184.23l-3.18-0.1c-0.02,0-0.04,0-0.06-0.01l-9.71-3.14c-0.09-0.03-0.14-0.11-0.14-0.2v-1
				c0-0.21-0.27-0.29-0.38-0.11l-0.93,1.52c-0.02,0.03-0.04,0.05-0.06,0.06l-0.94,0.64c0,0,0,0,0,0c0.01,0,0.03,0,0.04,0.01
				l10.73,2.55c0.23,0.06,0.37,0.31,0.28,0.54l-1.23,3.33c-0.06,0.16-0.21,0.26-0.37,0.26h-3.61c-0.1,0-0.19,0.04-0.26,0.1
				l-3.17,2.84c-0.07,0.07-0.17,0.1-0.26,0.1h-2.99c-0.15,0-0.28-0.08-0.35-0.21l-0.03,0.42c0,0.03,0,0.05,0.01,0.08l0.35,1.11
				c0.03,0.09,0.11,0.15,0.2,0.15h4.27c0.04,0,0.07-0.01,0.1-0.03l4.45-2.54c0.03-0.02,0.06-0.02,0.09-0.03l3-0.23
				c0.06,0,0.11-0.03,0.15-0.08l4.18-5.69C418.06,184.43,417.96,184.24,417.8,184.23z"
            />
            <linearGradient
              id="Card22SVGID19"
              gradientUnits="userSpaceOnUse"
              x1="401.1914"
              y1="186.7585"
              x2="413.467"
              y2="186.7585"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID19)"
              d="M401.54,191.62h2.99c0.1,0,0.19-0.04,0.26-0.1l3.17-2.84c0.07-0.07,0.17-0.1,0.26-0.1h3.61
				c0.17,0,0.31-0.1,0.37-0.26l1.23-3.33c0.08-0.23-0.05-0.48-0.28-0.54l-10.73-2.55c-0.01,0-0.03,0-0.04-0.01
				c-0.04,0.03-0.07,0.07-0.08,0.13l-0.35,1.57c-0.02,0.1,0.03,0.2,0.13,0.24l0.45,0.18c0.01,0.01,0.03,0.01,0.04,0.02l9.07,1.24
				c0.25,0.03,0.41,0.3,0.32,0.54l-0.52,1.41c-0.05,0.15-0.19,0.25-0.34,0.26l-3.5,0.29c-0.08,0.01-0.15,0.03-0.21,0.08l-2.9,2.23
				c-0.08,0.06-0.17,0.09-0.27,0.08l-2.71-0.19c-0.09-0.01-0.18,0.02-0.25,0.07l-0.08,1.34
				C401.26,191.53,401.39,191.62,401.54,191.62z"
            />
            <linearGradient
              id="Card22SVGID20"
              gradientUnits="userSpaceOnUse"
              x1="402.3172"
              y1="203.446"
              x2="459.4651"
              y2="203.446"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID20)"
              d="M410.07,210.24c-3.77-0.26-4.16-2.71-4.94-4.16c-0.6-1.13-0.98-3.29-1.11-5
				c-0.04-0.5-0.06-0.95-0.06-1.34c0-1.72,0-6.54,0-6.54s-1.64,4.96-1.64,5.64c0,0.68,0.06,3,0.24,7.07s0.71,5.34,2.85,5.61
				c2.14,0.27,42.99,2.17,49.67,2.17c1.4,0,2.58-0.39,3.56-1.03c0.29-0.19,0.57-0.41,0.83-0.64
				C452.9,211.93,413.72,210.5,410.07,210.24z"
            />
            <linearGradient
              id="Card22SVGID21"
              gradientUnits="userSpaceOnUse"
              x1="447.8997"
              y1="186.2428"
              x2="491.9062"
              y2="186.2428"
            >
              <stop offset="0.1719" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.2965" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.4706" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.4968" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID21)"
              d="M490.22,181.78c-3.57-2.34-9.84-3.78-19.54-3.83c-0.34,0-0.68,0.12-0.94,0.34
				c-0.66,0.56-1.98,1.7-2.82,2.67c-0.23,0.27-0.55,0.45-0.9,0.49c-2.46,0.34-12.28,1.63-16.77,2.8c-0.15,0.04-0.29,0.08-0.42,0.12
				c-0.73,0.25-1.2,0.62-0.77,1.34l2.81,4.49c0.27,0.45,0.76,0.72,1.27,0.71c0.34-0.01,0.75,0,1.18,0.02
				c0.23,0.01,0.46,0.03,0.69,0.05c0.29,0.01,0.5,0.04,0.64,0.08c0.38,0.06,0.74,0.15,1.01,0.28c1.3,0.59,4.68,3.17,7.53,3.17
				c2.86,0,17.79,0,19.74,0s4.29-1.85,6.04-4.69C490.74,187,493.86,184.16,490.22,181.78z M487.47,184.19
				c-16.51-1.11-33.09,1.18-35.84,1.58c-0.2,0.03-0.32,0.24-0.24,0.44l0.47,1.12c0.05,0.12,0.17,0.2,0.3,0.19
				c0.59-0.03,2.25-0.1,3.5-0.15c1.55-0.05,2.17,0.11,3.87,0.63c1.7,0.53,3.2,1.31,6.25,1.42c2.79,0.1,16.54-0.33,18.88-0.41
				c-1.53,2.03-3.66,4.03-5.24,4.03c-1.25,0-13.07,0-14.97,0s-5.84-2.74-7.19-3.2c-1.11-0.38-4.58-0.51-5.78-0.55
				c-0.25-0.01-0.49-0.15-0.61-0.38l-1.7-3.12c-0.23-0.42,0-0.95,0.46-1.07c1.84-0.46,6.5-1.5,14.26-2.45
				c10.33-1.27,20.81-0.66,22.66,0.41C487.34,183.13,487.57,183.61,487.47,184.19z"
            />
            <linearGradient
              id="Card22SVGID22"
              gradientUnits="userSpaceOnUse"
              x1="449.1026"
              y1="187.3094"
              x2="487.4944"
              y2="187.3094"
            >
              <stop offset="0.1719" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.2963" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.4703" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.4964" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.5444" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID22)"
              d="M486.57,182.69c-1.85-1.07-12.33-1.67-22.66-0.41c-7.76,0.95-12.42,1.99-14.26,2.45
				c-0.46,0.11-0.69,0.64-0.46,1.07l1.7,3.12c0.12,0.23,0.35,0.37,0.61,0.38c1.2,0.04,4.67,0.17,5.78,0.55
				c1.35,0.46,5.29,3.2,7.19,3.2s13.72,0,14.97,0c1.58,0,3.71-2,5.24-4.03c-2.34,0.07-16.09,0.51-18.88,0.41
				c-3.05-0.11-4.55-0.89-6.25-1.42c-1.7-0.53-2.32-0.68-3.87-0.63c-1.26,0.04-2.92,0.12-3.5,0.15c-0.13,0.01-0.25-0.07-0.3-0.19
				l-0.47-1.12c-0.08-0.19,0.04-0.41,0.24-0.44c2.75-0.41,19.33-2.69,35.84-1.58C487.57,183.61,487.34,183.14,486.57,182.69z"
            />
            <linearGradient
              id="Card22SVGID23"
              gradientUnits="userSpaceOnUse"
              x1="433.5123"
              y1="184.1591"
              x2="433.5123"
              y2="199.2068"
            >
              <stop offset="0.6474" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7973" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.9049" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.9993" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID23)"
              d="M454.53,191.01c-0.21-0.03-0.42-0.06-0.64-0.08c-0.1,0-0.21-0.01-0.32-0.01
				c-0.12,0-0.24-0.01-0.36-0.04c-0.43-0.02-0.85-0.02-1.18-0.02c-0.52,0.01-1-0.26-1.27-0.71l-2.81-4.49
				c-0.43-0.72,0.04-1.09,0.77-1.34c-0.04-0.11-0.06-0.16-0.06-0.16h-30.59l-0.12,0.14c0.07,0.07,0.08,0.18,0.02,0.27l-4.18,5.69
				c-0.04,0.05-0.09,0.08-0.15,0.08l-0.33,0.02l-0.83,0.11c0,0-1.97,8.7,6.26,8.7c5.46,0,22.49,0.03,25.42,0.03
				c2.93,0,6.79-3,8.25-5.17C453.6,192.25,455.43,191.29,454.53,191.01z"
            />
            <linearGradient
              id="Card22SVGID24"
              gradientUnits="userSpaceOnUse"
              x1="404.6929"
              y1="180.2368"
              x2="470.683"
              y2="180.2368"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4317" style={{ stopColor: "#040404", stopOpacity: 0.1526 }} />
              <stop offset="0.6631" style={{ stopColor: "#0F0F0F", stopOpacity: 0.2996 }} />
              <stop offset="0.8212" style={{ stopColor: "#1C1C1B", stopOpacity: 0.4 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID24)"
              d="M448.83,184.39c0.13-0.04,0.27-0.09,0.42-0.12c4.49-1.17,14.31-2.46,16.77-2.8
				c0.35-0.05,0.67-0.22,0.9-0.49c0.84-0.97,2.15-2.11,2.82-2.67c0.27-0.22,0.6-0.35,0.94-0.34c0,0-16.93-2.42-39.54-2.42
				s-26.45,1.71-26.45,3.1c0,0.4,0,0.76,0,1.07c0.01,0.02,0.02,0.05,0.02,0.08v1c0,0.09,0.06,0.17,0.14,0.2l9.71,3.14
				c0.02,0.01,0.04,0.01,0.06,0.01l3.18,0.1c0.06,0,0.11,0.03,0.14,0.06c0.07,0.07,0.08,0.18,0.02,0.27l-0.27,0.37l30.37-0.11
				C448.22,184.65,448.5,184.51,448.83,184.39z"
            />
            <linearGradient
              id="Card22SVGID25"
              gradientUnits="userSpaceOnUse"
              x1="420.9746"
              y1="152.0071"
              x2="498.0822"
              y2="152.0071"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6704" style={{ stopColor: "#020202", stopOpacity: 0.1742 }} />
              <stop offset="0.8349" style={{ stopColor: "#090908", stopOpacity: 0.237 }} />
              <stop offset="0.9517" style={{ stopColor: "#141414", stopOpacity: 0.2816 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID25)"
              d="M481.97,150.11c0,0,6.81-3.4,16.11-3.52c-3.68,0.03-25.72,0.28-42.26,1.76
				c-18.1,1.62-30.52,3.95-32.6,4.87c-1.64,0.72-2.12,1.8-2.25,2.22c0.22-0.29,0.47-0.55,0.76-0.76c1.28-0.91,5.6-1.45,6.04-1.51
				c0.02,0,0.05,0,0.07,0l1.89,0.33c0.02,0,0.05,0,0.07,0c9.67-1.2,27.79-0.71,28.73-0.68c0.03,0,0.05,0,0.08-0.01
				c0.29-0.09,2.21-0.67,2.46-0.61c0.27,0.07,6.07,0.07,10.93,0.89c4.31,0.73,9.1,3.41,10.12,4c0.11,0.07,0.16,0.21,0.1,0.33
				C482.22,157.42,487.12,150.03,481.97,150.11z"
            />
            <g>
              <linearGradient
                id="Card22SVGID26"
                gradientUnits="userSpaceOnUse"
                x1="405.3375"
                y1="170.1682"
                x2="479.7916"
                y2="170.1682"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0843" style={{ stopColor: "#161615", stopOpacity: 0.0617 }} />
                <stop offset="0.202" stopColor="#030303" stopOpacity="8.066498e-03" />
                <stop offset="0.2198" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.256" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.8928" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID26)"
                d="M478.74,162.41c0.22,0.3,0.32,0.49,0.32,0.49s-2.51,4.63-4.64,7.51
					c-2.12,2.88-13.12,2.88-19.57,3.2c-6.44,0.32-22.39,0.32-22.39,0.32s-17.3,0.09-20.38-0.1c-3.08-0.19-1.41-1.51-0.8-2.17
					c0.61-0.66,2.13-2.32,7.27-8.02c0.13-0.14,0.26-0.28,0.39-0.42l-0.51,0.01c0,0-4.82,5.32-9.23,10.12
					c-2.15,2.34-3.28,3.66-3.86,4.39c1.78-1.14,7.91-2.21,25.82-2.21c21.63,0,38.06,2.22,39.44,2.41c0.77-1.17,2.66-4.04,3.86-6.15
					c1.54-2.71,5.34-9.39,5.34-9.39L478.74,162.41z"
              />
            </g>
            <linearGradient
              id="Card22SVGID27"
              gradientUnits="userSpaceOnUse"
              x1="476.261"
              y1="211.3361"
              x2="624.1874"
              y2="211.3361"
            >
              <stop offset="0" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.2017" style={{ stopColor: "#161819", stopOpacity: 0.0631 }} />
              <stop offset="0.4078" style={{ stopColor: "#0B0C0D", stopOpacity: 0.0253 }} />
              <stop offset="0.5458" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6405" style={{ stopColor: "#0A0A09", stopOpacity: 0.0208 }} />
              <stop offset="0.8316" style={{ stopColor: "#171716", stopOpacity: 0.0629 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID27)"
              d="M476.26,223.47l19.49,2.35c0,0,7.27-27.89,25.33-25.37c18.06,2.52,14.6,26.8,14.6,26.42
				c0-0.38,45.16-2.14,62.98-2.64c0,0,4.08-27.93,16.21-25.54c12.13,2.39,7.67,23.53,7.67,23.53l1.24-0.13
				c0,0,3.34-24.14-9.03-26.17c-11.66-1.92-14.83,17.04-17.35,20.92c-0.87,1.34-0.96,1.76-3.37,1.65c-2.25-0.11-5.95-0.07-5.95-0.07
				c-1.49,0-47.04-1.47-48.82-1.71s-1.52-0.46-1.82-2.48c-0.3-2.01-0.69-15.65-15.46-17.97c-14.78-2.32-22.93,12.39-24.98,15.49
				c-2.06,3.1-5.26,2.01-6.02,2.01c-0.76,0-13.18-1.55-13.18-1.55L476.26,223.47z"
            />
            <linearGradient
              id="Card22SVGID28"
              gradientUnits="userSpaceOnUse"
              x1="568.4097"
              y1="197.7105"
              x2="568.4097"
              y2="214.8973"
            >
              <stop offset="1.784581e-04" style={{ stopColor: "#F6F6F6", stopOpacity: 0.9026 }} />
              <stop offset="0.1228" style={{ stopColor: "#F2F2F2", stopOpacity: 0.8041 }} />
              <stop offset="0.2576" style={{ stopColor: "#E4E4E4", stopOpacity: 0.696 }} />
              <stop offset="0.398" style={{ stopColor: "#CFCFCE", stopOpacity: 0.5832 }} />
              <stop offset="0.5424" style={{ stopColor: "#B0B0B0", stopOpacity: 0.4673 }} />
              <stop offset="0.6899" style={{ stopColor: "#888888", stopOpacity: 0.3489 }} />
              <stop offset="0.8399" style={{ stopColor: "#585858", stopOpacity: 0.2285 }} />
              <stop offset="0.9894" style={{ stopColor: "#20201F", stopOpacity: 0.1085 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID28)"
              d="M538.84,214.9c0,0,5.69-3.33,24.75-3.58s35.39,1.51,35.39,1.51l5.36-11.84
				c0,0-50.65-2.73-59.56-2.99c-2.76-0.08-13.81-0.94-12.13,0.77C540.32,206.57,538.84,214.9,538.84,214.9z"
            />
            <linearGradient
              id="Card22SVGID29"
              gradientUnits="userSpaceOnUse"
              x1="500.1652"
              y1="181.3377"
              x2="495.1406"
              y2="190.0405"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID29)"
              d="M491.63,183.25c0,0,6.57,0.32,12.94,0.63l0.58,1.69c-1.64,0.14-3.41,0.2-4.68,0.36
				c-4.94,0.62-9.48,1.46-9.83,1.52C490.51,187.46,492.6,184.96,491.63,183.25"
            />
            <g>
              <linearGradient
                id="Card22SVGID30"
                gradientUnits="userSpaceOnUse"
                x1="526.0386"
                y1="187.277"
                x2="532.9053"
                y2="187.277"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID30)"
                d="M530.6,186.22l2.3-0.14c0,0-0.72,1.89-2.51,2.24c-1.74,0.34-2.87,0.11-4.36-0.79
					c0,0,0.07-0.03,0.3-0.26s0,0,0,0s0.45,0.48,1.76,0.46C529.48,187.71,530.6,187.37,530.6,186.22L530.6,186.22"
              />
              <linearGradient
                id="Card22SVGID31"
                gradientUnits="userSpaceOnUse"
                x1="523.6328"
                y1="183.8098"
                x2="531.8583"
                y2="183.8098"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID31)"
                d="M531.81,184.59c0,0-7.46-1.69-7.7,0l-0.46-0.05c0,0-0.28-1.39,1.63-1.51
					C527.19,182.92,532.43,184.39,531.81,184.59z"
              />
              <linearGradient
                id="Card22SVGID32"
                gradientUnits="userSpaceOnUse"
                x1="524.059"
                y1="186.2382"
                x2="532.9053"
                y2="186.2382"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0795" style={{ stopColor: "#222222", stopOpacity: 0.1786 }} />
                <stop offset="0.1909" style={{ stopColor: "#353534", stopOpacity: 0.2887 }} />
                <stop offset="0.3212" style={{ stopColor: "#525252", stopOpacity: 0.4174 }} />
                <stop offset="0.4658" style={{ stopColor: "#7C7C7B", stopOpacity: 0.5603 }} />
                <stop offset="0.6222" style={{ stopColor: "#B1B1B1", stopOpacity: 0.7148 }} />
                <stop offset="0.7862" style={{ stopColor: "#F1F1F1", stopOpacity: 0.8769 }} />
                <stop offset="0.7903" style={{ stopColor: "#F3F3F3", stopOpacity: 0.881 }} />
                <stop offset="0.8928" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID32)"
                d="M524.86,184.95c0,0,1.11,1.07,3.4,1.13c2.29,0.06,4.64,0,4.64,0s-4.45,0.19-5.51,0.57
					c-1.05,0.38-1.36,0.88-1.36,0.88s-1.61-1.01-1.98-2.45L524.86,184.95z"
              />
            </g>
            <linearGradient
              id="Card22SVGID33"
              gradientUnits="userSpaceOnUse"
              x1="578.5393"
              y1="206.8532"
              x2="558.4246"
              y2="172.0135"
            >
              <stop offset="0.0127" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.2536" style={{ stopColor: "#818283", stopOpacity: 0.4996 }} />
              <stop offset="0.5553" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.6818" style={{ stopColor: "#B7B7B6", stopOpacity: 0.7439 }} />
              <stop offset="0.8367" style={{ stopColor: "#646463", stopOpacity: 0.4305 }} />
              <stop offset="0.9465" style={{ stopColor: "#30302F", stopOpacity: 0.2083 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID33)"
              d="M534.21,185.99l68.54,6.88c0,0-5.34,1.81-15.84,1.09c-10.5-0.72-34.36-2.9-39.35-3.62
				C542.58,189.61,534.21,185.99,534.21,185.99z"
            />
            <g>
              <linearGradient
                id="Card22SVGID34"
                gradientUnits="userSpaceOnUse"
                x1="564.0842"
                y1="191.0737"
                x2="569.6116"
                y2="191.0737"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID34)"
                d="M567.76,189.85l1.85-0.16c0,0-0.58,2.18-2.02,2.59c-1.4,0.4-2.31,0.13-3.51-0.92
					c0,0,0.06-0.04,0.24-0.3c0.18-0.26,0,0,0,0s0.36,0.55,1.42,0.53C566.85,191.57,567.76,191.18,567.76,189.85L567.76,189.85"
              />
              <linearGradient
                id="Card22SVGID35"
                gradientUnits="userSpaceOnUse"
                x1="562.1477"
                y1="187.0702"
                x2="568.7689"
                y2="187.0702"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.289" style={{ stopColor: "#BABABA", stopOpacity: 0.7399 }} />
                <stop offset="0.7814" style={{ stopColor: "#494948", stopOpacity: 0.2968 }} />
                <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID35)"
                d="M568.73,187.97c0,0-6.01-1.95-6.2,0l-0.37-0.05c0,0-0.23-1.6,1.31-1.74
					S569.23,187.74,568.73,187.97z"
              />
              <linearGradient
                id="Card22SVGID36"
                gradientUnits="userSpaceOnUse"
                x1="562.4908"
                y1="189.8742"
                x2="569.6116"
                y2="189.8742"
              >
                <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
                <stop offset="0.0795" style={{ stopColor: "#222222", stopOpacity: 0.1786 }} />
                <stop offset="0.1909" style={{ stopColor: "#353534", stopOpacity: 0.2887 }} />
                <stop offset="0.3212" style={{ stopColor: "#525252", stopOpacity: 0.4174 }} />
                <stop offset="0.4658" style={{ stopColor: "#7C7C7B", stopOpacity: 0.5603 }} />
                <stop offset="0.6222" style={{ stopColor: "#B1B1B1", stopOpacity: 0.7148 }} />
                <stop offset="0.7862" style={{ stopColor: "#F1F1F1", stopOpacity: 0.8769 }} />
                <stop offset="0.7903" style={{ stopColor: "#F3F3F3", stopOpacity: 0.881 }} />
                <stop offset="0.8928" style={{ stopColor: "#FFFFFF" }} />
              </linearGradient>
              <path
                fill="url(#Card22SVGID36)"
                d="M563.14,188.38c0,0,0.9,1.23,2.74,1.31s3.73,0,3.73,0s-3.59,0.22-4.43,0.65
					c-0.85,0.44-1.1,1.02-1.1,1.02s-1.29-1.16-1.59-2.83L563.14,188.38z"
              />
            </g>
            <linearGradient
              id="Card22SVGID37"
              gradientUnits="userSpaceOnUse"
              x1="539.4634"
              y1="167.3348"
              x2="557.4117"
              y2="167.3348"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <polygon fill="url(#Card22SVGID37)" points="539.46,153.32 551.8,180.71 557.41,181.35 544.05,153.79 			" />
            <linearGradient
              id="Card22SVGID38"
              gradientUnits="userSpaceOnUse"
              x1="480.9846"
              y1="215.2301"
              x2="493.0616"
              y2="194.3121"
            >
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2364" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2393" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.504" style={{ stopColor: "#030303", stopOpacity: 0.1275 }} />
              <stop offset="0.6941" style={{ stopColor: "#0C0C0C", stopOpacity: 0.2191 }} />
              <stop offset="0.8608" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2994 }} />
              <stop offset="0.862" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.9081" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9726" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID38)"
              d="M480.33,194.52c-4.21,1.73-7.43,6.34-7.43,6.34c5.79,0.36,5.27,4.53,4.97,9.93
				c-0.04,0.72-0.12,1.59-0.22,2.53l0.15-1.1c0,0,12.41,1.55,13.18,1.55c0.76,0,3.96,1.08,6.02-2.01c1.08-1.63,3.84-6.45,8.35-10.33
				c-2.65-0.71-8.64-2.33-12.83-3.65c-3.48-1.1-5.55-3.1-6.6-4.43c-1.01,0.76-2.03,1.19-2.97,1.19
				C482.56,194.52,481.61,194.52,480.33,194.52z"
            />
            <path
              fill="#5E5D5B"
              d="M597.79,205.76c0,0-0.15,2.73-0.38,5.26s-0.98,6.13-1.08,6.63c-0.09,0.51,0.18,0.69,0.82-0.09
				c0.63-0.78,2.58-5.43,3.4-7.41c0.82-1.98,1.36-3.59,1.63-4.33s-0.18-0.82-1.04-0.83c-0.68-0.01-1.37-0.03-2.5-0.01
				C598.2,204.99,597.79,205.04,597.79,205.76z"
            />
            <path
              fill="#4A4A4B"
              d="M597.79,205.46c0,0-0.98,2.32-1.22,5.64s-0.18,6.7-0.18,6.7s1.1-4.77,1.31-7.51
				S597.79,205.46,597.79,205.46z"
            />
            <path
              fill="#FFFFFF"
              d="M474.94,147.47c0,0-2.24-3.51-8.17-3.57l-1.09,2.4c0,0-0.81,0.7-0.81,1.58
				C464.87,147.88,470.74,149.05,474.94,147.47z"
            />
            <linearGradient
              id="Card22SVGID39"
              gradientUnits="userSpaceOnUse"
              x1="469.9063"
              y1="143.9055"
              x2="469.9063"
              y2="148.3001"
            >
              <stop offset="0" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              <stop offset="0.1379" style={{ stopColor: "#151514", stopOpacity: 0.0837 }} />
              <stop offset="0.532" style={{ stopColor: "#050505", stopOpacity: 0.0372 }} />
              <stop offset="0.847" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.9784" style={{ stopColor: "#000000", stopOpacity: 0.1717 }} />
              <stop offset="1" style={{ stopColor: "#000000", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID39)"
              d="M474.94,147.47c0,0-2.24-3.51-8.17-3.57l-1.09,2.4c0,0-0.81,0.7-0.81,1.58
				C464.87,147.88,470.74,149.05,474.94,147.47z"
            />
            <linearGradient
              id="Card22SVGID40"
              gradientUnits="userSpaceOnUse"
              x1="577.3151"
              y1="178.184"
              x2="585.3619"
              y2="178.184"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID40)"
              d="M577.32,172.33l4.87,11.69c0,0,1.78,0.27,2.96-0.87C586.33,182.01,582.45,177.82,577.32,172.33z"
            />
            <linearGradient
              id="Card22SVGID41"
              gradientUnits="userSpaceOnUse"
              x1="583.9653"
              y1="176.7076"
              x2="591.57"
              y2="176.7076"
            >
              <stop offset="0" style={{ stopColor: "#1A1C1D", stopOpacity: 0.1 }} />
              <stop offset="0.0143" style={{ stopColor: "#393A3C", stopOpacity: 0.1258 }} />
              <stop offset="0.0364" style={{ stopColor: "#626364", stopOpacity: 0.1657 }} />
              <stop offset="0.0612" style={{ stopColor: "#888989", stopOpacity: 0.2106 }} />
              <stop offset="0.0883" style={{ stopColor: "#A8A9A9", stopOpacity: 0.2595 }} />
              <stop offset="0.1182" style={{ stopColor: "#C4C4C4", stopOpacity: 0.3135 }} />
              <stop offset="0.152" style={{ stopColor: "#DADADA", stopOpacity: 0.3745 }} />
              <stop offset="0.1914" style={{ stopColor: "#EAEBEB", stopOpacity: 0.4456 }} />
              <stop offset="0.2402" style={{ stopColor: "#F6F6F6", stopOpacity: 0.5337 }} />
              <stop offset="0.3085" style={{ stopColor: "#FDFDFD", stopOpacity: 0.6572 }} />
              <stop offset="0.4984" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.6895" style={{ stopColor: "#FDFDFD", stopOpacity: 0.657 }} />
              <stop offset="0.7584" style={{ stopColor: "#F6F6F6", stopOpacity: 0.5334 }} />
              <stop offset="0.8075" style={{ stopColor: "#EBEBEA", stopOpacity: 0.4453 }} />
              <stop offset="0.8472" style={{ stopColor: "#DADADA", stopOpacity: 0.3741 }} />
              <stop offset="0.8812" style={{ stopColor: "#C4C4C4", stopOpacity: 0.3131 }} />
              <stop offset="0.9114" style={{ stopColor: "#A9A9A8", stopOpacity: 0.259 }} />
              <stop offset="0.9386" style={{ stopColor: "#888888", stopOpacity: 0.2101 }} />
              <stop offset="0.9637" style={{ stopColor: "#636362", stopOpacity: 0.1651 }} />
              <stop offset="0.986" style={{ stopColor: "#3A3A39", stopOpacity: 0.1252 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID41)"
              d="M583.98,173.82c-0.17-0.53,1.27-0.6,1.81-0.51c0.29,0.05,0.65,0.14,1.06,0.31
				c1.13,0.45,2.29,1.12,2.75,1.4c0.14,0.08,0.25,0.21,0.31,0.37l1.64,4.32c0.2,0.5-1.95,0.43-2.48,0.41l-1.98-0.79
				c-0.31-0.01-0.57-0.22-0.67-0.52L583.98,173.82z"
            />
            <path
              fill="#191C1C"
              d="M582.05,180.27h1.19c0,0,0-4.55,0-5.22s0.4-2.54,3.09-1.14c2.7,1.41,2.96,4.02,3.03,5.62h2.17
				c0,0,0.13,2.75-0.99,2.75c-1.12,0-1.98-0.13-2.83,0.54s-1.65,0.33-2.57,0.33s-1.12,0.87-2.96,0.87L582.05,180.27z"
            />
            <path
              opacity="0.63"
              fill="#A1C8D9"
              d="M584.7,174.91c0-0.32,0.3-0.55,0.61-0.45c1.05,0.34,3.29,1.51,3.29,5.58
				c0,1.44-2.45,2.2-3.46,2.06c-0.23-0.03-0.4-0.23-0.4-0.47L584.7,174.91z"
            />
            <linearGradient
              id="Card22SVGID42"
              gradientUnits="userSpaceOnUse"
              x1="584.7004"
              y1="178.2738"
              x2="588.5913"
              y2="178.2738"
            >
              <stop offset="0.1909" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.9838" style={{ stopColor: "#FFFFFF", stopOpacity: 0.02 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID42)"
              d="M584.7,174.91c0-0.32,0.3-0.55,0.61-0.45c1.05,0.34,3.29,1.51,3.29,5.58
				c0,1.44-2.45,2.2-3.46,2.06c-0.23-0.03-0.4-0.23-0.4-0.47L584.7,174.91z"
            />
            <path
              fill="#FFFFFF"
              d="M583.26,176.21c0,0,0,3.29,0,3.71c0,0.43-0.39,0.5-1.1,0.5v-0.19c0,0,0.95,0.08,0.95-0.7
				c0-1.12,0.17-3.31,0-3.14"
            />
            <linearGradient
              id="Card22SVGID43"
              gradientUnits="userSpaceOnUse"
              x1="442.3135"
              y1="230.6927"
              x2="450.2517"
              y2="230.6927"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0763 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.1498 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID43)"
              d="M450.25,233.81v1.14l-6.82-1.23l-1.12-7.28C442.31,226.44,442.92,232.67,450.25,233.81z"
            />
            <linearGradient
              id="Card22SVGID44"
              gradientUnits="userSpaceOnUse"
              x1="405.5921"
              y1="223.3762"
              x2="405.5921"
              y2="231.0523"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID44)"
              d="M402.04,223.38c0,0-0.98,2.1-1.27,3.19c-0.28,1.1,0.42,1,1.27,1.14
				c0.84,0.14,2.16,2.34,5.53,3.19l2.91,0.14c0,0-0.89-4.91-0.98-5.67c-0.09-0.76-0.28-1.43,0.05-1.48
				C409.54,223.9,404.15,223.42,402.04,223.38z"
            />
            <linearGradient
              id="Card22SVGID45"
              gradientUnits="userSpaceOnUse"
              x1="447.8999"
              y1="235.1641"
              x2="448.0676"
              y2="223.4937"
            >
              <stop offset="0.6685" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7309" style={{ stopColor: "#030303", stopOpacity: 0.085 }} />
              <stop offset="0.7757" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1461 }} />
              <stop offset="0.815" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1996 }} />
              <stop offset="0.8153" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID45)"
              d="M442.23,225.84c0,0,9.05,0.06,11.61,0.23l-0.51,0.97l-11.01-0.6L442.23,225.84z"
            />
            <linearGradient
              id="Card22SVGID46"
              gradientUnits="userSpaceOnUse"
              x1="601.7573"
              y1="223.6105"
              x2="621.3501"
              y2="223.6105"
            >
              <stop offset="0.4595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.6657" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
              <stop offset="0.8643" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID46)"
              d="M602.97,225.19c-0.22-3.62-0.65-6.72-1.22-9.33c0,0,3.24-13.38,11.38-11.61
				c9.39,2.04,8.44,21.75,7.89,24.81c-0.69,3.87-2.53,13.29-9.22,14.05C605.12,243.86,603.23,229.31,602.97,225.19z"
            />
            <linearGradient
              id="Card22SVGID47"
              gradientUnits="userSpaceOnUse"
              x1="492.6843"
              y1="201.8854"
              x2="470.9084"
              y2="204.9464"
            >
              <stop offset="0.5619" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.7054" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1561 }} />
              <stop offset="0.8377" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
              <stop offset="0.8853" style={{ stopColor: "#161615", stopOpacity: 0.185 }} />
              <stop offset="0.9519" style={{ stopColor: "#030303", stopOpacity: 0.0242 }} />
              <stop offset="0.9619" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID47)"
              d="M477.06,202.79c0,0,4.31-6.46,5.89-8.28c1.58-1.82-2.46,13.12-2.66,17.97l-2.49-0.28
				C477.81,212.21,479.29,207.64,477.06,202.79z"
            />
            <path
              fill="#FFFFFF"
              d="M516.53,175.61c0.05-0.02,30.79,0.2,30.79,0.2l1.9,4.65c0,0-23.46-2.42-26.78-3.1
				c-3.32-0.68-5.92-1.29-5.92-1.29L516.53,175.61z"
            />
            <linearGradient
              id="Card22SVGID48"
              gradientUnits="userSpaceOnUse"
              x1="527.9657"
              y1="169.01"
              x2="538.239"
              y2="186.8039"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="5.137858e-03" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0133" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0225" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.0325" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.0435" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.056" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.0705" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.0885" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.1138" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.1838" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.8846" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9343 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.94 }} />
              <stop offset="1" style={{ stopColor: "#282827", stopOpacity: 0.9457 }} />
              <stop offset="1" style={{ stopColor: "#464645", stopOpacity: 0.9559 }} />
              <stop offset="1" style={{ stopColor: "#787877", stopOpacity: 0.9694 }} />
              <stop offset="1" style={{ stopColor: "#BCBCBC", stopOpacity: 0.9855 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID48)"
              d="M516.53,175.61c0.05-0.02,30.83,0.09,30.83,0.09l1.87,4.76c0,0-22.11-1.7-27.08-3.03
				c-3.28-0.88-5.63-1.36-5.63-1.36L516.53,175.61z"
            />
            <g>
              <g>
                <path
                  fill="#5E5D5B"
                  d="M423.56,191.91c0.14-0.68,0.28-1.36,0.42-2.03c0.02-0.1,0.12-0.21-0.01-0.27
						c-0.14-0.06-0.2,0.07-0.25,0.18c-0.08,0.19-0.15,0.38-0.23,0.57c-0.04,0.08-0.1,0.16-0.2,0.15c-0.11-0.02-0.1-0.13-0.11-0.21
						c0-0.15,0.01-0.3-0.01-0.44c-0.01-0.1,0.05-0.27-0.13-0.27c-0.14,0-0.17,0.13-0.19,0.26c-0.11,0.61-0.17,1.22-0.34,1.82
						c-0.06,0.21-0.12,0.34-0.35,0.28c-0.23-0.06-0.12-0.22-0.09-0.36c0.19-1,0.39-1.99,0.58-2.99c0.08-0.3,0.08-0.61,0.17-0.91l0,0
						c0.28-0.15,0.51-0.01,0.52,0.17c0.05,0.37,0.07,0.75,0.1,1.13c0.02,0.22,0.08,0.32,0.28,0.12l0,0.01
						c0.19-0.34,0.23-0.54,0.36-0.8c0,0,0,0,0,0c0.13-0.24,0.16-0.39,0.28-0.59c0.07-0.1,0.19-0.15,0.32-0.11
						c0.14,0.05,0.16,0.12,0.13,0.24c-0.25,1.28-0.5,2.56-0.75,3.85C424.01,192.05,423.77,191.94,423.56,191.91z"
                />
                <path
                  fill="#5E5D5B"
                  d="M439.75,192.2c-0.35,0.02-0.28-0.19-0.21-0.48c0.26-1.21,0.57-2.41,0.71-3.64
						c0.03-0.28,0.16-0.42,0.46-0.4c0.41,0.02,0.73,0,1.14,0.02c0.13,0.01,0.48-0.05,0.45,0.24c-0.02,0.21-0.05,0.41-0.38,0.39
						c-0.28-0.01-0.57,0.03-0.84-0.02c-0.33-0.06-0.34,0.27-0.4,0.71c-0.01,0.11,0.06,0.21,0.17,0.22c0.28,0.02,0.74-0.14,0.68,0.29
						c-0.07,0.4-0.27,0.31-0.5,0.32c-0.64,0-0.53,0.02-0.63,0.65c-0.16,0.97-0.32,0.99,0.69,0.99c0.28,0,0.64-0.11,0.55,0.41
						c-0.04,0.25-0.18,0.31-0.4,0.31c-0.24,0-0.47,0-0.71,0C440.53,192.18,439.97,192.18,439.75,192.2z"
                />
                <path
                  fill="#5E5D5B"
                  d="M435.44,192.11c-0.07,0-0.15,0.02-0.22,0c-0.35-0.01-0.6,0.06-0.69-0.25c-0.09-0.3-0.01-0.55,0.2-0.82
						c0.1-0.13,0.27-0.33,0.4-0.43c0.43-0.52,0.78-0.82,1.15-1.26c0.14-0.12,0.22-0.36,0.24-0.56c0.03-0.21,0.03-0.41-0.21-0.46
						c-0.23-0.05-0.5-0.03-0.59,0.22c-0.03,0.08-0.04,0.17-0.08,0.25c-0.05,0.11-0.16,0.12-0.27,0.11
						c-0.13-0.01-0.16-0.11-0.16-0.22c0.01-0.38,0.07-0.76,0.43-0.96c0.38-0.21,0.91-0.22,1.19,0c0.28,0.17,0.27,0.51,0.27,0.76
						c-0.02,0.85-0.46,1.42-1.04,2c-0.22,0.22-0.45,0.42-0.67,0.64c-0.06,0.06-0.15,0.19-0.12,0.29c0.04,0.11,0.15,0.07,0.25,0.07
						c0.21-0.01,0.41-0.03,0.62-0.03c0.15,0,0.33-0.01,0.32,0.2c-0.01,0.18-0.03,0.41-0.29,0.43
						C435.95,192.13,435.7,192.11,435.44,192.11C435.44,192.11,435.44,192.11,435.44,192.11z"
                />
                <path
                  fill="#5E5D5B"
                  d="M437.83,192.11c-0.07,0-0.15,0.02-0.22,0c-0.35-0.01-0.6,0.06-0.69-0.25c-0.09-0.3-0.01-0.55,0.2-0.82
						c0.1-0.13,0.27-0.33,0.4-0.43c0.43-0.52,0.78-0.82,1.15-1.26c0.14-0.12,0.22-0.36,0.24-0.56c0.03-0.21,0.03-0.41-0.21-0.46
						c-0.23-0.05-0.5-0.03-0.59,0.22c-0.03,0.08-0.04,0.17-0.08,0.25c-0.05,0.11-0.16,0.12-0.27,0.11
						c-0.13-0.01-0.16-0.11-0.16-0.22c0.01-0.38,0.07-0.76,0.43-0.96c0.38-0.21,0.91-0.22,1.19,0c0.28,0.17,0.27,0.51,0.27,0.76
						c-0.02,0.85-0.46,1.42-1.04,2c-0.22,0.22-0.45,0.42-0.67,0.64c-0.06,0.06-0.15,0.19-0.12,0.29c0.04,0.11,0.15,0.07,0.25,0.07
						c0.21-0.01,0.41-0.03,0.62-0.03c0.15,0,0.33-0.01,0.32,0.2c-0.01,0.18-0.03,0.41-0.29,0.43
						C438.33,192.13,438.08,192.11,437.83,192.11C437.83,192.11,437.83,192.11,437.83,192.11z"
                />
                <path
                  fill="#5E5D5B"
                  d="M430.88,187.57c0.2,0.06,0.51-0.05,0.59,0.31c0.07,0.36-0.03,0.64-0.2,0.96
						c-0.05,0.09-0.19,0.07-0.29,0.05c-0.13-0.04-0.13-0.14-0.11-0.25c0.06-0.23,0.13-0.46-0.22-0.49c-0.3-0.03-0.52,0.05-0.59,0.39
						c-0.15,0.74-0.31,1.47-0.45,2.21c-0.05,0.24,0.03,0.46,0.29,0.53c0.3,0.08,0.57-0.03,0.71-0.32c0.11-0.22,0.17-0.58,0.49-0.39
						c0.25,0.15,0.07,0.46-0.04,0.67c-0.2,0.41-0.47,0.75-0.98,0.79c-0.7,0.06-1.08-0.32-0.96-1.01c0.11-0.7,0.26-1.39,0.4-2.08
						c0.02-0.1,0.06-0.2,0.07-0.3C429.71,187.79,429.97,187.57,430.88,187.57z"
                />
                <path
                  fill="#5E5D5B"
                  d="M427.81,190.59c-0.03-0.21-0.18-0.01-0.23,0.09c-0.18,0.37-0.37,0.73-0.56,1.09
						c-0.08,0.15-0.18,0.3-0.37,0.2c-0.22-0.11-0.07-0.27,0-0.41c0.26-0.56,0.56-1.03,0.81-1.65c0.14-0.26,0.15-0.4,0.12-0.61
						c-0.05-0.45-0.12-0.91-0.18-1.36c-0.03-0.19-0.01-0.37,0.22-0.4c0.24-0.03,0.3,0.14,0.32,0.33c0.02,0.25,0.03,0.5,0.04,0.75
						c0,0.09,0,0.19,0.12,0.21c0.1,0.02,0.17-0.05,0.21-0.14c0.14-0.29,0.27-0.59,0.41-0.88c0.08-0.16,0.2-0.35,0.4-0.24
						c0.22,0.12,0.08,0.29-0.01,0.45c-0.22,0.4-0.4,0.82-0.63,1.21c-0.2,0.33-0.19,0.67-0.15,1.03c0.06,0.47,0.13,0.94,0.18,1.41
						c0.02,0.16,0.03,0.34-0.21,0.35c-0.22,0.01-0.31-0.09-0.33-0.32C427.94,191.33,427.9,191.06,427.81,190.59z"
                />
                <path
                  fill="#5E5D5B"
                  d="M432.59,191.76c0.06-0.75,0.39-1.38,0.79-1.99c0.22-0.33,0.42-0.68,0.61-1.02
						c0.09-0.15,0.32-0.54-0.01-0.56c-0.42-0.01-0.53-0.03-0.64,0.3c-0.02,0.07-0.02,0.15-0.04,0.22c-0.06,0.17-0.2,0.15-0.33,0.13
						c-0.16-0.02-0.18-0.15-0.15-0.27c0.07-0.23,0.12-0.45,0.14-0.69c0.02-0.22,0.18-0.29,0.38-0.29c0.43,0,0.86,0,1.28,0.01
						c0.25,0,0.32,0.12,0.28,0.37c-0.08,0.66-0.52,1.14-0.82,1.69c-0.38,0.69-0.94,1.29-0.97,2.14c-0.01,0.19-0.17,0.27-0.35,0.24
						C432.58,192.03,432.57,191.9,432.59,191.76z"
                />
                <g>
                  <g>
                    <path
                      fill="#1EBEE4"
                      d="M422.68,186.76c-0.59,0-1.07,0-1.4,0c-0.32,0-0.6,0.23-0.67,0.54l-1.13,5.05
								c-0.1,0.43,0.23,0.84,0.67,0.84l0.97,0.01l0.25-0.07L422.68,186.76z"
                    />
                    <path
                      fill="#5E5D5B"
                      d="M420.96,193.09l-0.3-0.01l21.1,0.09c0.33,0,0.62-0.15,0.69-0.36c0,0-0.29,0.65-1.36,0.65
								s-18.92,0-20.03,0c-1.58,0-1.44-0.45-1.44-0.45l1.26,0.07l0.19,0.02"
                    />
                  </g>

                  <ellipse
                    transform="matrix(0.0978 -0.9952 0.9952 0.0978 193.3836 595.908)"
                    fill="#5E5D5B"
                    cx="425.37"
                    cy="191.29"
                    rx="1.14"
                    ry="0.74"
                  />

                  <ellipse
                    transform="matrix(0.1278 -0.9918 0.9918 0.1278 184.5531 586.6832)"
                    fill="#D25959"
                    cx="425.84"
                    cy="188.41"
                    rx="1.02"
                    ry="0.66"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#5E5D5B"
              d="M504,181.92c0,0,0.24,0.8,0.57,1.96s1.24,4.07,1.58,4.81c0.33,0.75,1.16,1.49,2.65,1.49
				c1.49,0,6.21-0.17,7.3-0.17c1.31,0,1.18-1.9,0.18-4.23c-1-2.32-1.42-4.18-4.66-4.48c-2.74-0.26-5.35-0.36-6.34-0.36
				S503.8,181.08,504,181.92z"
            />
            <path
              fill="#FFFFFF"
              d="M516.84,182.75c0.42,1.14,1.12,3.05,1.6,4.45c0.25,0.72,1.07,1.05,1.75,0.7l7.39-3.86
				c0.53-0.27,0.78-0.88,0.62-1.45c0,0-0.36-2.47-1.3-4.46c-0.58-1.24-1.65-1.41-2.34-1.02l-7.16,4.12
				C516.87,181.54,516.63,182.18,516.84,182.75z"
            />
            <linearGradient
              id="Card22SVGID49"
              gradientUnits="userSpaceOnUse"
              x1="516.7653"
              y1="182.4906"
              x2="528.2507"
              y2="182.4906"
            >
              <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4999" style={{ stopColor: "#040404", stopOpacity: 0.0382 }} />
              <stop offset="0.797" style={{ stopColor: "#0F0F0F", stopOpacity: 0.0749 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID49)"
              d="M516.84,182.75c0.42,1.14,1.12,3.05,1.6,4.45c0.25,0.72,1.07,1.05,1.75,0.7l7.39-3.86
				c0.53-0.27,0.78-0.88,0.62-1.45c0,0-0.36-2.47-1.3-4.46c-0.58-1.24-1.65-1.41-2.34-1.02l-7.16,4.12
				C516.87,181.54,516.63,182.18,516.84,182.75z"
            />
            <path
              fill="#C7C9C6"
              d="M515.57,184.31l2.35-0.97c0,0,0-0.66,1.21-1.21c1.21-0.54,4.95-2.84,5.8-3.2
				c0.85-0.36,2.41,2.41,1.69,3.86l-7.06,3.92l-0.97-1.27l-2.35,0.97L515.57,184.31z"
            />
            <path
              fill="#191C1C"
              d="M506.6,184.6c0,0-0.32-0.77-0.61-1.83s3.05-0.67,4.78-0.58c1.73,0.1,2.05,0.55,2.31,1.03L506.6,184.6z"
            />
            <g>
              <path
                fill="#5E5D5B"
                d="M506.6,184.6l1.3,3.38c0,0,1.41-0.81,2.19-1.37c0.78-0.56,0.74-1.16,0.88-1.62
					c0.1-0.33,0.37-0.99,0.86-1.38c-1.16-0.25-2.87-0.29-3.6-0.29C507.11,183.81,506.6,184.6,506.6,184.6z"
              />
              <path
                fill="#5E5D5B"
                d="M506.6,184.6l1.3,3.38c0,0,1.41-0.81,2.19-1.37c0.78-0.56,0.74-1.16,0.88-1.62
					c0.1-0.33,0.37-0.99,0.86-1.38c-1.16-0.25-2.87-0.29-3.6-0.29C507.11,183.81,506.6,184.6,506.6,184.6z"
              />
              <path
                fill="#FBFFFB"
                d="M513.72,184.24c-0.14-1.37-1.76-1.45-3.95-1.3c-0.61,0.04-1.12,0.19-1.54,0.37
					c0.74,0,2.44,0.04,3.6,0.29c0.19-0.14,0.4-0.25,0.65-0.28C513.4,183.22,513.72,184.24,513.72,184.24z"
              />
            </g>
            <path
              fill="#191C1C"
              d="M513.72,184.24l4.28,0.12c0,0-1.23,1.84,0.03,2.87l-4.82-0.31c0,0-0.99,1.5-2.12,2.32
				c0,0-1.23,0.14-2.02,0.03s-1.02-0.87-1.18-1.3l3.3-4.26c0,0,0.65-0.61,1.43-0.48C513.42,183.37,513.72,184.24,513.72,184.24z"
            />
            <path
              fill="#FBFFFB"
              d="M513.72,184.24c0,0-0.37-0.84-1.05-0.7c-0.68,0.14-1.13,1.2,1.06,1.2c2.19,0,4.03,0.07,4.03,0.07
				s-0.07-0.31,0.41-0.48L513.72,184.24z"
            />
            <linearGradient
              id="Card22SVGID50"
              gradientUnits="userSpaceOnUse"
              x1="508.2254"
              y1="183.5913"
              x2="513.7153"
              y2="183.5913"
            >
              <stop offset="0.2213" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.4937" style={{ stopColor: "#030303", stopOpacity: 0.0425 }} />
              <stop offset="0.6893" style={{ stopColor: "#0C0C0C", stopOpacity: 0.073 }} />
              <stop offset="0.8607" style={{ stopColor: "#1C1C1B", stopOpacity: 0.0998 }} />
              <stop offset="0.862" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
              <stop offset="0.9081" style={{ stopColor: "#161615", stopOpacity: 0.0617 }} />
              <stop offset="0.9726" stopColor="#030303" stopOpacity="8.066460e-03" />
              <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID50)"
              d="M509.77,182.94c-0.61,0.04-1.12,0.19-1.54,0.37c0.74,0,2.44,0.04,3.6,0.29
				c0.19-0.14,0.4-0.25,0.65-0.28c0.92-0.11,1.23,0.92,1.23,0.92"
            />
            <path
              fill="#5E5D5B"
              d="M518.42,187.6c-0.61,0-1.1-0.78-1.1-1.74c0-0.96,0.49-1.74,1.1-1.74h0.76v3.48H518.42z"
            />
            <linearGradient
              id="Card22SVGID51"
              gradientUnits="userSpaceOnUse"
              x1="517.3154"
              y1="185.8636"
              x2="519.1842"
              y2="185.8636"
            >
              <stop offset="0.1725" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID51)"
              d="M518.42,187.6c-0.61,0-1.1-0.78-1.1-1.74c0-0.96,0.49-1.74,1.1-1.74h0.76v3.48H518.42z"
            />
            <ellipse fill="#5E5D5B" cx="519.18" cy="185.86" rx="1.1" ry="1.74" />
            <path fill="#191C1C" d="M506.56,184.51l1.08,2.75c0,0-0.79-2.55-0.72-3.11" />
          </g>
          <linearGradient
            id="Card22SVGID52"
            gradientUnits="userSpaceOnUse"
            x1="399.8336"
            y1="227.6195"
            x2="413.7578"
            y2="227.6195"
          >
            <stop offset="0.1913" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.8 }} />
          </linearGradient>
          <path
            fill="url(#Card22SVGID52)"
            d="M402.04,223.38c0,0,6.47,0.24,7.5,0.52c1.03,0.29,1.17,0.67,1.5,2.48
			c0.33,1.81,0.75,3.29,0.75,3.29l1.97,2.19l-3.14-0.29l-0.14-0.52l-2.91-0.14l-0.09,0.62l-7.17-0.95l-0.47-2L402.04,223.38z"
          />
          <linearGradient
            id="Card22SVGID53"
            gradientUnits="userSpaceOnUse"
            x1="416.4211"
            y1="183.845"
            x2="472.4576"
            y2="151.4923"
          >
            <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
            <stop offset="8.980082e-03" style={{ stopColor: "#F2F2F1", stopOpacity: 0.9 }} />
            <stop offset="0.042" style={{ stopColor: "#C5C5C5", stopOpacity: 0.9 }} />
            <stop offset="0.0782" style={{ stopColor: "#9D9D9C", stopOpacity: 0.9 }} />
            <stop offset="0.1171" style={{ stopColor: "#7A7A79", stopOpacity: 0.9 }} />
            <stop offset="0.1592" style={{ stopColor: "#5D5D5C", stopOpacity: 0.9 }} />
            <stop offset="0.2056" style={{ stopColor: "#454544", stopOpacity: 0.9 }} />
            <stop offset="0.2581" style={{ stopColor: "#323232", stopOpacity: 0.9 }} />
            <stop offset="0.3204" style={{ stopColor: "#262625", stopOpacity: 0.9 }} />
            <stop offset="0.4017" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
            <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
            <stop offset="0.7091" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9317 }} />
            <stop offset="0.7692" style={{ stopColor: "#252524", stopOpacity: 0.9458 }} />
            <stop offset="0.8144" style={{ stopColor: "#323231", stopOpacity: 0.9564 }} />
            <stop offset="0.8523" style={{ stopColor: "#444443", stopOpacity: 0.9653 }} />
            <stop offset="0.8855" style={{ stopColor: "#5B5B5B", stopOpacity: 0.9731 }} />
            <stop offset="0.9155" style={{ stopColor: "#787877", stopOpacity: 0.9801 }} />
            <stop offset="0.943" style={{ stopColor: "#9A9A9A", stopOpacity: 0.9866 }} />
            <stop offset="0.9686" style={{ stopColor: "#C2C2C2", stopOpacity: 0.9926 }} />
            <stop offset="0.9918" style={{ stopColor: "#EEEEEE", stopOpacity: 0.9981 }} />
            <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
          </linearGradient>
          <path
            fill="url(#Card22SVGID53)"
            d="M411.27,171.66c-0.61,0.66-2.28,1.98,0.8,2.17c3.08,0.19,20.38,0.1,20.38,0.1s15.95,0,22.39-0.32
			c6.44-0.32,17.44-0.32,19.57-3.2c2.12-2.88,4.64-7.51,4.64-7.51s-0.1-0.19-0.32-0.49c-0.97-1.34-4.26-4.94-11.62-4.94
			c-9.04,0-33.94,0-36.54,0c-2.53,0-6.67,0.46-11.64,5.75c-0.13,0.14-0.26,0.28-0.39,0.42C413.4,169.34,411.88,171.01,411.27,171.66
			z"
          />
          <linearGradient
            id="Card22SVGID54"
            gradientUnits="userSpaceOnUse"
            x1="419.7698"
            y1="189.951"
            x2="443.4473"
            y2="189.951"
          >
            <stop offset="0.2213" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.2586" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.2634" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.518" style={{ stopColor: "#030303", stopOpacity: 0.085 }} />
            <stop offset="0.7009" style={{ stopColor: "#0C0C0C", stopOpacity: 0.1461 }} />
            <stop offset="0.8612" style={{ stopColor: "#1C1C1B", stopOpacity: 0.1996 }} />
            <stop offset="0.8624" style={{ stopColor: "#1C1C1B", stopOpacity: 0.2 }} />
            <stop offset="0.8971" style={{ stopColor: "#161615", stopOpacity: 0.1233 }} />
            <stop offset="0.9457" style={{ stopColor: "#030303", stopOpacity: 0.0161 }} />
            <stop offset="0.953" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.9823" style={{ stopColor: "#000000", stopOpacity: 0 }} />
          </linearGradient>
          <path
            fill="url(#Card22SVGID54)"
            d="M442.79,186.73c-3.78-0.1-15.3-0.09-19.88-0.08c-0.58,0-1.04,0-1.37,0
			c-0.32,0-0.59,0.23-0.66,0.54l-1.1,5.07c-0.09,0.43,0.22,0.84,0.65,0.85l0.94,0.01l20.36,0.14c0.32,0,0.6-0.23,0.67-0.55
			l1.02-5.14C443.52,187.14,443.21,186.74,442.79,186.73z"
          />
          <linearGradient
            id="Card22SVGID55"
            gradientUnits="userSpaceOnUse"
            x1="504.2765"
            y1="232.3916"
            x2="534.0919"
            y2="232.3916"
          >
            <stop offset="0.4595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="0.6657" style={{ stopColor: "#040404", stopOpacity: 0.1908 }} />
            <stop offset="0.8643" style={{ stopColor: "#0F0F0F", stopOpacity: 0.3745 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.5 }} />
          </linearGradient>
          <path
            fill="url(#Card22SVGID55)"
            d="M506.89,234.18c-0.33-4.09-1.78-8.46-2.62-11.4c0,0,5.61-14.25,17.66-12.26
			c13.89,2.3,12.49,24.58,11.68,28.03c-1.03,4.37-3.74,15.01-13.64,15.87C510.07,255.28,507.27,238.84,506.89,234.18z"
          />
          <g>
            <path
              fill="none"
              d="M520.28,166.21l0.16-3.63c-0.03,0.01-0.05,0.02-0.08,0.03C520.29,163.12,520.23,164.42,520.28,166.21z"
            />
            <path
              fill="none"
              d="M532.42,160.73c-0.01,0.13-0.01,0.76-0.01,0.89c-0.07,1.28-0.15,2.14-0.17,3.11
				c-0.09,4.59-0.4,7.63-0.85,9.51c-0.03,0.12-0.06,0.22-0.08,0.29l-0.02,0.27c0.28-0.16,0.45-0.22,0.78-0.27
				c0.41-0.07,0.63-0.21,0.99-0.41l0.02,0.01c0.82-2.5,0.65-5.84,0.43-6.74c-0.31-1.28-0.5-3.07-0.61-4.56
				c-0.13-0.08-0.2-0.15-0.2-0.15L532.42,160.73z"
            />
            <path
              fill="#EFC4AD"
              d="M536.22,162.16c-0.44,0.27-1.24,0.76-1.53,0.84c-0.89,0.26-1.51-0.01-1.8-0.19
				c0.11,1.5,0.3,3.28,0.61,4.56c0.22,0.91,0.39,4.25-0.43,6.74l-0.02-0.01c-0.36,0.2-0.58,0.35-0.99,0.41
				c-0.33,0.05-0.5,0.12-0.78,0.27c-0.07,0.04-0.15,0.09-0.24,0.14c-0.45,0.26-0.88-0.1-0.73,0.43c0.08,0.27,0.49,0.33,0.9,0.3
				c0.39-0.03,0.77-0.12,0.9-0.19c0.26-0.13,0.36-0.05,0.3,0.2s-0.38,0.69-0.66,1.14s-0.35,0.88-0.4,1.52s-0.12,0.89,0.25,0.91
				c0.36,0.02,0.55-0.88,0.73-1.41c0.18-0.53,0.61-1.17,0.61-1.17s-0.05,0.18-0.13,0.45c-0.08,0.26-0.26,0.86-0.17,1.22
				c0.1,0.36,0.21,0.69,0.53,0.76c0.31,0.07,0.43-0.55,0.43-0.55c0.07,0.28,0.28,0.55,0.6,0.53s0.5-0.68,0.6-1.04
				c0.1-0.36,0.3-0.68,0.3-0.68c-0.05,0.15,0,0.48-0.07,0.69c-0.07,0.21-0.13,0.56,0.1,0.58s0.23,0.02,0.63-0.36
				c0.4-0.38,0.17-0.56,0.2-1.17c0.03-0.48-0.5-1.41-0.59-1.92c0.47-3.07,0.91-2.81,1.27-5.97
				C536.87,167.12,536.55,164.26,536.22,162.16z"
            />
            <path
              fill="#EFC4AD"
              d="M524.46,174.88c0,0-0.67-0.04-1.01-0.14c-0.66-0.19-1.22-0.43-1.62-0.33c-0.66,0.16-0.74-0.45-0.8-0.81
				c-0.46-2.78-0.63-5.42-0.68-7.44c-0.04-1.79,0.15-3.26,0.21-3.77c-0.83,0.26-2.31,0.51-3.42-0.73c-0.06,0.97-0.23,5.07,0.46,9.16
				c0.4,2.36,1.24,5.25,1.24,5.25s0.17,0.35,0.3,0.91c0.13,0.56,0.43,0.91,0.63,1.4c0.2,0.49,0.6,0.99,0.98,0.74
				c0,0,0.38,0.77,0.79,0.68c0.4-0.1,0.33-0.39,0.33-0.39s0.35,0.27,0.64,0.14s0.24-0.75,0.24-0.75s0.38,0.08,0.51-0.15
				c0.13-0.23,0.04-1.47,0.02-1.68s-0.4-1.35-0.4-1.35s1.01,0.07,1.22,0.02c0.07-0.02,0.13-0.04,0.19-0.07
				C524.71,175.36,524.46,174.88,524.46,174.88z"
            />
            <path
              fill="#C16032"
              d="M523.77,155.61c-0.05-0.02-0.09-0.03-0.14-0.05C523.69,155.59,523.74,155.6,523.77,155.61z"
            />
            <path
              fill="#C16032"
              d="M535.73,157.8c-0.13-0.88-0.48-2.75-1.32-3.3c-0.82-0.53-2.34-0.48-3.69-0.32
				c-0.78,0.1-1.56,0.22-2.04,0.31c-0.08,1.07-0.07,1.28-0.15,1.79c-0.29,1.97-0.88,2.67-2.16,2.67c-2.36,0-2.51-3.32-2.51-3.37
				c0,0-0.07,0.02-0.08,0.02c-0.13-0.12-0.75-0.8-0.78-0.85c-0.08-0.12-0.24-0.34-0.42-0.65c-0.01,0-1.14-0.16-1.22-0.17
				c-0.01-0.01-0.01-0.01-0.01-0.02c-1.49-0.24-3.51-0.4-3.89,0.84c-0.69,2.25-0.69,6.62-0.69,6.62c0.09,0.13,0.19,0.25,0.29,0.37
				c1.1,1.24,2.46,1.1,3.29,0.84c0.03-0.01,0.05-0.02,0.08-0.03l-0.16,3.63c0.05,2.02,0.22,4.66,0.68,7.44
				c0.06,0.36,0.13,1.02,0.79,0.86c0.41-0.1,0.96,0.13,1.63,0.32c0.34,0.1,1.03,0.16,1.03,0.16s0.16,0.44-0.27,0.65l7.07,0.11
				l0.01-0.08c-0.41,0.03-0.82-0.03-0.9-0.3c-0.15-0.53,0.28-0.17,0.73-0.43c0.09-0.05,0.17-0.1,0.24-0.14l0.02-0.27
				c0.03-0.07,0.05-0.17,0.08-0.29c0.45-1.88,0.76-4.93,0.85-9.51c0.02-0.97,0.1-1.84,0.17-3.11c0.01-0.13,0-0.75,0.01-0.89
				l0.28,1.94c0,0,0.07,0.07,0.2,0.15c0.29,0.19,0.9,0.45,1.8,0.19c0.28-0.08,1.09-0.57,1.53-0.84c0.18-0.11,0.29-0.18,0.29-0.18
				S535.96,159.33,535.73,157.8z"
            />
            <path
              fill="#EFC4AD"
              d="M529.22,152.63c0,0,0-0.19,0.03-0.53c0.03-0.01,0.04-0.01,0.07-0.03c0.55-0.18,0.94-1.01,1.09-1.44
				c0.15-0.43,0.2-1.14-0.43-1.15c-0.19,0-2.7-3.85-2.7-3.85c-0.44,0.35-3.09,0.02-3.08-0.59c-0.1,0.61-2.68,0.89-2.41,0.3
				c0,0-0.03,0.07-0.08,0.19c-0.09,0.26-0.31,0.75-0.28,1.3c-0.07,0.79-0.16,1.34-0.08,1.64c0,0.07,0.01,0.05,0.01,0.19
				c-0.01,0.25-0.04,0.4-0.02,0.64c0.15,1.19-0.08,2.19,0.17,3.06c0.03,0.1,0.05,0.19,0.09,0.3c0,0.01,0.03,0.05,0.04,0.06
				c0.19,0.49,0.51,0.99,0.76,1.42c0.17,0.31,0.32,0.57,0.4,0.69c0.03,0.04,0.08,0.1,0.11,0.15c0.3,0.38,0.52,0.6,0.69,0.64
				c0.04,0.02,0.11-0.02,0.16,0c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0c0,0.05,0.23,3.44,2.59,3.44c1.28,0,1.94-0.8,2.23-2.76
				c0.08-0.52,0.06-0.7,0.14-1.77c0.02-0.3,0.17-0.63,0.21-1.09C529.27,152.98,529.22,152.63,529.22,152.63z"
            />
            <path
              fill="#EFC4AD"
              d="M521.91,149.36c-0.19,0.11-0.21,0.28-0.21,0.28s0.06,0,0.23-0.01c0.02,0,0.05,0,0.08,0
				C522,149.63,521.85,149.55,521.91,149.36z"
            />
            <path
              fill="#4D2C1A"
              d="M523.65,152.35c-0.21-0.01-0.4,0.02-0.51,0.11c0.06-0.03,0.21-0.09,0.48-0.11
				C523.63,152.35,523.64,152.35,523.65,152.35z"
            />
            <path
              fill="#4D2C1A"
              d="M524.75,153.05c0-0.01,0.01-0.01,0.01-0.02C524.75,153.04,524.75,153.04,524.75,153.05
				C524.75,153.05,524.75,153.05,524.75,153.05z"
            />
            <path
              fill="#4D2C1A"
              d="M523.4,153.53c0.17,0.16,0.39,0.23,0.67,0.13c-0.06,0.02-0.13,0.03-0.19,0.03
				C523.65,153.69,523.5,153.61,523.4,153.53z"
            />
            <path
              fill="#4D2C1A"
              d="M522.94,152.6l0.03-0.01c0,0,0.08,0.4,0.26,0.73c0,0-0.01-0.41,0.5-0.49c0.51-0.08,0.84-0.07,1.05,0.04
				c0,0,0.05,0.04,0,0.11c0.09-0.13,0.15-0.25,0.18-0.31l-0.08-0.07c-0.12-0.03-0.36-0.15-0.57-0.19c-0.62,0.25-1.04,0.05-1.1,0.03
				c0,0-0.12,0.05-0.12,0.05C523.06,152.54,523,152.57,522.94,152.6z"
            />
            <path
              fill="#AD7D62"
              d="M524.3,150.79c-0.04-0.08-0.07-0.12-0.07-0.12C524.25,150.7,524.27,150.74,524.3,150.79z"
            />
            <path
              fill="#DFD7C6"
              d="M524.37,152.46c-0.14-0.03-0.24-0.14-0.35-0.16c-0.11-0.02-0.26,0-0.37,0c-0.01,0-0.01,0-0.02,0
				c-0.27,0.02-0.43,0.07-0.49,0.1c-0.01,0.01-0.02,0.07-0.02,0.07C523.18,152.5,523.75,152.71,524.37,152.46z"
            />
            <path
              fill="#DFD7C6"
              d="M523.96,153.42c-0.44,0.01-0.72-0.1-0.72-0.1s0,0.15,0.12,0.25c0.1,0.08,0.28,0.19,0.51,0.19
				c0.07,0,0.18-0.01,0.25-0.03c0.39-0.11,0.6-0.6,0.64-0.68c0,0,0,0,0,0C524.62,153.23,524.4,153.41,523.96,153.42z"
            />
            <path
              fill="#D25959"
              d="M523.71,152.74c-0.51,0.08-0.48,0.58-0.48,0.58s0.24,0.18,0.68,0.16c0.44-0.01,0.7-0.25,0.83-0.43
				c0-0.01,0.01-0.01,0.01-0.01c0.01-0.02,0.02-0.04,0.04-0.05c0.05-0.07,0.07-0.13,0.07-0.13
				C524.65,152.74,524.22,152.66,523.71,152.74z"
            />
            <path fill="#4D2C1A" d="M529.85,146.59L529.85,146.59c0.01-0.01,0.01-0.03,0.02-0.05L529.85,146.59z" />
            <path
              fill="#AD7D62"
              d="M522.73,152.92c-0.01-0.03-0.02-0.06-0.02-0.09C522.72,152.94,522.77,153.04,522.73,152.92z"
            />
          </g>
          <path
            opacity="0.27"
            d="M519.95,176.68c0,0,0.31,1.02,0.42,1.6c0.08,0.43,0.14,0.79,0.17,0.95c0.01,0.05,0.01,0.08,0.01,0.08
			l0.15-0.08c0,0,0-0.01,0-0.04c-0.02-0.15-0.1-0.7-0.27-1.22C520.22,177.38,519.95,176.68,519.95,176.68z"
          />
          <path
            opacity="0.27"
            d="M521.21,177.55c0.05,0.33,0.46,1.84,0.53,2.14c0.01,0.03,0.01,0.05,0.01,0.05l0.05-0.21
			c0,0-0.01-0.02-0.01-0.06c-0.09-0.39-0.63-2.6-0.83-3.15C520.97,176.32,521.15,177.19,521.21,177.55z"
          />
          <path
            opacity="0.27"
            d="M522.09,176.39c-0.09-0.32-0.15-0.54-0.14-0.64c0,0-0.06,0.04,0.01,0.42c0.07,0.38,0.29,1.12,0.34,1.43
			c0.04,0.3,0.32,1.23,0.35,1.6c0,0.02,0,0.03,0,0.04l0.03-0.34c0,0,0-0.02-0.01-0.06c-0.04-0.18-0.15-0.75-0.32-1.45
			C522.26,177.01,522.17,176.66,522.09,176.39z"
          />
          <path
            opacity="0.27"
            d="M523.32,149.75c0-0.04,0-0.08,0-0.12c0-0.06-0.01-0.13-0.01-0.19c-0.03-0.34-0.12-0.62-0.12-0.62
			c0.01,0.01,0.01,0.02,0.02,0.03c-0.12-0.28-0.32-0.41-0.32-0.41c-0.67-0.42-1.08,0.05-1.08,0.05c0.02,0,0.04,0,0.07,0
			C523.02,148.47,523.27,149.32,523.32,149.75z"
          />
          <path
            opacity="0.27"
            d="M522.96,175.47c-0.15-0.07-0.65-0.29-0.78-0.35c0.55,0.28,0.69,0.52,0.69,0.52l0.44,0.03
			c0,0,0.04,0.01-0.03-0.03C523.24,175.59,523.14,175.55,522.96,175.47z"
          />
          <path
            opacity="0.27"
            d="M519.9,167.61c-0.05,1.24-0.71,1.47-0.88,1.51c0.19-0.03,1.06-0.1,1.16,1.17c0.11,1.42,0.4,2.85,0.46,3.59
			c0.06,0.74,0.81,0.63,0.81,0.63s0,0,0,0c-0.01-0.04-0.32-0.65-0.47-1.45c0.03-0.64,0.07-1.75,0.13-3.03
			c0.01-0.01,0.02-0.02,0.03-0.05c0.3-0.53,1.41,0.17,2.06,0.66c-1.21-1.13-3.14-3.84,0.4-1.76c3.76,2.2,5.12,2.07,3.7,1.56
			c-1.43-0.52-3.7-1.56-4.6-3.24c-0.91-1.69-1.36-3.11-1.11-5.53c0,0-0.03,0.3-0.08,0.78c0.03-0.39,0.06-0.67,0.08-0.78
			c0.18-0.88,0.57-1.37,0.18-0.97c-0.4,0.4-0.71,0.71-0.79-0.04c-0.09-0.75-0.33-2.73-0.33-2.73s0.02,1.32-0.03,2.47
			c-0.03,0.81-0.18,1.98-0.27,2.21c-0.13,0.05-0.64,0.14-0.64,0.15C519.72,163.01,519.95,166.24,519.9,167.61z"
          />
          <path
            opacity="0.27"
            d="M535.4,174.89c0.02-0.1,0.04-0.23,0.07-0.36c0.11-0.52,0.27-1.25,0.48-2.02c0.25-0.9,0.38-1.36,0.56-2.39
			c0.1-0.57,0.2-1.08,0.21-1.45c0.07-1.57,0.11-3.4-0.49-6.51c0-0.01,0,0-0.01-0.03c-1.32,1-2.3,0.98-2.3,0.98l-0.07,0.03
			c1.09,2.34,1.61,3.55,1.27,4.97c-0.22,0.9-0.24,1.66-0.05,2.63c0.31,1.56-0.16,3.28-0.19,3.99c0,0,0,0,0,0
			c-0.15,1.35,0,1.49-0.31,2.74c-0.08,0.31-0.15,0.53-0.21,0.67c-0.22,0.02-0.62,0.04-0.63-0.15c-0.02-0.26,0.32-1.37,0.15-1.11
			c-0.17,0.26-0.28,1.22-0.49,1.22c-0.19,0-0.49,0-0.79-0.03c-0.02,0,0.02,0,0,0c-0.02,0.45,0.27,1.14,0.66,1.02
			c0.28-0.09,0.32-0.53,0.32-0.53c-0.01,0.09,0.11,0.27,0.19,0.36c0.11,0.11,0.16,0.16,0.29,0.18c0.23,0.04,0.34-0.05,0.53-0.43
			c0,0,0.07-0.22,0.07-0.2c0.03-0.09,0.04-0.16,0.12-0.41c0.04-0.13,0.18-0.47,0.28-0.62c0,0.17,0,0.43-0.05,0.65
			c-0.01,0.07-0.04,0.14-0.05,0.21c-0.02,0.21,0.01,0.36,0.21,0.36c0.29-0.01,0.22-0.06,0.32-0.13c0.06-0.05,0.12-0.1,0.16-0.14
			c0.29-0.24,0.38-0.43,0.3-0.89c-0.02-0.09-0.01-0.34-0.02-0.45c-0.02-0.3-0.09-0.49-0.21-0.82c-0.04-0.14-0.09-0.18-0.12-0.28
			c-0.05-0.17-0.18-0.47-0.21-0.59c-0.08-0.25-0.01-0.24-0.01-0.29c0-0.02,0-0.03,0.01-0.05
			C535.39,174.97,535.4,174.93,535.4,174.89z M535.22,177.33c0-0.01,0-0.02,0-0.03c0,0,0,0,0,0
			C535.23,177.31,535.23,177.32,535.22,177.33z"
          />
          <path
            opacity="0.27"
            d="M532.9,176.38c0.02-0.2-0.18,0.16-0.47,0.67s-0.45,0.73-0.53,1.26c-0.08,0.5-0.26,0.83-0.54,0.86
			c-0.02,0,0.02,0,0,0c0,0,0.2,0.19,0.38,0.07c0.19-0.13,0.34-0.58,0.42-0.89c0.08-0.3,0.28-0.86,0.42-1.1s0.34-0.57,0.34-0.57
			S532.88,176.58,532.9,176.38z"
          />
          <path
            fill="#4D2C1A"
            d="M526.17,149.63c0.02,0,0.03,0,0.03,0l-0.07-0.03c-0.14-0.13-0.54-0.47-0.93-0.44
			c-0.48,0.03-0.69,0.47-0.69,0.47s0.21-0.29,0.53-0.37c-0.03,0.01-0.06,0.02-0.09,0.04c0,0-0.07,0.18,0.05,0.33l0.46,0.01
			c0,0,0.16-0.08,0.11-0.31c0.21,0.08,0.41,0.21,0.52,0.28c-0.06,0-0.27,0.02-0.55,0.02C525.26,149.61,525.98,149.62,526.17,149.63
			c0.01,0.01,0.02,0.02,0.02,0.02S526.18,149.64,526.17,149.63z"
          />
          <path
            fill="#4D2C1A"
            d="M522.48,149.32c0.27,0.08,0.47,0.25,0.47,0.25s-0.26-0.41-0.82-0.33c-0.39,0.06-0.51,0.42-0.51,0.42
			s0.5,0,0.72-0.03c0.04,0,0.05-0.01,0.06-0.01c0,0,0,0-0.01,0l0.06,0C522.53,149.52,522.5,149.37,522.48,149.32z M521.7,149.64
			c0,0,0.02-0.18,0.21-0.28c-0.06,0.19,0.09,0.27,0.1,0.27c-0.03,0-0.06,0-0.08,0C521.76,149.64,521.7,149.64,521.7,149.64z"
          />
          <path
            fill="#AD7D62"
            d="M525.48,149.1c0.16,0.03,0.32,0.09,0.47,0.15c0.15,0.06,0.31,0.13,0.46,0.21
			c-0.26-0.2-0.57-0.36-0.91-0.44c-0.17-0.04-0.35-0.05-0.52-0.01c-0.17,0.04-0.33,0.16-0.38,0.32c0.08-0.14,0.24-0.23,0.39-0.26
			C525.15,149.04,525.32,149.05,525.48,149.1z"
          />
          <path
            fill="#AD7D62"
            d="M526.65,148.78c0,0-0.52-0.7-1.2-0.68s-1.02,0.46-1.02,0.46s0.44-0.13,1.03-0.14
			C526.04,148.4,526.65,148.78,526.65,148.78z"
          />
          <path
            fill="#AD7D62"
            d="M522.74,151.4c0.04-0.1,0.08-0.16,0.11-0.22c0,0.04,0.02,0.09,0.06,0.15c0,0,0,0,0,0.01
			c-0.01,0.04,0.02,0.05,0.06,0.06c0.01,0.01,0.01,0.02,0.02,0.02c-0.01-0.01-0.01-0.02-0.02-0.02c0.06,0.01,0.16-0.01,0.2-0.02
			c0.06,0.05,0.14,0.08,0.26,0.04c0,0-0.35,0.03-0.32-0.39c0.01-0.22,0.17-0.66,0.22-1.04c0.05-0.38,0.06-0.82-0.14-1.17
			c0,0,0.2,0.59,0.11,1.08c-0.08,0.49-0.22,1.09-0.23,1.19c-0.01,0.05,0.01,0.16,0.07,0.25c-0.1-0.05-0.18-0.09-0.22-0.04
			c-0.04-0.11-0.02-0.26,0.17-0.35c0,0-0.1,0.03-0.17,0.1c0.02-0.05,0.03-0.12,0.03-0.22c0,0-0.21,0.43-0.33,0.85
			c-0.04,0.14-0.04,0.3-0.03,0.45C522.58,151.84,522.66,151.58,522.74,151.4z"
          />
          <path
            fill="#AD7D62"
            d="M524.18,151.22c-0.02-0.12-0.44,0.14-0.44,0.14C524.08,151.4,524.2,151.34,524.18,151.22z"
          />
          <path
            fill="#AD7D62"
            d="M524.33,151.08c-0.04-0.22-0.26-0.23-0.26-0.23c0.08-0.01,0.18,0.09,0.21,0.24
			c0.02,0.08-0.02,0.15-0.07,0.2C524.29,151.25,524.35,151.18,524.33,151.08z"
          />
          <path fill="#AD7D62" d="M524.22,151.3c-0.08,0.06-0.18,0.08-0.18,0.08" />
          <path fill="#AD7D62" d="M524.22,151.3c-0.05,0.05-0.11,0.08-0.18,0.08" />
          <path
            fill="#AD7D62"
            d="M521.73,149.19c0.11-0.08,0.25-0.11,0.39-0.12c0.14-0.01,0.28,0.01,0.42,0.04
			c0.13,0.04,0.27,0.1,0.37,0.18c-0.17-0.22-0.49-0.31-0.79-0.3c-0.15,0.01-0.31,0.05-0.42,0.14c-0.11,0.09-0.18,0.21-0.2,0.33
			C521.53,149.36,521.62,149.26,521.73,149.19z"
          />
          <path
            fill="#AD7D62"
            d="M522.71,152.82c-0.01-0.09,0-0.19,0.09-0.18c0.09,0.15,0.23,0.65,0.39,0.9s0.31,0.49,0.86,0.4
			c0.55-0.09,0.56-0.46,0.74-0.71c0.14-0.2,0.19-0.45,0.21-0.56c0.1,0.02,0.14,0.34,0.14,0.33c0-0.01-0.03-0.29-0.05-0.45
			c0-0.09-0.01-0.35-0.13-0.66c-0.02-0.07-0.06-0.14-0.1-0.22c-0.16-0.3-0.42-0.67-0.56-0.9c0.12,0.21,0.36,0.65,0.51,0.98
			c0.14,0.3,0.21,0.56,0.25,0.71c-0.06,0.1-0.16,0.07-0.19,0.09l0.01,0.01c-0.27-0.11-0.87-0.36-1.01-0.4
			c-0.18-0.06-0.25-0.01-0.38,0.03c-0.13,0.04-0.11-0.2-0.43,0c-0.21,0.14-0.26,0.3-0.27,0.38c-0.03,0.05-0.09,0.02-0.15-0.13
			c-0.01-0.03-0.02-0.04-0.02-0.04c-0.02-0.08-0.04-0.18-0.05-0.28c0,0.1,0.02,0.2,0.04,0.3
			C522.64,152.5,522.67,152.68,522.71,152.82z M523.12,152.47c0.01-0.01,0.02-0.01,0.02-0.02c0.11-0.08,0.29-0.11,0.51-0.11
			c0.1,0,0.21,0.01,0.32,0.03c0.11,0.02,0.25,0.05,0.4,0.08c0.21,0.05,0.41,0.1,0.53,0.14l0.08,0.07c-0.03,0.06-0.1,0.18-0.18,0.31
			c-0.01,0.02-0.02,0.03-0.04,0.05c0,0.01-0.01,0.01-0.01,0.02c-0.04,0.08-0.28,0.49-0.67,0.61c-0.28,0.1-0.51,0.03-0.67-0.13
			c-0.12-0.1-0.16-0.21-0.16-0.21c-0.19-0.32-0.26-0.73-0.26-0.73l-0.03,0.01C523,152.57,523.06,152.54,523.12,152.47
			C523.11,152.48,523.12,152.48,523.12,152.47z"
          />
          <path
            fill="#AD7D62"
            d="M529.33,151.18c-0.13,0.18-0.09,0.31,0.01,0.21s0.32-0.37,0.29-0.46s-0.15-0.06-0.19-0.24
			c0,0-0.05,0.02-0.04,0.14S529.46,151,529.33,151.18z"
          />
          <path
            fill="#AD7D62"
            d="M529.8,150.77c-0.01,0.13-0.02,0.24-0.02,0.24s0.11-0.11,0.12-0.26c0.01-0.14-0.01-0.23-0.07-0.43
			s0.08-0.39,0.2-0.4c0.18-0.01,0.2,0.44,0.2,0.44c0.01-0.2,0.05-0.48-0.11-0.58c-0.16-0.1-0.29,0.04-0.36,0.14
			c-0.07,0.09-0.26,0.51-0.19,0.69C529.64,150.79,529.81,150.64,529.8,150.77z"
          />
          <path
            fill="#AD7D62"
            d="M529.37,151.76c0.43-0.11,0.71-0.99,0.71-0.99C529.92,151.26,529.37,151.76,529.37,151.76z"
          />
          <path
            fill="#EFC4AD"
            d="M522.38,144.77c0,0,0.46-0.39,1.18-0.49c0.77-0.11,1.42-0.17,2.54,0.11c1.28,0.32,1.8,1.06,1.8,1.06
			s0.8,0.77,1.2,1.68c0.25,0.56,0.66,1.64,0.81,2.39s-3.04-3.66-3.04-3.66l-4.05-0.09l-1.15-0.07l0.1-0.36L522.38,144.77z"
          />
          <path
            fill="#AD7D62"
            d="M521.34,154.28c-0.1,1.19,0.09,2.13,0.22,2.82c0.09,0.97,0.26,1.94,0.5,2.88
			c-0.1-0.58-0.11-1.18-0.04-1.77c0.13,0.44,0.27,0.87,0.4,1.31c0.06,0.18,0.2,0.41,0.37,0.33c-0.34-0.46,0.07-0.37,0.21,0.26
			c0.08-0.7,0.39-1.03,0.59-0.53c-0.04-0.64-0.02-1.28,0.06-1.91c0.01,0,0.02-0.01,0.03-0.01c0.07,0.62,0.18,1.24,0.33,1.84
			c0.09-0.69-0.22-3.4-0.23-3.71c0-0.06,0-0.12,0-0.19c0,0,0,0,0,0c0,0-0.01,0-0.02,0c-0.02-0.01-0.07-0.02-0.14-0.05
			c-0.14-0.07-0.4-0.22-0.68-0.61c-0.16-0.22-0.27-0.42-0.47-0.77c0.02,0-0.57-1.04-0.76-1.53c0-0.01-0.01-0.02-0.01-0.03
			c-0.04-0.11-0.08-0.22-0.11-0.33c-0.25-0.86,0-1.79-0.15-2.98c-0.03-0.24-0.04-0.37-0.02-0.62c0.09-0.08,0.24-0.17,0.48-0.21
			c0.49-0.08,0.97-0.04,0.97-0.04s-0.42-0.31-0.95-0.26c-0.02,0-0.05,0-0.07,0.01c-0.21,0.03-0.34,0.12-0.42,0.22
			c0.02-0.31,0.05-0.78,0.08-1.57c-0.05-0.81,0.28-1.49,0.28-1.49c-0.28,0.59-0.22,1.33-0.22,1.33l0.21,0.79
			c0.01,0.01,0.02,0.03,0.03,0.04l0.53,0.04c0,0,0.28-0.08,0.28-0.01c0,0.02,0.06,0.04,0.15,0.06c0-0.07,0.04-0.14,0.11-0.16
			c0-0.07,0-0.13,0.01-0.2c0.04,0.07,0.07,0.15,0.08,0.22c0-0.11,0.01-0.23,0.01-0.34c0.06,0.18,0.13,0.35,0.19,0.53
			c0,0,0.01,0,0.01,0c-0.09-0.38-0.07-0.54-0.1-0.96c-0.01-0.16-0.07-0.22-0.01-0.37c0.03,0.59,0.11,0.83,0.27,1.34
			c0.02,0,0.03,0,0.04,0c0-0.12,0-0.23,0-0.35c0.04,0.07,0.06,0.15,0.07,0.23c-0.04-0.14-0.05-0.29-0.04-0.44
			c0.04,0.13,0.09,0.27,0.13,0.4c0.01,0,0.01,0,0.02,0c-0.06-0.25-0.11-0.51-0.14-0.77c0.09,0.3,0.19,0.59,0.3,0.87c0,0,0,0,0,0
			l0.01,0c-0.15-0.6-0.3-1.22-0.2-1.83c-0.01,0.61,0.13,1.23,0.4,1.78l0.03,0.04l0.62-0.04l0.39,0.08l1.59,0.05
			c0,0-0.05-0.96,0.4-1.31c0,0,0,0.07,0.13,0.84c0.11,0.64,1,1.22,1.19,1.66c0.26,0.64,0.69,0.64,0.79,0.77
			c0.02,0.19,0.05,0.39,0.1,0.57l0.27-0.54c0.13-0.1,0.29-0.16,0.48-0.16c0.63,0.01,0.55,0.64,0.4,1.07
			c-0.15,0.43-0.55,1.25-1.1,1.43c-0.03,0.01-0.06,0.02-0.09,0.03c-0.03,0.34-0.05,0.55-0.05,0.55s0.05,0.34-0.27,0.79
			c-0.04,0.47-0.07,0.83-0.09,1.13c0.49-0.09,1.2-0.23,1.98-0.33c0.27-0.75,0.44-1.44,0.34-1.83c-0.31-1.2-0.5-1.49-0.25-3.41
			c0.22-1.73-0.26-2.92-0.97-4.29c-0.26-0.75-1-1.35-2.68-1.72c-2.16-0.47-3.68-0.3-4.71,0.54c-1.33,0.88-1.73,1.51-1.91,2.96
			c-0.23,1.89,0.72,2.95,0.41,4.08c-0.05,0.2-0.09,0.37-0.11,0.54l-0.02,0c-0.01,0.09,0,0.17,0,0.25c-0.02,0.65,0.2,1.1,0.4,1.65
			c0.02,0.05,0.04,0.11,0.05,0.16c0.05,0.24,0.08,0.5,0.08,0.8C521.36,154.04,521.35,154.15,521.34,154.28z M529.85,146.57
			l0.02-0.05C529.86,146.54,529.85,146.56,529.85,146.57L529.85,146.57z"
          />
          <path
            opacity="0.27"
            d="M524.87,143.92c-0.79,0.82-0.54,2.4-0.49,3.56c0.03,0.01,0.11,0.04,0.19,0.09c0.22,0.11,0.54,0.3,0.54,0.3
			s0.64,0,1.27,0.03c0.58,0.03,1.29,0.8,1.37,1.14c0.22,1.03-0.51,1.66-0.91,2.76c-0.27,0.76-0.22,2.04-0.9,3.27
			c-0.27,0.31-0.7,0.47-0.94,0.53c-0.71,0.17-1.22,0.01-1.22,0.01c0,0.06,0,0.13,0,0.19c0,0.12,0.01,0.29,0.03,0.5
			c-0.04-0.4-0.04-0.61-0.03-0.69c0-0.02,0-0.03,0-0.03s-0.51-0.1-0.93-0.81c-0.3-0.49-0.69-1.2-0.9-1.58
			c-0.09-0.16-0.15-0.27-0.15-0.27c0.3,0.63,0.49,1.19,0.4,2.37c-0.09,1.17,0.07,1.55,0.26,2.18c0.19,0.63,0.28,2.11,0.28,2.11
			s0.06,0.07,0.12,0.17c0,0,0,0,0,0.01c0.06,0.09,0.11,0.21,0.14,0.33c0-0.02,0.01-0.05,0.02-0.08c0.01-0.03,0.01-0.06,0.02-0.1
			l-0.04,0.22c0,0,1.46-0.43,2.09-1.49c-0.2-0.16-0.37-0.35-0.51-0.55c0.1-0.29,0.49-1.36,1.13-2.03c0.56-0.58,1.52-1.33,1.97-1.67
			c0.35-0.22,0.68-0.46,0.93-0.7c0,0,0.14-0.16,0.26-0.35c0,0,0.01-0.01,0.03-0.04c-0.01,0.02-0.02,0.03-0.03,0.05l0,0.03l0-0.03
			c-0.01,0.02-0.02,0.03-0.04,0.05c-0.04,0.47-0.07,0.83-0.09,1.13c0.02,0-0.01,0.09,0.01,0.09l-0.06,0.81
			c0.22-0.07,2.37-1.23,3.91-1.34c0.05,0,0.09-0.01,0.14-0.01c-0.52-0.03-1.17,0-2.01,0.09c0.27-0.74,0.43-1.42,0.33-1.8
			c-0.31-1.2-0.5-1.49-0.25-3.41c0.15-1.15-0.02-2.06-0.35-2.94c-0.14-0.64-0.4-1.25-0.81-1.74c-0.01-0.02-0.03-0.04-0.04-0.05
			C528.81,143.31,525.76,142.99,524.87,143.92z M530.43,149.98c0.02,0.2-0.03,0.43-0.1,0.62c-0.06,0.16-0.15,0.39-0.27,0.6
			C530.26,150.79,530.46,150.33,530.43,149.98z M530.42,149.92c0,0,0-0.01,0-0.01C530.42,149.91,530.42,149.91,530.42,149.92z
			 M523.42,159.36C523.42,159.36,523.42,159.36,523.42,159.36C523.42,159.36,523.42,159.36,523.42,159.36z M524.16,157.26
			c0.02,0.04,0.03,0.08,0.05,0.12C524.19,157.34,524.18,157.3,524.16,157.26z"
          />
          <path
            fill="#D4BDAF"
            d="M536.02,135.72l0.52-2.55c0,0,1.55-1.92,2.4-2.31s2.58-1.19,3.59-1.06c1.02,0.13,2.79,0.07,4.2,1.03
			s1.83,0.93,2.13,2.1c0.3,1.17,0.39,1.41,0.33,2.19c-0.06,0.78-1.18,7.04-1.18,7.04s-4.53,0.84-4.62,0.84
			c-0.05,0-1.27-0.77-2.07-1.95c-0.75-1.1-1.08-2.62-1.08-2.62l-0.56-1.28l-1.76,0.45L536.02,135.72"
          />
          <path
            opacity="0.3"
            d="M558.19,256.66c0,0,0.84,1.1-1.45,2.63c-2.28,1.53-2.73,1.26-3.67,2.06c-0.94,0.8-7.79,2.07-19.19,3.59
			c-11.77,1.57-13.95,0.81-19.59-1.72c-5.69-2.55-0.43-1.58,21.21-4.94"
          />
          <g>
            <path
              fill="none"
              d="M554.95,185.07l-0.01,0.03c0.69,0,1.39,0.05,2.08,0.14C557.02,185.23,556.36,185.05,554.95,185.07z"
            />
            <path
              fill="none"
              d="M541.52,147.57c0.02-0.06,0.05-0.12,0.08-0.17c-0.03,0.01-0.06,0.03-0.09,0.04
				C541.51,147.48,541.51,147.52,541.52,147.57z"
            />
            <path
              fill="#D4BDAF"
              d="M557.51,164.3c-0.46,1.75-0.75,8.63-1.12,11.01c-0.1,0.65-0.19,0.92-0.08,1.12
				c0.12,0.2,0.43,0.32,0.26,1.14c-0.11,0.57-0.18,0.97-0.08,1.3c0.88-1.45,2.89-6.48,2.88-7.55c-0.01-1.76,0.06-4.57,0.13-7.32
				C559.36,164.11,558.74,164.44,557.51,164.3z"
            />
            <path
              fill="#D4BDAF"
              d="M554.94,185.09c0,0-0.42-0.02-0.65-0.01C554.29,185.09,554.72,185.09,554.94,185.09z"
            />
            <path
              fill="#D4BDAF"
              d="M534.9,167.47c-1.36-0.6-3.43-1.48-4.03-2.92c-0.45,1.44-1.2,3.84-1.9,6.67c0,0-2.78,3.09-5.68,5.58
				c-2.84,2.44-5.08,3.93-5.87,4.44c-0.26,0.08-0.49,0.14-0.71,0.19c-0.83,0.15-1.77,0.34-1.99,0.44c-0.41,0.18-1.24,1.22-1.43,1.52
				c0,0,0.08,0.08,0.25,0.34c-0.02,0.06-0.04,0.11-0.06,0.15c-0.18,0.37-0.44,2.69-0.24,3.52c0.11,0.45,0.03,0.13,0.39,0.85
				c0.36,0.72,0.81-0.08,0.97-0.14c0,0,0.34,0.39,0.59,0.56c0.25,0.17,0.99,0.08,0.99,0.08s0.53,0.32,0.84,0.17
				c0.31-0.15,0.67-0.43,0.67-0.43s0.15,0.09,0.64-0.04c0.37-0.1,0.65-0.76,0.69-0.84l-0.38-0.23c-0.6,0-0.54-0.68-0.54-1.62
				s0.21-1.26,0.81-1.26l0.45-0.3c0.13,0,0.26,0.04,0.38,0.11c0.22-0.19,0.52-0.39,0.94-0.57c1.73-0.7,5.56-2.94,7.74-4.27
				c0.2-0.12,0.38-0.16,0.56-0.28c4.35-2.87,5.49-5.03,6.69-7.35c0.89-1.72,1.6-3.32,2.15-4.68
				C536.99,167.98,536.31,167.96,534.9,167.47z"
            />
            <path
              fill="#191C1C"
              d="M552.86,252.98c0.37,0.59,1.4,2.09,2.4,1.85c1.24-0.31,2.52-1,2.52-1s0,0.17,0.04,0.43
				c-0.07-0.71-0.01-1.37,0.02-1.67c-0.64,0.38-1.55,0.8-2.68,1.01C554.22,253.78,553.45,253.44,552.86,252.98z"
            />
            <path
              fill="#191C1C"
              d="M552.66,252.82c0,0.12,0,0.24,0,0.36l0.05-0.32C552.69,252.84,552.67,252.83,552.66,252.82z"
            />
            <path
              fill="#1EBEE4"
              d="M555.25,254.83c-1,0.25-2.03-1.25-2.4-1.85c-0.05-0.04-0.1-0.08-0.15-0.13l-0.05,0.32
				c0.01,1.97,0.02,3.24,0.02,3.24c-0.17,0.06-0.34,0.12-0.51,0.17c0,0.03,0.01,0.06,0.01,0.09c0.17,0.07,1.31,0.59,2.3,0.95
				c0.95,0.21,1.79,0.33,2.22,0.25c0.87-0.16,1.3-0.59,1.5-0.86c0.06-0.17,0.09-0.35,0.09-0.52c0.03-0.62-0.21-1.22-0.31-1.54
				c-0.08-0.26-0.13-0.5-0.16-0.69c-0.04-0.26-0.04-0.43-0.04-0.43S556.49,254.52,555.25,254.83z"
            />
            <path
              fill="#FFFFFF"
              d="M556.7,258.95c0.79-0.07,1.18-0.41,1.37-0.78C557.83,258.55,557.42,258.89,556.7,258.95z"
            />
            <path
              fill="#191C1C"
              d="M556.7,257.87c-0.43,0.08-1.27-0.05-2.22-0.25c-0.99-0.36-2.14-0.87-2.3-0.95c0-0.03-0.01-0.06-0.01-0.09
				c-2.73,0.84-5.08,0.36-6.86-0.42c0.2,0.62,0.66,1.48,1.77,1.8c1.27,0.37,2.45,0.3,3.36,0.07c1.6-0.39,1.82-0.66,1.82-0.66
				l0.07,0.35c1.17,0.45,3.01,1.34,4.38,1.22c0.73-0.06,1.14-0.4,1.37-0.78c0.37-0.63,0.27-1.4,0.27-1.4s-0.01,0.04-0.05,0.1
				c0.01-0.12,0.01-0.25,0-0.37c-0.01,0.18-0.04,0.35-0.09,0.52C558,257.29,557.57,257.71,556.7,257.87z"
            />
            <path
              fill="#191C1C"
              d="M550.07,261.18c-1.82-0.07-4.18-0.2-10.08-0.93c-5.91-0.74-6.36-2.58-6.36-2.58
				c-0.06-0.19-0.08-0.36-0.09-0.51c-0.03,0-0.04,0-0.04,0s-0.3,1.25,0.88,2.21c1.82,1.48,4.43,1.48,7.34,2
				c2.91,0.52,7.9,0.79,9.54,0.56c1.64-0.22,1.91-0.59,1.82-1.7C552.89,260.96,551.89,261.26,550.07,261.18z"
            />
            <path
              fill="#1EBEE4"
              d="M552.25,257.37c0,0-0.21,0.27-1.82,0.66c-0.91,0.22-2.09,0.3-3.36-0.07c-1.1-0.32-1.57-1.17-1.77-1.8
				c-0.71-0.31-1.32-0.67-1.83-1.02c-0.24,0.08-0.52,0.2-0.86,0.37c0,0-0.83,0.29-3.91,0.38c-2.11,0.06-5.27-0.55-5.18,1.26
				c0.01,0.15,0.04,0.32,0.09,0.51c0,0,0.45,1.84,6.36,2.58c5.91,0.74,8.27,0.86,10.08,0.93c1.82,0.07,2.82-0.22,3-0.96
				s-0.55-1.45-0.55-1.45l-0.2-1.05L552.25,257.37z"
            />
            <path
              fill="#659896"
              d="M554.95,185.31c0.7-0.01,1.21,0.03,1.56,0.08c0.32,0.04,0.61-0.17,0.68-0.48
				c0.12-0.52,0.21-1.29-0.06-1.95c-0.44-1.1-0.44-1.86-0.11-2.52c0.22-0.43-0.18-0.72-0.39-1.33c-0.11-0.32-0.17-0.73-0.05-1.3
				c0.33-1.64-0.42-0.96-0.19-2.26c0.38-2.14,0.66-8.08,1.02-10.44c0.05-0.31,0.32-0.54,0.63-0.53c0.88,0.01,1.34-0.24,1.48-0.33
				c0.03-0.02,0.05-0.03,0.05-0.03s0.18-3.68,0.09-6.21c0-0.04,0-0.08,0.01-0.12c0.03-0.18,0.05-0.33,0.05-0.46
				c0-1.31-2.19-8.55-4.13-9.06c-3.3-0.87-3.24-1.25-4.26-1.59c-0.33-0.11-0.67-0.22-1.01-0.34c-0.09-0.03-0.17-0.08-0.24-0.14
				c-0.18-0.16-0.29-0.33-0.3-0.5c-0.04-0.5-0.8-1.02-1.52-1.01c-0.57,0.01-1.19-0.07-2.01,0.27c-1.74,0.72-4.02,0.89-4.73,2.16
				c-0.03,0.06-0.08,0.49-0.11,0.55c-0.01,0.03-0.04,0.1-0.05,0.14c-0.11,0.34-0.08,0.4-0.15,0.67c-0.02,0.07-0.03,0.22-0.05,0.35
				c-0.02,0.16-0.09,0.3-0.21,0.4c-0.12,0.1-0.24,0.21-0.35,0.31c-0.05,0.04-0.1,0.08-0.16,0.1c-2.66,1.17-6.24,2.74-7.02,5.47
				c-1.28,4.47-2.03,5.93-2.71,8.02c-0.22,0.68-0.17,1.28,0.05,1.81c0.6,1.44,2.46,2.39,3.82,2.99c0.02,0.01,0.04,0.02,0.06,0.02
				c1.39,0.48,2.38,0.15,3.19-0.67c0.07-0.08,0.15-0.15,0.22-0.24c-0.23,1.83-1.05,8.69-1.05,10.96c0,2.41,0.07,5.9,0.1,9.14
				c0,0.42,0.42,0.72,0.82,0.59c6.42-2.07,16.38-2.57,16.38-2.57"
            />
            <path
              fill="#4D2C1A"
              d="M554.29,185.09c0,0-10.85,0.48-17.2,2.78c0,0,0,0.05-0.01,0.15l0.01-0.15c0,0-0.52,0.35-0.45,3.36
				c0.06,3.01,0,4.1,0,4.1l0.04,0.03c-0.12,2.14-0.24,4.5-0.35,6.76c-0.33,6.68,2.3,21.14,2.96,25.63
				c0.66,4.49,3.08,17.78,2.65,21.51c-0.44,3.72-0.09,4.54-0.09,4.54s0.59,0.65,1.65,1.37c0.51,0.34,1.12,0.7,1.83,1.02
				c1.77,0.78,4.13,1.25,6.86,0.42c0.17-0.05,0.34-0.11,0.51-0.17c0,0,0-1.27-0.02-3.24c0-0.12,0-0.24,0-0.36
				c0.02,0.01,0.03,0.03,0.05,0.04c0.05,0.04,0.1,0.09,0.15,0.13c0.59,0.46,1.36,0.79,2.3,0.61c1.12-0.21,2.03-0.64,2.68-1.01
				c0.67-0.4,1.05-0.74,1.05-0.74c-0.55-5.15-4.49-32.43-4.49-32.43s0.44-11.17,1.75-19.28s0.88-14.9,0.88-14.9
				c-0.69-0.1-1.39-0.14-2.08-0.14C554.72,185.09,554.29,185.09,554.29,185.09z"
            />
            <g>
              <path
                fill="none"
                d="M539.61,138.86c0.15,0.82-0.21,1.93-0.21,1.93C539.89,140.32,539.61,138.86,539.61,138.86z"
              />
              <path
                fill="#D4BDAF"
                d="M548.02,142.16c-1.68-0.98-4.36,0.36-4.36,0.36s-2.96-1.21-3.02-3.56c-0.06-2.33-1.62-2.5-2.15-1.71
					c-0.44,0.66-0.25-0.26-0.63-0.51c-0.88-0.57-1.63-1.03-1.85-1.03c-0.09,0.92-0.02,1.86,0.24,2.79c0.05,0.18,0.07,0.37,0.07,0.54
					c0,0.22-0.01,0.53,0.01,0.67c0.02,0.17,0.07,0.33,0.18,0.48c0.36,0.45-0.11,1.24,0.41,2.48c0.28,0.66,0.41,1.29,0.48,1.77
					c0.02-0.2,4.09,3.22,4.08,2.98c0.03-0.01,0.06-0.03,0.09-0.04c0.72-1.27,3.1-1.33,4.84-2.05c0.82-0.34,1.55-0.51,2.12-0.52
					C548.12,143.96,547.98,143.06,548.02,142.16z M539.61,138.86c0,0,0.28,1.46-0.21,1.93C539.4,140.79,539.76,139.68,539.61,138.86
					z"
              />
            </g>
            <path
              fill="#4D2C1A"
              d="M539.4,140.79c0.49-0.48,0.21-1.93,0.21-1.93C539.76,139.68,539.4,140.79,539.4,140.79z"
            />
            <path
              fill="#5E5D5B"
              d="M518.38,185.89c0-0.94,0.49-1.71,1.08-1.71h-0.75c-0.6,0-1.08,0.76-1.08,1.71c0,0.94,0.49,1.71,1.08,1.71
				h0.41h0.34C518.86,187.6,518.38,186.83,518.38,185.89z"
            />
            <path
              fill="#5E5D5B"
              d="M519.36,184.18c-0.6,0-1.08,0.76-1.08,1.71c0,0.94,0.49,1.71,1.08,1.71s1.08-0.76,1.08-1.71
				c0-0.73-0.29-1.36-0.71-1.6C519.62,184.22,519.49,184.18,519.36,184.18z M519.4,187.52c-0.79,0.27-0.66-1.71-0.66-1.71l1.03,0.52
				C519.77,186.33,519.89,187.35,519.4,187.52z"
            />
            <path
              fill="#191C1C"
              d="M518.74,185.81c0,0-0.13,1.98,0.66,1.71c0.49-0.17,0.37-1.19,0.37-1.19L518.74,185.81z"
            />
          </g>
          <path
            opacity="0.3"
            d="M514.2,182.76c-0.35,0.07-0.8,0.73-0.81,0.75c0,0,0,0,0,0c0.06,0.09,0.06,0.1,0.15,0.22c0,0,0,0,0,0
			C513.54,183.73,513.75,183.18,514.2,182.76z"
          />
          <path
            opacity="0.3"
            d="M555.25,254.83c-1,0.25-2.03-1.25-2.4-1.85c-0.05-0.04-0.1-0.08-0.15-0.13l-0.05,0.32
			c0.01,1.97,0.02,3.24,0.02,3.24c-0.17,0.06-0.34,0.12-0.51,0.17c0,0.03,0.01,0.06,0.01,0.09c0.17,0.07,1.31,0.59,2.3,0.95
			c0.95,0.21,1.79,0.33,2.22,0.25c0.87-0.16,1.3-0.59,1.5-0.86c0.06-0.17,0.09-0.35,0.09-0.52c0.03-0.62-0.21-1.22-0.31-1.54
			c-0.08-0.26-0.13-0.5-0.16-0.69c-0.04-0.26-0.04-0.43-0.04-0.43S556.49,254.52,555.25,254.83z"
          />
          <path
            opacity="0.3"
            d="M552.25,257.37c0,0-1.83,1-3.79,0.74c-1.28-0.16-2.23-1.13-2.73-1.78c-0.84-0.33-1.57-0.74-2.16-1.13
			c-0.54,0.4-2.14,1.49-4.15,1.69c-2.42,0.25-5.73,0.05-5.88,0.04c0,0-0.01,0-0.01,0s-0.04,0.12-0.06,0.31
			c-0.06,0.61,0.07,1.96,2.23,2.88c0.3,0.13,0.6,0.24,0.9,0.33c2.21,0.72,4.49,0.76,6.44,1.13c2.22,0.42,6.4,0.56,7.48,0.43
			c1.08-0.13,2.53,0,2.63-1.22s-0.17-1.52-0.54-2.1c-0.08-0.13-0.15-0.33-0.21-0.53C552.31,257.79,552.25,257.37,552.25,257.37z"
          />
          <path
            opacity="0.3"
            d="M539.59,171.19c2.72-2.76,5.53-6.07,3.93-5.23c-1.61,0.84-6.06,6.42-4.17,2.28
			c1.89-4.14,6.15-6.97,3.8-5.66c-2.35,1.31-4.07,4.46-4.07,4.46s0.75-2.67,1.92-5.9c1.17-3.23,1.27-5.76-0.1-2.19
			c-0.87,2.27-1.61,3.85-2.04,4.69c0.51-2.49,1.12-5.58,0.96-5.08c-0.27,0.82-1.09,3.23-1.02,2.18c0.07-1.06-0.34-5.24-0.34-4.22
			s-0.53,2.33-1.49,4.77c-1.24,3.16-2.05,4.9-3.08,6.43c-0.25-0.12-0.55-0.29-0.81-0.43c-0.57,0.87-1.12,1.9-1.8,3.44
			c-0.8,2.08-12.27,10.2-13.22,10.44c-0.95,0.24-0.93,0.48-3.11,1.43c-0.96,0.41-1.37,1.57-1.55,2.01
			c-0.06,0.16-0.11,0.34-0.15,0.55c-0.07,0.39-0.08,0.88-0.08,1.4c0,0.52,0.01,0.94,0.41,1.56c0.13,0.2,0.15,0.38,0.42,0.43
			c0.18,0.03,0.62-0.45,0.62-0.45c0.41,0.44,0.49,0.53,0.71,0.62c0.23,0.1,0.87,0.03,0.87,0.03s0.44,0.29,0.78,0.18
			c0.34-0.1,0.73-0.45,0.73-0.45s0.37,0.14,0.81-0.13c0.27-0.17,0.53-0.75,0.53-0.75s-0.18,0.03-0.42,0.01
			c-0.4-0.04-0.89-0.53-0.96-1.43c-0.11-1.46,0.7-1.96,0.95-1.97c0.32-0.01,0.66-0.01,0.8-0.01c0.04,0,0.08,0.01,0.12,0.02
			l0.23,0.09c0,0,0.33-0.27,0.6-0.41c0.17-0.09,0.21-0.1,1.33-0.61c0.43-0.2,0.92-0.48,1.54-0.8c0.07-0.04,0.14-0.07,0.21-0.11
			c1.22-0.63,3.6-2,5.29-3.05c0.11-0.07,0.22-0.13,0.32-0.2c0.36-0.23,0.66-0.43,0.91-0.6c0.38-0.26,0.65-0.47,0.88-0.66
			c0.29-0.24,0.5-0.42,0.77-0.62c0.87-0.63,1.84-1.82,2.77-3.12c0.22-0.3,0.41-0.57,0.53-0.8c0.14-0.28,0.29-0.57,0.45-0.87
			c0.33-0.64,0.67-1.3,0.97-1.91c0.22-0.43,0.41-0.84,0.57-1.18c0.15-0.34,0.34-0.76,0.51-1.18c0.13-0.32,0.14-0.32,0.25-0.59
			c0,0,0.23-0.22,0.23-0.23c0.06-0.07,0.12-0.14,0.17-0.2c-0.16,1.41-0.43,2.98-0.59,4.8c-0.15,1.72-0.36,3.48-0.44,5.37
			c-0.04,0.88,0.01,1.86-0.01,2.74c-0.04,1.66-0.03,3.31,0.03,4.89c0.02,0.37,0.03,0.69,0.04,0.97
			C537.39,193.03,536.97,173.84,539.59,171.19z"
          />
          <path opacity="0.3" d="M540.79,178.52C540.17,178.96,540.08,179.13,540.79,178.52L540.79,178.52z" />
          <path
            opacity="0.3"
            d="M553.57,180.04c-3.07-0.66-8.54-1.75-11.94,0.99c0,0,7.56-1.97,11.17-0.11c3.4,1.75,3.96,1.16,4.02,1.08
			c0-0.01,0,0,0,0s0.03-0.11,0.02-0.15c-0.02-0.08-0.02-0.22-0.03-0.41c-0.02-0.3,0.04-0.61,0.28-1.12
			c0.03-0.06,0.02-0.36-0.07-0.43C556.87,179.78,555.93,180.55,553.57,180.04z"
          />
          <path
            opacity="0.3"
            d="M554.97,185.09l-0.03,0l-0.64,0c0,0-6.28,0.22-12.1,1.42c-1.74,0.36-3.63,0.78-5.11,1.35
			c0,0,7.57,0.37,10.27,1.21c3.63,1.14,4.52,6.58,4.73,10.43c-0.07,0.41-0.15,0.86-0.23,1.36c-0.4,2.43-0.97,9.89-1.6,11.83
			c-0.45,1.39-0.66,2.53-0.72,3.46c-0.21,0.83-1.01,3.9-1.8,6.37c-0.93,2.88,0.93-0.93,1.76-1.76c0.83-0.83,0.74,2.13,1.02,4.54
			c0.28,2.41,1.39,3.62,1.39,3.62s0-0.17-0.01-0.45c0,0,0,0,0,0.01c0.49,3.52,0.44,13.92,0.58,16.54c0.15,2.61,0.26,7.8,0.26,7.8
			s0.07,0.14,0.24,0.31c0.46,0.47,1.55,0.96,4.08-0.06c0.57-0.23,1.14-0.73,1.83-1.23c0,0-0.59-4.58-1.36-10.4
			c-1.13-8.48-2.62-19.43-3.11-21.96c-0.04-0.19,0.02-1.22,0.13-2.7c0.31-3.92,0.66-11.08,1.29-14.43
			c0.94-5.03,1.55-13.46,1.19-17.13l-1.41-0.13l-0.25-0.01L554.97,185.09z"
          />
          <path
            opacity="0.3"
            d="M538.62,193.76c-1.46-3.59-1.55-5.37-1.54-5.86c0-0.01,0-0.03,0-0.04c0,0.01,0,0.03,0,0.04
			C536.71,193.42,540.37,198.05,538.62,193.76z"
          />
          <path
            opacity="0.3"
            d="M559.73,157.43c0-0.13-0.01-0.14-0.01-0.14c0,0,0.01-0.13,0-0.1c-0.02-0.32-0.08-0.45-0.15-0.77
			c-0.26-1.33-0.8-2.81-1.31-4.12c-0.34-0.86-0.62-1.42-0.7-1.57c-0.28-0.52-0.19,2.01-0.08,5c0.3,8.28-7.04,10.5-4.61,18.95
			c0.1,0.36,0.08,0.63-0.03,0.83c-2.62-0.51-7.3-1.5-11.08,2.08c-0.46,0.43-0.8,0.74-1.05,0.96c1.22-0.85,4.47-2.69,7.95-1.73
			c5.92,1.64,8.1,2.63,8.1,2.63s-0.06-0.06-0.12-0.32c-0.01-0.06-0.09-0.25-0.1-0.34c0.04-0.08,0.17-0.26,0.39-0.76
			c0.15-0.34,0.39-0.82,0.59-1.28c0.78-1.8,1.83-4.49,1.84-5.42c0.02-1.53,0.05-4.13,0.13-7.06c0-0.02,0.06-0.05,0.06-0.05
			c0-0.04,0.02-0.29,0.02-0.33c0.05-2.13,0.15-4.04,0.1-5.32c0-0.1-0.02-0.53-0.02-0.62C559.71,157.68,559.73,157.7,559.73,157.43z"
          />
          <polygon opacity="0.3" points="559.71,157.18 559.71,157.18 559.71,157.18 		" />
          <path
            fill="#4D2C1A"
            d="M539.37,138.97c-0.09-0.21-0.18-0.32-0.41-0.56c-0.23-0.24-0.18-0.62-0.03-0.72
			c0.22-0.15,0.59,0.48,0.59,0.48c-0.13-0.3-0.29-0.73-0.57-0.75c-0.28-0.02-0.34,0.27-0.37,0.46c-0.03,0.18,0.03,0.92,0.25,1.12
			c0.22,0.21,0.33-0.13,0.41,0.07s0.15,0.36,0.15,0.36S539.46,139.19,539.37,138.97z"
          />
          <path
            fill="#4D2C1A"
            d="M538.73,139.19C538.73,139.19,538.64,139.03,538.73,139.19c0.1,0.16,0.24,0.44,0.2,0.8
			c-0.04,0.36,0.11,0.51,0.17,0.29s0.15-0.77,0.05-0.88C539.05,139.3,538.91,139.43,538.73,139.19z"
          />
          <path
            fill="#4D2C1A"
            d="M536.44,139.85c0.25,0.13,0.45,0.3,0.36-0.05c-0.07-0.3-0.38-0.64-0.47-0.74
			c-0.02-0.02-0.03-0.03-0.03-0.03c-0.09,0.23-0.04,0.54,0.04,0.71C536.38,139.79,536.41,139.84,536.44,139.85z"
          />
          <path
            fill="#4D2C1A"
            d="M541.52,147.57c0-0.04-0.01-0.09-0.01-0.13c-0.02-0.14-0.07-0.27-0.13-0.36
			c-0.73-1.09-1.43-3.59-1.98-4.51s-0.97-2.78-0.9-3.26c0.07-0.49-0.43-1.38,0.01-2.04c0.53-0.79,2.08-0.62,2.15,1.71
			c0.06,2.34,3.02,3.56,3.02,3.56s2.67-1.34,4.36-0.36c0,0,0,0,0,0c0,0,1.42-5.51,1.85-6.82c0.43-1.32,0.27-3.98,0.27-3.98
			s-0.2,0.87-0.38,1.31c-0.18,0.44-0.35-2.38-0.35-2.38s-0.02,0.38-0.41,0.94s-0.27-0.08-2.4-1.3c-1.4-0.8-2.92-1.27-4.3-1.38
			c-0.72-0.06-1.4-0.02-2,0.12c0,0,1.42,0.1,2.19,0.25c-0.24-0.04-2.65-0.37-5.79,2.77c0,0-1.15,1.59-1.8,3.57
			c0,0,0.56-0.95,1.02-1.31c0,0-0.51,1.64-0.51,2.98c0,0,0.31-0.83,0.55-1.17c0.02-0.03,0.04-0.04,0.06-0.04
			c0.23-0.01,0.51,1.55,1.39,2.12c0.96,0.62-0.27,2.59-0.32,3.34c-0.04,0.72,0.42,2.29,0.34,3.28c0,0.04-0.01,0.08-0.01,0.12
			c-0.14,0.97,0.31,1.26,0.66,2.19c0.34,0.93,0.32,3.03,1.87,2.63c0.61-0.16,1.01-0.47,1.26-0.81c0.07-0.27,0.16-0.58,0.28-0.92
			C541.49,147.63,541.5,147.6,541.52,147.57z"
          />
          <path
            opacity="0.3"
            d="M539.37,130.87c0,0,1.9-0.15,3.64,0.09c0.02,0,0.03,0,0.05,0.01c-0.87-0.02-2.92,0.14-4.85,1.48
			c0,0,2.5,1.33,2.47,3.29c-0.03,1.96,4.01,5.23,4.61,6.13c0.02,0.03,0.03,0.05,0.05,0.08c0.56,0.87,0.71,1.58,0.71,1.58
			s0.1,0.93-0.54,2.13c-0.46,0.87-1.31,1.87-2.86,2.77c-0.49,0.29,3.58-1.58,7.56-1.56c1.63,0.01,2.83,0.66,2.83,0.66
			c-0.67-0.27-1.11-0.61-1.94-0.84c-1.08-0.3-1.15-0.5-1.37-1.05c0,0-0.11-0.57-1.01-0.82c-0.08-0.02-0.15-0.04-0.19-0.08
			c-0.02-0.02-0.04-0.05-0.05-0.08c-0.01-0.03-0.02-0.05-0.02-0.08c-0.13-0.43-0.31-1.01-0.42-1.78c-0.03-0.2,0.01-0.41,0-0.64
			c0,0,0.03-0.02,0.04-0.08c0.03-0.3,0.28-1.03,0.52-1.95c0.07-0.28,0.17-0.74,0.29-1.19c0.38-1.42,0.89-3.12,1.13-4.11
			c0.29-1.2,0.14-3.47,0.14-3.47l-0.36,1.23c-0.04,0.18-0.11,0.13-0.12,0.04l-0.24-2.33c0,0,0.02,0.05,0.01,0.11
			c-0.05,0.2-0.11,0.48-0.41,0.83c-0.14,0.17-0.11,0.44-0.62-0.05c-0.48-0.45-1.41-0.82-1.85-0.98c-0.14-0.05-0.28-0.12-0.42-0.19
			C545.53,129.73,543.44,128.99,539.37,130.87z"
          />
          <linearGradient
            id="Card22SVGID56"
            gradientUnits="userSpaceOnUse"
            x1="517.6118"
            y1="185.8953"
            x2="519.4454"
            y2="185.8953"
          >
            <stop offset="0.2138" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            <stop offset="1" style={{ stopColor: "#1C1C1B", stopOpacity: 0.3 }} />
          </linearGradient>
          <path
            fill="url(#Card22SVGID56)"
            d="M518.36,185.9c0-0.94,0.49-1.71,1.08-1.71h-0.75c-0.6,0-1.08,0.76-1.08,1.71s0.49,1.71,1.08,1.71
			h0.41h0.34C518.85,187.6,518.36,186.84,518.36,185.9z"
          />
          <g>
            <path
              fill="none"
              d="M481.91,268.76c0.01,0.02,0.03,0.07,0.08,0.12c-0.02-0.09-0.04-0.18-0.04-0.27
				C481.92,268.69,481.91,268.74,481.91,268.76z"
            />
            <path
              opacity="0.3"
              d="M529.35,281.5c0.18-0.45-0.08-1.01-0.77-1.67c-3.69-3.53-12.28-4.77-21.5-5.83
				c-2.75-0.32-5.69-0.58-8.53-0.84c-1.97-0.18-3.76-0.35-5.38-0.51c5.49,0.71,17.48,2.34,24.49,3.87c4.93,1.08,12,3.79,10.95,5.8
				C529.08,281.98,529.27,281.69,529.35,281.5z"
            />
            <path
              opacity="0.3"
              d="M500.37,262.39c-6.84,1.34-14.59,2.86-17.94,5.69c-0.28,0.24-0.42,0.42-0.48,0.53
				c0,0.09,0.01,0.18,0.04,0.27c0.22,0.25,1,0.78,3.87,1.33c-1.3-1.8,2.09-4.06,8.66-5.78c7.92-2.07,22.11-4.96,26.06-5.65
				c5.28-0.93,2.01-1.8-1.23-1.17c-1.5,0.29-3.32,0.57-5.49,1c-1.61,0.78-3.45,1.47-5.55,2.05
				C506.11,261.26,503.32,261.81,500.37,262.39z"
            />
            <path
              opacity="0.3"
              d="M461.19,300.31c-0.03,0.07-2.49-1.17-2.67-1.32c-0.18-0.15-0.39-0.3-0.61-0.44
				c-0.44-0.29-0.93-0.55-1.44-0.79c-1.02-0.49-2.1-0.91-3.19-1.29c-2.2-0.75-4.47-1.38-6.74-1.82c-1.78-0.34-3.57-0.71-5.35-1.15
				c4.6,1.71,13.23,5.12,14.93,7.22c0.14-0.04,1.06,2.16,0.77,2.24c-0.61,0.16-1.21,0.29-1.82,0.41c-2.42,0.46-4.82,0.72-7.22,0.92
				c-2.4,0.2-4.8,0.34-7.19,0.43c-4.79,0.2-9.58,0.25-14.35,0.26l-14.3,0.06l-14.3,0.06l-1.79,0.01l-0.89,0
				c-0.31,0-0.62,0-0.93-0.01c-1.25-0.03-2.54-0.13-3.86-0.45c-0.66-0.16-1.32-0.38-1.96-0.69c-0.62-0.3-1.23-0.68-1.77-1.14
				c-5.82,0.72-8.58,2.79-2.22,3.71c8.79,1.28,49.78,1.81,70.3-0.38c6.07-0.65,7.84-2.11,8-3.66
				C462.68,301.36,462.02,300.6,461.19,300.31z"
            />
            <path opacity="0.3" d="M459.01,299.56C459.01,299.56,459.01,299.56,459.01,299.56L459.01,299.56z" />
            <path opacity="0.3" d="M434.56,288.83C434.56,288.83,434.56,288.83,434.56,288.83L434.56,288.83z" />
            <path
              opacity="0.3"
              d="M530.82,281.86c0,0-0.03,0.02-0.08,0.05c-0.01,0.04-0.03,0.08-0.05,0.12c-0.46,1.14-1.86,2.08-4.28,2.86
				c-1.88,0.61-4.4,1.12-7.48,1.54c-1.75,0.24-3.54,0.42-5.26,0.56c-0.75,0.06-1.51,0.13-2.26,0.18c-2.39,0.16-4.78,0.27-7.19,0.29
				l-7.15,0.03l-28.61,0.11l-28.61,0.11c-1.14,0-2.27,0.1-3.34,0.32c-0.53,0.11-1.05,0.27-1.47,0.46c-0.16,0.07-0.3,0.16-0.39,0.23
				c0.03,0.03,0.06,0.06,0.09,0.09c0.08,0.08,0.19,0.16,0.31,0.24c0.87-0.13,2.25-0.29,4.45-0.45c3.82-0.28,35.87,0.46,42.81,0.46
				s27.55,1.38,43.93-2.1C536.34,284.83,530.82,281.86,530.82,281.86z"
            />
            <path
              fill="#191C1C"
              d="M434.66,288.73c0.09-0.07,0.23-0.16,0.39-0.23c0.42-0.2,0.94-0.35,1.47-0.46
				c1.07-0.23,2.21-0.32,3.34-0.32l28.61-0.11l28.61-0.11l7.15-0.03c2.41-0.02,4.8-0.13,7.19-0.29c0.75-0.05,1.51-0.11,2.26-0.18
				c1.72-0.14,3.51-0.32,5.26-0.56c3.08-0.41,5.59-0.93,7.48-1.54c2.42-0.78,3.82-1.71,4.28-2.86c0.02-0.04,0.03-0.08,0.05-0.12
				c0.25-0.73,0.21-1.83-1.15-3.13c-4.02-3.84-12.86-5.14-22.33-6.22c-2.77-0.32-5.72-0.59-8.56-0.84c-4.39-0.4-8.54-0.78-11.69-1.3
				c-0.41-0.07-0.78-0.13-1.13-0.2c-2.87-0.56-3.66-1.08-3.87-1.33c-0.05-0.06-0.07-0.1-0.08-0.12c-0.01-0.02,0-0.07,0.04-0.15
				c0.06-0.12,0.19-0.29,0.48-0.53c3.35-2.83,11.1-4.35,17.94-5.69c2.95-0.58,5.74-1.13,7.95-1.74c2.1-0.58,3.93-1.27,5.55-2.05
				c11.81-5.69,11.51-16.28,11.24-25.76c-0.04-1.42-0.08-2.88-0.08-4.28c0-1.56-0.23-12.84-0.43-22.8
				c-0.15-7.64-0.29-14.25-0.29-14.94c0-2.13-0.57-3.71-1.7-4.7c-1.42-1.23-3.17-1.01-3.24-1c-0.39,0.05-0.67,0.42-0.61,0.81
				c0.05,0.39,0.42,0.67,0.81,0.62c0.01,0,1.2-0.14,2.11,0.67c0.79,0.7,1.19,1.91,1.19,3.59c0,0.71,0.13,7.32,0.29,14.97
				c0.2,9.95,0.43,21.22,0.43,22.77c0,1.42,0.04,2.89,0.08,4.32c0.15,5.35,0.31,10.88-1.62,15.57c-2.16,5.24-6.77,8.77-14.11,10.8
				c-2.15,0.6-4.92,1.14-7.84,1.71c-7.01,1.37-14.96,2.93-18.59,6c-0.87,0.74-1.2,1.49-0.97,2.22c0.57,1.81,4.39,2.6,12.64,3.45
				c1.62,0.17,3.41,0.34,5.38,0.51c2.84,0.26,5.77,0.52,8.53,0.84c9.22,1.06,17.8,2.3,21.5,5.83c0.69,0.66,0.95,1.22,0.77,1.67
				c-0.08,0.2-0.27,0.48-0.74,0.81c-1.1,0.79-3.7,1.85-9.87,2.69c-1.36,0.18-2.75,0.34-4.11,0.46c-1.1,0.1-2.21,0.18-3.31,0.25
				c-2.37,0.14-4.74,0.23-7.1,0.23l-7.15-0.03l-28.61-0.11l-28.61-0.11c-1.25-0.01-2.5,0.08-3.77,0.34c-0.63,0.13-1.27,0.3-1.93,0.6
				c-0.33,0.16-0.68,0.34-1.03,0.68c-0.17,0.17-0.36,0.39-0.49,0.7c-0.13,0.3-0.18,0.7-0.11,1.04c0.06,0.34,0.2,0.6,0.34,0.8
				c0.14,0.21,0.29,0.37,0.44,0.51c0.3,0.28,0.59,0.48,0.89,0.66c0.59,0.36,1.17,0.62,1.75,0.86c1.17,0.48,2.34,0.84,3.51,1.17
				c0.57,0.16,1.14,0.31,1.71,0.45c1.78,0.44,3.57,0.81,5.35,1.15c2.27,0.45,4.55,1.07,6.74,1.82c1.1,0.38,2.18,0.8,3.19,1.29
				c0.51,0.24,1,0.51,1.44,0.79c0.22,0.14,0.43,0.29,0.61,0.44c0.18,0.15,0.33,0.3,0.42,0.43c0.03,0.04,0.04,0.07,0.06,0.1
				c-0.01,0.01-0.02,0.02-0.03,0.04c-0.08,0.09-0.25,0.21-0.45,0.32c-0.2,0.11-0.43,0.21-0.67,0.31c-0.54,0.21-1.13,0.38-1.74,0.53
				c-0.49,0.12-0.99,0.22-1.49,0.32c-2.27,0.41-4.62,0.65-6.97,0.83c-2.35,0.18-4.72,0.29-7.09,0.37
				c-4.74,0.16-9.49,0.17-14.25,0.15l-14.3-0.06l-14.3-0.06l-1.79-0.01l-0.89,0c-0.29,0-0.57-0.01-0.86-0.01
				c-1.13-0.04-2.23-0.14-3.23-0.39c-1-0.25-1.87-0.67-2.57-1.28c-0.7-0.61-1.25-1.43-1.68-2.38c-0.42-0.95-0.72-2.01-0.95-3.11
				c-0.23-1.1-0.38-2.23-0.49-3.38c-0.22-2.3-0.28-4.64-0.27-7l0.06-14.3l0.11-28.61l0.06-14.3l0-0.9c0-0.31,0-0.63-0.01-0.95
				c-0.02-0.64-0.06-1.29-0.15-1.95c-0.09-0.66-0.21-1.34-0.43-2.05c-0.22-0.7-0.53-1.44-1.05-2.13c-0.51-0.69-1.24-1.3-2-1.66
				c-0.76-0.38-1.52-0.56-2.21-0.68c-0.7-0.11-1.36-0.16-2-0.19c-0.64-0.03-1.25-0.03-1.85-0.03l-0.01,0c-1,0-1.81,0.81-1.8,1.81
				c0,1,0.81,1.81,1.81,1.8h0c1.17,0,2.32,0,3.28,0.15c0.48,0.07,0.89,0.19,1.2,0.34c0.31,0.15,0.53,0.33,0.73,0.59
				c0.41,0.52,0.69,1.47,0.82,2.51c0.07,0.52,0.11,1.06,0.13,1.61c0.01,0.28,0.02,0.56,0.02,0.84l0,0.89l0.06,14.3l0.11,28.61
				l0.06,14.31c0.02,2.41,0.09,4.84,0.34,7.29c0.13,1.22,0.3,2.45,0.57,3.69c0.27,1.23,0.62,2.48,1.17,3.71
				c0.28,0.61,0.61,1.22,1.02,1.79c0.4,0.57,0.89,1.12,1.44,1.59c0.01,0.01,0.03,0.02,0.05,0.04c0.54,0.46,1.14,0.84,1.77,1.14
				c0.64,0.31,1.31,0.53,1.96,0.69c1.32,0.32,2.6,0.42,3.86,0.45c0.31,0.01,0.63,0.01,0.93,0.01l0.89,0l1.79-0.01l14.3-0.06
				l14.3-0.06c4.77-0.01,9.56-0.06,14.35-0.26c2.39-0.1,4.79-0.23,7.19-0.43c2.4-0.21,4.8-0.46,7.22-0.92
				c0.6-0.12,1.21-0.25,1.82-0.41c0.61-0.16,1.22-0.34,1.84-0.6c0.31-0.13,0.63-0.27,0.95-0.45c0.32-0.18,0.65-0.4,0.98-0.74
				c0.16-0.17,0.33-0.38,0.46-0.65c0.03-0.06,0.06-0.13,0.09-0.2c0.08-0.22,0.14-0.47,0.14-0.72c0.01-0.33-0.06-0.63-0.16-0.89
				c-0.1-0.26-0.23-0.47-0.36-0.65c-0.26-0.36-0.55-0.62-0.82-0.85c-0.28-0.23-0.56-0.42-0.84-0.6c-0.56-0.36-1.13-0.65-1.7-0.92
				c-1.14-0.53-2.29-0.97-3.44-1.36c-2.32-0.77-4.66-1.4-7.07-1.85c-2.33-0.43-4.64-0.9-6.9-1.5c-1.12-0.31-2.24-0.65-3.28-1.06
				c-0.52-0.21-1.02-0.44-1.46-0.7c-0.08-0.05-0.16-0.1-0.24-0.15c-0.12-0.08-0.23-0.16-0.31-0.24
				C434.72,288.79,434.69,288.76,434.66,288.73z M459.01,299.56C459.01,299.56,459.01,299.56,459.01,299.56L459.01,299.56z
				 M434.56,288.83C434.56,288.83,434.56,288.83,434.56,288.83L434.56,288.83z"
            />
          </g>
          <g>
            <path
              fill="none"
              d="M362.82,142.6c1.49-0.39,12.09-3.52,12.09-3.52s-18.92-2.3-19.49-2.39c-0.57-0.09-1.81,0.07-1.68,1
				c0.04,0.3,0.15,0.97,0.26,1.71l0.01,0l0.42,2.7c1.19,0.18,2.22,0.31,3.1,0.41C359.58,142.72,361.89,142.84,362.82,142.6z"
            />
            <path
              fill="none"
              d="M333.65,141.12l1.87,1.85l2.59,2.57c0,0,2.12-0.67,3.31-1.03c1.19-0.35,1.9-0.54,1.9-1.41
				c0-0.1-0.02-0.25-0.07-0.44L333.65,141.12z"
            />
            <path
              fill="none"
              d="M341.38,137.06c-1.93,0.47-6.08,1.35-8.27,1.81c0,0,4.52,0.67,9.41,1.41
				C341.99,138.69,341.38,137.06,341.38,137.06z"
            />
            <polygon fill="none" points="331.69,139.17 331.69,139.17 331.69,139.17 			" />
            <path
              fill="#1EBEE4"
              d="M384.97,135.28c-2.55-0.41-35.76-5.47-40.12-5.65c-4.35-0.17-7.16,1.09-8.47,1.51
				c-1.31,0.42-12.94,3.66-13.82,4.11c-1.31,0.67-1.8,2.14-1.9,2.74c0,0,0,0,0,0c0.01-0.13,0.14-0.25,0.38-0.37
				c1.32-0.65,11.46-3.33,13.58-4.02c2.12-0.7,4.58-2.13,9.97-1.82c5.39,0.3,37.93,4.84,40.74,5.32c1.68,0.29,2.23,0.67,2.14,1.04
				c0,0,0.01,0.01,0.01,0.01C387.49,138.15,387.92,135.75,384.97,135.28z"
            />
            <path
              opacity="0.3"
              d="M342.84,311.18l-1.32-0.13c-0.23-0.01-0.45-0.03-0.68-0.06l-7.41-0.71c-1.77-0.17-3.1-1.29-3.1-2.62v-0.95
				c-15.77,2.2-31.67,4.42-32.49,4.51c-1.65,0.19-4.76,0.61-2.15,2.18c3.03,1.82,8.76,5.15,10.51,5.92
				c1.74,0.77,2.84,0.88,4.56,0.59c11.17-1.93,53.68-9.01,61.94-10.61l-29.1,1.89C343.35,311.21,343.09,311.21,342.84,311.18z"
            />
            <path
              fill="#B0B0B0"
              d="M375.4,308.54c0.46-0.24,0.69-0.51,0.75-0.77C375.96,308.05,375.7,308.32,375.4,308.54z"
            />
            <path
              fill="#5E5D5B"
              d="M385.34,137.1c-2.81-0.48-35.35-5.01-40.74-5.32c-5.39-0.3-7.85,1.13-9.97,1.82
				c-2.12,0.7-12.26,3.37-13.58,4.02c-0.24,0.12-0.36,0.24-0.38,0.37c-0.06,0.57,2.15,1.27,7.47,2.18
				c1.41,1.46,8.5,10.39,10.5,12.91l2.66-0.27c0.67-0.08,1.74-0.08,1.91-0.12l0.58,0.04c0.22,0,0.42,0.01,0.57,0.03l11.82,0.84
				l0.01,0.01l0.01,0l0,0l0-0.01l0.04,0l-1.52-9.58c0,0,0.24,0.03,0.64,0.08c0.68,0.09,1.94,0.26,3.88,0.58
				c1.05,0.17,4.15,0.64,5.91,0.39c1.7-0.24,4.43-0.94,6.84-1.67c2.41-0.74,13.41-4.04,14.61-4.56c0.48-0.21,0.82-0.45,0.88-0.7
				C387.57,137.77,387.03,137.39,385.34,137.1z M343.12,142.17L343.12,142.17l-9.9-1.47l-0.04-0.01l-1.5-1.52l0,0c0,0,0,0,0.01,0
				l1.42-0.3l0,0c2.19-0.46,6.34-1.34,8.27-1.81c0,0,0.61,1.63,1.14,3.22c0.02,0,0.04,0.01,0.06,0.01L343.12,142.17z M341.41,144.51
				c-1.19,0.35-3.31,1.03-3.31,1.03l-2.59-2.57l-1.87-1.85l9.59,1.55c0.04,0.19,0.07,0.34,0.07,0.44
				C343.31,143.98,342.59,144.16,341.41,144.51z M376.85,140.21c-0.92,0.48-7.33,2.17-11.4,3.43c-4.07,1.26-5.27,0.87-7.33,0.61
				c-0.42-0.05-1.68-0.22-3.44-0.47l-0.26-1.68c0.71,0.13,1.86,0.29,3.1,0.41c-0.88-0.1-1.91-0.23-3.1-0.41l-0.42-2.7l-0.01,0
				c-0.11-0.74-0.22-1.41-0.26-1.71c-0.13-0.93,1.11-1.09,1.68-1c0.57,0.09,19.49,2.39,19.49,2.39
				C376.63,139.38,377.77,139.73,376.85,140.21z"
            />
            <path
              fill="#1EBEE4"
              d="M343.81,152.74l-0.58-0.04c-0.17,0.04-1.25,0.04-1.91,0.12C342.13,152.81,343.1,152.74,343.81,152.74z"
            />
            <path
              fill="#5E5D5B"
              d="M356.2,153.61l-11.82-0.84c-0.15-0.02-0.35-0.03-0.57-0.03L356.2,153.61L356.2,153.61z"
            />

            <rect
              x="356.23"
              y="153.59"
              transform="matrix(0.0703 -0.9975 0.9975 0.0703 177.9366 498.1581)"
              fill="#5E5D5B"
              width="0.01"
              height="0.04"
            />
            <path
              fill="#1EBEE4"
              d="M356.21,153.62L356.21,153.62L356.21,153.62l-12.4-0.88c-0.7,0-1.68,0.07-2.5,0.08l-2.66,0.27l-6.01,0.6
				l7.53-0.73c1.29-0.11,1.92-0.11,2.48,0.14c1.4,0.62,1.52,1.43,1.52,3.46s-0.08,150.36-0.08,151.47c0,2.71-1.23,3.09-2.57,3.03
				l1.32,0.13c0.25,0.02,0.51,0.03,0.76,0.01l29.1-1.89l0.54-0.04c0.84-0.05,1.59-0.33,2.16-0.73c0.31-0.22,0.56-0.48,0.75-0.77
				c0.22-0.34,0.34-0.72,0.34-1.12v-149.2c0-1.27-1.21-2.36-2.89-2.59l-17.34-1.22L356.21,153.62z"
            />
            <linearGradient
              id="Card22SVGID57"
              gradientUnits="userSpaceOnUse"
              x1="330.3319"
              y1="231.9561"
              x2="376.4833"
              y2="231.9561"
            >
              <stop offset="0.1019" style={{ stopColor: "#000000", stopOpacity: 0 }} />
              <stop offset="0.2683" style={{ stopColor: "#222221", stopOpacity: 0.2268 }} />
              <stop offset="0.3384" style={{ stopColor: "#161616", stopOpacity: 0.2038 }} />
              <stop offset="0.4571" style={{ stopColor: "#090909", stopOpacity: 0.1648 }} />
              <stop offset="0.6143" style={{ stopColor: "#020202", stopOpacity: 0.1132 }} />
              <stop offset="0.9595" style={{ stopColor: "#000000", stopOpacity: 0 }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID57)"
              d="M356.21,153.62L356.21,153.62L356.21,153.62l-12.4-0.88c-0.79-0.08-1.65-0.02-2.5,0.08
				l-2.66,0.27l-6.01,0.6l7.53-0.73c1.29-0.11,1.88-0.18,2.44,0.07c1.4,0.62,1.56,1.51,1.56,3.53s-0.08,150.36-0.08,151.47
				c0,2.71-1.23,3.09-2.57,3.03l1.32,0.13c0.25,0.02,0.51,0.03,0.76,0.01l29.1-1.89l0.54-0.04c0.84-0.05,1.59-0.33,2.16-0.73
				c0.31-0.22,0.56-0.48,0.75-0.77c0.22-0.34,0.34-0.72,0.34-1.12v-149.2c0-1.27-1.21-2.36-2.89-2.59l-17.34-1.22L356.21,153.62z"
            />
            <path fill="#B0B0B0" d="M340.84,311c0.23,0.03,0.45,0.05,0.68,0.06l-8.09-0.78L340.84,311z" />
            <path
              fill="#1EBEE4"
              d="M333.43,310.28l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				s-0.12-2.84-1.52-3.46c-0.56-0.25-1.19-0.25-2.48-0.14l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C330.33,308.99,331.66,310.11,333.43,310.28z"
            />
            <linearGradient
              id="Card22SVGID58"
              gradientUnits="userSpaceOnUse"
              x1="330.3319"
              y1="231.9762"
              x2="376.4833"
              y2="231.9762"
            >
              <stop offset="0.1019" style={{ stopColor: "#FFFFFF", stopOpacity: 0.1 }} />
              <stop offset="0.2683" style={{ stopColor: "#222221", stopOpacity: 0.2268 }} />
              <stop offset="0.2761" style={{ stopColor: "#2C2C2B", stopOpacity: 0.2355 }} />
              <stop offset="0.3148" style={{ stopColor: "#585857", stopOpacity: 0.2788 }} />
              <stop offset="0.3575" style={{ stopColor: "#80807F", stopOpacity: 0.3266 }} />
              <stop offset="0.4033" style={{ stopColor: "#A2A2A2", stopOpacity: 0.3778 }} />
              <stop offset="0.4531" style={{ stopColor: "#BFBFBF", stopOpacity: 0.4335 }} />
              <stop offset="0.5081" style={{ stopColor: "#D7D7D7", stopOpacity: 0.4951 }} />
              <stop offset="0.5707" style={{ stopColor: "#E9E9E9", stopOpacity: 0.565 }} />
              <stop offset="0.6452" style={{ stopColor: "#F6F6F5", stopOpacity: 0.6484 }} />
              <stop offset="0.7436" style={{ stopColor: "#FDFDFD", stopOpacity: 0.7585 }} />
              <stop offset="0.9595" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID58)"
              d="M333.43,310.28l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				s-0.12-2.84-1.52-3.46c-0.56-0.25-1.19-0.25-2.48-0.14l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C330.33,308.99,331.66,310.11,333.43,310.28z"
            />
            <path
              opacity="0.3"
              d="M333.43,310.28l8.09,0.78c1.33,0.07,2.57-0.32,2.57-3.03c0-1.11,0.08-149.45,0.08-151.47
				s-0.05-2.91-1.46-3.53c-0.56-0.25-1.26-0.18-2.55-0.07l-7.53,0.73c-1.39,0.39-2.31,1.37-2.31,2.47v150.56v0.95
				C330.33,308.99,331.66,310.11,333.43,310.28z"
            />
            <linearGradient
              id="Card22SVGID59"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="140.9199"
              x2="377.1792"
              y2="140.9199"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID59)"
              d="M363.83,142.35c0.97-0.22,11.08-3.27,11.08-3.27s-10.6,3.13-12.09,3.52
				c-0.93,0.24-3.23,0.13-5.3-0.08C362.43,143.07,363,142.53,363.83,142.35z"
            />
            <linearGradient
              id="Card22SVGID60"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="140.5213"
              x2="377.1792"
              y2="140.5213"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID60)"
              d="M333.11,138.87c-0.82,0.17-1.36,0.29-1.42,0.3l-0.01,0l1.54,1.53l9.89,1.47
				c-0.15-0.53-0.36-1.21-0.59-1.9C337.64,139.54,333.12,138.87,333.11,138.87z"
            />
            <polygon fill="#5E5D5B" points="333.18,140.69 333.23,140.7 331.69,139.17 			" />
            <linearGradient
              id="Card22SVGID61"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="139.935"
              x2="377.1792"
              y2="139.935"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <polygon fill="url(#Card22SVGID61)" points="333.18,140.69 333.23,140.7 331.69,139.17 			" />
            <path
              fill="#5E5D5B"
              d="M374.91,139.08c0,0-10.1,3.05-11.08,3.27c-0.82,0.18-1.4,0.72-6.31,0.17c-1.24-0.12-2.39-0.28-3.1-0.41
				l0.26,1.68c1.75,0.24,3.02,0.41,3.44,0.47c2.06,0.26,3.27,0.65,7.33-0.61c4.07-1.26,10.49-2.95,11.4-3.43
				C377.77,139.73,376.63,139.38,374.91,139.08z"
            />
            <linearGradient
              id="Card22SVGID62"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="141.7995"
              x2="377.1792"
              y2="141.7995"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID62)"
              d="M374.91,139.08c0,0-10.1,3.05-11.08,3.27c-0.82,0.18-1.4,0.72-6.31,0.17
				c-1.24-0.12-2.39-0.28-3.1-0.41l0.26,1.68c1.75,0.24,3.02,0.41,3.44,0.47c2.06,0.26,3.27,0.65,7.33-0.61
				c4.07-1.26,10.49-2.95,11.4-3.43C377.77,139.73,376.63,139.38,374.91,139.08z"
            />
            <path
              fill="#5E5D5B"
              d="M343.11,142.17L343.11,142.17l-0.54-1.89c-0.02,0-0.04-0.01-0.06-0.01
				C342.75,140.96,342.97,141.64,343.11,142.17z"
            />
            <linearGradient
              id="Card22SVGID63"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="141.2253"
              x2="377.1792"
              y2="141.2253"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID63)"
              d="M343.11,142.17L343.11,142.17l-0.54-1.89c-0.02,0-0.04-0.01-0.06-0.01
				C342.75,140.96,342.97,141.64,343.11,142.17z"
            />
            <path fill="#5E5D5B" d="M333.11,138.87l-1.42,0.3C331.75,139.16,332.3,139.04,333.11,138.87L333.11,138.87z" />
            <linearGradient
              id="Card22SVGID64"
              gradientUnits="userSpaceOnUse"
              x1="331.6863"
              y1="139.0192"
              x2="377.1792"
              y2="139.0192"
            >
              <stop offset="0" style={{ stopColor: "#FFFFFF", stopOpacity: 0.9 }} />
              <stop offset="0.0161" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9 }} />
              <stop offset="0.0416" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9 }} />
              <stop offset="0.0703" style={{ stopColor: "#939392", stopOpacity: 0.9 }} />
              <stop offset="0.1015" style={{ stopColor: "#727272", stopOpacity: 0.9 }} />
              <stop offset="0.136" style={{ stopColor: "#575756", stopOpacity: 0.9 }} />
              <stop offset="0.1749" style={{ stopColor: "#414140", stopOpacity: 0.9 }} />
              <stop offset="0.2203" style={{ stopColor: "#30302F", stopOpacity: 0.9 }} />
              <stop offset="0.2766" style={{ stopColor: "#252524", stopOpacity: 0.9 }} />
              <stop offset="0.3554" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9 }} />
              <stop offset="0.5743" style={{ stopColor: "#1C1C1B", stopOpacity: 0.9 }} />
              <stop offset="0.7365" style={{ stopColor: "#1E1E1D", stopOpacity: 0.9381 }} />
              <stop offset="0.795" style={{ stopColor: "#252524", stopOpacity: 0.9518 }} />
              <stop offset="0.8367" style={{ stopColor: "#30302F", stopOpacity: 0.9616 }} />
              <stop offset="0.8704" style={{ stopColor: "#414140", stopOpacity: 0.9695 }} />
              <stop offset="0.8992" style={{ stopColor: "#575756", stopOpacity: 0.9763 }} />
              <stop offset="0.9248" style={{ stopColor: "#727272", stopOpacity: 0.9823 }} />
              <stop offset="0.9479" style={{ stopColor: "#939392", stopOpacity: 0.9878 }} />
              <stop offset="0.9692" style={{ stopColor: "#B8B8B8", stopOpacity: 0.9928 }} />
              <stop offset="0.9881" style={{ stopColor: "#E1E1E1", stopOpacity: 0.9972 }} />
              <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
            </linearGradient>
            <path
              fill="url(#Card22SVGID64)"
              d="M333.11,138.87l-1.42,0.3C331.75,139.16,332.3,139.04,333.11,138.87L333.11,138.87z"
            />
          </g>
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text
          transform="matrix(1 0 0 1 197.4961 116.5576)"
          fontFamily={font.condensed}
          fontSize="68.3887px"
          letterSpacing="-1"
        >
          delivered
        </text>
        <text transform="matrix(1 0 0 1 90.3081 175.3672)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="13px">
            Mission accomplished.
          </tspan>
          <tspan x="129" y="0" fontFamily={font.condensed} fontSize="15.8124px">
            {" "}
            MINUS 53%.
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
