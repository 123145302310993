import { bp } from "#shared/theme";
import styled, { css } from "styled-components/macro";

export const Spacer = styled.div<{ size?: number; page?: string }>`
  ${({ size = 100, page }) => css`
    height: ${size}px;
    @media ${bp.m} {
      height: ${size * (page === "interview" ? 1.5 : 2.5)}px;
    }
    @media ${bp.t} {
      height: ${size * (page === "interview" ? 1.8 : 3.5)}px;
    }
    @media ${bp.d} {
      height: ${size * (page === "interview" ? 2 : 4)}px;
    }
  `}
`;
