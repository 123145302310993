import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { bp } from "#shared/theme";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { ChartContainer } from "../ChartContainer/ChartContainer";
import { InfoItemProps } from "../Info/Info";
import { KeyFiguresChartMobile } from "../KeyFiguresChartMobile/KeyFiguresChartMobile";
import { MultipleChart } from "../MultipleChart/MultipleChart";

export const KeyFiguresChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("KeyFiguresChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer
      title={labels.title}
      tooltipContent={labels.tooltip}
      list={(labels.info as unknown) as InfoItemProps[]}
    >
      {isDesktop ? <MultipleChart labels={labels} /> : <KeyFiguresChartMobile labels={labels} />}
    </ChartContainer>
  );
};
