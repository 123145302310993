import React, { FC, useEffect, useRef, useState } from "react";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";
import { Parallax, ParallaxAlign, ParallaxColor } from "./Parallax";
import { ImageAuthor } from "../Image/ImageAuthor";
import { CaptionProps } from "./ParallaxImage";
import styled from "styled-components/macro";
import { bp, gridColumnStart, gridColumnWidth, font } from "#shared/theme";
import htmr from "htmr";
import { useResize } from "#hooks";

const Container = styled.div`
  order: 2;
  ${gridColumnStart(1)}
  ${gridColumnWidth(8)}
  @media ${bp.s} {
    ${gridColumnStart(1)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    order: 1;
    margin-top: 80px;
    ${gridColumnStart(3)}
    ${gridColumnWidth(8)}
  }
  @media ${bp.p} {
    margin-top: initial;
  }
`;

const ParallaxText = styled.div`
  order: 1;
  z-index: 1;
  font-size: 20px;
  line-height: 1.89;
  font-weight: bold;
  margin-bottom: 30px;
  height: fit-content;

  strong {
    font-size: 28px;
    line-height: 1.36;
    font-family: ${font.condensed};
  }

  ${gridColumnStart(4)}
  ${gridColumnWidth(9)}
  @media ${bp.m} {
    order: 2;
    font-size: 20px;
    line-height: 1.8;

    strong {
      font-size: 30px;
      line-height: 1.2;
    }

    ${gridColumnStart(12)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.p} {
    font-size: 24px;
    line-height: 1.75;

    strong {
      font-size: 34px;
      line-height: 1.24;
    }
    ${gridColumnStart(12)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.d} {
    font-size: 34px;
    line-height: 1.53;

    strong {
      font-size: 44px;
      line-height: 1.18;
    }
    margin-top: 60px;
    ${gridColumnStart(12)}
    ${gridColumnWidth(9)}
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 35px;
`;

interface ParallaxReport {
  align: ParallaxAlign;
  parallaxColor: ParallaxColor;
  image: {
    src: string;
    caption: CaptionProps;
  };
  copytext: string;
}

export const ParallaxReport: FC<ParallaxReport> = ({ align, parallaxColor, image, copytext }) => {
  const textRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { width } = useResize();

  useEffect(() => {
    const refTextElem: HTMLDivElement | null = textRef.current;
    setHeight(refTextElem ? refTextElem!.offsetHeight : 0);
  }, [textRef, width]);

  return (
    <Parallax align={align} parallaxColor={parallaxColor} height={height}>
      <Wrapper>
        <Grid>
          <Container>
            <Image src={image.src} />
            <ImageAuthor {...image.caption} />
          </Container>
          <ParallaxText ref={textRef}>{htmr(copytext)}</ParallaxText>
        </Grid>
      </Wrapper>
    </Parallax>
  );
};
