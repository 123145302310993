import { useReducer } from "react";

export enum LoaderStep {
  Loading = "Loading",
  Complete = "Complete",
  // Finish = "Finish",
  Destroy = "Destroy",
}

interface LoaderContextProps {
  isLoading: boolean;
  isComplete: boolean;
  // isFinished: boolean;
  isDestroyed: boolean;
  complete: () => void;
  // finish: () => void;
  destroy: () => void;
}

interface LoaderState {
  step: LoaderStep;
}

type LoaderAction = { type: LoaderStep };

type LoaderReducerType = (state: LoaderState, action: LoaderAction) => LoaderState;

const LoaderReducer: LoaderReducerType = (_, action) => {
  return {
    step: action.type,
  };
};

export const useLoader = (defaultStep = LoaderStep.Loading): LoaderContextProps => {
  const [{ step }, dispatch] = useReducer(LoaderReducer, { step: defaultStep });
  const value = {
    isLoading: step === LoaderStep.Loading,
    isComplete: step === LoaderStep.Complete,
    // isFinished: step === LoaderStep.Finish,
    isDestroyed: step === LoaderStep.Destroy,
    complete: (): void => dispatch({ type: LoaderStep.Complete }),
    // finish: (): void => dispatch({ type: LoaderStep.Finish }),
    destroy: (): void => dispatch({ type: LoaderStep.Destroy }),
  };
  return value;
};
