import React, { FC } from "react";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";
import { Parallax, ParallaxAlign, ParallaxColor } from "./Parallax";
import styled, { css } from "styled-components/macro";
import { bp, gridColumnStart, gridColumnWidth, font, Color } from "#shared/theme";
import htmr from "htmr";

const underline = css`
  &::after {
    content: "";
    display: block;
    width: 42px;
    height: 5px;
    position: absolute;
    bottom: -10px;
    left: 2px;
    background-color: ${Color.Black};
    @media ${bp.t} {
      width: 100px;
      height: 6px;
      bottom: -20px;
      left: 4px;
    }
    @media ${bp.d} {
      bottom: -30px;
      left: 7px;
    }
  }
`;

const Container = styled.div<{ isDoubleCaption: boolean }>`
  display: flex;
  position: relative;
  margin-bottom: 40px;
  ${gridColumnStart(1)}
  ${gridColumnWidth(10)}
  @media ${bp.m} {
    margin-bottom: 50px;
    ${gridColumnStart(5)}
    ${gridColumnWidth(16)}
  }
  @media ${bp.p} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(12)}
  }
  @media ${bp.t} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.d} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(12)}
  }

  ${({ isDoubleCaption }) =>
    !isDoubleCaption &&
    css`
      ${underline}
    `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const ParallaxItem = styled.div`
  width: 50%;
`;
const ImageWrapper = styled.div<{ isDoubleCaption: boolean }>`
  margin-right: 3px;
  position: relative;
  ${({ isDoubleCaption }) =>
    isDoubleCaption &&
    css`
      margin-bottom: 40px;
      ${underline}
    `}
`;

const ParallaxImageCaption = styled.div<{ isDoubleCaption?: boolean }>`
  z-index: 1;
  font-size: 16px;
  line-height: 1.26;
  font-family: ${font.condensed};
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: ${({ isDoubleCaption }) => isDoubleCaption && 20}px;
  @media ${bp.m} {
    font-size: 20px;
    line-height: 1.15;
  }
  @media ${bp.p} {
    margin-left: ${({ isDoubleCaption }) => isDoubleCaption && 50}px;
    font-size: 22px;
    line-height: 1.18;
  }
`;

const ParallaxCaption = styled(ParallaxImageCaption)`
  ${gridColumnStart(1)}
  ${gridColumnWidth(9)}
  @media ${bp.s} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    ${gridColumnStart(7)}
    ${gridColumnWidth(12)}
  }
  @media ${bp.p} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.t} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(8)}
  }
  @media ${bp.d} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(7)}
  }
`;

export type CaptionProps = {
  headline: string;
  desc: string;
};

type ParallaxItemProps = {
  src: string;
  caption: CaptionProps;
};

interface ParallaxImage {
  align: ParallaxAlign;
  parallaxColor: ParallaxColor;
  caption: CaptionProps;
  isDoubleCaption: boolean;
  items: ParallaxItemProps[];
}

export const ParallaxImage: FC<ParallaxImage> = ({ align, isDoubleCaption, parallaxColor, items, caption }) => (
  <Parallax align={align} parallaxColor={parallaxColor}>
    <Wrapper>
      <Grid>
        <Container isDoubleCaption={isDoubleCaption}>
          {items.map((item: ParallaxItemProps, key: number) => (
            <ParallaxItem key={key}>
              <ImageWrapper isDoubleCaption={isDoubleCaption}>
                <Image src={item.src} />
              </ImageWrapper>

              {item?.caption?.headline && (
                <ParallaxImageCaption isDoubleCaption={isDoubleCaption}>
                  {htmr(item.caption.headline)}
                </ParallaxImageCaption>
              )}
              {item?.caption?.desc && (
                <ParallaxImageCaption isDoubleCaption={isDoubleCaption}>{htmr(item.caption.desc)}</ParallaxImageCaption>
              )}
            </ParallaxItem>
          ))}
        </Container>
        {caption?.headline && <ParallaxCaption>{htmr(caption.headline)}</ParallaxCaption>}
        {caption?.desc && <ParallaxCaption>{htmr(caption.desc)}</ParallaxCaption>}
      </Grid>
    </Wrapper>
  </Parallax>
);
