import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { VCPChartDesktop } from "./VCPChartDesktop/VCPChartDesktop";
import { VCPChartMobile } from "./VCPChartMobile/VCPChartMobile";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";

export const VCPChart: React.FC = () => {
  const { t } = useTranslation("VCP");
  const labels = t("chart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return isDesktop ? <VCPChartDesktop labels={labels} /> : <VCPChartMobile labels={labels} />;
};
