import styled from "styled-components/macro";
import { Color, font } from "#shared/theme";

const SvgTextBase = styled.g<{ alignEnd?: boolean; dark?: boolean }>`
  fill: ${({ dark }) => (dark ? Color.Black : Color.White)};
  opacity: 0;
  text-anchor: ${({ alignEnd }): string => (alignEnd ? "end" : "middle")};
`;

export const SvgText = styled(SvgTextBase)`
  transform: translateY(10px);
`;

export const SvgTextX = styled(SvgTextBase)<{ isNegative?: boolean }>`
  transform: ${({ isNegative }): string => (isNegative ? "translateX(-10px)" : "translateX(10px)")};
`;

export const SvgTextSmall = styled.g`
  font-family: ${font.condensed};
  text {
    white-space: pre;
  }
`;
