import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card12M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect y="85" width="290.68" height="7" />
        <rect x="317" fill="#42637C" width="38" height="530" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="screen">
          <path
            fill="none"
            d="M168.04,245.98c-2.16,0-3.92,1.76-3.92,3.92c0,2.16,1.76,3.92,3.92,3.92c1.08,0,2.06-0.44,2.77-1.15
			l-3.41-2.95l0.15-0.17l3.42,2.95c0.62-0.69,1-1.6,1-2.6C171.96,247.74,170.2,245.98,168.04,245.98z"
          />
          <path
            fill="#659896"
            d="M41.53,420.43c1.81-4.2,4.35-7.72,7.5-10.67c-0.01,0-0.02-0.01-0.03-0.01c-0.78-0.29-1.58-0.17-2.3-0.07
			c-0.71,0.1-1.32,0.19-1.75-0.14c-0.28-0.22-0.44-0.61-0.5-1.18c-0.05-0.54-0.07-1.37-0.1-2.44c-0.16-5.82-0.48-17.79-5.97-21.74
			l0,27.47c0.9,4.23,1.84,6.99,3.12,8.75C41.51,420.41,41.52,420.42,41.53,420.43z"
          />
          <path
            fill="#659896"
            d="M41.38,420.79c-0.01-0.01-0.02-0.03-0.03-0.04c-1.21-1.61-2.12-4.05-2.97-7.72l0,22.22l0,2.16
			C38.37,430.95,39.43,425.46,41.38,420.79z"
          />
          <path
            fill="#659896"
            d="M100.71,387.69l0.04-14.72c0-8.26-1.02-14.75-4.23-19.67c-0.01,0-0.03,0-0.04,0
			c-14.48,1.32-24.97,3.97-31.84,6.3c-10.38,3.52-14.98,7.08-15.02,7.12c-0.02,0.02-0.05,0.02-0.07,0.02
			c-0.03,0-0.07-0.01-0.09-0.04c-0.04-0.05-0.03-0.12,0.02-0.16c0.05-0.04,4.67-3.62,15.09-7.16c6.87-2.33,17.33-4.98,31.77-6.3
			c0.01,0,0.03,0,0.04,0c-2.29-3.4-5.66-6.04-10.52-7.98c-1.64-0.66-3.74-1.24-6.13-1.85c0,0,0,0.01-0.01,0.01
			c-0.21,0.33-0.4,0.68-0.58,1.06c-1.51,3.25-1.35,4.84-1.25,5.78c0.11,1.07,0.06,1.41-1.79,2.15c-0.25,0.1-0.47,0.19-0.67,0.27
			c-1.15,0.47-1.78,0.73-4.7,1.37c-0.14,0.03-0.27,0.04-0.4,0.04c-0.98,0-1.5-0.83-1.51-0.84c-0.04-0.06-1.11-1.65,0.37-5.67
			c0.64-1.75,0.13-2.58-0.37-3.38c-0.55-0.89-1.07-1.74,0.2-3.62c0,0,0.01-0.01,0.01-0.01c-0.94-0.29-1.89-0.59-2.84-0.91
			c0,0,0,0.01-0.01,0.01c-0.13,0.29-0.25,0.59-0.35,0.89c-1.21,3.72-1.42,6.71-1.6,9.12c-0.27,3.79-0.45,6.29-4.32,7.99
			c-6.91,3.03-11.3,5.42-11.34,5.44c-0.02,0.01-0.04,0.01-0.05,0.01c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.05-0.15
			c0.04-0.02,4.45-2.42,11.36-5.45c3.75-1.64,3.91-3.96,4.19-7.8c0.17-2.42,0.39-5.43,1.61-9.18c0.1-0.3,0.21-0.6,0.35-0.9
			c0,0,0-0.01,0.01-0.01c-1.07-0.37-2.14-0.77-3.21-1.2c0,0.01,0,0.01-0.01,0.02c-0.84,2.03-1.36,4.34-1.84,7.07
			c-0.11,0.63-0.2,1.21-0.27,1.72c-0.47,3.1-0.66,4.38-4.21,6.11c-4.62,2.26-7.84,3.44-7.87,3.45c-0.01,0-0.03,0.01-0.04,0.01
			c-0.05,0-0.09-0.03-0.11-0.07c-0.02-0.06,0.01-0.12,0.07-0.15c0.03-0.01,3.23-1.19,7.85-3.44c3.45-1.68,3.63-2.93,4.09-5.94
			c0.08-0.51,0.16-1.09,0.28-1.72c0.48-2.75,1-5.07,1.84-7.11c0-0.01,0-0.01,0.01-0.02c-1.5-0.61-2.99-1.28-4.46-2.04
			c0,0.01,0,0.01-0.01,0.02c-0.71,2.52-1.19,4.43-1.55,5.83c-1.19,4.66-1.19,4.66-3.9,7.03l-0.15,0.13
			c-1.24,1.08-4.4,2.29-4.53,2.34c-0.01,0.01-0.03,0.01-0.04,0.01c-0.05,0-0.09-0.03-0.11-0.07c-0.02-0.06,0.01-0.12,0.07-0.15
			c0.03-0.01,3.26-1.25,4.46-2.3l0.15-0.13c2.66-2.32,2.66-2.32,3.83-6.91c0.36-1.41,0.85-3.33,1.57-5.88c0-0.01,0-0.01,0.01-0.02
			c-10.05-5.23-18.77-14.27-19.5-31.77l0,0.79l-0.01,78.77c5.77,3.92,6.11,16.18,6.27,22.1c0.03,1.06,0.05,1.89,0.1,2.42
			c0.04,0.49,0.17,0.81,0.38,0.98c0.32,0.26,0.88,0.17,1.52,0.08c0.77-0.11,1.69-0.25,2.59,0.14c0.01,0,0.02,0.01,0.03,0.01
			c11.31-10.38,30.31-13.62,51.44-14.52c0-0.33,0-0.66,0-0.98L100.71,387.69z M89.76,363.65h0.23v24.34h-0.23V363.65z M77.68,364.68
			c0.36,0,0.65,0.29,0.65,0.65c0,0.36-0.29,0.65-0.65,0.65s-0.65-0.29-0.65-0.65C77.02,364.97,77.32,364.68,77.68,364.68z
			 M74.87,364.23c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76
			C74.1,364.57,74.45,364.23,74.87,364.23z M71.81,363.89c0.36,0,0.65,0.29,0.65,0.65c0,0.36-0.29,0.65-0.65,0.65
			c-0.36,0-0.65-0.29-0.65-0.65C71.16,364.18,71.45,363.89,71.81,363.89z M68.79,363.27c0.42,0,0.76,0.34,0.76,0.76
			c0,0.42-0.34,0.76-0.76,0.76s-0.76-0.34-0.76-0.76C68.03,363.61,68.37,363.27,68.79,363.27z M67.48,366.87
			c0.08,0,0.24,0.04,0.69,0.17c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23c-10.57-0.67-16.31-2.26-18.19-2.78
			c-0.33-0.09-0.58-0.16-0.63-0.16V366.87z M67.48,369.89c0.08,0,0.24,0.04,0.69,0.17c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23
			c-10.57-0.67-16.31-2.26-18.19-2.78c-0.33-0.09-0.58-0.16-0.63-0.16V369.89z M67.48,372.9c0.08,0,0.24,0.04,0.69,0.17
			c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23c-10.57-0.67-16.31-2.26-18.19-2.78c-0.33-0.09-0.58-0.16-0.63-0.16V372.9z
			 M46.69,404.66c-0.02,0.04-0.06,0.06-0.1,0.06c-0.02,0-0.04,0-0.06-0.01c-0.05-0.03-0.07-0.1-0.04-0.15l9.66-17l0.09,0.03
			c0.07,0.02,7.38,2.15,14.59,3.2c0.06,0.01,0.1,0.07,0.1,0.13c-0.01,0.06-0.06,0.1-0.13,0.1c-6.8-1-13.69-2.94-14.53-3.18
			L46.69,404.66z M70.45,393.86c-6.01-0.3-13.49-2.83-13.57-2.86c-0.06-0.02-0.09-0.08-0.07-0.14c0.02-0.06,0.08-0.09,0.14-0.07
			c0.07,0.03,7.53,2.54,13.5,2.84c0.06,0,0.11,0.06,0.11,0.12C70.57,393.81,70.52,393.86,70.45,393.86
			C70.46,393.86,70.45,393.86,70.45,393.86z M70.46,396.29c0,0.06-0.05,0.1-0.11,0.1c0,0-0.01,0-0.01,0
			c-9.96-0.75-13.96-2.88-14-2.9c-0.05-0.03-0.08-0.1-0.05-0.15c0.03-0.06,0.1-0.07,0.15-0.05c0.04,0.02,4.01,2.13,13.91,2.88
			C70.41,396.17,70.46,396.22,70.46,396.29z"
          />
          <path
            fill="#659896"
            d="M69.03,343.95c0.5,0.81,1.07,1.73,0.39,3.58c-1.43,3.89-0.41,5.45-0.4,5.47c0.03,0.04,0.59,0.93,1.67,0.7
			c2.9-0.63,3.53-0.89,4.66-1.36c0.2-0.08,0.42-0.17,0.67-0.27c1.78-0.71,1.75-0.97,1.65-1.92c-0.1-0.97-0.27-2.6,1.27-5.9
			c0.17-0.36,0.36-0.7,0.55-1.02c0,0,0-0.01,0.01-0.01c-3.05-0.77-6.56-1.6-10.23-2.71c0,0-0.01,0.01-0.01,0.01
			C68.02,342.31,68.49,343.08,69.03,343.95z"
          />
          <path
            fill="#1EBEE4"
            d="M109.7,282.86c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S109.4,282.86,109.7,282.86z"
          />
          <path
            fill="#1EBEE4"
            d="M194.52,368.73c0-0.06,0.05-0.11,0.12-0.11l2.23,0.08c0-3.15-1.16-6.14-3.32-8.45
			c-0.14-0.15-0.28-0.29-0.42-0.43l-0.57,0.89l-0.1-0.09c-0.03-0.03-3.4-3.14-7.27-3.28l-0.14-0.01l0.26-1.02
			c-0.14-0.01-0.27-0.02-0.41-0.02c-0.16-0.01-0.31-0.01-0.47-0.01c-3.14,0-6.13,1.17-8.44,3.32c-0.41,0.38-0.78,0.78-1.13,1.21
			l6.44,4.78c0.87-1.09,2.22-1.77,3.71-1.71c2.5,0.09,4.45,2.2,4.36,4.69c-0.04,1.21-0.56,2.33-1.44,3.15
			c-0.84,0.78-1.93,1.21-3.08,1.21c-0.06,0-0.11,0-0.17,0c-2.5-0.09-4.45-2.2-4.36-4.69c0.03-0.92,0.34-1.77,0.85-2.47l-6.45-4.79
			c-1.57,1.97-2.49,4.36-2.66,6.91l1.33,0.05c0.06,0,0.11,0.05,0.11,0.12c0,0.06-0.05,0.11-0.11,0.11c0,0,0,0,0,0l-1.34-0.05
			c0,0.04-0.01,0.09-0.01,0.13c-0.12,3.32,1.06,6.48,3.31,8.91c2.26,2.43,5.33,3.84,8.64,3.96c2.33,0.09,4.58-0.47,6.55-1.59
			l-0.55-0.86c-0.03-0.05-0.02-0.12,0.03-0.16c0.05-0.03,0.12-0.02,0.16,0.03l0.56,0.86c0.77-0.45,1.49-0.99,2.16-1.61
			c2.43-2.26,3.84-5.33,3.96-8.64c0-0.08,0-0.15,0-0.23l-2.24-0.08C194.56,368.85,194.52,368.8,194.52,368.73z M180.13,363.38
			l-0.15-0.16c0.69-0.64,1.48-1.14,2.36-1.47l0.08,0.21C181.56,362.28,180.8,362.75,180.13,363.38z M187.71,362.14
			c-0.82-0.38-1.7-0.59-2.62-0.62l-0.03,0l0.01-0.23l0.03,0c0.94,0.03,1.85,0.25,2.7,0.64L187.71,362.14z M177.84,368.18l-0.11-0.02
			c0.03-0.94,0.25-1.84,0.63-2.69l0.21,0.09c-0.37,0.82-0.58,1.69-0.61,2.6L177.84,368.18z M179.64,373.27
			c-0.64-0.69-1.14-1.48-1.47-2.36l0.21-0.08c0.32,0.85,0.8,1.62,1.42,2.28L179.64,373.27z M184.59,375.53
			c-0.94-0.03-1.85-0.25-2.69-0.63l0.09-0.21c0.82,0.37,1.7,0.58,2.6,0.61l0.01,0.11L184.59,375.53z M187.34,375.08l-0.08-0.21
			c0.85-0.32,1.62-0.79,2.29-1.42l0.15,0.17C189.01,374.26,188.22,374.76,187.34,375.08z M189.89,363.71l0.17-0.15
			c0.64,0.69,1.13,1.48,1.46,2.36l-0.21,0.08C190.99,365.15,190.51,364.38,189.89,363.71z M191.96,368.68
			c-0.03,0.93-0.25,1.84-0.64,2.69l-0.21-0.09c0.38-0.82,0.58-1.7,0.62-2.61l0.23,0L191.96,368.68z"
          />
          <path
            fill="#1EBEE4"
            d="M193.43,358.92c-2.25-2.83-6.91-3.5-7.63-3.59l-0.2,0.78c2.89,0.26,5.49,1.5,7.48,3.36L193.43,358.92z"
          />
          <path
            fill="#1EBEE4"
            d="M192.96,359.67c-2.04-1.92-4.62-3.09-7.42-3.33l-0.2,0.79c3.51,0.19,6.59,2.73,7.17,3.25L192.96,359.67z"
          />
          <polygon
            fill="#1EBEE4"
            points="138.61,272.78 136.87,271.88 135.13,272.73 135.12,275.03 136.92,276.03 138.68,274.98 		"
          />
          <polygon
            fill="#1EBEE4"
            points="204.81,291.25 200.66,288.97 196.46,291.5 196.46,296.47 200.69,299 204.81,296.27 		"
          />
          <path
            fill="#1EBEE4"
            d="M115.73,282.86c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S115.43,282.86,115.73,282.86z"
          />
          <path
            fill="#1EBEE4"
            d="M194.53,297.35l6.24,3.7l5.87-3.78v-7.03l-6.07-3.53l-6.04,3.99V297.35z M196.23,291.37l4.42-2.66
			l4.38,2.41v5.27l-4.33,2.88l-4.47-2.66V291.37z"
          />
          <path
            fill="#1EBEE4"
            d="M112.72,282.86c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S112.42,282.86,112.72,282.86z"
          />
          <polygon
            fill="#1EBEE4"
            points="121.91,381.42 121.91,377.19 125.48,375.04 129.02,376.99 129.02,381.25 125.52,383.57 
			123.92,382.62 122.39,385.56 129,385.18 132.65,378.94 129.14,373.09 122.08,373.18 118.47,379.37 121.9,385.59 122.13,385.57 
			123.72,382.5 		"
          />
          <path
            fill="#1EBEE4"
            d="M202.83,285.13c-0.46,0-0.83,0.37-0.83,0.83c0,0.46,0.37,0.83,0.83,0.83c0.22,0,0.42-0.09,0.57-0.23
			c-0.01-0.03,0-0.06,0.01-0.09c0.02-0.04,0.07-0.06,0.11-0.06c0.09-0.13,0.14-0.29,0.14-0.46
			C203.66,285.5,203.29,285.13,202.83,285.13z"
          />
          <path
            fill="#1EBEE4"
            d="M200.76,303.49c0.25-0.12,0.35-0.43,0.23-0.68c-0.12-0.25-0.43-0.35-0.68-0.23
			c-0.25,0.12-0.35,0.43-0.23,0.68C200.2,303.51,200.51,303.61,200.76,303.49z"
          />
          <polygon fill="#1EBEE4" points="192.65,274.29 193.95,272.67 191.87,270.87 191.52,273.44 		" />
          <path
            fill="#1EBEE4"
            d="M173.07,299.18l3.7,0.55c0.01-0.09,0.01-0.18,0.01-0.27c0-1.24-0.58-2.35-1.49-3.07L173.07,299.18z"
          />
          <path
            fill="#1EBEE4"
            d="M90.09,338.96c4.42,0.66,5.08,5.15,5.08,7.77c0,1.87,1.67,2.24,4.37,2.24l4.94,0.01
			c12.3,0.02,41.84-0.02,50.51-0.73c0.85-0.07,1.49-0.79,1.46-1.63c-0.07-2.25,0.19-6.41,2.71-9.03c1.45-1.51,3.44-2.28,5.93-2.3
			c0.05,0,0.1,0,0.15,0c8.93,0,9.72,7.3,10.05,10.42c0.02,0.22,0.05,0.43,0.07,0.61c0.06,0.49,0.5,0.85,0.98,0.81
			c2.37-0.17,8-0.62,8.98-0.99c4.69-1.75,2.75-9.52,1.59-14.16c-0.51-2.02-0.74-3.01-0.53-3.34c0.02-0.02,0.01-0.04,0-0.06
			c-0.37-0.79-8.01-1.78-11.28-2.2c-1.05-0.14-1.88-0.24-2.23-0.31c-0.33-0.06-1.2-0.36-2.51-0.81c-5.88-2.03-19.64-6.79-32.65-6.79
			c-10.86,0-17.23,3.92-21.89,6.77c-2.17,1.34-3.89,2.39-5.47,2.65l-2.5,0.2c-0.07,0-7.29,0.47-15.2,1.48
			c-12.06,1.54-19.76,6.33-22.94,10.3c-0.18,0.22-0.32,0.42-0.46,0.62c3.66,1.1,7.18,1.93,10.23,2.71
			C81.93,339.3,86.46,338.42,90.09,338.96z"
          />
          <path
            fill="#1EBEE4"
            d="M184.69,372.71c1.14,0.05,2.25-0.37,3.09-1.15c0.84-0.78,1.33-1.85,1.37-3c0.09-2.37-1.77-4.37-4.14-4.46
			c-0.05,0-0.11,0-0.16,0c-1.35,0-2.57,0.63-3.36,1.62l1.42,1.05c0.48-0.58,1.22-0.94,2.04-0.91c0.68,0.02,1.3,0.31,1.76,0.81
			c0.46,0.5,0.7,1.14,0.68,1.82c-0.02,0.68-0.31,1.3-0.81,1.77c-0.47,0.44-1.08,0.68-1.72,0.68c-0.03,0-0.06,0-0.1,0
			c-0.68-0.02-1.3-0.31-1.76-0.81c-0.46-0.5-0.7-1.14-0.68-1.82c0.02-0.5,0.18-0.97,0.45-1.36l-1.42-1.05
			c-0.48,0.66-0.77,1.47-0.8,2.34C180.45,370.63,182.31,372.63,184.69,372.71z"
          />
          <path
            fill="#1EBEE4"
            d="M197.71,259.58c-3.58,0-10.42,0-18.56,0l12.55,10.85l0.05-0.34l2.82-0.94l2.46,1.71l-0.35,3.34l-1.33,0.57
			l4.39,8.34c0.03,0.06,0.01,0.12-0.05,0.15c-0.05,0.03-0.12,0.01-0.15-0.05l-4.4-8.36l-1.35,0.57l-1.1-0.83l-17.26,21.62
			c0.96,0.76,1.57,1.93,1.57,3.24c0,0.1-0.01,0.2-0.02,0.3l22.82,3.4c-0.06-0.31,0.09-0.64,0.39-0.79c0.31-0.15,0.68-0.06,0.89,0.2
			l7.07-4.41v-8.8l-4.59-2.67c-0.19,0.19-0.46,0.31-0.75,0.31c-0.58,0-1.06-0.47-1.06-1.06c0-0.58,0.47-1.06,1.06-1.06
			c0.58,0,1.06,0.47,1.06,1.06c0,0.21-0.06,0.4-0.17,0.56l4.68,2.71v9.06l-7.18,4.48c0.13,0.35-0.01,0.75-0.36,0.92
			c-0.1,0.05-0.21,0.07-0.32,0.07c-0.25,0-0.49-0.13-0.62-0.35l-22.94-3.42c-0.26,2.04-2,3.62-4.11,3.62
			c-2.29,0-4.14-1.86-4.14-4.14c0-2.28,1.86-4.14,4.14-4.14c0.89,0,1.71,0.28,2.39,0.76l17.26-21.62l-1.24-0.93l0.39-2.84
			l-12.86-11.11c-18.93,0-44.64,0-52.65,0c-41.59,0-87.86,3.19-87.86,42.28c0,0.81,0.02,1.61,0.06,2.39
			c0.01,0.27,0.03,0.53,0.04,0.79l0-0.79c0.73,17.5,9.45,26.54,19.5,31.77c0.02-0.08,0.04-0.16,0.07-0.24
			c1.22-4.35,3.96-7.58,8.62-10.19c4.41-2.47,10.77-4.54,20.61-6.72c13.12-2.9,17.59-4.8,22.32-6.81c3.21-1.36,6.53-2.77,12.7-4.62
			c17.37-5.21,31.23-0.88,43.45,2.94c3.39,1.06,6.6,2.06,9.66,2.78c14.71,3.44,21.09,8.95,25.75,12.98l0.16,0.13
			c4.59,3.96,8.52,3.64,8.56,3.63c0.06-0.01,0.12,0.04,0.12,0.1c0.01,0.06-0.04,0.12-0.1,0.12c-0.04,0-4.06,0.35-8.73-3.69
			l-0.16-0.13c-4.64-4.01-11.01-9.51-25.66-12.93c-3.07-0.72-6.28-1.72-9.68-2.78c-12.19-3.81-26.01-8.13-43.32-2.94
			c-6.15,1.84-9.47,3.25-12.67,4.61c-4.74,2.01-9.22,3.92-22.36,6.82c-9.82,2.17-16.15,4.23-20.55,6.69
			c-4.6,2.58-7.31,5.77-8.51,10.06c-0.03,0.1-0.05,0.19-0.08,0.28c1.47,0.75,2.96,1.43,4.46,2.04c2.07-5.02,6.09-8.4,16.27-12.38
			c9.77-3.81,15.82-5.45,21.15-6.89c5.18-1.4,9.64-2.61,16.38-5.4c11.73-4.87,26.12-5.57,39.46-1.92
			c10.12,2.77,16.4,4.65,24.35,7.02l3.55,1.06c9.32,2.78,12.32,8.04,14.31,11.52c0.11,0.2,0.22,0.39,0.33,0.58
			c0.65,1.13,1.1,1.95,1.46,2.6c0.61,1.11,0.97,1.78,1.69,2.77c1.81,2.5,7.94,2.08,8,2.07c0.06,0,0.12,0.04,0.12,0.1
			c0,0.06-0.04,0.12-0.1,0.12c-0.26,0.02-6.32,0.43-8.2-2.17c-0.72-1-1.09-1.67-1.7-2.79c-0.36-0.66-0.8-1.48-1.46-2.6
			c-0.11-0.19-0.22-0.38-0.33-0.58c-1.97-3.45-4.95-8.66-14.18-11.41l-3.55-1.06c-7.95-2.38-14.23-4.26-24.34-7.02
			c-13.3-3.64-27.63-2.94-39.32,1.91c-6.75,2.8-11.22,4.01-16.41,5.41c-5.33,1.44-11.37,3.07-21.13,6.88
			c-10.1,3.94-14.09,7.29-16.14,12.25c1.07,0.43,2.14,0.83,3.21,1.2c3.85-8.62,21.22-13.08,40.25-17.23
			c5.52-1.2,9.05-2.79,12.46-4.33c4.82-2.17,9.36-4.22,18.71-4.4c10.13-0.19,26.13,4.48,34.76,7.29c1.79,0.58,3.53,1.08,5.22,1.57
			c3.31,0.95,6.43,1.85,9.37,3.19c2.85,1.29,5.02,3.74,6.83,7.69c0.56,1.22,1.07,2.51,1.57,3.77c1.86,4.67,3.62,9.09,7.21,9.09h7.15
			c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.05,0.11-0.11,0.11h-7.15c-3.75,0-5.54-4.48-7.42-9.23c-0.5-1.25-1.01-2.55-1.57-3.76
			c-1.79-3.9-3.92-6.31-6.72-7.58c-2.92-1.33-6.04-2.23-9.34-3.18c-1.69-0.49-3.43-0.99-5.22-1.57c-8.45-2.74-23.96-7.28-34.07-7.28
			c-0.21,0-0.41,0-0.61,0.01c-9.3,0.18-13.62,2.12-18.62,4.38c-3.42,1.54-6.96,3.14-12.51,4.35c-15.22,3.32-35.89,7.83-40.08,17.08
			c0.95,0.33,1.9,0.63,2.84,0.91c0.15-0.22,0.31-0.44,0.51-0.69c3.21-4.01,10.96-8.84,23.09-10.39c7.92-1.01,15.14-1.47,15.21-1.48
			l2.49-0.2c1.53-0.25,3.23-1.3,5.38-2.62c4.68-2.87,11.08-6.81,22-6.81c13.05,0,26.83,4.77,32.72,6.8c1.3,0.45,2.17,0.75,2.48,0.8
			c0.35,0.06,1.18,0.17,2.22,0.3c5.58,0.72,11.07,1.5,11.46,2.33c0.04,0.09,0.04,0.19-0.02,0.27c-0.17,0.26,0.17,1.6,0.56,3.16
			c1.18,4.71,3.15,12.6-1.73,14.42c-1.01,0.38-6.66,0.83-9.04,1c-0.61,0.05-1.15-0.4-1.23-1.01c-0.02-0.18-0.04-0.39-0.07-0.61
			c-0.36-3.32-1.1-10.22-9.82-10.22c-0.05,0-0.1,0-0.15,0c-2.43,0.02-4.37,0.77-5.77,2.23c-2.46,2.56-2.71,6.65-2.65,8.87
			c0.03,0.96-0.7,1.78-1.67,1.86c-8.68,0.72-38.23,0.75-50.53,0.73l-4.94-0.01c-2.22,0-4.59-0.19-4.59-2.47
			c0-5.93-3.06-7.28-4.89-7.55c-3.53-0.53-7.94,0.32-10.33,4.08c2.39,0.61,4.48,1.19,6.13,1.85c4.86,1.94,8.23,4.58,10.52,7.98
			c5.76-0.53,12.15-0.84,19.19-0.84h93.99c0.06,0,0.11,0.05,0.11,0.11s-0.05,0.11-0.11,0.11h-93.99c-6.99,0-13.34,0.31-19.06,0.83
			c3.22,4.92,4.23,11.41,4.23,19.67l-0.04,14.72l0,6.35c0,0.32,0,0.65,0,0.98l16.6-0.17l4.69-9.05l-0.24,0.01l-3.57-6.45l3.74-6.41
			l7.32-0.1l0.03,0.06l3.61,6.02l-0.03,0.06l-3.74,6.4l-0.06,0l-6.81,0.39l-4.7,9.06l9.77-0.1c19.88,0,58.44,0,69.97,0
			c11.53,0,24.65-5.96,24.65-23.85c0-17.89,0-75.94,0-87.86C221.96,271.11,213.21,259.58,197.71,259.58z M195.14,300.37l-2.57-1.87
			v-8.95l7.62-4.85l0.12,0.19l-7.52,4.78v8.71l2.48,1.8L195.14,300.37z M206.87,290.11v7.28l-6.09,3.92l-6.47-3.83v-6.9l6.26-4.13
			L206.87,290.11z M115.73,281.56c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76
			C114.97,281.9,115.31,281.56,115.73,281.56z M112.72,281.56c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76
			c-0.42,0-0.76-0.34-0.76-0.76C111.95,281.9,112.29,281.56,112.72,281.56z M109.7,281.56c0.42,0,0.76,0.34,0.76,0.76
			c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76C108.94,281.9,109.28,281.56,109.7,281.56z M106.69,281.56
			c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76C105.92,281.9,106.26,281.56,106.69,281.56z
			 M45.27,300.38l-0.21-0.08c4.15-10.38,11.51-16.53,16.94-19.86c5.89-3.61,10.87-4.78,10.92-4.79l0.05,0.22
			c-0.05,0.01-5,1.18-10.86,4.77C56.71,283.94,49.4,290.06,45.27,300.38z M57.26,312.33l-1.62,0.89c-0.02,0.01-0.04,0.01-0.05,0.01
			c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.04-0.15l1.62-0.89c0.05-0.03,0.12-0.01,0.15,0.04
			C57.33,312.23,57.31,312.3,57.26,312.33z M57.7,311.29v-1.88c0-0.06,0.05-0.11,0.11-0.11s0.11,0.05,0.11,0.11v1.88
			c0,0.06-0.05,0.11-0.11,0.11S57.7,311.35,57.7,311.29z M57.97,314.61c0,0.06-0.05,0.11-0.11,0.11c-0.06,0-0.11-0.05-0.11-0.11
			v-2.2c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11V314.61z M60.12,310.76l-1.75,0.96c-0.02,0.01-0.04,0.01-0.05,0.01
			c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.04-0.15l1.75-0.96c0.05-0.03,0.12-0.01,0.15,0.04
			C60.19,310.66,60.17,310.73,60.12,310.76z M57.36,288.39c-0.02,0.02-0.05,0.03-0.08,0.03c-0.03,0-0.06-0.01-0.08-0.03
			c-0.04-0.04-0.04-0.12,0-0.16c0.1-0.09,10.04-9.47,28.57-9.47c0.06,0,0.11,0.05,0.11,0.11s-0.05,0.11-0.11,0.11
			c-9.63,0-16.85,2.56-21.2,4.7C59.86,286.01,57.38,288.37,57.36,288.39z M67.84,308.52c0.04-0.03,4.13-2.64,13.53-3.53l0.02,0.22
			c-9.34,0.89-13.39,3.47-13.42,3.5L67.84,308.52z M68.25,310.32l-0.09-0.21c5.83-2.61,13.2-3.64,13.27-3.65l0.03,0.22
			C81.39,306.7,74.05,307.73,68.25,310.32z M92.28,313.73l-8.63-10.02c-0.7-0.02-8.61-0.13-15.97,3.39l-0.1-0.2
			c7.71-3.69,16.04-3.41,16.12-3.41l0.05,0l0.03,0.04l8.66,10.05L92.28,313.73z M94.86,282.73c-10.01,0-17.36,1.06-21.76,1.95
			c-4.77,0.97-7.2,1.94-7.22,1.95l-0.09-0.21c0.02-0.01,2.47-0.99,7.26-1.96c4.41-0.89,11.78-1.96,21.82-1.96V282.73z
			 M103.25,297.72h-0.23v-28.23h0.23V297.72z M105.35,285c0-0.06,0.05-0.11,0.11-0.11h14.39c0.06,0,0.11,0.05,0.11,0.11
			s-0.05,0.11-0.11,0.11h-14.39C105.4,285.11,105.35,285.06,105.35,285z M116.2,287.12c0,0.06-0.05,0.11-0.11,0.11h-10.41
			c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h10.41C116.15,287,116.2,287.05,116.2,287.12z M123.9,289.24h-18.22
			c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h18.22c0.06,0,0.11,0.05,0.11,0.11
			C124.01,289.19,123.96,289.24,123.9,289.24z M160.52,273.62h1.53v2.85h-1.53V273.62z M157.51,271.57h1.59v4.9h-1.59V271.57z
			 M154.49,266.05h1.6v10.42h-1.6V266.05z M134.9,272.59l1.97-0.96l1.96,1.01l0.07,2.48l-1.99,1.18l-2.03-1.14L134.9,272.59z
			 M132.94,287.2h-0.23v-15.97h0.23V287.2z M143.06,284.84h-8.17v-0.23h8.17V284.84z M143.3,283.46h-8.4v-0.23h8.4V283.46z
			 M147.91,281.83l-3.97,19.62l-0.22-0.04l3.95-19.49h-12.99v-0.23h13.27L147.91,281.83z M154.22,277.65
			c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h20.97c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.05,0.11-0.11,0.11H154.22z
			 M155.46,356.66h1.75v0.23h-1.75V356.66z M154.85,354.28h0.23v1.88h-0.23V354.28z M155.12,357.31v2.2h-0.23v-2.2H155.12z
			 M152.73,356.66h1.62v0.23h-1.62V356.66z M137.63,385.39l-1.82,1.21l-1.87-1.12v-2.2l1.86-1.12l1.84,1.01V385.39z M163.71,363.94
			c-1.47,0.48-3.01,0.02-4.49-0.43c-1.38-0.42-2.81-0.85-4.17-0.52c-0.65,0.16-1.25,0.48-1.82,0.8c-0.64,0.35-1.3,0.71-2.05,0.86
			c-1.42,0.27-2.75-0.28-4.05-0.82c-1.07-0.44-2.17-0.9-3.32-0.9c-0.01,0-0.02,0-0.02,0c-1.17,0-2.26,0.46-3.33,0.9
			c-0.98,0.41-1.99,0.83-3.08,0.92c-1.68,0.13-3.22-0.56-4.7-1.23c-1.32-0.59-2.68-1.21-4.15-1.25c-1.59-0.04-3.09,0.59-4.55,1.2
			c-1.53,0.64-3.12,1.31-4.81,1.2c-1.21-0.08-2.32-0.54-3.39-0.99c-1.08-0.45-2.2-0.92-3.4-0.98c-1.68-0.08-3.51,0.71-4.9,2.1
			c-0.01,0.01-0.02,0.01-0.04,0.01v5.96c0,0.06-0.05,0.11-0.11,0.11s-0.11-0.05-0.11-0.11v-13.54c0-0.06,0.05-0.11,0.11-0.11
			s0.11,0.05,0.11,0.11v7.28c1.42-1.36,3.25-2.11,4.94-2.05c1.24,0.06,2.38,0.53,3.48,1c1.05,0.44,2.14,0.9,3.31,0.98
			c1.64,0.1,3.2-0.55,4.71-1.18c1.48-0.62,3-1.27,4.64-1.22c1.51,0.04,2.9,0.66,4.24,1.27c1.52,0.69,2.96,1.33,4.59,1.21
			c1.05-0.08,2.05-0.5,3.01-0.9c1.08-0.45,2.2-0.92,3.41-0.92c0.01,0,0.02,0,0.03,0c1.2,0.01,2.32,0.47,3.41,0.92
			c1.32,0.55,2.57,1.06,3.92,0.81c0.72-0.14,1.36-0.49,1.98-0.83c0.59-0.32,1.19-0.66,1.88-0.82c1.42-0.34,2.88,0.1,4.29,0.52
			c1.52,0.46,2.96,0.89,4.36,0.43c0.06-0.02,0.12,0.01,0.14,0.07C163.8,363.85,163.77,363.92,163.71,363.94z M175.7,379.23
			l-4.08,5.04c-0.02,0.03-0.05,0.04-0.09,0.04c-0.03,0-0.05-0.01-0.07-0.03c-0.05-0.04-0.06-0.11-0.02-0.16l4.08-5.04
			c0.04-0.05,0.11-0.06,0.16-0.02C175.73,379.11,175.74,379.19,175.7,379.23z M172.14,378.85l1.81-1.49
			c0.05-0.04,0.12-0.03,0.16,0.02c0.04,0.05,0.03,0.12-0.02,0.16l-1.81,1.49c-0.02,0.02-0.05,0.03-0.07,0.03
			c-0.03,0-0.06-0.01-0.09-0.04C172.08,378.97,172.09,378.89,172.14,378.85z M177.53,380.35l-0.96,2.25
			c-0.02,0.04-0.06,0.07-0.1,0.07c-0.02,0-0.03,0-0.04-0.01c-0.06-0.02-0.08-0.09-0.06-0.15l0.96-2.25
			c0.02-0.06,0.09-0.08,0.15-0.06C177.53,380.22,177.56,380.29,177.53,380.35z M180.12,381.6l-1.27,4.38
			c-0.01,0.05-0.06,0.08-0.11,0.08c-0.01,0-0.02,0-0.03,0c-0.06-0.02-0.09-0.08-0.08-0.14l1.27-4.38c0.02-0.06,0.08-0.09,0.14-0.08
			C180.11,381.48,180.14,381.54,180.12,381.6z M192.44,382.03c1.02-0.55,2-1.25,2.88-2.12c2.07-2.05,2.67-4.12,2.68-4.14
			c0.02-0.06,0.08-0.1,0.14-0.08c0.06,0.02,0.1,0.08,0.08,0.14c-0.02,0.06-1.16,3.9-5.66,6.38l0.79,1.22
			c0.03,0.05,0.02,0.12-0.03,0.16c-0.02,0.01-0.04,0.02-0.06,0.02c-0.04,0-0.07-0.02-0.09-0.05l-0.79-1.23
			c-2.14,1.13-5.01,1.96-8.84,1.96c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11c2.53,0,5.79-0.39,8.72-1.92l-0.73-1.13
			c-2.19,1.11-4.8,1.68-7.82,1.68c-0.1,0-0.2,0-0.29,0c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11c0,0,0,0,0,0
			c3.1,0.04,5.77-0.52,7.99-1.64l-0.71-1.11c-1.84,1.04-3.96,1.64-6.21,1.64c-0.16,0-0.31,0-0.47-0.01
			c-6.97-0.26-12.43-6.13-12.17-13.1c0-0.04,0.01-0.09,0.01-0.13l-1.94-0.07c-0.06,0-0.11-0.05-0.11-0.12
			c0-0.06,0.05-0.11,0.12-0.11l1.95,0.07c0.17-2.59,1.11-5.03,2.7-7.03l-4-2.97c-0.05-0.04-0.06-0.11-0.02-0.16
			c0.04-0.05,0.11-0.06,0.16-0.02l4.01,2.98c0.36-0.43,0.74-0.85,1.16-1.24c2.47-2.3,5.7-3.5,9.07-3.37
			c0.15,0.01,0.31,0.02,0.46,0.03l0.26-1.01l0.1,0.01c0.05,0.01,5.47,0.56,7.94,3.75l0.05,0.06l-0.46,0.73
			c2.38,2.31,3.85,5.54,3.84,9.08l0.95,0.03c0.06,0,0.11,0.05,0.11,0.12c0,0.06-0.05,0.11-0.11,0.11c0,0,0,0,0,0l-0.95-0.03
			c0,0.08,0,0.15,0,0.23c-0.16,4.46-2.64,8.31-6.22,10.43l0.72,1.11c1.06-0.56,2.01-1.25,2.86-2.08c4.08-4,4.17-9.79,4.17-9.85
			c0-0.06,0.05-0.11,0.11-0.11c0,0,0,0,0,0c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.1,5.94-4.24,10c-0.86,0.84-1.82,1.54-2.89,2.11
			L192.44,382.03z M206.98,307.73h-1.62c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h1.62c0.06,0,0.11,0.05,0.11,0.11
			C207.09,307.68,207.04,307.73,206.98,307.73z M207.48,307.01v-1.88c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11v1.88
			c0,0.06-0.05,0.11-0.11,0.11C207.53,307.13,207.48,307.07,207.48,307.01z M207.75,310.36c0,0.06-0.05,0.11-0.11,0.11
			c-0.06,0-0.11-0.05-0.11-0.11v-2.2c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11V310.36z M209.84,307.73h-1.75
			c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h1.75c0.06,0,0.11,0.05,0.11,0.11
			C209.95,307.68,209.9,307.73,209.84,307.73z"
          />
          <polygon fill="#1EBEE4" points="192.84,274.43 193.82,275.17 195.04,274.66 194.09,272.86 		" />
          <path
            fill="#1EBEE4"
            d="M175.11,296.26c-0.64-0.45-1.41-0.71-2.25-0.71c-2.16,0-3.92,1.76-3.92,3.92c0,2.16,1.76,3.92,3.92,3.92
			c1.99,0,3.64-1.5,3.88-3.43l-4.1-0.61L175.11,296.26z"
          />
          <path
            fill="#1EBEE4"
            d="M183.15,369.98c0.42,0.45,0.99,0.71,1.61,0.74c0.62,0.03,1.21-0.2,1.66-0.62
			c0.45-0.42,0.71-0.99,0.74-1.61c0.02-0.62-0.2-1.21-0.62-1.66c-0.42-0.45-0.99-0.71-1.61-0.74c-0.03,0-0.06,0-0.09,0
			c-0.7,0-1.34,0.32-1.76,0.82l2.08,1.55c0.05,0.04,0.06,0.11,0.02,0.16c-0.02,0.03-0.06,0.05-0.09,0.05
			c-0.02,0-0.05-0.01-0.07-0.02l-2.09-1.55c-0.24,0.35-0.39,0.77-0.41,1.23C182.51,368.94,182.73,369.53,183.15,369.98z"
          />
          <polygon
            fill="#1EBEE4"
            points="195.24,274.57 196.47,274.05 196.79,270.97 194.54,269.4 191.96,270.25 191.91,270.6 
			194.09,272.49 194.13,272.45 		"
          />
          <path
            fill="#1EBEE4"
            d="M106.69,282.86c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S106.39,282.86,106.69,282.86z"
          />
          <path
            fill="#E1D9C8"
            d="M60.69,462.05v-38.43c-0.5-0.2-1.03-0.36-1.61-0.46v40.13c0.32-0.18,0.66-0.42,1.03-0.73
			C60.31,462.4,60.5,462.23,60.69,462.05z"
          />
          <path
            fill="#E1D9C8"
            d="M64.27,457.95v-31.32c-0.46-0.67-0.98-1.27-1.57-1.77v35.09c0.55-0.64,1.08-1.31,1.59-2H64.27z"
          />
          <path
            fill="#E1D9C8"
            d="M62.4,424.61c-0.44-0.34-0.9-0.63-1.41-0.87v38.02c0.49-0.47,0.95-0.96,1.41-1.48V424.61z"
          />
          <path
            fill="#E1D9C8"
            d="M58.78,463.45v-40.32c-0.59-0.08-1.22-0.1-1.89-0.06v40.21c0.13,0.11,0.26,0.2,0.41,0.26
			C57.73,463.74,58.22,463.7,58.78,463.45z"
          />
          <path
            fill="#E1D9C8"
            d="M41.53,420.43c2.21,3.01,5.42,3.08,11.17,2.66c-0.25-5.1-0.5-8.18-1.39-10.51
			c-0.66-1.72-1.46-2.5-2.28-2.82C45.87,412.71,43.34,416.23,41.53,420.43z"
          />
          <path
            fill="#E1D9C8"
            d="M69.9,449.75c-0.58-2.5-0.99-5.24-1.4-7.92c-0.55-3.63-1.1-7.3-2.06-10.43v23.5
			c0.39-0.57,0.77-1.15,1.14-1.72C68.36,452,69.12,450.85,69.9,449.75z"
          />
          <path
            fill="#E1D9C8"
            d="M53.73,437.12c0.18,2.76,0.29,5.59,0.4,8.34c0.1,2.45,0.19,4.87,0.33,7.1v-29.29
			c-0.5,0.04-0.99,0.08-1.45,0.11c0.04,0.76,0.07,1.56,0.11,2.4C53.27,428.81,53.44,432.58,53.73,437.12z"
          />
          <path
            fill="#E1D9C8"
            d="M66.14,430.5L66.14,430.5c-0.43-1.26-0.94-2.41-1.57-3.41v30.49c0.54-0.73,1.05-1.48,1.57-2.23V430.5z"
          />
          <path
            fill="#E1D9C8"
            d="M56.59,423.09c-0.63,0.05-1.24,0.09-1.82,0.14c0,0,0,0,0,0v32.32H54.7c0.34,3.49,0.89,6.22,1.89,7.43
			V423.09z"
          />
          <path
            fill="#E1D9C8"
            d="M49.27,409.53c0.84,0.37,1.66,1.2,2.32,2.94c0.9,2.38,1.16,5.47,1.41,10.6c0.56-0.04,1.14-0.09,1.75-0.14
			c0.63-0.05,1.29-0.1,1.98-0.15c9.11-0.68,10.61,9.32,12.07,19c0.39,2.58,0.79,5.22,1.33,7.64c2.01-2.8,4.12-5.19,6.73-6.43
			c1.86-0.89,3.32-1.05,4.45-0.49c1.88,0.91,2.48,3.56,3.05,6.12c0.19,0.84,0.37,1.63,0.59,2.35c1.22,3.97,4.27,4.62,4.3,4.63
			l-0.06,0.3c-0.03-0.01-3.26-0.7-4.53-4.84c-0.22-0.73-0.4-1.53-0.6-2.37c-0.56-2.49-1.15-5.07-2.89-5.92
			c-1.05-0.51-2.42-0.35-4.19,0.5c-2.62,1.25-4.74,3.69-6.77,6.55c0.25,1.07,0.53,2.09,0.86,3.04c1.74,5.06,4.8,9.92,8.64,13.66
			c3.47,3.39,7.03,5.32,9.51,5.16l0.02,0.3c-0.1,0.01-0.2,0.01-0.31,0.01c-5.34,0-14.46-8.3-18.14-19.04
			c-0.3-0.88-0.57-1.83-0.8-2.81c-0.72,1.03-1.43,2.11-2.16,3.2c-0.46,0.7-0.92,1.4-1.4,2.1v0.13h-0.09
			c-1.14,1.69-2.34,3.34-3.65,4.83v0.08h-0.07c-0.73,0.83-1.5,1.61-2.32,2.31c-0.92,0.79-1.71,1.17-2.41,1.17
			c-0.25,0-0.49-0.05-0.72-0.15c-2.67-1.16-2.98-9.13-3.34-18.36c-0.11-2.74-0.22-5.58-0.4-8.33c-0.3-4.54-0.47-8.32-0.61-11.35
			c-0.04-0.84-0.08-1.64-0.11-2.4c-5.81,0.42-9.08,0.36-11.33-2.6c-1.95,4.67-3,10.16-3,16.63l0-2.16
			c-0.01,0.19-0.02,0.38-0.02,0.58v7.84c0,32.25,28.71,44.84,42.95,48.65l4.05,1.04c8.43,2.74,15.43-3.28,15.43-12.77
			c0,0-0.04-53.68-0.07-85.58C79.58,395.92,60.58,399.15,49.27,409.53z M75.95,417.72l-1.62-0.66l0.09-0.21l1.62,0.66L75.95,417.72z
			 M76.49,415.37h0.23v1.88h-0.23V415.37z M76.76,420.62h-0.23v-2.2h0.23V420.62z M78.8,418.89l-1.75-0.72l0.09-0.21l1.75,0.72
			L78.8,418.89z M89.67,479.85h-0.3v-63.31h0.3V479.85z"
          />
          <path
            fill="#E9E8DB"
            d="M64.56,359.4c-10.42,3.53-15.05,7.12-15.09,7.16c-0.05,0.04-0.06,0.11-0.02,0.16
			c0.02,0.03,0.06,0.04,0.09,0.04c0.02,0,0.05-0.01,0.07-0.02c0.05-0.04,4.64-3.6,15.02-7.12c6.87-2.33,17.35-4.98,31.84-6.3
			c0.01,0,0.03,0,0.04,0c5.72-0.52,12.06-0.83,19.06-0.83h93.99c0.06,0,0.11-0.05,0.11-0.11s-0.05-0.11-0.11-0.11h-93.99
			c-7.05,0-13.44,0.32-19.19,0.84c-0.01,0-0.03,0-0.04,0C81.89,354.42,71.43,357.07,64.56,359.4z"
          />
          <polygon
            fill="#E9E8DB"
            points="129.02,381.25 129.02,376.99 125.48,375.04 121.91,377.19 121.91,381.42 123.72,382.5 
			124.75,380.53 124.95,380.63 123.92,382.62 125.52,383.57 		"
          />
          <path
            fill="#42637C"
            d="M129.08,385.4l0.06,0l3.74-6.4l0.03-0.06l-3.61-6.02l-0.03-0.06l-7.32,0.1l-3.74,6.41l3.57,6.45l0.24-0.01
			l-4.69,9.05l-1.36,2.63l-0.01,6.4h10.35v-0.23h-10.12v-6.1l1.41-2.71l4.7-9.06L129.08,385.4z M121.9,385.59l-3.43-6.22l3.61-6.19
			l7.06-0.09l3.51,5.85l-3.64,6.24l-6.61,0.38l1.53-2.94l1.03-1.98l-0.2-0.1l-1.02,1.97l-1.59,3.07L121.9,385.59z"
          />
          <polygon
            fill="#42637C"
            points="133.94,383.28 133.94,385.48 135.81,386.6 137.63,385.39 137.63,383.18 135.79,382.17 		"
          />
          <rect x="119.15" y="400.03" fill="#42637C" width="7.02" height="0.23" />
          <rect x="119.16" y="401.55" fill="#42637C" width="7.06" height="0.23" />
          <path
            fill="#42637C"
            d="M68.83,344.07c0.5,0.8,1.01,1.64,0.37,3.38c-1.48,4.02-0.42,5.61-0.37,5.67c0.01,0.01,0.52,0.84,1.51,0.84
			c0.13,0,0.26-0.01,0.4-0.04c2.92-0.63,3.55-0.9,4.7-1.37c0.2-0.08,0.42-0.17,0.67-0.27c1.85-0.74,1.9-1.08,1.79-2.15
			c-0.1-0.95-0.27-2.53,1.25-5.78c0.18-0.38,0.37-0.73,0.58-1.06c0,0,0-0.01,0.01-0.01c2.38-3.76,6.79-4.61,10.33-4.08
			c1.83,0.27,4.89,1.61,4.89,7.55c0,2.28,2.37,2.47,4.59,2.47l4.94,0.01c12.3,0.02,41.85-0.02,50.53-0.73
			c0.96-0.08,1.7-0.9,1.67-1.86c-0.07-2.21,0.19-6.31,2.65-8.87c1.4-1.46,3.35-2.21,5.77-2.23c0.05,0,0.1,0,0.15,0
			c8.72,0,9.47,6.9,9.82,10.22c0.02,0.22,0.05,0.43,0.07,0.61c0.07,0.61,0.61,1.06,1.23,1.01c2.38-0.18,8.03-0.62,9.04-1
			c4.88-1.82,2.91-9.71,1.73-14.42c-0.39-1.56-0.72-2.9-0.56-3.16c0.05-0.09,0.06-0.18,0.02-0.27c-0.39-0.83-5.87-1.61-11.46-2.33
			c-1.05-0.14-1.87-0.24-2.22-0.3c-0.31-0.06-1.17-0.35-2.48-0.8c-5.89-2.04-19.67-6.8-32.72-6.8c-10.93,0-17.33,3.93-22,6.81
			c-2.15,1.32-3.85,2.37-5.38,2.62l-2.49,0.2c-0.07,0-7.29,0.47-15.21,1.48c-12.13,1.54-19.88,6.38-23.09,10.39
			c-0.2,0.25-0.36,0.47-0.51,0.69c0,0-0.01,0.01-0.01,0.01C67.77,342.33,68.28,343.18,68.83,344.07z M69.26,340.51
			c0,0,0.01-0.01,0.01-0.01c0.13-0.19,0.28-0.4,0.46-0.62c3.18-3.97,10.88-8.77,22.94-10.3c7.91-1.01,15.12-1.47,15.2-1.48l2.5-0.2
			c1.58-0.26,3.3-1.32,5.47-2.65c4.65-2.86,11.02-6.77,21.89-6.77c13.01,0,26.77,4.76,32.65,6.79c1.31,0.45,2.18,0.75,2.51,0.81
			c0.36,0.06,1.19,0.17,2.23,0.31c3.27,0.42,10.91,1.41,11.28,2.2c0.01,0.02,0.01,0.03,0,0.06c-0.21,0.33,0.02,1.31,0.53,3.34
			c1.16,4.64,3.1,12.4-1.59,14.16c-0.98,0.37-6.61,0.81-8.98,0.99c-0.49,0.04-0.92-0.32-0.98-0.81c-0.02-0.18-0.04-0.39-0.07-0.61
			c-0.34-3.13-1.12-10.42-10.05-10.42c-0.05,0-0.1,0-0.15,0c-2.49,0.02-4.49,0.8-5.93,2.3c-2.52,2.62-2.78,6.78-2.71,9.03
			c0.03,0.84-0.62,1.56-1.46,1.63c-8.67,0.72-38.21,0.75-50.51,0.73l-4.94-0.01c-2.7,0-4.37-0.38-4.37-2.24
			c0-2.62-0.66-7.11-5.08-7.77c-3.63-0.54-8.16,0.34-10.59,4.25c0,0,0,0.01-0.01,0.01c-0.2,0.32-0.39,0.66-0.55,1.02
			c-1.54,3.31-1.37,4.93-1.27,5.9c0.1,0.95,0.13,1.21-1.65,1.92c-0.25,0.1-0.47,0.19-0.67,0.27c-1.14,0.47-1.76,0.73-4.66,1.36
			c-1.08,0.23-1.64-0.65-1.67-0.7c-0.01-0.02-1.04-1.58,0.4-5.47c0.68-1.85,0.11-2.77-0.39-3.58
			C68.49,343.08,68.02,342.31,69.26,340.51z"
          />
          <path
            fill="#E9E8DB"
            d="M65.63,340.35c-1.22,3.74-1.43,6.76-1.61,9.18c-0.27,3.84-0.44,6.15-4.19,7.8
			c-6.92,3.03-11.32,5.43-11.36,5.45c-0.05,0.03-0.08,0.1-0.05,0.15c0.02,0.04,0.06,0.06,0.1,0.06c0.02,0,0.04,0,0.05-0.01
			c0.04-0.02,4.44-2.41,11.34-5.44c3.87-1.7,4.05-4.2,4.32-7.99c0.17-2.41,0.39-5.4,1.6-9.12c0.1-0.3,0.21-0.6,0.35-0.89
			c0,0,0-0.01,0.01-0.01c4.19-9.25,24.86-13.76,40.08-17.08c5.55-1.21,9.08-2.8,12.51-4.35c5-2.25,9.32-4.2,18.62-4.38
			c0.2,0,0.41-0.01,0.61-0.01c10.12,0,25.62,4.54,34.07,7.28c1.79,0.58,3.54,1.08,5.22,1.57c3.3,0.95,6.42,1.85,9.34,3.18
			c2.8,1.27,4.93,3.68,6.72,7.58c0.55,1.21,1.07,2.51,1.57,3.76c1.89,4.75,3.67,9.23,7.42,9.23h7.15c0.06,0,0.11-0.05,0.11-0.11
			c0-0.06-0.05-0.11-0.11-0.11h-7.15c-3.6,0-5.35-4.41-7.21-9.09c-0.5-1.25-1.02-2.55-1.57-3.77c-1.81-3.96-3.98-6.4-6.83-7.69
			c-2.94-1.33-6.06-2.23-9.37-3.19c-1.69-0.49-3.43-0.99-5.22-1.57c-8.64-2.8-24.63-7.48-34.76-7.29
			c-9.34,0.18-13.89,2.23-18.71,4.4c-3.41,1.54-6.94,3.13-12.46,4.33c-19.02,4.15-36.39,8.6-40.25,17.23c0,0,0,0.01-0.01,0.01
			C65.85,339.75,65.73,340.05,65.63,340.35z"
          />
          <path
            fill="#E9E8DB"
            d="M60.71,345.29c-0.11,0.63-0.2,1.21-0.28,1.72c-0.45,3.02-0.64,4.26-4.09,5.94
			c-4.61,2.25-7.82,3.43-7.85,3.44c-0.06,0.02-0.09,0.09-0.07,0.15c0.02,0.05,0.06,0.07,0.11,0.07c0.01,0,0.03,0,0.04-0.01
			c0.03-0.01,3.24-1.2,7.87-3.45c3.55-1.73,3.75-3.02,4.21-6.11c0.08-0.51,0.16-1.09,0.27-1.72c0.48-2.73,1-5.03,1.84-7.07
			c0-0.01,0-0.01,0.01-0.02c2.06-4.96,6.04-8.31,16.14-12.25c9.76-3.81,15.8-5.44,21.13-6.88c5.18-1.4,9.66-2.61,16.41-5.41
			c11.69-4.85,26.02-5.55,39.32-1.91c10.11,2.77,16.39,4.64,24.34,7.02l3.55,1.06c9.23,2.75,12.21,7.96,14.18,11.41
			c0.11,0.2,0.22,0.39,0.33,0.58c0.65,1.12,1.1,1.94,1.46,2.6c0.61,1.12,0.98,1.8,1.7,2.79c1.88,2.6,7.94,2.18,8.2,2.17
			c0.06,0,0.11-0.06,0.1-0.12c0-0.06-0.06-0.11-0.12-0.1c-0.06,0.01-6.19,0.42-8-2.07c-0.71-0.99-1.08-1.66-1.69-2.77
			c-0.36-0.66-0.81-1.48-1.46-2.6c-0.11-0.19-0.22-0.38-0.33-0.58c-1.99-3.48-4.99-8.74-14.31-11.52l-3.55-1.06
			c-7.95-2.38-14.23-4.26-24.35-7.02c-13.35-3.65-27.73-2.95-39.46,1.92c-6.74,2.8-11.2,4-16.38,5.4
			c-5.33,1.44-11.38,3.07-21.15,6.89c-10.19,3.98-14.2,7.36-16.27,12.38c0,0.01,0,0.01-0.01,0.02
			C61.71,340.23,61.19,342.55,60.71,345.29z"
          />
          <path
            fill="#E9E8DB"
            d="M56.32,341.92c-1.17,4.59-1.17,4.59-3.83,6.91l-0.15,0.13c-1.21,1.05-4.43,2.29-4.46,2.3
			c-0.06,0.02-0.09,0.09-0.07,0.15c0.02,0.05,0.06,0.07,0.11,0.07c0.01,0,0.03,0,0.04-0.01c0.13-0.05,3.3-1.26,4.53-2.34l0.15-0.13
			c2.71-2.36,2.71-2.36,3.9-7.03c0.36-1.4,0.85-3.31,1.55-5.83c0-0.01,0-0.01,0.01-0.02c0.03-0.1,0.05-0.19,0.08-0.28
			c1.2-4.28,3.91-7.48,8.51-10.06c4.39-2.46,10.73-4.52,20.55-6.69c13.14-2.9,17.62-4.81,22.36-6.82c3.21-1.36,6.52-2.77,12.67-4.61
			c17.31-5.19,31.13-0.87,43.32,2.94c3.4,1.06,6.61,2.06,9.68,2.78c14.65,3.42,21.01,8.92,25.66,12.93l0.16,0.13
			c4.67,4.03,8.69,3.69,8.73,3.69c0.06-0.01,0.11-0.06,0.1-0.12c-0.01-0.06-0.06-0.11-0.12-0.1c-0.04,0-3.97,0.33-8.56-3.63
			l-0.16-0.13c-4.66-4.03-11.05-9.54-25.75-12.98c-3.06-0.72-6.27-1.72-9.66-2.78c-12.22-3.82-26.08-8.15-43.45-2.94
			c-6.16,1.85-9.48,3.26-12.7,4.62c-4.73,2.01-9.2,3.91-22.32,6.81c-9.84,2.17-16.2,4.25-20.61,6.72c-4.66,2.61-7.4,5.85-8.62,10.19
			c-0.02,0.08-0.04,0.16-0.07,0.24c0,0.01,0,0.01-0.01,0.02C57.18,338.59,56.68,340.51,56.32,341.92z"
          />
          <path
            fill="#659896"
            d="M74.87,365.53c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S74.57,365.53,74.87,365.53z"
          />
          <path
            fill="#659896"
            d="M68.79,364.57c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
			S68.5,364.57,68.79,364.57z"
          />
          <path
            fill="#42637C"
            d="M83.75,303.5l-0.05,0c-0.08,0-8.41-0.28-16.12,3.41l0.1,0.2c7.35-3.52,15.27-3.41,15.97-3.39l8.63,10.02
			l0.17-0.15l-8.66-10.05L83.75,303.5z"
          />
          <path
            fill="#42637C"
            d="M72.98,275.87l-0.05-0.22c-0.05,0.01-5.03,1.18-10.92,4.79c-5.44,3.33-12.79,9.48-16.94,19.86l0.21,0.08
			c4.13-10.32,11.44-16.43,16.84-19.74C67.98,277.04,72.93,275.88,72.98,275.87z"
          />
          <path
            fill="#42637C"
            d="M81.37,304.99c-9.4,0.89-13.49,3.51-13.53,3.53l0.12,0.19c0.04-0.03,4.09-2.61,13.42-3.5L81.37,304.99z"
          />
          <path
            fill="#42637C"
            d="M68.16,310.12l0.09,0.21c5.8-2.6,13.13-3.62,13.21-3.63l-0.03-0.22
			C81.36,306.48,73.99,307.51,68.16,310.12z"
          />
          <path
            fill="#E9E8DB"
            d="M85.89,278.87c0-0.06-0.05-0.11-0.11-0.11c-18.53,0-28.48,9.38-28.57,9.47c-0.05,0.04-0.05,0.12,0,0.16
			c0.02,0.02,0.05,0.03,0.08,0.03c0.03,0,0.06-0.01,0.08-0.03c0.02-0.02,2.5-2.38,7.22-4.7c4.35-2.15,11.57-4.7,21.2-4.7
			C85.84,278.99,85.89,278.94,85.89,278.87z"
          />
          <path
            fill="#42637C"
            d="M65.79,286.43l0.09,0.21c0.02-0.01,2.46-0.99,7.22-1.95c4.4-0.89,11.75-1.95,21.76-1.95v-0.23
			c-10.04,0-17.4,1.07-21.82,1.96C68.25,285.43,65.81,286.42,65.79,286.43z"
          />
          <rect x="154.49" y="266.05" fill="#42637C" width="1.6" height="10.42" />
          <rect x="160.52" y="273.62" fill="#42637C" width="1.53" height="2.85" />
          <rect x="157.51" y="271.57" fill="#E9E8DB" width="1.59" height="4.9" />
          <path
            fill="#E9E8DB"
            d="M175.31,277.54c0-0.06-0.05-0.11-0.11-0.11h-20.97c-0.06,0-0.11,0.05-0.11,0.11
			c0,0.06,0.05,0.11,0.11,0.11h20.97C175.26,277.65,175.31,277.6,175.31,277.54z"
          />
          <polygon
            fill="#42637C"
            points="192.79,289.67 200.31,284.89 200.19,284.7 192.57,289.55 192.57,298.5 195.14,300.37 
			195.27,300.18 192.79,298.38 		"
          />
          <path
            fill="#E9E8DB"
            d="M205.03,296.39v-5.27l-4.38-2.41l-4.42,2.66v5.23l4.47,2.66L205.03,296.39z M196.46,296.47v-4.98l4.2-2.53
			l4.15,2.28v5.02l-4.12,2.73L196.46,296.47z"
          />
          <path
            fill="#42637C"
            d="M194.31,290.58v6.9l6.47,3.83l6.09-3.92v-7.28l-6.31-3.67L194.31,290.58z M206.65,290.24v7.03l-5.87,3.78
			l-6.24-3.7v-6.65l6.04-3.99L206.65,290.24z"
          />
          <path
            fill="#E9E8DB"
            d="M191.96,270.25l2.58-0.86l2.25,1.57l-0.32,3.08l-1.22,0.52l-1.12-2.12l-0.03,0.04l0.09,0.08l-0.15,0.17
			l-0.08-0.07l-1.3,1.63l-1.14-0.86l0.35-2.57l-0.2-0.18l-0.39,2.84l1.24,0.93l-17.26,21.62c-0.68-0.48-1.5-0.76-2.39-0.76
			c-2.29,0-4.14,1.86-4.14,4.14c0,2.29,1.86,4.14,4.14,4.14c2.11,0,3.85-1.58,4.11-3.62l22.94,3.42c0.14,0.22,0.37,0.35,0.62,0.35
			c0.11,0,0.22-0.02,0.32-0.07c0.34-0.17,0.49-0.57,0.36-0.92l7.18-4.48v-9.06l-4.68-2.71c0.1-0.16,0.17-0.36,0.17-0.56
			c0-0.58-0.47-1.06-1.06-1.06c-0.58,0-1.06,0.47-1.06,1.06c0,0.58,0.47,1.06,1.06,1.06c0.29,0,0.56-0.12,0.75-0.31l4.59,2.67v8.8
			l-7.07,4.41c-0.21-0.26-0.58-0.35-0.89-0.2c-0.3,0.15-0.45,0.48-0.39,0.79l-22.82-3.4c0.01-0.1,0.02-0.2,0.02-0.3
			c0-1.31-0.62-2.49-1.57-3.24l17.26-21.62l1.1,0.83l1.35-0.57l4.4,8.36c0.03,0.06,0.1,0.08,0.15,0.05c0.06-0.03,0.08-0.1,0.05-0.15
			l-4.39-8.34l1.33-0.57l0.35-3.34l-2.46-1.71l-2.82,0.94l-0.05,0.34l0.2,0.18L191.96,270.25z M203.52,286.42
			c-0.04,0-0.09,0.02-0.11,0.06c-0.02,0.03-0.02,0.06-0.01,0.09c-0.15,0.14-0.35,0.23-0.57,0.23c-0.46,0-0.83-0.37-0.83-0.83
			c0-0.46,0.37-0.83,0.83-0.83c0.46,0,0.83,0.37,0.83,0.83C203.66,286.13,203.61,286.29,203.52,286.42z M200.31,302.58
			c0.25-0.12,0.55-0.02,0.68,0.23c0.12,0.25,0.02,0.55-0.23,0.68s-0.55,0.02-0.68-0.23C199.96,303.01,200.06,302.7,200.31,302.58z
			 M172.87,303.38c-2.16,0-3.92-1.76-3.92-3.92c0-2.16,1.76-3.92,3.92-3.92c0.84,0,1.61,0.27,2.25,0.71l-2.46,3.08l4.1,0.61
			C176.51,301.89,174.86,303.38,172.87,303.38z M176.79,299.47c0,0.09-0.01,0.18-0.01,0.27l-3.7-0.55l2.22-2.78
			C176.2,297.12,176.79,298.22,176.79,299.47z M193.82,275.17l-0.99-0.74l1.25-1.57l0.95,1.8L193.82,275.17z"
          />
          <rect x="132.72" y="271.23" fill="#42637C" width="0.23" height="15.97" />
          <rect x="103.02" y="269.49" fill="#42637C" width="0.23" height="28.23" />
          <path
            fill="#E9E8DB"
            d="M119.97,285c0-0.06-0.05-0.11-0.11-0.11h-14.39c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11h14.39
			C119.92,285.11,119.97,285.06,119.97,285z"
          />
          <path
            fill="#E9E8DB"
            d="M105.68,287c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h10.41c0.06,0,0.11-0.05,0.11-0.11
			c0-0.06-0.05-0.11-0.11-0.11H105.68z"
          />
          <path
            fill="#E9E8DB"
            d="M123.9,289.01h-18.22c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h18.22
			c0.06,0,0.11-0.05,0.11-0.11C124.01,289.06,123.96,289.01,123.9,289.01z"
          />
          <path
            fill="#42637C"
            d="M106.69,283.09c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
			C105.92,282.75,106.26,283.09,106.69,283.09z M106.69,281.79c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
			c-0.3,0-0.54-0.24-0.54-0.54S106.39,281.79,106.69,281.79z"
          />
          <path
            fill="#42637C"
            d="M109.7,283.09c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
			C108.94,282.75,109.28,283.09,109.7,283.09z M109.7,281.79c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
			c-0.3,0-0.54-0.24-0.54-0.54S109.4,281.79,109.7,281.79z"
          />
          <path
            fill="#42637C"
            d="M112.72,283.09c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
			C111.95,282.75,112.29,283.09,112.72,283.09z M112.72,281.79c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
			c-0.3,0-0.54-0.24-0.54-0.54S112.42,281.79,112.72,281.79z"
          />
          <path
            fill="#42637C"
            d="M115.73,283.09c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
			C114.97,282.75,115.31,283.09,115.73,283.09z M115.73,281.79c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
			c-0.3,0-0.54-0.24-0.54-0.54S115.43,281.79,115.73,281.79z"
          />
          <polygon
            fill="#42637C"
            points="134.67,281.92 147.66,281.92 143.71,301.41 143.94,301.45 147.91,281.83 147.94,281.69 
			134.67,281.69 		"
          />
          <rect x="134.9" y="283.23" fill="#42637C" width="8.4" height="0.23" />
          <path
            fill="#E9E8DB"
            d="M138.91,275.11l-0.07-2.48l-1.96-1.01l-1.97,0.96l-0.01,2.57l2.03,1.14L138.91,275.11z M135.12,275.03
			l0.01-2.29l1.74-0.85l1.74,0.9l0.07,2.21l-1.76,1.05L135.12,275.03z"
          />
          <rect x="134.9" y="284.61" fill="#42637C" width="8.17" height="0.23" />
          <path
            fill="#42637C"
            d="M193.55,360.25c2.15,2.32,3.32,5.31,3.32,8.45l0.23,0.01c0-3.54-1.46-6.77-3.84-9.08l-0.12,0.19
			C193.27,359.96,193.41,360.11,193.55,360.25z"
          />
          <path
            fill="#42637C"
            d="M193.08,359.48c-1.99-1.87-4.59-3.1-7.48-3.36l-0.06,0.22c2.79,0.24,5.38,1.41,7.42,3.33L193.08,359.48z"
          />
          <path
            fill="#42637C"
            d="M175.99,359.61c2.32-2.15,5.3-3.32,8.44-3.32c0.16,0,0.31,0,0.47,0.01c0.14,0.01,0.27,0.02,0.41,0.02
			l0.06-0.22c-0.15-0.01-0.3-0.02-0.46-0.03c-3.37-0.12-6.6,1.07-9.07,3.37c-0.42,0.39-0.8,0.8-1.16,1.24l0.18,0.14
			C175.21,360.39,175.59,359.99,175.99,359.61z"
          />
          <path
            fill="#42637C"
            d="M174.72,360.99l-0.18-0.13c-1.6,2.01-2.53,4.44-2.7,7.03l0.23,0.01
			C172.22,365.35,173.15,362.96,174.72,360.99z"
          />
          <path
            fill="#E9E8DB"
            d="M185.05,357.34l0.14,0.01c3.87,0.14,7.24,3.25,7.27,3.28l0.1,0.09l0.57-0.89l0.12-0.19l0.46-0.73
			l-0.05-0.06c-2.47-3.19-7.88-3.75-7.94-3.75l-0.1-0.01l-0.26,1.01l-0.06,0.22L185.05,357.34z M185.54,356.34l0.06-0.22l0.2-0.78
			c0.73,0.09,5.39,0.76,7.63,3.59l-0.35,0.56l-0.12,0.19l-0.45,0.7c-0.59-0.51-3.66-3.05-7.17-3.25L185.54,356.34z"
          />
          <path
            fill="#42637C"
            d="M182.99,370.14c0.46,0.5,1.09,0.78,1.76,0.81c0.03,0,0.06,0,0.1,0c0.64,0,1.25-0.24,1.72-0.68
			c0.5-0.46,0.78-1.09,0.81-1.77c0.02-0.68-0.22-1.32-0.68-1.82c-0.46-0.5-1.09-0.78-1.76-0.81c-0.82-0.04-1.55,0.33-2.04,0.91
			l0.18,0.14c0.43-0.5,1.06-0.82,1.76-0.82c0.03,0,0.06,0,0.09,0c0.62,0.02,1.19,0.28,1.61,0.74c0.42,0.45,0.64,1.04,0.62,1.66
			c-0.02,0.62-0.28,1.19-0.74,1.61c-0.45,0.42-1.04,0.64-1.66,0.62c-0.62-0.02-1.19-0.28-1.61-0.74c-0.42-0.45-0.64-1.04-0.62-1.66
			c0.02-0.46,0.17-0.88,0.41-1.23l-0.18-0.14c-0.27,0.39-0.43,0.85-0.45,1.36C182.28,369,182.52,369.64,182.99,370.14z"
          />
          <path
            fill="#42637C"
            d="M185.07,361.29l-0.01,0.23l0.03,0c0.91,0.03,1.79,0.24,2.62,0.62l0.09-0.21c-0.85-0.39-1.76-0.61-2.7-0.64
			L185.07,361.29z"
          />
          <path
            fill="#42637C"
            d="M179.98,363.21l0.15,0.16c0.67-0.62,1.43-1.1,2.28-1.42l-0.08-0.21
			C181.46,362.07,180.66,362.57,179.98,363.21z"
          />
          <path
            fill="#42637C"
            d="M187.26,374.87l0.08,0.21c0.88-0.33,1.67-0.82,2.36-1.46l-0.15-0.17
			C188.88,374.08,188.11,374.55,187.26,374.87z"
          />
          <path
            fill="#42637C"
            d="M191.52,365.92c-0.33-0.88-0.82-1.67-1.46-2.36l-0.17,0.15c0.62,0.67,1.1,1.44,1.41,2.29L191.52,365.92z"
          />
          <path
            fill="#42637C"
            d="M181.99,374.69l-0.09,0.21c0.85,0.39,1.75,0.6,2.69,0.63l0.01-0.11l-0.01-0.11
			C183.68,375.27,182.81,375.06,181.99,374.69z"
          />
          <path
            fill="#42637C"
            d="M178.17,370.91c0.33,0.88,0.82,1.67,1.47,2.36l0.17-0.15c-0.62-0.67-1.1-1.44-1.42-2.28L178.17,370.91z"
          />
          <path
            fill="#42637C"
            d="M178.36,365.47c-0.38,0.85-0.6,1.75-0.63,2.69l0.11,0.02l0.11-0.02c0.03-0.91,0.24-1.78,0.61-2.6
			L178.36,365.47z"
          />
          <path
            fill="#42637C"
            d="M191.12,371.27l0.21,0.09c0.39-0.85,0.6-1.76,0.64-2.69l0-0.02l-0.23,0
			C191.7,369.57,191.49,370.45,191.12,371.27z"
          />
          <path
            fill="#E9E8DB"
            d="M194.63,368.63c-0.07,0-0.11,0.05-0.12,0.11c0,0.06,0.05,0.11,0.11,0.12l2.24,0.08l0.23,0.01l0.95,0.03
			c0,0,0,0,0,0c0.06,0,0.11-0.05,0.11-0.11c0-0.06-0.05-0.11-0.11-0.12l-0.95-0.03l-0.23-0.01L194.63,368.63z"
          />
          <path
            fill="#E9E8DB"
            d="M173.38,368.17C173.39,368.17,173.39,368.17,173.38,368.17c0.06,0,0.11-0.05,0.12-0.11
			c0-0.06-0.05-0.11-0.11-0.12l-1.33-0.05l-0.23-0.01l-1.95-0.07c-0.07,0-0.11,0.05-0.12,0.11c0,0.06,0.05,0.11,0.11,0.12l1.94,0.07
			l0.23,0.01L173.38,368.17z"
          />
          <path
            fill="#E9E8DB"
            d="M183.39,382.46C183.39,382.46,183.39,382.46,183.39,382.46c-0.06,0-0.11,0.05-0.11,0.11
			c0,0.06,0.05,0.11,0.11,0.11c0.1,0,0.2,0,0.29,0c3.02,0,5.63-0.56,7.82-1.68l-0.12-0.19C189.16,381.94,186.49,382.5,183.39,382.46
			z"
          />
          <path
            fill="#E9E8DB"
            d="M198.84,368.79c0-0.06-0.05-0.11-0.11-0.11c0,0,0,0,0,0c-0.06,0-0.11,0.05-0.11,0.11
			c0,0.06-0.1,5.85-4.17,9.85c-0.84,0.83-1.8,1.52-2.86,2.08l0.12,0.19c1.07-0.57,2.04-1.27,2.89-2.11
			C198.74,374.73,198.84,368.85,198.84,368.79z"
          />
          <path
            fill="#E9E8DB"
            d="M181.16,365.78c-0.5,0.7-0.81,1.55-0.85,2.47c-0.09,2.5,1.87,4.6,4.36,4.69c0.06,0,0.11,0,0.17,0
			c1.15,0,2.23-0.43,3.08-1.21c0.89-0.82,1.4-1.94,1.44-3.15c0.09-2.5-1.86-4.6-4.36-4.69c-1.49-0.05-2.84,0.63-3.71,1.71
			l-6.44-4.78l-0.18-0.14l-4.01-2.98c-0.05-0.04-0.12-0.03-0.16,0.02c-0.04,0.05-0.03,0.12,0.02,0.16l4,2.97l0.18,0.13
			L181.16,365.78z M181.34,365.91l1.42,1.05l0.18,0.14l2.09,1.55c0.02,0.02,0.04,0.02,0.07,0.02c0.03,0,0.07-0.02,0.09-0.05
			c0.04-0.05,0.03-0.12-0.02-0.16l-2.08-1.55l-0.18-0.14l-1.42-1.05c0.79-0.99,2.01-1.62,3.36-1.62c0.05,0,0.11,0,0.16,0
			c2.37,0.09,4.23,2.09,4.14,4.46c-0.04,1.15-0.53,2.21-1.37,3c-0.84,0.78-1.94,1.2-3.09,1.15c-2.37-0.09-4.23-2.09-4.14-4.46
			C180.57,367.38,180.87,366.57,181.34,365.91z"
          />
          <path
            fill="#E9E8DB"
            d="M180.05,381.46c-0.06-0.02-0.12,0.02-0.14,0.08l-1.27,4.38c-0.02,0.06,0.02,0.12,0.08,0.14
			c0.01,0,0.02,0,0.03,0c0.05,0,0.09-0.03,0.11-0.08l1.27-4.38C180.14,381.54,180.11,381.48,180.05,381.46z"
          />
          <path
            fill="#E9E8DB"
            d="M177.47,380.2c-0.06-0.03-0.12,0-0.15,0.06l-0.96,2.25c-0.02,0.06,0,0.12,0.06,0.15
			c0.01,0.01,0.03,0.01,0.04,0.01c0.04,0,0.09-0.03,0.1-0.07l0.96-2.25C177.56,380.29,177.53,380.22,177.47,380.2z"
          />
          <path
            fill="#E9E8DB"
            d="M175.53,379.09l-4.08,5.04c-0.04,0.05-0.03,0.12,0.02,0.16c0.02,0.02,0.05,0.03,0.07,0.03
			c0.03,0,0.07-0.01,0.09-0.04l4.08-5.04c0.04-0.05,0.03-0.12-0.02-0.16C175.64,379.04,175.57,379.04,175.53,379.09z"
          />
          <path
            fill="#E9E8DB"
            d="M172.28,379.03l1.81-1.49c0.05-0.04,0.06-0.11,0.02-0.16c-0.04-0.05-0.11-0.06-0.16-0.02l-1.81,1.49
			c-0.05,0.04-0.06,0.11-0.02,0.16c0.02,0.03,0.05,0.04,0.09,0.04C172.23,379.05,172.26,379.05,172.28,379.03z"
          />
          <path
            fill="#42637C"
            d="M190.86,379.6c3.59-2.12,6.06-5.97,6.22-10.43c0-0.08,0-0.15,0-0.23l-0.23-0.01c0,0.08,0,0.15,0,0.23
			c-0.12,3.32-1.53,6.38-3.96,8.64c-0.67,0.62-1.39,1.16-2.16,1.61l-0.56-0.86c-0.03-0.05-0.1-0.07-0.16-0.03
			c-0.05,0.03-0.07,0.1-0.03,0.16l0.55,0.86c-1.98,1.12-4.23,1.68-6.55,1.59c-3.32-0.12-6.38-1.53-8.64-3.96
			c-2.26-2.43-3.43-5.59-3.31-8.91c0-0.04,0.01-0.09,0.01-0.13l-0.23-0.01c0,0.04-0.01,0.09-0.01,0.13
			c-0.26,6.97,5.21,12.85,12.17,13.1c0.16,0.01,0.31,0.01,0.47,0.01c2.25,0,4.37-0.6,6.21-1.64l0.71,1.11l0.12,0.19l0.73,1.13
			c-2.93,1.53-6.19,1.92-8.72,1.92c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11c3.83,0,6.7-0.82,8.84-1.96l0.79,1.23
			c0.02,0.03,0.06,0.05,0.09,0.05c0.02,0,0.04-0.01,0.06-0.02c0.05-0.03,0.07-0.1,0.03-0.16l-0.79-1.22
			c4.51-2.48,5.65-6.33,5.66-6.38c0.02-0.06-0.02-0.12-0.08-0.14c-0.06-0.02-0.12,0.02-0.14,0.08c-0.01,0.02-0.6,2.1-2.68,4.14
			c-0.89,0.87-1.86,1.57-2.88,2.12l-0.73-1.13l-0.12-0.19L190.86,379.6z"
          />
          <path
            fill="#E9E8DB"
            d="M57.86,312.3c-0.06,0-0.11,0.05-0.11,0.11v2.2c0,0.06,0.05,0.11,0.11,0.11c0.06,0,0.11-0.05,0.11-0.11
			v-2.2C57.97,312.35,57.92,312.3,57.86,312.3z"
          />
          <path
            fill="#E9E8DB"
            d="M60.01,310.57l-1.75,0.96c-0.05,0.03-0.07,0.1-0.04,0.15c0.02,0.04,0.06,0.06,0.1,0.06
			c0.02,0,0.04,0,0.05-0.01l1.75-0.96c0.05-0.03,0.07-0.1,0.04-0.15C60.13,310.56,60.06,310.54,60.01,310.57z"
          />
          <path
            fill="#E9E8DB"
            d="M57.15,312.13l-1.62,0.89c-0.05,0.03-0.07,0.1-0.04,0.15c0.02,0.04,0.06,0.06,0.1,0.06
			c0.02,0,0.04,0,0.05-0.01l1.62-0.89c0.05-0.03,0.07-0.1,0.04-0.15C57.27,312.12,57.2,312.1,57.15,312.13z"
          />
          <path
            fill="#E9E8DB"
            d="M57.93,311.29v-1.88c0-0.06-0.05-0.11-0.11-0.11s-0.11,0.05-0.11,0.11v1.88c0,0.06,0.05,0.11,0.11,0.11
			S57.93,311.35,57.93,311.29z"
          />
          <path
            fill="#E9E8DB"
            d="M207.64,308.04c-0.06,0-0.11,0.05-0.11,0.11v2.2c0,0.06,0.05,0.11,0.11,0.11c0.06,0,0.11-0.05,0.11-0.11
			v-2.2C207.75,308.09,207.7,308.04,207.64,308.04z"
          />
          <path
            fill="#E9E8DB"
            d="M209.84,307.51h-1.75c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h1.75
			c0.06,0,0.11-0.05,0.11-0.11C209.95,307.56,209.9,307.51,209.84,307.51z"
          />
          <path
            fill="#E9E8DB"
            d="M206.98,307.51h-1.62c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h1.62
			c0.06,0,0.11-0.05,0.11-0.11C207.09,307.56,207.04,307.51,206.98,307.51z"
          />
          <path
            fill="#E9E8DB"
            d="M207.7,307.01v-1.88c0-0.06-0.05-0.11-0.11-0.11c-0.06,0-0.11,0.05-0.11,0.11v1.88
			c0,0.06,0.05,0.11,0.11,0.11C207.65,307.13,207.7,307.07,207.7,307.01z"
          />
          <rect x="154.9" y="357.31" fill="#42637C" width="0.23" height="2.2" />
          <rect x="155.46" y="356.66" fill="#42637C" width="1.75" height="0.23" />
          <rect x="152.73" y="356.66" fill="#42637C" width="1.62" height="0.23" />
          <rect x="154.85" y="354.28" fill="#42637C" width="0.23" height="1.88" />
          <rect x="89.36" y="416.54" fill="#42637C" width="0.3" height="63.31" />
          <path
            fill="#42637C"
            d="M41.38,420.79c2.25,2.97,5.53,3.03,11.33,2.6c0.04,0.75,0.07,1.55,0.11,2.4
			c0.14,3.03,0.31,6.81,0.61,11.35c0.18,2.75,0.29,5.59,0.4,8.33c0.36,9.22,0.68,17.19,3.34,18.36c0.23,0.1,0.47,0.15,0.72,0.15
			c0.7,0,1.5-0.39,2.41-1.17c0.82-0.7,1.59-1.48,2.32-2.31h0.07v-0.08c1.31-1.49,2.5-3.15,3.65-4.83h0.09v-0.13
			c0.47-0.7,0.94-1.4,1.4-2.1c0.72-1.09,1.44-2.17,2.16-3.2c0.24,0.98,0.5,1.93,0.8,2.81c3.68,10.74,12.81,19.04,18.14,19.04
			c0.1,0,0.21,0,0.31-0.01l-0.02-0.3c-2.47,0.16-6.04-1.77-9.51-5.16c-3.83-3.75-6.9-8.6-8.64-13.66c-0.33-0.95-0.61-1.97-0.86-3.04
			c2.03-2.86,4.15-5.31,6.77-6.55c1.77-0.84,3.14-1.01,4.19-0.5c1.75,0.85,2.33,3.43,2.89,5.92c0.19,0.85,0.37,1.64,0.6,2.37
			c1.27,4.14,4.5,4.83,4.53,4.84l0.06-0.3c-0.03-0.01-3.08-0.66-4.3-4.63c-0.22-0.72-0.4-1.51-0.59-2.35
			c-0.58-2.56-1.18-5.21-3.05-6.12c-1.14-0.55-2.59-0.39-4.45,0.49c-2.61,1.24-4.72,3.63-6.73,6.43c-0.54-2.42-0.94-5.06-1.33-7.64
			c-1.46-9.68-2.96-19.68-12.07-19c-0.69,0.05-1.35,0.1-1.98,0.15c-0.61,0.05-1.19,0.09-1.75,0.14c-0.25-5.13-0.51-8.22-1.41-10.6
			c-0.66-1.73-1.48-2.56-2.32-2.94c-0.01,0-0.02-0.01-0.03-0.01c-0.9-0.39-1.82-0.25-2.59-0.14c-0.64,0.09-1.19,0.17-1.52-0.08
			c-0.21-0.17-0.34-0.49-0.38-0.98c-0.05-0.53-0.07-1.36-0.1-2.42c-0.16-5.93-0.49-18.18-6.27-22.1c0,0-0.01-0.01-0.01-0.01v0.37
			c0,0,0.01,0.01,0.01,0.01c5.48,3.96,5.81,15.93,5.97,21.74c0.03,1.06,0.05,1.9,0.1,2.44c0.05,0.58,0.21,0.96,0.5,1.18
			c0.43,0.34,1.04,0.25,1.75,0.14c0.72-0.11,1.52-0.22,2.3,0.07c0.01,0,0.02,0.01,0.03,0.01c0.82,0.32,1.62,1.09,2.28,2.82
			c0.89,2.34,1.14,5.41,1.39,10.51c-5.75,0.42-8.96,0.35-11.17-2.66c-0.01-0.01-0.02-0.03-0.03-0.04c-1.28-1.76-2.22-4.52-3.12-8.75
			c0-0.02-0.01-0.03-0.01-0.05v1.39c0,0.01,0.01,0.03,0.01,0.04c0.85,3.66,1.77,6.11,2.97,7.72
			C41.36,420.76,41.37,420.78,41.38,420.79z M66.44,431.4c0.96,3.13,1.52,6.8,2.06,10.43c0.4,2.68,0.82,5.42,1.4,7.92
			c-0.78,1.1-1.54,2.25-2.32,3.43c-0.38,0.57-0.76,1.15-1.14,1.72V431.4z M64.57,427.09c0.62,1,1.14,2.15,1.57,3.41h-0.01v24.84
			c-0.51,0.75-1.03,1.5-1.57,2.23V427.09z M62.7,424.86c0.58,0.5,1.1,1.1,1.57,1.77v31.32h0.03c-0.51,0.69-1.04,1.36-1.59,2V424.86z
			 M60.99,423.75c0.51,0.23,0.97,0.53,1.41,0.87v35.68c-0.46,0.51-0.92,1.01-1.41,1.48V423.75z M59.08,423.17
			c0.57,0.1,1.11,0.25,1.61,0.46v38.43c-0.19,0.18-0.38,0.35-0.58,0.52c-0.37,0.31-0.71,0.55-1.03,0.73V423.17z M56.89,423.08
			c0.67-0.04,1.3-0.02,1.89,0.06v40.32c-0.56,0.25-1.05,0.28-1.49,0.09c-0.14-0.06-0.28-0.15-0.41-0.26V423.08z M54.77,455.55
			v-32.32c0,0,0,0,0,0c0.58-0.05,1.19-0.09,1.82-0.14v39.88c-1-1.21-1.55-3.94-1.89-7.43H54.77z M54.47,423.26v29.29
			c-0.14-2.23-0.24-4.64-0.33-7.1c-0.11-2.74-0.22-5.58-0.4-8.34c-0.3-4.54-0.47-8.31-0.61-11.34c-0.04-0.85-0.08-1.65-0.11-2.4
			C53.48,423.34,53.97,423.3,54.47,423.26z"
          />
          <path
            fill="#E9E8DB"
            d="M70.8,391.01c0.06,0.01,0.12-0.03,0.13-0.1c0.01-0.06-0.03-0.12-0.1-0.13c-7.21-1.06-14.52-3.18-14.59-3.2
			l-0.09-0.03l-9.66,17c-0.03,0.05-0.01,0.12,0.04,0.15c0.02,0.01,0.04,0.01,0.06,0.01c0.04,0,0.08-0.02,0.1-0.06l9.57-16.84
			C57.11,388.06,64,390.01,70.8,391.01z"
          />
          <path
            fill="#E9E8DB"
            d="M70.57,393.75c0-0.06-0.05-0.12-0.11-0.12c-5.98-0.3-13.43-2.82-13.5-2.84c-0.06-0.02-0.12,0.01-0.14,0.07
			c-0.02,0.06,0.01,0.12,0.07,0.14c0.08,0.03,7.56,2.55,13.57,2.86c0,0,0,0,0.01,0C70.52,393.86,70.57,393.81,70.57,393.75z"
          />
          <path
            fill="#E9E8DB"
            d="M70.35,396.16c-9.9-0.74-13.87-2.86-13.91-2.88c-0.05-0.03-0.12-0.01-0.15,0.05
			c-0.03,0.05-0.01,0.12,0.05,0.15c0.04,0.02,4.04,2.15,14,2.9c0,0,0.01,0,0.01,0c0.06,0,0.11-0.05,0.11-0.1
			C70.46,396.22,70.41,396.17,70.35,396.16z"
          />
          <path
            fill="#42637C"
            d="M191.87,270.87l2.08,1.8l0.08,0.07l0.15-0.17l-0.09-0.08l-2.19-1.89l-0.2-0.18l-12.55-10.85l-8.02-6.93
			c0.65-0.73,1.05-1.69,1.05-2.75c0-2.28-1.86-4.14-4.14-4.14c-2.28,0-4.14,1.86-4.14,4.14s1.86,4.14,4.14,4.14
			c1.15,0,2.19-0.47,2.94-1.23l7.83,6.76l12.86,11.11L191.87,270.87z M170.96,252.5l-3.42-2.95l-0.15,0.17l3.41,2.95
			c-0.71,0.71-1.69,1.15-2.77,1.15c-2.16,0-3.92-1.76-3.92-3.92c0-2.16,1.76-3.92,3.92-3.92c2.16,0,3.92,1.76,3.92,3.92
			C171.96,250.9,171.58,251.81,170.96,252.5z"
          />
          <rect x="89.76" y="363.65" fill="#42637C" width="0.23" height="24.34" />
          <path
            fill="#42637C"
            d="M68.79,364.8c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76s-0.76,0.34-0.76,0.76
			C68.03,364.45,68.37,364.8,68.79,364.8z M68.79,363.5c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54c-0.3,0-0.54-0.24-0.54-0.54
			S68.5,363.5,68.79,363.5z"
          />
          <circle fill="#E9E8DB" cx="71.81" cy="364.54" r="0.65" />
          <path
            fill="#42637C"
            d="M74.87,365.75c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
			C74.1,365.41,74.45,365.75,74.87,365.75z M74.87,364.45c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
			c-0.3,0-0.54-0.24-0.54-0.54S74.57,364.45,74.87,364.45z"
          />
          <circle fill="#42637C" cx="77.68" cy="365.33" r="0.65" />
          <path
            fill="#42637C"
            d="M86.3,370.04l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
			c0.05,0,0.31,0.07,0.63,0.16C70,367.78,75.74,369.37,86.3,370.04z"
          />
          <path
            fill="#42637C"
            d="M86.3,373.05l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
			c0.05,0,0.31,0.07,0.63,0.16C70,370.8,75.74,372.38,86.3,373.05z"
          />
          <path
            fill="#42637C"
            d="M86.3,376.07l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
			c0.05,0,0.31,0.07,0.63,0.16C70,373.81,75.74,375.4,86.3,376.07z"
          />
          <rect x="76.53" y="418.42" fill="#42637C" width="0.23" height="2.2" />

          <rect
            x="77.86"
            y="417.48"
            transform="matrix(0.3777 -0.9259 0.9259 0.3777 -338.9086 332.5757)"
            fill="#42637C"
            width="0.23"
            height="1.89"
          />

          <rect
            x="75.07"
            y="416.41"
            transform="matrix(0.3777 -0.9259 0.9259 0.3777 -339.5939 329.2945)"
            fill="#42637C"
            width="0.23"
            height="1.75"
          />
          <rect x="76.49" y="415.37" fill="#42637C" width="0.23" height="1.88" />
          <path
            fill="#E9E8DB"
            d="M163.64,363.72c-1.4,0.46-2.84,0.03-4.36-0.43c-1.41-0.42-2.87-0.86-4.29-0.52
			c-0.68,0.16-1.29,0.5-1.88,0.82c-0.62,0.34-1.26,0.7-1.98,0.83c-1.35,0.25-2.6-0.26-3.92-0.81c-1.09-0.45-2.21-0.92-3.41-0.92
			c-0.01,0-0.02,0-0.03,0c-1.21,0-2.33,0.47-3.41,0.92c-0.96,0.4-1.96,0.82-3.01,0.9c-1.63,0.12-3.07-0.52-4.59-1.21
			c-1.34-0.6-2.72-1.23-4.24-1.27c-1.64-0.05-3.16,0.6-4.64,1.22c-1.51,0.63-3.07,1.29-4.71,1.18c-1.17-0.07-2.26-0.53-3.31-0.98
			c-1.1-0.46-2.23-0.94-3.48-1c-1.69-0.07-3.52,0.69-4.94,2.05v-7.28c0-0.06-0.05-0.11-0.11-0.11s-0.11,0.05-0.11,0.11v13.54
			c0,0.06,0.05,0.11,0.11,0.11s0.11-0.05,0.11-0.11v-5.96c0.01-0.01,0.03-0.01,0.04-0.01c1.39-1.39,3.22-2.18,4.9-2.1
			c1.2,0.05,2.32,0.52,3.4,0.98c1.07,0.45,2.18,0.92,3.39,0.99c1.69,0.11,3.28-0.56,4.81-1.2c1.45-0.61,2.96-1.24,4.55-1.2
			c1.47,0.04,2.83,0.65,4.15,1.25c1.48,0.67,3.02,1.36,4.7,1.23c1.09-0.08,2.1-0.51,3.08-0.92c1.06-0.44,2.16-0.9,3.33-0.9
			c0.01,0,0.02,0,0.02,0c1.16,0.01,2.26,0.46,3.32,0.9c1.29,0.54,2.63,1.09,4.05,0.82c0.75-0.14,1.41-0.51,2.05-0.86
			c0.57-0.32,1.17-0.64,1.82-0.8c1.36-0.33,2.79,0.1,4.17,0.52c1.49,0.45,3.03,0.91,4.49,0.43c0.06-0.02,0.09-0.08,0.07-0.14
			C163.76,363.73,163.69,363.7,163.64,363.72z"
          />
        </g>
        <g id="woman">
          <path
            fill="none"
            d="M211.62,419.37l-0.08-0.16c-0.2,0.12-0.32,0.19-0.32,0.19C211.1,419.52,211.28,419.49,211.62,419.37z"
          />
          <path
            fill="#111413"
            d="M243.04,434.48l-14.91-35.63l-1.53-3.67c-0.37-0.98-1.4-1.86-2.24-1.91l-25.36-1.71
			c-0.87-0.05-1.16,0.8-0.62,1.82l8.75,17.16c1.53-1.19,3.17-2.47,4.02-3.15c1.6-1.27,3.65-1.84,4.75-1.25
			c0.58,0.23,0.92,0.92,0.6,1.29c0,0-0.01,0.01-0.01,0.01c-0.38,0.98-2.14,2.07-3.47,3.33c-1.75,1.65-3.77,3.75-3.77,3.75l0.88,0.91
			c0,0,5.47-2.57,7.97-3.85c2.07-1.05,3.39-1.47,4.19-1.05c0.73,0.22,1.05,1.18,0.6,1.53c-0.1,0.08-0.21,0.16-0.34,0.25
			c-0.46,0.59-1.3,1.19-2.24,1.73c-1.25,0.72-5.19,3.03-7.53,4.42c-0.52,0.31-0.96,0.58-1.24,0.74l0.08,0.16
			c0.78-0.28,2.42-1.03,3.48-1.64c1.53-0.88,6.77-4.26,8.16-4.71c0.65-0.21,1.17,0.07,1.4,0.56c0.42,0.33,0.59,0.92,0.28,1.22
			c-0.22,0.21-0.52,0.5-0.82,0.74c-0.03,0.02-0.05,0.05-0.08,0.07c-0.78,0.69-1.79,1.24-1.79,1.24s0.44-0.05,0.78,0.24
			c0.4,0.17,0.65,0.62,0.46,0.91c-0.09,0.13-0.2,0.3-0.32,0.46c-0.23,0.35-0.64,0.75-1.18,1.35c-1.06,1.16-2.54,2.02-3.6,2.98
			c-0.85,0.77-2.56,1.89-3.73,2.55l3.27,6.42c0.41,0.77,1.17,1.4,1.86,1.56l16.46,1.94l5.78,0.68
			C242.95,436.16,243.41,435.48,243.04,434.48z"
          />
          <path
            fill="#D7BFB1"
            d="M222,419.82c0.54-0.6,0.95-1,1.18-1.35c-0.31,0.42-0.68,0.83-0.92,0.68c-0.33-0.21-0.75-0.43-0.87-0.74
			c-0.12-0.31,0-0.6,0.78-1.19c0.3-0.23,0.62-0.23,0.88-0.12c-0.34-0.29-0.78-0.24-0.78-0.24l0.1,0.02c0,0-3.1,2.56-4,3.09
			c-0.9,0.53-3.02,1.91-4.19,2.61c-1.16,0.7-0.9,1.22-0.98,1.03c-0.08-0.19,0.26-0.89,1.04-1.35c0.78-0.47,3.52-2.14,4.66-3.04
			s3.36-2.36,3.36-2.36s1.01-0.55,1.79-1.24c0.03-0.02,0.05-0.05,0.08-0.07c-0.42,0.34-0.84,0.57-1.05,0.37
			c-0.36-0.35-0.84-0.73-0.91-1.16c-0.07-0.43,0.15-0.76,1.29-1.29c0.47-0.22,0.9-0.12,1.2,0.12c-0.23-0.49-0.75-0.77-1.4-0.56
			c-1.39,0.44-6.63,3.83-8.16,4.71c-1.06,0.61-2.7,1.36-3.48,1.64c-0.35,0.12-0.52,0.15-0.4,0.04c0,0,0.12-0.07,0.32-0.19
			c0.28-0.17,0.72-0.43,1.24-0.74c2.34-1.4,6.28-3.71,7.53-4.42c0.94-0.54,1.79-1.14,2.24-1.73c-0.6,0.44-1.39,0.95-1.65,0.62
			c-0.31-0.39-0.74-0.83-0.76-1.26s0.24-0.73,1.44-1.13c0.26-0.09,0.5-0.08,0.71-0.02c-0.8-0.42-2.12,0-4.19,1.05
			c-2.5,1.28-7.97,3.85-7.97,3.85c-1.79,0.7-2.41,0.78-1.62-0.08c0.59-0.65,0.74-0.82,0.74-0.82s2.02-2.1,3.77-3.75
			c1.34-1.26,3.1-2.36,3.47-3.33c-0.48,0.56-1.36,1.58-1.74,1.27c-0.22-0.18-0.65-0.56-0.97-0.85c-0.25-0.22-0.25-0.6,0-0.82
			c0.25-0.22,0.62-0.51,1.12-0.8c0.36-0.21,0.71-0.21,1-0.1c-1.11-0.59-3.15-0.02-4.75,1.25c-0.86,0.68-2.49,1.96-4.02,3.15
			c-1.84,1.44-3.54,2.75-3.54,2.75c-1.2,1.03-1.21,1.95-1.23,2.66c-0.03,0.7,0.54,1.56,1.31,2.27c0.77,0.71,1.46,1.68,1.88,2.57
			c0.42,0.89,1.18,1.35,1.73,1.57s0.74,1.16,1.57,1.71c0.83,0.54,1.77,0.91,2.39,1.4c0.62,0.49,1.69,0.78,2.71,0.28
			c0.2-0.1,0.45-0.23,0.73-0.39c1.17-0.67,2.87-1.78,3.73-2.55C219.46,421.84,220.94,420.98,222,419.82z"
          />
          <path
            fill="#AD7D62"
            d="M208.51,415.35c-0.79,0.86-0.17,0.79,1.62,0.08l-0.88-0.91C209.25,414.53,209.1,414.7,208.51,415.35z"
          />
          <path
            fill="#151616"
            d="M214.24,422.26c-0.78,0.47-1.12,1.17-1.04,1.35c0.08,0.19-0.18-0.33,0.98-1.03
			c1.16-0.7,3.29-2.08,4.19-2.61c0.9-0.53,4-3.09,4-3.09l-0.1-0.02c0,0-2.21,1.46-3.36,2.36S215.01,421.79,214.24,422.26z"
          />
          <path
            fill="#D7BFB1"
            d="M304.74,455.35c0,0-0.66-2.4-3.06-3.88c-2.39-1.47-5.36-0.84-5.36-0.84s-1.31,3.76-1.49,4.74
			c-0.19,0.98-0.75,1.06-1.77,2.29c-1.03,1.23-2.61,2.12-4.29,3.27c-1.68,1.14-4.01,2.37-5.13,3.35c-0.3,0.26-0.51,0.53-0.68,0.81
			c-0.48,0.77-0.7,1.64-1.75,2.72c-1.43,1.46-1.51,2.09-1.99,3.34c-0.48,1.25-1.59,2.93-1.11,3.48c0.48,0.56,1.67,0.7,3.1-0.77
			c1.42-1.45,1.83-2.22,2.92-3.33c-0.08,0.07-0.16,0.14-0.23,0.21c-0.15,0.17-0.32,0.33-0.5,0.47c0,0,0.2-0.19,0.5-0.47
			c0.65-0.73,1.09-1.74,1.45-2.56c0.37-0.83,0.55-2.56,0.58-3.07c-0.01-0.08-0.01-0.13,0-0.14c0.01-0.02,0.01,0.04,0,0.14
			c0.04,0.49,0.31,2.03,0.31,2.53c0,0.58,0.53-2.71,1.11-3.72c0.58-1.01-0.35,2.17-0.31,3.37c0.03,0.77-0.09,1.61-0.18,2.09
			c0.02,0.33-0.06,0.75-0.24,1.13c-0.4,0.84-1.11,3.9-1.11,3.9s-1.91,2.72-2.78,4.04c-0.87,1.32-0.87,1.32-0.87,1.32
			s-1.94,0.74-1.8,1.71c0.13-0.5,1.58-0.86,1.58-0.86s-0.56,1.29,0.11,2.11c0,0,0.13,0.31,0.71,0.39c0.58,0.08,1.33-0.66,1.33-0.66
			s1.42-1.4,2.26-2.44c0.4-0.5,0.65-0.83,0.91-1.14c0.28-0.35,0.57-0.67,1.09-1.18c0.97-0.97,1.37-1.36,1.59-1.9
			c0.22-0.54,2.52-5.62,2.52-5.62s-1.44,4.33-2.26,5.86c-0.35,0.66-1.33,1.75-2.13,2.48c-0.8,0.74,0.62,0.58,1.02,1.01
			c0.4,0.43-0.66,1.05-1.06,1.32c-0.4,0.27,1.46-0.5,2.17-0.97c0.71-0.47,1.55-2.09,2.3-3.03c0.75-0.93,1.95-2.48,2.04-2.6
			c0.09-0.12-0.62,1.55-1.46,2.64c-0.84,1.09-1.82,2.44-1.99,2.99c-0.18,0.54,0.8,0.97,1.33,1.16c0.53,0.19,0.97-0.47,1.42-1.32
			c0.44-0.85,2.13-3.84,3.9-4.93s-0.27,0.5-0.4,1.09c-0.13,0.58-2.04,1.94-2.66,3.06c-0.62,1.12-0.84,1.47-0.53,1.59
			c0.22,0.08,0.43,0.14,0.54,0.17c0.55-0.39,0.83-1.05,1.5-1.84c0.75-0.89,2.17-3.22,2.7-3.88c0.53-0.66,0.49-2.99,0.8-4.42
			c0.31-1.43,0.53-4.27,1.2-5.47c0.63-1.13,1.57-1.65,1.71-3c0.01-0.08,0.02-0.17,0.02-0.26
			C302.3,460.28,304.74,455.35,304.74,455.35z"
          />
          <path
            fill="#AD7D62"
            d="M287.03,467.3c-0.04-1.2,0.89-4.38,0.31-3.37c-0.58,1.01-1.11,4.3-1.11,3.72c0-0.5-0.26-2.04-0.31-2.53
			c-0.03,0.52-0.21,2.24-0.58,3.07c-0.36,0.81-0.8,1.83-1.45,2.56c0.07-0.06,0.15-0.13,0.23-0.21c0.74-0.67,1.83-1.6,2.19-1.65
			c0.58-0.08,0.44,0.78,0.44,0.89c0,0.04,0.04-0.12,0.09-0.39C286.94,468.91,287.05,468.06,287.03,467.3z"
          />
          <path
            fill="#AD7D62"
            d="M283.9,470.74c-0.3,0.27-0.5,0.47-0.5,0.47C283.58,471.08,283.74,470.92,283.9,470.74z"
          />
          <path fill="#AD7D62" d="M285.92,464.97c-0.01,0.02,0,0.07,0,0.14C285.93,465,285.93,464.95,285.92,464.97z" />
          <path
            fill="#AD7D62"
            d="M281.61,480.63c0,0-1.44,0.36-1.58,0.86c-0.13,0.5,0.04,0.85,0.71,1.12c0.66,0.27,0.97,0.12,0.97,0.12
			C281.05,481.92,281.61,480.63,281.61,480.63z"
          />
          <path
            fill="#AD7D62"
            d="M294.33,478.93c0.62-1.12,2.52-2.48,2.66-3.06c0.13-0.58,2.17-2.17,0.4-1.09s-3.45,4.07-3.9,4.93
			c-0.44,0.85-0.89,1.51-1.42,1.32c-0.53-0.19-1.51-0.62-1.33-1.16c0.18-0.54,1.15-1.9,1.99-2.99c0.84-1.09,1.55-2.75,1.46-2.64
			c-0.09,0.12-1.28,1.67-2.04,2.6c-0.75,0.93-1.59,2.56-2.3,3.03c-0.71,0.47-2.57,1.24-2.17,0.97c0.4-0.27,1.46-0.89,1.06-1.32
			c-0.4-0.43-1.82-0.27-1.02-1.01c0.8-0.74,1.77-1.82,2.13-2.48c0.82-1.52,2.26-5.86,2.26-5.86s-2.3,5.08-2.52,5.62
			c-0.22,0.54-0.62,0.93-1.59,1.9c-0.51,0.51-0.8,0.84-1.09,1.18c-0.26,0.31-0.51,0.65-0.91,1.14c-0.84,1.05-2.26,2.44-2.26,2.44
			l1.11-0.27c0,0,0.13,0.16,0.66,0.31c0.53,0.16,0.97,0.27,1.9,0.08c0.93-0.19,1.64-0.47,2.35-0.31c0.71,0.16,1.2,0.31,1.59,0.12
			c0.4-0.19,0.8-0.62,1.46-0.78c0.66-0.16,1.59-0.89,1.59-0.89s-0.03-0.01-0.08-0.02c-0.11-0.03-0.32-0.09-0.54-0.17
			C293.49,480.4,293.71,480.06,294.33,478.93z"
          />
          <path
            fill="#E9E8DB"
            d="M214.9,406.23c-0.5,0.29-0.87,0.58-1.12,0.8c-0.25,0.22-0.25,0.6,0,0.82c0.32,0.29,0.75,0.67,0.97,0.85
			c0.39,0.32,1.27-0.71,1.74-1.27c0,0,0.01-0.01,0.01-0.01c0.32-0.37-0.02-1.06-0.6-1.29C215.61,406.02,215.26,406.02,214.9,406.23z
			"
          />
          <path
            fill="#E9E8DB"
            d="M221.58,410.55c-1.19,0.39-1.45,0.7-1.44,1.13s0.45,0.86,0.76,1.26c0.26,0.33,1.05-0.18,1.65-0.62
			c0.12-0.09,0.24-0.18,0.34-0.25c0.45-0.35,0.13-1.31-0.6-1.53C222.09,410.47,221.85,410.47,221.58,410.55z"
          />
          <path
            fill="#E9E8DB"
            d="M223.46,413.47c-1.13,0.54-1.35,0.87-1.29,1.29c0.07,0.43,0.55,0.8,0.91,1.16
			c0.21,0.21,0.63-0.03,1.05-0.37c0.3-0.24,0.6-0.53,0.82-0.74c0.31-0.3,0.14-0.89-0.28-1.22
			C224.36,413.35,223.93,413.24,223.46,413.47z"
          />
          <path
            fill="#E9E8DB"
            d="M222.16,417.22c-0.78,0.59-0.89,0.87-0.78,1.19c0.12,0.31,0.54,0.53,0.87,0.74
			c0.24,0.15,0.61-0.26,0.92-0.68c0.12-0.16,0.23-0.33,0.32-0.46c0.19-0.29-0.06-0.73-0.46-0.91
			C222.78,416.99,222.47,416.99,222.16,417.22z"
          />
          <g>
            <path
              fill="none"
              d="M273.24,343.47c-0.13-0.27-0.23-0.5-0.29-0.65C273.04,343.05,273.14,343.27,273.24,343.47z"
            />
            <polygon fill="none" points="274.45,345.38 276.06,344.18 274.44,345.36 			" />
            <path
              fill="#E9E8DB"
              d="M237.9,310.61c0.34-1.91,2.29-5.66,3.39-6.87c0.12-0.13,0.26-0.31,0.43-0.52c0.08-0.1,0.17-0.2,0.28-0.32
				c0.08-0.88,0.62-5.06,3.98-5.41c1.48-0.15,3.25,1.34,3.76,1.8c0.88,0.46,1.68,1.41,2.31,3.06c2.91,7.63-4.31,3.33,1.08,12.89
				c2.18,3.86,2.14,6.98,2.33,7.17s0.82-8.18,6.61-5.89c1.7,0.67,2.11,2.22,2.31,3.37c0.32,1.84-0.53,4.07-2.26,7.33
				c-2.67,5.05,10.32,14.03,10.32,14.03s6.3-3.77,6.08-18.42c0,0,6.85-7.48,0.12-20.83c-5.28-10.48-33.41-15.39-37.04-2.68
				c-5.47,2.3-7.19,10.48-5.94,21.25c0.92-1.27,1.81-2.48,2.16-3.05C238.49,316.47,237.5,312.86,237.9,310.61z"
            />
            <path
              fill="#FFFFFF"
              d="M264.19,389.93c3.54-31.04,13.68-42.41,13.68-42.41l-1.8-3.33l-1.61,1.19l-16.89,12.48l-0.74,10.22
				l-3.16-10.86l-3.1-4.38c0,0,0,0,0,0l-1.96-2.77c0,0-0.93,0.99-1.9,2.55c0,0,0,0,0,0l0.02-0.01c0,0,2.66,4.88,6.09,17.66
				c0.01,0.01,0.02,0.01,0.03,0.02c3.28,12.31,6.49,30.52,5.58,52.97c0.24,4.62,0.58,9.06,0.89,13.24l9.95,0.19
				C265.65,422.96,262.21,407.31,264.19,389.93z"
            />
            <path
              fill="#42637C"
              d="M252.85,370.3c-0.01-0.01-0.02-0.01-0.03-0.02c-3.43-12.78-6.09-17.66-6.09-17.66l-0.02,0.01c0,0,0,0,0,0
				c-5.44,2.18-10.07,3.94-13.33,6.68l-0.01-0.05c-5.76,4.08-6.85,9.31-6.58,13.05c0.81,11.08,2.67,23.74,1.73,25.98
				c-0.07,0.17-0.2,0.36-0.38,0.56l14.91,35.63c0.37,1-0.09,1.68-1,1.47l-5.78-0.68c-1.36,12.93-0.4,23.35-0.4,23.35
				s4.88,3.95,22.83,4.7c2.21-5.26,1.52-14.94,0.63-26.81c-0.31-4.18-0.65-8.62-0.89-13.24
				C259.34,400.82,256.13,382.61,252.85,370.3z"
            />
            <path
              fill="#E9E8DB"
              d="M250.56,352.84c0.95-1.95,1.07-3.94,1.08-4.31c0.1-0.59,0.2-1.22,0.29-1.91
				c-0.03-0.35-0.07-0.7-0.11-1.07c0,0-0.59-2.94-3.85-3.57c-3.03-0.58-7.13-1.32-7.8-1.44c-0.1,0-0.19,0-0.28,0
				c1.51,8.91-0.89,10.57-0.89,10.57s4.28,0.02,9.62-1.13c-0.01,0.06-0.01,0.09-0.01,0.09L250.56,352.84
				C250.56,352.84,250.56,352.84,250.56,352.84z"
            />
            <path
              fill="#D7BFB1"
              d="M240.52,316.49C240.52,316.49,240.52,316.49,240.52,316.49c0.03,0.01,0.05,0.01,0.08,0.02
				C240.57,316.5,240.54,316.5,240.52,316.49z"
            />
            <path
              fill="#D7BFB1"
              d="M240.07,340.53c2-0.42,5.89-0.66,10.07-2.06c4.18-1.39,5.39-2.06,6.48-4.42c0,0,0.12,4.36-1.03,6.42
				c-1.15,2.06-3.83,2.9-3.77,5.08c0.04,0.37,0.08,0.72,0.11,1.07c-0.09,0.68-0.18,1.32-0.29,1.91c-0.01,0.37-0.13,2.35-1.08,4.31
				l3.1,4.38l3.16,10.86l0.74-10.22l16.89-12.48c0,0-0.01-0.01-0.01-0.01l-0.05,0.03c-0.41-0.41-0.85-1.27-1.15-1.92
				c-0.1-0.21-0.2-0.42-0.29-0.65c-0.04-0.1-0.07-0.16-0.07-0.16c0,0,0,0,0,0c-0.17-0.43-0.32-0.89-0.45-1.39
				c0,0-12.99-8.99-10.32-14.03c1.72-3.26,2.58-5.48,2.26-7.33c-0.2-1.15-0.61-2.7-2.31-3.37c-5.79-2.3-6.42,6.08-6.61,5.89
				s-0.15-3.31-2.33-7.17c-5.39-9.56,1.83-5.26-1.08-12.89c-0.63-1.66-1.43-2.61-2.31-3.06c0.09,0.08,0.14,0.13,0.14,0.13
				s-3.67-0.29-7.89,3.64c0,0,0-0.06,0.01-0.16c-0.1,0.11-0.2,0.22-0.28,0.32c-0.17,0.21-0.31,0.39-0.43,0.52
				c-1.1,1.2-3.05,4.95-3.39,6.87c-0.4,2.25,0.59,5.86-0.08,6.93c-0.35,0.57-1.24,1.79-2.16,3.05c-0.82,1.12-1.66,2.28-2.19,3.06
				c-1.16,1.72-0.75,2.65,0.51,2.99c1.23,0.33,2.89,1.03,1.91,2.69c-0.17,0.29-0.27,0.56-0.3,0.81c0.22,0.13,1.05,0.43,4.04,0.48
				c0.09,0,0.15-0.02,0.18-0.04c0.03-0.03,0.03-0.02,0,0c0,0-0.01,0-0.01,0.01c-0.01,0.3-0.45,2.39-2.95,3.5
				c0.27,0.56,0.72,1.13,0.31,2.08c-0.59,1.37,0.1,4.49,1.95,4.4c0.18-0.01,0.44-0.02,0.77-0.02c0.09,0,0.18,0,0.28,0
				C240.11,340.53,240.07,340.53,240.07,340.53z M257.92,328.77c0,0,2.91-0.97,4.72-4.91C262.65,323.87,262.57,327.34,257.92,328.77
				z M258.1,318.72c1.03-1.51,3.45-1.64,4.42-0.06c0.97,1.57,0.55,4.24,0.55,4.24s-0.12-4.06-1.76-4.3
				c-1.64-0.24-1.94,1.09-0.79,2.24c1.15,1.15,0.73,2.91,0.06,2.79c-0.78-0.14-0.24-1.03-1.33-1.27
				C258.16,322.11,257.07,320.23,258.1,318.72z M257.13,324.9c0.18-1.76,0.06-3.15,0.06-3.15s0.61,1.39,1.45,2.18
				c0.85,0.79,0.36,1.64-0.42,2.24S256.95,326.65,257.13,324.9z M234,326.15c0.74-0.06,1.29,0.15,1.55,0.45
				c0.25,0.3-0.42-0.04-0.98-0.04C234.02,326.55,233.74,326.17,234,326.15z M235.32,327.11c0.54,0.06,1.37-0.06,1.31-1.06
				c0-0.01,0-0.02,0-0.02c0-0.06-0.01-0.13-0.02-0.2c0,0,0.01,0.08,0.02,0.2c0,0.01,0,0.02,0,0.02
				C236.67,326.57,236.64,327.78,235.32,327.11z M244.05,317.57c-0.34-0.09-0.5-0.33-0.5-0.33c0.33,0.15,0.69,0.34,1.06,0.27
				C244.98,317.44,244.57,317.71,244.05,317.57z M244.33,317.27c-0.55,0.03-0.89-0.24-0.89-0.24c0.57,0.06,1.18,0.16,1.69-0.12
				C245.64,316.62,245.16,317.22,244.33,317.27z M239.73,312.05c1.55-0.31,2.79,1.24,3.72,2.32c0.93,1.08,0.93,1.08,0.93,1.08
				s-2.56-2.32-3.8-2.4c-1.24-0.08-2.17,1.08-2.17,1.08C238.11,313.75,238.18,312.36,239.73,312.05z M243.2,316.62
				c-1.97-1.78-3.07-1.62-2.98-1.64C241.77,314.62,243.2,316.62,243.2,316.62z M239.96,316.36c0,0-0.69-0.23-1.38-0.63
				c-0.69-0.4,3.61,0.86,4.59,1.26c0,0-1.95,0.43-2.97,1.07c0,0,0.1-0.07,0.26-0.17c-0.16,0.08-0.31,0.17-0.46,0.27c0,0,0,0,0,0
				c-0.01,0-0.02,0-0.02,0s-0.01-0.03-0.02-0.08c-0.02-0.06-0.04-0.15-0.06-0.26C239.82,317.47,239.76,316.9,239.96,316.36z
				 M239.86,330.57c0.64-0.31,0.73-0.62,0.72-1.06c-0.01-0.34-0.17-1.13-0.17-1.13s0.29,0.42,0.82,2.84c0.39,1.83,0-0.25-0.6-0.55
				C240.41,330.56,239.86,330.57,239.86,330.57z"
            />
            <path
              fill="#D7BFB1"
              d="M243.04,316.97c-0.06-0.01-0.14-0.02-0.23-0.04c-0.02,0.01-0.03,0.02-0.05,0.04
				C242.86,316.96,242.95,316.96,243.04,316.97z"
            />
            <path
              fill="#E9E8DB"
              d="M240.58,313.06c1.24,0.08,3.8,2.4,3.8,2.4s0,0-0.93-1.08c-0.93-1.08-2.17-2.63-3.72-2.32
				c-1.55,0.31-1.63,1.7-1.32,2.09C238.42,314.14,239.35,312.98,240.58,313.06z"
            />
            <path
              fill="#AD7D62"
              d="M259.25,322.35c1.09,0.24,0.56,1.13,1.33,1.27c0.67,0.12,1.09-1.64-0.06-2.79
				c-1.15-1.15-0.85-2.48,0.79-2.24c1.64,0.24,1.76,4.3,1.76,4.3s0.42-2.66-0.55-4.24c-0.97-1.57-3.39-1.45-4.42,0.06
				S258.16,322.11,259.25,322.35z"
            />
            <path
              fill="#AD7D62"
              d="M258.22,326.17c0.79-0.61,1.27-1.45,0.42-2.24c-0.85-0.79-1.45-2.18-1.45-2.18s0.12,1.39-0.06,3.15
				C256.95,326.65,257.44,326.77,258.22,326.17z"
            />
            <path
              fill="#AD7D62"
              d="M257.92,328.77c4.65-1.43,4.72-4.91,4.72-4.91C260.83,327.8,257.92,328.77,257.92,328.77z"
            />
            <path
              fill="#AD7D62"
              d="M255.59,340.47c1.15-2.06,1.03-6.42,1.03-6.42c-1.09,2.36-2.3,3.03-6.48,4.42
				c-4.18,1.39-8.07,1.63-10.07,2.06c0,0,0.03,0.01,0.1,0.02c0.67,0.12,4.77,0.86,7.8,1.44c3.26,0.63,3.85,3.57,3.85,3.57
				C251.76,343.37,254.44,342.53,255.59,340.47z"
            />
            <path
              fill="#AD7D62"
              d="M235.63,329.96c0.01-0.03,0.01-0.06,0.02-0.08C235.65,329.88,235.64,329.91,235.63,329.96z"
            />
            <path
              fill="#AD7D62"
              d="M239.8,330.59C239.8,330.59,239.8,330.58,239.8,330.59c-0.02,0.01-0.08,0.04-0.17,0.03
				c-2.99-0.05-3.83-0.35-4.04-0.48c-0.01-0.01,0.02-0.11,0.04-0.18c-0.2,0.67-0.04,1.22,0.44,1.48c0.66,0.37,2.02,0.17,2.02,0.17
				c0.07-0.01,0.29-0.01,0.02,0.2c0,0-0.93,0.25-1.31,0.9c-0.38,0.65,0.04,1.39,0.04,1.39c0.01,0,0.01-0.01,0.02-0.01
				C239.35,332.98,239.79,330.89,239.8,330.59z"
            />
            <path fill="#AD7D62" d="M239.81,330.58C239.83,330.56,239.83,330.55,239.81,330.58L239.81,330.58z" />
            <path
              fill="#AD7D62"
              d="M241.23,331.22c-0.52-2.42-0.82-2.84-0.82-2.84s0.16,0.79,0.17,1.13c0.01,0.44-0.08,0.74-0.72,1.06
				c0,0,0.55-0.01,0.76,0.1C241.23,330.97,241.62,333.05,241.23,331.22z"
            />
            <path
              fill="#AD7D62"
              d="M235.55,326.6c-0.25-0.3-0.81-0.51-1.55-0.45c-0.26,0.02,0.02,0.4,0.57,0.4
				C235.12,326.55,235.8,326.89,235.55,326.6z"
            />
            <path
              fill="#FFFFFF"
              d="M242.76,316.97c0.02-0.01,0.03-0.02,0.05-0.04c-0.52-0.09-1.51-0.28-2.22-0.42
				c-0.03-0.01-0.05-0.01-0.08-0.02c0.05,0.29,0.13,0.91-0.01,1.38C241,317.57,241.99,317.02,242.76,316.97z"
            />
            <path
              fill="#FFFFFF"
              d="M239.99,318.17C240,318.17,240,318.17,239.99,318.17l-0.02,0C239.98,318.17,239.98,318.17,239.99,318.17z
				"
            />
            <path
              fill="#AD7D62"
              d="M239.95,318.09c0.01,0.05,0.02,0.08,0.02,0.08l0.02,0c0.15-0.1,0.3-0.18,0.46-0.27
				c-0.16,0.1-0.26,0.17-0.26,0.17c1.02-0.65,2.97-1.07,2.97-1.07c-0.97-0.4-5.27-1.66-4.59-1.26c0.69,0.4,1.38,0.63,1.38,0.63
				c-0.2,0.54-0.14,1.11-0.07,1.46C239.91,317.94,239.94,318.03,239.95,318.09z M240.52,316.49
				C240.52,316.49,240.52,316.49,240.52,316.49c0.03,0,0.05,0.01,0.08,0.02c0.7,0.15,1.69,0.33,2.22,0.42
				c0.09,0.02,0.17,0.03,0.23,0.04c-0.09-0.01-0.18-0.01-0.28-0.01c-0.77,0.05-1.76,0.6-2.25,0.9
				C240.64,317.41,240.57,316.78,240.52,316.49z"
            />
            <path
              fill="#AD7D62"
              d="M240.22,314.98c-0.09,0.02,1.01-0.14,2.98,1.64C243.2,316.62,241.77,314.62,240.22,314.98z"
            />
            <path
              fill="#AD7D62"
              d="M245.98,297.5c-3.36,0.35-3.9,4.53-3.98,5.41c-0.01,0.1-0.01,0.16-0.01,0.16
				c4.22-3.93,7.89-3.64,7.89-3.64s-0.05-0.05-0.14-0.13C249.23,298.84,247.46,297.35,245.98,297.5z"
            />
            <path
              fill="#AD7D62"
              d="M236.63,326.02c-0.01-0.12-0.02-0.2-0.02-0.2C236.61,325.9,236.62,325.96,236.63,326.02z"
            />
            <path
              fill="none"
              stroke="#AA7D62"
              strokeWidth="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M236.63,326.02
				c0,0.01,0,0.02,0,0.02"
            />
            <path
              fill="#AD7D62"
              d="M235.32,327.11c1.32,0.68,1.35-0.54,1.31-1.06C236.69,327.04,235.86,327.16,235.32,327.11z"
            />
            <path
              fill="#E9E8DB"
              d="M272.95,342.83c0.06,0.15,0.16,0.38,0.29,0.65c0.3,0.65,0.75,1.52,1.15,1.92l0.05-0.03l1.62-1.18
				c0,0-0.23-0.88-3.17-1.52c0,0,0,0,0,0C272.88,342.67,272.91,342.72,272.95,342.83z"
            />
            <path
              fill="#42637C"
              d="M317.21,392.72c-4.77-13.98-11.93-26.24-16.16-33.64c-2.73-5.92-5.38-7.18-9.79-9.26
				c-6-2.82-13.39-2.31-13.39-2.31s-10.14,11.37-13.68,42.41c-1.98,17.38,1.46,33.03,5.09,46.77c2.85,10.8,5.81,20.42,6.34,28.77
				c2.58-0.07,5.03-0.21,7.34-0.38c0.18-0.28,0.39-0.55,0.68-0.81c1.12-0.98,3.45-2.21,5.13-3.35c1.68-1.14,3.26-2.04,4.29-3.27
				c1.03-1.23,1.59-1.31,1.77-2.29s1.49-4.74,1.49-4.74s2.97-0.63,5.36,0.84c2.39,1.47,3.06,3.88,3.06,3.88s-2.44,4.93-2.48,6.48
				c0,0.09-0.01,0.17-0.02,0.26c6.04-1.5,9.13-2.92,9.13-2.92s-0.64-4.4-1.51-10.22c1.29-3.19,2.9-7.25,4.88-12.39
				C322.42,416.64,322.1,407.04,317.21,392.72z"
            />
            <path
              fill="#AD7D62"
              d="M243.44,317.03c0,0,0.34,0.27,0.89,0.24c0.83-0.05,1.3-0.65,0.8-0.36
				C244.63,317.19,244.01,317.09,243.44,317.03z"
            />
            <path
              fill="#AD7D62"
              d="M244.62,317.51c-0.37,0.07-0.72-0.11-1.06-0.27c0,0,0.15,0.24,0.5,0.33
				C244.57,317.71,244.98,317.44,244.62,317.51z"
            />
          </g>
        </g>
        <g id="guy">
          <path
            fill="none"
            d="M376.62,521.76c-0.06,0.17-0.18,0.4-0.34,0.63c0.12-0.03,0.2-0.08,0.26-0.15c0.04-0.09,0.07-0.17,0.1-0.24
			c0.02-0.12,0.03-0.25,0.05-0.38C376.67,521.65,376.64,521.7,376.62,521.76z"
          />
          <path
            fill="#42637C"
            d="M373.22,523.31c-0.18-0.08-0.32-0.17-0.44-0.25c-0.18-0.06-0.37-0.04-0.66,0.19
			c-0.62,0.51-2.38,1.71-3.1,1.38c-0.44-0.19-1.33-0.72-1.56-1.21c-0.22-0.25-0.31-0.7-0.36-1.12c-0.11-0.39-0.18-0.76-0.13-0.85
			c0.05-0.09,0.11-0.19,0.17-0.29c-0.22,0.3-0.44,0.56-0.64,0.76c-0.73,0.7-1.15,1.8-2.45,1.02c-0.31-0.18-0.52-0.4-0.67-0.64
			c-0.36-0.42-0.48-1.2-0.07-2.17c0.26-0.61,0.57-1.43,0.77-1.98c-0.08,0.04-0.21,0.06-0.32-0.09c-0.4-0.29-0.78-1.65-1.46-2.93
			c-0.8-1.5-1.46-3.04-0.59-4.76c0.87-1.72,1.34-3.83,3.28-5.45c1.94-1.62,3.84-3.39,5.35-4.56c0.37-0.28,0.67-0.64,0.92-1.02
			c-0.81-0.16-1.35-0.27-1.5-0.29c-0.01,0-0.01,0.01-0.02,0.01c-0.04-0.01-0.03-0.02,0.02-0.01c0.35-0.16,0.78-7.94,1.12-18.07
			c-15.2,2.66-37.15,3.99-46.09,4.44c0.07,3.95,0.19,8.9,0.39,14.38c-0.92,7.11-0.27,37.87,1.58,69.85
			c2.13,36.75,9.59,91.08,9.59,91.08h24.5v-0.28l11.72,0.28c0,0-0.45-41.69-2.66-63.39c-3.94-38.65,0.81-58.06,3.62-73.91
			c-0.01,0-0.01,0-0.02-0.01C373.41,523.41,373.31,523.36,373.22,523.31z"
          />
          <path
            fill="#42637C"
            d="M368.65,519.75c0,0,0.88-3.02,1.25-4.34c0.19-0.69,0.15-1.82,0.1-2.58c0,0.89-0.29,1.69-0.29,1.69
			s-0.17,0.9-0.39,1.9c-0.23,1.03-0.52,2.17-0.74,2.53c-0.09,0.15-0.21,0.34-0.34,0.56c-0.07,0.15-0.15,0.29-0.23,0.41
			L368.65,519.75z"
          />
          <path
            fill="#AD7D62"
            d="M227.1,375.58l0.56,6.06l-0.63-7.73c-0.27-0.2-0.57-0.4-0.89-0.58c0,0-4.79-3.2-6.47-4.58
			c-1.67-1.38-3.4-2.91-5.21-4.29c-1.82-1.38-3.58-3.41-5.25-4.5c-1.67-1.09-5.16-4.07-6.18-5.16c-1.02-1.09-1.96,1.96,0.44,4.21
			c0.93,0.88,2.07,2.27,3.21,3.78c-0.04,0-0.08,0-0.12,0h0.12l3.14,4.46c-1.9-2.61-3.75-4.46-3.75-4.46h0.09
			c-0.07,0-0.14-0.01-0.22-0.01c-0.72-0.06-1.01,1.43-1.67,3.1c-0.66,1.67-0.95,2.86-1.19,3.46c-0.24,0.6,0.18,1.67,0.42,2.8
			c0.24,1.13,0.24,1.79,1.07,1.97c0.38-0.15,1.31-0.3,1.5-1.16c0.19-0.86-0.04-3.16-0.04-3.16l-1.12-0.63c0,0,1.15,0.26,1.53-0.22
			c0.37-0.48,0.71-1.45,0.71-1.45l-0.89-2.31c0,0,0.97,1.82,1.9,2.12c0.93,0.3,2.01,0.45,2.01,0.45s-1.79-0.04-2.49-0.19l-1.12,2.08
			c0,0,0,1.79,0.04,2.53c0.04,0.74-0.1,2.13-2.02,1.94c0,0,1.55,2.92,1.91,3.7c0.36,0.78,0.78,1.01,1.43,0.72
			c0.66-0.3,0.83-1.67,0.54-2.74c0,0,1.13,1.07,1.73,1.61c0.6,0.54,1.31-0.54,1.07-1.25c-0.17-0.5-0.39-1.03-0.51-1.3l-0.05,0.09
			c-0.19-0.45-0.82-0.82-1.27-1.08c-0.45-0.26,1,0.52,1.27,0.22c0.26-0.3-0.3-1.12-0.41-1.56c-0.11-0.45,0.74,1.1,1.15,1.13
			c0,0,0.24-0.02,0.71-0.07c-0.58-2.39-1.72-5.47-1.72-5.47c2.03,2.62,2.09,3.53,4.17,5.68c2.09,2.15,2.56,2.19,0.12,3.2
			c-2.44,1.01-3.52,2.33-3.52,2.33s0.2-0.25,0.45-0.69c-0.29,0.39-0.59,0.67-0.82,0.81c-0.58,0.36-3.47,1.49-5.29,2.43
			c-1.82,0.94-1.82,2.11-1.24,2.98c0.38,0.56,0.75,0.52,1.41,0.28c-0.23,0.07-0.36,0.11-0.36,0.11c0.93-0.33,1.08-0.44,1.59-0.71
			c0.51-0.28,0.4-1.28-0.11-1.79c0,0,0.63,0.53,1.06,1.67c1.09-0.25,1.84-0.22,3.38-0.46c1.89-0.29,4.43-0.58,7.05-0.15
			c1.1,0.18,2.41,0.05,3.67-0.14C224.65,382.93,227.3,376.89,227.1,375.58z M210.65,371.18c0.38-0.08,0.66-0.15,0.66-0.15
			S211.03,371.11,210.65,371.18c-0.53,0.12-1.25,0.27-1.68,0.33c-0.74,0.11-0.45,0.89-0.89,1.82c-0.45,0.93,0.41,2.12,0.37,3.13
			c0,0-0.19-1.41-0.82-2.2c-0.63-0.78,0.63-1.67,0.6-2.64c-0.03-0.84-0.37-1.37-0.46-1.5c-0.01-0.02-0.02-0.03-0.02-0.03
			s0.01,0.01,0.02,0.03c0.11,0.15,0.69,0.89,1.36,1.09C209.53,371.34,210.17,371.27,210.65,371.18z"
          />
          <path
            fill="#AD7D62"
            d="M227.53,383.65c-0.24-0.05-0.59-0.13-1.02-0.2C226.87,383.56,227.22,383.62,227.53,383.65z"
          />
          <path
            fill="#AD7D62"
            d="M223.58,383.41c-0.45,0.06-0.89,0.13-1.21,0.18C222.78,383.53,223.19,383.47,223.58,383.41z"
          />
          <path fill="#AD7D62" d="M211.42,373.64l-0.26,0.45C211.28,373.94,211.38,373.78,211.42,373.64z" />
          <path
            fill="#4D2C1A"
            d="M214.7,376.98c2.44-1.01,1.97-1.06-0.12-3.2c-2.09-2.15-2.15-3.06-4.17-5.68c0,0,1.14,3.08,1.72,5.47
			c0.15,0.6,0.26,1.17,0.31,1.62c0.15,1.43-0.39,2.69-0.8,3.43c-0.24,0.43-0.45,0.69-0.45,0.69S212.26,378,214.7,376.98z"
          />
          <path
            fill="#4D2C1A"
            d="M206.59,372.18c-0.04-0.74-0.04-2.53-0.04-2.53l1.12-2.08c0.71,0.15,2.49,0.19,2.49,0.19
			s-1.08-0.15-2.01-0.45c-0.93-0.3-1.9-2.12-1.9-2.12l0.89,2.31c0,0-0.33,0.97-0.71,1.45c-0.37,0.48-1.53,0.22-1.53,0.22l1.12,0.63
			c0,0,0.22,2.31,0.04,3.16c-0.19,0.86-1.12,1-1.5,1.16C206.49,374.31,206.62,372.93,206.59,372.18z"
          />
          <path fill="#4D2C1A" d="M210.65,371.18c0.38-0.07,0.66-0.15,0.66-0.15S211.03,371.09,210.65,371.18z" />
          <path fill="#4D2C1A" d="M207.76,370.13c-0.01-0.02-0.02-0.03-0.02-0.03S207.75,370.11,207.76,370.13z" />
          <path
            fill="#4D2C1A"
            d="M209.12,371.22c-0.67-0.2-1.24-0.94-1.36-1.09c0.09,0.12,0.43,0.66,0.46,1.5c0.04,0.97-1.23,1.86-0.6,2.64
			c0.63,0.78,0.82,2.2,0.82,2.2c0.04-1-0.82-2.2-0.37-3.13c0.45-0.93,0.15-1.71,0.89-1.82c0.43-0.06,1.15-0.22,1.68-0.33
			C210.17,371.27,209.53,371.34,209.12,371.22z"
          />
          <path
            fill="#4D2C1A"
            d="M210.27,372.52c0.11,0.45,0.67,1.27,0.41,1.56c-0.26,0.3-1.71-0.48-1.27-0.22
			c0.45,0.26,1.08,0.63,1.27,1.08l0.05-0.09l0.43-0.75l0.26-0.45C211.01,373.62,210.16,372.07,210.27,372.52z"
          />
          <path
            fill="#4D2C1A"
            d="M206.81,382.73c0.51,0.5,0.62,1.51,0.11,1.79c-0.51,0.28-0.66,0.39-1.59,0.71c0,0,0.13-0.04,0.36-0.11
			c0.28-0.09,0.69-0.22,1.22-0.41c0.8-0.28,0.96-0.31,0.96-0.31c0,0,0-0.01,0-0.01C207.44,383.26,206.81,382.73,206.81,382.73z"
          />
          <path
            fill="#4D2C1A"
            d="M227.1,375.58c0.2,1.31-2.45,7.34-5.13,8.07c-0.05,0.01-0.1,0.03-0.15,0.04c0,0,0.22-0.04,0.55-0.1
			c0.32-0.06,0.75-0.12,1.21-0.18c0.74-0.1,1.53-0.17,1.99-0.11c0.34,0.04,0.65,0.09,0.94,0.15c0.42,0.07,0.78,0.15,1.02,0.2
			c0.15,0.03,0.26,0.06,0.3,0.07l-0.17-2.08L227.1,375.58z"
          />
          <path
            fill="#4D2C1A"
            d="M209.82,367.26l-3.14-4.46h-0.12h-0.4h-0.09C206.07,362.8,207.92,364.66,209.82,367.26z"
          />
          <path fill="#D7BFB1" d="M318.47,367.02c0,0,0,0-2.48,0.89l2.82-0.92L318.47,367.02z" />
          <path
            fill="#D7BFB1"
            d="M282.35,414.62c-0.51,0.1-0.99,0.19-1.43,0.26C281.4,414.8,281.87,414.72,282.35,414.62z"
          />
          <path
            fill="#1EBEE4"
            d="M354.49,365.47c0.03-0.03,0.05-0.04,0.05-0.04l-6.67-2.39c-1.72,1.2-3.61,3.67-9.64,8.74
			c-6.93,5.83-11.22,9.5-11.22,9.5s-6.98-13.23-7.21-14.62l-0.98,0.32l-2.82,0.92l-1.04,0.34c0.01,0.01,0.01,0.02,0.02,0.03
			c5.03,8.22,11.25,21.79,11.25,21.79C337.82,379.45,353.51,366.3,354.49,365.47z"
          />
          <path
            fill="#AD7D62"
            d="M306.31,348.09c0.44-1.74-0.08-1.62-2.11-1.48c-0.38,0.03-0.63-0.01-0.78-0.09
			c-0.01,0.02-0.02,0.03-0.03,0.05c-0.36,0.6,0.85,1.53,1.75,1.15c0.01,0,0.02-0.01,0.03-0.01c0.33-0.21,0.69-0.5,0.81-0.85
			c0.12-0.35-0.1,0.86-0.06,1.25c0,0.01,0,0.01,0,0.02c-0.03-0.19-0.2-0.79-0.74-0.42c0.13-0.02-0.49,0.6-0.99,0.77
			c-0.48,0.16-0.48,0.57-0.35,1.09C304.82,349.22,306.05,349.15,306.31,348.09z M306,348.29C306.02,348.35,306.01,348.37,306,348.29
			L306,348.29z"
          />
          <path
            fill="#AD7D62"
            d="M311.46,347.16c2.91-0.04,4.69-1.78,6.74-4.61c2.05-2.83,2.85-6.59,2.85-6.59s0.41-1-0.27-2.96
			c-1.29-3.7-5.7-4.95-4.03-9.22c1.68-4.26,0.06-6.12-4.17-4.55c-1.65,0.61-4.75,1.03-4.75,1.03c-2.67,5.18-2.32,8.79-2.56,9.96
			c-0.24,1.18,0.78,1.59-0.12,3.57c-0.93,2.06-2.23,4.67-3.92,6.24c-1.5,1.4-0.07,3.16,2.29,3.55c0.71,0.12,1.02,0.36,1.09,0.67
			c0.37,0.05,1.27,0.19,2.43,0.57C308.6,345.34,308.56,347.2,311.46,347.16z M313.21,334.34c-0.46,0.19-0.8-0.27-0.8-0.27
			c0.48,0.13,1.05,0.02,1.6-0.17C314.01,333.89,313.68,334.15,313.21,334.34z M302.36,342.66c0.27-0.35,0.58-0.08,1.63,0.43
			C303.99,343.09,302.09,343.01,302.36,342.66z M303.83,343.66c0,0,1.43-0.04,1.55-0.39s0.08-1.38,0.08-1.38
			C305.57,342.66,306.85,344.47,303.83,343.66z M307.43,328.54c1.88-0.17,5.48,2.21,6.48,3.21c1,1-2.27-1.55-4.37-1.61
			c-2.1-0.06-3.1,0.89-3.1,0.89C305.93,330.42,305.55,328.7,307.43,328.54z M312.04,333.64c0,0-2.68-2.11-3.8-1.59
			c0,0,0.19-0.62,1.32-0.23C310.68,332.21,312.04,333.64,312.04,333.64z M311.92,334.02c0,0-0.39-0.08-0.96-0.23l0.66,0.24
			c-0.77,0.29-1.7,0.47-2.35,0.58c0,0,0,0,0,0l-0.12,0.02c-0.43,0.07-0.71,0.1-0.71,0.1c-0.25-0.72-0.17-1.76-0.16-1.92
			c-0.58-0.28-1.12-0.62-1.51-1.01C306.77,331.8,309.98,333.24,311.92,334.02z"
          />
          <path
            fill="#AD7D62"
            d="M341.11,350.02c-0.08-0.69-0.11-1.46-0.11-2.31C340.86,349.54,341.11,350.02,341.11,350.02z"
          />
          <path
            fill="#AD7D62"
            d="M338.23,371.79c6.03-5.07,7.92-7.55,9.64-8.74c-0.1-0.06-0.19-0.12-0.28-0.18
			c-0.72-0.5-2.72-2.08-4.35-5.35c-1.32-2.79-1.86-5.66-2.05-6.88c-0.03-0.2-0.06-0.4-0.08-0.61c-3.2-1.26-3.73-1.91-3.73-1.91
			c0.01,0.02,0.01,0.04,0.02,0.05c-1.36-1.06-2.57-2.53-3.02-4.31c-0.37-1.46-0.63-2.32-0.82-2.81c0.47-0.77,0.89-1.52,1.21-2.16
			c1.44-2.82,0.14-6.31-2.05-7.31c-1.94-0.89-5.32-0.64-6.82,1.74c-0.58,0.93-0.75,2.11-0.7,3.33l-0.01,0
			c0.09,2.12,0.76,5.17,0.79,8.34c0.09,8.21-3.31,12.52-8.05,14.42c0.82,0.63,1.45,1.43,1.87,2.42c1.18,2.75-0.12,4.22-0.02,4.83
			c0.23,1.4,7.21,14.62,7.21,14.62S331.3,377.61,338.23,371.79z M329.45,343.7c0,0,3.61-3.26,3.61-5.91
			C333.06,337.78,333.16,342.49,329.45,343.7z M327.52,334.13c0.39-0.55,1.16-1.44,2.93-1.22c1.77,0.22,2.32,1.66,2.82,2.66
			c0,0-1.66-2.16-3.27-1.83c-1.14,0.24-1.88,1.38-0.66,2.27c1.22,0.89,1.66,1.27,1.99,1.99c0.33,0.72-0.33,1.44-0.33,1.44
			s-0.22-0.55-0.55-1.22c-0.33-0.66-1.38,0.33-2.6-0.44C326.64,337,327.13,334.68,327.52,334.13z M326.97,338.33
			c0.89,0.83,1.94,0.5,2.49,0.89c0.55,0.39-0.66,2.05-1.33,2.71c-0.66,0.66-1.44,0.11-0.77-1s0.11-1.33-0.33-1.88
			S326.97,338.33,326.97,338.33z"
          />
          <path
            fill="#151616"
            d="M327.85,337.78c1.22,0.77,2.27-0.22,2.6,0.44c0.33,0.66,0.55,1.22,0.55,1.22s0.66-0.72,0.33-1.44
			c-0.33-0.72-0.77-1.11-1.99-1.99c-1.22-0.89-0.48-2.03,0.66-2.27c1.61-0.33,3.27,1.83,3.27,1.83c-0.5-1-1.05-2.44-2.82-2.66
			c-1.77-0.22-2.55,0.66-2.93,1.22C327.13,334.68,326.64,337,327.85,337.78z"
          />
          <path
            fill="#151616"
            d="M327.35,340.93c-0.66,1.11,0.11,1.66,0.77,1c0.66-0.66,1.88-2.32,1.33-2.71
			c-0.55-0.39-1.61-0.06-2.49-0.89c0,0-0.39,0.17,0.06,0.72S328.02,339.83,327.35,340.93z"
          />
          <path
            fill="#151616"
            d="M329.45,343.7c3.71-1.21,3.61-5.91,3.61-5.91C333.06,340.44,329.45,343.7,329.45,343.7z"
          />
          <path
            fill="#151616"
            d="M313.9,331.75c-1-1-4.59-3.38-6.48-3.21c-1.88,0.17-1.49,1.88-1,2.49c0,0,1-0.94,3.1-0.89
			C311.63,330.2,314.9,332.74,313.9,331.75z"
          />
          <path
            fill="#151616"
            d="M308.24,332.05c1.12-0.52,3.8,1.59,3.8,1.59s-1.36-1.43-2.48-1.82
			C308.43,331.43,308.24,332.05,308.24,332.05z"
          />
          <path fill="#E9E8DB" d="M309.15,334.63l-0.71,0.1C308.43,334.72,308.72,334.69,309.15,334.63z" />
          <path
            fill="#E9E8DB"
            d="M309.26,334.61c0.65-0.11,1.57-0.29,2.35-0.58l-0.66-0.24l-1.51-0.56
			C309.46,333.85,309.27,334.58,309.26,334.61z"
          />
          <path
            fill="#151616"
            d="M302.36,342.66c-0.27,0.35,1.63,0.43,1.63,0.43C302.94,342.59,302.63,342.32,302.36,342.66z"
          />
          <path
            fill="#151616"
            d="M305.38,343.27c-0.12,0.35-1.55,0.39-1.55,0.39c3.02,0.81,1.74-1,1.62-1.77
			C305.46,341.89,305.5,342.93,305.38,343.27z"
          />
          <path
            fill="#151616"
            d="M325.99,344.99c-0.03-3.17-0.71-6.21-0.79-8.34l0.01,0c-0.05-1.22,0.12-2.4,0.7-3.33
			c1.49-2.38,4.88-2.63,6.82-1.74c2.19,1,3.49,4.48,2.05,7.31c-0.33,0.64-0.74,1.39-1.21,2.16c0.18,0.49,0.44,1.35,0.82,2.81
			c0.45,1.78,1.66,3.25,3.02,4.31c-0.01-0.02-0.01-0.04-0.02-0.05c0,0,0.53,0.65,3.73,1.91c0,0-0.25-0.48-0.11-2.31
			c0.05-0.69,0.16-1.57,0.36-2.7c0.94-5.35,3.45-7.16,4.76-13.27c1.76-8.24,1.57-24.56-22.66-23.34
			c-6.25,0.31-11.65,0.84-11.65,0.84c-5.18,0-6.33,1.82-6.33,1.82s-0.06-0.79-0.14,2.37c-0.02,0.64,2.5,6.83,2.5,6.83
			s3.1-0.42,4.75-1.03c4.23-1.57,5.84,0.28,4.17,4.55c-1.68,4.26,2.74,5.51,4.03,9.22c0.68,1.95,0.27,2.96,0.27,2.96
			s-0.79,3.76-2.85,6.59c-2.05,2.83-3.83,4.57-6.74,4.61c-2.91,0.04-2.87-1.82-4.42-2.32c-1.17-0.38-2.06-0.52-2.43-0.57
			c-0.12-0.02-0.19-0.02-0.19-0.02c-0.63,0.82-1.68,1.95-1.01,2.28c0.16,0.08,0.41,0.11,0.78,0.09c2.03-0.14,2.55-0.26,2.11,1.48
			c-0.27,1.06-1.5,1.13-2.49,1.48c-0.1,0.04-0.2,0.07-0.29,0.12c-0.49,0.23-0.88,0.57-0.98,1.23c-0.31,2.01-1.65,8.61,3.92,9.42
			c3.98,0.57,8.06,0.45,11.47-0.92C322.67,357.51,326.08,353.2,325.99,344.99z"
          />
          <path
            fill="#151616"
            d="M305.91,348.14c0-0.01,0-0.01,0-0.02c-0.04-0.39,0.18-1.6,0.06-1.25c-0.12,0.35-0.48,0.63-0.81,0.85
			c0,0,0,0,0,0c0,0,0,0,0,0C305.71,347.34,305.88,347.95,305.91,348.14z"
          />
          <path fill="#4D2C1A" d="M306,348.29C306.01,348.37,306.02,348.35,306,348.29L306,348.29z" />
          <path
            fill="#151616"
            d="M308.43,334.72l0.71-0.1l0.12-0.02c0,0,0,0,0,0c0.01-0.03,0.2-0.76,0.18-1.39l1.51,0.56
			c0.57,0.16,0.96,0.23,0.96,0.23c-1.94-0.77-5.15-2.21-5.15-2.21c0.39,0.39,0.93,0.72,1.51,1.01
			C308.26,332.96,308.18,334.01,308.43,334.72z"
          />
          <path
            fill="#AD7D62"
            d="M364.76,410.08c-0.32,3.52-1.01,7-2.09,10.36c-0.87,2.7-1.9,5.92-0.13,8.14c0.99,1.24,2.61,1.78,3.81,2.81
			c2.64,2.25,2.76,6.34,1.79,9.67c-0.97,3.33-2.81,6.4-3.41,9.82c-0.69,3.94,5.37,15.55,6.45,20.88
			c0.31-11.61,0.48-24.12,0.36-31.42C369.24,433.22,366.7,420.71,364.76,410.08z"
          />
          <path
            fill="#AD7D62"
            d="M276.79,415.19c-0.1,0.02-0.19,0.03-0.28,0.04c0,0,1.44,0.13,4.41-0.35c0.44-0.07,0.92-0.16,1.43-0.26
			c2.09-0.42,4.75-1.1,8-2.23c8.33-2.88,15.5-4.85,15.5-4.85s-0.8-2.76-0.8-6.94C305.05,396.48,282.7,414.19,276.79,415.19z"
          />
          <path
            fill="#AD7D62"
            d="M373.73,499.86c-0.95-0.2-1.79-0.37-2.46-0.5c-0.25,0.38-0.56,0.73-0.92,1.02
			c-1.52,1.17-3.42,2.94-5.35,4.56c-1.94,1.62-2.41,3.72-3.28,5.45c-0.87,1.72-0.21,3.26,0.59,4.76c0.68,1.28,1.06,2.64,1.46,2.93
			c-0.04-0.05-0.07-0.12-0.1-0.22c0,0,0.41-0.71,0.4-1.3c0-0.59-0.32-1.43-1.04-1.41c0,0,1.7-0.26,1.65,0.39
			c-0.04,0.51-0.16,1.35-0.34,2.07c0.58,0.73,1.44,1.68,2.34,1.68c0.91,0,1.74-0.2,2.33-2.04c0,0-0.29,1.33-0.76,2.28
			c0.13-0.22,0.25-0.41,0.34-0.56c0.22-0.36,0.51-1.5,0.74-2.53c0.22-1,0.39-1.9,0.39-1.9s0.29-0.8,0.29-1.69
			c0.06,0.76,0.1,1.89-0.1,2.58c-0.37,1.32-1.25,4.34-1.25,4.34s-0.5,1.09-0.81,1.78c-0.31,0.68,1.95,0.53,3.17,0.85
			c1.22,0.32,1.9,0.32,2.01-0.35c0.11-0.66,1.36-5.29,1.62-6.22c0.26-0.93,0.43-1.76,0.43-1.76s0.21,0.46,0.17,0.73
			c-0.04,0.27,0.36-0.18,0.56-0.35c0.2-0.17-0.73,0.77-1.23,2.84c-0.49,2.07-0.95,4.02-1.15,4.6c-0.2,0.58,2.13,0.58,2.72,0.53
			c0.05,0,0.09-0.01,0.13-0.02c0.16-0.23,0.28-0.45,0.34-0.63c0.02-0.06,0.05-0.11,0.07-0.14c0.01-0.04,0.01-0.08,0.02-0.11
			c0.11-0.4,0.84-3.62,1.32-4.72c0.48-1.1,1.1-1.2,1.1-1.2s-0.62,0.4-1.2,2.24c-0.58,1.84-0.72,3.4-0.44,3.73
			c0.3-0.4,2.81-3.82,3.33-4.74c0.52-0.93,1.03-1.26,1.19-2.64c0.16-1.38,0.22-3.27,0.52-4.54c0.27-1.14,0.5-2.02,0.3-4.28
			C381,503.34,376.45,501.09,373.73,499.86z M364.62,516.16c0,0,0.29-2.94,0.25-3.56c-0.03-0.38-0.29-0.51-0.49-0.56
			c-0.13,0-0.22-0.02-0.22-0.02s0.1-0.01,0.22,0.02c0.26,0.01,0.66-0.05,0.73-0.44c0.1-0.59,0.48-3.15,0.48-3.15
			S365.48,513.21,364.62,516.16z"
          />
          <path
            fill="#AD7D62"
            d="M367.12,522.3c-0.04-0.36-0.06-0.7-0.13-0.85C366.94,521.54,367.01,521.91,367.12,522.3z"
          />
          <path
            fill="#AD7D62"
            d="M369.03,524.63c-0.71-0.33-1.14-0.73-1.56-1.21C367.7,523.91,368.59,524.44,369.03,524.63z"
          />
          <path
            fill="#AD7D62"
            d="M365.29,523.04c-0.68,0.42-1.45-0.26-1.85-0.67c-0.02-0.02-0.03-0.04-0.05-0.07
			c0.15,0.24,0.37,0.45,0.67,0.64c1.3,0.78,1.72-0.32,2.45-1.02c0.21-0.2,0.43-0.46,0.64-0.76c-0.06,0.1-0.12,0.2-0.17,0.29
			l1.02-1.53C367.41,520.84,365.97,522.62,365.29,523.04z"
          />
          <path
            fill="#AD7D62"
            d="M372.79,523.06c0.12,0.07,0.26,0.16,0.44,0.25C373.06,523.21,372.93,523.11,372.79,523.06z"
          />
          <path
            fill="#4D2C1A"
            d="M364.37,512.04c0.2,0.04,0.47,0.17,0.49,0.56c0.04,0.62-0.25,3.56-0.25,3.56
			c0.86-2.96,0.96-7.71,0.96-7.71s-0.38,2.56-0.48,3.15C365.04,512,364.63,512.05,364.37,512.04z"
          />
          <path fill="#4D2C1A" d="M364.16,512.02c0,0,0.09,0.02,0.22,0.02C364.25,512.02,364.16,512.02,364.16,512.02z" />
          <path
            fill="#4D2C1A"
            d="M364.09,518.16c-0.2,0.55-0.5,1.36-0.77,1.98c-0.42,0.97-0.29,1.74,0.07,2.17
			c0.02,0.02,0.03,0.05,0.05,0.07c0.4,0.41,1.17,1.08,1.85,0.67c0.68-0.42,2.12-2.2,2.72-3.11c0.08-0.12,0.16-0.26,0.23-0.41
			c0.47-0.95,0.76-2.28,0.76-2.28c-0.58,1.84-1.41,2.04-2.33,2.04c-0.9,0-1.76-0.96-2.34-1.68c0.19-0.72,0.3-1.55,0.34-2.07
			c0.05-0.65-1.65-0.39-1.65-0.39c0.72-0.01,1.04,0.82,1.04,1.41c0,0.59-0.4,1.3-0.4,1.3c0.03,0.1,0.06,0.17,0.1,0.22
			C363.88,518.22,364.01,518.2,364.09,518.16z"
          />
          <path
            fill="#4D2C1A"
            d="M376.28,522.38c-0.04,0.01-0.08,0.02-0.13,0.02c-0.59,0.06-2.93,0.05-2.72-0.53
			c0.2-0.58,0.66-2.53,1.15-4.6c0.49-2.07,1.42-3.01,1.23-2.84c-0.2,0.17-0.6,0.62-0.56,0.35c0.04-0.27-0.17-0.73-0.17-0.73
			s-0.18,0.83-0.43,1.76c-0.26,0.93-1.5,5.56-1.62,6.22c-0.11,0.66-0.79,0.67-2.01,0.35c-1.22-0.32-3.48-0.16-3.17-0.85
			c0.31-0.68,0.81-1.78,0.81-1.78l-0.65,0.17l-1.02,1.53c0.07,0.15,0.09,0.49,0.13,0.85c0.05,0.41,0.13,0.86,0.36,1.12
			c0.42,0.48,0.84,0.88,1.56,1.21c0.71,0.33,2.47-0.87,3.1-1.38c0.28-0.23,0.48-0.25,0.66-0.19c0.14,0.05,0.27,0.15,0.44,0.25
			c0.09,0.05,0.19,0.1,0.3,0.14c0.01,0,0.01,0,0.02,0.01c0.58,0.2,1.09,0.49,2.19-0.26c0.5-0.34,0.69-0.69,0.81-0.96
			C376.49,522.3,376.41,522.35,376.28,522.38z"
          />
          <path
            fill="#4D2C1A"
            d="M376.64,521.99c-0.03,0.07-0.06,0.15-0.1,0.24C376.59,522.17,376.62,522.08,376.64,521.99z"
          />
          <path
            fill="#4D2C1A"
            d="M377.95,517.81c0.58-1.84,1.2-2.24,1.2-2.24s-0.62,0.1-1.1,1.2c-0.48,1.1-1.22,4.32-1.32,4.72
			c-0.01,0.04-0.02,0.08-0.02,0.11c-0.02,0.13-0.03,0.26-0.05,0.38c0.08-0.19,0.16-0.31,0.4-0.28c0.59,0.06,0.47-0.17,0.47-0.17
			C377.23,521.21,377.36,519.65,377.95,517.81z"
          />
          <path
            fill="#4D2C1A"
            d="M382.85,505.33c0.03,0.03,0.06,0.06,0.08,0.08c0,0-0.11-1.74,0.03-3.63c-2.94-0.62-6.53-1.37-9.23-1.93
			C376.45,501.09,381,503.34,382.85,505.33z"
          />
          <path
            fill="#D7BFB1"
            d="M395.8,417.05c-7.62-28.94-13.16-37.16-16.75-40.36c-1.66-1.89-3.57-3.35-5.78-4.23
			c-8.85-3.55-15.05-5.75-18.77-6.98c-0.99,0.83-16.68,13.98-28.28,24.6c0,0-6.23-13.57-11.25-21.79c-0.76,0.27-1.68,0.61-2.81,1.01
			c-8.01,2.88-9.22,7.83-15.3,11.11c-8.79,4.74-20.52,10.29-20.52,10.29s-9.19,0.75-25.01-6.03c-11.11-4.76-24.28-10.76-24.28-10.76
			l0,0.01l0.63,7.73l0.17,2.08l0.54,6.66c0,0,3.67,3.97,12.67,10.22c11.08,7.69,23.9,14.63,35.74,14.59
			c5.91-1,28.26-18.71,28.26-14.6c0,4.18,0.8,6.94,0.8,6.94s2.58,23.51,10.09,48.32c7.43,24.56,4.86,29.77,4.86,29.77
			s1.49-0.06,4.01-0.19c8.93-0.46,30.88-1.78,46.09-4.44c-0.35,10.13-0.77,17.9-1.12,18.07c0.15,0.02,0.7,0.12,1.5,0.29
			c0.66,0.13,1.5,0.3,2.46,0.5c2.7,0.56,6.3,1.31,9.23,1.93c2.98,0.63,5.28,1.11,5.28,1.11l4.38-25.86
			C395.91,455.22,400.76,435.9,395.8,417.05z M371.17,471.77c-1.08-5.33-7.14-16.94-6.45-20.88c0.6-3.42,2.44-6.49,3.41-9.82
			c0.97-3.33,0.85-7.42-1.79-9.67c-1.21-1.03-2.83-1.57-3.81-2.81c-1.76-2.22-0.73-5.44,0.13-8.14c1.08-3.36,1.78-6.85,2.09-10.36
			c1.94,10.63,4.49,23.14,6.77,30.26C371.65,447.65,371.48,460.16,371.17,471.77z"
          />
          <path
            fill="#D7BFB1"
            d="M369.75,499.08c0.01,0,0.01-0.01,0.02-0.01C369.72,499.07,369.71,499.07,369.75,499.08z"
          />
          <path
            fill="#151616"
            d="M312.41,334.06c0,0,0.34,0.46,0.8,0.27c0.46-0.19,0.8-0.44,0.8-0.44
			C313.47,334.08,312.9,334.19,312.41,334.06z"
          />
          <path
            opacity="0.3"
            d="M337.38,348.12c0.01,0.02,0.01,0.04,0.02,0.05c4.53,13.58,10.47,14.88,10.47,14.88
			c-0.1-0.06-0.19-0.12-0.28-0.18c-1.98-1.28-3.38-3.29-4.35-5.35c-1.32-2.79-1.86-5.66-2.05-6.88c-0.06-0.39-0.08-0.61-0.08-0.61
			C337.91,348.76,337.38,348.12,337.38,348.12z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="vr-woman_3_">
          <path
            fill="#AD7D62"
            d="M157.59,397.7c-0.55,0.86-0.67,1.8,0.72,1.93C157.61,399.14,157.29,398.46,157.59,397.7z"
          />
          <path
            fill="#664033"
            d="M332.36,491.74c-6.19,6.6-12.37,9.29-15,10.2c0.26,5.3,1.89,11.4,4.47,19.06
			c0.59,1.64,1.12,3.45,1.71,5.96c1.02,4.37,0.84,6.24,0.84,6.24l17.22-0.6c0,0-2.18-14.02-5.32-24.89
			c-2.02-7.01-3.25-12.81-3.86-16.05c-0.49,0.33-1.06,0.68-1.71,1.03C331.84,492.14,332.36,491.74,332.36,491.74z"
          />
          <path
            fill="#664033"
            d="M257.4,420.89c-0.01-0.16-0.02-0.32-0.02-0.47c0,1.11,0,2.29,0,3.47c0.04,0,0.07-0.01,0.11-0.01
			C257.48,422.96,257.46,421.94,257.4,420.89z"
          />
          <path
            fill="#664033"
            d="M290.57,392.5c-0.04-0.02-0.08-0.03-0.13-0.05C290.49,392.47,290.53,392.48,290.57,392.5
			C290.57,392.5,290.57,392.5,290.57,392.5z"
          />
          <g>
            <path
              fill="#664033"
              d="M289.6,400.61c0.15-0.24,0.3-0.49,0.46-0.73C289.9,400.13,289.75,400.37,289.6,400.61z"
            />
            <g>
              <path
                fill="#664033"
                d="M288.28,402.64c0.42-0.63,0.87-1.31,1.32-2.03C289.17,401.31,288.72,401.97,288.28,402.64z"
              />
              <path
                fill="#664033"
                d="M295.73,386.84c0.44-2.32,0.65-4.96,0.45-7.92c-0.84-12.08-7.31-21.29-20.75-22.71
					c-0.25-0.08-0.5-0.16-0.75-0.24c-2.01-0.64-4.28-1.26-6.14-0.28c-0.22,0.12-0.44,0.26-0.68,0.28c-0.23,0.02-0.45-0.06-0.67-0.13
					c-2.03-0.62-4.14-0.5-5.65,0.99c-1.09-0.42-2.67-0.35-3.59,0.38c-0.27,0.22-0.47,0.51-0.75,0.72c-0.84,0.62-2.13,0.25-2.99,0.84
					c-0.6,0.42-0.9,1.25-1.59,1.48c-0.32,0.11-0.68,0.06-1.01,0.14c-0.84,0.2-1.55,0.83-1.87,1.72c-0.28,0.76-1.28,0.99-1.81,1.6
					c-0.32,0.36-0.9,1.64,0.03,2.66c0.01,0.01,0.02,0.01,0.03,0.02c0.06,0.11,0.17,0.17,0.3,0.2c-1.4,1.79-2.14,3.66-2.5,5.64
					c7.55-0.18,14.04,0.13,19.03,0.67l-3.71,21.76c-3.38-0.53-7.44-1.4-8.18-1.71l0.01,0c-0.02-0.01-0.05-0.02-0.07-0.03l0.05-0.01
					c-0.41-0.11-0.72-0.22-0.95-0.33c-1.32-0.66-2.44-1.99-4.24-2.08c-1.8-0.09-1.87,0.42-1.96,1.8c-0.04,0.58-0.56,0.83-1.24,0.9
					c-0.12,0.11-0.17,0.33-0.22,0.83c-0.01,0.11-0.03,0.17-0.07,0.2c0.03,1.31,0.22,2.98,0.64,5.23c0.04,0.19,0.06,0.31,0.06,0.31
					c0,0,0,0,0,0c0,0,0,0,0.02,0.13c0.95,5.08-0.35,4.88,0.36,9.28c0.43,2.71,3.68,3.98,10.86,4.38c1.19,0.54,1.07,3.41,1.22,6.87
					c0.01,0.16,0.01,0.31,0.02,0.47c0.06,1.05,0.08,2.07,0.09,2.99c0,0,0,0,0,0c0,0-0.86,0.4-1.46,1.4l0,0c0,0-1.09,2.36,1.62,4.5
					c1.25,0.98,5.11,3.25,7.81,4.81c1.61,0.93,2.81,1.6,2.81,1.6l0.67,1.22c0.06,0.05,0.12,0.09,0.17,0.14l0.14,0.43l4.05,7.33
					c0.71-4.02,2.18-9.21,2.18-9.21s1.53-0.83,3.37-2.09c1.47-1.25,4.42-3.96,5.46-6.4l-0.59-2.66c-0.1-0.35-0.24-0.99-0.4-2.13
					c-0.6-4.36-0.99-11.78,2.35-16.39c0.87-1.2,1.74-2.47,2.6-3.77c-1.64,2.46-2.97,4.19-2.97,4.19c0.07-0.25,0.24-0.39,0.27-0.46
					C291.51,393.72,292.85,392.65,295.73,386.84z"
              />
            </g>
          </g>
          <path
            fill="#111413"
            d="M249.51,366.16c6.21-4.66,19.29-3.62,23.9,5.1c0.64,1.21,1.27,2.48,1.87,3.77
			c1.83,0.69,3.16,1.8,3.14,4.86c0,0.44-0.03,0.92-0.07,1.45c2.91,0.52,9.43,1.97,17.37,5.5c0.44-2.32,0.65-4.96,0.45-7.92
			c-0.84-12.08-7.31-21.29-20.75-22.71c-0.25-0.08-0.5-0.16-0.75-0.24c-2.01-0.64-4.28-1.26-6.14-0.28
			c-0.22,0.12-0.44,0.26-0.68,0.28c-0.23,0.02-0.45-0.06-0.67-0.13c-2.03-0.62-4.14-0.5-5.65,0.99c-1.09-0.42-2.67-0.35-3.59,0.38
			c-0.27,0.22-0.47,0.51-0.75,0.72c-0.84,0.62-2.13,0.25-2.99,0.84c-0.6,0.42-0.9,1.25-1.59,1.48c-0.32,0.11-0.68,0.06-1.01,0.14
			c-0.84,0.2-1.55,0.83-1.87,1.72c-0.28,0.76-1.28,0.99-1.81,1.6c-0.32,0.36-0.9,1.64,0.03,2.66c0.01,0.01,0.02,0.01,0.03,0.02
			c0.06,0.11,0.17,0.17,0.3,0.2C248.61,366.65,249.11,366.45,249.51,366.16z"
          />
          <path
            fill="#151616"
            d="M282.45,391.99c1.09,0.77,2.03-0.22,2.33,0.44c0.3,0.66,0.49,1.22,0.49,1.22s0.59-0.72,0.3-1.44
			c-0.1-0.25-0.22-0.47-0.38-0.67c-1.17-0.35-2.27-0.65-3.4-0.9C281.85,391.19,282.04,391.7,282.45,391.99z"
          />
          <path
            fill="#151616"
            d="M282.01,395.14c-0.59,1.11,0.1,1.66,0.69,1c0.59-0.66,1.68-2.32,1.19-2.71c-0.49-0.39-1.44-0.06-2.23-0.88
			c0,0-0.35,0.17,0.05,0.72C282.1,393.81,282.6,394.03,282.01,395.14z"
          />
          <path
            fill="#151616"
            d="M283.88,397.9c2.98-1.08,3.21-4.99,3.23-5.77c0,0,0,0,0,0C286.99,394.76,283.88,397.9,283.88,397.9z"
          />
          <path
            fill="#111413"
            d="M290.57,392.5c-0.04-0.02-0.08-0.04-0.12-0.06c-2.72-1.08-7.18-2.48-13.05-3.08
			c-1.12,5.12-4.79,5.7-9.39,5.3c-9.05-0.78-13.19-1.24-15.1-1.75l-0.05,0.01c0.02,0.01,0.05,0.02,0.07,0.03l-0.01,0
			c0.98,0.41,7.68,1.79,11.05,2.07c3.18,0.27,7.58,0.75,10.22-0.25c0.18-0.07,0.35-0.14,0.51-0.22c2.39-1.18,3.16-4.26,3.26-4.69
			c0.17,0.03,0.81,0.14,1.76,0.32c0.25,1.34,0.36,2.32,0.3,2.75c-0.11,0.81,0.23-0.66,1.07-2.48c0.23,0.05,0.46,0.1,0.7,0.15
			c1.13,0.25,2.23,0.55,3.4,0.9c0.61,0.18,1.25,0.38,1.91,0.59c0,0,0,0,0,0c0.5,0.16,1.03,0.33,1.57,0.51
			c-0.86,3-2.39,7.61-3.1,13.73c-0.01,0.08-0.2,0.21-0.27,0.46c0,0,2.4-3.14,4.74-6.95c0.83-1.36,1.66-2.8,2.37-4.22
			c0.33-0.55,0.67-1.16,1-1.85C293.18,393.68,292.2,393.15,290.57,392.5C290.57,392.5,290.57,392.5,290.57,392.5z"
          />
          <g>
            <path
              fill="#151616"
              d="M255.49,400.33c-0.55-0.97-1.03-3.27-1.03-3.27s0.12,0.97,0.12,1.43c0,0.45-0.21,0.7-0.85,0.68
				c-0.04-0.01-0.08-0.03-0.11-0.04c-0.66-0.14-1.33,0.03-3.05-1.23c-1.07-0.79-1.57-0.39-2.64-0.17s-0.92-0.36-1.91,0
				c-1.14,0.42-1.22,1.27-1.18,1.73c0.02,0.18,0.06,0.3,0.06,0.31c0,0,0,0,0,0c0,0,0,0,0.01,0c0.06-0.03,0.4-0.18,1.21-0.44
				c0.91-0.29,0.94,0.31,1.77,0.18c0.83-0.12,1.43-0.45,2.16-0.25c2.34,0.64,3.21,0.3,3.73,0.13c0.12,0.12,0.24,0.5-0.44,1.13
				c-0.94,0.87-2.07,1.4-2.79,1.44c0,0,0.68,0.14,1.77-0.36c0.66-0.23,1.28-0.92,1.71-1.5c0.01-0.01,0-0.02,0.01-0.03
				c0.12-0.16,0.18-0.28,0.22-0.38c0.41-0.4,1,0.35,1.23,0.7C255.51,400.36,255.5,400.35,255.49,400.33z"
            />
            <path fill="#151616" d="M255.52,400.38C255.67,400.64,255.64,400.57,255.52,400.38L255.52,400.38z" />
          </g>
          <path
            fill="#151616"
            d="M248.99,393.75c-0.29,0.21-0.04,0.38,0.77,0.55c0.81,0.17,1.19,0.17,1.19,0.17
			C250.09,393.98,249.36,393.49,248.99,393.75z"
          />
          <path
            fill="#151616"
            d="M251.73,394.44c-0.4,0.1-0.78,0.03-0.78,0.03s0.69,0.44,1.15,0.18c0.66-0.37-0.15-2.06-0.15-2.06
			C252.17,393.39,252.13,394.34,251.73,394.44z"
          />
          <path
            fill="#151616"
            d="M247.47,394.19c-0.28-0.19,0.05,0.29,0.56,0.29c0.51,0,0.61-0.33,0.61-0.33
			C248.31,394.36,247.79,394.41,247.47,394.19z"
          />
          <path
            fill="#151616"
            d="M246.69,393.74c-0.44,0.14-1.02,0.7-1.02,0.7l0.54-0.03C246.91,394.27,247.13,393.6,246.69,393.74z"
          />
          <path
            fill="#151616"
            d="M257.49,423.88C257.49,423.88,257.49,423.88,257.49,423.88c0,0-0.86,0.4-1.46,1.4l0,0
			c0,0-1.09,2.36,1.62,4.5c1.25,0.98,5.11,3.25,7.81,4.81c-3.08-2.54-6.37-5.42-7.63-7.16c2.13-2.54,11.35-13.54,13.99-17.16
			c2.99-4.1,3.28-7.14,3.28-7.14s-0.23,1.11-2.59,3.57c-2.64,2.76-4.46,5.05-7.69,6.25c-2.57,0.96-5.74,0.82-8.76,0.57
			c0.03,0.01,0.05,0.03,0.08,0.04c1.19,0.54,1.07,3.41,1.22,6.87c0.01,0.16,0.01,0.31,0.02,0.47
			C257.46,421.94,257.48,422.96,257.49,423.88z"
          />
          <path
            fill="#1EBEE4"
            d="M242.37,388.93c0,0-0.07,0.01-0.2,0.02c0.64-0.02,1.32-0.04,2.07-0.06c0.57-0.02,1.11-0.03,1.64-0.03
			l0.32,0l0-0.01c6.06-0.02,9.68,0.75,16.31,1.57c4.4,0.54,6.17-0.84,6.7-4.06c0.26-1.56,0.93-5.7,0.73-8.07
			c-0.2-2.37-1.89-3.51-4.91-4.13c-4.11-0.84-9.94-1.2-15.36-1.24c1.81,0.02,3.05,0.06,3.05,0.06
			C242.32,375.78,242.37,388.93,242.37,388.93z"
          />
          <path
            fill="#42637C"
            d="M244.5,393.2c0.69-0.07,1.2-0.32,1.24-0.9c0.09-1.37,0.17-1.89,1.96-1.8c1.8,0.09,2.92,1.42,4.24,2.08
			c0.23,0.12,0.54,0.23,0.95,0.33c1.92,0.51,6.05,0.97,15.1,1.75c4.6,0.39,8.27-0.18,9.39-5.3c5.87,0.59,10.33,2,13.05,3.08
			c0.04,0.02,0.08,0.03,0.13,0.05c1.63,0.65,2.61,1.18,2.85,1.32c0.04,0.02,0.06,0.04,0.06,0.04c1.72-3.21,2.26-7,2.26-7
			c-0.01,0-0.01-0.01-0.02-0.01c-7.94-3.53-14.46-4.98-17.37-5.5c0.04-0.53,0.06-1.01,0.07-1.45c0.02-3.06-1.31-4.17-3.14-4.86
			c-0.68-0.26-1.43-0.46-2.21-0.68c-4.45-1.28-14.33-2.42-27.3-2.11c-0.82,0.02-1.66,0.05-2.51,0.08c-2.81,0.11-4.77,0.59-6.12,1.3
			c0.29-0.1,0.61-0.17,0.94-0.21c0.29-0.06,0.61-0.11,0.95-0.14c1.14-0.13,2.48-0.21,3.86-0.26l-0.01-0.02l0.9-0.01
			c2.09-0.06,4.22-0.06,5.86-0.04c5.43,0.04,11.25,0.4,15.36,1.24c3.03,0.62,4.71,1.76,4.91,4.13c0.2,2.37-0.48,6.51-0.73,8.07
			c-0.53,3.22-2.3,4.6-6.7,4.06c-6.62-0.81-10.25-1.59-16.31-1.57l0,0.01l-0.32,0c-0.53,0-1.07,0.01-1.64,0.03
			c-0.75,0.02-1.43,0.04-2.07,0.06c-0.58,0.04-2.37,0.16-4.39,0.14c-1-0.01-1.74-0.29-2.29-0.76c0.24,0.4,0.61,0.81,1.05,1.22
			c0.32,0.18,1.28,0.42,4.6,0.42c2.41,0,3.77-0.3,5.96-0.27c1.01,0.02,2.32,0.14,3.97,0.23c5.24,0.27,12.83,2.3,15.99,1.76
			s3.79-5.06,4.15-9.12c0.14-1.52,0.28-3.44-0.09-4.97c-0.62-2.54-3.4-4.44-15.47-4.88c0,0,11.34,0.21,18.04,2.6
			c3.17,1.13,3.7,3.4,3.6,5.84c-0.38-0.06-0.58-0.08-0.58-0.08l-0.93,8.14c0.14,0.01,0.27,0.02,0.4,0.03
			c-0.29,0.99-0.69,1.86-1.24,2.67c-1.06,1.57-3.08,1.97-5.15,1.87c-5.78-0.27-15-1.33-16.87-1.67c-2.3-0.42-2.6-2.03-5.73-1.9
			c-3.13,0.13-0.96,2.6-2.76,2.65c-0.5,0.11-0.47,1.24-0.47,1.24s0.18,0.14,0.17,0.11c0.03,0.01,0.05,0.01,0.07-0.01
			c0.03-0.03,0.05-0.09,0.07-0.2C244.33,393.53,244.39,393.31,244.5,393.2z"
          />
          <path
            fill="#1EBEE4"
            d="M237.15,373.61c-0.15,0.05-0.3,0.11-0.45,0.18c0.4-0.16,0.86-0.29,1.38-0.39
			C237.76,373.44,237.44,373.51,237.15,373.61z"
          />
          <path fill="#1EBEE4" d="M242.88,372.97l0.01,0.02c0.3-0.01,0.59-0.02,0.89-0.03L242.88,372.97z" />
          <path
            fill="#111413"
            d="M244.44,392.89c1.79-0.05-0.37-2.52,2.76-2.65c3.13-0.13,3.43,1.48,5.73,1.9
			c1.87,0.34,11.09,1.4,16.87,1.67c2.07,0.1,4.1-0.31,5.15-1.87c0.55-0.81,0.94-1.68,1.24-2.67c-0.13-0.01-0.27-0.02-0.4-0.03
			l0.93-8.14c0,0,0.2,0.02,0.58,0.08c0.1-2.44-0.43-4.71-3.6-5.84c-6.7-2.4-18.04-2.6-18.04-2.6c12.07,0.44,14.85,2.33,15.47,4.88
			c0.37,1.53,0.22,3.44,0.09,4.97c-0.36,4.06-0.99,8.58-4.15,9.12s-10.75-1.49-15.99-1.76c-1.65-0.09-2.97-0.21-3.97-0.23
			c-2.19-0.03-3.55,0.27-5.96,0.27c-3.32,0-4.28-0.24-4.6-0.42c-0.02-0.01-0.04-0.02-0.05-0.03c0,0,1.23,1.12,2.72,2.02
			c0.5,0.34,1.11,0.69,1.72,1.03c0.47,0.26,0.95,0.51,1.38,0.73c0.37,0.19,1.67,0.83,1.67,0.83S243.94,393.01,244.44,392.89z"
          />
          <path
            fill="#FFFFFF"
            d="M274.57,385.44c-0.08-0.01-0.16,0.05-0.17,0.13l-0.38,3.06c-0.01,0.08,0.05,0.16,0.13,0.17
			c0.01,0,0.01,0,0.02,0c0.07,0,0.14-0.06,0.15-0.13l0.38-3.06C274.71,385.52,274.65,385.45,274.57,385.44z"
          />
          <path
            fill="#85BED3"
            d="M273.45,388.03c0.01,0,0.01,0,0.02,0c0.08,0,0.14-0.05,0.16-0.13l0.26-1.75c0.01-0.08-0.05-0.16-0.13-0.17
			c-0.08-0.01-0.16,0.05-0.17,0.13l-0.26,1.75C273.31,387.94,273.36,388.02,273.45,388.03z"
          />
          <path
            fill="#85BED3"
            d="M274.37,384.42c0.27,0.03,0.53-0.35,0.59-0.86c0.06-0.51-0.1-0.94-0.37-0.97
			c-0.27-0.03-0.53,0.35-0.59,0.86C273.93,383.95,274.1,384.39,274.37,384.42z"
          />
          <path
            fill="#151616"
            d="M283.72,424.13c-0.06-0.05-0.13-0.1-0.2-0.14l0.21,0.95l0.59,2.66
			C284.89,426.26,284.88,425.01,283.72,424.13z"
          />
          <path
            fill="#151616"
            d="M332.36,491.74c0,0-0.53,0.4-1.65,0.96c-1.55,0.78-4.25,1.88-8.28,2.68c-1.41,0.28-2.99,0.53-4.73,0.71
			c-0.33,1.86-0.44,3.79-0.34,5.85C319.99,501.03,326.18,498.34,332.36,491.74z"
          />
          <path fill="#151616" d="M268.96,437.41l0.31,0.57l-0.14-0.43C269.08,437.5,269.02,437.45,268.96,437.41z" />
          <path
            fill="#AD7D62"
            d="M323.54,526.97c-0.59-2.51-1.12-4.32-1.71-5.96c-2.57-7.66-4.21-13.76-4.47-19.06
			c-0.1-2.05,0-3.99,0.34-5.85c1.75-0.18,3.32-0.43,4.73-0.71c4.03-0.8,6.73-1.9,8.28-2.68c0.65-0.36,1.22-0.71,1.71-1.03
			c1.54-1.03,2.25-1.83,2.25-1.83s-7.26-17.78-9.12-26.22c-5.21-23.66-16.85-24.37-28.9-27.16c-3.77-0.87-6.79-1.68-8.91-2.29
			c-0.22-3.92-0.83-6.34-1.51-7.83c-0.15-0.34-0.31-0.64-0.48-0.89c-1.1-1.71-2.23-1.47-2.23-1.47c0.07,0.05,0.14,0.1,0.2,0.14
			c1.15,0.88,1.16,2.13,0.59,3.47c-1.03,2.44-3.99,5.15-5.46,6.4c-1.84,1.27-3.37,2.09-3.37,2.09s-1.47,5.19-2.18,9.21l-4.05-7.33
			l-0.31-0.57l-0.67-1.22c0,0-1.2-0.67-2.81-1.6c-2.71-1.56-6.57-3.83-7.81-4.81c-2.72-2.14-1.62-4.5-1.62-4.5l0,0
			c0.59-0.99,1.46-1.4,1.46-1.4c0,0,0,0,0,0c-0.04,0-0.07,0.01-0.11,0.01c-1.5,0.11-2.95,1.56-2.73,3.27
			c0.15,1.16,0.33,3.33,0.42,4.56c-14.41,1.23-21.58-3.1-33.61,8.6c-5.68,5.53-11.59,9.02-11.59,9.02c0.56-0.41,2.21,0.66,3.21,1.44
			c4.94,3.33,10.07,11.35,11.76,16.57c0.76,2.35,0.82,4.14-0.16,4.66l8.87-5.51c17.01,16.22,20.24,68.57,20.24,68.57
			c0.79,0.03,1.6,0.05,2.44,0.07l12.48,0.35c-0.1-0.1-0.2-0.2-0.29-0.3c24.2-0.21,55.95-1.99,55.95-1.99
			S324.56,531.34,323.54,526.97z"
          />
          <path
            fill="#664033"
            d="M159.14,399.19c-0.03-0.98,0.7-2.21,1.02-2.69c-0.03-0.37-0.05-0.73-0.09-1.07
			c-0.27,0.18-0.58,0.38-0.94,0.62c-0.85,0.57-1.34,1.13-1.54,1.65c-0.3,0.76,0.02,1.44,0.72,1.93c0,0,0,0,0.01,0
			c0.6,0.42,1.34,0.32,1.98-0.01c0-0.02,0-0.03,0-0.05C160.04,399.74,159.17,400.26,159.14,399.19z"
          />
          <path
            fill="#42637C"
            d="M169.97,416.29c-1.97-5-0.68-7.39-0.73-9.49c-0.05-2.19-2.09-4.33-2.28-6.57
			c-0.09-1.11,0.13-2.04,0.45-2.77c0.09-0.57,0.28-1,0.55-1.25c0.07-0.28,0.25-0.49,0.61-0.6c0.02-0.01,0.04-0.01,0.06-0.01
			c-0.34-2.83-0.69-5.26-0.98-6.75c-0.51-2.66-1.51-5.51-3.6-7.99c-1.65,1.8-1.69-0.49-4.32-2.77c-1.77-1.53-3.22-2.16-3.99-2.41
			c-0.36-0.1-0.6-0.16-0.6-0.16s0.22,0.03,0.6,0.16c0.36,0.1,0.82,0.24,1.27,0.4c-3.65-1.39-6.2-0.89-7.94,0.15
			c2.43-1.07,6.94,0.89,6.26,1.89c-0.4,0.58-0.75,0.9-1.39,1.46c-0.46,0.4-1.04,0.07-1.58-0.21c-3.03-1.54-4.76-1.57-5.35-1.32
			c-0.5,0.62-0.82,1.2-1.01,1.56c-0.84,1.61-0.5,2.8,0.28,3.78c-0.03-0.04-0.05-0.07-0.08-0.11c-0.73-1.13-0.96-2.47-0.24-3.01
			c0.96-0.72,2.47-0.35,4.45,0.48c3.64,1.53,10.31,5.49,11.45,13c0,0,0.26,4.64,0.99,11.58c0.22,0.28,0.44,0.56,0.67,0.85
			c0.58,0.73,1.05,1.35,1.37,1.88l0,0c0.09,0.11,0.16,0.25,0.23,0.42c0.31,0.63,0.4,1.14,0.21,1.59c-0.05,0.66-0.25,1.32-0.68,1.75
			c-0.07,0.07-0.11,0.15-0.13,0.25c0.53,0.42,1.18,0.81,2.08,1.08c2.14,0.66,2.59,1.79,2.49,2.53c-0.01,0.11-0.04,0.21-0.07,0.31
			c0.06,0.34,0.13,1.09-0.34,1.64c0.6,0.47,0.74,1.19,0.43,1.62c-0.03,0.17-0.14,0.29-0.32,0.37c-0.37,0.39-0.99,0.69-1.95,0.52
			c-0.6-0.11-1.54-0.5-2.39-0.89c0.25,3.19,0,3.86-0.45,3.37c0.66,1.04,1.51,1.78,2.56,1.78c0.81,0,1.5-0.39,2.04-1.18
			c0.59-0.86,1.01-2.19,1.26-3.98C169.92,418.68,170,417.65,169.97,416.29z"
          />
          <path
            fill="#111413"
            d="M164.47,419.24c0.85,0.4,1.79,0.78,2.39,0.89c0.96,0.17,1.58-0.13,1.95-0.52
			c-0.25,0.11-0.61,0.14-0.99,0.13c-0.55-0.01-1.55-0.28-1.55-0.28s-0.01-0.03-0.03-0.07c-0.72-0.17-1.57-0.45-2.68-0.87
			c-0.5-0.19-0.92-0.33-1.28-0.44c0.21,0.89,0.42,1.64,0.63,2.19c0.39,1.04,0.81,1.99,1.13,2.35
			C164.47,423.09,164.73,422.43,164.47,419.24z"
          />
          <path
            fill="#111413"
            d="M164.91,408.04L164.91,408.04c0.08,0.15,0.16,0.28,0.23,0.42C165.07,408.3,165,408.15,164.91,408.04z"
          />
          <path
            fill="#111413"
            d="M164.67,410.91c-0.03,0.11-0.08,0.22-0.15,0.33c-0.15,0.22-0.36,0.28-0.62,0.24
			c0.2,0.19,0.41,0.38,0.65,0.57c0.02-0.1,0.06-0.18,0.13-0.25c0.43-0.43,0.63-1.09,0.68-1.75c-0.04,0.11-0.1,0.21-0.17,0.31
			C165.02,410.6,164.85,410.78,164.67,410.91z"
          />
          <path
            fill="#111413"
            d="M168.87,416.62c-0.13,0.44-0.54,0.59-1.11,0.56c-0.06,0.02-0.1,0.03-0.1,0.03
			c0.44,0.06,0.78,0.21,1.04,0.42c0.47-0.55,0.4-1.3,0.34-1.64c-0.04,0.11-0.08,0.21-0.13,0.3
			C168.92,416.39,168.9,416.5,168.87,416.62z"
          />
          <path
            fill="#111413"
            d="M166.96,400.24c0.19,2.24,2.24,4.38,2.28,6.57c0.05,2.09-1.25,4.49,0.73,9.49
			c0.04,1.36-0.04,2.39-0.09,2.92c-0.05,0.58-0.05,0.59,0.2-0.21c0.49-1.58,3.04,0,1.7-1.83c-0.38-0.52-0.68-0.98-0.92-1.43
			c-0.13-0.29-0.26-0.6-0.39-0.94c-1.9-5.14,0.42-6.81-1.57-10.67c-1.32-2.57-1.75-5.13-1.49-6.68
			C167.09,398.2,166.86,399.12,166.96,400.24z"
          />
          <path
            fill="#1EBEE4"
            d="M152.37,379.36c0.54,0.27,1.12,0.61,1.58,0.21c0.65-0.56,0.99-0.88,1.39-1.46
			c0.68-0.99-3.84-2.96-6.26-1.89c-0.12,0.05-0.23,0.11-0.34,0.17c-1.14,0.71-1.83,1.69-1.83,1.69c0.03-0.02,0.07-0.04,0.11-0.05
			C147.62,377.79,149.35,377.82,152.37,379.36z"
          />
          <path
            fill="#664033"
            d="M160.33,401.23c0,0.05-0.01,0.09-0.01,0.15c-0.01,0.1,0.02,0.22,0.07,0.37
			C160.36,401.59,160.34,401.41,160.33,401.23z"
          />
          <path
            fill="#664033"
            d="M160.74,402.42c0.23,0.38,0.51,0.77,0.71,1.04c0.08,0.1,0.15,0.18,0.19,0.24
			C161.26,403.18,160.95,402.74,160.74,402.42z"
          />
          <path
            fill="#664033"
            d="M159.32,413.62c0.03,0.02,0.06,0.04,0.09,0.07c-0.01-0.01-0.01-0.02-0.02-0.02
			C159.33,413.62,159.29,413.6,159.32,413.62z"
          />
          <path
            fill="#664033"
            d="M166.24,419.38c-0.06-0.19-0.17-0.77,0.4-1.62c1.47,0.35,2.47,0.48,2.51,1.39c0,0.03-0.01,0.06-0.01,0.09
			c0.31-0.43,0.17-1.15-0.43-1.62c-0.26-0.2-0.6-0.36-1.04-0.42c0,0,0.04-0.01,0.1-0.03c-0.17-0.01-0.36-0.04-0.56-0.08
			c-0.83-0.18-1.8-0.6-1.8-0.6s-0.2-1.07,0.53-1.91c1.52,0.6,2.96,0.78,2.98,1.7c0.05-0.09,0.1-0.19,0.13-0.3
			c0.03-0.1,0.06-0.2,0.07-0.31c0.09-0.75-0.35-1.87-2.49-2.53c-0.9-0.28-1.55-0.66-2.08-1.08c-0.24-0.19-0.45-0.38-0.65-0.57
			c-0.32-0.06-0.71-0.29-1.09-0.57c-0.53-0.39-1.33-1.35-1.33-1.35s0.1-0.86,1.53-1.42c1.06,1.2,1.88,1.97,1.66,2.77
			c0.18-0.13,0.35-0.31,0.52-0.55c0.07-0.1,0.12-0.2,0.17-0.31c0.18-0.46,0.1-0.97-0.21-1.59c-0.07-0.13-0.14-0.27-0.23-0.42
			c-0.32-0.54-0.79-1.15-1.37-1.88c-0.23-0.29-0.46-0.57-0.67-0.85c-0.43-0.55-0.82-1.06-1.16-1.52c-0.02-0.02-0.12-0.15-0.26-0.33
			c-0.01-0.01-0.01-0.02-0.02-0.03c-1.06-1.32-3.11-2.61-2.97-2.69c0.11-0.05,0.24-0.09,0.37-0.13c-0.63,0.1-1.14,0.35-1.48,0.93
			c-0.58,0.99-0.49,2.97,0.49,4.45c0.33,0.5,0.76,0.99,1.23,1.48c-0.02,0.02-0.04,0.04-0.06,0.07c0.03-0.02,0.05-0.05,0.08-0.06
			c0,0,1.81,1.54,2.9,2.69c0,0-2.15-1.86-2.66-2.06c-0.5-0.19-0.73,0.03-0.73,0.03s0.03-0.06,0.07-0.14
			c-0.62,1.02-1.2,2.87-1.08,3.76c0.14,0.99,0.64,1.13,1.76,1.87c-0.04-0.04-0.06-0.06-0.06-0.06s1.56,1.13,3.13,1.9
			c0.26,0.12,0.43,0.21,0.43,0.21c-0.14-0.06-0.29-0.13-0.43-0.21c-0.66-0.31-1.86-0.87-2.26-0.97c-0.43-0.11-0.54-0.05-0.57-0.02
			c-0.06,0.34-0.06,0.91,0.42,2.05c0.43,1.03,0.9,1.1,2.26,1.53c0.36,0.12,0.78,0.25,1.28,0.44
			C164.67,418.93,165.51,419.21,166.24,419.38z"
          />
          <path
            fill="#111413"
            d="M224.83,467.35c-1.69-5.22-6.82-13.24-11.76-16.57c-1-0.78-2.65-1.85-3.21-1.44
			c-0.63,0.47-0.72,1.33-0.72,1.33l0.09-0.05l-2.93,2.01l-0.03,0.02c0,0,0.64,1.08,1.73,3.01c-0.47-2.48,0.21-3.98,1.83-5.38
			l0.66-0.37c2.11,1.93,8.93,8.65,11.77,17.09l-0.44-0.78c-2.62,2.75-6.03,6.32-11.73,8.45l0.12,0.16c-0.05,0.05-0.11,0.1-0.16,0.15
			c2.91-0.68,7.33-1.9,11.02-3.69c0.63,0.4,2.38,1.38,3.6,0.72C225.65,471.48,225.59,469.7,224.83,467.35z"
          />
          <path
            fill="#AD7D62"
            d="M164.51,411.25c0.07-0.11,0.12-0.22,0.15-0.33c0.22-0.8-0.6-1.57-1.66-2.77
			c-1.43,0.55-1.53,1.42-1.53,1.42s0.8,0.96,1.33,1.35c0.38,0.28,0.77,0.51,1.09,0.57C164.15,411.53,164.37,411.47,164.51,411.25z"
          />
          <path
            fill="#AD7D62"
            d="M166.64,417.76c-0.57,0.85-0.46,1.43-0.4,1.62c0.02,0.05,0.03,0.07,0.03,0.07s1,0.27,1.55,0.28
			c0.39,0.01,0.75-0.02,0.99-0.13c0.18-0.08,0.29-0.19,0.32-0.37c0-0.03,0.01-0.06,0.01-0.09
			C169.11,418.24,168.11,418.11,166.64,417.76z"
          />
          <path
            fill="#AD7D62"
            d="M168.87,416.62c0.04-0.12,0.05-0.23,0.04-0.34c-0.02-0.91-1.46-1.1-2.98-1.7
			c-0.74,0.84-0.53,1.91-0.53,1.91s0.97,0.42,1.8,0.6c0.2,0.04,0.39,0.07,0.56,0.08C168.33,417.21,168.74,417.06,168.87,416.62z"
          />
          <path
            fill="#AD7D62"
            d="M167.96,396.22c-0.17,0.66,0.29,1.72,0.56,2.97c1.02,0.13,1.75-0.5,1.86-0.6
			c0.01-0.01,0.02-0.02,0.02-0.02s-0.19-1.03-0.5-1.83c-0.31-0.79-0.72-1.27-1.26-1.14c-0.02,0.01-0.04,0-0.06,0.01
			C168.21,395.73,168.03,395.94,167.96,396.22z"
          />
          <path
            fill="#111413"
            d="M161.43,403.43c0.01,0.01,0.01,0.02,0.02,0.03c-0.2-0.27-0.48-0.66-0.71-1.04
			c0.21,0.32,0.52,0.76,0.9,1.28c-0.04-0.05-0.1-0.13-0.19-0.24c0.14,0.18,0.24,0.31,0.26,0.33c0.34,0.46,0.73,0.97,1.16,1.52
			c-0.72-6.94-0.99-11.58-0.99-11.58c-1.14-7.51-7.81-11.47-11.45-13c-1.98-0.83-3.49-1.19-4.45-0.48
			c-0.72,0.54-0.49,1.88,0.24,3.01c0.02,0.03,0.05,0.07,0.08,0.11c0.81,1.14,3.25,3.04,3.64,4.07c0.06,0.15,0.07,0.3,0.06,0.45
			c0.15-0.54,0.13-1.1-0.3-1.51c0,0,1.94-0.86,4.61,1.02c2.68,1.89,2.25,3.69,2.25,3.69s-0.61,0.06-1.46,1.22
			c-0.85,1.16-0.67,0.97-2.19-0.06c-1.52-1.03-3.2-1.55-3.7-1.29c0.46,1.73,3.45,3.05,4.91,3.25c1.46,0.2,2.32-2.52,3.92-3.05
			c0.15-0.05,0.3-0.07,0.45-0.06c0.9,0.44,1.35,1.98,1.58,4.33c0.03,0.34,0.06,0.7,0.09,1.07c-0.31,0.48-1.05,1.71-1.02,2.69
			c0.03,1.06,0.9,0.54,1.15,0.37c0,0.02,0,0.03,0,0.05c0.01,0.3,0.01,0.61,0.02,0.92c-0.14-0.01-0.89-0.06-1.48,0.08
			c-0.13,0.03-0.26,0.07-0.37,0.13C158.31,400.82,160.37,402.11,161.43,403.43z M160.33,401.23c0.01,0.18,0.03,0.36,0.06,0.52
			c-0.05-0.14-0.08-0.27-0.07-0.37C160.33,401.32,160.33,401.28,160.33,401.23z"
          />
          <path
            fill="#111413"
            d="M159.34,408.11c0.5,0.19,2.66,2.06,2.66,2.06c-1.08-1.15-2.9-2.69-2.9-2.69
			c-0.03,0.02-0.05,0.04-0.08,0.06c-0.14,0.13-0.26,0.33-0.33,0.46c-0.04,0.08-0.07,0.14-0.07,0.14S158.83,407.92,159.34,408.11z"
          />
          <path
            fill="#111413"
            d="M160.17,414.51c0.39,0.1,1.6,0.66,2.26,0.97c-1.56-0.77-3.13-1.9-3.13-1.9s0.03,0.03,0.06,0.06
			c0.01,0.01,0.01,0.01,0.02,0.02c0.01,0.01,0.01,0.02,0.02,0.02c0.05,0.05,0.11,0.12,0.18,0.21c0.08,0.11,0.07,0.29,0.04,0.43
			c-0.01,0.03-0.01,0.06-0.02,0.08c-0.01,0.05-0.03,0.09-0.03,0.09s0-0.01,0.01-0.02C159.63,414.45,159.75,414.4,160.17,414.51z"
          />
          <path
            fill="#111413"
            d="M162.43,415.47c0.15,0.07,0.29,0.14,0.43,0.21C162.86,415.68,162.69,415.59,162.43,415.47z"
          />
          <path
            fill="#FFFFFF"
            d="M242.17,388.95c0.13-0.01,0.2-0.02,0.2-0.02s-0.05-13.15,10.32-15.95c0,0-1.24-0.04-3.05-0.06
			c-1.65-0.02-3.77-0.02-5.86,0.04c-0.3,0.01-0.59,0.02-0.89,0.03c-1.38,0.05-2.71,0.14-3.86,0.26c-0.34,0.04-0.65,0.09-0.95,0.14
			c-0.53,0.1-0.99,0.23-1.38,0.39c-2.36,0.95-2.6,2.91-2.6,5.64c0,2.73-0.68,7.14,1.38,8.89c0.55,0.47,1.29,0.75,2.29,0.76
			C239.8,389.11,241.58,388.99,242.17,388.95z"
          />
          <path
            fill="#111413"
            d="M159.74,378.09c2.63,2.28,2.67,4.57,4.32,2.77c0.01-0.01,0.02-0.02,0.02-0.03c0,0-2.62-3.28-7.06-4.76
			c-0.45-0.16-0.92-0.3-1.27-0.4C156.52,375.92,157.97,376.55,159.74,378.09z"
          />
          <path fill="#111413" d="M155.75,375.67c-0.38-0.13-0.6-0.16-0.6-0.16S155.39,375.57,155.75,375.67z" />
          <path
            fill="#151616"
            d="M250.9,403.69c-1.35-0.68-1.8-1.09-0.68-1.37c0.49-0.13-1.01,0.04-2.64,0.04c-1.64,0-2.04-0.24-2.04-0.24
			s-0.02,0.09,0.41,0.33c0.86,0.48,0.57,0.84-0.16,1.77c-0.49,0.63,0.77-0.63,4.05,0.24S252.25,404.37,250.9,403.69z"
          />
          <path
            fill="#111413"
            d="M172.87,425.12c-0.04-0.03-0.06-0.04-0.06-0.04C172.84,425.1,172.85,425.11,172.87,425.12z"
          />
          <path
            fill="#111413"
            d="M180.23,432.12c-1.15-7.55-3.22-3.95-7.35-7c0.45,0.33,3.48,2.67,5.32,7.75c0.29,0.8,0.55,1.66,0.77,2.59
			c1.17,5.06,2.14,8.49,3.12,11.51c0.46,1.42,0.93,2.76,1.42,4.13C181.09,440.74,181.18,438.35,180.23,432.12z"
          />
          <path
            fill="#42637C"
            d="M152.9,392.25c1.52,1.03,1.34,1.22,2.19,0.06c0.85-1.16,1.46-1.22,1.46-1.22s0.42-1.8-2.25-3.69
			c-2.68-1.89-4.61-1.02-4.61-1.02c0.43,0.41,0.45,0.96,0.3,1.51c-0.12,0.43-0.33,0.85-0.53,1.2c-0.49,0.86-0.4,1.61-0.25,1.88
			C149.7,390.7,151.38,391.22,152.9,392.25z"
          />
          <path
            fill="#664033"
            d="M210.1,474.67c5.7-2.14,9.11-5.71,11.73-8.45l0.44,0.78c-2.84-8.44-9.66-15.15-11.77-17.09l-0.66,0.37
			c-1.62,1.4-2.3,2.9-1.83,5.38c-1.09-1.92-1.73-3.01-1.73-3.01l0.03-0.02c-3.28-5.42-16.79-21.25-24.79-33.4
			c-0.62-1.13-1.2-2.31-1.64-3.97c-1.45-5.56-3.03-6.15-5.26-8.69c-1.33-1.53-2.06-2.37-3.4-5.71c-0.32-0.8-0.59-1.58-0.84-2.27
			c-0.11,0.1-0.85,0.74-1.86,0.6c-0.27-1.25-0.73-2.31-0.56-2.97c-0.27,0.25-0.46,0.68-0.55,1.25c-0.26,1.55,0.18,4.11,1.49,6.68
			c1.98,3.86-0.33,5.54,1.57,10.67c0.12,0.34,0.25,0.64,0.39,0.94c0.24,0.44,0.54,0.9,0.92,1.43c1.34,1.83-1.22,0.24-1.7,1.83
			c-0.24,0.79-0.24,0.79-0.2,0.21c-0.25,1.8-0.67,3.13-1.26,3.98c1.98,1.45,3.75,1.32,5.91,3.62c0.08,0.09,0.17,0.16,0.27,0.23
			c1.01,1.31,2.07,2.44,3.39,5.82c-1.84-5.08-4.87-7.42-5.32-7.75c-0.02-0.01-0.04-0.03-0.06-0.04c0,0,0.02,0.01,0.06,0.04
			c4.13,3.05,6.2-0.55,7.35,7c0.95,6.23,0.87,8.62,3.27,18.98c-0.49-1.37-0.96-2.7-1.42-4.13c3.59,15.71,13.3,26.51,20.46,28.62
			c2.58,1.52,6.02,0.7,7.52-0.61c0.06-0.05,0.11-0.1,0.16-0.15L210.1,474.67z"
          />
          <path
            opacity="0.3"
            d="M318.19,480.08c-6.53-8.66-9.99-14.92-9.99-14.92c0.05,0.54,0.13,1.04,0.21,1.53
			c1.03,2.24,2.41,4.79,4.3,7.74c6.14,9.57,7.04,13.55,5.42,19.87c-0.14,0.55-0.27,1.07-0.39,1.59c-0.01,0.07-0.03,0.14-0.04,0.2
			c1.75-0.18,3.32-0.43,4.73-0.71C322.87,492.98,323.28,486.83,318.19,480.08z"
          />
          <path
            opacity="0.3"
            d="M268.48,519.31c-7.75-9.8-11.16-15.95-17.09-33.26c-5.92-17.32-14.13-19.82-11.39-20.05
			c2.73-0.23,11.64,11.51,6.43,4.57c-4.01-5.35-6.89-9.59-12.81-6.4c-3.92,2.11-7.09,2.89-8.79,3.17c0.76,2.35,0.82,4.14-0.16,4.66
			l8.87-5.51c17.01,16.22,20.24,68.57,20.24,68.57l2.44,0.07l12.48,0.35c-0.1-0.1-0.2-0.2-0.29-0.3
			C262.76,529.11,276.11,528.96,268.48,519.31z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="10" y="122.01" fill="none" width="280.05" height="182.74" />
        <text transform="matrix(1 0 0 1 53.0345 135.7889)">
          <tspan x="1" y="0" fontFamily={font.serif} fontSize="19px">
            Gegenüber 2011 hat sich die{" "}
          </tspan>
          <tspan x="-25" y="29" fontFamily={font.serif} fontSize="19px">
            Zahl weiblicher Führungskräfte
          </tspan>
          <tspan x="25.62" y="58" fontFamily={font.serif} fontSize="19px">
            in der BMW AG mehr als{" "}
          </tspan>
          <tspan x="91.61" y="87" fontFamily={font.condensed} fontSize="24px">
            VERDOPPELT.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 181.1949 72)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            g
          </tspan>
          <tspan x="24.95" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            e
          </tspan>
          <tspan x="48.48" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            tan
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
