import React from "react";
import { SvgContainer } from "#ui";
import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { MainKPI } from "./MainKPI";
import { Frameworks } from "./Frameworks";
import { ChartProps } from "#interfaces";

const CHART_COUNT = 2;

export const ResearchChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <MainKPI labels={labels?.mainKPI} {...chartProps} />
      <Frameworks labels={labels?.leftKPI} {...chartProps} />

      <line fill="none" stroke="#000000" x1="142" y1="328.5" x2="178" y2="328.5" />
      <line fill="none" stroke="#000000" x1="142" y1="266.5" x2="178" y2="266.5" />
    </SvgContainer>
  );
};
