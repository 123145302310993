import { StartSlideshow } from "#components";
import { useTranslation } from "#hooks";
import { bp, expander, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { ButtonAnimationType, ButtonCircle, ButtonCircleSize, Grid, Link, Wrapper } from "#ui";
import htmr from "htmr";
import React from "react";
import { PageName, Translations } from "#interfaces";
import styled from "styled-components/macro";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding-top: 175px;
  @media ${bp.m} {
    padding-top: 0;
    display: flex;
    align-items: center;
  }
`;
const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    margin-top: -20%;
    ${gridColumnStart(3)}
    ${gridColumnWidth(11)}
  }
`;
const Slideshow = styled.div`
  display: none;
  @media ${bp.m} {
    display: flex;
    ${expander}
    left: auto;
    align-items: center;
  }
`;
const Headline = styled.div`
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 1em;
  @media ${bp.p} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${bp.t} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${bp.d} {
    font-size: 36px;
    line-height: 43px;
  }
`;
const Title = styled.h1`
  ${fontCondensed}
  font-size: 32px;
  line-height: 32px;
  @media ${bp.m} {
    font-size: 36px;
    line-height: 36px;
  }
  @media ${bp.p} {
    font-size: 43px;
    line-height: 43px;
  }
  @media ${bp.t} {
    font-size: 52px;
    line-height: 52px;
  }
  @media ${bp.d} {
    font-size: 62px;
    line-height: 62px;
  }
`;
const StartButton = styled.div`
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  width: 50px;
  margin: auto;
  text-transform: lowercase;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
`;

export const Start: React.FC = () => {
  const { t: tPages } = useTranslation("pages");
  const { t: tHome } = useTranslation("home");
  const { headline, title, slideshow } = tHome("start");
  const { t } = useTranslation("common");
  const pagesList = tPages("list") as Translations;
  const { path } = pagesList[PageName.intro];

  return (
    <Container>
      <Wrapper>
        <Grid>
          <Content>
            <Headline>{htmr(headline)}</Headline>
            <Title>{htmr(title)}</Title>
          </Content>
        </Grid>
      </Wrapper>
      <Slideshow>
        <StartSlideshow list={slideshow} />
      </Slideshow>
      <StartButton>
        <Link to={path}>
          <ButtonCircle size={ButtonCircleSize.Large} animation={ButtonAnimationType.circleButtonStart_70x70}>
            {t("Start")}
          </ButtonCircle>
        </Link>
      </StartButton>
    </Container>
  );
};
