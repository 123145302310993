import { bp, Color, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  ${fontCondensed}
  ${gridColumnStart(2)}
  ${gridColumnWidth(11)}
  @media ${bp.m} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(18)}
  }
`;

const Headline = styled.blockquote`
  position: relative;
  margin: 0;
  font-size: 21px;
  line-height: 28px;
  @media ${bp.m} {
    font-size: 28px;
    line-height: 38px;
  }
  @media ${bp.p} {
    font-size: 40px;
    line-height: 54px;
  }
  @media ${bp.t} {
    font-size: 48px;
    line-height: 64px;
  }
  @media ${bp.d} {
    font-size: 56px;
    line-height: 75px;
  }
  &::before {
    content: "”";
    [lang="en"] & {
      content: "“";
    }
    position: absolute;
    right: 100%;
    margin-right: 0.1em;
  }
  &::after {
    content: "“";
    [lang="en"] & {
      content: "”";
    }
    margin-left: 0.1em;
  }
  strong {
    color: ${Color.BlueBold};
    font-size: 28px;
    @media ${bp.m} {
      font-size: 38px;
    }
    @media ${bp.p} {
      font-size: 54px;
    }
    @media ${bp.t} {
      font-size: 64px;
    }
    @media ${bp.d} {
      font-size: 75px;
    }
  }
`;
const Author = styled.cite`
  display: block;
  font-style: normal;
  margin-top: 0.5em;
  font-size: 16px;
  @media ${bp.m} {
    font-size: 21px;
  }
  @media ${bp.p} {
    font-size: 30px;
  }
  @media ${bp.t} {
    font-size: 36px;
  }
  @media ${bp.d} {
    font-size: 42px;
  }
`;

export interface QuotationProps {
  headline: string;
  author: string;
}

export const Quotation: React.FC<QuotationProps> = ({ headline, author }) => {
  return (
    <Container>
      <Headline dangerouslySetInnerHTML={{ __html: headline }} />
      <Author>{author}</Author>
    </Container>
  );
};
