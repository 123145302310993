import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { bp } from "#shared/theme";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { ChartContainer } from "../ChartContainer/ChartContainer";
import { EBITMarginChartDesktop } from "../EBITMarginChartDesktop/EBITMarginChartDesktop";
import { EBITMarginChartMobile } from "../EBITMarginChartMobile/EBITMarginChartMobile";
import { InfoItemProps } from "../Info/Info";

export const EBITMarginChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("EBITMarginChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer
      title={labels.title}
      tooltipContent={labels.tooltip}
      list={(labels.info as unknown) as InfoItemProps[]}
    >
      {isDesktop ? <EBITMarginChartDesktop labels={labels} /> : <EBITMarginChartMobile labels={labels} />}
    </ChartContainer>
  );
};
