export enum InfoCardType {
  Copytext = "Copytext",
  Diagram = "Diagram",
  Gesagt = "Gesagt",
  Getan = "Getan",
  Pdf = "Pdf",
}
export enum InfoCardTheme {
  Default = "Default",
  Gray = "Gray",
  GrayLightest = "GrayLightest",
}
