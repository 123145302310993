import React from "react";
import { SvgContainer } from "#ui";
import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { MainKPI } from "./MainKPI";
import { LeftKPI } from "./LeftKPI";
import { RightKPI1 } from "./RightKPI1";
import { RightKPI2 } from "./RightKPI2";
import { Translations } from "#interfaces";

export interface MultipleChartProps {
  labels: Translations;
}

const CHART_COUNT = 4;

export const MultipleChart: React.FC<MultipleChartProps> = ({ labels }) => {
  const chartProps = useChartSwitcher(Number(labels?.rightKPI_2?.currentValue) ? CHART_COUNT : CHART_COUNT - 1);
  return (
    <SvgContainer viewBox="0 0 896 544" xmlns="http://www.w3.org/2000/svg">
      <MainKPI labels={labels?.mainKPI} {...chartProps} />
      <LeftKPI labels={labels?.leftKPI} {...chartProps} />
      <RightKPI1 labels={labels?.rightKPI_1} {...chartProps} />
      {Number(labels?.rightKPI_2?.currentValue) && <RightKPI2 labels={labels?.rightKPI_2} {...chartProps} />}
    </SvgContainer>
  );
};
