import { chartMainItemDuration } from "#constants";
import { useChartAnimation, useNumberFormat } from "#hooks";
import { ChartProps } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";
import { MarginPath } from "./MarginPath";

export const EBITMargin: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const currentPathRef = useRef<SVGPathElement>(null);
  const prevPathRef = useRef<SVGPathElement>(null);
  const negativePathRef = useRef<SVGPathElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  useChartAnimation({
    ...props,
    maxValue: Number(labels?.maxValue),
    currentValue: Number(labels?.currentValue),
    prevValue: Number(labels?.prevValue),
    minDegree: -35.3,
    maxDegree: 250.9,
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    negativePathRef,
    duration: chartMainItemDuration,
  });
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="165" x="283" y="271.451">
        <circle cx="448" cy="271.951" r="155" />
        <path
          d="m448 126.951c80.081 0 145 64.919 145 145s-64.919 145-145 145-145-64.919-145-145 64.919-145 145-145m0-20c-22.266 0-43.877 4.365-64.232 12.975-19.65 8.311-37.294 20.206-52.441 35.353s-27.041 32.791-35.353 52.441c-8.609 20.354-12.974 41.965-12.974 64.231s4.365 43.877 12.975 64.232c8.311 19.65 20.206 37.294 35.353 52.441s32.791 27.041 52.441 35.353c20.355 8.609 41.966 12.975 64.232 12.975s43.877-4.365 64.232-12.975c19.65-8.311 37.294-20.206 52.441-35.353s27.041-32.791 35.353-52.441c8.608-20.355 12.973-41.966 12.973-64.232s-4.365-43.877-12.975-64.232c-8.311-19.65-20.206-37.294-35.353-52.441s-32.791-27.041-52.441-35.353c-20.354-8.609-41.965-12.974-64.231-12.974z"
          fill="#fff"
        />
      </mask>
      <path
        ref={negativePathRef}
        opacity=".3"
        d="M317.6,364.7C299,338.5,288,306.5,288,272c0-88.4,71.6-160,160-160s160,71.6,160,160
		c0,34.5-11,66.5-29.6,92.7"
        stroke={Color.BlackLight}
        strokeWidth="10"
      />
      <path
        d="M317.6,364.7C299,338.5,288,306.5,288,272c0-88.4,71.6-160,160-160s160,71.6,160,160
		c0,34.5-11,66.5-29.6,92.7"
        stroke={Color.White}
        ref={currentPathRef}
        strokeWidth="10"
      />
      <path
        d="M317.6,364.7C299,338.5,288,306.5,288,272c0-88.4,71.6-160,160-160s160,71.6,160,160
		c0,34.5-11,66.5-29.6,92.7"
        stroke={Color.BlackLight}
        ref={prevPathRef}
        strokeWidth="10"
      />
      <path
        d="m588.14 371.742c20.059-28.132 31.86-62.558 31.86-99.742 0-94.993-77.007-172-172-172s-172 77.007-172 172c0 37.184 11.801 71.61 31.86 99.742"
        stroke={Color.BlackLight}
      />
      <MarginPath labels={labels} {...props} />
      <path ref={lineRef} d="m448 271.951h-165" mask={`url(#${maskID})`} stroke={Color.White} />
      <SvgText ref={contentRef}>
        <SvgTextSmall>
          <text opacity=".5" fontSize="14" transform="matrix(1 0 0 1 448.0002 325.2773)">
            {labels?.diff}
          </text>
          <text fill={Color.BlackLight} fontSize="14" transform="matrix(1 0 0 1 448.0002 218.0771)">
            <tspan x="0" y="0">
              {labels?.label1}
            </tspan>
            <tspan x="0" y="16.8">
              {labels?.label2}
            </tspan>
          </text>
        </SvgTextSmall>
        <text fontSize="60" transform="matrix(1 0 0 1 447.9997 286.2128)">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
