import React, { FC, Fragment } from "react";
import { Root, ScrollHero, scrollHeroItems } from "#components";
import {
  ScrollTopButton,
  Spacer,
  Chapter,
  ImageQuote,
  Title,
  FooterTeaser,
  InterviewImage,
  InterviewVideo,
  ParallaxQuote,
  ParallaxImage,
  ParallaxReport,
  ImageGallery,
  StoryTeaser,
} from "#ui";
import { useTranslation } from "#hooks";

const StoriesComponentsMap: any = {
  Chapter: Chapter,
  Image: InterviewImage,
  ParallaxReport: ParallaxReport,
  ParallaxQuote: ParallaxQuote,
  ParallaxImage: ParallaxImage,
  ImageQuote: ImageQuote,
  ImageGallery: ImageGallery,
  Video: InterviewVideo,
  ScrollTopButton: ScrollTopButton,
  FooterTeaser: FooterTeaser,
  StoryTeaser: StoryTeaser,
};

const headerProps = {
  transparent: true,
  withNav: true,
};

const renderSections = (item: any, key: number) => {
  const Component = StoriesComponentsMap[item.type];
  return (
    <Fragment key={key}>
      <Component {...item.props} />
      <Spacer page={"interview"} />
    </Fragment>
  );
};

interface StoryPageProps {
  tStory: any;
  index?: number;
}

const StoryPage: FC<StoryPageProps> = ({ tStory, index }) => {
  const { i18n } = useTranslation();
  const sections: [] = tStory("sections");
  const { headline } = tStory("title");
  return (
    <Root withHeader headerProps={headerProps}>
      {index !== undefined && <ScrollHero items={scrollHeroItems[i18n.language][index]} />}
      <Spacer page={"interview"} />
      <Title headline={headline} />
      <Spacer page={"interview"} />

      {sections.map(renderSections)}
    </Root>
  );
};

const ReportPage: FC<StoryPageProps> = ({ tStory }) => {
  const sections: [] = tStory("sections");
  return (
    <Root withHeader headerProps={headerProps}>
      {sections.map(renderSections)}
    </Root>
  );
};

export const Diversity: FC = () => {
  const { t: tStory } = useTranslation("diversity");

  return <StoryPage tStory={tStory} index={0} />;
};
export const Emissions: FC = () => {
  const { t: tStory } = useTranslation("emissions");

  return <StoryPage tStory={tStory} index={1} />;
};
export const Production: FC = () => {
  const { t: tStory } = useTranslation("production");

  return <StoryPage tStory={tStory} index={2} />;
};
export const Report: FC = () => {
  const { t: tStory } = useTranslation("report");

  return <ReportPage tStory={tStory} />;
};
export const Foreword: FC = () => {
  const { t: tStory } = useTranslation("foreword");

  return <ReportPage tStory={tStory} />;
};
export const Interview: FC = ({ children }) => {
  return <>{children}</>;
};
