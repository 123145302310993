import { ControlledLine } from "#components";
import { useTranslation } from "#hooks";
import { Figure, LinkProps, TextAside } from "#ui";
import React from "react";

export const Values: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { copytext, image, caption, link, heading, footing, closeBtn } = tHome("values");
  return (
    <ControlledLine source={copytext} modal={link.modal}>
      {({ targetRef, render }) => (
        <TextAside content={render} withLine>
          <Figure
            image={image}
            caption={caption}
            heading={heading}
            footing={footing}
            targetRef={targetRef}
            link={link as LinkProps}
            closeBtn={closeBtn}
          />
        </TextAside>
      )}
    </ControlledLine>
  );
};
