import styled from "styled-components/macro";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";
import React, { FC } from "react";
import { Card, CardProps, Grid, Wrapper, WindowHeightContainer } from "#ui";
import { useTranslation } from "#hooks";

const Container = styled(WindowHeightContainer)`
  max-height: 570px;
  padding-top: 70px;
  padding-bottom: 20px;
  ${gridColumnStart(3)}
  ${gridColumnWidth(8)}
  @media ${bp.s} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    padding-top: 90px;
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.t} {
    ${gridColumnStart(6)}
    ${gridColumnWidth(14)}
  }
`;
const Cardholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
`;
const WrapperCard = styled.div<{ mobileOrder: number }>`
  width: 100%;
  height: calc(100% / 3);
  min-height: 100px;
  position: relative;
  @media ${bp.m} {
    height: 100%;
    min-height: 300px;
    width: calc(100% / 3);
    order: initial;
  }
  order: ${({ mobileOrder }) => mobileOrder};
`;

export const TourEnd: FC = () => {
  const { t } = useTranslation("tour");
  const cards: [] = t("TourEnd");

  return (
    <Wrapper>
      <Grid>
        <Container>
          <Cardholder>
            {cards.map((teaser: CardProps, i: number) => (
              <WrapperCard key={i} mobileOrder={teaser.mobileOrder}>
                <Card {...teaser} />
              </WrapperCard>
            ))}
          </Cardholder>
        </Container>
      </Grid>
    </Wrapper>
  );
};
