import { chartGlobalMarketsDuration } from "#constants";
import { useMarketsAnimation, useNumberFormat } from "#hooks";
import { Color } from "#shared/theme";
import { SvgTextSmall, SvgTextX } from "#ui";
import React, { useRef } from "react";
import { ChartMarketsProps } from "./chartProps";

export const Asia: React.FC<ChartMarketsProps> = ({ labels, ...props }) => {
  const currentPathRef = useRef<SVGCircleElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  useMarketsAnimation({
    ...props,
    currentValue: Number(labels?.charts?.asia),
    currentPathRef,
    contentRef,
    duration: chartGlobalMarketsDuration,
  });
  return (
    <g fill="none">
      <circle ref={currentPathRef} fill="none" stroke={Color.BlackLight} strokeWidth="10" cx="448" cy="272" r="160" />

      <SvgTextX isNegative ref={contentRef}>
        <SvgTextSmall>
          <text fill={Color.BlackLight} fontSize="14" transform="matrix(1 0 0 1 569.6697 315.2772)">
            {labels?.labels?.asia}
          </text>
        </SvgTextSmall>
        <text fontSize="70" transform="matrix(1 0 0 1 569.6699 286.1649)">
          {useNumberFormat(Number(labels?.charts?.asia), 1)}
        </text>
      </SvgTextX>
    </g>
  );
};
