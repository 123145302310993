import { bp, expander } from "#shared/theme";
import React from "react";
import { SlideshowProvider, useSlideshow } from "#providers";
import { NavButton, Card, CardProps } from "#ui";
import styled from "styled-components/macro";
import { Swiper } from "../Swiper/Swiper";
import { StartSlideshowNav } from "./StartSlideshowNav";

const Container = styled.div`
  position: relative;
  padding-left: 50px;
  margin-top: 120px;
  margin-bottom: 50px;
  height: calc(100% - 150px);
  display: flex;
  align-items: center;
`;

const Slides = styled.div`
  width: 230px;
  min-height: 200px;
  max-height: 430px;
  height: 100%;
  display: flex;
  position: relative;
  @media ${bp.t} {
    width: 276px;
    max-height: 516px;
  }
`;
const Item = styled.div`
  width: 100%;
  height: 100%;
`;
const Nav = styled.div`
  @media ${bp.m} {
  }
`;
const NavArrow = styled.div`
  @media ${bp.m} {
    ${expander}
    z-index: 1;
    height: 50px;
    margin: auto;
  }
`;
const NavPrev = styled(NavArrow)`
  transform: rotate(180deg);
  @media ${bp.m} {
    right: auto;
  }
`;

interface CardListProps {
  list: CardProps[];
}

const SlideList: React.FC<CardListProps> = ({ list }) => {
  const { next } = useSlideshow();
  const renderItem = (item: CardProps, key: number) => {
    return (
      <Item key={key}>
        <Card {...item} />
      </Item>
    );
  };
  return (
    <Container>
      <Slides>
        <Swiper<CardProps> list={list} renderItem={renderItem} />
        <Nav>
          <StartSlideshowNav length={list.length} />
        </Nav>
      </Slides>
      <NavPrev>
        <NavButton onClick={next} />
      </NavPrev>
    </Container>
  );
};

export const StartSlideshow: React.FC<CardListProps> = ({ list }) => {
  return (
    <SlideshowProvider length={list.length}>
      <SlideList list={list} />
    </SlideshowProvider>
  );
};
