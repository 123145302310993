import React from "react";
import { ReactComponent as DesktopSvg1 } from "./bmw-IKB-2020_footer_card-1.svg";
import { ReactComponent as MobileSvg1 } from "./bmw-IKB-2020_footer_card-1_mobile.svg";
import { ReactComponent as DesktopSvg2 } from "./bmw-IKB-2020_footer_card-2.svg";
import { ReactComponent as MobileSvg2 } from "./bmw-IKB-2020_footer_card-2_mobile.svg";
import { ReactComponent as DesktopSvg3 } from "./bmw-IKB-2020_footer_card-3.svg";
import { ReactComponent as MobileSvg3 } from "./bmw-IKB-2020_footer_card-3_mobile.svg";
import { storyTeaserListProps } from "../StoryTeaser";

export const storyTeaserListDe: storyTeaserListProps[] = [
  { mobile: <MobileSvg1 />, desktop: <DesktopSvg1 /> },
  { mobile: <MobileSvg2 />, desktop: <DesktopSvg2 /> },
  { mobile: <MobileSvg3 />, desktop: <DesktopSvg3 /> },
];
