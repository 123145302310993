import React from "react";
import { bpTypes } from "#shared/theme";
import { ScrollHeroItemsProps } from "#interfaces";
// DE
import { ReactComponent as LargeDesktopSvgDe1 } from "./itemsDe/story_1/large-desktop.svg";
import { ReactComponent as DesktopSvgDe1 } from "./itemsDe/story_1/desktop.svg";
import { ReactComponent as MobileSvgDe1 } from "./itemsDe/story_1/mobile.svg";
import { ReactComponent as LargeDesktopSvgDe2 } from "./itemsDe/story_2/large-desktop.svg";
import { ReactComponent as DesktopSvgDe2 } from "./itemsDe/story_2/desktop.svg";
import { ReactComponent as MobileSvgDe2 } from "./itemsDe/story_2/mobile.svg";
import { ReactComponent as LargeDesktopSvgDe3 } from "./itemsDe/story_3/large-desktop.svg";
import { ReactComponent as DesktopSvgDe3 } from "./itemsDe/story_3/desktop.svg";
import { ReactComponent as MobileSvgDe3 } from "./itemsDe/story_3/mobile.svg";

// EN
import { ReactComponent as LargeDesktopSvgEn1 } from "./itemsEn/story_1/large-desktop.svg";
import { ReactComponent as DesktopSvgEn1 } from "./itemsEn/story_1/desktop.svg";
import { ReactComponent as MobileSvgEn1 } from "./itemsEn/story_1/mobile.svg";
import { ReactComponent as LargeDesktopSvgEn2 } from "./itemsEn/story_2/large-desktop.svg";
import { ReactComponent as DesktopSvgEn2 } from "./itemsEn/story_2/desktop.svg";
import { ReactComponent as MobileSvgEn2 } from "./itemsEn/story_2/mobile.svg";
import { ReactComponent as LargeDesktopSvgEn3 } from "./itemsEn/story_3/large-desktop.svg";
import { ReactComponent as DesktopSvgEn3 } from "./itemsEn/story_3/desktop.svg";
import { ReactComponent as MobileSvgEn3 } from "./itemsEn/story_3/mobile.svg";

export const scrollHeroListDe: ScrollHeroItemsProps[] = [
  { [bpTypes.i]: <MobileSvgDe1 />, [bpTypes.m]: <DesktopSvgDe1 />, [bpTypes.l]: <LargeDesktopSvgDe1 /> },
  { [bpTypes.i]: <MobileSvgDe2 />, [bpTypes.m]: <DesktopSvgDe2 />, [bpTypes.l]: <LargeDesktopSvgDe2 /> },
  { [bpTypes.i]: <MobileSvgDe3 />, [bpTypes.m]: <DesktopSvgDe3 />, [bpTypes.l]: <LargeDesktopSvgDe3 /> },
];
export const scrollHeroListEn: ScrollHeroItemsProps[] = [
  { [bpTypes.i]: <MobileSvgEn1 />, [bpTypes.m]: <DesktopSvgEn1 />, [bpTypes.l]: <LargeDesktopSvgEn1 /> },
  { [bpTypes.i]: <MobileSvgEn2 />, [bpTypes.m]: <DesktopSvgEn2 />, [bpTypes.l]: <LargeDesktopSvgEn2 /> },
  { [bpTypes.i]: <MobileSvgEn3 />, [bpTypes.m]: <DesktopSvgEn3 />, [bpTypes.l]: <LargeDesktopSvgEn3 /> },
];
