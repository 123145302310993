import { triggerAnalyticsEvent, wrapCallbackWithAnalyticsEventHandler } from "#helpers";
import { useOnClickOutside, usePopover, useResize } from "#hooks";
import { AnalyticsEventTypes, ControlledLineIconTypes, ControlledLineTargetRef } from "#interfaces";
import { useDarkOverlay } from "#providers";
import { bp, Color, expander, fontCondensed, gridColumnStart, gridColumnWidth, IconKeys } from "#shared/theme";
import { motion } from "framer-motion";
import { rgba } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import { Burger } from "../Burger/Burger";
import { ButtonAnimationType, ButtonCircle } from "../ButtonCircle/ButtonCircle";
import { Grid } from "../Grid/Grid";
import { Icon } from "../Icon/Icon";
import { LinkProps } from "../Link/Link";
import { LinkIcon } from "../LinkIcon/LinkIcon";

const transition = {
  duration: 0.4,
  ease: "easeInOut",
};

const contentVariants = {
  hide: { opacity: 0, transition },
  show: { opacity: 1, transition: { ...transition, delay: 0.4 } },
};

const getModalSizes = (mobile: boolean) => {
  return mobile
    ? {
        width: 300,
        height: 480,
      }
    : {
        width: 490,
        height: 490,
      };
};
const getModalPosition = ({
  width,
  height,
  modalSize,
}: {
  width: number;
  height: number;
  modalSize: { width: number; height: number };
}) => ({
  width: width / 2 - modalSize.width / 2,
  height: height / 2 - modalSize.height / 2,
});

const Container = styled.figure`
  margin: 0;
`;
const Frame = styled.div`
  width: 133px;
  height: 133px;
  @media ${bp.t} {
    width: 190px;
    height: 190px;
  }
  @media ${bp.d} {
    width: 241px;
    height: 241px;
  }
`;
const Modal = styled(motion.div)`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  cursor: pointer;
`;
const Image = styled.div`
  margin: 10px;
  ${expander}
`;
const Img = styled.img`
  ${expander}
  max-width: 100%;
  max-height: 100%;
  width: 150px;
  height: 123.35px;
  object-fit: cover;
  object-position: center;
  margin: auto;
  @media ${bp.m} {
    width: 197px;
    height: 162px;
  }
`;
const Content = styled.div`
  margin-top: 35px;
  ${gridColumnStart(2)}
  ${gridColumnWidth(8)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(5)}
  }
`;
const Caption = styled.figcaption`
  ${fontCondensed}
  font-size: 13px;
  line-height: 18px;
  @media ${bp.d} {
    font-size: 16px;
    line-height: 22px;
  }
  p + p {
    margin-top: 1em;
  }
  a {
    font-size: 15px;
    color: ${Color.Green};
    font-weight: bold;
    @media ${bp.d} {
      font-size: 18px;
    }
  }
  strong {
    color: ${Color.Green};
    font-size: 15px;
    font-weight: bold;
    @media ${bp.d} {
      font-size: 18px;
    }
  }
`;
const ModalContent = styled.div`
  box-shadow: 0 0 34px ${rgba(Color.Black, 0.26)};
  background-color: ${Color.BlueBold};
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
`;
const CloseWrapper = styled.div`
  position: absolute;
  right: -17.5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Heading = styled(motion.div)`
  padding: 35px;
  @media ${bp.m} {
    padding: 35px 70px;
  }
`;
const Footing = styled(motion.div)`
  padding: 35px;
`;
const Title = styled.div`
  ${fontCondensed}
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  color: ${Color.Black};
`;
const Headline = styled.h4`
  font-size: 21px;
  line-height: 28px;
  font-weight: normal;
  margin-top: 10px;
`;
const Copytext = styled.div`
  color: ${Color.Black};
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 30px;
`;
export interface FigureProps {
  image?: {
    src: string;
  };
  caption?: string;
  link: LinkProps & {
    analyticsEvent?: AnalyticsEventTypes;
  };
  targetRef?: ControlledLineTargetRef;
  heading: {
    title: string;
    headline: string;
  };
  footing: {
    copytext: string;
  };
  closeBtn?: {
    analyticsEvent?: AnalyticsEventTypes;
  };
}

export const Figure: React.FC<FigureProps> = ({ image, caption, heading, targetRef, link, footing, closeBtn }) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const [animate, setAnimate] = useState("init");
  const isDesktop = useMediaQuery({ query: bp.m });
  const [modalSize, setModalSize] = useState(getModalSizes(!isDesktop));
  const [isPopover, setPopover] = useState(false);
  const [position, setPosition] = useState<DOMRect | undefined>();
  const { open: openOverlay, close: closeOverlay, reset: resetOverlay } = useDarkOverlay();
  const { isOpen, open, isClose, close, isClosing, reset } = usePopover();
  const { width, height } = useResize();
  const [modalPosition, setModalPosition] = useState(getModalPosition({ width, height, modalSize }));

  const handleOpen = () => {
    if (!frameRef.current) return;

    setPosition(frameRef.current.getBoundingClientRect());
    setPopover(true);
  };
  const handleClose = () => {
    if (!isOpen) return;
    closeOverlay();
    close();
    if (closeBtn?.analyticsEvent) {
      triggerAnalyticsEvent(closeBtn.analyticsEvent);
    }
  };
  const handleAnimationComplete = (definition: string) => {
    if (definition === "ready" && isPopover) {
      openOverlay();
      open();
    }
    if (definition === "close" && isClosing) {
      setPopover(false);
      resetOverlay();
      reset();
    }
  };
  useEffect(() => {
    if (isPopover) {
      if (isOpen) {
        setAnimate("open");
      }
      if (isClosing) {
        setAnimate("close");
      }
      if (isClose) {
        setAnimate("ready");
      }
    } else {
      setAnimate("init");
    }
  }, [isPopover, isClose, isOpen, isClosing, setAnimate]);
  useEffect(() => {
    setModalSize(getModalSizes(!isDesktop));
  }, [setModalSize, isDesktop, width]);
  useEffect(() => {
    setModalPosition(getModalPosition({ width, height, modalSize }));
  }, [width, height, modalSize]);
  useOnClickOutside(frameRef, () => {
    if (!isOpen) return;
    close();
  });
  return (
    <Container>
      {image?.src && (
        <Frame ref={frameRef}>
          <Modal
            onClick={handleOpen}
            animate={animate}
            initial="init"
            onAnimationComplete={handleAnimationComplete}
            variants={{
              init: {
                transform: "none",
                position: "static",
                width: "100%",
                height: "100%",
                cursor: "pointer",
                transition: {
                  duration: 0,
                },
              },
              ready: {
                position: "fixed",
                transform: `translate(${position?.x}px, ${position?.y}px)`,
                width: position?.width,
                height: position?.height,
                cursor: "unset",
                transition: {
                  duration: 0,
                },
              },
              open: {
                position: "fixed",
                transform: `translate(${modalPosition.width}px, ${modalPosition.height}px)`,
                cursor: "unset",
                ...modalSize,
                transition,
              },
              close: {
                position: "fixed",
                transform: `translate(${position?.x}px, ${position?.y}px)`,
                cursor: "unset",
                width: position?.width,
                height: position?.height,
                transition,
                transitionEnd: {
                  position: "static",
                  width: "100%",
                  height: "100%",
                  transform: "none",
                },
              },
            }}
          >
            <ModalContent>
              {isOpen && (
                <Heading initial="hide" animate="show" exit="hide" variants={contentVariants}>
                  <Title>{heading.title}</Title>
                  <Headline>{heading.headline}</Headline>
                </Heading>
              )}
              <Image>
                <Img src={image.src} />
              </Image>
              {isOpen && (
                <>
                  <Footing initial="hide" animate={isOpen ? "show" : "hide"} variants={contentVariants}>
                    <Copytext>{footing.copytext}</Copytext>
                    <a
                      onClick={
                        link.analyticsEvent
                          ? wrapCallbackWithAnalyticsEventHandler(handleClose, link.analyticsEvent)
                          : handleClose
                      }
                      href={link.to as string}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <ButtonCircle animation={ButtonAnimationType.circleButtonVcp_70x70}>
                        <Icon iconKey={IconKeys.SvgArrowRight} />
                      </ButtonCircle>
                    </a>
                  </Footing>
                </>
              )}
            </ModalContent>
            {isOpen && (
              <CloseWrapper>
                <ButtonCircle onClick={handleClose}>
                  <Burger isActive />
                </ButtonCircle>
              </CloseWrapper>
            )}
          </Modal>
        </Frame>
      )}
      <Grid columns={[10, 7]}>
        <Content>
          {caption && (
            <>
              <Caption dangerouslySetInnerHTML={{ __html: caption }} />
              <LinkIcon targetRef={targetRef} onClick={handleOpen} type={link.type as ControlledLineIconTypes} />
            </>
          )}
        </Content>
      </Grid>
    </Container>
  );
};
