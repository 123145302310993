import { bp } from "#shared/theme";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components/macro";
import { InfoCard, InfoCardProps } from "./InfoCard";
import { CollageContainer, CollageContent, CollageCopytext, CollageHeadline, CollageLine } from "./InfoCardCollage";

const Container = styled(CollageContainer)``;
const Content = styled(CollageContent)``;
const Copytext = styled(CollageCopytext)`
  text-align: right;
`;

const Headline = styled(CollageHeadline)`
  text-align: left;
  padding-left: 90px;
  @media ${bp.m} {
    padding-left: 135px;
  }
`;

const Visual = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eee;
  width: 176px;
  height: 167px;
  @media ${bp.m} {
    width: 190px;
    height: 211px;
  }
`;
const Line = styled(CollageLine)`
  margin-left: -15px;
  margin-right: auto;
  width: 155px;
  @media ${bp.m} {
    margin-left: -50px;
    width: 255px;
  }
`;
const Rect = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${rgba("#CEFB5F", 0.3)};
  width: 168px;
  height: 205px;
  @media ${bp.m} {
    width: 277px;
    height: 346px;
  }
`;
const Image = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 296px;
  height: 164px;
  background-color: #ddd;
  @media (orientation: landscape) {
    width: 35%;
    height: 70%;
  }
  @media ${bp.m} {
    width: 507px;
    height: 271px;
  }
`;

interface InfoCardGetanProps extends InfoCardProps {
  headline: string;
  copytext: string;
}

export const InfoCardGetan: React.FC<InfoCardGetanProps> = ({ title, category, headline, copytext }) => {
  return (
    <Container>
      <InfoCard title={title} category={category}>
        <Visual />
        <Content>
          <Headline>{headline}</Headline>
          <Line />
          <Copytext dangerouslySetInnerHTML={{ __html: copytext }} />
        </Content>
        <Image />
        <Rect />
      </InfoCard>
    </Container>
  );
};
