import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card12: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect y="133.5" width="306.5" height="8" />
        <rect x="529.5" fill="#42637C" width="111.5" height="341" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g>
          <g id="screen">
            <path
              fill="none"
              d="M446.62,69.73c-2.16,0-3.92,1.76-3.92,3.92c0,2.16,1.76,3.92,3.92,3.92c1.08,0,2.06-0.44,2.77-1.15
				l-3.41-2.95l0.15-0.17l3.42,2.95c0.62-0.69,1-1.6,1-2.6C450.54,71.48,448.78,69.73,446.62,69.73z"
            />
            <path
              fill="#659896"
              d="M320.11,244.18c1.81-4.2,4.35-7.72,7.5-10.67c-0.01,0-0.02-0.01-0.03-0.01c-0.78-0.29-1.58-0.17-2.3-0.07
				c-0.71,0.1-1.32,0.19-1.75-0.14c-0.28-0.22-0.44-0.61-0.5-1.18c-0.05-0.54-0.07-1.37-0.1-2.44c-0.16-5.82-0.48-17.79-5.97-21.74
				l0,27.47c0.9,4.23,1.84,6.99,3.12,8.75C320.09,244.15,320.1,244.16,320.11,244.18z"
            />
            <path
              fill="#659896"
              d="M319.96,244.53c-0.01-0.01-0.02-0.03-0.03-0.04c-1.21-1.61-2.12-4.05-2.97-7.72l0,22.22l0,2.16
				C316.95,254.69,318.01,249.2,319.96,244.53z"
            />
            <path
              fill="#659896"
              d="M379.29,211.44l0.04-14.72c0-8.26-1.02-14.75-4.23-19.67c-0.01,0-0.03,0-0.04,0
				c-14.48,1.32-24.97,3.97-31.84,6.3c-10.38,3.52-14.98,7.08-15.02,7.12c-0.02,0.02-0.05,0.02-0.07,0.02
				c-0.03,0-0.07-0.01-0.09-0.04c-0.04-0.05-0.03-0.12,0.02-0.16c0.05-0.04,4.67-3.62,15.09-7.16c6.87-2.33,17.33-4.98,31.77-6.3
				c0.01,0,0.03,0,0.04,0c-2.29-3.4-5.66-6.04-10.52-7.98c-1.64-0.66-3.74-1.24-6.13-1.85c0,0,0,0.01-0.01,0.01
				c-0.21,0.33-0.4,0.68-0.58,1.06c-1.51,3.25-1.35,4.84-1.25,5.78c0.11,1.07,0.06,1.41-1.79,2.15c-0.25,0.1-0.47,0.19-0.67,0.27
				c-1.15,0.47-1.78,0.73-4.7,1.37c-0.14,0.03-0.27,0.04-0.4,0.04c-0.98,0-1.5-0.83-1.51-0.84c-0.04-0.06-1.11-1.65,0.37-5.67
				c0.64-1.75,0.13-2.58-0.37-3.38c-0.55-0.89-1.07-1.74,0.2-3.62c0,0,0.01-0.01,0.01-0.01c-0.94-0.29-1.89-0.59-2.84-0.91
				c0,0,0,0.01-0.01,0.01c-0.13,0.29-0.25,0.59-0.35,0.89c-1.21,3.72-1.42,6.71-1.6,9.12c-0.27,3.79-0.45,6.29-4.32,7.99
				c-6.91,3.03-11.3,5.42-11.34,5.44c-0.02,0.01-0.04,0.01-0.05,0.01c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.05-0.15
				c0.04-0.02,4.45-2.42,11.36-5.45c3.75-1.64,3.91-3.96,4.19-7.8c0.17-2.42,0.39-5.43,1.61-9.18c0.1-0.3,0.21-0.6,0.35-0.9
				c0,0,0-0.01,0.01-0.01c-1.07-0.37-2.14-0.77-3.21-1.2c0,0.01,0,0.01-0.01,0.02c-0.84,2.03-1.36,4.34-1.84,7.07
				c-0.11,0.63-0.2,1.21-0.27,1.72c-0.47,3.1-0.66,4.38-4.21,6.11c-4.62,2.26-7.84,3.44-7.87,3.45c-0.01,0-0.03,0.01-0.04,0.01
				c-0.05,0-0.09-0.03-0.11-0.07c-0.02-0.06,0.01-0.12,0.07-0.15c0.03-0.01,3.23-1.19,7.85-3.44c3.45-1.68,3.63-2.93,4.09-5.94
				c0.08-0.51,0.16-1.09,0.28-1.72c0.48-2.75,1-5.07,1.85-7.11c0-0.01,0-0.01,0.01-0.02c-1.5-0.61-2.99-1.28-4.46-2.04
				c0,0.01,0,0.01-0.01,0.02c-0.71,2.52-1.19,4.43-1.55,5.83c-1.19,4.66-1.19,4.66-3.9,7.03l-0.15,0.13
				c-1.24,1.08-4.4,2.29-4.53,2.34c-0.01,0.01-0.03,0.01-0.04,0.01c-0.05,0-0.09-0.03-0.11-0.07c-0.02-0.06,0.01-0.12,0.07-0.15
				c0.03-0.01,3.26-1.25,4.46-2.3l0.15-0.13c2.66-2.32,2.66-2.32,3.83-6.91c0.36-1.41,0.85-3.33,1.57-5.88c0-0.01,0-0.01,0.01-0.02
				c-10.05-5.23-18.77-14.27-19.5-31.77l0,0.79l-0.01,78.77c5.77,3.92,6.11,16.18,6.27,22.1c0.03,1.06,0.05,1.89,0.1,2.42
				c0.04,0.49,0.17,0.81,0.38,0.98c0.32,0.26,0.88,0.17,1.52,0.08c0.77-0.11,1.69-0.25,2.59,0.14c0.01,0,0.02,0.01,0.03,0.01
				c11.31-10.38,30.31-13.62,51.44-14.52c0-0.33,0-0.66,0-0.98L379.29,211.44z M368.34,187.39h0.23v24.34h-0.23V187.39z
				 M356.26,188.42c0.36,0,0.65,0.29,0.65,0.65c0,0.36-0.29,0.65-0.65,0.65c-0.36,0-0.65-0.29-0.65-0.65
				C355.6,188.71,355.9,188.42,356.26,188.42z M353.45,187.97c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76
				c-0.42,0-0.76-0.34-0.76-0.76C352.68,188.31,353.03,187.97,353.45,187.97z M350.39,187.63c0.36,0,0.65,0.29,0.65,0.65
				c0,0.36-0.29,0.65-0.65,0.65c-0.36,0-0.65-0.29-0.65-0.65C349.74,187.92,350.03,187.63,350.39,187.63z M347.37,187.01
				c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76s-0.76-0.34-0.76-0.76C346.61,187.36,346.95,187.01,347.37,187.01z
				 M346.06,190.62c0.08,0,0.24,0.04,0.69,0.17c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23c-10.57-0.67-16.31-2.26-18.19-2.78
				c-0.33-0.09-0.58-0.16-0.63-0.16V190.62z M346.06,193.63c0.08,0,0.24,0.04,0.69,0.17c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23
				c-10.57-0.67-16.31-2.26-18.19-2.78c-0.33-0.09-0.58-0.16-0.63-0.16V193.63z M346.06,196.65c0.08,0,0.24,0.04,0.69,0.17
				c1.88,0.52,7.6,2.1,18.15,2.77l-0.01,0.23c-10.57-0.67-16.31-2.26-18.19-2.78c-0.33-0.09-0.58-0.16-0.63-0.16V196.65z
				 M325.27,228.41c-0.02,0.04-0.06,0.06-0.1,0.06c-0.02,0-0.04,0-0.06-0.01c-0.05-0.03-0.07-0.1-0.04-0.15l9.66-17l0.09,0.03
				c0.07,0.02,7.38,2.15,14.59,3.2c0.06,0.01,0.1,0.07,0.1,0.13c-0.01,0.06-0.06,0.1-0.13,0.1c-6.8-1-13.69-2.94-14.53-3.18
				L325.27,228.41z M349.03,217.6c-6.01-0.3-13.49-2.83-13.57-2.86c-0.06-0.02-0.09-0.08-0.07-0.14c0.02-0.06,0.08-0.09,0.14-0.07
				c0.07,0.03,7.53,2.54,13.5,2.84c0.06,0,0.11,0.06,0.11,0.12C349.15,217.55,349.1,217.6,349.03,217.6
				C349.04,217.6,349.03,217.6,349.03,217.6z M349.04,220.03c0,0.06-0.05,0.1-0.11,0.1c0,0-0.01,0-0.01,0
				c-9.96-0.75-13.96-2.88-14-2.9c-0.05-0.03-0.08-0.1-0.05-0.15c0.03-0.06,0.1-0.07,0.15-0.05c0.04,0.02,4.01,2.13,13.91,2.88
				C348.99,219.91,349.04,219.97,349.04,220.03z"
            />
            <path
              fill="#659896"
              d="M347.61,167.69c0.5,0.81,1.07,1.73,0.39,3.58c-1.43,3.89-0.41,5.45-0.4,5.47
				c0.03,0.04,0.59,0.93,1.67,0.7c2.9-0.63,3.53-0.89,4.66-1.36c0.2-0.08,0.42-0.17,0.67-0.27c1.78-0.71,1.75-0.97,1.65-1.92
				c-0.1-0.97-0.27-2.6,1.27-5.9c0.17-0.36,0.36-0.7,0.55-1.02c0,0,0-0.01,0.01-0.01c-3.05-0.77-6.56-1.6-10.23-2.71
				c0,0-0.01,0.01-0.01,0.01C346.6,166.06,347.07,166.82,347.61,167.69z"
            />
            <path
              fill="#1EBEE4"
              d="M388.28,106.61c0.3,0,0.54-0.24,0.54-0.54c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54
				C387.74,106.36,387.98,106.61,388.28,106.61z"
            />
            <path
              fill="#1EBEE4"
              d="M473.1,192.48c0-0.06,0.05-0.11,0.12-0.11l2.23,0.08c0-3.15-1.16-6.14-3.32-8.45
				c-0.14-0.15-0.28-0.29-0.42-0.43l-0.57,0.89l-0.1-0.09c-0.03-0.03-3.4-3.14-7.27-3.28l-0.14-0.01l0.26-1.02
				c-0.14-0.01-0.27-0.02-0.41-0.02c-0.16-0.01-0.31-0.01-0.47-0.01c-3.14,0-6.13,1.17-8.44,3.32c-0.41,0.38-0.78,0.78-1.13,1.21
				l6.44,4.78c0.87-1.09,2.22-1.77,3.71-1.71c2.5,0.09,4.45,2.2,4.36,4.69c-0.04,1.21-0.56,2.33-1.44,3.15
				c-0.84,0.78-1.93,1.21-3.08,1.21c-0.06,0-0.11,0-0.17,0c-2.5-0.09-4.45-2.2-4.36-4.69c0.03-0.92,0.34-1.77,0.85-2.47l-6.45-4.79
				c-1.57,1.97-2.49,4.36-2.66,6.91l1.33,0.05c0.06,0,0.11,0.05,0.11,0.12c0,0.06-0.05,0.11-0.11,0.11c0,0,0,0,0,0l-1.34-0.05
				c0,0.04-0.01,0.09-0.01,0.13c-0.12,3.32,1.06,6.48,3.31,8.91c2.26,2.43,5.33,3.84,8.64,3.96c2.33,0.09,4.58-0.47,6.55-1.59
				l-0.55-0.86c-0.03-0.05-0.02-0.12,0.03-0.16c0.05-0.03,0.12-0.02,0.16,0.03l0.56,0.86c0.77-0.45,1.49-0.99,2.16-1.61
				c2.43-2.26,3.84-5.33,3.96-8.64c0-0.08,0-0.15,0-0.23l-2.24-0.08C473.14,192.59,473.09,192.54,473.1,192.48z M458.71,187.12
				l-0.15-0.16c0.69-0.64,1.48-1.14,2.36-1.47l0.08,0.21C460.14,186.02,459.38,186.5,458.71,187.12z M466.29,185.88
				c-0.82-0.38-1.7-0.59-2.62-0.62l-0.03,0l0.01-0.23l0.03,0c0.94,0.03,1.85,0.25,2.7,0.64L466.29,185.88z M456.42,191.92
				l-0.11-0.02c0.03-0.94,0.25-1.84,0.63-2.69l0.21,0.09c-0.37,0.82-0.58,1.69-0.61,2.6L456.42,191.92z M458.22,197.02
				c-0.64-0.69-1.14-1.48-1.47-2.36l0.21-0.08c0.32,0.85,0.8,1.62,1.42,2.28L458.22,197.02z M463.17,199.27
				c-0.94-0.03-1.85-0.25-2.69-0.63l0.09-0.21c0.82,0.37,1.7,0.58,2.6,0.61l0.01,0.11L463.17,199.27z M465.92,198.83l-0.08-0.21
				c0.85-0.32,1.62-0.79,2.29-1.42l0.15,0.17C467.59,198.01,466.8,198.5,465.92,198.83z M468.47,187.46l0.17-0.15
				c0.64,0.69,1.13,1.48,1.46,2.36l-0.21,0.08C469.57,188.9,469.09,188.13,468.47,187.46z M470.54,192.43
				c-0.03,0.93-0.25,1.84-0.64,2.69l-0.21-0.09c0.38-0.82,0.58-1.7,0.62-2.61l0.23,0L470.54,192.43z"
            />
            <path
              fill="#1EBEE4"
              d="M472.01,182.67c-2.25-2.83-6.91-3.5-7.63-3.59l-0.2,0.78c2.89,0.26,5.49,1.5,7.48,3.36L472.01,182.67z"
            />
            <path
              fill="#1EBEE4"
              d="M471.54,183.41c-2.04-1.92-4.62-3.09-7.42-3.33l-0.2,0.79c3.51,0.19,6.59,2.73,7.17,3.25L471.54,183.41z"
            />
            <polygon
              fill="#1EBEE4"
              points="417.19,96.52 415.45,95.62 413.7,96.48 413.7,98.77 415.5,99.78 417.26,98.73 			"
            />
            <polygon
              fill="#1EBEE4"
              points="483.39,114.99 479.24,112.71 475.04,115.24 475.04,120.22 479.27,122.74 483.39,120.01 			"
            />
            <path
              fill="#1EBEE4"
              d="M394.31,106.61c0.3,0,0.54-0.24,0.54-0.54c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54
				C393.77,106.36,394.01,106.61,394.31,106.61z"
            />
            <path
              fill="#1EBEE4"
              d="M473.11,121.09l6.24,3.7l5.87-3.78v-7.03l-6.07-3.53l-6.04,3.99V121.09z M474.81,115.11l4.42-2.66
				l4.38,2.41v5.27l-4.33,2.88l-4.47-2.66V115.11z"
            />
            <path
              fill="#1EBEE4"
              d="M391.3,106.61c0.3,0,0.54-0.24,0.54-0.54c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54
				C390.76,106.36,391,106.61,391.3,106.61z"
            />
            <polygon
              fill="#1EBEE4"
              points="400.49,205.17 400.49,200.94 404.06,198.79 407.6,200.73 407.6,204.99 404.1,207.32 
				402.5,206.36 400.97,209.3 407.58,208.92 411.23,202.68 407.72,196.83 400.66,196.92 397.05,203.11 400.48,209.33 400.71,209.32 
				402.3,206.24 			"
            />
            <path
              fill="#1EBEE4"
              d="M481.41,108.87c-0.46,0-0.83,0.37-0.83,0.83c0,0.46,0.37,0.83,0.83,0.83c0.22,0,0.42-0.09,0.57-0.23
				c-0.01-0.03,0-0.06,0.01-0.09c0.02-0.04,0.07-0.06,0.11-0.06c0.09-0.13,0.14-0.29,0.14-0.46
				C482.24,109.24,481.87,108.87,481.41,108.87z"
            />
            <path
              fill="#1EBEE4"
              d="M479.34,127.23c0.25-0.12,0.35-0.43,0.23-0.68c-0.12-0.25-0.43-0.35-0.68-0.23
				c-0.25,0.12-0.35,0.43-0.23,0.68C478.78,127.25,479.09,127.36,479.34,127.23z"
            />
            <polygon fill="#1EBEE4" points="471.23,98.04 472.53,96.41 470.45,94.61 470.1,97.18 			" />
            <path
              fill="#1EBEE4"
              d="M451.65,122.93l3.7,0.55c0.01-0.09,0.01-0.18,0.01-0.27c0-1.24-0.58-2.35-1.49-3.07L451.65,122.93z"
            />
            <path
              fill="#1EBEE4"
              d="M368.67,162.7c4.42,0.66,5.08,5.15,5.08,7.77c0,1.87,1.67,2.24,4.37,2.24l4.94,0.01
				c12.3,0.02,41.84-0.02,50.51-0.73c0.85-0.07,1.49-0.79,1.46-1.63c-0.07-2.25,0.19-6.41,2.71-9.03c1.45-1.51,3.44-2.28,5.93-2.3
				c0.05,0,0.1,0,0.15,0c8.93,0,9.72,7.3,10.05,10.42c0.02,0.22,0.05,0.43,0.07,0.61c0.06,0.49,0.5,0.85,0.98,0.81
				c2.37-0.17,8-0.62,8.98-0.99c4.69-1.75,2.75-9.52,1.59-14.16c-0.51-2.02-0.74-3.01-0.53-3.34c0.02-0.02,0.01-0.04,0-0.06
				c-0.37-0.79-8.01-1.78-11.28-2.2c-1.05-0.14-1.88-0.24-2.23-0.31c-0.33-0.06-1.2-0.36-2.51-0.81
				c-5.88-2.03-19.64-6.79-32.65-6.79c-10.86,0-17.23,3.92-21.89,6.77c-2.17,1.34-3.89,2.39-5.47,2.65l-2.5,0.2
				c-0.07,0-7.29,0.47-15.2,1.48c-12.06,1.54-19.76,6.33-22.94,10.3c-0.18,0.22-0.32,0.42-0.46,0.62c3.66,1.1,7.18,1.93,10.23,2.71
				C360.51,163.04,365.04,162.16,368.67,162.7z"
            />
            <path
              fill="#1EBEE4"
              d="M463.27,196.46c1.14,0.05,2.25-0.37,3.09-1.15c0.84-0.78,1.33-1.85,1.37-3c0.09-2.37-1.77-4.37-4.14-4.46
				c-0.05,0-0.11,0-0.16,0c-1.35,0-2.57,0.63-3.36,1.62l1.42,1.05c0.48-0.58,1.22-0.94,2.04-0.91c0.68,0.02,1.3,0.31,1.76,0.81
				s0.7,1.14,0.68,1.82c-0.02,0.68-0.31,1.3-0.81,1.77c-0.47,0.44-1.08,0.68-1.72,0.68c-0.03,0-0.06,0-0.1,0
				c-0.68-0.02-1.3-0.31-1.76-0.81c-0.46-0.5-0.7-1.14-0.68-1.82c0.02-0.5,0.18-0.97,0.45-1.36l-1.42-1.05
				c-0.48,0.66-0.77,1.47-0.8,2.34C459.03,194.37,460.89,196.37,463.27,196.46z"
            />
            <path
              fill="#1EBEE4"
              d="M476.29,83.32c-3.58,0-10.42,0-18.56,0l12.55,10.85l0.05-0.34l2.82-0.94l2.46,1.71l-0.35,3.34l-1.33,0.57
				l4.39,8.34c0.03,0.06,0.01,0.12-0.05,0.15c-0.05,0.03-0.12,0.01-0.15-0.05l-4.4-8.36l-1.35,0.57l-1.1-0.83l-17.26,21.62
				c0.96,0.76,1.57,1.93,1.57,3.24c0,0.1-0.01,0.2-0.02,0.3l22.82,3.4c-0.06-0.31,0.09-0.64,0.39-0.79c0.31-0.15,0.68-0.06,0.89,0.2
				l7.07-4.41v-8.8l-4.59-2.67c-0.19,0.19-0.46,0.31-0.75,0.31c-0.58,0-1.06-0.47-1.06-1.06c0-0.58,0.47-1.06,1.06-1.06
				s1.06,0.47,1.06,1.06c0,0.21-0.06,0.4-0.17,0.56l4.68,2.71v9.06l-7.18,4.48c0.13,0.35-0.01,0.75-0.36,0.92
				c-0.1,0.05-0.21,0.07-0.32,0.07c-0.25,0-0.49-0.13-0.62-0.35l-22.94-3.42c-0.26,2.04-2,3.62-4.11,3.62
				c-2.29,0-4.14-1.86-4.14-4.14c0-2.28,1.86-4.14,4.14-4.14c0.89,0,1.71,0.28,2.39,0.76l17.26-21.62l-1.24-0.93l0.39-2.84
				l-12.86-11.11c-18.93,0-44.64,0-52.65,0c-41.59,0-87.86,3.19-87.86,42.28c0,0.81,0.02,1.61,0.06,2.39
				c0.01,0.27,0.03,0.53,0.04,0.79l0-0.79c0.73,17.5,9.45,26.54,19.5,31.77c0.02-0.08,0.04-0.16,0.07-0.24
				c1.22-4.35,3.96-7.58,8.62-10.19c4.41-2.47,10.77-4.54,20.61-6.72c13.12-2.9,17.59-4.8,22.32-6.81c3.21-1.36,6.53-2.77,12.7-4.62
				c17.37-5.21,31.23-0.88,43.45,2.94c3.39,1.06,6.6,2.06,9.66,2.78c14.71,3.44,21.09,8.95,25.75,12.98l0.16,0.13
				c4.59,3.96,8.52,3.64,8.56,3.63c0.06-0.01,0.12,0.04,0.12,0.1c0.01,0.06-0.04,0.12-0.1,0.12c-0.04,0-4.06,0.35-8.73-3.69
				l-0.16-0.13c-4.64-4.01-11.01-9.51-25.66-12.93c-3.07-0.72-6.28-1.72-9.68-2.78c-12.19-3.81-26.01-8.13-43.32-2.94
				c-6.15,1.84-9.47,3.25-12.67,4.61c-4.74,2.01-9.22,3.92-22.36,6.82c-9.82,2.17-16.15,4.23-20.55,6.69
				c-4.6,2.58-7.31,5.77-8.51,10.06c-0.03,0.1-0.05,0.19-0.08,0.28c1.47,0.75,2.96,1.43,4.46,2.04c2.07-5.02,6.09-8.4,16.27-12.38
				c9.77-3.81,15.82-5.45,21.15-6.89c5.18-1.4,9.64-2.61,16.38-5.4c11.73-4.87,26.12-5.57,39.46-1.92
				c10.12,2.77,16.4,4.65,24.35,7.02l3.55,1.06c9.32,2.78,12.32,8.04,14.31,11.52c0.11,0.2,0.22,0.39,0.33,0.58
				c0.65,1.13,1.1,1.95,1.46,2.6c0.61,1.11,0.97,1.78,1.69,2.77c1.81,2.5,7.94,2.08,8,2.07c0.06,0,0.12,0.04,0.12,0.1
				c0,0.06-0.04,0.12-0.1,0.12c-0.26,0.02-6.32,0.43-8.2-2.17c-0.72-1-1.09-1.67-1.7-2.79c-0.36-0.66-0.8-1.48-1.46-2.6
				c-0.11-0.19-0.22-0.38-0.33-0.58c-1.97-3.45-4.95-8.66-14.18-11.41l-3.55-1.06c-7.95-2.38-14.23-4.26-24.34-7.02
				c-13.3-3.64-27.63-2.94-39.32,1.91c-6.75,2.8-11.22,4.01-16.41,5.41c-5.33,1.44-11.37,3.07-21.13,6.88
				c-10.1,3.94-14.09,7.29-16.14,12.25c1.07,0.43,2.14,0.83,3.21,1.2c3.85-8.62,21.22-13.08,40.25-17.23
				c5.52-1.2,9.05-2.79,12.46-4.33c4.82-2.17,9.36-4.22,18.71-4.4c10.13-0.19,26.13,4.48,34.76,7.29c1.79,0.58,3.53,1.08,5.22,1.57
				c3.31,0.95,6.43,1.85,9.37,3.19c2.85,1.29,5.02,3.74,6.83,7.69c0.56,1.22,1.07,2.51,1.57,3.77c1.86,4.67,3.62,9.09,7.21,9.09
				h7.15c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.05,0.11-0.11,0.11h-7.15c-3.75,0-5.54-4.48-7.42-9.23c-0.5-1.25-1.01-2.55-1.57-3.76
				c-1.79-3.9-3.92-6.31-6.72-7.58c-2.92-1.33-6.04-2.23-9.34-3.18c-1.69-0.49-3.43-0.99-5.22-1.57
				c-8.45-2.74-23.96-7.28-34.07-7.28c-0.21,0-0.41,0-0.61,0.01c-9.3,0.18-13.62,2.12-18.62,4.38c-3.42,1.54-6.96,3.14-12.51,4.35
				c-15.22,3.32-35.89,7.83-40.08,17.08c0.95,0.33,1.9,0.63,2.84,0.91c0.15-0.22,0.31-0.44,0.51-0.69
				c3.21-4.01,10.96-8.84,23.09-10.39c7.92-1.01,15.14-1.47,15.21-1.48l2.49-0.2c1.53-0.25,3.23-1.3,5.38-2.62
				c4.68-2.87,11.08-6.81,22-6.81c13.05,0,26.83,4.77,32.72,6.8c1.3,0.45,2.17,0.75,2.48,0.8c0.35,0.06,1.18,0.17,2.22,0.31
				c5.58,0.72,11.07,1.5,11.46,2.33c0.04,0.09,0.04,0.19-0.02,0.27c-0.17,0.26,0.17,1.6,0.56,3.16c1.18,4.71,3.15,12.6-1.73,14.42
				c-1.01,0.38-6.66,0.83-9.04,1c-0.61,0.05-1.15-0.4-1.23-1.01c-0.02-0.18-0.04-0.39-0.07-0.61c-0.36-3.32-1.1-10.22-9.82-10.22
				c-0.05,0-0.1,0-0.15,0c-2.43,0.02-4.37,0.77-5.77,2.23c-2.46,2.56-2.71,6.65-2.65,8.87c0.03,0.96-0.7,1.78-1.67,1.86
				c-8.68,0.72-38.23,0.75-50.53,0.73l-4.94-0.01c-2.22,0-4.59-0.19-4.59-2.47c0-5.93-3.06-7.28-4.89-7.55
				c-3.53-0.53-7.94,0.32-10.33,4.08c2.39,0.61,4.48,1.19,6.13,1.85c4.86,1.94,8.23,4.58,10.52,7.98
				c5.76-0.53,12.15-0.84,19.19-0.84h93.99c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.05,0.11-0.11,0.11h-93.99
				c-6.99,0-13.34,0.31-19.06,0.83c3.22,4.92,4.23,11.41,4.23,19.67l-0.04,14.72l0,6.35c0,0.32,0,0.65,0,0.98l16.6-0.17l4.69-9.05
				l-0.24,0.01l-3.57-6.45l3.74-6.41l7.32-0.1l0.03,0.06l3.61,6.02l-0.03,0.06l-3.74,6.4l-0.06,0l-6.81,0.39l-4.7,9.06l9.77-0.1
				c19.88,0,58.44,0,69.97,0c11.53,0,24.65-5.96,24.65-23.85c0-17.89,0-75.94,0-87.86C500.54,94.85,491.79,83.32,476.29,83.32z
				 M473.72,124.11l-2.57-1.87v-8.95l7.62-4.85l0.12,0.19l-7.52,4.78v8.71l2.48,1.8L473.72,124.11z M485.45,113.86v7.28l-6.09,3.92
				l-6.47-3.83v-6.9l6.26-4.13L485.45,113.86z M394.31,105.3c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76
				c-0.42,0-0.76-0.34-0.76-0.76C393.55,105.65,393.89,105.3,394.31,105.3z M391.3,105.3c0.42,0,0.76,0.34,0.76,0.76
				c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76C390.53,105.65,390.87,105.3,391.3,105.3z M388.28,105.3
				c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76C387.52,105.65,387.86,105.3,388.28,105.3z
				 M385.26,105.3c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76
				C384.5,105.65,384.84,105.3,385.26,105.3z M323.85,124.12l-0.21-0.08c4.15-10.38,11.51-16.53,16.94-19.86
				c5.89-3.61,10.87-4.78,10.92-4.79l0.05,0.22c-0.05,0.01-5,1.18-10.86,4.77C335.29,107.69,327.98,113.8,323.85,124.12z
				 M335.84,136.08l-1.62,0.89c-0.02,0.01-0.04,0.01-0.05,0.01c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.04-0.15
				l1.62-0.89c0.05-0.03,0.12-0.01,0.15,0.04C335.91,135.98,335.89,136.05,335.84,136.08z M336.28,135.03v-1.88
				c0-0.06,0.05-0.11,0.11-0.11s0.11,0.05,0.11,0.11v1.88c0,0.06-0.05,0.11-0.11,0.11S336.28,135.1,336.28,135.03z M336.55,138.35
				c0,0.06-0.05,0.11-0.11,0.11c-0.06,0-0.11-0.05-0.11-0.11v-2.2c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11V138.35z
				 M338.69,134.51l-1.75,0.96c-0.02,0.01-0.04,0.01-0.05,0.01c-0.04,0-0.08-0.02-0.1-0.06c-0.03-0.05-0.01-0.12,0.04-0.15
				l1.75-0.96c0.05-0.03,0.12-0.01,0.15,0.04C338.77,134.41,338.75,134.48,338.69,134.51z M335.94,112.14
				c-0.02,0.02-0.05,0.03-0.08,0.03c-0.03,0-0.06-0.01-0.08-0.03c-0.04-0.04-0.04-0.12,0-0.16c0.1-0.09,10.04-9.47,28.57-9.47
				c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.05,0.11-0.11,0.11c-9.63,0-16.85,2.56-21.2,4.7C338.43,109.76,335.96,112.11,335.94,112.14
				z M346.42,132.27c0.04-0.03,4.13-2.64,13.53-3.53l0.02,0.22c-9.34,0.89-13.39,3.47-13.42,3.5L346.42,132.27z M346.83,134.07
				l-0.09-0.21c5.83-2.61,13.2-3.64,13.27-3.65l0.03,0.22C359.97,130.45,352.63,131.47,346.83,134.07z M370.86,137.48l-8.63-10.02
				c-0.7-0.02-8.61-0.13-15.97,3.39l-0.1-0.2c7.71-3.69,16.04-3.41,16.12-3.41l0.05,0l0.03,0.04l8.66,10.05L370.86,137.48z
				 M373.44,106.47c-10.01,0-17.36,1.06-21.76,1.95c-4.77,0.97-7.2,1.94-7.22,1.95l-0.09-0.21c0.02-0.01,2.47-0.99,7.26-1.96
				c4.41-0.89,11.78-1.96,21.82-1.96V106.47z M381.83,121.46h-0.23V93.23h0.23V121.46z M383.93,108.74c0-0.06,0.05-0.11,0.11-0.11
				h14.39c0.06,0,0.11,0.05,0.11,0.11s-0.05,0.11-0.11,0.11h-14.39C383.98,108.86,383.93,108.8,383.93,108.74z M394.78,110.86
				c0,0.06-0.05,0.11-0.11,0.11h-10.41c-0.06,0-0.11-0.05-0.11-0.11s0.05-0.11,0.11-0.11h10.41
				C394.73,110.75,394.78,110.8,394.78,110.86z M402.48,112.98h-18.22c-0.06,0-0.11-0.05-0.11-0.11s0.05-0.11,0.11-0.11h18.22
				c0.06,0,0.11,0.05,0.11,0.11S402.54,112.98,402.48,112.98z M439.1,97.37h1.53v2.85h-1.53V97.37z M436.09,95.32h1.59v4.9h-1.59
				V95.32z M433.07,89.8h1.6v10.42h-1.6V89.8z M413.48,96.33l1.97-0.96l1.96,1.01l0.07,2.48l-1.99,1.18l-2.03-1.14L413.48,96.33z
				 M411.52,110.95h-0.23V94.97h0.23V110.95z M421.64,108.58h-8.17v-0.23h8.17V108.58z M421.88,107.2h-8.4v-0.23h8.4V107.2z
				 M426.49,105.57l-3.97,19.62l-0.22-0.04l3.95-19.49h-12.99v-0.23h13.27L426.49,105.57z M432.8,101.4c-0.06,0-0.11-0.05-0.11-0.11
				s0.05-0.11,0.11-0.11h20.97c0.06,0,0.11,0.05,0.11,0.11s-0.05,0.11-0.11,0.11H432.8z M434.04,180.4h1.75v0.23h-1.75V180.4z
				 M433.43,178.03h0.23v1.88h-0.23V178.03z M433.7,181.05v2.2h-0.23v-2.2H433.7z M431.31,180.4h1.62v0.23h-1.62V180.4z
				 M416.21,209.14l-1.82,1.21l-1.87-1.12v-2.2l1.86-1.12l1.84,1.01V209.14z M442.29,187.68c-1.47,0.48-3.01,0.02-4.49-0.43
				c-1.38-0.42-2.81-0.85-4.17-0.52c-0.65,0.16-1.25,0.48-1.82,0.8c-0.64,0.35-1.3,0.71-2.05,0.86c-1.42,0.27-2.75-0.29-4.05-0.82
				c-1.07-0.44-2.17-0.9-3.32-0.9c-0.01,0-0.02,0-0.02,0c-1.17,0-2.26,0.46-3.33,0.9c-0.98,0.41-1.99,0.83-3.08,0.92
				c-1.68,0.13-3.22-0.56-4.7-1.23c-1.32-0.59-2.68-1.21-4.15-1.25c-1.59-0.04-3.09,0.59-4.55,1.2c-1.53,0.64-3.12,1.31-4.81,1.2
				c-1.21-0.08-2.32-0.54-3.39-0.99c-1.08-0.45-2.2-0.92-3.4-0.98c-1.68-0.08-3.51,0.71-4.9,2.1c-0.01,0.01-0.02,0.01-0.04,0.01
				v5.96c0,0.06-0.05,0.11-0.11,0.11c-0.06,0-0.11-0.05-0.11-0.11v-13.54c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11
				v7.28c1.42-1.36,3.25-2.11,4.94-2.05c1.24,0.06,2.38,0.53,3.48,1c1.05,0.44,2.14,0.9,3.31,0.98c1.64,0.1,3.2-0.55,4.71-1.18
				c1.48-0.62,3-1.27,4.64-1.22c1.51,0.04,2.9,0.66,4.24,1.27c1.52,0.69,2.96,1.33,4.59,1.21c1.05-0.08,2.05-0.5,3.01-0.9
				c1.08-0.45,2.2-0.92,3.41-0.92c0.01,0,0.02,0,0.03,0c1.2,0.01,2.32,0.47,3.41,0.92c1.32,0.55,2.57,1.06,3.92,0.81
				c0.72-0.14,1.36-0.49,1.98-0.83c0.59-0.32,1.19-0.66,1.88-0.82c1.42-0.34,2.88,0.1,4.29,0.52c1.52,0.46,2.96,0.89,4.36,0.43
				c0.06-0.02,0.12,0.01,0.14,0.07C442.38,187.6,442.34,187.66,442.29,187.68z M454.28,202.98l-4.08,5.04
				c-0.02,0.03-0.05,0.04-0.09,0.04c-0.03,0-0.05-0.01-0.07-0.03c-0.05-0.04-0.06-0.11-0.02-0.16l4.08-5.04
				c0.04-0.05,0.11-0.06,0.16-0.02C454.31,202.86,454.32,202.93,454.28,202.98z M450.72,202.6l1.81-1.49
				c0.05-0.04,0.12-0.03,0.16,0.02c0.04,0.05,0.03,0.12-0.02,0.16l-1.81,1.49c-0.02,0.02-0.05,0.03-0.07,0.03
				c-0.03,0-0.06-0.01-0.09-0.04C450.66,202.71,450.67,202.64,450.72,202.6z M456.11,204.09l-0.96,2.25
				c-0.02,0.04-0.06,0.07-0.1,0.07c-0.02,0-0.03,0-0.04-0.01c-0.06-0.02-0.08-0.09-0.06-0.15l0.96-2.25
				c0.02-0.06,0.09-0.08,0.15-0.06C456.11,203.97,456.13,204.03,456.11,204.09z M458.7,205.34l-1.27,4.38
				c-0.01,0.05-0.06,0.08-0.11,0.08c-0.01,0-0.02,0-0.03,0c-0.06-0.02-0.09-0.08-0.08-0.14l1.27-4.38c0.02-0.06,0.08-0.09,0.14-0.08
				C458.69,205.22,458.72,205.28,458.7,205.34z M471.02,205.78c1.02-0.55,2-1.25,2.88-2.12c2.07-2.05,2.67-4.12,2.68-4.14
				c0.02-0.06,0.08-0.1,0.14-0.08c0.06,0.02,0.1,0.08,0.08,0.14c-0.02,0.06-1.16,3.9-5.66,6.38l0.79,1.22
				c0.03,0.05,0.02,0.12-0.03,0.16c-0.02,0.01-0.04,0.02-0.06,0.02c-0.04,0-0.07-0.02-0.09-0.05l-0.79-1.23
				c-2.14,1.13-5.01,1.96-8.84,1.96c-0.06,0-0.11-0.05-0.11-0.11s0.05-0.11,0.11-0.11c2.53,0,5.79-0.39,8.72-1.92l-0.73-1.13
				c-2.19,1.11-4.8,1.68-7.82,1.68c-0.1,0-0.2,0-0.29,0c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11c0,0,0,0,0,0
				c3.1,0.04,5.77-0.52,7.99-1.64l-0.71-1.11c-1.84,1.04-3.96,1.64-6.21,1.64c-0.16,0-0.31,0-0.47-0.01
				c-6.97-0.26-12.43-6.13-12.17-13.1c0-0.04,0.01-0.09,0.01-0.13l-1.94-0.07c-0.06,0-0.11-0.05-0.11-0.12
				c0-0.06,0.05-0.11,0.12-0.11l1.95,0.07c0.17-2.59,1.11-5.03,2.7-7.03l-4-2.97c-0.05-0.04-0.06-0.11-0.02-0.16
				c0.04-0.05,0.11-0.06,0.16-0.02l4.01,2.98c0.36-0.43,0.74-0.85,1.16-1.24c2.47-2.3,5.7-3.5,9.07-3.37
				c0.15,0.01,0.31,0.02,0.46,0.03l0.26-1.01l0.1,0.01c0.05,0.01,5.47,0.56,7.94,3.75l0.05,0.06l-0.46,0.73
				c2.38,2.31,3.85,5.54,3.84,9.08l0.95,0.03c0.06,0,0.11,0.05,0.11,0.12c0,0.06-0.05,0.11-0.11,0.11c0,0,0,0,0,0l-0.95-0.03
				c0,0.08,0,0.15,0,0.23c-0.16,4.46-2.64,8.31-6.22,10.43l0.72,1.11c1.06-0.56,2.01-1.25,2.86-2.08c4.08-4,4.17-9.79,4.17-9.85
				c0-0.06,0.05-0.11,0.11-0.11c0,0,0,0,0,0c0.06,0,0.11,0.05,0.11,0.11c0,0.06-0.1,5.94-4.24,10c-0.86,0.84-1.82,1.54-2.89,2.11
				L471.02,205.78z M485.56,131.48h-1.62c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h1.62c0.06,0,0.11,0.05,0.11,0.11
				C485.67,131.43,485.62,131.48,485.56,131.48z M486.06,130.76v-1.88c0-0.06,0.05-0.11,0.11-0.11c0.06,0,0.11,0.05,0.11,0.11v1.88
				c0,0.06-0.05,0.11-0.11,0.11C486.11,130.87,486.06,130.82,486.06,130.76z M486.33,134.1c0,0.06-0.05,0.11-0.11,0.11
				s-0.11-0.05-0.11-0.11v-2.2c0-0.06,0.05-0.11,0.11-0.11s0.11,0.05,0.11,0.11V134.1z M488.42,131.48h-1.75
				c-0.06,0-0.11-0.05-0.11-0.11c0-0.06,0.05-0.11,0.11-0.11h1.75c0.06,0,0.11,0.05,0.11,0.11
				C488.53,131.43,488.48,131.48,488.42,131.48z"
            />
            <polygon fill="#1EBEE4" points="471.42,98.17 472.4,98.92 473.62,98.4 472.67,96.6 			" />
            <path
              fill="#1EBEE4"
              d="M453.69,120.01c-0.64-0.45-1.41-0.71-2.25-0.71c-2.16,0-3.92,1.76-3.92,3.92c0,2.16,1.76,3.92,3.92,3.92
				c1.99,0,3.64-1.5,3.88-3.43l-4.1-0.61L453.69,120.01z"
            />
            <path
              fill="#1EBEE4"
              d="M461.73,193.73c0.42,0.45,0.99,0.71,1.61,0.74c0.62,0.03,1.21-0.2,1.66-0.62
				c0.45-0.42,0.71-0.99,0.74-1.61c0.02-0.62-0.2-1.21-0.62-1.66c-0.42-0.45-0.99-0.71-1.61-0.74c-0.03,0-0.06,0-0.09,0
				c-0.7,0-1.34,0.32-1.76,0.82l2.08,1.55c0.05,0.04,0.06,0.11,0.02,0.16c-0.02,0.03-0.06,0.05-0.09,0.05
				c-0.02,0-0.05-0.01-0.07-0.02l-2.09-1.55c-0.24,0.35-0.39,0.77-0.41,1.23C461.09,192.69,461.31,193.28,461.73,193.73z"
            />
            <polygon
              fill="#1EBEE4"
              points="473.82,98.31 475.05,97.79 475.37,94.71 473.12,93.14 470.54,94 470.49,94.35 472.67,96.23 
				472.71,96.19 			"
            />
            <path
              fill="#1EBEE4"
              d="M385.26,106.61c0.3,0,0.54-0.24,0.54-0.54c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
				C384.73,106.36,384.97,106.61,385.26,106.61z"
            />
            <path
              fill="#E1D9C8"
              d="M339.27,285.8v-38.43c-0.5-0.2-1.03-0.36-1.61-0.46v40.13c0.32-0.18,0.66-0.42,1.03-0.73
				C338.89,286.14,339.08,285.97,339.27,285.8z"
            />
            <path
              fill="#E1D9C8"
              d="M342.85,281.69v-31.32c-0.46-0.67-0.98-1.27-1.57-1.77v35.09c0.55-0.64,1.08-1.31,1.59-2H342.85z"
            />
            <path
              fill="#E1D9C8"
              d="M340.98,248.36c-0.44-0.34-0.9-0.63-1.41-0.87v38.02c0.49-0.47,0.95-0.96,1.41-1.48V248.36z"
            />
            <path
              fill="#E1D9C8"
              d="M337.36,287.2v-40.32c-0.59-0.08-1.22-0.1-1.89-0.06v40.21c0.13,0.11,0.26,0.2,0.41,0.26
				C336.31,287.48,336.8,287.44,337.36,287.2z"
            />
            <path
              fill="#E1D9C8"
              d="M320.11,244.18c2.21,3.01,5.42,3.08,11.17,2.66c-0.25-5.1-0.5-8.18-1.39-10.51
				c-0.66-1.72-1.46-2.5-2.28-2.82C324.45,236.45,321.92,239.98,320.11,244.18z"
            />
            <path
              fill="#E1D9C8"
              d="M348.48,273.49c-0.58-2.5-0.99-5.24-1.4-7.92c-0.55-3.63-1.1-7.3-2.06-10.43v23.5
				c0.39-0.57,0.77-1.15,1.14-1.72C346.94,275.75,347.7,274.59,348.48,273.49z"
            />
            <path
              fill="#E1D9C8"
              d="M332.31,260.86c0.18,2.76,0.29,5.59,0.4,8.34c0.1,2.45,0.19,4.87,0.33,7.1V247
				c-0.5,0.04-0.99,0.08-1.45,0.11c0.04,0.76,0.07,1.56,0.11,2.4C331.84,252.55,332.02,256.32,332.31,260.86z"
            />
            <path
              fill="#E1D9C8"
              d="M344.72,254.25L344.72,254.25c-0.43-1.26-0.94-2.41-1.57-3.41v30.49c0.54-0.73,1.05-1.48,1.57-2.23
				V254.25z"
            />
            <path
              fill="#E1D9C8"
              d="M335.17,246.84c-0.63,0.05-1.24,0.09-1.82,0.14c0,0,0,0,0,0v32.32h-0.07c0.34,3.49,0.89,6.22,1.89,7.43
				V246.84z"
            />
            <path
              fill="#E1D9C8"
              d="M327.85,233.28c0.84,0.37,1.66,1.2,2.32,2.94c0.9,2.38,1.16,5.47,1.41,10.6
				c0.56-0.04,1.14-0.09,1.75-0.14c0.63-0.05,1.29-0.1,1.98-0.15c9.11-0.68,10.61,9.32,12.07,19c0.39,2.58,0.79,5.22,1.33,7.64
				c2.01-2.8,4.12-5.19,6.73-6.43c1.86-0.89,3.32-1.05,4.45-0.49c1.88,0.91,2.48,3.56,3.05,6.12c0.19,0.84,0.37,1.63,0.59,2.35
				c1.22,3.97,4.27,4.62,4.3,4.63l-0.06,0.3c-0.03-0.01-3.26-0.7-4.53-4.84c-0.22-0.73-0.4-1.53-0.6-2.37
				c-0.56-2.49-1.15-5.07-2.89-5.92c-1.05-0.51-2.42-0.35-4.19,0.5c-2.62,1.25-4.74,3.69-6.77,6.55c0.25,1.07,0.53,2.09,0.86,3.04
				c1.74,5.06,4.8,9.92,8.64,13.66c3.47,3.39,7.03,5.32,9.51,5.16l0.02,0.3c-0.1,0.01-0.2,0.01-0.31,0.01
				c-5.34,0-14.46-8.3-18.14-19.04c-0.3-0.88-0.57-1.83-0.8-2.81c-0.72,1.03-1.43,2.11-2.16,3.2c-0.46,0.7-0.92,1.4-1.4,2.1v0.13
				h-0.09c-1.14,1.69-2.34,3.34-3.65,4.83v0.08h-0.07c-0.73,0.83-1.5,1.61-2.32,2.31c-0.92,0.79-1.71,1.17-2.41,1.17
				c-0.25,0-0.49-0.05-0.72-0.15c-2.67-1.16-2.98-9.13-3.34-18.36c-0.11-2.74-0.22-5.58-0.4-8.33c-0.3-4.54-0.47-8.32-0.61-11.35
				c-0.04-0.84-0.08-1.64-0.11-2.4c-5.81,0.42-9.08,0.36-11.33-2.6c-1.95,4.67-3,10.16-3,16.63l0-2.16
				c-0.01,0.19-0.02,0.38-0.02,0.58v7.84c0,32.25,28.71,44.84,42.95,48.65l4.05,1.04c8.43,2.74,15.43-3.28,15.43-12.77
				c0,0-0.04-53.68-0.07-85.58C358.16,219.66,339.16,222.9,327.85,233.28z M354.53,241.46l-1.62-0.66l0.09-0.21l1.62,0.66
				L354.53,241.46z M355.07,239.12h0.23V241h-0.23V239.12z M355.34,244.36h-0.23v-2.2h0.23V244.36z M357.38,242.63l-1.75-0.72
				l0.09-0.21l1.75,0.72L357.38,242.63z M368.25,303.6h-0.3v-63.31h0.3V303.6z"
            />
            <path
              fill="#E8E7D9"
              d="M343.14,183.14c-10.42,3.53-15.05,7.12-15.09,7.16c-0.05,0.04-0.06,0.11-0.02,0.16
				c0.02,0.03,0.06,0.04,0.09,0.04c0.02,0,0.05-0.01,0.07-0.02c0.05-0.04,4.64-3.6,15.02-7.12c6.87-2.33,17.35-4.98,31.84-6.3
				c0.01,0,0.03,0,0.04,0c5.72-0.52,12.06-0.83,19.06-0.83h93.99c0.06,0,0.11-0.05,0.11-0.11c0-0.06-0.05-0.11-0.11-0.11h-93.99
				c-7.05,0-13.44,0.32-19.19,0.84c-0.01,0-0.03,0-0.04,0C360.47,178.16,350.01,180.81,343.14,183.14z"
            />
            <polygon
              fill="#E8E7D9"
              points="407.6,204.99 407.6,200.73 404.06,198.79 400.49,200.94 400.49,205.17 402.3,206.24 
				403.33,204.27 403.53,204.38 402.5,206.36 404.1,207.32 			"
            />
            <path
              fill="#42637C"
              d="M407.66,209.15l0.06,0l3.74-6.4l0.03-0.06l-3.61-6.02l-0.03-0.06l-7.32,0.1l-3.74,6.41l3.57,6.45
				l0.24-0.01l-4.69,9.05l-1.36,2.63l-0.01,6.4h10.35v-0.23h-10.12v-6.1l1.41-2.71l4.7-9.06L407.66,209.15z M400.48,209.33
				l-3.43-6.22l3.61-6.19l7.06-0.09l3.51,5.85l-3.64,6.24l-6.61,0.38l1.53-2.94l1.03-1.98l-0.2-0.1l-1.02,1.97l-1.59,3.07
				L400.48,209.33z"
            />
            <polygon
              fill="#42637C"
              points="412.52,207.03 412.52,209.23 414.39,210.34 416.21,209.14 416.21,206.92 414.37,205.91 			"
            />
            <rect x="397.73" y="223.77" fill="#42637C" width="7.02" height="0.23" />
            <rect x="397.74" y="225.29" fill="#42637C" width="7.06" height="0.23" />
            <path
              fill="#42637C"
              d="M347.41,167.81c0.5,0.8,1.01,1.64,0.37,3.38c-1.48,4.02-0.42,5.61-0.37,5.67
				c0.01,0.01,0.52,0.84,1.51,0.84c0.13,0,0.26-0.01,0.4-0.04c2.92-0.63,3.55-0.9,4.7-1.37c0.2-0.08,0.42-0.17,0.67-0.27
				c1.85-0.74,1.9-1.08,1.79-2.15c-0.1-0.95-0.27-2.53,1.25-5.78c0.18-0.38,0.37-0.73,0.58-1.06c0,0,0-0.01,0.01-0.01
				c2.38-3.76,6.79-4.61,10.33-4.08c1.83,0.27,4.89,1.61,4.89,7.55c0,2.28,2.37,2.47,4.59,2.47l4.94,0.01
				c12.3,0.02,41.85-0.02,50.53-0.73c0.96-0.08,1.7-0.9,1.67-1.86c-0.07-2.21,0.19-6.31,2.65-8.87c1.4-1.46,3.35-2.21,5.77-2.23
				c0.05,0,0.1,0,0.15,0c8.72,0,9.47,6.9,9.82,10.22c0.02,0.22,0.05,0.43,0.07,0.61c0.07,0.61,0.61,1.06,1.23,1.01
				c2.38-0.18,8.03-0.62,9.04-1c4.88-1.82,2.91-9.71,1.73-14.42c-0.39-1.56-0.72-2.9-0.56-3.16c0.05-0.09,0.06-0.18,0.02-0.27
				c-0.39-0.83-5.87-1.61-11.46-2.33c-1.05-0.14-1.87-0.24-2.22-0.31c-0.31-0.06-1.17-0.35-2.48-0.8c-5.89-2.04-19.67-6.8-32.72-6.8
				c-10.93,0-17.33,3.93-22,6.81c-2.15,1.32-3.85,2.37-5.38,2.62l-2.49,0.2c-0.07,0-7.29,0.47-15.21,1.48
				c-12.13,1.54-19.88,6.38-23.09,10.39c-0.2,0.25-0.36,0.47-0.51,0.69c0,0-0.01,0.01-0.01,0.01
				C346.35,166.07,346.86,166.92,347.41,167.81z M347.84,164.26c0,0,0.01-0.01,0.01-0.01c0.13-0.19,0.28-0.4,0.46-0.62
				c3.18-3.97,10.88-8.77,22.94-10.3c7.91-1.01,15.12-1.47,15.2-1.48l2.5-0.2c1.58-0.26,3.3-1.32,5.47-2.65
				c4.65-2.86,11.02-6.77,21.89-6.77c13.01,0,26.77,4.76,32.65,6.79c1.31,0.45,2.18,0.75,2.51,0.81c0.36,0.06,1.19,0.17,2.23,0.31
				c3.27,0.42,10.91,1.41,11.28,2.2c0.01,0.02,0.01,0.03,0,0.06c-0.21,0.33,0.02,1.31,0.53,3.34c1.16,4.64,3.1,12.4-1.59,14.16
				c-0.98,0.37-6.61,0.81-8.98,0.99c-0.49,0.04-0.92-0.32-0.98-0.81c-0.02-0.18-0.04-0.39-0.07-0.61
				c-0.34-3.13-1.12-10.42-10.05-10.42c-0.05,0-0.1,0-0.15,0c-2.49,0.02-4.49,0.8-5.93,2.3c-2.52,2.62-2.78,6.78-2.71,9.03
				c0.03,0.84-0.62,1.56-1.46,1.63c-8.67,0.72-38.21,0.75-50.51,0.73l-4.94-0.01c-2.7,0-4.37-0.38-4.37-2.24
				c0-2.62-0.66-7.11-5.08-7.77c-3.63-0.54-8.16,0.34-10.59,4.25c0,0,0,0.01-0.01,0.01c-0.2,0.32-0.39,0.66-0.55,1.02
				c-1.54,3.31-1.37,4.93-1.27,5.9c0.1,0.95,0.13,1.21-1.65,1.92c-0.25,0.1-0.47,0.19-0.67,0.27c-1.14,0.47-1.76,0.73-4.66,1.36
				c-1.08,0.23-1.64-0.65-1.67-0.7c-0.01-0.02-1.04-1.58,0.4-5.47c0.68-1.85,0.11-2.77-0.39-3.58
				C347.07,166.82,346.6,166.06,347.84,164.26z"
            />
            <path
              fill="#E8E7D9"
              d="M344.21,164.1c-1.22,3.74-1.43,6.76-1.61,9.18c-0.27,3.84-0.44,6.15-4.19,7.8
				c-6.92,3.03-11.32,5.43-11.36,5.45c-0.05,0.03-0.08,0.1-0.05,0.15c0.02,0.04,0.06,0.06,0.1,0.06c0.02,0,0.04,0,0.05-0.01
				c0.04-0.02,4.44-2.41,11.34-5.44c3.87-1.7,4.05-4.2,4.32-7.99c0.17-2.41,0.39-5.4,1.6-9.12c0.1-0.3,0.21-0.6,0.35-0.89
				c0,0,0-0.01,0.01-0.01c4.19-9.25,24.86-13.76,40.08-17.08c5.55-1.21,9.08-2.8,12.51-4.35c5-2.25,9.32-4.2,18.62-4.38
				c0.2,0,0.41-0.01,0.61-0.01c10.12,0,25.62,4.54,34.07,7.28c1.79,0.58,3.54,1.08,5.22,1.57c3.3,0.95,6.42,1.85,9.34,3.18
				c2.8,1.27,4.93,3.68,6.72,7.58c0.55,1.21,1.07,2.51,1.57,3.76c1.89,4.75,3.67,9.23,7.42,9.23h7.15c0.06,0,0.11-0.05,0.11-0.11
				c0-0.06-0.05-0.11-0.11-0.11h-7.15c-3.6,0-5.35-4.41-7.21-9.09c-0.5-1.25-1.02-2.55-1.57-3.77c-1.81-3.96-3.98-6.4-6.83-7.69
				c-2.94-1.33-6.06-2.23-9.37-3.19c-1.69-0.49-3.43-0.99-5.22-1.57c-8.64-2.8-24.63-7.48-34.76-7.29
				c-9.34,0.18-13.89,2.23-18.71,4.4c-3.41,1.54-6.94,3.13-12.46,4.33c-19.02,4.15-36.39,8.6-40.25,17.23c0,0,0,0.01-0.01,0.01
				C344.43,163.5,344.31,163.79,344.21,164.1z"
            />
            <path
              fill="#E8E7D9"
              d="M339.29,169.04c-0.11,0.63-0.2,1.21-0.28,1.72c-0.45,3.02-0.64,4.26-4.09,5.94
				c-4.61,2.25-7.82,3.43-7.85,3.44c-0.06,0.02-0.09,0.09-0.07,0.15c0.02,0.05,0.06,0.07,0.11,0.07c0.01,0,0.03,0,0.04-0.01
				c0.03-0.01,3.24-1.2,7.87-3.45c3.55-1.73,3.75-3.02,4.21-6.11c0.08-0.51,0.16-1.09,0.27-1.72c0.48-2.73,1-5.03,1.84-7.07
				c0-0.01,0-0.01,0.01-0.02c2.06-4.96,6.04-8.31,16.14-12.25c9.76-3.81,15.8-5.44,21.13-6.88c5.18-1.4,9.66-2.61,16.41-5.41
				c11.69-4.85,26.02-5.55,39.32-1.91c10.11,2.77,16.39,4.64,24.34,7.02l3.55,1.06c9.23,2.75,12.21,7.96,14.18,11.41
				c0.11,0.2,0.22,0.39,0.33,0.58c0.65,1.12,1.1,1.94,1.46,2.6c0.61,1.12,0.98,1.8,1.7,2.79c1.88,2.6,7.94,2.18,8.2,2.17
				c0.06,0,0.11-0.06,0.1-0.12c0-0.06-0.06-0.11-0.12-0.1c-0.06,0.01-6.19,0.42-8-2.07c-0.71-0.99-1.08-1.66-1.69-2.77
				c-0.36-0.66-0.81-1.48-1.46-2.6c-0.11-0.19-0.22-0.38-0.33-0.58c-1.99-3.48-4.99-8.74-14.31-11.52l-3.55-1.06
				c-7.95-2.38-14.23-4.26-24.35-7.02c-13.35-3.65-27.73-2.95-39.46,1.92c-6.74,2.8-11.2,4-16.38,5.4
				c-5.33,1.44-11.38,3.07-21.15,6.89c-10.19,3.98-14.2,7.36-16.27,12.38c0,0.01,0,0.01-0.01,0.02
				C340.29,163.97,339.77,166.29,339.29,169.04z"
            />
            <path
              fill="#E8E7D9"
              d="M334.9,165.66c-1.17,4.59-1.17,4.59-3.83,6.91l-0.15,0.13c-1.21,1.05-4.43,2.29-4.46,2.3
				c-0.06,0.02-0.09,0.09-0.07,0.15c0.02,0.05,0.06,0.07,0.11,0.07c0.01,0,0.03,0,0.04-0.01c0.13-0.05,3.3-1.26,4.53-2.34l0.15-0.13
				c2.71-2.36,2.71-2.36,3.9-7.03c0.36-1.4,0.85-3.31,1.55-5.83c0-0.01,0-0.01,0.01-0.02c0.03-0.1,0.05-0.19,0.08-0.28
				c1.2-4.28,3.91-7.48,8.51-10.06c4.39-2.46,10.73-4.52,20.55-6.69c13.14-2.9,17.62-4.81,22.36-6.82
				c3.21-1.36,6.52-2.77,12.67-4.61c17.31-5.19,31.13-0.87,43.32,2.94c3.4,1.06,6.61,2.06,9.68,2.78
				c14.65,3.42,21.01,8.92,25.66,12.93l0.16,0.13c4.67,4.03,8.69,3.69,8.73,3.69c0.06-0.01,0.11-0.06,0.1-0.12
				c-0.01-0.06-0.06-0.11-0.12-0.1c-0.04,0-3.97,0.33-8.56-3.63l-0.16-0.13c-4.66-4.03-11.05-9.54-25.75-12.98
				c-3.06-0.72-6.27-1.72-9.66-2.78c-12.22-3.82-26.08-8.15-43.45-2.94c-6.16,1.85-9.48,3.26-12.7,4.62
				c-4.73,2.01-9.2,3.91-22.32,6.81c-9.84,2.17-16.2,4.25-20.61,6.72c-4.66,2.61-7.4,5.85-8.62,10.19
				c-0.02,0.08-0.04,0.16-0.07,0.24c0,0.01,0,0.01-0.01,0.02C335.75,162.33,335.26,164.25,334.9,165.66z"
            />
            <path
              fill="#659896"
              d="M353.45,189.27c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54
				S353.15,189.27,353.45,189.27z"
            />
            <path
              fill="#659896"
              d="M347.37,188.31c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54
				S347.08,188.31,347.37,188.31z"
            />
            <path
              fill="#42637C"
              d="M362.33,127.24l-0.05,0c-0.08,0-8.41-0.28-16.12,3.41l0.1,0.2c7.35-3.52,15.27-3.41,15.97-3.39
				l8.63,10.02l0.17-0.15l-8.66-10.05L362.33,127.24z"
            />
            <path
              fill="#42637C"
              d="M351.56,99.61l-0.05-0.22c-0.05,0.01-5.03,1.18-10.92,4.79c-5.44,3.33-12.79,9.48-16.94,19.86l0.21,0.08
				c4.13-10.32,11.44-16.43,16.84-19.74C346.56,100.79,351.51,99.62,351.56,99.61z"
            />
            <path
              fill="#42637C"
              d="M359.95,128.73c-9.4,0.89-13.49,3.51-13.53,3.53l0.12,0.19c0.04-0.03,4.09-2.61,13.42-3.5L359.95,128.73z
				"
            />
            <path
              fill="#42637C"
              d="M346.74,133.86l0.09,0.21c5.8-2.6,13.13-3.62,13.21-3.63l-0.03-0.22
				C359.94,130.22,352.57,131.25,346.74,133.86z"
            />
            <path
              fill="#E8E7D9"
              d="M364.47,102.62c0-0.06-0.05-0.11-0.11-0.11c-18.53,0-28.48,9.38-28.57,9.47c-0.05,0.04-0.05,0.12,0,0.16
				c0.02,0.02,0.05,0.03,0.08,0.03c0.03,0,0.06-0.01,0.08-0.03c0.02-0.02,2.5-2.38,7.22-4.7c4.35-2.15,11.57-4.7,21.2-4.7
				C364.42,102.73,364.47,102.68,364.47,102.62z"
            />
            <path
              fill="#42637C"
              d="M344.37,110.17l0.09,0.21c0.02-0.01,2.46-0.99,7.22-1.95c4.4-0.89,11.75-1.95,21.76-1.95v-0.23
				c-10.04,0-17.4,1.07-21.82,1.96C346.83,109.18,344.39,110.16,344.37,110.17z"
            />
            <rect x="433.07" y="89.8" fill="#42637C" width="1.6" height="10.42" />
            <rect x="439.1" y="97.37" fill="#42637C" width="1.53" height="2.85" />
            <rect x="436.09" y="95.32" fill="#E8E7D9" width="1.59" height="4.9" />
            <path
              fill="#E8E7D9"
              d="M453.89,101.28c0-0.06-0.05-0.11-0.11-0.11H432.8c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11h20.97
				C453.84,101.4,453.89,101.35,453.89,101.28z"
            />
            <polygon
              fill="#42637C"
              points="471.37,113.42 478.89,108.63 478.77,108.44 471.15,113.29 471.15,122.24 473.72,124.11 
				473.85,123.93 471.37,122.13 			"
            />
            <path
              fill="#E8E7D9"
              d="M483.61,120.13v-5.27l-4.38-2.41l-4.42,2.66v5.23l4.47,2.66L483.61,120.13z M475.04,120.22v-4.98
				l4.2-2.53l4.15,2.28v5.02l-4.12,2.73L475.04,120.22z"
            />
            <path
              fill="#42637C"
              d="M472.89,114.32v6.9l6.47,3.83l6.09-3.92v-7.28l-6.31-3.67L472.89,114.32z M485.23,113.99v7.03l-5.87,3.78
				l-6.24-3.7v-6.65l6.04-3.99L485.23,113.99z"
            />
            <path
              fill="#E8E7D9"
              d="M470.54,94l2.58-0.86l2.25,1.57l-0.32,3.08l-1.22,0.52l-1.12-2.12l-0.03,0.04l0.09,0.08l-0.15,0.17
				l-0.08-0.07l-1.3,1.63l-1.14-0.86l0.35-2.57l-0.2-0.18l-0.39,2.84l1.24,0.93l-17.26,21.62c-0.68-0.48-1.5-0.76-2.39-0.76
				c-2.29,0-4.14,1.86-4.14,4.14c0,2.28,1.86,4.14,4.14,4.14c2.11,0,3.85-1.58,4.11-3.62l22.94,3.42c0.14,0.22,0.37,0.35,0.62,0.35
				c0.11,0,0.22-0.02,0.32-0.07c0.34-0.17,0.49-0.57,0.36-0.92l7.18-4.48v-9.06l-4.68-2.71c0.1-0.16,0.17-0.36,0.17-0.56
				c0-0.58-0.47-1.06-1.06-1.06s-1.06,0.47-1.06,1.06c0,0.58,0.47,1.06,1.06,1.06c0.29,0,0.56-0.12,0.75-0.31l4.59,2.67v8.8
				l-7.07,4.41c-0.21-0.26-0.58-0.35-0.89-0.2c-0.3,0.15-0.45,0.48-0.39,0.79l-22.82-3.4c0.01-0.1,0.02-0.2,0.02-0.3
				c0-1.31-0.62-2.49-1.57-3.24l17.26-21.62l1.1,0.83l1.35-0.57l4.4,8.36c0.03,0.06,0.1,0.08,0.15,0.05
				c0.06-0.03,0.08-0.1,0.05-0.15l-4.39-8.34l1.33-0.57l0.35-3.34l-2.46-1.71l-2.82,0.94l-0.05,0.34l0.2,0.18L470.54,94z
				 M482.1,110.16c-0.04,0-0.09,0.02-0.11,0.06c-0.02,0.03-0.02,0.06-0.01,0.09c-0.15,0.14-0.35,0.23-0.57,0.23
				c-0.46,0-0.83-0.37-0.83-0.83c0-0.46,0.37-0.83,0.83-0.83s0.83,0.37,0.83,0.83C482.24,109.87,482.19,110.03,482.1,110.16z
				 M478.89,126.32c0.25-0.12,0.55-0.02,0.68,0.23c0.12,0.25,0.02,0.55-0.23,0.68c-0.25,0.12-0.55,0.02-0.68-0.23
				C478.54,126.75,478.64,126.45,478.89,126.32z M451.45,127.13c-2.16,0-3.92-1.76-3.92-3.92c0-2.16,1.76-3.92,3.92-3.92
				c0.84,0,1.61,0.27,2.25,0.71l-2.46,3.08l4.1,0.61C455.09,125.63,453.44,127.13,451.45,127.13z M455.36,123.21
				c0,0.09-0.01,0.18-0.01,0.27l-3.7-0.55l2.22-2.78C454.78,120.86,455.36,121.97,455.36,123.21z M472.4,98.92l-0.99-0.74l1.25-1.57
				l0.95,1.8L472.4,98.92z"
            />
            <rect x="411.3" y="94.97" fill="#42637C" width="0.23" height="15.97" />
            <rect x="381.6" y="93.23" fill="#42637C" width="0.23" height="28.23" />
            <path
              fill="#E8E7D9"
              d="M398.55,108.74c0-0.06-0.05-0.11-0.11-0.11h-14.39c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11
				h14.39C398.49,108.86,398.55,108.8,398.55,108.74z"
            />
            <path
              fill="#E8E7D9"
              d="M384.26,110.75c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11h10.41c0.06,0,0.11-0.05,0.11-0.11
				s-0.05-0.11-0.11-0.11H384.26z"
            />
            <path
              fill="#E8E7D9"
              d="M402.48,112.76h-18.22c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11h18.22
				c0.06,0,0.11-0.05,0.11-0.11S402.54,112.76,402.48,112.76z"
            />
            <path
              fill="#42637C"
              d="M385.26,106.83c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
				C384.5,106.49,384.84,106.83,385.26,106.83z M385.26,105.53c0.3,0,0.54,0.24,0.54,0.54c0,0.3-0.24,0.54-0.54,0.54
				c-0.3,0-0.54-0.24-0.54-0.54C384.73,105.77,384.97,105.53,385.26,105.53z"
            />
            <path
              fill="#42637C"
              d="M388.28,106.83c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
				C387.52,106.49,387.86,106.83,388.28,106.83z M388.28,105.53c0.3,0,0.54,0.24,0.54,0.54c0,0.3-0.24,0.54-0.54,0.54
				s-0.54-0.24-0.54-0.54C387.74,105.77,387.98,105.53,388.28,105.53z"
            />
            <path
              fill="#42637C"
              d="M391.3,106.83c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
				C390.53,106.49,390.87,106.83,391.3,106.83z M391.3,105.53c0.3,0,0.54,0.24,0.54,0.54c0,0.3-0.24,0.54-0.54,0.54
				s-0.54-0.24-0.54-0.54C390.76,105.77,391,105.53,391.3,105.53z"
            />
            <path
              fill="#42637C"
              d="M394.31,106.83c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
				C393.55,106.49,393.89,106.83,394.31,106.83z M394.31,105.53c0.3,0,0.54,0.24,0.54,0.54c0,0.3-0.24,0.54-0.54,0.54
				s-0.54-0.24-0.54-0.54C393.77,105.77,394.01,105.53,394.31,105.53z"
            />
            <polygon
              fill="#42637C"
              points="413.25,105.66 426.24,105.66 422.29,125.15 422.52,125.2 426.49,105.57 426.52,105.44 
				413.25,105.44 			"
            />
            <rect x="413.48" y="106.98" fill="#42637C" width="8.4" height="0.23" />
            <path
              fill="#E8E7D9"
              d="M417.49,98.85l-0.07-2.48l-1.96-1.01l-1.97,0.96l-0.01,2.57l2.03,1.14L417.49,98.85z M413.7,98.77
				l0.01-2.29l1.74-0.85l1.74,0.9l0.07,2.21l-1.76,1.05L413.7,98.77z"
            />
            <rect x="413.48" y="108.36" fill="#42637C" width="8.17" height="0.23" />
            <path
              fill="#42637C"
              d="M472.13,184c2.15,2.32,3.32,5.31,3.32,8.45l0.23,0.01c0-3.54-1.46-6.77-3.84-9.08l-0.12,0.19
				C471.85,183.71,471.99,183.85,472.13,184z"
            />
            <path
              fill="#42637C"
              d="M471.66,183.22c-1.99-1.87-4.59-3.1-7.48-3.36l-0.06,0.22c2.79,0.24,5.38,1.41,7.42,3.33L471.66,183.22z"
            />
            <path
              fill="#42637C"
              d="M454.57,183.35c2.32-2.15,5.3-3.32,8.44-3.32c0.16,0,0.31,0,0.47,0.01c0.14,0.01,0.27,0.02,0.41,0.02
				l0.06-0.22c-0.15-0.01-0.3-0.02-0.46-0.03c-3.37-0.12-6.6,1.07-9.07,3.37c-0.42,0.39-0.8,0.8-1.16,1.24l0.18,0.14
				C453.79,184.14,454.17,183.73,454.57,183.35z"
            />
            <path
              fill="#42637C"
              d="M453.3,184.73l-0.18-0.13c-1.6,2.01-2.53,4.44-2.7,7.03l0.23,0.01
				C450.8,189.09,451.73,186.7,453.3,184.73z"
            />
            <path
              fill="#E8E7D9"
              d="M463.63,181.08l0.14,0.01c3.87,0.14,7.24,3.25,7.27,3.28l0.1,0.09l0.57-0.89l0.12-0.19l0.46-0.73
				l-0.05-0.06c-2.47-3.19-7.88-3.75-7.94-3.75l-0.1-0.01l-0.26,1.01l-0.06,0.22L463.63,181.08z M464.12,180.08l0.06-0.22l0.2-0.78
				c0.73,0.09,5.39,0.76,7.63,3.59l-0.35,0.56l-0.12,0.19l-0.45,0.7c-0.59-0.51-3.66-3.05-7.17-3.25L464.12,180.08z"
            />
            <path
              fill="#42637C"
              d="M461.57,193.88c0.46,0.5,1.09,0.78,1.76,0.81c0.03,0,0.06,0,0.1,0c0.64,0,1.25-0.24,1.72-0.68
				c0.5-0.46,0.78-1.09,0.81-1.77c0.02-0.68-0.22-1.32-0.68-1.82s-1.09-0.78-1.76-0.81c-0.82-0.04-1.55,0.33-2.04,0.91l0.18,0.14
				c0.43-0.5,1.06-0.82,1.76-0.82c0.03,0,0.06,0,0.09,0c0.62,0.02,1.19,0.28,1.61,0.74c0.42,0.45,0.64,1.04,0.62,1.66
				c-0.02,0.62-0.28,1.19-0.74,1.61c-0.45,0.42-1.04,0.64-1.66,0.62c-0.62-0.02-1.19-0.28-1.61-0.74c-0.42-0.45-0.64-1.04-0.62-1.66
				c0.02-0.46,0.17-0.88,0.41-1.23l-0.18-0.14c-0.27,0.39-0.43,0.85-0.45,1.36C460.86,192.74,461.1,193.39,461.57,193.88z"
            />
            <path
              fill="#42637C"
              d="M463.65,185.04l-0.01,0.23l0.03,0c0.91,0.03,1.79,0.24,2.62,0.62l0.09-0.21
				c-0.85-0.39-1.76-0.61-2.7-0.64L463.65,185.04z"
            />
            <path
              fill="#42637C"
              d="M458.56,186.96l0.15,0.16c0.67-0.62,1.43-1.1,2.28-1.42l-0.08-0.21
				C460.04,185.82,459.24,186.31,458.56,186.96z"
            />
            <path
              fill="#42637C"
              d="M465.84,198.62l0.08,0.21c0.88-0.33,1.67-0.82,2.36-1.46l-0.15-0.17
				C467.46,197.82,466.69,198.3,465.84,198.62z"
            />
            <path
              fill="#42637C"
              d="M470.1,189.67c-0.33-0.88-0.82-1.67-1.46-2.36l-0.17,0.15c0.62,0.67,1.1,1.44,1.41,2.29L470.1,189.67z"
            />
            <path
              fill="#42637C"
              d="M460.57,198.43l-0.09,0.21c0.85,0.39,1.75,0.6,2.69,0.63l0.01-0.11l-0.01-0.11
				C462.26,199.01,461.39,198.81,460.57,198.43z"
            />
            <path
              fill="#42637C"
              d="M456.75,194.66c0.33,0.88,0.82,1.67,1.47,2.36l0.17-0.15c-0.62-0.67-1.1-1.43-1.42-2.28L456.75,194.66z"
            />
            <path
              fill="#42637C"
              d="M456.94,189.21c-0.38,0.85-0.6,1.75-0.63,2.69l0.11,0.02l0.11-0.02c0.03-0.91,0.24-1.78,0.61-2.6
				L456.94,189.21z"
            />
            <path
              fill="#42637C"
              d="M469.7,195.02l0.21,0.09c0.39-0.85,0.6-1.76,0.64-2.69l0-0.02l-0.23,0
				C470.28,193.32,470.07,194.2,469.7,195.02z"
            />
            <path
              fill="#E8E7D9"
              d="M473.21,192.37c-0.07,0-0.11,0.05-0.12,0.11c0,0.06,0.05,0.11,0.11,0.12l2.24,0.08l0.23,0.01l0.95,0.03
				c0,0,0,0,0,0c0.06,0,0.11-0.05,0.11-0.11c0-0.06-0.05-0.11-0.11-0.12l-0.95-0.03l-0.23-0.01L473.21,192.37z"
            />
            <path
              fill="#E8E7D9"
              d="M451.96,191.91C451.96,191.91,451.97,191.91,451.96,191.91c0.06,0,0.11-0.05,0.12-0.11
				c0-0.06-0.05-0.11-0.11-0.12l-1.33-0.05l-0.23-0.01l-1.95-0.07c-0.07,0-0.11,0.05-0.12,0.11c0,0.06,0.05,0.11,0.11,0.12
				l1.94,0.07l0.23,0.01L451.96,191.91z"
            />
            <path
              fill="#E8E7D9"
              d="M461.97,206.2C461.97,206.2,461.97,206.2,461.97,206.2c-0.06,0-0.11,0.05-0.11,0.11
				c0,0.06,0.05,0.11,0.11,0.11c0.1,0,0.2,0,0.29,0c3.02,0,5.63-0.56,7.82-1.68l-0.12-0.19
				C467.74,205.69,465.07,206.24,461.97,206.2z"
            />
            <path
              fill="#E8E7D9"
              d="M477.42,192.54c0-0.06-0.05-0.11-0.11-0.11c0,0,0,0,0,0c-0.06,0-0.11,0.05-0.11,0.11
				c0,0.06-0.1,5.85-4.17,9.85c-0.84,0.83-1.8,1.52-2.86,2.08l0.12,0.19c1.07-0.57,2.04-1.27,2.89-2.11
				C477.32,198.48,477.42,192.59,477.42,192.54z"
            />
            <path
              fill="#E8E7D9"
              d="M459.74,189.52c-0.5,0.7-0.81,1.55-0.85,2.47c-0.09,2.5,1.87,4.6,4.36,4.69c0.06,0,0.11,0,0.17,0
				c1.15,0,2.23-0.43,3.08-1.21c0.89-0.82,1.4-1.94,1.44-3.15c0.09-2.5-1.86-4.6-4.36-4.69c-1.49-0.05-2.84,0.63-3.71,1.71
				l-6.44-4.78l-0.18-0.14l-4.01-2.98c-0.05-0.04-0.12-0.03-0.16,0.02c-0.04,0.05-0.03,0.12,0.02,0.16l4,2.97l0.18,0.13
				L459.74,189.52z M459.92,189.65l1.42,1.05l0.18,0.14l2.09,1.55c0.02,0.02,0.04,0.02,0.07,0.02c0.03,0,0.07-0.02,0.09-0.05
				c0.04-0.05,0.03-0.12-0.02-0.16l-2.08-1.55l-0.18-0.14l-1.42-1.05c0.79-0.99,2.01-1.62,3.36-1.62c0.05,0,0.11,0,0.16,0
				c2.37,0.09,4.23,2.09,4.14,4.46c-0.04,1.15-0.53,2.21-1.37,3c-0.84,0.78-1.94,1.2-3.09,1.15c-2.37-0.09-4.23-2.09-4.14-4.46
				C459.15,191.12,459.45,190.32,459.92,189.65z"
            />
            <path
              fill="#E8E7D9"
              d="M458.63,205.2c-0.06-0.02-0.12,0.02-0.14,0.08l-1.27,4.38c-0.02,0.06,0.02,0.12,0.08,0.14
				c0.01,0,0.02,0,0.03,0c0.05,0,0.09-0.03,0.11-0.08l1.27-4.38C458.72,205.28,458.69,205.22,458.63,205.2z"
            />
            <path
              fill="#E8E7D9"
              d="M456.05,203.94c-0.06-0.03-0.12,0-0.15,0.06l-0.96,2.25c-0.02,0.06,0,0.12,0.06,0.15
				c0.01,0.01,0.03,0.01,0.04,0.01c0.04,0,0.09-0.03,0.1-0.07l0.96-2.25C456.13,204.03,456.11,203.97,456.05,203.94z"
            />
            <path
              fill="#E8E7D9"
              d="M454.11,202.84l-4.08,5.04c-0.04,0.05-0.03,0.12,0.02,0.16c0.02,0.02,0.05,0.03,0.07,0.03
				c0.03,0,0.07-0.01,0.09-0.04l4.08-5.04c0.04-0.05,0.03-0.12-0.02-0.16C454.22,202.78,454.15,202.79,454.11,202.84z"
            />
            <path
              fill="#E8E7D9"
              d="M450.86,202.77l1.81-1.49c0.05-0.04,0.06-0.11,0.02-0.16c-0.04-0.05-0.11-0.06-0.16-0.02l-1.81,1.49
				c-0.05,0.04-0.06,0.11-0.02,0.16c0.02,0.03,0.05,0.04,0.09,0.04C450.81,202.8,450.84,202.79,450.86,202.77z"
            />
            <path
              fill="#42637C"
              d="M469.44,203.35c3.59-2.12,6.06-5.97,6.22-10.43c0-0.08,0-0.15,0-0.23l-0.23-0.01c0,0.08,0,0.15,0,0.23
				c-0.12,3.32-1.53,6.38-3.96,8.64c-0.67,0.62-1.39,1.16-2.16,1.61l-0.56-0.86c-0.03-0.05-0.1-0.07-0.16-0.03
				c-0.05,0.03-0.07,0.1-0.03,0.16l0.55,0.86c-1.98,1.12-4.23,1.68-6.55,1.59c-3.32-0.12-6.38-1.53-8.64-3.96
				c-2.26-2.43-3.43-5.59-3.31-8.91c0-0.04,0.01-0.09,0.01-0.13l-0.23-0.01c0,0.04-0.01,0.09-0.01,0.13
				c-0.26,6.97,5.21,12.85,12.17,13.1c0.16,0.01,0.31,0.01,0.47,0.01c2.25,0,4.37-0.6,6.21-1.64l0.71,1.11l0.12,0.19l0.73,1.13
				c-2.93,1.53-6.19,1.92-8.72,1.92c-0.06,0-0.11,0.05-0.11,0.11s0.05,0.11,0.11,0.11c3.83,0,6.7-0.82,8.84-1.96l0.79,1.23
				c0.02,0.03,0.06,0.05,0.09,0.05c0.02,0,0.04-0.01,0.06-0.02c0.05-0.03,0.07-0.1,0.03-0.16l-0.79-1.22
				c4.51-2.48,5.65-6.33,5.66-6.38c0.02-0.06-0.02-0.12-0.08-0.14c-0.06-0.02-0.12,0.02-0.14,0.08c-0.01,0.02-0.6,2.1-2.68,4.14
				c-0.89,0.87-1.86,1.57-2.88,2.12l-0.73-1.13l-0.12-0.19L469.44,203.35z"
            />
            <path
              fill="#E8E7D9"
              d="M336.44,136.04c-0.06,0-0.11,0.05-0.11,0.11v2.2c0,0.06,0.05,0.11,0.11,0.11c0.06,0,0.11-0.05,0.11-0.11
				v-2.2C336.55,136.09,336.5,136.04,336.44,136.04z"
            />
            <path
              fill="#E8E7D9"
              d="M338.59,134.31l-1.75,0.96c-0.05,0.03-0.07,0.1-0.04,0.15c0.02,0.04,0.06,0.06,0.1,0.06
				c0.02,0,0.04,0,0.05-0.01l1.75-0.96c0.05-0.03,0.07-0.1,0.04-0.15C338.71,134.3,338.64,134.28,338.59,134.31z"
            />
            <path
              fill="#E8E7D9"
              d="M335.73,135.88l-1.62,0.89c-0.05,0.03-0.07,0.1-0.04,0.15c0.02,0.04,0.06,0.06,0.1,0.06
				c0.02,0,0.04,0,0.05-0.01l1.62-0.89c0.05-0.03,0.07-0.1,0.04-0.15C335.85,135.87,335.78,135.85,335.73,135.88z"
            />
            <path
              fill="#E8E7D9"
              d="M336.51,135.03v-1.88c0-0.06-0.05-0.11-0.11-0.11s-0.11,0.05-0.11,0.11v1.88c0,0.06,0.05,0.11,0.11,0.11
				S336.51,135.1,336.51,135.03z"
            />
            <path
              fill="#E8E7D9"
              d="M486.22,131.79c-0.06,0-0.11,0.05-0.11,0.11v2.2c0,0.06,0.05,0.11,0.11,0.11s0.11-0.05,0.11-0.11v-2.2
				C486.33,131.84,486.28,131.79,486.22,131.79z"
            />
            <path
              fill="#E8E7D9"
              d="M488.42,131.25h-1.75c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h1.75
				c0.06,0,0.11-0.05,0.11-0.11C488.53,131.3,488.48,131.25,488.42,131.25z"
            />
            <path
              fill="#E8E7D9"
              d="M485.56,131.25h-1.62c-0.06,0-0.11,0.05-0.11,0.11c0,0.06,0.05,0.11,0.11,0.11h1.62
				c0.06,0,0.11-0.05,0.11-0.11C485.67,131.3,485.62,131.25,485.56,131.25z"
            />
            <path
              fill="#E8E7D9"
              d="M486.28,130.76v-1.88c0-0.06-0.05-0.11-0.11-0.11c-0.06,0-0.11,0.05-0.11,0.11v1.88
				c0,0.06,0.05,0.11,0.11,0.11C486.23,130.87,486.28,130.82,486.28,130.76z"
            />
            <rect x="433.48" y="181.05" fill="#42637C" width="0.23" height="2.2" />
            <rect x="434.04" y="180.4" fill="#42637C" width="1.75" height="0.23" />
            <rect x="431.31" y="180.4" fill="#42637C" width="1.62" height="0.23" />
            <rect x="433.43" y="178.03" fill="#42637C" width="0.23" height="1.88" />
            <rect x="367.94" y="240.28" fill="#42637C" width="0.3" height="63.31" />
            <path
              fill="#42637C"
              d="M319.96,244.53c2.25,2.97,5.53,3.03,11.33,2.6c0.04,0.75,0.07,1.55,0.11,2.4
				c0.14,3.03,0.31,6.81,0.61,11.35c0.18,2.75,0.29,5.59,0.4,8.33c0.36,9.22,0.68,17.19,3.34,18.36c0.23,0.1,0.47,0.15,0.72,0.15
				c0.7,0,1.5-0.39,2.41-1.17c0.82-0.7,1.59-1.48,2.32-2.31h0.07v-0.08c1.31-1.49,2.5-3.15,3.65-4.83h0.09v-0.13
				c0.47-0.7,0.94-1.4,1.4-2.1c0.72-1.09,1.44-2.17,2.16-3.2c0.24,0.98,0.5,1.93,0.8,2.81c3.68,10.74,12.81,19.04,18.14,19.04
				c0.1,0,0.21,0,0.31-0.01l-0.02-0.3c-2.47,0.16-6.04-1.77-9.51-5.16c-3.83-3.75-6.9-8.6-8.64-13.66
				c-0.33-0.95-0.61-1.97-0.86-3.04c2.03-2.86,4.15-5.31,6.77-6.55c1.77-0.84,3.14-1.01,4.19-0.5c1.75,0.85,2.33,3.43,2.89,5.92
				c0.19,0.85,0.37,1.64,0.6,2.37c1.27,4.14,4.5,4.83,4.53,4.84l0.06-0.3c-0.03-0.01-3.08-0.66-4.3-4.63
				c-0.22-0.72-0.4-1.51-0.59-2.35c-0.58-2.56-1.18-5.21-3.05-6.12c-1.14-0.55-2.59-0.39-4.45,0.49c-2.61,1.24-4.72,3.63-6.73,6.43
				c-0.54-2.42-0.94-5.06-1.33-7.64c-1.46-9.68-2.96-19.68-12.07-19c-0.69,0.05-1.35,0.1-1.98,0.15c-0.61,0.05-1.19,0.09-1.75,0.14
				c-0.25-5.13-0.51-8.22-1.41-10.6c-0.66-1.73-1.48-2.56-2.32-2.94c-0.01,0-0.02-0.01-0.03-0.01c-0.9-0.39-1.82-0.25-2.59-0.14
				c-0.64,0.09-1.19,0.17-1.52-0.08c-0.21-0.17-0.34-0.49-0.38-0.98c-0.05-0.53-0.07-1.36-0.1-2.42c-0.16-5.93-0.49-18.18-6.27-22.1
				c0,0-0.01-0.01-0.01-0.01v0.37c0,0,0.01,0.01,0.01,0.01c5.48,3.96,5.81,15.93,5.97,21.74c0.03,1.06,0.05,1.9,0.1,2.44
				c0.05,0.58,0.21,0.96,0.5,1.18c0.43,0.34,1.04,0.25,1.75,0.14c0.72-0.11,1.52-0.22,2.3,0.07c0.01,0,0.02,0.01,0.03,0.01
				c0.82,0.32,1.62,1.09,2.28,2.82c0.89,2.34,1.14,5.41,1.39,10.51c-5.75,0.42-8.96,0.35-11.17-2.66c-0.01-0.01-0.02-0.03-0.03-0.04
				c-1.28-1.76-2.22-4.52-3.12-8.75c0-0.02-0.01-0.03-0.01-0.05v1.39c0,0.01,0.01,0.03,0.01,0.04c0.85,3.66,1.77,6.11,2.97,7.72
				C319.94,244.51,319.95,244.52,319.96,244.53z M345.02,255.14c0.96,3.13,1.52,6.8,2.06,10.43c0.4,2.68,0.82,5.42,1.4,7.92
				c-0.78,1.1-1.54,2.25-2.32,3.43c-0.38,0.57-0.76,1.15-1.14,1.72V255.14z M343.15,250.84c0.62,1,1.14,2.15,1.57,3.41h-0.01v24.84
				c-0.51,0.75-1.03,1.5-1.57,2.23V250.84z M341.28,248.6c0.58,0.5,1.1,1.1,1.57,1.77v31.32h0.03c-0.51,0.69-1.04,1.36-1.59,2V248.6
				z M339.57,247.49c0.51,0.23,0.97,0.53,1.41,0.87v35.68c-0.46,0.51-0.92,1.01-1.41,1.48V247.49z M337.66,246.91
				c0.57,0.1,1.11,0.25,1.61,0.46v38.43c-0.19,0.18-0.38,0.35-0.58,0.52c-0.37,0.31-0.71,0.55-1.03,0.73V246.91z M335.47,246.82
				c0.67-0.04,1.3-0.02,1.89,0.06v40.32c-0.56,0.25-1.05,0.28-1.49,0.09c-0.14-0.06-0.28-0.15-0.41-0.26V246.82z M333.35,279.29
				v-32.32c0,0,0,0,0,0c0.58-0.05,1.19-0.09,1.82-0.14v39.88c-1-1.21-1.55-3.94-1.89-7.43H333.35z M333.05,247v29.29
				c-0.14-2.23-0.24-4.64-0.33-7.1c-0.11-2.74-0.22-5.58-0.4-8.34c-0.3-4.54-0.47-8.31-0.61-11.34c-0.04-0.85-0.08-1.65-0.11-2.4
				C332.06,247.08,332.55,247.04,333.05,247z"
            />
            <path
              fill="#E8E7D9"
              d="M349.38,214.75c0.06,0.01,0.12-0.03,0.13-0.1c0.01-0.06-0.03-0.12-0.1-0.13
				c-7.21-1.06-14.52-3.18-14.59-3.2l-0.09-0.03l-9.66,17c-0.03,0.05-0.01,0.12,0.04,0.15c0.02,0.01,0.04,0.01,0.06,0.01
				c0.04,0,0.08-0.02,0.1-0.06l9.57-16.84C335.69,211.81,342.58,213.75,349.38,214.75z"
            />
            <path
              fill="#E8E7D9"
              d="M349.15,217.49c0-0.06-0.05-0.12-0.11-0.12c-5.98-0.3-13.43-2.82-13.5-2.84
				c-0.06-0.02-0.12,0.01-0.14,0.07c-0.02,0.06,0.01,0.12,0.07,0.14c0.08,0.03,7.56,2.55,13.57,2.86c0,0,0,0,0.01,0
				C349.1,217.6,349.15,217.55,349.15,217.49z"
            />
            <path
              fill="#E8E7D9"
              d="M348.93,219.91c-9.9-0.74-13.87-2.85-13.91-2.88c-0.05-0.03-0.12-0.01-0.15,0.05
				c-0.03,0.05-0.01,0.12,0.05,0.15c0.04,0.02,4.04,2.15,14,2.9c0,0,0.01,0,0.01,0c0.06,0,0.11-0.05,0.11-0.1
				C349.04,219.97,348.99,219.91,348.93,219.91z"
            />
            <path
              fill="#42637C"
              d="M470.45,94.61l2.08,1.8l0.08,0.07l0.15-0.17l-0.09-0.08l-2.19-1.89l-0.2-0.18l-12.55-10.85l-8.02-6.93
				c0.65-0.73,1.05-1.69,1.05-2.75c0-2.28-1.86-4.14-4.14-4.14c-2.28,0-4.14,1.86-4.14,4.14c0,2.28,1.86,4.14,4.14,4.14
				c1.15,0,2.19-0.47,2.94-1.23l7.83,6.76l12.86,11.11L470.45,94.61z M449.54,76.25l-3.42-2.95l-0.15,0.17l3.41,2.95
				c-0.71,0.71-1.69,1.15-2.77,1.15c-2.16,0-3.92-1.76-3.92-3.92c0-2.16,1.76-3.92,3.92-3.92c2.16,0,3.92,1.76,3.92,3.92
				C450.54,74.64,450.16,75.55,449.54,76.25z"
            />
            <rect x="368.34" y="187.39" fill="#42637C" width="0.23" height="24.34" />
            <path
              fill="#42637C"
              d="M347.37,188.54c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76s-0.76,0.34-0.76,0.76
				C346.61,188.2,346.95,188.54,347.37,188.54z M347.37,187.24c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
				s-0.54-0.24-0.54-0.54S347.08,187.24,347.37,187.24z"
            />
            <circle fill="#E8E7D9" cx="350.39" cy="188.28" r="0.65" />
            <path
              fill="#42637C"
              d="M353.45,189.5c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76c-0.42,0-0.76,0.34-0.76,0.76
				C352.68,189.16,353.03,189.5,353.45,189.5z M353.45,188.2c0.3,0,0.54,0.24,0.54,0.54s-0.24,0.54-0.54,0.54
				c-0.3,0-0.54-0.24-0.54-0.54S353.15,188.2,353.45,188.2z"
            />
            <circle fill="#42637C" cx="356.26" cy="189.07" r="0.65" />
            <path
              fill="#42637C"
              d="M364.88,193.78l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
				c0.05,0,0.31,0.07,0.63,0.16C348.57,191.53,354.32,193.11,364.88,193.78z"
            />
            <path
              fill="#42637C"
              d="M364.88,196.8l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
				c0.05,0,0.31,0.07,0.63,0.16C348.57,194.54,354.32,196.13,364.88,196.8z"
            />
            <path
              fill="#42637C"
              d="M364.88,199.81l0.01-0.23c-10.54-0.67-16.27-2.25-18.15-2.77c-0.45-0.13-0.61-0.17-0.69-0.17v0.23
				c0.05,0,0.31,0.07,0.63,0.16C348.57,197.56,354.32,199.14,364.88,199.81z"
            />
            <rect x="355.11" y="242.16" fill="#42637C" width="0.23" height="2.2" />

            <rect
              x="356.44"
              y="241.22"
              transform="matrix(0.3777 -0.9259 0.9259 0.3777 -2.3502 480.8367)"
              fill="#42637C"
              width="0.23"
              height="1.89"
            />

            <rect
              x="353.65"
              y="240.15"
              transform="matrix(0.3777 -0.9259 0.9259 0.3777 -3.0268 477.5539)"
              fill="#42637C"
              width="0.23"
              height="1.75"
            />
            <rect x="355.07" y="239.12" fill="#42637C" width="0.23" height="1.88" />
            <path
              fill="#E8E7D9"
              d="M442.21,187.46c-1.4,0.46-2.84,0.03-4.36-0.43c-1.41-0.42-2.87-0.86-4.29-0.52
				c-0.68,0.16-1.29,0.5-1.88,0.82c-0.62,0.34-1.26,0.7-1.98,0.83c-1.35,0.25-2.6-0.26-3.92-0.81c-1.09-0.45-2.21-0.92-3.41-0.92
				c-0.01,0-0.02,0-0.03,0c-1.21,0-2.33,0.47-3.41,0.92c-0.96,0.4-1.96,0.82-3.01,0.9c-1.63,0.12-3.07-0.52-4.59-1.21
				c-1.34-0.6-2.72-1.23-4.24-1.27c-1.64-0.05-3.16,0.6-4.64,1.22c-1.51,0.63-3.07,1.29-4.71,1.18c-1.17-0.07-2.26-0.53-3.31-0.98
				c-1.1-0.46-2.23-0.94-3.48-1c-1.69-0.07-3.52,0.69-4.94,2.05v-7.28c0-0.06-0.05-0.11-0.11-0.11c-0.06,0-0.11,0.05-0.11,0.11
				v13.54c0,0.06,0.05,0.11,0.11,0.11c0.06,0,0.11-0.05,0.11-0.11v-5.96c0.01-0.01,0.03-0.01,0.04-0.01c1.39-1.39,3.22-2.18,4.9-2.1
				c1.2,0.05,2.32,0.52,3.4,0.98c1.07,0.45,2.18,0.92,3.39,0.99c1.69,0.11,3.28-0.56,4.81-1.2c1.45-0.61,2.96-1.24,4.55-1.2
				c1.47,0.04,2.83,0.65,4.15,1.25c1.48,0.67,3.02,1.36,4.7,1.23c1.09-0.08,2.1-0.51,3.08-0.92c1.06-0.44,2.16-0.9,3.33-0.9
				c0.01,0,0.02,0,0.02,0c1.16,0.01,2.26,0.46,3.32,0.9c1.29,0.54,2.63,1.09,4.05,0.82c0.75-0.14,1.41-0.51,2.05-0.86
				c0.57-0.32,1.17-0.64,1.82-0.8c1.36-0.33,2.79,0.1,4.17,0.52c1.49,0.45,3.03,0.91,4.49,0.43c0.06-0.02,0.09-0.08,0.07-0.14
				C442.34,187.48,442.27,187.45,442.21,187.46z"
            />
          </g>
          <g id="woman">
            <path
              fill="none"
              d="M490.2,243.11l-0.08-0.16c-0.2,0.12-0.32,0.19-0.32,0.19C489.68,243.27,489.86,243.24,490.2,243.11z"
            />
            <path
              fill="#111413"
              d="M521.62,258.23l-14.91-35.63l-1.53-3.67c-0.37-0.98-1.4-1.86-2.24-1.91l-25.36-1.71
				c-0.87-0.05-1.16,0.8-0.62,1.82l8.75,17.16c1.53-1.19,3.17-2.47,4.02-3.15c1.6-1.27,3.65-1.84,4.75-1.25
				c0.58,0.23,0.92,0.92,0.6,1.29c0,0-0.01,0.01-0.01,0.01c-0.38,0.98-2.14,2.07-3.47,3.33c-1.75,1.65-3.77,3.75-3.77,3.75
				l0.88,0.91c0,0,5.47-2.57,7.97-3.85c2.07-1.05,3.39-1.47,4.19-1.05c0.73,0.22,1.05,1.18,0.6,1.53c-0.1,0.08-0.21,0.16-0.34,0.25
				c-0.46,0.59-1.3,1.19-2.24,1.73c-1.25,0.72-5.19,3.03-7.53,4.42c-0.52,0.31-0.96,0.58-1.24,0.74l0.08,0.16
				c0.78-0.28,2.42-1.03,3.48-1.64c1.53-0.88,6.77-4.26,8.16-4.71c0.65-0.21,1.17,0.07,1.4,0.56c0.42,0.33,0.59,0.92,0.28,1.22
				c-0.22,0.21-0.52,0.5-0.82,0.74c-0.03,0.02-0.05,0.05-0.08,0.07c-0.78,0.69-1.79,1.24-1.79,1.24s0.44-0.05,0.78,0.24
				c0.4,0.17,0.65,0.62,0.46,0.91c-0.09,0.13-0.2,0.3-0.32,0.46c-0.23,0.35-0.64,0.75-1.18,1.35c-1.06,1.16-2.54,2.02-3.6,2.98
				c-0.85,0.77-2.56,1.89-3.73,2.55l3.27,6.42c0.41,0.77,1.17,1.4,1.86,1.56l16.46,1.94l5.78,0.68
				C521.53,259.9,521.99,259.22,521.62,258.23z"
            />
            <path
              fill="#D7BFB1"
              d="M500.58,243.56c0.54-0.6,0.95-1,1.18-1.35c-0.31,0.42-0.68,0.83-0.92,0.68
				c-0.33-0.21-0.75-0.43-0.87-0.74c-0.12-0.31,0-0.6,0.78-1.19c0.3-0.23,0.62-0.23,0.88-0.12c-0.34-0.29-0.78-0.24-0.78-0.24
				l0.1,0.02c0,0-3.1,2.56-4,3.09c-0.9,0.53-3.02,1.91-4.19,2.61c-1.16,0.7-0.9,1.22-0.98,1.03c-0.08-0.19,0.26-0.89,1.04-1.35
				c0.78-0.47,3.52-2.14,4.66-3.04c1.15-0.9,3.36-2.36,3.36-2.36s1.01-0.55,1.79-1.24c0.03-0.02,0.05-0.05,0.08-0.07
				c-0.42,0.34-0.84,0.57-1.05,0.37c-0.36-0.35-0.84-0.73-0.91-1.16c-0.07-0.43,0.15-0.76,1.29-1.29c0.47-0.22,0.9-0.12,1.2,0.12
				c-0.23-0.49-0.75-0.77-1.4-0.56c-1.39,0.44-6.63,3.83-8.16,4.71c-1.06,0.61-2.7,1.36-3.48,1.64c-0.35,0.12-0.52,0.15-0.4,0.04
				c0,0,0.12-0.07,0.32-0.19c0.28-0.17,0.72-0.43,1.24-0.74c2.34-1.4,6.28-3.71,7.53-4.42c0.94-0.54,1.79-1.14,2.24-1.73
				c-0.6,0.44-1.39,0.95-1.65,0.62c-0.31-0.39-0.74-0.83-0.76-1.26c-0.01-0.43,0.24-0.73,1.44-1.13c0.26-0.09,0.5-0.08,0.71-0.02
				c-0.8-0.42-2.12,0-4.19,1.05c-2.5,1.28-7.97,3.85-7.97,3.85c-1.79,0.7-2.41,0.78-1.62-0.08c0.59-0.65,0.74-0.82,0.74-0.82
				s2.02-2.1,3.77-3.75c1.34-1.26,3.1-2.36,3.47-3.33c-0.48,0.56-1.36,1.58-1.74,1.27c-0.22-0.18-0.65-0.56-0.97-0.85
				c-0.25-0.22-0.25-0.6,0-0.82c0.25-0.22,0.62-0.51,1.12-0.8c0.36-0.21,0.71-0.21,1-0.1c-1.11-0.59-3.15-0.02-4.75,1.25
				c-0.86,0.68-2.49,1.96-4.02,3.15c-1.84,1.44-3.54,2.75-3.54,2.75c-1.2,1.03-1.21,1.95-1.23,2.66c-0.03,0.7,0.54,1.56,1.31,2.27
				c0.77,0.71,1.46,1.68,1.88,2.57c0.42,0.89,1.18,1.35,1.73,1.57c0.55,0.21,0.74,1.16,1.57,1.71c0.83,0.54,1.77,0.91,2.39,1.4
				c0.62,0.49,1.69,0.78,2.71,0.28c0.2-0.1,0.45-0.23,0.73-0.39c1.17-0.67,2.87-1.78,3.73-2.55
				C498.04,245.59,499.52,244.72,500.58,243.56z"
            />
            <path
              fill="#AD7D62"
              d="M487.09,239.09c-0.79,0.86-0.17,0.79,1.62,0.08l-0.88-0.91C487.83,238.27,487.68,238.45,487.09,239.09z"
            />
            <path
              fill="#141616"
              d="M492.82,246c-0.78,0.47-1.12,1.17-1.04,1.35c0.08,0.19-0.18-0.33,0.98-1.03c1.16-0.7,3.29-2.08,4.19-2.61
				c0.9-0.53,4-3.09,4-3.09l-0.1-0.02c0,0-2.21,1.46-3.36,2.36C496.33,243.87,493.59,245.54,492.82,246z"
            />
            <path
              fill="#D7BFB1"
              d="M583.32,279.09c0,0-0.66-2.4-3.05-3.88s-5.36-0.84-5.36-0.84s-1.31,3.76-1.49,4.74
				c-0.19,0.98-0.75,1.06-1.77,2.29c-1.03,1.23-2.61,2.12-4.29,3.27c-1.68,1.14-4.01,2.37-5.13,3.35c-0.3,0.26-0.51,0.53-0.68,0.81
				c-0.48,0.77-0.7,1.64-1.75,2.72c-1.43,1.46-1.51,2.09-1.99,3.34c-0.48,1.25-1.59,2.93-1.11,3.48c0.48,0.56,1.67,0.7,3.1-0.77
				c1.42-1.45,1.83-2.22,2.92-3.33c-0.08,0.07-0.16,0.14-0.23,0.21c-0.15,0.17-0.32,0.33-0.5,0.47c0,0,0.2-0.19,0.5-0.47
				c0.65-0.73,1.09-1.74,1.45-2.56c0.37-0.83,0.55-2.56,0.58-3.07c-0.01-0.08-0.01-0.13,0-0.14c0.01-0.02,0.01,0.04,0,0.14
				c0.04,0.49,0.31,2.03,0.31,2.53c0,0.58,0.53-2.71,1.11-3.72c0.58-1.01-0.35,2.17-0.31,3.37c0.03,0.77-0.09,1.61-0.18,2.09
				c0.02,0.33-0.06,0.75-0.24,1.13c-0.4,0.84-1.11,3.9-1.11,3.9s-1.91,2.72-2.78,4.04c-0.87,1.32-0.87,1.32-0.87,1.32
				s-1.94,0.74-1.8,1.71c0.13-0.5,1.58-0.86,1.58-0.86s-0.56,1.29,0.11,2.11c0,0,0.13,0.31,0.71,0.39c0.58,0.08,1.33-0.66,1.33-0.66
				s1.42-1.4,2.26-2.44c0.4-0.5,0.65-0.83,0.91-1.14c0.28-0.35,0.57-0.67,1.09-1.18c0.97-0.97,1.37-1.36,1.59-1.9
				c0.22-0.54,2.52-5.62,2.52-5.62s-1.44,4.33-2.26,5.86c-0.35,0.66-1.33,1.75-2.13,2.48c-0.8,0.74,0.62,0.58,1.02,1.01
				s-0.66,1.05-1.06,1.32c-0.4,0.27,1.46-0.5,2.17-0.97c0.71-0.47,1.55-2.09,2.3-3.03c0.75-0.93,1.95-2.48,2.04-2.6
				c0.09-0.12-0.62,1.55-1.46,2.64c-0.84,1.09-1.82,2.44-1.99,2.99s0.8,0.97,1.33,1.16c0.53,0.19,0.97-0.47,1.42-1.32
				c0.44-0.85,2.13-3.84,3.9-4.93c1.77-1.09-0.27,0.5-0.4,1.09s-2.04,1.94-2.66,3.06c-0.62,1.12-0.84,1.47-0.53,1.59
				c0.22,0.08,0.43,0.14,0.54,0.17c0.55-0.39,0.83-1.05,1.5-1.84c0.75-0.89,2.17-3.22,2.7-3.88c0.53-0.66,0.49-2.99,0.8-4.42
				c0.31-1.43,0.53-4.27,1.2-5.47c0.63-1.13,1.57-1.65,1.71-3c0.01-0.08,0.02-0.17,0.02-0.26
				C580.88,284.02,583.32,279.09,583.32,279.09z"
            />
            <path
              fill="#AD7D62"
              d="M565.61,291.04c-0.04-1.2,0.89-4.38,0.31-3.37c-0.58,1.01-1.11,4.3-1.11,3.72c0-0.5-0.26-2.04-0.31-2.53
				c-0.03,0.52-0.21,2.24-0.58,3.07c-0.36,0.81-0.8,1.83-1.45,2.56c0.07-0.06,0.15-0.13,0.23-0.21c0.74-0.67,1.83-1.6,2.19-1.65
				c0.58-0.08,0.44,0.78,0.44,0.89c0,0.04,0.04-0.12,0.09-0.39C565.52,292.65,565.63,291.81,565.61,291.04z"
            />
            <path
              fill="#AD7D62"
              d="M562.48,294.49c-0.3,0.27-0.5,0.47-0.5,0.47C562.15,294.82,562.32,294.66,562.48,294.49z"
            />
            <path fill="#AD7D62" d="M564.5,288.71c-0.01,0.02,0,0.07,0,0.14C564.51,288.75,564.51,288.69,564.5,288.71z" />
            <path
              fill="#AD7D62"
              d="M560.19,304.37c0,0-1.44,0.36-1.58,0.86c-0.13,0.5,0.04,0.85,0.71,1.12s0.97,0.12,0.97,0.12
				C559.63,305.66,560.19,304.37,560.19,304.37z"
            />
            <path
              fill="#AD7D62"
              d="M572.91,302.67c0.62-1.12,2.52-2.48,2.66-3.06s2.17-2.17,0.4-1.09c-1.77,1.09-3.45,4.07-3.9,4.93
				c-0.44,0.85-0.89,1.51-1.42,1.32c-0.53-0.19-1.51-0.62-1.33-1.16s1.15-1.9,1.99-2.99c0.84-1.09,1.55-2.75,1.46-2.64
				c-0.09,0.12-1.28,1.67-2.04,2.6c-0.75,0.93-1.59,2.56-2.3,3.03c-0.71,0.47-2.57,1.24-2.17,0.97c0.4-0.27,1.46-0.89,1.06-1.32
				s-1.82-0.27-1.02-1.01c0.8-0.74,1.77-1.82,2.13-2.48c0.82-1.52,2.26-5.86,2.26-5.86s-2.3,5.08-2.52,5.62
				c-0.22,0.54-0.62,0.93-1.59,1.9c-0.51,0.51-0.8,0.84-1.09,1.18c-0.26,0.31-0.51,0.65-0.91,1.14c-0.84,1.05-2.26,2.44-2.26,2.44
				l1.11-0.27c0,0,0.13,0.16,0.66,0.31c0.53,0.16,0.97,0.27,1.9,0.08c0.93-0.19,1.64-0.47,2.35-0.31c0.71,0.16,1.2,0.31,1.59,0.12
				c0.4-0.19,0.8-0.62,1.46-0.78c0.66-0.16,1.59-0.89,1.59-0.89s-0.03-0.01-0.08-0.02c-0.11-0.03-0.32-0.09-0.54-0.17
				C572.07,304.15,572.29,303.8,572.91,302.67z"
            />
            <path
              fill="#E8E7D9"
              d="M493.48,229.98c-0.5,0.29-0.87,0.58-1.12,0.8c-0.25,0.22-0.25,0.6,0,0.82c0.32,0.29,0.75,0.67,0.97,0.85
				c0.39,0.32,1.27-0.71,1.74-1.27c0,0,0.01-0.01,0.01-0.01c0.32-0.37-0.02-1.06-0.6-1.29
				C494.19,229.76,493.84,229.76,493.48,229.98z"
            />
            <path
              fill="#E8E7D9"
              d="M500.16,234.3c-1.19,0.39-1.45,0.7-1.44,1.13c0.01,0.43,0.45,0.86,0.76,1.26
				c0.26,0.33,1.05-0.18,1.65-0.62c0.12-0.09,0.24-0.18,0.34-0.25c0.45-0.35,0.13-1.31-0.6-1.53
				C500.67,234.21,500.43,234.21,500.16,234.3z"
            />
            <path
              fill="#E8E7D9"
              d="M502.04,237.21c-1.13,0.54-1.35,0.87-1.29,1.29c0.07,0.43,0.55,0.8,0.91,1.16
				c0.21,0.21,0.63-0.03,1.05-0.37c0.3-0.24,0.6-0.53,0.82-0.74c0.31-0.3,0.14-0.89-0.28-1.22
				C502.94,237.1,502.51,236.99,502.04,237.21z"
            />
            <path
              fill="#E8E7D9"
              d="M500.74,240.96c-0.78,0.59-0.89,0.87-0.78,1.19c0.12,0.31,0.54,0.53,0.87,0.74
				c0.24,0.15,0.61-0.26,0.92-0.68c0.12-0.16,0.23-0.33,0.32-0.46c0.19-0.29-0.06-0.73-0.46-0.91
				C501.36,240.73,501.05,240.73,500.74,240.96z"
            />
            <g>
              <path
                fill="none"
                d="M551.82,167.22c-0.13-0.27-0.23-0.5-0.29-0.65C551.62,166.79,551.72,167.01,551.82,167.22z"
              />
              <polygon fill="none" points="553.03,169.12 554.64,167.93 553.02,169.11 				" />
              <path
                fill="#E8E7D9"
                d="M516.48,134.35c0.34-1.91,2.29-5.66,3.39-6.87c0.12-0.13,0.26-0.31,0.43-0.52
					c0.08-0.1,0.17-0.2,0.28-0.32c0.08-0.88,0.62-5.06,3.98-5.41c1.48-0.15,3.25,1.34,3.76,1.8c0.88,0.46,1.68,1.41,2.31,3.06
					c2.91,7.63-4.31,3.33,1.08,12.89c2.18,3.86,2.14,6.98,2.33,7.17s0.82-8.18,6.61-5.89c1.7,0.67,2.11,2.22,2.31,3.37
					c0.32,1.84-0.53,4.07-2.26,7.33c-2.67,5.05,10.32,14.03,10.32,14.03s6.3-3.77,6.08-18.42c0,0,6.85-7.48,0.12-20.83
					c-5.28-10.48-33.41-15.39-37.04-2.68c-5.47,2.3-7.19,10.48-5.94,21.25c0.92-1.27,1.81-2.48,2.16-3.05
					C517.07,140.21,516.08,136.61,516.48,134.35z"
              />
              <path
                fill="#FFFFFF"
                d="M542.77,213.67c3.54-31.04,13.68-42.41,13.68-42.41l-1.8-3.33l-1.61,1.19l-16.89,12.48l-0.74,10.22
					l-3.16-10.86l-3.1-4.38c0,0,0,0,0,0l-1.96-2.77c0,0-0.93,0.99-1.9,2.55c0,0,0,0,0,0l0.02-0.01c0,0,2.66,4.88,6.09,17.66
					c0.01,0.01,0.02,0.01,0.03,0.02c3.28,12.31,6.49,30.52,5.58,52.97c0.24,4.62,0.58,9.06,0.89,13.24l9.95,0.19
					C544.23,246.7,540.79,231.05,542.77,213.67z"
              />
              <path
                fill="#42637C"
                d="M531.43,194.04c-0.01-0.01-0.02-0.01-0.03-0.02c-3.43-12.78-6.09-17.66-6.09-17.66l-0.02,0.01
					c0,0,0,0,0,0c-5.44,2.18-10.07,3.94-13.33,6.68l-0.01-0.05c-5.76,4.08-6.85,9.31-6.58,13.05c0.81,11.08,2.67,23.74,1.73,25.98
					c-0.07,0.17-0.2,0.36-0.38,0.56l14.91,35.63c0.37,1-0.09,1.68-1,1.47l-5.78-0.68c-1.36,12.93-0.4,23.35-0.4,23.35
					s4.88,3.95,22.83,4.7c2.21-5.26,1.52-14.94,0.63-26.81c-0.31-4.18-0.65-8.62-0.89-13.24
					C537.92,224.56,534.71,206.35,531.43,194.04z"
              />
              <path
                fill="#E8E7D9"
                d="M529.14,176.58c0.95-1.95,1.07-3.94,1.08-4.31c0.1-0.59,0.2-1.22,0.29-1.91
					c-0.03-0.35-0.07-0.7-0.11-1.07c0,0-0.59-2.94-3.85-3.57c-3.03-0.58-7.13-1.32-7.8-1.44c-0.1,0-0.19,0-0.28,0
					c1.51,8.91-0.89,10.57-0.89,10.57s4.28,0.02,9.62-1.13c-0.01,0.06-0.01,0.09-0.01,0.09L529.14,176.58
					C529.14,176.59,529.14,176.59,529.14,176.58z"
              />
              <path
                fill="#D7BFB1"
                d="M519.1,140.24C519.1,140.24,519.1,140.24,519.1,140.24c0.03,0.01,0.05,0.01,0.08,0.02
					C519.15,140.25,519.12,140.24,519.1,140.24z"
              />
              <path
                fill="#D7BFB1"
                d="M518.65,164.27c2-0.42,5.89-0.66,10.07-2.06s5.39-2.06,6.48-4.42c0,0,0.12,4.36-1.03,6.42
					c-1.15,2.06-3.83,2.9-3.77,5.08c0.04,0.37,0.08,0.72,0.11,1.07c-0.09,0.68-0.18,1.32-0.29,1.91c-0.01,0.37-0.13,2.35-1.08,4.31
					l3.1,4.38l3.16,10.86l0.74-10.22l16.89-12.48c0,0-0.01-0.01-0.01-0.01l-0.05,0.03c-0.41-0.41-0.85-1.27-1.15-1.92
					c-0.1-0.21-0.2-0.42-0.29-0.65c-0.04-0.1-0.07-0.16-0.07-0.16c0,0,0,0,0,0c-0.17-0.43-0.32-0.89-0.45-1.39
					c0,0-12.99-8.99-10.32-14.03c1.72-3.26,2.58-5.48,2.26-7.33c-0.2-1.15-0.61-2.7-2.31-3.37c-5.79-2.3-6.42,6.08-6.61,5.89
					s-0.15-3.31-2.33-7.17c-5.39-9.56,1.83-5.26-1.08-12.89c-0.63-1.66-1.43-2.61-2.31-3.06c0.09,0.08,0.14,0.13,0.14,0.13
					s-3.67-0.29-7.89,3.64c0,0,0-0.06,0.01-0.16c-0.1,0.11-0.2,0.22-0.28,0.32c-0.17,0.21-0.31,0.39-0.43,0.52
					c-1.1,1.2-3.05,4.95-3.39,6.87c-0.4,2.25,0.59,5.86-0.08,6.93c-0.35,0.57-1.24,1.79-2.16,3.05c-0.82,1.12-1.66,2.28-2.19,3.06
					c-1.16,1.72-0.75,2.65,0.51,2.99c1.23,0.33,2.89,1.03,1.91,2.69c-0.17,0.29-0.27,0.56-0.3,0.81c0.22,0.13,1.05,0.43,4.04,0.48
					c0.09,0,0.15-0.02,0.18-0.04c0.03-0.03,0.03-0.02,0,0c0,0-0.01,0-0.01,0.01c-0.01,0.3-0.45,2.39-2.95,3.5
					c0.27,0.56,0.72,1.13,0.31,2.08c-0.59,1.37,0.1,4.49,1.95,4.4c0.18-0.01,0.44-0.02,0.77-0.02c0.09,0,0.18,0,0.28,0
					C518.69,164.28,518.65,164.27,518.65,164.27z M536.5,152.52c0,0,2.91-0.97,4.72-4.91C541.22,147.61,541.15,151.09,536.5,152.52z
					 M536.68,142.46c1.03-1.51,3.45-1.64,4.42-0.06c0.97,1.57,0.55,4.24,0.55,4.24s-0.12-4.06-1.76-4.3
					c-1.64-0.24-1.94,1.09-0.79,2.24c1.15,1.15,0.73,2.91,0.06,2.79c-0.78-0.14-0.24-1.03-1.33-1.27
					C536.74,145.86,535.65,143.98,536.68,142.46z M535.71,148.64c0.18-1.76,0.06-3.15,0.06-3.15s0.61,1.39,1.45,2.18
					c0.85,0.79,0.36,1.64-0.42,2.24C536.02,150.52,535.53,150.4,535.71,148.64z M512.58,149.89c0.74-0.06,1.29,0.15,1.55,0.45
					c0.25,0.3-0.42-0.04-0.98-0.04S512.32,149.92,512.58,149.89z M513.9,150.85c0.54,0.06,1.37-0.06,1.31-1.06c0-0.01,0-0.02,0-0.02
					c0-0.06-0.01-0.13-0.02-0.2c0,0,0.01,0.08,0.02,0.2c0,0.01,0,0.02,0,0.02C515.25,150.31,515.22,151.52,513.9,150.85z
					 M522.63,141.31c-0.34-0.09-0.5-0.33-0.5-0.33c0.33,0.15,0.69,0.34,1.06,0.27S523.15,141.45,522.63,141.31z M522.91,141.02
					c-0.55,0.03-0.89-0.24-0.89-0.24c0.57,0.06,1.18,0.16,1.69-0.12S523.74,140.97,522.91,141.02z M518.31,135.79
					c1.55-0.31,2.79,1.24,3.72,2.32c0.93,1.08,0.93,1.08,0.93,1.08s-2.56-2.32-3.8-2.4c-1.24-0.08-2.17,1.08-2.17,1.08
					C516.69,137.5,516.76,136.1,518.31,135.79z M521.78,140.36c-1.97-1.78-3.07-1.62-2.98-1.64
					C520.35,138.36,521.78,140.36,521.78,140.36z M518.54,140.11c0,0-0.69-0.23-1.38-0.63s3.61,0.86,4.59,1.26
					c0,0-1.95,0.43-2.97,1.07c0,0,0.1-0.07,0.26-0.17c-0.16,0.08-0.31,0.17-0.46,0.27c0,0,0,0,0,0c-0.01,0-0.02,0-0.02,0
					s-0.01-0.03-0.02-0.08c-0.02-0.06-0.04-0.15-0.06-0.26C518.4,141.22,518.34,140.64,518.54,140.11z M518.44,154.32
					c0.64-0.31,0.73-0.62,0.72-1.06c-0.01-0.34-0.17-1.13-0.17-1.13s0.29,0.42,0.82,2.84c0.39,1.83,0-0.25-0.6-0.55
					C518.99,154.31,518.44,154.32,518.44,154.32z"
              />
              <path
                fill="#D7BFB1"
                d="M521.62,140.72c-0.06-0.01-0.14-0.02-0.23-0.04c-0.02,0.01-0.03,0.02-0.05,0.04
					C521.44,140.7,521.53,140.7,521.62,140.72z"
              />
              <path
                fill="#E8E7D9"
                d="M519.16,136.8c1.24,0.08,3.8,2.4,3.8,2.4s0,0-0.93-1.08c-0.93-1.08-2.17-2.63-3.72-2.32
					s-1.63,1.7-1.32,2.09C517,137.88,517.92,136.72,519.16,136.8z"
              />
              <path
                fill="#AD7D62"
                d="M537.83,146.1c1.09,0.24,0.56,1.13,1.33,1.27c0.67,0.12,1.09-1.64-0.06-2.79
					c-1.15-1.15-0.85-2.48,0.79-2.24c1.64,0.24,1.76,4.3,1.76,4.3s0.42-2.66-0.55-4.24c-0.97-1.57-3.39-1.45-4.42,0.06
					C535.65,143.98,536.74,145.86,537.83,146.1z"
              />
              <path
                fill="#AD7D62"
                d="M536.8,149.91c0.79-0.61,1.27-1.45,0.42-2.24c-0.85-0.79-1.45-2.18-1.45-2.18s0.12,1.39-0.06,3.15
					S536.02,150.52,536.8,149.91z"
              />
              <path
                fill="#AD7D62"
                d="M536.5,152.52c4.65-1.43,4.72-4.91,4.72-4.91C539.41,151.55,536.5,152.52,536.5,152.52z"
              />
              <path
                fill="#AD7D62"
                d="M534.17,164.21c1.15-2.06,1.03-6.42,1.03-6.42c-1.09,2.36-2.3,3.03-6.48,4.42s-8.07,1.63-10.07,2.06
					c0,0,0.03,0.01,0.1,0.02c0.67,0.12,4.77,0.86,7.8,1.44c3.26,0.63,3.85,3.57,3.85,3.57
					C530.34,167.12,533.02,166.27,534.17,164.21z"
              />
              <path
                fill="#AD7D62"
                d="M514.21,153.7c0.01-0.03,0.01-0.06,0.02-0.08C514.23,153.62,514.22,153.66,514.21,153.7z"
              />
              <path
                fill="#AD7D62"
                d="M518.38,154.33C518.38,154.33,518.38,154.33,518.38,154.33c-0.02,0.01-0.08,0.04-0.17,0.03
					c-2.99-0.05-3.83-0.35-4.04-0.48c-0.01-0.01,0.02-0.11,0.04-0.18c-0.2,0.67-0.04,1.22,0.44,1.48c0.66,0.37,2.02,0.17,2.02,0.17
					c0.07-0.01,0.29-0.01,0.02,0.2c0,0-0.93,0.25-1.31,0.9c-0.38,0.65,0.04,1.39,0.04,1.39c0.01,0,0.01-0.01,0.02-0.01
					C517.93,156.72,518.37,154.63,518.38,154.33z"
              />
              <path fill="#AD7D62" d="M518.39,154.32C518.41,154.31,518.41,154.3,518.39,154.32L518.39,154.32z" />
              <path
                fill="#AD7D62"
                d="M519.81,154.97c-0.52-2.42-0.82-2.84-0.82-2.84s0.16,0.79,0.17,1.13c0.01,0.44-0.08,0.74-0.72,1.06
					c0,0,0.55-0.01,0.76,0.1C519.81,154.71,520.2,156.79,519.81,154.97z"
              />
              <path
                fill="#AD7D62"
                d="M514.13,150.34c-0.25-0.3-0.81-0.51-1.55-0.45c-0.26,0.02,0.02,0.4,0.57,0.4
					S514.38,150.64,514.13,150.34z"
              />
              <path
                fill="#FFFFFF"
                d="M521.34,140.71c0.02-0.01,0.03-0.02,0.05-0.04c-0.52-0.09-1.51-0.28-2.22-0.42
					c-0.03-0.01-0.05-0.01-0.08-0.02c0.05,0.29,0.13,0.91-0.01,1.38C519.58,141.31,520.57,140.76,521.34,140.71z"
              />
              <path
                fill="#FFFFFF"
                d="M518.57,141.91C518.57,141.91,518.58,141.91,518.57,141.91l-0.02,0
					C518.56,141.91,518.56,141.91,518.57,141.91z"
              />
              <path
                fill="#AD7D62"
                d="M518.53,141.83c0.01,0.05,0.02,0.08,0.02,0.08l0.02,0c0.15-0.1,0.3-0.18,0.46-0.27
					c-0.16,0.1-0.26,0.17-0.26,0.17c1.02-0.65,2.97-1.07,2.97-1.07c-0.97-0.4-5.27-1.66-4.59-1.26s1.38,0.63,1.38,0.63
					c-0.2,0.54-0.14,1.11-0.07,1.46C518.49,141.68,518.52,141.77,518.53,141.83z M519.1,140.24
					C519.1,140.24,519.1,140.24,519.1,140.24c0.03,0,0.05,0.01,0.08,0.02c0.7,0.15,1.69,0.33,2.22,0.42
					c0.09,0.02,0.17,0.03,0.23,0.04c-0.09-0.01-0.18-0.01-0.28-0.01c-0.77,0.05-1.76,0.6-2.25,0.9
					C519.22,141.15,519.15,140.53,519.1,140.24z"
              />
              <path
                fill="#AD7D62"
                d="M518.8,138.72c-0.09,0.02,1.01-0.14,2.98,1.64C521.78,140.36,520.35,138.36,518.8,138.72z"
              />
              <path
                fill="#AD7D62"
                d="M524.56,121.24c-3.36,0.35-3.9,4.53-3.98,5.41c-0.01,0.1-0.01,0.16-0.01,0.16
					c4.22-3.93,7.89-3.64,7.89-3.64s-0.05-0.05-0.14-0.13C527.81,122.58,526.04,121.09,524.56,121.24z"
              />
              <path
                fill="#AD7D62"
                d="M515.21,149.77c-0.01-0.12-0.02-0.2-0.02-0.2C515.19,149.64,515.2,149.71,515.21,149.77z"
              />
              <path
                fill="none"
                stroke="#AA7D62"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M515.21,149.77
					c0,0.01,0,0.02,0,0.02"
              />
              <path
                fill="#AD7D62"
                d="M513.9,150.85c1.32,0.68,1.35-0.54,1.31-1.06C515.27,150.78,514.44,150.91,513.9,150.85z"
              />
              <path
                fill="#E8E7D9"
                d="M551.53,166.57c0.06,0.15,0.16,0.38,0.29,0.65c0.3,0.65,0.75,1.52,1.15,1.92l0.05-0.03l1.62-1.18
					c0,0-0.23-0.88-3.17-1.52c0,0,0,0,0,0C551.46,166.41,551.49,166.47,551.53,166.57z"
              />
              <path
                fill="#42637C"
                d="M595.79,216.47c-4.77-13.98-11.93-26.24-16.16-33.64c-2.73-5.92-5.38-7.18-9.79-9.26
					c-6-2.82-13.39-2.31-13.39-2.31s-10.14,11.37-13.68,42.41c-1.98,17.38,1.46,33.03,5.09,46.77c2.85,10.8,5.81,20.42,6.34,28.77
					c2.58-0.07,5.03-0.21,7.34-0.38c0.18-0.28,0.39-0.55,0.68-0.81c1.12-0.98,3.45-2.21,5.13-3.35c1.68-1.14,3.26-2.04,4.29-3.27
					c1.03-1.23,1.59-1.31,1.77-2.29c0.19-0.98,1.49-4.74,1.49-4.74s2.97-0.63,5.36,0.84c2.39,1.47,3.06,3.88,3.06,3.88
					s-2.44,4.93-2.48,6.48c0,0.09-0.01,0.17-0.02,0.26c6.04-1.5,9.13-2.92,9.13-2.92s-0.64-4.4-1.51-10.22
					c1.29-3.19,2.9-7.25,4.88-12.39C601,240.38,600.68,230.78,595.79,216.47z"
              />
              <path
                fill="#AD7D62"
                d="M522.02,140.77c0,0,0.34,0.27,0.89,0.24c0.83-0.05,1.3-0.65,0.8-0.36S522.59,140.84,522.02,140.77z"
              />
              <path
                fill="#AD7D62"
                d="M523.19,141.25c-0.37,0.07-0.72-0.11-1.06-0.27c0,0,0.15,0.24,0.5,0.33
					C523.15,141.45,523.56,141.18,523.19,141.25z"
              />
            </g>
          </g>
          <g id="guy">
            <path
              fill="none"
              d="M655.2,345.5c-0.06,0.17-0.18,0.4-0.34,0.63c0.12-0.03,0.2-0.08,0.26-0.15c0.04-0.09,0.07-0.17,0.1-0.24
				c0.02-0.12,0.03-0.25,0.05-0.38C655.25,345.39,655.22,345.44,655.2,345.5z"
            />
            <path
              fill="#42637C"
              d="M651.8,347.05c-0.18-0.08-0.32-0.17-0.44-0.25c-0.18-0.06-0.37-0.04-0.66,0.19
				c-0.62,0.51-2.38,1.71-3.1,1.38c-0.44-0.19-1.33-0.72-1.56-1.21c-0.22-0.25-0.31-0.7-0.36-1.12c-0.11-0.39-0.18-0.76-0.13-0.85
				c0.05-0.09,0.11-0.19,0.17-0.29c-0.22,0.3-0.44,0.56-0.64,0.76c-0.73,0.7-1.15,1.8-2.45,1.02c-0.31-0.18-0.52-0.4-0.67-0.64
				c-0.36-0.42-0.48-1.2-0.07-2.17c0.26-0.61,0.57-1.43,0.77-1.98c-0.08,0.04-0.21,0.06-0.32-0.09c-0.4-0.29-0.78-1.65-1.46-2.93
				c-0.8-1.5-1.46-3.04-0.59-4.76c0.87-1.72,1.34-3.83,3.28-5.45c1.94-1.62,3.84-3.39,5.35-4.56c0.37-0.28,0.67-0.64,0.92-1.02
				c-0.81-0.16-1.35-0.27-1.5-0.29c-0.01,0-0.01,0.01-0.02,0.01c-0.04-0.01-0.03-0.02,0.02-0.01c0.35-0.16,0.78-7.94,1.12-18.07
				c-15.2,2.66-37.15,3.99-46.09,4.44c0.07,3.95,0.19,8.9,0.39,14.38c-0.92,7.11-0.27,37.87,1.58,69.85
				c2.13,36.75,9.59,91.08,9.59,91.08h24.5v-0.28l11.72,0.28c0,0-0.45-41.69-2.66-63.39c-3.94-38.65,0.81-58.06,3.62-73.91
				c-0.01,0-0.01,0-0.02-0.01C651.99,347.15,651.89,347.1,651.8,347.05z"
            />
            <path
              fill="#42637C"
              d="M647.23,343.5c0,0,0.88-3.02,1.25-4.34c0.19-0.69,0.15-1.82,0.1-2.58c0,0.89-0.29,1.69-0.29,1.69
				s-0.17,0.9-0.39,1.9c-0.23,1.03-0.52,2.17-0.74,2.53c-0.09,0.15-0.21,0.34-0.34,0.56c-0.07,0.15-0.15,0.29-0.23,0.41
				L647.23,343.5z"
            />
            <path
              fill="#AD7D62"
              d="M505.68,199.33l0.56,6.06l-0.63-7.73c-0.27-0.2-0.57-0.4-0.89-0.58c0,0-4.79-3.2-6.47-4.58
				c-1.67-1.38-3.4-2.91-5.21-4.29c-1.82-1.38-3.58-3.41-5.25-4.5c-1.67-1.09-5.16-4.07-6.18-5.16c-1.02-1.09-1.96,1.96,0.44,4.21
				c0.93,0.88,2.07,2.27,3.21,3.78c-0.04,0-0.08,0-0.12,0h0.12l3.14,4.46c-1.9-2.61-3.75-4.46-3.75-4.46h0.09
				c-0.07,0-0.14-0.01-0.22-0.01c-0.72-0.06-1.01,1.43-1.67,3.1c-0.66,1.67-0.95,2.86-1.19,3.46c-0.24,0.6,0.18,1.67,0.42,2.8
				c0.24,1.13,0.24,1.79,1.07,1.97c0.38-0.15,1.31-0.3,1.5-1.16c0.19-0.86-0.04-3.16-0.04-3.16l-1.12-0.63c0,0,1.15,0.26,1.53-0.22
				c0.37-0.48,0.71-1.45,0.71-1.45l-0.89-2.31c0,0,0.97,1.82,1.9,2.12c0.93,0.3,2.01,0.45,2.01,0.45s-1.79-0.04-2.49-0.19
				l-1.12,2.08c0,0,0,1.79,0.04,2.53s-0.1,2.13-2.02,1.94c0,0,1.55,2.92,1.91,3.7c0.36,0.78,0.78,1.01,1.43,0.72
				c0.66-0.3,0.83-1.67,0.54-2.74c0,0,1.13,1.07,1.73,1.61c0.6,0.54,1.31-0.54,1.07-1.25c-0.17-0.5-0.39-1.03-0.51-1.3l-0.05,0.09
				c-0.19-0.45-0.82-0.82-1.27-1.08c-0.45-0.26,1,0.52,1.27,0.22c0.26-0.3-0.3-1.12-0.41-1.56c-0.11-0.45,0.74,1.1,1.15,1.13
				c0,0,0.24-0.02,0.71-0.07c-0.58-2.39-1.72-5.47-1.72-5.47c2.03,2.62,2.09,3.53,4.17,5.68c2.09,2.15,2.56,2.19,0.12,3.2
				c-2.44,1.01-3.52,2.33-3.52,2.33s0.2-0.25,0.45-0.69c-0.29,0.39-0.59,0.67-0.82,0.81c-0.58,0.36-3.47,1.49-5.29,2.43
				c-1.82,0.94-1.82,2.11-1.23,2.98c0.38,0.56,0.75,0.52,1.41,0.28c-0.23,0.07-0.36,0.11-0.36,0.11c0.93-0.33,1.08-0.44,1.59-0.72
				c0.51-0.28,0.4-1.28-0.11-1.79c0,0,0.63,0.53,1.06,1.67c1.09-0.25,1.84-0.22,3.38-0.46c1.89-0.29,4.43-0.58,7.05-0.15
				c1.1,0.18,2.41,0.05,3.67-0.14C503.23,206.67,505.88,200.63,505.68,199.33z M489.23,194.92c0.38-0.08,0.66-0.15,0.66-0.15
				S489.61,194.85,489.23,194.92c-0.53,0.12-1.25,0.27-1.68,0.33c-0.74,0.11-0.45,0.89-0.89,1.82c-0.45,0.93,0.41,2.12,0.37,3.13
				c0,0-0.19-1.41-0.82-2.2c-0.63-0.78,0.63-1.67,0.6-2.64c-0.03-0.84-0.37-1.37-0.46-1.5c-0.01-0.02-0.02-0.03-0.02-0.03
				s0.01,0.01,0.02,0.03c0.11,0.15,0.69,0.89,1.36,1.09C488.11,195.08,488.75,195.01,489.23,194.92z"
            />
            <path
              fill="#AD7D62"
              d="M506.11,207.4c-0.24-0.05-0.59-0.13-1.02-0.2C505.45,207.3,505.8,207.36,506.11,207.4z"
            />
            <path
              fill="#AD7D62"
              d="M502.16,207.16c-0.45,0.06-0.89,0.13-1.21,0.18C501.36,207.28,501.77,207.21,502.16,207.16z"
            />
            <path fill="#AD7D62" d="M490,197.39l-0.26,0.45C489.86,197.69,489.96,197.53,490,197.39z" />
            <path
              fill="#4D2C1A"
              d="M493.28,200.73c2.44-1.01,1.97-1.06-0.12-3.2c-2.09-2.15-2.15-3.06-4.17-5.68c0,0,1.14,3.08,1.72,5.47
				c0.15,0.6,0.26,1.17,0.31,1.62c0.15,1.43-0.39,2.69-0.8,3.43c-0.24,0.43-0.45,0.69-0.45,0.69S490.84,201.74,493.28,200.73z"
            />
            <path
              fill="#4D2C1A"
              d="M485.17,195.93c-0.04-0.74-0.04-2.53-0.04-2.53l1.12-2.08c0.71,0.15,2.49,0.19,2.49,0.19
				s-1.08-0.15-2.01-0.45c-0.93-0.3-1.9-2.12-1.9-2.12l0.89,2.31c0,0-0.33,0.97-0.71,1.45c-0.37,0.48-1.53,0.22-1.53,0.22l1.12,0.63
				c0,0,0.22,2.31,0.04,3.16c-0.19,0.86-1.12,1-1.5,1.16C485.07,198.05,485.2,196.67,485.17,195.93z"
            />
            <path fill="#4D2C1A" d="M489.23,194.92c0.38-0.07,0.66-0.15,0.66-0.15S489.61,194.84,489.23,194.92z" />
            <path fill="#4D2C1A" d="M486.34,193.87c-0.01-0.02-0.02-0.03-0.02-0.03S486.33,193.85,486.34,193.87z" />
            <path
              fill="#4D2C1A"
              d="M487.7,194.96c-0.67-0.2-1.24-0.94-1.36-1.09c0.09,0.12,0.43,0.66,0.46,1.5c0.04,0.97-1.23,1.86-0.6,2.64
				c0.63,0.78,0.82,2.2,0.82,2.2c0.04-1-0.82-2.2-0.37-3.13c0.45-0.93,0.15-1.71,0.89-1.82c0.43-0.06,1.15-0.22,1.68-0.33
				C488.75,195.01,488.11,195.08,487.7,194.96z"
            />
            <path
              fill="#4D2C1A"
              d="M488.85,196.26c0.11,0.45,0.67,1.27,0.41,1.56c-0.26,0.3-1.71-0.48-1.27-0.22
				c0.45,0.26,1.08,0.63,1.27,1.08l0.05-0.09l0.43-0.75l0.26-0.45C489.59,197.36,488.74,195.81,488.85,196.26z"
            />
            <path
              fill="#4D2C1A"
              d="M485.39,206.48c0.51,0.5,0.62,1.51,0.11,1.79c-0.51,0.28-0.66,0.39-1.59,0.72c0,0,0.13-0.04,0.36-0.11
				c0.28-0.09,0.69-0.22,1.22-0.41c0.8-0.28,0.96-0.31,0.96-0.31c0,0,0-0.01,0-0.01C486.02,207,485.39,206.48,485.39,206.48z"
            />
            <path
              fill="#4D2C1A"
              d="M505.68,199.33c0.2,1.31-2.45,7.34-5.13,8.07c-0.05,0.01-0.1,0.03-0.15,0.04c0,0,0.22-0.04,0.55-0.1
				c0.32-0.06,0.75-0.12,1.21-0.18c0.74-0.1,1.53-0.17,1.99-0.11c0.34,0.04,0.65,0.09,0.94,0.15c0.42,0.07,0.78,0.15,1.02,0.2
				c0.15,0.03,0.26,0.06,0.3,0.07l-0.17-2.08L505.68,199.33z"
            />
            <path
              fill="#4D2C1A"
              d="M488.4,191.01l-3.14-4.46h-0.12h-0.4h-0.09C484.65,186.55,486.5,188.4,488.4,191.01z"
            />
            <path fill="#D7BFB1" d="M597.05,190.76c0,0,0,0-2.48,0.89l2.82-0.92L597.05,190.76z" />
            <path
              fill="#D7BFB1"
              d="M560.93,238.36c-0.51,0.1-0.99,0.19-1.43,0.26C559.98,238.55,560.45,238.46,560.93,238.36z"
            />
            <path
              fill="#1EBEE4"
              d="M633.07,189.22c0.03-0.03,0.05-0.04,0.05-0.04l-6.67-2.39c-1.72,1.2-3.61,3.67-9.64,8.74
				c-6.93,5.83-11.22,9.5-11.22,9.5s-6.98-13.23-7.21-14.62l-0.98,0.32l-2.82,0.92l-1.04,0.34c0.01,0.01,0.01,0.02,0.02,0.03
				c5.03,8.22,11.25,21.79,11.25,21.79C616.4,203.2,632.09,190.04,633.07,189.22z"
            />
            <path
              fill="#AD7D62"
              d="M584.89,171.83c0.44-1.74-0.08-1.62-2.11-1.48c-0.38,0.03-0.63-0.01-0.78-0.09
				c-0.01,0.02-0.02,0.03-0.03,0.05c-0.36,0.6,0.85,1.53,1.75,1.15c0.01,0,0.02-0.01,0.03-0.01c0.33-0.21,0.69-0.5,0.81-0.85
				c0.12-0.35-0.1,0.86-0.06,1.25c0,0.01,0,0.01,0,0.02c-0.03-0.19-0.2-0.79-0.74-0.42c0.13-0.02-0.49,0.6-0.99,0.77
				c-0.48,0.16-0.48,0.57-0.35,1.09C583.4,172.96,584.63,172.89,584.89,171.83z M584.58,172.04
				C584.59,172.1,584.59,172.11,584.58,172.04L584.58,172.04z"
            />
            <path
              fill="#AD7D62"
              d="M590.04,170.9c2.91-0.04,4.69-1.78,6.74-4.61c2.05-2.83,2.85-6.59,2.85-6.59s0.41-1-0.27-2.96
				c-1.29-3.7-5.7-4.95-4.03-9.22c1.68-4.26,0.06-6.12-4.17-4.55c-1.65,0.61-4.75,1.03-4.75,1.03c-2.67,5.18-2.32,8.79-2.56,9.96
				c-0.24,1.18,0.78,1.59-0.12,3.57c-0.93,2.06-2.23,4.67-3.92,6.24c-1.5,1.4-0.07,3.16,2.29,3.55c0.71,0.12,1.02,0.36,1.09,0.67
				c0.37,0.05,1.27,0.19,2.43,0.57C587.17,169.08,587.14,170.94,590.04,170.9z M591.79,158.08c-0.46,0.19-0.8-0.27-0.8-0.27
				c0.48,0.13,1.05,0.02,1.6-0.17C592.59,157.64,592.26,157.89,591.79,158.08z M580.94,166.41c0.27-0.35,0.58-0.08,1.63,0.43
				C582.57,166.83,580.67,166.76,580.94,166.41z M582.41,167.41c0,0,1.43-0.04,1.55-0.39c0.12-0.35,0.08-1.38,0.08-1.38
				C584.15,166.41,585.43,168.22,582.41,167.41z M586.01,152.28c1.88-0.17,5.48,2.21,6.48,3.21c1,1-2.27-1.55-4.37-1.61
				c-2.1-0.06-3.1,0.89-3.1,0.89C584.51,154.16,584.13,152.45,586.01,152.28z M590.62,157.38c0,0-2.68-2.11-3.8-1.59
				c0,0,0.19-0.62,1.32-0.23C589.26,155.95,590.62,157.38,590.62,157.38z M590.5,157.76c0,0-0.39-0.08-0.96-0.23l0.66,0.24
				c-0.77,0.29-1.7,0.47-2.35,0.58c0,0,0,0,0,0l-0.12,0.02c-0.43,0.07-0.71,0.1-0.71,0.1c-0.25-0.72-0.17-1.76-0.16-1.92
				c-0.58-0.28-1.12-0.62-1.51-1.01C585.35,155.55,588.56,156.98,590.5,157.76z"
            />
            <path
              fill="#AD7D62"
              d="M619.69,173.77c-0.08-0.69-0.11-1.46-0.11-2.31C619.44,173.28,619.69,173.77,619.69,173.77z"
            />
            <path
              fill="#AD7D62"
              d="M616.81,195.53c6.03-5.07,7.92-7.55,9.64-8.74c-0.1-0.06-0.19-0.12-0.28-0.18
				c-0.72-0.5-2.72-2.08-4.35-5.35c-1.32-2.79-1.86-5.66-2.05-6.88c-0.03-0.2-0.06-0.4-0.08-0.61c-3.2-1.26-3.73-1.91-3.73-1.91
				c0.01,0.02,0.01,0.04,0.02,0.05c-1.36-1.06-2.57-2.53-3.02-4.31c-0.37-1.46-0.63-2.32-0.82-2.81c0.47-0.77,0.89-1.52,1.21-2.16
				c1.44-2.82,0.14-6.31-2.05-7.31c-1.94-0.89-5.32-0.64-6.82,1.74c-0.58,0.93-0.75,2.11-0.7,3.33l-0.01,0
				c0.09,2.12,0.76,5.17,0.79,8.34c0.09,8.21-3.31,12.52-8.05,14.42c0.82,0.63,1.45,1.43,1.87,2.42c1.18,2.75-0.12,4.22-0.02,4.83
				c0.23,1.4,7.21,14.62,7.21,14.62S609.88,201.36,616.81,195.53z M608.03,167.44c0,0,3.61-3.26,3.61-5.91
				C611.64,161.53,611.74,166.23,608.03,167.44z M606.1,157.87c0.39-0.55,1.16-1.44,2.93-1.22c1.77,0.22,2.32,1.66,2.82,2.66
				c0,0-1.66-2.16-3.27-1.83c-1.14,0.24-1.88,1.38-0.66,2.27c1.22,0.89,1.66,1.27,1.99,1.99c0.33,0.72-0.33,1.44-0.33,1.44
				s-0.22-0.55-0.55-1.22c-0.33-0.66-1.38,0.33-2.6-0.44C605.21,160.75,605.71,158.42,606.1,157.87z M605.55,162.08
				c0.89,0.83,1.94,0.5,2.49,0.89c0.55,0.39-0.66,2.05-1.33,2.71c-0.66,0.66-1.44,0.11-0.77-1c0.66-1.11,0.11-1.33-0.33-1.88
				C605.16,162.24,605.55,162.08,605.55,162.08z"
            />
            <path
              fill="#141616"
              d="M606.43,161.52c1.22,0.77,2.27-0.22,2.6,0.44c0.33,0.66,0.55,1.22,0.55,1.22s0.66-0.72,0.33-1.44
				c-0.33-0.72-0.77-1.11-1.99-1.99c-1.22-0.89-0.48-2.03,0.66-2.27c1.61-0.33,3.27,1.83,3.27,1.83c-0.5-1-1.05-2.44-2.82-2.66
				c-1.77-0.22-2.55,0.66-2.93,1.22S605.21,160.75,606.43,161.52z"
            />
            <path
              fill="#141616"
              d="M605.93,164.68c-0.66,1.11,0.11,1.66,0.77,1c0.66-0.66,1.88-2.32,1.33-2.71
				c-0.55-0.39-1.61-0.06-2.49-0.89c0,0-0.39,0.17,0.06,0.72C606.05,163.35,606.6,163.57,605.93,164.68z"
            />
            <path
              fill="#141616"
              d="M608.03,167.44c3.71-1.21,3.61-5.91,3.61-5.91C611.64,164.18,608.03,167.44,608.03,167.44z"
            />
            <path
              fill="#141616"
              d="M592.48,155.49c-1-1-4.59-3.38-6.48-3.21c-1.88,0.17-1.49,1.88-1,2.49c0,0,1-0.94,3.1-0.89
				C590.21,153.94,593.48,156.49,592.48,155.49z"
            />
            <path
              fill="#141616"
              d="M586.82,155.79c1.12-0.52,3.8,1.59,3.8,1.59s-1.36-1.43-2.48-1.82
				C587.01,155.18,586.82,155.79,586.82,155.79z"
            />
            <path fill="#E8E7D9" d="M587.72,158.37l-0.71,0.1C587.01,158.47,587.3,158.44,587.72,158.37z" />
            <path
              fill="#E8E7D9"
              d="M587.84,158.35c0.65-0.11,1.57-0.29,2.35-0.58l-0.66-0.24l-1.51-0.56
				C588.04,157.59,587.85,158.32,587.84,158.35z"
            />
            <path
              fill="#141616"
              d="M580.94,166.41c-0.27,0.35,1.63,0.43,1.63,0.43C581.52,166.33,581.21,166.06,580.94,166.41z"
            />
            <path
              fill="#141616"
              d="M583.96,167.02c-0.12,0.35-1.55,0.39-1.55,0.39c3.02,0.81,1.74-1,1.62-1.77
				C584.04,165.63,584.08,166.67,583.96,167.02z"
            />
            <path
              fill="#141616"
              d="M604.57,168.73c-0.03-3.17-0.71-6.21-0.79-8.34l0.01,0c-0.05-1.22,0.12-2.4,0.7-3.33
				c1.49-2.38,4.88-2.63,6.82-1.74c2.19,1,3.49,4.48,2.05,7.31c-0.33,0.64-0.74,1.39-1.21,2.16c0.18,0.49,0.44,1.35,0.82,2.81
				c0.45,1.78,1.66,3.25,3.02,4.31c-0.01-0.02-0.01-0.04-0.02-0.05c0,0,0.53,0.65,3.73,1.91c0,0-0.25-0.48-0.11-2.31
				c0.05-0.69,0.16-1.57,0.36-2.7c0.94-5.35,3.45-7.16,4.76-13.27c1.76-8.24,1.57-24.56-22.66-23.34
				c-6.25,0.31-11.65,0.84-11.65,0.84c-5.18,0-6.33,1.82-6.33,1.82s-0.06-0.79-0.14,2.37c-0.02,0.64,2.5,6.83,2.5,6.83
				s3.1-0.42,4.75-1.03c4.23-1.57,5.84,0.28,4.17,4.55c-1.68,4.26,2.74,5.51,4.03,9.22c0.68,1.95,0.27,2.96,0.27,2.96
				s-0.79,3.76-2.85,6.59c-2.05,2.83-3.83,4.57-6.74,4.61c-2.91,0.04-2.87-1.82-4.42-2.32c-1.17-0.38-2.06-0.52-2.43-0.57
				c-0.12-0.02-0.19-0.02-0.19-0.02c-0.63,0.82-1.68,1.95-1.01,2.28c0.16,0.08,0.41,0.11,0.78,0.09c2.03-0.14,2.55-0.26,2.11,1.48
				c-0.27,1.06-1.5,1.13-2.49,1.48c-0.1,0.04-0.2,0.07-0.29,0.12c-0.49,0.23-0.88,0.57-0.98,1.23c-0.31,2.01-1.65,8.61,3.92,9.42
				c3.98,0.57,8.06,0.45,11.47-0.92C601.25,181.25,604.66,176.94,604.57,168.73z"
            />
            <path
              fill="#141616"
              d="M584.49,171.88c0-0.01,0-0.01,0-0.02c-0.04-0.39,0.18-1.6,0.06-1.25c-0.12,0.35-0.48,0.63-0.81,0.85
				c0,0,0,0,0,0c0,0,0,0,0,0C584.29,171.09,584.46,171.69,584.49,171.88z"
            />
            <path fill="#4D2C1A" d="M584.58,172.04C584.59,172.11,584.59,172.1,584.58,172.04L584.58,172.04z" />
            <path
              fill="#141616"
              d="M587.01,158.47l0.71-0.1l0.12-0.02c0,0,0,0,0,0c0.01-0.03,0.2-0.76,0.18-1.39l1.51,0.56
				c0.57,0.16,0.96,0.23,0.96,0.23c-1.94-0.77-5.15-2.21-5.15-2.21c0.39,0.39,0.93,0.72,1.51,1.01
				C586.84,156.71,586.76,157.75,587.01,158.47z"
            />
            <path
              fill="#AD7D62"
              d="M643.34,233.82c-0.32,3.52-1.01,7-2.09,10.36c-0.87,2.7-1.9,5.92-0.13,8.14
				c0.99,1.24,2.61,1.78,3.81,2.81c2.64,2.25,2.76,6.34,1.79,9.67c-0.97,3.33-2.81,6.4-3.41,9.82c-0.69,3.94,5.37,15.55,6.45,20.88
				c0.31-11.61,0.48-24.12,0.36-31.42C647.82,256.96,645.28,244.45,643.34,233.82z"
            />
            <path
              fill="#AD7D62"
              d="M555.37,238.93c-0.1,0.02-0.19,0.03-0.28,0.04c0,0,1.44,0.13,4.41-0.35c0.44-0.07,0.92-0.16,1.43-0.26
				c2.09-0.42,4.75-1.1,8-2.23c8.33-2.88,15.5-4.85,15.5-4.85s-0.8-2.76-0.8-6.94C583.63,220.22,561.28,237.93,555.37,238.93z"
            />
            <path
              fill="#AD7D62"
              d="M652.31,323.6c-0.95-0.2-1.79-0.37-2.46-0.5c-0.25,0.38-0.56,0.73-0.92,1.02
				c-1.52,1.17-3.42,2.94-5.35,4.56c-1.94,1.62-2.41,3.72-3.28,5.45c-0.87,1.72-0.21,3.26,0.59,4.76c0.68,1.28,1.06,2.64,1.46,2.93
				c-0.04-0.05-0.07-0.12-0.1-0.22c0,0,0.41-0.71,0.4-1.3c0-0.59-0.32-1.43-1.04-1.41c0,0,1.7-0.26,1.65,0.39
				c-0.04,0.51-0.16,1.35-0.34,2.07c0.58,0.73,1.44,1.68,2.34,1.68c0.91,0,1.74-0.2,2.33-2.04c0,0-0.29,1.33-0.76,2.28
				c0.13-0.22,0.25-0.41,0.34-0.56c0.22-0.36,0.51-1.5,0.74-2.53c0.22-1,0.39-1.9,0.39-1.9s0.29-0.8,0.29-1.69
				c0.06,0.76,0.1,1.89-0.1,2.58c-0.37,1.32-1.25,4.34-1.25,4.34s-0.5,1.09-0.81,1.78c-0.31,0.68,1.95,0.53,3.17,0.85
				c1.22,0.32,1.9,0.32,2.01-0.35c0.11-0.66,1.36-5.29,1.62-6.22c0.26-0.93,0.43-1.76,0.43-1.76s0.21,0.46,0.17,0.73
				c-0.04,0.27,0.36-0.18,0.56-0.35c0.2-0.17-0.73,0.77-1.23,2.84c-0.49,2.07-0.95,4.02-1.15,4.6c-0.2,0.58,2.13,0.58,2.72,0.53
				c0.05,0,0.09-0.01,0.13-0.02c0.16-0.23,0.28-0.45,0.34-0.63c0.02-0.06,0.05-0.11,0.07-0.14c0.01-0.04,0.01-0.08,0.02-0.11
				c0.11-0.4,0.84-3.62,1.32-4.72s1.1-1.2,1.1-1.2s-0.62,0.4-1.2,2.24s-0.72,3.4-0.44,3.73c0.3-0.4,2.81-3.82,3.33-4.74
				c0.52-0.93,1.03-1.26,1.19-2.64s0.22-3.27,0.52-4.54c0.27-1.14,0.5-2.02,0.3-4.28C659.58,327.08,655.03,324.83,652.31,323.6z
				 M643.2,339.91c0,0,0.29-2.94,0.25-3.56c-0.03-0.38-0.29-0.51-0.49-0.56c-0.13,0-0.22-0.02-0.22-0.02s0.1-0.01,0.22,0.02
				c0.26,0.01,0.66-0.05,0.73-0.44c0.1-0.59,0.48-3.15,0.48-3.15S644.06,336.95,643.2,339.91z"
            />
            <path
              fill="#AD7D62"
              d="M645.7,346.05c-0.04-0.36-0.06-0.7-0.13-0.85C645.52,345.29,645.59,345.65,645.7,346.05z"
            />
            <path
              fill="#AD7D62"
              d="M647.61,348.37c-0.71-0.33-1.14-0.73-1.56-1.21C646.28,347.65,647.17,348.19,647.61,348.37z"
            />
            <path
              fill="#AD7D62"
              d="M643.87,346.78c-0.68,0.42-1.45-0.26-1.85-0.67c-0.02-0.02-0.03-0.04-0.05-0.07
				c0.15,0.24,0.37,0.45,0.67,0.64c1.3,0.78,1.72-0.32,2.45-1.02c0.21-0.2,0.43-0.46,0.64-0.76c-0.06,0.1-0.12,0.2-0.17,0.29
				l1.02-1.53C645.99,344.58,644.55,346.37,643.87,346.78z"
            />
            <path
              fill="#AD7D62"
              d="M651.36,346.81c0.12,0.07,0.26,0.16,0.44,0.25C651.64,346.96,651.51,346.85,651.36,346.81z"
            />
            <path
              fill="#4D2C1A"
              d="M642.95,335.79c0.2,0.04,0.47,0.17,0.49,0.56c0.04,0.62-0.25,3.56-0.25,3.56
				c0.86-2.96,0.96-7.71,0.96-7.71s-0.38,2.56-0.48,3.15C643.62,335.74,643.21,335.79,642.95,335.79z"
            />
            <path
              fill="#4D2C1A"
              d="M642.74,335.77c0,0,0.09,0.02,0.22,0.02C642.83,335.76,642.74,335.77,642.74,335.77z"
            />
            <path
              fill="#4D2C1A"
              d="M642.67,341.9c-0.2,0.55-0.5,1.36-0.77,1.98c-0.42,0.97-0.29,1.74,0.07,2.17
				c0.02,0.02,0.03,0.05,0.05,0.07c0.4,0.41,1.17,1.08,1.85,0.67c0.68-0.42,2.12-2.2,2.72-3.11c0.08-0.12,0.16-0.26,0.23-0.41
				c0.47-0.95,0.76-2.28,0.76-2.28c-0.58,1.84-1.41,2.04-2.33,2.04c-0.9,0-1.76-0.95-2.34-1.68c0.19-0.72,0.3-1.55,0.34-2.07
				c0.05-0.65-1.65-0.39-1.65-0.39c0.72-0.01,1.04,0.82,1.04,1.41c0,0.59-0.4,1.3-0.4,1.3c0.03,0.1,0.06,0.17,0.1,0.22
				C642.46,341.96,642.59,341.94,642.67,341.9z"
            />
            <path
              fill="#4D2C1A"
              d="M654.86,346.13c-0.04,0.01-0.08,0.02-0.13,0.02c-0.59,0.06-2.93,0.05-2.72-0.53
				c0.2-0.58,0.66-2.53,1.15-4.6c0.49-2.07,1.42-3.01,1.23-2.84c-0.2,0.17-0.6,0.62-0.56,0.35c0.04-0.27-0.17-0.73-0.17-0.73
				s-0.18,0.83-0.43,1.76c-0.26,0.93-1.5,5.56-1.62,6.22c-0.11,0.66-0.79,0.67-2.01,0.35c-1.22-0.32-3.48-0.16-3.17-0.85
				c0.31-0.68,0.81-1.78,0.81-1.78l-0.65,0.17l-1.02,1.53c0.07,0.15,0.09,0.49,0.13,0.85c0.05,0.41,0.13,0.86,0.36,1.12
				c0.42,0.48,0.84,0.88,1.56,1.21c0.71,0.33,2.47-0.87,3.1-1.38c0.28-0.23,0.48-0.25,0.66-0.19c0.14,0.05,0.27,0.15,0.44,0.25
				c0.09,0.05,0.19,0.1,0.3,0.14c0.01,0,0.01,0,0.02,0.01c0.58,0.2,1.09,0.49,2.19-0.26c0.5-0.34,0.69-0.69,0.81-0.96
				C655.07,346.04,654.99,346.1,654.86,346.13z"
            />
            <path
              fill="#4D2C1A"
              d="M655.22,345.74c-0.03,0.07-0.06,0.15-0.1,0.24C655.17,345.91,655.2,345.83,655.22,345.74z"
            />
            <path
              fill="#4D2C1A"
              d="M656.53,341.55c0.58-1.84,1.2-2.24,1.2-2.24s-0.62,0.1-1.1,1.2s-1.22,4.32-1.32,4.72
				c-0.01,0.04-0.02,0.08-0.02,0.11c-0.02,0.13-0.03,0.26-0.05,0.38c0.08-0.19,0.16-0.31,0.4-0.28c0.59,0.06,0.47-0.17,0.47-0.17
				C655.81,344.95,655.94,343.39,656.53,341.55z"
            />
            <path
              fill="#4D2C1A"
              d="M661.43,329.08c0.03,0.03,0.06,0.06,0.08,0.08c0,0-0.11-1.74,0.03-3.63c-2.94-0.62-6.53-1.37-9.23-1.93
				C655.03,324.83,659.58,327.08,661.43,329.08z"
            />
            <path
              fill="#D7BFB1"
              d="M674.38,240.79c-7.62-28.94-13.16-37.16-16.75-40.36c-1.66-1.89-3.57-3.35-5.78-4.23
				c-8.85-3.55-15.05-5.75-18.77-6.98c-0.99,0.83-16.68,13.98-28.28,24.6c0,0-6.23-13.57-11.25-21.79
				c-0.76,0.27-1.68,0.61-2.81,1.01c-8.01,2.88-9.22,7.83-15.3,11.11c-8.79,4.74-20.52,10.29-20.52,10.29s-9.19,0.75-25.01-6.03
				c-11.11-4.76-24.28-10.76-24.28-10.76l0,0.01l0.63,7.73l0.17,2.08l0.54,6.66c0,0,3.67,3.97,12.67,10.22
				c11.08,7.69,23.9,14.63,35.74,14.59c5.91-1,28.26-18.71,28.26-14.6c0,4.18,0.8,6.94,0.8,6.94s2.58,23.51,10.09,48.32
				c7.43,24.56,4.86,29.77,4.86,29.77s1.49-0.06,4.01-0.19c8.93-0.46,30.88-1.78,46.09-4.44c-0.35,10.13-0.77,17.9-1.12,18.07
				c0.15,0.02,0.7,0.12,1.5,0.29c0.66,0.13,1.5,0.3,2.46,0.5c2.7,0.56,6.3,1.31,9.23,1.93c2.98,0.63,5.28,1.11,5.28,1.11l4.38-25.86
				C674.49,278.97,679.34,259.65,674.38,240.79z M649.75,295.51c-1.08-5.33-7.14-16.94-6.45-20.88c0.6-3.42,2.44-6.49,3.41-9.82
				c0.97-3.33,0.85-7.42-1.79-9.67c-1.21-1.03-2.83-1.57-3.81-2.81c-1.76-2.22-0.73-5.44,0.13-8.14c1.08-3.36,1.78-6.85,2.09-10.36
				c1.94,10.63,4.49,23.14,6.77,30.26C650.23,271.39,650.06,283.9,649.75,295.51z"
            />
            <path
              fill="#D7BFB1"
              d="M648.33,322.83c0.01,0,0.01-0.01,0.02-0.01C648.29,322.81,648.29,322.81,648.33,322.83z"
            />
            <path
              fill="#141616"
              d="M590.99,157.81c0,0,0.34,0.46,0.8,0.27c0.46-0.19,0.8-0.44,0.8-0.44
				C592.04,157.83,591.48,157.93,590.99,157.81z"
            />
            <path
              opacity="0.3"
              d="M615.96,171.86c0.01,0.02,0.01,0.04,0.02,0.05c4.53,13.58,10.47,14.88,10.47,14.88
				c-0.1-0.06-0.19-0.12-0.28-0.18c-1.98-1.28-3.38-3.29-4.35-5.35c-1.32-2.79-1.86-5.66-2.05-6.88c-0.06-0.39-0.08-0.61-0.08-0.61
				C616.49,172.51,615.96,171.86,615.96,171.86z"
            />
          </g>
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="vr-woman">
          <path
            fill="#AD7D62"
            d="M436.82,220.75c-0.55,0.86-0.67,1.8,0.72,1.93C436.84,222.19,436.52,221.51,436.82,220.75z"
          />
          <path
            fill="#664033"
            d="M611.6,314.8c-6.19,6.6-12.37,9.29-15,10.2c0.26,5.3,1.89,11.4,4.47,19.06c0.59,1.64,1.12,3.45,1.71,5.96
			c1.02,4.37,0.84,6.24,0.84,6.24l17.22-0.6c0,0-2.18-14.02-5.32-24.89c-2.02-7.01-3.25-12.81-3.86-16.05
			c-0.49,0.33-1.06,0.68-1.71,1.03C611.07,315.19,611.6,314.8,611.6,314.8z"
          />
          <path
            fill="#664033"
            d="M536.63,243.95c-0.01-0.16-0.02-0.32-0.02-0.47c0,1.11,0,2.29,0,3.47c0.04,0,0.07-0.01,0.11-0.01
			C536.71,246.01,536.69,245,536.63,243.95z"
          />
          <path
            fill="#664033"
            d="M569.8,215.55c-0.04-0.02-0.08-0.03-0.13-0.05C569.72,215.52,569.76,215.54,569.8,215.55
			C569.8,215.55,569.8,215.55,569.8,215.55z"
          />
          <g>
            <path
              fill="#664033"
              d="M568.83,223.67c0.15-0.24,0.3-0.49,0.46-0.73C569.14,223.18,568.98,223.42,568.83,223.67z"
            />
            <g>
              <path
                fill="#664033"
                d="M567.51,225.69c0.42-0.63,0.87-1.31,1.32-2.03C568.4,224.36,567.95,225.02,567.51,225.69z"
              />
              <path
                fill="#664033"
                d="M574.96,209.89c0.44-2.32,0.65-4.96,0.45-7.92c-0.84-12.08-7.31-21.29-20.75-22.71
					c-0.25-0.08-0.5-0.16-0.75-0.24c-2.01-0.64-4.28-1.26-6.14-0.28c-0.22,0.12-0.44,0.26-0.68,0.28c-0.23,0.02-0.45-0.06-0.67-0.13
					c-2.03-0.62-4.14-0.5-5.65,0.99c-1.09-0.42-2.67-0.35-3.59,0.38c-0.27,0.22-0.47,0.51-0.75,0.72c-0.84,0.62-2.13,0.25-2.99,0.84
					c-0.6,0.42-0.9,1.25-1.59,1.48c-0.32,0.11-0.68,0.06-1.01,0.14c-0.84,0.2-1.55,0.83-1.87,1.72c-0.28,0.76-1.28,0.99-1.81,1.6
					c-0.32,0.36-0.9,1.64,0.03,2.66c0.01,0.01,0.02,0.01,0.03,0.02c0.06,0.11,0.17,0.17,0.3,0.2c-1.4,1.79-2.14,3.66-2.5,5.64
					c7.55-0.18,14.04,0.13,19.03,0.67l-3.71,21.76c-3.38-0.53-7.44-1.4-8.18-1.71l0.01,0c-0.02-0.01-0.05-0.02-0.07-0.03l0.05-0.01
					c-0.41-0.11-0.72-0.22-0.95-0.33c-1.32-0.66-2.44-1.99-4.24-2.08c-1.8-0.09-1.87,0.42-1.96,1.8c-0.04,0.58-0.56,0.83-1.24,0.9
					c-0.12,0.11-0.17,0.33-0.22,0.83c-0.01,0.11-0.03,0.17-0.07,0.2c0.03,1.31,0.22,2.98,0.64,5.23c0.04,0.19,0.06,0.31,0.06,0.31
					c0,0,0,0,0,0c0,0,0,0,0.02,0.13c0.95,5.08-0.35,4.88,0.36,9.28c0.43,2.71,3.68,3.98,10.86,4.38c1.19,0.54,1.07,3.41,1.22,6.87
					c0.01,0.16,0.01,0.31,0.02,0.47c0.06,1.05,0.08,2.07,0.09,2.99c0,0,0,0,0,0c0,0-0.86,0.4-1.46,1.4l0,0c0,0-1.09,2.36,1.62,4.5
					c1.25,0.98,5.11,3.25,7.81,4.81c1.61,0.93,2.81,1.6,2.81,1.6l0.67,1.22c0.06,0.05,0.12,0.09,0.17,0.14l0.14,0.43l4.05,7.33
					c0.71-4.02,2.18-9.21,2.18-9.21s1.53-0.83,3.37-2.09c1.47-1.25,4.42-3.96,5.46-6.4l-0.59-2.66c-0.1-0.35-0.24-0.99-0.4-2.13
					c-0.6-4.36-0.99-11.78,2.35-16.39c0.87-1.2,1.74-2.47,2.6-3.77c-1.64,2.46-2.97,4.19-2.97,4.19c0.07-0.25,0.24-0.39,0.27-0.46
					C570.75,216.78,572.09,215.71,574.96,209.89z"
              />
            </g>
          </g>
          <path
            fill="#111413"
            d="M528.74,189.21c6.21-4.66,19.29-3.62,23.9,5.1c0.64,1.21,1.27,2.48,1.87,3.77
			c1.83,0.69,3.16,1.8,3.14,4.86c0,0.44-0.03,0.92-0.07,1.45c2.91,0.52,9.43,1.97,17.37,5.5c0.44-2.32,0.65-4.96,0.45-7.92
			c-0.84-12.08-7.31-21.29-20.75-22.71c-0.25-0.08-0.5-0.16-0.75-0.24c-2.01-0.64-4.28-1.26-6.14-0.28
			c-0.22,0.12-0.44,0.26-0.68,0.28c-0.23,0.02-0.45-0.06-0.67-0.13c-2.03-0.62-4.14-0.5-5.65,0.99c-1.09-0.42-2.67-0.35-3.59,0.38
			c-0.27,0.22-0.47,0.51-0.75,0.72c-0.84,0.62-2.13,0.25-2.99,0.84c-0.6,0.42-0.9,1.25-1.59,1.48c-0.32,0.11-0.68,0.06-1.01,0.14
			c-0.84,0.2-1.55,0.83-1.87,1.72c-0.28,0.76-1.28,0.99-1.81,1.6c-0.32,0.37-0.9,1.64,0.03,2.66c0.01,0.01,0.02,0.01,0.03,0.02
			c0.06,0.11,0.17,0.17,0.3,0.2C527.84,189.71,528.35,189.51,528.74,189.21z"
          />
          <path
            fill="#141616"
            d="M561.68,215.04c1.09,0.77,2.03-0.22,2.33,0.44c0.3,0.66,0.49,1.22,0.49,1.22s0.59-0.72,0.3-1.44
			c-0.1-0.25-0.22-0.47-0.38-0.67c-1.17-0.35-2.27-0.65-3.4-0.9C561.08,214.24,561.27,214.75,561.68,215.04z"
          />
          <path
            fill="#141616"
            d="M561.24,218.19c-0.59,1.11,0.1,1.66,0.69,1c0.59-0.66,1.68-2.32,1.19-2.71c-0.49-0.39-1.44-0.06-2.23-0.88
			c0,0-0.35,0.17,0.05,0.72C561.34,216.86,561.83,217.09,561.24,218.19z"
          />
          <path
            fill="#141616"
            d="M563.11,220.95c2.98-1.08,3.21-4.99,3.23-5.77c0,0,0,0,0,0C566.22,217.81,563.11,220.95,563.11,220.95z"
          />
          <path
            fill="#111413"
            d="M569.8,215.56c-0.04-0.02-0.08-0.04-0.12-0.06c-2.72-1.08-7.18-2.48-13.05-3.08
			c-1.12,5.12-4.79,5.7-9.39,5.3c-9.05-0.78-13.19-1.24-15.1-1.75l-0.05,0.01c0.02,0.01,0.05,0.02,0.07,0.03l-0.01,0
			c0.98,0.41,7.68,1.79,11.05,2.07c3.18,0.27,7.58,0.75,10.22-0.25c0.18-0.07,0.35-0.14,0.51-0.22c2.39-1.18,3.16-4.26,3.26-4.69
			c0.17,0.03,0.81,0.14,1.76,0.32c0.25,1.34,0.36,2.32,0.3,2.75c-0.11,0.81,0.23-0.66,1.07-2.48c0.23,0.05,0.46,0.1,0.7,0.15
			c1.13,0.25,2.23,0.55,3.4,0.9c0.61,0.18,1.25,0.38,1.91,0.59c0,0,0,0,0,0c0.5,0.16,1.03,0.33,1.57,0.51
			c-0.86,3-2.39,7.61-3.1,13.73c-0.01,0.08-0.2,0.21-0.27,0.46c0,0,2.4-3.14,4.74-6.95c0.83-1.36,1.66-2.8,2.37-4.22
			c0.33-0.55,0.67-1.16,1-1.85C572.41,216.73,571.43,216.2,569.8,215.56C569.8,215.55,569.8,215.55,569.8,215.56z"
          />
          <g>
            <path
              fill="#141616"
              d="M534.72,223.38c-0.55-0.97-1.03-3.27-1.03-3.27s0.12,0.97,0.12,1.43c0,0.45-0.21,0.7-0.85,0.68
				c-0.04-0.01-0.08-0.03-0.11-0.04c-0.66-0.14-1.33,0.03-3.05-1.23c-1.07-0.79-1.57-0.39-2.64-0.17s-0.92-0.36-1.91,0
				c-1.14,0.42-1.22,1.27-1.18,1.73c0.02,0.18,0.06,0.3,0.06,0.31c0,0,0,0,0,0c0,0,0,0,0.01,0c0.06-0.03,0.4-0.18,1.21-0.44
				c0.91-0.29,0.94,0.31,1.77,0.18c0.83-0.12,1.43-0.45,2.16-0.25c2.34,0.64,3.21,0.3,3.73,0.13c0.12,0.12,0.24,0.5-0.44,1.13
				c-0.94,0.87-2.07,1.4-2.79,1.44c0,0,0.68,0.14,1.77-0.36c0.66-0.23,1.28-0.92,1.71-1.5c0.01-0.01,0-0.02,0.01-0.03
				c0.12-0.16,0.18-0.28,0.22-0.38c0.41-0.4,1,0.35,1.23,0.7C534.74,223.41,534.73,223.4,534.72,223.38z"
            />
            <path fill="#141616" d="M534.75,223.43C534.9,223.69,534.87,223.62,534.75,223.43L534.75,223.43z" />
          </g>
          <path
            fill="#141616"
            d="M528.23,216.81c-0.29,0.21-0.04,0.38,0.77,0.55c0.81,0.17,1.19,0.17,1.19,0.17
			C529.32,217.03,528.59,216.54,528.23,216.81z"
          />
          <path
            fill="#141616"
            d="M530.97,217.49c-0.4,0.1-0.78,0.03-0.78,0.03s0.69,0.44,1.15,0.18c0.66-0.37-0.15-2.06-0.15-2.06
			C531.4,216.45,531.37,217.39,530.97,217.49z"
          />
          <path
            fill="#141616"
            d="M526.71,217.24c-0.28-0.19,0.05,0.29,0.56,0.29c0.51,0,0.61-0.33,0.61-0.33
			C527.55,217.41,527.02,217.46,526.71,217.24z"
          />
          <path
            fill="#141616"
            d="M525.92,216.79c-0.44,0.14-1.02,0.7-1.02,0.7l0.54-0.03C526.14,217.32,526.36,216.66,525.92,216.79z"
          />
          <path
            fill="#141616"
            d="M536.72,246.93C536.72,246.93,536.72,246.93,536.72,246.93c0,0-0.86,0.4-1.46,1.4l0,0
			c0,0-1.09,2.36,1.62,4.5c1.25,0.98,5.11,3.25,7.81,4.81c-3.08-2.54-6.37-5.42-7.63-7.16c2.13-2.54,11.35-13.54,13.99-17.16
			c2.99-4.09,3.28-7.14,3.28-7.14s-0.23,1.11-2.59,3.57c-2.64,2.76-4.46,5.05-7.69,6.25c-2.57,0.96-5.74,0.82-8.76,0.57
			c0.03,0.01,0.05,0.03,0.08,0.04c1.19,0.54,1.07,3.41,1.22,6.87c0.01,0.16,0.01,0.31,0.02,0.47
			C536.69,245,536.71,246.01,536.72,246.93z"
          />
          <path
            fill="#1EBEE4"
            d="M521.6,211.98c0,0-0.07,0.01-0.2,0.02c0.64-0.02,1.32-0.04,2.07-0.06c0.57-0.02,1.11-0.03,1.64-0.03
			l0.32,0l0-0.01c6.06-0.02,9.68,0.75,16.31,1.57c4.4,0.54,6.17-0.84,6.7-4.06c0.26-1.56,0.93-5.7,0.73-8.07
			c-0.2-2.37-1.89-3.51-4.91-4.13c-4.11-0.84-9.94-1.2-15.36-1.24c1.81,0.02,3.05,0.06,3.05,0.06
			C521.56,198.83,521.6,211.98,521.6,211.98z"
          />
          <path
            fill="#42637C"
            d="M523.74,216.25c0.69-0.07,1.2-0.32,1.24-0.9c0.09-1.37,0.17-1.89,1.96-1.8c1.8,0.09,2.92,1.42,4.24,2.08
			c0.23,0.12,0.54,0.23,0.95,0.33c1.92,0.51,6.05,0.97,15.1,1.75c4.6,0.39,8.27-0.18,9.39-5.3c5.87,0.59,10.33,2,13.05,3.08
			c0.04,0.02,0.08,0.03,0.13,0.05c1.63,0.65,2.61,1.18,2.85,1.32c0.04,0.02,0.06,0.04,0.06,0.04c1.72-3.21,2.26-7,2.26-7
			c-0.01,0-0.01-0.01-0.02-0.01c-7.94-3.53-14.46-4.98-17.37-5.5c0.04-0.53,0.06-1.01,0.07-1.45c0.02-3.06-1.31-4.17-3.14-4.86
			c-0.68-0.26-1.43-0.46-2.21-0.68c-4.45-1.28-14.33-2.42-27.3-2.11c-0.82,0.02-1.66,0.05-2.51,0.08c-2.81,0.11-4.77,0.59-6.12,1.3
			c0.29-0.1,0.61-0.17,0.94-0.21c0.29-0.06,0.61-0.11,0.95-0.14c1.14-0.13,2.48-0.21,3.86-0.26l-0.01-0.02l0.9-0.01
			c2.09-0.06,4.22-0.06,5.86-0.04c5.43,0.04,11.25,0.4,15.36,1.24c3.03,0.62,4.71,1.76,4.91,4.13c0.2,2.37-0.48,6.51-0.73,8.07
			c-0.53,3.22-2.3,4.6-6.7,4.06c-6.62-0.81-10.25-1.59-16.31-1.57l0,0.01l-0.32,0c-0.53,0-1.07,0.01-1.64,0.03
			c-0.75,0.02-1.43,0.04-2.07,0.06c-0.58,0.04-2.37,0.16-4.39,0.14c-1-0.01-1.74-0.29-2.29-0.76c0.24,0.4,0.61,0.81,1.05,1.22
			c0.32,0.18,1.28,0.42,4.6,0.42c2.41,0,3.77-0.3,5.96-0.27c1.01,0.02,2.32,0.14,3.97,0.23c5.24,0.27,12.83,2.3,15.99,1.76
			c3.16-0.54,3.79-5.06,4.15-9.12c0.14-1.52,0.28-3.44-0.09-4.97c-0.62-2.54-3.4-4.44-15.47-4.88c0,0,11.34,0.21,18.04,2.6
			c3.17,1.13,3.7,3.4,3.6,5.84c-0.38-0.06-0.58-0.08-0.58-0.08l-0.93,8.14c0.14,0.01,0.27,0.02,0.4,0.03
			c-0.29,0.99-0.69,1.86-1.24,2.67c-1.06,1.57-3.08,1.97-5.15,1.87c-5.78-0.27-15-1.33-16.87-1.67c-2.3-0.42-2.6-2.03-5.73-1.9
			s-0.96,2.6-2.76,2.65c-0.5,0.11-0.47,1.24-0.47,1.24s0.18,0.14,0.17,0.11c0.03,0.01,0.05,0.01,0.07-0.01
			c0.03-0.03,0.05-0.09,0.07-0.2C523.57,216.58,523.62,216.36,523.74,216.25z"
          />
          <path
            fill="#1EBEE4"
            d="M516.38,196.66c-0.15,0.05-0.3,0.11-0.45,0.18c0.4-0.16,0.86-0.29,1.38-0.39
			C516.99,196.49,516.67,196.56,516.38,196.66z"
          />
          <path fill="#1EBEE4" d="M522.12,196.02l0.01,0.02c0.3-0.01,0.59-0.02,0.89-0.03L522.12,196.02z" />
          <path
            fill="#111413"
            d="M523.67,215.94c1.79-0.05-0.37-2.52,2.76-2.65s3.43,1.48,5.73,1.9c1.87,0.34,11.09,1.4,16.87,1.67
			c2.07,0.1,4.1-0.31,5.15-1.87c0.55-0.81,0.94-1.68,1.24-2.67c-0.13-0.01-0.27-0.02-0.4-0.03l0.93-8.14c0,0,0.2,0.02,0.58,0.08
			c0.1-2.44-0.43-4.71-3.6-5.84c-6.7-2.4-18.04-2.6-18.04-2.6c12.07,0.44,14.85,2.33,15.47,4.88c0.37,1.53,0.22,3.44,0.09,4.97
			c-0.36,4.06-0.99,8.58-4.15,9.12c-3.16,0.54-10.75-1.49-15.99-1.76c-1.65-0.09-2.97-0.21-3.97-0.23
			c-2.19-0.03-3.55,0.27-5.96,0.27c-3.32,0-4.28-0.24-4.6-0.42c-0.02-0.01-0.04-0.02-0.05-0.03c0,0,1.23,1.12,2.72,2.02
			c0.5,0.34,1.11,0.69,1.72,1.03c0.47,0.26,0.95,0.51,1.38,0.73c0.37,0.19,1.67,0.83,1.67,0.83S523.18,216.06,523.67,215.94z"
          />
          <path
            fill="#FFFFFF"
            d="M553.8,208.49c-0.08-0.01-0.16,0.05-0.17,0.13l-0.38,3.06c-0.01,0.08,0.05,0.16,0.13,0.17
			c0.01,0,0.01,0,0.02,0c0.07,0,0.14-0.06,0.15-0.13l0.38-3.06C553.94,208.58,553.88,208.5,553.8,208.49z"
          />
          <path
            fill="#85BED3"
            d="M552.68,211.08c0.01,0,0.01,0,0.02,0c0.08,0,0.14-0.05,0.16-0.13l0.26-1.75c0.01-0.08-0.05-0.16-0.13-0.17
			c-0.08-0.01-0.16,0.05-0.17,0.13l-0.26,1.75C552.54,210.99,552.6,211.07,552.68,211.08z"
          />
          <path
            fill="#85BED3"
            d="M553.6,207.47c0.27,0.03,0.53-0.35,0.59-0.86c0.06-0.51-0.1-0.94-0.37-0.97
			c-0.27-0.03-0.53,0.35-0.59,0.86C553.17,207,553.33,207.44,553.6,207.47z"
          />
          <path
            fill="#141616"
            d="M562.96,247.18c-0.06-0.05-0.13-0.1-0.2-0.14l0.21,0.95l0.59,2.66
			C564.12,249.31,564.11,248.06,562.96,247.18z"
          />
          <path
            fill="#141616"
            d="M611.6,314.8c0,0-0.53,0.4-1.65,0.96c-1.55,0.78-4.25,1.88-8.28,2.68c-1.41,0.28-2.99,0.53-4.73,0.71
			c-0.33,1.86-0.44,3.79-0.34,5.85C599.22,324.08,605.41,321.4,611.6,314.8z"
          />
          <path fill="#141616" d="M548.19,260.46l0.31,0.57l-0.14-0.43C548.31,260.55,548.25,260.5,548.19,260.46z" />
          <path
            fill="#AD7D62"
            d="M602.77,350.02c-0.59-2.51-1.12-4.32-1.71-5.96c-2.57-7.66-4.21-13.76-4.47-19.06
			c-0.1-2.05,0-3.99,0.34-5.85c1.75-0.18,3.32-0.43,4.73-0.71c4.03-0.8,6.73-1.9,8.28-2.68c0.65-0.36,1.22-0.71,1.71-1.03
			c1.54-1.03,2.25-1.83,2.25-1.83s-7.26-17.78-9.12-26.22c-5.21-23.66-16.85-24.37-28.9-27.16c-3.77-0.87-6.79-1.68-8.91-2.29
			c-0.22-3.92-0.83-6.34-1.51-7.83c-0.15-0.34-0.31-0.64-0.48-0.89c-1.1-1.71-2.23-1.47-2.23-1.47c0.07,0.05,0.14,0.1,0.2,0.14
			c1.15,0.88,1.16,2.13,0.59,3.47c-1.03,2.44-3.99,5.15-5.46,6.4c-1.84,1.27-3.37,2.09-3.37,2.09s-1.47,5.19-2.18,9.21l-4.05-7.33
			l-0.31-0.57l-0.67-1.22c0,0-1.2-0.67-2.81-1.6c-2.71-1.56-6.57-3.83-7.81-4.81c-2.72-2.14-1.62-4.5-1.62-4.5l0,0
			c0.59-0.99,1.46-1.4,1.46-1.4c0,0,0,0,0,0c-0.04,0-0.07,0.01-0.11,0.01c-1.5,0.11-2.95,1.56-2.73,3.27
			c0.15,1.16,0.33,3.33,0.42,4.56c-14.41,1.23-21.58-3.1-33.61,8.6c-5.68,5.53-11.59,9.02-11.59,9.02c0.56-0.41,2.21,0.66,3.21,1.44
			c4.94,3.33,10.07,11.35,11.76,16.57c0.76,2.35,0.82,4.14-0.16,4.66l8.87-5.51c17.01,16.22,20.24,68.57,20.24,68.57
			c0.79,0.03,1.6,0.05,2.44,0.07l12.48,0.35c-0.1-0.1-0.2-0.2-0.29-0.3c24.2-0.21,55.95-1.99,55.95-1.99
			S603.79,354.39,602.77,350.02z"
          />
          <path
            fill="#664033"
            d="M438.37,222.25c-0.03-0.98,0.7-2.21,1.02-2.69c-0.03-0.37-0.05-0.73-0.09-1.07
			c-0.27,0.18-0.58,0.38-0.94,0.62c-0.85,0.57-1.34,1.13-1.54,1.65c-0.3,0.76,0.02,1.44,0.72,1.93c0,0,0,0,0.01,0
			c0.6,0.42,1.34,0.32,1.98-0.01c0-0.02,0-0.03,0-0.05C439.27,222.79,438.4,223.31,438.37,222.25z"
          />
          <path
            fill="#42637C"
            d="M449.2,239.34c-1.97-5-0.68-7.39-0.73-9.49c-0.05-2.19-2.09-4.33-2.28-6.57
			c-0.09-1.11,0.13-2.04,0.45-2.77c0.09-0.57,0.28-1,0.55-1.25c0.07-0.28,0.25-0.49,0.61-0.6c0.02-0.01,0.04-0.01,0.06-0.01
			c-0.34-2.83-0.69-5.26-0.98-6.75c-0.51-2.66-1.51-5.51-3.6-7.99c-1.65,1.8-1.69-0.49-4.32-2.77c-1.77-1.53-3.22-2.16-3.99-2.41
			c-0.36-0.1-0.6-0.16-0.6-0.16s0.22,0.03,0.6,0.16c0.36,0.1,0.82,0.24,1.27,0.4c-3.65-1.39-6.2-0.89-7.94,0.15
			c2.43-1.07,6.94,0.89,6.26,1.89c-0.4,0.58-0.75,0.9-1.39,1.46c-0.46,0.4-1.04,0.07-1.58-0.21c-3.03-1.54-4.76-1.57-5.35-1.32
			c-0.5,0.62-0.82,1.2-1.01,1.56c-0.84,1.61-0.5,2.8,0.28,3.78c-0.03-0.04-0.05-0.07-0.08-0.11c-0.73-1.13-0.96-2.47-0.24-3.01
			c0.96-0.72,2.47-0.35,4.45,0.48c3.64,1.53,10.31,5.49,11.45,13c0,0,0.26,4.64,0.99,11.58c0.22,0.28,0.44,0.56,0.67,0.85
			c0.58,0.73,1.05,1.35,1.37,1.88l0,0c0.09,0.11,0.16,0.25,0.23,0.42c0.31,0.63,0.4,1.14,0.21,1.59c-0.05,0.66-0.25,1.32-0.68,1.75
			c-0.07,0.07-0.11,0.15-0.13,0.25c0.53,0.42,1.18,0.81,2.08,1.08c2.14,0.66,2.59,1.79,2.49,2.53c-0.01,0.11-0.04,0.21-0.07,0.31
			c0.06,0.34,0.13,1.09-0.34,1.64c0.6,0.47,0.74,1.19,0.43,1.62c-0.03,0.17-0.14,0.29-0.32,0.37c-0.37,0.39-0.99,0.69-1.95,0.52
			c-0.6-0.11-1.54-0.5-2.39-0.89c0.25,3.19,0,3.86-0.45,3.37c0.66,1.04,1.51,1.78,2.56,1.78c0.81,0,1.5-0.39,2.04-1.18
			c0.59-0.86,1.01-2.19,1.26-3.98C449.16,241.73,449.24,240.7,449.2,239.34z"
          />
          <path
            fill="#111413"
            d="M443.71,242.29c0.85,0.4,1.79,0.78,2.39,0.89c0.96,0.17,1.58-0.13,1.95-0.52
			c-0.25,0.11-0.61,0.14-0.99,0.13c-0.55-0.01-1.55-0.28-1.55-0.28s-0.01-0.03-0.03-0.07c-0.72-0.17-1.57-0.45-2.68-0.87
			c-0.5-0.19-0.92-0.33-1.28-0.44c0.21,0.89,0.42,1.64,0.63,2.19c0.39,1.04,0.81,1.99,1.13,2.35
			C443.7,246.14,443.96,245.48,443.71,242.29z"
          />
          <path
            fill="#111413"
            d="M444.14,231.09L444.14,231.09c0.08,0.15,0.16,0.28,0.23,0.42C444.31,231.35,444.23,231.2,444.14,231.09z"
          />
          <path
            fill="#111413"
            d="M443.9,233.97c-0.03,0.11-0.08,0.22-0.15,0.33c-0.15,0.22-0.36,0.28-0.62,0.24
			c0.2,0.19,0.41,0.38,0.65,0.57c0.02-0.1,0.06-0.18,0.13-0.25c0.43-0.43,0.63-1.09,0.68-1.75c-0.04,0.11-0.1,0.21-0.17,0.31
			C444.25,233.65,444.08,233.83,443.9,233.97z"
          />
          <path
            fill="#111413"
            d="M448.1,239.67c-0.13,0.44-0.54,0.59-1.11,0.56c-0.06,0.02-0.1,0.03-0.1,0.03
			c0.44,0.06,0.78,0.21,1.04,0.42c0.47-0.55,0.4-1.3,0.34-1.64c-0.04,0.11-0.08,0.21-0.13,0.3
			C448.15,239.44,448.14,239.55,448.1,239.67z"
          />
          <path
            fill="#111413"
            d="M446.19,223.29c0.19,2.24,2.24,4.38,2.28,6.57c0.05,2.09-1.25,4.49,0.73,9.49
			c0.04,1.36-0.04,2.39-0.09,2.92c-0.05,0.58-0.05,0.59,0.2-0.21c0.49-1.58,3.04,0,1.7-1.83c-0.38-0.52-0.68-0.98-0.92-1.43
			c-0.13-0.29-0.26-0.6-0.39-0.94c-1.9-5.14,0.42-6.81-1.57-10.67c-1.32-2.57-1.75-5.13-1.49-6.68
			C446.32,221.25,446.09,222.18,446.19,223.29z"
          />
          <path
            fill="#1EBEE4"
            d="M431.61,202.41c0.54,0.27,1.12,0.61,1.58,0.21c0.65-0.56,0.99-0.88,1.39-1.46
			c0.68-0.99-3.84-2.96-6.26-1.89c-0.12,0.05-0.23,0.11-0.34,0.17c-1.14,0.71-1.83,1.69-1.83,1.69c0.03-0.02,0.07-0.04,0.11-0.06
			C426.85,200.84,428.58,200.87,431.61,202.41z"
          />
          <path
            fill="#664033"
            d="M439.57,224.28c0,0.05-0.01,0.09-0.01,0.15c-0.01,0.1,0.02,0.22,0.07,0.37
			C439.59,224.64,439.58,224.46,439.57,224.28z"
          />
          <path
            fill="#664033"
            d="M439.97,225.47c0.23,0.38,0.51,0.77,0.71,1.04c0.08,0.1,0.15,0.18,0.19,0.24
			C440.49,226.23,440.18,225.8,439.97,225.47z"
          />
          <path
            fill="#664033"
            d="M438.55,236.67c0.03,0.02,0.06,0.04,0.09,0.07c-0.01-0.01-0.01-0.02-0.02-0.02
			C438.56,236.67,438.52,236.65,438.55,236.67z"
          />
          <path
            fill="#664033"
            d="M445.47,242.43c-0.06-0.19-0.17-0.77,0.4-1.62c1.47,0.35,2.47,0.48,2.51,1.39c0,0.03-0.01,0.06-0.01,0.09
			c0.31-0.43,0.17-1.15-0.43-1.62c-0.26-0.2-0.6-0.36-1.04-0.42c0,0,0.04-0.01,0.1-0.03c-0.17-0.01-0.36-0.04-0.56-0.08
			c-0.83-0.18-1.8-0.6-1.8-0.6s-0.2-1.07,0.53-1.91c1.52,0.6,2.96,0.78,2.98,1.7c0.05-0.09,0.1-0.19,0.13-0.3
			c0.03-0.1,0.06-0.2,0.07-0.31c0.09-0.75-0.35-1.87-2.49-2.53c-0.9-0.28-1.55-0.66-2.08-1.08c-0.24-0.19-0.45-0.38-0.65-0.57
			c-0.32-0.06-0.71-0.29-1.09-0.57c-0.53-0.39-1.33-1.35-1.33-1.35s0.1-0.86,1.53-1.42c1.06,1.2,1.88,1.97,1.66,2.77
			c0.18-0.13,0.35-0.31,0.52-0.55c0.07-0.1,0.12-0.2,0.17-0.31c0.18-0.46,0.1-0.97-0.21-1.59c-0.07-0.13-0.14-0.27-0.23-0.42
			c-0.32-0.54-0.79-1.15-1.37-1.88c-0.23-0.29-0.46-0.57-0.67-0.85c-0.43-0.55-0.82-1.06-1.16-1.52c-0.02-0.02-0.12-0.15-0.26-0.33
			c-0.01-0.01-0.01-0.02-0.02-0.03c-1.06-1.32-3.11-2.61-2.97-2.69c0.11-0.05,0.24-0.09,0.37-0.13c-0.63,0.1-1.14,0.35-1.48,0.93
			c-0.58,0.99-0.49,2.97,0.49,4.45c0.33,0.5,0.76,0.99,1.23,1.48c-0.02,0.02-0.04,0.04-0.06,0.07c0.03-0.02,0.05-0.05,0.08-0.06
			c0,0,1.81,1.54,2.9,2.69c0,0-2.15-1.86-2.66-2.06c-0.5-0.19-0.73,0.03-0.73,0.03s0.03-0.06,0.07-0.14
			c-0.62,1.02-1.2,2.87-1.08,3.76c0.14,0.99,0.64,1.13,1.76,1.87c-0.04-0.04-0.06-0.06-0.06-0.06s1.56,1.13,3.13,1.9
			c0.26,0.12,0.43,0.21,0.43,0.21c-0.14-0.06-0.29-0.13-0.43-0.21c-0.66-0.31-1.86-0.87-2.26-0.97c-0.43-0.11-0.54-0.05-0.57-0.02
			c-0.06,0.34-0.06,0.91,0.42,2.05c0.43,1.03,0.9,1.1,2.26,1.53c0.36,0.12,0.78,0.25,1.28,0.44
			C443.9,241.98,444.75,242.26,445.47,242.43z"
          />
          <path
            fill="#111413"
            d="M504.06,290.4c-1.69-5.22-6.82-13.24-11.76-16.57c-1-0.78-2.65-1.85-3.21-1.44
			c-0.63,0.47-0.72,1.33-0.72,1.33l0.09-0.05l-2.93,2.01l-0.03,0.02c0,0,0.64,1.08,1.73,3.01c-0.47-2.48,0.21-3.98,1.83-5.38
			l0.66-0.37c2.11,1.93,8.93,8.65,11.77,17.09l-0.44-0.78c-2.62,2.75-6.03,6.32-11.73,8.45l0.12,0.16c-0.05,0.05-0.11,0.1-0.16,0.15
			c2.91-0.68,7.33-1.9,11.02-3.69c0.63,0.4,2.38,1.38,3.6,0.72C504.88,294.53,504.82,292.75,504.06,290.4z"
          />
          <path
            fill="#AD7D62"
            d="M443.74,234.3c0.07-0.11,0.12-0.22,0.15-0.33c0.22-0.8-0.6-1.57-1.66-2.77c-1.43,0.55-1.53,1.42-1.53,1.42
			s0.8,0.96,1.33,1.35c0.38,0.28,0.77,0.51,1.09,0.57C443.38,234.58,443.6,234.52,443.74,234.3z"
          />
          <path
            fill="#AD7D62"
            d="M445.87,240.81c-0.57,0.85-0.46,1.43-0.4,1.62c0.02,0.05,0.03,0.07,0.03,0.07s1,0.27,1.55,0.28
			c0.39,0.01,0.75-0.02,0.99-0.13c0.18-0.08,0.29-0.19,0.32-0.37c0-0.03,0.01-0.06,0.01-0.09
			C448.34,241.29,447.34,241.16,445.87,240.81z"
          />
          <path
            fill="#AD7D62"
            d="M448.1,239.67c0.04-0.12,0.05-0.23,0.04-0.34c-0.02-0.91-1.46-1.1-2.98-1.7
			c-0.74,0.84-0.53,1.91-0.53,1.91s0.97,0.42,1.8,0.6c0.2,0.04,0.39,0.07,0.56,0.08C447.56,240.27,447.97,240.12,448.1,239.67z"
          />
          <path
            fill="#AD7D62"
            d="M447.19,219.27c-0.17,0.66,0.29,1.72,0.56,2.97c1.02,0.13,1.75-0.5,1.86-0.6
			c0.01-0.01,0.02-0.02,0.02-0.02s-0.19-1.03-0.5-1.83c-0.31-0.79-0.72-1.27-1.26-1.14c-0.02,0-0.04,0-0.06,0.01
			C447.45,218.78,447.26,218.99,447.19,219.27z"
          />
          <path
            fill="#111413"
            d="M440.66,226.48c0.01,0.01,0.01,0.02,0.02,0.03c-0.2-0.27-0.48-0.66-0.71-1.04
			c0.21,0.32,0.52,0.76,0.9,1.28c-0.04-0.05-0.1-0.13-0.19-0.24c0.14,0.18,0.24,0.31,0.26,0.33c0.34,0.46,0.73,0.97,1.16,1.52
			c-0.72-6.94-0.99-11.58-0.99-11.58c-1.14-7.51-7.81-11.47-11.45-13c-1.98-0.83-3.49-1.19-4.45-0.48
			c-0.72,0.54-0.49,1.88,0.24,3.01c0.02,0.03,0.05,0.07,0.08,0.11c0.81,1.14,3.25,3.04,3.64,4.07c0.06,0.15,0.07,0.3,0.06,0.45
			c0.15-0.54,0.13-1.1-0.3-1.51c0,0,1.94-0.87,4.61,1.02c2.68,1.89,2.25,3.69,2.25,3.69s-0.61,0.06-1.46,1.22
			c-0.85,1.16-0.67,0.97-2.19-0.06c-1.52-1.03-3.2-1.55-3.7-1.29c0.46,1.73,3.45,3.05,4.91,3.25c1.46,0.2,2.32-2.52,3.92-3.05
			c0.15-0.05,0.3-0.07,0.45-0.06c0.9,0.44,1.35,1.98,1.58,4.33c0.03,0.34,0.06,0.7,0.09,1.07c-0.31,0.48-1.05,1.71-1.02,2.69
			c0.03,1.06,0.9,0.54,1.15,0.37c0,0.02,0,0.03,0,0.05c0.01,0.3,0.01,0.61,0.02,0.92c-0.14-0.01-0.89-0.06-1.48,0.08
			c-0.13,0.03-0.26,0.07-0.37,0.13C437.55,223.87,439.6,225.17,440.66,226.48z M439.57,224.28c0.01,0.18,0.03,0.36,0.06,0.52
			c-0.05-0.14-0.08-0.27-0.07-0.37C439.56,224.38,439.56,224.33,439.57,224.28z"
          />
          <path
            fill="#111413"
            d="M438.57,231.16c0.5,0.19,2.66,2.06,2.66,2.06c-1.08-1.15-2.9-2.69-2.9-2.69
			c-0.03,0.02-0.05,0.04-0.08,0.06c-0.14,0.13-0.26,0.33-0.33,0.46c-0.04,0.08-0.07,0.14-0.07,0.14S438.07,230.97,438.57,231.16z"
          />
          <path
            fill="#111413"
            d="M439.4,237.56c0.39,0.1,1.6,0.66,2.26,0.97c-1.56-0.77-3.13-1.9-3.13-1.9s0.03,0.03,0.06,0.06
			c0.01,0.01,0.01,0.01,0.02,0.02c0.01,0.01,0.01,0.02,0.02,0.02c0.05,0.05,0.11,0.12,0.18,0.21c0.08,0.11,0.07,0.29,0.04,0.43
			c-0.01,0.03-0.01,0.06-0.02,0.08c-0.01,0.05-0.03,0.09-0.03,0.09s0-0.01,0.01-0.02C438.86,237.51,438.98,237.45,439.4,237.56z"
          />
          <path
            fill="#111413"
            d="M441.66,238.53c0.15,0.07,0.29,0.14,0.43,0.21C442.1,238.73,441.92,238.65,441.66,238.53z"
          />
          <path
            fill="#FFFFFF"
            d="M521.4,212c0.13-0.01,0.2-0.02,0.2-0.02s-0.05-13.15,10.32-15.95c0,0-1.24-0.04-3.05-0.06
			c-1.65-0.02-3.77-0.02-5.86,0.04c-0.3,0.01-0.59,0.02-0.89,0.03c-1.38,0.05-2.71,0.14-3.86,0.26c-0.34,0.04-0.65,0.09-0.95,0.14
			c-0.53,0.1-0.99,0.23-1.38,0.39c-2.36,0.95-2.6,2.91-2.6,5.64c0,2.73-0.68,7.14,1.38,8.89c0.55,0.47,1.29,0.75,2.29,0.76
			C519.03,212.16,520.82,212.04,521.4,212z"
          />
          <path
            fill="#111413"
            d="M438.97,201.14c2.63,2.28,2.67,4.57,4.32,2.77c0.01-0.01,0.02-0.02,0.02-0.03c0,0-2.62-3.28-7.06-4.76
			c-0.45-0.16-0.92-0.3-1.27-0.4C435.75,198.98,437.2,199.61,438.97,201.14z"
          />
          <path fill="#111413" d="M434.98,198.72c-0.38-0.13-0.6-0.16-0.6-0.16S434.63,198.62,434.98,198.72z" />
          <path
            fill="#141616"
            d="M530.13,226.75c-1.35-0.68-1.8-1.09-0.68-1.37c0.49-0.13-1.01,0.04-2.64,0.04s-2.04-0.24-2.04-0.24
			s-0.02,0.09,0.41,0.33c0.86,0.48,0.57,0.84-0.16,1.77c-0.49,0.63,0.77-0.63,4.05,0.24C532.35,228.39,531.48,227.42,530.13,226.75z
			"
          />
          <path
            fill="#111413"
            d="M452.11,248.17c-0.04-0.03-0.06-0.04-0.06-0.04C452.07,248.15,452.09,248.16,452.11,248.17z"
          />
          <path
            fill="#111413"
            d="M459.46,255.17c-1.15-7.55-3.22-3.95-7.35-7c0.45,0.33,3.48,2.67,5.32,7.75c0.29,0.8,0.55,1.66,0.77,2.59
			c1.17,5.06,2.14,8.49,3.12,11.51c0.46,1.42,0.93,2.76,1.42,4.13C460.33,263.79,460.41,261.4,459.46,255.17z"
          />
          <path
            fill="#42637C"
            d="M432.14,215.3c1.52,1.03,1.34,1.22,2.19,0.06c0.85-1.16,1.46-1.22,1.46-1.22s0.42-1.8-2.25-3.69
			c-2.68-1.89-4.61-1.02-4.61-1.02c0.43,0.41,0.45,0.96,0.3,1.51c-0.12,0.43-0.33,0.85-0.53,1.2c-0.49,0.86-0.4,1.61-0.25,1.88
			C428.94,213.76,430.62,214.27,432.14,215.3z"
          />
          <path
            fill="#664033"
            d="M489.33,297.72c5.7-2.14,9.11-5.71,11.73-8.45l0.44,0.78c-2.84-8.44-9.66-15.15-11.77-17.09l-0.66,0.37
			c-1.62,1.4-2.3,2.9-1.83,5.38c-1.09-1.92-1.73-3.01-1.73-3.01l0.03-0.02c-3.28-5.42-16.79-21.25-24.79-33.4
			c-0.62-1.13-1.2-2.31-1.64-3.97c-1.45-5.56-3.03-6.15-5.26-8.69c-1.33-1.53-2.06-2.37-3.4-5.71c-0.32-0.8-0.59-1.58-0.84-2.27
			c-0.11,0.1-0.85,0.74-1.86,0.6c-0.27-1.25-0.73-2.31-0.56-2.97c-0.27,0.25-0.46,0.68-0.55,1.25c-0.26,1.55,0.18,4.11,1.49,6.68
			c1.98,3.86-0.33,5.53,1.57,10.67c0.12,0.34,0.25,0.64,0.39,0.94c0.24,0.44,0.54,0.9,0.92,1.43c1.34,1.83-1.22,0.24-1.7,1.83
			c-0.24,0.79-0.24,0.79-0.2,0.21c-0.25,1.8-0.67,3.13-1.26,3.98c1.98,1.45,3.75,1.32,5.91,3.62c0.08,0.09,0.17,0.16,0.27,0.23
			c1.01,1.31,2.07,2.44,3.39,5.82c-1.84-5.08-4.87-7.42-5.32-7.75c-0.02-0.01-0.04-0.03-0.06-0.04c0,0,0.02,0.01,0.06,0.04
			c4.13,3.05,6.2-0.55,7.35,7c0.95,6.23,0.87,8.62,3.27,18.98c-0.49-1.37-0.96-2.7-1.42-4.13c3.59,15.71,13.3,26.51,20.46,28.62
			c2.58,1.52,6.02,0.7,7.52-0.61c0.06-0.05,0.11-0.1,0.16-0.15L489.33,297.72z"
          />
          <path
            opacity="0.3"
            d="M597.42,303.13c-6.53-8.66-9.99-14.92-9.99-14.92c0.05,0.54,0.13,1.04,0.21,1.53
			c1.03,2.24,2.41,4.79,4.3,7.74c6.14,9.57,7.04,13.55,5.42,19.87c-0.14,0.55-0.27,1.07-0.39,1.59c-0.01,0.07-0.03,0.14-0.04,0.2
			c1.75-0.18,3.32-0.43,4.73-0.71C602.1,316.04,602.51,309.88,597.42,303.13z"
          />
          <path
            opacity="0.3"
            d="M547.71,342.37c-7.75-9.8-11.16-15.95-17.09-33.26c-5.92-17.32-14.13-19.82-11.39-20.05
			c2.73-0.23,11.64,11.51,6.43,4.57c-4.01-5.35-6.89-9.59-12.81-6.4c-3.92,2.11-7.09,2.89-8.79,3.17c0.76,2.35,0.82,4.14-0.16,4.66
			l8.87-5.51c17.01,16.22,20.24,68.57,20.24,68.57l2.44,0.07l12.48,0.35c-0.1-0.1-0.2-0.2-0.29-0.3
			C541.99,352.16,555.34,352.01,547.71,342.37z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text
          transform="matrix(1 0 0 1 244.1748 116.5576)"
          fontFamily={font.condensed}
          fontSize="68.3887px"
          letterSpacing="-1"
        >
          getan
        </text>
        <text transform="matrix(1 0 0 1 43.7197 175.3672)">
          <tspan x="10" y="0" fontFamily={font.serif} fontSize="13px">
            Gegenüber 2011 hat sich die Zahl weiblicher
          </tspan>
          <tspan x="26.55" y="19" fontFamily={font.serif} fontSize="13px">
            Führungskräfte in der BMW AG mehr als
          </tspan>
          <tspan x="166.71" y="38" fontFamily={font.condensed} fontSize="15.8124px">
            VERDOPPELT.
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
