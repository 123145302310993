import { bpTypes } from "#shared/theme";

export const HEADER_HEIGHT = {
  [bpTypes.i]: 70,
  [bpTypes.m]: 90,
};

export const HERO_SVG_POSITIONS = {
  [bpTypes.l]: {
    textRight: -880,
    textLeft: -728,
  },
  [bpTypes.m]: {
    textRight: -298,
    textLeft: -168,
  },
  [bpTypes.i]: {
    textRight: -135,
    textLeft: -365,
  },
};
