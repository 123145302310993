import { useTranslation } from "#hooks";
import { bp, Color, font, IconKeys } from "#shared/theme";
import { ButtonCircle, Icon, WindowHeightContainer, Wrapper } from "#ui";
import htmr from "htmr";
import React from "react";
import styled from "styled-components/macro";
import { Link } from "#ui";
import { useTour } from "#providers";
import { createAnalyticsEventHandler, wrapCallbackWithAnalyticsEventHandler } from "#helpers";
import { AnalyticsEventTypes } from "#interfaces";

const Container = styled(WindowHeightContainer)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  position: sticky;
  top: 50%;
  width: fit-content;
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: fit-content;
  min-width: 280px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;
const Headline = styled.h3`
  font-size: 28px;
  line-height: 28px;
  font-weight: normal;
  color: ${Color.Black};
  @media ${bp.m} {
    font-size: 38px;
    line-height: 41px;
  }
`;
const Subheadline = styled.div`
  font-size: 22px;
  line-height: 28px;
  margin-top: 1em;
  @media ${bp.m} {
    font-size: 31px;
    line-height: 41px;
  }
`;
const Buttons = styled.ul`
  margin: 45px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
`;
const ButtonsItem = styled.li`
  display: flex;
  position: relative;
  & + & {
    margin-left: 30px;
  }
`;
const ButtonLabel = styled.span`
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  margin-right: 30px;
  text-align: right;
  font-family: ${font.condensed};
  color: ${Color.Green};
  font-size: 15px;
  line-height: 17px;
  strong {
    text-transform: uppercase;
    display: block;
  }
`;

export const GuidedTour: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation("common");
  const { t: tMenu } = useTranslation("menu");
  const { t: tHome } = useTranslation("home");
  const { headline, subheadline } = tHome("guidedtour");
  const { set, play } = useTour();
  const { list: tourMenuList } = tMenu("tour");
  const tourList = tourMenuList[0].list;
  const startAutoplay = () => {
    set(0);
    play();
  };
  return (
    <Wrapper id={id}>
      <Container>
        <Content>
          <Inner>
            <Headline>{headline}</Headline>
            <Subheadline>{htmr(subheadline)}</Subheadline>
            <Buttons>
              <ButtonsItem>
                <ButtonLabel>{htmr(t("autoplay"))}</ButtonLabel>
                <Link to={tourList[0].to}>
                  <ButtonCircle
                    onClick={wrapCallbackWithAnalyticsEventHandler(
                      startAutoplay,
                      AnalyticsEventTypes.IntroTourStartAutoplay
                    )}
                  >
                    <Icon iconKey={IconKeys.SvgPlay} />
                  </ButtonCircle>
                </Link>
              </ButtonsItem>
              <ButtonsItem>
                <Link to={tourList[0].to} onClick={createAnalyticsEventHandler(AnalyticsEventTypes.IntroTourStartGo)}>
                  <ButtonCircle>
                    <Icon iconKey={IconKeys.SvgArrowRight} />
                  </ButtonCircle>
                </Link>
              </ButtonsItem>
            </Buttons>
          </Inner>
        </Content>
      </Container>
    </Wrapper>
  );
};
