import { Color, IconType } from "#shared/theme";
import React, { MouseEvent } from "react";
import styled, { css } from "styled-components/macro";
import { Icon, IconSize } from "../Icon/Icon";
import { AnalyticsEventTypes } from "#interfaces";
import { triggerAnalyticsEvent } from "#helpers";

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  min-height: 20px;
`;

const LinkLabel = styled.span<{ dimmed?: boolean }>`
  margin-left: 5px;
  border-bottom: 2px solid ${Color.Green};
  font-size: 13px;
  line-height: 1.23;
  ${({ dimmed }) =>
    dimmed &&
    css`
      border-color: transparent;
      color: ${Color.GrayLight};
    `}
`;

export interface LinkIconSmallProps {
  href?: string;
  download?: boolean;
  dimmed?: boolean;
  onClick?: () => void;
  iconKey?: IconType;
  analyticsEvent?: AnalyticsEventTypes;
}

export const LinkIconSmall: React.FC<LinkIconSmallProps> = ({
  href,
  download,
  onClick,
  iconKey,
  children,
  dimmed,
  analyticsEvent,
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (analyticsEvent) {
      triggerAnalyticsEvent(analyticsEvent);
    }

    if (!onClick) return;

    e.preventDefault();
    onClick();
  };
  return (
    <StyledLink onClick={handleClick} download={download} href={href}>
      {iconKey && <Icon size={IconSize.Small} iconKey={iconKey} />}
      <LinkLabel dimmed={dimmed}>{children}</LinkLabel>
    </StyledLink>
  );
};
