import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card21: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect fill="#FFFFFF" width="641" height="341" />
        <rect x="477" y="58.22" fill="#659896" width="164" height="282.78" />
        <rect x="284.5" y="133.5" width="356.5" height="8" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="scribble_2_">
          <path
            fill="#27BAE1"
            d="M280.56,191.2l-0.95,0.08l-0.48,0.05l-0.24,0.03c-0.04,0.01-1.48,0.08-1.54,0.09l0.09,0.33l0.02,0.53
			c0.05-0.01,1.45-0.28,1.54-0.3l0.23-0.05l0.47-0.09l0.94-0.17l1.88-0.33l3.77-0.63l-3.81,0.29L280.56,191.2z"
          />
          <path
            fill="#27BAE1"
            d="M206.36,176.02c-0.1,0.35-0.22,0.71-0.35,1.02l-0.03,0.06l-0.01,0.04c-0.33,1.22-0.77,2.47-1.23,3.69
			c-0.46,1.22-0.96,2.43-1.52,3.59c-0.29,0.58-0.58,1.14-0.94,1.65c-0.36,0.53-0.74,1-1.2,1.36c-0.9,0.73-2.18,0.9-3.47,0.94
			l-7.97,0.08l-1.99,0.02l-0.25,0c-0.07,0-0.11-0.01-0.17-0.02c-0.05-0.01-0.11-0.04-0.17-0.05c-0.06-0.02-0.11-0.06-0.17-0.09
			c-0.23-0.14-0.45-0.34-0.66-0.57c-0.81-0.94-1.41-2.13-1.99-3.3l-0.1-0.2l-0.22,0.01c-0.61,0.04-1.19,0.11-1.79,0.23
			c-0.15,0.03-0.3,0.07-0.45,0.11c-0.03,0.01-0.08,0.03-0.14,0.05c-0.06,0.02-0.1,0.05-0.14,0.07c-0.09,0.05-0.16,0.11-0.22,0.17
			c-0.25,0.24-0.42,0.49-0.59,0.73c-0.34,0.48-0.65,0.95-1.03,1.36c-0.38,0.4-0.81,0.74-1.31,0.92c-0.25,0.09-0.51,0.15-0.78,0.17
			l-0.2,0.01l-0.22,0l-0.44,0c-0.58,0-1.17,0-1.75-0.01c-1.16-0.03-2.32-0.1-3.46-0.29c-0.57-0.1-1.13-0.22-1.67-0.41
			c-0.54-0.18-1.07-0.41-1.52-0.74c-0.45-0.32-0.83-0.76-1.1-1.25c-0.28-0.49-0.46-1.03-0.59-1.59c-0.13-0.56-0.22-1.13-0.29-1.7
			l-0.23-1.72c-0.14-1.15-0.21-2.31-0.25-3.46l0-0.12l-0.1,0.06c-0.5,0.31-1.01,0.62-1.52,0.9c-0.52,0.28-1.04,0.56-1.6,0.74
			c-0.28,0.09-0.58,0.05-0.87,0.05c-0.3-0.01-0.59-0.03-0.88-0.06c-0.58-0.07-1.11-0.14-1.72-0.39c0.55,0.22,1.13,0.35,1.72,0.43
			c0.29,0.04,0.59,0.06,0.89,0.08c0.15,0.01,0.3,0.01,0.44,0.02c0.15,0.01,0.31-0.01,0.45-0.05c0.58-0.17,1.1-0.44,1.63-0.71
			c0.49-0.26,0.97-0.53,1.44-0.81c0.02,1.12,0.07,2.25,0.18,3.37l0.2,1.73c0.06,0.58,0.14,1.16,0.26,1.73
			c0.13,0.57,0.31,1.15,0.59,1.68c0.28,0.53,0.68,1.01,1.16,1.37c0.49,0.36,1.04,0.61,1.6,0.81c0.56,0.2,1.14,0.35,1.72,0.46
			c1.17,0.22,2.35,0.31,3.52,0.36c0.59,0.02,1.18,0.04,1.76,0.04l0.44,0l0.22,0l0.24-0.01c0.32-0.02,0.63-0.08,0.93-0.19
			c0.6-0.21,1.12-0.6,1.55-1.04c0.43-0.44,0.77-0.93,1.11-1.4c0.17-0.23,0.34-0.46,0.52-0.62c0.04-0.04,0.09-0.07,0.13-0.09
			c0.02-0.01,0.04-0.02,0.05-0.03c0.01,0,0.03-0.01,0.07-0.02c0.13-0.03,0.27-0.07,0.41-0.09c0.48-0.09,0.98-0.14,1.47-0.17
			c0.54,1.13,1.09,2.27,1.94,3.3c0.23,0.27,0.49,0.53,0.83,0.74c0.09,0.05,0.17,0.1,0.27,0.14c0.1,0.03,0.2,0.07,0.31,0.1
			c0.11,0.02,0.23,0.03,0.33,0.04l0.25,0l1.99,0.02l7.99,0.07c0.68-0.01,1.38-0.05,2.08-0.2c0.7-0.14,1.42-0.43,2.01-0.88
			c0.6-0.45,1.06-1.01,1.46-1.57c0.41-0.57,0.75-1.17,1.05-1.77c0.61-1.2,1.14-2.43,1.64-3.66c0.48-1.23,0.94-2.44,1.32-3.74
			c0.18-0.39,0.3-0.76,0.42-1.15c0.12-0.4,0.22-0.82,0.27-1.25c0.05-0.43,0.08-0.87,0.03-1.33c-0.01-0.05-0.02-0.1-0.02-0.15
			l0.32-0.06l0.74-0.14l2.96-0.56c1.97-0.37,3.95-0.72,5.93-1.07c3.95-0.71,7.91-1.38,11.88-2l2.98-0.45
			c0.91-0.14,1.82-0.26,2.77-0.44l0,0.04c2.44,0.17,4.88,0.37,7.3,0.65c0.3,0.03,0.6,0.07,0.9,0.11l0.45,0.06l0.22,0.03l0.2,0.04
			c0.52,0.11,1.02,0.33,1.49,0.63c0.46,0.3,0.88,0.68,1.26,1.11c0.27,0.31,0.53,0.65,0.75,1c-2.62,1.77-5.33,3.45-8.12,4.94
			c-0.73,0.39-1.48,0.77-2.23,1.11c-0.74,0.33-1.52,0.6-2.31,0.83c-1.59,0.45-3.24,0.72-4.89,0.91c-1.66,0.19-3.33,0.28-5,0.29
			c-1.67,0.01-3.34-0.05-5-0.26c-1.65-0.2-3.23-0.78-4.74-1.48c-0.75-0.36-1.49-0.75-2.22-1.16c-0.36-0.21-0.72-0.42-1.08-0.64
			l-0.53-0.33c-0.17-0.1-0.31-0.2-0.5-0.29l1.61-3.08l-1.74,3.08l-0.03,0.05l0.06,0.03c0.35,0.18,0.7,0.44,1.05,0.66
			c0.35,0.23,0.71,0.45,1.07,0.67c0.72,0.43,1.45,0.84,2.2,1.22c1.5,0.74,3.1,1.37,4.79,1.62c1.67,0.25,3.36,0.35,5.04,0.38
			c1.69,0.03,3.37-0.03,5.06-0.18c1.68-0.15,3.36-0.39,5.01-0.82c0.82-0.21,1.64-0.47,2.44-0.81c0.78-0.34,1.54-0.7,2.29-1.08
			c3.01-1.52,5.9-3.24,8.73-5.07l0.38-0.24l-0.2-0.41c-0.16-0.32-0.32-0.58-0.5-0.86c-0.18-0.27-0.37-0.53-0.58-0.78
			c-0.42-0.5-0.9-0.96-1.45-1.34c-0.55-0.38-1.18-0.68-1.86-0.84l-0.25-0.05l-0.23-0.04l-0.46-0.08c-0.31-0.05-0.61-0.1-0.92-0.14
			c-1.36-0.19-2.73-0.35-4.09-0.49l2.69-0.95c1.9-0.67,3.78-1.38,5.67-1.97c1.9-0.6,3.83-1.12,5.78-1.6
			c3.89-0.97,7.83-1.73,11.77-2.54c1.97-0.4,3.94-0.79,5.9-1.29c0.98-0.25,1.95-0.52,2.9-0.85c0.47-0.17,0.95-0.35,1.41-0.56
			c0.23-0.1,0.45-0.22,0.67-0.34c0.21-0.13,0.44-0.26,0.6-0.45c-0.17,0.18-0.4,0.3-0.62,0.41c-0.22,0.11-0.45,0.21-0.69,0.3
			c-0.46,0.19-0.95,0.33-1.42,0.48c-0.96,0.28-1.94,0.51-2.92,0.71c-1.96,0.4-3.95,0.72-5.93,1.05c-3.97,0.64-7.94,1.31-11.89,2.17
			c-1.97,0.44-3.93,0.93-5.88,1.5c-1.95,0.57-3.85,1.23-5.75,1.88l-2.85,0.97c-0.89,0.29-1.77,0.58-2.65,0.77
			c-0.02,0-0.03,0-0.05-0.01l0,0.02c-0.04,0.01-0.08,0.02-0.12,0.03c-0.93,0.19-1.94,0.29-2.94,0.44c-1,0.13-1.99,0.28-2.99,0.43
			c-3.98,0.6-7.96,1.25-11.92,1.95c-1.98,0.36-3.96,0.72-5.94,1.1l-2.96,0.57l-0.74,0.15l-0.37,0.07l-0.14,0.03l-0.1-0.16
			c-0.15-0.23-0.32-0.45-0.5-0.66c-0.36-0.42-0.79-0.82-1.31-1.12c-0.26-0.15-0.55-0.27-0.86-0.35c-0.3-0.08-0.66-0.1-0.88-0.1
			c-1.01-0.04-2.02-0.07-3.03-0.09c-2.02-0.05-4.03-0.07-6.05-0.06c-1.01,0.01-2.02,0.02-3.03,0.06c-1.01,0.05-2.02,0.08-3.06,0.22
			c-1.03,0.14-2.06,0.39-3.04,0.8c-0.49,0.21-0.96,0.45-1.41,0.74c-0.22,0.15-0.44,0.31-0.65,0.49c-0.05,0.05-0.1,0.09-0.16,0.14
			l-0.1,0.1c-0.02,0.01-0.04,0.03-0.07,0.03l-1.24-0.04l-0.03,0l-0.01,0c-0.07,0.01-0.13-0.03-0.16-0.08c0,0.01-0.05-0.16-0.09-0.28
			c-0.04-0.13-0.09-0.25-0.14-0.37c-0.22-0.49-0.56-0.98-1.1-1.27c-0.26-0.15-0.59-0.21-0.82-0.24c-0.25-0.03-0.5-0.06-0.74-0.08
			c-0.49-0.04-0.97-0.06-1.45-0.09c-1.93-0.09-3.86-0.1-5.79-0.08c-0.48,0.01-0.97,0.02-1.45,0.04c-0.48,0.01-1,0.07-1.48,0.23
			c-0.48,0.15-0.93,0.38-1.34,0.67c-0.2,0.14-0.4,0.3-0.58,0.47c-0.09,0.09-0.18,0.17-0.26,0.26c-0.09,0.1-0.14,0.17-0.22,0.21
			c-0.17,0.08-0.36,0.07-0.54-0.06c-0.21-0.13-0.41-0.26-0.62-0.38c-0.42-0.25-0.85-0.46-1.29-0.66c-0.81-0.36-1.66-0.66-2.54-0.83
			c0.12-0.16,0.25-0.32,0.39-0.47c0.92-0.96,2.05-1.73,3.24-2.38c1.19-0.65,2.44-1.18,3.71-1.66c2.54-0.97,5.17-1.71,7.82-2.37
			c2.65-0.67,5.32-1.23,8.01-1.74c5.37-1.03,10.79-1.85,16.22-2.59c1.36-0.19,2.72-0.36,4.07-0.54l2.04-0.26l1.02-0.13
			c0.36-0.05,0.73-0.11,1.09-0.2c0.72-0.18,1.42-0.45,2.07-0.79c0.65-0.34,1.25-0.76,1.82-1.19c0.56-0.43,1.09-0.9,1.6-1.35
			l1.54-1.36c2.04-1.81,4.11-3.59,6.23-5.28c2.13-1.68,4.32-3.29,6.61-4.75c2.28-1.46,4.66-2.78,7.13-3.88
			c1.23-0.55,2.5-1.03,3.79-1.46c1.28-0.44,2.59-0.81,3.91-1.16c2.63-0.69,5.31-1.24,8.01-1.7c2.7-0.46,5.41-0.81,8.13-1.12
			c2.72-0.31,5.45-0.54,8.19-0.73c2.74-0.18,5.48-0.33,8.22-0.45c-5.49-0.03-10.98,0.15-16.47,0.58c-2.74,0.21-5.48,0.52-8.2,0.91
			c-0.39,0.06-0.78,0.12-1.16,0.18c-0.66,0.08-1.32,0.16-1.98,0.25c-0.93,0.14-1.87,0.27-2.79,0.44c-0.56,0.09-1.11,0.19-1.67,0.29
			c0.17-0.08,0.34-0.17,0.52-0.25l8.73-4.18l4.38-2.08c1.45-0.71,2.93-1.38,4.37-2.13c-3.11,0.93-6.17,2.02-9.18,3.24
			c-3.02,1.19-5.98,2.53-8.9,3.93c-1.65,0.8-3.28,1.63-4.9,2.49c-1.47,0.33-2.94,0.66-4.4,1.03c-1.83,0.45-3.64,0.95-5.46,1.45
			c-0.91,0.25-1.81,0.53-2.71,0.8c-0.9,0.27-1.8,0.54-2.7,0.83c-1.79,0.57-3.57,1.18-5.35,1.79c-1.77,0.65-3.53,1.29-5.28,2
			c1.84-0.41,3.66-0.89,5.48-1.35l2.73-0.69l2.72-0.71c1.82-0.44,3.63-0.95,5.45-1.4c1.58-0.4,3.16-0.82,4.75-1.2
			c-2.07,0.96-4.07,2.07-6.01,3.27c-2.35,1.46-4.6,3.06-6.76,4.77c-2.16,1.71-4.25,3.49-6.31,5.3c-2.09,1.77-3.96,3.82-6.45,4.39
			c-0.31,0.08-0.62,0.13-0.93,0.18l-1.02,0.13l-2.04,0.26c-1.36,0.19-2.72,0.37-4.08,0.56c-2.72,0.38-5.44,0.82-8.15,1.28
			c-2.71,0.46-5.42,0.96-8.11,1.53c-5.38,1.15-10.75,2.46-15.87,4.55c-1.28,0.53-2.53,1.13-3.72,1.85c-1.19,0.71-2.32,1.55-3.25,2.6
			c-0.92,1.08-1.7,2.68-2.01,4.01c-0.3,1.34-0.58,3.96-0.02,4.22c-0.02-1.36,0.11-3.15,0.47-4.45c0.3-1.09,0.77-2.15,1.45-3.04
			c0.86,0.22,1.69,0.55,2.48,0.96c0.42,0.22,0.84,0.46,1.24,0.72c0.2,0.12,0.4,0.27,0.59,0.4c0.2,0.17,0.55,0.24,0.82,0.11
			c0.07-0.03,0.13-0.07,0.19-0.13l0.13-0.13c0.08-0.08,0.17-0.16,0.26-0.24c0.18-0.15,0.36-0.29,0.56-0.41
			c0.39-0.25,0.81-0.44,1.26-0.56c0.45-0.12,0.89-0.16,1.37-0.16c0.48,0,0.96,0,1.44,0.01c1.92,0.04,3.84,0.14,5.75,0.28
			c0.48,0.04,0.95,0.07,1.42,0.13c0.24,0.02,0.47,0.06,0.69,0.09c0.24,0.04,0.39,0.08,0.55,0.17c0.31,0.17,0.54,0.51,0.7,0.89
			c0.04,0.1,0.07,0.2,0.1,0.3c0.04,0.11,0.03,0.17,0.13,0.4c0.17,0.38,0.59,0.63,1.01,0.61l-0.04,0l1.28,0.08l0.02,0l0.03,0
			c0.22-0.01,0.44-0.08,0.62-0.22l0.3-0.26c0.17-0.14,0.36-0.28,0.56-0.4c0.39-0.25,0.82-0.45,1.26-0.63
			c0.44-0.17,0.9-0.31,1.37-0.42c0.47-0.1,0.94-0.2,1.42-0.24c0.95-0.11,1.96-0.12,2.96-0.15c1-0.02,2-0.02,3-0.01
			c2,0.01,4.01,0.06,6.01,0.13c1,0.04,2.01,0.08,3,0.13c0.28,0.01,0.43,0.02,0.61,0.07c0.18,0.05,0.36,0.12,0.53,0.22
			c0.35,0.2,0.67,0.49,0.95,0.83c0.14,0.17,0.28,0.35,0.4,0.54l0.09,0.14l-0.02,0.01c0.14,0.24,0.23,0.53,0.27,0.85
			c0.04,0.32,0.03,0.67-0.01,1.01C206.53,175.31,206.45,175.67,206.36,176.02z"
          />
          <path
            fill="#27BAE1"
            d="M251.86,205.84c-0.12,4.49,0.77,8.75,2.52,11.99c1.81,3.36,4.33,5.26,7.09,5.33c0.06,0,0.11,0,0.17,0
			c2.7,0,5.25-1.75,7.2-4.94c1.92-3.14,3.04-7.35,3.16-11.84c0.12-4.49-0.77-8.75-2.52-11.99c-1.81-3.36-4.33-5.26-7.09-5.33
			c-2.76-0.07-5.38,1.68-7.37,4.94C253.11,197.14,251.98,201.35,251.86,205.84z M254.26,198.12l0.62,4.28l-1.16,0.06l1.05,0.73
			l-1.84,3.17c0-0.16,0-0.32,0.01-0.49C253.01,203.1,253.47,200.46,254.26,198.12z M255.36,217.54c-0.03-0.06-0.07-0.13-0.1-0.19
			l1.63,0.35l-0.36,1.24l0.83-0.65l0.91,2.82C257.19,220.3,256.2,219.1,255.36,217.54z M265.58,220.69l0.98-3.56l0.99,0.6
			l-0.55-1.28l2.13-1.03C268.2,217.67,266.98,219.49,265.58,220.69z M269.63,201.45l0.32-3.15c0.54,1.84,0.86,3.85,0.96,5.96
			l-1.15-2.09l0.84-0.6L269.63,201.45z M262.37,189.91c0.98,0.03,1.93,0.34,2.82,0.92l-2.39,2.01l-0.43-2.06l-0.44,2.13l-2.76-2.04
			c0.98-0.64,2.02-0.97,3.07-0.97C262.28,189.91,262.33,189.91,262.37,189.91z M258.04,191.77l4.35,3.22l3.9-3.28
			c0.81,0.77,1.55,1.76,2.2,2.97c0.13,0.23,0.25,0.47,0.36,0.72l-0.69,6.75l2.73,4.96c-0.12,2.37-0.53,4.59-1.15,6.57l-0.04-0.08
			l-4.17,2.02l-1.71,6.21c-0.71,0.32-1.45,0.49-2.2,0.49c-0.05,0-0.09,0-0.14,0c-0.5-0.01-0.99-0.11-1.47-0.27l-1.76-5.47l-3.72-0.8
			c-0.81-2.03-1.33-4.35-1.53-6.82l3.37-5.81l-1.09-7.53c0.22-0.44,0.45-0.86,0.7-1.27C256.62,193.31,257.31,192.46,258.04,191.77z"
          />
          <path
            fill="#27BAE1"
            d="M299.97,231.07l-4.84-0.5l-9.68-0.99c-6.21-0.61-12.42-1.22-18.63-1.8c2.53-0.45,5.07-0.9,7.6-1.38
			c5.76-1.07,11.52-2.18,17.26-3.43c-5.83,0.68-11.64,1.5-17.43,2.37c-2.48,0.36-4.95,0.76-7.43,1.14c1.36-1.07,2.61-2.53,3.72-4.35
			c2.47-4.05,3.94-9.43,4.15-15.2l1.12-0.41l-1.09-0.35c0.1-5.78-1.07-11.25-3.32-15.44c-2.36-4.39-5.64-6.86-9.23-6.96
			c-0.22-0.01-0.44,0-0.65,0.01l0-0.05l-8.92,0.14c1.05-1.29,2.48-2.21,4.04-2.86c1.62-0.67,3.37-1.05,5.13-1.34
			c0.89-0.11,1.78-0.25,2.68-0.31c0.45-0.04,0.9-0.08,1.34-0.11l0.65-0.02c0.22,0,0.43,0.02,0.65,0.02
			c0.86,0.06,1.71,0.23,2.52,0.53c0.8,0.3,1.55,0.74,2.22,1.29c1.35,1.09,2.35,2.59,3.09,4.2c0.75,1.61,1.24,3.35,1.6,5.11
			c0.18,0.88,0.33,1.77,0.44,2.66c0.1,0.89,0.18,1.79,0.23,2.69c0.1,1.8,0.1,3.61-0.02,5.42c-0.25,3.6-0.97,7.19-2.32,10.56
			l-0.1,0.24l0.25-0.05l9.13-1.72l-8.93,1.41c1.38-3.3,2.18-6.83,2.51-10.39c0.17-1.82,0.22-3.66,0.16-5.49
			c-0.02-0.92-0.1-1.83-0.19-2.75c-0.1-0.92-0.24-1.83-0.41-2.73c-0.34-1.81-0.82-3.6-1.58-5.31c-0.75-1.7-1.79-3.33-3.27-4.56
			c-0.73-0.61-1.56-1.12-2.46-1.47c-0.89-0.36-1.84-0.56-2.79-0.63c-0.24-0.01-0.48-0.03-0.71-0.03l-0.71,0.01
			c-0.46,0.03-0.92,0.06-1.38,0.09c-0.92,0.05-1.83,0.18-2.75,0.28c-1.83,0.27-3.66,0.64-5.42,1.33l-0.66,0.27
			c-0.22,0.09-0.42,0.22-0.64,0.33c-0.21,0.11-0.42,0.22-0.63,0.34l-0.6,0.4l-0.3,0.2c-0.09,0.07-0.19,0.15-0.28,0.23l-0.55,0.47
			c-0.18,0.16-0.34,0.34-0.51,0.51l-0.25,0.26c-0.08,0.09-0.15,0.19-0.23,0.28l-0.45,0.56c-0.14,0.19-0.27,0.39-0.4,0.59l-0.2,0.3
			c-0.06,0.1-0.12,0.2-0.18,0.31l-0.35,0.61c-0.43,0.83-0.83,1.66-1.2,2.5l-0.28,0.63l-0.26,0.65c-0.18,0.43-0.34,0.86-0.5,1.29
			c-0.33,0.86-0.61,1.73-0.91,2.6c-1.15,3.47-2.18,6.97-3.18,10.47c-0.95,3.35-1.87,6.7-2.76,10.06c-2.13,0.14-4.27,0.25-6.41,0.34
			c-2.3,0.1-4.6,0.17-6.89,0.19c-1.15,0.01-2.29,0-3.43-0.03c-1.14-0.04-2.28-0.11-3.43-0.19c-2.29-0.15-4.59-0.35-6.89-0.54
			c-9.19-0.77-18.37-1.66-27.56-2.48l-6.89-0.61c-2.3-0.2-4.6-0.38-6.88-0.64c-2.28-0.26-4.56-0.58-6.8-1.04
			c-1.12-0.23-2.23-0.5-3.32-0.84c-1.08-0.34-2.15-0.74-3.12-1.29c-0.48-0.27-0.94-0.59-1.32-0.96c-0.38-0.37-0.7-0.81-0.86-1.3
			c-0.08-0.24-0.13-0.5-0.14-0.76c-0.01-0.27-0.01-0.57-0.01-0.85l-0.02-1.73l-0.08-6.92l-0.08-6.92c-0.03-1.15,0.02-2.3,0.24-3.43
			c0.21-1.13,0.54-2.25,1.08-3.26c-0.58,1-0.94,2.11-1.19,3.24c-0.25,1.13-0.34,2.3-0.35,3.45l-0.08,6.92l-0.08,6.92l-0.02,1.73
			c0,0.29-0.01,0.57-0.01,0.87c0,0.32,0.06,0.63,0.15,0.94c0.18,0.61,0.56,1.14,0.99,1.58c0.43,0.44,0.93,0.79,1.44,1.1
			c1.03,0.6,2.13,1.03,3.24,1.4c0.6,0.2,1.21,0.37,1.81,0.53c1.35,5.59,3.31,8.85,6.42,10.41c-0.41-0.03-0.82-0.06-1.23-0.09
			l-3.48-0.21l-4.91-0.3l-5.27-0.18l4.84,0.52l4.84,0.5l3.93,0.34c4.1,0.41,8.2,0.8,12.3,1.2c-1.4,0.14-2.79,0.29-4.19,0.46
			c-1.9,0.21-3.79,0.45-5.69,0.7c-1.89,0.27-3.79,0.52-5.67,0.87c3.82-0.1,7.64-0.26,11.45-0.5c1.91-0.1,3.81-0.26,5.71-0.38
			l5.62-0.45c6.4,0.61,12.8,1.18,19.19,1.77l19.37,1.71l18.22,1.54c0.21,0.04,0.43,0.08,0.65,0.11l10.31,0.94l-8.2,1.41
			c-2.88,0.52-5.77,1.02-8.65,1.57c-2.88,0.53-5.76,1.09-8.64,1.66c-2.87,0.59-5.75,1.15-8.61,1.83c5.82-0.74,11.63-1.55,17.42-2.43
			c2.9-0.42,5.79-0.9,8.69-1.34l8.68-1.42l5.47-0.94c1.14,0.09,2.28,0.18,3.42,0.27c6.46,0.51,12.93,1,19.39,1.47l9.7,0.68
			l4.85,0.34l4.85,0.31L299.97,231.07z M262.14,184.91c6.57,0.18,11.65,9.87,11.33,21.59c-0.16,5.75-1.58,11.11-4.01,15.09
			c-2.35,3.86-5.35,5.95-8.46,5.86l-0.79-0.07c-6.18-0.89-10.85-10.26-10.55-21.52c0.16-5.75,1.58-11.11,4.01-15.09
			c2.31-3.79,5.25-5.86,8.29-5.86C262.02,184.91,262.08,184.91,262.14,184.91z M241.96,215.1l0.13-0.48
			c1.85-7.02,3.78-14.05,5.97-20.95c0.57-1.72,1.14-3.42,1.87-5.06c0.36-0.83,0.74-1.64,1.14-2.43l0.32-0.57
			c0.05-0.09,0.11-0.19,0.16-0.29l0.03-0.05l5.7-0.09c-1.73,1.09-3.33,2.79-4.71,5.04c-2.53,4.14-4.01,9.68-4.17,15.6
			c-0.16,5.92,1.02,11.53,3.32,15.8c1.19,2.21,2.62,3.94,4.19,5.12l-9.64-0.88c-4.48-0.65-6.84-3.53-8.51-10.51
			c1.23-0.07,2.46-0.14,3.7-0.23L241.96,215.1z M186.19,212.2c3.84,0.4,7.68,0.78,11.53,1.17c-2.36,4.65-5.74,7.36-9.17,7.26
			l-0.87-0.08c-3.47-0.55-6.58-3.85-8.49-9.07c0.04,0,0.09,0.01,0.13,0.01L186.19,212.2z M165.51,209.59c0.02,0,0.03,0.01,0.05,0.01
			c2.27,0.5,4.56,0.86,6.86,1.15c1.75,0.22,3.5,0.4,5.24,0.57c1.36,3.99,3.38,7.03,5.78,8.83l-9.51-0.89
			C169.56,218.63,167.15,215.89,165.51,209.59z M227.35,224.21l-19.38-1.64c-4.35-0.35-8.7-0.71-13.04-1.06
			c1.74-0.18,3.48-0.35,5.22-0.56c3.79-0.43,7.58-0.91,11.36-1.52c-3.83,0.04-7.64,0.22-11.45,0.45c-1.91,0.1-3.81,0.25-5.71,0.38
			l-0.57,0.05c2.08-1.45,3.96-3.77,5.41-6.79c0.26,0.03,0.51,0.05,0.77,0.08c4.59,0.45,9.18,0.91,13.78,1.31
			c2.3,0.2,4.6,0.4,6.91,0.56c1.16,0.08,2.31,0.16,3.48,0.2c1.16,0.04,2.33,0.05,3.48,0.04c2.93-0.02,5.85-0.11,8.77-0.26
			c1.04,4.49,2.48,8.01,5.21,10.03L227.35,224.21z"
          />
          <path
            fill="#27BAE1"
            d="M164.91,203.88c-0.26,0.18-0.58,0.31-0.9,0.43c-0.65,0.24-1.32,0.43-1.99,0.62
			c-0.65,0.21-1.35,0.2-2.02,0.02c-0.67-0.18-1.31-0.49-1.88-0.89c0.55,0.43,1.17,0.78,1.85,1c0.67,0.22,1.42,0.29,2.12,0.1
			c0.68-0.17,1.36-0.32,2.04-0.54c0.34-0.11,0.68-0.23,1-0.43c0.16-0.11,0.34-0.23,0.43-0.47c0.05-0.12,0.04-0.27,0.01-0.39
			c-0.04-0.12-0.1-0.21-0.17-0.3l-0.01-0.01l-0.03-0.02l-5.05-3.74l0,0l-0.02-0.01c-0.14-0.09-0.23-0.25-0.23-0.41l-0.01-0.79
			l-0.01-0.82c0-0.15,0.09-0.31,0.21-0.39c0.06-0.04,0.13-0.06,0.19-0.07c0.03,0,0.06,0,0.1,0.01l0.2,0.04
			c0.27,0.05,0.54,0.1,0.81,0.15c0.54,0.1,1.09,0.19,1.63,0.26c2.18,0.31,4.36,0.52,6.54,0.73c2.18,0.19,4.36,0.37,6.55,0.53
			c2.18,0.16,4.37,0.3,6.55,0.43c2.18,0.13,4.37,0.27,6.55,0.38l6.55,0.33l6.55,0.31l6.56,0.27l6.56,0.25l1.64,0.06l0.2,0.01
			c0.04,0,0.04,0,0.06,0.01c0.03,0.01,0.06,0.02,0.09,0.05c0.02,0.02,0.01,0.01,0.04,0.05l0.12,0.17l0.24,0.33l0.47,0.67l0.12,0.17
			l0.06,0.08c0.03,0.04,0.03,0.04,0.03,0.05c0.02,0.03,0.03,0.06,0.03,0.08c0.01,0.04-0.02,0.13-0.12,0.2
			c-0.02,0.02-0.05,0.04-0.08,0.05c-0.01,0.01-0.03,0.01-0.04,0.02l-0.02,0.01l-0.04,0.02l-0.38,0.14
			c-2.04,0.71-4.14,1.35-6.22,2.02c-1.04,0.34-2.08,0.67-3.13,1.03c-0.26,0.09-0.52,0.18-0.79,0.29l-0.21,0.08
			c-0.08,0.04-0.16,0.08-0.25,0.13c-0.04,0.03-0.1,0.07-0.14,0.11c-0.06,0.06-0.11,0.1-0.16,0.19c-0.05,0.07-0.1,0.2-0.11,0.32
			c-0.01,0.13,0.01,0.25,0.05,0.35c0.08,0.19,0.17,0.26,0.26,0.35c0.08,0.07,0.16,0.13,0.23,0.17c0.15,0.09,0.28,0.16,0.42,0.23
			c0.27,0.13,0.53,0.23,0.79,0.33l0.77,0.29l0.37,0.15c0.13,0.05,0.28,0.11,0.43,0.15c0.59,0.18,1.2,0.21,1.76,0.21
			c0.57-0.01,1.12-0.06,1.65-0.08c0.53-0.02,1.08-0.04,1.63-0.07l6.56-0.32c4.37-0.22,8.74-0.45,13.11-0.72
			c-4.38,0.01-8.75,0.06-13.12,0.12l-6.55,0.1c-0.55,0.01-1.09,0.02-1.65,0.02c-0.56,0.01-1.1,0.04-1.63,0.03
			c-0.52-0.01-1.02-0.06-1.48-0.21c-0.12-0.03-0.22-0.08-0.34-0.13l-0.39-0.16l-0.76-0.3c-0.25-0.1-0.49-0.2-0.72-0.32
			c-0.03-0.02-0.07-0.03-0.1-0.05c0.23-0.08,0.47-0.17,0.72-0.24c1.03-0.33,2.08-0.64,3.12-0.96c2.1-0.63,4.19-1.25,6.28-1.94
			l0.39-0.13l0.05-0.02l0.07-0.03c0.05-0.02,0.1-0.04,0.14-0.07c0.09-0.05,0.18-0.1,0.27-0.17c0.34-0.26,0.61-0.69,0.61-1.21
			c0-0.25-0.08-0.5-0.2-0.7c-0.07-0.12-0.1-0.15-0.14-0.21l-0.12-0.17l-0.47-0.67l-0.23-0.34l-0.12-0.17
			c-0.05-0.07-0.14-0.19-0.23-0.26c-0.18-0.16-0.39-0.28-0.62-0.34c-0.11-0.03-0.25-0.05-0.34-0.05l-0.2-0.01l-1.64-0.08l-6.55-0.28
			l-3.12-0.14l1.84-6.62c3.14-1.47,6.32-2.88,9.56-4.08c0.84-0.3,1.68-0.59,2.52-0.84c0.42-0.13,0.84-0.24,1.26-0.34
			c0.42-0.1,0.83-0.17,1.27-0.23c1.75-0.26,3.51-0.39,5.28-0.4c0.89-0.01,1.77,0.02,2.66,0.06c0.44,0.02,0.89,0.05,1.33,0.09
			c0.22,0.01,0.38,0.03,0.46,0.05c0,0.01,0.01,0.02,0.01,0.04c0.02,0.09,0.06,0.24,0.13,0.41c0.07,0.17,0.19,0.35,0.33,0.49
			c0.29,0.28,0.58,0.4,0.85,0.49c0.53,0.17,1.02,0.21,1.49,0.25c0.48,0.03,0.94,0.03,1.4,0.03l1.35-0.03
			c0.9-0.01,1.8-0.01,2.72-0.06c0.92-0.05,1.89-0.16,2.83-0.51c0.94-0.34,1.78-0.91,2.48-1.58c0.7-0.66,1.28-1.4,1.81-2.16
			c0.27-0.38,0.52-0.77,0.76-1.15c0.24-0.37,0.48-0.74,0.74-1.09c0.51-0.71,1.06-1.4,1.65-2.05c1.18-1.29,2.53-2.43,4.02-3.36
			c2.98-1.86,6.48-2.81,10.01-3.12c1.76-0.15,3.55-0.11,5.2,0.44c0.82,0.27,1.6,0.69,2.31,1.2c0.71,0.52,1.36,1.13,1.97,1.77
			c0.61,0.64,1.2,1.36,1.95,1.91c0.37,0.28,0.79,0.51,1.23,0.65c0.45,0.14,0.92,0.19,1.37,0.19c0.92-0.01,1.81-0.16,2.7-0.25
			c0.89-0.1,1.79-0.16,2.69-0.18c-0.9-0.02-1.8,0-2.7,0.06c-0.9,0.05-1.8,0.15-2.69,0.13c-0.44-0.01-0.88-0.08-1.28-0.22
			c-0.41-0.15-0.78-0.37-1.12-0.64c-0.69-0.54-1.24-1.24-1.84-1.93c-0.6-0.68-1.24-1.33-1.97-1.91c-0.72-0.57-1.54-1.05-2.42-1.37
			c-0.87-0.33-1.79-0.52-2.72-0.6c-0.92-0.09-1.84-0.08-2.76-0.03c-1.82,0.12-3.64,0.38-5.41,0.87c-1.77,0.49-3.49,1.21-5.08,2.15
			c-1.59,0.94-3.04,2.12-4.31,3.47c-0.64,0.67-1.23,1.38-1.77,2.13c-0.27,0.37-0.53,0.75-0.79,1.14c-0.25,0.37-0.49,0.74-0.75,1.09
			c-1.02,1.41-2.2,2.72-3.73,3.24c-0.76,0.27-1.6,0.36-2.47,0.4c-0.87,0.04-1.77,0.02-2.67,0.04c-0.92,0-1.8,0.06-2.65-0.01
			c-0.42-0.03-0.84-0.08-1.16-0.19c-0.16-0.05-0.29-0.12-0.32-0.16c-0.04-0.04-0.05-0.04-0.11-0.3c-0.03-0.12-0.09-0.34-0.25-0.55
			c-0.16-0.21-0.39-0.34-0.58-0.41c-0.18-0.06-0.34-0.09-0.48-0.11l-0.11-0.01l-0.08-0.01l-0.17-0.01
			c-0.45-0.03-0.91-0.07-1.36-0.09c-0.91-0.05-1.83-0.08-2.74-0.08c-1.83,0.01-3.66,0.13-5.48,0.4c-0.45,0.06-0.93,0.15-1.38,0.26
			c-0.45,0.11-0.9,0.23-1.34,0.36c-0.88,0.27-1.74,0.56-2.6,0.88c-1.71,0.63-3.38,1.33-5.04,2.05c-1.61,0.7-3.2,1.43-4.78,2.2
			l-11.25-0.49l-11.42-0.46L173,189.62l-11.36-0.35c-0.28-0.37-0.55-0.77-0.82-1.16l-0.84-1.24c-0.55-0.83-1.1-1.66-1.64-2.5
			c0.49,0.87,0.99,1.73,1.5,2.59c0.25,0.43,0.51,0.86,0.77,1.28c0.27,0.42,0.53,0.84,0.82,1.26l0.04,0.05l0.08,0.01l11.4,0.75
			l11.41,0.7l11.41,0.66l10.71,0.59l-0.52,6.45l-9.99-0.44l-13.1-0.61c-2.18-0.11-4.36-0.22-6.55-0.35
			c-2.18-0.12-4.36-0.25-6.54-0.39c-2.18-0.16-4.35-0.32-6.51-0.56c-0.54-0.06-1.08-0.13-1.61-0.21c-0.27-0.04-0.54-0.08-0.8-0.13
			l-0.2-0.03c-0.1-0.02-0.21-0.02-0.31-0.01c-0.21,0.02-0.41,0.1-0.57,0.22c-0.32,0.24-0.52,0.62-0.52,1.01l0.03,0.82l0.03,0.84
			c0.02,0.39,0.25,0.75,0.58,0.94l-0.02-0.01l5.16,3.53c0.08,0.1,0.12,0.18,0.09,0.26C165.15,203.68,165.03,203.79,164.91,203.88z"
          />
          <path
            fill="#27BAE1"
            d="M201.54,206.7c-0.49-0.16-1.01-0.31-1.56-0.37l-1.5-0.16c-1.99-0.22-3.99-0.38-5.99-0.57l-5.99-0.47
			c-4-0.27-8-0.51-12.01-0.63c-2.01-0.06-4.01-0.11-6.04-0.03c-0.26,0.02-0.5,0.03-0.8,0.09c-0.11,0.03-0.15,0.05-0.21,0.07
			l-0.18,0.07l-0.35,0.14c-0.47,0.19-0.93,0.39-1.39,0.6c-0.91,0.42-1.81,0.88-2.68,1.39c0.93-0.38,1.87-0.72,2.81-1.05
			c0.47-0.16,0.95-0.31,1.42-0.46l0.36-0.11l0.18-0.05c0.06-0.02,0.13-0.04,0.15-0.04c0.19-0.02,0.46-0.01,0.69,0
			c1.98,0.08,3.98,0.28,5.97,0.43l5.99,0.49l5.99,0.45l5.99,0.41c2,0.11,4,0.25,6,0.35l1.5,0.08c0.45,0.02,0.91,0.12,1.38,0.24
			c0.94,0.24,1.87,0.58,2.8,0.95c1.86,0.72,3.68,1.55,5.5,2.39c-1.67-1.11-3.42-2.12-5.22-3.03
			C203.44,207.46,202.52,207.04,201.54,206.7z"
          />
          <path
            fill="#27BAE1"
            d="M230.77,187.62c-0.14,0-0.28,0.01-0.44,0.01c-1.02,0-1.02,0.91-1.02,1.35c0,0.25,0.02,1.44,0.03,2.95
			c0.03,2.49,0.08,5.91,0.08,7.08c0,1.99,0.27,2.41,1,3.53c0.22,0.34,0.37,0.61,0.48,0.8c0.25,0.44,0.43,0.76,0.97,0.76
			c0.04,0,0.07,0,0.11,0c0.03,0,0.06,0,0.09,0c0.16,0,0.34-0.02,0.5-0.17c0.23-0.22,0.25-0.58,0.25-1.28v-12.57
			c0-1.2-0.13-1.78-0.48-2.12C231.97,187.6,231.44,187.61,230.77,187.62z M232,202.66c0,0.33-0.01,0.51-0.02,0.62
			c-0.05,0-0.11,0-0.14,0c0,0,0,0-0.01,0c-0.04-0.04-0.13-0.19-0.21-0.34c-0.11-0.2-0.27-0.48-0.52-0.86
			c-0.7-1.07-0.86-1.32-0.86-3.07c0-1.18-0.04-4.6-0.08-7.09c-0.02-1.5-0.03-2.69-0.03-2.94c0-0.4,0.05-0.49,0.05-0.5
			c0,0,0.03-0.02,0.14-0.02c0.16,0,0.31,0,0.46-0.01c0.49-0.01,0.85-0.02,0.98,0.11c0.23,0.23,0.23,1.21,0.23,1.53V202.66z"
          />
        </g>
        <g id="clouds_1_">
          <path
            fill="#E1D9C8"
            d="M440.27,36.29c-5.7-8.38-17.96-18.35-31.88-11.97c-2.63,1.3-5.55,3.17-8.63,5.85
			c0.24,0.06,0.48,0.12,0.73,0.2c3.84,1.21,5.63,3.81,5.63,3.81s11.94-0.96,15.68,9.18c0,0-6-9.49-16.39-7.64
			c0,0-4.61-7.45-16.82-3.26c0,0,5.06-3.58,10.82-2.37c1.09-1.47,3.51-4.3,7.53-6.84c-6.62-8.56-14.46-11.15-20.04-12.03
			c-15.1-2.38-31.27,2.72-44.48,10.51C316,37.34,292.19,38.8,263.94,47.34l213.29-2.85C456.7,44.11,448.29,31.42,440.27,36.29z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g id="father_1_">
          <path
            fill="#F1C6B0"
            d="M77.71,205.51l-1.87-2.26c0,0-1.64-6.03-1.37-6.46s0.27-0.42,0.27-0.42l3.24-2.22l1.32,3.36L77.71,205.51z
			"
          />
          <path opacity="0.3" d="M94.97,369.73C94.94,369.81,94.94,369.81,94.97,369.73L94.97,369.73z" />
          <path
            opacity="0.3"
            d="M95.01,369.65c-0.01,0.02-0.01,0.03-0.02,0.04c-0.01,0.01-0.01,0.03-0.02,0.04c0-0.01,0.01-0.02,0.02-0.04
			C95,369.68,95,369.66,95.01,369.65C95.01,369.65,95.01,369.65,95.01,369.65z"
          />
          <g>
            <g>
              <g>
                <path
                  fill="#F1C6B0"
                  d="M114.69,201.42c-0.04-0.26-0.09-0.52-0.16-0.77c-0.56-2.2-0.7-2.76-2.92-5.62
						c0.58-0.42,1.06-1.19-0.78-4.54c-1.41-2.55-2.67-3.76-3.44-3.71c-0.26,0.02-0.46,0.18-0.59,0.49c0,0,0,0,0,0
						c-0.06,0.15-0.68,0.95-1.23,0.28c0,0-0.59-1.23-2.38-2.63c-1.8-1.4-4.72-3.29-5.65-3.55s-6.91-3.4-7.98-3.13
						c-1.07,0.28-0.18,2.88-2.72,3.78c-2.54,0.91-6.01,1.34-7.32,2.03c-0.65,0.34-1.44,1.05-2.1,1.77c-0.27-0.7-0.47-1.09-0.46-0.66
						c-0.07-0.11,0.01,0.3,0.18,0.98c-0.54,0.62-0.96,1.21-1.1,1.54c-0.32,0.76-1.01,3.17-0.96,3.19c0.04,0.01-0.68,4.56-0.68,4.56
						l0.07,1.36l1.56-1.08c0.23,1.84,0.69,3.64,1.38,5.36c-0.23-0.39-0.46-0.78-0.69-1.17c0.2,0.56,0.4,1.11,0.6,1.67
						c-0.49-0.7-0.92-1.44-1.29-2.2c0.53,1.5,1.12,2.98,1.76,4.43c-0.9-1.36-1.7-2.8-2.38-4.28c0.7,2,1.41,4.04,2.5,5.84
						c-0.02,0.01-0.04,0.03-0.05,0.04c-0.79-1.1-1.5-2.26-2.12-3.45c0,0,0,0.01,0,0.01c0.61,1.2,1.24,2.42,1.97,3.56
						c-0.1,0.08-0.2,0.15-0.29,0.22c-0.05,0.04-0.1,0.08-0.15,0.12c-0.8,0.61-1.03,1.49-0.77,2.23c0.05,0.2,0.22,0.44,0.22,0.44
						s-1.36,1.12-0.82,2.19c0.12,0.56,0.7,0.63,0.7,0.63s-0.97,0.72-0.4,1.74c0.08,0.15,0.23,0.35,0.23,0.35s0.28,0.47,0.54,0.45
						c1.22-0.08,3.91,0.19,3.66,0.34c-0.03,0.05-0.15,0.08-0.37,0.08c-0.57,0-1.73-0.16-2.49,0.05c-0.77,0.21-1.09,0.89-1.14,1.48
						c-0.05,0.58,0.67,0.86,1.15,1.27s0.58,0.5,1.51,0.64c0.92,0.15,1.62,0.12,2.04,0.26c0.43,0.14,1.06,0.9,1.79,1.07
						c0.73,0.16,1.42,1.2,2.49,1.58c1.08,0.39,2.03,0.63,3.19,0.46c0,0,0.56,0.66,1.7,2.12c0.28,0.36,0.57,0.7,0.85,0.99
						c1.32-0.86,5.79-6.99,6.44-9.37c0,0,1.13-1.04,2.86-0.22c0.95,0.45,1.52,0.96,2.51,0.78c0.38-0.07,1.34,0.44,2.6,1.31
						c0.14-0.06,0.27-0.12,0.4-0.18c0.85-0.85,1.69-1.97,2.48-3.86c2.84-6.83,5.4-9.18,8.44-9.83
						C115.44,202.77,115.01,202.2,114.69,201.42z M106.2,189.42c-0.01-0.01-0.02-0.02-0.03-0.03l-0.02-0.09
						C106.17,189.33,106.19,189.37,106.2,189.42z M76.41,195.44l0.64-0.44c0.42,2,0.63,2.97,1.66,5.34
						C77.81,198.77,77.04,197.14,76.41,195.44z M77.29,194.83l0.57-0.39c0.3,1.02,0.62,2.05,0.95,3.06
						C77.98,196.05,77.73,195.76,77.29,194.83z"
                />
                <path
                  fill="none"
                  d="M92.2,129.52c-0.25,0.39-0.53,0.75-0.84,1.08c-0.36,0.38-0.7,0.7-1.02,0.97c0.19,0.48,0.38,0.98,0.56,1.48
						C91.4,132.25,91.95,131.07,92.2,129.52z"
                />
                <path
                  fill="none"
                  d="M76.07,203.79c0.02,0,0.04,0.01,0.06,0.01c-0.1-0.18-0.2-0.37-0.29-0.56c-0.88-1.13-1.44-2.5-1.59-3.92
						c0.04,0.71,0.08,1.42,0.13,2.13c-0.34-1.64-0.43-3.33-0.28-5c-0.05,0.61-0.17,1.21-0.35,1.79c0.17-1.5,0.15-3.02-0.05-4.51
						c-0.01,0.57-0.01,1.14-0.02,1.72c0-0.18-0.08-0.4-0.11-0.58c-0.03-0.2-0.06-0.41-0.09-0.61c-0.05-0.4-0.09-0.8-0.11-1.2
						c-1.42,0.07-2.7,0.74-3.84,1.78c-0.14,3.4-0.01,6.55,0.71,8.26l-0.14,0.01c0.07,0,0.15,0.01,0.22,0.01
						c1.63-0.05,3.49,0.13,5.72,0.67c-0.05-0.08-0.11-0.15-0.16-0.23C75.94,203.64,76.01,203.71,76.07,203.79z"
                />
                <path
                  fill="none"
                  d="M64.35,203.48c0.11-0.01,0.21-0.03,0.32-0.04l-0.31,0.02C64.35,203.46,64.35,203.47,64.35,203.48z"
                />
                <path
                  fill="#D7BFB1"
                  d="M91.14,170.18c0,0.89,0.02,3.04,0.42,3.53c0.19-1.04,0.62-2.12,0.62-2.6c0-0.38-0.19-0.63-0.32-0.75
						c0.01-0.04,0.01-0.07,0.02-0.11c0.76-0.15,0.52-0.66,0.27-1.49c0.03-0.19,0.06-0.37,0.09-0.56c-0.07,0.02-0.14,0.05-0.22,0.08
						C91.37,168.57,91.14,168.35,91.14,170.18z"
                />
                <path
                  fill="#D7BFB1"
                  d="M91.66,146.96c0.02-0.02,0.09-0.03,0.17-0.03c0.43-0.87,0.87-1.8,1.07-3.14
						c-0.09,0.17,0.07-1.79,0.09-1.8c-0.05-1.91-0.54-4.5-1.84-8.26c-0.04-0.12-0.08-0.23-0.12-0.35c-0.12-0.33,0.12,0.35-0.12-0.33
						c-0.18-0.5-0.37-1-0.56-1.48c-4.47,3.75-5.15-2.83-7.42,7.28c-0.59,2.62-0.72,5.23-0.67,7.7c0.18,1.04,0.36,2.32,0.52,3.9
						c1.06,10.57,6.92,15.8,9.86,14.33c0.01,0,0.03,0.01,0.04,0.02c0.01-0.06,0.01-0.13,0.02-0.19c0.3-0.24,0.6-0.49,0.83-0.64
						c-0.49,0.3-0.77,0.55-0.77,0.55c0.07-3.15-0.02-4.77-1.09-7.97c-1.07-3.2-0.85-4.48-0.89-5.88c0.32,2.67,1.04,4.11,1.5,5.76
						c0.46,1.65,0.39,4.05,1.14,5.21c0.75,1.16,3.67,1.03,2.22,1.41c-0.78,0.2-1.45,0.52-1.94,0.81c0.03-0.01,0.05-0.02,0.07-0.03
						c3.1-0.86,3.89-1.49,1.87-5.1c-1.61-2.87-3.74-7.54-4.68-9.63c0.15-0.66,0.4-1.23,0.67-1.79 M93.42,161.64
						c-0.75-1.16-0.68-3.56-1.14-5.21c-0.46-1.65-1.19-3.09-1.5-5.76c0.04,1.41-0.18,2.68,0.89,5.88c1.07,3.2,1.16,4.82,1.09,7.97
						c0,0,0.28-0.25,0.77-0.55c0.06-0.04,0.12-0.07,0.18-0.11c0.49-0.29,1.16-0.61,1.94-0.81C97.09,162.67,94.17,162.8,93.42,161.64
						z"
                />
                <path
                  fill="#4D2C1A"
                  d="M74.44,186.3c-0.02,0.03-0.03,0.06-0.05,0.1c-0.07,0.16-0.13,0.32-0.2,0.49c0,0.03-0.01,0.06-0.01,0.09
						c0.07-0.19,0.14-0.38,0.22-0.57C74.42,186.36,74.43,186.33,74.44,186.3z"
                />
                <path
                  fill="#AB7D62"
                  d="M68.7,147.96c1.51,0.31,2.64,2.32,1.21,4.06c-1.44,1.75-1.95,2.5-2.27,3.85
						c-0.32,1.35,0.65,2.59,0.65,2.59s0.2-1.03,0.53-2.28c0.33-1.25,1.84,0.45,3.29-1.09c1.45-1.55,0.45-5.73-0.14-6.69
						c-0.59-0.97-1.72-2.5-3.98-1.9c-2.25,0.6-2.75,3.28-3.24,5.15C64.76,151.65,66.57,147.53,68.7,147.96z"
                />
                <path
                  fill="#AB7D62"
                  d="M71.85,158.54c-0.66,0.77,1.17,3.66,2.13,4.8c0.96,1.14,1.88,0.04,0.85-1.9
						c-1.03-1.95-0.35-2.41,0.14-3.47c0.49-1.06-0.04-1.32-0.04-1.32C73.92,158.26,72.51,157.77,71.85,158.54z"
                />
                <path fill="#AB7D62" d="M72.55,166.71c0,0-5.15-5.56-5.56-10.4C66.98,156.31,67.57,164.9,72.55,166.71z" />
                <path
                  fill="#D7BFB1"
                  d="M140.48,165.95c2.18,0.78,4.07,1.53,4.87,1.79c1.28,0.43,3.42,2.22,4.94,3.15
						c1.52,0.93,2.99,2.41,3.62,3.07c0.59,0.63,0.58,1.79-0.06,2.24c-0.19-0.14-0.4-0.3-0.63-0.48c0.11-0.32,0.13-0.72-0.01-1.25
						c0.16,0.82-0.16,1.32-0.93,1.28c-0.78-0.04-0.76-1.13-0.66-1.94c0.02-0.19-0.37-0.55-0.71-0.98c-0.26-0.33-0.44-0.84-0.77-0.5
						c-1.36,1.4-5.4,0.31-7.62,1.24c-2.22,0.93-5.79,1.59-8.63,3.07c-2.77,1.44-7.42,3.81-11.54,6.91
						c-1.43-1.74-2.95-2.71-3.58-3.06c0.86-0.71,1.73-1.46,2.22-1.95c1.21-1.21,2.92-3.42,5.17-5.4s4.04-4.67,5.13-6.34
						c0.85-1.3,1.37-2.54,3.26-2.52L140.48,165.95z"
                />
                <path
                  fill="#AB7D62"
                  d="M150.07,176.7c0.02,0,0.04,0.01,0.06,0.01c0.43,0.07,1.06,0.14,1.63,0.02c0.64-0.13,1.24-0.4,1.45-1.02
						c0.11-0.32,0.13-0.72-0.01-1.25c0.16,0.82-0.16,1.32-0.93,1.28c-0.78-0.04-0.76-1.13-0.66-1.94c0.02-0.19-0.37-0.55-0.71-0.98
						c-0.26-0.33-0.44-0.84-0.77-0.5c-1.36,1.4-5.4,0.31-7.62,1.24c-2.22,0.93-5.79,1.59-8.63,3.07c-2.77,1.44-7.42,3.81-11.54,6.91
						c1.57,1.92,3.04,4.79,3.1,8.84c0.99-1.03,1.4-1.26,1.99-1.52c0.78-0.35,0.74-0.35,1.21-0.82c0.47-0.47,2.53-0.04,4.08-1.36
						s3.27-3.69,3.42-3.81c0.16-0.12,0.93-0.31,1.17-0.47s1.56,0.12,2.49,0.51c0.93,0.39,1.83,0.58,2.45,0.82
						c0.62,0.23,1.48,0.43,2.29-0.16c0.82-0.58,0.97-1.4,0.31-2.06c-0.66-0.66-1.4-1.32-1.79-1.75s-1.83-0.74-2.72-1.24c0,0,0,0,0,0
						c-1.02,0.82,2.43-2.01,0.68-0.56c0.18,0.07,0.41,0.2,0.76,0.33c0.66,0.23,1.59,0.27,2.41,0.93c0.82,0.66,1.05,0.7,1.48,1.48
						c0.43,0.78,1.24,1.83,2.26,1.21c1.01-0.62,1.05-1.52,0.82-2.33c-0.23-0.82-0.27-1.4-0.39-1.59c-0.12-0.19-0.74-0.74-1.13-1.01
						c-0.39-0.27-1.32-1.13-1.32-1.13s1.4,0.74,1.75,1.17c0.35,0.43,0.97,1.36,1.52,1.67c0.54,0.31,1.52,0.16,1.91-0.62
						c0.39-0.78,0.12-2.06-0.47-2.68"
                />
                <path
                  fill="#D7BFB1"
                  d="M139.87,180.34c-1.79,1.28-4.39,3.03-8.55,4.78c0,0,4.2-1.28,6.3-2.57c0.71-0.43,1.71-1.2,2.73-2.03
						c0.23-0.18,0.45-0.37,0.68-0.56c1.75-1.45,3.36-2.9,3.36-2.9C143.72,177.42,141.66,179.06,139.87,180.34z"
                />
                <path
                  fill="#659896"
                  d="M110.83,190.49c1.84,3.34,1.36,4.12,0.78,4.54c2.22,2.87,2.36,3.42,2.92,5.62
						c0.06,0.25,0.11,0.51,0.16,0.77c0.32,0.78,0.75,1.34,2.4,1.42c0.86-0.18,1.75-0.23,2.7-0.21c0.49-0.05,1-0.07,1.54-0.05
						c2.09-5.67,4.1-9.48,4.1-9.48c0.01-0.24,0.01-0.47,0-0.7c-0.06-4.05-1.52-6.92-3.1-8.84c-1.43-1.74-2.95-2.71-3.58-3.06
						c-0.19-0.11-0.3-0.16-0.3-0.16c-2.5,0.23-4.36,0.89-11.07,6.45C108.15,186.73,109.42,187.95,110.83,190.49z"
                />
                <path
                  fill="#659896"
                  d="M106.18,216.54c-0.13,0.06-0.27,0.12-0.4,0.18c0.04,0.03,0.08,0.06,0.13,0.09
						C105.99,216.72,106.08,216.63,106.18,216.54z"
                />
                <path
                  fill="#659896"
                  d="M106.8,187.28c-0.09,0.07-0.18,0.15-0.27,0.23c0.05,0.06,0.09,0.12,0.14,0.18
						C106.69,187.56,106.74,187.43,106.8,187.28z"
                />
                <path
                  fill="#659896"
                  d="M76.2,203.95c-0.03-0.05-0.05-0.1-0.08-0.15c-0.02,0-0.04-0.01-0.06-0.01
						C76.12,203.85,76.16,203.9,76.2,203.95z"
                />
                <path
                  fill="#D7BFB1"
                  d="M41.75,205.63c10.72,0,17.28-1.44,22.69-2.15c0-0.01,0.01-0.01,0.01-0.02l0.31-0.02l5.43-0.33
						l0.14-0.01c-0.72-1.71-0.85-4.87-0.71-8.26c1.14-1.04,2.42-1.71,3.84-1.78c0-0.01-0.01-0.02,0-0.03
						c0.65-0.14,2.86-3.72,3.69-4.03c4.42-1.6,2.3-5.13,2.48-5.44c2.56-4.44,10.02-2.5,10.05-2.64c0.05-0.39-15.4,5.94-15.4,5.91
						c-0.19,0.45-0.38,0.96-0.56,1.42c0.17-0.48,0.36-0.95,0.56-1.42c0.47-5.49,2.32-11.63,2.52-15.27
						c0.08-1.45,0.14-2.77,0.19-3.99c0.21-0.32,0.38-0.75,0.48-1.33c0.38-2.21-0.08-3.86-0.48-7.13c-0.08-1.49-0.21-2.76-0.37-3.93
						c-0.02-0.16-0.04-0.32-0.05-0.48c-0.64-4.19-2.49-10.99-7.67-9.29c-5.76,1.9-4.47,9.29-6.64,23.3
						c-2.36,15.22-17.69,22.11-31.28,14.34c0,0-0.07-0.38-0.2-1.03c0.04,2.4-0.22,4.52-0.65,6.37c-0.85,2.48-2.52,5.13-4.06,6.88
						c-0.01,0.02-0.03,0.03-0.04,0.05c-1.01,1.16-1.5,2.22-1.52,3.2C25.35,200.31,28.89,205.63,41.75,205.63z"
                />
                <path
                  fill="#F1C6B0"
                  d="M76.7,208.5c-0.02-0.04-0.09-0.16-0.2-0.41C76.55,208.23,76.61,208.37,76.7,208.5z"
                />
                <path
                  fill="#AB7D62"
                  d="M74.47,186.23c-0.02,0.06-0.05,0.11-0.07,0.17c0.02-0.03,0.03-0.06,0.05-0.1
						C74.45,186.28,74.46,186.25,74.47,186.23z"
                />
                <path
                  fill="#AB7D62"
                  d="M74.2,186.82c-0.21,0.46-0.39,0.94-0.56,1.42c0.19-0.45,0.37-0.9,0.56-1.36
						c0.07-0.16,0.13-0.32,0.2-0.49C74.33,186.54,74.26,186.68,74.2,186.82z"
                />
                <path
                  fill="#C16032"
                  d="M119.8,202.64c-0.95-0.02-1.85,0.03-2.7,0.21c-3.04,0.65-5.6,3-8.44,9.83
						c-0.79,1.89-1.62,3.01-2.48,3.86c-0.09,0.09-0.19,0.18-0.28,0.27c-0.04-0.03-0.08-0.06-0.13-0.09
						c-1.27-0.87-2.22-1.38-2.6-1.31c-0.99,0.18-1.56-0.33-2.51-0.78c-1.73-0.82-2.86,0.22-2.86,0.22
						c-0.66,2.38-5.12,8.51-6.44,9.37c-0.05,0.03-0.09,0.05-0.13,0.07c-0.16,1.68,1.98,2.99,1.77,4.04
						c-0.21,1.05,1.63,2.57,3.95,6.16c1.03,1.6,3.9,4.67,7.09,7.52c-0.07-0.4-0.02-0.51,0.15-0.26c0.27,0.4,0.55,0.77,0.83,1.12
						c2.57,2.2,5.23,4.16,7.24,5.01c2.32,0.59,3.95,0.2,3.95,0.2s-0.46,1.32,1.18,4.12c5.74,9.77,12.81,25.6,16.12,33.25l0.31-0.02
						c4.76-10.71,11.81-11.5,13.67-15.24c1.85-3.74,3.94-10.49,6.44-12.16c0.16-0.11,0.32-0.2,0.48-0.28c0-0.09-0.01-0.18-0.01-0.27
						c-0.12-0.88-0.29-2.32-0.22-3.08c0.11-1.14,0.84-1.8,1.35-2.48c0.51-0.69,0.53-1.71,0.63-3.02c0.1-1.31,0.51-1.84,0.87-2.42
						c0.01-0.01,0.01-0.02,0.02-0.03c-1.41-3.4-3.14-6.72-4.7-10.05c-3.15-6.73-8.83-13.46-12.18-18.17
						c-2.5-3.51-3.55-6.34-5.79-8.99c-3.96-4.67-9.04-6.54-13.03-6.64C120.8,202.57,120.29,202.59,119.8,202.64z"
                />
                <path
                  fill="#F1C6B0"
                  d="M76.59,211.34c-0.09-0.02-0.16-0.04-0.23-0.08C76.49,211.34,76.59,211.34,76.59,211.34z"
                />
                <path fill="#D7BFB1" d="M168.09,256.63c0,0.02,0,0.05,0,0.07" />
                <path
                  fill="#D7BFB1"
                  d="M168.96,262.56c0.19-1.23,0.73-2.41,0.36-3.54c-0.12-0.35-0.27-0.66-0.44-0.94
						c-0.02-0.24-0.61-0.79-0.79-1.37c-0.02-0.07-0.03-0.14-0.02-0.21c-0.07-0.73,0.44-1.48-0.43-3.12c-0.27-0.51-0.82-0.66-1.2-1.2
						c0,0-0.5-0.61-0.44-1.2c0.13-1.24-0.67-2.8-2.18-3.57c-0.46-0.23-1.09-0.47-1.74-0.68c-0.23-2.62-1.83-3.69-3.34-2.96
						c-0.3,0.15-0.52,0.29-0.68,0.45c-0.31,0.27-0.38,0.45-0.38,0.45c-0.13,0.54-0.13,0.54-0.27,0.91l-0.16,0.45l-0.21,0.42
						c0,0-0.41,0.03-0.78,1.72c-0.14,0.65-0.17,1.58-0.33,2.82c-0.12,0.95-1.25,1.68-1.63,2.77c-0.56,1.59,0.12,3.99,0.12,3.99
						s0.87-0.45,1.59-0.46c0.72-0.02,1.77,0.07,3.09,0.46c1.32,0.4,1.88,0.53,1.88,0.53c3.95,0.76,7.03,5.45,8.16,9.58
						c0.32-0.89,0.83-2.07,0.8-2.77c-0.03-0.77-0.68-1.8-0.97-2.33c0,0,0,0.01,0.01,0.02C168.96,262.7,168.95,262.63,168.96,262.56z
						"
                />
                <path
                  fill="#D7BFB1"
                  d="M154.41,257.73c0.01,0,0.02-0.01,0.02-0.01c-0.01-0.08-0.02-0.16-0.04-0.26
						C154.4,257.55,154.41,257.64,154.41,257.73z"
                />
                <path
                  fill="#659896"
                  d="M169.15,267.91c-0.01-0.02-0.01-0.05-0.02-0.07c-0.01,0.02-0.01,0.03-0.02,0.05
						c0-0.1-0.26-0.9-0.26-0.9c0-0.01,0-0.01,0-0.02c-1.41-4.22-4.08-7.89-7.89-8.71c-0.04-0.01-0.08-0.02-0.11-0.03
						c-1.74-0.34-4.08-1.69-6.42-0.51c-0.01,0-0.02,0.01-0.02,0.01c-0.16,0.08-0.32,0.17-0.48,0.28c-2.5,1.67-4.59,8.43-6.44,12.16
						c-1.85,3.74-8.91,4.52-13.67,15.24l-0.31,0.02c-3.32-7.65-10.38-23.48-16.12-33.25c-1.64-2.79-1.18-4.12-1.18-4.12
						s-1.63,0.39-3.95-0.2c-2.01-0.85-4.68-2.8-7.24-5.01c-0.28-0.35-0.56-0.72-0.83-1.12c-0.17-0.25-0.22-0.15-0.15,0.26
						c-3.18-2.84-6.05-5.91-7.09-7.52c-2.32-3.59-4.16-5.12-3.95-6.16c0.21-1.05-1.92-2.35-1.77-4.04c0.04-0.02,0.08-0.04,0.13-0.07
						c-0.28-0.3-0.57-0.63-0.85-0.99c-1.14-1.46-1.7-2.12-1.7-2.12c-1.16,0.17-2.12-0.08-3.19-0.46c-1.08-0.39-1.76-1.42-2.49-1.58
						c-0.73-0.16-1.37-0.93-1.79-1.07c-0.43-0.14-1.12-0.11-2.04-0.26c-0.92-0.15-1.03-0.24-1.51-0.64
						c-0.48-0.41-1.19-0.69-1.15-1.27c0.05-0.58,0.37-1.27,1.14-1.48c0.77-0.21,1.93-0.05,2.49-0.05c0.22,0,0.34-0.04,0.37-0.08
						c-0.02,0.01-0.04,0.02-0.07,0.03c0,0,0.16-0.18-0.42-0.26c-0.04-0.01-0.09-0.01-0.14-0.02c-0.61-0.07-1.66-0.16-2.11-0.25
						c-0.48-0.1-0.58,0.07-0.89,0.08c-0.4,0.02-0.55-0.26-0.59-0.38c-0.07-0.09-0.15-0.21-0.23-0.35c-0.57-1.02,0.4-1.74,0.4-1.74
						s-0.1,0-0.23-0.08c-0.36-0.18-0.47-0.56-0.47-0.56c-0.54-1.07,0.82-2.19,0.82-2.19s0-0.01-0.01-0.02
						c-0.09-0.13-0.15-0.27-0.2-0.41c0,0,0-0.01,0-0.01c-0.26-0.74-0.03-1.62,0.77-2.23c0.05-0.04,0.1-0.08,0.15-0.12
						c-0.46-0.65-0.92-1.3-1.38-1.95c-2.23-0.54-4.09-0.72-5.72-0.67c-0.07,0-0.15-0.01-0.22-0.01l-5.43,0.33
						c-0.11,0.01-0.21,0.03-0.32,0.04c-5.41,0.71-11.97,2.15-22.69,2.15c-12.86,0-16.4-5.31-17.23-7.12c0.01-0.98,0.5-2.04,1.52-3.2
						c-4.35-0.51-9.34,0.37-14.9,4.3c-13.28,9.4-13.86,35.99-10.17,54.45c3.03,15.13,5.75,8.61,14.66,78.42
						c2.05,16.07,0,32.78,0,32.78s58.02,11.07,91.18,1.49c0,0-4-33.06-2.04-57.04c8.06,10.71,11.1,14.43,18.44,16.18
						c0.02,0.01,0.03,0.02,0.05,0.02c2.35,0.92,4.95,1.11,7.64,0.71c3.49-0.28,6.71-1.43,9.1-3.25
						c10.12-5.55,19.37-16.74,20.33-25.8c0.4-3.8,9.3-17.76,9.7-21.99C170.15,273.21,169.88,270.49,169.15,267.91z"
                />
                <path
                  fill="#AB7D62"
                  d="M168.86,266.97c0,0.01,0,0.01,0,0.02c0,0,0.26,0.8,0.26,0.9c0.01-0.02,0.01-0.03,0.02-0.05
						c0.23-0.68,0.47-1.25,0.62-1.77c0.2-0.66,0.26-1.21,0.02-1.73c-0.03-0.06-0.05-0.12-0.08-0.17c-0.11-0.23-0.2-0.42-0.29-0.59
						c-0.25-0.49-0.4-0.75-0.44-0.82c-0.01-0.01-0.01-0.02-0.01-0.02C168.74,263.82,169.07,265.41,168.86,266.97z"
                />
                <path
                  fill="#4D2C1A"
                  d="M26.27,169.73c0.13,0.48,0.21,0.75,0.21,0.75C26.42,170.24,26.35,169.99,26.27,169.73z"
                />
                <path
                  fill="#D7BFB1"
                  d="M145.43,176.54c0,0,0.74-0.47,1.42-0.37c0.64,0.09,2.94,0.63,3.47,0.98c0.06,0.04,0.1,0.06,0.13,0.07
						c0.16,0.05-0.34-0.51-0.34-0.51c-0.01,0-0.02-0.01-0.02-0.01c-0.88-0.32-2.33-0.45-3.06-0.67
						C146.19,175.79,145.43,176.54,145.43,176.54z"
                />
                <path
                  fill="#AB7D62"
                  d="M89.87,148.57c-0.26,0.44-0.83,0.39-0.83,0.39C88.47,148.57,89.44,149.74,89.87,148.57z"
                />
                <path fill="#AB7D62" d="M89.89,148.7l-0.65,0.7C89.85,149.17,89.89,148.7,89.89,148.7z" />
              </g>
              <path
                opacity="0.3"
                d="M71.33,187.57c-0.27-2.52-0.45-3.94-0.57-4.74c-0.17,1.16-0.42,2.89-0.64,4.87
					c-0.15,1.91-0.32,3.97-0.51,5.66c-0.03,0.49-0.06,0.99-0.08,1.47c0.21-0.22,0.58-0.56,1.23-0.98c0.59-0.38,1.61-0.76,2.62-0.82
					c0-0.05-0.01,0.08-0.01,0.03c0-0.1-0.01-0.37-0.01-0.47C71.59,191.76,71.66,190.72,71.33,187.57z"
              />
              <path
                opacity="0.3"
                d="M107.29,204.18c0.13,0.3,0.11,0.63-0.07,0.72l0,0.05l-0.09,0.31c0.04-0.09,0.17-0.27,0.23-0.33
					c0.33-0.29,0.95-0.23,0.95-0.23"
              />
              <path
                opacity="0.3"
                d="M103.97,211.46c-0.3,0.22-0.58,0.39-0.8,0.47c0,0,1.34-0.54,1.23-0.37c-0.28,0.42-0.35,0.83-0.12,0.65
					c0.23-0.18,0.44-0.48,1.4-1.54c1.01-1.12,2.54-1.95,1.98-1.89s-0.78,0.5-0.75,0.19c0.03-0.32,0.07-0.77-0.07-0.53
					c-0.11,0.19-0.18,0.36-0.48,0.73c-0.1,0.12-0.21,0.26-0.36,0.43C105.55,210.09,104.7,210.92,103.97,211.46z"
              />
              <path
                opacity="0.3"
                d="M77.6,205.64c0.92-0.7,2.07-1.53,2.95-1.5c1.01,0.03,2.6,0.12,3.32,0.4c0.72,0.28,0.97,1.08,1.85,1.14
					c2.12,0.14,3.01,0.99,4.52,2.12s2.98,3.78,4.42,4.59c0.6,0.34,1.31,0.93,1.9,1.47l2.28,0.58c0.47-0.09,1.08-0.07,1.78,0.27
					c0.25,0.12,0.47,0.24,0.68,0.35l0.21,0.05c0,0-0.28-0.34-0.7-0.95c-0.41-0.61-0.56-1.21-0.3-1.44l-0.04-0.01l-0.02-0.15l-0.04,0
					c0,0-0.28,0.53-0.03,1.25c0.06,0.17,0.15,0.33,0.26,0.48c-0.61-0.56-1.39-1.23-1.89-1.5c-0.9-0.49-2.79-0.16-4.1-1.15
					c-0.94-0.7-1.75-2.4-2.13-3.31c-0.09-0.22-0.16-0.39-0.2-0.49c-0.03-0.07-0.04-0.11-0.04-0.11c-2.78,0.52-3.04-1.5-3.04-1.5
					s0.25,0.14-0.02-0.12c-0.09-0.09-0.24-0.22-0.49-0.43c-0.17-0.14-0.38-0.31-0.64-0.52c-3.89-3.13-6.35-3.06-9.79-3.96
					c-0.38-0.1-0.72-0.21-1.03-0.33c-0.03-0.01-0.05-0.02-0.08-0.03c-0.08-0.03-0.16-0.06-0.23-0.09c-0.12-0.05-0.24-0.11-0.35-0.16
					c-0.08-0.04-0.16-0.08-0.24-0.13c-0.34-0.19-0.63-0.4-0.88-0.63c-0.01-0.01-0.03-0.02-0.04-0.04c-0.65-0.62-1.02-1.4-1.2-2.39
					c-0.12-0.62-0.19-1.22-0.25-1.76l-0.13,2.12l-0.03,0.56l0.1-0.85l0.04-0.37c-0.01,0.07-0.01,0.16-0.02,0.24
					c-0.02,0.42,0.01,0.99,0.06,1.57c0.11,1.27,0.33,2.6,0.33,2.6l0.06-0.32l-0.16-1.39c0.03,0.25,0.12,0.56,0.24,0.91
					c0.19,0.53,0.45,1.13,0.73,1.7c0.26,0.53,0.52,1.04,0.72,1.41c0.22,0.4,0.36,0.65,0.36,0.65l0.41,0.36L77.6,205.64z"
              />
              <path
                opacity="0.3"
                d="M158.56,257.57c-1.2-0.37-1.09-0.33-1.49-0.36c0,0-1.08-0.13-2.43,0.45c-0.07,0.03-0.15,0.07-0.22,0.11
					c-0.01,0-0.02,0.01-0.02,0.01c0-0.09-0.01-0.18-0.01-0.26c-0.12-0.88-0.29-2.32-0.22-3.08c0.11-1.14,0.84-1.8,1.35-2.48
					c0.51-0.69,0.53-1.71,0.63-3.02c0.1-1.31,0.51-1.84,0.87-2.42c0.01-0.02,0.03-0.05,0.04-0.07l-0.93-2.11
					c0,0-0.01-0.01-0.03-0.01c-0.15-0.06-0.78-0.23-1.01,1c-0.26,1.4,0.4,1.92-0.22,3.15c-0.95,1.9-0.44,2.67-0.71,3.54
					c-0.26,0.88-1.43,1.92-1.25,3.32c0.05,0.4,0.18,0.85,0.31,1.36c-0.4,1.33-1.91,4.28-3.62,6.16c-1.96,2.14-1.83,3.29-2.67,6.36
					c-0.58,2.13-2.3,3.62-3.86,4.87c-0.24-0.24-0.48-0.47-0.72-0.71l0.04-0.03c0,0-4.51-6.23-5.24-7.36
					c-1.42-2.18-1.12-3.36-1.42-6.53c-0.22-2.37-0.76,1.59-0.34,3.67c0.42,2.09-0.55-0.13-1.34-3.52c-0.79-3.39-0.91-0.09-0.06,1.97
					c0.85,2.06,0.96,1.7-0.48,1.22c-2.78-0.92,0.44,0.18,2.25,2.29c0.45,0.62,1.23,1.72,2.1,3.02c1.33,2.06,2.78,4.44,3.38,5.44
					c0.13,0.24,0.26,0.48,0.38,0.72c-0.69,0.39-3.68,2.18-5.83,5.27c-3.51-0.59-3.87-5.06-4.79-6.75c-1.94-3.6-4.29-4.7-3.73-7.47
					c0.55-2.77-4.43-8.44-5.81-12.17c-1.38-3.73,1.31-13.55,0.3-10.66c-0.21,0.61-0.48,1.16-0.78,1.65c0.6-1.16,0.75-2.11,0.75-2.11
					c-4.64,5.3-6.01-0.19-6.95-2.84c-0.94-2.67-1.57-4.56-1.57-2.52c0,2.04,1.26,6.45-0.94,2.99c-2.2-3.46-7.7-8.65-11.01-13.21
					c-3.13-4.32-5.08-7.71-5.27-8.06c-0.01-0.02-0.02-0.03-0.02-0.03c-0.11,0.2-0.22,0.39-0.34,0.59c-0.42,0.7-0.88,1.38-1.33,2.02
					l0.63-0.97l0.96-1.48c-1.04,0.66-1.8,0.75-3.85-0.74c-1.89-1.37-5.54-0.63-7.39-1.42c-1.92-0.83-5.71,0.03-7.61-0.42
					c-0.22-0.05-0.34-0.14-0.4-0.23c0.09-0.54,0.42-1.11,1.11-1.3c0.77-0.21,1.93-0.05,2.49-0.05c0.22,0,0.34-0.04,0.37-0.08
					c-0.02,0.01-0.04,0.02-0.07,0.03c0,0,0.16-0.18-0.42-0.26c-0.04,0-0.09-0.01-0.14-0.02c-0.61-0.07-1.66-0.16-2.11-0.25
					c-0.48-0.1-0.58,0.07-0.89,0.08c-0.4,0.02-0.55-0.26-0.59-0.38c-0.07-0.09-0.15-0.21-0.23-0.35c-0.57-1.02,0.4-1.74,0.4-1.74
					s-0.1,0-0.23-0.08c-0.36-0.18-0.47-0.56-0.47-0.56c-0.54-1.07,0.82-2.19,0.82-2.19s0-0.01-0.01-0.02
					c-0.32-0.48-0.39-1.08-0.19-1.64h0c0,0-0.51,1.11-0.43,1.71c0.09,0.6-0.94,1.37-0.6,2.23c0.34,0.86-0.26,1.46,0.17,2.4
					c0.43,0.94,0.69,2.06,0.51,2.83c-0.03,0.14-0.02,0.32,0.04,0.54c0.02,0.12,0.05,0.23,0.12,0.34c0.4,0.97,1.52,2.21,3.27,2.21
					c2.4,0,3.34,0.94,4.2,1.63c0.86,0.69,3.09,1.37,4.29,1.11s2.31,2.31,3.09,3.43c0.12,0.17,0.3,0.39,0.51,0.63l1.32,2.41
					c0,0,0.52,1.12,1.52,2.77c0.49,0.81,1.09,1.75,1.81,2.75c1.92,3.3,4.17,6.85,6.38,9.58c5.56,6.85,10.67,8.73,14.59,8.76
					c0.23,0.47,0.5,0.97,0.79,1.51c1.92,4.15,4.6,9.38,9.12,19.15c4.22,9.13,6.28,15.97,6.15,22.75c0,0,1.72-4.4,0.21-9.76
					c0,0,0,0.01,0.01,0.01l1.54,3.36c0.87,2.54,1.66,6.41,1.03,11.3c-1.16,8.91,1.19,2,1.97-6.27c0.53-5.6-0.74-7.68-1.62-8.44
					c1.37,0.88,4.24,3.2,4.98,7.64c0.98,5.91,3.63-7.22-3.26-8.6c-6.89-1.38,0.1-4,5.61-1.63c0,0,1.18-2.36-4.14-2.17
					c-5.32,0.2,2.76-4.73,6.11-6.3c3.18-1.5-3.65-0.35-7.39,3.6c0.38-0.45,0.78-0.89,1.2-1.31c0.56-0.56,1.14-1.1,1.76-1.57
					c-0.05,0.04-0.09,0.09-0.14,0.13c-0.06,0.05-0.11,0.1-0.15,0.15c-1.16,1.23,3.97-1.71,5.18-3.59c0.14-0.22,0.32-0.52,0.52-0.88
					c0.43-0.76,0.98-1.79,1.56-2.89c1.32-2.5,2.77-5.38,3.25-6.34c1.6-1.94,3.07-2.28,4.26-2.66c2.05-0.65,6.67-0.69,6.67-0.69
					c-0.29-0.11-0.75-0.28-1.34-0.45C160.03,257.92,159.68,257.92,158.56,257.57z M91.29,224.65c-0.03,0.03-0.05,0.06-0.08,0.08
					c-0.01-0.07-0.01-0.14-0.01-0.21C91.24,224.57,91.27,224.61,91.29,224.65z M114.76,248.29c0.16-0.02,0.31-0.04,0.46-0.06
					c0.68-0.11,1.29-0.28,1.83-0.5c-0.52,0.26-0.86,0.38-0.86,0.38S115.73,248.35,114.76,248.29z M134.1,286.87
					c0.25,0.5,0.56,1.18,0.87,2.03C134.75,288.4,134.46,287.71,134.1,286.87z"
              />
              <path
                opacity="0.3"
                d="M128.09,232.42c0.41-1.78,0.78-11.94,0.24-14.1c-0.56,1.47-1.23,3.05-2,4.76
					c-0.15,0.33-0.28,0.67-0.41,1.02c-0.1,0.31-0.2,0.63-0.3,0.95c-1.34,4.66-1,10.63-2.71,15.13c6.76-4.68,13.84,1.24,19.26,4.87
					c5.55,3.72,7.54,3.77,6.38,3.33s1.94,1.36-6.93-5.38c-10.09-7.67-10.54-6.35-10.46-9.11c0.01-0.45,0.19-3.38,1.41-6.17
					C133.45,225.7,127.57,234.69,128.09,232.42z"
              />
              <path
                opacity="0.3"
                d="M100.91,329.31c-0.75-18.12,2.82-21,2.82-21c-6.02-11.38-28.59-47.86-28.25-58.57
					c0.33-10.71-2.68,6.69-2.34,15.4c0.33,8.7-4.02-5.36-5.36-13.39c-1.34-8.03,1.67,10.04,2.01,13.72s-4.35-4.35-8.7-5.36
					c-4.35-1,5.44,8.62,8.45,19.33c3.01,10.71,4.38,13.81,3.37,27.2s25.85,22.65,14.47,29.34c-11.38,6.69-36.95,9.65-48,9.65
					s15.34,9.47,32.75,3.78s2.53,7.99-18.22,9c-19.38,0.94,16.98,8,3.36,9.21c-0.52-0.12-1.11-0.26-1.78-0.39
					c-16.79-3.44-38.09-9.26-31.42-20.66c5.58-9.54,2.87-19.61-3.98-39.49c-0.62-1.8-1.28-3.68-1.98-5.62l0.15,0.15
					c-0.24-0.56-0.47-1.13-0.71-1.7c-0.85-2.37-1.75-4.83-2.67-7.34c-3.68-11.23-6.39-24.31-8.44-36.27
					c-2.52-14.67-2.3-14.38-1.18,1.71c0.31,4.4-0.96,4.43-2.34,2.08c-0.13-0.36-0.25-0.71-0.38-1.06c-1.35-5.73-2.36-9.68-2.46-9.15
					c-0.02,0.11,0,0.43,0.07,0.97c0.23,1.84,0.61,3.56,1.06,5.07c0.23,0.78,0.48,1.5,0.74,2.15c0.6,2.14,1.39,5.01,2.26,8.31
					c0.1,0.37,0.19,0.74,0.29,1.11c1.5,6.57,3.08,13.68,4.23,18.84c0.66,2.98,1.17,5.31,1.44,6.52c0.23,1.4,0.42,2.74,0.57,3.99
					c1.6,13.44,3.73,24,5.02,37.92c0.14,1.55,0.28,3.14,0.4,4.79c0.06,0.8,0.12,1.61,0.17,2.44c1.04,16.33-0.82,23.35-0.82,23.35
					s3.26,0.43,8.31,1.39c1.14,0.22,2.36,0.46,3.67,0.73c3.72,0.78,9.95,1.39,15.98,1.74c1.76,0.1,3.51,0.18,5.17,0.23
					c2.36,0.31,5.54,0.62,8.85,0.86c4.18,0.31,8.56,0.52,11.73,0.52c1.84,0,3.73-0.04,5.61-0.1c9.71-0.32,19.08-1.33,19.14-1.33
					C94.69,367.68,101.79,350.59,100.91,329.31z"
              />
              <path
                opacity="0.3"
                d="M114.68,224.83c2.14,2.5,2.74,3.93,2.98,4.89c0.24,0.95,0.34-0.75-0.83-2.38
					c-1.17-1.63,0.74-0.13,1.7,1.05s1.47-0.66-0.88-1.83c-1.16-0.58-3.32-2.4-5.21-4.09C113.23,223.23,114.03,224.06,114.68,224.83z
					"
              />
              <path opacity="0.3" d="M112.43,222.45c-1.81-1.79-3.59-3.34-3.59-3.34S110.5,220.71,112.43,222.45z" />
              <path
                opacity="0.3"
                d="M105.77,216.96l3.08,2.15c0,0,0.17-1.61,0.38-3.88c0.22-2.27,2.33-8.19,2.16-7.89
					c-0.4,0.74-1.16,2.05-1.98,3.59c-0.5,0.94-1.01,1.97-1.48,3.01c-0.28,0.62-0.55,1.11-0.8,1.51
					C106.35,216.7,105.77,216.96,105.77,216.96z"
              />
            </g>
            <path
              fill="#161616"
              d="M151.78,176.71c-0.57,0.11-1.21,0.05-1.63-0.02c0.82,0.66,1.38,1.1,1.55,1.25
				c3.46,2.98,5.68,3.38,5.96,3.46c0.06,0.02,0.13,0.02,0.19,0.02c0.07,0.06,0.14,0.11,0.21,0.15c0.2,0.12,0.25-0.05,0.17-0.17
				c-0.06-0.08-0.11-0.15-0.16-0.2c0.02-0.12-0.03-0.29-0.17-0.53c-0.39-0.65-1.05-1.78-4.03-4.49c-0.17-0.16-0.4-0.3-0.63-0.48
				C153.01,176.31,152.42,176.58,151.78,176.71z"
            />
          </g>
          <path
            fill="#AB7D62"
            d="M91.85,142.85c-0.54,0.79-1.21,3.28-1.21,3.28s1.02-0.9,1.94-1.76c0.13-0.12,0.24-0.27,0.33-0.44
			c0.43-0.79,0.45-1.93,0.09-1.8c-0.02,0.01-0.04,0.02-0.07,0.03C92.94,142.16,92.39,142.05,91.85,142.85z"
          />
          <path
            fill="#AB7D62"
            d="M91.63,146.17c0,0-1,0.96-1.56,2.1c-0.03,0.05-0.07,0.1-0.1,0.15c0,0,0.01,0.01,0.02,0.02
			c-0.01,0.03-0.02,0.04-0.02,0.04c0.01-0.01,0.01-0.03,0.02-0.04c0.11,0.09,0.6,0.47,0.91,0.42c0,0,0.26-0.83,0.23-1.42
			c0.15-0.06,0.33-0.12,0.52-0.16c0.01,0,0.02-0.01,0.03-0.01c1.33-0.26,1.1-0.37,1.1-0.37s-0.62-0.04-0.93-0.02
			c-0.08,0.01-0.14,0.02-0.17,0.03C91.56,147,91.63,146.17,91.63,146.17z"
          />
          <path
            fill="#AB7D62"
            d="M93.42,161.65c-0.75-1.16-0.68-3.56-1.14-5.21c-0.46-1.65-1.19-3.09-1.5-5.76
			c0.04,1.41-0.18,2.68,0.89,5.88c1.07,3.2,1.1,4.91,1.03,8.06c0,0,0.36-0.31,0.83-0.64c0.06-0.04,0.12-0.07,0.18-0.11
			c0.49-0.29,1.16-0.61,1.94-0.81C97.09,162.68,94.17,162.81,93.42,161.65z"
          />
          <path fill="#AB7D62" d="M72.33,166.85c0,0-5.15-5.56-5.56-10.4C66.77,156.45,67.35,165.04,72.33,166.85z" />
          <path
            fill="#AB7D62"
            d="M70.31,148.75c1.51,0.31,2.64,2.32,1.21,4.06s-1.95,2.5-2.27,3.85c-0.32,1.35,0.65,2.59,0.65,2.59
			s0.2-1.03,0.53-2.28c0.33-1.25,1.84,0.45,3.29-1.09c1.45-1.55,0.45-5.73-0.14-6.69c-0.59-0.97-1.72-2.5-3.98-1.9
			s-2.75,3.28-3.24,5.15C66.37,152.44,68.19,148.32,70.31,148.75z"
          />
          <path
            fill="#AB7D62"
            d="M71.87,158.68c-0.66,0.77,1.17,3.66,2.13,4.8c0.96,1.14,1.88,0.04,0.85-1.9
			c-1.03-1.95-0.35-2.41,0.14-3.47c0.49-1.06-0.04-1.32-0.04-1.32C73.94,158.4,72.53,157.91,71.87,158.68z"
          />
          <path
            fill="#AB7D62"
            d="M140.02,168.81c-1.58-0.98-1.86-0.27-1.58,0.71c0.27,0.98,1.26,2.02,0.82,2.13
			c-0.44,0.11-2.18,0.76-1.37,0.66c0.08-0.01,0.16-0.02,0.25-0.04c0.76-0.26,1.69-0.49,2.81-0.58c0.3-0.02,0.57-0.05,0.81-0.07
			c0.6-0.08,1.26-0.14,1.63-0.17c-1.02-0.81-2.07-1.65-3.12-2.49C140.18,168.91,140.1,168.86,140.02,168.81z"
          />
          <path
            fill="#161616"
            d="M143.39,171.45c0.01,0.01,0.03,0.02,0.04,0.03c0.81-0.07,1.27-0.16,2.14-0.38
			c0.38-0.1,0.75-0.19,1.12-0.27c-1.98-1.52-4.12-3.16-6.19-4.75c-5.44-4.18-10.39-7.98-10.55-8.11c-0.37-0.28-0.34,0.11-0.45,0
			c-0.11-0.11-1.18-1.01-1.77-0.39c-0.59,0.62,0.85,1.61,0.96,1.72c0.11,0.11-0.3,0.25-0.08,0.39c0,0,2.55,2.03,5.95,4.74
			c1.75,1.39,3.73,2.97,5.71,4.54C141.32,169.81,142.37,170.64,143.39,171.45z"
          />
          <path
            fill="#EB9996"
            d="M106.74,205.78c-0.24,0.66-0.5,0.78-1.15,1.18c-0.65,0.4-1.14,0.68-1.61,1.1
			c-0.47,0.42-0.2,0.95-0.88,1.35c-0.68,0.4-0.74,0.58-1.02,1.3c-0.28,0.73-0.31,0.94-0.88,1.28c-0.57,0.34-0.79,0.39-0.91,0.88
			c0.4-0.48,0.53-0.47,0.84-0.64c0.3-0.17,0.53-0.16,0.99-0.44c0.46-0.28,1.42-0.06,2.02-0.48c1.39-0.99,2.2-2.01,2.63-2.79
			c0.41-0.76,0.26-1.85,0.27-2.32c0.01-0.47,0.43-1.47,0.43-1.47C107.15,204.92,106.98,205.12,106.74,205.78z"
          />
          <path opacity="0.3" d="M108.31,204.68c-0.88-0.34-1.15-0.8-1.02-0.51" />
          <path
            opacity="0.3"
            d="M100.44,212.56l0.05,0.13l-0.02,0.02c0,0,0-0.03,0.62-0.43c0.72-0.46,0.98-0.41,1.14-0.81
			c0.16-0.41,0.18-0.52,1.05-1.16c0.88-0.64,1.06-0.94,1.56-1.89c0.5-0.96,1.2-1.02,1.7-1.63c0.48-0.59,0.54-1.34,0.67-1.83
			c0-0.02,0.01-0.04,0.01-0.05c0,0-0.02,0.04-0.06,0.1c-0.08,0.14-0.24,0.4-0.35,0.58c-0.03,0.05-0.05,0.09-0.07,0.14
			c-0.1,0.23-0.18,0.48-0.34,0.68c-0.08,0.1-0.31,0.23-0.61,0.44c-0.4,0.28-0.88,0.57-1.24,0.8c-0.09,0.06-0.21,0.13-0.28,0.18
			c-0.35,0.24-0.52,0.45-0.59,0.68c-0.02,0.05-0.03,0.1-0.04,0.16c-0.04,0.2-0.09,0.32-0.18,0.43c-0.08,0.11-0.2,0.2-0.37,0.34
			c-0.07,0.06-0.22,0.14-0.3,0.2c-0.23,0.18-0.39,0.35-0.55,0.66c-0.04,0.08-0.08,0.16-0.11,0.25c-0.01,0.03-0.02,0.05-0.03,0.07
			c-0.19,0.54-0.26,0.74-0.42,1c-0.11,0.17-0.74,0.53-1.03,0.74C100.46,212.47,100.45,212.58,100.44,212.56z"
          />
          <path
            fill="#AB7D62"
            d="M99.89,207.89c-0.52,0.11-0.99,0.29-1.33,0.81c-0.38,0.57,0.03,0.43,0.42,0.11
			c0.29-0.24,0.74-0.65,0.96-0.84C99.96,207.94,99.93,207.88,99.89,207.89z"
          />
          <path
            fill="#AB7D62"
            d="M101.38,205.23c-0.46,0.29-0.76,1.04-0.74,1.62c0,0.04,0.05,0.05,0.07,0.01c0.12-0.24,0.4-0.78,0.73-1.13
			C101.87,205.28,101.87,204.92,101.38,205.23z"
          />
          <path
            fill="#AB7D62"
            d="M97.52,209.69c-0.25-0.1-0.43-0.33-0.51-0.59c-0.04-0.12-0.06-0.3-0.12-0.45
			c-0.06-0.16-0.14-0.31-0.25-0.44c-0.21-0.26-0.48-0.45-0.75-0.61c0.19,0.26,0.4,0.49,0.55,0.75c0.07,0.13,0.13,0.26,0.17,0.4
			c0.04,0.14,0.06,0.27,0.11,0.46c0.11,0.33,0.37,0.64,0.73,0.73c0.18,0.04,0.36,0.02,0.52-0.04c0.16-0.07,0.29-0.17,0.4-0.28
			c-0.15,0.06-0.29,0.12-0.44,0.14C97.79,209.76,97.65,209.74,97.52,209.69z"
          />
          <path
            fill="#AB7D62"
            d="M101.17,204.24c0.27,0,0.55,0.1,0.72,0.3c0.08,0.1,0.14,0.23,0.17,0.37c0.03,0.14,0.02,0.3,0,0.46
			c0.08-0.14,0.13-0.3,0.15-0.47c0.01-0.17-0.03-0.35-0.12-0.51c-0.19-0.32-0.57-0.47-0.92-0.47c-0.19,0.01-0.32,0.04-0.47,0.04
			c-0.14,0.01-0.29-0.01-0.43-0.04c-0.29-0.06-0.58-0.19-0.88-0.29c0.24,0.21,0.49,0.41,0.81,0.53c0.16,0.06,0.33,0.09,0.5,0.1
			C100.86,204.27,101.04,204.23,101.17,204.24z"
          />
          <path
            fill="#AB7D62"
            d="M99.69,193.18c1.09-0.87,1.51-1.42,1.51-1.42s-1.03-0.02-3.19,1.99s-2.99,4.63-2.44,3.86
			C96.33,196.54,97.97,194.56,99.69,193.18z"
          />
          <path
            fill="#AB7D62"
            d="M98.49,199.82c0.02,0.05,0.04,0.1,0.05,0.15c0.03-0.1,0.05-0.19,0.08-0.29c0.05,0.04,0.08,0.09,0.1,0.14
			c0.01-0.1,0.04-0.2,0.09-0.28c0.02,0.04,0.06,0.08,0.1,0.1c0-0.09,0.02-0.19,0.07-0.27c0.05,0.1,0.12,0.18,0.21,0.24
			c0-0.13,0.02-0.26,0.05-0.38c0.06,0.06,0.11,0.13,0.17,0.19c0-0.12,0.01-0.24,0.01-0.35c0.06,0.07,0.11,0.14,0.17,0.21
			c-0.01-0.12-0.02-0.24-0.04-0.35c0.08,0.11,0.17,0.21,0.27,0.3c-0.04-0.2-0.06-0.39-0.08-0.59c0.2,0.1,0.4,0.27,0.55,0.42
			c-0.14-0.19-0.26-0.41-0.36-0.63c0,0,0.01-0.01,0.01-0.01c0.19,0.15,0.38,0.29,0.57,0.44c-0.15-0.19-0.26-0.41-0.32-0.65
			c0,0,0.01,0,0.01-0.01c0.15,0.09,0.31,0.18,0.46,0.27c-0.09-0.15-0.17-0.31-0.22-0.48c0.02-0.02,0.04-0.03,0.05-0.05
			c0.13,0.06,0.26,0.1,0.4,0.12c-0.09-0.08-0.16-0.19-0.21-0.31c0.03-0.03,0.06-0.06,0.08-0.09c0.1-0.02,0.2-0.03,0.3-0.05
			c-0.06-0.04-0.11-0.09-0.15-0.14c0.01-0.02,0.03-0.03,0.04-0.05c0.26,0.03,0.34,0.07,0.63-0.02c-0.26-0.04-0.4-0.1-0.5-0.16
			c0,0,0,0,0,0c0.29-0.01,0.58-0.08,0.85-0.2c-0.23-0.02-0.46-0.03-0.69-0.05c0.01-0.01,0.02-0.03,0.03-0.04
			c0.24-0.05,0.47-0.12,0.69-0.23c-0.18,0.02-0.36,0.01-0.54-0.02c0.05-0.08,0.09-0.16,0.14-0.25c0.11-0.06,0.22-0.12,0.33-0.2
			c-0.08,0.01-0.15,0.01-0.23,0c0.02-0.05,0.05-0.11,0.07-0.16c0.16-0.07,0.32-0.15,0.47-0.25c-0.12,0-0.24,0.01-0.37,0.01
			c0.01-0.03,0.03-0.06,0.04-0.09c0.08-0.05,0.16-0.1,0.22-0.18c-0.05,0-0.1,0-0.15,0c0.02-0.06,0.05-0.12,0.07-0.18
			c0.04-0.03,0.07-0.06,0.11-0.09c-0.02-0.01-0.04-0.02-0.06-0.03c0.01-0.02,0.01-0.03,0.02-0.05c0.02-0.01,0.03-0.01,0.05-0.02
			c0.09-0.03,0.17-0.06,0.26-0.1c-0.07,0.01-0.15,0.03-0.22,0.05c0.06-0.01,0.11-0.05,0.15-0.09c-0.04,0.01-0.08,0.01-0.12,0.02
			c0.12-0.08,0.22-0.19,0.31-0.31c-0.09,0.04-0.17,0.08-0.26,0.12c0.09-0.07,0.18-0.14,0.26-0.23c-0.07,0.01-0.13,0.04-0.19,0.08
			c0.09-0.07,0.17-0.16,0.24-0.26c-0.05,0.01-0.1,0.03-0.16,0.04c0.08-0.08,0.16-0.16,0.23-0.25c-0.05,0-0.09,0.02-0.13,0.05
			c0.05-0.06,0.1-0.11,0.14-0.17c-0.02-0.02-0.06-0.01-0.08,0.01c0.04-0.05,0.07-0.09,0.11-0.14c-0.02-0.02-0.07-0.01-0.08,0.02
			c0.1-0.18,0.16-0.39,0.16-0.6c0-0.04-0.01-0.09-0.05-0.1c-0.01,0-0.01,0-0.01,0c0.01-0.06,0.01-0.1,0-0.1
			c-0.09-0.03-0.53,1.23-1.08,2.31c-0.55,1.09-1.07,1.83-2.06,2.77s-1.33,1.47-1.28,1.5c0.01,0,0.03-0.01,0.07-0.03
			c0,0.08,0.04,0.18,0.04,0.25C98.45,199.97,98.47,199.89,98.49,199.82z"
          />
          <path
            fill="#AB7D62"
            d="M99.5,199.87l-0.41,0.22c2.7-0.84,3.88-3.4,3.88-3.4C101.65,198.72,99.5,199.87,99.5,199.87z"
          />
          <path
            fill="#AB7D62"
            d="M109.98,190.59c0.76,1.52,0.8,3.83,0.8,3.83C111.36,193.26,109.98,190.59,109.98,190.59z"
          />
          <path
            fill="#AB7D62"
            d="M109.49,191.86c-0.25-0.15-0.42,0.17-0.88-0.19c0,0-0.05,0.14,0.22,0.4c0.27,0.26,0.47,0.32,0.62,1.03
			c0.15,0.72,0.5,0.93,0.48,0.47C109.91,193.12,109.74,192.01,109.49,191.86z"
          />
          <path
            fill="#AB7D62"
            d="M88.17,206.3c0.2,0.11,0.39,0.23,0.59,0.36c0.85-1.19,1.76-2.24,2.36-2.85
			C91.71,203.19,89.73,204.21,88.17,206.3z"
          />
          <path
            fill="#AB7D62"
            d="M93.24,206.7c-0.03-0.01-0.07-0.01-0.1,0c0.02-0.07,0.02-0.12,0.01-0.15c0,0-0.39,0.29-0.68,0.84
			c-0.15,0.29-0.56,0.89-1.06,1.53c0.11,0.12,0.21,0.25,0.32,0.37c0-0.06,0.01-0.11,0.01-0.17c0.03,0.07,0.06,0.14,0.08,0.2
			c-0.01-0.12-0.01-0.24,0.01-0.36c0.06,0.07,0.11,0.15,0.15,0.24c0-0.13-0.01-0.26-0.01-0.39c0.03,0.05,0.07,0.09,0.1,0.14
			c0-0.08,0-0.17,0-0.25c0.05,0.04,0.1,0.08,0.15,0.12c-0.01-0.11-0.02-0.21-0.04-0.32c0.06,0.05,0.12,0.09,0.18,0.14
			c-0.02-0.1-0.04-0.19-0.06-0.29c0.06,0.06,0.12,0.11,0.18,0.17c-0.01-0.1-0.02-0.19-0.04-0.29c0.05,0.04,0.1,0.08,0.16,0.1
			c-0.02-0.12-0.03-0.23-0.05-0.35c0.06,0.04,0.13,0.07,0.2,0.09c-0.05-0.07-0.11-0.15-0.16-0.22c0.09,0.03,0.17,0.06,0.25,0.11
			c-0.04-0.07-0.08-0.13-0.12-0.2c0.05,0.03,0.1,0.06,0.16,0.09c-0.03-0.06-0.06-0.12-0.1-0.18c0.06,0.04,0.12,0.08,0.19,0.1
			c-0.04-0.07-0.07-0.14-0.09-0.22c0.04,0.01,0.09,0.01,0.13,0.02c-0.04-0.06-0.1-0.11-0.16-0.15c0.08,0,0.16-0.01,0.23-0.04
			c-0.04-0.02-0.08-0.04-0.13-0.05c0.05-0.06,0.13-0.09,0.21-0.08c-0.06-0.04-0.12-0.07-0.18-0.11c0.12-0.01,0.24-0.05,0.34-0.12
			c-0.06-0.05-0.15-0.07-0.23-0.06c0.08-0.05,0.18-0.08,0.28-0.09c-0.09-0.02-0.19-0.04-0.28-0.03c0,0,0,0,0,0
			c0.08-0.02,0.17-0.05,0.25-0.09c-0.06-0.01-0.13-0.01-0.19-0.02c0.1,0,0.2-0.01,0.3-0.01C93.38,206.73,93.31,206.71,93.24,206.7z"
          />
          <path
            fill="#AB7D62"
            d="M80.35,213.66l-2.65-0.42c-0.74,0.04-1.35-1.41-1.14-0.81s0.85,0.91,0.63,1.02
			c-0.22,0.11-0.45,0.15-0.79-0.08c0,0,0,0.02,0.02,0.05c0.04,0.11,0.2,0.4,0.59,0.38c0.3-0.02,0.4-0.18,0.89-0.08
			c0.45,0.09,1.5,0.18,2.11,0.25c0.05,0.01,0.09,0.01,0.14,0.02c0.58,0.08,0.42,0.26,0.42,0.26c0.02-0.01,0.05-0.02,0.07-0.03
			C80.93,214.03,80.87,213.68,80.35,213.66z"
          />
          <path
            fill="#AB7D62"
            d="M79.74,210.62c1.06,0.09,1.41,0.05,1.71,0.05l-2.08-0.35c-0.51-0.04-0.97-0.17-1.74,0.18
			c-0.69,0.32-1.27,0.75-1.76,0.19c0,0,0.11,0.38,0.47,0.56c0.07,0.03,0.14,0.06,0.23,0.08c0.4-0.24,1-0.51,1.43-0.69
			C78.45,210.46,78.69,210.53,79.74,210.62z"
          />
          <path
            fill="#AB7D62"
            d="M80.52,207.19c0.61,0.14,1.97,0.58,2.34,0.77l-1.88-1.24c-0.53,0.04-1.57,0.05-1.99,0.31
			c-0.42,0.25-1.36,0.85-1.78,1.11c-0.41,0.25-0.69-0.05-0.71-0.07c0,0,0,0.01,0,0.01c0.11,0.25,0.18,0.37,0.2,0.41
			c0.01,0.01,0.01,0.02,0.01,0.02s0.5-0.29,1.37-0.78C79.42,206.97,80.06,207.08,80.52,207.19z"
          />
          <path
            fill="#AB7D62"
            d="M156.15,248.88c-0.17,1.36-0.12,2.33-0.63,3.02c-0.51,0.69-1.18,1.16-1.37,2.3
			c-0.23,1.35,0.08,2.46,0.2,3.34c0.01,0.09,0.05,0.12,0.06,0.2c2.34-1.18,4.71,0.16,6.44,0.5c0.04,0.01,0.08,0.02,0.11,0.03
			c-0.69-1.17-1.52-2.41-1.89-3.52c-1.31-3.94-1.47-7.33-1.56-7.73c-0.14-0.59,0.04-1.11,1.21-1.57c1.17-0.46,2.32,0,2.32,0
			c-2.72-1.4-3.42,0.7-3.25-0.04c0.17-0.71-0.03-0.77,0.25-1.19c0.01-0.01,0.02-0.03,0.03-0.04c0,0-0.35,0.22-0.49,0.69
			c-0.14,0.47-0.22,1.01-0.56,1.58c-0.01,0.01-0.01,0.02-0.02,0.03C156.66,247.04,156.35,247.27,156.15,248.88z"
          />
          <path
            fill="#AB7D62"
            d="M165.96,253.89c0.13,1.22,0.95-0.42,1.18-1.08c0,0-0.38-0.2-0.7-0.64c-0.21-0.29-0.4-0.68-0.44-1.2
			C165.9,251.81,165.83,252.68,165.96,253.89z"
          />
          <path
            fill="#AB7D62"
            d="M168.15,259.13c0.07,1.21,0.6-0.6,0.72-0.96c0.01-0.03,0.01-0.06,0.01-0.09c-0.02-0.2-0.26-0.46-0.48-0.77
			c-0.14-0.19-0.26-0.39-0.31-0.61C168.1,257.32,168.11,258.43,168.15,259.13z"
          />
          <path
            fill="#4D2C1A"
            d="M62.18,168.7c2.17-14,0.88-21.4,6.64-23.3c5.18-1.7,7.02,5.1,7.67,9.29c0.01,0.16,0.02,0.32,0.05,0.48
			c0.16,1.17,0.29,2.44,0.37,3.93c0.4,3.27,0.87,4.93,0.48,7.13c-0.1,0.57-0.27,1.01-0.48,1.33c-0.04,1.22-0.11,2.54-0.19,3.99
			c-0.2,3.64-2.05,9.78-2.52,15.27c0.02-0.05,7.07-1.79,8.67-2.61c1.74-0.89,4.64-2.12,6.02-2.81c1.72-0.87,2.33-3.36,2.46-3.38
			c0.02-0.54,0.01-0.91-0.06-1.16c0.17-0.33,0.31-0.8,0.41-1.42c0.03-0.18,0.1-0.48,0.19-0.86c0.07-0.3-0.04-0.61-0.28-0.81
			c-0.02-0.01-0.03-0.03-0.05-0.05c-0.4-0.5-0.42-2.65-0.42-3.53c0-1.83,0.23-1.61,0.89-1.89c0.08-0.03,0.15-0.06,0.22-0.08
			c0.51-0.17,0.8-0.11,1.04-0.85c0.02-0.05,0.03-0.07,0.04-0.09c0.08-1.27-0.07-2.24-0.65-2.47c-0.01-0.01-0.03-0.01-0.04-0.02
			c-2.95,1.46-8.8-3.76-9.86-14.33c-0.16-1.58-0.33-2.86-0.52-3.9c-0.05-2.46,0.08-5.08,0.67-7.7c2.27-10.11,2.95-3.53,7.42-7.28
			c0.32-0.27,0.66-0.59,1.02-0.97c0.31-0.33,0.59-0.7,0.84-1.08c-0.26,1.55-0.8,2.73-1.29,3.54c-0.53,0.87-1.01,1.31-1.01,1.31
			s0.4-0.21,1.12-0.98c0.54-0.59,1.27-1.51,2.13-2.92c1.6-2.61,1.68-5.53,0.83-7.88c0.78,1.14,1.21,2,1.21,2
			c-0.49-3.42-1.9-5.76-3.31-7.29c-0.21-0.4-0.44-0.78-0.69-1.16c1.86,1.01,2.42,1.88,2.42,1.88c-1.59-3.09-4.19-5.1-7.39-6.06
			c-0.84-0.32-1.73-0.51-2.67-0.55c-0.13-0.01-0.25-0.02-0.37-0.02c-0.06-0.01-0.13-0.01-0.19-0.01c-1.95-0.16-3.39-0.67-4.7-1.42
			c3.57,0.55,6.65,0.52,3.92-0.05c-3.96-0.83-7.14-2.64-7.14-2.64l7.59,1.32c0,0-2.99-0.78-7.11-3.57
			c-4.11-2.78-10.71-4.3-13.73-4.08c-5.8-0.33-11.54,2.74-20.79,3.05c-5.49,0.18-10.43,2.58-14.07,6.27
			c-2.79,2.48-5.91,6.05-7.45,11.23c-2.03,6.82-2.77,10.33-2.77,10.33s2.12-5.44,3.44-8.47c1.33-3.03-0.47,2.37-1.71,7.58
			c-1.23,5.21,0.47,15.64,0.47,15.64s-1.04-8.1,2.04-16.2c-0.81,4.31-2.36,10.97-1.39,19.86c0.26,2.4,0.75,4.58,1.35,6.58
			c0.17,0.57,0.36,1.13,0.55,1.68c1.67,4.77,3.86,8.38,4.76,11.27c-0.66-2.33-2.7-9.58-4.02-14.41c-0.18-0.65-0.28-1.38-0.33-2.14
			c1.29,5.07,2.99,9.65,4.52,13.44c2.1,5.2,3.71,12.67,4.26,15.39c0.13,0.66,0.2,1.03,0.2,1.03
			C44.49,190.82,59.83,183.93,62.18,168.7z"
          />
          <path
            fill="#AB7D62"
            d="M90.37,177.59c-0.99,0.04-1.96,0.25-2.88,0.61c0.22-0.41,0.43-0.83,0.65-1.24
			c-0.63,0.49-1.26,0.99-1.9,1.48c0.2-0.38,0.4-0.76,0.6-1.14c-0.68,0.41-1.37,0.82-2.06,1.21c0.13-0.21,0.23-0.43,0.21-0.68
			c-0.22,0.32-0.51,0.59-0.85,0.78c0.24-0.35,0.39-0.75,0.46-1.16c-0.28,0.75-0.86,1.38-1.57,1.73c0.34-0.49,0.67-0.98,1.01-1.46
			c-0.73,0.8-1.47,1.59-2.26,2.33c0.33-0.47,0.55-1.01,0.65-1.58c-0.18,0.52-0.44,1-0.78,1.43c0-0.03-0.01-0.06-0.01-0.09
			c-0.02,0.02-0.04,0.05-0.07,0.07c0.01-0.06,0.02-0.11,0.03-0.17c-0.05,0.1-0.1,0.19-0.15,0.29c-0.05,0.05-0.09,0.09-0.14,0.14
			c0.06-0.25,0.13-0.49,0.19-0.74c-0.18,0.24-0.39,0.46-0.62,0.64c-0.01-0.03-0.01-0.05-0.02-0.08c0.02-0.12,0.04-0.23,0.06-0.35
			c-0.02,0.1-0.05,0.21-0.07,0.31c-0.03-0.14-0.03-0.28-0.01-0.42c-0.15,0.27-0.31,0.53-0.48,0.79c0.05-0.45,0.07-0.91,0.06-1.36
			c-0.09,0.62-0.23,1.23-0.4,1.83c-0.02,0.02-0.03,0.04-0.05,0.06c0.08-0.38,0.11-0.77,0.07-1.15c-0.15,0.29-0.3,0.59-0.45,0.88
			c-0.03-0.16-0.06-0.31-0.09-0.47c0.04-0.24,0.07-0.48,0.11-0.72c-0.07,0.12-0.13,0.25-0.19,0.37c-0.07-0.31-0.14-0.62-0.21-0.93
			c-0.01,0.57-0.06,1.14-0.12,1.71c-0.07,0.19-0.13,0.38-0.18,0.57c0-0.04-0.01-0.07-0.01-0.11c0.05-0.27,0.1-0.55,0.15-0.82
			c-0.06,0.15-0.13,0.29-0.19,0.44c-0.03-0.3-0.06-0.6-0.09-0.9c0.03-0.14,0.05-0.29,0.08-0.43c-0.03,0.12-0.06,0.24-0.09,0.36
			c-0.05-0.54-0.11-1.08-0.16-1.62c-0.01,0.17-0.02,0.33-0.03,0.5c-0.03-0.22-0.06-0.44-0.09-0.66c-0.06,0.49-0.13,0.98-0.22,1.47
			c0.01-0.28,0.01-0.56,0-0.84c-0.09,0.59-0.21,1.17-0.36,1.75c-0.01-1.12-0.03-2.24-0.04-3.35c-0.18,1.39-0.41,2.78-0.7,4.15
			c-0.03-0.1-0.05-0.21-0.09-0.31c0.01,0.19,0.02,0.38,0.03,0.56c-0.04,0.18-0.07,0.36-0.12,0.54c-0.05-0.19-0.1-0.38-0.15-0.57
			c-0.01,0.44-0.16,0.88-0.42,1.23c-0.09-0.38-0.11-0.79-0.04-1.18c-0.2,0.49-0.32,1.01-0.34,1.54c0.09-1.01,0.03-2.04-0.17-3.03
			c-0.03,1.9-0.47,3.63-0.9,5.5c-0.01,0.02-0.03,0.04-0.04,0.07l0.02-0.05c-0.12,0.21-0.23,0.43-0.33,0.65
			c-0.05,0.11-0.11,0.22-0.16,0.33c-0.01,0.03-0.02,0.06-0.03,0.1c-0.08,0.19-0.15,0.38-0.22,0.57c-0.28,0.78-0.47,1.59-0.57,2.41
			c-0.03,0.14-0.04,0.29-0.06,0.41c-0.06,0.35-0.11,0.71-0.14,1.07c-0.07,0.72-0.09,1.45-0.05,2.18c0,0.01,0,0.02,0,0.03
			c0.02,0.4,0.05,0.8,0.11,1.2c0.03,0.2,0.06,0.41,0.09,0.61c0.03,0.18,0.11,0.4,0.11,0.58c0.01-0.57,0.01-1.14,0.02-1.72
			c0.2,1.49,0.22,3.01,0.05,4.51c0.18-0.58,0.29-1.18,0.35-1.79c-0.15,1.67-0.06,3.36,0.28,5c-0.04-0.71-0.08-1.42-0.13-2.13
			c0.15,1.42,0.71,2.79,1.59,3.92c0.09,0.19,0.19,0.37,0.29,0.56c0.03,0.05,0.05,0.1,0.08,0.15c-0.04-0.05-0.09-0.11-0.13-0.16
			c-0.07-0.08-0.13-0.16-0.2-0.24c0.05,0.08,0.11,0.15,0.16,0.23c0.46,0.65,0.92,1.3,1.38,1.95c0.09-0.07,0.19-0.15,0.29-0.22
			c-0.73-1.13-1.37-2.35-1.97-3.56c0,0,0-0.01,0-0.01c0.63,1.2,1.34,2.35,2.12,3.45c0.02-0.01,0.04-0.03,0.05-0.04
			c-1.09-1.81-1.8-3.84-2.5-5.84c0.68,1.48,1.48,2.92,2.38,4.28c-0.64-1.46-1.23-2.94-1.76-4.43c0.37,0.77,0.8,1.5,1.29,2.2
			c-0.2-0.56-0.4-1.11-0.6-1.67c0.23,0.39,0.46,0.78,0.69,1.17c-0.91-2.27-1.43-4.71-1.53-7.15c0.71,2.23,1.66,4.39,2.83,6.42
			c-1.24-2.84-1.28-3.67-1.95-6.7c0.87,2.06,0.94,1.92,2.05,3.86c-0.73-2.24-1.39-4.49-1.99-6.76c0.52,1.39,1.74,4.87,2.53,6.12
			c-0.94-2.39-1.03-3.65-1.33-4.49c0.27,0.56,0.59,1.09,0.98,1.57c-0.12-0.57-0.25-1.14-0.37-1.71c0.3,0.44,0.6,0.89,0.9,1.33
			c-0.04-1.37-0.41-2.74-1.08-3.94c0.63,1.18,1.27,2.37,1.9,3.55c-0.43-1.58-0.87-3.16-1.3-4.74c0.38,0.89,0.81,1.75,1.3,2.59
			c0-0.58-0.08-1.16-0.24-1.72c0.13,0.24,0.29,0.46,0.48,0.64c-0.01-0.53-0.09-1.06-0.22-1.58c0.1,0.27,0.24,0.52,0.44,0.73
			c0.01-0.58,0.42-2.15,0.43-2.73c0.17-0.47-0.07,0.05,0.1-0.42c0.07,0.51-0.15,2.4,0.34,2.25c-0.12-0.49,0.56-2.5,0.44-2.99
			c0.45,0.33,0.72,1.47,1.04,2.42c-0.07-0.27,0.3-2.62,0.06-2.77c0.55,0.32,1.21,0.65,1.41,1.74c0.08-0.31,0.33-2.26,0.04-2.39
			c1.02,0.35,3.72-0.23,4.68-0.72c-0.55-0.21-2.54-0.18-2.74-0.74c1.6,0.32,3.62,0.01,5.72,0.97c-0.08-0.22-0.91-1.02-1.32-1.26
			c0.75,0.3,0.97,0.3,1.84,0.54c1.39,0.39,2.88,0.78,2.88,0.78s-1.11-0.72-2.03-1.31c0.79,0.33,1.72,0.6,2.2,0.66
			c1.21,0.16,1.99,0.04,2.72,0.02l-0.27,0.6c1.65,0.84,3.1,2.08,4.18,3.58c-0.24-0.62-0.57-1.21-0.99-1.73
			c0.65,0.64,1.31,1.29,1.96,1.93c-0.35-0.37-0.63-0.81-0.79-1.3c0.62,0.6,1.23,1.2,1.85,1.8c-0.3-0.4-0.52-0.85-0.65-1.33
			c0.8,0.87,1.57,1.77,2.32,2.68c-0.21-0.62-0.42-1.24-0.63-1.86c0.55,0.67,1.1,1.34,1.65,2.01c-0.08-0.29-0.17-0.59-0.25-0.88
			c0.22,0.33,0.45,0.63,0.69,0.91c0.2,0.56,0.61,1.43,1.01,1.65c0.53,0.29,0.51-0.41,0.78-0.07c0.27,0.34,0.49,0.63,0.49,0.63
			s-0.04-0.49-0.33-0.86c-0.29-0.37-0.51-0.53-1.07-0.89c-0.1-0.07-0.19-0.14-0.27-0.23c-0.03-0.04-0.06-0.08-0.09-0.13
			c-0.25-0.37-0.28-0.82-0.12-1.02c0.31-0.39,1.34,0.63,1.34,0.63c-0.41-0.51-0.96-1.26-1.47-1.16c-0.34,0.07-0.43,0.36-0.43,0.66
			c-0.25-0.34-0.51-0.67-0.75-0.99c-0.03-0.04-0.06-0.08-0.09-0.12c-0.05-0.06-0.09-0.12-0.14-0.18c-0.71-0.92-1.39-1.69-2.28-2.49
			c-0.51-0.46-1.06-0.87-1.63-1.24c-1.35-1.77-2.85-3.45-4.86-4.34c0.28,0.22,0.57,0.43,0.85,0.65c-1.17-0.65-1.29-0.76-1.72-0.9
			c0.28,0.09-1.51-0.73-1.31-0.51c1.07,0.77-0.73-0.11-2.14,0.19c0.36-0.09,1.17-0.49,1.46-0.54c-0.94,0.07-1.88,0.14-2.82,0.21
			c0.16-0.25,0.36-0.47,0.6-0.65c-0.44,0.05-0.88,0.09-1.32,0.14c-0.5,0.05-1,0.1-1.49,0.16C90.03,177.99,90.2,177.79,90.37,177.59z
			"
          />
        </g>
        <path
          opacity="0.3"
          d="M26.84,207.11c9.33,2.8,31.13-1.3,34.25-3.1c0.03-0.02,0.06-0.03,0.08-0.05
		c-0.71-14.22,4.58-34.66,4.58-34.66c0.53-2.83,0.7-4.36,0.65-5.14c0.03-0.67-0.05-1.32-0.27-1.95c-1.73-4.95-1.75-8.7-1.49-11.82
		c0.3-1.01,0.64-1.82,0.99-2.47c1.51-2.85,3.21-2.63,3.21-2.63s1.36-0.33,2.3-0.22c1.54,0.17,1.42-0.74,0.69-3.31
		c-0.4-1.4-0.25-2.6,0.24-3.72l0.02,0.15c0,0,3.02-5.83,1.04,1.46c2.5-4.58,7.43-12,5-6.66c-2.44,5.33,0.45,10.1-0.31,7.97
		c-0.76-2.13,0.52-6.41,2.74-10.82c1.55-3.07,1.52-6.34,1.18-8.62c0.02-0.23,0.02-0.46,0.02-0.69c1.57,4.54,0.48,8.24,0.48,8.24
		s1.37-3.66,0.15-9.45c-1.22-5.79-8.39-7.49-12.93-7.72l0.01,0.13c-7.26-1.08-12.51,1.09-8-1.17c6.26-3.13,13.15-1.46,3.37-2.63
		c-7.18-0.86-14.87,3.24-15.17,3.4c0.09-0.05,0.98-0.55,2.96-1.8c4.62-2.93,8.03-3.73,9.47-3.92c-0.19,0-0.41-0.01-0.66-0.03
		c-3.87-0.28-9.99,0.78-16.25-0.37c-5.52-1.02-10.06-0.21-14.08,1.5c-0.23,0.1-0.47,0.2-0.7,0.31c-1.24,0.82-2.39,1.77-3.43,2.82
		c-2.63,2.34-5.57,5.66-7.18,10.38c-0.09,0.28-0.19,0.56-0.27,0.84c-2.03,6.82-2.77,10.33-2.77,10.33s2.12-5.44,3.44-8.47
		c1.33-3.03-0.47,2.37-1.71,7.58c-0.98,4.14-0.1,11.58,0.3,14.46c0.1,0.74,0.18,1.18,0.18,1.18s-0.95-7.35,1.63-15.06
		c0.13-0.38,0.26-0.76,0.41-1.14c-0.81,4.31-2.36,10.97-1.39,19.86c0.26,2.4,0.75,4.58,1.35,6.58c0.17,0.57,0.36,1.13,0.55,1.68
		c1.41,4.04,3.19,7.24,4.26,9.88c0.19,0.48,0.37,0.94,0.5,1.39c-0.66-2.33-2.7-9.58-4.02-14.41c-0.13-0.47-0.22-0.97-0.27-1.5
		c-0.02-0.21-0.04-0.42-0.06-0.64c1.29,5.07,2.99,9.65,4.52,13.44c2.1,5.2,3.71,12.67,4.26,15.38c0,0.17,0,0.33,0,0.49
		c0,2-0.21,3.79-0.55,5.4c-0.03,0.13-0.07,0.26-0.11,0.39c-0.7,2.39-2.53,5.24-4.06,6.97c-0.05,0.06-0.1,0.12-0.15,0.18
		c-0.26-0.01-0.51-0.02-0.76-0.02c-2.76-0.06-5.25,0.28-7.47,0.94c-3.56,1.05-6.47,2.92-8.84,5.29c-1.79,1.78-3.27,3.84-4.49,6.04
		c-6.01,10.78-5.89,24.97-5.4,26.45c0.01,0.02,0.01,0.04,0.02,0.05C1.39,224.95,4.71,200.47,26.84,207.11z M63.98,153.42
		c-0.09,0.47-0.17,0.96-0.24,1.43c0.13-0.97,0.28-1.85,0.44-2.63c0.05-0.25,0.11-0.49,0.17-0.73
		C64.23,152.06,64.1,152.73,63.98,153.42z"
        />
        <path
          id="cloud-left"
          fill="#E1D9C8"
          d="M257.07,98.17c-15.02-1.67-16.98-12.96-31.96-9.61c-11.87,2.66-17.34-5.97-27.6,0.06
		c-1.73,1.02-3.05,2.24-3.73,2.93c2.28,0.76,3.54,2.2,4.16,3.14c2.72-0.45,5.1,0.41,5.1,0.41c2.4-2.52,5.81-2.27,5.81-2.27
		c-4.67,0.76-5.68,3.66-5.68,3.66c-6.31-2.9-11.11,2.4-11.11,2.4c1.43-2.52,3.42-3.65,5.32-4.08c-3.78-2.65-8.28-3.99-14.62-2.3
		c-2.65,0.9-6.06,2.42-10.56,4.93c-7.48,4.16-28.88,6.44-44.62,6.87l151.76-2.18C279.33,102.12,280.21,100.74,257.07,98.17z"
        />
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text transform="matrix(1 0 0 1 215.8496 116.5576)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            g
          </tspan>
          <tspan x="34.12" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            e
          </tspan>
          <tspan x="66.31" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            sag
          </tspan>
          <tspan x="161.71" y="0" fontFamily={font.condensed} fontSize="68.3887px">
            t
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 284.5 175.3672)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="13px">
            Wir wollen die{" "}
          </tspan>
          <tspan x="86.71" y="0" fontFamily={font.condensed} fontSize="15.8124px">
            CO2-EMISSIONEN
          </tspan>
          <tspan x="0" y="19" fontFamily={font.serif} fontSize="13px">
            in der europäischen Neufahrzeugflotte
          </tspan>
          <tspan x="0" y="38" fontFamily={font.serif} fontSize="13px">
            zwischen 1995 und 2020{" "}
          </tspan>
          <tspan x="141.69" y="38" fontFamily={font.condensed} fontSize="15.8124px">
            HALBIEREN.
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
