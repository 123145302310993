import React, { FC } from "react";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";
import { Parallax, ParallaxAlign, ParallaxColor } from "./Parallax";
import styled from "styled-components/macro";
import { bp, gridColumnStart, gridColumnWidth, font } from "#shared/theme";
import htmr from "htmr";

const ParallaxText = styled.div`
  z-index: 1;
  font-size: 18px;
  line-height: 1.64;
  font-weight: bold;

  strong {
    font-size: 24px;
    line-height: 1.21;
    font-family: ${font.condensed};
  }

  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(1)}
    ${gridColumnWidth(11)}
  }
  @media ${bp.o} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    font-size: 30px;
    line-height: 1.53;

    strong {
      font-size: 40px;
      line-height: 1.18;
    }

    ${gridColumnStart(7)}
    ${gridColumnWidth(17)}
  }
  @media ${bp.p} {
    ${gridColumnStart(9)}
    ${gridColumnWidth(15)}
  }
  @media ${bp.t} {
    ${gridColumnStart(11)}
    ${gridColumnWidth(13)}
  }
  @media ${bp.d} {
    ${gridColumnStart(13)}
    ${gridColumnWidth(11)}
  }
`;

interface ParallaxQuote {
  align: ParallaxAlign;
  parallaxColor: ParallaxColor;
  copytext: string;
}

export const ParallaxQuote: FC<ParallaxQuote> = ({ align, parallaxColor, copytext }) => {
  return (
    <Parallax parallaxColor={parallaxColor} align={align}>
      <Wrapper>
        <Grid>
          <ParallaxText>{htmr(copytext)}</ParallaxText>
        </Grid>
      </Wrapper>
    </Parallax>
  );
};
