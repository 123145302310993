export enum PageName {
  comingSoon = "comingSoon",
  index = "index",
  home = "home",
  start = "start",
  startindex = "startindex",
  intro = "intro",
  intro_ceo = "intro_ceo",
  intro_cfo = "intro_cfo",
  guidedTour2020 = "guidedTour2020",
  guidedTour2020Slides = "guidedTour2020Slides",
  guidedTour2020_automobile_deliveries = "guidedTour2020_automobile_deliveries",
  guidedTour2020_ebit_margin_automotive_segment = "guidedTour2020_ebit_margin_automotive_segment",
  guidedTour2020_motorcycles_deliveries = "guidedTour2020_motorcycles_deliveries",
  guidedTour2020_new_contracts_retail_customer_financing_financial_services = "guidedTour2020_new_contracts_retail_customer_financing_financial_services",
  guidedTour2020_group_key_figures = "guidedTour2020_group_key_figures",
  guidedTour2020_earnings_per_share = "guidedTour2020_earnings_per_share",
  guidedTour2020_research_and_development_expenditure = "guidedTour2020_research_and_development_expenditure",
  guidedTour2020_key_automobile_markets = "guidedTour2020_key_automobile_markets",
  guidedTour2020_deliveries_of_electrified_vehicles = "guidedTour2020_deliveries_of_electrified_vehicles",
  guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value = "guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value",
  guidedTour2020_share_of_women_in_management_positions_at_bmw_ag = "guidedTour2020_share_of_women_in_management_positions_at_bmw_ag",
  guidedTour2020_tour_end = "guidedTour2020_tour_end",
  interactiveValueCreationProcess = "interactiveValueCreationProcess",
  interview = "interview",
  interviewDiversity = "interviewDiversity",
  interviewFleetEmissions = "interviewFleetEmissions",
  interviewCleanProduction = "interviewCleanProduction",
  reportOfTheSupervisoryBoard = "reportOfTheSupervisoryBoard",
  statementOfTheChairmanOfTheBoardOfManagement = "statementOfTheChairmanOfTheBoardOfManagement",
  financialStatements = "financialStatements",
  downloads = "downloads",
  glossary = "glossary",
  legalDisclaimer = "legalDisclaimer",
  cookies = "cookies",
  imprint = "imprint",
  contact = "contact",
  dataProtection = "dataProtection",
  note = "note",
}
export interface PageMeta {
  title: string;
  topTitle: string;
  description: string;
  image: string;
  path: string;
}
export interface PagesList {
  [k: string]: PageMeta;
}
