export const common = {
  Start: "Start",
  Download: "Download Gesamt-PDF",
  fullPdfReport: "/data/pdf/de/BMW-Group-Bericht-2020-DE.pdf",
  Fullscreen: "Fullscreen",
  Scroll: "Scroll",
  Top: "Top",
  autoplay: "<strong>AUTOPLAY</strong>ca. 90 Sek.",
  rotatePhone: "For better experience turn your phone",
  pdfLoading: "PDF wird geladen …",
  pdf_title: "Der BMW GROUP<br>BERICHT 2020",
  pdf_type: "PDF",
};
