import { useTranslation } from "#hooks";
import { Image, ImageAuthor, TextAside } from "#ui";
import htmr from "htmr";
import React from "react";

export const About: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { copytext, image, caption } = tHome("about");
  return (
    <TextAside content={htmr(copytext)}>
      <Image src={image?.src}>
        <ImageAuthor {...caption} />
      </Image>
    </TextAside>
  );
};
