import React from "react";
import { AnalyticsEventTypes, ControlledLineIconTypes, ControlledLineTargetRef } from "#interfaces";
import styled, { css } from "styled-components/macro";
import { Color, bp, IconKeys, font, fontCondensed } from "#shared/theme";
import { Icon } from "../Icon/Icon";
import htmr from "htmr";
import { Link, LinkProps } from "../Link/Link";
import { createAnalyticsEventHandler, wrapCallbackWithAnalyticsEventHandler } from "#helpers";

const containerStyles = css`
  display: flex;
  margin-top: 1em;
  cursor: pointer;
`;
const ContainerLink = styled(Link)`
  ${containerStyles}
`;
const Container = styled.div`
  ${containerStyles}
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  fill: ${Color.Green};
  vertical-align: text-bottom;
`;
const StyledIconLinkExternal = styled(StyledIcon)`
  flex: 0 0 28px;
  height: 25px;
`;
const StyledIconPdf = styled(StyledIcon)`
  flex: 0 0 62px;
  height: 46px;
`;
const IconLinkExternal: React.FC = () => <StyledIconLinkExternal iconKey={IconKeys.SvgExternalLink} />;
const IconPdf: React.FC = () => <StyledIconPdf iconKey={IconKeys.SvgPdf} />;

const spanLineIcons: { [k: string]: JSX.Element } = {
  [ControlledLineIconTypes.external]: <IconLinkExternal />,
  [ControlledLineIconTypes.pdf]: <IconPdf />,
};

const LinkLabel = styled.span`
  margin-left: 5px;
  font-size: 15px;
  line-height: 17px;
  font-family: ${font.condensed};
  color: ${Color.Green};
  @media ${bp.d} {
    font-size: 18px;
    line-height: 20px;
    margin-left: 15px;
  }
  strong {
    display: block;
    font-weight: bold;
    ${fontCondensed}
  }
`;

export interface LinkIconProps extends Partial<LinkProps> {
  targetRef?: ControlledLineTargetRef;
  type?: ControlledLineIconTypes;
  label?: string;
  onClick?: () => void;
  analyticsEvent?: AnalyticsEventTypes;
}

export const LinkIcon: React.FC<LinkIconProps> = ({ type, targetRef, label, to, onClick, analyticsEvent }) => {
  const children = (
    <>
      {type && spanLineIcons[type]}
      {label && <LinkLabel>{htmr(label)}</LinkLabel>}
    </>
  );
  return to ? (
    <ContainerLink innerRef={targetRef} to={to} onClick={analyticsEvent && createAnalyticsEventHandler(analyticsEvent)}>
      {children}
    </ContainerLink>
  ) : (
    <Container
      ref={targetRef}
      onClick={analyticsEvent && onClick ? wrapCallbackWithAnalyticsEventHandler(onClick, analyticsEvent) : onClick}
    >
      {children}
    </Container>
  );
};
