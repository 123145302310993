import BMWGroupRegular from "./fonts/bmwgroup/BMWGroup-Regular.woff";
import BMWGroupRegular2 from "./fonts/bmwgroup/BMWGroup-Regular.woff2";
import BMWGroupCondensedBold from "./fonts/bmwgroup-cond/BMWGroupCondensed-Bold.woff";
import BMWGroupCondensedBold2 from "./fonts/bmwgroup-cond/BMWGroupCondensed-Bold.woff2";
import BMWGroupCondensedRegular from "./fonts/bmwgroup-cond/BMWGroupCondensed-Regular.woff";
import BMWGroupCondensedRegular2 from "./fonts/bmwgroup-cond/BMWGroupCondensed-Regular.woff2";
import BMWGroupSerifRegular from "./fonts/bmwgroup-serif/BMWGroupSerif-Regular.woff";
import BMWGroupSerifRegular2 from "./fonts/bmwgroup-serif/BMWGroupSerif-Regular.woff2";
import BMWGroupSerifBold from "./fonts/bmwgroup-serif/BMWGroupSerif-Bold.woff";
import BMWGroupSerifBold2 from "./fonts/bmwgroup-serif/BMWGroupSerif-Bold.woff2";

export enum FontFamily {
  BMWGroup = "BMWGroup",
  BMWGroupSerif = "BMWGroupSerif",
  BMWGroupCondensed = "BMWGroupCondensed",
}

export const font = {
  default: `${FontFamily.BMWGroup}, sans-serif`,
  serif: `${FontFamily.BMWGroupSerif}, sans-serif`,
  condensed: `${FontFamily.BMWGroupCondensed}, sans-serif`,
};

export const fontFace = `
  @font-face {
    font-family: ${FontFamily.BMWGroup};
    src: url('${BMWGroupRegular2}') format('woff2'),
         url('${BMWGroupRegular}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${FontFamily.BMWGroupCondensed};
    src: url('${BMWGroupCondensedBold2}') format('woff2'),
         url('${BMWGroupCondensedBold}') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${FontFamily.BMWGroupCondensed};
    src: url('${BMWGroupCondensedRegular2}') format('woff2'),
         url('${BMWGroupCondensedRegular}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${FontFamily.BMWGroupSerif};
    src: url('${BMWGroupSerifRegular2}') format('woff2'),
         url('${BMWGroupSerifRegular}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${FontFamily.BMWGroupSerif};
    src: url('${BMWGroupSerifBold2}') format('woff2'),
         url('${BMWGroupSerifBold}') format('woff');
    font-weight: bold;
    font-style: normal;
  }
`;
