import React, { FC } from "react";
import styled from "styled-components/macro";
import { Grid } from "../Grid/Grid";
import { Wrapper } from "../Wrapper/Wrapper";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";

const PageTitle = styled.h1`
  font-size: 24px;
  @media ${bp.m} {
    font-size: 26px;
    line-height: 1.46;
  }
  @media ${bp.p} {
    font-size: 32px;
    line-height: 1.38;
  }
  @media ${bp.t} {
    font-size: 38px;
    line-height: 1.32;
  }

  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(6)}
    ${gridColumnWidth(14)}
  }
`;

interface TitleProps {
  headline: string;
}

export const Title: FC<TitleProps> = ({ headline }) => (
  <Wrapper>
    <Grid>
      <PageTitle>{headline}</PageTitle>
    </Grid>
  </Wrapper>
);
