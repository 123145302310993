import { BasicThreeDemo } from "./basicThreeDemo";
import * as THREE from "three";
// import Mountains from "./Mountains";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import gui from "./gui";
// import { getCurve } from "./getCurve";
import config from "./config";
/**
 * - Mountains
 * - Road
 * - RoadLights
 * -
 *  */

// let debugCam = false;
// debugCam = true;
let debug = {
  debugOrbit: false,
  moveAlongCurve: false,
  useOrbitCamera: false,
  makeOrbitFollowCurve: true,
  time: false,
  lookAhead: false,
};
// debug.cam = true;
// debug.time = true;
class Core extends BasicThreeDemo {
  constructor(container, canvas) {
    super(container, canvas);
    // this.camera.position.z = 20;
    // this.camera.position.y = 5;

    this.regularCamera = this.camera;
    this.scene.fog = new THREE.Fog(new THREE.Color("#1a4f77"), 50, 150);

    // this.orbitCamera = new THREE.
    // console.log(this.renderer, container);
    // this.controls = new OrbitControls(this.camera, canvas);

    this.globalUniforms = {
      uTime: { value: 0 },
      uTravelLength: { value: config.travelLength },
      uCurveAmplitude: { value: config.curveAmplitude },
      uCurveFrequency: { value: config.curveFrequency },
    };
    this.updates = [];
  }
  //
  init() {
    // this.controls = new OrbitControls(
    // 	this.orbitCamera,
    // 	this.renderer.domElement
    // );
    // this.controls.enableZoom = false;

    // const helper = new THREE.CameraHelper(this.regularCamera);
    // helper.visible = debug.useOrbitCamera;
    // this.regularCamera.userData.helper = helper;
    // this.scene.add(helper);
    // let plane = new THREE.PlaneBufferGeometry(100, 100, 100, 100);
    // let mat = new THREE.MeshBasicMaterial({ wireframe: true });
    // let mesh = new THREE.Mesh(plane, mat);
    // mesh = new Mountains();
    // mesh.scale.set(100, 100, 1);
    // mesh.rotation.x = -Math.PI / 2;
    // this.mesh = mesh;
    // this.scene.add(mesh);

    // let plane = new THREE.BoxBufferGeometry(0.25, 0.25, 4);
    // let mat = new THREE.MeshBasicMaterial();

    // let d = 5;
    // for (let i = 0; i < 10; i++) {
    //   let mesh = new THREE.Mesh(plane, mat);
    //   mesh.position.z -= d * i;
    //   let curve = getCurve(mesh.position.z, 0);
    //   mesh.position.x = curve[0];

    //   this.rotateObject(mesh);

    //   // this.scene.add(mesh);
    // }
    // this.createCameraGUI();
    this.addEvents();
  }
  //   createCameraGUI() {
  //     let folder = gui.addFolder("camera");
  //     folder.add(debug, "lookAhead");
  //     folder.add(debug, "moveAlongCurve");
  //     folder.add(debug, "useOrbitCamera").onFinishChange(() => {
  //       this.camera = debug.useOrbitCamera ? this.orbitCamera : this.regularCamera;

  //       this.scene.fog.near = debug.useOrbitCamera ? 1000 : 50;
  //       this.scene.fog.far = debug.useOrbitCamera ? 1000 : 150;
  //       this.regularCamera.userData.helper.visible = debug.useOrbitCamera;
  //     });
  //     folder.add(debug, "makeOrbitFollowCurve");
  //   }
  //   rotateObject(obj) {
  //     let curve = getCurve(obj.position.z, this.clock.elapsedTime);
  //     let nextCurve = getCurve(obj.position.z - 1, this.clock.elapsedTime);

  //     obj.position.x = curve[0];

  //     obj.rotation.y = -Math.atan2(1, curve[0] - nextCurve[0]) + Math.PI / 2;
  //   }
  update() {
    this.updates.forEach((upt) => upt());

    let elapsed = this.clock.elapsedTime * 0.5;
    if (debug.time) elapsed = 0;
    this.globalUniforms.uTime.value = elapsed;

    if (debug.makeOrbitFollowCurve) {
      // console.log(this.orbitCamera.target);
      // this.controls.target.copy(this.regularCamera.position);
      // this.controls.update();
    }
    // this.controls.target0.copy(this.regularCamera.position);

    return;
    // let curve = getCurve(this.regularCamera.position.z, elapsed);
    // let depth = 5;
    // let nextCurve = getCurve(this.regularCamera.position.z - depth, elapsed);

    // if (debug.moveAlongCurve) this.regularCamera.position.x = curve[0];
    // if (debug.lookAhead) this.regularCamera.rotation.y = -Math.atan2(depth, curve[0] - nextCurve[0]) + Math.PI / 2;
    // else this.regularCamera.rotation.y = 0;

    // // this.regularCamera.updateMatrix();
    // this.regularCamera.updateMatrixWorld();

    // if (this.regularCamera.userData.helper) {
    //   this.regularCamera.userData.helper.update();
    // }
  }
}
export default Core;
