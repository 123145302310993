import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { bp } from "#shared/theme";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { ChartContainer } from "../ChartContainer/ChartContainer";
import { DeliveriesChartMobile } from "../DeliveriesChartMobile/DeliveriesChartMobile";
import { InfoItemProps } from "../Info/Info";
import { MultipleChart } from "../MultipleChart/MultipleChart";

export const DeliveriesChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels: Translations = t("DeliveriesChart");
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer
      title={labels.title}
      tooltipContent={labels.tooltip}
      bottomLink={{ to: labels.bottomLink.to.toString(), text: labels.bottomLink.text.toString() }}
      list={(labels.info as unknown) as InfoItemProps[]}
    >
      {isDesktop ? <MultipleChart labels={labels} /> : <DeliveriesChartMobile labels={labels} />}
    </ChartContainer>
  );
};
