import React from "react";
import { font } from "#shared/theme";
import styled from "styled-components/macro";

const Container = styled.div`
  max-width: 500px;
  font-family: ${font.condensed};
  font-size: 12px;
`;
const Item = styled.div`
  padding-left: 20px;
  display: flex;
`;
const Symbol = styled.span`
  display: inline-block;
  flex: 0 0 10px;
  margin-right: 5px;
  text-align: right;
`;
const Copytext = styled.span`
  flex: 1 0 0%;
`;

export interface FootnoteProps {
  symbol: string;
  copytext: string;
}

export interface FootnotesProps {
  list: FootnoteProps[];
}
export const Footnotes: React.FC<FootnotesProps> = ({ list }) => {
  const renderList = ({ symbol, copytext }: FootnoteProps, key: number) => {
    return (
      <Item key={key}>
        <Symbol>{symbol}</Symbol>
        <Copytext>{copytext}</Copytext>
      </Item>
    );
  };
  return <Container>{list.map(renderList)}</Container>;
};
