import { InfoCardTheme, InfoCardType } from "../../interfaces";

export const VCP = {
  intro: {
    main: {
      text:
        "Within the BMW Group, value creation is a comprehensive process involving a broad range of positive <a href='#' data-line='external'>factors</a>, some of which <span>interact in complex ways</span>",
    },
    modal: {
      title: "BMW GROUP <span>Value Creation</span>",
      text:
        "These factors may be of a financial or a non-financial nature and relate to both tangible and intangible values that are geared towards creating additional value using a minimum of resources. These input factors form the basis that drives the BMW Group’s overall performance.",
    },
  },
  chart: {
    item1: {
      title1: "Financial capital",
      title2: "Financial capital",
      inputLabel1: "Total assets (€ million)",
      inputValue1: 216658,
      inputLabel2: "Equity capital (€ million)",
      inputValue2: 61520,
      outputLabel1: "Dividend total (proposal to the Annual General Meeting) (€million)",
      outputValue1: 1253,
      outputLabel2: "Profit before financial result (EBIT) (€ million)",
      outputValue2: 4830,
    },
    item2: {
      title1: "Human capital",
      title2: "Human capital",
      inputLabel1: "Workforce at end of year",
      inputValue1: 120726,
      inputLabel2: "Training and education expenditure (€&nbsp;million)",
      inputValue2: 279,
      outputLabel1: "Wages/salaries/social insurance contributions (€ million)",
      outputValue1: 12244,
      outputLabel2: "Employee satisfaction (in %)",
      outputValue2: 82,
    },
    item3: {
      title1: "Intellectual capital",
      title2: "Intellectual capital",
      inputLabel1: "Employee ideas implemented in conjunction with CRE8 Ideas Management",
      inputValue1: 1561,
      inputLabel2: "R&D expenditure (€ million)",
      inputValue2: 6279,
      outputLabel1: "First-year benefits from implemented employee ideas (CRE8) (€ million)",
      outputValue1: 18.2,
      outputLabel2: "R&D ratio (in %)",
      outputValue2: 6.3,
    },
    item4: {
      title1: "Relationship capital",
      title2: "Relationship capital",
      inputLabel1: "Proportion of production-relevant purchasing volume within the CDP supply chain programme (in %)",
      inputValue1: 79,
      inputLabel2: "Purchase volume (€ million)",
      inputValue2: 55.5,
      outputLabel1: "Proportion of value creation from suppliers (in %)",
      outputValue1: 60,
      outputLabel2: "Proportion of suppliers in the CDP Supply Chain programme with an assessment of at least B (in %)",
      outputValue2: 35,
    },
    item5: {
      title1: "Produced capital",
      title2: "Produced capital",
      inputLabel1: "Number of plants",
      inputValue1: 31,
      inputLabel2: "thereof plants producing electrified vehicles",
      inputValue2: 13,
      outputLabel1: "Deliveries to customers (in million units)",
      outputValue1: 2.33,
      outputLabel2: "Deliveries of electrified vehicles",
      outputValue2: 192662,
    },
    item6: {
      title1: "Natural capital",
      title2: "Natural capital",
      inputLabel1: "Energy consumption per produced vehicle (in MWh)",
      inputValue1: 2.12,
      inputLabel2: "Proportion of electricity from renewable energy sources (in %)",
      inputValue2: 100,
      outputLabel1: "Improvement in resource consumption and emissions from vehicle production (in %, average)",
      outputValue1: -56.7,
      outputLabel2: "Development CO2 emissions EU new car fleet since 1995 (in %)",
      outputValue2: -53,
    },
  },
  info: [
    {
      type: InfoCardType.Copytext,
      props: {
        category: "The BMW Group",
        title: "Value creation",
        theme: InfoCardTheme.Default,
        content: [
          {
            copytext:
              "<p>The representation  aligns with the framework set out by the International Integrated Reporting Council and shows related input and output resources in simplified form. It is intended to provide examples of how integrated management of the company takes a holistic view of all essential aspects throughout the entire value chain.</p>",
          },
          {
            headline: "Capital types",
            copytext:
              "<p><strong>Financial capital</strong> – examples include equity and total assets: An appropriately high return on equity, among other factors, lays a solid foundation for high earnings and, therefore, stable dividend distributions.</p><p><strong>Human and intellectual capital</strong> – one of the main success factors for the BMW Group. This is where employees’ knowledge, skills, competencies, strengths and talents come together.</p><p><strong>Relational capital</strong> – basis for the trust placed in the BMW Group by shareholders, customers, business partners and the public.</p><p><strong>Produced capital</strong> – the BMW Group’s international production network forms the backbone of its produced capital.</p><p><strong>Natural capital</strong> – includes renewable and non-renewable natural resources used in the manufacturing process.</p>",
          },
        ],
      },
    },
    {
      type: InfoCardType.Pdf,
      props: {
        theme: InfoCardTheme.Gray,
        chapter: "Combined Management Report",
        headline: "General Information and Group Profile",
        page: 21,
        src: "/data/pdf/en/General-Information-and-Group-Profile.pdf",
      },
    },
  ],
};
