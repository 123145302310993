const transition = {
  duration: 0.6,
  delay: 0.2,
  ease: [0.33, 1, 0.68, 1],
};
const variants = {
  enter: {
    x: 0,
    transition,
  },
  exit: {
    transition: {
      ...transition,
      duration: 0,
    },
  },
};
export const variantsRight = {
  ...variants,
  exit: {
    ...variants.exit,
    x: 80,
  },
};
export const variantsLeft = {
  ...variants,
  exit: {
    ...variants.exit,
    x: 100,
  },
};
export const variantsText = {
  ...variants,
  exit: {
    ...variants.exit,
    x: 70,
  },
};
