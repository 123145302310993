import { PageName } from "#interfaces";

export const pages = {
  list: {
    [PageName.comingSoon]: {
      path: "/coming-soon",
      title: "BMW Group Report 2020",
      topTitle: "BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
    },
    [PageName.index]: {
      path: "/",
      title: "BMW Group Report 2020",
    },
    [PageName.home]: {
      path: "/home/",
    },
    [PageName.start]: {
      path: "/home/start/",
      title: "Home",
      topTitle: "The BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.startindex]: {
      path: "/home/start/index.html",
      title: "Home",
      topTitle: "The BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.intro]: {
      path: "/home/intro/",
      title: "Intro",
      topTitle: "BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.intro_ceo]: {
      path: "/home/intro/#ceo",
      title: "Intro CEO",
      topTitle: "BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.intro_cfo]: {
      path: "/home/intro/#cfo",
      title: "Prolog CFO",
      topTitle: "BMW Group Report 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.guidedTour2020]: {
      path: "/home/intro/#guided-tour-2020",
      title: "Guided Tour 2020",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020Slides]: {
      path: "/home/guided-tour-2020/",
      title: "Guided Tour 2020",
      topTitle: "Guided Tour 2020",
      description: "Willkommen zum ersten Integrierten Bericht der BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_automobile_deliveries]: {
      path: "/home/guided-tour-2020/#automobile-deliveries",
      title: "Automobile deliveries",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_ebit_margin_automotive_segment]: {
      path: "/home/guided-tour-2020/#ebit-margin-automotive-segment",
      title: "EBIT margin automotive segment",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_motorcycles_deliveries]: {
      path: "/home/guided-tour-2020/#motorcycles-deliveries",
      title: "Motorcycles deliveries",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services]: {
      path: "/home/guided-tour-2020/#new-contracts-retail-customer-financing-financial-services",
      title: "New contracts, Financial Services",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_group_key_figures]: {
      path: "/home/guided-tour-2020/#group-key-figures",
      title: "Group key figures",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_earnings_per_share]: {
      path: "/home/guided-tour-2020/#earnings-per-share",
      title: "Earnings per share",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_research_and_development_expenditure]: {
      path: "/home/guided-tour-2020/#research-and-development-expenditure",
      title: "Research and development expenditure",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_key_automobile_markets]: {
      path: "/home/guided-tour-2020/#key-automobile-markets",
      title: "Key automobile markets",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_deliveries_of_electrified_vehicles]: {
      path: "/home/guided-tour-2020/#deliveries-of-electrified-vehicles",
      title: "Deliveries of electrified vehicles",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value]: {
      path: "/home/guided-tour-2020/#co2-emissions-per-vehicle-in-production-and-eu-fleet-value",
      title: "CO2 emissions",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag]: {
      path: "/home/guided-tour-2020/#share-of-women-in-management-positions-at-bmw-ag",
      title: "Share of women in management functions",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.guidedTour2020_tour_end]: {
      path: "/home/guided-tour-2020/#guidedTour2020_tour_end",
      title: "Guided Tour 2020 End",
      topTitle: "Guided Tour 2020",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_guidedtour-en.jpg",
    },
    [PageName.interactiveValueCreationProcess]: {
      path: "/value-creation/",
      title: "How the BMW Group creates value",
      topTitle: "How the BMW Group creates value",
      description: "Discover the value creation process of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_vcp-en.jpg",
    },
    [PageName.interview]: {
      path: "/interview/",
      title: "Interview",
      topTitle: "",
      description: "",
      image: "https://report2020.bmwgroup.com/assets/images/",
    },
    [PageName.interviewDiversity]: {
      path: "/interview/diversity/",
      title: "Interview Diversity",
      topTitle: "Diversity",
      description: "Find out why the BMW Group is convinced that diversity increases performance.",
      image: "https://report2020.bmwgroup.com/assets/images/og_diversity-en.jpg",
    },
    [PageName.interviewFleetEmissions]: {
      path: "/interview/fleet-emissions/",
      title: "Interview Fleet Emissions",
      topTitle: "Fleet Emissions",
      description: "Find out how the BMW Group even exceeded the targets for reducing CO2 fleet emissions.",
      image: "https://report2020.bmwgroup.com/assets/images/og_co2emissions-en.jpg",
    },
    [PageName.interviewCleanProduction]: {
      path: "/interview/clean-production/",
      title: "Interview Clean Production",
      topTitle: "Clean Production",
      description:
        "Find out what the BMW Group is doing for more sustainability in production and it´s supplier network.",
      image: "https://report2020.bmwgroup.com/assets/images/og_cleanproduction-en.jpg",
    },
    [PageName.reportOfTheSupervisoryBoard]: {
      path: "/report-of-the-supervisory-board/",
      title: "Report of the Supervisory Board",
      topTitle: "Report of the Supervisory Board",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_report-en.jpg",
    },
    [PageName.statementOfTheChairmanOfTheBoardOfManagement]: {
      path: "/statement-of-the-chairman-of-the-board-of-management/",
      title: "Statement of the Chairman of the Board of Management",
      topTitle: "Statement of the Chairman of the Board of Management",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_statement-en.jpg",
    },
    [PageName.financialStatements]: {
      path: "/financial-statements/",
      title: "Financial Statements",
      topTitle: "Financial Statements",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_financialstatements-en.jpg",
    },
    [PageName.downloads]: {
      path: "/downloads/",
      title: "Downloads",
      topTitle: "Downloads",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.glossary]: {
      path: "/glossary/",
      title: "Glossary",
      topTitle: "Glossary",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.legalDisclaimer]: {
      path: "/legal-disclaimer/",
      title: "Legal Disclaimer",
      topTitle: "Legal Disclaimer",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.cookies]: {
      path: "/cookies/",
      title: "Cookies",
      topTitle: "Cookies",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.imprint]: {
      path: "/imprint/",
      title: "Imprint",
      topTitle: "Imprint",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.contact]: {
      path: "/contact/",
      title: "Contact",
      topTitle: "Contact",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.dataProtection]: {
      path: "/data-protection/",
      title: "Data Protection",
      topTitle: "Data Protection",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
    [PageName.note]: {
      path: "/note/",
      title: "Note",
      topTitle: "Note",
      description: "Welcome to the first Integrated Report of the BMW Group.",
      image: "https://report2020.bmwgroup.com/assets/images/og_home-en.jpg",
    },
  },
};
