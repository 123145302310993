import { ContextMenu, HeaderProps, Root, SvgAnimation, Webgl } from "#components";
import { isReactSnap, TextColorThemeTypes } from "#constants";
import { rootElement } from "#helpers";
import { useTranslation } from "#hooks";
import { PageName, PagesList, SlideshowItemProps } from "#interfaces";
import { TourProvider } from "#providers";
import { FixedContainer } from "#ui";
import { gsap } from "gsap";
// import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { ScrollTrigger } from "#shared/ScrollTrigger";

import ScrollToPlugin from "gsap/ScrollToPlugin";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

// gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const ScrollContainer = styled.div`
  flex: 1 0 0%;
`;

const GradientContainer = styled(FixedContainer)`
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -2;
  background-image: radial-gradient(circle at 60% 80%, rgb(0, 193, 233), rgb(0, 109, 182));
  pointer-events: none;
`;
const GradientAnimation: React.FC<{ startPathname: string; startPath: string }> = ({ startPathname, startPath }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<GSAPAnimation | null>(null);
  const { pathname } = useLocation();
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  useEffect(() => {
    if (pathname !== startPathname && isInitiated) {
      const trigger = ScrollTrigger.getById("gradient");
      const currentGradient = divRef.current?.style.backgroundImage;
      trigger.disable();
      if (trigger) trigger.kill(true);
      if (animRef.current) {
        animRef.current.kill();
      }
      gsap.set(divRef.current, { backgroundImage: currentGradient });
      //   gsap.to(divRef.current, {
      //     backgroundImage: "radial-gradient(circle at 60% 80%, rgb(0, 109, 182), rgb(0, 109, 182))",
      //     ease: "power3.out",
      //     duration: 0.3,
      //   });
      //   console.log(animRef.current, divRef.current?.style.backgroundImage);

      setIsInitiated(false);
      return;
    }

    if (pathname == startPath) {
      gsap.to(divRef.current, {
        backgroundImage: "radial-gradient(circle at 60% 80%,rgb(0, 193, 233), rgb(0, 109, 182))",
        ease: "power3.in",
        duration: 0.3,
      });
    }

    if (pathname !== startPathname || isInitiated) return;
    const anim = gsap.fromTo(
      divRef.current,
      {
        backgroundImage: "radial-gradient(circle at 60% 80%,rgb(0, 193, 233), rgb(0, 109, 182))",
      },
      {
        backgroundImage: "radial-gradient(circle at 60% 80%, rgb(0, 109, 182), rgb(0, 109, 182))",
        ease: "power3.in",
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          id: "gradient",
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
          scroller: rootElement,
        },
      }
    );
    animRef.current = anim;
    setIsInitiated(true);
    // return () => {
    //   console.log("cleaning");
    //   if (animRef.current) {
    //     animRef.current.kill();
    //   }
    //   const trigger = ScrollTrigger.getById("gradient");
    //   console.log("killing", trigger, animRef.current);
    //   if (trigger) trigger.kill();
    // };
  }, [pathname, startPathname, isInitiated, startPath]);
  return <GradientContainer ref={divRef} />;
};

export const Home: React.FC = ({ children }) => {
  const { pathname, hash } = useLocation();
  const { t } = useTranslation("menu");
  const { t: tPages } = useTranslation("pages");
  const pagesList = tPages("list") as PagesList;
  const { list: tourMenuList } = t("tour");
  const tourList = tourMenuList[0].list;
  const startPagePath = pagesList[PageName.start].path;
  const guidedTourPath = pagesList[PageName.guidedTour2020Slides].path;
  const defaultIndex = tourList.findIndex((item: SlideshowItemProps) => item.to === `${pathname}${hash}`);
  const [headerProps, setHeaderProps] = useState<HeaderProps | undefined>();
  if (pathname === guidedTourPath && defaultIndex === -1) {
    return <Redirect to={tourList[0].to} />;
  }
  useEffect(() => {
    const isStartPage = pathname === startPagePath;
    const isGuidedTour = pathname === guidedTourPath;
    setHeaderProps({
      theme: isStartPage || isGuidedTour ? TextColorThemeTypes.Default : TextColorThemeTypes.Light,
      transparent: !isStartPage,
    });
  }, [startPagePath, pathname, guidedTourPath]);

  return (
    <Root withHeader headerProps={{ withNav: true, ...headerProps }} theme={TextColorThemeTypes.Light}>
      <GradientAnimation startPath={startPagePath} startPathname={pagesList[PageName.intro].path} />
      <SvgAnimation startPathname={pagesList[PageName.intro].path} tourPathname={guidedTourPath} />
      {!isReactSnap && !process.env.REACT_APP_DISABLE_WEBGL && <Webgl startPathname={pagesList[PageName.intro].path} />}
      <ScrollContainer id="scroll">
        <TourProvider length={tourList.length} defaultIndex={defaultIndex}>
          <ContextMenu />
          {children}
        </TourProvider>
      </ScrollContainer>
    </Root>
  );
};
