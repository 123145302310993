import { Gallery } from "#components";
import { useTranslation } from "#hooks";
import { PreCaption, Wrapper } from "#ui";
import React from "react";

export const GesagtGetan: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { head } = tHome("gesagtgetan");
  return (
    <>
      <Wrapper>
        <PreCaption {...head} />
      </Wrapper>
      <Gallery />
    </>
  );
};
