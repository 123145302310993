import { triggerAnalyticsEvent } from "#helpers";
import { useOnClickOutside, usePopover, useTranslation } from "#hooks";
import { AnalyticsEventTypes, PageName, PagesList } from "#interfaces";
import { useDarkOverlay, useTour } from "#providers";
import { Color, expander, fadeIn, fadeOut, font, fontCondensed, slideRight } from "#shared/theme";
import { Burger, ButtonCircle, buttonCircleSizeByType, NavButton, Scrollbars, ScrollbarsTheme } from "#ui";
import React, { TransitionEvent, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Menu } from "../Menu/Menu";

const BURGER_OFFSET = 20;
const MENU_CLIP_OFFSET = BURGER_OFFSET + buttonCircleSizeByType.Default / 2;
interface ContainerProps {
  isClose: boolean;
}

const Container = styled.div<ContainerProps>`
  ${expander}
  position: fixed;
  z-index: 200;
  max-width: 420px;
  right: auto;
  color: ${Color.Black};
  width: calc(100% - 30px);
  font-family: ${font.condensed};
  z-index: ${({ isClose }): number => (isClose ? 10 : 105)};
  pointer-events: ${({ isClose }): string => (isClose ? "none" : "auto")};
`;
const Box = styled.div`
  ${expander}
  min-width: 312px;
  left: auto;
  width: 100%;
`;

interface SectionProps {
  isOpen: boolean;
}
const Section = styled.div<SectionProps>`
  height: 100%;
  display: flex;
  background-color: ${Color.Green};
  transition: clip-path 0.2s ease-in-out;
  clip-path: polygon(
    ${({ isOpen }) =>
      !isOpen
        ? `${MENU_CLIP_OFFSET}px 50%, ${MENU_CLIP_OFFSET}px 50%, ${MENU_CLIP_OFFSET}px 50%, ${MENU_CLIP_OFFSET}px 50%`
        : "0 0, 100% 0, 100% 100%, 0% 100%"}
  );
`;
const Inner = styled.div<SectionProps>`
  margin: auto 0;
  padding: 42px 10% 42px 22.5%;
  display: flex;
  flex-direction: column;
  ${({ isOpen }) => slideRight(isOpen)};
`;
const Control = styled.div`
  ${expander}
  right: auto;
  width: 50px;
  margin: auto;
  margin-left: ${BURGER_OFFSET}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Hamburger = styled.div`
  margin: 80px 0;
  pointer-events: auto;
`;
const StyledNavButton = styled.div<{ isVisible: boolean }>`
  height: 100px;
  pointer-events: auto;
  ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)};
`;
const StyledNavButtonInner = styled.div<{ rotated?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
  transform: rotate(${({ rotated = false }) => (rotated ? 90 : -90)}deg);
`;
const StyledBurger = styled(Burger)`
  position: absolute;
`;

const StopLabel = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 24px;
  display: flex;
  align-items: center;
  color: ${Color.Green};
  font-weight: bold;
  font-size: 16px;
  ${fontCondensed}
`;

export const ContextMenu: React.FC = () => {
  const containerRef = useRef(null);
  const sectionRef = useRef(null);
  const { pathname } = useLocation();
  const { index, set, setReady, prevReady, nextReady, stop, isStopped } = useTour();
  const isAutoplay = !isStopped;

  const handleTourPrev = () => {
    prevReady();

    triggerAnalyticsEvent(AnalyticsEventTypes.TourPrevSlide, { index: document.title });
  };

  const handleTourNext = () => {
    nextReady();

    triggerAnalyticsEvent(AnalyticsEventTypes.TourNextSlide, { index: document.title });
  };

  const { t } = useTranslation("menu");
  const { t: tPages } = useTranslation("pages");
  const { list: introMenuList } = t("intro");
  const { list: tourMenuList } = t("tour");
  const tourList = tourMenuList[0].list;
  const { open: openDarkOverlay, close: closeDarkOverlay, reset: resetDarkOverlay } = useDarkOverlay();
  const {
    isOpen: isPopoverOpen,
    isClose: isPopoverClose,
    open: openPopover,
    close: closePopover,
    reset: resetPopover,
  } = usePopover();
  const openMenu = () => {
    openPopover();
    openDarkOverlay();
  };
  const closeMenu = () => {
    closePopover();
    closeDarkOverlay();
  };
  const resetMenu = () => {
    resetPopover();
    resetDarkOverlay();
  };
  const handleBurgerClick = () => {
    if (isAutoplay) {
      stop();
    } else {
      if (isPopoverOpen) {
        closeMenu();
      } else {
        openMenu();
      }
    }
  };
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== sectionRef.current || isPopoverOpen) return;
    resetMenu();
  };
  const handleTourLinkClick = (n: number) => {
    closeMenu();
    if (index === -1) {
      set(n);
    } else {
      setReady(n);
    }
  };

  useOnClickOutside(containerRef, () => {
    if (!isPopoverOpen) return;
    closeMenu();
  });
  useEffect(() => {
    const pagesList = tPages("list") as PagesList;
    const { path: tourPathWithoutHash } = pagesList[PageName.guidedTour2020Slides];
    if (pathname !== tourPathWithoutHash && index !== -1) {
      set(-1);
    }
  }, [index, set, pathname, tPages]);
  return (
    <Container ref={containerRef} isClose={isPopoverClose}>
      <Box>
        <Section ref={sectionRef} isOpen={isPopoverOpen} onTransitionEnd={handleTransitionEnd}>
          <Scrollbars centerContent theme={ScrollbarsTheme.Inversed}>
            <Inner isOpen={isPopoverOpen}>
              <Menu list={introMenuList} withLastRowUnderline onClick={closeMenu} />
              <Menu list={tourMenuList} index={index} onClick={closeMenu} onLinkClick={handleTourLinkClick} />
            </Inner>
          </Scrollbars>
        </Section>
        <Control>
          <StyledNavButton isVisible={index > 0 && index !== -1 && !isAutoplay}>
            <StyledNavButtonInner>
              {tourList[index - 1] && (
                <Link to={tourList[index - 1].to}>
                  <NavButton onClick={handleTourPrev}>Back</NavButton>
                </Link>
              )}
            </StyledNavButtonInner>
          </StyledNavButton>
          <Hamburger>
            <ButtonCircle onClick={handleBurgerClick}>
              <StyledBurger isActive={isPopoverOpen} />
              {isAutoplay && <StopLabel>STOP</StopLabel>}
            </ButtonCircle>
          </Hamburger>
          <StyledNavButton isVisible={index < tourList.length - 1 && index !== -1 && !isAutoplay}>
            <StyledNavButtonInner rotated>
              {tourList[index + 1] && (
                <Link to={tourList[index + 1].to}>
                  <NavButton onClick={handleTourNext}>Next</NavButton>
                </Link>
              )}
            </StyledNavButtonInner>
          </StyledNavButton>
        </Control>
      </Box>
    </Container>
  );
};
