import React, { ReactComponentElement, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import { bp, bpTypes } from "#shared/theme";
import { HERO_SVG_POSITIONS, Languages } from "#constants";
import useMeasure from "react-use-measure";
import mergeRefs from "react-merge-refs";
import { rootElement } from "#helpers";
import { useResize } from "#hooks";
import { scrollHeroListDe, scrollHeroListEn } from "./ScrollHeroItems";
import { ScrollHeroItemsProps } from "#interfaces";

gsap.registerPlugin(ScrollTrigger);

export const scrollHeroItems: { [key: string]: ScrollHeroItemsProps[] } = {
  [Languages.de]: scrollHeroListDe,
  [Languages.en]: scrollHeroListEn,
};

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 100%;
  overflow: hidden;
  position: relative;
  pointer-events: none;
`;

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: 100%;
    font-weight: bold;
  }
`;

interface ItemProps {
  [p: string]: ReactComponentElement<"svg">;
}

export interface ScrollHeroProps {
  items: ItemProps;
}

export const ScrollHero: React.FC<ScrollHeroProps> = ({ items }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [screen, setScreen] = useState(bpTypes.i);
  const largeDesktop = useMediaQuery({ query: bp.l });
  const desktop = useMediaQuery({ query: bp.m });
  const [containerMeasureRef, { width: containerWidthValue }] = useMeasure();
  const containerWidth = Math.round(containerWidthValue);
  const [wrapperMeasureRef, { width: wrapperWidthValue }] = useMeasure();
  const wrapperWidth = Math.round(wrapperWidthValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const diff = containerWidth - wrapperWidth;
  const diffPercent = diff < containerWidth ? ((containerWidth - wrapperWidth) * 2) / containerWidth : 1;
  const tl = useMemo(() => gsap.timeline(), []);
  useEffect(() => {
    if (largeDesktop) {
      setScreen(bpTypes.l);
    } else if (desktop) {
      setScreen(bpTypes.m);
    } else {
      setScreen(bpTypes.i);
    }
  }, [largeDesktop, desktop]);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 700);
  }, []);
  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!isLoaded) return;
    tl.clear();
    tl.fromTo(containerRef.current, { x: 0 }, { x: -diff }, 0)
      .fromTo("#story-header-text-right", { x: HERO_SVG_POSITIONS[screen].textRight * diffPercent }, { x: 0 }, 0)
      .fromTo("#story-header-text-left", { x: 0 }, { x: HERO_SVG_POSITIONS[screen].textLeft * diffPercent }, 0);
    const scrollTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          pin: true,
          pinSpacing: true,
          pinType: "fixed",
          start: "top",
          end: "bottom 0",
          invalidateOnRefresh: true,
          scrub: 1,
          scroller: rootElement,
        },
      })
      .add(tl);
    ScrollTrigger.refresh(true);
    return () => {
      tl.kill();
      scrollTl.kill();
    };
  }, [wrapperRef, screen, diff, diffPercent, isLoaded, tl]);
  const { height } = useResize();
  return (
    <Wrapper ref={mergeRefs([wrapperRef, wrapperMeasureRef])} height={height}>
      <Container ref={mergeRefs([containerRef, containerMeasureRef])}>{items[screen]}</Container>
    </Wrapper>
  );
};
