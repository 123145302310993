import React from "react";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";
import styled from "styled-components/macro";
import { Grid } from "../Grid/Grid";

const Container = styled.figure`
  margin: 0;
`;
const Frame = styled.div`
  overflow: hidden;
  border-radius: 4px;
  @media ${bp.m} {
    margin-right: -${(1 / 7) * 100}%;
  }
  @media ${bp.t} {
    margin-right: 0;
  }
`;
const FrameWrap = styled.div`
  height: 0;
  padding-bottom: 68%;
  position: relative;
`;
const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
const Caption = styled.figcaption`
  margin-top: 35px;
  ${gridColumnStart(2)}
  ${gridColumnWidth(8)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(5)}
  }
`;

export interface ImageProps {
  src: string;
}

export const Image: React.FC<ImageProps> = ({ src, children }) => {
  return (
    <Container>
      <Frame>
        <FrameWrap>
          <Img src={src} />
        </FrameWrap>
      </Frame>
      <Grid columns={[10, 7]}>
        <Caption>{children}</Caption>
      </Grid>
    </Container>
  );
};
