import { normalize } from "polished";
import { createGlobalStyle } from "styled-components/macro";
import { bp, Color, defTransition, font, fontFace } from "#shared/theme";
import "overlayscrollbars/css/OverlayScrollbars.css";

const loadFonts = (): void => {
  const head = document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.innerHTML = fontFace;
  head.appendChild(style);
};
loadFonts();

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
    ::selection {
      background-color: ${Color.Green};
    }
  }
  html, body {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    overscroll-behavior: none;
    pointer-events: none;
  }
  body, .sb-show-main.sb-main-fullscreen {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: ${font.serif};
    color: ${Color.Black};
    line-height: 1.5;
    font-size: 16px;
    max-width: 100%;
    background-color: ${Color.White};
  }
  a, input, button {
    outline: none;
    color: inherit;
    text-decoration: inherit;
  }
  a {
    transition: color ${defTransition};
  }
  #root {
    pointer-events: auto;
    z-index: 1;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
  }
  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }
  p + p {
    margin-top: 1em;
  }
  
  ul, ol {
    font-size: 22px;
    line-height: 1.55;
    margin-top: 34px;
    margin-bottom: 34px;
    padding-left: 25px;
    @media ${bp.p} {
      font-size: 24px;
      line-height: 1.5;
    }
    @media ${bp.d} {
      font-size: 28px;
      line-height: 1.43;
    }
  }
  
  ol {
    padding-left: 30px;
  }
`;
