import React, { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { bp, expander } from "#shared/theme";
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  position: relative;
  height: 100%;
`;
const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - 50px);
  @media ${bp.o} {
    width: 75vw;
  }
  @media ${bp.m} {
    width: 45vw;
  }
  @media ${bp.l} {
    width: 30vw;
  }
`;

const gaps = [20, 30];

const Text = styled.div`
  column-fill: auto;
  height: 100%;
  column-gap: ${gaps[0]}px;
  @media ${bp.m} {
    column-gap: ${gaps[1]}px;
  }
  p + p {
    margin-top: 20px;
  }
  img {
    max-width: 100%;
    user-drag: none;
  }
`;
const TextGhost = styled(Text)`
  ${expander}
  bottom: auto;
  visibility: hidden;
  height: auto;
`;

export const ColumnCopytext: React.FC<{ copytext: string }> = ({ copytext }) => {
  const [cols, setCols] = useState<number>(1);
  const [width, setWidth] = useState<number>(1);
  const [textGhostRef, { height: textGhostHeight, width: textGhostWidth }] = useMeasure();
  const [containerRef, { height: containerHeight }] = useMeasure();
  const isDesktop = useMediaQuery({ query: bp.m });
  useEffect(() => {
    if (textGhostHeight === 0 || containerHeight === 0) return;
    const columns = Math.ceil(textGhostHeight / containerHeight);
    setCols(columns);
    setWidth(textGhostWidth * columns + gaps[Number(isDesktop)] * (columns - 1));
  }, [textGhostHeight, containerHeight, isDesktop, textGhostWidth]);
  return (
    <Container ref={containerRef} style={{ width }}>
      <Text dangerouslySetInnerHTML={{ __html: copytext }} style={{ columnCount: cols }} />
      <Box>
        <TextGhost dangerouslySetInnerHTML={{ __html: copytext }} ref={textGhostRef} />
      </Box>
    </Container>
  );
};
