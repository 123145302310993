import { RefObject, useEffect } from "react";

type UseOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: () => void,
  exceptRefs?: RefObject<HTMLElement>[]
) => void;

export const useOnClickOutside: UseOnClickOutside = (ref, handler, exceptRefs) => {
  useEffect(() => {
    const listener: EventListener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      // Do nothing if clicking other exceptional ref's elements or their descendent elements
      if (exceptRefs) {
        for (let i = 0; i < exceptRefs?.length; i++) {
          if (!exceptRefs[i].current || exceptRefs[i].current?.contains(event.target as Node)) {
            return;
          }
        }
      }

      handler();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return (): void => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, exceptRefs]);
};
