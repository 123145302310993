import { chartMainItemDuration } from "#constants";
import { useMarginAnimation } from "#hooks";
import { ChartProps } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgTextSmall } from "#ui";
import React, { useRef } from "react";

export const MarginPath: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const currentPathRef = useRef<SVGPathElement>(null);
  const minLineRef = useRef<SVGPathElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  const maxLineRef = useRef<SVGPathElement>(null);
  const minTextRef = useRef<SVGGElement>(null);
  const maxTextRef = useRef<SVGGElement>(null);
  useMarginAnimation(
    {
      ...props,
      maxValue: Number(labels?.maxValue),
      currentValue: Number(labels?.currentValue),
      maxMargin: Number(labels?.maxMargin),
      minMargin: Number(labels?.minMargin),
      minDegree: -35.3,
      maxDegree: 250.9,
    },
    { currentPathRef, lineRef, minLineRef, maxLineRef, minTextRef, maxTextRef },
    chartMainItemDuration
  );
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="165" x="283" y="271.5">
        <circle cx="448" cy="272" r="123.5" />
        <circle cx="448" cy="272" fill="none" r="123.5" stroke="#000" strokeMiterlimit="10" strokeWidth="90" />
        <circle cx="448" cy="272" fill="none" r="123.5" stroke="#fff" strokeMiterlimit="10" strokeWidth="35" />
      </mask>
      <path
        ref={currentPathRef}
        opacity="0.2"
        fill="none"
        stroke={Color.White}
        strokeWidth="35"
        d="M347.324,343.547
		C332.952,323.361,324.5,298.667,324.5,272c0-68.207,55.293-123.5,123.5-123.5S571.5,203.793,571.5,272
		c0,26.667-8.452,51.361-22.824,71.547"
      />
      <path ref={minLineRef} d="m448 272h-165" mask={`url(#${maskID})`} stroke={Color.White} />
      <path ref={maxLineRef} d="m448 272h-165" mask={`url(#${maskID})`} stroke={Color.White} />
      <path ref={lineRef} d="m448 272h-165" mask={`url(#${maskID})`} stroke={Color.BlackLight} />
      <SvgTextSmall ref={minTextRef}>
        <path d="m448 272h-165" opacity="0" />
        <text fill={Color.BlackLight} fontSize="12" transform="matrix(-6.524900e-04 -1 1 -6.524900e-04 355.2128 275)">
          {labels?.minMargin}
        </text>
      </SvgTextSmall>
      <SvgTextSmall ref={maxTextRef}>
        <path d="m448 272h-165" opacity="0" />
        <text fill={Color.BlackLight} fontSize="12" transform="matrix(-6.524900e-04 -1 1 -6.524900e-04 355.2128 275)">
          {labels?.maxMargin}
        </text>
      </SvgTextSmall>
    </g>
  );
};
