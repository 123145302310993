import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import mergeRefs from "react-merge-refs";
import styled from "styled-components/macro";
import useMeasure from "react-use-measure";
import { gsap, Linear } from "gsap";
import { Power3 } from "gsap/gsap-core";
import { Color } from "#shared/theme";
import { rootElement } from "#helpers";

const Svg = styled.svg`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

type RefElement = (element: SVGElement | HTMLElement | null) => void;

interface ChildrenProps {
  valueInputRef1: RefElement;
  valueInputRef2: RefElement;
  valueOutputRef1: RefElement;
  valueOutputRef2: RefElement;
  turboInputRef: RefElement;
  turboOutputRef: RefElement;
  pathInputLength: number;
  pathOutputLength: number;
}

interface SvgLinesProps {
  turboSvgRef: RefObject<SVGSVGElement>;
  contentRef: RefObject<HTMLDivElement>;
  inputDefaultRef: RefObject<SVGLineElement>;
  inputFillRef1: RefObject<SVGLineElement>;
  inputFillRef2: RefObject<SVGLineElement>;
  outputDefaultRef: RefObject<SVGLineElement>;
  outputFillRef1: RefObject<SVGLineElement>;
  outputFillRef2: RefObject<SVGLineElement>;
  valuesInputRef: RefObject<HTMLDivElement>;
  valuesOutputRef: RefObject<HTMLDivElement>;
  isActive: boolean;
  children: ({ valueInputRef1 }: ChildrenProps) => JSX.Element;
}

export const SvgLines: React.FC<SvgLinesProps> = ({
  children,
  turboSvgRef,
  contentRef,
  inputDefaultRef,
  outputDefaultRef,
  inputFillRef1,
  inputFillRef2,
  outputFillRef1,
  outputFillRef2,
  valuesInputRef,
  valuesOutputRef,
  isActive = false,
}) => {
  const svgRefAll = useRef<SVGElement>(null);
  const [svgRef, { width: svgWidth, height: svgHeight, left: svgLeft }, forceRefresh] = useMeasure();
  const [
    valueInputRef1,
    { bottom: valueInputBottom1, right: valueInputRight1, left: valueInputLeft1 },
    valueInputRefresh1,
  ] = useMeasure();
  const [
    valueInputRef2,
    { bottom: valueInputBottom2, right: valueInputRight2, left: valueInputLeft2 },
    valueInputRefresh2,
  ] = useMeasure();
  const [
    valueOutputRef1,
    { bottom: valueOutputBottom1, right: valueOutputRight1, left: valueOutputLeft1 },
    valueOutputRefresh1,
  ] = useMeasure();
  const [
    valueOutputRef2,
    { bottom: valueOutputBottom2, right: valueOutputRight2, left: valueOutputLeft2 },
    valueOutputRefresh2,
  ] = useMeasure();
  const [turboInputRef, { bottom: turboInputBottom, left: turboInputLeft }] = useMeasure();
  const [turboOutputRef, { top: turboOutputTop, right: turboOutputRight }] = useMeasure();

  const [isLoaded, setLoaded] = useState(false);
  const [isDefaultLinesReady, setDefaultLinesReady] = useState(false);
  const [pathInputLength, setPathInputLength] = useState(0);
  const [lineInputLength1, setLineInputLength1] = useState(0);
  const [lineInputLength2, setLineInputLength2] = useState(0);
  const [pathOutputLength, setPathOutputLength] = useState(0);
  const [lineOutputLength1, setLineOutputLength1] = useState(0);
  const [lineOutputLength2, setLineOutputLength2] = useState(0);

  const inputPolyline1 = useRef<SVGPolylineElement>(null);
  const inputPolyline2 = useRef<SVGPolylineElement>(null);
  const outputPolyline1 = useRef<SVGPolylineElement>(null);
  const outputPolyline2 = useRef<SVGPolylineElement>(null);

  const openTl = useMemo(() => gsap.timeline({ defaults: { ease: Linear.easeNone }, paused: true }), []);
  const closeTl = useMemo(
    () => gsap.timeline({ defaults: { ease: Linear.easeNone, force3D: true, willChange: "opacity" }, paused: true }),
    []
  );

  useEffect(() => {
    if (!isDefaultLinesReady) return;
    setLineInputLength1(Math.round(inputPolyline1.current?.getTotalLength() || 0));
    setLineInputLength2(Math.round(inputPolyline2.current?.getTotalLength() || 0));
    setLineOutputLength1(Math.round(outputPolyline1.current?.getTotalLength() || 0));
    setLineOutputLength2(Math.round(outputPolyline2.current?.getTotalLength() || 0));
  }, [
    valueInputBottom1,
    valueInputRight1,
    valueInputLeft1,
    valueInputBottom2,
    valueInputRight2,
    valueInputLeft2,
    valueOutputBottom1,
    valueOutputRight1,
    valueOutputLeft1,
    valueOutputBottom2,
    valueOutputRight2,
    valueOutputLeft2,
    isDefaultLinesReady,
  ]);

  const initOpenAnimation = () => {
    openTl.clear();
    openTl
      .set(svgRefAll.current, { opacity: 1 })
      .to(rootElement, { duration: 0.3, scrollTo: { y: 0 }, ease: Power3.easeInOut })
      .to(
        [valuesInputRef.current, valuesOutputRef.current],
        { height: "auto", duration: 0.3, ease: Power3.easeInOut },
        "valuesTime"
      )
      .to(
        valuesInputRef.current,
        {
          opacity: 1,
          duration: 0.4,
          onComplete: () => {
            valueInputRefresh1();
            valueInputRefresh2();
            valueOutputRefresh1();
            valueOutputRefresh2();
          },
        },
        "valuesTime"
      )
      .fromTo(
        inputPolyline1.current,
        { opacity: 1, strokeDasharray: lineInputLength1, strokeDashoffset: lineInputLength1 },
        { strokeDasharray: lineInputLength1, strokeDashoffset: lineInputLength1 * 2, duration: 0.3 },
        "inputLineTime"
      )
      .fromTo(
        inputPolyline2.current,
        { opacity: 1, strokeDasharray: lineInputLength2, strokeDashoffset: lineInputLength2 },
        { strokeDasharray: lineInputLength2, strokeDashoffset: lineInputLength2 * 2, duration: 0.3 },
        "inputLineTime"
      )
      .fromTo(
        [inputFillRef1.current, inputFillRef2.current],
        { opacity: 1, strokeDasharray: `0 ${pathInputLength}` },
        { duration: 0.3, strokeDasharray: `${pathInputLength} ${pathInputLength}` }
      )
      .fromTo(
        [outputFillRef1.current, outputFillRef2.current],
        {
          opacity: 1,
          strokeDasharray: `0 ${pathOutputLength}`,
        },
        { duration: 0.3, strokeDasharray: `${pathOutputLength} ${pathOutputLength}` }
      )
      .fromTo(
        outputPolyline1.current,
        { opacity: 1, strokeDasharray: lineOutputLength1, strokeDashoffset: lineOutputLength1 },
        { strokeDasharray: lineOutputLength1, strokeDashoffset: lineOutputLength1 * 2, duration: 0.3 },
        "outputLineTime"
      )
      .fromTo(
        outputPolyline2.current,
        { opacity: 1, strokeDasharray: lineOutputLength2, strokeDashoffset: lineOutputLength2 },
        { strokeDasharray: lineOutputLength2, strokeDashoffset: lineOutputLength2 * 2, duration: 0.3 },
        "outputLineTime"
      )
      .to(valuesOutputRef.current, { opacity: 1 });
  };

  const initCloseAnimation = () => {
    closeTl.clear();
    closeTl
      .fromTo(
        [
          inputPolyline1.current,
          inputPolyline2.current,
          outputPolyline1.current,
          outputPolyline2.current,
          inputFillRef1.current,
          inputFillRef2.current,
          outputFillRef1.current,
          outputFillRef2.current,
        ],
        { opacity: 1 },
        { opacity: 0, duration: 0 },
        0.3
      )
      .to(
        [valuesInputRef.current, valuesOutputRef.current],
        {
          height: 0,
          opacity: 0,
          duration: 0.3,
          ease: Power3.easeInOut,
        },
        0.3
      );
  };

  useEffect(() => {
    forceRefresh();
    setPathInputLength(Math.round(inputDefaultRef.current?.getTotalLength() || 0));
    setPathOutputLength(Math.round(outputDefaultRef.current?.getTotalLength() || 0));
    gsap.timeline({ defaults: { force3D: true, willChange: "transform" } }).to([turboSvgRef.current], {
      opacity: 1,
      duration: 0.4,
      delay: 0.5,
      onComplete: () => {
        setLoaded(true);
      },
    });
  }, [forceRefresh, inputDefaultRef, outputDefaultRef, pathInputLength, pathOutputLength, turboSvgRef]);

  useEffect(() => {
    if (!isLoaded) return;
    gsap
      .timeline({ defaults: { force3D: true, willChange: "transform" } })
      .to(inputDefaultRef.current, { duration: 0.5, strokeDasharray: `${pathInputLength} ${pathInputLength}` })
      .to(outputDefaultRef.current, { duration: 0.5, strokeDasharray: `${pathOutputLength} 0` })
      .to(contentRef.current, {
        duration: 0.3,
        opacity: 1,
        onComplete: () => {
          setDefaultLinesReady(true);
        },
      });
  }, [isLoaded]); // eslint-disable-line

  useEffect(() => {
    if (isActive) {
      initOpenAnimation();
      closeTl.pause().clear();
      openTl.play();
    } else {
      initCloseAnimation();
      openTl.pause().clear();
      closeTl.play();
    }
  }, [isActive, lineInputLength1, lineInputLength2, lineOutputLength1, lineOutputLength2]); // eslint-disable-line

  return (
    <>
      <Svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} ref={mergeRefs([svgRef, svgRefAll])}>
        <polyline
          ref={inputPolyline1}
          points={`${turboInputLeft - svgLeft},${turboInputBottom} ${valueInputRight1 - svgLeft},${valueInputBottom1} ${
            valueInputLeft1 - svgLeft
          },${valueInputBottom1}`}
          stroke={Color.GreenDark}
          fill="none"
          strokeDashoffset={lineInputLength1}
          strokeDasharray={lineInputLength1}
        />
        <polyline
          ref={inputPolyline2}
          points={`${turboInputLeft - svgLeft},${turboInputBottom} ${valueInputRight2 - svgLeft},${valueInputBottom2} ${
            valueInputLeft2 - svgLeft
          },${valueInputBottom2}`}
          stroke={Color.GreenDark}
          fill="none"
          strokeDashoffset={lineInputLength2}
          strokeDasharray={lineInputLength2}
        />
        <polyline
          ref={outputPolyline1}
          points={`${valueOutputRight1 - svgLeft},${valueOutputBottom1} ${
            valueOutputLeft1 - svgLeft
          },${valueOutputBottom1} ${turboOutputRight - svgLeft},${turboOutputTop} `}
          stroke={Color.Green}
          fill="none"
          strokeDashoffset={lineOutputLength1}
          strokeDasharray={lineOutputLength1}
        />
        <polyline
          ref={outputPolyline2}
          points={`${valueOutputRight2 - svgLeft},${valueOutputBottom2} ${
            valueOutputLeft2 - svgLeft
          },${valueOutputBottom2} ${turboOutputRight - svgLeft},${turboOutputTop}`}
          stroke={Color.Green}
          fill="none"
          strokeDashoffset={lineOutputLength2}
          strokeDasharray={lineOutputLength2}
        />
      </Svg>
      {children({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
      })}
    </>
  );
};
