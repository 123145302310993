import { AnalyticsEventTypes } from "#interfaces";

export const financialStatements = {
  sections: [
    {
      copytext: "<h2>GEWINN­-UND-­VERLUST-­RECHNUNG DES KONZERNS UND DER SEGMENTE</h2>",
      links: [
        {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE_GuV.pdf",
          children: "PDF",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsIncomeDownloadPDF,
        },
        {
          href: "/data/xls/de/BMW-IKB20_DE_Group_GuV.xlsx",
          children: "XLS",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsIncomeDownloadXLS,
        },
      ],
      table: {
        head: [
          [
            { children: "" },
            { children: "" },
            { children: "Konzern", props: { colSpan: 2 } },
            { children: "Automobile (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
            { children: "Motorräder (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
            { children: "Finanzdienstleistungen (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
            { children: "Sonstige Gesellschaften (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
            { children: "Konsolidierungen (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
          ],
          [
            { children: "in­ Mio. €", props: { bold: false, type: "th" } },
            { children: "Anhang" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
          ],
        ],
        data: [
          [
            { children: "Umsatzerlöse", props: { type: "th" } },
            { children: "7" },
            { children: "98.990", props: { accented: true } },
            { children: "104.210" },
            { children: "80.853", props: { accented: true } },
            { children: "91.682" },
            { children: "2.284", props: { accented: true } },
            { children: "2.368" },
            { children: "30.044", props: { accented: true } },
            { children: "29.598" },
            { children: "3", props: { accented: true } },
            { children: "5" },
            { children: "– 14.194", props: { accented: true } },
            { children: "– 19.443" },
          ],
          [
            { children: "Umsatzkosten", props: { type: "th" } },
            { children: "8" },
            { children: "– 85.408", props: { accented: true } },
            { children: "– 86.147" },
            { children: "– 71.456", props: { accented: true } },
            { children: "– 78.062" },
            { children: "– 1.941", props: { accented: true } },
            { children: "– 1.911" },
            { children: "– 26.958", props: { accented: true } },
            { children: "– 25.938" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "14.947", props: { accented: true } },
            { children: "19.764" },
          ],
          [
            { children: "Bruttoergebnis vom Umsatz", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "13.582", props: { accented: true, bold: true, thickLine: true } },
            { children: "18.063", props: { bold: true, thickLine: true } },
            { children: "9.397", props: { accented: true, bold: true, thickLine: true } },
            { children: "13.620", props: { bold: true, thickLine: true } },
            { children: "343", props: { accented: true, bold: true, thickLine: true } },
            { children: "457", props: { bold: true, thickLine: true } },
            { children: "3.086", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.660", props: { bold: true, thickLine: true } },
            { children: "3", props: { accented: true, bold: true, thickLine: true } },
            { children: "5", props: { bold: true, thickLine: true } },
            { children: "753", props: { accented: true, bold: true, thickLine: true } },
            { children: "321", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Vertriebskosten und allgemeine Verwaltungskosten", props: { type: "th" } },
            { children: "9" },
            { children: "– 8.795", props: { accented: true } },
            { children: "– 9.367" },
            { children: "– 7.237", props: { accented: true } },
            { children: "– 7.762" },
            { children: "– 240", props: { accented: true } },
            { children: "– 264" },
            { children: "– 1.326", props: { accented: true } },
            { children: "– 1.341" },
            { children: "– 26", props: { accented: true } },
            { children: "– 24" },
            { children: "34", props: { accented: true } },
            { children: "24" },
          ],
          [
            { children: "Sonstige betriebliche Erträge", props: { type: "th" } },
            { children: "10" },
            { children: "916", props: { accented: true } },
            { children: "1.031" },
            { children: "931", props: { accented: true } },
            { children: "976" },
            { children: "2", props: { accented: true } },
            { children: "2" },
            { children: "34", props: { accented: true } },
            { children: "73" },
            { children: "125", props: { accented: true } },
            { children: "173" },
            { children: "– 176", props: { accented: true } },
            { children: "– 193" },
          ],
          [
            { children: "Sonstige betriebliche Aufwendungen", props: { type: "th" } },
            { children: "10" },
            { children: "– 873", props: { accented: true } },
            { children: "– 2.316" },
            { children: "– 929", props: { accented: true } },
            { children: "– 2.335" },
            { children: "– 2", props: { accented: true } },
            { children: "– 1" },
            { children: "– 73", props: { accented: true } },
            { children: "– 80" },
            { children: "– 66", props: { accented: true } },
            { children: "– 125" },
            { children: "197", props: { accented: true } },
            { children: "225" },
          ],
          [
            { children: "Ergebnis vor Finanzergebnis", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "4.830", props: { accented: true, bold: true, thickLine: true } },
            { children: "7.411", props: { bold: true, thickLine: true } },
            { children: "2.162", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.499", props: { bold: true, thickLine: true } },
            { children: "103", props: { accented: true, bold: true, thickLine: true } },
            { children: "194", props: { bold: true, thickLine: true } },
            { children: "1.721", props: { accented: true, bold: true, thickLine: true } },
            { children: "2.312", props: { bold: true, thickLine: true } },
            { children: "36", props: { accented: true, bold: true, thickLine: true } },
            { children: "29", props: { bold: true, thickLine: true } },
            { children: "808", props: { accented: true, bold: true, thickLine: true } },
            { children: "377", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Ergebnis aus Equity-Bewertung", props: { type: "th" } },
            { children: "24" },
            { children: "920", props: { accented: true } },
            { children: "136" },
            { children: "920", props: { accented: true } },
            { children: "136" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Zinsen und ähnliche Erträge", props: { type: "th" } },
            { children: "11" },
            { children: "116", props: { accented: true } },
            { children: "179" },
            { children: "236", props: { accented: true } },
            { children: "420" },
            { children: "–", props: { accented: true } },
            { children: "1" },
            { children: "3", props: { accented: true } },
            { children: "4" },
            { children: "1.169", props: { accented: true } },
            { children: "1.515" },
            { children: "– 1.292", props: { accented: true } },
            { children: "– 1.761" },
          ],
          [
            { children: "Zinsen und ähnliche Aufwendungen", props: { type: "th" } },
            { children: "11" },
            { children: "– 458", props: { accented: true } },
            { children: "– 499" },
            { children: "– 613", props: { accented: true } },
            { children: "– 737" },
            { children: "– 3", props: { accented: true } },
            { children: "– 8" },
            { children: "– 4", props: { accented: true } },
            { children: "– 7" },
            { children: "– 1.232", props: { accented: true } },
            { children: "– 1.419" },
            { children: "1.394", props: { accented: true } },
            { children: "1.672" },
          ],
          [
            { children: "Übriges Finanzergebnis", props: { type: "th" } },
            { children: "12" },
            { children: "– 186", props: { accented: true } },
            { children: "– 109" },
            { children: "17", props: { accented: true } },
            { children: "149" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "5", props: { accented: true } },
            { children: "– 37" },
            { children: "– 208", props: { accented: true } },
            { children: "– 221" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Finanzergebnis", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "392", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 293", props: { bold: true, thickLine: true } },
            { children: "560", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 32", props: { bold: true, thickLine: true } },
            { children: "– 3", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 7", props: { bold: true, thickLine: true } },
            { children: "4", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 40", props: { bold: true, thickLine: true } },
            { children: "– 271", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 125", props: { bold: true, thickLine: true } },
            { children: "102", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 89", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Ergebnis vor Steuern", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "5.222", props: { accented: true, bold: true, thickLine: true } },
            { children: "7.118", props: { bold: true, thickLine: true } },
            { children: "2.722", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.467", props: { bold: true, thickLine: true } },
            { children: "100", props: { accented: true, bold: true, thickLine: true } },
            { children: "187", props: { bold: true, thickLine: true } },
            { children: "1.725", props: { accented: true, bold: true, thickLine: true } },
            { children: "2.272", props: { bold: true, thickLine: true } },
            { children: "– 235", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 96", props: { bold: true, thickLine: true } },
            { children: "910", props: { accented: true, bold: true, thickLine: true } },
            { children: "288", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Ertragsteuern", props: { type: "th" } },
            { children: "13" },
            { children: "– 1.365", props: { accented: true } },
            { children: "– 2.140" },
            { children: "– 713", props: { accented: true } },
            { children: "– 1.354" },
            { children: "– 26", props: { accented: true } },
            { children: "– 56" },
            { children: "– 456", props: { accented: true } },
            { children: "– 672" },
            { children: "62", props: { accented: true } },
            { children: "29" },
            { children: "– 232", props: { accented: true } },
            { children: "– 87" },
          ],
          [
            {
              children: "Ergebnis aus fortgeführten Geschäftsbereichen",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "3.857", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.978", props: { bold: true, thickLine: true } },
            { children: "2.009", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.113", props: { bold: true, thickLine: true } },
            { children: "74", props: { accented: true, bold: true, thickLine: true } },
            { children: "131", props: { bold: true, thickLine: true } },
            { children: "1.269", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.600", props: { bold: true, thickLine: true } },
            { children: "– 173", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 67", props: { bold: true, thickLine: true } },
            { children: "678", props: { accented: true, bold: true, thickLine: true } },
            { children: "201", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Ergebnis aus aufgegebenem Geschäftsbereich", props: { type: "th" } },
            { children: "" },
            { children: "–", props: { accented: true } },
            { children: "44" },
            { children: "–", props: { accented: true } },
            { children: "44" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Jahresüberschuss / ­-fehlbetrag", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "3.857", props: { accented: true, bold: true, thickLine: true } },
            { children: "5.022", props: { bold: true, thickLine: true } },
            { children: "2.009", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.157", props: { bold: true, thickLine: true } },
            { children: "74", props: { accented: true, bold: true, thickLine: true } },
            { children: "131", props: { bold: true, thickLine: true } },
            { children: "1.269", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.600", props: { bold: true, thickLine: true } },
            { children: "– 173", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 67", props: { bold: true, thickLine: true } },
            { children: "678", props: { accented: true, bold: true, thickLine: true } },
            { children: "201", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Ergebnisanteil fremder Gesellschafter", props: { type: "th" } },
            { children: "" },
            { children: "82", props: { accented: true } },
            { children: "107" },
            { children: "17", props: { accented: true } },
            { children: "30" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "67", props: { accented: true } },
            { children: "77" },
            { children: "– 2", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Ergebnisanteil der Aktionäre der BMW AG", props: { type: "th", bold: true, thickLine: true } },
            { children: "31", props: { thickLine: true } },
            { children: "3.775", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.915", props: { bold: true, thickLine: true } },
            { children: "1.992", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.127", props: { bold: true, thickLine: true } },
            { children: "74", props: { accented: true, bold: true, thickLine: true } },
            { children: "131", props: { bold: true, thickLine: true } },
            { children: "1.202", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.523", props: { bold: true, thickLine: true } },
            { children: "– 171", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 67", props: { bold: true, thickLine: true } },
            { children: "678", props: { accented: true, bold: true, thickLine: true } },
            { children: "201", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "<strong>Unverwässertes Ergebnis je Stammaktie</strong> in €",
              props: { type: "th" },
            },
            { children: "14" },
            { children: "5,73", props: { accented: true } },
            { children: "7,47" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            {
              children: "<strong>Unverwässertes Ergebnis je Vorzugsaktie</strong> in €",
              props: { type: "th" },
            },
            { children: "14" },
            { children: "5,75", props: { accented: true } },
            { children: "7,49" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Verwässerungseffekte", props: { type: "th" } },
            { children: "" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            {
              children: "<strong>Verwässertes Ergebnis je Stammaktie</strong> in €",
              props: { type: "th" },
            },
            { children: "14" },
            { children: "5,73", props: { accented: true } },
            { children: "7,47" },
            { children: "", props: { accented: true } },
            { children: "", props: { bold: true } },
            { children: "", props: { accented: true } },
            { children: "", props: { bold: true } },
            { children: "", props: { accented: true } },
            { children: "", props: { bold: true } },
            { children: "", props: { accented: true } },
            { children: "", props: { bold: true } },
            { children: "", props: { accented: true } },
            { children: "", props: { bold: true } },
          ],
          [
            {
              children: "<strong>Verwässertes Ergebnis je Vorzugsaktie</strong> in €",
              props: { type: "th" },
            },
            { children: "14" },
            { children: "5,75", props: { accented: true } },
            { children: "7,49" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
        ],
      },
    },
    {
      copytext: "<h2>GESAMTERGEBNISRECHNUNG DES KONZERNS</h2>",
      links: [
        {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE_Gesamtergebnis.pdf",
          children: "PDF",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsConsolidationDownloadPDF,
        },
        {
          href: "/data/xls/de/BMW-IKB20_DE_Group_Gesamtergebnis.xlsx",
          children: "XLS",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsConsolidationDownloadXLS,
        },
      ],
      table: {
        head: [
          [
            { children: "in Mio. €" },
            { children: "Anhang" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
          ],
        ],
        data: [
          [
            { children: "Jahresüberschuss", props: { type: "th", thickLine: true, bold: true } },
            { children: "", props: { thickLine: true } },
            { children: "3.857", props: { accented: true, bold: true, thickLine: true } },
            { children: "5.022", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Neubewertungen der Nettoschuld aus leistungsorientierten Versorgungsplänen",
              props: { type: "th" },
            },
            { children: "32" },
            { children: "– 354", props: { accented: true } },
            { children: "– 1.254" },
          ],
          [
            {
              children: "Latente Steuern",
              props: { type: "th" },
            },
            { children: "" },
            { children: "139", props: { accented: true } },
            { children: "387" },
          ],
          [
            {
              children: "Bestandteile, die zukünftig nicht in die Gewinn­-und-­Verlust-­Rechnung umgegliedert werden",
              props: { type: "th", thickLine: true, bold: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "– 215", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 867", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Wertpapiere (erfolgsneutral zum beizulegenden Zeitwert)",
              props: { type: "th" },
            },
            { children: "" },
            { children: "7", props: { accented: true } },
            { children: "42" },
          ],
          [
            {
              children: "Derivative Finanzinstrumente",
              props: { type: "th" },
            },
            { children: "" },
            { children: "991", props: { accented: true } },
            { children: "– 706" },
          ],
          [
            {
              children: "Kosten der Sicherungsmaßnahmen",
              props: { type: "th" },
            },
            { children: "" },
            { children: "201", props: { accented: true } },
            { children: "125" },
          ],
          [
            {
              children: "Sonstiges Ergebnis aus at Equity bewerteten Beteiligungen",
              props: { type: "th" },
            },
            { children: "" },
            { children: "106", props: { accented: true } },
            { children: "– 3" },
          ],
          [
            {
              children: "Latente Steuern",
              props: { type: "th" },
            },
            { children: "" },
            { children: "– 444", props: { accented: true } },
            { children: "171" },
          ],
          [
            {
              children: "Währungsumrechnung ausländischer Tochterunternehmen",
              props: { type: "th" },
            },
            { children: "" },
            { children: "– 1.283", props: { accented: true } },
            { children: "544" },
          ],
          [
            {
              children:
                "Bestandteile, die zukünftig möglicherweise in die Gewinn­-und-­Verlust-­Rechnung umgegliedert werden",
              props: { type: "th", thickLine: true, bold: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "– 422", props: { accented: true, bold: true, thickLine: true } },
            { children: "173", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Sonstiges Ergebnis nach Steuern",
              props: { type: "th", thickLine: true, bold: true },
            },
            { children: "19", props: { thickLine: true } },
            { children: "– 637", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 694", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Gesamtergebnis",
              props: { type: "th", thickLine: true, bold: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "3.220", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.328", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Gesamtergebnisanteil fremder Gesellschafter",
              props: { type: "th" },
            },
            { children: "" },
            { children: "82", props: { accented: true } },
            { children: "107" },
          ],
          [
            {
              children: "Gesamtergebnisanteil der Aktionäre der BMW AG",
              props: { type: "th", thickLine: true, bold: true },
            },
            { children: "31", props: { thickLine: true } },
            { children: "3.138", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.221", props: { bold: true, thickLine: true } },
          ],
        ],
      },
    },
    {
      copytext: "<h2>BILANZ DES KONZERNS UND DER SEGMENTE<br/>ZUM 31. DEZEMBER 2020</h2>",
      links: [
        {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE_Bilanz.pdf",
          children: "PDF",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsBalanceDownloadPDF,
        },
        {
          href: "/data/xls/de/BMW-IKB20_DE_Group_Bilanz.xlsx",
          children: "XLS",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsBalanceDownloadXLS,
        },
      ],
      table: {
        head: [
          [
            { children: "" },
            { children: "" },
            { children: "Konzern", props: { colSpan: 2 } },
            { children: "Automobile (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Motorräder (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Finanzdienstleistungen (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Sonstige Gesellschaften (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Konsolidierungen (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
          ],
          [
            { children: "in Mio. €", props: { bold: false, type: "th" } },
            { children: "Anhang" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
          ],
        ],
        data: [
          [
            { children: "AKTIVA", props: { type: "th", bold: true } },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
          ],
          [
            { children: "Immaterielle Vermögenswerte", props: { type: "th" } },
            { children: "21" },
            { children: "12.342", props: { accented: true } },
            { children: "11.729" },
            { children: "11.809", props: { accented: true } },
            { children: "11.212" },
            { children: "155", props: { accented: true } },
            { children: "127" },
            { children: "377", props: { accented: true } },
            { children: "389" },
            { children: "1", props: { accented: true } },
            { children: "1" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sachanlagen", props: { type: "th" } },
            { children: "22" },
            { children: "21.850", props: { accented: true } },
            { children: "23.245" },
            { children: "21.371", props: { accented: true } },
            { children: "22.749" },
            { children: "401", props: { accented: true } },
            { children: "407" },
            { children: "78", props: { accented: true } },
            { children: "89" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Vermietete Erzeugnisse", props: { type: "th" } },
            { children: "23" },
            { children: "41.995", props: { accented: true } },
            { children: "42.609" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "48.759", props: { accented: true } },
            { children: "50.348" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "– 6.764", props: { accented: true } },
            { children: "– 7.739" },
          ],
          [
            { children: "At Equity bewertete Beteiligungen", props: { type: "th" } },
            { children: "24" },
            { children: "3.585", props: { accented: true } },
            { children: "3.199" },
            { children: "3.585", props: { accented: true } },
            { children: "3.199" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sonstige Finanzanlagen", props: { type: "th" } },
            { children: "" },
            { children: "735", props: { accented: true } },
            { children: "703" },
            { children: "4.711", props: { accented: true } },
            { children: "5.144" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "20", props: { accented: true } },
            { children: "1" },
            { children: "6.938", props: { accented: true } },
            { children: "6.847" },
            { children: "– 10.934", props: { accented: true } },
            { children: "– 11.289" },
          ],
          [
            { children: "Forderungen aus Finanzdienstleistungen", props: { type: "th" } },
            { children: "25" },
            { children: "48.025", props: { accented: true } },
            { children: "51.030" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "48.082", props: { accented: true } },
            { children: "51.079" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "– 57", props: { accented: true } },
            { children: "– 49" },
          ],
          [
            { children: "Finanzforderungen", props: { type: "th" } },
            { children: "26" },
            { children: "2.644", props: { accented: true } },
            { children: "1.370" },
            { children: "559", props: { accented: true } },
            { children: "131" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "161", props: { accented: true } },
            { children: "139" },
            { children: "1.939", props: { accented: true } },
            { children: "1.168" },
            { children: "– 15", props: { accented: true } },
            { children: "– 68" },
          ],
          [
            { children: "Latente Ertragsteuern", props: { type: "th" } },
            { children: "13" },
            { children: "2.459", props: { accented: true } },
            { children: "2.194" },
            { children: "3.196", props: { accented: true } },
            { children: "3.451" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "550", props: { accented: true } },
            { children: "512" },
            { children: "131", props: { accented: true } },
            { children: "84" },
            { children: "– 1.418", props: { accented: true } },
            { children: "– 1.853" },
          ],
          [
            { children: "Sonstige Vermögenswerte", props: { type: "th" } },
            { children: "28" },
            { children: "1.216", props: { accented: true } },
            { children: "1.325" },
            { children: "2.861", props: { accented: true } },
            { children: "2.203" },
            { children: "33", props: { accented: true } },
            { children: "36" },
            { children: "2.929", props: { accented: true } },
            { children: "3.351" },
            { children: "41.860", props: { accented: true } },
            { children: "38.919" },
            { children: "– 46.467", props: { accented: true } },
            { children: "– 43.184" },
          ],
          [
            { children: "Langfristige Vermögenswerte", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "134.851", props: { accented: true, bold: true, thickLine: true } },
            { children: "137.404", props: { bold: true, thickLine: true } },
            { children: "48.092", props: { accented: true, bold: true, thickLine: true } },
            { children: "48.089", props: { bold: true, thickLine: true } },
            { children: "589", props: { accented: true, bold: true, thickLine: true } },
            { children: "570", props: { bold: true, thickLine: true } },
            { children: "100.956", props: { accented: true, bold: true, thickLine: true } },
            { children: "105.908", props: { bold: true, thickLine: true } },
            { children: "50.869", props: { accented: true, bold: true, thickLine: true } },
            { children: "47.019", props: { bold: true, thickLine: true } },
            { children: "– 65.655", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 64.182", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Vorräte", props: { type: "th" } },
            { children: "29" },
            { children: "14.896", props: { accented: true } },
            { children: "15.891" },
            { children: "13.391", props: { accented: true } },
            { children: "14.404" },
            { children: "687", props: { accented: true } },
            { children: "679" },
            { children: "818", props: { accented: true } },
            { children: "808" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Forderungen aus Lieferungen und Leistungen", props: { type: "th" } },
            { children: "30" },
            { children: "2.298", props: { accented: true } },
            { children: "2.518" },
            { children: "1.979", props: { accented: true } },
            { children: "2.228" },
            { children: "219", props: { accented: true } },
            { children: "186" },
            { children: "100", props: { accented: true } },
            { children: "103" },
            { children: "–", props: { accented: true } },
            { children: "1" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Forderungen aus Finanzdienstleistungen", props: { type: "th" } },
            { children: "25" },
            { children: "36.252", props: { accented: true } },
            { children: "41.407" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "36.252", props: { accented: true } },
            { children: "41.407" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Finanzforderungen", props: { type: "th" } },
            { children: "26" },
            { children: "5.108", props: { accented: true } },
            { children: "5.955" },
            { children: "4.152", props: { accented: true } },
            { children: "4.772" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "612", props: { accented: true } },
            { children: "1.009" },
            { children: "392", props: { accented: true } },
            { children: "187" },
            { children: "– 48", props: { accented: true } },
            { children: "– 13" },
          ],
          [
            { children: "Laufende Ertragsteuern", props: { type: "th" } },
            { children: "27" },
            { children: "606", props: { accented: true } },
            { children: "1.209" },
            { children: "342", props: { accented: true } },
            { children: "1.000" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "64", props: { accented: true } },
            { children: "84" },
            { children: "200", props: { accented: true } },
            { children: "125" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sonstige Vermögenswerte", props: { type: "th" } },
            { children: "28" },
            { children: "9.110", props: { accented: true } },
            { children: "11.614" },
            { children: "33.747", props: { accented: true } },
            { children: "33.492" },
            { children: "2", props: { accented: true } },
            { children: "1" },
            { children: "5.952", props: { accented: true } },
            { children: "5.106" },
            { children: "52.625", props: { accented: true } },
            { children: "64.692" },
            { children: "– 83.216", props: { accented: true } },
            { children: "– 91.677" },
          ],
          [
            { children: "Zahlungsmittel und Zahlungsmitteläquivalente", props: { type: "th" } },
            { children: "" },
            { children: "13.537", props: { accented: true } },
            { children: "12.036" },
            { children: "9.522", props: { accented: true } },
            { children: "9.077" },
            { children: "5", props: { accented: true } },
            { children: "11" },
            { children: "2.863", props: { accented: true } },
            { children: "2.075" },
            { children: "1.147", props: { accented: true } },
            { children: "873" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Kurzfristige Vermögenswerte", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "81.807", props: { accented: true, bold: true, thickLine: true } },
            { children: "90.630", props: { bold: true, thickLine: true } },
            { children: "63.133", props: { accented: true, bold: true, thickLine: true } },
            { children: "64.973", props: { bold: true, thickLine: true } },
            { children: "913", props: { accented: true, bold: true, thickLine: true } },
            { children: "877", props: { bold: true, thickLine: true } },
            { children: "46.661", props: { accented: true, bold: true, thickLine: true } },
            { children: "50.592", props: { bold: true, thickLine: true } },
            { children: "54.364", props: { accented: true, bold: true, thickLine: true } },
            { children: "65.878", props: { bold: true, thickLine: true } },
            { children: "– 83.264", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 91.690", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Bilanzsumme", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "216.658", props: { accented: true, bold: true, thickLine: true } },
            { children: "228.034", props: { bold: true, thickLine: true } },
            { children: "111.225", props: { accented: true, bold: true, thickLine: true } },
            { children: "113.062", props: { bold: true, thickLine: true } },
            { children: "1.502", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.447", props: { bold: true, thickLine: true } },
            { children: "147.617", props: { accented: true, bold: true, thickLine: true } },
            { children: "156.500", props: { bold: true, thickLine: true } },
            { children: "105.233", props: { accented: true, bold: true, thickLine: true } },
            { children: "112.897", props: { bold: true, thickLine: true } },
            { children: "– 148.919", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 155.872", props: { bold: true, thickLine: true } },
          ],
        ],
      },
    },
    {
      table: {
        head: [
          [
            { children: "" },
            { children: "" },
            { children: "Konzern", props: { colSpan: 2 } },
            { children: "Automobile (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Motorräder (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Finanzdienstleistungen (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Sonstige Gesellschaften (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
            { children: "Konsolidierungen (ungeprüfte Zusatzinformationen)", props: { colSpan: 2 } },
          ],
          [
            { children: "in Mio. €", props: { bold: false, type: "th" } },
            { children: "Anhang" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
          ],
        ],
        data: [
          [
            { children: "PASSIVA", props: { type: "th", bold: true } },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
            { children: "" },
          ],
          [
            { children: "Gezeichnetes Kapital", props: { type: "th" } },
            { children: "31" },
            { children: "660", props: { accented: true } },
            { children: "659" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Kapitalrücklage", props: { type: "th" } },
            { children: "31" },
            { children: "2.199", props: { accented: true } },
            { children: "2.161" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Gewinnrücklagen", props: { type: "th" } },
            { children: "31" },
            { children: "59.550", props: { accented: true } },
            { children: "57.667" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Kumuliertes übriges Eigenkapital", props: { type: "th" } },
            { children: "31" },
            { children: "– 1.518", props: { accented: true } },
            { children: "– 1.163" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Eigenkapital der Aktionäre der BMW AG", props: { type: "th", bold: true, thickLine: true } },
            { children: "31", props: { thickLine: true } },
            { children: "60.891", props: { accented: true, bold: true, thickLine: true } },
            { children: "59.324", props: { bold: true, thickLine: true } },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Anteile anderer Gesellschafter", props: { type: "th" } },
            { children: "" },
            { children: "629", props: { accented: true } },
            { children: "583" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
            { children: "", props: { accented: true } },
            { children: "" },
          ],
          [
            { children: "Eigenkapital", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "61.520", props: { accented: true, bold: true, thickLine: true } },
            { children: "59.907", props: { bold: true, thickLine: true } },
            { children: "41.117", props: { accented: true, bold: true, thickLine: true } },
            { children: "40.174", props: { bold: true, thickLine: true } },
            { children: "–", props: { accented: true, bold: true, thickLine: true } },
            { children: "–", props: { bold: true, thickLine: true } },
            { children: "15.555", props: { accented: true, bold: true, thickLine: true } },
            { children: "15.545", props: { bold: true, thickLine: true } },
            { children: "21.389", props: { accented: true, bold: true, thickLine: true } },
            { children: "21.972", props: { bold: true, thickLine: true } },
            { children: "– 16.541", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 17.784", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Rückstellungen für Pensionen", props: { type: "th" } },
            { children: "32" },
            { children: "3.693", props: { accented: true } },
            { children: "3.335" },
            { children: "3.197", props: { accented: true } },
            { children: "2.820" },
            { children: "109", props: { accented: true } },
            { children: "96" },
            { children: "49", props: { accented: true } },
            { children: "47" },
            { children: "338", props: { accented: true } },
            { children: "372" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sonstige Rückstellungen", props: { type: "th" } },
            { children: "33" },
            { children: "6.488", props: { accented: true } },
            { children: "5.788" },
            { children: "6.268", props: { accented: true } },
            { children: "5.605" },
            { children: "74", props: { accented: true } },
            { children: "81" },
            { children: "146", props: { accented: true } },
            { children: "102" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Latente Ertragsteuern", props: { type: "th" } },
            { children: "13" },
            { children: "509", props: { accented: true } },
            { children: "632" },
            { children: "697", props: { accented: true } },
            { children: "543" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "2.812", props: { accented: true } },
            { children: "3.804" },
            { children: "78", props: { accented: true } },
            { children: "34" },
            { children: "– 3.078", props: { accented: true } },
            { children: "– 3.749" },
          ],
          [
            { children: "Finanzverbindlichkeiten", props: { type: "th" } },
            { children: "35" },
            { children: "67.390", props: { accented: true } },
            { children: "70.647" },
            { children: "2.087", props: { accented: true } },
            { children: "2.680" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "17.730", props: { accented: true } },
            { children: "18.170" },
            { children: "47.588", props: { accented: true } },
            { children: "49.865" },
            { children: "– 15", props: { accented: true } },
            { children: "– 68" },
          ],
          [
            { children: "Sonstige Verbindlichkeiten", props: { type: "th" } },
            { children: "36" },
            { children: "5.095", props: { accented: true } },
            { children: "5.100" },
            { children: "7.270", props: { accented: true } },
            { children: "7.929" },
            { children: "522", props: { accented: true } },
            { children: "569" },
            { children: "42.506", props: { accented: true } },
            { children: "39.639" },
            { children: "1.011", props: { accented: true } },
            { children: "102" },
            { children: "– 46.214", props: { accented: true } },
            { children: "– 43.139" },
          ],
          [
            {
              children: "Langfristige Rückstellungen und  Verbindlichkeiten",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "83.175", props: { accented: true, bold: true, thickLine: true } },
            { children: "85.502", props: { bold: true, thickLine: true } },
            { children: "19.519", props: { accented: true, bold: true, thickLine: true } },
            { children: "19.577", props: { bold: true, thickLine: true } },
            { children: "705", props: { accented: true, bold: true, thickLine: true } },
            { children: "746", props: { bold: true, thickLine: true } },
            { children: "63.243", props: { accented: true, bold: true, thickLine: true } },
            { children: "61.762", props: { bold: true, thickLine: true } },
            { children: "49.015", props: { accented: true, bold: true, thickLine: true } },
            { children: "50.373", props: { bold: true, thickLine: true } },
            { children: "– 49.307", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 46.956", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Sonstige Rückstellungen", props: { type: "th" } },
            { children: "33" },
            { children: "7.494", props: { accented: true } },
            { children: "7.421" },
            { children: "6.960", props: { accented: true } },
            { children: "6.962" },
            { children: "100", props: { accented: true } },
            { children: "105" },
            { children: "388", props: { accented: true } },
            { children: "299" },
            { children: "46", props: { accented: true } },
            { children: "55" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Laufende Ertragsteuern", props: { type: "th" } },
            { children: "34" },
            { children: "747", props: { accented: true } },
            { children: "963" },
            { children: "537", props: { accented: true } },
            { children: "704" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "192", props: { accented: true } },
            { children: "184" },
            { children: "18", props: { accented: true } },
            { children: "75" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Finanzverbindlichkeiten", props: { type: "th" } },
            { children: "35" },
            { children: "38.986", props: { accented: true } },
            { children: "46.093" },
            { children: "897", props: { accented: true } },
            { children: "1.929" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "25.178", props: { accented: true } },
            { children: "26.938" },
            { children: "12.959", props: { accented: true } },
            { children: "17.239" },
            { children: "– 48", props: { accented: true } },
            { children: "– 13" },
          ],
          [
            { children: "Verbindlichkeiten aus Lieferungen und  Leistungen", props: { type: "th" } },
            { children: "37" },
            { children: "8.644", props: { accented: true } },
            { children: "10.182" },
            { children: "7.365", props: { accented: true } },
            { children: "8.814" },
            { children: "378", props: { accented: true } },
            { children: "413" },
            { children: "892", props: { accented: true } },
            { children: "943" },
            { children: "9", props: { accented: true } },
            { children: "12" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sonstige Verbindlichkeiten", props: { type: "th" } },
            { children: "36" },
            { children: "16.092", props: { accented: true } },
            { children: "17.966" },
            { children: "34.830", props: { accented: true } },
            { children: "34.902" },
            { children: "319", props: { accented: true } },
            { children: "183" },
            { children: "42.169", props: { accented: true } },
            { children: "50.829" },
            { children: "21.797", props: { accented: true } },
            { children: "23.171" },
            { children: "– 83.023", props: { accented: true } },
            { children: "– 91.119" },
          ],
          [
            {
              children: "Kurzfristige Rückstellungen und  Verbindlichkeiten",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "", props: { thickLine: true } },
            { children: "71.963", props: { accented: true, bold: true, thickLine: true } },
            { children: "82.625", props: { bold: true, thickLine: true } },
            { children: "50.589", props: { accented: true, bold: true, thickLine: true } },
            { children: "53.311", props: { bold: true, thickLine: true } },
            { children: "797", props: { accented: true, bold: true, thickLine: true } },
            { children: "701", props: { bold: true, thickLine: true } },
            { children: "68.819", props: { accented: true, bold: true, thickLine: true } },
            { children: "79.193", props: { bold: true, thickLine: true } },
            { children: "34.829", props: { accented: true, bold: true, thickLine: true } },
            { children: "40.552", props: { bold: true, thickLine: true } },
            { children: "– 83.071", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 91.132", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Bilanzsumme", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { thickLine: true } },
            { children: "216.658", props: { accented: true, bold: true, thickLine: true } },
            { children: "228.034", props: { bold: true, thickLine: true } },
            { children: "111.225", props: { accented: true, bold: true, thickLine: true } },
            { children: "113.062", props: { bold: true, thickLine: true } },
            { children: "1.502", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.447", props: { bold: true, thickLine: true } },
            { children: "147.617", props: { accented: true, bold: true, thickLine: true } },
            { children: "156.500", props: { bold: true, thickLine: true } },
            { children: "105.233", props: { accented: true, bold: true, thickLine: true } },
            { children: "112.897", props: { bold: true, thickLine: true } },
            { children: "– 148.919", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 155.872", props: { bold: true, thickLine: true } },
          ],
        ],
      },
    },
    {
      copytext: "<h2>KAPITALFLUSSRECHNUNG DES KONZERNS UND DER SEGMENTE</h2>",
      links: [
        {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE_KFR.pdf",
          children: "PDF",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsCashFlowDownloadPDF,
        },
        {
          href: "/data/xls/de/BMW-IKB20_DE_Group_KFR.xlsx",
          children: "XLS",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsCashFlowDownloadXLS,
        },
      ],
      table: {
        head: [
          [
            { children: "" },
            { children: "Konzern", props: { colSpan: 2 } },
            { children: "Automobile (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
            { children: "Finanzdienstleistungen (ungeprüfte Zusatzinformation)", props: { colSpan: 2 } },
          ],
          [
            { children: "in Mio. €", props: { bold: false, type: "th" } },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
            { children: "2020", props: { accented: true } },
            { children: "2019" },
          ],
        ],
        data: [
          [
            { children: "Ergebnis vor Steuern<sup>1</sup>", props: { type: "th" } },
            { children: "5.222", props: { accented: true } },
            { children: "7.118" },
            { children: "2.722", props: { accented: true } },
            { children: "4.467" },
            { children: "1.725", props: { accented: true } },
            { children: "2.272" },
          ],
          [
            { children: "Gezahlte Ertragsteuern", props: { type: "th" } },
            { children: "– 1.605", props: { accented: true } },
            { children: "– 3.389" },
            { children: "– 382", props: { accented: true } },
            { children: "– 1.984" },
            { children: "– 1.513", props: { accented: true } },
            { children: "– 345" },
          ],
          [
            { children: "Erhaltene Zinsen<sup>2</sup>", props: { type: "th" } },
            { children: "163", props: { accented: true } },
            { children: "91" },
            { children: "283", props: { accented: true } },
            { children: "91" },
            { children: "3", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Sonstige Zinsen und ähnliche Erträge / Aufwendunge<sup>2</sup>", props: { type: "th" } },
            { children: "104", props: { accented: true } },
            { children: "51" },
            { children: "152", props: { accented: true } },
            { children: "61" },
            { children: "1", props: { accented: true } },
            { children: "3" },
          ],
          [
            { children: "Abschreibungen auf das übrige Anlagevermögen", props: { type: "th" } },
            { children: "6.139", props: { accented: true } },
            { children: "6.017" },
            { children: "5.974", props: { accented: true } },
            { children: "5.853" },
            { children: "46", props: { accented: true } },
            { children: "54" },
          ],
          [
            { children: "Sonstige zahlungsunwirksame Erträge und Aufwendungen", props: { type: "th" } },
            { children: "99", props: { accented: true } },
            { children: "– 200" },
            { children: "94", props: { accented: true } },
            { children: "– 262" },
            { children: "6", props: { accented: true } },
            { children: "23" },
          ],
          [
            { children: "Ergebnis aus Equity-Bewertung", props: { type: "th" } },
            { children: "– 920", props: { accented: true } },
            { children: "– 136" },
            { children: "– 920", props: { accented: true } },
            { children: "– 136" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Ergebnis aus dem Verkauf von Anlagevermögen und Wertpapieren", props: { type: "th" } },
            { children: "–", props: { accented: true } },
            { children: "4" },
            { children: "–", props: { accented: true } },
            { children: "3" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Veränderung der vermieteten Erzeugnisse", props: { type: "th" } },
            { children: "– 1.016", props: { accented: true } },
            { children: "– 3.825" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "– 311", props: { accented: true } },
            { children: "– 3.600" },
          ],
          [
            { children: "Veränderung der Forderungen aus Finanzdienstleistungen", props: { type: "th" } },
            { children: "4.192", props: { accented: true } },
            { children: "– 3.560" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "4.184", props: { accented: true } },
            { children: "– 3.589" },
          ],
          [
            { children: "Veränderung des Working Capital", props: { type: "th" } },
            { children: "– 996", props: { accented: true } },
            { children: "– 1.117" },
            { children: "– 841", props: { accented: true } },
            { children: "– 831" },
            { children: "– 76", props: { accented: true } },
            { children: "– 222" },
          ],
          [
            { children: "Veränderung der Vorräte", props: { type: "th" } },
            { children: "370", props: { accented: true } },
            { children: "– 1.560" },
            { children: "422", props: { accented: true } },
            { children: "– 1.255" },
            { children: "– 43", props: { accented: true } },
            { children: "– 193" },
          ],
          [
            { children: "Veränderung der Forderungen aus Lieferungen und Leistungen", props: { type: "th" } },
            { children: "160", props: { accented: true } },
            { children: "14" },
            { children: "191", props: { accented: true } },
            { children: "43" },
            { children: "1", props: { accented: true } },
            { children: "– 11" },
          ],
          [
            { children: "Veränderung der Verbindlichkeiten aus Lieferungen und Leistungen", props: { type: "th" } },
            { children: "– 1.526", props: { accented: true } },
            { children: "429" },
            { children: "– 1.454", props: { accented: true } },
            { children: "381" },
            { children: "– 34", props: { accented: true } },
            { children: "– 18" },
          ],
          [
            { children: "Veränderung der Rückstellungen", props: { type: "th" } },
            { children: "1.115", props: { accented: true } },
            { children: "1.512" },
            { children: "1.122", props: { accented: true } },
            { children: "1.745" },
            { children: "129", props: { accented: true } },
            { children: "– 59" },
          ],
          [
            { children: "Veränderung der sonstigen betrieblichen Aktiva und Passiva", props: { type: "th" } },
            { children: "754", props: { accented: true } },
            { children: "1.096" },
            { children: "– 26", props: { accented: true } },
            { children: "683" },
            { children: "– 1.432", props: { accented: true } },
            { children: "118" },
          ],
          [
            {
              children: "Mittelzufluss / ­-abfluss aus der betrieblichen Tätigkeit",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "13.251", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.662", props: { bold: true, thickLine: true } },
            { children: "8.178", props: { accented: true, bold: true, thickLine: true } },
            { children: "9.690", props: { bold: true, thickLine: true } },
            { children: "2.762", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 5.345", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Zahlungsausgänge für Gesamtinvestitionen in immaterielle Vermögenswerte und Sachanlagen",
              props: { type: "th" },
            },
            { children: "– 6.150", props: { accented: true } },
            { children: "– 6.902" },
            { children: "– 5.990", props: { accented: true } },
            { children: "– 6.734" },
            { children: "– 14", props: { accented: true } },
            { children: "– 19" },
          ],
          [
            {
              children: "Zahlungseingänge aus Zuschüssen für immaterielle Vermögenswerte und Sachanlagen",
              props: { type: "th" },
            },
            { children: "53", props: { accented: true } },
            { children: "50" },
            { children: "45", props: { accented: true } },
            { children: "50" },
            { children: "8", props: { accented: true } },
            { children: "2" },
          ],
          [
            {
              children: "Zahlungseingänge aus dem Abgang von immateriellen Vermögenswerten und Sachanlagen",
              props: { type: "th" },
            },
            { children: "34", props: { accented: true } },
            { children: "32" },
            { children: "34", props: { accented: true } },
            { children: "31" },
            { children: "–", props: { accented: true } },
            { children: "1" },
          ],
          [
            { children: "Zahlungsausgänge für Investitionen in Finanzanlagen", props: { type: "th" } },
            { children: "– 176", props: { accented: true } },
            { children: "– 1.598" },
            { children: "– 199", props: { accented: true } },
            { children: "– 1.557" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Zahlungseingänge aus dem Abgang von Finanzanlagen<sup>3</sup>", props: { type: "th" } },
            { children: "1.328", props: { accented: true } },
            { children: "1.087" },
            { children: "1.327", props: { accented: true } },
            { children: "1.087" },
            { children: "–", props: { accented: true } },
            { children: "57" },
          ],
          [
            {
              children: "Zahlungsausgänge für Investitionen in Wertpapiere und Investmentanteile",
              props: { type: "th" },
            },
            { children: "– 925", props: { accented: true } },
            { children: "– 775" },
            { children: "– 829", props: { accented: true } },
            { children: "– 507" },
            { children: "– 91", props: { accented: true } },
            { children: "– 268" },
          ],
          [
            {
              children: "Zahlungseingänge aus dem Verkauf von Wertpapieren und Investmentanteilen",
              props: { type: "th" },
            },
            { children: "2.200", props: { accented: true } },
            { children: "822" },
            { children: "1.679", props: { accented: true } },
            { children: "465" },
            { children: "521", props: { accented: true } },
            { children: "356" },
          ],
          [
            {
              children: "Mittelzufluss / -­abfluss aus der Investitionstätigkeit",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "– 3.636", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 7.284", props: { bold: true, thickLine: true } },
            { children: "– 3.933", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 7.165", props: { bold: true, thickLine: true } },
            { children: "424", props: { accented: true, bold: true, thickLine: true } },
            { children: "129", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Einzahlung in das Eigenkapital", props: { type: "th" } },
            { children: "28", props: { accented: true } },
            { children: "33" },
            { children: "28", props: { accented: true } },
            { children: "33" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Zahlung von Dividenden für das Vorjahr", props: { type: "th" } },
            { children: "– 1.671", props: { accented: true } },
            { children: "– 2.366" },
            { children: "– 1.671", props: { accented: true } },
            { children: "– 2.366" },
            { children: "–", props: { accented: true } },
            { children: "–" },
          ],
          [
            { children: "Konzerninterne Finanzierungs- und Eigenkapitaltransaktionen", props: { type: "th" } },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "– 901", props: { accented: true } },
            { children: "877" },
            { children: "– 2.438", props: { accented: true } },
            { children: "5.491" },
          ],
          [
            { children: "Gezahlte Zinsen<sup>2</sup>", props: { type: "th" } },
            { children: "– 275", props: { accented: true } },
            { children: "– 199" },
            { children: "– 447", props: { accented: true } },
            { children: "– 197" },
            { children: "– 18", props: { accented: true } },
            { children: "– 1" },
          ],
          [
            { children: "Aufnahme langfristiger Finanzverbindlichkeiten", props: { type: "th" } },
            { children: "164.478", props: { accented: true } },
            { children: "150.517" },
            { children: "–", props: { accented: true } },
            { children: "173" },
            { children: "153.823", props: { accented: true } },
            { children: "132.408" },
          ],
          [
            { children: "Rückzahlung langfristiger Finanzverbindlichkeiten", props: { type: "th" } },
            { children: "– 171.532", props: { accented: true } },
            { children: "– 143.500" },
            { children: "– 982", props: { accented: true } },
            { children: "– 605" },
            { children: "– 156.657", props: { accented: true } },
            { children: "– 133.089" },
          ],
          [
            { children: "Veränderung der sonstigen Finanzverbindlichkeiten", props: { type: "th" } },
            { children: "718", props: { accented: true } },
            { children: "305" },
            { children: "–", props: { accented: true } },
            { children: "–" },
            { children: "2.782", props: { accented: true } },
            { children: "491" },
          ],
          [
            {
              children: "Mittelzufluss / ­-abfluss aus der Finanzierungstätigkeit",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "– 8.254", props: { accented: true, bold: true, thickLine: true } },
            { children: "4.790", props: { bold: true, thickLine: true } },
            { children: "– 3.973", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 2.085", props: { bold: true, thickLine: true } },
            { children: "– 2.508", props: { accented: true, bold: true, thickLine: true } },
            { children: "5.300", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Wechselkursbedingte Veränderung der Zahlungsmittel und Zahlungsmitteläquivalente",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "180", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 28", props: { bold: true, thickLine: true } },
            { children: "130", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 22", props: { bold: true, thickLine: true } },
            { children: "110", props: { accented: true, bold: true, thickLine: true } },
            { children: "6", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Konsolidierungskreisbedingte Veränderung der Zahlungsmittel und Zahlungsmitteläquivalente",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "– 40", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 83", props: { bold: true, thickLine: true } },
            { children: "43", props: { accented: true, bold: true, thickLine: true } },
            { children: "28", props: { bold: true, thickLine: true } },
            { children: "–", props: { accented: true, bold: true, thickLine: true } },
            { children: "–", props: { bold: true, thickLine: true } },
          ],
          [
            {
              children: "Veränderung der Zahlungsmittel und Zahlungsmitteläquivalente",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "1.501", props: { accented: true, bold: true, thickLine: true } },
            { children: "1.057", props: { bold: true, thickLine: true } },
            { children: "445", props: { accented: true, bold: true, thickLine: true } },
            { children: "446", props: { bold: true, thickLine: true } },
            { children: "788", props: { accented: true, bold: true, thickLine: true } },
            { children: "90", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Zahlungsmittel und Zahlungsmitteläquivalente am 1. Januar", props: { type: "th" } },
            { children: "12.036", props: { accented: true } },
            { children: "10.979" },
            { children: "9.077", props: { accented: true } },
            { children: "8.631" },
            { children: "2.075", props: { accented: true } },
            { children: "1.985" },
          ],
          [
            {
              children: "Zahlungsmittel und Zahlungsmitteläquivalente am 31. Dezember",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "13.537", props: { accented: true, bold: true, thickLine: true } },
            { children: "12.036", props: { bold: true, thickLine: true } },
            { children: "9.522", props: { accented: true, bold: true, thickLine: true } },
            { children: "9.077", props: { bold: true, thickLine: true } },
            { children: "2.863", props: { accented: true, bold: true, thickLine: true } },
            { children: "2.075", props: { bold: true, thickLine: true } },
          ],
        ],
      },
      footnotes: [
        {
          symbol: "¹",
          copytext:
            "Ausgangspunkt für die Ermittlung der Veränderung der Zahlungsmittel und Zahlungsmitteläquivalente ist seit dem Geschäftsjahr 2020 das Ergebnis vor Steuern. Das Vorjahr wurde entsprechend angepasst.",
        },
        {
          symbol: "²",
          copytext:
            "Zinsen im Finanzdienstleistungsgeschäft sind, mit Ausnahme von Zinsen aus Leasingnehmerverhältnissen, als Umsatzerlöse / -kosten klassifiziert.",
        },
        {
          symbol: "³",
          copytext:
            "Beinhaltet eine erhaltene Dividende von Beteiligungen in Höhe von 1.020 Mio. € (2019: 643 Mio. €).",
        },
      ],
    },
    {
      copytext: "<h2>ENTWICKLUNG DES KONZERNEIGENKAPITALS</h2>",
      links: [
        {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE_Eigenkapital.pdf",
          children: "PDF",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsEquityDevelopmentDownloadPDF,
        },
        {
          href: "/data/xls/de/BMW-IKB20_DE_Group_Eigenkapital.xlsx",
          children: "XLS",
          analyticsEvent: AnalyticsEventTypes.FinancialStatementsEquityDevelopmentDownloadXLS,
        },
      ],
      table: {
        head: [
          [
            { children: "", props: { colSpan: 5, accented: true } },
            { children: "Kumuliertes übriges Eigenkapital", props: { colSpan: 4, accented: true } },
            { children: "", props: { colSpan: 3, accented: true } },
          ],
          [
            { children: "in ­Mio. €", props: { type: "th", accented: true } },
            { children: "Anhang", props: { accented: true } },
            { children: "Gezeichnetes Kapital", props: { accented: true } },
            { children: "Kapitalrücklage", props: { accented: true } },
            { children: "Gewinnrücklagen", props: { accented: true } },
            { children: "Unterschiede aus Währungsumrechnung", props: { accented: true } },
            { children: "Wertpapiere", props: { accented: true } },
            { children: "Derivative Finanzinstrumente", props: { accented: true } },
            { children: "Kosten der Sicherungsmaßnahmen", props: { accented: true } },
            { children: "Eigenkapital der Aktionäre der BMW AG", props: { accented: true } },
            { children: "Anteile anderer Gesellschafte", props: { accented: true } },
            { children: "Gesamt", props: { accented: true } },
          ],
        ],
        data: [
          [
            { children: "1. Januar 2020", props: { type: "th", accented: true, bold: true, thickLine: true } },
            { children: "31", props: { accented: true, bold: true, thickLine: true } },
            { children: "659", props: { accented: true, bold: true, thickLine: true } },
            { children: "2.161", props: { accented: true, bold: true, thickLine: true } },
            { children: "57.667", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 760", props: { accented: true, bold: true, thickLine: true } },
            { children: "29", props: { accented: true, bold: true, thickLine: true } },
            { children: "15", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 447", props: { accented: true, bold: true, thickLine: true } },
            { children: "59.324", props: { accented: true, bold: true, thickLine: true } },
            { children: "583", props: { accented: true, bold: true, thickLine: true } },
            { children: "59.907", props: { accented: true, bold: true, thickLine: true } },
          ],
          [
            { children: "Jahresüberschuss", props: { type: "th", accented: true } },
            { children: "", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "3.775", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "3.775", props: { accented: true } },
            { children: "82", props: { accented: true } },
            { children: "3.857", props: { accented: true, bold: true } },
          ],
          [
            { children: "Sonstiges Ergebnis nach Steuern", props: { type: "th", accented: true } },
            { children: "", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "– 215", props: { accented: true } },
            { children: "– 1.396", props: { accented: true } },
            { children: "5", props: { accented: true } },
            { children: "781", props: { accented: true } },
            { children: "188", props: { accented: true } },
            { children: "– 637", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "– 637", props: { accented: true, bold: true } },
          ],
          [
            {
              children: "Gesamtergebnis zum 31. Dezember 2020",
              props: { type: "th", accented: true, bold: true, thickLine: true },
            },
            { children: "", props: { accented: true, bold: true, thickLine: true } },
            { children: "–", props: { accented: true, bold: true, thickLine: true } },
            { children: "–", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.560", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 1.396", props: { accented: true, bold: true, thickLine: true } },
            { children: "5", props: { accented: true, bold: true, thickLine: true } },
            { children: "781", props: { accented: true, bold: true, thickLine: true } },
            { children: "188", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.138", props: { accented: true, bold: true, thickLine: true } },
            { children: "82", props: { accented: true, bold: true, thickLine: true } },
            { children: "3.220", props: { accented: true, bold: true, thickLine: true } },
          ],
          [
            { children: "Dividendenzahlungen", props: { type: "th", accented: true } },
            { children: "", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "– 1.646", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "– 1.646", props: { accented: true } },
            { children: "– 25", props: { accented: true } },
            { children: "– 1.671", props: { accented: true, bold: true } },
          ],
          [
            { children: "Kapitalerhöhung aus Genehmigtem Kapital", props: { type: "th", accented: true } },
            { children: "", props: { accented: true } },
            { children: "1", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "1", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "1", props: { accented: true, bold: true } },
          ],
          [
            { children: "Agio aus der Kapitalerhöhung für Vorzugsaktien", props: { type: "th", accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "38", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "38", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "38", props: { accented: true, bold: true } },
          ],
          [
            { children: "Übrige Veränderungen", props: { type: "th", accented: true } },
            { children: "", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "– 31", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "–", props: { accented: true } },
            { children: "72", props: { accented: true } },
            { children: "– 5", props: { accented: true } },
            { children: "36", props: { accented: true } },
            { children: "– 11", props: { accented: true } },
            { children: "25", props: { accented: true, bold: true } },
          ],
          [
            { children: "31. Dezember  2020", props: { type: "th", accented: true, bold: true, thickLine: true } },
            { children: "31", props: { accented: true, bold: true, thickLine: true } },
            { children: "660", props: { accented: true, bold: true, thickLine: true } },
            { children: "2.199", props: { accented: true, bold: true, thickLine: true } },
            { children: "59.550", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 2.156", props: { accented: true, bold: true, thickLine: true } },
            { children: "34", props: { accented: true, bold: true, thickLine: true } },
            { children: "868", props: { accented: true, bold: true, thickLine: true } },
            { children: "– 264", props: { accented: true, bold: true, thickLine: true } },
            { children: "60.891", props: { accented: true, bold: true, thickLine: true } },
            { children: "629", props: { accented: true, bold: true, thickLine: true } },
            { children: "61.520", props: { accented: true, bold: true, thickLine: true } },
          ],
        ],
      },
    },
    {
      table: {
        head: [
          [
            { children: "", props: { colSpan: 5 } },
            { children: "Kumuliertes übriges Eigenkapital", props: { colSpan: 4 } },
            { children: "", props: { colSpan: 3 } },
          ],
          [
            { children: "in ­Mio. €", props: { type: "th" } },
            { children: "Anhang" },
            { children: "Gezeichnetes Kapital" },
            { children: "Kapitalrücklage" },
            { children: "Gewinnrücklagen" },
            { children: "Unterschiede aus Währungsumrechnung" },
            { children: "Wertpapiere" },
            { children: "Derivative Finanzinstrumente" },
            { children: "Kosten der Sicherungsmaßnahmen" },
            { children: "Eigenkapital der Aktionäre der BMW AG" },
            { children: "Anteile  anderer  Gesellschafte" },
            { children: "Gesamt" },
          ],
        ],
        data: [
          [
            {
              children: "1. Januar 2019 (wie ursprünglich ausgewiesen)",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "31", props: { thickLine: true } },
            { children: "658", props: { bold: true, thickLine: true } },
            { children: "2.118", props: { bold: true, thickLine: true } },
            { children: "55.862", props: { bold: true, thickLine: true } },
            { children: "– 1.326", props: { bold: true, thickLine: true } },
            { children: "– 1", props: { bold: true, thickLine: true } },
            { children: "558", props: { bold: true, thickLine: true } },
            { children: "– 569", props: { bold: true, thickLine: true } },
            { children: "57.300", props: { bold: true, thickLine: true } },
            { children: "529", props: { bold: true, thickLine: true } },
            { children: "57.829", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Effekte aus der Änderung von Bilanzierungsmethoden<sup>*</sup>", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "–" },
            { children: "– 32" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "– 32" },
            { children: "–" },
            { children: "– 32", props: { bold: true } },
          ],
          [
            {
              children: "1. Januar 2019 (nach Anpassung aufgrund der Änderung von Bilanzierungsmethoden)",
              props: { type: "th", bold: true, thickLine: true },
            },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "658", props: { bold: true, thickLine: true } },
            { children: "2.118", props: { bold: true, thickLine: true } },
            { children: "55.830", props: { bold: true, thickLine: true } },
            { children: "– 1.326", props: { bold: true, thickLine: true } },
            { children: "– 1", props: { bold: true, thickLine: true } },
            { children: "558", props: { bold: true, thickLine: true } },
            { children: "– 569", props: { bold: true, thickLine: true } },
            { children: "57.268", props: { bold: true, thickLine: true } },
            { children: "529", props: { bold: true, thickLine: true } },
            { children: "57.797", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Jahresüberschuss", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "–" },
            { children: "4.915" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "4.915" },
            { children: "107" },
            { children: "5.022", props: { bold: true } },
          ],
          [
            { children: "Sonstiges Ergebnis nach Steuern", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "–" },
            { children: "– 867" },
            { children: "566" },
            { children: "30" },
            { children: "– 551" },
            { children: "128" },
            { children: "– 694" },
            { children: "–" },
            { children: "– 694", props: { bold: true } },
          ],
          [
            { children: "Gesamtergebnis zum 31. Dezember 2019", props: { type: "th", bold: true, thickLine: true } },
            { children: "", props: { bold: true, thickLine: true } },
            { children: "–", props: { bold: true, thickLine: true } },
            { children: "–", props: { bold: true, thickLine: true } },
            { children: "4.048", props: { bold: true, thickLine: true } },
            { children: "566", props: { bold: true, thickLine: true } },
            { children: "30", props: { bold: true, thickLine: true } },
            { children: "– 551", props: { bold: true, thickLine: true } },
            { children: "128", props: { bold: true, thickLine: true } },
            { children: "4.221", props: { bold: true, thickLine: true } },
            { children: "107", props: { bold: true, thickLine: true } },
            { children: "4.328", props: { bold: true, thickLine: true } },
          ],
          [
            { children: "Dividendenzahlungen", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "–" },
            { children: "– 2.303" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "– 2.303" },
            { children: "– 63" },
            { children: "– 2.366", props: { bold: true } },
          ],
          [
            { children: "Kapitalerhöhung aus Genehmigtem Kapital", props: { type: "th" } },
            { children: "" },
            { children: "1" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "1" },
            { children: "–" },
            { children: "1", props: { bold: true } },
          ],
          [
            { children: "Agio aus der Kapitalerhöhung für Vorzugsaktien", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "43" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "–" },
            { children: "43" },
            { children: "–" },
            { children: "43", props: { bold: true } },
          ],
          [
            { children: "Übrige Veränderungen", props: { type: "th" } },
            { children: "" },
            { children: "–" },
            { children: "–" },
            { children: "92" },
            { children: "–" },
            { children: "–" },
            { children: "8" },
            { children: "– 6" },
            { children: "94" },
            { children: "10" },
            { children: "104", props: { bold: true } },
          ],
          [
            { children: "31. Dezember  2019", props: { type: "th", bold: true, thickLine: true } },
            { children: "31", props: { thickLine: true } },
            { children: "659", props: { bold: true, thickLine: true } },
            { children: "2.161", props: { bold: true, thickLine: true } },
            { children: "57.667", props: { bold: true, thickLine: true } },
            { children: "– 760", props: { bold: true, thickLine: true } },
            { children: "29", props: { bold: true, thickLine: true } },
            { children: "15", props: { bold: true, thickLine: true } },
            { children: "– 447", props: { bold: true, thickLine: true } },
            { children: "59.324", props: { bold: true, thickLine: true } },
            { children: "583", props: { bold: true, thickLine: true } },
            { children: "59.907", props: { bold: true, thickLine: true } },
          ],
        ],
      },
      footnotes: [
        {
          symbol: "*",
          copytext:
            "Die Zahlen aus dem Jahr 2018 wurden aufgrund der Änderung von Bilanzierungsmethoden im Rahmen der Einführung des IFRS 16 angepasst (siehe Geschäftsbericht 2019, Textziffer [6] im Konzernanhang).",
        },
      ],
    },
  ],
};
