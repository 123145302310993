import { useLoader } from "#hooks";
import { bp, Color, expander, fadeOutFor, gridColumnStart, gridColumnWidth } from "#shared/theme";
import React, { TransitionEvent, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { isReactSnap, Languages } from "#constants";
import styled from "styled-components/macro";
import { LoadingTextDe, LoadingTextEn } from "./LoadingText";
import i18next from "src/i18n";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";

const loadingText: { [k: string]: React.ReactNode } = {
  [Languages.de]: <LoadingTextDe />,
  [Languages.en]: <LoadingTextEn />,
};
const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
  }
`;
const LoadingText = styled.div<{ isComplete?: boolean }>`
  flex: 1 0 0%;
  ${({ isComplete }) => isComplete && navigator.userAgent !== "ReactSnap" && fadeOutFor(0.5)}
`;

export const Fallback = styled.div<{ isComplete?: boolean }>`
  ${expander}
  position: fixed;
  z-index: 10000;
  display: flex;
  padding-top: 100px;
  background-color: ${Color.BlueLoading};
  ${({ isComplete }) => isComplete && navigator.userAgent !== "ReactSnap" && fadeOutFor(0.75)}
`;

type LoadingBarRef = {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue: number): void;
  complete(): void;
};

export const Loader: React.FC<{ isFontLoaded: boolean }> = ({ isFontLoaded }) => {
  const lsInitiallyLoaded = localStorage.getItem("initiallyLoaded");
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const { isLoading, isComplete, isDestroyed, complete, destroy } = useLoader();

  const handleLoaderFinished = () => {
    complete();
  };
  const handleFadeOut = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== containerRef.current) return;
    localStorage.setItem("initiallyLoaded", "true");
    destroy();
  };

  useEffect(() => {
    if (!isLoading || isDestroyed) return;
    if (!isFontLoaded) {
      loadingBarRef.current?.continuousStart(0, 5);
    } else {
      loadingBarRef.current?.complete();
    }
  }, [isDestroyed, isLoading, isFontLoaded, complete, lsInitiallyLoaded]);
  if (isDestroyed) return null;
  return (
    <Fallback isComplete={isComplete} onTransitionEnd={handleFadeOut} ref={containerRef}>
      <LoadingBar
        color={Color.Green}
        shadow={false}
        height={lsInitiallyLoaded || isReactSnap ? 0 : 5}
        ref={loadingBarRef}
        onLoaderFinished={handleLoaderFinished}
      />
      {!lsInitiallyLoaded && (
        <LoadingText isComplete={isComplete}>
          <Wrapper>
            <Grid>
              <Content>{loadingText[i18next.language]}</Content>
            </Grid>
          </Wrapper>
        </LoadingText>
      )}
    </Fallback>
  );
};
