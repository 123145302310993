import React from "react";
import { ScrollTopButton, Spacer, Text } from "#ui";
import { GeneralPageLayout } from "#components";
import { useTranslation } from "#hooks";

export const Imprint: React.FC = () => {
  const { t } = useTranslation("imprint");
  return (
    <GeneralPageLayout>
      <Text text={t("text")} />
      <Spacer size={50} page={"interview"} />
      <ScrollTopButton />
      <Spacer size={20} page={"interview"} />
    </GeneralPageLayout>
  );
};
