import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card31: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect x="505" y="59" opacity="0.9" fill="#C16032" width="136" height="282" />
        <rect x="287.5" y="133.5" width="353.5" height="8" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g>
          <g>
            <path
              fill="#1EBEE4"
              d="M276.03,156.92c0.09-3.12-1.32-8.16-9.4-11.94c-0.21-0.1-0.45,0.09-0.4,0.32l3.1,13.08
				c0.02,0.07,0.01,0.15-0.03,0.21l-3.83,6.34c-0.07,0.11-0.05,0.26,0.05,0.35c5.74,5.58,9.99,13.35,9.93,24.12
				C275.51,184.38,275.89,161.58,276.03,156.92z"
            />
            <path
              fill="#1EBEE4"
              d="M252.77,141.17c-3.39-0.51-7.25-0.88-11.67-1.06c-0.34-0.01-0.6,0.31-0.5,0.64l1.77,5.81
				c0.04,0.13,0.03,0.26-0.04,0.38l-2.53,4.78c-0.15,0.29,0.01,0.64,0.33,0.72c3.14,0.73,7.65,2.03,12.4,4.21
				c0.24,0.11,0.52,0.02,0.64-0.21l2.8-5.05c0.07-0.12,0.08-0.26,0.04-0.38l-2.85-9.48C253.12,141.33,252.96,141.2,252.77,141.17z"
            />
            <path
              fill="#1EBEE4"
              d="M240.16,140.61c-0.1-0.33-0.4-0.56-0.74-0.57c-0.15-0.01-0.29-0.01-0.44-0.01l-6.15-0.07
				c-0.55-0.01-0.95,0.54-0.77,1.06l3.4,10.1c0.1,0.29,0.34,0.49,0.64,0.54c0.49,0.07,1.32,0.22,2.42,0.44
				c0.35,0.07,0.71-0.09,0.88-0.41l2.47-4.68c0.1-0.19,0.12-0.41,0.06-0.61L240.16,140.61z"
            />
            <g>
              <path
                fill="#659896"
                d="M249.94,147c0.02-2.17-0.36-4.28-1.07-6.26l2.33-2.46l-3.22-5.36l-3.44,1.03
					c-1.52-1.5-3.31-2.75-5.33-3.66l-0.09-3.23l-6.06-1.51l-1.69,3.14c-1.87,0.04-3.7,0.36-5.43,0.95l-2.88-2.59l-5.36,3.22
					l1.15,3.79c-1.25,1.26-2.33,2.71-3.18,4.32l-3.98,0.02l-1.51,6.06l3.32,1.79l0.08-0.22c-0.09,1.95,0.13,3.87,0.64,5.69
					l-2.54,2.74l3.22,5.36l3.18-0.95c1.44,1.64,3.18,3.03,5.16,4.08l0.09,3.39l6.06,1.51l1.51-2.8c2.32,0.11,4.6-0.23,6.73-0.96
					l2.37,2.24l5.36-3.22l-0.95-3.17c1.57-1.52,2.89-3.34,3.84-5.39l-0.26,0.32l3.69-0.07l1.51-6.06L249.94,147z M228.67,159.22
					c-6.81-1.7-10.96-8.6-9.26-15.42c1.7-6.81,8.6-10.96,15.42-9.26s10.96,8.6,9.26,15.42
					C242.39,156.78,235.49,160.92,228.67,159.22z"
              />
            </g>
            <g>
              <g>
                <linearGradient
                  id="Card31SVGID1"
                  gradientUnits="userSpaceOnUse"
                  x1="-202.6817"
                  y1="-4553.3545"
                  x2="-159.5499"
                  y2="-4553.3545"
                  gradientTransform="matrix(0.1834 0.983 -0.983 0.1834 -4265.3442 1147.175)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31SVGID1)"
                  d="M162.52,114.98c0,0,8.64-1.52,16.37,2.61c9.94,5.32,18.84,17.33,12.25,35.92l-26.87-10.39"
                />

                <linearGradient
                  id="Card31SVGID2"
                  gradientUnits="userSpaceOnUse"
                  x1="-2013.9415"
                  y1="1521.5215"
                  x2="-1969.9337"
                  y2="1521.5215"
                  gradientTransform="matrix(0.752 -0.6591 0.6591 0.752 646.0207 -2320.0391)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31SVGID2)"
                  d="M142.16,160.57c0,0-4.95-6.37-5.34-15.13c-0.49-11.26,5.36-25.72,24.7-29.54l3.5,27.13"
                />

                <linearGradient
                  id="Card31SVGID3"
                  gradientUnits="userSpaceOnUse"
                  x1="-6356.7007"
                  y1="-2952.7173"
                  x2="-6312.6816"
                  y2="-2952.7173"
                  gradientTransform="matrix(-0.9529 -0.3032 0.3032 -0.9529 -4974.5957 -4576.1826)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31SVGID3)"
                  d="M191.11,153.64c0,0-2.89,7.53-10.2,12.39c-9.39,6.24-24.86,8.9-38.13-5.69l21.49-17.21"
                />
              </g>
              <g>
                <path
                  fill="#DDD7C5"
                  d="M190.29,151.73c-0.81-0.51-2.92-1.8-5.35-3.29c-3.93-2.4-8.69-5.32-10.16-6.28
						c-2.37-1.55-2.26-0.69-3.72-0.22c-1.24,0.4-2.44,0.47-3.5,0.18c0,0.03,0,0.05,0,0.08c-0.02,1.33-1.12,2.4-2.45,2.38
						c-1.18-0.02-2.14-0.87-2.34-1.99c-1.68,1.39-9.36,7.75-14.57,12.07c-2.34,1.94-4.18,3.46-4.75,3.94
						c-1.55,1.29-0.49,2.95,1.39,1.87c0.84-0.48,3-1.76,5.5-3.23c4.03-2.37,8.92-5.25,10.47-6.11c2.52-1.38,1.7-1.69,2-3.21
						c0.17-0.88,0.47-1.67,0.89-2.34l-1.14,25.34c0,0,1.26,0.14,2.52,0.15c1.97,0.02,2.3-0.19,2.31-0.14
						c-0.08-2.97-0.75-20.97-0.96-26.4c2,0.81,11.08,4.51,17.24,7.02c2.77,1.13,4.95,2.02,5.63,2.29
						C191.13,154.59,192.1,152.87,190.29,151.73z"
                />
                <path
                  fill="#DDD7C5"
                  d="M166.5,140.17c-0.48-3.35-1.68-11.77-2.52-17.7c-0.43-3.01-0.77-5.37-0.87-6.11
						c-0.28-2-2.25-1.96-2.32,0.21c-0.03,0.97-0.07,3.48-0.12,6.37c-0.08,4.67-0.19,10.35-0.27,12.12
						c-0.14,2.87,0.55,2.34,1.69,3.38c0.64,0.58,1.14,1.22,1.49,1.89c0.43-0.37,0.99-0.59,1.61-0.58
						C165.68,139.76,166.13,139.92,166.5,140.17z"
                />
              </g>
            </g>
            <radialGradient id="Card31SVGID4" cx="203.3141" cy="161.3978" r="12.7454" gradientUnits="userSpaceOnUse">
              <stop offset="0.053" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.1616" style={{ stopColor: "#FAFDFE" }} />
              <stop offset="0.2848" style={{ stopColor: "#EDF6FA" }} />
              <stop offset="0.405" style={{ stopColor: "#D8ECF4" }} />
              <stop offset="0.4714" style={{ stopColor: "#CDE9F3" }} />
              <stop offset="0.5882" style={{ stopColor: "#B0E1F0" }} />
              <stop offset="0.7415" style={{ stopColor: "#80D3EA" }} />
              <stop offset="0.9234" style={{ stopColor: "#3FC1E3" }} />
              <stop offset="1" style={{ stopColor: "#21B9E0" }} />
            </radialGradient>
            <path
              fill="url(#Card31SVGID4)"
              d="M211.21,162.5c-2.46-5.5-7.75-16.98-7.89-17.29l0-0.01c0,0,0,0,0,0c0,0,0,0,0,0l0,0.01
				c-0.14,0.31-5.43,11.79-7.89,17.29c-2.75,6.15-1.04,14.57,7.15,15.06l0,0c0.19,0.01,0.37,0.01,0.55,0.02c0.06,0,0.12,0,0.19,0
				c0.06,0,0.12,0,0.19,0c0.18,0,0.37-0.01,0.55-0.02l0,0C212.25,177.08,213.96,168.66,211.21,162.5z"
            />
            <path
              fill="#1EBEE4"
              d="M226.94,139.69l-0.07,4.17c-2.86-3.51-6.39-6.29-10.51-8.27c-15.29-7.34-33.71-0.88-41.06,14.42
				c-4.47,9.3-3.99,20.15,1.28,29.04l0.89-0.53c-5.09-8.59-5.55-19.08-1.24-28.06c7.1-14.78,24.89-21.03,39.67-13.93
				c3.99,1.91,7.4,4.6,10.16,7.99l-3.91-0.17l-0.04,1.04l5.76,0.25l0.1-5.93L226.94,139.69z"
            />
            <path
              fill="#1EBEE4"
              d="M229.22,147.17l-0.88,0.54c5.34,8.67,5.9,19.32,1.5,28.49c-3.44,7.16-9.46,12.55-16.95,15.18
				c-7.49,2.63-15.56,2.19-22.72-1.25c-3.99-1.92-7.41-4.61-10.18-8.01l3.8,0.92l0.24-1.01l-5.6-1.36l-1.24,5.8l1.01,0.22l0.87-4.06
				c2.88,3.59,6.46,6.42,10.65,8.43c4.22,2.03,8.75,3.05,13.3,3.05c3.43,0,6.88-0.58,10.22-1.75c7.75-2.72,13.98-8.3,17.54-15.71
				C235.33,167.17,234.75,156.15,229.22,147.17z"
            />
            <g>
              <path
                fill="#1EBEE4"
                d="M265.16,144.34c-2.9-1.18-6.53-2.21-11.1-2.97c-0.22-0.04-0.41,0.17-0.34,0.39l2.82,9.37
					c0.02,0.08,0.01,0.16-0.03,0.23l-2.99,5.39c-0.08,0.15-0.02,0.34,0.13,0.42c3.71,1.8,7.52,4.16,10.89,7.22
					c0.14,0.13,0.36,0.1,0.46-0.07l3.54-5.84c0.04-0.07,0.05-0.15,0.04-0.23l-3.24-13.7C265.32,144.46,265.25,144.38,265.16,144.34z
					"
              />
            </g>
            <g>
              <path
                fill="#659896"
                d="M254.63,170.52c-4.1,1.3-8.99,2.55-14.8,3.71c-0.39,0.08-0.71,0.34-0.87,0.7l-11.82,27.65
					c-0.15,0.35-0.12,0.76,0.08,1.09l13.58,22.4c0.22,0.37,0.62,0.59,1.05,0.57c2.46-0.07,10.94-0.73,18.78-6.25
					c0.52-0.37,0.65-1.08,0.31-1.62l-12.73-19.85c-0.19-0.3-0.24-0.66-0.14-1l8.05-25.92C256.41,171.1,255.54,170.23,254.63,170.52z
					"
              />
              <path
                fill="#659896"
                d="M273.33,199.49c0.17,0.29,0.62,0.23,0.69-0.1c0.81-3.43,1.32-7.29,1.45-11.64c0,0,0.36-24.27,0.54-30.43
					c0.1-3.42-1.17-6.46-3.74-8.61c0,0,4.57,4,2.04,9.28c-0.17,0.36-0.23,0.7-0.25,0.81l-5.14,32.48c-0.05,0.34,0.01,0.69,0.19,0.99
					L273.33,199.49z"
              />
              <path
                fill="#659896"
                d="M273.29,201.23l-5.25-8.99c-0.1-0.17-0.14-0.37-0.1-0.56l4.53-28.59c0.12-0.78-0.81-1.3-1.39-0.76
					c-2.5,2.27-6.44,4.61-12.51,6.86c-0.25,0.09-0.44,0.29-0.52,0.54l-8.76,28.19c-0.07,0.24-0.04,0.5,0.1,0.71l12.56,19.57
					c0.28,0.43,0.88,0.52,1.27,0.18c4.18-3.65,7.94-8.91,10.17-16.48C273.43,201.67,273.4,201.43,273.29,201.23z"
              />
            </g>
            <g>
              <path
                fill="#027084"
                d="M272.28,148.72c0.17,0.15,4.52,4.1,2.03,9.27c-0.17,0.36-0.23,0.7-0.25,0.81l-2.33,14.73
					c2.27,4.29,3.67,9.4,3.69,15.47c0-0.1,0.01-0.2,0.01-0.3c0.05-2.93,0.15-9.27,0.27-15.59c0.11-6.35,0.23-12.96,0.31-15.78
					c0.04-1.43-0.16-2.79-0.59-4.05c-0.49-1.34-1.33-2.76-2.71-4.17C272.57,148.97,272.43,148.84,272.28,148.72z"
              />
              <path
                fill="#659896"
                d="M272.28,148.72C272.28,148.71,272.28,148.71,272.28,148.72C272.28,148.71,272.28,148.71,272.28,148.72z"
              />
            </g>
            <path
              fill="#027084"
              d="M271.06,162.33c-1.27,1.15-2.91,2.32-5.02,3.49c1.86,1.89,3.55,4.02,4.96,6.41l1.45-9.14
				C272.58,162.31,271.65,161.79,271.06,162.33z"
            />
            <polygon
              opacity="0.9"
              fill="#C16032"
              points="203.91,101.96 204.78,88.09 204.78,101.98 206.52,88.2 205.64,102.07 
				208.25,88.42 206.51,102.2 209.96,88.75 207.36,102.39 211.65,89.18 208.19,102.64 213.31,89.72 209.01,102.93 214.93,90.36 
				209.81,103.28 216.51,91.1 210.59,103.67 218.04,91.94 211.34,104.12 219.51,92.88 212.06,104.61 220.92,93.9 212.75,105.14 
				222.26,95.01 213.41,105.72 223.53,96.21 214.02,106.33 224.73,97.48 214.6,106.99 225.84,98.82 215.13,107.68 226.86,100.23 
				215.62,108.4 227.8,101.71 216.07,109.15 228.64,103.23 216.46,109.93 229.38,104.81 216.81,110.73 230.02,106.43 217.11,111.55 
				230.56,108.09 217.35,112.38 231,109.78 217.54,113.24 231.32,111.49 217.68,114.1 231.54,113.22 217.76,114.96 231.65,114.96 
				217.78,115.84 231.65,116.71 217.76,116.71 231.54,118.45 217.68,117.58 231.32,120.18 217.54,118.44 231,121.89 217.35,119.29 
				230.56,123.58 217.11,120.12 230.02,125.24 216.81,120.95 229.38,126.86 216.46,121.75 228.64,128.44 216.07,122.52 
				227.8,129.97 215.62,123.27 226.86,131.44 215.13,123.99 225.84,132.85 214.6,124.68 224.73,134.19 214.02,125.34 223.53,135.46 
				213.41,125.95 222.26,136.66 212.75,126.53 220.92,137.77 212.06,127.06 219.51,138.79 211.34,127.55 218.04,139.73 210.59,128 
				216.51,140.57 209.81,128.39 214.93,141.31 209.01,128.74 213.31,141.95 208.19,129.04 211.65,142.49 207.36,129.28 
				209.96,142.93 206.51,129.47 208.25,143.25 205.64,129.61 206.52,143.47 204.78,129.69 204.78,143.58 203.91,129.72 
				203.03,143.58 203.03,129.69 201.29,143.47 202.17,129.61 199.56,143.25 201.3,129.47 197.85,142.93 200.45,129.28 
				196.16,142.49 199.62,129.04 194.5,141.95 198.8,128.74 192.88,141.31 198,128.39 191.3,140.57 197.22,128 189.77,139.73 
				196.47,127.55 188.3,138.79 195.75,127.06 186.89,137.77 195.06,126.53 185.55,136.66 194.4,125.95 184.28,135.46 193.79,125.34 
				183.08,134.19 193.21,124.68 181.97,132.85 192.68,123.99 180.95,131.44 192.19,123.27 180.01,129.97 191.74,122.52 
				179.17,128.44 191.35,121.75 178.43,126.86 191,120.95 177.79,125.24 190.71,120.12 177.25,123.58 190.46,119.29 176.81,121.89 
				190.27,118.44 176.49,120.18 190.14,117.58 176.27,118.45 190.05,116.71 176.16,116.71 190.03,115.84 176.16,114.96 
				190.05,114.96 176.27,113.22 190.14,114.1 176.49,111.49 190.27,113.24 176.81,109.78 190.46,112.38 177.25,108.09 
				190.71,111.55 177.79,106.43 191,110.73 178.43,104.81 191.35,109.93 179.17,103.23 191.74,109.15 180.01,101.71 192.19,108.4 
				180.95,100.23 192.68,107.68 181.97,98.82 193.21,106.99 183.08,97.48 193.79,106.33 184.28,96.21 194.4,105.72 185.55,95.01 
				195.06,105.14 186.89,93.9 195.75,104.61 188.3,92.88 196.47,104.12 189.77,91.94 197.22,103.67 191.3,91.1 198,103.28 
				192.88,90.36 198.8,102.93 194.5,89.72 199.62,102.64 196.16,89.18 200.45,102.39 197.85,88.75 201.3,102.2 199.56,88.42 
				202.17,102.07 201.29,88.2 203.03,101.98 203.03,88.09 			"
            />
          </g>
          <path
            fill="#1EBEE4"
            d="M165.11,144.58c1.33,0.02,2.43-1.04,2.45-2.38c0-0.03,0-0.05,0-0.08c-0.01-0.81-0.43-1.53-1.06-1.96
			c-0.38-0.26-0.83-0.41-1.32-0.42c-0.61-0.01-1.17,0.21-1.61,0.58c-0.51,0.43-0.84,1.08-0.85,1.8c0,0.16,0.01,0.31,0.04,0.46
			C162.97,143.71,163.94,144.57,165.11,144.58z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g>
          <g>
            <g>
              <path
                fill="none"
                d="M78.55,117.82c0.09,0,0.18-0.01,0.27-0.01c0.11-0.08,0.22-0.17,0.34-0.26
					C78.91,117.64,78.65,117.71,78.55,117.82z"
              />
              <path fill="none" d="M85.97,116.83c0.02,0,0.05,0,0.07,0C86.02,116.83,86,116.83,85.97,116.83z" />
              <path
                fill="none"
                d="M82.84,115.42c0.09-0.01,0.18-0.02,0.27-0.03c-0.01,0-0.01,0-0.01,0c-0.62-0.1-0.83,0.06-1.02,0.28
					C82.35,115.54,82.61,115.45,82.84,115.42z"
              />
              <path fill="none" d="M85.36,116.19c0.01,0.01,0.01,0.01,0.02,0.02C85.37,116.2,85.36,116.2,85.36,116.19z" />
              <path
                fill="none"
                d="M85.62,116.72c0,0.01,0,0.01,0,0.02c0-0.01,0-0.01,0.01-0.01C85.62,116.73,85.62,116.72,85.62,116.72
					C85.62,116.72,85.62,116.72,85.62,116.72z"
              />
              <path
                fill="none"
                d="M84.67,115.84c0.28,0.06,0.47,0.17,0.61,0.28c-0.2-0.17-0.49-0.32-0.91-0.45
					C84.48,115.72,84.58,115.78,84.67,115.84z"
              />
              <path
                fill="none"
                d="M79.99,120.39c0.43-0.08,1.09-0.2,1.75-0.33c0-0.1,0-0.21,0-0.31c-0.02,0-0.04-0.01-0.06-0.01
					c-0.92,0.28-1.75,0.56-2.15,0.72C79.53,120.47,79.7,120.44,79.99,120.39z"
              />
              <path
                fill="none"
                d="M80.25,116.74c-0.17,0.1-0.33,0.21-0.47,0.34C79.93,116.97,80.08,116.85,80.25,116.74z"
              />
              <path
                fill="none"
                d="M26.7,196.63l-0.04,0c-0.09-0.33-0.17-0.66-0.25-0.98c-1.05-3.4-1.41-2.97-1.96-2.18l-2.1,2.74L26.7,196.63
					L26.7,196.63z"
              />
              <path fill="none" d="M79.77,119.63c-0.19,0.22-0.29,0.36-0.29,0.36L79.77,119.63z" />
              <path
                fill="none"
                d="M84.45,116.31c-0.39,0.14-1.02,1.01-1.36,3.02c-0.1,0.56-0.17,1.21-0.21,1.97
					c0.02,0.11,0.04,0.21,0.07,0.31c-0.03-0.01-0.05-0.03-0.08-0.04c-0.01,0.26-0.02,0.52-0.02,0.81c-0.03,4.25,1.1,7.57,2.15,1.98
					C86.03,118.75,85.56,115.91,84.45,116.31z M83.6,121.98C83.59,121.98,83.59,121.98,83.6,121.98l0.02,0
					C83.62,121.98,83.61,121.98,83.6,121.98z M83.65,121.88c0.03-0.11,0.06-0.22,0.08-0.34C83.7,121.69,83.67,121.8,83.65,121.88z"
              />
              <path
                fill="none"
                d="M85.45,116.31c-0.01-0.01-0.02-0.02-0.03-0.03C85.44,116.29,85.45,116.3,85.45,116.31z"
              />
              <path fill="none" d="M87.38,247.14C87.39,247.14,87.39,247.14,87.38,247.14L87.38,247.14z" />
              <path
                fill="none"
                d="M88.53,256.91c0.03,0.01,0.07,0.02,0.14,0.03C88.6,256.92,88.56,256.91,88.53,256.91z"
              />
              <path fill="none" d="M85.56,116.51c0-0.01-0.01-0.02-0.01-0.03C85.55,116.49,85.56,116.5,85.56,116.51z" />
              <path fill="none" d="M85.9,116.82c-0.02,0-0.03-0.01-0.05-0.01C85.87,116.81,85.89,116.82,85.9,116.82z" />
              <path
                fill="none"
                d="M85.52,116.42c-0.01-0.02-0.02-0.04-0.03-0.06C85.5,116.38,85.51,116.4,85.52,116.42z"
              />
              <path
                fill="#41637C"
                d="M22.82,286.58c0-0.03,0.01-0.06,0.01-0.09C22.82,286.52,22.82,286.55,22.82,286.58z"
              />
              <path
                fill="#41637C"
                d="M40.05,280.52c-4.34-1.1-8.06-2.49-11.15-3.89c0,0,0,0,0,0C31.56,277.88,35.3,279.34,40.05,280.52z"
              />
              <path
                fill="#41637C"
                d="M25.23,328.06C25.23,328.06,25.23,328.06,25.23,328.06c0.45,5.75,0.91,10.4,1.35,14.12c0,0,0,0,0,0
					C26.18,337.97,25.71,333.08,25.23,328.06z"
              />
              <path
                fill="#41637C"
                d="M22.75,287.42c-0.45,5.56-0.16,10.93,0.39,17.03C22.48,295.38,22.5,290.74,22.75,287.42z"
              />
              <path
                fill="#41637C"
                d="M61.56,282.7c-4.93,0.97-30.36,6.74-33.34,15.77c-0.02,8.33,0.34,18.13,1.56,27.59
					c2.58,19.95,3.17,29.75,3.3,32.29c0,0,0,0,0,0c8.82,6.08,29.08,18.88,44.26,19.52c3.74,0.16,19-0.68,20.61-7.89
					c4.28-19.22-2.67-59.92-8.66-88.4l-22.1,0.72C65.26,282.5,63.38,282.63,61.56,282.7z"
              />
              <path
                fill="#41637C"
                d="M25.06,274.83c-0.05,0.16-0.09,0.32-0.13,0.48c0.07-0.21,0.14-0.37,0.2-0.45
					C25.1,274.85,25.08,274.84,25.06,274.83z"
              />
              <path
                fill="#D7BFB1"
                d="M71.27,138.45c-0.09-0.06-0.18-0.12-0.27-0.17C71.09,138.34,71.18,138.4,71.27,138.45z"
              />
              <path
                fill="#D7BFB1"
                d="M73.32,139.76c-0.33-0.21-0.67-0.43-1.02-0.65C72.65,139.33,72.99,139.55,73.32,139.76z"
              />
              <path
                fill="#D7BFB1"
                d="M74.6,140.57c-0.36-0.23-0.74-0.47-1.12-0.71C73.87,140.11,74.24,140.34,74.6,140.57z"
              />
              <g>
                <path
                  fill="#D7BFB1"
                  d="M88.4,126.2c-0.94-1.59-1.35-2.17-1.7-3.23c-0.02,0.09-0.03,0.12-0.03,0.12s0.24-1.07,0.04-1.73
						c-0.03-0.09-0.16-0.21-0.2-0.31c-0.12-0.34-0.89-1.03-0.94-0.69c-0.14,0.97,0.25,5.97-1.69,6.81c-1.81,0.79-1.85-3.95-1.69-5.9
						c-0.48-0.17-1.45-0.52-1.81-0.61c-0.13-0.08-0.25-0.17-0.38-0.27c-0.28,0.05-0.46,0.08-0.46,0.08c0.4-0.16,1.23-0.44,2.15-0.72
						c-0.32-0.04-0.88-0.09-1.79-0.12c-0.13,0.11-0.27,0.23-0.41,0.36c0,0,0.11-0.15,0.29-0.36c-0.17,0-0.36-0.01-0.56-0.01
						l0.55-1.53c0.95-0.09,1.67-0.22,2.06-0.24c0.05-0.19,0.41-0.3,0.55-0.43c0.67-0.64,1.09-1.34,1.92-1.26
						c0.1,0.01,1.03,0.05,1.05,0.07c0.01,0.01,0.01,0.01,0.02,0.02c0.02,0.02,0.04,0.04,0.06,0.06c0.01,0.01,0.02,0.02,0.03,0.03
						c0.01,0.02,0.02,0.04,0.04,0.05c0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.02,0.02,0.04,0.03,0.06c0,0.01,0.01,0.02,0.01,0.03
						c0.04,0.09,0.05,0.17,0.05,0.21c0,0,0,0,0,0c0,0,0.01,0,0.01,0.01c0.01,0,0.02,0,0.04,0.01c0.04,0.02,0.22,0.08,0.23,0.08
						c0.02,0,0.12,0.01,0.14,0.01c0.04,0,0.83,0.07,0.63-0.72c0.05-0.54,0.48-1.2,0.51-1.73c-0.02-1.08,1.1-3.32,0.54-5.3
						c-0.38-1.34-0.54-4.6-2.96-3.8c0,0,0.01,0,0.03-0.01c-0.01-0.03-0.02-0.05-0.03-0.08c-0.89,1.05-14.64-2.82-14.64-2.82
						l-20.83,31.46l-0.44,10.7c-2.16,1.02-4.26,1.36-4.35,1.38c0.03,0.98-0.06,2.17-0.51,3.29c-0.57,1.41-0.86,2.02-2.03,2.29
						c0,0,0,0,0,0c0.01,0,0.02,0,0.02,0c0.03,0.02,0.02,0.02-0.02,0.01c-1.55,0.78-3.05,1.33-4.1,1.52l2.36,1.24
						c9.78,8.21,23.5,32.03,23.5,32.03c3.98-6.29,5.3-20.55,5.74-25.35c0.14-1.51-0.44-2.67-0.44-2.67c0.01,0,0.01,0,0.02,0.01
						c-0.02-0.1-0.03-0.21-0.04-0.33c0.03-0.78-0.12-1.91-0.08-2.92c0.58-1.08-2.31-7.79,13.54-6.15c3.9-0.11,3.82-2.69,3.45-4.53
						c-0.37-1.87,0.5-1.97,0.82-3.06c-1.69-1.55-3.46-3.46-3.68-3.76c0.66-0.44,4.35-1.92,4.49-3.48c0.02,0.02,0.03,0.05,0.05,0.07
						c-0.83-1.82,0.77-1.88,2.1-2.66C91.56,130.37,90.59,129.91,88.4,126.2z M85.87,131.01c0.01-0.15,0.03-0.25,0.03-0.25
						C85.89,130.85,85.88,130.93,85.87,131.01z"
                />
                <path
                  fill="#D7BFB1"
                  d="M41.94,151.52L41.94,151.52c-0.12,0.02-0.25,0.04-0.37,0.05C41.69,151.56,41.82,151.54,41.94,151.52z"
                />
              </g>
              <path
                fill="#4C2B1A"
                d="M80.6,96.2c-4.42-4.87-10.48-6.35-20.44-5.25c0.1-0.01,0.19-0.03,0.29-0.04c0,0-2.34-0.08-4.08,0.47
					c-0.04,0.04-0.08,0.07-0.12,0.09l0.08,0l-0.9,0.17l0-0.16c-0.04-0.02-0.07-0.04-0.11-0.06c-0.01,0-0.01-0.01-0.02-0.01
					c-0.04-0.03-0.09-0.05-0.15-0.06c-1.17-0.05-2.24,0.02-3.22,0.21c-1.39,0.35-3.15,1.06-4.96,2.32c-0.23,0.16-0.47,0.33-0.7,0.52
					c-0.93-0.5-2.19-0.87-3.83-1.26c-7.65-1.78-11.94,3.25-13.47,7.34c-1.47,3.93-1.31,7.44-0.14,10.17
					c0.5,1.97,1.34,4.19,2.64,6.67c0,0-0.34-1.56-0.61-3.6c1.47,1.52,3.36,2.51,5.44,2.81c0.07,3.3,0.67,6.33,1.68,8.66
					c0.11,0.24,0.21,0.48,0.33,0.71c2.29,4.55,3.73,8.75,4.65,12.12c0.48,1.69,0.88,3.41,1.14,5.09c0.05,0.27,0.09,0.52,0.12,0.76
					l0,0c0,0,0.23,0.88,0.27,2.08c0.09-0.02,2.19-0.36,4.35-1.38c2.61-1.23,5.31-3.44,4.67-7.23c-0.19-0.04-0.05-1.79-0.25-1.83
					c-7.01-1.71-5.91-8.09-5.74-8.19c0.21-4.32,16.31-3.86,16.2,0.81c-0.01,0.57,1.04,0.85,0.93,1.39c1.51-1.46,3.85-3.48,7.44-6.13
					c0.33-0.24,0.65-0.49,0.97-0.75c-2.12,2.92-3.66,5.93-4.14,6.92c0.5-0.59,1.55-1.79,3.69-4.15c1.77-1.94,3.19-3.91,4.35-5.81
					c0.36-0.59,0.7-1.17,1.01-1.75c0.02-0.04,0.05-0.08,0.07-0.13c3.69-4.41,6.14-9.29,6.73-12.54c0.01,0.03,0.02,0.05,0.03,0.08
					c0.36-0.11,4.06-1,1.95,10.44c0,0,0.61-0.38,0.61,1.17C91.6,107.28,88.07,97.52,80.6,96.2z"
              />
              <path
                fill="#99857D"
                d="M44.48,145.94c0.03,0.98-0.06,2.17-0.51,3.29c-0.57,1.43-0.87,2.04-2.07,2.3c0.02,0,0.04-0.01,0.06-0.01
					c0.46,0.32,0.92,0.68,1.38,1.07c3.53-1.28,5.44-4.47,5.48-8.03C46.67,145.58,44.57,145.92,44.48,145.94z"
              />
              <path
                fill="#FFFFFF"
                d="M83.6,121.98c0.01,0,0.02,0,0.02,0L83.6,121.98C83.59,121.98,83.59,121.98,83.6,121.98z"
              />
              <path
                fill="#FFFFFF"
                d="M82.88,121.3c0,0.09-0.01,0.18-0.01,0.27c0.03,0.01,0.05,0.03,0.08,0.04
					C82.91,121.51,82.89,121.4,82.88,121.3z"
              />
              <path
                fill="#FFFFFF"
                d="M83.73,121.54c-0.02,0.11-0.05,0.23-0.08,0.34C83.67,121.8,83.7,121.69,83.73,121.54z"
              />
              <path
                fill="#FFFFFF"
                d="M80.37,120.66c0.36,0.09,0.81,0.22,1.29,0.39c0.01-0.14,0.02-0.27,0.04-0.38
					c0.03-0.21,0.04-0.41,0.04-0.61c-0.66,0.13-1.33,0.25-1.75,0.33C80.12,120.49,80.24,120.58,80.37,120.66z"
              />
              <path
                fill="#AB7D62"
                d="M79.89,119.63c-0.04,0-0.08,0-0.12,0l-0.29,0.36C79.62,119.86,79.76,119.75,79.89,119.63z"
              />
              <path fill="#AB7D62" d="M85.9,130.76c0,0-0.02,0.1-0.03,0.25C85.88,130.93,85.89,130.85,85.9,130.76z" />
              <path
                fill="none"
                stroke="#AB7D62"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M85.87,131.01
					c0,0.01,0,0.02,0,0.03"
              />
              <path
                fill="#4C2B1A"
                d="M85.48,136.95c-0.55,0.16-1-0.13-1.5-0.13c-0.01,0.02-0.39,0.54-0.39,0.56
					c-0.05,0.31,0.86,0.93,1.4,1.22c0.13,0.07,0.2-0.19,0.3-0.14C84.74,138.15,84.58,137.49,85.48,136.95z"
              />
              <path
                fill="none"
                stroke="#669694"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M89.58,257.15
					c0.01,0,0.02,0,0.02,0.01"
              />
              <path
                fill="none"
                stroke="#669694"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M89.71,257.19
					c0.01,0,0.03,0.01,0.04,0.01"
              />
              <path fill="#659896" d="M22.54,273.26c-2.03-1.38-4.46-3.15-4.46-3.15S19.6,271.45,22.54,273.26z" />
              <path
                fill="#659896"
                d="M18.25,268.91c0.03-0.17,0.06-0.36,0.09-0.56C18.31,268.54,18.28,268.73,18.25,268.91z"
              />
              <path fill="#659896" d="M8.17,222.37c0.1-0.4,0.2-0.81,0.3-1.21C8.38,221.47,8.28,221.9,8.17,222.37z" />
              <path fill="#659896" d="M10.4,244.11c-0.31,0.15-0.68,0.28-1.1,0.4C9.71,244.4,10.07,244.27,10.4,244.11z" />
              <path fill="#659896" d="M9.75,214.86c0,0,0.53-0.55,1.33-1.43C10.52,214,10.04,214.5,9.75,214.86z" />
              <path
                fill="#659896"
                d="M-17.13,233.97c-0.1-0.24-0.2-0.48-0.29-0.73C-17.33,233.48-17.23,233.73-17.13,233.97z"
              />
              <path
                fill="#659896"
                d="M-17.11,234.01c1.78,4.2,5.3,7.75,11.7,9.23c2.94,0.68,5.81,1.23,8.36,1.49
					c-4.18-0.48-8.68-1.74-10.78-2.41C-11.38,241.18-15.11,238.61-17.11,234.01z"
              />
              <path
                fill="#659896"
                d="M12.21,241.51c0.02,0.17,0.06,0.56-0.12,1.02c0.13-0.27,0.22-0.57,0.28-0.89
					c-0.04-0.05-0.07-0.09-0.11-0.14C12.25,241.5,12.23,241.5,12.21,241.51z"
              />
              <polygon fill="#659896" points="26.7,196.63 30.18,196.97 26.7,196.63 				" />
              <path
                fill="#41637C"
                d="M29.78,326.06c-1.23-9.47-1.58-19.26-1.56-27.59c2.98-9.03,28.41-14.8,33.34-15.77
					c-8.3,0.32-15.5-0.65-21.52-2.18c-4.75-1.18-8.49-2.64-11.15-3.89c0,0,0,0,0,0c-1.4-0.64-2.68-1.28-3.81-1.89
					c-0.01,0.03-0.02,0.06-0.03,0.09c0.02,0.01,0.05,0.02,0.06,0.03c-0.05,0.09-0.12,0.24-0.2,0.45c-1.1,3.98-1.76,7.63-2.1,11.18
					c0,0.03-0.01,0.06-0.01,0.09c-0.03,0.28-0.05,0.56-0.07,0.84c-0.25,3.32-0.27,7.96,0.39,17.03c0.52,5.82,1.28,12.3,1.85,20.23
					c0.08,1.17,0.17,2.3,0.25,3.39c0,0,0,0,0,0c0.48,5.02,0.94,9.91,1.35,14.12c0,0,0,0,0,0c1.13,9.61,2.08,13.01,2.08,13.01
					s1.64,1.25,4.41,3.16c0,0,0,0,0,0C32.96,355.82,32.37,346.01,29.78,326.06z"
              />
              <path
                fill="#254354"
                d="M29.78,326.06c-1.23-9.47-1.58-19.26-1.56-27.59c2.98-9.03,28.41-14.8,33.34-15.77
					c-8.3,0.32-15.5-0.65-21.52-2.18c-4.75-1.18-8.49-2.64-11.15-3.89c0,0,0,0,0,0c-1.4-0.64-2.68-1.28-3.81-1.89
					c-0.01,0.03-0.02,0.06-0.03,0.09c0.02,0.01,0.05,0.02,0.06,0.03c-0.05,0.09-0.12,0.24-0.2,0.45c-1.1,3.98-1.76,7.63-2.1,11.18
					c0,0.03-0.01,0.06-0.01,0.09c-0.03,0.28-0.05,0.56-0.07,0.84c-0.25,3.32-0.27,7.96,0.39,17.03c0.52,5.82,1.28,12.3,1.85,20.23
					c0.08,1.17,0.17,2.3,0.25,3.39c0,0,0,0,0,0c0.48,5.02,0.94,9.91,1.35,14.12c0,0,0,0,0,0c1.13,9.61,2.08,13.01,2.08,13.01
					s1.64,1.25,4.41,3.16c0,0,0,0,0,0C32.96,355.82,32.37,346.01,29.78,326.06z"
              />
              <path
                fill="#659896"
                d="M61.56,282.7c0.13-0.03,0.24-0.05,0.34-0.07c-8.72,0.34-16.05-0.67-21.86-2.11
					C46.07,282.05,53.26,283.02,61.56,282.7z"
              />
              <path
                fill="#659896"
                d="M28.9,276.63c-3.04-4.78-4.11-11.98-4.44-17.8l-3.25-1.55c-1.24,3.88-2.28,7.6-2.87,11.08
					c-0.03,0.2-0.06,0.39-0.09,0.56c-0.06,0.4-0.13,0.8-0.18,1.2c0,0,2.43,1.77,4.46,3.15c0.76,0.47,1.6,0.96,2.55,1.47
					C26.22,275.35,27.5,275.99,28.9,276.63z"
              />
              <path
                fill="#3E6869"
                d="M28.9,276.63c-3.04-4.78-4.11-11.98-4.44-17.8l-3.25-1.55c-1.24,3.88-2.28,7.6-2.87,11.08
					c-0.03,0.2-0.06,0.39-0.09,0.56c-0.06,0.4-0.13,0.8-0.18,1.2c0,0,2.43,1.77,4.46,3.15c0.76,0.47,1.6,0.96,2.55,1.47
					C26.22,275.35,27.5,275.99,28.9,276.63z"
              />
              <polygon fill="#161616" points="24.46,258.82 24.46,258.82 21.04,257.19 21.04,257.19 21.21,257.27 				" />
              <path
                fill="#659896"
                d="M7.18,225.68c0.01-0.05,0.01-0.1,0.02-0.15C7.19,225.58,7.19,225.63,7.18,225.68
					c0.09-0.07,0.19-0.14,0.3-0.2c0.16-0.83,0.31-1.6,0.36-1.74c-0.01-0.05,0.01-0.16,0.06-0.32c0.1-0.34,0.19-0.69,0.28-1.05
					c0.11-0.47,0.21-0.9,0.3-1.21c0.76-3.18,1.28-6.29,1.28-6.29c0.29-0.37,0.76-0.87,1.33-1.43c0.14-0.15,0.28-0.31,0.43-0.47
					l-0.18-1.25c-1.49,1.01-2.95,1.67-4.06,1.21c-3.61-1.48-1.76,6.15-1.38,13.01c0.28,0.09,0.49,0.22,0.65,0.37
					C6.7,226.09,6.91,225.88,7.18,225.68z"
              />
              <path
                fill="#3E6869"
                d="M7.18,225.68c0.01-0.05,0.01-0.1,0.02-0.15C7.19,225.58,7.19,225.63,7.18,225.68
					c0.09-0.07,0.19-0.14,0.3-0.2c0.16-0.83,0.31-1.6,0.36-1.74c-0.01-0.05,0.01-0.16,0.06-0.32c0.1-0.34,0.19-0.69,0.28-1.05
					c0.11-0.47,0.21-0.9,0.3-1.21c0.76-3.18,1.28-6.29,1.28-6.29c0.29-0.37,0.76-0.87,1.33-1.43c0.14-0.15,0.28-0.31,0.43-0.47
					l-0.18-1.25c-1.49,1.01-2.95,1.67-4.06,1.21c-3.61-1.48-1.76,6.15-1.38,13.01c0.28,0.09,0.49,0.22,0.65,0.37
					C6.7,226.09,6.91,225.88,7.18,225.68z"
              />
              <polygon fill="#161616" points="11.52,213 11.52,213 11.33,211.71 11.33,211.71 11.51,212.96 				" />
              <path
                fill="#659896"
                d="M102.24,205.1c-5.25-31.52-10.76-36.12-15.91-39.92c0,0-4.09-2.48-17.31-6.89c-0.01,0-0.01,0-0.02-0.01
					c0,0-0.04,0.92-0.18,2.43c-0.43,4.79-1.81,15.56-5.79,21.85c0,0-9.9-21.78-19.68-29.99c-0.46-0.39-0.92-0.74-1.38-1.07
					c-0.02,0-0.04,0.01-0.06,0.01c-0.33,0.07-0.73,0.12-1.22,0.15c0,0,0,0,0,0c-0.82,0.08-1.73,0.13-2.71,0.17
					c-1.31,0.05-2.75,0.08-4.26,0.13c-8.27,0.26-18.77,1.1-23.51,9.21c-6.62,11.31-3.06,10.31-12.22,25.59
					c-2.36,3.93-4.27,7.52-5.79,10.59c-0.8,0.94-2.08,2.6-3.59,5.17c0,0,0,0,0,0c-2.1,4.21-4.38,12.26-2.39,17.51
					c0.06,0.09,0.12,0.22,0.19,0.4c0.02,0.07,0.05,0.15,0.08,0.22c0.21,0.46,0.46,0.89,0.75,1.29c2.83,3.91,5.73,13.88,8.75,16.82
					c0.93-0.63,1.76-6.11,2.38-7c1.21-3.94,3.42-5.21,4.39-5.53c1.53-0.51,2.51-0.53,3.15-0.33c-0.38-6.85-2.23-14.49,1.38-13.01
					c1.11,0.45,3.99,0.07,5.48-0.94l-2.33-14.17c-0.24-1.51-0.4-1.61,0.67-1.18l11.27-0.43l2.1-2.74c0.54-0.79,0.91-1.22,1.96,2.18
					c0.09,0.3,0.19,0.62,0.29,0.98l3.39,0.33c0.03,0.33,0.06,0.66,0.09,0.98l6.93,2.01c1.03,0.42,1,0.9,1.04,2.29l7.9,64.71
					c0.04,1.41-0.68,0.65-1.73,0l-21.09-10.58c0.33,5.83,1.95,16.7,4.99,21.49c3.09,1.4,6.98,3.06,11.31,4.16
					c5.81,1.44,13.38,1.8,22.37,0.62c-0.1,0.02,0.47,1.3,0.34,1.32c1.82-0.07,3-1.46,4.93-1.66l22.1-0.72l3.4-0.11
					c0.3-5.15-5.31-34.33-5.31-34.33c14.47,11.02,17.36-4.13,17.92-8.61C106.33,230.25,107.1,234.27,102.24,205.1z"
              />
              <path
                fill="#3E6869"
                d="M30.18,196.97v0.98c-0.98-8.59,1.76-22.31,1.14-21.28c-0.76,1.27-2.04,10.19-3.06,5.35
					c-1.02-4.84-1.78-6.37-1.78-6.37c0,8.65-1.84,12.68-0.08,20c0,0,0,0,0,0.01c0.09,0.3,0.63,1.87,0.73,2.23L30.18,196.97z"
              />
              <polygon fill="#161616" points="30.35,196.98 30.35,196.98 30.18,196.97 				" />
              <path
                fill="#659896"
                d="M12.21,241.51c-2.96,0.63-6.02-0.75-7.76-2.84c-1.28-1.54-1.77-2.76-1.95-3.34
					c-0.5-1.42-0.57-2.88-0.09-4.1c1.16-2.97,2.61-3.47,3.44-3.49c0.01-0.05,0.02-0.1,0.04-0.15c0.02-0.05,0.04-0.11,0.06-0.17
					c-0.01-0.49-0.03-0.99-0.06-1.49c-0.64-0.2-1.62-0.18-3.15,0.33c-0.96,0.32-3.18,1.59-4.39,5.53c-0.62,0.89-1.46,6.37-2.38,7
					c-3.03-2.94-5.92-12.91-8.75-16.82c-0.29-0.4-0.54-0.84-0.75-1.29c-0.03-0.07-0.05-0.15-0.08-0.22
					c-0.06-0.18-0.13-0.31-0.19-0.4c-1.99-5.26,0.29-13.3,2.39-17.51c0,0,0,0,0,0c-1.59,2.7-3.43,6.39-5.23,11.28
					c-1.42,3.85-3.36,12.4-0.79,19.42c0.09,0.25,0.19,0.49,0.29,0.73c0.01,0.01,0.01,0.02,0.02,0.04c2.01,4.6,5.73,7.17,9.28,8.31
					c2.1,0.67,6.6,1.93,10.78,2.41c2.51,0.26,4.71,0.23,6.36-0.21c0.42-0.12,0.79-0.25,1.1-0.4c0.77-0.37,1.35-0.89,1.69-1.58
					C12.27,242.07,12.23,241.68,12.21,241.51z"
              />
              <path
                fill="#3E6869"
                d="M12.34,241.71c-2.96,0.63-6.19-0.88-7.92-2.97c-1.28-1.54-1.77-2.76-1.95-3.34
					c-0.5-1.42-0.57-2.88-0.09-4.1c1.16-2.97,2.61-3.47,3.44-3.49c0.01-0.05,0.02-0.1,0.04-0.15c0.02-0.05,0.04-0.11,0.06-0.17
					c-0.01-0.49-0.03-0.99-0.06-1.49c-0.64-0.2-1.62-0.18-3.15,0.33c-0.96,0.32-3.18,1.59-4.39,5.53c-0.62,0.89-1.46,6.37-2.38,7
					c-3.03-2.94-5.92-12.91-8.75-16.82c-0.29-0.4-0.54-0.84-0.75-1.29c-0.03-0.07-0.05-0.15-0.08-0.22
					c-0.06-0.18-0.13-0.31-0.19-0.4c-1.99-5.26,0.29-13.3,2.39-17.51c0,0,0,0,0,0c-1.59,2.7-3.43,6.39-5.23,11.28
					c-1.42,3.85-3.36,12.4-0.79,19.42c0.09,0.25,0.19,0.49,0.29,0.73c0.01,0.01,0.01,0.02,0.02,0.04c2.01,4.6,5.73,7.17,9.28,8.31
					c2.1,0.67,6.64,1.86,10.81,2.33c2.51,0.26,4.65,0.26,6.29-0.19c0.42-0.12,0.8-0.25,1.11-0.4c0.77-0.37,1.36-0.84,1.7-1.53
					C12.24,242.15,12.36,241.89,12.34,241.71z"
              />
              <path
                fill="#659896"
                d="M6.54,226.3c-0.16-0.15-0.37-0.28-0.65-0.37c0.03,0.5,0.05,1,0.06,1.49
					C6.08,227.07,6.24,226.68,6.54,226.3z"
              />
              <path
                fill="#41637C"
                d="M55.22,92.24l0.95,0c0.05,0,0.11,0.01,0.16,0.02l0-0.8l-0.08,0l-0.82,0l-0.18,0L55.22,92.24z"
              />
              <path
                fill="#41637C"
                d="M56.93,118.67l-0.07-25.69c0-0.35-0.23-0.64-0.54-0.71c-0.05-0.01-0.1-0.02-0.16-0.02l-0.95,0l-0.03,0
					c-0.39,0-0.7,0.33-0.7,0.74l-0.13,25.73c0.54-0.09,1.09-0.13,1.65-0.11C56.33,118.6,56.63,118.63,56.93,118.67z"
              />

              <linearGradient
                id="Card31SVGID5"
                gradientUnits="userSpaceOnUse"
                x1="123.8016"
                y1="56.7384"
                x2="110.5199"
                y2="64.4066"
                gradientTransform="matrix(0.9997 0.0235 -0.0235 0.9997 -60.4426 42.5346)"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.5156" style={{ stopColor: "#020202", stopOpacity: 0.5156 }} />
                <stop offset="0.7501" style={{ stopColor: "#090909", stopOpacity: 0.7501 }} />
                <stop offset="0.9271" style={{ stopColor: "#161615", stopOpacity: 0.9271 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31SVGID5)"
                d="M56.93,118.67l-0.07-25.69c0-0.35-0.23-0.64-0.54-0.71c-0.05-0.01-0.1-0.02-0.16-0.02l-0.95,0
					l-0.03,0c-0.39,0-0.7,0.33-0.7,0.74l-0.13,25.73c0.54-0.09,1.09-0.13,1.65-0.11C56.33,118.6,56.63,118.63,56.93,118.67z"
              />
              <g>
                <path
                  fill="#99857D"
                  d="M53.25,135.49c0.2,0.05,0.06,1.8,0.25,1.83c2.47,0.49,4.35,0.18,5.79-0.52c0,0,0.01,0,0.01,0
						c1.48-0.71,2.48-1.82,3.17-2.86c2.87,1.88,9.88,6.47,11.57,7.49c1.42,0.85,2.1,0.53,1.92-0.01
						c-0.11-0.06-10.71-7.12-13.02-8.59c-0.18,0.24-12.7-2.84-15.58-4.75C47.23,129.87,47.7,134.14,53.25,135.49z"
                />
                <path
                  fill="#33190D"
                  d="M46.77,127.41c0-0.08,0.01-0.15,0.01-0.23c-0.04-0.01-0.07-0.01-0.07-0.01
						c-0.17,0.1-0.81,8.31,6.2,10.02c0.2,0.05,0.39,0.09,0.59,0.13c-0.19-0.04-0.05-1.79-0.25-1.83c-5.55-1.36-6.02-5.62-5.89-7.4
						C46.98,127.84,46.77,127.61,46.77,127.41z"
                />
              </g>
              <circle fill="#41637C" cx="55.77" cy="127.6" r="9.01" />
              <linearGradient
                id="Card31SVGID6"
                gradientUnits="userSpaceOnUse"
                x1="46.7716"
                y1="127.6252"
                x2="64.8327"
                y2="127.6252"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4913" style={{ stopColor: "#020202", stopOpacity: 0.4913 }} />
                <stop offset="0.7387" style={{ stopColor: "#0A0A09", stopOpacity: 0.7387 }} />
                <stop offset="0.931" style={{ stopColor: "#171716", stopOpacity: 0.931 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31SVGID6)"
                d="M46.77,127.41c-0.04,1.71,0.4,3.33,1.2,4.71c0.22,0.38,0.46,0.74,0.73,1.08
					c0.18,0.23,0.37,0.45,0.57,0.65c0.6,0.63,1.29,1.17,2.04,1.6c0.6,0.35,1.25,0.62,1.94,0.83c0.74,0.22,1.53,0.35,2.34,0.37
					c0.17,0,0.34,0,0.52,0c0.05,0,0.11-0.01,0.16-0.01c0.12-0.01,0.23-0.01,0.35-0.02c0.06-0.01,0.12-0.01,0.18-0.02
					c0.11-0.01,0.22-0.03,0.33-0.04c0.06-0.01,0.12-0.02,0.18-0.03c0.11-0.02,0.23-0.04,0.34-0.06c0.05-0.01,0.1-0.02,0.15-0.03
					c0.16-0.04,0.32-0.08,0.47-0.12c0,0,0,0,0,0c0.16-0.05,0.32-0.1,0.48-0.15c0.04-0.01,0.07-0.03,0.11-0.04
					c0.12-0.04,0.24-0.09,0.35-0.13c0.05-0.02,0.09-0.04,0.14-0.06c0.11-0.05,0.22-0.09,0.32-0.15c0.04-0.02,0.09-0.04,0.13-0.06
					c0.11-0.06,0.23-0.12,0.34-0.18c0.03-0.02,0.06-0.03,0.1-0.05c0.29-0.16,0.57-0.34,0.84-0.54c0.01-0.01,0.03-0.02,0.04-0.03
					c0.12-0.09,0.24-0.18,0.35-0.27c0.03-0.02,0.05-0.04,0.08-0.07c0.1-0.08,0.2-0.17,0.3-0.26c0.03-0.02,0.05-0.05,0.08-0.07
					c0.1-0.09,0.2-0.19,0.3-0.29c0.02-0.02,0.03-0.03,0.05-0.05c0.24-0.24,0.46-0.5,0.67-0.77c-1.41-0.9-2.57-1.64-3.29-2.1
					c-0.97,1.09-2.39,1.76-3.97,1.73c-2.85-0.07-5.1-2.43-5.03-5.28c0.07-2.85,2.43-5.1,5.28-5.03c2.85,0.07,5.1,2.43,5.03,5.28
					c-0.02,0.75-0.2,1.45-0.5,2.09c0.77,0.52,1.91,1.28,3.25,2.16c0.42-0.77,0.74-1.6,0.93-2.49c0.11-0.54,0.18-1.1,0.2-1.67
					c0.11-4.68-3.35-8.6-7.9-9.17c-0.3-0.04-0.61-0.06-0.92-0.07c-0.56-0.01-1.12,0.03-1.65,0.11c-4.14,0.67-7.37,4.16-7.58,8.47h0
					C46.78,127.26,46.78,127.34,46.77,127.41z"
              />
              <path
                fill="#41637C"
                d="M60.46,129.83c0.3-0.63,0.48-1.34,0.5-2.09c0.07-2.85-2.19-5.21-5.03-5.28
					c-2.85-0.07-5.21,2.19-5.28,5.03c-0.07,2.85,2.19,5.21,5.03,5.28c1.57,0.04,3-0.64,3.97-1.73
					C59.97,130.69,60.25,130.28,60.46,129.83z M55.75,130.01c-1.32-0.03-2.36-1.12-2.33-2.44c0.03-1.32,1.12-2.36,2.44-2.33
					c1.32,0.03,2.36,1.12,2.33,2.44C58.15,129,57.06,130.04,55.75,130.01z"
              />

              <linearGradient
                id="Card31SVGID7"
                gradientUnits="userSpaceOnUse"
                x1="123.3698"
                y1="82.3352"
                x2="113.055"
                y2="82.3352"
                gradientTransform="matrix(0.9997 0.0235 -0.0235 0.9997 -60.4426 42.5346)"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4913" style={{ stopColor: "#020202", stopOpacity: 0.4913 }} />
                <stop offset="0.7387" style={{ stopColor: "#0A0A09", stopOpacity: 0.7387 }} />
                <stop offset="0.931" style={{ stopColor: "#171716", stopOpacity: 0.931 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31SVGID7)"
                d="M60.46,129.83c0.3-0.63,0.48-1.34,0.5-2.09c0.07-2.85-2.19-5.21-5.03-5.28
					c-2.85-0.07-5.21,2.19-5.28,5.03c-0.07,2.85,2.19,5.21,5.03,5.28c1.57,0.04,3-0.64,3.97-1.73
					C59.97,130.69,60.25,130.28,60.46,129.83z M55.75,130.01c-1.32-0.03-2.36-1.12-2.33-2.44c0.03-1.32,1.12-2.36,2.44-2.33
					c1.32,0.03,2.36,1.12,2.33,2.44C58.15,129,57.06,130.04,55.75,130.01z"
              />
              <path
                fill="#161616"
                d="M58.18,127.68c0.03-1.32-1.01-2.41-2.33-2.44c-1.32-0.03-2.41,1.01-2.44,2.33
					c-0.03,1.32,1.01,2.41,2.33,2.44S58.15,129,58.18,127.68z"
              />
              <path
                fill="#41637C"
                d="M75.61,141.22c0.12,0.08,0.24,0.14,0.35,0.21l0,0C75.85,141.36,75.73,141.29,75.61,141.22z"
              />
              <path
                fill="#41637C"
                d="M72.31,139.11c-0.34-0.22-0.69-0.44-1.03-0.66C71.62,138.68,71.97,138.89,72.31,139.11z"
              />
              <path
                fill="#41637C"
                d="M75.61,141.22c-0.32-0.21-0.66-0.42-1.01-0.64C74.95,140.8,75.29,141.01,75.61,141.22z"
              />
              <path
                fill="#41637C"
                d="M73.48,139.86c-0.05-0.03-0.1-0.07-0.16-0.1C73.38,139.79,73.43,139.83,73.48,139.86z"
              />
              <path
                fill="#41637C"
                d="M62.94,133.15L62.94,133.15L62.94,133.15c2.3,1.47,5.27,3.35,8.06,5.13
					C68.21,136.5,65.25,134.62,62.94,133.15z"
              />
              <path
                fill="#33190D"
                d="M41.78,103.53c5.61-11.98,13.34-12.02,13.34-12.02l0.31-0.05c-0.01,0-0.12-0.07-0.13-0.07
					c-0.04-0.03-0.09-0.05-0.15-0.06c-0.83-0.2-2.24-0.02-3.22,0.21c-1.39,0.35-3.15,1.06-4.96,2.32
					C42.62,97.45,41.78,103.53,41.78,103.53z"
              />
              <polygon fill="#41637C" points="59.65,131.06 62.94,133.15 63.71,131.99 60.46,129.83 				" />
              <path
                fill="#41637C"
                d="M60.46,129.83c-0.21,0.45-0.49,0.86-0.81,1.22c0.72,0.46,1.88,1.2,3.29,2.1l0,0
					c2.3,1.47,5.27,3.35,8.06,5.13c0.09,0.06,0.18,0.12,0.27,0.17c0.35,0.22,0.69,0.44,1.03,0.66c0.35,0.22,0.68,0.44,1.02,0.65
					c0.05,0.03,0.11,0.07,0.16,0.1c0.38,0.24,0.76,0.48,1.12,0.71c0.35,0.22,0.69,0.44,1.01,0.64c0.12,0.08,0.24,0.14,0.35,0.21l0,0
					c2.32,1.29,3.06-0.24,1.14-1.21c-1.11-0.56-1.85-1.07-4.65-2.72c-2.79-1.64-6.14-3.79-8.74-5.5
					C62.37,131.11,61.23,130.35,60.46,129.83z"
              />
              <path
                fill="none"
                d="M15.07,240.08c0.01,0.14-0.02-0.05,0.3-0.29l-0.02-0.13C15.16,239.83,15.06,239.99,15.07,240.08z"
              />
              <path
                fill="none"
                d="M14.65,234.8l-0.02-0.15c-0.12,0.03-0.19,0.04-0.19,0.04C14.3,234.76,14.39,234.79,14.65,234.8z"
              />
              <path
                fill="none"
                d="M11.08,213.43c-0.8,0.89-1.33,1.43-1.33,1.43c0.09,0.97-0.54,3.66-1.28,6.29c0,0,0,0,0,0
					c-0.1,0.41-0.2,0.81-0.3,1.21c-0.22,0.99-0.47,2.19-0.69,3.1c0.22-0.13,0.47-0.26,0.76-0.38c0,0,0.14-0.05,0.37-0.13
					c0.74-0.72,3.44-3.29,4.09-3.61l0.06,0.24l-0.29-2l0.01,0l-0.46-3.15l-0.5-3.42C11.37,213.15,11.22,213.29,11.08,213.43
					C11.09,213.43,11.08,213.43,11.08,213.43z"
              />
              <path
                fill="none"
                d="M13.78,228.74l0-0.03c-0.17,0.1-0.44,0.25-0.85,0.48C13.48,228.89,13.69,228.78,13.78,228.74z"
              />
              <path
                fill="#161616"
                d="M16.53,238.4c-0.62,0.14-0.91,1-1.19,1.26l0.02,0.13c0.21-0.16,0.94,0.37,1.55,0.23
					c1.55-0.34,4.76-1.13,5.95-1.39c1.19-0.26,4.85-2.62,4.85-2.62l-0.11-0.06c0,0-3.26,0.03-4.87,0.6
					C21.13,237.12,17.57,238.17,16.53,238.4z"
              />
              <path
                fill="#161616"
                d="M24.46,258.82L24.46,258.82l21.32,10.16c1.05,0.65,1.92-0.01,1.87-1.42l-6.62-66.38
					c-0.04-1.39-0.96-2.93-1.99-3.35l-8.69-0.85l0,0l-0.17-0.02l-3.48-0.34l-4.35-0.43l-11.59-1.13c-1.07-0.43-1.79,0.51-1.55,2.02
					l2.12,14.62l0,0l0.19,1.3l0,0l0.5,3.42l0.46,3.15l-0.01,0l0.29,2l0.18,2.71c2.14-0.76,4.93-1.72,6.13-2.16
					c2.25-0.82,3.88-1.07,4.89,0c0.56,0.47,0.75,0.85,0.26,1.15c0,0-0.49,0.43-0.49,0.43c-0.78,0.94-2.47,0.72-4.42,1.62
					c-2.55,1.19-5.51,2.08-5.51,2.08s-0.22,1.18-0.43,1.3l0,0c0.05-0.02-0.28,0-0.28,0l0.19,0.29c0.01,0,0.23,0.08,0.23,0.08
					l0.17,1.16c0.21-0.01,0.75,0.69,1,0.68c0,0,6.78-0.97,10.02-1.46c2.68-0.4,3.8-0.91,4.52-0.14c0.72,0.52,0.66,1.15,0.03,1.37
					c-0.14,0.05-0.2,0.15-0.37,0.21c-0.72,0.48-1.55,0.43-2.79,0.68c-1.65,0.33-6.97,1.11-10.07,1.79
					c-0.69,0.15-0.77,0.56-1.14,0.64c-0.09,0.02-0.66,0.63-0.73,0.64l0.13,0.38c0.07,0,0.61,0.11,0.7,0.1
					c0.96-0.02,3.1-0.13,4.5-0.41c2.02-0.41,8.84-2.44,10.54-2.42c0.8,0.01,0.95,0.7,1.03,1.33c0.34,0.52,0,0.8-0.45,1.02
					c-0.32,0.16-0.16-0.11-0.58,0.04c-0.04,0.02-0.65,0.35-0.69,0.37c-1.11,0.48-2.39,1.09-2.39,1.09s0.71,0.13,0.97,0.58
					c0.38,0.34,0.72,0.6,0.4,0.85c-0.15,0.11-0.37,0.17-0.56,0.31c-0.38,0.3-1.14,0.56-1.97,1.02c-1.6,0.89-3.38,1.03-4.9,1.7
					c-1.23,0.54-3.35,0.92-4.89,1.23c-0.1,0.02-0.27,0.03-0.36,0.05l0.77,4.83l0.03-0.01l0.98,6.79c0.18,1.13,0.86,2.25,1.67,2.75
					l2,0.95l0,0L24.46,258.82z"
              />
              <path
                fill="#AB7D62"
                d="M13.29,229.92c0.21,0,0.45,0,0.72-0.01l-0.13-0.9c0,0,0,0-0.01,0l0.12,0.88
					C13.73,229.9,13.49,229.91,13.29,229.92z"
              />
              <path
                fill="#D7BFB1"
                d="M21.13,241.1c1.53-0.67,3.49-1.08,5.09-1.97c0.82-0.46,1.42-0.76,1.81-1.06
					c0.08-0.06,1.4-0.52,0.44-1.57c-0.27-0.45-0.86-0.55-0.86-0.55l0.11,0.06c0,0-4.38,1.69-5.57,1.95c-1.19,0.26-4.06,1-5.6,1.35
					c-0.61,0.14-0.96,0.32-1.17,0.49c-0.31,0.25-0.28,0.44-0.3,0.29c-0.01-0.09,0.09-0.25,0.28-0.43c0.28-0.26,0.75-0.55,1.37-0.69
					c1.03-0.23,4.68-1.07,6.29-1.64c1.6-0.57,4.6-1.37,4.6-1.37s1.3-0.29,2.44-0.71c2.37-0.88,1.35-1.48,1.43-2.03
					c0.09-0.54-0.55-1.13-1.35-1.14c-1.7-0.02-8.76,1.79-10.77,2.2c-1.4,0.28-3.49,0.51-4.46,0.53c-0.09,0-0.17,0-0.24,0
					c-0.25-0.01-0.35-0.04-0.21-0.11c0,0,0.07-0.02,0.19-0.04c0.06-0.01,0.14-0.03,0.23-0.05c0.37-0.08,0.95-0.21,1.65-0.37
					c3.1-0.68,8.33-1.79,9.97-2.12c1.24-0.25,2.4-0.6,3.13-1.09c1.51-0.8,0.38-1.83,0.37-2.07c-0.55-0.71-2.35-0.79-5.03-0.38
					c-3.24,0.49-10.26,1.32-10.26,1.32c-0.25,0.01-0.47,0.02-0.68,0.03c-0.27,0.01-0.52,0.01-0.72,0.01
					c-1.52,0.04-1.41-0.14-0.36-0.73c0.41-0.23,0.68-0.38,0.85-0.48c0.2-0.12,0.27-0.16,0.27-0.16s3.01-1.58,5.56-2.76
					c1.95-0.9,4.3-1.47,5.08-2.41c0.65-0.38,0.16-1.55-0.17-1.67c-1.01-1.07-3.48-1.19-5.73-0.37c-1.2,0.44-3.48,1.25-5.62,2.01
					l-0.42-1.77l-0.06-0.24c-0.65,0.33-3.34,2.9-4.09,3.61c-0.24,0.08-0.37,0.13-0.37,0.13c-0.29,0.12-0.54,0.25-0.76,0.38
					c0,0,0,0,0,0c-0.1,0.06-0.2,0.13-0.3,0.2c0,0,0,0,0,0c-0.27,0.2-0.47,0.41-0.64,0.62c-0.3,0.38-0.46,0.77-0.59,1.12
					c-0.02,0.06-0.04,0.11-0.06,0.17c-0.02,0.05-0.03,0.1-0.04,0.15c-0.82,0.02-2.28,0.52-3.44,3.49c-0.48,1.23-0.4,2.68,0.09,4.1
					c-0.07-0.24-0.09-0.38-0.09-0.38s0.54,3.19,4.13,5.5c3.57,2.3,5.79,1.13,5.73,1.05c-0.02-0.03-0.02-0.03,0,0
					c0.03,0.05,0.07,0.1,0.11,0.14c0.55,0.68,1.54,1.24,2.73,1.08c0.19-0.03,0.41-0.06,0.67-0.11c0.09-0.02,0.19-0.04,0.29-0.06
					C17.59,242.24,19.9,241.64,21.13,241.1z"
              />
              <path
                fill="#AC7D61"
                d="M13.29,229.92c0.21-0.01,0.44-0.02,0.71-0.03l-0.12-0.88l-0.04-0.29c0,0,0,0-0.06,0.02
					c-0.08,0.04-0.3,0.14-0.85,0.45C11.87,229.78,11.77,229.96,13.29,229.92z"
              />
            </g>
            <path
              fill="#3E6869"
              d="M84.2,242.23l-10.98-19.58c1.46-0.09,4.03-0.6,4.19-0.71c0.58-0.32,1.29-0.39,1.81-0.83
				c2.18-1.85,0.51-2.33,0.51-2.33s3.95-0.57,1.77-2.68c-2.18-2.1-4.25-4.16-6.17-5.77c-1.93-1.62-3.15-2.49-3.87-3.46
				c-0.72-0.96-1.42-2.37-2.96-2.11c0,0-0.32-0.65-1.01-1.11c-0.69-0.46-1.92-0.5-2.94,0.11c-1.02,0.61-4.54,2.48-6.6,3.33
				c-2.07,0.84-2.97,1.56-4.37,2.53c-1.39,0.97-2.86,1.67-4.38,2.25c-0.78,0.3-1.68,0.57-2.31,0.91c-0.52,0.24-0.9,0.5-0.83,0.82
				c0.01,0.05,0.02,0.09,0.04,0.14c0,0.1,0.02,0.2,0.06,0.31c0,0,0.69,2.56,3.95,1.49c2.07-0.68,7.05-2.79,7.05-2.79l0.05,0.52
				c0-0.01,0.01-0.01,0.01-0.02c-0.02,0.09-0.04,0.18-0.06,0.28c-0.13,0.62,0.06,1.25,0.5,1.92c0,0-0.97,0.56-1.54,1.26
				c-0.29,0.36-0.44,1-0.36,1.6c0,0.06,0,0.11,0.01,0.17c0.15,1.49,1.41,1.36,2.29,1.85c0.88,0.5,1.98,1.22,2.75,1.45
				c0.66,0.2,1.12-0.4,1.38-1.09c0.16,0.09,0.32,0.16,0.45,0.22c0,0-0.01,0.01-0.01,0.01l0.32,0.12c0.1,0.04,0.15,0.06,0.15,0.06
				s-0.45,0.97,0.82,1.74s2.47,1.31,3.08,1.33c0.61,0.02,1.74-0.71,1.74-0.71s0.85-0.65,2.02-0.87c0.92,0.65,5.87,8.62,8.24,12.14
				C82.77,240.35,84.2,242.23,84.2,242.23z"
            />
            <g>
              <path
                fill="#AB7D62"
                d="M61.4,210.97c0.48-0.32,1.61-1.25,2.81-2.01c0.83-0.53,1.76-0.93,2.09-1.18
					c0.82-0.62,1.59-2.75,1.08-2.49c-0.5,0.26-0.79-0.16-0.69-0.38c0,0-1.31,0.68-2.63,1.56c-1.32,0.88-2.9,2.07-3.74,2.53
					c-0.82,0.45-2.23,1.22-3.1,0.23c-0.03-0.05-0.06-0.1-0.09-0.15c-0.42,0.37-0.98,0.91-1.2,1.32c-0.21,0.4-1.08-0.27-1.24,0.99
					c-0.01,0.06-0.01,0.11-0.02,0.17c-0.1,1.5,1.16,1.57,1.95,2.21c0.79,0.64,1.75,1.53,2.47,1.89c0.62,0.31,1.17-0.21,1.54-0.84
					C60.21,214.5,60.59,211.51,61.4,210.97z"
              />
              <path
                fill="#D7BFB1"
                d="M50.38,206.94c1.65-0.3,5.36-0.81,7-1.34c1.64-0.53,5.45-2.97,6.41-3.46s1.62-0.65,1.06-0.28
					c-0.56,0.37-1.19,0.7-3.41,1.83c-2.2,1.12-4.04,2.36-4.45,2.96c-0.04,0.08-0.07,0.17-0.11,0.26c-0.23,0.59-0.15,1.24,0.17,1.98
					c0,0-1.05,0.39-1.73,0.98c-0.35,0.3-0.6,0.91-0.62,1.51c0.16-1.26,1.03-0.59,1.24-0.99c0.22-0.42,0.78-0.96,1.2-1.32
					c0.03,0.05,0.06,0.1,0.09,0.15c0.87,0.99,2.17,0.87,2.99,0.42c0.84-0.46,2.83-1.58,4.15-2.46c1.32-0.88,1.94-1.76,1.94-1.76
					c-0.1,0.23-0.3,0.6,0.2,0.34c0.5-0.26-0.07,0.23-0.89,0.85c-0.34,0.25-1.09,0.75-1.92,1.28c-1.2,0.76-2.55,1.61-3.03,1.93
					c-0.81,0.54-1.23,0.69-1.72,1.72c0,0,0-0.01,0-0.02c-0.08,0.27-0.15,0.64-0.06,1.06c-0.37-0.62-0.75-0.85-1.17-0.48
					c-0.49,0.43-0.49,1.76,0.05,2.19c0.54,0.43,0.79,1.24,1.18,1.06c0.36-0.17,0.5-1.14,0.29-1.95c0.01,0.02,0.02,0.04,0.03,0.05
					c0.41,0.58,0.93,1.04,1.36,1.37c0.15,0.11,0.28,0.21,0.4,0.29c0.61-0.8,2.3-1.51,3.86-2.36c1.56-0.85,2.49-2.09,2.49-2.09
					c-0.39,1.34-2.07,2.26-3.34,2.95c-1.26,0.69-2.29,1.37-2.58,1.77c0,0-0.6,0.88,0.52,1.85c1.12,0.96,2.21,1.7,2.82,1.83
					c0.6,0.12,1.84-0.41,1.84-0.41s0.25-0.09,0.41-0.43c0.16-0.34,0.62-0.25,0.62-0.25s0.27,0.9,1-0.72c0,0,0.4,1,1.42,1.07
					c1.02,0.07,1.57-0.52,2.01-0.91c0.44-0.39,0.56,1.99,2.39,2.99c1.83,1,3.02,1.72,3.02,1.72s-0.01,0-0.03,0.01
					c0.63-0.22,1.25-0.49,1.84-0.84c2.46-1.46,3.64-4,3.64-4s-0.7-1.32-2.49-3.76c-1.8-2.44-3.49-4.81-5.12-6.73
					c-1.63-1.92-2.69-2.99-3.24-4.05c-0.55-1.07-1-2.57-2.56-2.58c0,0-0.2-0.69-0.81-1.27c-0.61-0.57-1.81-0.82-2.92-0.38
					c-1.11,0.43-4.89,1.69-7.07,2.17c-2.18,0.48-3.19,1.04-4.73,1.76c-1.54,0.72-3.1,1.16-4.7,1.49c-0.82,0.17-1.75,0.28-2.43,0.51
					c0.22-0.06-0.89,1.25-0.95,0.82c-0.01,0.1-0.02,0.2,0.01,0.31C46.65,207.42,49.36,207.12,50.38,206.94z"
              />
              <path fill="#D7BFB1" d="M61.47,215.38l-0.14-0.08C61.42,215.35,61.47,215.38,61.47,215.38z" />
              <path
                fill="#AB7D62"
                d="M74.77,219.63c-1.83-1-1.56-3.77-1.99-3.39c-0.44,0.39-1.86,1.45-2.88,1.38
					c-1.02-0.07-1.28-1.61-1.28-1.61c-0.74,1.62-2.3,2.19-2.3,2.19s0.91-0.33,0.75,0c-0.16,0.34-0.41,0.43-0.41,0.43
					s1.65,0.69,2.99,0.84c0.87,0.09,1.51,0.7,2.21,1.52c0.7,0.83,1.43,1.74,1.43,1.74c2.18,0.28,3.98-0.5,4.22-0.61
					c0.02-0.01,0.03-0.01,0.03-0.01S76.6,220.63,74.77,219.63z"
              />
              <path
                fill="#AB7D62"
                d="M64.05,213.6c1.26-0.69,2.95-1.61,3.34-2.95c0,0-0.93,1.24-2.49,2.09c-1.55,0.85-3.24,1.57-3.86,2.36
					c0,0-0.01,0.01-0.01,0.01l0.3,0.17l0.14,0.08C61.76,214.97,62.79,214.29,64.05,213.6z"
              />
              <path
                fill="#AB7D62"
                d="M49.6,207.71c2.15-0.33,7.42-1.57,7.42-1.57l-0.04,0.53c0-0.01,0.01-0.01,0.01-0.02
					c0.41-0.6,2.25-1.83,4.45-2.96c2.22-1.14,2.85-1.46,3.41-1.83c0.56-0.37-0.1-0.21-1.06,0.28s-5.11,2.55-6.75,3.08
					s-5.01,1.06-6.67,1.36c-1.03,0.19-3.72,0.83-4.42-1C45.96,205.58,46.21,208.23,49.6,207.71z"
              />
              <path
                fill="#E7E5D8"
                d="M47.12,206.13c0.99-0.01,2.21-0.62,1.93-1.33c-0.28-0.7-0.59-0.78-1.44-0.6
					c-0.25,0.05-0.49,0.11-0.72,0.17c-0.55,0.15-0.97,0.35-0.96,0.67c0,0.05,0.01,0.1,0.01,0.15
					C46,205.63,46.23,206.14,47.12,206.13z"
              />
            </g>
            <path fill="#3E6869" d="M81.32,214.64c0,0,3.38-0.54,1.73,2.76L81.32,214.64z" />
            <path
              fill="#AB7D62"
              d="M88.51,131.69c0.71,0,1.06-0.49,0.73-0.52c-0.95-0.08-1.66,0.19-1.99,0.57
				C86.93,132.13,87.8,131.69,88.51,131.69z"
            />
            <path
              fill="#AB7D62"
              d="M87.54,132.4c-0.69,0.08-1.76-0.08-1.68-1.36C85.81,131.71,85.85,133.27,87.54,132.4z"
            />
            <path
              fill="#AB7D62"
              d="M82.99,136.68c0.01-0.23,0.09-0.48,0.16-0.7c0.36-1.18,1.71-3.64,1.71-3.64
				c0.01-0.09-2.56,3.99-3.29,6.94c-0.59,2.36,0.3-0.74,1.03-1.47c0.01,0.01,0.02,0.01,0.03,0.02c0,0,1.81,2.56,4.07,3.48
				c0,0,1.05-0.91-0.31-1.98c-0.47-0.37-0.57-0.49-1.39-1.05c-0.54-0.29-1.23-0.72-1.18-1.04c0-0.02-0.01,0.02,0,0
				c0.5-0.01,1.09-0.13,1.65-0.29c0.2-0.06,0.39-0.13,0.57-0.21c0,0,2.76-0.98,1.52-2.6c-0.02-0.02-0.03-0.05-0.05-0.07
				C87.52,134.02,85.16,135.88,82.99,136.68z"
            />
            <path
              fill="#AB7D62"
              d="M70.96,147.27c-5.36-1.79-6.92-2.64-8.32-5.68c0,0,2.23,4.74,3.71,7.38c1.48,2.64,2.57,4.63,2.5,7.43
				c0,0,1.52-6.56,4.81-6.8c4.72-0.35,9.06-0.67,9.06-0.67c-0.1-0.01-0.19-0.02-0.29-0.03C78.23,148.41,76.21,149.02,70.96,147.27z"
            />
            <path
              fill="#AB7D62"
              d="M79.28,120.74c-0.27,0.11-0.57,0.46-1.14,0.49C78.14,121.23,79.08,121.49,79.28,120.74z"
            />
            <path
              fill="#AB7D62"
              d="M78.68,120.72c0.35-0.17,0.4-0.25,0.4-0.25c-0.63,0.08-0.98-0.05-1.09-0.14
				C77.89,120.25,78.19,120.96,78.68,120.72z"
            />
            <path fill="#AB7D62" d="M79.77,119.63" />
            <g>
              <path fill="none" d="M85.49,118.76v0.04C85.51,118.78,85.51,118.77,85.49,118.76z" />
              <path
                fill="#D7BFB1"
                d="M83.1,119.26c0,0.03-0.01,0.05-0.01,0.07c0,0,0,0,0,0l0,0C83.09,119.3,83.09,119.28,83.1,119.26z"
              />
              <path
                fill="#D7BFB1"
                d="M84.67,115.84c-0.39-0.34-1.95,2.56-1.94,3.17c0,0-0.99,1.8-0.99,2.6c0,0.07-0.13,0.32-0.12,0.38
					c-0.85,0.03-0.58,0.87-0.6,0.98c-0.53,4.25,3.35,7.47,4.93,1.56C87.43,119.02,85.56,116.62,84.67,115.84z M85.49,118.76
					c0.02,0.01,0.02,0.02,0,0.04V118.76z"
              />
            </g>
            <path
              fill="#D7BFB1"
              d="M81.74,119.86c0-0.02,0-0.05,0-0.1c0,0-0.03-0.01-0.06-0.01c-0.94,0-1.91,0.61-2.3,0.77
				c0,0,0.18-0.03,0.46-0.08c0.43-0.08,1.24-0.24,1.9-0.37C81.74,119.96,81.74,119.96,81.74,119.86z"
            />
            <path
              fill="#4C2B1A"
              d="M81.74,119.76c-0.02,0-0.04-0.01-0.06-0.01c-0.92,0.28-1.75,0.56-2.15,0.72c0,0,0.18-0.03,0.46-0.08
				c0.43-0.08,1.09-0.2,1.75-0.33C81.74,119.96,81.74,119.86,81.74,119.76z"
            />
            <path
              fill="#4C2B1A"
              d="M83.93,114.13c-1.99-0.4-3.58,1.59-4.77,2.98c-0.25,0.3-0.45,0.53-0.61,0.71c0.09,0,0.18-0.01,0.27-0.01
				c1.09-0.86,2.95-2.22,4.02-2.38c0.75-0.11,1.37,0.12,1.83,0.42c0.28,0.06,0.42,0.33,0.55,0.45c0.28,0.27,0.35,0.45,0.35,0.52
				c0-0.02,0.07-0.09,0.1-0.07C86.01,116.16,85.84,114.51,83.93,114.13z"
            />
            <path
              fill="#4C2B1A"
              d="M86.22,121.49c0.01-0.01,0.03-0.02,0.04-0.03c-0.02-0.08-0.05-0.16-0.07-0.23
				c-0.09,0.1-0.16,0.19-0.23,0.27C86.04,121.5,86.13,121.49,86.22,121.49z"
            />
            <path
              fill="#161616"
              d="M86.73,115.71c2.12-11.44-1.58-10.55-1.95-10.44c-0.02,0.01-0.03,0.01-0.03,0.01
				c1.65,3.83,1.96,7.41,1.99,9.22c-0.03,0.53-0.08,1.07-0.13,1.61c-0.1,0.29-0.25,0.65-0.44,0.71c-0.05,0.02-0.09,0.02-0.14,0.02
				c-0.03,0-0.05,0-0.07,0c-0.02,0-0.05,0-0.07-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.08-0.02-0.14-0.05-0.18-0.07
				c-0.02-0.01-0.03-0.01-0.04-0.01c-0.01,0-0.01,0-0.01,0.01c0,0,0-0.01,0-0.02c0-0.04-0.02-0.12-0.05-0.21
				c0-0.01-0.01-0.02-0.01-0.03c-0.01-0.02-0.02-0.04-0.03-0.06c-0.01-0.02-0.02-0.04-0.03-0.06c-0.01-0.02-0.02-0.04-0.04-0.05
				c-0.01-0.01-0.09-0.11-0.1-0.12c-0.02-0.02-0.05-0.05-0.07-0.07c-0.14-0.12-0.34-0.22-0.61-0.28c-0.08-0.02-0.17-0.03-0.27-0.04
				c-0.83-0.08-1.75,0.72-2.42,1.36c-0.14,0.13-0.22,0.3-0.27,0.49c-0.49,0.03-1.52,0.08-2.89,0.16c-0.09,0-0.18,0.01-0.27,0.01
				c-0.19,0.01-0.4,0.02-0.6,0.03c-0.31,0.57-0.65,1.16-1.01,1.75c0.64,0,1.29,0.01,1.96,0.02c0.32,0,0.61,0.01,0.87,0.02
				c0.04,0,0.08,0,0.12,0c0.91,0.03,1.46,0.08,1.79,0.12c0.02,0,0.04,0.01,0.06,0.01c0,0.1,0,0.2,0,0.31c0,0.2-0.02,0.4-0.04,0.61
				c-0.01,0.11-0.03,0.24-0.04,0.38c-0.15,1.95-0.05,6.9,1.9,6.56c2.09-0.36,2.21-5.58,2.35-6.55c0.05-0.34,0.16-0.19,0.28,0.15
				c0.02,0.07,0.05,0.14,0.07,0.23c0.2,0.65,0.4,1.64,0.4,1.64s0.01-0.03,0.03-0.12c0.05-0.21,0.17-0.78,0.32-2.09
				c0.02-0.21,0.05-0.45,0.07-0.7c0.07-0.73,0.15-1.63,0.24-2.75c0.02-0.21,0.02-0.39,0.02-0.56
				C87.35,115.32,86.73,115.71,86.73,115.71z M84.99,124.35c-1.05,5.6-2.18,2.27-2.15-1.98c0-0.28,0.01-0.55,0.02-0.81
				c0-0.09,0.01-0.18,0.01-0.27c0.04-0.75,0.11-1.4,0.21-1.97c0.34-2.01,0.97-2.88,1.36-3.02
				C85.56,115.91,86.03,118.75,84.99,124.35z"
            />
            <path
              fill="#4C2B1A"
              d="M83.59,121.98l0.03,0c0,0,0.01-0.04,0.03-0.1c0.02-0.08,0.05-0.19,0.08-0.34
				c0.09-0.45,0.17-1.19-0.09-1.88c0,0,0.88-0.29,1.77-0.81c0.46-0.27-0.82,0.04-2.33,0.48c-0.1,0.56-0.17,1.21-0.21,1.97
				c0.02,0.11,0.04,0.21,0.07,0.31C83.16,121.72,83.38,121.84,83.59,121.98z"
            />
            <polygon
              fill="#D7BFB1"
              points="63.71,131.99 77.38,140.37 78.55,141.07 78.14,141.85 77.17,141.96 75.96,141.42 62.94,133.15 
							"
            />
            <path
              fill="#41637C"
              d="M60.46,129.83c-0.21,0.45-0.49,0.86-0.81,1.22c0.72,0.46,1.88,1.2,3.29,2.1l0,0
				c2.3,1.47,5.27,3.35,8.06,5.13c0.09,0.06,0.18,0.12,0.27,0.17c0.35,0.22,0.69,0.44,1.03,0.66c0.35,0.22,0.68,0.44,1.02,0.65
				c0.05,0.03,0.11,0.07,0.16,0.1c0.38,0.24,0.76,0.48,1.12,0.71c0.35,0.22,0.69,0.44,1.01,0.64c0.12,0.08,0.24,0.14,0.35,0.21l0,0
				c2.32,1.29,3.06-0.24,1.14-1.21c-1.11-0.56-1.85-1.07-4.65-2.72c-2.79-1.64-6.14-3.79-8.74-5.5
				C62.37,131.11,61.23,130.35,60.46,129.83z"
            />
            <path
              fill="#33190D"
              d="M42.95,138.01c0.48,1.69,0.88,3.41,1.14,5.09c0.05,0.27,0.09,0.52,0.12,0.76l0,0c0,0,0.23,0.88,0.27,2.08
				c0.09-0.02,2.19-0.36,4.35-1.38c0-0.23,0-0.46-0.01-0.69c-0.33-5.94-8.05-14.7-10.84-18.69c0.11,0.24,0.21,0.48,0.33,0.71
				C40.58,130.44,42.03,134.64,42.95,138.01z"
            />
            <path
              fill="#3E6869"
              d="M41.9,151.53c-0.27,0.06-0.58,0.1-0.95,0.13c-0.08,0.01-0.18,0.02-0.27,0.02
				c-0.68,0.07-1.43,0.11-2.22,0.15c-0.17,0.01-0.34,0.02-0.48,0.02c0,0-0.01,0-0.01,0c-1.31,0.05-2.75,0.08-4.26,0.13c0,0,0,0,0,0
				c3.94,1.47,7.18,1.5,9.64,0.61c-0.46-0.39-0.92-0.74-1.38-1.07C41.94,151.52,41.92,151.52,41.9,151.53z"
            />
            <path
              fill="#E8E6D9"
              d="M23.38,221.45c-0.66,0.14-1.18,0.32-1.54,0.48c-0.35,0.15-0.5,0.58-0.31,0.91
				c0.25,0.44,0.58,1.02,0.76,1.3c0.31,0.49,1.66-0.31,2.4-0.76c0,0,0.01-0.01,0.01-0.01c0.49-0.3,0.37-1.19-0.19-1.66
				C24.24,221.48,23.85,221.35,23.38,221.45z"
            />
            <path
              fill="#E8E6D9"
              d="M27.18,229.43c-0.14,0.48,0.18,1.12,0.37,1.68c0.17,0.46,1.23,0.19,2.05-0.08
				c0.17-0.05,0.33-0.11,0.47-0.16c0.63-0.22,0.71-1.4-0.09-1.92c-0.21-0.14-0.47-0.24-0.79-0.24
				C27.72,228.71,27.32,228.95,27.18,229.43z"
            />
            <path
              fill="#E8E6D9"
              d="M30.19,232.63c-1.45,0.17-1.82,0.46-1.9,0.96c-0.08,0.5,0.31,1.09,0.58,1.62c0.15,0.31,0.71,0.2,1.3-0.02
				c0.42-0.15,0.86-0.36,1.18-0.52c0.45-0.22,0.48-0.93,0.14-1.46C31.22,232.84,30.79,232.56,30.19,232.63z"
            />
            <path
              fill="#E8E6D9"
              d="M27.36,236.31c-1.08,0.36-1.31,0.64-1.3,1.03c0.01,0.39,0.41,0.78,0.69,1.14
				c0.2,0.26,0.77-0.06,1.27-0.41c0.2-0.14,0.38-0.28,0.53-0.39c0.32-0.25,0.21-0.83-0.17-1.17
				C28.13,236.28,27.78,236.17,27.36,236.31z"
            />
            <path
              fill="#AC7D61"
              d="M12.34,241.81c0.02-0.01,0.05-0.14,0.03-0.17c-0.5-0.78-1.44-1.41-2.16-2.31
				c-0.72-0.91-0.57-2.03-1.11-2.47c-0.34-0.28-0.74-0.71-1.01-1.27c-0.99-0.38-2.27-0.69-3.5-0.46c-1.76,0.32-2.04,0.18-2.04,0.18
				s0.02,0.14,0.09,0.38c0.18,0.58,0.66,1.8,1.95,3.34C6.33,241.11,10.53,242.58,12.34,241.81z"
            />
            <path fill="#E7E5D8" d="M47.61,204.29c-0.25,0.05-0.49,0.11-0.72,0.17c-0.55,0.15-0.97,0.35-0.96,0.67" />
          </g>
          <g>
            <g>
              <polygon fill="none" points="172.54,219.07 172.54,219.07 172.54,219.07 				" />
              <path
                fill="none"
                d="M132.26,189.28c0,0,0.01,0.02,0.02,0.04c0-0.02-0.01-0.04-0.03-0.07
					C132.26,189.27,132.26,189.28,132.26,189.28z"
              />
              <path
                fill="#E6D6CF"
                d="M176.56,236.03c-0.45,0.27-1.01,0.57-1.47,0.86C175.6,236.61,176.09,236.36,176.56,236.03z"
              />
              <path
                fill="#E6D6CF"
                d="M173.68,212.02c-0.12,0.37-2.03,2.14-2.03,2.14c-2.12,2.34-2.52,4.45-3.34,8.39
					c-0.82,3.94-2.57,6.39-1.36,8.86c1.21,2.47,1.07,3.14-1.96,4.26c-1.35,0.5-2.26,2.1-3.12,2.82c0.96,0.93,1.95,1.98,2.8,3.05
					c0.27-0.16,1.07-0.21,1.42-0.41c2.89-1.62,7.06-3.86,9.25-4.96c2.2-1.1,1.87-1.79,3.66-3.46c1.79-1.68,2.3-2.68,2.73-2.94
					c0.43-0.26,0.3-2.05,0.44-2.57c0.14-0.52,0,0,0.14-0.52l0.78-1.62c0,0-0.02,0.06-0.06,0.15c0.05-0.11,0.09-0.21,0.14-0.31
					c0.26-0.57-1.04-2.08-2.59-0.16c-1.56,1.92-1.5,2.96-2.39,3.63c-0.88,0.67-2.23,1.87-2.54,2.13c-0.16,0.14-0.97,0.31-1.92,0.52
					c-0.21-0.11-1.05-0.5-1.82-0.54c-0.44-0.03-0.93,0.02-1.26-0.82c-0.13-0.28-0.17-0.64-0.09-1.05c0.21-1.14-0.26-3.27,0.42-5.08
					c0.04-0.12,0.08-0.23,0.12-0.35c0.03,0.02,1.37,0.17,1.41,0.19c-0.03-0.04-0.43-0.47-0.43-0.47l-0.29-0.76l0,0l0.71-1.03
					c0,0,0.61,0.03,1.25,0.21c0.64,0.17,0.99,0.93,1.6,1.22s1.18-0.25,1.18-0.25s-0.02,0.14-0.11,0.31c0.12-0.2,0.15-0.42,0.06-0.56
					c-0.23-0.36-0.74-0.89-1.19-1.19s-0.79-0.98-1.47-1.32c-0.43-0.22-0.77-0.38-1.2-0.46c-0.03,0-0.07,0.01-0.1,0.01
					c0,0,0.01-0.01,0.01-0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.14-0.24,0.95-1.41,1.22-1.93c0,0.64,0.56,0.68,1.02,0.92
					c0.97,0.5,2.63,0.8,2.76-0.15c0,0,0,0.02-0.01,0.06c0.39-0.89-0.58-1.26-1.15-1.37c-0.6-0.11-0.68-0.72-1.36-0.79
					c-0.19-0.02-0.37-0.05-0.6-0.09c0.2-0.3,0.41-0.58,0.64-0.85c0.88-0.99,2.57-3.73,1.19-4.46c-0.88-0.47-2.02,0.78-2.44,1.14
					C173.41,212.3,173.84,211.55,173.68,212.02z"
              />
              <path
                fill="#E6D6CF"
                d="M160.09,234.74c-0.19,0.42-0.48,0.94-0.76,1.4c0.01,0.01,0.03,0.03,0.05,0.04
					C159.63,235.76,159.89,235.27,160.09,234.74z"
              />
              <path
                fill="#E6D6CF"
                d="M168.08,217.33c0.85-0.42,1.16-0.64,1.56-1.91c-0.51,0.11-0.9,0.31-1.21,0.57
					C167.39,217.28,167.56,217.89,168.08,217.33z"
              />
              <path fill="#E6D6CF" d="M174.56,222.71c-0.81-0.45-1.58-1.12-1.58-1.12S173.72,222.27,174.56,222.71z" />
              <path
                fill="#E6D6CF"
                d="M173.62,223.45c0,0,0,0.13-0.08,0.26c0.15-0.19,0.2-0.41,0.15-0.51c-0.13-0.23-0.71-0.79-1.1-1.12
					c-0.12-0.1-0.25-0.21-0.39-0.31l-0.12,0.03C172.6,222.35,173.48,223.29,173.62,223.45z"
              />
              <path
                fill="#E6D6CF"
                d="M172.52,223.91c0.24,0.09,0.44,0.1,0.6,0.06C172.97,224,172.77,223.99,172.52,223.91z"
              />
              <path
                fill="#AC7D61"
                d="M168.52,215.86c0.01-0.01,0.02-0.03,0.04-0.04C168.56,215.81,168.54,215.83,168.52,215.86z"
              />
              <path
                fill="#AC7D61"
                d="M168.49,215.88c0.01-0.01,0.02-0.02,0.03-0.03C168.51,215.87,168.5,215.87,168.49,215.88z"
              />
              <path
                fill="#AC7D61"
                d="M175.56,221.98c-0.61-0.29-1.1-1.01-1.73-1.18c-0.64-0.17-1.96-0.14-1.96-0.14l-0.32,1.27l0,0
					l-0.38,1.27c0,0,0.01,0.02,0.05,0.06c0.11,0.12,0.46,0.42,1.3,0.66c0.26,0.07,0.45,0.09,0.6,0.06c0.23-0.03,0.36-0.15,0.42-0.27
					c0.08-0.13,0.08-0.26,0.08-0.26c-0.14-0.16-1.02-1.11-1.54-1.66l0.12-0.03l0.77-0.18c0,0,0.77,0.67,1.58,1.12
					c0.27,0.15,0.55,0.27,0.8,0.34c0.63,0.16,0.93-0.17,1.07-0.44c0.08-0.17,0.11-0.31,0.11-0.31S176.17,222.27,175.56,221.98z"
              />
              <path
                fill="#AC7D61"
                d="M172.93,231.17c-0.09,0.01-0.18,0.04-0.27,0.07C173.13,231.13,173.47,231.1,172.93,231.17z"
              />
              <path
                fill="#AC7D61"
                d="M148.27,242.48c3.78-3.06,7.66-6.02,9.91-7.23C156.05,236.34,152.37,239.11,148.27,242.48z"
              />
              <path fill="#E6D6CF" d="M241.85,249.96c0,0.03,0,0.05,0,0.08C241.85,250.02,241.85,249.99,241.85,249.96z" />
              <path
                fill="#E6D6CF"
                d="M241.8,249.74C241.8,249.75,241.8,249.75,241.8,249.74C241.8,249.75,241.8,249.75,241.8,249.74z"
              />
              <path
                fill="#E6D6CF"
                d="M241.83,249.84c0,0.01,0.01,0.03,0.01,0.04C241.83,249.87,241.83,249.85,241.83,249.84z"
              />
              <path
                fill="#E6D6CF"
                d="M237.44,255.56c-2.3,1.31-6.87,3.64-9.3,4.71l0,0.01C230.57,259.2,235.14,256.88,237.44,255.56
					c2.3-1.3,3.47-3.7,4.17-4.75c0.08-0.12,0.14-0.25,0.18-0.36c-0.04,0.12-0.1,0.24-0.18,0.36
					C240.91,251.85,239.74,254.25,237.44,255.56z"
              />
              <path
                fill="#E6D6CF"
                d="M241.86,250.1c0,0.05-0.01,0.1-0.02,0.15C241.85,250.2,241.85,250.15,241.86,250.1z"
              />
              <path
                fill="#E6D6CF"
                d="M241.84,250.28c-0.01,0.05-0.02,0.1-0.04,0.15C241.81,250.37,241.83,250.33,241.84,250.28z"
              />
              <path
                fill="#E6D6CF"
                d="M241.68,249.52C241.68,249.53,241.69,249.53,241.68,249.52c0.3-0.37,0.68-0.84,1.12-1.4
					c0.29-0.37,0.43-0.69,0.48-0.96c-0.05,0.27-0.19,0.59-0.48,0.95C242.37,248.68,241.99,249.15,241.68,249.52z"
              />
              <path
                fill="#E6D6CF"
                d="M226.74,260.66c-1.57,0.39-7.9,2.43-9.62,2.72c-1.72,0.29-2.45,0.21-4.4,0.95
					c-1.95,0.74-2.04,0.82-2.8,1.38c-0.28,0.21-1.01,0.81-1.83,1.49c0,0,0,0,0,0.01c0.82-0.68,1.55-1.28,1.83-1.49
					c0.76-0.57,0.85-0.64,2.8-1.38c1.95-0.74,2.67-0.66,4.4-0.95C218.84,263.09,225.17,261.06,226.74,260.66
					c0.6-0.14,1.06-0.3,1.44-0.47l0-0.01C227.8,260.35,227.34,260.51,226.74,260.66z"
              />
              <path
                fill="#E6D6CF"
                d="M206.15,256.59c0.14,0.07,0.25,0.2,0.33,0.39c0.17,0.38,0.32,0.76,0.45,1.14
					c1.88-0.21,4.97-1.06,7.29-6.01c2.96-6.3,15.37-3.47,18.16-4.85c2.41-1.19,4.71-2.56,8.63-4.37c-1.32,0.17-3.22,0.87-3.83,1
					c-0.94,0.2-3.62,1.25-3.62,1.25s-4.59,0.6-7.03,0.63c-2.04,0.03-4.82,0.48-5.43,0.42c-0.02,0.07-0.02,0.11-0.02,0.11
					s-0.56,0.04,0-1.24c0.56-1.28,1.62-2.4,2.77-3.12c1.15-0.72,1.54-0.85,3.15-1.63c1.62-0.78,1.38-2.28,1.5-2.73
					c0,0,0.01,0.01,0.01,0.01c-0.35-0.53-1.01-0.71-1.37-0.48c-0.77,0.48-2.34,1.14-3.64,1.66c-1.3,0.53-2.5,1.17-3.79,2.11
					c-1.29,0.94-3.3,1.25-4.75,2.22c-1.45,0.98-2.11,1.61-3.32,2.82c-1.21,1.21-2.72,1.81-3.6,4.14c-0.87,2.33-1.42,2.63-2.22,3.31
					c-0.24,0.21-0.71,0.58-1.29,1.03C205.1,254.86,205.66,255.53,206.15,256.59z"
              />
              <path
                fill="#E6D6CF"
                d="M242.74,245.99c0.38-0.55,0.55-1,0.59-1.36C243.29,244.99,243.12,245.43,242.74,245.99
					C242.74,245.99,242.74,245.99,242.74,245.99z"
              />
              <path
                fill="#AC7D61"
                d="M242.74,245.99C242.74,245.99,242.74,245.99,242.74,245.99c0.38-0.55,0.55-1,0.59-1.36
					c0.12-1.09-0.95-1.39-0.95-1.39c-0.42-0.55-1.05-0.67-1.73-0.58c-0.01,0-0.01,0.01-0.02,0.01c-3.92,1.8-6.1,2.46-8.51,3.65
					c-2.79,1.38-15.71-1.24-18.67,5.06c-2.33,4.95-4.64,6.54-6.52,6.75c0.37,1,0.06,2.34,0.26,3.31c0.21,1.05-0.02,2.29,0.03,3.26
					c0.06,1.1,0.95,1.58,0.88,2.51c0.82-0.68,1.55-1.28,1.83-1.49c0.76-0.57,0.85-0.64,2.8-1.38c1.95-0.74,2.67-0.66,4.4-0.95
					c1.72-0.29,8.05-2.32,9.62-2.72c0.6-0.15,1.06-0.31,1.44-0.48l0,0.01l-0.03,0.09c0,0,0,0,0,0c2.43-1.07,7-3.4,9.3-4.71
					c2.3-1.31,3.47-3.71,4.17-4.75c0.08-0.12,0.14-0.24,0.18-0.36c0-0.01,0.01-0.02,0.01-0.02c0.02-0.05,0.03-0.1,0.04-0.15
					c0-0.01,0-0.02,0-0.02c0.01-0.05,0.01-0.1,0.02-0.15c0-0.02,0-0.04,0-0.06c0-0.03,0-0.05,0-0.08c0-0.03-0.01-0.05-0.01-0.08
					c0-0.01-0.01-0.03-0.01-0.04c-0.01-0.03-0.02-0.06-0.03-0.09c0,0,0,0,0,0c-0.03-0.08-0.06-0.15-0.11-0.22c0,0,0,0,0,0
					c0.3-0.37,0.68-0.85,1.12-1.41c0.29-0.37,0.43-0.69,0.48-0.95C243.43,246.37,242.76,246,242.74,245.99z"
              />
              <path
                fill="#E6D6CF"
                d="M106.8,238.38c2.88-0.19,7.16-1.32,10.71-1.93c-0.97-3.23-3.41-11.39-1.61-13.99
					c-0.08,0.05,4.84-3.44,8.59-2.18c-0.27-0.01,2.62,0.28,3.96,0.43c2.47,0.27,4.17-1.82,4.48-4.38c0.38-3.12-0.62-3.8,0.48-4.97
					c0.39-0.42,0.85-0.84,0.99-1.33c-0.03,0.07-0.07,0.13-0.11,0.19c0,0-1.04-3.58-0.89-3.77c0.89-1.07,1.39-1.94,1.5-2.14
					c-0.08-0.12-0.17-0.25-0.29-0.39c-0.85-1.03-0.82-2.26-0.68-3.04c0.33-1.69,2.05-2.06,2.85-2.09c0.02,0,0.05-0.01,0.06-0.02
					c0.92-0.66,0.78-1.54-0.89-3.77c-1.6-2.14-3.22-4.86-3.6-5.5c-0.05-0.09-0.06-0.2-0.03-0.3l-0.03-1.66l1.05-1.15l0,0
					c-0.5-1.16-1.4-2.37-1.17-3.06c0.05-0.14,0.08-0.3,0.1-0.47c0,0.02-0.01,0.03-0.01,0.03c-0.19-0.19-0.39-0.37-0.62-0.49
					c0,0,0,0,0,0c-0.35,0.32-0.6,0.49-0.66,0.53c-0.01,0.01-0.02,0-0.02,0c-0.04-0.06-0.25-0.28-0.72-0.29c0,0,0,0,0,0
					c-0.19,0.13-0.4,0.3-0.62,0.53c0,0,0.09-0.21,0.24-0.49c-0.29,0.06-0.64,0.19-1.07,0.44c-1.28,0.74-3.29,1.01-4.82,1.14
					c-0.38,0.03-0.73,0.05-1.04,0.07c-0.1,0.02-0.19,0.04-0.35,0.05c-0.02,0-0.05,0.01-0.08,0.01c-0.03,0.01-0.06,0.02-0.1,0.03
					c0,0-0.01,0-0.01,0c-0.01,0-0.01,0.01-0.02,0.01c-0.21,0.08-0.44,0.25-0.51,0.42c-0.39,0.67-1.12,2.01-1.69,3.5
					c-0.26,0.71-0.48,1.45-0.61,2.16c-0.11,0.64-0.32,1.2-0.56,1.69c-0.02,0.05-0.05,0.1-0.08,0.15c-0.03,0.05-0.05,0.1-0.08,0.14
					c-1,1.8-2.71,2.72-2.71,2.72l0-6.22c-0.51,0.34-1.01,0.66-1.49,0.9c0,0,0.04-0.1,0.1-0.24c0,0,0,0-0.01,0
					c0,0,0.03-0.06,0.07-0.16c0.13-0.36,0.27-0.82,0.11-0.82c-0.12,0-0.44,0.28-0.87,0.68c-0.14,0.12-1.26,1.18-1.43,1.33
					c-0.14,0.12-0.27,0.24-0.4,0.35c0,0,0.07-0.12,0.13-0.27c-0.01,0.01,0.02-0.07,0.08-0.21c0.05-0.15,0.08-0.3,0.05-0.42
					c-0.11-0.06-0.14-0.14-0.26-0.16c-0.09-0.02-0.26,0.02-0.35,0.02c-0.16,0.05-0.36,0.14-0.61,0.26
					c-0.12,0.06-0.23,0.11-0.32,0.15c-0.01,0.01-0.03,0.02-0.04,0.04c-0.31,0.27-0.75-0.11-0.53-0.45c0-0.01,0.01-0.02,0.01-0.02
					c0.02-0.03,0.03-0.06,0.04-0.09c0.19-0.73,0.72-1.97,0.58-3.11c0,0-1.08,0.13-2.22,3.09c-1.13,2.96-3.61,14.56-6.02,20.25
					c-1.43,3.37-5.12,6.72-13.76,5.38c0,0,0,0,0,0c-0.21,8.37-4.26,13-6.05,14.64C84.32,231.47,90.08,238.68,106.8,238.38z"
              />
              <path
                fill="#E6D6CF"
                d="M136.21,199.13c0.04-0.02,0.08-0.04,0.12-0.06c-0.22,0.1-0.42,0.19-0.6,0.28
					C135.88,199.28,136.04,199.21,136.21,199.13z"
              />
              <path fill="#AC7D61" d="M116.35,207.06c0-0.08,0-0.12,0-0.12S116.35,206.98,116.35,207.06z" />
              <path fill="#AC7D61" d="M134.19,186.99l0.08,0.06C134.27,187.05,134.24,187.02,134.19,186.99z" />
              <path
                fill="#AC7D61"
                d="M153.16,305.97c0.03-0.04,0.06-0.09,0.08-0.13c-0.7-0.24-1.41-0.52-2.14-0.83
					C152.42,305.63,153.16,305.97,153.16,305.97z"
              />
              <path
                fill="#AC7D61"
                d="M143.32,301.16c-2.64-1.41-5.65-3.05-9.17-4.89C137.5,298.12,140.62,299.77,143.32,301.16z"
              />
              <path
                fill="#AC7D61"
                d="M59.65,369.24c0.26,0.25,0.54,0.49,0.81,0.73c-0.27-0.32-0.51-0.66-0.74-1
					C59.67,369.14,59.65,369.24,59.65,369.24z"
              />
              <path
                fill="#AC7D61"
                d="M66.64,307.29c0.03,0.18,0.06,0.36,0.09,0.54c-1.16-8.6-2.8-15.06-4.26-21.75
					C63.79,292.89,65.3,300.05,66.64,307.29z"
              />
              <path
                fill="#AC7D61"
                d="M67.31,312.67c1.2,16.75-3.9,40.92-6.38,51.39C63.52,353.91,68.99,329.72,67.31,312.67z"
              />
              <path
                fill="#AC7D61"
                d="M59.62,268.2c-0.26-2.92-0.43-6.08-0.5-9.59C59.07,261.59,59.26,264.8,59.62,268.2z"
              />
              <path
                fill="#AC7D61"
                d="M93.67,379.41c8.91,0.36,17.98-0.2,25.67-1C112.37,379.06,103,379.66,93.67,379.41z"
              />
              <path
                fill="#AC7D61"
                d="M124.3,313.82c1.4,14.19,4.69,23.05,4.23,32.92c-0.69,14.86-5.83,17.6-0.46,30.69
					c5.5-0.83,11.33-2,11.33-2s-10.27-35.65-8.36-80.82c-0.47-0.28-0.92-0.56-1.34-0.85C129.69,293.77,122.32,293.75,124.3,313.82z"
              />
              <path
                fill="#AC7D61"
                d="M153.83,306.04c-0.08-0.03-0.16-0.05-0.24-0.08C153.67,305.98,153.75,306.01,153.83,306.04z"
              />
              <path
                fill="#4D2C1B"
                d="M206.93,258.12c-0.14-0.38-0.29-0.76-0.45-1.14c-0.09-0.2-0.2-0.32-0.33-0.39
					c-1.85-0.94-9.92,7.56-20.08,19.58c-10.9,12.91-14.29,3.75-16.01,2.03c-1.72-1.72-4.71-2.47-4.42-1.61
					c0.29,0.86-3.69-1.6-3.69,2.13s-3.28-1.98-3.28-1.98s0.45,9.31-7.17,3.79c-5.94-4.29-11.38-15.26-11.38-15.26
					c-4.39-9.46-17.5-30.09-29.82-29.44c-1.12,0.06-2.2,0.1-3.24,0.11c-16.72,0.29-22.73-4.48-24.09-5.82
					c-0.22-0.22-0.32-0.35-0.32-0.35c-12.94,3.35-23.71,13.45-23.54,27.76c0,0.36,0.01,0.72,0.02,1.08c0.07,3.5,0.24,6.67,0.5,9.59
					c0.61,6.93,1.67,12.48,2.85,17.87c1.46,6.69,3.1,13.16,4.26,21.75c0.2,1.46,0.38,2.98,0.55,4.58c0.01,0.09,0.02,0.18,0.03,0.27
					c0.72,19.59-4.1,41.4-6.38,51.39c-0.69,3.02-1.28,4.79-1.28,4.79c0.02,0.04,0.05,0.07,0.07,0.11c0.23,0.34,0.47,0.68,0.74,1
					c5.58,6.8,19.34,9.07,33.2,9.44c9.33,0.25,18.71-0.35,25.67-1c6.71-0.63,11.18-1.31,11.18-1.31c-0.02-0.04-0.03-0.08-0.05-0.11
					c-5.37-13.09-0.62-15.48,0.08-30.34c0.46-9.88-2.62-18.87-4.02-33.06c-1.98-20.08,3.17-19.82,3.17-19.82
					c0.42,0.29,0.87,0.57,1.34,0.85c0.34,0.2,0.69,0.39,1.06,0.58c0.71,0.36,1.39,0.72,2.06,1.07c3.53,1.84,6.53,3.48,9.17,4.89
					c2.96,1.58,5.48,2.87,7.78,3.85c0.73,0.31,1.44,0.59,2.14,0.83c0.11,0.04,0.23,0.08,0.34,0.12c0.08,0.03,0.16,0.05,0.24,0.08
					c4.77,1.56,9.01,1.52,15.24-0.45c9.55-3.03,16.68-13.13,25.19-20.68c9.29-8.24,12.85-12.63,12.85-12.63s0.75-2.03,1-5.07
					c0,0,0,0,0-0.01C208.31,264.67,208.16,261.45,206.93,258.12z"
              />
              <path
                fill="#4D2C1B"
                d="M150.88,268.01c0.48-2.47,2.97-6.41,3.85-8.98c1.42-4.16,3.75-8.29,5.37-11.68
					c1.53-3.23,2.17-6.04,2.43-7.62c0.14-0.85-0.57-0.96-0.57-0.96c-0.28-0.33,0.24-1.03,0.04-1.32c-0.51-0.75-0.85-1.36-1.34-1.78
					c-0.02-0.01-1.31,0.47-1.32,0.46c-0.71-0.6-1.16-0.89-1.16-0.89c-2.25,1.21-6.13,4.17-9.91,7.23
					c-5.36,4.33-10.51,8.86-10.51,8.86c0.02,0.02-0.02,0.85,0,0.87c0.56,0.57,0.85,1.3,1.61,2.15c2.17,2.43,4.53,7.01,6.72,9.67
					C148.21,266.59,150.88,268.01,150.88,268.01z"
              />
              <path
                fill="#A0948F"
                d="M107.05,235.95c1.05-0.07,2.13-0.16,3.27-0.28c-0.68-7.27,2.32-11.17,5.56-13.18
					c1.41-0.87,2.87-1.39,4.09-1.67c1.94-0.44,3.17-0.55,4.26-0.52c0.11,0,0.22,0.01,0.32,0.01c1.16,0.07,2.22,0.28,3.91,0.4
					c1.12,0.08,1.95-0.29,2.45-0.61c0.37-0.24,0.56-0.45,0.56-0.45c-0.02,0.01-0.03,0.01-0.05,0.02c-5.45,2.59-3.25-8.25-3.51-14.25
					c-0.24-5.44-0.91-7.16-2.27-10.72c-1.35-3.54-3.36-7.94-2.22-10.4c-0.16,0.01-0.32,0.02-0.46,0.03
					c-0.1,0.02-0.19,0.04-0.35,0.05c-0.02,0-0.05,0.01-0.08,0.01c-0.03,0.01-0.06,0.02-0.1,0.03c0,0-0.01,0-0.01,0
					c-0.01,0-0.01,0.01-0.02,0.01c-0.21,0.08-0.44,0.25-0.51,0.42c-0.39,0.67-1.12,2.01-1.69,3.5c-0.26,0.71-0.48,1.45-0.61,2.16
					c-0.11,0.64-0.32,1.2-0.56,1.69c-0.02,0.05-0.05,0.1-0.08,0.15c-0.03,0.05-0.05,0.1-0.08,0.14c-1,1.8-2.71,2.72-2.71,2.72
					l0-6.22c-0.51,0.34-1.01,0.66-1.49,0.9c0,0,0.04-0.1,0.1-0.24c0,0,0,0-0.01,0c0,0,0.03-0.06,0.07-0.16
					c0.13-0.36,0.27-0.82,0.11-0.82c-0.26,0-1.57,1.4-2.7,2.35c0,0,0.07-0.12,0.13-0.27c-0.01,0.01-0.03,0.03-0.04,0.04
					c0,0,0.06-0.11,0.12-0.25c0.14-0.45,0.12-0.97-1.17-0.3c-2.01,1.05,0-1.4-0.26-3.49c0,0-1.08,0.13-2.22,3.09
					c-1.13,2.96-3.61,14.56-6.02,20.25c-1.43,3.39-5.16,6.75-13.87,5.37c0.03,0.67,0.06,1.31-0.01,1.93
					c-0.5,4.28-2.25,8.37-4.01,10.61c-0.73,0.93-1.42,1.64-1.93,2.11C84.29,231.44,90.29,236.24,107.05,235.95z"
              />
              <path
                fill="#6D5B51"
                d="M242.49,243.53c-0.15,0.58-0.9,1.23-0.9,1.23s-0.73-0.23-0.43-0.81c0.32-0.63,0.62-0.85,0.62-0.85
					s-1.08,0.81-0.84,1.37c0.23,0.56,0.43,0.48,0.43,0.48s-0.49,0.72-1.23,1.2c-0.73,0.48-0.92,0.28-0.92,0.28s-0.21,0.54-1.68,1.53
					c-0.82,0.56-1.28,0.88-1.75,1.14c-0.26,0.09-0.42-0.17-0.42-0.17s0.12,0.32-0.25,0.48c0,0-0.09,0.05-0.64,0.25
					c-1.29,0.45-4.45,1.47-5.92,1.9c-0.81,0.24-1.15,0.11-1.26,0.02c0.01,0.02,0.02,0.04,0.03,0.07c0.02,0.05,0.05,0.11,0.08,0.18
					c0.2,0.21,0.43,0.47,0.68,0.78c-0.09-0.34-0.12-0.66,0.07-0.69c0.42-0.07,3.8-1.15,5.39-1.66c1.59-0.51,2.14-0.59,2.67-1.1
					c0.53-0.5,2.87-1.91,3.62-2.48c0.74-0.57,1.46-1.2,2.03-1.79C242.43,244.32,242.6,243.13,242.49,243.53z"
              />
              <path
                fill="#6D5B51"
                d="M243.29,244.3c0,0-0.02,0.84-0.61,1.57c-0.31,0.38-0.56,0.58-0.72,0.68c-0.11-0.1-0.38-0.38-0.16-0.81
					c0.27-0.54,0.97-1.55,0.97-1.55s-0.76,1.06-1.13,1.55c-0.36,0.47,0,0.63,0.02,1.08c-0.11,0.26-0.38,0.86-0.96,1.95
					c-0.06,0.11-0.12,0.2-0.18,0.3c-0.26,0.18-0.43-0.21-0.45-0.02c-0.02,0.17-0.02,0.44-0.06,0.7c-0.59,0.66-1.28,1.15-1.95,1.74
					c-0.13,0.11-0.26,0.22-0.39,0.33c-0.16,0.07-0.25,0.02-0.31-0.06c0.02,0.1,0.03,0.21-0.01,0.3c-0.79,0.57-1.62,1.01-2.29,1.28
					c-0.87,0.35-2.48,1.01-3.7,1.61c-0.9,0.45-1.51,0.25-1.77,0.12c0.11,0.23,0.23,0.47,0.34,0.73l0.23,0.4c0,0,0-0.32,2.17-1.37
					c2.17-1.04,2.94-1.35,4.3-2.04c1.36-0.68,3.14-2.44,3.63-3.19c0.5-0.75,1.13-1.96,1.37-2.41c0.24-0.45,1.11-1.2,1.11-1.2
					s0,0,0,0c0-0.01,0.02-0.03,0.04-0.06C242.92,245.69,243.39,244.85,243.29,244.3z"
              />
              <path
                fill="#341A0D"
                d="M86.52,377.54c17.45-0.83,23.28-5.2,2.37-8.42c-29.79-4.6-21.07-7.43-11.51-11.8
					c9.72-4.44-7.44-2.64-1.39-19.95c3.71-10.6-1.45-22.28-5.82-33.8c-2.84-7.5-5.51-14.88-7.57-21.17
					c-2.09-11.99-3.69-26.69,1.56-35.86c6.53-11.41,12.57-13.04,18.48-16.76c0,0-1.26,0.21-3.31,1.02
					c-3.79,1.5-10.28,5.07-16.56,13.2c-0.2,0.26-0.39,0.54-0.57,0.82c-0.77,1.21-1.37,2.63-1.83,4.24
					c-0.9,3.16-1.24,6.99-1.22,11.11c0.01,2.01,0.11,4.09,0.28,6.19c0.1,1.27,0.22,2.54,0.37,3.82c0.22,1.93,0.25,4.19,1.22,8.71
					c0.07,0.34,0.15,0.69,0.22,1.05c0.29,1.64,0.6,3.22,0.91,4.73c0.13,0.64,0.26,1.27,0.4,1.88c0.17,0.77,0.33,1.52,0.49,2.23
					c0.32,1.65,0.62,3.36,0.96,5.1c2.63,13.42,3.09,19.77,3.25,26.61c0.36,15.66-7.61,48.76-7.61,48.76s1.03,2.02,3.48,3.22
					C67.8,374.76,76.36,378.02,86.52,377.54z"
              />
              <path
                fill="#7B5743"
                d="M129.69,293.77L129.69,293.77L129.69,293.77c0,0-5.15-0.25-3.17,19.82c1.4,14.19,4.48,23.18,4.02,33.06
					c-0.61,13.17-4.42,16.54-1.53,26.26c0.08,0.2,0.12,0.31,0.12,0.31s4.24-15.08,0.1-51.7
					C126.97,301.6,130.12,296.08,129.69,293.77z"
              />
              <path
                fill="#6D5B51"
                d="M242.33,248.58c-0.1-0.15-0.34-0.58-0.2-0.83c0.17-0.31,0.9-1.03,0.9-1.03
					c-0.33,0.3-0.76,0.61-0.98,0.92c-0.07,0.1-0.11,0.31-0.09,0.4c0.07,0.29,0.21,0.54,0.27,0.64c-0.59,0.63-0.9,1.14-1.75,2.08
					c-0.19,0.21-0.42,0.46-0.67,0.73c-1.15,1.25-2.72,2.92-3.12,3.36c-0.48,0.53-5.85,3.43-5.85,3.43l0,0c0,0,5.1-2.46,6.14-3.19
					c0.53-0.37,3.3-3.68,3.92-4.63l0.48-0.6c-0.26,0.37-0.4,0.69-0.26,0.9c0.13,0.19,0.24,0.29,0.29,0.33c0,0,0,0,0,0
					c0.01,0.01,0.02,0.02,0.02,0.02l0.05-0.13c0,0,0,0,0,0c0,0,0,0,0,0c-0.03-0.02-0.28-0.17-0.23-0.51
					c0.04-0.29,0.22-0.67,0.28-0.8l1.13-1.39l0.15-0.18l0,0c0,0,0,0,0,0c-0.01,0.01-0.01,0.01-0.02,0.01
					C242.62,248.3,242.47,248.44,242.33,248.58z"
              />
            </g>
            <path
              fill="#AC7D61"
              d="M131.77,187.98c-0.17,0.2-0.31,0.35-0.42,0.48c-0.33,0.32-0.49,0.45-0.84,0.92
				c-0.26,0.35,0.36-0.33,0.75-0.52c0.09,0.02,0.2,0.04,0.35,0.07c0.43,0.1,0.58,0.24,0.64,0.33c0.02,0.03,0.03,0.05,0.03,0.07
				c0,0.02,0.01,0.03,0.01,0.03s0-0.01,0.01-0.01c0.03-0.05,0.4-0.65,0.32-1.78l0.36-0.57c0.61-0.3,1.04-0.12,1.21-0.01l-0.82-0.6
				l0,0C133.36,186.39,132.58,187.05,131.77,187.98z"
            />
            <path
              fill="#AC7D61"
              d="M130.32,189.74c-0.07,0.44-1.09,1.24-1.09,1.24C130.14,190.65,130.26,190.44,130.32,189.74z"
            />
            <path
              fill="#AC7D61"
              d="M129.78,189.74c0,0-0.55,0.36-0.99,0.33C128.79,190.06,129.38,190.61,129.78,189.74z"
            />
            <path
              fill="#AC7D61"
              d="M134.95,198.96c-1.15,0.21-1.07-0.33-1.36-1.28c-0.29-0.95-0.78-3.17-0.91-3.17
				c-0.12,0,0.86,3.04,1.31,5.96c0.01,0.06,0.02,0.13,0.03,0.19c0,0,0-0.5,1.7-1.35c0.18-0.09,0.38-0.18,0.6-0.28
				c0.09-0.04,0.18-0.08,0.27-0.12c0.16-0.07,0.21-0.11,0.18-0.14C136.7,198.7,135.83,198.8,134.95,198.96z"
            />
            <path
              fill="#AC7D61"
              d="M133.03,206.93c-0.2-0.18,0.71-2.89,0.47-2.3c-0.51,1.25-1.54,3.51-1.72,4.17
				c-0.18,0.68,0.26-1.05,0.88-1.17c1.28,0.42,1.64,2.59,1.64,2.59c0.05-0.06,0.08-0.13,0.11-0.19c0.52-1.04-0.52-2.44-0.93-2.92
				c0.22-0.15,0.46-0.33,0.71-0.56c0.87-0.78,1.28-1.49,0.75-2.29c0,0-0.01,0.02-0.03,0.05c-0.11,0.21-0.62,1.07-1.5,2.14
				C133.26,206.64,133.14,206.79,133.03,206.93z"
            />
            <path
              fill="#715845"
              d="M124.49,220.28c-3.76-1.25-5.64-3.01-6.89-7.01c-1.09-3.48-1.23-5.69-1.25-6.21
				c0.03,1.21,0.25,11.14-0.58,15.49c0,0,0.04-0.03,0.12-0.08c0.6-0.39,3.45-2.08,7.79-2.18
				C123.95,220.28,124.21,220.28,124.49,220.28z"
            />
            <path
              fill="#715845"
              d="M115.2,198.64c-0.69-1.31-0.23-1.62,0.09-2.34c0.33-0.71-0.03-0.89-0.03-0.89
				c-0.68,1.09-1.64,0.75-2.08,1.27c-0.44,0.52,0.79,2.47,1.44,3.23C115.28,200.69,115.9,199.95,115.2,198.64z"
            />
            <path
              fill="#715845"
              d="M114.35,190.29c-0.13-0.22-0.31-0.48-0.53-0.71c-0.08,0.08-1.37-0.92-1.37-0.92s-0.93,0.45-0.95,0.46
				c-0.03,0.01-0.69,0.79-0.69,0.79s-0.43,0.21-0.38,0c-0.52,0.74-0.73,1.76-0.94,2.56c0,0,0.61-1.38,1.47-2.09
				c-0.1,0.04,0.93-0.6,0.93-0.6s0.1,0.15,0,0.18c0.09,0,0.17,0,0.26,0.02c0.12,0.02,0.24,0.07,0.36,0.12c-0.01-0.04,0.46,0,0.46,0
				l0.18,0.09c-0.08,0.07-0.15,0.14-0.23,0.21c0.54,0.54,0.74,1.47,0.04,2.31c-0.97,1.18-1.31,1.69-1.53,2.6
				c-0.21,0.91,0.44,1.74,0.44,1.74s0.14-0.7,0.36-1.54c0.22-0.84,1.24,0.31,2.22-0.74C115.42,193.76,114.74,190.94,114.35,190.29z"
            />
            <path fill="#715845" d="M113.66,202.19c0,0-3.47-3.75-3.75-7.01C109.91,195.18,110.3,200.97,113.66,202.19z" />
            <path
              fill="#141616"
              d="M102.78,210.09c2.41-5.69,4.89-17.29,6.02-20.25c1.13-2.96,2.22-3.09,2.22-3.09
				c0.15,1.17-0.41,2.45-0.59,3.16c-0.12,0.49-0.06,0.72,0.53,0.48c0.09-0.04,0.2-0.09,0.32-0.15c0.25-0.13,0.45-0.21,0.61-0.26
				c0.41-0.13,0.57-0.03,0.61,0.14c0.03,0.12,0,0.27-0.05,0.42c-0.02,0.07-0.05,0.15-0.08,0.21c-0.07,0.16-0.13,0.27-0.13,0.27
				c0.22-0.19,0.45-0.39,0.68-0.6c0.31-0.29,0.62-0.58,0.9-0.84c0.55-0.51,0.98-0.91,1.12-0.91c0.16,0,0.02,0.47-0.11,0.82
				c-0.02,0.06-0.04,0.11-0.06,0.16c-0.05,0.14-0.1,0.24-0.1,0.24c0.48-0.25,0.98-0.56,1.49-0.9l0,6.22c0,0,1.71-0.92,2.71-2.72
				c0.03-0.05,0.05-0.09,0.08-0.14c0.03-0.05,0.05-0.1,0.08-0.15c0.21-0.44,0.38-0.92,0.47-1.45c0.14-0.79,0.4-1.62,0.69-2.4
				c0.57-1.49,1.3-2.82,1.69-3.5c0.07-0.17,0.3-0.34,0.51-0.42c0.01,0,0.01-0.01,0.02-0.01c0,0,0.01,0,0.01,0
				c0.03-0.01,0.07-0.02,0.1-0.03c0.03-0.01,0.05-0.01,0.08-0.01c0.16-0.01,0.25-0.03,0.35-0.05c0.12-0.02,0.24-0.05,0.48-0.07
				c0.03-0.06,0.07-0.13,0.1-0.19c1.94-11.27,0.34-15.53,0.34-15.53c1.75,3.67,1.36,9.51,1,12.47c3.5-10.02-1.45-19.16-1.45-19.16
				c5.46,7.85,2.39,19.87,2.39,19.87c5.71-15.86-5.8-24.73-5.8-24.73c7.16,4.69,8.87,14.07,8.87,14.07
				c-0.69-12.17-11.27-17.06-13.43-17.93c-10.65-1.77-21.61,1.07-21.61,1.07s-1.76,0.14-3.54,1.15l0.22-0.3l0.13-0.18
				c0,0-7.32,2.55-13.05,8.59c0,0,2.94-2.38,6.51-4.17c0.38-0.19,0.76-0.37,1.14-0.54c-3.74,2.32-8.47,5.46-11.64,12.23
				c-1.39,2.97-1.99,5.59-2.11,7.84c0,0.01,0,0.01,0,0.02c0,0.05,0,0.09,0,0.14c-0.07,1.67,0.11,3.14,0.44,4.39
				c0.87,4.01,3.17,9.2,7.66,15.02c0,0-2.04-3.59-3.33-7.8c0-0.01-0.01-0.03-0.01-0.04c0.12,0.16,0.24,0.33,0.36,0.49
				c0.9,1.23,1.63,2.29,1.63,2.29s1.13,1.47,3.83,5.4c2.7,3.92,4.51,7.24,4.51,7.24c2.58,5.41,2.25,9.52,2.3,9.52
				c0.05,0.01,0.09,0.01,0.14,0.02C97.65,216.82,101.35,213.47,102.78,210.09z"
            />
            <path
              fill="#141616"
              d="M123.43,184.25c0,0,4.32-0.06,6.44-1.57c2.12-1.51,2.81-3.79,2.81-3.79l0.97-12.14
				c0,0-3.52-5.41-3.87-6.45c-0.35-1.04-2.77-2.84-2.77-2.84l-6.87-2.77L123.43,184.25z"
            />
            <path
              fill="#4D2C1B"
              d="M93.84,154.2c0,0,10.96-2.84,21.61-1.07c2.16,0.87,12.74,5.76,13.43,17.93c0,0-1.71-9.38-8.87-14.07
				c0,0,11.51,8.87,5.8,24.73c0,0,3.07-12.03-2.39-19.87c0,0,4.95,9.14,1.45,19.16c0.36-2.97,0.76-8.81-1-12.47
				c0,0,1.6,4.26-0.34,15.53c-0.03,0.06-0.07,0.13-0.1,0.19c-0.24,0.02-0.37,0.05-0.48,0.07c0.3-0.02,0.66-0.04,1.04-0.07
				c1.53-0.13,3.57-0.46,4.93-1.04c0.63-0.27,0.65-0.33,0.95-0.53c-0.15,0.28-0.24,0.49-0.24,0.49c0.23-0.23,0.43-0.4,0.62-0.53
				c0.53,0.01,0.73,0.3,0.73,0.3s0.27-0.17,0.68-0.54c0.23,0.11,0.43,0.3,0.62,0.49c0,0,0-0.01,0.01-0.03
				c0.03-0.13,0.15-0.67,0.03-1.12c1.55-1.71,3.74-5.35,2.51-11.56c-0.56-2.82-1.5-5.22-2.72-7.25c3.09,3.8,3.48,7.37,3.52,8.19
				c-0.36-11.69-11.85-15.96-14.32-16.74c-1.87-0.68-3.82-1.17-5.78-1.5c-10.65-1.77-21.61,1.07-21.61,1.07s-1.68,0.14-3.42,1.08
				l-0.22,0.3C92.08,154.34,93.84,154.2,93.84,154.2z"
            />
            <path
              fill="#AC7D61"
              d="M174.9,217.22c-0.52,0.03-0.81-0.23-0.81-0.87c-0.27,0.52-1.41,2.48-1.55,2.72c0,0,0,0,0,0
				c-0.01,0.02-0.01,0.02-0.01,0.02c0.04,0,0.07-0.01,0.1-0.01c1.23-0.13,1.92-0.52,1.92-0.52c2.51,0.94,2.93-0.3,2.99-0.59
				c0.01-0.04,0.01-0.06,0.01-0.06C176.95,218.28,175.42,217.19,174.9,217.22z"
            />
            <path
              fill="#AC7D61"
              d="M171,232.06c0.03,0.41,0.87-0.54,1.66-0.83c-0.28,0.06-0.61,0.16-0.82,0.28
				c-0.58,0.32,0.14-0.46,0.52-0.66c0.38-0.2-0.58,0.38-0.98-0.12c-0.21-0.26-0.4-0.55-0.53-0.77c-0.12-0.19-0.19-0.33-0.19-0.33
				c0.01,0.01,0.01,0.03,0.01,0.04C170.99,230.51,170.97,231.61,171,232.06z"
            />
            <path
              fill="#AC7D61"
              d="M110.3,235.83c12.32-0.65,23.77,20.59,28.15,30.04c0,0,6.7,11.82,12.64,16.11
				c7.62,5.51,7.74-4.2,7.74-4.2s2.29,2.01,2.58,5.74c0.29,3.73,0,6.02,0,6.02s1.43-6.31,1.43-10.04c0-3.73,1.43-1.15,1.72,2.29
				c0.29,3.44,1.43-3.44,1.15-4.3c-0.29-0.86,1.72,0,3.44,1.72c1.72,1.72,7.69,11.72,18.59-1.18c10.15-12.02,16.55-22.4,18.4-21.45
				c-0.49-1.06-1.05-1.73-1.6-2.16c-0.09-0.07-0.17-0.13-0.26-0.19c-2.24-1.47-5.59,0.71-6.89,1.53
				c-23.84,15.04-33.63,19.5-35.31,20.23c-1.26-0.18-4.21-1.4-9.67-7.71c7.89-11.49,14.1-23.67,14.1-23.67
				c-0.12-0.33-0.28-0.67-0.46-1.01c0.26-0.39,0.51-0.75,0.67-0.95c0.34-0.43,1.26-1.22,2.01-1.82c0.26-0.21,0.86-0.63,1.05-0.76
				c0.72-0.52,2.61-1.43,3.18-1.81c0.19-0.13,0.39-0.25,0.59-0.37c0.39-0.25,0.79-0.5,1.23-0.81c0.09-0.07,0.2-0.13,0.31-0.2
				c0.46-0.28,1.02-0.59,1.47-0.86c0.21-0.13,0.4-0.25,0.53-0.36c0.42-0.34,0.94-1.04,1.5-1.84c0.06-0.08,0.11-0.16,0.17-0.24
				c0.64-0.9,2.19-2.45,2.71-3.09c0.01-0.01,0.01-0.01,0.02-0.02c0.5-0.62,0.56-1.01,0.73-2.35c0.14-1.12,0.64-2.46,0.81-2.91
				c0.04-0.1,0.06-0.15,0.06-0.15l-0.78,1.62c-0.29-0.2-0.49-0.4-0.35-0.9s0.43-1.07,0.84-1.39c0,0-0.87,0.69-1.04,1.44
				c-0.17,0.75,0.55,0.84,0.4,1.36c-0.14,0.52-0.29,2.22-0.72,2.48c-0.43,0.26-0.95,1.21-2.74,2.89c-1.79,1.68-1.56,2.34-3.75,3.44
				c-2.2,1.1-6.47,3.38-9.36,5c-0.35,0.2-0.66,0.37-0.93,0.53c-0.84-1.07-1.84-2.12-2.8-3.05c0.86-0.72,2.01-1.51,3.36-2.01
				c3.03-1.13,4.06-2.57,2.85-5.04c-1.21-2.47,0.17-4.81,1-8.75c0.82-3.94,0.82-6.11,2.95-8.45c0,0,1.39-1.95,1.66-2.23
				c0.28-0.28,1.32,0.45,2.58-1.23c0.08-0.11-1.58,1.51-2.43,0.76c0,0-2.6,2.27-3.77,3.44c-0.16,0.16-0.3,0.3-0.43,0.43
				c-0.83,0.8-1.15,1.02-1.56,1.91c0,0,0.1-0.9,0.34-1.34c0.02-0.04,0.04-0.08,0.07-0.11c-0.04,0.05-0.1,0.11-0.18,0.2
				c-0.36,0.42-1.06,1.3-1.8,2.55c-0.3,0.52-0.61,0.97-0.93,1.4c-0.77,1.03-1.61,1.82-2.63,2.59c-1.43,1.08-1.73,1.21-1.73,3.73
				s-0.6,6.97-1.14,8.4c-0.2,0.53-0.46,1.02-0.72,1.44c0.68,0.58,1.6,1.44,2.58,2.6c0,0-8.88,15.33-11.28,27.7
				c0,0-9.12-11.32-12.85-15.08c-0.04,0.04-0.08,0.07-0.12,0.11c-11.95-12.08-20.46-16.83-20.46-16.83
				c-0.19,0.03-0.37,0.06-0.56,0.09c-3.55,0.6-6.75,0.97-9.63,1.16C108.09,235.93,109.17,235.89,110.3,235.83z"
            />
            <path
              fill="#AC7D61"
              d="M226.84,239.87c-1.62,0.78-2.48-0.67-2.48-0.67s0.32,1.6-0.83,2.32c-1.15,0.72-2.29,2.1-2.85,3.38
				s-0.87,1.32-2.48,1.33c-0.35,0-0.62,0-0.82-0.01c0.13,0.02,0.29,0.04,0.49,0.07c1.24,0.21-0.55,0.43-1.76,0.95
				c0.63-0.21,4.97-0.95,4.97-0.95s0.01-0.04,0.02-0.11c0.01-0.07,0.04-0.16,0.09-0.28c0.07-0.2,0.2-0.45,0.4-0.72
				c0.09-0.11,0.18-0.22,0.3-0.33c0.17-0.16,0.4-0.32,0.66-0.49c0.86-0.55,2.11-1.15,3.12-1.89c0.32-0.23,0.6-0.43,0.84-0.62
				c0.76-0.59,1.2-1.02,1.69-1.78c0.64-0.99,1-1.79,0.32-2.48c0,0-0.01-0.01-0.01-0.01C228.39,238.04,228.45,239.09,226.84,239.87z"
            />
          </g>
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text
          transform="matrix(1 0 0 1 193.9546 116.5576)"
          fontFamily={font.condensed}
          fontSize="68.3887px"
          letterSpacing="-1"
        >
          promised
        </text>
        <text transform="matrix(1 0 0 1 287.5 175.3672)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="13px">
            We aim to reduce{" "}
          </tspan>
          <tspan x="102.39" y="0" fontFamily={font.condensed} fontSize="15.8124px">
            CO2 EMISSIONS
          </tspan>
          <tspan x="0" y="19" fontFamily={font.serif} fontSize="13px">
            per vehicle produced.
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
