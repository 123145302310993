import React, { FC } from "react";
import styled from "styled-components/macro";
import { bp, gridColumnStart, gridColumnWidth, font } from "#shared/theme";
import { Grid } from "../Grid/Grid";
import { Wrapper } from "../Wrapper/Wrapper";

const Image = styled.img`
  width: 100%;
  margin-bottom: 50px;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    margin-bottom: 80px;
    ${gridColumnStart(8)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.t} {
    margin-bottom: 100px;
  }
  @media ${bp.p} {
    ${gridColumnStart(9)}
    ${gridColumnWidth(8)}
  }
  @media ${bp.t} {
    ${gridColumnStart(10)}
    ${gridColumnWidth(6)}
  }
`;

const Qoute = styled.div`
  font-size: 30px;
  line-height: 1.73;
  font-family: ${font.condensed};
  margin-bottom: 20px;
  text-align: center;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    font-size: 38px;
    line-height: 1.61;
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
  }
  @media ${bp.p} {
    font-size: 42px;
    line-height: 1.55;
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.d} {
    font-size: 51px;
    line-height: 1.53;
  }
  @media ${bp.d} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
  }
`;

const Author = styled.div`
  font-size: 18px;
  line-height: 1.61;
  font-family: ${font.condensed};
  text-align: center;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    font-size: 22px;
    line-height: 1.45;
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
  }
`;

interface ImageQuote {
  src: string;
  quote: string;
  author: string;
  copytext: string;
}

export const ImageQuote: FC<ImageQuote> = ({ src, quote, author, copytext }) => (
  <Wrapper>
    <Grid>
      {src && <Image src={src} />}
      <Qoute>{quote}</Qoute>
      <Author>{author}</Author>
      <Author>{copytext}</Author>
    </Grid>
  </Wrapper>
);
