import { rootElement, scrollToElement } from "#helpers";
import { useTranslation } from "#hooks";
import { PagesList, RouteProps } from "#interfaces";
import { useRouting } from "#providers";
import { TransitionContainer } from "#ui";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Head } from "../Head/Head";
// import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollTrigger } from "#shared/ScrollTrigger";

const transition = {
  duration: 0.4,
  ease: "easeInOut",
};

const variants = {
  enter: {
    opacity: 1,
    transition: {
      ...transition,
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
  exit: {
    opacity: 0,
    transition,
  },
};

const Page = styled(motion.div)`
  flex: 0 0 100%;
  margin-right: -100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

interface RoutesProps {
  list: RouteProps[];
  level: number;
}

export const Routes: React.FC<RoutesProps> = ({ list, level = 0 }) => {
  const location = useLocation();
  const { keys } = useRouting();
  const { t } = useTranslation("pages");
  const renderRoutes = ({ exact = true, redirect, pageName, component, routes }: RouteProps, key: number) => {
    const pageList = t("list") as PagesList;
    const { path } = pageList[pageName];
    const Component = component || null;
    let redirectChildren;
    if (redirect) {
      const { path: to } = pageList[redirect];
      redirectChildren = <Redirect to={to} />;
    }
    const animationComplete = (definition: string) => {
      if (definition === "enter") {
        scrollToElement(location.hash);
      }
    };
    return (
      <Route exact={exact} path={path} key={key}>
        <Page onAnimationComplete={animationComplete} initial="exit" animate="enter" exit="exit" variants={variants}>
          <TransitionContainer>
            {redirectChildren ||
              (Component &&
                (routes ? (
                  <Component>{routes && <Routes list={routes} level={level + 1} />}</Component>
                ) : (
                  <>
                    <Component />
                    <Head />
                  </>
                )))}
          </TransitionContainer>
        </Page>
      </Route>
    );
  };
  const handleExitComplete = useCallback(() => {
    const st = ScrollTrigger.getById("masterTimeline");
    if (st) {
      //   st.enable();
      //   console.log(st.scroll(0));
      //   console.log(st.scroll(), st);
      //   st.enable();
      //   st.disable();
    }
    rootElement.scrollTo(0, 0);
    console.log("refreshing");
    ScrollTrigger.refresh(true);
    ScrollTrigger.update();
  }, []);
  //   useEffect(() => {
  //     const st = ScrollTrigger.getById("masterTimeline");
  //     if (st) {
  //       console.log("enabling");
  //       st.enable();
  //       //   st.disable();
  //     }
  //   }, []);
  return (
    <AnimatePresence onExitComplete={handleExitComplete}>
      <Switch location={location} key={keys[level]}>
        {list.map(renderRoutes)}
      </Switch>
    </AnimatePresence>
  );
};
