import React from "react";
import { SvgContainer } from "#ui";
import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { LeftKPI } from "./LeftKPI";
import { RightKPI } from "./RightKPI";
import { ChartProps } from "#interfaces";

const CHART_COUNT = 2;

export const EmissionsChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <LeftKPI labels={labels?.value1} {...chartProps} />
      <RightKPI labels={labels?.value2} {...chartProps} />
      <line fill="none" stroke="#000000" x1="142" y1="328.5" x2="157" y2="328.5" />
      <line fill="none" stroke="#000000" x1="163" y1="328.5" x2="178" y2="328.5" />
    </SvgContainer>
  );
};
