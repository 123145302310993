import { isFullscreenSupported, isIos } from "#constants";
import { FullScreen, UseFullscreen, useWindowScrollLock } from "#hooks";
import { Color, expander } from "#shared/theme";
import { ModalCloseButton } from "#ui";
import React from "react";
import { FullScreenHandle } from "react-full-screen";
import styled from "styled-components/macro";

const Container = styled.div`
  ${expander}
  position: fixed;
  z-index: 123456789;
  background-color: ${Color.Black};
`;

const FullscreenComponent: React.FC<UseFullscreen> = ({ exit, children }) => {
  useWindowScrollLock();
  return (
    <Container>
      {children}
      {<ModalCloseButton onClick={exit} />}
    </Container>
  );
};

export const Fullscreen: React.FC<UseFullscreen> = ({ children, ...handle }) => {
  return !isFullscreenSupported || isIos ? (
    handle.active ? (
      <FullscreenComponent {...handle}>{children}</FullscreenComponent>
    ) : null
  ) : (
    <FullScreen handle={handle as FullScreenHandle}>
      {children}
      {handle.active && <ModalCloseButton onClick={handle.exit} />}
    </FullScreen>
  );
};
