import React, { createContext, useContext, useState } from "react";
import { disablePageScroll, enablePageScroll, getScrollState } from "scroll-lock";
import { rootElement } from "#helpers";

const lockPosition = {
  x: 0,
  y: 0,
};

const scrollListener = (ev: Event): void => {
  ev.preventDefault();
  ev.stopPropagation();

  rootElement.scrollTo(lockPosition.x, lockPosition.y);
};

const lockCurrentPosition = (): void => {
  lockPosition.x = rootElement.scrollLeft;
  lockPosition.y = rootElement.scrollTop;
};

interface ScrollLockContextProps {
  isScrollLocked: boolean;
  disableScrolling: () => void;
  enableScrolling: () => void;
}

const DEFAULT_VALUE = {
  isScrollLocked: false,
  disableScrolling: (): void => undefined,
  enableScrolling: (): void => undefined,
};

const ScrollLockContext = createContext<ScrollLockContextProps>(DEFAULT_VALUE);
export const useScrollLock = (): ScrollLockContextProps => useContext<ScrollLockContextProps>(ScrollLockContext);

export interface ScrollLockProviderProps {
  isDefaultLocked?: boolean;
}

export const ScrollLockProvider: React.FC<ScrollLockProviderProps> = ({ children, isDefaultLocked = false }) => {
  const [isLocked, setLocked] = useState(isDefaultLocked);
  const disableScrolling = (): void => {
    lockCurrentPosition();
    disablePageScroll();
    setLocked(true);
    rootElement.addEventListener("scroll", scrollListener);
  };
  const enableScrolling = (): void => {
    enablePageScroll();
    setLocked(false);
    rootElement.removeEventListener("scroll", scrollListener);
  };
  const value = {
    isScrollLocked: getScrollState() && isLocked,
    disableScrolling,
    enableScrolling,
  };
  return <ScrollLockContext.Provider value={value}>{children}</ScrollLockContext.Provider>;
};
