import { EventArgs } from "react-ga";

enum AnalyticsCategories {
  Download = "Downloads",
  IntroAction = "Events im Intro",
  IntroPdfPreview = "PDF-Viewer-Events im Intro",
  TourEndAction = "Guided Tour Endframe Events",
  TargetAchievedView = "FlipCards Views",
  Audio = "Audio CEO und CFO",
  TourAction = "Guided Tour Events",
  VCPInfo = "VCP Info",
}

export enum AnalyticsEventTypes {
  FullReportPDFDownload = "FullReportPDFDownload",
  GoToVCPFromIntro = "GoToVCPFromIntro",
  CloseVCPModalFromIntro = "CloseVCPModalFromIntro",
  IntroModalPdfPreviewGoals2020 = "IntroModalPdfPreviewGoals2020",
  IntroModalPdfPreviewStrategicGoals = "IntroModalPdfPreviewStrategicGoals",
  IntroModalPdfPreviewHowTheBMWGroupCreatesValue = "IntroModalPdfPreviewHowTheBMWGroupCreatesValue",
  TourEndReplay = "TourEndReplay",
  TourEndRestart = "TourEndRestart",
  TourEndGoToVCP = "TourEndGoToVCP",
  IntroTourStartAutoplay = "IntroTourStartAutoplay",
  IntroTourStartGo = "IntroTourStartGo",
  TargetAchievedTopView = "TargetAchievedTopView",
  TargetAchievedBottomView = "TargetAchievedBottomView",
  AudioCEOPlay = "AudioCEOPlay",
  AudioCEOPause = "AudioCEOPause",
  AudioCEOFinish = "AudioCEOFinish",
  AudioCFOPlay = "AudioCFOPlay",
  AudioCFOPause = "AudioCFOPause",
  AudioCFOFinish = "AudioCFOFinish",
  TourNextSlide = "TourNextSlide",
  TourPrevSlide = "TourPrevSlide",
  TourInfo = "TourInfo",
  FinancialStatementsIncomeDownloadPDF = "FinancialStatementsIncomeDownloadPDF",
  FinancialStatementsIncomeDownloadXLS = "FinancialStatementsIncomeDownloadXLS",
  FinancialStatementsConsolidationDownloadPDF = "FinancialStatementsConsolidationDownloadPDF",
  FinancialStatementsConsolidationDownloadXLS = "FinancialStatementsConsolidationDownloadXLS",
  FinancialStatementsBalanceDownloadPDF = "FinancialStatementsBalanceDownloadPDF",
  FinancialStatementsBalanceDownloadXLS = "FinancialStatementsBalanceDownloadXLS",
  FinancialStatementsCashFlowDownloadPDF = "FinancialStatementsCashFlowDownloadPDF",
  FinancialStatementsCashFlowDownloadXLS = "FinancialStatementsCashFlowDownloadXLS",
  FinancialStatementsEquityDevelopmentDownloadPDF = "FinancialStatementsEquityDevelopmentDownloadPDF",
  FinancialStatementsEquityDevelopmentDownloadXLS = "FinancialStatementsEquityDevelopmentDownloadXLS",
  VCPInfoOpenInfo = "VCPInfoOpenInfo",
}

export const AnalyticsEvents: {
  [key in AnalyticsEventTypes]: Omit<EventArgs, "category"> & {
    category: AnalyticsCategories;
  };
} = {
  [AnalyticsEventTypes.FullReportPDFDownload]: {
    category: AnalyticsCategories.Download,
    action: "Download PDF-Bericht",
  },
  [AnalyticsEventTypes.GoToVCPFromIntro]: {
    category: AnalyticsCategories.IntroAction,
    action: "Wechsel zu der VCP aus dem Overlay im Intro",
  },
  [AnalyticsEventTypes.CloseVCPModalFromIntro]: {
    category: AnalyticsCategories.IntroAction,
    action: "VCP-Overlay schliessen im Intro",
  },
  [AnalyticsEventTypes.IntroModalPdfPreviewGoals2020]: {
    category: AnalyticsCategories.IntroPdfPreview,
    action: "PDF-Viewer im Intro: Ziele 2020",
  },
  [AnalyticsEventTypes.IntroModalPdfPreviewStrategicGoals]: {
    category: AnalyticsCategories.IntroPdfPreview,
    action: "PDF-Viewer im Intro: Strategische Ziele",
  },
  [AnalyticsEventTypes.IntroModalPdfPreviewHowTheBMWGroupCreatesValue]: {
    category: AnalyticsCategories.IntroPdfPreview,
    action: "PDF-Viewer im Intro: Wie die BMW Group Werte schafft",
  },
  [AnalyticsEventTypes.TourEndReplay]: {
    category: AnalyticsCategories.TourEndAction,
    action: "GT Endframe Replay",
  },
  [AnalyticsEventTypes.TourEndRestart]: {
    category: AnalyticsCategories.TourEndAction,
    action: "GT Endframe Restart",
  },
  [AnalyticsEventTypes.TourEndGoToVCP]: {
    category: AnalyticsCategories.TourEndAction,
    action: "GT Endframe Replay: Wechsel zu VCP",
  },
  [AnalyticsEventTypes.IntroTourStartAutoplay]: {
    category: AnalyticsCategories.IntroAction,
    action: "Intro: Autoplay GT",
  },
  [AnalyticsEventTypes.IntroTourStartGo]: {
    category: AnalyticsCategories.IntroAction,
    action: "Intro: Start GT",
  },
  [AnalyticsEventTypes.TargetAchievedTopView]: {
    category: AnalyticsCategories.TargetAchievedView,
    action: "FlipCard {{index}} Gesagt",
  },
  [AnalyticsEventTypes.TargetAchievedBottomView]: {
    category: AnalyticsCategories.TargetAchievedView,
    action: "FlipCard {{index}} Getan",
  },
  [AnalyticsEventTypes.AudioCEOPlay]: {
    category: AnalyticsCategories.Audio,
    action: "CEO Audio Play bei {{progress}}% des Verlaufs pausiert.",
  },
  [AnalyticsEventTypes.AudioCEOPause]: {
    category: AnalyticsCategories.Audio,
    action: "CEO Audio wurde bei {{progress}}% des Verlaufs pausiert.",
  },
  [AnalyticsEventTypes.AudioCEOFinish]: {
    category: AnalyticsCategories.Audio,
    action: "CEO Audio wurde zu Ende angehört.",
  },
  [AnalyticsEventTypes.AudioCFOPlay]: {
    category: AnalyticsCategories.Audio,
    action: "CFO Audio Play bei {{progress}}% des Verlaufs pausiert.",
  },
  [AnalyticsEventTypes.AudioCFOPause]: {
    category: AnalyticsCategories.Audio,
    action: "CFO Audio wurde bei {{progress}}% des Verlaufs pausiert.",
  },
  [AnalyticsEventTypes.AudioCFOFinish]: {
    category: AnalyticsCategories.Audio,
    action: "CFO Audio wurde zu Ende angehört.",
  },
  [AnalyticsEventTypes.TourNextSlide]: {
    category: AnalyticsCategories.TourAction,
    action: "Klick auf Weiter in {{index}}",
  },
  [AnalyticsEventTypes.TourPrevSlide]: {
    category: AnalyticsCategories.TourAction,
    action: "Klick auf Zurück in {{index}}",
  },
  [AnalyticsEventTypes.TourInfo]: {
    category: AnalyticsCategories.TourAction,
    action: "Klick auf Info in {{index}}",
  },
  [AnalyticsEventTypes.FinancialStatementsIncomeDownloadPDF]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: GuV PDF",
  },
  [AnalyticsEventTypes.FinancialStatementsIncomeDownloadXLS]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: GuV XLS",
  },
  [AnalyticsEventTypes.FinancialStatementsConsolidationDownloadPDF]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Gesamtergebnis PDF",
  },
  [AnalyticsEventTypes.FinancialStatementsConsolidationDownloadXLS]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Gesamtergebnis XLS",
  },
  [AnalyticsEventTypes.FinancialStatementsBalanceDownloadPDF]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Bilanz PDF",
  },
  [AnalyticsEventTypes.FinancialStatementsBalanceDownloadXLS]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Bilanz XLS",
  },
  [AnalyticsEventTypes.FinancialStatementsCashFlowDownloadPDF]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Kapitalflussrechnung PDF",
  },
  [AnalyticsEventTypes.FinancialStatementsCashFlowDownloadXLS]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Kapitalflussrechnung XLS",
  },
  [AnalyticsEventTypes.FinancialStatementsEquityDevelopmentDownloadPDF]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Konzerneigenkapital PDF",
  },
  [AnalyticsEventTypes.FinancialStatementsEquityDevelopmentDownloadXLS]: {
    category: AnalyticsCategories.Download,
    action: "Download im Konzernabschluss: Konzerneigenkapital XLS",
  },
  [AnalyticsEventTypes.VCPInfoOpenInfo]: {
    category: AnalyticsCategories.VCPInfo,
    action: "Klick auf Info in VCP",
  },
};
