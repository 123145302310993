import React, { Provider, useReducer } from "react";

export enum OverlayStatus {
  Open = "Open",
  Closing = "Closing",
  Close = "Close",
}
export interface OverlayContextProps {
  isOpen: boolean;
  isClosing: boolean;
  isClose: boolean;
  open: () => void;
  close: () => void;
  reset: () => void;
}
interface OverlayState {
  status: OverlayStatus;
}
type OverlayAction = { type: OverlayStatus };

type OverlayReducer = (state: OverlayState, action: OverlayAction) => OverlayState;

const OverlayReducer: OverlayReducer = (_, action) => {
  return {
    status: action.type,
  };
};

export const OVERLAY_DEFAULT_VALUE = {
  isOpen: false,
  isClosing: false,
  isClose: true,
  open: (): void => undefined,
  close: (): void => undefined,
  reset: (): void => undefined,
};

export interface OverlayProviderProps {
  defaultStatus?: OverlayStatus;
}

export interface OverlayProviderOwnProps extends OverlayProviderProps {
  ContextProvider: Provider<OverlayContextProps>;
}

export const OverlayProvider: React.FC<OverlayProviderOwnProps> = ({
  ContextProvider,
  children,
  defaultStatus = OverlayStatus.Close,
}) => {
  const [{ status }, dispatch] = useReducer(OverlayReducer, { status: defaultStatus });
  const value = {
    isOpen: status === OverlayStatus.Open,
    isClose: status === OverlayStatus.Close,
    isClosing: status === OverlayStatus.Closing,
    open: (): void => dispatch({ type: OverlayStatus.Open }),
    close: (): void => dispatch({ type: OverlayStatus.Closing }),
    reset: (): void => dispatch({ type: OverlayStatus.Close }),
  };
  return <ContextProvider value={value}>{children}</ContextProvider>;
};
