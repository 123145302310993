import ReactGA, { EventArgs } from "react-ga";
import { AnalyticsEventTypes, AnalyticsEvents } from "#interfaces";

let isGaInitialized = false;

const GA_LOCAL_STORAGE_KEY = "ga:clientId";
const GA_ENABLED_KEY = "ga:enabled";

export const getIsAnalyticsEnabledActionApplied = (): boolean => {
  const localStorageFlag = localStorage.getItem(GA_ENABLED_KEY);

  return localStorageFlag === "true" || localStorageFlag === "false";
};

export const getIsAnalyticsEnabled = (): boolean => false;

export const setupAnalytics = (): void => {
  if (isGaInitialized || !getIsAnalyticsEnabled()) {
    return;
  }

  ReactGA.initialize(process.env.REACT_APP_GA_CODE as string, {
    gaOptions: {
      storage: "none",
      clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY) as never,
      storeGac: false,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReactGA.ga((tracker: any) => {
    localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get("clientId"));
  });

  isGaInitialized = true;
};

export const disableAnalytics = (): void => {
  localStorage.setItem(GA_ENABLED_KEY, "false");
  localStorage.removeItem(GA_LOCAL_STORAGE_KEY);

  isGaInitialized = false;
};

export const enableAnalytics = (): void => {
  localStorage.setItem(GA_ENABLED_KEY, "true");

  setupAnalytics();
};

interface ActionArgs {
  [key: string]: string | number;
}

const processEventActionArgs = (action: string, actionArgs: ActionArgs): string => {
  return Object.keys(actionArgs).reduce((action, argKey) => {
    return action.replace(`{{${argKey}}}`, actionArgs[argKey].toString());
  }, action);
};

export const triggerAnalyticsEvent = (type: AnalyticsEventTypes, eventActionArgs?: ActionArgs): void => {
  if (!getIsAnalyticsEnabled()) {
    return;
  }

  const event: EventArgs = {
    ...AnalyticsEvents[type],
    action: processEventActionArgs(AnalyticsEvents[type].action, eventActionArgs || {}),
  };

  ReactGA.event(event);
};

export const createAnalyticsEventHandler = (type: AnalyticsEventTypes) => (): void => triggerAnalyticsEvent(type);

export const wrapCallbackWithAnalyticsEventHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => any,
  type: AnalyticsEventTypes,
  eventActionArgs?: ActionArgs
) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
any => {
  callback(...args);

  triggerAnalyticsEvent(type, eventActionArgs);
};

export const triggerAnalyticsPageView = (path: string): void => {
  if (!getIsAnalyticsEnabled()) {
    return;
  }

  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};
