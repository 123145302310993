import styled from "styled-components/macro";
import { bp } from "#shared/theme";

export const SvgContainer = styled.svg`
  display: block;
  height: 100%;
  width: 100%;
  @media ${bp.m} {
    max-width: 1344px;
    max-height: 816px;
  }
`;
