export default {
  mountWidth: 80,
  mountLength: 150,
  roadWidth: 5,
  travelLength: 400,
  lightRadius: 0.1,
  lightLength: 140,
  curveAmplitude: 30,
  curveFrequency: 0.03,
  // Performance
  maxPixelRatio: 2,
  mountainDetail: 200, // Less is better, don't go over 200
  lightLengthDetail: 10,
  lineTubeDetail: 80,
  lightCount: 10,
  roadDetail: 100,

  // This is the biggest win and should be turned off
  // ONLY for really low end machines
  // Although it doesn't look much different. Just no bloom
  disablePostProcessing: false,
};
