import React from "react";
import { TourComponents, ComparisonItemsProps } from "#interfaces";
import { ReactComponent as DeliveriesSvg } from "./01_BMW_speedometer-5YC-delivery-automobiles.svg";
import { ReactComponent as EbitSvg } from "./02_BMW_speedometer-5YC-ebit.svg";
import { ReactComponent as MotoSvg } from "./03_BMW_speedometer-5YC-delivery-motorcycles.svg";
import { ReactComponent as ContractsSvg } from "./04_BMW_speedometer-5YC-contracts.svg";
import { ReactComponent as KeyfiguresSvg } from "./05_BMW_speedometer-5YC-profit.svg";
import { ReactComponent as SharesSvg } from "./06_BMW_speedometer-5YC-shares.svg";
import { ReactComponent as ResearchSvg } from "./07_BMW_speedometer-5YC-fe.svg";
import { ReactComponent as MarketsSvg } from "./08_BMW_speedometer-5YC-markets.svg";
import { ReactComponent as ElectricVehiclesSvg } from "./09_BMW_speedometer-5YC-electric.svg";
import { ReactComponent as EmissionsSvg } from "./10_BMW_speedometer-5YC-co2.svg";
import { ReactComponent as WomenSvg } from "./11_BMW_speedometer-5YC-women.svg";

export const comparisonItemsEn: ComparisonItemsProps = {
  [TourComponents.Deliveries]: <DeliveriesSvg />,
  [TourComponents.EbitMargin]: <EbitSvg />,
  [TourComponents.Moto]: <MotoSvg />,
  [TourComponents.Contracts]: <ContractsSvg />,
  [TourComponents.Keyfigures]: <KeyfiguresSvg />,
  [TourComponents.Shares]: <SharesSvg />,
  [TourComponents.Research]: <ResearchSvg />,
  [TourComponents.Markets]: <MarketsSvg />,
  [TourComponents.ElectricVehicles]: <ElectricVehiclesSvg />,
  [TourComponents.Emissions]: <EmissionsSvg />,
  [TourComponents.Women]: <WomenSvg />,
};
