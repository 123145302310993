import { ChartProps } from "#interfaces";
import { RefObject, useEffect, useMemo, useState, useRef } from "react";
import { gsap, Linear } from "gsap";
import { chartReverseDuration, chartSideItemTimeDelay } from "#constants";
import { Power3 } from "gsap/gsap-core";

interface Values extends ChartProps {
  maxValue: number;
  maxMargin: number;
  currentValue: number;
  minMargin: number;
  minDegree: number;
  maxDegree: number;
}

type useMarginAnimation = (
  values: Values,
  refs: {
    currentPathRef: RefObject<SVGPathElement>;
    lineRef: RefObject<SVGPathElement>;
    minLineRef: RefObject<SVGPathElement>;
    maxLineRef: RefObject<SVGPathElement>;
    minTextRef: RefObject<SVGGElement>;
    maxTextRef: RefObject<SVGGElement>;
  },
  duration: number
) => void;

const getCurrentLength = (pathLength: number, percent: number): number => {
  return (pathLength / 100) * percent;
};

export const useMarginAnimation: useMarginAnimation = (
  { playIn, playOut, onPlayOutEnd, currentValue, maxValue, maxMargin, minMargin, minDegree, maxDegree },
  { currentPathRef, lineRef, minLineRef, maxLineRef, minTextRef, maxTextRef },
  duration
) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pathLength, setPathLength] = useState(0);
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(() => gsap.timeline({ defaults: { ease: Linear.easeInOut, force3D: true }, paused: true }), []);
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );
  const maxMarginPercent = Number(((100 / maxValue) * maxMargin).toFixed(1));
  const minMarginPercent = Number(((100 / maxValue) * minMargin).toFixed(1));
  const currentPercent = Number(((100 / maxValue) * currentValue).toFixed(1));

  useEffect(() => {
    setPathLength(Math.round(currentPathRef.current?.getTotalLength() || 0));
    setLoaded(true);
  }, [currentPathRef]);

  useEffect(() => {
    const percentageDiff = Math.abs(maxMarginPercent - minMarginPercent);
    const prevLineDuration = duration * (1 - percentageDiff / maxMarginPercent);
    const currentLineDuration = duration * (percentageDiff / maxMarginPercent);
    const prevPathLength = getCurrentLength(pathLength, minMarginPercent);
    const currentPathLength = getCurrentLength(pathLength, maxMarginPercent);
    const minMarginLineRotation = minDegree + (maxDegree * minMarginPercent) / 100;
    const maxMarginLineRotation = minDegree + (maxDegree * maxMarginPercent) / 100;
    const currentLineRotation = minDegree + (maxDegree * currentPercent) / 100;
    if (!isLoaded) return;
    tl.set(currentPathRef.current, { strokeDashoffset: -prevPathLength })
      .fromTo(
        minLineRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, opacity: 1, delay: chartSideItemTimeDelay },
        prevLineDuration
      )
      .fromTo(
        maxLineRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, rotate: maxMarginLineRotation, opacity: 1, delay: chartSideItemTimeDelay },
        prevLineDuration
      )
      .fromTo(
        lineRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, rotate: currentLineRotation, opacity: 1, delay: chartSideItemTimeDelay },
        prevLineDuration + currentLineDuration
      )
      .fromTo(
        minTextRef.current,
        { rotate: minMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, opacity: 1, delay: chartSideItemTimeDelay },
        prevLineDuration + currentLineDuration
      )
      .fromTo(
        maxTextRef.current,
        { rotate: maxMarginLineRotation, transformOrigin: "right center", opacity: 0 },
        { duration: currentLineDuration, opacity: 1, delay: chartSideItemTimeDelay },
        prevLineDuration + currentLineDuration
      )
      .fromTo(
        currentPathRef.current,
        { strokeDasharray: `.001 ${pathLength}` },
        {
          duration: currentLineDuration,
          strokeDashoffset: -prevPathLength,
          strokeDasharray: `${currentPathLength - prevPathLength} ${pathLength}`,
          delay: chartSideItemTimeDelay,
        },
        prevLineDuration
      );

    tlReverse.to(
      [
        currentPathRef.current,
        maxTextRef.current,
        minTextRef.current,
        lineRef.current,
        maxLineRef.current,
        minLineRef.current,
      ],
      { opacity: 0 },
      0
    );
  }, [isLoaded]); // eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
    }, [playIn, playOut]); // eslint-disable-line
};
