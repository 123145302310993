import React, { useState } from "react";
import styled from "styled-components/macro";
import { gridColumnWidth } from "#shared/theme";
import { useKeyPress } from "#hooks";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";

const Container = styled.div`
  z-index: 9;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  ${gridColumnWidth(1)}
  background-color: red;
  opacity: 0.5;
  border-right: 1px solid #a90404;
  border-left: 1px solid #a90404;
`;

export const GridVisual: React.FC = () => {
  const [isActive, showGrid] = useState(false);

  const onPress = (): void => {
    showGrid(!isActive);
  };

  useKeyPress("g", { onPressUp: onPress });
  return isActive ? (
    <Container>
      <Wrapper>
        <Grid fullHeight>
          {[...Array(24)].map((value, index) => (
            <Col key={index} />
          ))}
        </Grid>
      </Wrapper>
    </Container>
  ) : null;
};
