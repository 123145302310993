import React, { useRef } from "react";
import { useNumberFormat } from "#hooks";
import { useSlideshow } from "#providers";
import { SvgLines } from "./SvgLines";
import { VCPContent, VCPLeftContent, VCPValue, VCPLink, RightContent, VCPTurbo, VCPValueLabel, VCPValues } from "#ui";
import { VCPItemProps } from "#interfaces";

export const Item5: React.FC<VCPItemProps> = ({ slideIndex, labels }) => {
  const { index, set } = useSlideshow();
  const turboSvgRef = useRef<SVGSVGElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const valuesInputRef = useRef<HTMLDivElement>(null);
  const valuesOutputRef = useRef<HTMLDivElement>(null);
  const inputDefaultRef = useRef<SVGLineElement>(null);
  const inputFillRef1 = useRef<SVGLineElement>(null);
  const inputFillRef2 = useRef<SVGLineElement>(null);
  const outputDefaultRef = useRef<SVGLineElement>(null);
  const outputFillRef1 = useRef<SVGLineElement>(null);
  const outputFillRef2 = useRef<SVGLineElement>(null);
  const isActive = index === slideIndex;
  const handleClick = () => {
    set(slideIndex === index ? -1 : slideIndex);
  };

  return (
    <SvgLines
      {...{
        turboSvgRef,
        contentRef,
        inputDefaultRef,
        inputFillRef1,
        inputFillRef2,
        outputDefaultRef,
        outputFillRef1,
        outputFillRef2,
        valuesInputRef,
        valuesOutputRef,
        isActive,
      }}
    >
      {({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
      }) => (
        <>
          <VCPContent ref={contentRef}>
            <VCPLeftContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title1}
              </VCPLink>
              <VCPValues ref={valuesInputRef}>
                <VCPValue ref={valueInputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue1))}
                </VCPValue>
                <VCPValue ref={valueInputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue2))}
                </VCPValue>
              </VCPValues>
            </VCPLeftContent>
            <RightContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title2}
              </VCPLink>
              <VCPValues ref={valuesOutputRef}>
                <VCPValue ref={valueOutputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue1))}
                </VCPValue>
                <VCPValue ref={valueOutputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue2))}
                </VCPValue>
              </VCPValues>
            </RightContent>
          </VCPContent>
          <VCPTurbo ref={turboSvgRef} viewBox="0 0 40 272" xmlns="http://www.w3.org/2000/svg" opacity="0">
            <g>
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_24_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="160"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="160" width="15" height="10" id="_x30_5-input-black_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_24_)">
                  <polygon
                    id="_x30_1-input-black_15_"
                    fill="#FFFFFF"
                    points="0,160 15,160 15,170 0,170 5.083,165    "
                  />
                </g>
              </mask>
              <line ref={turboInputRef} fill="none" stroke="#000000" strokeWidth="0" x1="0" y1="165" x2="15" y2="165" />
              <line
                ref={inputDefaultRef}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_5-input-black_2_)"
                fill="none"
                stroke="#000000"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="165"
                x2="15"
                y2="165"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_25_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="160"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="160" width="15" height="10" id="_x30_5-input-green_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_25_)">
                  <polygon
                    id="_x30_1-input-black_14_"
                    fill="#FFFFFF"
                    points="0,160 15,160 15,170 0,170 5.083,165    "
                  />
                </g>
              </mask>

              <line
                ref={inputFillRef1}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_5-input-green_2_)"
                fill="none"
                stroke="#346C64"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="165"
                x2="15"
                y2="165"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_26_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="164"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="164" width="15" height="2" id="_x30_5-input-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_26_)">
                  <polygon
                    id="_x30_1-input-black_13_"
                    fill="#FFFFFF"
                    points="0,160 15,160 15,170 0,170 5.083,165    "
                  />
                </g>
              </mask>

              <line
                ref={inputFillRef2}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_5-input-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="0"
                y1="165"
                x2="15"
                y2="165"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_27_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="102"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="102" width="15" height="10" id="_x30_5-output-white_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_27_)">
                  <polygon
                    id="_x30_1-output-white_15_"
                    fill="#FFFFFF"
                    points="25,102 35,102 40,107 35,112 25,112    "
                  />
                </g>
              </mask>
              <line
                ref={turboOutputRef}
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="0"
                x1="25"
                y1="107"
                x2="40"
                y2="107"
              />
              <line
                ref={outputDefaultRef}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_5-output-white_2_)"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="107"
                x2="40"
                y2="107"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_28_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="102"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="102" width="15" height="10" id="_x30_5-output-yellow_2_">
                <g filter="url(#Adobe_OpacityMaskFilter_28_)">
                  <polygon
                    id="_x30_1-output-white_14_"
                    fill="#FFFFFF"
                    points="25,102 35,102 40,107 35,112 25,112    "
                  />
                </g>
              </mask>

              <line
                ref={outputFillRef1}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_5-output-yellow_2_)"
                fill="none"
                stroke="#CEFB5F"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="107"
                x2="40"
                y2="107"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_29_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="106"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="106" width="15" height="2" id="_x30_5-output-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_29_)">
                  <polygon
                    id="_x30_1-output-white_13_"
                    fill="#FFFFFF"
                    points="25,102 35,102 40,107 35,112 25,112    "
                  />
                </g>
              </mask>

              <line
                ref={outputFillRef2}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_5-output-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="25"
                y1="107"
                x2="40"
                y2="107"
              />
            </g>
          </VCPTurbo>
        </>
      )}
    </SvgLines>
  );
};
