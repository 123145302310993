import { MenuLinkProps } from "#interfaces";
import { useMenuStyle } from "#providers";
import { Color } from "#shared/theme";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Link, LinkProps } from "../Link/Link";

export interface MenuAnchorProps {
  withLastRowUnderline?: boolean;
  isActive?: boolean;
}

const FilteredLink: React.FC<MenuAnchorProps & LinkProps> = ({ withLastRowUnderline, isActive, ...props }) => {
  return <Link {...props} />;
};
const StyledLink = styled(FilteredLink)<MenuAnchorProps>`
  line-height: 1.4;
  overflow: hidden;
  ${({ withLastRowUnderline = false, isActive = false }) =>
    withLastRowUnderline
      ? css`
          display: block;
          &:after {
            display: inline-block;
            vertical-align: bottom;
            box-shadow: -100vw 100vw 0 100vw ${Color.Black};
            height: 1px;
            margin-top: 1px;
            content: "";
          }
        `
      : css`
          border-bottom: ${isActive ? 0 : 1}px solid ${Color.Black};
        `}
  b {
    display: block;
  }
`;

type MenuLinkOwnProps = MenuLinkProps & LinkProps & Omit<MenuAnchorProps, "withLastRowUnderline">;

export const MenuLink: React.FC<MenuLinkOwnProps> = ({ to, title, text, isActive, onClick }) => {
  const { withLastRowUnderline } = useMenuStyle();
  return (
    <StyledLink to={to} withLastRowUnderline={withLastRowUnderline} isActive={isActive} onClick={onClick}>
      {title && <b>{title}</b>}
      {text}
    </StyledLink>
  );
};
