import React from "react";
import { rgba } from "polished";
import { useDarkOverlay } from "#providers";
import { Color, expander, fadeIn, fadeOut } from "#shared/theme";
import styled from "styled-components/macro";

interface DarkOverlayProps {
  isOpen?: boolean;
  isClose?: boolean;
}

const Container = styled.div<DarkOverlayProps>`
  ${expander}
  z-index: ${({ isClose }) => (isClose ? -1 : 100)};
  position: fixed;
  background-color: ${rgba(Color.Black, 0.4)};
  ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)};
`;

export const DarkOverlay: React.FC = () => {
  const { isOpen, isClose } = useDarkOverlay();
  return <Container isOpen={isOpen} isClose={isClose} />;
};
