import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { Root } from "#components";
import { bp, Color, fontCondensed, gridColumnStart, gridColumnWidth, IconKeys } from "#shared/theme";
import { ButtonCircle, ButtonCircleSize, Grid, Icon, Wrapper } from "#ui";
import { useTranslation } from "#hooks";
import { TextColorThemeTypes } from "#constants";
import { images } from "#mocks";

const Main = styled.main`
  margin: 90px 0 40px;
  @media ${bp.m} {
    margin: 100px 0;
  }
  @media ${bp.d} {
    margin: 128px 0 40px;
  }
`;

const Content = styled.div`
  text-align: center;
  color: ${Color.White};
  ${gridColumnStart(2)}
  ${gridColumnWidth(10)}
  @media ${bp.m} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.t} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const TextContainer = styled.div`
  margin-top: 104px;
  @media ${bp.t} {
    margin-top: 0;
    margin-bottom: 96px;
  }
  @media ${bp.d} {
    margin-bottom: 112px;
  }
`;

const Title = styled.h1`
  ${fontCondensed}
  font-weight: normal;
  font-size: 36px;
  margin: 0 0 49px;
  line-height: 1;
`;

const Text = styled.div<{ isSmall?: boolean }>`
  font-size: 21px;
  line-height: 1.2;
  ${({ isSmall }): FlattenSimpleInterpolation | false =>
    !isSmall &&
    css`
      @media ${bp.d} {
        font-size: 31px;
        line-height: 1.13;
      }
    `}
  p + p {
    margin-top: 10px;
  }
`;

const Link = styled.a`
  color: ${Color.Green};
  font-size: 13px;
  display: inline-flex;
  align-items: flex-end;
  margin-top: 36px;
`;

const LinkIcon = styled(Icon)`
  fill: ${Color.Green};
  flex: 0 0 17px;
  height: 16px;
`;

const LinkLabel = styled.span`
  white-space: nowrap;
  margin-left: 7px;
  border-bottom: 2px solid ${Color.Green};
`;

const TimerLabel = styled.span`
  font-size: 48px;
  font-weight: bold;
  color: ${Color.Black};
`;

const TimerContainer = styled.div`
  margin: 44px auto 48px;
`;

export const ComingSoonPage: React.FC = () => {
  const { t } = useTranslation("comingSoon");
  const headerProps = {
    theme: TextColorThemeTypes.Light,
    transparent: true,
    withNav: false,
  };

  const endTime = t("dateTime");
  const currentTime = new Date(Date.now()).toLocaleString("en-US", { timeZone: "Europe/Berlin" });
  const currentTimeMilliseconds = new Date(currentTime).getTime();
  const endTimeMilliseconds = new Date(endTime).getTime();
  const differenceInDays = Math.floor((endTimeMilliseconds - currentTimeMilliseconds) / (1000 * 3600 * 24));
  const differenceInHours = Math.floor((endTimeMilliseconds - currentTimeMilliseconds) / (1000 * 3600));
  return (
    <Root
      style={{ backgroundImage: `url(${images.comingSoonBg})`, backgroundColor: Color.BlueBg }}
      withHeader
      headerProps={headerProps}
      theme={TextColorThemeTypes.Light}
    >
      <Main>
        <Wrapper>
          <Grid>
            <Content>
              <div>
                <Title dangerouslySetInnerHTML={{ __html: t("title") }} />
                <Text isSmall dangerouslySetInnerHTML={{ __html: t("availableIn") }} />
                <TimerContainer>
                  <ButtonCircle size={ButtonCircleSize.ExtraLarge}>
                    <TimerLabel>{differenceInDays > 0 ? differenceInDays : differenceInHours}</TimerLabel>
                  </ButtonCircle>
                </TimerContainer>
                <Text isSmall>{differenceInDays > 0 ? t("days") : t("hours")}</Text>
                <Link download href="/data/Termineintrag.ics">
                  <LinkIcon iconKey={IconKeys.SvgDownloadSmall} />
                  <LinkLabel>{t("downloadLink")}</LinkLabel>
                </Link>
              </div>
              <TextContainer>
                <Text dangerouslySetInnerHTML={{ __html: t("text") }} />
              </TextContainer>
            </Content>
          </Grid>
        </Wrapper>
      </Main>
    </Root>
  );
};
