import React, { useRef } from "react";
import { Color } from "#shared/theme";
import { useMarketsChartAnimationMobile, useNumberFormat } from "#hooks";
import { SvgText, SvgTextSmall } from "#ui";
import { chartMobileGlobalMarketsDuration } from "#constants";
import { ChartMarketPropsMobile } from "./chartProps";

export const Other: React.FC<ChartMarketPropsMobile> = (props) => {
  const currentPathRef = useRef<SVGLineElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPolylineElement>(null);
  const topLineRef = useRef<SVGLineElement>(null);
  const pathLength = useMarketsChartAnimationMobile({
    ...props,
    currentPathRef,
    contentRef,
    lineRef,
    topLineRef,
    animationDuration: chartMobileGlobalMarketsDuration,
  });
  return (
    <g fill="none">
      <line ref={currentPathRef} stroke={Color.BlackLight} strokeWidth="9" x1="182.5" y1="322" x2="182.5" y2="322" />
      <line ref={topLineRef} stroke={Color.White} strokeWidth="9" x1="182.5" y1="322" x2="182.5" y2="322" />
      <polyline
        ref={lineRef}
        stroke={Color.White}
        strokeMiterlimit="9"
        points="284,265.5 210,265.5 187,322 178,322"
        strokeDashoffset={pathLength}
        strokeDasharray={pathLength}
      />

      <SvgText ref={contentRef} alignEnd>
        <SvgTextSmall>
          <text fill={Color.BlackLight} transform="matrix(1 0 0 1 284 257.7695)" fontSize="10">
            {props.labels?.labels.other}
          </text>
        </SvgTextSmall>
        <text transform="matrix(1 0 0 1 284 241.666)" fontSize="28">
          {useNumberFormat(props.currentValue)}
        </text>
      </SvgText>
    </g>
  );
};
