import React from "react";
import styled from "styled-components/macro";
import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useTranslation } from "#hooks";
import { NavButton, NavButtonTheme } from "../NavButton/NavButton";
import { rootElement } from "#helpers";

gsap.registerPlugin(ScrollToPlugin);

const Container = styled.div`
  width: 50px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
`;

export const ScrollTopButton: React.FC = () => {
  const { t } = useTranslation("common");
  const scrollTop = () => {
    gsap.to(rootElement, { duration: 1, scrollTo: { y: 0 }, ease: Power3.easeInOut });
  };
  return (
    <Container>
      <Wrapper>
        <NavButton onClick={scrollTop} theme={NavButtonTheme.Black}>
          {t("Top")}
        </NavButton>
      </Wrapper>
    </Container>
  );
};
