import { Color } from "#shared/theme";
import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import styled from "styled-components/macro";
import circleInlineInfoButton_50x50 from "./circle-inline-info-button_50x50.json";
import circleButtonStart_70x70 from "./circle-button-start_70x70.json";
import circleButtonVcp_70x70 from "./circle-button-vpc_50x50.json";
import { ProgressCircle } from "./ProgressCircle";

export enum ButtonCircleSize {
  Default = "Default",
  Large = "Large",
  ExtraLarge = "ExtraLarge",
}

export const buttonCircleSizeByType: { [i: string]: number } = {
  [ButtonCircleSize.Default]: 34,
  [ButtonCircleSize.Large]: 58,
  [ButtonCircleSize.ExtraLarge]: 90,
};

export enum ButtonAnimationType {
  circleInlineInfoButton_50x50 = "circleInlineInfoButton_50x50",
  circleButtonStart_70x70 = "circleButtonStart_70x70",
  circleButtonVcp_70x70 = "circleButtonVcp_70x70",
}

const buttonAnimationByType: { [i: string]: Record<string, unknown> } = {
  [ButtonAnimationType.circleInlineInfoButton_50x50]: circleInlineInfoButton_50x50,
  [ButtonAnimationType.circleButtonStart_70x70]: circleButtonStart_70x70,
  [ButtonAnimationType.circleButtonVcp_70x70]: circleButtonVcp_70x70,
};

interface BtnProps {
  size: ButtonCircleSize;
}

const Btn = styled.div<BtnProps>`
  border-radius: 50%;
  width: ${({ size }) => buttonCircleSizeByType[size]}px;
  height: ${({ size }) => buttonCircleSizeByType[size]}px;
  background-color: ${Color.Green};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  position: relative;
  cursor: pointer;
  color: ${Color.Black};
`;

const getPlayerStyle = (size: ButtonCircleSize) => {
  const sizeOffset = size === ButtonCircleSize.ExtraLarge ? 34 : 16;
  const positionOffset = size === ButtonCircleSize.ExtraLarge ? 17 : 8;
  return {
    position: "absolute",
    display: "block",
    height: `calc(100% + ${sizeOffset}px)`,
    width: `calc(100% + ${sizeOffset}px)`,
    left: `-${positionOffset}px`,
    top: `-${positionOffset}px`,
    bottom: `${positionOffset}px`,
    right: `${positionOffset}px`,
  };
};

const Border = styled.svg<BtnProps>`
  position: absolute;
  display: block;
  height: calc(100% + 16px);
  width: calc(100% + 16px);
  left: -8px;
  top: -8px;
  bottom: 8px;
  right: 8px;
`;

export interface ButtonCircleProps extends Partial<BtnProps> {
  onClick?: () => void;
  progress?: number;
  animation?: ButtonAnimationType;
}

export const ButtonCircle: React.FC<ButtonCircleProps> = ({
  size = ButtonCircleSize.Default,
  animation,
  children,
  progress,
  ...props
}) => {
  return (
    <Btn size={size} {...props}>
      {children}
      {progress ? (
        <ProgressCircle progress={progress} />
      ) : animation ? (
        <Player autoplay loop src={buttonAnimationByType[animation]} style={getPlayerStyle(size)} />
      ) : (
        <Border size={size} viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="23" strokeWidth="4" fill="none" stroke="#000" vectorEffect="non-scaling-stroke" />
        </Border>
      )}
    </Btn>
  );
};
