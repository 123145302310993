import { ThemeKeys } from "#constants";
import { InfoCardTheme } from "#interfaces";
import { bp, Color, fontCondensed } from "#shared/theme";
import React from "react";
import styled, { css } from "styled-components/macro";
import theme from "styled-theming";

const Container = styled.div`
  position: relative;
  background-color: ${Color.White};
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: fit-content;
  ${theme(ThemeKeys.infoBg, {
    [InfoCardTheme.GrayLightest]: css`
      background-color: ${Color.GrayLightest};
    `,
    [InfoCardTheme.Gray]: css`
      background-color: ${Color.GrayLighten};
    `,
  })}
`;
const Head = styled.div`
  padding: 35px 15px 20px;
  position: relative;
  z-index: 1;
  @media ${bp.m} {
    padding-bottom: 45px;
  }
`;
const Category = styled.div`
  ${fontCondensed}
  font-weight: bold;
  font-size: 13px;
  line-height: 1.23;
`;
export const InfoCardTitle = styled.h2`
  font-size: 22px;
  line-height: 1.45;
  width: fit-content;
  @media ${bp.m} {
    font-size: 26px;
  }
`;
const Body = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  max-width: 100%;
  display: flex;
  width: fit-content;
`;

export interface InfoCardProps {
  category?: string;
  title?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ category, title, children }) => {
  return (
    <Container>
      {(category || title) && (
        <Head>
          {category && <Category>{category}</Category>}
          {title && <InfoCardTitle>{title}</InfoCardTitle>}
        </Head>
      )}
      <Body>{children}</Body>
    </Container>
  );
};
