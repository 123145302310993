import { isFullscreenSupported } from "#constants";
import { FullScreenHandle, useFullScreenHandle, FullScreen } from "react-full-screen";
import { usePopover } from "./use-popover";

export { FullScreen };
export type { FullScreenHandle };

export interface UseFullscreen extends Pick<FullScreenHandle, "node" | "active"> {
  enter: () => void;
  exit: () => void;
}

export const useFullscreen = (): UseFullscreen => {
  const handle = useFullScreenHandle();
  const { isOpen, open, close } = usePopover();
  const enter = () => {
    !isFullscreenSupported ? open() : handle.enter();
  };
  const exit = () => {
    !isFullscreenSupported ? close() : handle.exit();
  };
  const active = !isFullscreenSupported ? isOpen : handle.active;
  return {
    node: handle.node,
    enter,
    exit,
    active,
  };
};
