export const emissions = {
  title: {
    headline:
      "Dass die BMW Group den von der EU vorgegebenen CO2-Flottengrenzwert 2020 unterboten hat, freut Christian Miedaner, aber überrascht ihn nicht. Wir haben bei ihm nachgefragt warum, denn schließlich verantwortet er das Thema CO2-Reduzierung in der Produktnutzungsphase.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        src: "/data/interview/emissions/image-emissions-1.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "CO2-Strategie im Rahmen der Produktnutzungsphase – was bedeutet das?",
        copytext:
          "<p><strong>Christian Miedaner</strong> Die Aufgabe besteht darin, mit unseren Fahrzeugen alle marktspezifischen regulatorischen Vorgaben zum Flottenverbrauch und der entsprechenden CO2-Flottenemissionen zu erfüllen. Dies gilt sowohl kurzfristig, als auch mittel- und langfristig. Und da wird es spannend. Denn dann sprechen wir nicht mehr nur über bereits bestehende Gesetze oder Vorgaben, sondern über eine Einschätzung zu gesetzlichen Entwicklungen, die wir antizipieren und in eigene Ziele übersetzen müssen. Viele Märkte haben für den Zeitraum nach 2025 noch gar keine verabschiedete Gesetzgebung. Wir müssen aber dafür sorgen, dass die Fahrzeuge, die wir gerade entwickeln, zu diesem Zeitpunkt die Vorgaben robust erfüllen. Eine komplexe Aufgabe. Sie reicht von technischen Maßnahmen über Entscheidungen zum zukünftigen Antriebsportfolio bis zur betriebswirtschaftlichen Bewertung.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "GreenLight",
        copytext:
          "<p>Der Anteil an elektrifizierten Fahrzeugen in Europa hatte vergangenes Jahr einen <strong>ZUWACHS</strong> von über 80%.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Sie sprechen über marktspezifische Vorgaben. In den Kennzahlen wird meist über den CO2-Flottenwert der Neufahrzeuge in der EU gesprochen? Warum darüber?",
        copytext:
          "<p><strong>Christian Miedaner</strong> Die CO2-Grenzwertvorgaben der EU gehören schon seit langem zu den strengsten weltweit und auch in Zukunft zu den ambitioniertesten. Diese Vorgaben zu erfüllen ist quasi die größte Herausforderung. Deshalb legen wir den klaren Fokus auf die EU-Zielerreichung. Man könnte es so ausdrücken: Wenn man in Europa besteht, dann ist es deutlich leichter auch die Anforderungen in anderen Märkten und Regionen zu erfüllen. Davon abgesehen ist Europa unsere größte Absatzregion.</p><p>Aber wie gesagt: Mein Job ist es, sicherzustellen, dass wir die Vorgaben in allen Märkten erreichen. Und deshalb haben wir auch die Erfüllung der regulatorischen Vorgaben in anderen Märkten und Regionen ständig im Blick.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1406,
        items: [
          { width: 48, height: 34, src: "/data/interview/emissions/image-emissions-gallery-1.jpg" },
          { width: 26, height: 34, src: "/data/interview/emissions/image-emissions-gallery-2.jpg" },
          { width: 26, height: 34, src: "/data/interview/emissions/image-emissions-gallery-3.jpg" },
        ],
        caption: {
          headline: "",
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Herr Helfmann, was macht die Situation in der Schweiz besonders?",
        copytext:
          "<p>Einiges. Wir haben ähnlich harte CO2 Ziele für unseren Markt wie in der EU – wir haben aber eine andere Ausgangssituation. Viele Kundinnen und Kunden in der Schweiz haben bislang ein elektrifiziertes Fahrzeug nicht in Betracht gezogen. Weil es in unserem Markt kaum politische Anreize gibt. Weil die Ladesituation noch alles andere als gut ist. Und auch weil sich in unserem von den Alpen geprägten Land viele für allradgetriebene Fahrzeuge mit starken Motorisierungen entscheiden. Umso stolzer sind wir, dass wir auch in der Schweiz die gesetzten Ziele übertreffen konnten. Unsere Händler haben sehr viel Überzeugungsarbeit geleistet. Und vor allem haben wir viel Energie in die Beratung zu Lademöglichkeiten gesteckt – für Privat- und Firmenkunden.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        quote:
          "Letzten Endes haben wir die Ziele 2020 sogar übererfüllt. Und das ist gut so, es geht uns ja nicht um die Ziele an sich, sondern um unseren Beitrag zur ökologischen Verantwortung.",
        author: "Christian Miedaner",
      },
    },
    {
      type: "Image",
      props: {
        align: "Center",
        src: "/data/interview/emissions/image-auto.jpg",
        caption: {
          desc:
            "Die Nachhaltigkeitsziele der BMW Group sehen vor, bis 2030 weltweit mehr als sieben Millionen Fahrzeuge mit elektrifiziertem Antrieb auf den Straßen zu haben.",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Zurück zu den EU Flottenzielen 2020. Die BMW Group war sich immer sicher, diese zu erfüllen. Wie ist das gelungen?",
        copytext:
          "<p><strong>Christian Miedaner</strong> Es war natürlich ein großes Stück Arbeit. Gelungen ist es uns dadurch, dass wir immer vorausschauend gehandelt haben und frühzeitig wichtige und richtige Entscheidungen getroffen haben. Zum Beispiel, dass wir bereits heute in jedem Segment elektrifizierte Fahrzeuge anbieten. Mit diesem breiten Angebot überzeugen wir unsere Kunden, was man an den Verkaufszahlen sieht. Gleichzeitig haben wir uns entschieden, die Effizienz bei den konventionellen Antrieben noch weiter zu verbessern. Seit 2007 sind unsere Efficient Dynamics Technologien im Einsatz und bei jedem neuen Fahrzeug wird konsequent auf Aerodynamik, Leichtbau, Energiemanagement und das abgestimmte Zusammenspiel der Innovationen geachtet. Ein wichtiger Schritt war auch der flächendeckende Einsatz von 48-Volt-Systemen.</p><p>Letzten Endes haben wir die Ziele 2020 sogar übererfüllt. Und das ist gut so, es geht uns ja nicht um die Ziele an sich, sondern um unseren Beitrag zur ökologischen Verantwortung.</p>",
      },
    },
    {
      type: "Video",
      props: {
        align: "Center",
        poster: "/data/interview/emissions/co2emmissions_06_videoposter.jpg",
        src: "/data/interview/emissions/co2emissions.mp4",
        proportions: (800 / 1920) * 100,
      },
    },
    {
      type: "ImageQuote",
      props: {
        quote:
          "Eine so konsequente Lösungsorientierung ist für mich in einem großen Unternehmen nicht selbstverständlich.",
        author: "Christian Miedaner",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Auch die zukünftigen Ziele sind ambitioniert. Wo liegen die größten Herausforderungen?",
        copytext:
          "<p><strong>Christian Miedaner</strong> Für die Ziele 2021 sind wir auf einem sehr guten Weg. Was die Ziele 2030 angeht – das ist eine andere Hausnummer. Ich bin mir sicher, mit unserem Produktportfolio sind wir richtig und gut aufgestellt. 2023 werden wir 25 elektrifizierte Modelle auf der Straße haben. Doch auch wenn das Angebot stimmt, müssen sich am Ende genügend Kundinnen und Kunden für elektrifizierte Modelle entscheiden. Und dafür ist es wichtig, dass die Rahmenbedingungen passen. Dazu gehört, dass die Ladeinfrastruktur deutlich ausgebaut wird. Politik und Ladesäulen-Betreiber müssen mit der Entwicklung der Elektromobilität Schritt halten und ebenso ihren Beitrag leisten.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1406,
        items: [
          { width: 21, height: 39, src: "/data/interview/emissions/image-emissions-gallery-4.jpg" },
          { width: 51, height: 39, src: "/data/interview/emissions/image-emissions-gallery-5.jpg" },
          { width: 28, height: 39, src: "/data/interview/emissions/image-emissions-gallery-6.jpg" },
        ],
        caption: {
          headline: "",
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Was macht besonders Spaß an Ihrem Job?",
        copytext:
          "<p><strong>Christian Miedaner</strong> Mir gefällt, dass wir bei der BMW Group wichtige Entscheidungen auch zügig treffen. Das gilt auf allen Ebenen, in den Märkten, in der Zentrale und auf oberster Managementebene. Diese Lösungsorientierung ist für mich in einem großen Unternehmen nicht selbstverständlich.</p><p>Und ganz besonders freut es mich, wie gut unsere elektrifizierten Autos von den Kunden angenommen werden. Der Anteil an elektrifizierten Fahrzeugen in Europa hatte letztes Jahr einen Zuwachs von über 80% – das ist doch deutlich, oder?</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Vielen Dank für das Gespräch.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        isDoubleCaption: true,
        parallaxColor: "GreenLight",
        items: [
          {
            src: "/data/interview/emissions/image-emissions-2.jpg",
            caption: {
              headline: "Christian Miedaner",
              desc: "fährt aktuell einen BMW 330e Plug-in-Hybrid. Aus Überzeugung.",
            },
          },
          {
            src: "/data/interview/emissions/image-emissions-3.jpg",
            caption: {
              headline: "",
              desc:
                "Andreas Helfmann leitet die Strategie und Planung bei der BMW Schweiz AG. Dazu gehört auch die Koordination der CO2-Steuerung.",
            },
          },
        ],
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 2,
            link: "/interview/clean-production/",
          },
          {
            storyId: 0,
            link: "/interview/diversity/",
          },
        ],
      },
    },
  ],
};
