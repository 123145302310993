import { bp, font, Color, maxWidth, maxHeight, screenSize } from "#shared/theme";
import styled from "styled-components/macro";

export const CollageContainer = styled.div`
  flex: 1 0 420px;
  display: flex;
  @media ${maxWidth(screenSize.m)} and (orientation: landscape) {
    width: calc(100vw - 20px);
    max-width: none;
  }
  @media ${bp.m} {
    flex: 1 0 525px;
  }
`;
export const CollageLine = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  height: 7px;
  background-color: ${Color.Black};
  @media ${maxHeight(580)} and (orientation: portrait) {
    display: none;
  }
`;

export const CollageContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 0 60%;
  flex: 1 0 0%;
  @media (orientation: landscape) {
    padding-bottom: 0;
  }
`;
export const CollageHeadline = styled.h2`
  font-family: ${font.condensed};
  font-size: 50px;
  line-height: 1.2;
  margin-top: 40px;
  @media ${bp.m} {
    font-size: 70px;
  }
  @media ${maxHeight(580)} and (orientation: portrait) {
    display: none;
  }
`;

export const CollageCopytext = styled.div`
  font-size: 17px;
  line-height: 1.65;
  margin-left: 50px;
  margin-right: 50px;
  @media ${bp.m} {
    margin-left: 70px;
    margin-right: 70px;
    font-size: 22px;
  }
  strong {
    font-family: ${font.condensed};
    font-size: 24px;
    @media ${bp.m} {
      font-size: 29px;
    }
  }
`;
