import { bp } from "#shared/theme";
import React from "react";
import { ColumnCopytext } from "#ui";
import styled from "styled-components/macro";
import { InfoCard, InfoCardProps, InfoCardTitle } from "./InfoCard";

const Container = styled.div`
  position: relative;
  margin: 0 15px 40px;
  display: flex;
  width: fit-content;
  @media ${bp.m} {
    margin: 0 50px 70px;
  }
`;
const Content = styled.div`
  position: relative;
  height: 100%;
  & + & {
    margin-left: 15px;
    @media ${bp.m} {
      margin-left: 30px;
    }
  }
`;
const Headline = styled(InfoCardTitle)`
  position: absolute;
  bottom: 100%;
  margin-bottom: 45px;
`;

interface ContentProps {
  headline: string;
  copytext: string;
}
interface InfoCardCopytextProps extends InfoCardProps {
  content: ContentProps[];
}

export const InfoCardCopytext: React.FC<InfoCardCopytextProps> = ({ title, category, content }) => {
  return (
    <InfoCard title={title} category={category}>
      <Container>
        {content.map(({ headline, copytext }, key) => (
          <Content key={key}>
            {headline && <Headline>{headline}</Headline>}
            <ColumnCopytext copytext={copytext} />
          </Content>
        ))}
      </Container>
    </InfoCard>
  );
};
