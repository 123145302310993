import getCurve from "./getCurve";
import getHeight from "./getHeight";
const mountainVert = `varying vec2 vUv;
uniform float uTime;
uniform float uProgress;
uniform vec2 uStepSize;
varying vec3 vWPos;
varying vec3 vWNormal;
uniform float uWorldZInUV;
uniform float uTravelLength;
uniform float uCurveAmplitude;
uniform float uHeightAct;
uniform float uCurveFrequency;
uniform sampler2D uHeightMap;
varying float vProgress;

uniform float uOffset0;
uniform float uOffset1;
uniform float uOffset2;

#include <fog_pars_vertex>

${getHeight}
${getCurve}

void main(){
	vec3 transformed = position;
	
	float smoothSide = 0.3;

	vec2 uv2 = uv;
	
	
	float height = getHeight(uv, uTime);
	
	transformed.z += height * uHeightAct ;
	
	// transformed.x += sin(uv2.y) * 2.;
	vec4 mPosition = modelMatrix * vec4(transformed, 1.);
	mPosition.xy += getCurve(mPosition.z, uTime);

	// float waveThing =sin(mPosition.z * 0.03 + mPosition.x * 0.15 + 2. ) *5. * (1.-uHeightAct);
	// waveThing *=  smoothstep(0., 0.3 ,uv.x) * smoothstep(1., 1. -0.3 ,uv.x);
	// mPosition.y += waveThing;
	
	vec4 mvPosition = viewMatrix * mPosition;
	gl_Position = projectionMatrix * mvPosition;
	vWPos = mPosition.xyz;

	vUv = uv2;
	#include <fog_vertex>
}`;

export default mountainVert;
