import React from "react";
import { Text } from "#ui";
import { GeneralPageLayout } from "#components";
import { useTranslation } from "#hooks";

export const Glossar: React.FC = () => {
  const { t } = useTranslation("glossary");
  return (
    <GeneralPageLayout>
      <Text text={t("text")} withLines />
    </GeneralPageLayout>
  );
};
