import { bp, Color, defTransition, fontCondensed, gridColumnStart, gridColumnWidth } from "#shared/theme";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Grid } from "../Grid/Grid";
import { Wrapper } from "../Wrapper/Wrapper";

const Container = styled(Wrapper)`
  overflow: hidden;
`;

const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  font-size: 24px;
  line-height: 34px;
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(14)}
    font-size: 30px;
    line-height: 40px;
  }
  @media ${bp.p} {
    font-size: 38px;
    line-height: 48px;
  }
  @media ${bp.t} {
    ${gridColumnWidth(12)}
  }
  @media ${bp.d} {
    font-size: 45px;
    line-height: 56px;
  }
  [data-line] {
    color: ${Color.Green};
    ${fontCondensed}
    font-size: 18px;
    font-weight: bold;
    position: relative;
    @media ${bp.m} {
      font-size: 20px;
    }
    @media ${bp.p} {
      font-size: 28px;
    }
    @media ${bp.d} {
      font-size: 33px;
    }
    strong {
      border-bottom: 1px solid ${Color.Green};
      transition: color ${defTransition};
      &:hover {
        color: ${Color.Black};
      }
    }
  }
  cite {
    font-size: 24px;
    line-height: 48px;
    font-style: normal;
    margin-top: 60px;
    @media ${bp.p} {
      font-size: 28px;
    }
    @media ${bp.d} {
      margin-top: 72px;
      font-size: 33px;
      line-height: 58px;
    }
    small {
      display: block;
      font-size: 13px;
      line-height: 18px;
      ${fontCondensed}
      @media ${bp.d} {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
`;
interface AsideProps {
  withLine: boolean;
}
const Aside = styled.div<AsideProps>`
  margin-top: 50px;
  margin-bottom: 50px;
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  ${({ withLine }) =>
    withLine &&
    css`
      display: none;
    `}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    margin-bottom: 0;
    display: block;
    ${gridColumnStart(18)}
    ${gridColumnWidth(7)}
  }
`;

export interface TextAsideProps extends Partial<AsideProps> {
  content: React.ReactNode;
}

export const TextAside: React.FC<TextAsideProps> = ({ content, children, withLine = false }) => {
  return (
    <Container>
      <Grid>
        <Content>{content}</Content>
        <Aside withLine={withLine}>{children}</Aside>
      </Grid>
    </Container>
  );
};
