import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card11: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect x="453" fill="#1EBEE4" width="164" height="270.5" />
        <rect x="284.5" y="133.5" width="356.5" height="8" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="human">
          <g>
            <path
              fill="none"
              d="M506.98,202.07c-0.01,0.02-0.01,0.05-0.02,0.07l-0.4-7.33c-0.04-0.48-0.07-0.89-0.1-1.22
				c-0.05,0.25-0.12,0.53-0.2,0.88c-0.14,0.56,0.06,3.1-0.08,4.25c-0.19,1.48-0.14,2.24-0.07,2.61c0.07,0.2,0.16,0.49,0.18,0.6
				c0.03,0.16,0.13,0.18,0.3,0.38c0.08,0.1,0.19,0.19,0.31,0.27l0.09-0.38C506.98,202.16,506.98,202.11,506.98,202.07z"
            />
            <path
              fill="none"
              d="M510.1,178.03c0-0.01-0.01-0.03-0.01-0.04c-0.03-0.09-0.06-0.16-0.09-0.22c0.05,0.12,0.09,0.23,0.1,0.28
				C510.1,178.05,510.1,178.04,510.1,178.03z"
            />
            <polygon fill="none" points="514.77,177.72 514.77,177.72 514.77,177.71 			" />
            <path
              fill="none"
              d="M514.23,234.65c0,0,0.02,0.05,0.05,0.11c-0.03-0.15-0.05-0.3-0.07-0.45c-0.31-0.1-0.51-0.19-0.51-0.19
				s-0.45-7.53-0.45-10.63s-0.81-13.48-0.81-13.48s-0.4,5.39-0.54,8.49s-0.4,15.69-0.4,15.69c-0.11,0.06-0.23,0.11-0.34,0.15
				c-0.01,0.1-0.01,0.2-0.02,0.3c0.01-0.01,0.02-0.02,0.02-0.02s0.17,0.38,0.26,1.03c0,0,0,0.01,0,0.02c0,0.01,0,0.02,0,0.03
				c0.04-0.01,0.07-0.01,0.11-0.02c0,0,1.32-0.02,2.58-0.04c0,0,0-0.01,0-0.01C514.2,235.09,514.23,234.65,514.23,234.65z"
            />
            <path
              opacity="0.3"
              d="M542.39,236.61c-7.09-0.37-18.1-0.78-25.22-0.98c0.24,0.32,0.22,0.45,0.99,0.79
				c1.09,0.48,1.73,0.42,1.74,1.11c0,0.07-0.01,0.13-0.03,0.18c-0.05,0.53-0.41,0.88-1.52,0.82c-0.93-0.05-2.3-0.76-2.93-1.11
				c-0.74-0.41-1.04-0.43-1.22-0.74c-0.18-0.31-0.1-0.78-0.1-0.78c0.01-0.09,0.02-0.18,0.03-0.26c-1.26,0.02-2.58,0.04-2.58,0.04
				c-0.04,0.01-0.08,0.01-0.11,0.02c0.02,0.12,0.05,0.47-0.1,0.73c-0.18,0.31-0.38,0.32-1.39,0.73c-0.67,0.27-1.56,0.91-2.94,0.89
				c-0.2,0-0.37-0.01-0.52-0.03c1.45,0.89,14.31,2.97,30.49,3.88C559.24,243.15,558.95,237.46,542.39,236.61z"
            />
            <path
              fill="#151616"
              d="M511.15,234.64c0.01-0.1,0.01-0.2,0.02-0.3c0.12-0.04,0.23-0.09,0.34-0.15c0,0,0.27-12.59,0.4-15.69
				s0.54-8.49,0.54-8.49s0.81,10.38,0.81,13.48s0.45,10.63,0.45,10.63s0.2,0.1,0.51,0.19c0.02,0.15,0.04,0.3,0.07,0.45
				c0.1,0.19,0.37,0.53,0.87,0.34c0.8-0.3,1.16-0.37,1.3-0.35c-0.01-0.16-0.03-0.32-0.05-0.48c0.14-0.08,0.28-0.17,0.4-0.29
				c0.13-0.92,0.53-5.72,0.8-9.9c0.27-4.18,0.41-8.79,0.53-13.18c0.06-2.49-0.3-5.95-0.5-7.67c-0.38,0.92-3.29,0.87-3.98,0.87
				c0.01,0,0.02-0.01,0.03-0.01c-1.8,0-3.84-0.16-5.26-0.29c0.04,0.07,0.09,0.14,0.17,0.22c0.24,0.25,0.25,0.35,0.33,0.56
				s0.27,0.49,0.19,0.59c-0.08,0.09-0.28,0.12-0.52-0.13c-0.24-0.24-0.31-0.37-0.49-0.56c-0.12-0.11-0.31-0.27-0.37-0.28
				c-0.1-0.01-0.07,0.13-0.07,0.15c0,0.01-0.01-0.02-0.01-0.05c0,0.05,0.01,0.12,0.04,0.18c0.07,0.14,0.19,0.66,0.19,0.66
				s0.32,0.46,0.47,0.68c0.15,0.22,0.15,0.22,0.15,0.22s0.33,0.12,0.3,0.29c0.02,0.08-0.01,0.14-0.12,0.19
				c-0.11,0.05-0.16,0.02-0.16,0.02s-0.02,0.05-0.12,0.07c-0.1,0.01-0.22-0.11-0.22-0.11l-0.19-0.05c0,0-0.02,0.03-0.11,0.05
				s-0.16,0.05-0.32,0.01c-0.16-0.03-0.28-0.08-0.39-0.05c-0.12,0.03-0.2,0.05-0.27,0.02c-0.06-0.03-0.13-0.1-0.24-0.13
				c-0.03,2.45-0.07,5.95,0.02,9.01c0.14,4.64,0.53,7.18,0.8,10.42c0.27,3.23,0.76,8.34,0.76,8.34s0.31,0.15,0.78,0.27
				c0,0.09,0,0.18,0,0.26c0.17,0,0.58,0.02,1.02,0.37C510.43,235.35,511.03,234.77,511.15,234.64z"
            />
            <path
              fill="#151616"
              d="M509.95,237.16c1-0.41,1.21-0.42,1.39-0.73c0.15-0.26,0.12-0.62,0.1-0.73c0-0.01,0-0.02,0-0.03
				c0-0.01,0-0.02,0-0.02s0.01,0.36-0.48,0.69c-1.05,0.69-1.71,0.79-2.79,1.25c-0.64,0.27-2.49,0.37-2.55-0.54
				c-0.06,0.6-0.01,0.88,0.76,0.97c0.04,0,0.08,0.01,0.12,0.01c0.15,0.01,0.32,0.02,0.52,0.03
				C508.39,238.07,509.28,237.43,509.95,237.16z"
            />
            <path
              fill="none"
              stroke="#DFD8C6"
              strokeWidth="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M508.93,234.66
				C508.92,234.66,508.92,234.66,508.93,234.66"
            />
            <path
              fill="#DFD8C6"
              d="M508.16,237.59c1.08-0.46,1.75-0.55,2.79-1.25c0.5-0.33,0.48-0.69,0.48-0.69
				c-0.09-0.65-0.26-1.03-0.26-1.03s-0.01,0.01-0.02,0.02c-0.12,0.12-0.72,0.7-1.12,0.39c-0.44-0.35-0.86-0.38-1.02-0.37
				c-0.03,0-0.06,0-0.07,0c0.01,0.01,0.01,0.09-0.39,0.5c-0.48,0.5-0.49,0.68-1.43,1.04c-0.78,0.3-1.53,0.62-1.51,0.85
				C505.66,237.96,507.51,237.86,508.16,237.59z"
            />
            <path
              fill="#151616"
              d="M517.05,237.79c-1.92-0.97-1.82-0.93-2.47-1.21c-0.55-0.23-0.48-0.69-0.48-0.69s-0.08,0.46,0.1,0.78
				c0.18,0.31,0.48,0.34,1.22,0.74c0.64,0.35,2,1.06,2.93,1.11c1.11,0.06,1.47-0.29,1.52-0.82
				C519.61,238.4,517.79,238.16,517.05,237.79z"
            />
            <path
              fill="#DFD8C6"
              d="M515.16,235.11c-0.51,0.19-0.77-0.16-0.87-0.34c-0.03-0.06-0.05-0.11-0.05-0.11s-0.03,0.44-0.1,0.97
				c0,0,0,0.01,0,0.01c-0.01,0.09-0.02,0.17-0.03,0.26c0,0-0.07,0.46,0.48,0.69c0.65,0.28,0.55,0.23,2.47,1.21
				c0.74,0.37,2.56,0.61,2.82-0.08c0.02-0.06,0.04-0.12,0.03-0.18c-0.01-0.69-0.65-0.63-1.74-1.11c-0.77-0.34-0.75-0.47-0.99-0.79
				c0,0,0,0-0.01-0.01c-0.05-0.07-0.11-0.14-0.19-0.22c-0.48-0.5-0.48-0.5-0.48-0.5c0.04-0.05,0.09-0.13-0.05-0.15
				C516.31,234.74,515.96,234.81,515.16,235.11z"
            />
            <path
              fill="#D8BFB1"
              d="M509.52,182.4c-0.05,0.03-0.1,0.06-0.16,0.09l0.3-0.14C509.58,182.38,509.52,182.4,509.52,182.4z"
            />
            <path
              fill="#9F7C63"
              d="M515.18,182.77c0,0-0.38-0.11-0.73-0.45l0.01-0.1c-0.33,0.52-0.96,0.63-1.89,0.55
				c-1.11-0.1-1.55-0.63-1.91-1.64c-0.02,0.42-0.14,0.73-0.5,0.99l-0.49,0.23l-0.3,0.14l-0.02,0.01l3.22,2.62L515.18,182.77z"
            />
            <path
              fill="#9F7C63"
              d="M510.14,178.24c-0.01-0.06-0.02-0.12-0.04-0.18c-0.01-0.05-0.05-0.17-0.1-0.28
				c-0.18-0.29-0.44-0.16-0.44-0.16c0-0.01,0-0.01,0-0.02c-0.02,0.06-0.04,0.13-0.05,0.22c-0.12,0.77,0.76,1.6,0.76,1.6l0.05,0.25
				c-0.06-0.49-0.08-0.93-0.16-1.35C510.15,178.29,510.14,178.27,510.14,178.24z"
            />
            <path
              fill="#9F7C63"
              d="M513.03,176.33c-1.22,0-1.4-0.41-1.83-0.44c-0.37-0.03-0.71,0.34-0.77,0.97c0,0.02,0,0.04,0,0.06
				c0,0.08-0.01,0.15,0,0.24c0,0.01,0,0.02,0,0.03c0.01,0.44,0.1,0.91,0.19,1.29c0.25,1.01,0.79,1.31,1.31,1.09
				c0.53-0.22,0.86-0.19,1.28-0.07c0.41,0.11,0.49-0.23,0.9-0.08c0.36,0.13,0.52,0.14,0.56,0.14c0.17-0.54,0.06-0.88,0.1-1.77
				c0-0.02,0-0.05,0-0.07c0,0-0.01,0.01-0.01,0.01s0-0.01,0.01-0.01c0,0,0-0.01,0-0.01c-0.01-0.01-0.05-0.03-0.19-0.05
				c-0.26-0.03-0.53,0-0.6,0.01l0,0c-0.18,0.06-0.4,0.21-0.34,0.55c0.05,0.29,0.1,0.51,0.14,0.64c0.03,0.1,0.05,0.22-0.05,0.29
				c0.05,0,0.09,0.02,0.11,0.03c0.03,0.01-0.07,0.01-0.12,0.02c-0.03,0.01-0.06-0.01-0.07-0.02c-0.02,0.01-0.04,0.01-0.06,0.02
				c-0.25,0.05-0.07,0.26-0.07,0.26s-0.18-0.11-0.07-0.25c0.11-0.15,0.31-0.12,0.25-0.33c-0.05-0.21-0.19-0.52-0.19-0.76
				c0-0.28,0.13-0.43,0.28-0.5c0,0-0.01,0-0.01,0s0.21-0.12,0.3-0.15c0.1-0.03,0.25-0.01,0.46-0.02c0.2-0.01,0.16-0.02,0.23,0.06
				c0.02-0.71-0.05-1.29-0.19-1.74c-0.02,0.02-0.03,0.04-0.05,0.06C514.12,176.28,513.71,176.34,513.03,176.33z M512.77,178.17
				c-0.21,0.06-0.26,0.11-0.49,0.03c-0.15-0.05-0.44-0.1-0.33-0.1c0.15-0.01,0.4,0.04,0.64,0.01c0.28-0.03,0.43-0.02,0.43-0.02
				S512.98,178.11,512.77,178.17z M512.72,177.55c-0.18-0.03-0.25,0.02-0.6,0.02c-0.25,0-0.41,0.12-0.41,0.12s0.13-0.21,0.27-0.24
				c0.15-0.03,0.55-0.05,0.81-0.03c0.25,0.02,0.32,0.22,0.25,0.23C512.98,177.66,512.9,177.58,512.72,177.55z M513.17,179.22
				c-0.09,0-0.14-0.01-0.14-0.01c0.1-0.04,0.16-0.04,0.18-0.03C513.26,179.19,513.26,179.22,513.17,179.22z M514.12,178.19
				c0.07,0.01,0.2,0,0.27-0.01c0.09-0.02,0.13-0.04,0.21-0.05c0,0-0.08,0.05-0.17,0.1c-0.09,0.05-0.16,0.06-0.26,0.04
				s-0.24-0.08-0.34-0.09C513.7,178.17,513.98,178.17,514.12,178.19z"
            />
            <path
              fill="#9F7C63"
              d="M510.09,178c0,0,0-0.01,0-0.01c0,0.01,0.01,0.03,0.01,0.04C510.09,178.02,510.09,178.01,510.09,178z"
            />
            <path
              fill="#151616"
              d="M512.57,182.76c0.92,0.09,1.56-0.03,1.89-0.55c0.1-0.16,0.17-0.34,0.22-0.58c0.17-0.91,0-2,0.01-2.07
				c0,0,0,0-0.01,0c-0.04,0-0.2-0.01-0.56-0.14c-0.41-0.15-0.49,0.19-0.9,0.08c-0.41-0.11-0.75-0.15-1.28,0.07
				c-0.53,0.23-1.06-0.07-1.31-1.09c-0.1-0.38-0.18-0.86-0.19-1.29c0-0.01,0-0.02,0-0.03c0-0.08,0-0.16,0-0.24c0-0.02,0-0.04,0-0.06
				c0.06-0.63,0.4-1,0.77-0.97c0.43,0.03,0.61,0.44,1.83,0.44c0.68,0,1.09-0.06,1.51-0.49c0.02-0.02,0.03-0.04,0.05-0.06
				c0.27-0.34,0.32-0.99,0.19-1.09c-0.17-0.13-0.32-0.32-1-0.47c-0.68-0.15-1.51-0.5-2.6,0.02c-1.09,0.52-1.78,0.7-1.62,3.34
				c0,0.01,0,0.01,0,0.02c0,0,0.26-0.13,0.44,0.16c0.03,0.06,0.06,0.13,0.09,0.22c0,0,0,0.01,0,0.01c0,0.01,0,0.02,0.01,0.03
				c0,0.01,0,0.02,0,0.03c0.01,0.06,0.02,0.12,0.04,0.18c0,0.03,0.01,0.05,0.01,0.07c0.07,0.42,0.1,0.86,0.16,1.35
				c0.05,0.37,0.12,0.78,0.27,1.23c0.03,0.08,0.05,0.16,0.08,0.24C511.02,182.13,511.45,182.66,512.57,182.76z M512.49,179.92
				c0.46-0.15,1.17-0.07,1.62-0.02c0.44,0.05,0.17,0.61-0.5,0.68C512.99,180.65,512.02,180.07,512.49,179.92z"
            />
            <path
              fill="#9F7C63"
              d="M513.61,180.58c0.66-0.07,0.94-0.63,0.5-0.68c-0.44-0.05-1.16-0.13-1.62,0.02
				C512.02,180.07,512.99,180.65,513.61,180.58z M513.35,180.03c0.29,0.05,0.27-0.01,0.52,0.04c0.26,0.05,0.25,0.07,0.25,0.07
				s-0.13,0.21-0.52,0.27c-0.38,0.05-1.01-0.3-1.01-0.3C512.99,180.02,513.07,179.98,513.35,180.03z"
            />
            <path
              fill="#D8BFB1"
              d="M517.06,196.43c0,0-0.06-0.02-0.16-0.05l-0.02,0.04C517.01,196.45,517.1,196.45,517.06,196.43z"
            />
            <path
              fill="#9F7C63"
              d="M506.38,205.89c-0.1-0.19-0.42-0.42-0.45-0.52c-0.02-0.1-0.36-0.37-0.07-0.18
				c0.3,0.18,0.58,0.68,0.66,0.83c0.07,0.14,0.15,0.25,0.24,0.22c0.09-0.03,0.25-0.1,0.22-0.2s-0.19-0.32-0.33-0.5
				c-0.14-0.18-0.26-0.46-0.25-0.44c0.01,0.02,0.22,0.28,0.34,0.44c0.13,0.16,0.27,0.43,0.39,0.51c0.12,0.08,0.43,0.21,0.36,0.16
				c-0.07-0.05-0.25-0.15-0.18-0.22c0.07-0.07,0.31-0.05,0.17-0.17c-0.13-0.12-0.3-0.31-0.36-0.42c-0.14-0.26-0.38-0.98-0.38-0.98
				s0.39,0.85,0.42,0.95c0.04,0.09,0.1,0.16,0.27,0.32c0.09,0.09,0.13,0.14,0.18,0.2c0.04,0.05,0.09,0.11,0.15,0.19
				c0.14,0.18,0.38,0.41,0.38,0.41s0.13,0.12,0.22,0.11c0.1-0.01,0.12-0.07,0.12-0.07c0.11-0.14,0.02-0.35,0.02-0.35
				s0.24,0.06,0.27,0.15c0.02-0.16-0.3-0.29-0.3-0.29s0,0-0.15-0.22c-0.15-0.22-0.47-0.68-0.47-0.68s-0.12-0.52-0.19-0.66
				c-0.03-0.06-0.04-0.12-0.04-0.18c-0.01-0.08-0.04-0.23-0.03-0.36c0.01-0.2-0.15-0.74-0.05-0.57c0.1,0.17,0.19,0.72,0.19,0.63
				c0-0.09,0.04-0.35,0.05-0.43c0-0.02,0-0.03,0-0.02c0,0,0,0.01,0,0.02c0,0.08,0.03,0.38,0.1,0.52c0.07,0.17,0.17,0.39,0.33,0.51
				c0,0-0.05-0.05-0.12-0.11c0.19,0.19,0.25,0.31,0.49,0.56c0.24,0.25,0.44,0.22,0.52,0.13c0.08-0.09-0.11-0.37-0.19-0.59
				s-0.09-0.32-0.33-0.56c-0.08-0.08-0.13-0.15-0.17-0.22c-0.08-0.14-0.12-0.26-0.24-0.37c-0.19-0.16-0.58-0.37-0.86-0.56
				c-0.15-0.1-0.29-0.19-0.41-0.28c-0.12-0.08-0.22-0.17-0.31-0.27c-0.17-0.21-0.27-0.22-0.3-0.38c-0.02-0.11-0.12-0.4-0.18-0.6
				c0.05,0.25,0.1,0.32,0.1,0.32c-1.59,0.27-1.39,0.61-1.39,0.61l0.14,0.4c0,0-0.01,0-0.02,0c0.05,0.14,0.09,0.28,0.09,0.35
				c0.01,0.26,0.18,0.35,0.29,0.55c0.11,0.2,0.15,0.68,0.2,0.92c0.05,0.24,0.04,0.63,0.13,0.74c0.09,0.11,0.33,0.5,0.45,0.65
				c0.11,0.13,0.16,0.24,0.26,0.31c0.02-0.01,0.05-0.01,0.09-0.03C506.52,206.14,506.48,206.08,506.38,205.89z"
            />
            <path
              fill="#4D2C1A"
              d="M507.89,204.09c-0.06-0.14-0.09-0.44-0.1-0.52c-0.01,0.08-0.05,0.34-0.05,0.43c0,0.1-0.09-0.46-0.19-0.63
				c-0.1-0.17,0.06,0.37,0.05,0.57c-0.01,0.14,0.02,0.29,0.03,0.36c0.01,0.04,0.01,0.06,0.01,0.05c0-0.02-0.02-0.16,0.07-0.15
				c0.06,0.01,0.24,0.16,0.37,0.28c0.07,0.06,0.12,0.11,0.12,0.11C508.06,204.48,507.96,204.25,507.89,204.09z"
            />
            <path fill="#4D2C1A" d="M507.79,203.57c0-0.01,0-0.02,0-0.02C507.79,203.54,507.79,203.55,507.79,203.57z" />
            <path
              fill="#4D2C1A"
              d="M508.52,206.18c0,0,0.09,0.22-0.02,0.35c0,0,0.05,0.03,0.16-0.02c0.11-0.05,0.14-0.1,0.12-0.19
				C508.76,206.24,508.52,206.18,508.52,206.18z"
            />
            <path
              fill="#4D2C1A"
              d="M507.14,206.45c0.12-0.03,0.24,0.02,0.39,0.05c0.16,0.03,0.23,0.01,0.32-0.01s0.11-0.05,0.11-0.05
				l0.19,0.05c0,0-0.24-0.23-0.38-0.41c-0.07-0.08-0.11-0.14-0.15-0.19c-0.05-0.06-0.1-0.11-0.18-0.2
				c-0.16-0.16-0.23-0.23-0.27-0.32c-0.04-0.09-0.42-0.95-0.42-0.95s0.24,0.73,0.38,0.98c0.06,0.11,0.22,0.29,0.36,0.42
				c0.13,0.12-0.1,0.1-0.17,0.17c-0.07,0.07,0.11,0.18,0.18,0.22c0.07,0.05-0.25-0.08-0.36-0.16c-0.12-0.08-0.26-0.35-0.39-0.51
				c-0.13-0.16-0.33-0.42-0.34-0.44c-0.01-0.02,0.1,0.26,0.25,0.44c0.14,0.18,0.31,0.41,0.33,0.5s-0.13,0.16-0.22,0.2
				c-0.09,0.03-0.16-0.08-0.24-0.22c-0.07-0.14-0.36-0.65-0.66-0.83c-0.3-0.18,0.04,0.08,0.07,0.18c0.02,0.1,0.34,0.33,0.45,0.52
				c0.1,0.19,0.14,0.25,0.09,0.27c-0.03,0.01-0.07,0.02-0.09,0.03c-0.01,0-0.02,0-0.02,0s0.16,0.12,0.27,0.15c0,0,0.01,0,0.01,0
				c0.11,0.03,0.17,0.1,0.24,0.13C506.94,206.5,507.02,206.48,507.14,206.45z"
            />
            <path
              fill="#151616"
              d="M504.82,202.26c0,0-0.19-0.33,1.39-0.61c-1.57-0.17-1.95,0.97-1.95,0.97c0.06,0.16,0.6,0.06,0.68,0.04
				c0.01,0,0.02,0,0.02,0L504.82,202.26z"
            />
            <path
              fill="#9F7C63"
              d="M506.96,202.14c0.01-0.02,0.01-0.05,0.02-0.07c0.17-0.56,0.25-1.15,0.24-1.74
				c-0.01-0.82-0.2-1.63-0.1-2.44c0.12-0.96,0.62-1.87,0.53-2.84c-0.06-0.63-0.37-1.2-0.48-1.82c-0.19-1.11,0.32-2.24,0.26-3.37
				c-0.01,0.02-0.02,0.05-0.03,0.07c-0.03,0.36-0.11,0.86-0.35,1.48c-0.35,0.93-0.43,1.37-0.61,2.18c0.03,0.33,0.06,0.73,0.1,1.22
				L506.96,202.14z"
            />
            <path
              fill="#D8BFB1"
              d="M506.21,201.65c0,0-0.06-0.08-0.1-0.32c-0.07-0.37-0.12-1.12,0.07-2.61c0.15-1.15-0.06-3.69,0.08-4.25
				c0.09-0.34,0.15-0.63,0.2-0.88c0.18-0.81,0.25-1.26,0.61-2.18c0.24-0.62,0.32-1.12,0.35-1.48c0.01-0.02,0.02-0.05,0.03-0.07
				c0.05,1.13-0.45,2.25-0.26,3.37c0.1,0.62,0.41,1.2,0.48,1.82c0.09,0.96-0.41,1.88-0.53,2.84c-0.1,0.81,0.09,1.63,0.1,2.44
				c0.01,0.59-0.08,1.18-0.24,1.74c0,0.05,0,0.09,0,0.13l-0.09,0.38c0.13,0.09,0.27,0.18,0.41,0.28c0.28,0.19,0.67,0.4,0.86,0.56
				c0.13,0.11,0.16,0.23,0.24,0.37c1.41,0.13,3.46,0.29,5.26,0.29c2.57-0.9,3.58-3.47,3.22-3.12c-0.36,0.36-1.52,1.31-0.63,0.21
				c0.69-0.85,1.05-2.53,1.18-2.89c0.01-0.22,0.03-0.45,0.05-0.69c-0.07-0.06-0.15-0.11-0.22-0.16c-0.09,0.09-0.23,0.16-0.4,0.13
				c-0.08-0.02-0.22-0.06-0.36-0.11l-0.45,1.26c-0.04,0.12-0.16,0.19-0.29,0.18l-4.13-0.76c-0.16-0.01-0.28-0.16-0.26-0.33
				l1.77-7.03c0.03-0.16,0.18-0.27,0.34-0.23l4.77,1.1c0.16,0.04,0.26,0.21,0.2,0.37l-1.02,2.83c0.32,0.14,0.6,0.27,0.6,0.27
				c0.23,0.12,0.27,0.27,0.31,0.38c0.03,0.11-0.02,0.27-0.12,0.42c-0.02,0.02-0.03,0.05-0.04,0.08c0.06,0.05,0.14,0.09,0.22,0.14
				c0.21,0.01,0.46,0.12,0.44,0.64c-0.02,0.52-0.31,1.27-1,1c-0.01,0-0.02-0.01-0.02-0.01c-0.07-0.01-0.13-0.04-0.19-0.07
				c0.12,0.23,0.28,0.42,0.47,0.52c2.86,1.39,3.86-0.77,2.86-6.16c-0.68-3.68-0.78-4.93-1.23-6.28l-0.01,0.02
				c-0.04-0.58-0.29-0.98-1.74-1.67c-0.78-0.37-1.46-0.65-2.06-0.92l-3.29,2.88l-3.9-3.3c-0.91,0.42-2.2,0.99-3.25,1.74
				c-0.33,0.24-0.52,0.53-0.62,0.87l-0.01-0.01c-0.08,0.61-0.47,1.59-1.2,3.94c-0.71,2.27-1.03,3.71-0.93,4.69
				c0.09,0.92,1.38,7.82,1.52,8.54c0.01,0.04,0.01,0.07,0.01,0.07S504.64,201.48,506.21,201.65z"
            />
            <path
              fill="#151616"
              d="M518.21,197.68c-0.14,0.1-0.26,0.12-0.37,0.09c0.01,0,0.01,0.01,0.02,0.01c0.69,0.27,0.98-0.48,1-1
				c0.02-0.52-0.23-0.63-0.44-0.64C518.69,196.29,518.71,197.34,518.21,197.68z"
            />
            <path
              fill="#151616"
              d="M515.33,196.93c-0.22-0.15-0.32-0.19-0.31-0.32s0.15-0.15,0.15-0.15s-0.19-0.05-0.34-0.13
				c-0.28-0.15-0.22-0.47,0.02-0.45c0.24,0.02,1.23,0.35,1.51,0.44c0.15,0.04,0.37,0.09,0.52,0.11l0.02-0.04
				c-0.04-0.01-0.08-0.03-0.13-0.04c-0.43-0.13-1.16-0.35-1.39-0.41c-0.32-0.09-0.59-0.23-0.52-0.41c0.08-0.18,0.32-0.18,0.78-0.08
				c0.46,0.11,1.44,0.3,1.44,0.3l0.11-0.18c0,0-0.41-0.26-0.76-0.46c-0.35-0.2-0.81-0.32-0.71-0.55c0.09-0.23,0.52-0.24,0.88-0.08
				c0.18,0.08,0.54,0.24,0.86,0.38l1.02-2.83c0.06-0.16-0.04-0.33-0.2-0.37l-4.77-1.1c-0.16-0.04-0.32,0.07-0.34,0.23l-1.77,7.03
				c-0.03,0.16,0.09,0.31,0.26,0.33l4.13,0.76c0.13,0.01,0.24-0.07,0.29-0.18l0.45-1.26c-0.18-0.06-0.36-0.13-0.48-0.19
				C515.83,197.16,515.55,197.07,515.33,196.93z"
            />
            <path
              fill="#4D2C1A"
              d="M517.34,195.67c-0.12-0.08-0.15-0.1-0.15-0.1l-0.11,0.18C517.4,195.79,517.5,195.77,517.34,195.67z"
            />
            <path
              fill="#151616"
              d="M515.93,196.81c0.17,0.05,0.57,0.19,0.78,0.26s0.19,0.16,0.2,0.13s-0.08-0.13-0.22-0.18
				c-0.14-0.04-0.65-0.21-0.88-0.3c-0.22-0.1-0.64-0.25-0.64-0.25l-0.02,0.01C515.16,196.47,515.76,196.76,515.93,196.81z"
            />
            <path
              fill="#9F7C63"
              d="M518.21,197.68c0.5-0.34,0.48-1.39,0.21-1.53c-0.09-0.04-0.16-0.09-0.22-0.14
				c0.01-0.03,0.03-0.06,0.04-0.08c0.1-0.15,0.15-0.31,0.12-0.42c-0.03-0.11-0.07-0.26-0.31-0.38c0,0-0.28-0.12-0.6-0.27
				c-0.32-0.14-0.68-0.3-0.86-0.38c-0.36-0.17-0.79-0.15-0.88,0.08c-0.09,0.23,0.36,0.35,0.71,0.55c0.35,0.2,0.76,0.46,0.76,0.46
				s0.03,0.02,0.15,0.1c0.16,0.11,0.06,0.12-0.26,0.08c0,0-0.99-0.2-1.44-0.3c-0.46-0.11-0.7-0.11-0.78,0.08
				c-0.08,0.18,0.2,0.32,0.52,0.41c0.23,0.07,0.96,0.28,1.39,0.41c0.05,0.01,0.09,0.03,0.13,0.04c0.09,0.03,0.16,0.05,0.16,0.05
				c0.04,0.02-0.05,0.01-0.17,0c-0.15-0.02-0.37-0.07-0.52-0.11c-0.28-0.08-1.27-0.42-1.51-0.44c-0.24-0.02-0.3,0.31-0.02,0.45
				c0.15,0.08,0.34,0.13,0.34,0.13s0.42,0.15,0.64,0.25c0.22,0.1,0.73,0.26,0.88,0.3c0.14,0.04,0.23,0.14,0.22,0.18
				s0.02-0.06-0.2-0.13s-0.62-0.21-0.78-0.26c-0.17-0.05-0.77-0.34-0.77-0.34l0.02-0.01c0,0-0.13,0.02-0.15,0.15
				s0.09,0.18,0.31,0.32c0.22,0.15,0.49,0.23,0.7,0.34c0.12,0.06,0.3,0.13,0.48,0.19c0.14,0.05,0.28,0.09,0.36,0.11
				c0.17,0.04,0.31-0.03,0.4-0.13c0.07,0.05,0.15,0.1,0.22,0.16c0.05,0.04,0.1,0.07,0.15,0.1c0.06,0.03,0.12,0.06,0.19,0.07
				C517.95,197.8,518.07,197.78,518.21,197.68z"
            />
            <polygon
              fill="#23B8DF"
              points="515.94,183.2 515.97,183.17 515.18,182.77 512.56,185.12 509.34,182.49 509.31,182.47 
				508.67,182.72 508.75,182.78 512.65,186.08 			"
            />
            <path
              fill="#9F7C63"
              d="M516.9,200.97c0.36-0.36-0.65,2.22-3.22,3.12c-0.01,0-0.02,0.01-0.03,0.01c0.7,0,3.61,0.06,3.98-0.87
				c0.03-0.06,0.04-0.13,0.04-0.21c0-1.1-0.21-3.58-0.18-4.65c0.01-0.19-0.01-0.2-0.05-0.09c-0.12,0.36-0.49,2.04-1.18,2.89
				C515.38,202.28,516.54,201.33,516.9,200.97z"
            />
            <path
              fill="#4D2C1A"
              d="M513.61,180.4c0.38-0.05,0.52-0.27,0.52-0.27s0.01-0.01-0.25-0.07c-0.26-0.05-0.24,0.01-0.52-0.04
				s-0.37-0.01-0.75,0.07C512.6,180.1,513.23,180.45,513.61,180.4z"
            />
            <path
              fill="#151616"
              d="M512.78,177.41c-0.25-0.02-0.66,0-0.81,0.03c-0.15,0.03-0.27,0.24-0.27,0.24s0.16-0.12,0.41-0.12
				c0.35,0,0.42-0.05,0.6-0.02c0.18,0.03,0.26,0.11,0.31,0.1C513.1,177.63,513.03,177.43,512.78,177.41z"
            />
            <path
              fill="#151616"
              d="M514.55,177.46c-0.2,0.01-0.36-0.01-0.46,0.02c-0.1,0.03-0.3,0.15-0.3,0.15s0.01,0,0.01,0
				c-0.14,0.07-0.28,0.22-0.28,0.5c0,0.24,0.14,0.55,0.19,0.76c0.05,0.21-0.14,0.18-0.25,0.33c-0.11,0.15,0.07,0.25,0.07,0.25
				s-0.19-0.21,0.07-0.26c0.02,0,0.04-0.01,0.06-0.02c0.01,0.02,0.04,0.03,0.07,0.02c0.05-0.01,0.15-0.01,0.12-0.02
				c-0.02-0.01-0.07-0.03-0.11-0.03c0.1-0.07,0.08-0.19,0.05-0.29c-0.04-0.13-0.09-0.35-0.14-0.64c-0.06-0.34,0.16-0.49,0.34-0.55
				l0,0c0.07-0.01,0.34-0.04,0.6-0.01c0.14,0.02,0.18,0.03,0.19,0.05c0,0,0,0,0,0.01c0.02-0.03,0.07-0.11,0.01-0.19
				c0,0,0-0.01-0.01-0.01C514.71,177.44,514.75,177.45,514.55,177.46z"
            />
            <polygon fill="#151616" points="514.76,177.73 514.77,177.73 514.77,177.72 514.77,177.72 			" />
            <path
              fill="#151616"
              d="M512.58,178.12c-0.24,0.03-0.48-0.02-0.64-0.01c-0.11,0,0.18,0.05,0.33,0.1
				c0.23,0.08,0.28,0.03,0.49-0.03c0.21-0.06,0.24-0.07,0.24-0.07S512.87,178.08,512.58,178.12z"
            />
            <path
              fill="#151616"
              d="M514.18,178.28c0.1,0.01,0.17,0.01,0.26-0.04c0.09-0.05,0.17-0.1,0.17-0.1
				c-0.08,0.01-0.12,0.03-0.21,0.05c-0.07,0.01-0.2,0.02-0.27,0.01c-0.14-0.02-0.42-0.02-0.28,0
				C513.94,178.2,514.08,178.26,514.18,178.28z"
            />
            <path
              fill="#151616"
              d="M513.22,179.18c-0.02-0.01-0.08-0.01-0.18,0.03c0,0,0.05,0.01,0.14,0.01
				C513.26,179.22,513.26,179.19,513.22,179.18z"
            />
          </g>
          <path
            fill="#9F7C63"
            d="M525.68,254.21c0-0.07,0.01-0.13,0.01-0.19C525.68,254.09,525.68,254.15,525.68,254.21z"
          />
          <path
            fill="#9F7C63"
            d="M525.57,255.63c0,0.04,0.01,0.08,0.03,0.11c-0.01-0.07-0.02-0.18-0.02-0.31
			C525.57,255.5,525.57,255.57,525.57,255.63z"
          />
          <path
            opacity="0.3"
            d="M669.91,322.49c-5.87-0.84-55.57-8.31-84.23-12.53c-8.11-1.19-13.73-1.92-17.64-2.09
			c-0.42,0.42-1.02,0.71-1.95,0.75c-1.38,0.05-4.35-0.12-4.35-0.12s11.64,3.58,3.43,3.88c-3.99,0.15-16.99-1.24-24.35-1.75
			c-2.74-0.19-9.24-0.55-10.67,0.04c-2.48,1.03-1.88,1.01,35.37,7.33c27.94,4.74,75.9,14.34,94.35,15.29
			C679.93,334.31,687.05,324.94,669.91,322.49z"
          />
          <path
            opacity="0.3"
            d="M635.75,291.8c-4.34-0.37-23.53-2.4-32.17-2.92c0.47,0.39,1.03,0.8,1.56,1.1c1.32,0.73,1.37,1.27,1.17,1.81
			c-0.2,0.54-1.57,0.78-3.67,0.29c-2.11-0.49-2.2-1.17-2.79-1.13c-0.59,0.05-1.96-0.44-2.3-1.22c0,0,0-0.03-0.01-0.09
			c-0.6,0.37-1.15,0.75-1.73,0.86c-0.56,0.1-1.08,0.17-1.57,0.21c-0.07,0.61-0.43,1.16-1.44,1.22c-1.55,0.09-2.56,0.32-3.2-0.36
			c-2.25,2.78,50.7,8.14,69.16,10.36C693.88,306.16,721.25,299.06,635.75,291.8z"
          />
          <g>
            <path
              fill="#9F7C63"
              d="M527.75,298.34c-0.26,1.04-0.72,2.44-1.38,3.43c0.04-0.02,0.06-0.03,0.06-0.03s-0.06,3.88,4.08,1.74
				c0.26-1.13,0.72-2.95,1.25-4.45C530.51,299.15,529.89,297.07,527.75,298.34z"
            />
            <path
              fill="#9F7C63"
              d="M567.85,296.45c-0.7-0.8-1.97-2.46-2.95-4.8c-0.22,0.63-1.83,0.01-2.42,0.56
				c-0.59,0.55-0.16,2.28-0.85,2.7l3.48,5.32C568.84,301.35,567.83,296.84,567.85,296.45z"
            />
            <path
              fill="#D8BFB1"
              d="M560.51,281.1c0.2,0.38,0.32,0.62,0.32,0.62s-4.02-8.5-4.77-14.89c-0.01-0.11-0.02-0.21-0.03-0.32
				c-0.01-0.12-0.02-0.23-0.03-0.35c0-0.03-0.01-0.06-0.01-0.09c-0.02-0.21-0.03-0.41-0.04-0.61c-0.01-0.17-0.02-0.2-0.03-0.12
				c-0.3-3.87-0.12-7.28-0.17-8.95c-0.1-3.75-0.18-5.98-0.66-7.75c0.44-0.33,0.81-0.68,1.06-1.01c0,0-0.75-2.54-1.56-5.52
				c-0.58,0.1-1.11,0.2-1.58,0.3l0.01,0.04l0.04,0.1c0,0-0.01,0-0.01,0l0.21,0.6l-8.78,4.11c0,0-5.99,3.49-7.22,2.92
				c-1.41,3.98-3.12,8.99-4.58,13.78c-3.9,12.76-7.09,33.5-7.09,33.5s0.91,0.45,2.13,0.87c1.22,0.42,2.76,0.81,4.01,0.7
				c0.37-0.03,0.72-0.11,1.03-0.24c0.59-3.71,3.61-7.85,6.07-16.72c0.51-1.85,0.99-3.33,1.48-4.71c-0.16,0.84-0.27,1.3-0.27,1.3
				c2.15-6.06,3.63-8.93,3.63-8.93s-0.02-0.16-0.08-0.48c0-0.01,0.01-0.02,0.01-0.03c1.9,4.72,4.25,9.24,5.54,12.23
				c1.73,4.04,4.22,6.4,6.67,10.44c0.62,1.02,0.33,0.55,2.9,4.5c0,0,1.4-0.57,2.88-1.48c1.38-0.85,2.82-2,3.27-3.26
				c0.07-0.19,0.11-0.38,0.13-0.57C565.03,291.07,562.66,286,560.51,281.1z"
            />
            <path fill="none" d="M535.69,218.75l0.05,0.3C535.71,218.86,535.69,218.75,535.69,218.75z" />
            <path
              fill="none"
              d="M541.38,209.58c-0.3-0.16-0.63-0.17-0.89-0.11C540.73,209.42,541.05,209.43,541.38,209.58z"
            />
            <path
              fill="none"
              d="M552.21,230.1c0,0.47,0.53,4.16,0.53,4.16c0.14,0.96,0.44,2.31,0.8,3.79l2.48-1.45
				c-1.45-2.75-1.7-3.28-1.7-3.28c0.01,0,0.03-0.01,0.04-0.01c0.07-0.02,0.23-0.05,0.44-0.12c-0.37,0.09-0.75,0.18-1.16,0.25
				c0,0-0.01-0.01-0.01-0.01c0.04,0.09,0.06,0.14,0.06,0.14C553.08,232.95,552.21,230.1,552.21,230.1z"
            />
            <path
              opacity="0.3"
              d="M537.77,241.94c0.22,0.01,0.43,0.02,0.64,0.03c0.01-0.53,0-1.05-0.03-1.55
				C538.24,240.86,538.05,241.35,537.77,241.94z"
            />
            <path
              fill="#9F7C63"
              d="M546.38,212.67c0.11,0.31,0.22,0.61,0.34,0.88c1.32,2.96,4.35,1.32,4.35,1.32s-2.27-1.76-1.2-5.29
				c1.07-3.53,0.66-7.47-1.76-8.31c-1.32-0.46-2.46-0.2-3.78,0.36c-0.74-0.19-1.56-0.24-2.4-0.05c-0.02,0-0.04,0.01-0.06,0.01
				c-0.01,0-0.01,0-0.02,0c-0.38,0.05-0.77,0.15-1.17,0.31c-1.36,0.41-2.03,0.8-2.03,0.8s-2.08,1.06-2.31,3.52
				c-0.19,2.04,0.6,2.39,0.87,2.46c0.06,0.18,0.13,0.34,0.19,0.48c0.06,0.13,0.1,0.28,0.13,0.43c-0.02,0.16-0.02,0.23-0.01,0.31
				c0-0.01-0.01-0.03-0.02-0.04l0.02,0.04c0,0,0,0.01,0,0.01c0.01,0.09-0.08,0.18-0.34,0.16c-0.31-0.03,0.04,0.03,0.2,0.07
				c0.07,0.02,0.14,0.04,0.19,0.07c0,0.07,0,0.14-0.01,0.21c-0.01,0.27-0.03,0.56-0.04,0.85c-0.03,0.32-0.04,0.67,0.06,1.07
				c0,0,0,0,0,0c0.08,0.42,0.22,0.84,0.51,1.24c0.07,0.1,0.13,0.2,0.19,0.3c0.01,0.04,0.03,0.13,0.06,0.19
				c0.04,0.08,0.08,0.15,0.08,0.2c0.01,0.04,0.01,0.2,0.16,0.28c0.42,1.09,0.46,2,2.02,1.75c-0.28,0.26-0.72,0.56-1.38,0.9
				c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02,0.7,0.08,3.4,2.98,2.85c2.99-0.57,5.09-3.06,5.48-3.56c-0.08-0.03-0.17-0.07-0.27-0.11
				c-1.24-1.26-1.38-2.4-1.13-3.45C546.27,212.88,546.32,212.79,546.38,212.67z"
            />
            <path
              fill="#9F7C63"
              d="M541.88,264.24c-0.09-0.3-0.16-0.62-0.23-0.94c-0.04,6.51-0.86,11.74-1.3,14.06
				c-0.16,0.84-0.27,1.3-0.27,1.3c2.15-6.06,3.63-8.93,3.63-8.93s-0.02-0.16-0.08-0.48c-0.01-0.03-0.01-0.07-0.02-0.1
				C542.95,267.51,542.36,265.86,541.88,264.24z"
            />
            <path
              fill="#9F7C63"
              d="M537.98,237.21c0.17,1.05,0.29,1.98,0.29,1.98s-0.71-1.81-1.16-3.62c-0.62-2.48-1.66-3.36-2.27-3.95
				c0.19,0.08,0.39,0.15,0.58,0.21c-0.38-0.13-0.71-0.29-0.98-0.44c0.17,1.3,0.33,2.75,0.33,3.63c0,0.48-0.02,0.87-0.08,1.23
				c-0.17,1.06-0.63,1.9-1.69,4.23c-0.16,0.36-0.31,0.74-0.46,1.14c-0.8,2.15-1.54,4.92-3.48,8.22c0,0,0,0,0,0l-0.23,0.25
				c-0.16,0.17-0.38,0.4-0.59,0.59c-0.12,0.1-0.28,0.21-0.48,0.3c-0.22,0.11-0.4,0.19-0.62,0.31c-0.14,0.07-0.28,0.15-0.42,0.26
				c0,0-0.01,0-0.01,0.01c-0.22,0.15-0.33,0.28-0.43,0.41c-0.31,0.3-0.5,0.46-0.62,0.55c-0.02,0.02-0.04,0.04-0.07,0.08
				c-0.11,0.13-0.11,0.21-0.2,0.33c-0.13,0.19-0.23,0.46-0.46,0.68c-0.05,0.04-0.08,0.07-0.1,0.11c-0.08,0.11-0.05,0.16-0.04,0.17
				c0.03,0.13,0.17,0.23,0.34,0.22c0.15-0.01,0.36-0.1,0.56-0.21c0.17-0.1,0.34-0.22,0.44-0.32c0.22-0.22,0.29-0.39,0.49-0.46
				c0,0,0,0.01,0,0.01c0.25-0.24,0.39-0.34,1.07-0.37c0.85-0.04,1.4-0.36,1.83-0.85c0,0-0.49,0.97-1.5,1.36
				c-1.13,0.45-1.46,0.26-1.7,0.51c-0.05,0.09-0.11,0.19-0.17,0.27c-0.09,0.12-0.17,0.28-0.23,0.45l0,0.01
				c-0.01,0.03-0.03,0.07-0.04,0.1c0-0.01,0-0.02,0-0.02c-0.01,0.04-0.03,0.07-0.04,0.11c0,0,0,0,0,0.01c0,0.18-0.19,0.74,0.02,0.89
				c0.21,0.16,0.13,0.2,0.46,0.35c0.22,0.1,0.41,0.14,0.6,0.1c0.1-0.04,0.19-0.08,0.23-0.09c0.02-0.02,0.05-0.03,0.07-0.05
				l0.37,0.33l0.03,0.03c0,0,0.25-0.05,0.56-0.17c0.31-0.12,0.61-0.39,0.89-0.6c0.27-0.21,0.25-0.09,0.25-0.09s-0.01,0.07-0.15,0.2
				c-0.15,0.12-0.86,0.65-0.86,0.65s0.09,0.34,0.29,0.31c0.2-0.04,0.43-0.1,0.66-0.27c0.23-0.17,0.63-0.64,0.63-0.64l-0.01-0.18
				c0,0,0.1-0.31,0.19-0.54c0.09-0.22,0.42-0.84,0.54-1.51c0.11-0.64,0.4-1.66,0.65-2.07c1.11-1.22,3.22-3.68,4.99-6.6
				c0.68-1.13,1.18-2.04,1.54-2.8c0.28-0.59,0.47-1.08,0.61-1.52C538.31,239.26,538.16,238.18,537.98,237.21z M526.58,255.55
				c0,0,0,0.01-0.01,0.01c0.01-0.16,0.02-0.35,0.02-0.35c0.02-0.09,0.06-0.2,0.13-0.25c0.18-0.15,0.37-0.1,0.8-0.35
				c0.43-0.25,0.58-0.35,0.82-0.17c0,0-0.52,0.02-0.8,0.35S526.67,255.33,526.58,255.55z M529.18,254.99
				c-0.06-0.07-0.22-0.17-0.59,0.03c-0.49,0.27-0.66,0.51-0.96,0.59c-0.02,0-0.04,0.01-0.05,0.02c0.25-0.21,0.49-0.44,0.66-0.63
				c0.11-0.07,0.25-0.14,0.37-0.18C528.86,254.73,529.06,254.82,529.18,254.99c0.02,0.02,0.03,0.04,0.03,0.04
				C529.2,255.01,529.19,255,529.18,254.99z"
            />
            <path
              fill="#4D2C1A"
              d="M526.43,301.75c0,0-0.03,0.01-0.06,0.03c-0.35,0.14-2.21,0.87-3.23,0.93c-1.07,0.06-3.35,0.04-5.26-0.22
				c-1.8-0.25-1.39,1.9-1.39,1.9c0.69,0.36,0.97,0.45,3.43,1.23c2.14,0.67,7.86,2.29,9.32,2.7c0.2,0.06,0.42-0.03,0.51-0.22
				c1.06-2.13,0.9-4.6,0.9-4.6c-0.05,0-0.09,0-0.13,0C527.43,303.49,526.43,301.75,526.43,301.75z"
            />
            <path
              fill="#4D2C1A"
              d="M571.44,299.9c-1.04-1.28-1.52-2.07-3.59-3.52c0,0,0,0.03,0,0.06c-0.02,0.39-0.23,2.78-2.74,3.78
				c-0.02,0.01-0.04,0.02-0.06,0.02c0,0,1.14,2.03-0.78,5.06c-1.2,1.9-3.2,0.21-2.55,3.18c0,0,2.97,0.18,4.35,0.12
				c0.93-0.03,1.53-0.32,1.95-0.75c0.56-0.56,0.81-1.37,1.09-2.13C570.08,303.1,571.95,300.54,571.44,299.9z"
            />
            <path fill="#9F7C63" d="M528.87,256.66c0,0-0.01,0-0.03,0.01C528.86,256.67,528.87,256.66,528.87,256.66z" />
            <path
              fill="#9F7C63"
              d="M527.63,256.68c0-0.05-0.02-0.13-0.05-0.2C527.58,256.56,527.6,256.63,527.63,256.68z"
            />
            <path
              fill="#9F7C63"
              d="M526.78,256.16c0.04,0,0.07-0.02,0.12-0.04c0.07-0.04,0.15-0.09,0.23-0.15
				c-0.07,0.04-0.15,0.07-0.22,0.08c-0.08,0.03-0.17,0.06-0.26,0.08C526.69,256.16,526.73,256.17,526.78,256.16
				c0.11,0.1,0.27,0.19,0.39,0.25c0,0,0.01,0,0.01,0c-0.03-0.02-0.07-0.05-0.12-0.08C527,256.29,526.87,256.25,526.78,256.16z"
            />
            <path
              fill="#9F7C63"
              d="M527.32,256.43c0.07-0.02,0.14-0.06,0.18-0.1c0,0,0,0,0,0S527.42,256.39,527.32,256.43z"
            />
            <path
              fill="#9F7C63"
              d="M529.32,256.36c0,0,0.12,0.05,0.21,0.18c0.09,0.13,0.09,0.24,0.29,0.25c0.17-0.03,0.34-0.42,0.46-0.56
				c0.08-0.1,0.13-0.26,0.11-0.41c0.02,0.03,0.03,0.06,0.03,0.06s0.08-0.11-0.12-0.25c-0.06-0.06-0.13-0.07-0.21-0.06
				c-0.13,0-0.23,0.07-0.23,0.07s-0.02,0.12-0.14,0.25C529.61,256.02,529.32,256.36,529.32,256.36z"
            />
            <path
              fill="#9F7C63"
              d="M529.18,256.85c0.03,0,0.07,0,0.1-0.01c0.01,0,0.02,0,0.03,0c-0.03,0-0.07,0.01-0.11,0.01
				C529.19,256.85,529.18,256.85,529.18,256.85z"
            />
            <path fill="#4D2C1A" d="M529.21,255.03c0,0-0.01-0.02-0.03-0.04C529.19,255,529.2,255.01,529.21,255.03z" />
            <path
              fill="#4D2C1A"
              d="M528.61,254.81c-0.12,0.04-0.25,0.11-0.37,0.18c-0.17,0.19-0.41,0.42-0.66,0.63
				c0.02-0.01,0.04-0.01,0.05-0.02c0.3-0.08,0.47-0.32,0.96-0.59c0.36-0.2,0.53-0.1,0.59-0.03
				C529.06,254.82,528.86,254.73,528.61,254.81z"
            />
            <path
              fill="#4D2C1A"
              d="M529.24,256.29c-0.23,0.17-0.46,0.23-0.66,0.27c-0.2,0.04-0.29-0.31-0.29-0.31s0.71-0.52,0.86-0.65
				c0.15-0.12,0.15-0.2,0.15-0.2s0.02-0.12-0.25,0.09c-0.27,0.21-0.58,0.47-0.89,0.6c-0.31,0.12-0.56,0.17-0.56,0.17l-0.03-0.03
				l-0.37-0.33c-0.02,0.02-0.05,0.03-0.07,0.05c-0.04,0.02-0.13,0.05-0.23,0.09c0.07-0.01,0.15-0.04,0.22-0.08
				c-0.08,0.06-0.16,0.11-0.23,0.15c-0.04,0.02-0.08,0.04-0.12,0.04c0.09,0.08,0.21,0.13,0.28,0.18c0.05,0.03,0.08,0.06,0.12,0.08
				c0.01,0.01,0.03,0.01,0.05,0.02c0,0,0.01,0,0.01,0c0.01,0,0.03,0,0.05,0c0.01,0,0.03,0,0.04-0.01c0,0,0,0,0,0
				c0.1-0.04,0.18-0.1,0.18-0.1s0,0,0,0c0.01,0.01,0.04,0.08,0.07,0.15c0.03,0.07,0.06,0.15,0.05,0.2c-0.01,0.11,0.24,0.2,0.4,0.2
				c0.16,0,0.43-0.04,0.55-0.1c0.09-0.04,0.21-0.09,0.26-0.11c0.02-0.01,0.03-0.01,0.03-0.01s0.12,0.2,0.31,0.19
				c0.01,0,0.01,0,0.02,0c0.04,0,0.07-0.01,0.11-0.01c0.15-0.01,0.26-0.01,0.26-0.01l0.26-0.02c-0.2-0.01-0.2-0.12-0.29-0.25
				c-0.09-0.13-0.21-0.18-0.21-0.18s0.29-0.34,0.41-0.47c0.12-0.13,0.14-0.25,0.14-0.25S529.47,256.11,529.24,256.29z"
            />
            <path
              fill="#FFFFFF"
              d="M558.23,230.83c-0.01,0.01-0.01,0.03-0.02,0.05c-0.01,0.03-0.03,0.07-0.05,0.12
				c-0.02,0.04-0.04,0.09-0.06,0.14C558.15,231.03,558.19,230.92,558.23,230.83z"
            />
            <path
              fill="#FFFFFF"
              d="M551.86,236.74c-0.28,0.2-0.5,0.4-0.65,0.56l0.24,0.67C552.06,236.98,552.34,236.39,551.86,236.74z"
            />
            <path
              fill="#FFFFFF"
              d="M551.72,250.31c0.2-0.26,0.49-0.63,0.92-1.18c1.36-1.74,1.84-3,1.74-4.36c-0.02-0.23-0.05-0.52-0.1-0.85
				l-9.22,4.62l-0.57-1.28l-0.01,0l-0.41-0.93c-0.18,0.08-0.42,0.16-0.63,0.16c-0.28,0-0.44-0.15-0.51-0.25
				c-0.08-0.03-0.15-0.09-0.2-0.19c-0.12-0.22-0.09-0.4-0.04-0.51c0,0-0.01,0.01-0.01,0.01l0.07-0.14
				c-0.11-0.01-0.74-0.06-0.92-0.33c-0.2-0.29,0.15-0.56,0.15-0.56s0,0-0.01,0c0,0,0,0,0,0l-0.01,0c-0.08-0.01-0.41-0.05-0.59-0.35
				c-0.15-0.24-0.03-0.51,0.06-0.65c0,0,0,0,0,0l0.01-0.01c0.03-0.05,0.06-0.09,0.06-0.09s-0.38-0.14-0.51-0.42
				c-0.14-0.28,0.15-0.86,0.7-1.22c0.1-0.06,0.2-0.13,0.3-0.19l-3.53-8.03l-0.17-0.33c-0.05-0.12,0.01-0.25,0.12-0.3l9.87-3.92
				l0.04,0.12c-1.85,0.75-9.26,3.74-9.61,3.88c-0.34,0.14-0.17,0.4,0.12,0.28c0.25-0.1,7.66-3.11,9.58-3.89l2.75,7.78
				c0.13-0.2,0.3-0.5,0.54-0.92c1.16-2.03-0.05-2.73-0.24-5.15c-0.19-2.42-0.29-5.03,0.19-3.19c0.43,1.63,1.68,4.79,1.96,5.5
				c0,0,0.01,0.01,0.01,0.01c0.41-0.08,0.8-0.16,1.16-0.25c0.89-0.25,2.74-0.93,3.35-2.2c0.02-0.04,0.04-0.08,0.05-0.12
				c0.01-0.03,0.02-0.05,0.03-0.08c0.18-0.51,0.12-0.89,0.12-0.89s0,0.01,0,0.02c-0.02-0.23-0.06-0.47-0.13-0.73
				c-2.33-4.91-2.06-8.75-5.23-10.17c-3.17-1.43-5.31-2.59-5.31-2.59s-0.02,0.03-0.07,0.09c-0.39,0.49-2.48,2.99-5.48,3.56
				c-2.9,0.55-3-2.15-2.98-2.85c0-0.1,0.01-0.16,0.01-0.16l-3.49,1.69l0,0c-2.5,0.63-1.43,8.85-2.14,12.03c0,0,0.32,0.29,0.88,0.61
				c0.27,0.15,0.6,0.31,0.98,0.44c0.06,0.02,0.12,0.04,0.18,0.06c0.67-0.92,0.9-3.77,0.9-3.77c-0.1,2.32,0.35,4.03,0.35,4.03
				s0.3,1.56,0.69,2.92c0.17,0.57,0.32,1.39,0.45,2.15c0.18,0.97,0.33,2.05,0.4,3.21c0.03,0.5,0.04,1.02,0.03,1.55
				c-0.01,0.74-0.06,1.5-0.17,2.28c-0.69,4.98-1.33,5.63-1.17,5.84c0,0,0.07,0.04,0.21,0.1
				C538.51,250.77,544.86,253.39,551.72,250.31z"
            />
            <path
              fill="#D8BFB1"
              d="M543.67,246.2c-0.22,0.03-0.51,0.15-0.73,0.06c0.07,0.1,0.23,0.25,0.51,0.25c0.21,0,0.45-0.08,0.63-0.16
				l-0.12-0.27C543.86,246.13,543.77,246.18,543.67,246.2z"
            />
            <path
              fill="#D8BFB1"
              d="M551.86,236.74c0.48-0.35,0.19,0.25-0.42,1.23l0.36,1.01l0.92-0.45l0.82-0.48
				c-0.36-1.48-0.66-2.84-0.8-3.79c0,0-0.53-3.68-0.53-4.16c0,0,0.87,2.85,1.48,3.48c0,0-0.02-0.05-0.06-0.14
				c-0.28-0.71-1.53-3.87-1.96-5.5c-0.48-1.84-0.39,0.77-0.19,3.19c0.19,2.42,1.4,3.12,0.24,5.15c-0.24,0.42-0.42,0.72-0.54,0.92
				l0.04,0.1C551.36,237.14,551.58,236.94,551.86,236.74z"
            />
            <path
              fill="#9F7C63"
              d="M541.92,201.57c-0.41,0.05-0.82,0.16-1.25,0.33c0.35-0.11,0.75-0.21,1.19-0.32
				C541.89,201.58,541.91,201.58,541.92,201.57z"
            />
            <path
              fill="#9F7C63"
              d="M537.56,210.41c-0.01,0.27-0.03,0.56-0.04,0.85c0.02-0.18,0.03-0.34,0.03-0.49
				C537.55,210.67,537.55,210.55,537.56,210.41z"
            />
            <path
              fill="#4D2C1A"
              d="M537.4,204.98c0,0-0.31,2.25,1.02,3.66c0,0,0.72,0.15,1.57,0.24c-0.22,0.13-0.23,0.29,0.01,0.31
				c0,0,0.27-0.13,0.69-0.25c0.38,0.02,0.76,0.03,1.09-0.01c0.03,0.01,0.05,0.01,0.05,0.01s-0.01-0.01-0.01-0.01
				c0.21-0.02,0.39-0.06,0.55-0.11c0,0,0.19-0.38,0.26-0.8c0.14,0.54,0.39,1.24,0.71,1.78c0.69,1.15,0.79-1.35,1.76-1.54
				c0.97-0.19,1.43,0.3,0.85,1.89c-0.55,1.51,0.22,2.93,0.22,2.93s0.03-0.05,0.07-0.13c0.04-0.07,0.09-0.17,0.15-0.29
				c0.11,0.31,0.22,0.61,0.34,0.88c1.32,2.96,4.35,1.32,4.35,1.32s-2.27-1.76-1.2-5.29c1.07-3.53,0.66-7.47-1.76-8.31
				c-1.32-0.46-2.46-0.2-3.78,0.36c-0.74-0.19-1.56-0.24-2.4-0.05c-0.02,0-0.04,0.01-0.06,0.01c-0.44,0.1-0.84,0.21-1.19,0.32
				c-1.36,0.41-2.03,0.8-2.03,0.8s-2.08,1.06-2.31,3.52c-0.19,2.04,0.6,2.39,0.87,2.46c0.06,0.01,0.09,0.01,0.09,0.01
				S536.81,205.85,537.4,204.98z"
            />
            <path
              fill="#4D2C1A"
              d="M528.34,254.44c-0.23-0.18-0.38-0.08-0.82,0.17c-0.43,0.25-0.62,0.2-0.8,0.35
				c-0.07,0.06-0.11,0.16-0.13,0.25c0,0.01-0.01,0.19-0.02,0.35c0,0,0-0.01,0.01-0.01c0.09-0.22,0.68-0.43,0.96-0.76
				S528.34,254.44,528.34,254.44z"
            />
            <path
              fill="#4D2C1A"
              d="M526.32,255.95c-0.33-0.15-0.25-0.19-0.46-0.35c-0.21-0.16-0.02-0.71-0.02-0.89c0,0,0,0,0-0.01
				c0.01-0.04,0.02-0.07,0.04-0.11c0,0.01,0,0.02,0,0.02c0.01-0.03,0.02-0.07,0.04-0.1l0-0.01c0.07-0.17,0.15-0.33,0.23-0.45
				c0.06-0.09,0.12-0.18,0.17-0.27c0.24-0.25,0.57-0.07,1.7-0.51c1-0.4,1.5-1.36,1.5-1.36c-0.43,0.49-0.98,0.81-1.83,0.85
				c-0.68,0.03-0.82,0.13-1.07,0.37c0,0,0-0.01,0-0.01c-0.2,0.07-0.27,0.24-0.49,0.46c-0.1,0.1-0.27,0.22-0.44,0.32
				c0,0.03,0,0.07-0.01,0.12c0,0.05-0.01,0.12-0.01,0.19c-0.01,0.22-0.04,0.49-0.07,0.75c-0.02,0.18-0.03,0.34-0.03,0.47
				c0,0.13,0.01,0.24,0.02,0.31c0.01,0.07,0.02,0.11,0.02,0.11s0.06,0.05,0.36,0.16c0.29,0.11,0.31,0.18,0.53,0.16
				c0.04,0,0.1-0.02,0.16-0.03c0.08-0.02,0.17-0.05,0.26-0.08C526.73,256.09,526.54,256.05,526.32,255.95z"
            />
            <path
              fill="#4D2C1A"
              d="M541.91,216.45c1.67-0.87,1.75-2.27,1.75-2.27s-0.52,1.23-1.14,1.54c-0.62,0.3-1.79,0.53-1.79,0.53
				l-1.3,0.89C539.44,217.14,540.24,217.32,541.91,216.45z"
            />
            <path
              fill="#D8BFB1"
              d="M536.49,228.12c0,0-0.23,2.85-0.9,3.77c0.67,0.19,1.24,0.26,1.24,0.26S536.39,230.44,536.49,228.12z"
            />
            <path
              fill="#4D2C1A"
              d="M537.1,235.57c0.45,1.81,1.16,3.62,1.16,3.62s-0.11-0.93-0.29-1.98c-0.12-0.76-0.28-1.57-0.45-2.15
				c-0.4-1.36-0.69-2.92-0.69-2.92s-0.57-0.07-1.24-0.26c-0.06-0.02-0.12-0.04-0.18-0.06c-0.19-0.06-0.39-0.13-0.58-0.21
				C535.44,232.21,536.48,233.09,537.1,235.57z"
            />
            <path
              fill="#23B8DF"
              d="M543.65,240.59L543.65,240.59c0,0,0.12-0.14-0.24-0.23c-0.36-0.1-0.88-0.4-1.25-0.53
				c-0.08-0.02-0.14-0.04-0.2-0.08c-0.04-0.02-0.06-0.03-0.05-0.03c-0.32-0.23-0.16-0.68-0.01-0.68c0.25,0.01,0.31,0.11,1.05,0.23
				c0.74,0.12,0.79,0.05,1.42,0.19c0.7,0.16,0.58,0.06,1.23,0.32c0.61,0.24,0.88,0.12,1.7,0.68c0.27,0.18,2-0.32,2.31-0.41
				l2.18-1.07l-0.36-1.01l-0.24-0.67l-0.04-0.1l-2.75-7.78c-1.93,0.78-9.33,3.79-9.58,3.89c-0.29,0.12-0.46-0.14-0.12-0.28
				c0.35-0.15,7.75-3.14,9.61-3.88l-0.04-0.12l-9.87,3.92c-0.12,0.05-0.17,0.18-0.12,0.3l0.17,0.33l3.53,8.03
				c0.46-0.29,0.93-0.57,1.38-0.82C543.52,240.71,543.6,240.65,543.65,240.59z"
            />
            <polygon fill="#23B8DF" points="553.03,242.45 553.01,242.42 553.01,242.42 			" />
            <path
              fill="#23B8DF"
              d="M546.9,244.8c-0.69,0.5-0.83,0.64-1.63,1.08c-0.8,0.43-1.16,0.5-1.16,0.5l-0.13-0.29
				c0,0,0.01-0.01,0.02-0.03c-0.01,0.01-0.02,0.01-0.04,0.02l0.12,0.27l0.41,0.93l0.01,0l8.78-4.11l-0.21-0.6
				c-0.09,0.03-2.82,0.97-3.83,1.1C548.22,243.78,547.59,244.3,546.9,244.8z"
            />
            <path
              fill="#42637C"
              d="M550.34,243.05c-0.07,0.03-0.13,0.05-0.2,0.08c-0.6,0.25-1.94,0.34-2.87,0.97s-2.01,1.39-2.55,1.56
				c-0.34,0.11-0.55,0.27-0.73,0.39c-0.01,0.02-0.02,0.03-0.02,0.03l0.13,0.29c0,0,0.36-0.06,1.16-0.5c0.8-0.43,0.94-0.58,1.63-1.08
				s1.32-1.02,2.34-1.15c1-0.13,3.73-1.07,3.83-1.1c0,0,0.01,0,0.01,0l-0.04-0.1l-0.02-0.04
				C551.73,242.68,550.86,242.9,550.34,243.05z"
            />
            <path
              fill="#9F7C63"
              d="M542.17,239.83c-0.09-0.03-0.16-0.06-0.2-0.08C542.02,239.79,542.09,239.82,542.17,239.83z"
            />
            <path
              fill="#4D2C1A"
              d="M541.97,239.76c0.04,0.02,0.11,0.04,0.2,0.08c0.37,0.12,0.89,0.43,1.25,0.53
				c0.36,0.1,0.24,0.23,0.24,0.23l0,0c0,0,0.37-0.21,0.69-0.35c0.32-0.14,0.48-0.12,0.48-0.12c-0.36-0.08-0.68,0.17-1.24,0.07
				c-0.56-0.1-0.94-0.28-1.39-0.41c-0.17-0.05-0.25-0.07-0.27-0.06C541.91,239.73,541.92,239.74,541.97,239.76z"
            />
            <path
              fill="#4D2C1A"
              d="M542.55,242.41c-0.04,0.32-0.21,0.49-0.54,0.71c-0.34,0.22-0.5,0.32-0.59,0.28l0.09,0.04l-0.06,0.09
				l-0.01,0.01c0,0,0,0,0,0c0.01-0.01,0.42-0.27,0.8-0.51C542.62,242.79,542.55,242.41,542.55,242.41z"
            />
            <path
              fill="#4D2C1A"
              d="M543.09,243.91c0,0-0.71,0.35-0.87,0.45c-0.16,0.09-0.31,0.16-0.39,0.15l0.14,0.03l0.01,0c0,0,0,0,0,0
				c0.01,0,0.12-0.07,0.26-0.15C542.39,244.31,543.09,243.91,543.09,243.91z"
            />
            <path
              fill="#4D2C1A"
              d="M543.46,245.15c0.31-0.12,0.38-0.07,0.38-0.07s0.02-0.02-0.11-0.04c-0.13-0.03-0.5,0.18-0.66,0.25
				c-0.17,0.07-0.29,0.15-0.39,0.14l0.08,0l0,0.01l-0.07,0.14c0,0,0.01-0.01,0.01-0.01C542.79,245.41,543.17,245.26,543.46,245.15z"
            />
            <path fill="#9F7C63" d="M554.32,233.32c0,0,0.01,0,0.04-0.01C554.35,233.31,554.34,233.31,554.32,233.32z" />
            <path
              fill="#9F7C63"
              d="M558.24,230.79C558.24,230.79,558.24,230.79,558.24,230.79C558.24,230.79,558.24,230.79,558.24,230.79
				c0,0.01-0.01,0.02-0.01,0.03c-0.04,0.1-0.08,0.2-0.14,0.31c-0.34,0.71-1.3,2.37-3.15,3.32l-0.62-1.14c0,0,0.25,0.54,1.7,3.28
				l-2.48,1.45l-0.82,0.48l-0.92,0.45l-2.18,1.07c-0.31,0.09-2.04,0.59-2.31,0.41c-0.82-0.56-1.1-0.44-1.7-0.68
				c-0.65-0.26-0.54-0.15-1.23-0.32c-0.63-0.15-0.68-0.07-1.42-0.19c-0.74-0.12-0.8-0.22-1.05-0.23c-0.15,0-0.31,0.45,0.01,0.68
				c0.02,0,0.1,0.01,0.27,0.06c0.45,0.13,0.82,0.31,1.39,0.41c0.56,0.1,0.88-0.16,1.24-0.07c0,0-0.16-0.02-0.48,0.12
				c-0.32,0.14-0.69,0.35-0.69,0.35c-0.05,0.05-0.13,0.12-0.28,0.2c-0.45,0.24-0.92,0.53-1.38,0.82c-0.1,0.06-0.2,0.13-0.3,0.19
				c-0.56,0.36-0.84,0.94-0.7,1.22c0.14,0.28,0.51,0.42,0.51,0.42s-0.03,0.03-0.06,0.09l0.06-0.09l-0.09-0.04
				c0.09,0.04,0.25-0.06,0.59-0.28c0.34-0.22,0.51-0.38,0.54-0.71c0,0,0.08,0.38-0.31,0.62c-0.38,0.24-0.79,0.5-0.8,0.51
				c-0.08,0.14-0.21,0.41-0.06,0.65c0.19,0.3,0.52,0.34,0.59,0.35l-0.14-0.03c0.07,0.01,0.22-0.05,0.39-0.15
				c0.16-0.09,0.87-0.45,0.87-0.45s-0.7,0.4-0.84,0.48c-0.14,0.08-0.26,0.14-0.26,0.15c0,0,0.01,0,0.01,0s-0.35,0.27-0.15,0.56
				c0.18,0.27,0.81,0.32,0.92,0.33l0-0.01l-0.08,0c0.1,0.01,0.23-0.07,0.39-0.14c0.17-0.07,0.53-0.28,0.66-0.25
				c0.13,0.03,0.11,0.04,0.11,0.04s-0.08-0.05-0.38,0.07c-0.3,0.11-0.68,0.27-0.76,0.41c-0.05,0.11-0.08,0.29,0.04,0.51
				c0.05,0.1,0.12,0.16,0.2,0.19c0.22,0.09,0.5-0.02,0.73-0.06c0.1-0.02,0.2-0.06,0.29-0.13c0.01-0.01,0.02-0.01,0.04-0.02
				c0.18-0.12,0.39-0.28,0.73-0.39c0.54-0.17,1.62-0.94,2.55-1.56s2.27-0.72,2.87-0.97c0.07-0.03,0.13-0.05,0.2-0.08
				c0.51-0.14,1.38-0.37,2.67-0.63c0,0,0,0,0,0c0.47-0.1,1-0.2,1.58-0.3c0.66-0.12,1.38-0.24,2.18-0.36
				c4.63-0.69,3.32-4.74,2.97-6.35c-0.67-3.07-1.33-5.31-1.38-5.48c0-0.01,0-0.02,0-0.02S558.42,230.28,558.24,230.79z"
            />
            <path
              fill="#4D2C1A"
              d="M558.15,231c0.02-0.05,0.04-0.09,0.05-0.12C558.19,230.91,558.17,230.96,558.15,231z"
            />
            <path
              fill="#4D2C1A"
              d="M554.32,233.32l0.62,1.14c1.85-0.95,2.81-2.61,3.15-3.32c0.02-0.05,0.05-0.1,0.06-0.14
				c-0.61,1.27-2.46,1.94-3.35,2.2c-0.21,0.06-0.37,0.1-0.44,0.12C554.34,233.31,554.32,233.32,554.32,233.32z"
            />
            <path
              fill="#4D2C1A"
              d="M558.24,230.79C558.24,230.79,558.24,230.79,558.24,230.79C558.24,230.79,558.24,230.79,558.24,230.79
				c-0.01,0.02-0.02,0.05-0.03,0.08c0.01-0.02,0.01-0.03,0.02-0.05C558.23,230.81,558.24,230.8,558.24,230.79z"
            />
            <path
              fill="#9F7C63"
              d="M556.29,275.01c0.45,2.36,0.18-1.66,0.36-2.52c0.18-0.86-0.06-0.18,0.68,1.91
				c0.58,1.65,2.43,5.25,3.18,6.7c0.2,0.38,0.32,0.62,0.32,0.62s-4.02-8.5-4.77-14.89c-0.01-0.11-0.02-0.21-0.03-0.32
				c-0.01-0.12-0.02-0.23-0.03-0.35c0-0.03-0.01-0.06-0.01-0.09c-0.02-0.21-0.03-0.41-0.04-0.61c-0.01-0.17-0.02-0.2-0.03-0.12
				C555.85,265.88,555.71,271.98,556.29,275.01z"
            />
            <path
              fill="#4D2C1A"
              d="M540.72,209.53c0.51-0.01,1.06,0.32,1.06,0.32c-0.13-0.12-0.27-0.21-0.4-0.28
				c-0.33-0.16-0.64-0.16-0.89-0.11c-0.01,0-0.02,0-0.02,0c-0.02,0-0.03,0.01-0.05,0.01c-0.02,0-0.03,0.01-0.05,0.01
				c-0.01,0-0.01,0-0.02,0.01c-0.07,0.02-0.14,0.05-0.19,0.08c-0.02,0.01-0.03,0.02-0.05,0.03c0.03-0.01,0.14-0.03,0.47-0.08
				C540.64,209.54,540.68,209.54,540.72,209.53z"
            />
            <path fill="#4D2C1A" d="M540.11,209.63c0,0,0.01,0,0.01-0.01C540.11,209.63,540.11,209.63,540.11,209.63z" />
            <path
              fill="#4D2C1A"
              d="M538.19,213.52c0.1-0.23,0.42-0.49-0.18-0.91c-0.6-0.42-0.36-0.79-0.2-1.07
				c0.17-0.29,0.56-0.75,0.62-1.37c0.06-0.62-0.3-0.93-0.65-1.02c-0.23-0.06-0.53-0.3-0.35,0.1c0.04,0.1,0.07,0.22,0.09,0.35
				c0,0,0,0,0,0.01c-0.02,0.16-0.02,0.23-0.01,0.31c0-0.01-0.01-0.03-0.02-0.04l0.02,0.04c0,0,0,0.01,0,0.01
				c0.01,0.09-0.08,0.18-0.34,0.16c-0.31-0.03,0.04,0.03,0.2,0.07c0.07,0.02,0.14,0.04,0.19,0.07c0,0.07,0,0.14-0.01,0.21
				c0,0.14-0.01,0.26-0.01,0.36c0,0.15-0.02,0.32-0.03,0.49c-0.03,0.32-0.04,0.67,0.06,1.07c0,0,0,0,0,0
				C537.74,212.96,538.09,213.75,538.19,213.52z"
            />
            <path
              fill="#4D2C1A"
              d="M538.34,212.53c-0.04,0.06,0.1,0.05,0.43,0.11C539.02,212.69,538.38,212.46,538.34,212.53z"
            />
            <path
              fill="#4D2C1A"
              d="M539.66,214.31c0.12-0.12,0.2-0.26,0.24-0.38c0.13-0.1,0.32,0.06,0.41,0.15c0,0,0-0.01-0.01-0.01
				l-0.41-0.57c0.05,0.12,0.05,0.2,0.02,0.26c-0.08,0.07-0.26,0.21-0.46,0.17c-0.26-0.06-0.36-0.22-0.58-0.18
				c-0.22,0.04-0.18,0.15-0.28,0.08c-0.09-0.07-0.18-0.12-0.32,0.02c0,0,0,0.01,0.01,0.03c0.01,0.04,0.03,0.13,0.06,0.19
				c0.04,0.08,0.08,0.15,0.08,0.2c0.01,0.04,0.01,0.2,0.16,0.27c0.05,0.03,0.11,0.04,0.2,0.05
				C539.13,214.6,539.46,214.52,539.66,214.31z"
            />
            <path
              fill="#4D2C1A"
              d="M545.36,209.67c0.05,0.13,0.09,0.25,0.09,0.25s0.11-0.15,0.05-0.29c-0.05-0.15-0.12-0.22-0.32-0.4
				c-0.19-0.18-0.08-0.41,0.11-0.46c0.26-0.07,0.52,0.37,0.52,0.37c-0.08-0.2-0.17-0.49-0.45-0.54c-0.28-0.05-0.41,0.13-0.47,0.25
				c-0.06,0.11-0.14,0.58,0.05,0.74C545.14,209.74,545.31,209.53,545.36,209.67z"
            />
            <path
              fill="#4D2C1A"
              d="M544.87,210.22c-0.11,0.22,0.02,0.34,0.12,0.2c0.11-0.13,0.3-0.47,0.21-0.55
				c-0.09-0.08-0.26-0.01-0.4-0.18c0,0-0.06,0.03,0.01,0.15S544.97,209.99,544.87,210.22z"
            />
            <path
              fill="#4D2C1A"
              d="M545.2,210.78c0.59-0.25,0.58-1.2,0.58-1.2C545.78,210.12,545.2,210.78,545.2,210.78z"
            />
            <path fill="#4D2C1A" d="M539.89,213.51c0,0,0-0.01-0.01-0.02C539.89,213.5,539.89,213.5,539.89,213.51z" />
            <path fill="#4D2C1A" d="M539.87,213.47C539.87,213.46,539.87,213.46,539.87,213.47L539.87,213.47z" />
            <path fill="#4D2C1A" d="M540.31,214.09C540.37,214.17,540.36,214.14,540.31,214.09L540.31,214.09z" />
            <path
              fill="#4D2C1A"
              d="M539.88,213.49C539.88,213.49,539.88,213.49,539.88,213.49C539.88,213.49,539.88,213.49,539.88,213.49z"
            />
            <path
              fill="#4D2C1A"
              d="M539.88,213.49c0-0.01-0.01-0.02-0.01-0.03C539.87,213.47,539.88,213.48,539.88,213.49z"
            />
            <path fill="#9F7C63" d="M541.6,210.12c0,0-0.01,0-0.03-0.01L541.6,210.12z" />
            <path
              fill="#4D2C1A"
              d="M540.05,210.05c0.17-0.05,0.34-0.07,0.5-0.08c-0.03,0.05-0.05,0.11-0.05,0.17c0,0.12,0.07,0.22,0.16,0.26
				c-0.11,0.02-0.18,0.02-0.18,0.02c0.69,0.07,1.13-0.31,1.13-0.31l-0.03-0.01c0.02,0.01,0.03,0.01,0.03,0.01
				C540.82,209.69,540.05,210.05,540.05,210.05z M540.88,210.36c0.06-0.05,0.09-0.13,0.09-0.22c0-0.06-0.02-0.12-0.05-0.17
				c0.27,0.02,0.48,0.08,0.59,0.11C541.38,210.22,541.11,210.31,540.88,210.36z"
            />
          </g>
          <g>
            <path
              fill="none"
              d="M595.07,215.52c0.03,0.02,0.06,0.04,0.1,0.06l0.26-0.37l-0.27,0.36
				C595.13,215.55,595.1,215.54,595.07,215.52z"
            />
            <path
              fill="none"
              d="M595.8,207.6C595.8,207.6,595.8,207.6,595.8,207.6C595.8,207.6,595.8,207.6,595.8,207.6z"
            />
            <path
              fill="none"
              d="M589.03,215.39L589.03,215.39c0.01-0.03,0.02-0.06,0.03-0.09C589.05,215.34,589.04,215.37,589.03,215.39z"
            />
            <path
              fill="none"
              d="M595.84,215.84c0.01-0.03,0.01-0.05,0.01-0.05l-0.02,0.04C595.84,215.83,595.84,215.83,595.84,215.84z"
            />
            <path
              fill="none"
              d="M595.51,210.88c0.02-0.01,0.03-0.01,0.05-0.01C595.54,210.87,595.52,210.87,595.51,210.88z"
            />
            <path
              fill="#D8BFB1"
              d="M598.07,286.6c-0.02,0.14-0.06,0.3-0.1,0.45c0.02-0.04,1.06,2.43,2.43,2.48s2.02-1.69,2.13-1.58
				c-0.24-0.34-0.42-0.68-0.56-0.97L598.07,286.6z"
            />
            <path
              fill="#151616"
              d="M600.38,288.45c-1.37-0.05-2.35-1.52-2.35-1.52c-0.03,0.03-0.05,0.07-0.07,0.11
				c-0.52,0.83-0.46,2.22-0.43,2.59c0,0.06,0.01,0.09,0.01,0.09c0.34,0.78,1.71,1.27,2.3,1.22c0.59-0.05,0.69,0.64,2.79,1.13
				c2.11,0.49,3.48,0.24,3.67-0.29c0.2-0.54,0.15-1.08-1.17-1.81c-0.53-0.29-1.08-0.71-1.56-1.1c-0.46-0.38-0.84-0.73-1.05-0.93
				c-0.11-0.11-0.18-0.17-0.18-0.17C601.02,287.67,601.76,288.5,600.38,288.45z"
            />
            <path
              fill="#D8BFB1"
              d="M589.71,287.28c0,0,0.18,2.01,1.96,1.18c0.58-0.27,1.21-0.33,1.42-1.19c-0.1-0.35-0.27-0.72-0.39-0.96
				c-0.61,0.05-2.06-2.62-3.02,0.86C589.7,287.2,589.7,287.23,589.71,287.28z"
            />
            <path
              fill="#D8BFB1"
              d="M589.24,289.9c0.03-0.09,0.05-0.17,0.08-0.27C589.26,289.73,589.24,289.82,589.24,289.9z"
            />
            <path
              fill="#151616"
              d="M592.75,287.43c-0.24,0.1-0.49-0.54-1.13-0.44c-0.64,0.1-0.88,0.44-1.17,0.78
				c-0.29,0.34-0.73-0.49-0.73-0.49s-0.2,1.66-0.39,2.35c-0.03,0.09-0.05,0.18-0.08,0.27c-0.16,0.53-0.27,0.92,0.28,1.59
				c0.02,0.03,0.05,0.06,0.08,0.08c0.64,0.68,1.65,0.45,3.2,0.36c1.01-0.06,1.37-0.61,1.44-1.22c0.04-0.37-0.02-0.76-0.11-1.08
				c-0.16-0.53-0.67-1.63-1.02-2.37c-0.2-0.42-0.35-0.72-0.35-0.72S592.99,287.33,592.75,287.43z"
            />
            <path fill="#D8BFB1" d="M595.85,215.79c0,0-0.02,0-0.04,0c0.01,0.01,0.01,0.03,0.02,0.04L595.85,215.79z" />
            <path
              fill="#D8BFB1"
              d="M589.04,215.39l3.28,3.13l0.33,1.69l0.43-1.71l2.1-2.93c-0.03-0.02-0.06-0.04-0.1-0.06
				c-0.2-0.11-0.34-0.28-0.43-0.42c-0.11-0.06-1.02-0.54-2.05-1.25c-1.1-0.76-1.02-1.88-1.02-1.88s0.27,1.21,0.76,1.41
				c1.36,0.55,2,0.38,2,0.38s0.51,0.01,0.9-0.05c0.07-0.01,0.14-0.02,0.2-0.04c0.26-0.06,0.39-0.21,0.49-0.45
				c0.11-0.36,0.17-0.56-0.02-0.82c-0.21-0.28-0.29-0.35-0.29-0.35s0.42-0.06,0.53-0.3c0.06-0.12,0.03-0.23,0.01-0.31
				c-0.09,0.05-0.19,0.08-0.33,0.08c-0.27,0-0.58-0.16-0.75-0.28c-0.13-0.03-0.22,0.18-0.22,0.18s0.12-0.61,0.12-0.54
				c0,0.06,0,0.21,0.13,0.24c0.24,0.02,0.29-0.19,0.41-0.23c0.01-0.01,0.03-0.01,0.05-0.01c0.18,0,0.25,0.24,0.46,0.06
				c0.15-0.12,0.21-0.13,0.28-0.01c-0.01-0.07-0.04-0.13-0.11-0.16c-0.19-0.08-0.55-0.38-0.13-0.45c0.42-0.06,0.55-0.21,0.49-0.38
				c-0.06-0.17-0.17-0.55-0.43-1.11c-0.3-0.64-0.46-0.9-0.32-1.22c0,0,0,0,0-0.01c0.02-0.04,0.04-0.07,0.06-0.11
				c0.03-0.05,0.07-0.09,0.1-0.14c-0.01,0.01-0.02,0.01-0.02,0.01s-0.03-0.05,0.12-0.23c0.15-0.18,0.33-0.11,0.59-0.03
				c0.14-0.53,0.07-2.08-0.61-3.2c-0.02-0.02-0.04-0.05-0.05-0.07c0,0,0,0,0,0c-0.38-0.42-0.85-0.59-1.21-0.65
				c-0.46,0.59-0.84,2.25-1.45,3.83c-0.09,0.29-0.23,0.63-0.46,1.03c-0.49,0.87-1.7,1.54-1.74,1.58c-0.04,0.04,0.76-2.38-0.73-2.22
				c-1.12,0.12-0.8,2.02-0.08,3.02c0.13,0.18,0.2,0.54,0.14,0.76c-0.3,0.98-0.8,1.29-0.93,1.36c0.01,0.22,0,0.44-0.04,0.68
				c-0.1,0.67-0.21,1.22-0.31,1.66c-0.02,0.12-0.07,0.31-0.11,0.44C589.05,215.33,589.04,215.36,589.04,215.39z M595.98,210.06
				c0.17-0.04-0.02,0.13-0.34,0.13l-0.03-0.03C595.61,210.17,595.81,210.1,595.98,210.06z M593.73,207.99
				c0.34-0.36,0.71-0.38,0.93-0.34c0.11,0.01,0.28-0.01,0.35-0.06c0,0-0.02,0.07-0.16,0.11c0,0,0,0,0,0c0,0-0.01,0-0.01,0
				c-0.05,0.01-0.11,0.02-0.19,0.03c0.01,0.06,0.02,0.24-0.11,0.33c0.08,0.01,0.17,0.02,0.24,0.03c0.21,0.04,0.01,0.01-0.25-0.02
				c0,0-0.01,0.01-0.01,0.01l-0.23-0.03C593.96,208.03,593.68,208.04,593.73,207.99z M593.64,207.83c0,0,0.62-0.82,1.18-0.25
				C594.83,207.58,594.25,207.15,593.64,207.83z M594.67,210.69c-0.36,0.47-0.44,0.87-0.55,1.32c-0.11,0.45,0.11-0.66-0.04-1.01
				c-0.15-0.35,0.32-0.14,0.72-0.68c0.4-0.54,0.49-0.94,0.49-0.94C595.28,209.89,595.03,210.22,594.67,210.69z M594.38,206.99
				c0.45-0.18,0.73,0,0.78,0.25c0,0-0.25-0.07-0.55-0.08c-0.3-0.02-1.13,0.25-1.13,0.25S593.93,207.17,594.38,206.99z
				 M593.68,208.03c-0.18,0.31-0.49,0.14-0.49,0.14C593.54,208.19,593.68,208.03,593.68,208.03z M590.95,208.91
				c-0.07,0.11-0.16,0.16-0.05,0.38c0.11,0.22-0.02,0.34-0.12,0.2c-0.11-0.13-0.3-0.47-0.21-0.55c0.09-0.08,0.26-0.01,0.4-0.18
				C590.96,208.76,591.02,208.79,590.95,208.91z M590.4,207.66c0.28-0.05,0.41,0.13,0.47,0.25c0.06,0.11,0.14,0.58-0.05,0.74
				s-0.36-0.05-0.42,0.09s-0.09,0.25-0.09,0.25s-0.11-0.15-0.05-0.29c0.05-0.15,0.12-0.22,0.32-0.4c0.19-0.18,0.08-0.41-0.11-0.46
				c-0.26-0.07-0.52,0.37-0.52,0.37C590.03,207.99,590.12,207.7,590.4,207.66z M589.98,208.65c0,0.54,0.58,1.2,0.58,1.2
				C589.97,209.6,589.98,208.65,589.98,208.65z"
            />
            <path
              fill="#D8BFB1"
              d="M593.88,207.97c0,0,0.15,0.02,0.33,0.05c0-0.04-0.01-0.15,0.02-0.23
				C594.01,207.86,593.88,207.97,593.88,207.97z"
            />
            <polygon fill="#D8BFB1" points="596.62,207.24 596.62,207.24 596.62,207.25 			" />
            <path
              fill="#4D2C1A"
              d="M595.36,240l-2.84,0.26c-0.05,0.79-5.78,0.78-7-0.26c-0.69,2.39-1.6,6.66-1.01,11.16
				c0.9,6.86,1.79,15.67,1.79,15.67s-0.38,3.78,0.51,7.56c0.9,3.78,1.79,12.73,1.79,12.73l0.9,0.14c0.06-0.03,0.12-0.07,0.18-0.1
				c1.16-0.63,2.4-0.81,3.02-0.86c0.24-0.02,0.39-0.02,0.39-0.02s0.26-5.46,0.13-9.23c-0.13-3.78-0.3-10.33,0.47-16.21
				c0.28-2.13,0.38-5.5,0.39-8.94c0.93,4.78,2.82,15.49,2.82,15.49l-0.1,18.98l1.26,0.23l3.55,0.64l0.35-0.26l0.9-0.69
				c0,0-0.08-9.83,0.48-24.23c0.47-12.07-1.11-19.14-1.97-22C600.59,240.8,595.6,240.81,595.36,240z"
            />
            <path
              fill="#FFFFFF"
              d="M594.58,235.9c-0.3-1.63-0.46-3.33-0.5-5.02c-0.42,0.14-0.87,0.29-1.34,0.45
				c0.14,3.47-0.04,6.33-0.22,8.93l2.84-0.26C595.12,239.2,594.89,238.06,594.58,235.9z"
            />
            <path
              fill="#FFFFFF"
              d="M592.36,226.48c0.02,0.17,0.03,0.33,0.05,0.49c0.44-0.08,0.85-0.16,1.14-0.24
				c0.21-0.05,0.41-0.1,0.61-0.14c0.17-2.65,0.52-5.08,0.86-6.97c0,0,0,0,0,0c0.32-1.87,0.73-3.5,0.81-3.79c0,0,0,0,0,0
				c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.01-0.01-0.03-0.02-0.04c-0.2-0.35-0.38-0.57-0.38-0.57l-0.26,0.37l-2.1,2.93l-0.43,1.71
				l-0.33-1.69l-3.28-3.13l0,0c-0.01,0.03-0.01,0.04-0.01,0.04l-0.37-0.41l-0.83,0.88C587.83,215.91,591.56,219.46,592.36,226.48z"
            />
            <path fill="#FFFFFF" d="M587.48,216l0.35-0.09C587.83,215.91,587.57,215.85,587.48,216z" />
            <path
              fill="#151616"
              d="M604.51,231.78c-0.03,0.02-0.07,0.03-0.11,0.05c-0.01,0-0.01,0-0.02,0.01c-0.3,0.11-0.7,0.17-1.23,0.11
				c-0.19-0.02-0.33-0.07-0.45-0.14c-0.76-0.37-0.38-1.69-0.38-1.69s0,0,0.01,0c0.05-0.37,0.2-0.84,0.42-1
				c0.28-0.19,0.43-0.17,1.1-0.45c0.67-0.28,0.56,0.04,0.5,0.19c-0.06,0.15,0.31-0.27,0.57-0.77c0.15-0.29,0.34-0.38,0.47-0.38
				c-0.01,0-0.01-0.01-0.02-0.01c-0.21-0.05-0.5,0.14-0.65,0.43c-0.09,0.16-0.17,0.32-0.22,0.43l-4.95-0.11
				c-0.01,0-0.01-0.01-0.01-0.01l-0.07,0.01l-0.05,0l-6.92,0.72c0,0-0.07-0.29,0.52-0.37c0.53-0.07,5.2-0.64,6.13-0.75l-0.02-0.02
				c0-0.03-0.04-0.07-0.12-0.13c-0.17-0.08-0.59-0.26-0.87-0.27c-0.35-0.02-0.35,0.21-0.64,0.4c-0.31,0.2-0.94,0.29-1.23,0.32
				c-0.31,0.06-0.56,0.1-0.56,0.1s-0.39-0.6-0.21-2.06c0,0,1.76,0.09,2.5-0.15c0.68-0.23,1.69-0.34,1.84-0.35l2.17-6.72
				c-0.52-3.01-3.56-2.33-6.14-3.36c0,0-0.01,0.02-0.01,0.05c0,0,0,0,0,0c-0.08,0.29-0.49,1.92-0.81,3.79c0,0,0,0,0,0
				c-0.34,1.89-0.69,4.32-0.86,6.97c-0.2,0.04-0.4,0.09-0.61,0.14c-0.3,0.07-0.7,0.15-1.14,0.24c-0.02-0.16-0.03-0.33-0.05-0.49
				c-0.8-7.02-4.53-10.57-4.53-10.57l-0.35,0.09l-4.07,1.08c-0.04,0-0.07,0-0.11,0.01c-1.21,0.36-2.57,0.5-2.7,1.35
				s-0.07,2.74-0.29,6.12c-0.12,1.74,0.54,4.59,1.87,7.38c0.88,1.84,1.99,1.93,3.26,1.63c-0.39,1.95-0.99,5.35-0.99,5.35
				s0.35,0.47,1.06,1.08c1.22,1.04,3.49,2.47,6.84,2.56c0.05-0.75,0.1-1.51,0.16-2.3c0.18-2.6,0.35-5.47,0.22-8.93
				c0.47-0.16,0.92-0.31,1.34-0.45c0.05,1.69,0.2,3.39,0.5,5.02c0.31,2.16,0.54,3.31,0.78,4.1c0.24,0.81,0.49,1.26,0.83,2.07
				c2.75-0.11,4.4-1.27,5.19-2.01c0.38-0.35,0.56-0.61,0.56-0.61s0.15-1.78-0.38-4.95c0.29,0.58,0.64,0.98,1.06,1.03
				c1.78,0.21,2.48-1.92,2.55-2.83c0.06-0.73,0.07-1.44,0.07-1.69c-0.06,0.16-0.14,0.34-0.32,0.52
				C604.83,231.62,604.68,231.71,604.51,231.78z"
            />
            <path
              fill="#4D2C1A"
              d="M617.45,217.79l-14.27-0.69c-0.2-0.01-0.37,0.11-0.43,0.3l-3.41,10.54c-0.02,0.05-0.02,0.11-0.02,0.17
				c0.01,0.15,0.1,0.26,0.22,0.33c0.01,0,0.01,0.01,0.01,0.01l4.95,0.11c0.05-0.11,0.13-0.26,0.22-0.43
				c0.15-0.28,0.44-0.47,0.65-0.43c0.01,0,0.01,0.01,0.02,0.01c0.1,0,0.17,0.06,0.16,0.15c0,0.05,0,0.15,0.01,0.25
				c0.07-0.25,0.21-0.73,0.27-0.97c0.08-0.27,0.29-0.36,0.47-0.35c0,0,0.01,0,0.01,0c0.04,0,0.08,0.01,0.11,0.01
				c0.37,0.09,0.18,0.87,0.12,1.17c-0.03,0.15-0.09,0.39-0.15,0.63l0.25,0.01c0.04-0.09,0.07-0.18,0.11-0.28
				c0.12-0.28,0.24-0.56,0.37-0.84c0.05-0.12,0.13-0.24,0.16-0.37c0.06-0.26,0.24-0.36,0.32-0.36c0.01,0,0.02,0.01,0.03,0.02
				c0.04-0.01,0.09-0.01,0.16,0.01c0.3,0.09,0.35,0.5,0.19,1.14c-0.06,0.26-0.13,0.5-0.2,0.7l2.39,0.05l3.77,0.07
				c0.19,0,0.36-0.12,0.42-0.29l3.47-10.1C617.93,218.09,617.74,217.8,617.45,217.79z"
            />
            <path
              fill="#151616"
              d="M605.96,227.3c0.07-0.25,0.1-0.5,0.34-0.51c-0.18,0-0.39,0.09-0.47,0.35c-0.07,0.24-0.2,0.72-0.27,0.97
				c0.01,0.14,0.02,0.27,0.02,0.27S605.85,227.7,605.96,227.3z"
            />
            <path
              fill="#151616"
              d="M606.2,229.29c0.12-0.08,0.39-0.28,0.5-0.47c0.15-0.28,0.5-1.01,0.63-1.45c0.1-0.34,0.15-0.56,0.32-0.59
				c-0.01-0.01-0.02-0.01-0.03-0.02c-0.08,0-0.26,0.09-0.32,0.36c-0.03,0.13-0.11,0.25-0.16,0.37c-0.12,0.28-0.24,0.56-0.37,0.84
				c-0.04,0.09-0.08,0.18-0.11,0.28l-0.25-0.01c-0.06,0.25-0.13,0.5-0.18,0.63C606.22,229.25,606.21,229.27,606.2,229.29z"
            />
            <path
              fill="#151616"
              d="M607.7,228.92c0.07-0.05,0.41-0.28,0.9-0.24c0,0,0,0,0,0c0.24,0.02,0.31,0.25,0.22,0.36
				c-0.05,0.05-0.1,0.11-0.14,0.18c2.68-0.2,5.42-0.44,5.42-0.44l-3.9-0.09l-2.39-0.05C607.76,228.74,607.73,228.84,607.7,228.92z"
            />
            <path
              fill="#D8BFB1"
              d="M604.38,231.84c-0.39,0.14-0.93,0.19-1.53,0.03c-0.06-0.02-0.11-0.04-0.16-0.06
				c0.12,0.07,0.27,0.12,0.45,0.14C603.69,232.01,604.08,231.95,604.38,231.84z"
            />
            <path
              fill="#D8BFB1"
              d="M597.98,226.22c-0.73,0.24-2.5,0.15-2.5,0.15c-0.17,1.46,0.21,2.06,0.21,2.06s0.25-0.04,0.56-0.1
				c-0.09,0.01-0.15,0.01-0.15,0.01c0.44-0.27,0.71-0.67,1.11-1c0.4-0.33,0.79-0.22,1.17,0.02c0.42,0.27,0.69,0.55,0.69,0.55
				s-0.03-0.02-0.08-0.04c0.08,0.06,0.12,0.1,0.12,0.13l0.02,0.02c-0.93,0.11-5.6,0.69-6.13,0.75c-0.59,0.07-0.52,0.37-0.52,0.37
				l6.92-0.72l0.05,0l0.07-0.01c-0.12-0.07-0.2-0.19-0.22-0.33c0-0.06,0-0.11,0.02-0.17l3.41-10.54c0.06-0.19,0.24-0.31,0.43-0.3
				l-0.52-0.02l-0.67,2.07l-2.17,6.72C599.68,225.89,598.66,226,597.98,226.22z M599.37,227.35c0,0-0.96-0.41-0.9-0.44
				c0.38-0.18,1.09-0.15,1.09-0.15L599.37,227.35z"
            />
            <path
              fill="#9F7C63"
              d="M598.4,227.38c-0.38-0.24-0.77-0.35-1.17-0.02c-0.4,0.33-0.67,0.73-1.11,1c0,0,0.06,0,0.15-0.01
				c0.29-0.03,0.92-0.12,1.23-0.32c0.3-0.19,0.29-0.42,0.64-0.4c0.28,0.02,0.7,0.2,0.87,0.27c0.05,0.02,0.08,0.04,0.08,0.04
				S598.82,227.64,598.4,227.38z"
            />
            <path
              fill="#9F7C63"
              d="M598.47,226.91c-0.06,0.03,0.9,0.44,0.9,0.44l0.19-0.59C599.56,226.76,598.85,226.73,598.47,226.91z"
            />
            <path
              fill="#E8E7D9"
              d="M590.46,211.17c0.07-0.22-0.01-0.57-0.14-0.76c-0.72-1-1.04-2.9,0.08-3.02c1.5-0.16,0.69,2.27,0.73,2.22
				c0.04-0.04,1.25-0.71,1.74-1.58c0.23-0.4,0.37-0.74,0.46-1.03c0.61-1.59,0.99-3.24,1.45-3.83c0.11-0.14,0.22-0.22,0.34-0.23
				c0.68-0.03,0.74,0.32,1.02,1.05c-0.03-0.04-0.05-0.07-0.08-0.1c0.68,1.12,0.75,2.67,0.61,3.2c-0.25-0.08-0.44-0.15-0.59,0.03
				c-0.15,0.18-0.12,0.23-0.12,0.23s0.01,0,0.02-0.01c0.09-0.05,0.46-0.23,0.66-0.1c0,0,0,0,0,0c0,0,0,0,0,0
				c0.11,0.15,0.1,0.37,0.04,0.58c0,0,0.1,0.02,0.22,0.02c0.09,0-0.09,0.08-0.21,0.13c-0.01,0.06-0.01,0.16-0.03,0.21
				c0.01,0.21,0.06,0.46,0.11,0.74c0.1,0.47,0.08,1.04-0.05,1.44c-0.13,0.4-0.15,1.03-0.34,1.61c-0.19,0.57-0.62,1.79-0.48,1.33
				c0.01-0.03,0.02-0.05,0.02-0.07c-0.1,0.24-0.23,0.39-0.49,0.45c-0.06,0.01-0.13,0.03-0.2,0.04c1.32,0.06,2.43-0.3,2.43-0.3
				s-0.71-0.28-0.49-3.71c0.24-3.77,0.06-5.9-0.96-6.4c-0.54-3.12-6.75-2.06-8.12,0.37c-1.65,2.95-0.45,4.64-0.45,4.64
				c0.84,3.42,1.85,4.23,1.85,4.23s0.01-0.01,0.03-0.02C589.66,212.46,590.16,212.14,590.46,211.17z"
            />
            <path
              fill="#151616"
              d="M594.64,215.1c0.09,0.14,0.22,0.31,0.43,0.42c0.03,0.02,0.06,0.03,0.09,0.05l0.27-0.36
				c0,0-0.21-0.23-0.87-0.33c0.05,0.14,0.09,0.23,0.09,0.23S594.65,215.1,594.64,215.1z"
            />
            <path
              fill="#151616"
              d="M589.03,215.39c0.01-0.02,0.02-0.05,0.03-0.09c0.04-0.13,0.09-0.32,0.11-0.44c0-0.02,0.01-0.05,0.01-0.06
				c0,0-0.22-0.03-0.53,0.23l0.37,0.41C589.02,215.43,589.03,215.42,589.03,215.39z"
            />
            <path
              fill="#9F7C63"
              d="M592.33,213.38c-0.49-0.2-0.76-1.41-0.76-1.41s-0.08,1.12,1.02,1.88c1.03,0.71,1.95,1.19,2.05,1.25
				c0.01,0,0.01,0.01,0.01,0.01s-0.04-0.08-0.09-0.23c-0.09-0.24-0.21-0.65-0.23-1.12C594.33,213.76,593.68,213.93,592.33,213.38z"
            />
            <path
              fill="#9F7C63"
              d="M595.11,202.94c-0.12,0.01-0.23,0.09-0.34,0.23c0.36,0.06,0.83,0.23,1.21,0.65c0,0,0,0,0,0
				c0.02,0.02,0.04,0.05,0.05,0.07c0.03,0.03,0.06,0.06,0.08,0.1C595.85,203.26,595.8,202.91,595.11,202.94z"
            />
            <path
              fill="#9F7C63"
              d="M606.51,230.35c-0.21,0.03-0.36,0.01-0.49-0.04c-0.09-0.17,0.13-0.83,0.13-0.83s-0.04,0.1-0.14,0.28
				c-0.09,0.18-0.22,0.45-0.39,0.53c-0.2,0.06-0.46,0.17-0.71,0.1c-0.14-0.04-0.21-0.09-0.27-0.14c-0.02-0.04-0.02-0.09,0.03-0.15
				c0.16-0.17,0.46-0.39,0.57-0.65c0.09-0.21,0.17-0.51,0.2-0.62c-0.03,0.11-0.13,0.4-0.31,0.6c-0.01,0.01-0.01,0.01-0.02,0.02
				c-0.23,0.25-0.47,0.6-0.79,0.7c-0.06,0-0.13,0.01-0.22,0.04c-0.14,0.03-0.24,0.03-0.32,0.02c-0.01-0.01-0.03-0.02-0.05-0.03
				c-0.23-0.08,0.06-0.47,0.2-0.64c-0.18,0.19-0.38,0.36-0.64,0.5c-0.31,0.06-0.92,0.07-1,0.07c-0.01,0-0.01,0-0.01,0
				s-0.38,1.31,0.38,1.69c0.05,0.02,0.1,0.04,0.16,0.06c0.6,0.16,1.14,0.11,1.53-0.03c0.01,0,0.01,0,0.02-0.01
				c0.04-0.01,0.08-0.03,0.11-0.05c0.17-0.08,0.32-0.17,0.41-0.26c0.18-0.18,0.25-0.37,0.32-0.52c0.04-0.1,0.07-0.18,0.12-0.24
				c0.03-0.02,0.05-0.05,0.08-0.07c0.02-0.01,0.04-0.02,0.06-0.03C605.72,230.6,606.34,230.41,606.51,230.35
				C606.51,230.36,606.5,230.36,606.51,230.35L606.51,230.35z"
            />
            <path
              fill="#9F7C63"
              d="M603.96,229.55c0.01-0.01,0.02-0.02,0.03-0.03C604.07,229.43,604.03,229.46,603.96,229.55z"
            />
            <path fill="#9F7C63" d="M605.46,228.84c0.01-0.03,0.01-0.04,0.01-0.04S605.47,228.82,605.46,228.84z" />
            <path
              fill="#D8BFB1"
              d="M608.59,228.68c-0.15,0.06-0.51,0.32-0.59,0.52c-0.08,0.2,0.1,0.28,0.1,0.28s-0.39,0.04-0.43,0.1
				c-0.04,0.06-0.02,0.12-0.02,0.12s-0.54,0.18-0.67,0.27c-0.13,0.1,0.55-0.55,0.55-0.55l0.16-0.5c0,0,0.01,0,0.01-0.01
				c0.03-0.08,0.06-0.18,0.1-0.29c0.07-0.2,0.14-0.43,0.2-0.7c0.15-0.65,0.11-1.06-0.19-1.14c-0.06-0.02-0.11-0.02-0.16-0.01
				c-0.16,0.03-0.21,0.25-0.32,0.59c-0.13,0.43-0.47,1.17-0.63,1.45c-0.11,0.19-0.38,0.39-0.5,0.47c0.01-0.02,0.01-0.04,0.02-0.06
				c0.04-0.12,0.11-0.37,0.18-0.63c0.06-0.24,0.12-0.48,0.15-0.63c0.06-0.3,0.25-1.08-0.12-1.17c-0.03-0.01-0.07-0.01-0.11-0.01
				c0,0-0.01,0-0.01,0c-0.25,0.01-0.27,0.26-0.34,0.51c-0.11,0.4-0.39,1.09-0.39,1.09s-0.01-0.13-0.02-0.27
				c-0.01-0.1-0.01-0.21-0.01-0.25c0-0.09-0.06-0.14-0.16-0.15c-0.13-0.01-0.32,0.09-0.47,0.38c-0.26,0.5-0.64,0.92-0.57,0.77
				c0.06-0.15,0.17-0.47-0.5-0.19c-0.67,0.28-0.82,0.26-1.1,0.45c-0.23,0.16-0.37,0.62-0.42,1c0.08,0,0.68-0.01,1-0.07
				c0.26-0.14,0.46-0.31,0.64-0.5c0.07-0.08,0.11-0.11,0.03-0.03c-0.01,0.01-0.02,0.02-0.03,0.03c-0.14,0.17-0.43,0.56-0.2,0.64
				c0.03,0.01,0.04,0.02,0.05,0.03c0.07,0.01,0.17,0.01,0.32-0.02c0.09-0.02,0.16-0.03,0.22-0.04c0.31-0.09,0.56-0.44,0.79-0.7
				c0.01-0.01,0.01-0.01,0.02-0.02c0.19-0.21,0.28-0.5,0.31-0.6c0.01-0.03,0.01-0.04,0.01-0.04s0,0.02-0.01,0.04
				c-0.03,0.11-0.12,0.41-0.2,0.62c-0.11,0.26-0.42,0.48-0.57,0.65c-0.06,0.06-0.06,0.11-0.03,0.15c0.06,0.05,0.12,0.11,0.27,0.14
				c0.25,0.07,0.51-0.04,0.71-0.1c0.16-0.08,0.29-0.35,0.39-0.53c0.1-0.18,0.14-0.28,0.14-0.28s-0.22,0.66-0.13,0.83
				c0.13,0.05,0.27,0.07,0.49,0.04l-0.01,0.01c0,0,0,0,0,0c0.01,0,0.18,0,0.56-0.15c0.38-0.16,0.41-0.33,0.67-0.42
				c0.26-0.08,0.72-0.05,0.79-0.28c0.04-0.12,0.09-0.22,0.15-0.3c0.05-0.06,0.09-0.12,0.14-0.18
				C608.91,228.94,608.83,228.7,608.59,228.68z"
            />
            <path
              fill="#9F7C63"
              d="M607.52,229.42c0,0-0.69,0.65-0.55,0.55c0.13-0.1,0.67-0.27,0.67-0.27s-0.01-0.06,0.02-0.12
				c0.04-0.06,0.43-0.1,0.43-0.1s-0.18-0.07-0.1-0.28c0.08-0.2,0.45-0.46,0.59-0.52c0,0,0,0,0,0c-0.49-0.04-0.83,0.19-0.9,0.24
				c-0.01,0.01-0.01,0.01-0.01,0.01L607.52,229.42z"
            />
            <path
              fill="#9F7C63"
              d="M595.98,210.06c-0.17,0.04-0.37,0.11-0.37,0.11l0.03,0.03C595.96,210.19,596.15,210.02,595.98,210.06z"
            />
            <path
              fill="#E8E7D9"
              d="M595.16,207.24c-0.05-0.25-0.33-0.43-0.78-0.25c-0.45,0.18-0.9,0.41-0.9,0.41s0.83-0.27,1.13-0.25
				C594.91,207.17,595.16,207.24,595.16,207.24z"
            />
            <path
              fill="#9F7C63"
              d="M594.52,208.07c0,0,0.01-0.01,0.01-0.01c0.26,0.04,0.46,0.06,0.25,0.02c-0.07-0.01-0.16-0.02-0.24-0.03
				c0.13-0.09,0.12-0.27,0.11-0.33c0.08,0,0.14-0.01,0.19-0.03c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0.14-0.04,0.16-0.11,0.16-0.11
				c-0.07,0.05-0.23,0.07-0.35,0.06c-0.22-0.04-0.59-0.02-0.93,0.34c-0.05,0.06,0.24,0.05,0.56,0.06L594.52,208.07z M594.22,208.02
				c-0.18-0.03-0.33-0.05-0.33-0.05s0.13-0.11,0.35-0.18C594.21,207.87,594.21,207.98,594.22,208.02z"
            />
            <path
              fill="#9F7C63"
              d="M594.08,211c0.15,0.35-0.06,1.46,0.04,1.01c0.11-0.45,0.19-0.85,0.55-1.32c0.36-0.47,0.61-0.8,0.61-1.32
				c0,0-0.08,0.4-0.49,0.94C594.39,210.86,593.93,210.64,594.08,211z"
            />
            <path
              fill="#9F7C63"
              d="M596.37,211.97c0.19-0.57,0.2-1.2,0.34-1.61c0.13-0.4,0.15-0.97,0.05-1.44c-0.06-0.28-0.1-0.53-0.11-0.74
				c0.02-0.06,0.02-0.15,0.03-0.21c0.12-0.05,0.3-0.13,0.21-0.13c-0.12,0-0.22-0.02-0.22-0.02c0.05-0.21,0.06-0.43-0.04-0.58
				c0,0,0,0,0,0c-0.2-0.12-0.57,0.06-0.66,0.1c-0.03,0.04-0.07,0.09-0.1,0.14c-0.02,0.04-0.04,0.07-0.06,0.11c0,0,0,0,0,0.01
				c-0.14,0.32,0.02,0.58,0.32,1.22c0.26,0.56,0.37,0.94,0.43,1.11c0.06,0.17-0.08,0.32-0.49,0.38c-0.42,0.06-0.06,0.36,0.13,0.45
				c0.07,0.03,0.11,0.09,0.11,0.16c-0.07-0.12-0.13-0.11-0.28,0.01c-0.21,0.18-0.29-0.06-0.46-0.06c-0.02,0-0.03,0.01-0.05,0.01
				c-0.12,0.05-0.18,0.25-0.41,0.23c-0.13-0.03-0.13-0.17-0.13-0.24c0-0.07-0.12,0.54-0.12,0.54s0.1-0.21,0.22-0.18
				c0.16,0.12,0.48,0.28,0.75,0.28c0.14,0,0.25-0.03,0.33-0.08c0.02,0.09,0.05,0.19-0.01,0.31c-0.11,0.23-0.53,0.3-0.53,0.3
				s0.08,0.08,0.29,0.35c0.2,0.26,0.13,0.46,0.02,0.82c-0.01,0.02-0.01,0.05-0.02,0.07C595.75,213.76,596.18,212.54,596.37,211.97z"
            />
            <path
              fill="#9F7C63"
              d="M590.47,207.83c0.18,0.05,0.3,0.28,0.11,0.46c-0.19,0.18-0.27,0.26-0.32,0.4
				c-0.05,0.15,0.05,0.29,0.05,0.29s0.04-0.11,0.09-0.25s0.22,0.07,0.42-0.09s0.12-0.63,0.05-0.74c-0.06-0.11-0.19-0.29-0.47-0.25
				c-0.28,0.04-0.37,0.34-0.45,0.54C589.95,208.2,590.22,207.76,590.47,207.83z"
            />
            <path
              fill="#9F7C63"
              d="M590.77,209.49c0.11,0.13,0.23,0.02,0.12-0.2c-0.11-0.22-0.02-0.27,0.05-0.38
				c0.07-0.11,0.01-0.15,0.01-0.15c-0.14,0.17-0.31,0.1-0.4,0.18C590.47,209.02,590.67,209.36,590.77,209.49z"
            />
            <path fill="#9F7C63" d="M589.98,208.65c0,0-0.02,0.96,0.58,1.2C590.56,209.85,589.98,209.19,589.98,208.65z" />
            <path fill="#9F7C63" d="M593.68,208.03c0,0-0.14,0.16-0.49,0.14C593.19,208.17,593.5,208.33,593.68,208.03z" />
            <path
              fill="#9F7C63"
              d="M594.83,207.58c-0.56-0.57-1.18,0.25-1.18,0.25C594.25,207.15,594.83,207.58,594.83,207.58z"
            />
          </g>
        </g>
        <g id="man">
          <path
            fill="#42637C"
            d="M205.95,148.56c0,0-0.37-1.02-1.28-1.16c0.13,0.13-7.74,0.3-7.74,0.07c0-0.05,0.01-0.09,0.04-0.14
			c0.05-0.1,0.16-0.19,0.29-0.23c-0.39-0.01-0.74-0.02-1.05-0.02c0,0,0,0-0.01,0l-5.57,0c-0.47,0.18-0.71,0.37-0.97,0.59
			c-0.03,0.03-0.04,0.04-0.04,0.06c-0.81,0.72-1.21,2.05-0.74,4.43c0.31,1.57-0.09,0.48,0.6,4.7c0.07,0.42,0.09,0.79,0.1,1.09
			c-0.34,0-0.56-0.01-0.56-0.01s-0.04,1-0.01,1.86c0.02-0.05,0.05-0.1,0.07-0.16l0.12,0.63l0.66,0.15l0.77,0.26
			c-0.05,0.06-0.09,0.13-0.14,0.19c-0.01,0.02-0.03,0.04-0.04,0.05c0,0,2.65,0.31,2.65,0.31l0-0.25l0.6-0.11
			c0.27-0.26,0.3-0.7,0.3-1.09c0-0.34,0-0.68,0.01-1.02c0-0.02,0-0.03,0-0.05c0-0.13-0.01-0.26-0.05-0.38
			c-0.01-0.03-0.03-0.07-0.04-0.1c-0.05-0.09-0.13-0.16-0.21-0.23l-0.6-0.41l0-0.82h-0.51l0.03-0.6h0.48h0v-1.39l0.6-0.21l0.16-0.56
			c3.11-0.28,9.73-0.85,10.66-0.8l0.6-0.51c0.12-0.14,0.2-0.31,0.27-0.48c0.15-0.37,0.27-0.76,0.37-1.15
			C206.44,149.77,205.94,148.78,205.95,148.56z"
          />
          <path fill="none" d="M209.29,162.51h-8.92c1.2,1.03,2.76,1.66,4.46,1.66S208.08,163.55,209.29,162.51z" />
          <path
            fill="none"
            d="M196.16,161.01c-0.26-0.86-0.41-1.77-0.41-2.72c0-0.52,0.04-1.02,0.13-1.51h-1.75v4.23H196.16z"
          />
          <path
            fill="none"
            d="M190.25,164.47c0.13,0,0.26,0,0.4,0l0.39-0.38c-0.11-0.06-0.23-0.11-0.36-0.14
			c-0.08-0.02-0.17-0.03-0.25-0.03c-0.03,0-0.05,0-0.08,0c-0.07,0.13-0.11,0.27-0.11,0.43C190.24,164.4,190.25,164.43,190.25,164.47
			z"
          />
          <path fill="none" d="M193.69,161.58v0.33h2.79c-0.05-0.11-0.09-0.22-0.13-0.33H193.69z" />
          <path
            fill="none"
            d="M199.42,146.94c-0.6-0.1-1.13-0.21-1.47-0.32c-0.06-0.02-0.13-0.04-0.19-0.06c-0.58-0.14-1.16-0.3-1.7-0.47
			c-0.08-0.03-0.17-0.05-0.25-0.08c-0.03-0.01-0.07-0.02-0.1-0.03c-0.04-0.01-0.08-0.03-0.12-0.05c-0.18,0.44-0.64,0.44-1.06,0.63
			c-0.43,0.2-0.19-0.19,0.07-0.37c0.32-0.22,0.12-0.22-0.17-0.09c-0.05,0.02-0.43,0.22-0.8,0.4c-0.06,0.09-0.12,0.17-0.17,0.21
			c-0.09,0.05-0.15,0.06-0.16-0.05c-0.06,0.03-0.12,0.05-0.18,0.07c-0.02,0.01-0.03,0.01-0.05,0.02h3.14c0.03,0,0.06,0.02,0.06,0.05
			l0,0.2c0,0.04-0.02,0.07-0.06,0.07c0,0,0,0,0,0c0.31,0.01,0.66,0.01,1.05,0.02c0.08-0.03,0.17-0.05,0.27-0.05h1.87
			C199.41,147.02,199.41,146.98,199.42,146.94z"
          />
          <path
            fill="none"
            d="M211.58,162.51h-0.89c-0.67,0.76-1.49,1.39-2.41,1.84c0.63,0,1.22-0.01,1.76-0.01
			C210.64,163.82,211.16,163.2,211.58,162.51z"
          />
          <path
            fill="none"
            d="M198.96,162.51h-0.9c0.44,0.71,0.98,1.35,1.61,1.89c0.59,0,1.18-0.01,1.77-0.01
			C200.5,163.94,199.66,163.3,198.96,162.51z"
          />
          <path
            fill="none"
            d="M199.05,161.01h5.47v-1.44c-0.56-0.14-0.98-0.65-0.98-1.27c0-0.22,0.06-0.43,0.15-0.62
			c-0.01-0.05-0.01-0.1-0.01-0.15c0-0.29,0.11-0.56,0.28-0.76h-6.02c-0.01,0.14-0.01,0.28-0.01,0.42
			C197.92,158.61,198.34,159.92,199.05,161.01z"
          />
          <path
            fill="none"
            d="M198.09,155.66h6.42v-0.77c-1.72-0.06-3.98-0.19-6.09-0.32C198.29,154.93,198.17,155.29,198.09,155.66z"
          />
          <path
            fill="none"
            d="M196.94,157.28c-0.04,0.33-0.07,0.67-0.07,1.01c0,0.96,0.17,1.87,0.47,2.72h0.56
			C197.3,159.9,196.96,158.63,196.94,157.28z"
          />
          <path
            fill="none"
            d="M197.57,161.58c0.05,0.11,0.1,0.22,0.16,0.33h0.74c-0.08-0.11-0.16-0.22-0.24-0.33H197.57z"
          />
          <path
            fill="none"
            d="M205.73,151.58l-0.61,1.32v0.03c0.35,0.02,0.55,0.03,0.55,0.03S205.67,152.61,205.73,151.58z"
          />
          <path
            fill="none"
            d="M211.73,157.2c0-2.87-1.72-5.34-4.17-6.41c-0.14,1.58-0.4,4.15-1.16,4.15c-0.35,0-0.78-0.01-1.28-0.02v1.5
			c0.49,0.13,0.86,0.58,0.86,1.12c0,0.05,0,0.1-0.01,0.15c0.1,0.18,0.15,0.39,0.15,0.62c0,0.62-0.43,1.14-1,1.27v1.44h5.48
			C211.31,159.92,211.73,158.61,211.73,157.2z"
          />
          <path
            fill="none"
            d="M189.55,164.47c0-0.04-0.01-0.08-0.01-0.11c0-0.02,0-0.04,0-0.06c-0.05,0.06-0.1,0.12-0.15,0.18
			C189.45,164.47,189.5,164.47,189.55,164.47z"
          />
          <path fill="none" d="M199.45,161.58c0.09,0.11,0.19,0.23,0.29,0.33h4.77v-0.33H199.45z" />
          <path
            fill="none"
            d="M193.69,155.66h2.39c0.09-0.34,0.2-0.76,0.32-1.21c-0.98-0.06-1.87-0.12-2.57-0.17
			c-0.05,0.26-0.09,0.54-0.14,0.83V155.66z"
          />
          <path fill="none" d="M193.09,161.23v-0.12c-0.01,0-0.01,0-0.02,0C193.07,161.16,193.08,161.19,193.09,161.23z" />
          <path
            fill="none"
            d="M189.85,160.82c-0.09,0.01-0.35,0.01-0.56-0.16c-0.06,0.13-0.11,0.26-0.17,0.39
			c0.22-0.05,0.44-0.12,0.72-0.22C189.84,160.83,189.85,160.82,189.85,160.82z"
          />
          <path
            fill="none"
            d="M189.05,163.47l-1.01-0.02c-0.11,0.23-0.2,0.43-0.26,0.58l0.68,0.21C188.6,163.95,188.8,163.68,189.05,163.47
			z"
          />
          <path fill="none" d="M205.12,161.58v0.33h4.79c0.1-0.11,0.2-0.22,0.29-0.33H205.12z" />
          <path
            fill="none"
            d="M192.66,146.61c0.33-0.13,0.56-0.42,0.84-0.66c0.11-0.27,0.2-0.36,0.32-0.4c0.29-0.09,0.57-0.08,0.83-0.13
			c0,0,0.01,0,0.01,0c-0.47-0.28-0.58-0.17-0.58-0.17s-0.06-0.16-0.08-0.39c0,0-0.01,0-0.01,0c-0.76,0.56-1.15,1.08-1.47,1.18
			c-0.32,0.1,0.08-0.63,0.5-1.24c-0.21-0.02-0.36-0.01-0.38,0.08c-0.04,0.16-0.2,0.53-0.36,0.9c0,0.03,0,0.06,0,0.1
			c-0.01,0.28-0.13,0.39-0.22,0.43c-0.02,0.05-0.04,0.09-0.05,0.13c-0.06,0.17-0.21,0.29-0.33,0.32h0.81
			C192.47,146.71,192.53,146.66,192.66,146.61z"
          />
          <path
            fill="none"
            d="M211.18,161.91h0.74c0.54-1.09,0.85-2.32,0.85-3.62c0-0.34-0.02-0.67-0.06-1
			C212.69,159.02,212.12,160.61,211.18,161.91z"
          />
          <path
            fill="none"
            d="M193.51,162.51l-0.47,0.45c0,0,0,0,0,0s0,0-0.01,0l-0.35,0.34l0,0.23l-0.13,0c0.14,0.24,0.22,0.52,0.22,0.81
			c0,0.03,0,0.06,0,0.09c1.55-0.01,3.38-0.02,5.3-0.03c-0.51-0.57-0.95-1.21-1.3-1.9H193.51z"
          />
          <path
            fill="#D7BDB0"
            d="M199.47,134.87c0.02,0.05,0.03,0.08,0.03,0.08l0.02-0.01c0,0,0,0,0,0
			C199.51,134.91,199.49,134.89,199.47,134.87z"
          />
          <path
            fill="#D7BDB0"
            d="M196.78,131.67c-0.02,0.01-0.07,0.03-0.12,0.05l0.09,0.13c0.03-0.08,0.09-0.2,0.1-0.25
			C196.83,131.63,196.8,131.66,196.78,131.67z"
          />
          <path
            fill="#D7BDB0"
            d="M198.84,134.43c0.29-0.24,0.23-0.2,0.37-0.65c0.06-0.18,0.17,0.18,0.3,0.44c0.03,0.07,0.1,0.23,0.17,0.42
			c0.32-0.52,0.69-0.83,1.02-0.95c0.34-0.12,0.65-0.2,0.94-0.24c-0.5-0.47-0.82-0.82-1.01-1.24c-0.43,0.12-0.99,0.18-1.09-0.32
			c-0.17-0.84,0.07-1.42-0.66-1.37c-0.78,0.05-0.39,1.24-0.39,1.24l-0.34,0.07c-0.65-0.38-0.5-1.04-0.62-1.37s-0.55-1.21-1.04-0.6
			c-0.24,0.29-0.57,0.32-0.77,0.3c-0.05,0.3,0.07,0.66,0.09,1.04c0.01,0.39,0.03,0.41,0.21,0.57c0.2,0.18,0.2,0.61,0.13,0.96
			s-0.15,0.49-0.04,0.55c0.22,0.13,0.38-0.11,0.5,0.08c0.11,0.18,0.09,0.34,0.24,0.4c-0.01-0.01-0.03-0.02-0.04-0.04
			c-0.07-0.08-0.1-0.14,0.05-0.18c0.13-0.03,0.4-0.12,0.5-0.16c-0.01-0.01-0.02-0.01-0.03-0.02c-0.25-0.15-0.38-0.49-0.38-0.49
			s-0.02,0.07-0.09,0.17c-0.07,0.1-0.34,0.22-0.34,0.22s0.13-0.11,0.24-0.21c0.11-0.1,0.11-0.19,0.05-0.29
			c-0.05-0.1-0.1-0.12-0.2-0.11c-0.01,0-0.02,0-0.02,0c0.01,0,0.01,0,0.02,0c0.05-0.01,0.17-0.03,0.15-0.09
			c-0.03-0.09-0.02-0.24-0.02-0.24s0.23,0.49,0.32,0.66c0.1,0.17,0.19,0.25,0.37,0.38c0.18,0.12,0.31,0.51,0.27,0.46
			c-0.03-0.04-0.11-0.27-0.31-0.42c-0.03,0.09-0.15,0.3-0.24,0.41c-0.12,0.14-0.21,0.15-0.21,0.15s0.06-0.12-0.01-0.17
			c0.04,0.06-0.07,0.16,0.07,0.24c0.18,0.1,0.21,0.35,0.34,0.61c0.13,0.25,0.37,0.29,0.61,0.24c0.14-0.03,0.32-0.11,0.5-0.21
			c-0.15,0.07-0.26,0.13-0.26,0.13C198.5,134.59,198.57,134.65,198.84,134.43z M198.89,131.98c-0.06,0.1-0.15,0.04-0.1-0.12
			s-0.02-0.18-0.08-0.25c-0.06-0.07-0.02-0.1-0.02-0.1c0.11,0.1,0.22,0.03,0.29,0.08S198.95,131.88,198.89,131.98z M199.07,132.2
			c0,0,0.31-0.5,0.25-0.86C199.32,131.33,199.44,131.97,199.07,132.2z M198.65,130.94c0.03-0.08,0.09-0.22,0.29-0.22
			c0.19,0,0.28,0.18,0.36,0.31c0,0-0.23-0.26-0.39-0.19c-0.12,0.05-0.17,0.22-0.02,0.32s0.21,0.14,0.26,0.23c0.05,0.09,0,0.2,0,0.2
			s-0.04-0.07-0.09-0.15c-0.05-0.08-0.14,0.07-0.29-0.01C198.62,131.34,198.62,131.02,198.65,130.94z M196.13,131.51
			c-0.24-0.17-0.1-0.34,0.26-0.48s0.5,0.09,0.5,0.09C196.27,131.01,196.14,131.53,196.13,131.51z M196.61,133.12
			c-0.09,0.05-0.34,0.09-0.34,0.09c-0.01-0.05,0.1-0.08,0.26-0.11C196.68,133.07,196.69,133.06,196.61,133.12z M196.75,131.9
			c-0.02,0.02-0.02,0.02-0.02,0.01l-0.01,0.02c-0.11-0.08-0.14-0.14-0.15-0.16c-0.08,0.03-0.15,0.06-0.15,0.06l0.45-0.29l0.07-0.04
			C196.94,131.49,196.83,131.81,196.75,131.9z M196.46,131.7c0,0-0.07-0.12,0.11-0.19c0.18-0.07,0.48-0.12,0.48-0.12
			S196.49,131.53,196.46,131.7z"
          />
          <path
            fill="#9F7C63"
            d="M196.27,133.21c0,0,0.25-0.04,0.34-0.09c0.09-0.05,0.08-0.05-0.08-0.02
			C196.37,133.13,196.25,133.16,196.27,133.21z"
          />
          <path
            fill="#9F7C63"
            d="M196.96,133.97c0,0,0.1-0.01,0.21-0.15c0.09-0.11,0.21-0.32,0.24-0.41c0.2,0.15,0.28,0.38,0.31,0.42
			c0.04,0.04-0.09-0.34-0.27-0.46c-0.18-0.12-0.27-0.21-0.37-0.38c-0.1-0.17-0.32-0.66-0.32-0.66s0,0.15,0.02,0.24
			c0.02,0.06-0.1,0.08-0.15,0.09c0.1-0.02,0.15,0.01,0.2,0.11c0.05,0.11,0.05,0.2-0.05,0.29c-0.11,0.1-0.24,0.21-0.24,0.21
			s0.27-0.12,0.34-0.22c0.07-0.1,0.09-0.17,0.09-0.17s0.12,0.34,0.38,0.49c0.01,0.01,0.02,0.01,0.03,0.02
			c-0.1,0.03-0.37,0.13-0.5,0.16c-0.15,0.03-0.12,0.1-0.05,0.18c0.01,0.02,0.03,0.03,0.04,0.04c0.02,0.01,0.05,0.03,0.09,0.05
			c0,0,0,0,0,0C197.01,133.84,196.96,133.97,196.96,133.97z"
          />
          <path fill="#9F7C63" d="M196.62,132.65c-0.01,0-0.01,0-0.02,0C196.6,132.65,196.61,132.65,196.62,132.65z" />
          <path
            fill="#9F7C63"
            d="M196.46,131.7c0.03-0.17,0.59-0.31,0.59-0.31s-0.3,0.05-0.48,0.12C196.4,131.58,196.46,131.7,196.46,131.7
			z"
          />
          <path
            fill="#9F7C63"
            d="M196.42,131.82c0,0,0.07-0.03,0.15-0.06c0.01,0.02,0.04,0.09,0.15,0.16l0.01-0.02
			c0,0.01,0.01,0.01,0.02-0.01c0.08-0.09,0.19-0.41,0.19-0.41l-0.07,0.04L196.42,131.82z M196.75,131.85l-0.09-0.13
			c0.05-0.02,0.09-0.04,0.12-0.05c0.03-0.01,0.05-0.04,0.08-0.07C196.84,131.65,196.78,131.78,196.75,131.85z"
          />
          <path
            fill="#9F7C63"
            d="M196.89,131.12c0,0-0.13-0.23-0.5-0.09s-0.5,0.31-0.26,0.48C196.14,131.53,196.27,131.01,196.89,131.12z"
          />
          <path
            opacity="0.3"
            d="M215.26,167.19l-1.99-2.6c-0.13-0.16-0.34-0.26-0.56-0.26c-0.27,0-0.62,0-1.05,0
			c-1.66,1.92-4.1,3.13-6.82,3.13c-2.68,0-5.09-1.18-6.75-3.05c-1.92,0.01-3.76,0.02-5.3,0.03c0-0.03,0-0.06,0-0.09
			c0-0.3-0.08-0.57-0.22-0.81l-0.1,0l-0.29,0.29c0.28,0.37,0.45,0.83,0.45,1.33c0,1.21-0.97,2.2-2.17,2.2c-1.2,0-2.17-0.98-2.17-2.2
			c0-0.12,0.01-0.23,0.03-0.34l-0.23-0.07l-2.31,2.39c-0.37,0.38-0.05,0.95,0.52,0.95h28.43
			C215.23,168.09,215.55,167.58,215.26,167.19z"
          />
          <path
            opacity="0.3"
            d="M189.55,164.47c-0.05,0-0.1,0-0.15,0c-0.01,0.02-0.02,0.03-0.03,0.05l0.92,0.29l0.35-0.34
			c-0.14,0-0.27,0-0.4,0c0-0.04-0.01-0.07-0.01-0.11c0-0.16,0.04-0.3,0.11-0.43c-0.31,0.02-0.59,0.15-0.79,0.36
			c0,0.02,0,0.04,0,0.06C189.55,164.4,189.55,164.44,189.55,164.47z"
          />
          <path opacity="0.3" d="M193.69,156.18h2.26c0.04-0.14,0.08-0.31,0.13-0.51h-2.39V156.18z" />
          <path
            opacity="0.3"
            d="M210.69,162.51h-1.41c-1.2,1.03-2.76,1.66-4.46,1.66s-3.26-0.62-4.46-1.66h-1.4
			c0.69,0.78,1.54,1.43,2.49,1.88c-0.59,0-1.18,0.01-1.77,0.01c1.39,1.19,3.18,1.92,5.15,1.92c2,0,3.82-0.75,5.22-1.98
			c-0.54,0-1.13,0.01-1.76,0.01C209.2,163.9,210.02,163.27,210.69,162.51z"
          />
          <path
            opacity="0.3"
            d="M190.46,165.49l-1.26-0.39c0,0.02,0,0.04,0,0.07c0,0.69,0.55,1.24,1.23,1.24c0.09,0,0.17-0.01,0.26-0.03
			c0.56-0.11,0.98-0.61,0.98-1.21c0-0.24-0.07-0.47-0.19-0.66L190.46,165.49z"
          />
          <path
            opacity="0.3"
            d="M196.16,161.01h-2.03v-4.23h-0.43v0.4h0V158c0.08,0.07,0.16,0.14,0.21,0.23c0.02,0.03,0.03,0.06,0.04,0.1
			c0.05,0.09,0.06,0.22,0.05,0.38c0,0.02,0,0.03,0,0.05c0,0.34,0,0.68-0.01,1.02c0,0.39-0.04,0.83-0.3,1.09v0.7h2.66
			C196.28,161.39,196.21,161.2,196.16,161.01z"
          />
          <path
            opacity="0.3"
            d="M198.43,154.58c-0.21-0.01-0.42-0.03-0.63-0.04c-0.27,0.52-0.49,1.06-0.64,1.64h7.36v-0.51h-6.42
			C198.17,155.29,198.29,154.93,198.43,154.58z"
          />
          <path
            opacity="0.3"
            d="M204.51,161.58v-0.56h-5.47c-0.71-1.1-1.13-2.41-1.13-3.82c0-0.14,0.01-0.28,0.01-0.42h-0.91
			c-0.03,0.17-0.06,0.33-0.08,0.5c0.01,1.35,0.36,2.62,0.96,3.73h-0.56c0.07,0.19,0.14,0.38,0.23,0.56h0.67
			c0.07,0.11,0.15,0.22,0.24,0.33h1.27c-0.1-0.11-0.2-0.22-0.29-0.33H204.51z"
          />
          <path
            opacity="0.3"
            d="M207.56,150.75c0,0.01,0,0.02,0,0.04c2.45,1.07,4.17,3.53,4.17,6.41c0,1.41-0.42,2.72-1.13,3.82h-5.48v0.56
			h5.08c-0.09,0.11-0.19,0.23-0.29,0.33h1.27c0.94-1.3,1.51-2.89,1.53-4.62C212.33,154.27,210.3,151.77,207.56,150.75z"
          />
          <path
            d="M205.12,156.41v0.62c0.37,0.09,0.67,0.33,0.85,0.66c0.01-0.05,0.01-0.1,0.01-0.15C205.98,157,205.61,156.55,205.12,156.41
			z"
          />
          <path
            d="M203.68,157.69c0.17-0.32,0.47-0.56,0.83-0.65v-0.26h-0.56c-0.17,0.2-0.28,0.47-0.28,0.76
			C203.67,157.59,203.68,157.64,203.68,157.69z"
          />
          <polygon fill="#4D2C1A" points="193.05,162.97 193.04,162.97 193.05,162.97 		" />
          <path
            fill="#4D2C1A"
            d="M193.06,161.12l0.03,0.12v0C193.08,161.19,193.07,161.16,193.06,161.12
			C193.06,161.12,193.06,161.12,193.06,161.12z"
          />
          <path
            fill="#4D2C1A"
            d="M191.38,161.09c-0.31-0.03-0.94-0.17-0.94-0.17c0.01-0.02,0.03-0.04,0.04-0.05l-0.6-0.05
			c0,0-0.01,0-0.03,0c0,0-0.01,0-0.01,0.01c-0.28,0.1-0.5,0.17-0.72,0.22c-0.29,0.08-0.47,0.13-0.71,0.12
			c-0.14,0.02-0.3,0.03-0.49,0.05c-0.65,0.05-1.51,0.08-1.71,0.41c-0.25,0.42-0.07,1.19-0.07,1.19s0.22,0.01,0.58,0.04l5.51,0.09
			l0.87-0.85v-0.52C192.75,161.42,192.06,161.22,191.38,161.09z"
          />
          <path
            fill="#24B6DE"
            d="M189.85,160.82c0.02,0,0.03,0,0.03,0l0.6,0.05c0.31-0.39,0.6-0.81,0.61-1.3c0-0.27-0.08-0.54-0.07-0.81
			s0.2-0.57,0.47-0.57c-0.37-0.05-0.74-0.07-1.12-0.07c-0.06,0.13-0.12,0.28-0.18,0.44c-0.18,0.45-0.53,1.25-0.9,2.1
			C189.5,160.83,189.77,160.83,189.85,160.82z"
          />
          <path fill="#24B6DE" d="M194,158.71c0.01-0.16,0-0.29-0.05-0.38C193.99,158.45,194,158.58,194,158.71z" />
          <path
            fill="#24B6DE"
            d="M193.84,154.28c-0.05,0-0.1-0.01-0.14-0.01v0.84C193.74,154.82,193.79,154.54,193.84,154.28z"
          />
          <path
            fill="#24B6DE"
            d="M189.64,158.33c0.28-0.7,0.55-1.24,0.87-1.6c0.12-0.68,0.24-1.37,0.37-2.05c0.03-0.16,0.06-0.34,0-0.49
			s-0.67-1.37-0.76-2.1c-0.09-0.73,0.65-0.98,0.51-1.5c-0.07-0.28-0.34-0.45-0.53-0.68c-0.5-0.62-0.27-1.61,0.33-2.13
			c0.08-0.07,0.16-0.13,0.24-0.18c-0.67,0.11-1.03,0.18-1.04,0.12c-0.81,0.72-1.21,2.05-0.74,4.43c0.31,1.57-0.09,0.48,0.6,4.7
			c0.07,0.42,0.1,0.79,0.1,1.09c-0.34,0-0.56-0.01-0.56-0.01s-0.04,1-0.01,1.86C189.27,159.2,189.5,158.67,189.64,158.33z"
          />
          <path
            fill="#D7BDB0"
            d="M193.3,146.66c0,0.11,0.07,0.11,0.16,0.05c0.05-0.03,0.11-0.11,0.17-0.21c0.37-0.18,0.75-0.38,0.8-0.4
			c0.29-0.13,0.49-0.13,0.17,0.09c-0.26,0.18-0.51,0.57-0.07,0.37c0.43-0.2,0.88-0.2,1.06-0.63c0,0,0,0,0,0
			c-0.25-0.1-0.48-0.21-0.67-0.35c-0.09-0.07-0.18-0.12-0.25-0.16c0,0-0.01,0-0.01,0c-0.25,0.04-0.53,0.03-0.83,0.13
			c-0.12,0.04-0.21,0.13-0.32,0.4c-0.29,0.24-0.51,0.53-0.84,0.66c-0.13,0.05-0.19,0.1-0.19,0.14h0.6c0.02-0.01,0.03-0.01,0.05-0.02
			C193.18,146.71,193.24,146.69,193.3,146.66z"
          />
          <path
            fill="#9F7C63"
            d="M199.41,147.05h0.68c-0.23-0.04-0.46-0.07-0.68-0.11C199.41,146.98,199.41,147.02,199.41,147.05z"
          />
          <path
            fill="#4D2C1A"
            d="M203.85,136.15c0.11,0.92,0.53,3.67,0.36,5.21c-0.29,2.6-0.84,4.27-1.35,5.23
			c-0.19,0.07-0.4,0.11-0.64,0.15c-0.8,0.13-3.66-0.27-5.21-0.64c-1.55-0.38-1.95-0.62-2.11-0.86c-0.16-0.24-0.18-1.15-0.18-0.89
			c0,0.27-0.04,0.71-0.35,0.62c-0.3-0.09-0.38-0.22-0.39-0.23c0,0,0,0,0,0c0,0.04,0,0.09,0.01,0.13c0.02,0.22,0.08,0.39,0.08,0.39
			s0.11-0.11,0.58,0.17c0.07,0.04,0.16,0.1,0.25,0.16c0.19,0.14,0.42,0.25,0.67,0.35c0.07,0.03,0.15,0.06,0.23,0.08
			c0.08,0.03,0.16,0.05,0.25,0.08c0.51,0.16,1.09,0.29,1.7,0.47c0.06,0.02,0.13,0.04,0.19,0.06c0.34,0.1,0.87,0.21,1.47,0.32
			c0.22,0.04,0.45,0.07,0.68,0.11h4C204.47,145.25,205.31,140.16,203.85,136.15z"
          />
          <path
            fill="#111413"
            d="M188.26,165.17c0,1.21,0.97,2.2,2.17,2.2c1.2,0,2.17-0.98,2.17-2.2c0-0.5-0.17-0.96-0.45-1.33l0.29-0.29
			l0.1,0l0.13,0l0-0.23l0.35-0.34l0.01-0.01l0.47-0.45h3.26c0.35,0.69,0.79,1.33,1.3,1.9c1.66,1.87,4.07,3.05,6.75,3.05
			c2.72,0,5.16-1.21,6.82-3.13c1.4-1.61,2.25-3.73,2.25-6.04c0-4.06-2.61-7.5-6.23-8.71c0.8-5.59,1.03-7.43,1.03-7.43s0,0-0.4-0.21
			c0.1-0.28,0.26-0.71,0.4-1.05c0.21-0.51,0.36-0.59,0.86-0.44c0.1,0.03,0.22,0.07,0.33,0.1c0,0.12,0.08,0.22,0.19,0.26l2.93,0.87
			c0.14,0.04,0.29-0.04,0.33-0.18l0.1-0.34c0.04-0.14-0.04-0.3-0.18-0.34l-2.93-0.87c-0.1-0.03-0.2,0-0.27,0.07
			c-0.26-0.08-0.51-0.16-0.71-0.23c-0.4-0.14-0.8-0.05-0.99,0.37c-0.21,0.48-0.47,1.12-0.63,1.5c-0.03-0.01-0.06-0.03-0.09-0.04
			c0,0-0.58,1.45-1.06,3.4c-0.27,1.08-0.51,2.31-0.61,3.53c-0.02,0.22-0.04,0.43-0.05,0.63c-0.33-0.04-0.67-0.06-1.01-0.06
			c-0.12-0.33-0.28-0.6-0.49-0.82c-0.18-0.18-0.39-0.32-0.63-0.42h1.15c0.33,0,0.6-0.19,0.6-0.42c0-0.23-0.27-0.42-0.6-0.42h-0.83
			h-4h-0.68h-1.87c-0.1,0-0.19,0.02-0.27,0.05c-0.14,0.05-0.24,0.13-0.29,0.23c-0.02,0.04-0.04,0.09-0.04,0.14
			c0,0.23,0.27,0.42,0.6,0.42h1.36c-0.75,0.3-1.04,1.01-1.19,1.58c-0.06,0.23-0.21,0.79-0.39,1.49h-2.24c-0.93,0-1.61,0.5-1.87,1.33
			c-1.27,0.33-0.97,1.88-0.97,1.88s0.31,0.02,0.85,0.06v1.95h0h-1.39c-0.5,0-0.87,0.19-1.19,0.55c-0.32,0.36-0.58,0.9-0.87,1.6
			c-0.14,0.34-0.36,0.86-0.63,1.47c-0.19,0.43-0.4,0.9-0.6,1.37c-0.26,0.6-0.53,1.18-0.75,1.67l-0.95-0.02l0,0.03l-0.01,0.57
			l0.69,0.01c-0.18,0.4-0.29,0.65-0.3,0.66l-0.14,0.31l1.11,0.34l0.23,0.07C188.27,164.94,188.26,165.05,188.26,165.17z
			 M190.35,163.93c0.03,0,0.05,0,0.08,0c0.09,0,0.17,0.01,0.25,0.03c0.13,0.03,0.25,0.07,0.36,0.14l-0.39,0.38l-0.35,0.34
			l-0.92-0.29c0.01-0.02,0.02-0.03,0.03-0.05c0.04-0.06,0.09-0.13,0.15-0.18C189.76,164.08,190.04,163.95,190.35,163.93z
			 M190.68,166.38c-0.08,0.02-0.17,0.03-0.26,0.03c-0.68,0-1.23-0.56-1.23-1.24c0-0.02,0-0.04,0-0.07l1.26,0.39l1.01-0.99
			c0.12,0.19,0.19,0.42,0.19,0.66C191.66,165.77,191.24,166.27,190.68,166.38z M199.74,161.91h-1.27h-0.74
			c-0.06-0.11-0.11-0.22-0.16-0.33c-0.08-0.18-0.16-0.37-0.23-0.56c-0.3-0.85-0.47-1.77-0.47-2.72c0-0.34,0.02-0.68,0.07-1.01
			c0.02-0.17,0.05-0.34,0.08-0.5h0.91h6.02h0.56v0.26c-0.36,0.09-0.66,0.33-0.83,0.65c-0.1,0.18-0.15,0.39-0.15,0.62
			c0,0.61,0.42,1.13,0.98,1.27v1.44v0.56v0.33H199.74z M204.51,150.27c0,0.09,0.01,0.18,0.01,0.28v2.35
			c-1.22-0.07-3.26-0.18-5.29-0.3C200.59,151.23,202.45,150.35,204.51,150.27z M204.51,155.66v0.51h-7.36
			c0.15-0.57,0.37-1.12,0.64-1.64c0.21,0.01,0.42,0.03,0.63,0.04c2.11,0.13,4.37,0.26,6.09,0.32V155.66z M205.12,152.9v-0.19v-2.16
			c0-0.1,0-0.19-0.01-0.28c0.24,0.01,0.47,0.03,0.71,0.06c-0.02,0.28-0.04,0.53-0.05,0.76c-0.01,0.18-0.02,0.34-0.03,0.49
			c-0.06,1.03-0.06,1.38-0.06,1.38s-0.2-0.01-0.55-0.03V152.9z M193.84,154.28c0.7,0.05,1.59,0.11,2.57,0.17
			c-0.12,0.46-0.23,0.88-0.32,1.21c-0.05,0.2-0.1,0.38-0.13,0.51h-2.26v-0.51v-0.55v-0.84
			C193.74,154.27,193.79,154.28,193.84,154.28z M193.69,161.91v-0.33v-0.7V158v-0.82h0v-0.4h0.43h1.75c-0.08,0.49-0.13,1-0.13,1.51
			c0,0.95,0.14,1.86,0.41,2.72c0.06,0.19,0.12,0.38,0.2,0.56c0.04,0.11,0.09,0.22,0.13,0.33H193.69z M204.82,166.32
			c-1.96,0-3.76-0.72-5.15-1.92c-0.63-0.54-1.17-1.18-1.61-1.89h0.9h1.4h8.92h1.41h0.89c-0.42,0.69-0.94,1.3-1.54,1.83
			C208.65,165.58,206.82,166.32,204.82,166.32z M211.92,161.91h-0.74h-1.27h-4.79v-0.33v-0.56v-1.44c0.57-0.13,1-0.65,1-1.27
			c0-0.22-0.06-0.43-0.15-0.62c-0.17-0.33-0.48-0.57-0.85-0.66v-0.62v-1.5c0.49,0.02,0.92,0.02,1.28,0.02
			c0.76,0,1.03-2.57,1.16-4.15c0-0.01,0-0.02,0-0.04c2.74,1.02,4.78,3.52,5.15,6.54c0.04,0.33,0.06,0.66,0.06,1
			C212.77,159.6,212.46,160.82,211.92,161.91z M198.3,149.62c0.24-0.91,0.65-1.27,1.4-1.27c0.47,0,0.96,0,1.48-0.01
			c0.56-0.01,1.14-0.01,1.73-0.01c0.48,0,1.03,0.17,1.34,0.82c-2.61,0.16-4.92,1.44-6.47,3.37c-0.08-0.01-0.16-0.01-0.24-0.02
			C197.88,151.19,198.2,150,198.3,149.62z M195.08,151.56h2.08c-0.08,0.29-0.16,0.59-0.24,0.9c-1.14-0.08-2.17-0.15-2.94-0.22
			c-0.04,0-0.07,0-0.11-0.01C194.18,151.64,194.78,151.56,195.08,151.56z M189.12,161.05c0.06-0.13,0.11-0.26,0.17-0.39
			c0.38-0.85,0.72-1.65,0.9-2.1c0.06-0.16,0.12-0.3,0.18-0.44c0.45-1.02,0.79-1.34,1.32-1.34h1.39v4.34l-0.03,0l-0.01-0.04
			l-2.61-0.16l-0.41-0.03c0,0,0.15,0.02,0.38,0.06c0.24,0.04,0.57,0.1,0.92,0.17c0.68,0.13,1.43,0.3,1.76,0.44v0.52l-0.87,0.85
			l-3.9-0.08C188.56,162.31,188.84,161.67,189.12,161.05z M188.05,163.45l1.01,0.02c-0.25,0.21-0.45,0.47-0.59,0.77l-0.68-0.21
			C187.85,163.88,187.94,163.68,188.05,163.45z"
          />
          <path
            fill="#111413"
            d="M196.21,147.07c0.04,0,0.06-0.03,0.06-0.07l0-0.2c0-0.03-0.03-0.05-0.06-0.05h-3.14h-0.6h-0.81
			c-0.04,0.01-0.07,0.01-0.1,0h-1.39c-0.03,0-0.05-0.02-0.06-0.04l-1.82-5.83c-0.01-0.03-0.05-0.05-0.08-0.04
			c-0.15,0.06-0.24,0.22-0.19,0.38l1.83,5.81c0.01,0.03,0.03,0.04,0.06,0.04l0.73,0L196.21,147.07z"
          />
          <path
            fill="#D7BDB0"
            d="M191.66,146.75c0.12-0.03,0.27-0.15,0.33-0.32c0.02-0.04,0.03-0.09,0.05-0.13
			c0.09-0.04,0.2-0.15,0.22-0.43c0-0.03,0-0.06,0-0.1c0.16-0.37,0.32-0.74,0.36-0.9c0.02-0.09,0.18-0.09,0.38-0.08
			c-0.42,0.62-0.83,1.34-0.5,1.24c0.32-0.1,0.72-0.62,1.47-1.18c0,0,0.01,0,0.01,0c0-0.04-0.01-0.08-0.01-0.13c0,0,0,0,0,0
			c-0.03-0.41,0.01-1,0.12-1.3c0,0,0,0,0-0.01c-0.52,0.11-1.08-0.23-1.72,0.26c-0.03,0.02-0.05,0.05-0.08,0.08
			c-0.65,0.58-0.74,0.53-1.03,0.85c-0.29,0.33,0.33,0.59,0.33,1.01c0,0.11,0.03,0.16,0.07,0.17c-0.02,0.09-0.04,0.18-0.07,0.27
			c-0.06,0.22-0.17,0.34-0.17,0.45c0,0.13,0.04,0.2,0.11,0.22C191.59,146.76,191.63,146.76,191.66,146.75z"
          />
          <path
            fill="#9F7C63"
            d="M196.06,146.09c0.53,0.17,1.11,0.33,1.7,0.47C197.15,146.39,196.57,146.25,196.06,146.09z"
          />
          <path
            fill="#9F7C63"
            d="M195.71,145.98c0.03,0.01,0.07,0.02,0.1,0.03c-0.08-0.03-0.15-0.05-0.23-0.08c0,0,0,0,0,0
			C195.63,145.95,195.67,145.97,195.71,145.98z"
          />
          <path
            fill="#AD7D62"
            d="M193.99,144.74c0.01,0.01,0.09,0.14,0.39,0.23c0.31,0.09,0.35-0.35,0.35-0.62c0-0.27,0.02,0.64,0.18,0.89
			c0.16,0.24,0.55,0.49,2.11,0.86c1.55,0.38,4.41,0.78,5.21,0.64c0.24-0.04,0.45-0.08,0.64-0.15c0.51-0.96,1.06-2.63,1.35-5.23
			c0.17-1.55-0.25-4.3-0.36-5.21c1.46,4,0.62,9.1,0.24,10.9h0.83c0.33,0,0.6,0.19,0.6,0.42c0,0.23-0.27,0.42-0.6,0.42h-1.15
			c0.24,0.1,0.45,0.24,0.63,0.42c0.91,0.14,1.55,0.25,1.55,0.25c0.1-1.22,0.34-2.45,0.61-3.53c0.04-1.01-0.01-2.26-0.2-3.75
			c-0.14-1.12,0.15-3.4-1-5.44c-0.38-0.68-0.83-1.28-1.63-1.87c-0.53-0.39-1.08-0.59-1.73-0.56c-0.3-0.21-0.78-0.67-0.86-1.39
			l-0.31,0.15c0,0,0-0.01,0-0.02c-0.06,0.02-0.13,0.05-0.21,0.07c0.18,0.41,0.51,0.76,1.01,1.24c-0.29,0.04-0.6,0.12-0.94,0.24
			c-0.33,0.12-0.71,0.43-1.02,0.95c-0.07-0.2-0.13-0.36-0.17-0.42c-0.13-0.26-0.24-0.62-0.3-0.44c-0.14,0.45-0.08,0.41-0.37,0.65
			c-0.27,0.22-0.34,0.16-0.64,0.38c0,0,0.11-0.06,0.26-0.13c0.17-0.07,0.38-0.16,0.56-0.19c0.11-0.02,0.28,0.14,0.45,0.37
			c0.02,0.02,0.03,0.05,0.05,0.07c0,0,0,0,0,0c-0.52,1.07-0.79,2.84-0.16,5.39c0.16,0.64,0.26,1.24,0.32,1.8
			c0.03,0.75-0.26,0.94-0.38,0.99c-0.01,0-0.03,0-0.04,0c-1.21-0.11-4.92-0.17-5.14,0.31c0,0,0,0,0,0.01
			C194,143.74,193.96,144.32,193.99,144.74z"
          />
          <path
            fill="#111413"
            d="M201.06,129.91c0.2,0.92,0.19,1.53,0.16,1.85c0.06-0.47,0.11-1.51-0.32-2.54
			c-0.56-1.35-1.29-1.92-2.32-1.65c0.03-0.01,0.07-0.01,0.1-0.02C199.97,127.3,200.75,128.51,201.06,129.91z"
          />
          <path
            fill="#111413"
            d="M200.84,132.15c0,0.01,0,0.02,0,0.02l0.31-0.15l0.02-0.01C201.14,132.03,201.02,132.09,200.84,132.15z"
          />
          <path
            fill="#4D2C1A"
            d="M196.5,129.86c0.49-0.61,0.92,0.27,1.04,0.6s-0.03,0.99,0.62,1.37l0.34-0.07c0,0-0.39-1.18,0.39-1.24
			c0.73-0.05,0.49,0.53,0.66,1.37c0.1,0.5,0.65,0.45,1.09,0.32c0.07-0.02,0.14-0.04,0.21-0.07c0.17-0.06,0.3-0.12,0.33-0.14
			c0,0,0.01,0,0.01,0s0.02-0.09,0.04-0.25c0.03-0.32,0.04-0.93-0.16-1.85c-0.31-1.4-1.09-2.6-2.38-2.36
			c-0.03,0.01-0.07,0.01-0.1,0.02c-1.24,0.24-2.22,0.48-2.76,0.95c-0.52,0.45-0.63,1.2-0.26,1.61c0,0,0.07,0.02,0.17,0.03
			C195.93,130.18,196.26,130.15,196.5,129.86z"
          />
          <path
            fill="#9F7C63"
            d="M198.77,131.42c0.15,0.08,0.24-0.07,0.29,0.01c0.05,0.08,0.09,0.15,0.09,0.15s0.05-0.11,0-0.2
			c-0.05-0.09-0.11-0.14-0.26-0.23s-0.1-0.27,0.02-0.32c0.16-0.07,0.39,0.19,0.39,0.19c-0.08-0.13-0.17-0.31-0.36-0.31
			c-0.19,0-0.26,0.13-0.29,0.22S198.62,131.34,198.77,131.42z"
          />
          <path
            fill="#9F7C63"
            d="M198.69,131.51c0,0-0.04,0.03,0.02,0.1c0.06,0.07,0.12,0.09,0.08,0.25s0.05,0.22,0.1,0.12
			c0.06-0.1,0.15-0.35,0.08-0.39S198.8,131.61,198.69,131.51z"
          />
          <path
            fill="#9F7C63"
            d="M199.07,132.2c0.37-0.23,0.25-0.86,0.25-0.86C199.38,131.69,199.07,132.2,199.07,132.2z"
          />
        </g>
        <g id="woman-small">
          <path
            fill="none"
            d="M232.19,155.21l-0.18-1.06l-0.14,0.15c-0.24,0.31-0.33,0.36-0.47,0.49l-0.47,0.5c0,0,0,0,0,0
			c-1.29,1.4-2.33,2.32-1.9,3.35c0.95,2.27,2.47,4.3,2.78,4.7c0.21-0.02,0.45-0.08,0.65-0.16l-0.04,0.31c0,0,0.41-2.54,0.45-3.75
			c0.03-0.79-0.1-1.75-0.23-2.48c-0.13-0.64-0.3-1.31-0.46-2.18C232.18,155.12,232.18,155.17,232.19,155.21z"
          />
          <path
            fill="none"
            d="M232.44,163.48c0.01-0.06,0.02-0.13,0.04-0.2c-0.05,0.14-0.06,0.21-0.06,0.21S232.43,163.48,232.44,163.48z"
          />
          <path fill="none" d="M234.31,142.63c0-0.04,0-0.08,0-0.11C234.31,142.56,234.31,142.6,234.31,142.63z" />
          <path
            fill="none"
            d="M243.11,203.53c0.06-0.35,0.16-0.6,0.22-0.72c-0.03-0.17-0.06-0.35-0.09-0.53c-0.04-0.01-0.08-0.01-0.11-0.02
			c-0.09-0.02-0.14-0.04-0.15-0.06c-0.01-0.03-0.02-0.07-0.02-0.1l-0.36-1.53c0,0,0,0,0,0c-2.1-9.02-1.91-10.1-3.04-16.01
			c-1.14-5.97-1.75-10.41-2.07-13.37c-0.25,3.5-1.96,13.19-2.06,18.82c-0.08,4.37-0.47,8.56-0.98,10.57c0,0,0,0,0,0
			c-0.06,0.43-0.1,1.37-0.1,1.37c-0.12,0.05-0.26,0.07-0.42,0.09c-0.01,0.24-0.02,0.49-0.03,0.72c0.01-0.02,0.02-0.03,0.02-0.03
			s0.17,0.3,0.26,0.8c0,0,0.08,0.36-0.09,0.61c-0.02,0.03-0.05,0.06-0.09,0.09c1.36,0.33,3.57,1.24,5.32,1.26
			c2.66,0.03,2.86-1.11,3.9-1.37C243.04,203.87,243.11,203.53,243.11,203.53z"
          />
          <path fill="none" d="M236.35,142.66c0,0-0.06,0.04-0.13,0.08C236.3,142.71,236.35,142.66,236.35,142.66z" />
          <path
            fill="none"
            d="M241.85,155.56c0,0.07-0.01,0.15-0.01,0.23c0-0.03,0.01-0.06,0.01-0.09
			C241.85,155.64,241.85,155.6,241.85,155.56z"
          />
          <path
            opacity="0.3"
            d="M275.77,207.52c-5.69-0.66-20.9-2.65-28.55-3.27c0.72,0.36,1.72,0.59,1.68,1.04
			c0.05,0.55-0.14,1.03-1.44,1.04c-1.36,0.02-2.62-1.03-3.1-1.45c-0.48-0.42-0.98-0.51-1.16-0.75c0-0.01-0.01-0.01-0.01-0.02
			c-1.04,0.25-1.24,1.4-3.9,1.37c-1.74-0.02-3.95-0.93-5.32-1.26c-0.22,0.18-0.66,0.3-1.07,0.66c-0.48,0.42-1.83,1.44-3.18,1.43
			c-0.2,0-0.36-0.02-0.51-0.04c3.59,1.53,21.01,3.84,34.2,6.27C281,215.8,296.07,209.87,275.77,207.52z"
          />
          <path
            fill="#111413"
            d="M230.93,155.28C230.93,155.28,230.93,155.28,230.93,155.28l0.47-0.5c-0.02,0.02-0.04,0.03-0.06,0.05
			c0,0-0.47-0.08-0.73-0.29c0.01,0,0.01,0,0.02,0c-0.92-0.49-1.55-1.27-1.84-1.68C228.86,153.17,229.27,154.54,230.93,155.28z"
          />
          <path
            fill="#111413"
            d="M243.77,154.99c0.06,0.06,0.03,0.02,0.03,0.02c1.33-1.1,1.82-2.19,1.96-2.56
			c-0.39,0.55-1.26,1.67-2.24,2.27C243.66,154.87,243.73,154.95,243.77,154.99z"
          />
          <path
            fill="#664033"
            d="M233.04,203.14c0.47-0.03,0.76-0.31,0.84-0.39c0.02-0.24,0.03-0.49,0.03-0.72
			c-0.7,0.06-1.7-0.15-2.15-0.25c0,0.34,0,0.68,0.01,1c0.02-0.02,0.02-0.02,0.02-0.02S232.49,203.17,233.04,203.14z"
          />
          <path
            fill="#664033"
            d="M243.24,202.28c0.02,0.18,0.05,0.35,0.09,0.53c0.02-0.05,0.04-0.08,0.04-0.08s0.3,0.39,0.86,0.42
			c0.49,0.03,1.08-0.29,1.22-0.37c-0.02-0.21-0.04-0.43-0.06-0.65c-0.19,0.08-0.45,0.15-0.82,0.18
			C243.86,202.35,243.46,202.32,243.24,202.28z"
          />
          <path fill="#9F7C63" d="M245.92,201.04l-0.05,0.49C245.87,201.54,245.9,201.4,245.92,201.04z" />
          <path
            fill="#9F7C63"
            d="M243.13,202.26c-0.12-0.03-0.15-0.06-0.15-0.06l-0.02-0.1c0.01,0.03,0.02,0.07,0.02,0.1
			C242.99,202.23,243.05,202.24,243.13,202.26z"
          />
          <path
            fill="#42637C"
            d="M232.21,165.83c0.15,0.05,0.39,0.19,0.61,0.24c0.17,0.04,0.39,0.1,0.5,0.12
			c-0.15-0.05-0.35-0.21-0.63-0.31c-0.33-0.12-0.29-0.19-0.62-0.27c-0.08-0.02-0.16-0.04-0.24-0.06l-0.06,0.21l-0.01,0.04
			C231.77,165.81,232.06,165.78,232.21,165.83z"
          />
          <path
            fill="#42637C"
            d="M234.38,165.56c-0.15-0.04-0.41-0.19-0.41-0.19s0.15,0.11,0.24,0.23c0.13,0.01,0.21-0.01,0.27-0.04
			C234.44,165.56,234.41,165.56,234.38,165.56z"
          />
          <path
            fill="#42637C"
            d="M234.03,164.78c0,0,0.05,0.04,0.13,0.1c0.21-0.04,0.25-0.19,0.24-0.27l0,0
			C234.38,164.74,234.24,164.86,234.03,164.78z"
          />
          <path
            fill="#42637C"
            d="M232.83,165.42c-0.1-0.04,0.19,0.12,0.4,0.26c0.15,0.07,0.29,0.13,0.37,0.17
			c0.15,0.07,0.33,0.07,0.44,0.06c-0.03,0-0.06-0.01-0.09-0.02C233.76,165.81,232.97,165.48,232.83,165.42z"
          />
          <path
            fill="#D8BFB1"
            d="M233.92,202.03c0.16-0.01,0.3-0.04,0.42-0.09c0,0,0.04-0.94,0.1-1.37c0,0,0,0,0,0
			c-0.02,0-1.84,0.17-2.87-0.16c-0.02-0.01-0.03-0.01-0.05-0.02l0.02,1.32c0,0,0.09,0.02,0.23,0.06
			C232.22,201.88,233.22,202.09,233.92,202.03z"
          />
          <path
            fill="#D8BFB1"
            d="M242.59,200.57C242.59,200.57,242.59,200.57,242.59,200.57l0.36,1.53l0.02,0.1c0,0,0.03,0.03,0.15,0.06
			c0.03,0.01,0.07,0.01,0.11,0.02c0.22,0.04,0.62,0.06,1.32,0.02c0.37-0.02,0.63-0.09,0.82-0.18c0.52-0.23,0.49-0.59,0.49-0.59
			l0.05-0.49l0.05-0.51c0,0-0.01,0-0.03,0c-0.13,0-0.67,0.01-1.62,0.19C243.26,200.93,242.63,200.59,242.59,200.57z"
          />
          <path
            fill="#24B6DE"
            d="M242.53,153.93c0.04,0.04,0.07,0.07,0.11,0.12C242.61,153.99,242.57,153.94,242.53,153.93z"
          />
          <path
            fill="#24B6DE"
            d="M243.26,154.87c0.01,0,0.02-0.01,0.03-0.02c-0.13-0.17-0.22-0.29-0.31-0.39
			C243.14,154.68,243.26,154.87,243.26,154.87z"
          />
          <path
            fill="#24B6DE"
            d="M231.87,154.3l-0.53,0.54c0.02-0.02,0.04-0.04,0.06-0.05C231.54,154.66,231.63,154.6,231.87,154.3z"
          />
          <path
            fill="#24B6DE"
            d="M242.36,154.04c0,0.01-0.01,0.01-0.01,0.02c0.03-0.03,0.06-0.07,0.09-0.1
			C242.4,153.99,242.37,154.02,242.36,154.04z"
          />
          <path
            fill="#24B6DE"
            d="M241.88,158.3c-0.01,0.16-0.03,0.33-0.05,0.51C241.85,158.66,241.87,158.5,241.88,158.3z"
          />
          <path
            fill="#24B6DE"
            d="M241.79,160.59c-0.04-0.31-0.07-0.61-0.07-0.88C241.7,160.04,241.74,160.32,241.79,160.59z"
          />
          <path
            fill="#664033"
            d="M232.69,165.89c0.28,0.1,0.48,0.26,0.63,0.31c0.02,0.01,0.04,0.01,0.07,0.02
			c0.15,0.02,0.17-0.27,0.02-0.41c-0.04-0.03-0.1-0.08-0.18-0.13c-0.22-0.14-0.5-0.3-0.4-0.26c0.14,0.06,0.93,0.39,1.12,0.46
			c0.03,0.01,0.06,0.02,0.09,0.02c0.15,0.02,0.26-0.05,0.24-0.18c-0.01-0.04-0.03-0.09-0.07-0.14c-0.09-0.12-0.24-0.23-0.24-0.23
			s0.25,0.15,0.41,0.19c0.03,0.01,0.07,0,0.1-0.01c0.12-0.04,0.21-0.18,0.11-0.28c-0.08-0.09-0.29-0.27-0.43-0.38
			c-0.07-0.06-0.13-0.1-0.13-0.1c0.21,0.08,0.35-0.04,0.37-0.17c0.01-0.05-0.01-0.11-0.06-0.14c-0.15-0.12-0.68-0.48-1.28-0.77
			c-0.44-0.21-0.58-0.23-0.62-0.22c-0.02,0-0.02,0.01-0.02,0.01l0.04-0.31c-0.21,0.07-0.44,0.14-0.65,0.16
			c-0.31-0.4-1.83-2.43-2.78-4.7c-0.43-1.03,0.61-1.95,1.9-3.35c-1.67-0.74-2.07-2.12-2.14-2.41c0,0-0.01-0.01-0.01-0.01
			c-1.29,1.9-1.66,2.59-2.34,3.56c-1.56,2.23-0.63,2.42,2.16,5.95c0.37,0.47,0.7,0.81,0.99,1.05c0.24,0.24,0.45,0.43,0.61,0.53
			c0.62,0.37,0.71,1.05,1.02,1.28c0.23,0.17,0.41,0.26,0.63,0.32c0.07,0.02,0.15,0.04,0.24,0.06
			C232.4,165.69,232.37,165.77,232.69,165.89z"
          />
          <path
            fill="#42637C"
            d="M241.85,155.47c0-0.03,0-0.05-0.01-0.08c0,0,0,0.06,0,0.16c0,0.04,0,0.09,0,0.14
			C241.86,155.61,241.86,155.54,241.85,155.47z"
          />
          <path
            fill="#42637C"
            d="M242.01,161.9c0-0.14-0.02-0.28-0.03-0.42c-0.04-0.29-0.13-0.58-0.19-0.9c-0.05-0.26-0.09-0.55-0.07-0.88
			c0.01-0.15,0.02-0.3,0.05-0.47c0.03-0.15,0.05-0.29,0.06-0.43c0.02-0.18,0.04-0.35,0.05-0.51c0.04-0.54,0.02-1.01,0-1.5
			c-0.02-0.45-0.03-0.85-0.03-1.1c0,0.03-0.01,0.06-0.01,0.09c-0.08,0.61-0.45,1.51-1.36,1.86c0,0,0.77,0.65,0.94,1.45
			c0.17,0.8-0.1,0.43-0.06,1.66c0.03,0.95,0.23,0.89,0.46,1.57c0.1,0.3,0.14,0.46,0.15,0.55
			C241.99,162.69,242.02,162.3,242.01,161.9z"
          />
          <path
            fill="#42637C"
            d="M232.87,159.74c-0.04,1.21-0.45,3.75-0.45,3.75s0.01-0.07,0.06-0.21c0.05-0.16,0.16-0.4,0.36-0.75
			c0.37-0.64,0.76-1.28,0.6-2.23c-0.15-0.94-0.3-1.06,0.08-1.62c0.38-0.57,0.45-1.06,0.45-1.06c-1.17-0.08-1.32-0.87-1.55-1.47
			c0,0,0.11,0.46,0.22,1.1C232.77,157.99,232.9,158.95,232.87,159.74z"
          />
          <path
            fill="#111413"
            d="M232.91,204.89c0.41-0.36,0.85-0.48,1.07-0.66c0.03-0.03,0.06-0.06,0.09-0.09
			c0.18-0.24,0.09-0.61,0.09-0.61s0.01,0.28-0.47,0.54c-1.03,0.54-1.69,1.31-2.75,1.67c-0.63,0.21-2.06,0.39-2.49-0.24
			c-0.05-0.07-0.08-0.14-0.08-0.21c-0.05,0.46,0.02,0.87,0.85,0.99c0.15,0.02,0.31,0.03,0.51,0.04
			C231.08,206.33,232.43,205.31,232.91,204.89z"
          />
          <path
            fill="#9F7C63"
            d="M230.94,205.73c1.06-0.36,1.72-1.13,2.75-1.67c0.49-0.26,0.47-0.54,0.47-0.54
			c-0.09-0.51-0.26-0.8-0.26-0.8s-0.01,0.01-0.02,0.03c-0.08,0.09-0.36,0.37-0.84,0.39c-0.56,0.03-1.24-0.39-1.24-0.39
			s0,0-0.02,0.02c-0.04,0.03-0.15,0.12-0.45,0.37c-0.47,0.39-0.34,0.6-1.16,1.04c-0.72,0.39-1.84,0.63-1.8,1.1
			c0.01,0.06,0.03,0.13,0.08,0.21C228.88,206.12,230.31,205.95,230.94,205.73z"
          />
          <path
            fill="#111413"
            d="M246.33,205.73c-1.06-0.36-1.72-1.13-2.75-1.67c-0.49-0.26-0.47-0.54-0.47-0.54s-0.08,0.34,0.08,0.59
			c0,0.01,0.01,0.01,0.01,0.02c0.18,0.24,0.68,0.33,1.16,0.75c0.48,0.42,1.74,1.47,3.1,1.45c1.3-0.02,1.49-0.5,1.44-1.04
			c-0.01,0.06-0.03,0.13-0.08,0.2C248.39,206.12,246.96,205.95,246.33,205.73z"
          />
          <path
            fill="#9F7C63"
            d="M244.23,203.14c-0.56-0.03-0.86-0.42-0.86-0.42s-0.02,0.03-0.04,0.08c-0.06,0.12-0.16,0.37-0.22,0.72
			c0,0-0.01,0.28,0.47,0.54c1.03,0.54,1.69,1.31,2.75,1.67c0.63,0.21,2.06,0.39,2.49-0.24c0.05-0.07,0.08-0.14,0.08-0.2
			c0.04-0.45-0.96-0.69-1.68-1.04c-0.04-0.02-0.08-0.04-0.12-0.06c-0.82-0.45-0.69-0.66-1.16-1.04c-0.47-0.39-0.47-0.39-0.47-0.39
			s-0.01,0.01-0.03,0.02C245.31,202.85,244.72,203.17,244.23,203.14z"
          />
          <path
            fill="#42637C"
            d="M231.34,154.84l0.53-0.54l0.14-0.15l0.18,1.06c-0.01-0.04-0.01-0.09-0.01-0.14
			c0.03-0.47,0.56-1.35,0.39-1.26c-0.18,0.1-0.39,0.41-0.36,0.13c0.03-0.28,0.41-1.03,0.28-0.93s0,0.21-0.52,0.7
			c-0.51,0.48-1.07,0.77-1.36,0.84c-0.01,0-0.01,0-0.02,0C230.87,154.76,231.34,154.84,231.34,154.84z"
          />
          <path
            fill="#42637C"
            d="M242.36,154.04c0.01-0.02,0.04-0.05,0.07-0.08c0.02-0.02,0.04-0.03,0.06-0.04c0.01-0.01,0.03,0,0.04,0
			c0.04,0.01,0.08,0.06,0.11,0.12c0.03,0.04,0.05,0.08,0.07,0.11c0.1,0.12,0.18,0.21,0.26,0.31c0.09,0.11,0.18,0.22,0.31,0.39
			c0,0,0,0,0,0c0,0,0,0,0,0c-0.36-0.55-0.61-1.11-0.84-1.72c0.03,0.2,0.03,0.41,0,0.61c-0.05-0.22-0.19-0.01-0.31-0.2
			c-0.05,0.45,0.19,0.49-0.04,0.9c0.02-0.02,0.03-0.04,0.04-0.07c0.08-0.12,0.15-0.21,0.21-0.31
			C242.35,154.06,242.35,154.05,242.36,154.04z"
          />
          <path
            opacity="0.3"
            d="M244.35,177.25c0.02,0.25,0.05,0.49,0.07,0.73c0-0.02,0-0.05-0.01-0.07c-0.01-0.05-0.01-0.14-0.03-0.26
			C244.38,177.53,244.36,177.4,244.35,177.25z"
          />
          <path
            opacity="0.3"
            d="M244.27,176.46c0.05,0.5,0.09,0.92,0.12,1.18c0.01,0.12,0.02,0.21,0.03,0.26
			c-0.06-0.81-0.18-2.46-0.42-4.41c0.05,0.44,0.09,0.91,0.13,1.4C244.17,175.44,244.22,175.96,244.27,176.46z"
          />
          <path
            fill="#9F7C63"
            d="M243.64,170.83c0.16,1.13,0.45,3.89,0.63,5.63c-0.05-0.5-0.1-1.02-0.14-1.57
			c-0.04-0.49-0.09-0.96-0.13-1.4c-0.21-2.03-0.47-3.57-0.8-5c-0.19-0.8-0.4-1.56-0.64-2.37c0,0,0,0,0,0h0
			C242.54,166.12,243.36,168.89,243.64,170.83z"
          />
          <path
            fill="#AD7D62"
            d="M234.43,200.58c0.51-2.01,0.9-6.2,0.98-10.57c0.11-5.63,1.81-15.32,2.06-18.82
			c0.32,2.97,0.93,7.4,2.07,13.37c1.13,5.92,0.95,7,3.04,16.01c0.04,0.02,0.66,0.35,1.72,0.15c0.95-0.18,1.49-0.19,1.62-0.19
			c0.03-1.45-0.09-4.75-0.99-11.57c0-5.45-0.25-8.17-0.53-10.98c-0.02-0.24-0.05-0.49-0.07-0.73c-0.1-0.96-0.32-2.83-0.69-5.23
			c-0.54-3.55-2.82-4.33-3.81-4.38l0.06-0.01c-2.06,0.07-4.8-0.06-7.06-0.92c-0.43-0.11-0.77-0.24-0.97-0.37
			c-0.84,2.62-1.32,5.66-1.32,9.41c0,4.12,0.52,10.69,0.85,14.03s-0.11,7.24,0,9.24c0.03,0.56,0.09,1.02,0.15,1.4
			C232.59,200.74,234.41,200.58,234.43,200.58z"
          />
          <path
            fill="#24B6DE"
            d="M241.11,166.56c0.18-0.05,0.36-0.1,0.54-0.15C241.46,166.47,241.28,166.52,241.11,166.56z"
          />
          <path
            opacity="0.3"
            d="M241.11,166.56c0.18-0.05,0.36-0.1,0.54-0.15C241.46,166.47,241.28,166.52,241.11,166.56z"
          />
          <path
            fill="#24B6DE"
            d="M240.66,166.67c0.02,0,0.04-0.01,0.06-0.01C240.7,166.66,240.68,166.67,240.66,166.67z"
          />
          <path opacity="0.3" d="M240.66,166.67c0.02,0,0.04-0.01,0.06-0.01C240.7,166.66,240.68,166.67,240.66,166.67z" />
          <path
            opacity="0.3"
            d="M242.54,166.12c-0.16,0.06-0.32,0.11-0.48,0.17C242.22,166.23,242.38,166.17,242.54,166.12L242.54,166.12z"
          />
          <path
            fill="#664033"
            d="M233.93,142.81L233.93,142.81c-0.11,0.34-0.31,0.49,0.07,1.42c0.12,0.29,0.3,0.87,0.28,0.83
			c0.3,1.08,0.24,1.56,1.83,1.42c-0.06,0.37-0.38,0.72-0.53,0.83c0,0,0,0,0,0c-0.05,0.04-0.09,0.06-0.09,0.06h0
			c0.01,0,0.02,0.01,0.04,0.01c0.95,0.22,1.78,1.02,1.78,1.02l0.28,1.3l0.23-1.35c0.51-0.35,1.6-0.67,1.91-0.76c0,0,0.01,0,0.01,0
			c-0.03-0.02-0.06-0.05-0.1-0.07c-0.07-0.06-0.15-0.12-0.21-0.18c-0.01-0.01-0.01-0.02-0.02-0.03c-0.18-0.2-0.41-1.48-0.1-2.43
			c0.21-0.44,1.22-2.63,1.05-3.94c-0.19-1.41-0.86-2.45-2.59-2.6c-0.01,0-0.01,0-0.02-0.01c-0.25-0.08-0.54-0.16-0.78-0.03
			c-0.02,0.01-0.04,0.02-0.07,0.03c-0.01,0-0.03,0-0.04,0c-0.02,0-0.04-0.01-0.06-0.01c-0.26-0.08-0.52-0.06-0.71,0.13
			c-0.14-0.05-0.34-0.04-0.45,0.05c-0.03,0.03-0.06,0.06-0.09,0.09c-0.11,0.08-0.27,0.03-0.38,0.11c-0.08,0.05-0.11,0.16-0.2,0.19
			c-0.04,0.01-0.09,0.01-0.13,0.02c-0.11,0.02-0.2,0.11-0.24,0.22c-0.04,0.1-0.16,0.13-0.23,0.2c-0.04,0.05-0.11,0.21,0,0.34
			c0,0,0.01,0,0.01,0c-0.41,0.62-0.57,1.43-0.43,2.27c0.08-0.01,0.03,0.26,0.03,0.27c-0.01,0-0.03,0.01-0.03,0l0.03,0.21
			L233.93,142.81z"
          />
          <path
            fill="#111413"
            d="M235.49,147.37c1.97-0.63,3.16-2.88,2.49-1.97c-0.76,1.02-1.56,0.98-1.81,1.06
			c-0.04,0.01-0.06-0.01-0.06,0.01c0,0,0,0,0,0.01c-0.06,0.37-0.38,0.72-0.53,0.83c0,0,0,0,0,0
			C235.53,147.34,235.49,147.37,235.49,147.37L235.49,147.37z"
          />
          <path
            fill="#111413"
            d="M234.55,139.61c0.79-0.59,2.48,0.1,2.8,0.45c0.73,0.81,0.79,2.74,0.76,2.97c-0.03,0.24,0.3-1.24,0.94-1.09
			c0.5,0.12,0.47,0.82,0.2,1.23c-0.39,0.61-0.24,1.16,0.01,1.8c0,0,0.02-0.03,0.04-0.08c0.21-0.44,1.22-2.63,1.05-3.94
			c-0.19-1.41-0.86-2.45-2.59-2.6c-0.01,0-0.01,0-0.02-0.01c-0.25-0.08-0.54-0.16-0.78-0.03c-0.02,0.01-0.04,0.02-0.07,0.03
			c-0.01,0-0.03,0-0.04,0c-0.02,0-0.04-0.01-0.06-0.01c-0.26-0.08-0.52-0.06-0.71,0.13c-0.14-0.05-0.34-0.04-0.45,0.05
			c-0.03,0.03-0.06,0.06-0.09,0.09c-0.11,0.08-0.27,0.03-0.38,0.11c-0.08,0.05-0.11,0.16-0.2,0.19c-0.04,0.01-0.09,0.01-0.13,0.02
			c-0.11,0.02-0.2,0.11-0.24,0.22c-0.04,0.1-0.16,0.13-0.23,0.2c-0.04,0.05-0.11,0.21,0,0.34c0,0,0.01,0,0.01,0
			c0.01,0,0.01,0,0.01,0C234.43,139.67,234.49,139.65,234.55,139.61z"
          />
          <path
            fill="#111413"
            d="M236.52,142.08c0,0-0.01-0.17-0.35-0.24c-0.11-0.02-0.63-0.04-0.89,0.06c-0.26,0.09-0.33,0.35-0.13,0.37
			c0,0,0.22-0.11,0.57-0.2C236.08,141.97,236.52,142.08,236.52,142.08z"
          />
          <path
            fill="#111413"
            d="M236.35,142.66c0,0-0.44-0.33-1.11-0.01c0,0,0.16-0.04,0.39-0.05c-0.01,0.04-0.02,0.13,0.03,0.23
			c-0.13,0.01-0.2,0.01-0.2,0.01c0.12,0.03,0.28,0.12,0.55,0.02c0.05-0.02,0.14-0.07,0.21-0.11
			C236.3,142.7,236.35,142.66,236.35,142.66z M236.01,142.78c-0.03,0.01-0.06,0.01-0.09,0.02c0.02-0.03,0.05-0.13,0.02-0.2
			c0.1,0.01,0.2,0.02,0.3,0.04C236.21,142.67,236.11,142.76,236.01,142.78z"
          />
          <path
            fill="#111413"
            d="M236.32,142.52c-0.38-0.32-0.9-0.19-1.07-0.06c0.02-0.01,0.1-0.02,0.35-0.06
			C236.01,142.35,236.32,142.52,236.32,142.52z"
          />
          <path
            fill="#111413"
            d="M235.24,142.47C235.25,142.46,235.25,142.46,235.24,142.47C235.24,142.47,235.24,142.47,235.24,142.47z"
          />
          <path
            fill="#111413"
            d="M234.51,144.21c-0.03,0.04,0.07,0.03,0.28,0.07C234.95,144.31,234.53,144.17,234.51,144.21z"
          />
          <path
            fill="#111413"
            d="M234.8,145.38c0.3,0.01,0.5-0.12,0.69-0.27c0.05-0.04,0.09-0.09,0.12-0.15c0.16-0.14,0.34,0.1,0.34,0.1
			c-0.21-0.27-0.26-0.57-0.26-0.45c0,0.08,0,0.12-0.04,0.17c-0.05,0.03-0.15,0.09-0.3,0.03c-0.18-0.07-0.32-0.18-0.51-0.15
			c-0.17,0.03-0.14,0.11-0.19,0.09c-0.01,0-0.02-0.01-0.02-0.02c-0.08-0.06-0.14-0.11-0.28,0.02c0,0-0.04,0.05-0.07,0.12
			c-0.02,0.05-0.03,0.11-0.02,0.14c0,0.02,0.01,0.03,0.01,0.04c0,0,0,0,0,0c0.02,0.04,0.06,0.07,0.09,0.11
			C234.4,145.2,234.5,145.37,234.8,145.38z"
          />
          <path
            fill="#111413"
            d="M234.88,143.94c0.12,0.09,0.17,0.16,0.13,0.29c-0.04,0.13-0.26,0.09-0.26,0.09s0.19,0.08,0.31-0.06
			C235.19,144.13,234.88,143.94,234.88,143.94z"
          />
          <path
            fill="#111413"
            d="M233.96,142.2c0.01,0,0.01,0,0.01,0c0.21-0.03,0.42,0.09,0.48,0.14c0.07,0.05,0.13-0.24-0.07-0.35
			c-0.16-0.09-0.37-0.06-0.45-0.05c0,0-0.07,0.22-0.01,0.27C233.94,142.21,233.95,142.2,233.96,142.2z"
          />
          <path
            fill="#111413"
            d="M234.21,143.94c0.01-0.24,0.3-0.36,0.32-0.7c0.01-0.18-0.02-0.32-0.02-0.32c0.01,0.16-0.02,0.4-0.22,0.65
			c-0.21,0.27-0.37,0.66,0.17,0.7C234.47,144.27,234.2,144.18,234.21,143.94z"
          />
          <path
            fill="#111413"
            d="M234.35,142.77c-0.03,0-0.06,0-0.09,0l0.03,0c0,0,0.02-0.06,0.02-0.13c0-0.04,0-0.08,0-0.11
			c0.06,0.02,0.11,0.04,0.11,0.04c-0.21-0.17-0.45-0.15-0.45-0.15l0.01,0.1c0.03,0,0.06-0.01,0.09-0.01c0,0-0.02,0.14,0.05,0.27
			c-0.08-0.01-0.14-0.01-0.16-0.02c-0.01,0-0.01,0-0.01,0l-0.01,0.06l0,0C234.15,142.81,234.35,142.77,234.35,142.77z"
          />
          <path
            fill="#151616"
            d="M238.91,142.85c0.03,0.08,0.04,0.15,0.04,0.15s0.08-0.08,0.05-0.17c-0.03-0.09-0.07-0.14-0.18-0.26
			c-0.11-0.12-0.02-0.25,0.09-0.27c0.17-0.02,0.31,0.25,0.31,0.25c-0.04-0.12-0.08-0.3-0.25-0.35c-0.18-0.04-0.27,0.05-0.31,0.12
			c-0.05,0.06-0.12,0.34-0.01,0.44C238.76,142.88,238.88,142.77,238.91,142.85z"
          />
          <path
            fill="#151616"
            d="M238.63,143.27c0.07-0.07,0.22-0.26,0.17-0.31c-0.05-0.05-0.16-0.02-0.24-0.13c0,0-0.04,0.02,0,0.09
			c0.04,0.07,0.09,0.1,0.01,0.23C238.48,143.27,238.55,143.35,238.63,143.27z"
          />
          <path
            fill="#151616"
            d="M238.74,143.5c0.39-0.11,0.43-0.67,0.43-0.67C239.14,143.14,238.74,143.5,238.74,143.5z"
          />
          <path
            fill="#111413"
            d="M236.4,142.69c0.16,0.14,0.33-0.02,0.33-0.02C236.65,142.74,236.45,142.66,236.4,142.69z"
          />
          <path
            opacity="0.3"
            d="M244.42,177.96c0,0,0-0.02-0.01-0.06c0,0,0,0,0,0.01C244.42,177.94,244.42,177.96,244.42,177.96z"
          />
          <path
            fill="#24B6DE"
            d="M239.12,166.96c0.5-0.07,1.02-0.17,1.54-0.29c-4.27,0.97-7.94,0-7.94,0c0.04,0.02,0.08,0.03,0.12,0.04
			C234.25,167.07,236.7,167.26,239.12,166.96z"
          />
          <path
            opacity="0.3"
            d="M239.12,166.96c0.5-0.07,1.02-0.17,1.54-0.29c-4.27,0.97-7.94,0-7.94,0c0.04,0.02,0.08,0.03,0.12,0.04
			C234.25,167.07,236.7,167.26,239.12,166.96z"
          />
          <path
            fill="#24B6DE"
            d="M241.65,166.41c0,0-0.01,0-0.01,0C241.65,166.41,241.65,166.41,241.65,166.41
			C241.65,166.41,241.65,166.41,241.65,166.41z"
          />
          <path
            opacity="0.3"
            d="M241.65,166.41c0,0-0.01,0-0.01,0C241.65,166.41,241.65,166.41,241.65,166.41
			C241.65,166.41,241.65,166.41,241.65,166.41z"
          />
          <path
            fill="#24B6DE"
            d="M240.72,166.66c0.13-0.03,0.25-0.06,0.38-0.09C240.98,166.59,240.85,166.63,240.72,166.66z"
          />
          <path
            opacity="0.3"
            d="M240.72,166.66c0.13-0.03,0.25-0.06,0.38-0.09C240.98,166.59,240.85,166.63,240.72,166.66z"
          />
          <path fill="#24B6DE" d="M242.06,166.28c-0.01,0-0.01,0-0.02,0C242.05,166.29,242.06,166.28,242.06,166.28z" />
          <path
            opacity="0.3"
            d="M242.06,166.28c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0C242.05,166.29,242.06,166.28,242.06,166.28z"
          />
          <path
            opacity="0.3"
            d="M242.04,166.29C242.04,166.29,242.05,166.29,242.04,166.29c-0.06,0.02-0.13,0.04-0.2,0.06c0,0,0,0,0,0
			C241.91,166.33,241.98,166.31,242.04,166.29z"
          />
          <path
            opacity="0.3"
            d="M244.41,177.9C244.41,177.9,244.41,177.9,244.41,177.9c-0.01-0.06-0.01-0.14-0.03-0.26
			C244.4,177.76,244.41,177.85,244.41,177.9z"
          />
          <path
            opacity="0.3"
            d="M244.41,177.9C244.41,177.9,244.41,177.9,244.41,177.9c-0.01-0.06-0.01-0.14-0.03-0.26
			C244.4,177.76,244.41,177.85,244.41,177.9z"
          />
          <path
            opacity="0.3"
            d="M244.27,176.46c0.03,0.27,0.05,0.53,0.08,0.79c0.02,0.16,0.03,0.29,0.04,0.39
			C244.36,177.38,244.32,176.96,244.27,176.46z"
          />
          <path
            opacity="0.3"
            d="M244.27,176.46c0.03,0.27,0.05,0.53,0.08,0.79c0.02,0.16,0.03,0.29,0.04,0.39
			C244.36,177.38,244.32,176.96,244.27,176.46z"
          />
          <path
            fill="#9F7C63"
            d="M243.64,170.83c-0.28-1.94-1.09-4.71-1.09-4.71c-0.18,0.07-0.37,0.14-0.55,0.21
			c0.02-0.01,0.03-0.02,0.05-0.03c-0.07,0.02-0.13,0.04-0.2,0.06c-0.03,0.03-0.06,0.04-0.09,0.05c-0.02,0.01-0.03,0.01-0.05,0.01
			c-0.82,0.26-1.7,0.43-2.58,0.54c-2.42,0.3-4.87,0.11-6.28-0.25c2.26,0.86,5,0.99,7.06,0.92l-0.06,0.01
			c0.99,0.05,3.27,0.82,3.81,4.38c0.37,2.4,0.59,4.27,0.69,5.23c-0.03-0.26-0.05-0.52-0.08-0.79
			C244.09,174.72,243.8,171.96,243.64,170.83z"
          />
          <path
            opacity="0.3"
            d="M243.64,170.83c-0.28-1.94-1.09-4.71-1.09-4.71c-0.18,0.07-0.37,0.14-0.55,0.21
			c0.02-0.01,0.03-0.02,0.05-0.03c-0.07,0.02-0.13,0.04-0.2,0.06c-0.03,0.03-0.06,0.04-0.09,0.05c-0.02,0.01-0.03,0.01-0.05,0.01
			c-0.82,0.26-1.7,0.43-2.58,0.54c-2.42,0.3-4.87,0.11-6.28-0.25c2.26,0.86,5,0.99,7.06,0.92l-0.06,0.01
			c0.99,0.05,3.27,0.82,3.81,4.38c0.37,2.4,0.59,4.27,0.69,5.23c-0.03-0.26-0.05-0.52-0.08-0.79
			C244.09,174.72,243.8,171.96,243.64,170.83z"
          />
          <path
            fill="#24B6DE"
            d="M241.7,166.41c-0.02,0-0.03,0-0.05-0.01c0,0-0.01,0-0.01,0c-0.18,0.05-0.36,0.1-0.54,0.15
			c-0.13,0.03-0.25,0.06-0.38,0.09c-0.02,0-0.04,0.01-0.06,0.01c-0.52,0.12-1.04,0.22-1.54,0.29
			C240,166.85,240.88,166.67,241.7,166.41z"
          />
          <path
            opacity="0.3"
            d="M241.7,166.41c-0.02,0-0.03,0-0.05-0.01c0,0-0.01,0-0.01,0c-0.18,0.05-0.36,0.1-0.54,0.15
			c-0.13,0.03-0.25,0.06-0.38,0.09c-0.02,0-0.04,0.01-0.06,0.01c-0.52,0.12-1.04,0.22-1.54,0.29
			C240,166.85,240.88,166.67,241.7,166.41z"
          />
          <path
            opacity="0.3"
            d="M241.7,166.41c-0.02,0-0.03,0-0.05-0.01c0,0-0.01,0-0.01,0c-0.18,0.05-0.36,0.1-0.54,0.15
			c-0.13,0.03-0.25,0.06-0.38,0.09c-0.02,0-0.04,0.01-0.06,0.01c-0.52,0.12-1.04,0.22-1.54,0.29
			C240,166.85,240.88,166.67,241.7,166.41z"
          />
          <path
            fill="#24B6DE"
            d="M241.99,166.32c0.19-0.06,0.37-0.13,0.55-0.21c-0.16,0.06-0.32,0.11-0.48,0.17c-0.01,0-0.01,0-0.02,0.01
			C242.03,166.3,242.01,166.31,241.99,166.32z"
          />
          <path
            opacity="0.3"
            d="M241.99,166.32c0.19-0.06,0.37-0.13,0.55-0.21c-0.16,0.06-0.32,0.11-0.48,0.17c-0.01,0-0.01,0-0.02,0.01
			C242.03,166.3,242.01,166.31,241.99,166.32z"
          />
          <path
            opacity="0.3"
            d="M241.99,166.32c0.19-0.06,0.37-0.13,0.55-0.21c-0.16,0.06-0.32,0.11-0.48,0.17c-0.01,0-0.01,0-0.02,0.01
			C242.03,166.3,242.01,166.31,241.99,166.32z"
          />
          <path
            fill="#24B6DE"
            d="M244.66,150.51c-0.66-0.82-0.97-1.65-2.08-2.07c-0.3-0.13-0.7-0.23-1.24-0.34l-1.66-0.58
			c-0.01,0-0.02,0.01-0.03,0.01c0.03,0.02,0.06,0.05,0.1,0.07c0,0-0.01,0-0.01,0c-0.31,0.09-1.4,0.41-1.91,0.76l-0.23,1.35
			l-0.28-1.3c0,0-0.83-0.81-1.78-1.02c-0.01,0-0.02-0.01-0.04-0.01h0c0,0,0.04-0.02,0.09-0.06c-0.01,0-0.01,0-0.02-0.01
			c-0.18,0.04-0.8,0.26-1.37,0.4c-1.76,0.24-2.3,0.58-3.25,1.6c-1.79,1.92-2.32,3.3-2.32,3.3s0.06,0.1,0.17,0.26
			c0,0,0.01,0.01,0.01,0.01c0.29,0.41,0.92,1.19,1.84,1.68c0.28-0.07,0.85-0.35,1.36-0.84c0.52-0.49,0.39-0.59,0.52-0.7
			c0.13-0.1-0.26,0.64-0.28,0.93c-0.03,0.28,0.18-0.03,0.36-0.13c0.16-0.09-0.37,0.78-0.39,1.26c0.16,0.87,0.33,1.54,0.46,2.18
			c-0.11-0.64-0.22-1.1-0.22-1.1c0.23,0.6,0.38,1.4,1.55,1.47c0,0-0.08,0.49-0.45,1.06c-0.38,0.57-0.23,0.68-0.08,1.62
			s-0.23,1.58-0.6,2.23c-0.2,0.35-0.3,0.59-0.36,0.75c-0.01,0.07-0.02,0.13-0.04,0.2c0.04-0.01,0.18,0.01,0.62,0.22
			c0.6,0.29,1.12,0.66,1.28,0.77c0.05,0.04,0.07,0.09,0.06,0.14h0c0.01,0.08-0.03,0.22-0.24,0.27c0.14,0.11,0.36,0.29,0.43,0.38
			c0.09,0.11,0,0.25-0.11,0.28c-0.05,0.03-0.14,0.05-0.27,0.04c0.04,0.05,0.07,0.1,0.07,0.14c0.02,0.13-0.09,0.2-0.24,0.18
			c-0.11,0.01-0.29,0-0.44-0.06c-0.08-0.03-0.22-0.1-0.37-0.17c0.07,0.05,0.14,0.09,0.18,0.13c0.15,0.14,0.14,0.43-0.02,0.41
			c-0.02,0-0.04-0.01-0.07-0.02c-0.11-0.03-0.33-0.09-0.5-0.12c-0.23-0.05-0.47-0.19-0.61-0.24c-0.15-0.05-0.44-0.03-0.44-0.03
			l0.01-0.04c-0.08,0.15-0.12,0.2-0.12,0.2c-0.05,0.12,0.03,0.25,0.21,0.37c0.2,0.13,0.54,0.26,0.97,0.37
			c-0.04-0.02-0.08-0.03-0.12-0.04c0,0,3.67,0.98,7.94,0c0.02,0,0.04-0.01,0.06-0.01c0.13-0.03,0.25-0.06,0.38-0.09
			c0.18-0.05,0.36-0.1,0.54-0.15c0,0,0.46-0.16,0.4-0.13c0.01,0,0.01,0,0.02,0c0.16-0.05,0.32-0.11,0.48-0.17h0
			c0,0-0.02-0.06-0.04-0.17c-0.04,0.04-0.29-1.43-0.47-2.41c-0.03-0.18-0.05-0.36-0.08-0.55c0-0.01,0.01-0.03,0.01-0.04
			c0-0.02,0-0.04,0.01-0.06c-0.01-0.09-0.05-0.25-0.15-0.55c-0.23-0.68-0.43-0.63-0.46-1.57c-0.04-1.22,0.23-0.86,0.06-1.66
			c-0.17-0.8-0.94-1.45-0.94-1.45c0.91-0.35,1.28-1.25,1.36-1.86c0-0.08,0-0.16,0.01-0.23c0-0.1,0-0.16,0-0.16
			c0,0.02,0,0.05,0.01,0.08c0.04-0.5,0.14-0.84,0.28-1.09c-0.01,0.02-0.03,0.04-0.04,0.07c0.23-0.42-0.01-0.45,0.04-0.9
			c0.11,0.19,0.26-0.01,0.31,0.2c0.03-0.2,0.03-0.41,0-0.61c0.23,0.61,0.49,1.17,0.84,1.72c0.08-0.04,0.16-0.08,0.23-0.13
			c0.98-0.6,1.85-1.72,2.24-2.27c0.02-0.03,0.04-0.05,0.06-0.08c0.11-0.15,0.17-0.25,0.17-0.25S245.35,151.37,244.66,150.51z"
          />
          <path
            fill="#42637C"
            d="M242.67,165.35c0-0.02-0.06-0.86-0.06-0.86l-0.76-0.55l-1.02,0.23l0.04,0.28
			c-0.12,0.06-0.24,0.12-0.33,0.17c0.05,0,0.12,0,0.25,0c0.04-0.02,0.06-0.03,0.1-0.05l0.03,0.18l-0.68,0.5l0.05,0.15
			c-0.07,0.02-0.15,0.04-0.2,0.03c-0.05,0.07-0.02,0.14,0.16,0.14c0.27,0,0.29,0.29,0.84,0.18c0.4-0.08,0.83-0.21,1.07-0.29
			c0.02-0.02,0,0.36,0,0.36l-0.17,0.44c-0.05,0.04-0.1,0.07-0.15,0.11c0,0,0,0-0.01,0c0.07-0.02,0.13-0.04,0.2-0.06
			c0.13-0.08,0.29-0.19,0.45-0.34c0.06-0.05,0.12-0.11,0.17-0.17C242.66,165.78,242.67,165.36,242.67,165.35z"
          />
          <path
            fill="#664033"
            d="M248.87,157.02c-0.71-1.09-1.88-2.94-3.05-4.65c-0.02,0.02-0.04,0.05-0.06,0.08
			c-0.14,0.37-0.63,1.46-1.96,2.56c0,0,0.03,0.04-0.03-0.02c1.59,1.83,3.16,2.02,2,3.82c-0.81,1.25-2.6,4.08-3.03,4.76
			c-0.17,0.07-0.26,0.07-0.47,0.02c-0.08-0.02-0.16-0.04-0.24-0.07c-0.21-0.06-0.45-0.13-0.75-0.09c-0.42,0.06-0.69,0.59-0.99,0.8
			c-0.31,0.21,0.02,0.48,0.25,0.36c0,0,0,0,0.01,0c0.1-0.05,0.25-0.13,0.4-0.2c0.19-0.1,0.39-0.2,0.5-0.25
			c0.19-0.1,0.21,0.1,0.21,0.1s-0.17,0.02-0.48,0.17c-0.1,0.05-0.23,0.12-0.38,0.18c-0.31,0.15-0.65,0.31-0.76,0.4
			c-0.12,0.1-0.12,0.35,0.07,0.4c0.06,0.02,0.15,0.01,0.26-0.03c0,0,0.11,0.33,0.43,0.27s0.67-0.07,0.93-0.16
			c0.26-0.09,0.34-0.16,0.47-0.21c0.08-0.03,0.07,0.06-0.01,0.17c-0.04,0.05-0.09,0.09-0.16,0.13c-0.21,0.11-0.44,0.44-0.49,0.54
			c-0.03,0.07,0.01,0.26,0.13,0.3c0.03-0.01,0.03-0.01,0,0c0.02,0,0.03,0.01,0.05,0.01c0.02,0,0.03,0,0.05-0.01
			c0.03-0.01,0.06-0.03,0.09-0.05c-0.03,0.01-0.02,0.01,0,0c0,0,0,0,0.01,0c0.26-0.22,0.54-0.37,0.75-0.53
			c0.03-0.02,0.05-0.03,0.08-0.04c0.16-0.06,0.28,0.09,0.5-0.07c0.26-0.18,0.42-0.59,0.71-0.92c0.29-0.33,0.39-0.38,0.48-0.58
			c0,0,0.34-0.42,1.81-2.05S249.88,158.59,248.87,157.02z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <path
          fill="#D8BFB1"
          d="M251.3,234.58c0.01,0.01,0.02,0.02,0.03,0.02c-1.32-0.52-2.52,0.29-2.52,0.29l0.04-1.14
		c0,0-0.01,0.01-0.01,0.01c0.03-0.81,0.08-1.87,0.18-2.5c0.18-1.12,0.09-2.31-1-2.71c-1.09-0.41-1.84,0.14-1.84,0.14c0,0,0,0,0-0.01
		c0.01-0.05,0.06-0.34-0.03-0.67c-0.01-0.08-0.02-0.14-0.02-0.14c-0.01,0-0.01,0.01-0.02,0.02c-0.13-0.31-0.41-0.62-1.02-0.76
		c-2.36-0.55-3.26,3.39-3.26,3.39c-1.84,0.47-5.38,4.65-5.38,4.65c-1.27,0.69-2.68,1.88-3.52,2.66c-0.27,0.05-0.55,0.11-0.85,0.16
		c-2.76,0.51-4.16-0.71-9.5-0.64c-3.65,0.05-5.76,1.71-11.33,2.48c0.16,0.49-3.63,14.15-0.72,19.1c4.72-0.16,20.6,1.93,23.24,2.41
		c2.65,0.48,4.15-2.81,6.79-4.9c2.65-2.09,5.29-4.58,5.94-5.3c0.64-0.72,2-5.14,2.93-7.07c0.66-1.37,1.46-3.46,2.1-5.14
		c0.08-0.2,0.15-0.38,0.21-0.53c0.2-0.43,0.34-0.81,0.44-1.14c0.01-0.03,0.03-0.07,0.04-0.1C252.87,235.54,252.3,234.9,251.3,234.58
		z"
        />
        <path
          fill="#9F7C63"
          d="M186.33,234.06c-0.08-0.57-0.32-1.28-0.47-1.95c-0.03-0.15-0.06-0.3-0.08-0.44
		c-0.02-0.16-0.08-0.29-0.17-0.4s-0.19-0.19-0.32-0.26c-0.13-0.07-0.28-0.12-0.44-0.16c-0.16-0.04-0.33-0.07-0.5-0.1
		c-0.17-0.03-0.35-0.06-0.52-0.09c-0.26-0.05-0.5-0.11-0.71-0.2c-0.21-0.1-0.39-0.22-0.5-0.41c-0.19-0.31-0.25-0.64-0.24-0.94
		c0-0.06,0.01-0.12,0.01-0.18c0.01-0.12,0.04-0.23,0.06-0.33c0.03-0.1,0.06-0.2,0.09-0.28c0.03-0.08,0.07-0.16,0.1-0.22
		c0.06-0.12,0.11-0.19,0.11-0.19s-0.19,0.11-0.4,0.4c-0.07,0.1-0.14,0.21-0.21,0.35c-0.1,0.21-0.19,0.46-0.25,0.78
		c-0.04,0.21-0.06,0.45-0.07,0.71c0,0.26,0.01,0.56,0.06,0.88c0.02,0.16,0.05,0.33,0.09,0.51c0.13-0.09,0.31-0.18,0.52-0.24
		c0.15-0.05,0.32-0.09,0.5-0.12c0.12-0.02,0.24-0.03,0.36-0.04s0.24-0.01,0.36,0c0.24,0.01,0.47,0.05,0.67,0.13
		c0.05,0.02,0.1,0.04,0.14,0.06c0.32,0.17,0.59,0.37,0.77,0.67c0.05,0.08,0.1,0.18,0.14,0.28c0.08,0.2,0.13,0.43,0.15,0.7
		c0,0.07,0.01,0.14,0.01,0.21c0,0.15-0.01,0.31-0.02,0.48c-0.02,0.17-0.04,0.33-0.06,0.49c-0.06,0.47-0.14,0.86-0.21,1.16
		c-0.09,0.4-0.17,0.62-0.17,0.63c0,0,0.02-0.01,0.05-0.03c0.17-0.1,0.81-0.51,1.09-1.05c0.06-0.12,0.09-0.26,0.09-0.43
		C186.36,234.31,186.35,234.19,186.33,234.06z"
        />
        <path
          fill="#D8BFB1"
          d="M189.39,224.98c-0.35-2.02-3.07-0.66-6.31-0.23c-3.25,0.43-7.04-0.26-11.47-0.81
		c-1.7-0.21-3.34-0.46-4.72-0.71c0.06-0.02,0.1-0.03,0.13-0.03l-3.06-0.69c0.41-0.56,1.36-1.49,3.41-2.07
		c3.11-0.88,2.68-0.62,6.18-2.15c3.5-1.53,3.44-4.43-0.02-3.84c-3.47,0.59-1.61-0.65-5.46,0.72s-7.33,1.21-8.6,2.12
		c-2.52,1.8-5.43,2.86-8.5,5.19c-0.6,0.46-1.13,0.91-1.59,1.36c1.27,7.97-7.77,24.08-6.25,28.39c4,0.42,20.26-11.11,36.19-12.27
		c0.71-0.05,1.43-0.1,2.18-0.15c0.06-0.04,0.12-0.09,0.18-0.13c0.18-0.13,0.35-0.28,0.52-0.43c0.7-0.64,1.3-1.42,1.69-1.94
		c0.55-0.65,1.04-1.23,1.04-1.23s0.12-0.08,0.28-0.19c0.17-0.1,0.81-0.51,1.09-1.05c0.33-0.58,0.61-2,0.47-3.8
		c-0.08-1.04-0.26-3.08-0.26-3.08s1.6-0.43,2.37-1.38C189.25,226.15,189.5,225.62,189.39,224.98z"
        />
        <path
          fill="#9F7C63"
          d="M185.25,226.14c-3.05-0.19-3.51,1.68-4.61,2.48c-1.1,0.8-3.28,0.97-5.11,0.77
		c-1.23-0.14-1.23-2.53-1.23-2.53c-0.33,1.48,0.43,3.64,1.61,4.22c0,0,2.83-0.21,4.47-0.26c-0.8,0.96-0.7,2.59-1.18,2.92
		c-0.63,0.43-0.7,0.79-3.41,0.95c-3.33,0.2-7.39,0.57-3.58,0.66c1.31,0.03,1.42,0.42,2.81,0.15c1.38-0.27,3.49-0.25,3.49-0.25
		c0.47-0.45,1.88-1.68,1.88-1.68c0.14-1.71,1.07-3.4,1.07-3.4c0.24-0.16,0.62-4.04,3.18-3.77c0,0,1.33-0.04,1.83,1.58
		c0,0,1.6-0.43,2.37-1.38c0.08-0.1,0.15-0.2,0.21-0.3C189.06,226.28,187.29,226.26,185.25,226.14z"
        />
        <path
          fill="#9F7C63"
          d="M182.86,233.84c-0.06-1.21,0.7-2.66,0.7-2.66s-1.63,0.43-2.5,1.77c-0.87,1.33-0.64,3.13-0.64,3.13
		s-0.37-0.78-0.16-1.24c0.2-0.46-1.71,1.36-2.29,1.74c-0.58,0.38-1.85,0.44-2.91,0.76c-0.86,0.25-1.37,0.9-1.42,1.61
		c-0.96,0.19-1.15,0.64-3.41,0.64c-2.31,0-4.61,0.14-5.57,0.05c-0.96-0.09,0.93,0.29,2.23,0.24c1.31-0.04,2.48,0.06,4.1-0.02
		c1.37-0.07,2.44-0.07,2.99-0.11c0.06,0.01,0.12,0,0.19-0.02c0.02,0,0.04,0,0.06-0.01c0.04,0,0.06-0.02,0.08-0.03
		c0.65-0.21,1.64-0.99,2.4-1.12c1.13-0.19,2.12,0.11,2.4,0.68c0.11,0.21,0.16,0.48,0.19,0.73c0.71-0.05,1.43-0.1,2.18-0.15h0
		c0.06-0.04,0.12-0.09,0.18-0.13c0.18-0.13,0.35-0.28,0.52-0.43c0.7-0.64,1.3-1.42,1.69-1.94c0.12-0.16,0.22-0.3,0.3-0.4
		C184.56,236.4,182.91,235.05,182.86,233.84z"
        />
        <path fill="#9F7C63" d="M167.01,223.21l0.04,0.01C167.05,223.22,167.04,223.22,167.01,223.21z" />
        <path
          fill="#9F7C63"
          d="M158.95,229.33c0,0,4.24-2.07,6-4.21c1.16-1.41,1.7-1.78,1.93-1.87c0.06-0.02,0.1-0.03,0.13-0.03
		l-3.06-0.69l-0.03-0.01C163.26,223.7,163.05,227.28,158.95,229.33z"
        />
        <path
          fill="#42637C"
          d="M212.93,248.69c-0.08-3.05-0.71-5.55-1.04-6.69c-0.03-0.09-0.05-0.17-0.07-0.24
		c-0.04-0.12-0.07-0.22-0.09-0.29c-0.19-0.72-0.37-1.32-0.52-1.77c-0.12-0.36-0.22-0.63-0.27-0.77c-0.04-0.09-0.06-0.14-0.06-0.14
		c-17.46-0.09-19.76,0.4-29.35,1.04c-0.02,0-0.03,0-0.05,0c-0.75,0.05-1.47,0.1-2.18,0.15c-15.92,1.16-22.18,2.69-26.19,2.27
		c-1.52-4.31-2.48-10.43-3.75-18.39c-0.26-1.63-0.53-3.33-0.83-5.11c-2.26-13.66-1.96-15.5-3.61-25.58
		c-1.55-19.78-7.68-33.92-37.93-44.04c-1.55-0.39-3.16-0.77-4.84-1.13c-0.16-0.05-0.32-0.11-0.5-0.17c0.01,0.04,0.02,0.06,0.02,0.06
		c-3.21,0.46-6.37,0.76-10.37,0.58c0,0,0,0-0.01,0c0,0.01,0.01,0.02,0.01,0.02s-47.89-17.47-46.97-18.47
		c-5.43,0.99-24.77,6.04-24.47,30.84c0.01,0.89-0.01,1.69-0.05,2.42c0,28.09,6.32,44.65,11.44,63.89c1.94,7.29,3.7,14.96,4.88,23.78
		c0.37,2.75,0.68,5.61,0.93,8.61c0.17,2.08,0.3,4.15,0.4,6.19c0.6,12.57-0.07,24.24-0.95,33.12c-0.44,4.45-0.94,8.2-1.36,11.01
		c-0.11,0.74-0.22,1.42-0.31,2.03c-0.03,0.2-0.06,0.4-0.1,0.59c-0.28,0.58-0.55,1.2-0.83,1.83c-0.16,0.36-0.32,0.73-0.48,1.11
		c-0.14,0.32-0.28,0.66-0.41,1c0,0,0,0,0,0c-0.05,0.12-0.1,0.24-0.15,0.36c-4.87,12.06-9.76,31.57-11.89,62.97
		c-2.92,42.96,4.05,113.36,3.4,137.48c-1.04,38.92-2.35,54.64-3.43,66.18c-0.49,4.4-0.84,7.52-0.99,8.88
		c-0.14,1.37-0.39,4.15-0.36,6.82c0.01,1.02,0.06,2.03,0.17,2.93c0.81,3.57,3.85,6.15,8.04,7.88c0.81,0.33,1.66,0.64,2.55,0.91
		c3.84,1.03,11.17,2.53,18.74,1.51c0,0-0.53,0.09-1.35,0.29c0.42-0.02,0.85-0.05,1.27-0.08c2.9-0.21,5.67-0.63,8.13-1.22
		c0.32-5.64,5.68-101.1,8.55-126.42c3.24-28.66,6.33-68.42,12.29-77.06c0.45,5.56,2.1,20.56,3.47,31.81l-0.02,5.66
		c0.04,0.25,0.07,0.59,0.12,0.99c0.05-0.59,0.11-1.18,0.16-1.77c3.37,29.33,4.79,66.19,5.66,105.42
		c0.35,15.57,0.55,29.97,0.78,41.92c0.71,18.04,1.17,30.28,1.17,30.28c0.1,0.15,0.19,0.29,0.29,0.44c2.96,4.23,8.37,6.71,13.71,8.16
		c-0.17,4.53-2.91,11.67-3.4,12.9c0.03-0.02,0.05-0.04,0.07-0.05c0.02-0.01,0.02-0.02,0.02-0.02l0,0c0,0,2.72,0.13,6.31,1.85
		c0.17,0.08,0.34,0.17,0.52,0.26c0.35,0.18,0.71,0.37,1.07,0.58c0.36,0.21,0.73,0.44,1.1,0.68s0.75,0.51,1.12,0.79
		c0.38,0.28,0.76,0.58,1.14,0.9c0.19,0.16,0.38,0.33,0.57,0.5c0.19,0.17,0.38,0.35,0.57,0.53c0,0,0.03,0.03,0.09,0.08
		c0.02,0.02,0.04,0.04,0.07,0.06c0.19,0.17,0.53,0.49,1,1c0.33,0.36,0.73,0.82,1.17,1.38c0.18,0.22,0.36,0.46,0.56,0.72
		c0.58,0.77,1.23,1.69,1.92,2.79c0.35,0.55,0.7,1.14,1.07,1.77c0.37,0.64,0.74,1.32,1.12,2.04c0.25,0.48,0.51,0.99,0.77,1.52
		c1.42,2.89,3.16,5.58,5.13,8.11c0.66,0.84,1.34,1.67,2.04,2.47c0,0,0,0,0,0c-0.75-1.81-1.67-4.2-2.59-6.83
		c-0.1-0.29-0.2-0.59-0.31-0.88c-0.2-0.59-0.41-1.2-0.6-1.81c-0.7-2.14-1.36-4.34-1.91-6.46c-0.16-0.61-0.3-1.2-0.44-1.79
		c-0.27-1.17-0.5-2.31-0.66-3.36c-0.21-1.32-0.32-2.52-0.32-3.55c0-0.41,0.03-0.8,0.07-1.15c0.09-0.72,0.2-1.41,0.32-2.05
		c0.04-0.21,0.08-0.43,0.13-0.63c0.26-1.24,0.58-2.33,0.91-3.28c0.61-1.74,1.3-3.01,1.86-3.89c0.21-0.32,0.4-0.58,0.57-0.8
		c0.17-0.22,0.31-0.38,0.42-0.51c0.08-0.09,0.15-0.16,0.19-0.2c0.04-0.04,0.06-0.06,0.06-0.06l0.05-1.67
		c4.97-0.52,8.53-2.16,8.94-5.32c2.2-17.25,11.7-170.27,10.88-230.45c-0.48-35.14-6.52-66.12-11.28-81.45
		c0.33-0.11,0.66-0.23,0.95-0.34c-0.01-0.24-0.56-3.59-0.6-3.82c-1.23-8.08-1.66-13.34-1.67-17.54c0.19-2.37,0.43-9.84,0.68-12.07
		c-0.16-0.01-0.32-0.04-0.47-0.06c0.16,0.02,0.32,0.04,0.47,0.06c1.71,0.15,3.36-0.03,5.4-0.04c1.97-0.02,4.32-0.16,7.24-0.38
		c0.02,0,0.03,0,0.05,0c1.3,0.03,2.7-0.07,4.18-0.25c0.08-0.01,0.16-0.02,0.25-0.03c0.04,0,0.07-0.01,0.11-0.01c0,0,0,0,0,0
		c0.42-0.05,0.84-0.12,1.27-0.19c0.02,0,0.04,0,0.06,0c0.02,0,0.04,0,0.06,0h0l0,0l0,0c1.56-0.25,3.18-0.59,4.86-1c0,0,0,0,0,0
		c0.08-0.02,0.17-0.04,0.25-0.06c0,0,0.01,0,0.01,0c6.46-1.61,13.61-4.22,20.04-6.73c0.15-0.05,0.31-0.11,0.47-0.17
		c14.47-5.44,33.26-13.91,33.26-13.91C212.52,255.56,213.02,251.84,212.93,248.69z"
        />
        <path
          fill="#D8BFB1"
          d="M128.47,99.94c-0.79-1.55-3.52-6.86-4.49-9.23c-0.22-0.53-0.36-0.99-0.44-1.36
		c-0.02,0.05-0.03,0.09-0.05,0.13c0,0,0.16,0.19,0.19,0.59c0.04,0.41-0.39-1.13-0.99-1.12c-0.1,0-0.14-0.03-0.14-0.09
		c-0.21,0.22-0.44,0.52-0.72,0.93c-0.06,0.09,0.85-1.89,1.97-2.71c0.22-0.56,0.52-1.38,0.68-2.2c0.02-0.08,0.37-0.55,0.6-0.85
		c0.14-0.13,0.46-0.57,0.46-0.57c0.24-0.45,0.41-0.92,0.28-1.44c-0.03-0.8-0.15-1.94-0.19-2.35c-0.08-1.01-0.13-1.86-3.27-13.51
		c-0.15-0.55-0.34-1.1-0.54-1.64c-2.44-3.2-7.33-7.02-7.33-7.02s-69,74-67,77s31.37,14.17,43.84,14.01c0.05,0,0.1-0.02,0.15-0.02
		c3.91,0.16,7.02-0.14,10.18-0.59c0,0-0.01-0.02-0.02-0.06c-0.2-0.58-1.77-5.4-1.77-9.78c0-2.14,0.03-3.68,0.1-4.85
		c0.33-1.8,1.33-5.34,4.17-7.16c2.24-1.43,3.77-1.39,7.97-0.47c2.93,0.64,4.87,0.87,4.65,0.7c3.09-0.1,3.87-2.92,3.71-4.69
		c-0.18-1.96,0-2.75,1.55-4.79c0,0,0.62-0.53,0.5-1.77c-0.12-1.24-1.46-2.72-1.46-2.72s0.03-0.06,0.08-0.17
		c0.43-0.06,1.28,0.32,1.82,0.09c1.32-0.56,1.43-1.59,1.22-2.23c-0.35-1.29-1.04-3.98-1.04-3.98s0.04-0.05,0.11-0.14
		c1.21-0.94,3.05-2.1,4.12-2.79c0.69-0.44,0.94-1,0.99-1.15C128.79,101.37,128.86,100.72,128.47,99.94z M97.11,112.31
		c-0.28,3.77-0.72,7.02-1.31,9.94c-0.81,4.02-1.88,7.43-3.16,10.72c-0.09-0.29-0.14-0.46-0.14-0.46l4.43-28.04
		C97.18,106.97,97.31,109.62,97.11,112.31z"
        />
        <path fill="none" d="M122.49,110.9c0.09-0.03,0.18-0.08,0.26-0.15C122.67,110.81,122.58,110.86,122.49,110.9z" />
        <path
          fill="none"
          d="M144.81,219.24c-0.17-1.12-0.35-2.25-0.53-3.38C144.46,216.99,144.64,218.12,144.81,219.24z"
        />
        <path fill="none" d="M110.16,647.77c0.38,0.28,0.76,0.58,1.14,0.9C110.92,648.35,110.54,648.05,110.16,647.77z" />
        <path fill="none" d="M27.25,492.58c0.26,17.91-1.66,43.1-3.55,64.06C25.63,536.05,27.52,510.85,27.25,492.58z" />
        <path fill="none" d="M119.45,659.54c0.25,0.48,0.51,0.99,0.77,1.52C119.96,660.53,119.7,660.02,119.45,659.54z" />
        <path fill="none" d="M120.67,111.65c0.28-0.59,0.46-1.06,0.62-1.75C121.13,110.47,120.92,111.07,120.67,111.65z" />
        <path fill="none" d="M127.31,295.11c0.19-0.7,0.37-1.42,0.53-2.15C127.67,293.69,127.49,294.41,127.31,295.11z" />
        <path fill="none" d="M146.29,256.84c-0.01,0.01-0.02,0-0.02-0.01C146.27,256.84,146.28,256.85,146.29,256.84z" />
        <path
          fill="none"
          d="M119.93,113.42c0.07-0.27,0.15-0.52,0.24-0.74c-0.37,0.86-0.52,2-0.58,2.79c0.03-0.27,0.06-0.55,0.1-0.83
		C119.75,114.24,119.82,113.83,119.93,113.42z"
        />
        <path
          fill="none"
          d="M121.69,107.54c0-0.01,0-0.03,0-0.04c0,0.02,0,0.05,0,0.07c0-0.01,0-0.01,0-0.02
		C121.69,107.55,121.69,107.55,121.69,107.54z"
        />
        <g>
          <path
            fill="#9F7C63"
            d="M38.53,618.6c-1.38,3.54,0.15,8.3,1.07,10.6c3.77,7.59,4.5,14.53,7.6,24.89
			c3.12,10.39,4.84,12.45,7.61,11.76c0.52-0.13,1.12-0.22,1.75-0.25c-1.7-2.11-6.15-8.36-7.46-17.5
			c-1.61-11.29-3.71-26.62-0.08-35.55c-0.42,0.03-0.84,0.06-1.27,0.08C45.25,613.25,39.96,614.96,38.53,618.6z"
          />
          <path
            fill="#FFFFFF"
            d="M38.53,618.6c-1.38,3.54,0.15,8.3,1.07,10.6c3.77,7.59,4.5,14.53,7.6,24.89
			c3.12,10.39,4.84,12.45,7.61,11.76c0.52-0.13,1.12-0.22,1.75-0.25c-1.7-2.11-6.15-8.36-7.46-17.5
			c-1.61-11.29-3.71-26.62-0.08-35.55c-0.42,0.03-0.84,0.06-1.27,0.08C45.25,613.25,39.96,614.96,38.53,618.6z"
          />
          <path
            fill="#D7BFB1"
            d="M38.53,618.6c-1.38,3.54,0.15,8.3,1.07,10.6c3.77,7.59,4.5,14.53,7.6,24.89
			c3.12,10.39,4.84,12.45,7.61,11.76c0.52-0.13,1.12-0.22,1.75-0.25c-1.7-2.11-6.15-8.36-7.46-17.5
			c-1.61-11.29-3.71-26.62-0.08-35.55c-0.42,0.03-0.84,0.06-1.27,0.08C45.25,613.25,39.96,614.96,38.53,618.6z"
          />
        </g>
        <g>
          <path
            fill="#9F7C63"
            d="M27.82,609.92c0,0.05,0,0.09,0,0.14c0.38,0.13,1.27,0.42,2.55,0.77
			C29.49,610.56,28.63,610.26,27.82,609.92z"
          />
          <path
            fill="#FFFFFF"
            d="M27.82,609.92c0,0.05,0,0.09,0,0.14c0.38,0.13,1.27,0.42,2.55,0.77
			C29.49,610.56,28.63,610.26,27.82,609.92z"
          />
          <path
            fill="#D7BFB1"
            d="M27.82,609.92c0,0.05,0,0.09,0,0.14c0.38,0.13,1.27,0.42,2.55,0.77
			C29.49,610.56,28.63,610.26,27.82,609.92z"
          />
        </g>
        <g>
          <path
            fill="#9F7C63"
            d="M147.08,678.08c-0.09,0.01-0.18,0.02-0.27,0.02c0.39,0.03,0.81,0.06,1.26,0.11
			C147.8,678.1,147.47,678.04,147.08,678.08z"
          />
          <path
            fill="#FFFFFF"
            d="M147.08,678.08c-0.09,0.01-0.18,0.02-0.27,0.02c0.39,0.03,0.81,0.06,1.26,0.11
			C147.8,678.1,147.47,678.04,147.08,678.08z"
          />
          <path
            fill="#D7BFB1"
            d="M147.08,678.08c-0.09,0.01-0.18,0.02-0.27,0.02c0.39,0.03,0.81,0.06,1.26,0.11
			C147.8,678.1,147.47,678.04,147.08,678.08z"
          />
        </g>
        <g>
          <path
            fill="#9F7C63"
            d="M124.41,635.14c-0.17,0.22-0.36,0.48-0.57,0.8c-0.57,0.88-1.25,2.15-1.86,3.89
			c-0.33,0.95-0.65,2.04-0.91,3.28c-0.04,0.21-0.09,0.42-0.13,0.63c-0.12,0.64-0.23,1.33-0.32,2.05c-0.04,0.35-0.07,0.74-0.07,1.15
			c-0.01,1.03,0.11,2.23,0.32,3.55c0.17,1.06,0.39,2.19,0.66,3.36c0.14,0.59,0.28,1.19,0.44,1.79c0.55,2.12,1.21,4.33,1.91,6.46
			c0.2,0.61,0.4,1.21,0.6,1.81c0.1,0.3,0.2,0.59,0.31,0.88c0.92,2.63,1.84,5.02,2.59,6.83c2.95,3.39,6.27,6.47,9.69,9.28
			c0.07,0.06,0.14,0.1,0.21,0.15c1.04,0.5,2.09,0.79,3.21,0.9c0.85,0,1.77-0.09,2.77-0.1c0.81-0.11,1.68-0.29,2.62-0.54
			c0,0-0.32-2.66-1.85-3.23c0,0,0.21-0.02,0.58-0.03c-3.42-0.4-6.95-2.48-8.91-7.7c-2.44-6.5-4.07-9.76-7.31-17.48
			c-1.73-4.12-3.12-9.86-3.36-18.45c-0.04,0.04-0.11,0.11-0.19,0.2C124.72,634.76,124.58,634.93,124.41,635.14z"
          />
          <path
            fill="#FFFFFF"
            d="M124.41,635.14c-0.17,0.22-0.36,0.48-0.57,0.8c-0.57,0.88-1.25,2.15-1.86,3.89
			c-0.33,0.95-0.65,2.04-0.91,3.28c-0.04,0.21-0.09,0.42-0.13,0.63c-0.12,0.64-0.23,1.33-0.32,2.05c-0.04,0.35-0.07,0.74-0.07,1.15
			c-0.01,1.03,0.11,2.23,0.32,3.55c0.17,1.06,0.39,2.19,0.66,3.36c0.14,0.59,0.28,1.19,0.44,1.79c0.55,2.12,1.21,4.33,1.91,6.46
			c0.2,0.61,0.4,1.21,0.6,1.81c0.1,0.3,0.2,0.59,0.31,0.88c0.92,2.63,1.84,5.02,2.59,6.83c2.95,3.39,6.27,6.47,9.69,9.28
			c0.07,0.06,0.14,0.1,0.21,0.15c1.04,0.5,2.09,0.79,3.21,0.9c0.85,0,1.77-0.09,2.77-0.1c0.81-0.11,1.68-0.29,2.62-0.54
			c0,0-0.32-2.66-1.85-3.23c0,0,0.21-0.02,0.58-0.03c-3.42-0.4-6.95-2.48-8.91-7.7c-2.44-6.5-4.07-9.76-7.31-17.48
			c-1.73-4.12-3.12-9.86-3.36-18.45c-0.04,0.04-0.11,0.11-0.19,0.2C124.72,634.76,124.58,634.93,124.41,635.14z"
          />
          <path
            fill="#D7BFB1"
            d="M124.41,635.14c-0.17,0.22-0.36,0.48-0.57,0.8c-0.57,0.88-1.25,2.15-1.86,3.89
			c-0.33,0.95-0.65,2.04-0.91,3.28c-0.04,0.21-0.09,0.42-0.13,0.63c-0.12,0.64-0.23,1.33-0.32,2.05c-0.04,0.35-0.07,0.74-0.07,1.15
			c-0.01,1.03,0.11,2.23,0.32,3.55c0.17,1.06,0.39,2.19,0.66,3.36c0.14,0.59,0.28,1.19,0.44,1.79c0.55,2.12,1.21,4.33,1.91,6.46
			c0.2,0.61,0.4,1.21,0.6,1.81c0.1,0.3,0.2,0.59,0.31,0.88c0.92,2.63,1.84,5.02,2.59,6.83c2.95,3.39,6.27,6.47,9.69,9.28
			c0.07,0.06,0.14,0.1,0.21,0.15c1.04,0.5,2.09,0.79,3.21,0.9c0.85,0,1.77-0.09,2.77-0.1c0.81-0.11,1.68-0.29,2.62-0.54
			c0,0-0.32-2.66-1.85-3.23c0,0,0.21-0.02,0.58-0.03c-3.42-0.4-6.95-2.48-8.91-7.7c-2.44-6.5-4.07-9.76-7.31-17.48
			c-1.73-4.12-3.12-9.86-3.36-18.45c-0.04,0.04-0.11,0.11-0.19,0.2C124.72,634.76,124.58,634.93,124.41,635.14z"
          />
        </g>
        <path
          fill="#D8BFB1"
          d="M181.48,239.83c0.02,0,0.03,0,0.05,0c0.23-0.18,0.45-0.37,0.65-0.56c-0.17,0.16-0.34,0.3-0.52,0.43
		C181.59,239.74,181.54,239.79,181.48,239.83z"
        />
        <path
          fill="#D8BFB1"
          d="M246.18,228.68c0.01-0.05,0.06-0.34-0.03-0.67C246.18,228.18,246.21,228.47,246.18,228.68z"
        />
        <path
          fill="#9F7C63"
          d="M243.58,235.82c0.08,0.01,0.17,0.02,0.24,0.03c0.51-1.92,1.17-4.83,1.53-5.8c0.27-0.72,0.49-1.12,0.73-1.29
		c0.07-0.05,0.09-0.07,0.1-0.07c0,0,0,0,0,0c0.01-0.01,0,0,0,0c0,0,0,0,0-0.01c0.03-0.21,0-0.5-0.03-0.67
		c-0.01-0.08-0.02-0.14-0.02-0.14c-0.01,0-0.01,0.01-0.02,0.02C244.95,228.56,244.33,233.22,243.58,235.82z"
        />
        <path
          fill="#9F7C63"
          d="M239.18,236.5c0.18-0.46,1.18-1.66,1.18-1.66c0.18-1.12,0.85-2.78,0.85-2.78c-0.59,1-1.18,2.7-1.18,2.7
		c-0.52,0.53-1.68,1.55-2.24,2.03c0.37-0.07,0.77-0.15,1.19-0.23L239.18,236.5z"
        />
        <path
          fill="#D8BFB1"
          d="M251.75,238.4c0.2-0.43,0.34-0.81,0.44-1.14c-0.18,0.45-0.4,1.03-0.65,1.67
		C251.62,238.73,251.69,238.55,251.75,238.4z"
        />
        <path
          fill="#9F7C63"
          d="M251.3,234.58c0.01,0.01,0.02,0.02,0.03,0.02c0.63,0.49,0.24,2.17-0.81,4.07c-1.07,1.93-3.29,6.59-4.43,8.2
		c-1.14,1.61-2.93,2.09-5.22,3.38c-1.77,1-3.94,0.14-6-0.79c0-0.03-0.02-0.06-0.07-0.08c-1.22-0.51-3.09-1.78-3.09-1.78
		s-0.33-0.21,1.3-1.29c1.64-1.08,5.05-1.94,6.08-2.38c0.3-0.13,0.63-0.27,0.99-0.45c-1.43,1.22-2.54,2.61-2.98,3.18
		c1.46-1.88,3.97-3.68,3.97-3.68s0.01-0.02,0.01-0.02c0.68-0.38,1.46-0.86,2.39-1.51c0.37-0.26,0.68-0.54,0.93-0.81
		c-1.13,2.16-2.27,4.33-2.27,4.33c-1.34,0.66-3.9,2.76-3.9,2.76c-2.33-1.55-4.71-0.53-4.71-0.53c1.96-0.31,1.17,0.46,2.48,0.35
		c1.31-0.12,2,0.93,2.9,0.08c0.9-0.85,3.34-2.44,3.34-2.44s1.79-2.79,2.38-3.83c0.59-1.05,1.55-3.02,2.55-4.49
		c1-1.47,1.66-1.97,1.66-1.97l0.04-1.14c0,0-0.01,0.01-0.01,0.01c-0.09,0.09-1.72,1.66-2.65,3.43c-0.01,0.02-0.02,0.05-0.04,0.07
		c0.01,0.06,0.04,0.11,0.05,0.18c0.31,1.98-3.19,3.17-3.89,3.39c-1.19,0.6-2.77,1.28-4.74,2.04c-1.85,0.71-3.85,1.83-5.5,2.84
		c-2.08,1.09-15.21,2.69-19.13,2.99c0.09,3.15-0.4,6.86-2.4,10.24c4.72-0.16,20.6,1.93,23.24,2.41c2.65,0.48,4.15-2.81,6.79-4.9
		c2.65-2.09,5.29-4.58,5.94-5.3c0.64-0.72,2-5.14,2.93-7.07c0.66-1.37,1.46-3.46,2.1-5.14c0.24-0.64,0.47-1.22,0.65-1.67
		c0.01-0.03,0.03-0.07,0.04-0.1C252.87,235.54,252.3,234.9,251.3,234.58z"
        />
        <path
          fill="#E8E7D9"
          d="M240.79,237.03c0,0-0.42,2.67,1.4,3.83c0,0,0.04-0.01,0.12-0.04c0.7-0.22,4.2-1.41,3.89-3.39
		c-0.01-0.06-0.03-0.12-0.05-0.18C245.58,234.89,241.65,236.72,240.79,237.03z"
        />
        <path
          fill="#42637C"
          d="M19.58,599.49c-0.06,0.9,0.02,1.75,0.2,2.56c-0.11-0.9-0.16-1.91-0.17-2.93
		C19.6,599.24,19.59,599.36,19.58,599.49z"
        />
        <path
          fill="#42637C"
          d="M81.47,439.66c-1.36-11.25-3.02-26.25-3.47-31.81c-5.96,8.64-9.05,48.4-12.29,77.06
		c-2.87,25.32-8.23,120.78-8.55,126.42c5.56-1.34,9.56-3.6,9.82-6.5c0.52-5.74,8.05-87.57,14.58-158.51
		c-0.04-0.41-0.08-0.74-0.12-0.99L81.47,439.66z"
        />
        <g>
          <path
            fill="#85BED3"
            d="M26.19,673.66c0.13,0.13,0.27,0.14,0.36-0.18c-0.06,0.08-0.13,0.13-0.2,0.14
			C26.3,673.64,26.24,673.65,26.19,673.66z"
          />
          <path
            fill="#FFFFFF"
            d="M26.19,673.66c0.13,0.13,0.27,0.14,0.36-0.18c-0.06,0.08-0.13,0.13-0.2,0.14
			C26.3,673.64,26.24,673.65,26.19,673.66z"
          />
          <path
            fill="#41647D"
            d="M26.19,673.66c0.13,0.13,0.27,0.14,0.36-0.18c-0.06,0.08-0.13,0.13-0.2,0.14
			C26.3,673.64,26.24,673.65,26.19,673.66z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M22.64,664.41c-0.23-5.24-0.74-12.12-1.53-15.58C21.75,652.45,22.28,658.98,22.64,664.41z"
          />
          <path
            fill="#FFFFFF"
            d="M22.64,664.41c-0.23-5.24-0.74-12.12-1.53-15.58C21.75,652.45,22.28,658.98,22.64,664.41z"
          />
          <path
            fill="#41647D"
            d="M22.64,664.41c-0.23-5.24-0.74-12.12-1.53-15.58C21.75,652.45,22.28,658.98,22.64,664.41z"
          />
        </g>
        <g>
          <path fill="#85BED3" d="M29.8,653.43c0.1-0.07,0.19-0.13,0.26-0.17C29.95,653.22,29.86,653.27,29.8,653.43z" />
          <path fill="#FFFFFF" d="M29.8,653.43c0.1-0.07,0.19-0.13,0.26-0.17C29.95,653.22,29.86,653.27,29.8,653.43z" />
          <path fill="#41647D" d="M29.8,653.43c0.1-0.07,0.19-0.13,0.26-0.17C29.95,653.22,29.86,653.27,29.8,653.43z" />
        </g>
        <g>
          <path fill="#85BED3" d="M17.88,635.7c0.14-1.07,0.37-2.07,0.66-3.01C18.15,633.73,17.91,634.75,17.88,635.7z" />
          <path fill="#FFFFFF" d="M17.88,635.7c0.14-1.07,0.37-2.07,0.66-3.01C18.15,633.73,17.91,634.75,17.88,635.7z" />
          <path fill="#41647D" d="M17.88,635.7c0.14-1.07,0.37-2.07,0.66-3.01C18.15,633.73,17.91,634.75,17.88,635.7z" />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M23.17,673.5l0.11-0.02c-0.04-0.06-0.09-0.13-0.12-0.21C23.17,673.42,23.17,673.5,23.17,673.5z"
          />
          <path
            fill="#FFFFFF"
            d="M23.17,673.5l0.11-0.02c-0.04-0.06-0.09-0.13-0.12-0.21C23.17,673.42,23.17,673.5,23.17,673.5z"
          />
          <path
            fill="#41647D"
            d="M23.17,673.5l0.11-0.02c-0.04-0.06-0.09-0.13-0.12-0.21C23.17,673.42,23.17,673.5,23.17,673.5z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M60.39,672.07c-0.47,0.14-1.35,0.38-2.53,0.63C58.9,672.51,59.73,672.29,60.39,672.07z"
          />
          <path
            fill="#FFFFFF"
            d="M60.39,672.07c-0.47,0.14-1.35,0.38-2.53,0.63C58.9,672.51,59.73,672.29,60.39,672.07z"
          />
          <path
            fill="#41647D"
            d="M60.39,672.07c-0.47,0.14-1.35,0.38-2.53,0.63C58.9,672.51,59.73,672.29,60.39,672.07z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M47.21,654.09c-3.11-10.36-3.83-17.3-7.6-24.89c0.34,0.84,0.59,1.36,0.59,1.36s-0.9-1.4-2.39-3.03
			c1.87,4.22,0.48,8.16,3.45,16.9c3.57,10.5,5.53,20.28,6.53,22.96c2.57,6.92,5.63,4.77,12.94,4.55c2.35-0.89,2.07-1.82,2.07-1.82
			c-0.28-3.69-3.53-4.66-6.23-4.52c-0.63,0.03-1.23,0.12-1.75,0.25C52.04,666.55,50.33,664.49,47.21,654.09z"
          />
          <path
            fill="#FFFFFF"
            d="M47.21,654.09c-3.11-10.36-3.83-17.3-7.6-24.89c0.34,0.84,0.59,1.36,0.59,1.36s-0.9-1.4-2.39-3.03
			c1.87,4.22,0.48,8.16,3.45,16.9c3.57,10.5,5.53,20.28,6.53,22.96c2.57,6.92,5.63,4.77,12.94,4.55c2.35-0.89,2.07-1.82,2.07-1.82
			c-0.28-3.69-3.53-4.66-6.23-4.52c-0.63,0.03-1.23,0.12-1.75,0.25C52.04,666.55,50.33,664.49,47.21,654.09z"
          />
          <path
            fill="#41647D"
            d="M47.21,654.09c-3.11-10.36-3.83-17.3-7.6-24.89c0.34,0.84,0.59,1.36,0.59,1.36s-0.9-1.4-2.39-3.03
			c1.87,4.22,0.48,8.16,3.45,16.9c3.57,10.5,5.53,20.28,6.53,22.96c2.57,6.92,5.63,4.77,12.94,4.55c2.35-0.89,2.07-1.82,2.07-1.82
			c-0.28-3.69-3.53-4.66-6.23-4.52c-0.63,0.03-1.23,0.12-1.75,0.25C52.04,666.55,50.33,664.49,47.21,654.09z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M113.37,674.97c0.05,0.06,0.09,0.11,0.14,0.16c1.36,1.67,2.89,4.21,4.85,6.77
			c-0.42-0.65-0.84-1.33-1.25-2.04C115.81,677.66,114.54,676.06,113.37,674.97z"
          />
          <path
            fill="#FFFFFF"
            d="M113.37,674.97c0.05,0.06,0.09,0.11,0.14,0.16c1.36,1.67,2.89,4.21,4.85,6.77
			c-0.42-0.65-0.84-1.33-1.25-2.04C115.81,677.66,114.54,676.06,113.37,674.97z"
          />
          <path
            fill="#41647D"
            d="M113.37,674.97c0.05,0.06,0.09,0.11,0.14,0.16c1.36,1.67,2.89,4.21,4.85,6.77
			c-0.42-0.65-0.84-1.33-1.25-2.04C115.81,677.66,114.54,676.06,113.37,674.97z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M107.69,674.21c0.45-0.46,1.17-1.02,2.09-1.13C108.83,673.03,108.1,673.45,107.69,674.21z"
          />
          <path
            fill="#FFFFFF"
            d="M107.69,674.21c0.45-0.46,1.17-1.02,2.09-1.13C108.83,673.03,108.1,673.45,107.69,674.21z"
          />
          <path
            fill="#41647D"
            d="M107.69,674.21c0.45-0.46,1.17-1.02,2.09-1.13C108.83,673.03,108.1,673.45,107.69,674.21z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M100.04,643.61c0,0-0.01,0.01-0.02,0.02c-0.02,0.01-0.04,0.03-0.07,0.05c-0.42,0.3-2.24,1.75-3.57,5.12
			C97.82,645.64,100.04,643.61,100.04,643.61z"
          />
          <path
            fill="#FFFFFF"
            d="M100.04,643.61c0,0-0.01,0.01-0.02,0.02c-0.02,0.01-0.04,0.03-0.07,0.05c-0.42,0.3-2.24,1.75-3.57,5.12
			C97.82,645.64,100.04,643.61,100.04,643.61z"
          />
          <path
            fill="#41647D"
            d="M100.04,643.61c0,0-0.01,0.01-0.02,0.02c-0.02,0.01-0.04,0.03-0.07,0.05c-0.42,0.3-2.24,1.75-3.57,5.12
			C97.82,645.64,100.04,643.61,100.04,643.61z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M95.05,654.8c-0.45,5.77,1.74,9.79,4.07,18.37c0.07,0.25,0.13,0.5,0.2,0.76
			c-0.48-2.35-1.06-4.59-1.75-6.48C95.46,661.68,94.96,657.64,95.05,654.8z"
          />
          <path
            fill="#FFFFFF"
            d="M95.05,654.8c-0.45,5.77,1.74,9.79,4.07,18.37c0.07,0.25,0.13,0.5,0.2,0.76
			c-0.48-2.35-1.06-4.59-1.75-6.48C95.46,661.68,94.96,657.64,95.05,654.8z"
          />
          <path
            fill="#41647D"
            d="M95.05,654.8c-0.45,5.77,1.74,9.79,4.07,18.37c0.07,0.25,0.13,0.5,0.2,0.76
			c-0.48-2.35-1.06-4.59-1.75-6.48C95.46,661.68,94.96,657.64,95.05,654.8z"
          />
        </g>
        <g>
          <path fill="#85BED3" d="M106.64,678.23c-1.1,5.58-2.22,14.27-2.22,14.27L106.64,678.23z" />
          <path fill="#FFFFFF" d="M106.64,678.23c-1.1,5.58-2.22,14.27-2.22,14.27L106.64,678.23z" />
          <path fill="#41647D" d="M106.64,678.23c-1.1,5.58-2.22,14.27-2.22,14.27L106.64,678.23z" />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M156.3,688.31c1.79-0.77,2.64-1.65,2.97-2.48C159.02,686.33,158.29,687.31,156.3,688.31z"
          />
          <path
            fill="#FFFFFF"
            d="M156.3,688.31c1.79-0.77,2.64-1.65,2.97-2.48C159.02,686.33,158.29,687.31,156.3,688.31z"
          />
          <path
            fill="#41647D"
            d="M156.3,688.31c1.79-0.77,2.64-1.65,2.97-2.48C159.02,686.33,158.29,687.31,156.3,688.31z"
          />
        </g>
        <g>
          <path
            fill="#85BED3"
            d="M145.88,681.32c-0.94,0.25-1.81,0.43-2.62,0.54c2.44-0.02,5.41,0.42,9.25,3.7
			c2.96,2.53,6.55-3.22,6.89-0.14c0.33-1.5-0.84-2.75-0.84-2.75c-3.24-3.03-7.44-4.1-10.49-4.46c-0.45-0.05-0.87-0.09-1.26-0.11
			c-0.95-0.06-1.71-0.06-2.19-0.04c-0.37,0.01-0.58,0.03-0.58,0.03C145.56,678.66,145.88,681.32,145.88,681.32z"
          />
          <path
            fill="#FFFFFF"
            d="M145.88,681.32c-0.94,0.25-1.81,0.43-2.62,0.54c2.44-0.02,5.41,0.42,9.25,3.7
			c2.96,2.53,6.55-3.22,6.89-0.14c0.33-1.5-0.84-2.75-0.84-2.75c-3.24-3.03-7.44-4.1-10.49-4.46c-0.45-0.05-0.87-0.09-1.26-0.11
			c-0.95-0.06-1.71-0.06-2.19-0.04c-0.37,0.01-0.58,0.03-0.58,0.03C145.56,678.66,145.88,681.32,145.88,681.32z"
          />
          <path
            fill="#41647D"
            d="M145.88,681.32c-0.94,0.25-1.81,0.43-2.62,0.54c2.44-0.02,5.41,0.42,9.25,3.7
			c2.96,2.53,6.55-3.22,6.89-0.14c0.33-1.5-0.84-2.75-0.84-2.75c-3.24-3.03-7.44-4.1-10.49-4.46c-0.45-0.05-0.87-0.09-1.26-0.11
			c-0.95-0.06-1.71-0.06-2.19-0.04c-0.37,0.01-0.58,0.03-0.58,0.03C145.56,678.66,145.88,681.32,145.88,681.32z"
          />
        </g>
        <g>
          <path fill="#85BED3" d="M137.27,681.06c1,0.73,2.05,0.9,3.21,0.9C139.36,681.86,138.31,681.57,137.27,681.06z" />
          <path fill="#FFFFFF" d="M137.27,681.06c1,0.73,2.05,0.9,3.21,0.9C139.36,681.86,138.31,681.57,137.27,681.06z" />
          <path fill="#41647D" d="M137.27,681.06c1,0.73,2.05,0.9,3.21,0.9C139.36,681.86,138.31,681.57,137.27,681.06z" />
        </g>
        <path
          fill="#151616"
          d="M152.51,685.56c-3.84-3.28-6.8-3.72-9.25-3.7c-1,0.01-1.92,0.1-2.77,0.1c-1.16,0-2.21-0.16-3.21-0.9
		c-0.07-0.05-0.14-0.1-0.21-0.15c-3.42-2.81-6.74-5.88-9.69-9.28c0,0,0,0,0,0c-0.7-0.81-1.38-1.63-2.04-2.47
		c-1.97-2.53-3.71-5.22-5.13-8.11c-0.26-0.53-0.52-1.03-0.77-1.52c-0.38-0.73-0.76-1.41-1.12-2.04c-0.37-0.64-0.72-1.23-1.07-1.77
		c-0.69-1.1-1.34-2.02-1.92-2.79c-0.19-0.26-0.38-0.5-0.56-0.72c-0.45-0.56-0.84-1.01-1.17-1.38c-0.47-0.51-0.81-0.83-1-1
		c-0.03-0.02-0.05-0.05-0.07-0.06c-0.06-0.05-0.09-0.08-0.09-0.08c-0.19-0.18-0.38-0.36-0.57-0.53c-0.19-0.17-0.38-0.34-0.57-0.5
		c-0.38-0.32-0.76-0.62-1.14-0.9c-0.38-0.28-0.75-0.54-1.12-0.79c-0.37-0.24-0.74-0.47-1.1-0.68c-0.36-0.21-0.72-0.4-1.07-0.58
		c-0.17-0.09-0.35-0.18-0.52-0.26c-3.59-1.72-6.31-1.85-6.31-1.85l0,0c0,0-2.22,2.03-3.66,5.18c-0.36,0.78-0.67,1.63-0.89,2.53
		c-0.21,0.89-0.41,2.03-0.45,3.48c-0.09,2.84,0.41,6.88,2.52,12.65c0.69,1.88,1.27,4.12,1.75,6.48c1.73,8.5,2.19,18.47,2.19,18.47
		l2.92,0.11c0,0,1.12-8.69,2.22-14.27c0.29-1.46,0.57-2.71,0.84-3.53c0.06-0.17,0.12-0.34,0.21-0.49c0.41-0.76,1.14-1.19,2.09-1.13
		c0.99,0.05,2.23,0.63,3.59,1.9c1.18,1.09,2.44,2.69,3.73,4.9c0.41,0.71,0.83,1.38,1.25,2.04c3.57,5.51,7.65,9.04,13.4,9.25
		c14.28,0.54,21.2-1.18,24.55-2.86c1.99-1,2.72-1.98,2.97-2.48c0.11-0.21,0.13-0.33,0.13-0.33c0-0.03-0.01-0.05-0.01-0.07
		C159.05,682.34,155.46,688.09,152.51,685.56z"
        />
        <path
          fill="#151616"
          d="M47.79,667.39c-0.99-2.68-2.96-12.46-6.53-22.96c-2.97-8.74-1.58-12.68-3.45-16.9
		c-3.02-3.3-8.5-7.55-13.98-3.05c0,0,0.02-0.04,0.06-0.1c-1.77,1.64-4.13,4.42-5.35,8.3c-0.3,0.94-0.53,1.94-0.66,3.01
		c-0.61,4.69,1.76,8.11,3.02,12.31c0.07,0.25,0.15,0.53,0.22,0.83c0.79,3.46,1.31,10.33,1.53,15.58c0.09,2.12,0.14,3.97,0.14,5.22
		c0,1.88,0.08,3.01,0.38,3.64c0.04,0.08,0.08,0.15,0.12,0.21c0.46,0.66,1.33,0.54,2.91,0.18c0.05-0.01,0.11-0.02,0.16-0.04
		c0.08-0.02,0.14-0.06,0.2-0.14c0.44-0.58,0.52-2.91,1.17-8.82c0.62-5.58,1.39-9.34,2.07-11.24c0.06-0.16,0.15-0.21,0.26-0.17
		c0.94,0.28,3.66,6.37,5.53,11.97c2.1,6.3,1.13,7.45,9.53,8.29c5.13,0.51,9.84-0.19,12.73-0.81c1.18-0.25,2.06-0.5,2.53-0.63
		c0.26-0.08,0.41-0.12,0.41-0.12c-0.02,0-0.04,0-0.06,0C53.42,672.17,50.36,674.31,47.79,667.39z"
        />
        <path
          fill="#42637C"
          d="M37.81,627.53c1.49,1.63,2.39,3.03,2.39,3.03s-0.26-0.52-0.59-1.36c-0.92-2.3-2.46-7.07-1.07-10.6
		c1.42-3.64,6.71-5.35,9.23-5.97c0.82-0.2,1.35-0.29,1.35-0.29c-7.57,1.02-14.9-0.47-18.74-1.51c-1.29-0.35-2.18-0.64-2.55-0.77
		c-0.13-0.05-0.21-0.07-0.21-0.07c0.2,7.6-3.22,13.57-3.72,14.39c-0.04,0.06-0.06,0.1-0.06,0.1
		C29.31,619.98,34.79,624.23,37.81,627.53z"
        />
        <path
          opacity="0.3"
          d="M34.49,316.8c-0.01,0.02-0.01,0.03-0.02,0.05c-0.02,0.04-0.03,0.08-0.05,0.12c0.08,0.12,0.17,0.25,0.27,0.39
		C34.61,317.16,34.55,316.97,34.49,316.8z"
        />
        <path
          opacity="0.3"
          d="M33.02,316.43c0.13-0.35,0.26-0.69,0.39-1.03c0.01,0.01,0.01,0.02,0.02,0.03c0.16-0.38,0.32-0.75,0.48-1.11
		c0.28-0.63,0.56-1.24,0.83-1.83c0.03-0.19,0.06-0.38,0.1-0.59c0.1-0.61,0.2-1.29,0.31-2.03c-1.12,5.09-2.47,6.78-2.47,6.78
		s0.07,0.05,0.19,0.13C32.92,316.67,32.97,316.55,33.02,316.43C33.02,316.43,33.02,316.43,33.02,316.43z"
        />
        <path
          fill="#151616"
          d="M33.02,316.43c0.14-0.34,0.28-0.67,0.41-1c-0.01-0.01-0.01-0.02-0.02-0.03
		C33.28,315.74,33.15,316.08,33.02,316.43z"
        />
        <path
          opacity="0.3"
          d="M33.02,316.43c0.14-0.34,0.28-0.67,0.41-1c-0.01-0.01-0.01-0.02-0.02-0.03
		C33.28,315.74,33.15,316.08,33.02,316.43z"
        />
        <path
          fill="#151616"
          d="M34.93,317.93c-0.09-0.2-0.17-0.39-0.24-0.57c-0.09-0.13-0.18-0.26-0.27-0.39
		c-0.08,0.21-0.17,0.43-0.25,0.65c0.25,0.16,0.53,0.32,0.84,0.5C34.99,318.06,34.96,317.99,34.93,317.93z"
        />
        <path
          opacity="0.3"
          d="M34.93,317.93c-0.09-0.2-0.17-0.39-0.24-0.57c-0.09-0.13-0.18-0.26-0.27-0.39
		c-0.08,0.21-0.17,0.43-0.25,0.65c0.25,0.16,0.53,0.32,0.84,0.5C34.99,318.06,34.96,317.99,34.93,317.93z"
        />
        <path fill="#151616" d="M76.81,330.73c-1.89,0.38-3.01,0.59-3.01,0.59C74.82,331.16,75.82,330.96,76.81,330.73z" />
        <path opacity="0.3" d="M76.81,330.73c-1.89,0.38-3.01,0.59-3.01,0.59C74.82,331.16,75.82,330.96,76.81,330.73z" />
        <path
          fill="#151616"
          d="M83.41,329.38c-0.15,0.02-0.31,0.05-0.46,0.07c0,0,0,0-0.01,0c-0.39,0.06-0.78,0.11-1.16,0.17
		c-0.05,0.01-0.1,0.01-0.15,0.02c0.22-0.07,0.43-0.14,0.65-0.21c-0.04,0.01-0.08,0.01-0.13,0.02c0,0,0,0-0.01,0
		c-0.44,0.07-0.88,0.13-1.31,0.19c0,0,0,0,0,0c-1.32,0.41-2.66,0.78-4.02,1.09C78.48,330.4,80.76,329.94,83.41,329.38z"
        />
        <path
          opacity="0.3"
          d="M83.41,329.38c-0.15,0.02-0.31,0.05-0.46,0.07c0,0,0,0-0.01,0c-0.39,0.06-0.78,0.11-1.16,0.17
		c-0.05,0.01-0.1,0.01-0.15,0.02c0.22-0.07,0.43-0.14,0.65-0.21c-0.04,0.01-0.08,0.01-0.13,0.02c0,0,0,0-0.01,0
		c-0.44,0.07-0.88,0.13-1.31,0.19c0,0,0,0,0,0c-1.32,0.41-2.66,0.78-4.02,1.09C78.48,330.4,80.76,329.94,83.41,329.38z"
        />
        <path
          fill="#4D2C1A"
          d="M121.83,89.8c0.28-0.41,0.52-0.71,0.72-0.93c0,0.05,0.04,0.09,0.14,0.09c0.59-0.01,1.02,1.52,0.99,1.12
		c-0.04-0.41-0.19-0.59-0.19-0.59c0.02-0.04,0.03-0.09,0.05-0.13c0.15-0.39,0.22-0.83,0.25-1.07c0.12,0.01,0.23,0.03,0.36-0.01
		c1.01-0.33,1.84-3.1,1.22-2.21c-0.63,0.89-1.25,0.84-1.25,0.84c-0.11,0.05-0.21,0.12-0.31,0.2
		C122.68,87.91,121.77,89.89,121.83,89.8z"
        />
        <path
          fill="#4D2C1A"
          d="M120.15,54.9c0,0-4.51-7.86-17.53-11.96c-4.69-1.48-10.47-2.47-17.55-2.44
		c-30.22,0.14-37.22,32.3-32.97,57.24c2.33,13.68-0.65,24.33-7.47,31.97c-0.09,0.1-0.19,0.21-0.28,0.31
		c0.1,0.01,0.21,0.02-0.01,0.01c-0.92,1-1.9,1.96-2.96,2.86c-9.33,7.97-9.71,16.24-9.71,16.24c7.22,17.63,26.03,23.7,38.26,25.79
		c1.78,0.3,3.43,0.53,4.88,0.68c-0.11-13.18,4.3-20.9,4.3-20.9c-3.08,8.76-3.19,18.22-3.15,21.02c3.19,0.3,5.18,0.3,5.18,0.3
		s-0.17-2.88,0.11-7.17c0.76,4.68,2.28,6.79,2.28,6.79c-1.68-6.95,0.49-17.67,2.59-25.4c0.03-0.12,0.06-0.23,0.1-0.35
		c0.17-0.61,0.34-1.21,0.5-1.78c0,0,0-0.01,0-0.01c1.12-3.88,2.11-6.67,2.33-7.27c-0.76,2.6-1.34,5.04-1.8,7.33c0,0,0,0,0,0
		c-2.66,13.51-0.55,21.57-0.55,21.57s-1.25-9.05,1.15-20.8c0.05-0.23,0.1-0.46,0.14-0.68c0,0,0,0,0,0
		c1.32-6.27,2.72-10.26,3.95-13.52c1.59-3.84,2.91-7.73,3.86-12.47c0.59-2.92,1.03-6.17,1.31-9.94c0.38-5.1-0.44-10.11-0.99-14.15
		c-0.07-0.5-0.13-0.98-0.19-1.44c-0.67-1.12-1.19-2.29-1.56-3.46c-0.02-0.06-0.04-0.12-0.06-0.17c-0.04-0.11-0.07-0.22-0.1-0.33
		c-1.09-3.9-0.41-7.65,2.5-9.08c1.88-0.92,3.74-0.79,5.11,0.31c0.79,0.63,1.41,1.59,1.78,2.85c0.18,0.63,0.31,1.32,0.35,2.1
		c5.92-1.58,8.83-7.84,12.29-13.95c2.58-4.56,4.6-7.71,5.59-10.46C122.98,61.3,122.69,58.63,120.15,54.9z"
        />
        <path
          fill="#D8BFB1"
          d="M95.94,96.71c0.06,0.46,0.12,0.95,0.19,1.44c-0.03-0.41-0.04-0.81-0.04-1.21
		C96.04,96.86,95.99,96.79,95.94,96.71z"
        />
        <path
          fill="#A4A2A2"
          d="M95.94,96.71c0.06,0.46,0.12,0.95,0.19,1.44c-0.03-0.41-0.04-0.81-0.04-1.21
		C96.04,96.86,95.99,96.79,95.94,96.71z"
        />
        <path
          fill="#9F7C63"
          d="M99.13,86.07c1.18,0.24,2.06,1.8,0.94,3.17c-1.12,1.36-1.52,1.95-1.77,3c-0.25,1.05,0.51,2.01,0.51,2.01
		s0.16-0.8,0.41-1.78c0.25-0.97,1.43,0.35,2.56-0.85c1.13-1.2,0.35-4.46-0.11-5.21c-0.46-0.75-1.34-1.94-3.1-1.48
		c-1.76,0.47-2.14,2.56-2.52,4.01C96.07,88.94,97.48,85.73,99.13,86.07z"
        />
        <path
          fill="#9F7C63"
          d="M102.78,93.35c0.38-0.82-0.03-1.03-0.03-1.03c-0.79,1.25-1.89,0.87-2.4,1.47c-0.51,0.6,0.91,2.85,1.66,3.74
		c0.75,0.89,1.46,0.03,0.66-1.48C101.87,94.54,102.4,94.18,102.78,93.35z"
        />
        <path
          fill="#24B6DE"
          d="M130.61,281.27c-0.51-0.15-1.03-0.34-1.59-0.58c0.02,0.52,0.04,1.05,0.04,1.57
		c0.01,1.31-0.05,2.62-0.16,3.91c-0.07,0.78-0.15,1.55-0.25,2.31c-0.07,0.51-0.14,1.02-0.23,1.52c-0.17,1.01-0.36,2-0.58,2.97
		c-0.16,0.73-0.34,1.44-0.53,2.15c-0.13,0.47-0.26,0.93-0.39,1.39c-0.14,0.46-0.28,0.9-0.42,1.35c-0.14,0.44-0.29,0.88-0.44,1.3
		s-0.31,0.84-0.46,1.25c-0.39,1.02-0.8,1.99-1.22,2.9c-3.3,7.15,1.77,14.07,2.65,14.35c1.81-0.63,4.53-1.43,6.65-2.16
		c0.33-0.11,0.66-0.23,0.95-0.34c-0.01-0.24-0.56-3.59-0.6-3.82c-1.23-8.08-1.66-13.34-1.67-17.54c0.19-2.37,0.43-9.84,0.68-12.07
		c-0.31-0.03-0.63-0.07-0.95-0.12C131.61,281.53,131.12,281.42,130.61,281.27z"
        />
        <path
          opacity="0.3"
          d="M37.46,265.75c0.31,9.91-0.53,18.61-0.85,30.44c-0.02,0.93-0.06,1.82-0.1,2.68
		C37.4,289.98,38.06,278.32,37.46,265.75z"
        />
        <path opacity="0.3" d="M36.14,250.94c-1.18-8.83-2.95-16.5-4.88-23.78C32.87,233.72,34.95,242.91,36.14,250.94z" />
        <path
          fill="#151616"
          d="M80.98,329.63c-0.05,0.01-0.1,0.01-0.15,0.02h0c0,0,0,0,0,0c0.43-0.06,0.87-0.12,1.31-0.19
		C81.75,329.52,81.36,329.57,80.98,329.63z"
        />
        <path
          opacity="0.3"
          d="M80.98,329.63c-0.05,0.01-0.1,0.01-0.15,0.02h0c0,0,0,0,0,0c0.43-0.06,0.87-0.12,1.31-0.19
		C81.75,329.52,81.36,329.57,80.98,329.63z"
        />
        <path
          opacity="0.3"
          d="M80.98,329.63c-0.05,0.01-0.1,0.01-0.15,0.02h0c0,0,0,0,0,0c0.43-0.06,0.87-0.12,1.31-0.19
		C81.75,329.52,81.36,329.57,80.98,329.63z"
        />
        <path
          fill="#D8BFB1"
          d="M86.73,148.09C86.73,148.09,86.73,148.09,86.73,148.09c0.18,0.03,0.35,0.05,0.53,0.07c0,0,0,0,0,0
		C87.08,148.13,86.9,148.11,86.73,148.09z"
        />
        <path
          fill="#9F7C63"
          d="M90.59,142.27c0.82-3.62,4.73-16.02,5.22-20.02c-0.95,4.74-2.27,8.62-3.86,12.47
		c-1.23,3.26-2.65,7.35-3.97,13.62c0.4,0.04,0.96,0.08,1.34,0.11c0.82,0.07,2,0.06,2,0.06s-0.01-0.02-0.02-0.03
		C91.19,148.31,89.78,145.84,90.59,142.27z"
        />
        <path
          fill="#9F7C63"
          d="M89.05,140.82c-0.21,0.6-1.23,3.48-2.35,7.36c0.17,0.02,0.35,0.05,0.53,0.07
		C87.69,145.96,88.29,143.42,89.05,140.82z"
        />
        <path
          fill="#9F7C63"
          d="M122.76,110.76c-0.08,0.07-0.17,0.11-0.26,0.15c-0.31,0.17-0.63,0.34-1.02,0.38
		c0.31-0.85,0.35-4.71,0.35-4.71c-0.05,0.36-0.09,0.68-0.14,0.98c0,0,0-0.01,0-0.01c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02
		c-0.15,1.05-0.27,1.76-0.4,2.32c-0.16,0.69-0.34,1.16-0.62,1.75c-0.1,0.22-0.22,0.46-0.35,0.73c-0.04,0.08-0.09,0.17-0.13,0.26
		c0,0.01-0.01,0.02-0.01,0.03c0,0,0,0.01,0,0.01c-0.09,0.22-0.17,0.47-0.24,0.74c-0.11,0.41-0.19,0.82-0.24,1.22
		c-0.04,0.28-0.07,0.56-0.1,0.83c-0.04,0.57-0.04,0.97-0.04,0.97s0.01-0.17,0.04-0.44c-0.01,0.46,0.01,0.75,0.01,0.75
		c0-1.01,0.22-2.12,0.41-2.82c0.07-0.27,0.27-0.61,0.49-0.97l1.52,3.84c0,0,0.62-0.53,0.5-1.77c-0.12-1.24-1.46-2.72-1.46-2.72
		s0.03-0.06,0.08-0.17c0.43-0.06,1.28,0.32,1.82,0.09c1.32-0.56,1.43-1.59,1.22-2.23c-0.14-0.41-0.2-0.48-0.2-0.48
		S123.57,110.31,122.76,110.76z"
        />
        <path
          fill="#9F7C63"
          d="M102.88,121.22c-3.02-2.47-2.53-14.34-2.73-13.76s-0.43,16.94-0.32,26.86c0,0,0.03-0.43,0.16-1.11
		c0.33-1.8,1.33-5.34,4.17-7.16c2.24-1.43,3.77-1.39,7.97-0.47c2.93,0.64,4.87,0.87,4.65,0.7c-0.11-0.08-0.78-0.27-2.14-0.55
		C109.89,124.75,106.1,123.85,102.88,121.22z"
        />
        <path
          fill="#9F7C63"
          d="M127.65,102.4c-0.36,0.23-0.89,0.48-1.61,0.85c-2.06,1.05-2.48,0-2.7-1.21c-0.69-3.75-1.54-6.77-1.54-6.77
		c1.07,3.78,0.67,8.13,1.22,10.81c0.08-0.07,0.17-0.14,0.26-0.2c1.21-0.94,3.05-2.1,4.12-2.79c0.69-0.44,0.94-1,0.99-1.15
		c0.01-0.02,0.01-0.04,0.01-0.04S128.09,102.12,127.65,102.4z"
        />
        <path
          fill="#151616"
          d="M41.38,132.88c-9.33,7.97-9.71,16.24-9.71,16.24c7.22,17.63,26.03,23.7,38.26,25.79
		c-0.03-0.09-0.07-0.21-0.11-0.35c-0.11-0.37-0.24-0.92-0.35-1.63c-0.01-0.09-0.03-0.18-0.04-0.27c-0.05-0.38-0.1-0.8-0.14-1.26
		c-0.04-0.46-0.07-0.96-0.08-1.51c0-0.14,0-0.28-0.01-0.42c-0.03-6.09,1.95-17.07,12.46-33c0.21-0.32,0.42-0.63,0.63-0.95
		c-1.32,2.79-2.5,5.54-3.24,7.85c-2.75,8.55-4.03,17.17-4.03,17.17s2.67-12.07,6.63-21.23c1.88-4.34,4.52-9.32,6.98-13.89
		c1.18-2,2.28-3.95,3.26-5.83c0.51-0.98,0.99-1.94,1.43-2.88c0.22-0.47,0.43-0.93,0.63-1.38c0.39-0.82,0.72-1.55,0.97-2.18
		l-0.17,0.27c1.09-2.77,1.78-5.29,1.93-7.48c0.19-2.77-0.4-5.38-0.56-7.77c-0.07-0.5-0.13-0.98-0.19-1.44
		c-0.67-1.12-1.19-2.29-1.56-3.46c-0.02-0.06-0.04-0.12-0.06-0.17c-0.04-0.11-0.07-0.22-0.1-0.33c-1.09-3.9-0.41-7.65,2.5-9.08
		c1.88-0.92,3.74-0.79,5.11,0.31c15.52-20.88-5.41-39.25,1.29-40.53c0.11-0.02,0.23-0.04,0.35-0.05c-0.29-0.16-0.57-0.31-0.86-0.46
		c-4.69-1.48-10.47-2.47-17.55-2.44c-30.22,0.14-37.22,32.3-32.97,57.24c2.33,13.68-0.65,24.33-7.47,31.97
		c-0.09,0.1-0.19,0.21-0.28,0.31c0.1,0.01,0.21,0.02-0.01,0.01C43.41,131.03,42.43,131.98,41.38,132.88z"
        />
        <path fill="#9F7C63" d="M100.89,100.16c0,0-4.01-4.33-4.33-8.1C96.55,92.06,97.01,98.75,100.89,100.16z" />
        <path
          fill="#42637C"
          d="M44.34,130.01C44.24,130.01,44.14,130,44.34,130.01C44.55,130.03,44.44,130.02,44.34,130.01z"
        />
        <path
          fill="#151616"
          d="M84.71,329.18c0.15-0.02,0.3-0.05,0.46-0.07c0.61-0.1,1.22-0.2,1.84-0.31c0.46-0.1,0.93-0.2,1.4-0.3
		c0.15-0.03,0.29-0.06,0.44-0.1c0.34-0.07,0.68-0.15,1.02-0.22c0.17-0.04,0.34-0.07,0.51-0.11c0.35-0.08,0.7-0.15,1.06-0.23
		c0.15-0.03,0.3-0.07,0.46-0.1c0.5-0.11,1.01-0.22,1.53-0.34c0.89-0.2,1.79-0.4,2.7-0.61c0.23-0.05,0.45-0.1,0.68-0.16
		c0.23-0.05,0.45-0.1,0.68-0.16c2.73-0.63,5.49-1.29,8.13-1.96c0.88-0.22,1.75-0.45,2.6-0.67c0.42-0.12,0.85-0.24,1.28-0.36
		c0.86-0.25,1.72-0.5,2.6-0.76c0.44-0.13,0.88-0.26,1.32-0.4c0.89-0.27,1.78-0.54,2.69-0.82c0.91-0.28,1.82-0.57,2.75-0.87
		c0.22-0.08,0.44-0.17,0.65-0.25c0.21-0.08,0.41-0.16,0.61-0.25c0.27-0.11,0.5-0.23,0.7-0.35c0-0.01-0.01-0.03-0.01-0.04
		c-0.46,0.15-0.92,0.3-1.38,0.45c-0.46,0.15-0.91,0.29-1.37,0.44c-2.7,1.01-6.45,2.11-10.64,3.21c-7.89,2.22-14.93,3.83-21.21,4.94
		c-0.97,0.21-1.91,0.4-2.8,0.59C83.84,329.32,84.27,329.25,84.71,329.18z"
        />
        <path
          opacity="0.3"
          d="M84.71,329.18c0.15-0.02,0.3-0.05,0.46-0.07c0.61-0.1,1.22-0.2,1.84-0.31c0.46-0.1,0.93-0.2,1.4-0.3
		c0.15-0.03,0.29-0.06,0.44-0.1c0.34-0.07,0.68-0.15,1.02-0.22c0.17-0.04,0.34-0.07,0.51-0.11c0.35-0.08,0.7-0.15,1.06-0.23
		c0.15-0.03,0.3-0.07,0.46-0.1c0.5-0.11,1.01-0.22,1.53-0.34c0.89-0.2,1.79-0.4,2.7-0.61c0.23-0.05,0.45-0.1,0.68-0.16
		c0.23-0.05,0.45-0.1,0.68-0.16c2.73-0.63,5.49-1.29,8.13-1.96c0.88-0.22,1.75-0.45,2.6-0.67c0.42-0.12,0.85-0.24,1.28-0.36
		c0.86-0.25,1.72-0.5,2.6-0.76c0.44-0.13,0.88-0.26,1.32-0.4c0.89-0.27,1.78-0.54,2.69-0.82c0.91-0.28,1.82-0.57,2.75-0.87
		c0.22-0.08,0.44-0.17,0.65-0.25c0.21-0.08,0.41-0.16,0.61-0.25c0.27-0.11,0.5-0.23,0.7-0.35c0-0.01-0.01-0.03-0.01-0.04
		c-0.46,0.15-0.92,0.3-1.38,0.45c-0.46,0.15-0.91,0.29-1.37,0.44c-2.7,1.01-6.45,2.11-10.64,3.21c-7.89,2.22-14.93,3.83-21.21,4.94
		c-0.97,0.21-1.91,0.4-2.8,0.59C83.84,329.32,84.27,329.25,84.71,329.18z"
        />
        <path
          fill="#151616"
          d="M81.77,329.63c0.38-0.05,0.77-0.11,1.16-0.17c0,0,0,0,0.01,0c0.15-0.02,0.31-0.05,0.46-0.07
		c0.89-0.19,1.83-0.39,2.8-0.59c-0.62,0.11-1.23,0.21-1.84,0.31c-0.15,0.03-0.31,0.05-0.46,0.07c-0.55,0.09-1.1,0.18-1.65,0.26
		c-0.22,0.07-0.43,0.14-0.65,0.21C81.67,329.64,81.72,329.63,81.77,329.63z"
        />
        <path
          opacity="0.3"
          d="M81.77,329.63c0.38-0.05,0.77-0.11,1.16-0.17c0,0,0,0,0.01,0c0.15-0.02,0.31-0.05,0.46-0.07
		c0.89-0.19,1.83-0.39,2.8-0.59c-0.62,0.11-1.23,0.21-1.84,0.31c-0.15,0.03-0.31,0.05-0.46,0.07c-0.55,0.09-1.1,0.18-1.65,0.26
		c-0.22,0.07-0.43,0.14-0.65,0.21C81.67,329.64,81.72,329.63,81.77,329.63z"
        />
        <path
          opacity="0.3"
          d="M81.77,329.63c0.38-0.05,0.77-0.11,1.16-0.17c0,0,0,0,0.01,0c0.15-0.02,0.31-0.05,0.46-0.07
		c0.89-0.19,1.83-0.39,2.8-0.59c-0.62,0.11-1.23,0.21-1.84,0.31c-0.15,0.03-0.31,0.05-0.46,0.07c-0.55,0.09-1.1,0.18-1.65,0.26
		c-0.22,0.07-0.43,0.14-0.65,0.21C81.67,329.64,81.72,329.63,81.77,329.63z"
        />
        <path
          fill="#151616"
          d="M115.31,321.51c-0.52,0.16-1.03,0.32-1.54,0.47c-0.16,0.05-0.33,0.1-0.49,0.15
		c-0.22,0.07-0.44,0.13-0.66,0.2c-0.13,0.04-0.26,0.08-0.39,0.12c-0.18,0.06-0.37,0.11-0.55,0.16c-0.13,0.04-0.26,0.08-0.39,0.12
		c-0.09,0.03-0.18,0.05-0.27,0.08c-0.78,0.23-1.56,0.46-2.32,0.68c-0.43,0.12-0.85,0.24-1.28,0.36c4.19-1.1,7.94-2.2,10.64-3.21
		C117.14,320.94,116.22,321.23,115.31,321.51z"
        />
        <path
          opacity="0.3"
          d="M115.31,321.51c-0.52,0.16-1.03,0.32-1.54,0.47c-0.16,0.05-0.33,0.1-0.49,0.15
		c-0.22,0.07-0.44,0.13-0.66,0.2c-0.13,0.04-0.26,0.08-0.39,0.12c-0.18,0.06-0.37,0.11-0.55,0.16c-0.13,0.04-0.26,0.08-0.39,0.12
		c-0.09,0.03-0.18,0.05-0.27,0.08c-0.78,0.23-1.56,0.46-2.32,0.68c-0.43,0.12-0.85,0.24-1.28,0.36c4.19-1.1,7.94-2.2,10.64-3.21
		C117.14,320.94,116.22,321.23,115.31,321.51z"
        />
        <path
          opacity="0.3"
          d="M115.31,321.51c-0.52,0.16-1.03,0.32-1.54,0.47c-0.16,0.05-0.33,0.1-0.49,0.15
		c-0.22,0.07-0.44,0.13-0.66,0.2c-0.13,0.04-0.26,0.08-0.39,0.12c-0.18,0.06-0.37,0.11-0.55,0.16c-0.13,0.04-0.26,0.08-0.39,0.12
		c-0.09,0.03-0.18,0.05-0.27,0.08c-0.78,0.23-1.56,0.46-2.32,0.68c-0.43,0.12-0.85,0.24-1.28,0.36c4.19-1.1,7.94-2.2,10.64-3.21
		C117.14,320.94,116.22,321.23,115.31,321.51z"
        />
        <path fill="#9F7C63" d="M121.69,89.99c-0.39,0.65-1.22,0.57-1.22,0.57C119.97,90.31,121.2,91.3,121.69,89.99z" />
        <path
          fill="#9F7C63"
          d="M121.06,91c-0.09,0.08-0.19,0.14-0.29,0.23c0.26-0.01,0.5-0.14,0.69-0.33c0.09-0.1,0.17-0.21,0.21-0.33
		c0.05-0.12,0.07-0.25,0.05-0.38c-0.12,0.23-0.25,0.41-0.41,0.58C121.24,90.84,121.15,90.92,121.06,91z"
        />
        <path
          fill="#4D2C1A"
          d="M124.92,80.89c-1.43-0.37-3.1,1.16-4.23,3.05c0,0,2.08-1.53,3.24-1.44c0.77,0.06,1.38,0.71,1.1,1.53
		c-0.02,0.06,0,0.05,0.05,0.01c0.14-0.13,0.46-0.57,0.46-0.57c0.24-0.45,0.41-0.92,0.28-1.44c-0.07-0.28-0.22-0.57-0.5-0.87
		C125.22,81.04,125.09,80.93,124.92,80.89z"
        />
        <path
          opacity="0.3"
          d="M33.38,317.12c0.22,0.14,0.49,0.31,0.8,0.5c0.08-0.22,0.17-0.44,0.25-0.65c0.02-0.04,0.03-0.08,0.05-0.12
		c0.01-0.02,0.01-0.03,0.02-0.05c0.06,0.17,0.12,0.36,0.2,0.56c0.07,0.18,0.15,0.37,0.24,0.57c0.03,0.07,0.06,0.13,0.09,0.2h0
		c2.39,1.38,6.35,3.38,10.66,4.28c10.93,2.28,42.94,1.27,26.45-2.11c-16.49-3.38-35.09-8.03-30.86-20.29
		c4.23-12.26,13.53-19.87,9.3-31.29c-4.23-11.41-16.49-35.51-21.56-50.31c0,0,0.98,3.55,2.25,8.76c1.94,7.29,3.7,14.96,4.88,23.78
		c0.18,1.19,0.34,2.36,0.47,3.49c0.48,4.06,0.74,7.76,0.85,11.31c0.6,12.57-0.07,24.24-0.95,33.12c-0.25,4.76-0.77,8.35-1.36,11.01
		c0,0,0,0,0,0c-0.11,0.74-0.22,1.42-0.31,2.03c-0.03,0.2-0.06,0.4-0.1,0.59c-0.28,0.58-0.55,1.2-0.83,1.83
		c-0.16,0.36-0.32,0.73-0.48,1.11c-0.14,0.32-0.28,0.66-0.41,1c0,0,0,0,0,0c-0.05,0.12-0.1,0.24-0.15,0.36
		c0.09,0.06,0.2,0.14,0.35,0.23C33.27,317.05,33.32,317.09,33.38,317.12z"
        />
        <path
          opacity="0.3"
          d="M84.37,329.1c0.61-0.1,1.22-0.2,1.84-0.31c6.28-1.11,13.33-2.72,21.21-4.94c0.42-0.12,0.85-0.24,1.28-0.36
		c0.77-0.22,1.54-0.45,2.32-0.68c0.09-0.03,0.18-0.05,0.27-0.08c0.13-0.04,0.26-0.08,0.39-0.12c0.18-0.05,0.37-0.11,0.55-0.16
		c0.13-0.04,0.26-0.08,0.39-0.12c0.22-0.07,0.44-0.13,0.66-0.2c0.16-0.05,0.33-0.1,0.49-0.15c0.51-0.16,1.02-0.31,1.54-0.47
		c0.91-0.28,1.82-0.57,2.75-0.87c0.45-0.15,0.91-0.29,1.37-0.44c0.46-0.15,0.92-0.3,1.38-0.45c0,0.01,0.01,0.03,0.01,0.04
		c0.1-0.06,0.19-0.12,0.28-0.19c0.1-0.08,0.19-0.16,0.27-0.24c0.04-0.04,0.08-0.08,0.11-0.12c0.14-0.17,0.24-0.34,0.3-0.53
		c0.02-0.05,0.03-0.09,0.04-0.14c0.32-1.24-0.74-2.92-1.64-5.34c-0.07-0.19-0.14-0.38-0.2-0.57c-0.07-0.19-0.13-0.39-0.19-0.6
		c-0.09-0.31-0.18-0.62-0.26-0.95c-0.08-0.33-0.15-0.66-0.21-1.01c-0.08-0.46-0.15-0.95-0.19-1.46c-0.02-0.25-0.04-0.51-0.04-0.77
		c-0.01-0.26-0.01-0.53,0-0.81c0.01-0.27,0.02-0.55,0.04-0.84c0.01-0.14,0.02-0.29,0.04-0.43c0.06-0.58,0.15-1.19,0.28-1.82
		c0.5-2.42,1.36-4.34,2.34-5.95c0.45-0.74,0.93-1.42,1.41-2.06c0.24-0.32,0.48-0.62,0.72-0.92c3.33-4.17,6.21-11.93,0.76-19.7
		c-1.47-2.1-3.52-5.09-4.22-8.92c-0.11-0.58-0.21-1.16-0.3-1.76c-0.06-0.4-0.12-0.8-0.18-1.2c-0.09-0.6-0.17-1.22-0.25-1.83
		c-0.03-0.21-0.05-0.41-0.08-0.62c-0.15-1.24-0.28-2.48-0.41-3.72c-0.06-0.62-0.12-1.24-0.18-1.85c-0.15-1.63-0.29-3.24-0.42-4.75
		c-0.03-0.38-0.07-0.75-0.1-1.12c-0.05-0.55-0.1-1.09-0.16-1.62c-0.05-0.52-0.11-1.03-0.16-1.52c-0.07-0.65-0.15-1.27-0.23-1.85
		c-0.12-0.87-0.26-1.65-0.4-2.32c-0.43-1.94-2.22-20.85-2.28-21.27c-0.12-0.84-0.17-1.99-0.28-2.82c0.01,0.1,0.03,0.19,0-0.01
		c-0.17-1.23-0.33-2.44-0.48-3.61c-0.08-0.58-0.15-1.15-0.23-1.71c-0.02-0.19-0.05-0.37-0.07-0.55c-0.07-0.55-0.14,4.19-0.21,3.67
		c-0.02-0.17-0.04-0.34-0.07-0.51c-0.11-0.84-0.21-1.63-0.31-2.36c0-0.42,0.01-0.84,0.02-1.24c0.01-0.2,0.01-0.4,0.02-0.6
		c0.02-0.4,0.04-0.78,0.07-1.16c0.15-1.9,0.47-3.61,0.99-5.26c0.21-0.66,0.45-1.3,0.72-1.94c0.41-0.96,0.91-1.92,1.49-2.89
		c0.19-0.32,0.4-0.65,0.62-0.98c0.54-0.82,1.14-1.66,1.82-2.53c6.03-7.78-7.03,4.23-8.95,15.02c-0.03,0.17-0.06,0.33-0.09,0.48
		c-0.08,0.45-0.15,0.82-0.21,1.11c-0.02,0.1-0.04,0.19-0.06,0.27c-0.07,0.32-0.12,0.51-0.15,0.58c-0.01,0.02-0.02,0.03-0.02,0.03
		c-0.02,0.01-0.03-0.09-0.03-0.28c0.01-0.2,0.03-0.49,0.06-0.87c0.06-0.66,0.15-1.58,0.27-2.68c0.03-0.31,0.07-0.64,0.11-0.98
		c0.06-0.51,0.12-1.04,0.18-1.6c0.04-0.37,0.09-0.75,0.13-1.14c0.09-0.78,0.18-1.59,0.28-2.41c0.07-0.62,0.14-1.25,0.22-1.87
		c0.1-0.84,0.2-1.68,0.29-2.52c0.51-4.37,0.97-8.46,1.06-10.07c0.02-0.31,0.01-0.54-0.01-0.69c-0.01-0.08-0.03-0.14-0.05-0.18
		c-0.12-0.21-0.35,0.01-0.66,0.57c-0.12,0.22-0.26,0.5-0.41,0.83c-0.15,0.32-0.3,0.7-0.46,1.11c-0.08,0.21-0.16,0.42-0.24,0.65
		c-0.04,0.11-0.08,0.23-0.12,0.35c-0.08,0.24-0.17,0.48-0.25,0.73c-0.04,0.13-0.09,0.25-0.13,0.38c-0.09,0.26-0.17,0.53-0.26,0.8
		c-0.17,0.55-0.34,1.13-0.51,1.73c-0.13,0.45-0.25,0.91-0.38,1.38c-0.08,0.31-0.16,0.63-0.24,0.95c-0.35,1.45-0.68,2.96-0.93,4.47
		c-0.06,0.34-0.11,0.67-0.16,1.01c-0.12,0.84-0.22,1.67-0.29,2.48c-0.01,0.16-0.03,0.32-0.04,0.48c-0.02,0.32-0.04,0.64-0.06,0.95
		c-0.03,0.63-0.03,1.24-0.02,1.82c0.28,9.38-2.79-3.49-5.12-7c-0.28-0.42-0.37-0.54-0.32-0.36c0.01,0.05,0.03,0.11,0.06,0.19
		c0.07,0.21,0.2,0.54,0.36,1c0.07,0.18,0.14,0.39,0.21,0.61c0.08,0.22,0.16,0.47,0.25,0.73c0.09,0.26,0.18,0.55,0.27,0.85
		c0.14,0.46,0.3,0.96,0.45,1.51c0.16,0.55,0.32,1.15,0.49,1.8c0.17,0.65,0.34,1.34,0.51,2.09c1.33,5.71,2.74,14.29,3.08,26.1
		c0.1,3.59,0.11,7.48-0.02,11.68c-0.07,2.4-0.19,4.9-0.35,7.5c-0.1,0.45-0.21,0.9-0.31,1.35c-0.52,2.23-1.06,4.39-1.62,6.44
		c-1.12,4.1-2.3,7.78-3.51,10.85c-1.03,2.6-1.45,4.62-1.48,6.2c-0.01,0.43,0.02,0.83,0.07,1.2c0.03,0.25,0.08,0.48,0.14,0.7
		c0.08,0.33,0.19,0.64,0.32,0.92c0.13,0.28,0.27,0.54,0.44,0.78c0.22,0.32,0.46,0.6,0.73,0.86c0.13,0.13,0.27,0.25,0.41,0.36
		c0.07,0.06,0.14,0.11,0.21,0.17c0.29,0.22,0.59,0.41,0.89,0.59c0.15,0.09,0.3,0.17,0.45,0.26c0.15,0.08,0.3,0.16,0.45,0.24
		c2,1.05,3.68,1.76,2.16,4.45c-0.56,1-1.57,2.13-2.94,3.34c-0.27,0.24-0.56,0.49-0.87,0.74c-1.36,1.12-3.01,2.3-4.88,3.49
		c-0.42,0.27-0.84,0.53-1.28,0.8c-1.09,0.67-2.25,1.34-3.46,2c-0.48,0.27-0.98,0.53-1.48,0.8c-1.25,0.66-2.56,1.31-3.9,1.95
		c-1.88,0.9-3.84,1.76-5.85,2.59c-0.86,0.35-1.73,0.7-2.61,1.03c-0.59,0.22-1.17,0.44-1.76,0.65c-1.18,0.43-2.37,0.83-3.57,1.22
		c-2.69,0.87-5.41,1.62-8.09,2.21c-1.19,0.26-2.37,0.5-3.54,0.7c-5.42,0.92-6.43,1.51-4.56,1.64c0.1,0.01,0.21,0.01,0.33,0.02
		c0.06,0,0.12,0,0.18,0.01c0.13,0,0.26,0,0.4,0c0.57,0,1.26-0.02,2.07-0.07c0.2-0.01,0.41-0.02,0.62-0.04
		c0.64-0.04,1.35-0.1,2.11-0.17c0.25-0.02,0.51-0.05,0.78-0.07c1.59-0.16,3.38-0.37,5.33-0.65c0.81-0.11,1.65-0.24,2.51-0.38
		c4.48-0.71,9.59-1.71,14.81-3.04c0.4-0.1,0.8-0.21,1.21-0.31c0.8-0.21,1.61-0.44,2.42-0.67c1.61-0.46,3.22-0.95,4.82-1.48
		c0.2-0.07,0.4-0.13,0.6-0.2c1.99-0.67,3.94-1.39,5.84-2.17c11.51-4.72,12.57-0.24,7.41,6.29c-0.16,0.2-0.32,0.4-0.49,0.6
		c-0.34,0.4-0.69,0.81-1.07,1.23c-0.38,0.41-0.78,0.84-1.2,1.26c-1.68,1.69-3.69,3.45-5.97,5.15c-0.57,0.43-1.16,0.85-1.76,1.27
		c-0.6,0.42-1.22,0.84-1.85,1.24c-2.85,1.84-6,3.56-9.36,5.02c-1.67,0.72-3.39,1.37-5.15,1.95c0.54-0.08,1.09-0.17,1.65-0.26
		C84.07,329.15,84.22,329.13,84.37,329.1z"
        />
        <path
          fill="#24B6DE"
          d="M91.3,148.48c2.14,3.5,8.07,5.49,8.07,5.49s16.57,5.04,23.58,9.27l0-0.02c0,0,14.96,6.08,17.82,29.47
		c0.27,2.19,0.63,4.74,1.05,7.52c0.08,0.51,0.15,1.02,0.23,1.54c0.24,1.56,0.49,3.17,0.75,4.82c0.09,0.55,0.17,1.1,0.26,1.66
		c0.22,1.39,0.45,2.81,0.67,4.22c0.14,0.85,0.27,1.7,0.41,2.55c0.05,0.28,0.09,0.57,0.13,0.85c0.18,1.13,0.36,2.26,0.53,3.38
		s0.35,2.23,0.51,3.32c0.04,0.27,0.08,0.54,0.12,0.82c1.02,6.77,1.82,12.78,1.95,16.35c0.05,1.43,0.07,2.74,0.07,3.96
		c0,6.59-0.67,10.32-1.14,12.66c-0.04,0.22-0.06,0.36-0.06,0.43c0,0.02,0,0.04,0,0.05c0,0.01,0.01,0.02,0.02,0.01
		s0.02-0.02,0.04-0.04c0.01-0.02,0.03-0.05,0.05-0.08c0-0.01,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0.04-0.09,0.1-0.21,0.17-0.37
		c0.04-0.11,0.09-0.22,0.15-0.36c0.02-0.04,0.04-0.09,0.06-0.14c0.04-0.1,0.09-0.22,0.13-0.33c0.08-0.21,0.17-0.45,0.27-0.7
		c0.06-0.15,0.12-0.3,0.18-0.46c0.17-0.45,0.35-0.93,0.55-1.43c0.06-0.17,0.13-0.34,0.19-0.51c0.35-0.91,0.72-1.84,1.08-2.7
		c0.05-0.12,0.1-0.23,0.15-0.35c0.32-0.75,0.63-1.43,0.91-1.95c0.1-0.19,0.2-0.35,0.29-0.49c0.05-0.08,0.1-0.15,0.14-0.2
		c0.04-0.04,0.07-0.08,0.1-0.11c0.06-0.06,0.11-0.08,0.16-0.08c0.11,0.01,0.16,0.2,0.17,0.53c0,0.04,0,0.08,0,0.13
		c0.01,1.96-1.14,7.78-1.14,10.02c0,3.06,3.61-8.34,5.84-10.56c0.03-0.03,0.08-0.07,0.12-0.1c0.18-0.14,0.45-0.27,0.8-0.4
		c0.09-0.03,0.18-0.06,0.28-0.1c0.19-0.06,0.41-0.13,0.64-0.19c0.58-0.15,1.26-0.3,2.04-0.44c0.16-0.03,0.32-0.06,0.48-0.08
		c0.33-0.06,0.67-0.11,1.02-0.16c0.18-0.03,0.36-0.05,0.54-0.08c0.37-0.05,0.75-0.1,1.14-0.15c5.29-0.67,12.69-1.15,19.39-1.41
		c1.49-0.06,2.94-0.1,4.33-0.14c0.23-0.01,0.46-0.01,0.69-0.02c0.46-0.01,0.9-0.02,1.34-0.03c0.66-0.01,1.29-0.02,1.91-0.03
		c1.23-0.01,2.36-0.02,3.36-0.01c1.37,0.01,2.64,0.01,3.82-0.02c0.39-0.01,0.77-0.02,1.15-0.03c0.37-0.01,0.73-0.02,1.08-0.04
		c1.58-0.06,2.96-0.16,4.17-0.27c0.54-0.05,1.04-0.1,1.5-0.16c0.35-0.04,0.68-0.09,1-0.13c0.1-0.01,0.21-0.03,0.31-0.05
		c0.5-0.08,0.96-0.15,1.37-0.23c0.16-0.03,0.32-0.06,0.47-0.09c0.34-0.07,0.64-0.14,0.91-0.21c0.09-0.02,0.18-0.05,0.26-0.07
		c0.17-0.04,0.32-0.09,0.46-0.13c0.98-0.3,1.32-0.52,1.32-0.52l0,0c0,0,0.02,0.05,0.04,0.13c-0.19-0.72-0.37-1.32-0.52-1.77
		c-0.12-0.36-0.22-0.63-0.27-0.77c-0.04-0.09-0.06-0.14-0.06-0.14c-17.46-0.09-19.76,0.4-29.35,1.04c-0.02,0-0.03,0-0.05,0h0
		c-0.75,0.05-1.47,0.1-2.18,0.15c-15.92,1.16-22.18,2.69-26.19,2.27c-1.52-4.31-2.48-10.43-3.75-18.39
		c-0.26-1.63-0.53-3.33-0.83-5.11c-2.26-13.66-1.96-15.5-3.61-25.58c-1.55-19.78-7.68-33.92-37.93-44.04
		c-1.55-0.39-3.16-0.77-4.84-1.13c-0.16-0.05-0.32-0.11-0.5-0.17c0.01,0.04,0.02,0.06,0.02,0.06
		C98.46,148.36,95.3,148.66,91.3,148.48z"
        />
        <path
          fill="#151616"
          d="M104.15,630.45c0.17,0.03,0.36,0.06,0.58,0.09c0.11,0.02,0.22,0.03,0.34,0.05c0.24,0.04,0.5,0.08,0.78,0.12
		c0.28,0.04,0.58,0.09,0.89,0.13c0.32,0.05,0.65,0.09,0.99,0.14c0.35,0.05,0.71,0.1,1.08,0.15c0.37,0.05,0.76,0.1,1.16,0.16
		s0.8,0.11,1.22,0.16c1.24,0.16,2.55,0.32,3.87,0.47c1.1,0.12,2.21,0.23,3.28,0.33c0.12-1.12,0.41-3.89,0.83-7.84
		c0.13-1.21,0.27-2.54,0.42-3.97c0.07-0.71,0.15-1.45,0.23-2.21c0.16-1.52,0.33-3.13,0.5-4.81c1.31-12.65,3.03-29.63,4.49-45.13
		c0.15-1.55,0.29-3.09,0.43-4.6c0.09-1.01,0.19-2.01,0.28-3c0.14-1.48,0.27-2.94,0.39-4.37c0.08-0.95,0.17-1.89,0.25-2.82
		c0.04-0.46,0.08-0.92,0.12-1.38c0.17-1.94,0.34-4,0.52-6.16c0.09-1.08,0.18-2.2,0.28-3.34c0.05-0.57,0.09-1.15,0.14-1.73
		c0.28-3.5,0.57-7.23,0.87-11.17c0.78-10.5,1.56-22.44,2.2-35.23c0.2-4,0.38-8.08,0.54-12.22c0.07-1.66,0.13-3.33,0.19-5
		s0.11-3.36,0.17-5.06c0.03-0.85,0.05-1.7,0.07-2.55c0.05-1.7,0.09-3.41,0.13-5.13c0.16-6.86,0.25-13.82,0.25-20.8
		c0-2.62-0.01-5.24-0.03-7.86c-0.02-1.75-0.04-3.49-0.07-5.24c-0.04-2.62-0.1-5.23-0.17-7.84c-0.07-2.61-0.16-5.21-0.26-7.8
		c-0.14-3.45-0.3-6.89-0.49-10.29c-0.19-3.29-0.39-6.48-0.59-9.59c-0.08-1.24-0.17-2.47-0.25-3.68s-0.17-2.41-0.26-3.59
		c-0.22-2.96-0.45-5.83-0.69-8.61c-0.09-1.11-0.19-2.21-0.29-3.29c-0.05-0.54-0.1-1.08-0.15-1.62c-0.1-1.07-0.2-2.13-0.3-3.17
		c-0.05-0.52-0.1-1.04-0.15-1.55c-0.1-1.03-0.2-2.04-0.3-3.05c-0.1-1-0.2-1.99-0.31-2.96c-0.1-0.97-0.21-1.94-0.31-2.88
		c-0.05-0.47-0.1-0.94-0.16-1.41c-0.11-0.93-0.21-1.86-0.32-2.77c-0.21-1.82-0.43-3.58-0.64-5.3c-0.11-0.86-0.22-1.7-0.33-2.53
		c-1.58-12.06-3.22-21.44-4.68-28.56c-0.02-0.12-0.05-0.24-0.07-0.35c-0.2,0.12-0.43,0.24-0.7,0.35c-0.19,0.08-0.4,0.16-0.61,0.25
		c-0.21,0.08-0.43,0.17-0.65,0.25c-0.93,0.3-1.84,0.59-2.75,0.87c-0.91,0.28-1.8,0.56-2.69,0.82c-0.44,0.13-0.88,0.27-1.32,0.4
		c-0.88,0.26-1.74,0.51-2.6,0.76c-0.43,0.12-0.85,0.24-1.28,0.36c-0.85,0.22-1.72,0.45-2.6,0.67c-2.64,0.67-5.4,1.33-8.13,1.96
		c-0.23,0.05-0.45,0.1-0.68,0.16c-0.23,0.05-0.46,0.1-0.68,0.16c-0.91,0.21-1.81,0.41-2.7,0.61c-0.51,0.11-1.02,0.23-1.53,0.34
		c-0.15,0.03-0.3,0.07-0.46,0.1c-0.35,0.08-0.71,0.16-1.06,0.23c-0.17,0.04-0.34,0.07-0.51,0.11c-0.34,0.07-0.68,0.15-1.02,0.22
		c-0.15,0.03-0.3,0.06-0.44,0.1c-0.47,0.1-0.94,0.2-1.4,0.3c-0.62,0.11-1.23,0.21-1.84,0.31c-0.15,0.03-0.31,0.05-0.46,0.07
		c-0.44,0.07-0.87,0.14-1.31,0.2c-0.47,0.07-1.63,0.24-2.58,0.26c-6.41,0.9-11.94,1.25-16.71,1.17c-5.17-0.08-9.45-0.65-13-1.52
		c-1.09-0.27-2.11-0.57-3.07-0.89c-0.48-0.16-0.94-0.33-1.38-0.5c-0.45-0.17-0.88-0.35-1.29-0.53c-0.41-0.18-0.81-0.37-1.2-0.56
		c-0.38-0.19-0.75-0.38-1.11-0.58c-0.18-0.1-0.35-0.2-0.52-0.3c-0.34-0.2-0.67-0.4-0.98-0.61c-0.16-0.1-0.31-0.21-0.46-0.31
		c-0.3-0.21-0.59-0.42-0.87-0.63c-0.41-0.32-0.8-0.63-1.16-0.95c-0.24-0.21-0.47-0.43-0.69-0.64c-0.22-0.21-0.42-0.42-0.62-0.64
		c-0.39-0.42-0.74-0.84-1.05-1.25c-0.08-0.1-0.15-0.2-0.23-0.3c-0.66-0.91-1.13-1.75-1.47-2.49h0c-0.31-0.18-0.59-0.35-0.84-0.5
		c-4.3,11.24-8.51,27.97-9.95,52.1c-1.91,31.82,2.54,88.72,3.03,122.85c0.26,18.28-1.62,43.47-3.55,64.06
		c-1,10.72-2.02,20.19-2.74,26.78c-0.49,4.4-0.84,7.52-0.99,8.88c-0.14,1.37-0.39,4.15-0.36,6.82c0.01,1.02,0.06,2.03,0.17,2.93
		c0.81,3.57,3.85,6.15,8.04,7.88c0.81,0.33,1.66,0.64,2.55,0.91c3.84,1.03,11.17,2.53,18.74,1.51c0,0-0.53,0.09-1.35,0.29
		c0.42-0.02,0.85-0.05,1.27-0.08c2.9-0.21,5.67-0.63,8.13-1.22c0.32-5.64,5.68-101.1,8.55-126.42c3.24-28.66,6.33-68.42,12.29-77.06
		c0.45,5.56,2.1,20.56,3.47,31.81l-0.02,5.66c0.04,0.25,0.07,0.59,0.12,0.99c0.05-0.59,0.11-1.18,0.16-1.77
		c3.37,29.33,4.79,66.19,5.66,105.42c0.35,15.57,0.56,29.97,0.78,41.92c0.71,18.04,1.17,30.28,1.17,30.28
		c0.1,0.15,0.19,0.29,0.29,0.44c2.96,4.23,8.37,6.71,13.71,8.16c0.01-0.15,0.01-0.3,0.01-0.44
		C103.36,630.33,103.64,630.37,104.15,630.45z"
        />
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text transform="matrix(1 0 0 1 215.8496 116.5576)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            g
          </tspan>
          <tspan x="34.12" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            e
          </tspan>
          <tspan x="66.31" y="0" fontFamily={font.condensed} fontSize="68.3887px" letterSpacing="-1">
            sag
          </tspan>
          <tspan x="161.71" y="0" fontFamily={font.condensed} fontSize="68.3887px">
            t
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 284.5 175.3672)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="15.8124px">
            DIE BMW GROUP SIEHT
          </tspan>
          <tspan x="0" y="19" fontFamily={font.condensed} fontSize="15.8124px">
            VIELFALT{" "}
          </tspan>
          <tspan x="67.13" y="19" fontFamily={font.serif} fontSize="13px">
            als
          </tspan>
          <tspan x="82.71" y="19" fontFamily={font.condensed} fontSize="15.8124px">
            {" "}
            STÄRKE.
          </tspan>
          <tspan x="0" y="38" fontFamily={font.serif} fontSize="13px">
            Für eine nachhaltige Frauenförderung
          </tspan>
          <tspan x="0" y="57" fontFamily={font.serif} fontSize="13px">
            hat sie sich für alle Ebenen des
          </tspan>
          <tspan x="0" y="76" fontFamily={font.serif} fontSize="13px">
            Unternehmens Ziele beim
          </tspan>
          <tspan x="0" y="95" fontFamily={font.serif} fontSize="13px">
            Frauenanteil gesetzt.
          </tspan>
          <tspan x="117.64" y="95" fontFamily={font.serif} fontSize="11.8593px">
            {" "}
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
