import { GalleryItemState, SlideshowProvider, useSlideshow } from "#providers";
import { bp, expander, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid, Wrapper } from "#ui";
import React from "react";
import { Languages } from "#constants";
import i18next from "src/i18n";
import styled from "styled-components/macro";
import { Swiper } from "../Swiper/Swiper";
import { cardListDe } from "./cardListDe";
import { cardListEn } from "./cardListEn";
import { FlipCard, FlipCardSideProps } from "./FlipCard";
import { GalleryNav } from "./GalleryNav";

const cardList: { [k: string]: any } = {
  [Languages.de]: cardListDe,
  [Languages.en]: cardListEn,
};
export interface GalleryItemProps extends FlipCardSideProps {
  thumb: { src: string };
}
export interface GalleryProps {
  list: GalleryItemProps[][];
}

const Container = styled.div`
  position: relative;
  @media ${bp.m} {
    margin-top: 10px;
  }
`;
const Item = styled(Wrapper)`
  @media ${bp.m} {
    padding-bottom: 70px;
  }
`;
const Inner = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.m} {
    padding: 40px;
    position: relative;
    ${gridColumnStart(2)}
    ${gridColumnWidth(22)}
  }
  @media ${bp.m} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
`;
const Nav = styled.div`
  margin-top: 30px;
  @media ${bp.m} {
    ${expander}
    margin-top: 0;
    display: flex;
  }
`;
const SlideList: React.FC<GalleryProps> = ({ list }) => {
  const { index } = useSlideshow();

  const renderItem = (item: GalleryItemProps[], key: number) => {
    return (
      <Item key={key}>
        <Grid>
          <Inner>
            <GalleryItemState.Provider value={{ isActive: key === index }}>
              <FlipCard list={item} />
            </GalleryItemState.Provider>
          </Inner>
        </Grid>
      </Item>
    );
  };
  return (
    <Container>
      <Swiper<GalleryItemProps[]> list={list} renderItem={renderItem} disableDrag />
      <Nav>
        <Wrapper>
          <Grid>
            <Inner>
              <GalleryNav length={list.length} />
            </Inner>
          </Grid>
        </Wrapper>
      </Nav>
    </Container>
  );
};

export const Gallery: React.FC = () => {
  return (
    <SlideshowProvider length={3}>
      <SlideList list={cardList[i18next.language]} />
    </SlideshowProvider>
  );
};
