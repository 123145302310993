import React from "react";
import { bp, Color, expander, font, IconKeys } from "#shared/theme";
import { Link } from "../Link/Link";
import styled, { css } from "styled-components/macro";
import htmr from "htmr";
import { ButtonCircle } from "../ButtonCircle/ButtonCircle";
import { Icon } from "../Icon/Icon";
import { useTour } from "#providers";
import { useTranslation } from "#hooks";
import { AnalyticsEventTypes } from "#interfaces";
import { createAnalyticsEventHandler, wrapCallbackWithAnalyticsEventHandler } from "#helpers";

enum CardType {
  Slider = "Slider",
  Story = "Story",
  Tour = "Tour",
}
enum CardTheme {
  BlueAccent = "BlueAccent",
  Blue = "Blue",
  GrayLightest = "GrayLightest",
}
enum CardColor {
  White = "White",
  Black = "Black",
}
export interface CardProps {
  type: CardType;
  theme: CardTheme;
  color: CardColor;
  headline: string;
  image: {
    src: string;
  };
  bgImage: {
    src: string;
    srcMobile: string;
  };
  link: {
    download?: boolean;
    color: string;
    href: string;
    label: string;
    analyticsEvent?: AnalyticsEventTypes;
  };
  controls?: {
    label: string;
    analyticsEvent?: AnalyticsEventTypes;
  }[];
  hideMobileHeadline: boolean;
  mobileOrder: number;
}

interface CardContainerProps {
  type: CardType;
  theme: CardTheme;
  bgImage: string;
  bgImageMobile: string;
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & + & {
    margin-left: 40px;
  }
  @media ${bp.m} {
    & + & {
      margin-top: 30px;
      margin-left: 0;
    }
  }
`;
const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  padding-top: 10px;
  @media ${bp.m} {
    margin-bottom: 0;
    flex-direction: column;
  }
`;

const IconLabel = styled.div`
  margin-top: 20px;
  color: ${Color.Green};
  font-size: 10px;
  text-transform: uppercase;
  font-family: ${font.condensed};
  font-weight: bold;
  @media ${bp.s} {
    font-size: 11px;
  }
  @media ${bp.p} {
    font-size: 15px;
  }
  @media ${bp.d} {
    font-size: 18px;
  }
`;

const CardContainer = styled.div<CardContainerProps>`
  ${expander}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px 35px;
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Black};
  background-image: ${({ bgImageMobile }) => (bgImageMobile ? `url("${bgImageMobile}")` : "none")};
  background-size: cover;
  background-color: ${({ theme }) => Color[theme]};
  @media ${bp.t} {
    background-image: ${({ bgImage }) => (bgImage ? `url("${bgImage}")` : "none")};
  }
  @media ${bp.t} {
    font-size: 18px;
    line-height: 21px;
  }
  ${({ type }) =>
    (type === CardType.Tour || type === CardType.Story) &&
    css`
      padding: 14px;
    `}
  ${({ type, bgImage }) =>
    type === CardType.Tour &&
    css`
      @media ${bp.m} {
        background-image: ${bgImage ? `url("${bgImage}")` : "none"};
      }
    `}
`;
const Headline = styled.h4<{ type: CardType; color: CardColor; hideMobileHeadline: boolean }>`
  text-align: center;
  font-weight: normal;
  color: ${({ color }) => color};
  ${({ type }) =>
    type === CardType.Story &&
    css`
      display: none;
      @media ${bp.m} {
        display: block;
        font-size: 19px;
        line-height: 1.11;
        margin: 15px 15%;
      }
      @media ${bp.p} {
        font-size: 24px;
        line-height: 1.17;
      }
      @media ${bp.t} {
        font-size: 14px;
        line-height: 1.14;
        margin: 15px 20%;
      }
      @media ${bp.d} {
        font-size: 15px;
      }
    `}
  ${({ type, hideMobileHeadline }) =>
    type === CardType.Tour &&
    css`
      display: ${hideMobileHeadline && "none"};
      font-size: 13px;
      line-height: 1.15;
      @media ${bp.m} {
        display: block;
        margin: 15px;
        font-size: 15px;
        line-height: 1.13;
      }
      @media ${bp.p} {
        margin: 25px;
      }
      @media ${bp.d} {
        font-size: 18px;
        line-height: 1.17;
      }
    `}
`;
const Img = styled.img`
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  height: 50%;
  object-fit: contain;
  @media ${bp.m} {
    padding: 20px;
  }
`;
const StyledLink = styled(Link)<{ type: CardType; color: CardColor }>`
  color: ${({ color }) => color};
  font-weight: bold;
  padding-bottom: 2px;
  text-align: center;
  ${({ type }) =>
    type === CardType.Story &&
    css`
      margin-top: auto;
      font-size: 13px;
      @media ${bp.s} {
        margin-bottom: 5px;
      }
      @media ${bp.m} {
        font-size: 19px;
        line-height: 1.21;
        margin-bottom: 20px;
      }
      @media ${bp.p} {
        font-size: 25px;
        margin-bottom: 30px;
      }
      @media ${bp.t} {
        font-size: 14px;
      }
      @media ${bp.d} {
        font-size: 15px;
        margin-bottom: 20px;
      }
    `}
  ${({ type }) =>
    type === CardType.Tour &&
    css`
      font-size: 13px;
      line-height: 1.25;
      @media ${bp.m} {
        font-size: 15px;
        line-height: 1.23;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      @media ${bp.p} {
        margin-top: 25px;
        margin-bottom: 30px;
      }
      @media ${bp.d} {
        font-size: 18px;
        margin-bottom: 35px;
      }
    `}
`;
const LabelLink = styled.span`
  border-bottom: 2px solid ${Color.Green};
`;

export const Card: React.FC<CardProps> = ({
  type,
  theme,
  color,
  headline,
  image,
  bgImage,
  link,
  controls,
  hideMobileHeadline,
}) => {
  const { t: tMenu } = useTranslation("menu");
  const { set, play } = useTour();
  const { list: tourMenuList } = tMenu("tour");
  const tourList = tourMenuList[0].list;

  const go = () => {
    set(0);
  };
  const startAutoplay = () => {
    go();
    play();
  };

  return (
    <CardContainer type={type} theme={theme} bgImage={bgImage?.src} bgImageMobile={bgImage?.srcMobile}>
      <Headline type={type} color={color} hideMobileHeadline={hideMobileHeadline}>
        {htmr(headline)}
      </Headline>
      {image.src && <Img src={image.src} draggable={false} />}
      {controls && (
        <IconsWrapper>
          <IconWrapper>
            <Link to={tourList[0].to}>
              <ButtonCircle
                onClick={
                  controls[0].analyticsEvent
                    ? wrapCallbackWithAnalyticsEventHandler(startAutoplay, controls[0].analyticsEvent)
                    : startAutoplay
                }
              >
                <Icon iconKey={IconKeys.SvgPlay} />
              </ButtonCircle>
            </Link>
            <IconLabel>{controls[0].label}</IconLabel>
          </IconWrapper>
          <IconWrapper>
            <Link to={tourList[0].to}>
              <ButtonCircle
                onClick={
                  controls[1].analyticsEvent
                    ? wrapCallbackWithAnalyticsEventHandler(go, controls[1].analyticsEvent)
                    : go
                }
              >
                <Icon iconKey={IconKeys.SvgRestart} />
              </ButtonCircle>
            </Link>
            <IconLabel>{controls[1].label}</IconLabel>
          </IconWrapper>
        </IconsWrapper>
      )}
      {link.download ? (
        <StyledLink
          as="a"
          type={type}
          href={link.href}
          color={color}
          download
          onClick={link.analyticsEvent ? createAnalyticsEventHandler(link.analyticsEvent) : undefined}
        >
          <LabelLink>{htmr(link.label)}</LabelLink>
        </StyledLink>
      ) : (
        <StyledLink
          type={type}
          to={link.href}
          color={color}
          onClick={link.analyticsEvent ? createAnalyticsEventHandler(link.analyticsEvent) : undefined}
        >
          <LabelLink>{htmr(link.label)}</LabelLink>
        </StyledLink>
      )}
    </CardContainer>
  );
};
