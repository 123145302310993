import { AnalyticsEventTypes } from "#interfaces";
import { InfoCardTheme, InfoCardType, TourComponents } from "#interfaces";

export const tour = {
  sound: "/data/autopilot.mp3",
  ContractsChart: {
    title: "Neuverträge Finanzdienstleistungen",
    mainKPI: {
      label1: "",
      label2: "in Einheiten",
      maxValue: 3000000,
      currentValue: 1845271,
      prevValue: 2003782,
      diff: "− 7,9%",
    },
    leftKPI: {
      label1: "Anteil Finanzierung",
      label2: "in %",
      maxValue: 100,
      currentValue: 67.1,
      prevValue: 67.1,
      diff: "",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Anteil Leasing",
      label2: "in %",
      maxValue: 100,
      currentValue: 32.9,
      prevValue: 32.9,
      diff: "",
      rate: 0.4,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "Neuverträge Finanzdienstleistungen",
          component: TourComponents.Contracts,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Neuverträge Finanzdienstleistungen",
          content: [
            {
              copytext:
                "<p>Im Berichtsjahr 2020 verzeichnete das Segment Finanzdienstleistungen in einem schwierigen Umfeld, das durch die weltweiten Auswirkungen der Corona-Pandemie geprägt war, insgesamt eine deutlich rückläufige Geschäftsentwicklung. Mit einem Minus von 24,1 % sank das Ergebnis vor Steuern auf 1.725 Mio. € (2019: 2.272 Mio. €). Wie in den Vorquartalen beschrieben, liegt die Hauptursache in einer erhöhten Vorsorge für Kredit- und Restwertrisiken, die die Ergebnissituation im Berichtsjahr belasteten. Durch die Auswirkungen der Corona-Pandemie ging auch das bilanzielle Geschäftsvolumen im Vergleich zum Vorjahr moderat um 6,8 % auf 133.093 Mio. € (2019: 142.834 Mio. €) zurück. Zum Jahresende 2020 umfasste der betreute Bestand insgesamt 5.981.928 Verträge und lag damit auf dem Niveau des Vorjahres (2019: 5.973.682 Verträge / + 0,1 %).</p><p>Die Anzahl an Neuverträgen im Finanzierungs- und Leasinggeschäft mit Endkunden lag im Berichtszeitraum bei insgesamt 1.845.271. Dies entspricht einem moderaten Rückgang um 7,9 % (2019: 2.003.782 Verträge).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 29,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  DeliveriesChart: {
    title: "Auslieferungen Automobile",
    tooltip:
      "Ein Neu- oder Gebrauchtfahrzeug wird als Auslieferung erfasst, wenn das Fahrzeug an einen Endkunden übergeben wird. Zu den Endkunden gehören auch Leasingnehmer im Rahmen von Leasingverträgen mit BMW Financial Services sowie in den USA und Kanada auch Händler, wenn sie ein Fahrzeug als Werkstattersatzfahrzeug oder Vorführfahrzeug klassifizieren. Im Falle von Gebrauchtfahrzeugen zählen zu den Endkunden auch Händler und andere Dritte, wenn sie ein Fahrzeug bei einer Auktion oder direkt von der BMW Group erwerben. Für den Endkunden vorgesehene Fahrzeuge, die auf dem Transportwege einen Totalschaden erleiden, werden auch als Auslieferung gezählt. Auslieferungen können durch die BMW AG, eine ihrer internationalen Tochtergesellschaften, eine BMW Group Niederlassung oder einen unabhängigen Händler erfolgen. Die überwiegende Mehrzahl der Auslieferungen und damit die Meldung einer Auslieferung an die BMW Group erfolgt durch unabhängige Händler. Auslieferungen von Fahrzeugen während eines Berichtszeitraumes stehen nicht in direktem Zusammenhang mit der Umsatzlegung der BMW Group im entsprechenden Berichtszeitraum.",
    bottomLink: {
      text: "Hinweis",
      to: "/hinweis/",
    },
    mainKPI: {
      label1: "Gesamt",
      label2: "in Einheiten",
      maxValue: 3000000,
      currentValue: 2325179,
      prevValue: 2538367,
      diff: "− 8,4%",
    },
    leftKPI: {
      label1: "BMW",
      label2: "in Einheiten",
      maxValue: 3000000,
      currentValue: 2028841,
      prevValue: 2185793,
      diff: "− 7,1%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Rolls-Royce",
      label2: "in Einheiten",
      maxValue: 100000,
      currentValue: 3756,
      prevValue: 5100,
      diff: "− 26,4%",
      rate: 0.2,
    },
    rightKPI_2: {
      label1: "MINI",
      label2: "in Einheiten",
      maxValue: 3000000,
      currentValue: 292582,
      prevValue: 347474,
      diff: "− 15,8%",
      rate: 0.05,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          theme: InfoCardTheme.Default,
          title: "Fünf-Jahres-Vergleich",
          category: "Auslieferungen Automobile",
          component: TourComponents.Deliveries,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Auslieferungen Automobile",
          content: [
            {
              copytext:
                "<p>Die BMW Group bestätigt ihre Position als führender Premiumhersteller.</p><p>Die weltweiten pandemiebedingten Beschränkungen des öffentlichen und privaten Lebens betrafen auch die BMW Group und ihre Handelsorganisation. Vor dem Hintergrund der Pandemie entwickelten sich die Auslieferungen im Berichtsjahr sehr volatil: Einem vielversprechenden Jahresauftakt folgte im Frühjahr ein Einbruch bei den Verkaufszahlen, seit dem dritten Quartal ist eine Belebung bei den Absatzzahlen zu beobachten. In den letzten Monaten des Jahres befand sich die BMW Group wieder auf Erfolgskurs.</p><p>Im Berichtsjahr 2020 hat die BMW Group insgesamt 2.325.179 Automobile der Marken BMW, MINI und Rolls-Royce an Kunden übergeben (2019: 2.537.504 Automobile / – 8,4 %) und führt damit das Premiumsegment erneut weltweit an.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "Auslieferungen Automobile",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_Automobile.jpg'><br/><br/><p>Die Corona-Pandemie hat sich massiv auf unsere Handelsbetriebe weltweit ausgewirkt. Wie wir für unsere Kunden einen möglichst kontaktlosen, aber auch komfortablen Verkaufsprozess aufrechterhalten haben, erklärt Pieter Nota, Mitglied des Vorstands für Kunde, Marken, und Vertrieb: „In über 60 Märkten haben wir den Verkäufern im Handel bereits ab April ermöglicht, Kunden ohne Einschränkungen ortsunabhängig, also auch vom Home-Office aus zu beraten und ihnen Fahrzeuge zu verkaufen. So konfigurieren sie beispielsweise während einer telefonischen Beratung gemeinsam mit ihren Kunden deren Wunschfahrzeug über einen geteilten Bildschirm oder führen darüber entsprechende Bestandsfahrzeuge live vor.“</p><p>„In einem zweiten Schritt haben wir in kürzester Zeit in wichtigen Märkten über unsere hochfrequentierten Websites den Verkaufsprozess für die Handelspartner umfangreich online umgesetzt. Das wollen wir in diesem Jahr auf weitere Märkte ausrollen. Gemeinsam mit den Händlern arbeiten wir daran, dass unsere Kunden künftig auch ihr individuell konfiguriertes Wunschfahrzeug komplett online kaufen und sich bis vor die Haustür liefern lassen können. Dazu wird die gesamte Online-Journey von der Konfiguration über die Bestellung bis einschließlich der Nutzungsphase intuitiv aufgesetzt.“</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 21,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  EBITMarginChart: {
    title: "EBIT-Marge Segment Automobile",
    tooltip: "Ergebnis vor Finanzergebnis bezogen auf die Umsatzerlöse.",
    margin: {
      label1: "EBIT-Marge",
      label2: "in %",
      maxValue: 10,
      maxMobileValue: 5,
      currentValue: 2.7,
      prevValue: 4.9,
      diff: "− 44,9%",
      maxMargin: 3,
      minMargin: 0,
    },
    earnings: {
      label1: "Ergebnis vor Finanzergebnis",
      label2: "in Mio. €",
      maxValue: 10000,
      currentValue: 2162,
      prevValue: 4499,
      diff: "− 51,9%",
    },
    sales: {
      label1: "Umsatzerlöse",
      label2: "in Mio. €",
      maxValue: 100000,
      currentValue: 80853,
      prevValue: 91682,
      diff: "− 11,8%",
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "EBIT-Marge Segment Automobile",
          component: TourComponents.EbitMargin,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "EBIT-Marge Segment Automobile",
          content: [
            {
              copytext:
                "<p>Die EBIT-Marge (Ergebnis vor Finanzergebnis bezogen auf die Umsatzerlöse) des Segments lag im Berichtsjahr bei 2,7 % (2019: 4,9 % / – 2,2 Prozentpunkte). Wie in der Quartalsmitteilung zum 30. September 2020 prognostiziert, lag die EBIT-Marge im Korridor von 0 bis 3 % und damit im Rahmen der angepassten Erwartung. Im Geschäftsbericht 2019 wurde ursprünglich eine EBIT-Marge des Segments im Korridor von 2 bis 4 % erwartet.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "EBIT-Marge Segment Automobile",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_EBIT_Marge.jpg'><br/><br/><p>Die BMW M GmbH konnte 2020 mit ihrem Angebot an Performance- und High-Performance-Automobilen ihre Marktführerschaft im Segment nicht nur behaupten, sondern ausbauen.</p><p>„Dass wir dieses außergewöhnlich herausfordernde Jahr mit einer erneuten Bestmarke abschließen konnten, ist eine großartige Bestätigung unserer konsequenten Wachstumsstrategie“, sagt Markus Flasch, Vorsitzender der Geschäftsführung der BMW M GmbH.</p><p>Die Fahrzeuge der BMW M GmbH faszinieren durch aus dem Rennsport transferierte Technologie, präzise aufeinander abgestimmte Merkmale in den Bereichen Antrieb, Fahrwerk und Aerodynamik und Interieurgestaltung.</p><p>Und der Blick in die Zukunft stimmt zuversichtlich. 2021 wird nicht nur die neue Generation der BMW M3 und M4 Modelle auf den Markt kommen, sondern die BMW M GmbH wird erstmals auch ein Performance Automobil mit elektrifiziertem Antrieb vorstellen.</p><p>BMW M3 Competition Limousine (Kraftstoffverbrauch kombiniert: 10,2 l/100 km; CO2-Emissionen kombiniert: 234 g/km). BMW M4 Competition Coupé (Kraftstoffverbrauch kombiniert: 10,2 l/100 km; CO2-Emissionen kombiniert: 234 g/km).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 25,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  ElectricVehiclesChart: {
    title: "Auslieferungen elektrifizierte Fahrzeuge",
    mainKPI: {
      label1: "Elektrifizierte Fahrzeuge",
      label2: "in Einheiten",
      maxValue: 200000,
      currentValue: 192662,
      prevValue: 146178,
      diff: "+ 31,8%",
    },
    leftKPI: {
      label1: "Vollelektrische Fahrzeuge",
      label2: "in Einheiten",
      maxValue: 200000,
      currentValue: 44541,
      prevValue: 39522,
      diff: "+ 12,7%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Plug-in-Hybride",
      label2: "in Einheiten",
      maxValue: 200000,
      currentValue: 148121,
      prevValue: 106639,
      diff: "+ 38,9%",
      rate: 0.25,
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Auslieferungen elektrifizierte Fahrzeuge",
          content: [
            {
              copytext:
                "<p>Der fortschreitende Ausbau des Angebots elektrifizierter Modelle der Marken BMW und MINI zeigte sich auch im Berichtsjahr in stark steigenden Auslieferungszahlen. Die BMW Group setzte 2020 mit weltweit insgesamt 192.662 elektrifizierten BMW und MINI rund ein Drittel (31,8 %) mehr Fahrzeuge ab als noch im Vorjahr (2019: 146.158). Damit ist die Elektromobilität ein signifikanter Wachstumsmotor für das Unternehmen. Die BMW Group hat ihr Portfolio an elektrifizierten Fahrzeugen inzwischen auf 14 Modelle erweitert, die in insgesamt 95 Ländern weltweit verfügbar sind. Im Jahr 2023 wird die BMW Group den eigenen Planungen zufolge bereits 25 elektrifizierte Modelle auf der Straße haben.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "Auslieferungen elektrifizierte Fahrzeuge",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_elektrifizierte_Fahrzeuge.jpg'><br/><br/><p>Trotz Corona-Pandemie haben wir rund ein Drittel mehr elektrifizierte BMW und MINI Fahrzeuge ausgeliefert als im Vorjahr. Neben vollelektrischen Fahrzeugen sind auch unsere Plug-in-Hybride bei den Kundinnen und Kunden sehr beliebt.</p><p>Mit innovativen digitalen Services wollen wir aktiv dazu beitragen, die elektrischen Fahranteile von Plug-in-Hybrid-Modellen zu steigern. Deshalb haben wir den Service BMW eDrive Zone entwickelt. Damit erkennt ein BMW Plug-in-Hybrid ganz automatisch, wenn eine definierte Zone – zum Beispiel eine Umweltzone oder ein Innenstadtbereich – befahren wird, und schaltet selbstständig auf einen lokal emissionsfreien, rein elektrischen Betrieb um.</p><p>Außerdem sammeln PHEV-Kunden mit dem BMW Points Programm für elektrisch zurückgelegte Kilometer Bonuspunkte. Die Punkte lassen sich anschließend in nützliche Prämien, wie beispielsweise kostenfreie BMW Charging Ladekontingente umwandeln.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 21,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  EmissionsChart: {
    title: "CO2-Emissionen",
    tooltip:
      "Zur Berechnung der durchschnittlichen CO2-Flottenemissionen eines Herstellers wird der gewichtete Mittelwert der -Emissionen über alle im Berichtszeitraum neu zugelassenen Fahrzeuge ermittelt. Zugrunde liegen das Volumen der Neuzulassungen eines Herstellers in der EU im Kalenderjahr sowie die einzelfahrzeugspezifischen CO2-Emissionen, die nach dem WLTP-Typprüfverfahren ermittelt und auf den Neuen Europäischen Fahrzyklus (NEFZ) zurückgerechnet werden. Zusätzliche Effekte aus der Anrechnung von Öko-Innovationen sind nicht in der Angabe der Flottenemissionen 2019 berücksichtigt. Im Rahmen der Prognose für 2020 bezieht sich die Angabe auf die reine Reduzierung der CO2-Flottenemissionen und nicht auf den CO2-Flottengrenzwert, den die BMW Group zu erreichen hat. Das bedeutet, die Anrechnung von Supercredits, Phase-in und Öko-Innovationen ist nicht Bestandteil der Prognose.",
    value1: {
      label1: "CO2-Emissionen",
      label2: "je produziertes Fahrzeug in t",
      maxValue: 1,
      currentValue: 0.23,
      prevValue: 0.3,
      diff: "− 23,3%",
    },
    value2: {
      label1: "CO2-Emissionen",
      label2: "EU-Neuwagenflotte",
      label3: "in g CO2/km",
      labelMobile1: "CO2-Emissionen",
      labelMobile2: "EU-Neuwagenflotte",
      labelMobile3: "in g CO2/km",
      labelMobile4: "",
      maxValue: 650,
      currentValue: 99,
      prevValue: 127,
      diff: "− 22,0%",
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "CO2-Emissionen",
          content: [
            {
              copytext:
                "<p>Im Vergleich zum Vorjahr sind die CO2-Emissionen je Fahrzeug in der Produktion um rund 23 % auf 0,23 t CO2 gesunken. Die Reduktion ist auf die vollständige Umstellung des BMW Group Produktionsnetzwerks auf den Bezug von Grünstrom aus regenerativen Energiequellen zurückzuführen. Auch die absoluten Emissionen im Produktionsnetzwerk konnten auf 486.630 t CO2 reduziert werden (2019: 697.025 t CO2).</p><p>Die Maßnahmen der BMW Group zur Senkung der CO2-Flottenemissionen zeigen deutliche Wirkung. Der gestiegene Anteil elektrifizierter Fahrzeuge an den Auslieferungen und der konsequente Einsatz von Efficient Dynamics Technologien haben dazu geführt, dass die BMW Group den CO2-Flottengrenzwert unter Berücksichtigung regulatorischer Vorgaben und Regelungen im Berichtszeitraum 2020 unterschritten hat. Die CO2-Flottenemissionen der in Europa ausgelieferten Fahrzeuge lagen zum Ende des Berichtszeitraums bei 99 g CO2 / km (2019: 127 g CO2 / km / –22,0 %).</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "CO2-Emissionen",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_CO2Emissionen.jpg'><br/><br/><p>Die BMW Group hatte 2012 zehn strategische Nachhaltigkeitsziele bis 2020 formuliert. Wir haben nachgefragt. Zum Beispiel bei Christian Miedaner und Andreas Helfmann, denn ein Ziel war es, die CO2-Emissionen unserer EU-Neuwagenflotte von 1995 bis 2020 zu halbieren. Das haben wir geschafft. Wir haben sogar minus 53 Prozent herausgeholt.</p><p>Und auch bei Patricia Perez Szmak und Jury Witschnig. Sie kümmern sich um die CO2-Emissionen in der Lieferkette bzw. in der Produktion. Auch hier gibt es Erfolge zu feiern.</p><p>Lesen Sie dazu mehr in den Interviews.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Produktion, Einkauf und Lieferantennetzwerk",
          page: 9,
          src: "/data/pdf/de/Produktion-Einkauf-Lieferantennetzwerk.pdf",
        },
      },
    ],
  },
  GlobalMarketsChart: {
    title: "Die wichtigsten Automobilmärkte",
    labels: {
      america: "Amerika in %",
      europe: "Europa in %",
      asia: "Asien in %",
      other: "Weitere in %",
      legend1: "* in %",
      legend2: "",
    },
    charts: {
      america: 16.3,
      europe: 39.3,
      asia: 42.4,
      other: 2.0,
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Die wichtigsten Automobilmärkte",
          content: [
            {
              copytext:
                "<p>Auslieferungen in Asien legen zu, Europa und Amerika unter Vorjahr.</p><p>In Asien legte der Absatz der BMW Group im Jahr 2020 solide zu: So stieg die Zahl der Auslieferungen um 6,0 % auf 986.464 Automobile der Marken BMW, MINI und Rolls-Royce (2019: 930.767 Automobile). Insbesondere in China übertraf die BMW Group ihren Absatz mit 778.412 ausgelieferten Fahrzeugen im Vergleich zum Vorjahr um 7,4 % (2019: 724.711 Automobile).</p><p>In Europa hingegen war die Absatzsituation durch die Auswirkungen der Corona-Krise stärker belastet. Hier übergab die BMW Group in einem außerordentlichen und von Einschränkungen im Handel geprägten Jahr insgesamt 913.642 Einheiten an Kunden (2019: 1.081.649 / –15,5 %). In Deutschland verzeichnete das Unternehmen einen Rückgang von – 13,8 % (2020: 285.019 Automobile, 2019: 330.507 Automobile).</p><p>Im UK blieb der Absatz nicht zuletzt wegen der Ungewissheit über ein Handelsabkommen mit der EU und den Verlauf der Pandemie mit 163.174 Fahrzeugen deutlich unter dem Vorjahr (2019: 233.771 Automobile / – 30,2 %).</p><p>Auf dem amerikanischen Kontinent setzte die BMW Group im Berichtsjahr 379.714 Automobile ab (2019: 472.879 Automobile / – 19,7 %). In den USA lieferte die BMW Group im vergangenen Jahr insgesamt 307.876 Einheiten (2019: 375.726 Automobile / – 18,1 %) aus. In einem herausfordernden und von der Corona-Pandemie geprägten Umfeld sorgte die wieder anziehende Nachfrage dort für ein starkes Schlussquartal.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 22,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  KeyFiguresChart: {
    title: "Konzernkennzahlen",
    tooltip: "",
    mainKPI: {
      label1: "Konzernergebnis vor Steuern",
      label2: "in Mio. €",
      maxValue: 10000,
      currentValue: 5222,
      prevValue: 7118,
      diff: "− 26,6%",
      rate: 1,
    },
    leftKPI: {
      label1: "Konzernumsatzerlöse",
      label2: "in Mio. €",
      maxValue: 120000,
      currentValue: 98990,
      prevValue: 104210,
      diff: "− 5,0%",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Konzernjahresüberschuss",
      label2: "in Mio. €",
      maxValue: 10000,
      currentValue: 3857,
      prevValue: 5022,
      diff: "− 23,2%",
      rate: 0.6,
    },
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "Konzernkennzahlen",
          component: TourComponents.Keyfigures,
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 9,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  MotoChart: {
    title: "Auslieferungen Motorräder",
    tooltip: "",
    label1: "",
    label2: "in Einheiten",
    maxValue: 200000,
    currentValue: 169272,
    prevValue: 175162,
    diff: "− 3,4%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "Auslieferungen Motorräder",
          component: TourComponents.Moto,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Auslieferungen Motorräder",
          content: [
            {
              copytext:
                "<p>Im Segment Motorräder waren die Auslieferungen im Jahr 2020 aufgrund der weltweiten Pandemieauswirkungen mit 169.272 Fahrzeugen gegenüber dem Vorjahr leicht rückläufig (2019: 175.162 Motorräder / – 3,4 %). Aufgrund der positiven Marktentwicklung im vierten Quartal liegen die Auslieferungen über den Erwartungen aus der Quartalsmitteilung zum 30. September 2020.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "Auslieferungen Motorräder",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Auslieferungen_Motorraeder.jpg'><br/><br/><p>BMW Motorrad hat 2020 trotz der Einschränkungen aufgrund der Corona-Pandemie das zweitbeste Absatzergebnis aller Zeiten erzielt und zahlreiche neue und überarbeitete Motorradmodelle eingeführt.</p><p>Ein besonderes Highlight war die virtuelle Präsentation des BMW Definition CE04 auf der #NEXTGen. Der Scooter mit elektrischem Antrieb und innovativen Lösungen zur Vernetzung gibt einen Ausblick auf die Zukunft. Gerade in der Stadt kann man damit komfortabel und nachhaltig unterwegs sein.</p><p>Im September feierte das erste M Modell von BMW Motorrad Premiere. Die M 1000 RR rundet ab 2021 die Produktpalette nach oben ab.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 27,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  ResearchChart: {
    title: "F&E Leistungen",
    tooltip:
      "F&E Leistungen bestehen aus Forschungs- und nicht aktivierungsfähigen Entwicklungskosten sowie aus Investitionen in aktivierungspflichtige Entwicklungskosten ohne deren planmäßige Abschreibungen. Die F&E Quote gibt das Verhältnis der Forschungs- und Entwicklungsleistungen zu den Konzernumsatzerlösen wider.",
    mainKPI: {
      label1: "F&E Leistungen",
      label2: "in Mio. €",
      maxValue: 10000,
      currentValue: 6140,
      prevValue: 6279,
      diff: "− 2,2%",
    },
    leftKPI: {
      label1_1: "F&E Quote",
      label1_2: "in %",
      label2_1: "Zielrahmen",
      label2_2: "in %",
      maxValue: 8,
      maxMobileValue: 6.3,
      currentValue: 6.3,
      prevValue: 6.3,
      diff: "+ 0,1%-Punkte",
      frameworkMin: 5,
      frameworkMax: 5.5,
      frameworkValuesLabel: "5,0 – 5,5",
    },
    info: [
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "F&E Leistungen",
          content: [
            {
              copytext:
                "<p>Die Forschungs- und Entwicklungsleistungen lagen leicht unter denen des Vorjahres. Im Vorjahr wirkten unter anderem höhere Aufwendungen wie zum Beispiel für den BMW iX*. Im Geschäftsjahr stiegen hingegen die Aktivierungen für künftige Fahrzeuganläufe. Diese höheren aktivierten Entwicklungskosten stehen im Wesentlichen im Zusammenhang mit neuen Modellen, der Elektrifizierung der Fahrzeugflotte sowie dem automatisierten Fahren.</p><p>*BMW iX: Stromverbrauch kombiniert: < 21 kWh/100km im Testzyklus WLTP; CO2-Emissionen kombiniert: 0 g/km. Angaben sind vorläufig und beruhen auf Prognosen.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "F&E Leistungen",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_F_E_Leistungen.jpg'><br/><br/><p>Um ein neues Fahrzeug zu entwickeln, müssen zahlreiche Experten zusammenarbeiten. Mit dem Programm FIZ Future investiert die BMW Group in eine digitale und vollvernetzte Zukunft. Im neu fertiggestellten FIZ Nord, das das bestehende Forschungs- und Innovationszentrum erweitert, wird themen- und funktionsübergreifend an den Schwerpunkten Elektrifizierung, Digitalisierung und Konnektivität der Fahrzeuge gearbeitet und geforscht.</p><p>„Hier entwickeln wir die Technologien, die die Mobilität von morgen prägen und dafür sorgen, dass unsere Premium-Automobile nachhaltige Freude bringen“, sagte Oliver Zipse, Vorsitzender des Vorstands der BMW AG. „Damit stärken wir unsere Rolle als Hightech Unternehmen und gleichzeitig den Forschungsstandort Deutschland.“</p><p>Im Gebäudekomplex FIZ Nord stehen den Entwicklerinnen und Entwicklern auf einer Gesamtgeschossfläche von gut 150.000 Quadratmetern 100 modernste Prüfstände, 200 vollausgestattete Laboratorien und enorme Datenverarbeitungskapazitäten zur Verfügung.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Wirtschaftsbericht",
          page: 11,
          src: "/data/pdf/de/Wirtschaftsbericht.pdf",
        },
      },
    ],
  },
  ShareEarningsChart: {
    title: "Ergebnis je Aktie",
    tooltip:
      "Das Ergebnis je Aktie ergibt sich, indem für Stamm- und Vorzugsaktien das auf die jeweilige Aktiengattung entfallende Konzernergebnis nach Minderheitenanteilen auf die durchschnittliche Anzahl der jeweiligen Aktien bezogen wird. Beim Ergebnis je Vorzugsaktie werden nur die für das jeweilige Geschäftsjahr dividendenberechtigten Vorzugsaktien berücksichtigt.",
    label1_1: "je Stammaktie",
    label1_2: "in €",
    label2_1: "je Vorzugsaktie",
    label2_2: "in €",
    maxValue: 10,
    currentValue1: 5.73,
    currentValue2: 5.75,
    prevValue: 7.49,
    valueLabel1: "5,73",
    valueLabel2: "5,75",
    diff1: "− 23,3%",
    diff2: "− 23,2%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "Ergebnis je Aktie",
          component: TourComponents.Shares,
        },
      },
    ],
  },
  WomenChart: {
    title: "Frauenanteil in Führungsfunktionen",
    label1: "Frauenanteil",
    label2: "in %",
    maxValue: 50,
    currentValue: 17.8,
    prevValue: 17.2,
    diff: "+ 3,5%",
    info: [
      {
        type: InfoCardType.Diagram,
        props: {
          title: "Fünf-Jahres-Vergleich",
          category: "Frauenanteil in Führungsfunktionen",
          component: TourComponents.Women,
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Summary",
          theme: InfoCardTheme.GrayLightest,
          category: "Frauenanteil in Führungsfunktionen",
          content: [
            {
              copytext:
                "<p>Der Frauenanteil in Führungsfunktionen in der BMW Group steigt seit vielen Jahren kontinuierlich. Weltweit lag der Anteil weiblicher Führungskräfte in der BMW Group zum Ende des Berichtsjahres 2020 bei 17,8 % (2019 neu: 17,2 %). In der BMW AG hat sich die Zahl der Frauen in Führungsfunktionen von 2011 bis heute mehr als verdoppelt. Prozentual lag der Anteil weiblicher Führungskräfte in der BMW AG zum Ende des Jahres 2020 bei 16,2 % (2019 neu: 15,5 %).</p><p>Der Frauenanteil in der Gesamtbelegschaft der BMW Group erreichte im Berichtszeitraum 19,5 % (2019 neu: 19,3 %) und übertraf damit den selbst gesetzten Zielkorridor von 15 bis 17 %. In der BMW AG lag der Frauenanteil an der Gesamtbelegschaft mit einer Quote von 15,9 % (2019 neu: 15,7 %) im Zielkorridor von 15 bis 17 %.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Copytext,
        props: {
          title: "Kennzahlen im Kontext",
          theme: InfoCardTheme.Default,
          category: "Frauenanteil in Führungsfunktionen",
          content: [
            {
              copytext:
                "<img src='/data/tour/kontext_Frauenquote.jpg'><br/><br/><p>Die BMW Group hatte 2012 zehn strategische Nachhaltigkeitsziele bis 2020 formuliert. Wir haben nachgefragt. Zum Beispiel bei Gabriele von Stetten und Sabine Distler. Sie verantworten zusammen die Diversity-Strategie in unserem Personalwesen. Wir wollten wissen, wie es in Sachen Frauenanteil in Führungsfunktionen aussieht und haben auch nachgefragt, wie es um andere Dimensionen von Vielfalt bestellt ist.</p><p>Lesen Sie dazu mehr im Interview.</p>",
            },
          ],
        },
      },
      {
        type: InfoCardType.Pdf,
        props: {
          theme: InfoCardTheme.Gray,
          chapter: "Zusammengefasster Lagebericht",
          headline: "Mitarbeiter und Gesellschaft",
          page: 13,
          src: "/data/pdf/de/Mitarbeiter-Gesellschaft.pdf",
        },
      },
    ],
  },
  TourEnd: [
    {
      type: "Tour",
      theme: "BlueBold",
      color: "Black",
      hideMobileHeadline: true,
      headline: "Denn Glaubwürdigkeit entsteht durch Transparenz.",
      image: {
        src: "/data/teaser-1.png",
      },
      link: {
        href: "/wertschoepfung/",
        label: "Wie die BMW Group<br/> Werte schafft",
        analyticsEvent: AnalyticsEventTypes.TourEndGoToVCP,
      },
      mobileOrder: 2,
    },
    {
      type: "Tour",
      theme: "BlueSaturated",
      color: "White",
      hideMobileHeadline: true,
      headline: "Ausgewählte Kennzahlen unseres Geschäftsjahres 2020",
      image: {
        src: "",
      },
      link: {
        href: "#",
        label: "",
      },
      controls: [
        {
          label: "Replay",
          analyticsEvent: AnalyticsEventTypes.TourEndReplay,
        },
        {
          label: "Restart",
          analyticsEvent: AnalyticsEventTypes.TourEndRestart,
        },
      ],
      mobileOrder: 1,
    },
    {
      type: "Tour",
      theme: "GrayLightest",
      color: "Black",
      headline: "Der BMW Group Bericht 2020 PDF",
      image: {
        src: "",
      },
      bgImage: {
        srcMobile: "/data/teaser-4.jpg",
        src: "/data/teaser-4-portrait.jpg",
      },
      link: {
        icon: "",
        href: "/data/pdf/de/BMW-Group-Bericht-2020-DE.pdf",
        label: "Download",
        download: true,
        analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
      },
      mobileOrder: 3,
    },
  ],
};
