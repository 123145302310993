import { clearBtn, Color, defTransition, expander, font } from "#shared/theme";
import { ThemeKeys } from "#constants";
import React from "react";
import styled, { css, ThemeProvider } from "styled-components/macro";
import theme from "styled-theming";

export enum NavButtonTheme {
  Default = "Default",
  Black = "Black",
}

const Label = styled.div`
  overflow: hidden;
  margin-left: 50px;
  font-family: ${font.condensed};
`;
const Span = styled.span`
  display: block;
  overflow: hidden;
`;
const Inner = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  transform: translateX(-20px);
  transition: transform ${defTransition};
`;
const Circle = styled.div`
  content: "";
  ${expander}
  right: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  left: -20px;
  border-width: 3px;
  border-style: solid;
  clip-path: polygon(100% 0, 50% 50%, 100% 100%);
  transition: transform ${defTransition};
  ${theme(ThemeKeys.navButtonColor, {
    [NavButtonTheme.Default]: css`
      border-color: ${Color.Green};
    `,
    [NavButtonTheme.Black]: css`
      border-color: ${Color.Black};
    `,
  })}
`;
const Button = styled.button`
  ${clearBtn}
  pointer-events: auto;
  min-width: 50px;
  font-size: 11px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: ${Color.Green};
  ${theme(ThemeKeys.navButtonColor, {
    [NavButtonTheme.Default]: css`
      color: ${Color.Green};
    `,
    [NavButtonTheme.Black]: css`
      color: ${Color.Black};
    `,
  })}
  ${({ onClick }) =>
    onClick
      ? css`
          &:hover {
            ${Inner} {
              transform: translateX(0);
            }
          }
        `
      : css`
          cursor: default;
        `}
`;

export interface NavButtonProps {
  onClick?: () => void;
  theme?: NavButtonTheme;
}

export const NavButton: React.FC<NavButtonProps> = ({ children, onClick, theme = NavButtonTheme.Default }) => {
  return (
    <ThemeProvider theme={{ [ThemeKeys.navButtonColor]: theme }}>
      <Button onClick={onClick} as={onClick ? "button" : "span"}>
        <Inner>
          <Circle />
          {children && (
            <Label>
              <Span>{children}</Span>
            </Label>
          )}
        </Inner>
      </Button>
    </ThemeProvider>
  );
};
