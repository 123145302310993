import React, { useRef } from "react";
import { useNumberFormat } from "#hooks";
import { useSlideshow } from "#providers";
import { SvgLines } from "./SvgLines";
import { VCPContent, VCPLeftContent, VCPValue, VCPLink, RightContent, VCPTurbo, VCPValueLabel, VCPValues } from "#ui";
import { VCPItemProps } from "#interfaces";

export const Item1: React.FC<VCPItemProps> = ({ slideIndex, labels }) => {
  const { index, set } = useSlideshow();
  const turboSvgRef = useRef<SVGSVGElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const valuesInputRef = useRef<HTMLDivElement>(null);
  const valuesOutputRef = useRef<HTMLDivElement>(null);
  const inputDefaultRef = useRef<SVGLineElement>(null);
  const inputFillRef1 = useRef<SVGLineElement>(null);
  const inputFillRef2 = useRef<SVGLineElement>(null);
  const outputDefaultRef = useRef<SVGLineElement>(null);
  const outputFillRef1 = useRef<SVGLineElement>(null);
  const outputFillRef2 = useRef<SVGLineElement>(null);
  const isActive = index === slideIndex;
  const handleClick = () => {
    set(slideIndex === index ? -1 : slideIndex);
  };

  return (
    <SvgLines
      {...{
        turboSvgRef,
        contentRef,
        inputDefaultRef,
        inputFillRef1,
        inputFillRef2,
        outputDefaultRef,
        outputFillRef1,
        outputFillRef2,
        valuesInputRef,
        valuesOutputRef,
        isActive,
      }}
    >
      {({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
      }) => (
        <>
          <VCPContent ref={contentRef}>
            <VCPLeftContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title1}
              </VCPLink>
              <VCPValues ref={valuesInputRef}>
                <VCPValue ref={valueInputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue1))}
                </VCPValue>
                <VCPValue ref={valueInputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.inputValue2))}
                </VCPValue>
              </VCPValues>
            </VCPLeftContent>
            <RightContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title2}
              </VCPLink>
              <VCPValues ref={valuesOutputRef}>
                <VCPValue ref={valueOutputRef1}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel1.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue1))}
                </VCPValue>
                <VCPValue ref={valueOutputRef2}>
                  <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel2.toString() || "" }} />
                  {useNumberFormat(Number(labels?.outputValue2))}
                </VCPValue>
              </VCPValues>
            </RightContent>
          </VCPContent>
          <VCPTurbo ref={turboSvgRef} viewBox="0 0 40 272" xmlns="http://www.w3.org/2000/svg" opacity="0">
            <g>
              <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="236" width="15" height="10">
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="236" width="15" height="10" id="_x30_1-input-black_19_">
                <g filter="url(#Adobe_OpacityMaskFilter)">
                  <polygon id="_x30_1-input-black_1_" fill="#FFFFFF" points="0,236 15,236 15,246 0,246 5.083,241    " />
                </g>
              </mask>
              <line ref={turboInputRef} fill="none" stroke="#000000" strokeWidth="0" x1="0" y1="241" x2="15" y2="241" />
              <line
                ref={inputDefaultRef}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_1-input-black_19_)"
                fill="none"
                stroke="#000000"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="241"
                x2="15"
                y2="241"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_1_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="236"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="236" width="15" height="10" id="_x30_1-input-green_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_1_)">
                  <polygon id="_x30_1-input-black_2_" fill="#FFFFFF" points="0,236 15,236 15,246 0,246 5.083,241    " />
                </g>
              </mask>
              <line
                ref={inputFillRef1}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_1-input-green_1_)"
                fill="none"
                stroke="#346C64"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="0"
                y1="241"
                x2="15"
                y2="241"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_2_"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="240"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="0" y="240" width="15" height="2" id="_x30_1-input-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_2_)">
                  <polygon id="_x30_1-input-black_3_" fill="#FFFFFF" points="0,236 15,236 15,246 0,246 5.083,241    " />
                </g>
              </mask>
              <line
                ref={inputFillRef2}
                strokeDasharray={`0 ${pathInputLength}`}
                mask="url(#_x30_1-input-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="0"
                y1="241"
                x2="15"
                y2="241"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_3_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="26"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="26" width="15" height="10" id="_x30_1-output-white_19_">
                <g filter="url(#Adobe_OpacityMaskFilter_3_)">
                  <polygon id="_x30_1-output-white" fill="#FFFFFF" points="25,26 35,26 40,31 35,36 25,36    " />
                </g>
              </mask>
              <line ref={turboOutputRef} fill="none" stroke="#FFFFFF" strokeWidth="0" x1="25" y1="31" x2="40" y2="31" />
              <line
                ref={outputDefaultRef}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_1-output-white_19_)"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="31"
                x2="40"
                y2="31"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_4_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="26"
                  width="15"
                  height="10"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="26" width="15" height="10" id="_x30_1-output-yellow_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_4_)">
                  <polygon id="_x30_1-output-white_2_" fill="#FFFFFF" points="25,26 35,26 40,31 35,36 25,36    " />
                </g>
              </mask>
              <line
                ref={outputFillRef1}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_1-output-yellow_1_)"
                fill="none"
                stroke="#CEFB5F"
                strokeWidth="10"
                strokeMiterlimit="10"
                x1="25"
                y1="31"
                x2="40"
                y2="31"
              />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_5_"
                  filterUnits="userSpaceOnUse"
                  x="25"
                  y="30"
                  width="15"
                  height="2"
                >
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>
              <mask maskUnits="userSpaceOnUse" x="25" y="30" width="15" height="2" id="_x30_1-output-blue_1_">
                <g filter="url(#Adobe_OpacityMaskFilter_5_)">
                  <polygon id="_x30_1-output-white_3_" fill="#FFFFFF" points="25,26 35,26 40,31 35,36 25,36    " />
                </g>
              </mask>
              <line
                ref={outputFillRef2}
                strokeDasharray={`0 ${pathOutputLength}`}
                mask="url(#_x30_1-output-blue_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="25"
                y1="31"
                x2="40"
                y2="31"
              />
            </g>
          </VCPTurbo>
        </>
      )}
    </SvgLines>
  );
};
