import { disableAnalytics, enableAnalytics, getIsAnalyticsEnabledActionApplied } from "#helpers";
import { PopoverStatus, usePopover, useTranslation } from "#hooks";
import { PageName, PagesList } from "#interfaces";
import { useDarkOverlay } from "#providers";
import { bp, Color, fadeIn, fadeOut, fontCondensed, IconKeys } from "#shared/theme";
import { FixedContainer, Link, LinkIconSmall } from "#ui";
import htmr from "htmr";
import { rgba } from "polished";
import React, { TransitionEvent, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import styled from "styled-components/macro";

const Container = styled(FixedContainer)<{ isOpen: boolean }>`
  top: 0;
  bottom: 0;
  left: 0;
  ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)}
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
`;
const Box = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 40px ${rgba(Color.Black, 0.3)};
  border: 2px solid ${Color.Green};
  background-color: ${Color.White};
  margin: 10px;
  padding: 25px 10px;
  max-width: 835px;
  @media ${bp.m} {
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${bp.d} {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
const Headline = styled.h3`
  font-size: 13px;
  margin-bottom: 15px;
  ${fontCondensed}
  text-align: center;
`;
const Copytext = styled.div`
  a {
    border-bottom: 2px solid ${Color.Green};
  }
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
`;
const ButtonsItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 30px;
  }
`;

export const CookiesModal: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation("cookies");
  const { t: tPages } = useTranslation("pages");
  const pagesList: PagesList = tPages("list");
  const { isOpen, open, close, reset } = usePopover(
    !getIsAnalyticsEnabledActionApplied() ? PopoverStatus.Open : undefined
  );
  const { isOpen: isOverlayOpen, open: openOverlay, close: closeOverlay, reset: resetOverlay } = useDarkOverlay();
  const containerRef = useRef<HTMLDivElement>(null);
  const closeModal = () => {
    close();
    closeOverlay();
  };
  const accept = () => {
    enableAnalytics();
    closeModal();
  };
  const decline = () => {
    disableAnalytics();
    closeModal();
  };
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== containerRef.current || isOpen) return;
    reset();
    resetOverlay();
  };
  const transform = {
    a: ({ children, href }: { children?: React.ReactNode; href?: string }) => {
      if (!href) return <>{children}</>;
      return (
        <Link onClick={closeModal} to={href}>
          {children}
        </Link>
      );
    },
  };
  const disableModal = getIsAnalyticsEnabledActionApplied() || pathname === pagesList[PageName.cookies].path;
  useEffect(() => {
    if (disableModal) return;
    if (!isOverlayOpen) {
      openOverlay();
    }
    if (!isOpen) {
      open();
    }
  }, [disableModal, openOverlay, isOverlayOpen, open, isOpen]);

  if (disableModal) return null;
  return (
    <Container isOpen={isOpen} onTransitionEnd={handleTransitionEnd} ref={containerRef}>
      <Box>
        <Headline>{t("modal_headline")}</Headline>
        <Copytext>{htmr(t("modal_copytext"), { transform })}</Copytext>
        <Buttons>
          <ButtonsItem>
            <LinkIconSmall href={pagesList[PageName.cookies].path} onClick={decline} dimmed>
              {t("decline")}
            </LinkIconSmall>
          </ButtonsItem>
          <ButtonsItem>
            <LinkIconSmall iconKey={IconKeys.SvgCheck} onClick={accept}>
              {t("accept")}
            </LinkIconSmall>
          </ButtonsItem>
        </Buttons>
      </Box>
    </Container>
  );
};
