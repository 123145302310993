import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card31M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect x="282" y="53.67" opacity="0.9" fill="#C16032" width="73" height="476.33" />
        <rect x="64.32" y="85" width="290.68" height="7" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g>
          <g>
            <path
              fill="#1EBEE4"
              d="M274.94,315.27c0.1-3.34-1.41-8.74-10.07-12.79c-0.23-0.11-0.48,0.1-0.43,0.34l3.32,14.01
				c0.02,0.08,0.01,0.16-0.04,0.23l-4.11,6.79c-0.07,0.12-0.05,0.27,0.05,0.37c6.15,5.98,10.7,14.3,10.63,25.83
				C274.38,344.69,274.79,320.27,274.94,315.27z"
            />
            <path
              fill="#1EBEE4"
              d="M250.03,298.4c-3.63-0.55-7.77-0.94-12.5-1.14c-0.37-0.02-0.64,0.34-0.53,0.69l1.89,6.23
				c0.04,0.13,0.03,0.28-0.04,0.4l-2.71,5.12c-0.16,0.31,0.01,0.69,0.35,0.77c3.37,0.78,8.2,2.17,13.28,4.51
				c0.25,0.12,0.55,0.02,0.69-0.22l3-5.41c0.07-0.13,0.09-0.27,0.04-0.41l-3.05-10.15C250.4,298.58,250.23,298.43,250.03,298.4z"
            />
            <path
              fill="#1EBEE4"
              d="M236.52,297.81c-0.11-0.35-0.43-0.6-0.8-0.61c-0.16-0.01-0.31-0.01-0.47-0.02l-6.59-0.07
				c-0.59-0.01-1.02,0.57-0.83,1.14l3.64,10.82c0.1,0.31,0.37,0.53,0.69,0.58c0.52,0.08,1.41,0.23,2.6,0.48
				c0.38,0.08,0.76-0.1,0.94-0.44l2.65-5.01c0.11-0.2,0.13-0.44,0.06-0.65L236.52,297.81z"
            />
            <g>
              <path
                fill="#659896"
                d="M247,304.65c0.02-2.32-0.38-4.58-1.14-6.7l2.5-2.64l-3.45-5.74l-3.68,1.11
					c-1.62-1.61-3.54-2.95-5.71-3.92l-0.09-3.46l-6.49-1.62l-1.81,3.36c-2,0.04-3.96,0.39-5.81,1.01l-3.09-2.77l-5.74,3.45
					l1.23,4.06c-1.34,1.35-2.5,2.9-3.41,4.63l-4.26,0.02l-1.62,6.49l3.56,1.92l0.08-0.23c-0.1,2.09,0.14,4.14,0.68,6.09l-2.72,2.94
					l3.45,5.74l3.41-1.02c1.54,1.76,3.4,3.25,5.53,4.38l0.1,3.63l6.49,1.62l1.62-3c2.49,0.12,4.93-0.24,7.21-1.02l2.53,2.4
					l5.74-3.45l-1.02-3.39c1.69-1.63,3.09-3.57,4.11-5.78l-0.28,0.34l3.95-0.07l1.62-6.49L247,304.65z M224.22,317.74
					c-7.3-1.82-11.74-9.21-9.92-16.51c1.82-7.3,9.21-11.74,16.51-9.92s11.74,9.21,9.92,16.51
					C238.91,315.12,231.51,319.56,224.22,317.74z"
              />
            </g>
            <g>
              <g>
                <linearGradient
                  id="Card31MSVGID1"
                  gradientUnits="userSpaceOnUse"
                  x1="-1201.1353"
                  y1="-2925.4409"
                  x2="-1154.9359"
                  y2="-2925.4409"
                  gradientTransform="matrix(0.1834 0.983 -0.983 0.1834 -2490.3076 1985.3837)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31MSVGID1)"
                  d="M153.36,270.35c0,0,9.26-1.63,17.54,2.8c10.65,5.7,20.18,18.56,13.13,38.48l-28.78-11.13"
                />

                <linearGradient
                  id="Card31MSVGID2"
                  gradientUnits="userSpaceOnUse"
                  x1="-1051.0543"
                  y1="1749.3687"
                  x2="-1003.9166"
                  y2="1749.3687"
                  gradientTransform="matrix(0.752 -0.6591 0.6591 0.752 -239.4516 -1698.7511)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31MSVGID2)"
                  d="M131.55,319.18c0,0-5.31-6.82-5.72-16.21c-0.53-12.06,5.74-27.55,26.46-31.64l3.75,29.06"
                />

                <linearGradient
                  id="Card31MSVGID3"
                  gradientUnits="userSpaceOnUse"
                  x1="-5198.8384"
                  y1="-317.5742"
                  x2="-5151.689"
                  y2="-317.5742"
                  gradientTransform="matrix(-0.9529 -0.3032 0.3032 -0.9529 -4677.6758 -1555.0806)"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="0.1677" style={{ stopColor: "#FCFEFF" }} />
                  <stop offset="0.3055" style={{ stopColor: "#F2FBFD" }} />
                  <stop offset="0.4327" style={{ stopColor: "#E0F5FB" }} />
                  <stop offset="0.5534" style={{ stopColor: "#C8EEF7" }} />
                  <stop offset="0.6698" style={{ stopColor: "#A9E4F3" }} />
                  <stop offset="0.7827" style={{ stopColor: "#83D8EE" }} />
                  <stop offset="0.8914" style={{ stopColor: "#56CAE7" }} />
                  <stop offset="0.997" style={{ stopColor: "#23B9E0" }} />
                  <stop offset="1" style={{ stopColor: "#21B9E0" }} />
                </linearGradient>
                <path
                  fill="url(#Card31MSVGID3)"
                  d="M183.98,311.76c0,0-3.1,8.07-10.92,13.27c-10.06,6.69-26.63,9.53-40.84-6.1l23.02-18.43"
                />
              </g>
              <g>
                <path
                  fill="#DDD7C5"
                  d="M183.1,309.72c-0.87-0.54-3.13-1.93-5.73-3.52c-4.21-2.57-9.31-5.7-10.88-6.73
						c-2.54-1.66-2.42-0.73-3.98-0.24c-1.33,0.42-2.61,0.5-3.74,0.2c0,0.03,0,0.06,0,0.09c-0.02,1.43-1.2,2.57-2.63,2.55
						c-1.26-0.02-2.29-0.94-2.51-2.13c-1.8,1.49-10.02,8.3-15.6,12.93c-2.51,2.08-4.48,3.71-5.09,4.22c-1.66,1.38-0.53,3.16,1.49,2
						c0.9-0.52,3.22-1.88,5.89-3.46c4.31-2.54,9.55-5.63,11.22-6.54c2.7-1.48,1.82-1.81,2.14-3.43c0.18-0.94,0.5-1.79,0.95-2.51
						l-1.22,27.14c0,0,1.35,0.15,2.69,0.16c2.11,0.02,2.47-0.2,2.48-0.15c-0.08-3.19-0.81-22.47-1.03-28.28
						c2.14,0.87,11.87,4.83,18.47,7.52c2.97,1.21,5.3,2.16,6.03,2.45C184.01,312.78,185.04,310.93,183.1,309.72z"
                />
                <path
                  fill="#DDD7C5"
                  d="M157.62,297.33c-0.51-3.58-1.8-12.61-2.7-18.96c-0.46-3.22-0.82-5.76-0.93-6.54
						c-0.3-2.14-2.41-2.1-2.48,0.22c-0.03,1.04-0.08,3.73-0.13,6.83c-0.09,5.01-0.2,11.08-0.29,12.98c-0.15,3.08,0.59,2.5,1.81,3.62
						c0.68,0.63,1.22,1.31,1.6,2.03c0.46-0.4,1.06-0.63,1.72-0.62C156.74,296.9,157.22,297.06,157.62,297.33z"
                />
              </g>
            </g>
            <radialGradient id="Card31MSVGID4" cx="197.054" cy="320.0708" r="13.6519" gradientUnits="userSpaceOnUse">
              <stop offset="0.053" style={{ stopColor: "#FFFFFF" }} />
              <stop offset="0.1616" style={{ stopColor: "#FAFDFE" }} />
              <stop offset="0.2848" style={{ stopColor: "#EDF6FA" }} />
              <stop offset="0.405" style={{ stopColor: "#D8ECF4" }} />
              <stop offset="0.4714" style={{ stopColor: "#CDE9F3" }} />
              <stop offset="0.5882" style={{ stopColor: "#B0E1F0" }} />
              <stop offset="0.7415" style={{ stopColor: "#80D3EA" }} />
              <stop offset="0.9234" style={{ stopColor: "#3FC1E3" }} />
              <stop offset="1" style={{ stopColor: "#21B9E0" }} />
            </radialGradient>
            <path
              fill="url(#Card31MSVGID4)"
              d="M205.51,321.26c-2.63-5.89-8.3-18.19-8.45-18.52l0-0.01c0,0,0,0,0,0c0,0,0,0,0,0l0,0.01
				c-0.15,0.33-5.82,12.63-8.45,18.52c-2.95,6.59-1.12,15.61,7.66,16.13l0,0.01c0.2,0.01,0.4,0.02,0.59,0.02c0.07,0,0.13,0,0.2,0
				c0.07,0,0.13,0,0.2,0c0.2,0,0.39-0.01,0.59-0.02l0-0.01C206.62,336.87,208.45,327.85,205.51,321.26z"
            />
            <path
              fill="#1EBEE4"
              d="M222.36,296.82l-0.07,4.47c-3.06-3.76-6.85-6.73-11.26-8.85c-16.38-7.87-36.11-0.94-43.98,15.44
				c-4.78,9.96-4.27,21.59,1.37,31.1l0.95-0.57c-5.45-9.2-5.95-20.43-1.33-30.06c7.6-15.83,26.67-22.53,42.5-14.92
				c4.27,2.05,7.93,4.93,10.89,8.56l-4.18-0.18l-0.05,1.11l6.17,0.26l0.11-6.35L222.36,296.82z"
            />
            <path
              fill="#1EBEE4"
              d="M224.8,304.83l-0.95,0.58c5.72,9.29,6.32,20.7,1.61,30.52c-3.68,7.67-10.13,13.44-18.16,16.26
				c-8.03,2.82-16.67,2.34-24.34-1.34c-4.27-2.05-7.94-4.94-10.9-8.58l4.07,0.99l0.26-1.08l-6-1.45l-1.33,6.21l1.09,0.23l0.93-4.35
				c3.09,3.84,6.92,6.88,11.4,9.03c4.52,2.17,9.37,3.27,14.24,3.27c3.68,0,7.37-0.62,10.95-1.88c8.31-2.92,14.98-8.89,18.79-16.83
				C231.35,326.25,230.72,314.44,224.8,304.83z"
            />
            <g>
              <path
                fill="#1EBEE4"
                d="M263.3,301.8c-3.1-1.27-7-2.37-11.89-3.18c-0.24-0.04-0.43,0.18-0.37,0.41l3.02,10.03
					c0.03,0.08,0.02,0.17-0.03,0.25l-3.2,5.78c-0.09,0.16-0.03,0.37,0.14,0.45c3.98,1.93,8.05,4.46,11.66,7.73
					c0.15,0.14,0.39,0.1,0.49-0.07l3.79-6.25c0.04-0.07,0.06-0.16,0.04-0.24l-3.47-14.68C263.47,301.92,263.4,301.84,263.3,301.8z"
              />
            </g>
            <g>
              <path
                fill="#659896"
                d="M252.02,329.84c-4.39,1.39-9.63,2.73-15.86,3.97c-0.41,0.08-0.76,0.36-0.93,0.75l-12.66,29.62
					c-0.16,0.38-0.13,0.81,0.08,1.16l14.55,23.99c0.24,0.39,0.67,0.63,1.13,0.61c2.63-0.08,11.72-0.78,20.12-6.7
					c0.56-0.39,0.7-1.16,0.33-1.73l-13.64-21.26c-0.2-0.32-0.26-0.71-0.14-1.07l8.62-27.76C253.92,330.46,253,329.53,252.02,329.84z
					"
              />
              <path
                fill="#659896"
                d="M272.05,360.88c0.18,0.32,0.66,0.24,0.74-0.11c0.86-3.67,1.41-7.81,1.55-12.47c0,0,0.38-26,0.58-32.59
					c0.11-3.66-1.25-6.92-4-9.23c0,0,4.9,4.29,2.18,9.94c-0.18,0.38-0.25,0.75-0.27,0.87l-5.51,34.79c-0.06,0.36,0.01,0.74,0.2,1.06
					L272.05,360.88z"
              />
              <path
                fill="#659896"
                d="M272,362.73l-5.63-9.63c-0.11-0.18-0.15-0.39-0.11-0.6l4.85-30.63c0.13-0.84-0.87-1.39-1.49-0.82
					c-2.67,2.43-6.9,4.94-13.4,7.34c-0.26,0.1-0.47,0.31-0.55,0.58l-9.38,30.2c-0.08,0.25-0.04,0.53,0.1,0.76l13.45,20.96
					c0.3,0.46,0.94,0.56,1.36,0.19c4.47-3.91,8.5-9.54,10.89-17.66C272.16,363.21,272.13,362.95,272,362.73z"
              />
            </g>
            <g>
              <path
                fill="#027084"
                d="M270.93,306.49c0.18,0.16,4.84,4.39,2.17,9.93c-0.18,0.38-0.25,0.75-0.27,0.87l-2.5,15.78
					c2.43,4.6,3.93,10.07,3.96,16.57c0.01-0.11,0.01-0.21,0.02-0.32c0.05-3.13,0.16-9.93,0.28-16.7c0.11-6.8,0.24-13.88,0.33-16.9
					c0.05-1.53-0.17-2.99-0.63-4.34c-0.52-1.43-1.43-2.96-2.9-4.47C271.24,306.76,271.09,306.62,270.93,306.49z"
              />
              <path
                fill="#659896"
                d="M270.93,306.49C270.92,306.48,270.92,306.48,270.93,306.49C270.92,306.48,270.92,306.49,270.93,306.49z"
              />
            </g>
            <path
              fill="#027084"
              d="M269.62,321.07c-1.36,1.23-3.12,2.49-5.37,3.74c1.99,2.02,3.8,4.3,5.32,6.87l1.55-9.79
				C271.25,321.04,270.25,320.5,269.62,321.07z"
            />
            <polygon
              opacity="0.9"
              fill="#C16032"
              points="197.69,256.4 198.62,241.55 198.62,256.43 200.49,241.67 199.55,256.52 
				202.34,241.9 200.47,256.66 204.17,242.25 201.38,256.87 205.98,242.72 202.28,257.13 207.76,243.29 203.16,257.45 209.5,243.98 
				204.02,257.82 211.19,244.78 204.85,258.24 212.82,245.68 205.65,258.72 214.4,246.68 206.43,259.24 215.91,247.77 
				207.16,259.81 217.35,248.96 207.86,260.43 218.71,250.24 208.52,261.09 219.99,251.61 209.14,261.79 221.18,253.04 
				209.71,262.53 222.28,254.56 210.24,263.3 223.28,256.13 210.72,264.11 224.18,257.77 211.14,264.94 224.98,259.46 211.51,265.8 
				225.66,261.2 211.83,266.67 226.24,262.97 212.09,267.57 226.7,264.78 212.29,268.48 227.05,266.62 212.44,269.41 227.29,268.47 
				212.52,270.33 227.41,270.33 212.55,271.27 227.41,272.2 212.52,272.2 227.29,274.07 212.44,273.13 227.05,275.92 212.29,274.05 
				226.7,277.75 212.09,274.97 226.24,279.56 211.83,275.86 225.66,281.34 211.51,276.74 224.98,283.08 211.14,277.6 224.18,284.77 
				210.72,278.43 223.28,286.4 210.24,279.23 222.28,287.98 209.71,280.01 221.18,289.49 209.14,280.74 219.99,290.93 
				208.52,281.45 218.71,292.29 207.86,282.11 217.35,293.57 207.16,282.72 215.91,294.76 206.43,283.3 214.4,295.86 205.65,283.82 
				212.82,296.86 204.85,284.3 211.19,297.76 204.02,284.72 209.5,298.56 203.16,285.09 207.76,299.24 202.28,285.41 205.98,299.82 
				201.38,285.67 204.17,300.29 200.47,285.87 202.34,300.64 199.55,286.02 200.49,300.87 198.62,286.11 198.62,300.99 
				197.69,286.14 196.75,300.99 196.75,286.11 194.89,300.87 195.82,286.02 193.04,300.64 194.9,285.87 191.2,300.29 193.99,285.67 
				189.39,299.82 193.09,285.41 187.62,299.24 192.21,285.09 185.88,298.56 191.36,284.72 184.19,297.76 190.53,284.3 
				182.55,296.86 189.72,283.82 180.97,295.86 188.95,283.3 179.46,294.76 188.21,282.72 178.02,293.57 187.51,282.11 
				176.66,292.29 186.85,281.45 175.38,290.93 186.23,280.74 174.19,289.49 185.66,280.01 173.1,287.98 185.13,279.23 172.09,286.4 
				184.66,278.43 171.19,284.77 184.24,277.6 170.4,283.08 183.86,276.74 169.71,281.34 183.55,275.86 169.13,279.56 183.29,274.97 
				168.67,277.75 183.08,274.05 168.32,275.92 182.94,273.13 168.09,274.07 182.85,272.2 167.97,272.2 182.82,271.27 167.97,270.33 
				182.85,270.33 168.09,268.47 182.94,269.41 168.32,266.62 183.08,268.48 168.67,264.78 183.29,267.57 169.13,262.97 
				183.55,266.67 169.71,261.2 183.86,265.8 170.4,259.46 184.24,264.94 171.19,257.77 184.66,264.11 172.09,256.13 185.13,263.3 
				173.1,254.56 185.66,262.53 174.19,253.04 186.23,261.79 175.38,251.61 186.85,261.09 176.66,250.24 187.51,260.43 
				178.02,248.96 188.21,259.81 179.46,247.77 188.95,259.24 180.97,246.68 189.72,258.72 182.55,245.68 190.53,258.24 
				184.19,244.78 191.36,257.82 185.88,243.98 192.21,257.45 187.62,243.29 193.09,257.13 189.39,242.72 193.99,256.87 
				191.2,242.25 194.9,256.66 193.04,241.9 195.82,256.52 194.89,241.67 196.75,256.43 196.75,241.55 			"
            />
          </g>
          <path
            fill="#1EBEE4"
            d="M156.13,302.06c1.43,0.02,2.61-1.12,2.63-2.55c0-0.03,0-0.06,0-0.09c-0.02-0.87-0.46-1.64-1.13-2.09
			c-0.4-0.27-0.89-0.44-1.41-0.45c-0.66-0.01-1.26,0.23-1.72,0.62c-0.54,0.47-0.9,1.15-0.91,1.92c0,0.17,0.01,0.33,0.04,0.5
			C153.84,301.13,154.87,302.04,156.13,302.06z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g>
          <g>
            <g>
              <path
                fill="none"
                d="M63.42,273.39c0.1-0.01,0.19-0.01,0.28-0.02c0.11-0.09,0.23-0.18,0.36-0.28
					C63.8,273.2,63.52,273.27,63.42,273.39z"
              />
              <path fill="none" d="M71.37,272.33c0.03,0,0.05,0,0.08,0C71.42,272.33,71.39,272.33,71.37,272.33z" />
              <path
                fill="none"
                d="M68.01,270.82c0.1-0.01,0.2-0.02,0.29-0.03c-0.01,0-0.01,0-0.02,0c-0.66-0.11-0.89,0.07-1.1,0.3
					C67.49,270.95,67.77,270.86,68.01,270.82z"
              />
              <path
                fill="none"
                d="M70.71,271.65c0.01,0.01,0.01,0.01,0.02,0.02C70.72,271.66,70.71,271.66,70.71,271.65z"
              />
              <path
                fill="none"
                d="M70.99,272.22c0,0.01,0,0.01,0,0.02c0-0.01,0-0.01,0.01-0.01C70.99,272.22,70.99,272.22,70.99,272.22
					C70.99,272.22,70.99,272.22,70.99,272.22z"
              />
              <path
                fill="none"
                d="M69.97,271.27c0.3,0.06,0.51,0.18,0.66,0.3c-0.21-0.18-0.52-0.34-0.97-0.48
					C69.77,271.15,69.87,271.21,69.97,271.27z"
              />
              <path
                fill="none"
                d="M64.95,276.15c0.46-0.08,1.17-0.21,1.88-0.35c0-0.11,0-0.22,0-0.33c-0.02,0-0.04-0.01-0.06-0.01
					c-0.99,0.3-1.88,0.6-2.31,0.78C64.46,276.24,64.65,276.2,64.95,276.15z"
              />
              <path
                fill="none"
                d="M65.23,272.24c-0.18,0.1-0.35,0.22-0.51,0.36C64.89,272.48,65.06,272.36,65.23,272.24z"
              />
              <path
                fill="none"
                d="M7.88,357.8l-0.04,0c-0.1-0.36-0.19-0.7-0.27-1.05c-1.12-3.64-1.51-3.18-2.1-2.34l-2.25,2.93L7.88,357.8
					L7.88,357.8z"
              />
              <path fill="none" d="M64.73,275.33c-0.2,0.23-0.31,0.39-0.31,0.39L64.73,275.33z" />
              <path
                fill="none"
                d="M69.73,271.78c-0.42,0.15-1.09,1.08-1.46,3.23c-0.1,0.6-0.18,1.3-0.22,2.11c0.02,0.11,0.04,0.22,0.07,0.33
					c-0.03-0.01-0.06-0.03-0.09-0.04c-0.01,0.28-0.02,0.56-0.02,0.86c-0.03,4.55,1.18,8.11,2.3,2.12
					C71.43,274.39,70.92,271.34,69.73,271.78z M68.82,277.85C68.82,277.85,68.82,277.85,68.82,277.85l0.03,0
					C68.85,277.85,68.84,277.85,68.82,277.85z M68.88,277.74c0.04-0.12,0.07-0.24,0.09-0.36C68.94,277.54,68.9,277.66,68.88,277.74z
					"
              />
              <path
                fill="none"
                d="M70.81,271.78c-0.01-0.01-0.02-0.02-0.03-0.04C70.79,271.75,70.8,271.76,70.81,271.78z"
              />
              <path fill="none" d="M72.88,411.91C72.89,411.91,72.88,411.91,72.88,411.91L72.88,411.91z" />
              <path
                fill="none"
                d="M74.11,422.38c0.03,0.01,0.08,0.02,0.15,0.03C74.18,422.39,74.13,422.38,74.11,422.38z"
              />
              <path fill="none" d="M70.93,271.99c0-0.01-0.01-0.02-0.01-0.03C70.92,271.97,70.92,271.98,70.93,271.99z" />
              <path fill="none" d="M71.29,272.32c-0.02,0-0.04-0.01-0.05-0.01C71.26,272.32,71.28,272.32,71.29,272.32z" />
              <path
                fill="none"
                d="M70.89,271.9c-0.01-0.02-0.02-0.04-0.04-0.06C70.86,271.86,70.88,271.88,70.89,271.9z"
              />
              <path fill="#41637C" d="M3.72,454.16c0-0.03,0.01-0.06,0.01-0.09C3.73,454.09,3.72,454.12,3.72,454.16z" />
              <path
                fill="#41637C"
                d="M22.18,447.66c-4.65-1.18-8.63-2.67-11.94-4.17c0,0,0,0,0,0C13.08,444.83,17.09,446.4,22.18,447.66z"
              />
              <path
                fill="#41637C"
                d="M6.31,498.59C6.31,498.59,6.31,498.59,6.31,498.59c0.48,6.16,0.98,11.14,1.45,15.12c0,0,0,0,0,0
					C7.32,509.2,6.82,503.97,6.31,498.59z"
              />
              <path
                fill="#41637C"
                d="M3.64,455.06c-0.49,5.95-0.18,11.71,0.41,18.24C3.35,463.58,3.38,458.61,3.64,455.06z"
              />
              <path
                fill="#41637C"
                d="M45.22,450c-5.28,1.04-32.52,7.22-35.71,16.89c-0.02,8.92,0.36,19.42,1.67,29.56
					c2.76,21.36,3.4,31.87,3.53,34.58c0,0,0,0,0,0c9.45,6.52,31.15,20.23,47.4,20.9c4.01,0.17,20.35-0.73,22.07-8.45
					c4.59-20.58-2.86-64.19-9.27-94.69l-23.67,0.77C49.18,449.78,47.17,449.92,45.22,450z"
              />
              <path
                fill="#41637C"
                d="M6.12,441.57c-0.05,0.17-0.09,0.34-0.14,0.51c0.08-0.23,0.15-0.39,0.21-0.49
					C6.17,441.59,6.14,441.58,6.12,441.57z"
              />
              <path
                fill="#D7BFB1"
                d="M55.62,295.49c-0.1-0.06-0.19-0.12-0.29-0.19C55.43,295.37,55.52,295.43,55.62,295.49z"
              />
              <path
                fill="#D7BFB1"
                d="M57.82,296.89c-0.36-0.23-0.72-0.46-1.09-0.69C57.1,296.43,57.46,296.67,57.82,296.89z"
              />
              <path
                fill="#D7BFB1"
                d="M59.19,297.77c-0.39-0.25-0.79-0.5-1.2-0.76C58.4,297.26,58.8,297.52,59.19,297.77z"
              />
              <g>
                <path
                  fill="#D7BFB1"
                  d="M73.96,282.37c-1.01-1.7-1.45-2.33-1.82-3.46c-0.02,0.09-0.04,0.13-0.04,0.13s0.25-1.15,0.04-1.85
						c-0.03-0.1-0.18-0.22-0.21-0.33c-0.13-0.37-0.95-1.1-1-0.74c-0.15,1.04,0.26,6.39-1.81,7.29c-1.94,0.84-1.98-4.23-1.82-6.32
						c-0.51-0.18-1.55-0.56-1.94-0.65c-0.14-0.09-0.27-0.19-0.41-0.29c-0.3,0.05-0.49,0.09-0.49,0.09c0.43-0.18,1.32-0.47,2.31-0.78
						c-0.35-0.04-0.94-0.09-1.91-0.12c-0.14,0.12-0.29,0.25-0.44,0.38c0,0,0.11-0.16,0.31-0.39c-0.18-0.01-0.39-0.01-0.6-0.01
						l0.59-1.64c1.02-0.1,1.79-0.24,2.2-0.26c0.05-0.2,0.43-0.32,0.58-0.46c0.72-0.68,1.17-1.44,2.06-1.35
						c0.1,0.01,1.1,0.05,1.13,0.07c0.01,0.01,0.01,0.01,0.02,0.02c0.02,0.02,0.04,0.05,0.06,0.07c0.01,0.01,0.02,0.02,0.03,0.04
						c0.01,0.02,0.03,0.04,0.04,0.06c0.01,0.02,0.02,0.04,0.04,0.06c0.01,0.02,0.02,0.04,0.03,0.06c0,0.01,0.01,0.02,0.01,0.03
						c0.04,0.1,0.05,0.18,0.06,0.23c0,0,0,0,0,0c0,0,0.01,0,0.01,0.01c0.01,0,0.02,0,0.04,0.01c0.04,0.02,0.23,0.08,0.25,0.09
						c0.02,0,0.12,0.01,0.15,0.01c0.05,0,0.89,0.07,0.67-0.77c0.06-0.58,0.52-1.29,0.55-1.85c-0.02-1.15,1.18-3.55,0.57-5.68
						c-0.41-1.44-0.57-4.93-3.17-4.07c0,0,0.01,0,0.03-0.01c-0.01-0.03-0.02-0.06-0.04-0.09c-0.95,1.12-15.68-3.02-15.68-3.02
						l-22.31,33.7l-0.47,11.46c-2.32,1.09-4.56,1.46-4.66,1.48c0.03,1.05-0.07,2.32-0.55,3.52c-0.61,1.51-0.92,2.17-2.18,2.45
						c0,0,0,0,0,0c0.01,0,0.02,0,0.03,0c0.03,0.02,0.02,0.02-0.02,0.01c-1.66,0.84-3.27,1.43-4.4,1.63l2.53,1.33
						c10.47,8.79,25.17,34.31,25.17,34.31c4.27-6.74,5.68-22.01,6.14-27.15c0.15-1.62-0.47-2.86-0.47-2.86c0.01,0,0.01,0,0.02,0.01
						c-0.02-0.11-0.03-0.23-0.04-0.35c0.03-0.83-0.13-2.05-0.09-3.13c0.62-1.16-2.47-8.34,14.5-6.58c4.18-0.12,4.09-2.88,3.7-4.85
						c-0.4-2.01,0.53-2.11,0.88-3.27c-1.81-1.66-3.71-3.71-3.94-4.02c0.71-0.47,4.66-2.06,4.81-3.73c0.02,0.02,0.04,0.05,0.06,0.07
						c-0.89-1.95,0.83-2.01,2.25-2.85C77.35,286.83,76.31,286.34,73.96,282.37z M71.26,287.52c0.02-0.16,0.03-0.27,0.03-0.27
						C71.27,287.35,71.26,287.44,71.26,287.52z"
                />
                <path
                  fill="#D7BFB1"
                  d="M24.2,309.49L24.2,309.49c-0.13,0.02-0.26,0.04-0.4,0.06C23.94,309.53,24.07,309.51,24.2,309.49z"
                />
              </g>
              <path
                fill="#4C2B1A"
                d="M65.61,250.23c-4.73-5.22-11.22-6.8-21.9-5.63c0.1-0.02,0.21-0.03,0.31-0.05c0,0-2.51-0.08-4.37,0.5
					c-0.05,0.04-0.09,0.07-0.13,0.1l0.08,0l-0.96,0.18l0-0.17c-0.04-0.02-0.08-0.04-0.12-0.06c-0.01,0-0.01-0.01-0.02-0.01
					c-0.05-0.03-0.1-0.05-0.16-0.07c-1.25-0.06-2.4,0.03-3.45,0.23c-1.49,0.37-3.37,1.14-5.31,2.49c-0.25,0.18-0.5,0.36-0.75,0.55
					c-0.99-0.54-2.35-0.94-4.11-1.34c-8.19-1.91-12.79,3.49-14.42,7.87c-1.57,4.21-1.41,7.97-0.15,10.9
					c0.54,2.12,1.43,4.49,2.82,7.14c0,0-0.36-1.67-0.65-3.86c1.57,1.62,3.6,2.68,5.83,3.01c0.07,3.53,0.72,6.78,1.8,9.27
					c0.11,0.26,0.23,0.51,0.35,0.76c2.45,4.87,4,9.37,4.98,12.98c0.52,1.81,0.95,3.65,1.22,5.45c0.05,0.29,0.09,0.56,0.13,0.81l0,0
					c0,0,0.25,0.94,0.29,2.23c0.1-0.02,2.34-0.39,4.66-1.48c2.8-1.32,5.69-3.69,5-7.75c-0.21-0.04-0.05-1.91-0.27-1.96
					c-7.51-1.84-6.33-8.66-6.15-8.77c0.22-4.62,17.47-4.14,17.35,0.87c-0.01,0.61,1.12,0.91,0.99,1.49
					c1.61-1.56,4.12-3.73,7.97-6.56c0.35-0.26,0.7-0.53,1.04-0.8c-2.28,3.13-3.92,6.35-4.44,7.41c0.53-0.63,1.66-1.92,3.96-4.44
					c1.89-2.08,3.41-4.19,4.66-6.22c0.39-0.63,0.75-1.26,1.08-1.87c0.02-0.05,0.05-0.09,0.07-0.14c3.95-4.72,6.57-9.95,7.21-13.43
					c0.01,0.03,0.02,0.06,0.04,0.09c0.39-0.12,4.35-1.07,2.09,11.18c0,0,0.66-0.41,0.65,1.25C77.4,262.11,73.62,251.65,65.61,250.23
					z"
              />
              <path
                fill="#99857D"
                d="M26.92,303.51c0.03,1.05-0.07,2.32-0.55,3.52c-0.61,1.53-0.93,2.18-2.21,2.46
					c0.02,0,0.05-0.01,0.07-0.01c0.49,0.35,0.99,0.73,1.48,1.14c3.78-1.37,5.83-4.79,5.87-8.6
					C29.26,303.12,27.02,303.49,26.92,303.51z"
              />
              <path
                fill="#FFFFFF"
                d="M68.82,277.85c0.02,0,0.03,0,0.03,0L68.82,277.85C68.82,277.85,68.82,277.85,68.82,277.85z"
              />
              <path
                fill="#FFFFFF"
                d="M68.05,277.12c-0.01,0.1-0.01,0.19-0.02,0.29c0.03,0.01,0.06,0.03,0.09,0.04
					C68.09,277.34,68.07,277.23,68.05,277.12z"
              />
              <path
                fill="#FFFFFF"
                d="M68.97,277.38c-0.02,0.12-0.05,0.24-0.09,0.36C68.9,277.66,68.94,277.54,68.97,277.38z"
              />
              <path
                fill="#FFFFFF"
                d="M65.37,276.44c0.39,0.1,0.87,0.24,1.38,0.42c0.01-0.16,0.03-0.29,0.04-0.41
					c0.03-0.22,0.04-0.44,0.04-0.65c-0.71,0.14-1.42,0.27-1.88,0.35C65.09,276.25,65.23,276.35,65.37,276.44z"
              />
              <path
                fill="#AB7D62"
                d="M64.85,275.34c-0.04,0-0.08,0-0.13,0l-0.31,0.39C64.57,275.58,64.71,275.46,64.85,275.34z"
              />
              <path fill="#AB7D62" d="M71.29,287.25c0,0-0.02,0.11-0.03,0.27C71.26,287.44,71.27,287.35,71.29,287.25z" />
              <path
                fill="none"
                stroke="#AB7D62"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M71.26,287.52
					c0,0.01,0,0.02,0,0.03"
              />
              <path
                fill="#4C2B1A"
                d="M70.84,293.89c-0.59,0.17-1.07-0.14-1.6-0.14c-0.01,0.02-0.41,0.57-0.42,0.6c-0.05,0.34,0.92,1,1.5,1.31
					c0.13,0.07,0.22-0.2,0.32-0.15C70.05,295.17,69.87,294.46,70.84,293.89z"
              />
              <path
                fill="none"
                stroke="#669694"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M75.23,422.64
					c0.01,0,0.02,0,0.03,0.01"
              />
              <path
                fill="none"
                stroke="#669694"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M75.38,422.67
					c0.01,0,0.03,0.01,0.04,0.01"
              />
              <path fill="#659896" d="M3.42,439.89c-2.18-1.48-4.78-3.37-4.78-3.37S0.27,437.95,3.42,439.89z" />
              <path
                fill="#659896"
                d="M-1.17,435.23c0.03-0.18,0.06-0.38,0.1-0.6C-1.11,434.84-1.14,435.04-1.17,435.23z"
              />
              <path
                fill="#659896"
                d="M-11.97,385.38c0.11-0.43,0.22-0.87,0.32-1.3C-11.74,384.42-11.85,384.87-11.97,385.38z"
              />
              <path
                fill="#659896"
                d="M-9.58,408.67c-0.33,0.16-0.72,0.3-1.18,0.43C-10.32,408.98-9.93,408.84-9.58,408.67z"
              />
              <path fill="#659896" d="M-10.27,377.34c0,0,0.56-0.59,1.43-1.54C-9.46,376.41-9.96,376.95-10.27,377.34z" />
              <path
                fill="#659896"
                d="M-39.07,397.8c-0.11-0.25-0.21-0.52-0.31-0.78C-39.28,397.28-39.18,397.54-39.07,397.8z"
              />
              <path
                fill="#659896"
                d="M-39.05,397.84c1.9,4.5,5.67,8.3,12.53,9.89c3.15,0.73,6.23,1.31,8.96,1.59
					c-4.47-0.51-9.3-1.86-11.55-2.58C-32.91,405.52-36.9,402.77-39.05,397.84z"
              />
              <path
                fill="#659896"
                d="M-7.64,405.88c0.02,0.19,0.06,0.6-0.13,1.1c0.14-0.29,0.24-0.61,0.3-0.96c-0.04-0.05-0.08-0.1-0.12-0.15
					C-7.6,405.87-7.62,405.87-7.64,405.88z"
              />
              <polygon fill="#659896" points="7.88,357.8 11.61,358.17 7.88,357.8 				" />
              <path
                fill="#41637C"
                d="M11.18,496.45c-1.31-10.14-1.69-20.63-1.67-29.56c3.19-9.67,30.43-15.85,35.71-16.89
					c-8.89,0.34-16.6-0.7-23.05-2.33c-5.09-1.26-9.1-2.83-11.94-4.17c0,0,0,0,0,0c-1.5-0.68-2.87-1.37-4.09-2.03
					c-0.01,0.03-0.02,0.07-0.03,0.1c0.02,0.01,0.05,0.02,0.07,0.03c-0.06,0.09-0.13,0.26-0.21,0.49c-1.18,4.26-1.88,8.17-2.25,11.98
					c0,0.03-0.01,0.06-0.01,0.09c-0.03,0.3-0.05,0.6-0.08,0.9c-0.27,3.55-0.29,8.53,0.41,18.24c0.56,6.24,1.37,13.18,1.98,21.67
					c0.09,1.25,0.18,2.46,0.27,3.63c0,0,0,0,0,0c0.51,5.38,1.01,10.61,1.45,15.12c0,0,0,0,0,0c1.21,10.29,2.23,13.93,2.23,13.93
					s1.76,1.34,4.73,3.38c0,0,0,0,0,0C14.58,528.32,13.95,517.81,11.18,496.45z"
              />
              <path
                fill="#254354"
                d="M11.18,496.45c-1.31-10.14-1.69-20.63-1.67-29.56c3.19-9.67,30.43-15.85,35.71-16.89
					c-8.89,0.34-16.6-0.7-23.05-2.33c-5.09-1.26-9.1-2.83-11.94-4.17c0,0,0,0,0,0c-1.5-0.68-2.87-1.37-4.09-2.03
					c-0.01,0.03-0.02,0.07-0.03,0.1c0.02,0.01,0.05,0.02,0.07,0.03c-0.06,0.09-0.13,0.26-0.21,0.49c-1.18,4.26-1.88,8.17-2.25,11.98
					c0,0.03-0.01,0.06-0.01,0.09c-0.03,0.3-0.05,0.6-0.08,0.9c-0.27,3.55-0.29,8.53,0.41,18.24c0.56,6.24,1.37,13.18,1.98,21.67
					c0.09,1.25,0.18,2.46,0.27,3.63c0,0,0,0,0,0c0.51,5.38,1.01,10.61,1.45,15.12c0,0,0,0,0,0c1.21,10.29,2.23,13.93,2.23,13.93
					s1.76,1.34,4.73,3.38c0,0,0,0,0,0C14.58,528.32,13.95,517.81,11.18,496.45z"
              />
              <path
                fill="#659896"
                d="M45.22,450c0.14-0.03,0.26-0.05,0.37-0.07c-9.34,0.37-17.19-0.72-23.42-2.26
					C28.62,449.3,36.33,450.34,45.22,450z"
              />
              <path
                fill="#659896"
                d="M10.23,443.5c-3.25-5.12-4.4-12.83-4.75-19.07L2,422.77c-1.33,4.16-2.44,8.14-3.08,11.87
					c-0.04,0.22-0.07,0.42-0.1,0.6c-0.07,0.43-0.13,0.86-0.19,1.28c0,0,2.6,1.89,4.78,3.37c0.81,0.5,1.72,1.03,2.73,1.58
					C7.37,442.13,8.73,442.81,10.23,443.5z"
              />
              <path
                fill="#3E6869"
                d="M10.23,443.5c-3.25-5.12-4.4-12.83-4.75-19.07L2,422.77c-1.33,4.16-2.44,8.14-3.08,11.87
					c-0.04,0.22-0.07,0.42-0.1,0.6c-0.07,0.43-0.13,0.86-0.19,1.28c0,0,2.6,1.89,4.78,3.37c0.81,0.5,1.72,1.03,2.73,1.58
					C7.37,442.13,8.73,442.81,10.23,443.5z"
              />
              <polygon fill="#161616" points="5.48,424.43 5.48,424.43 1.81,422.68 1.81,422.68 2,422.77 				" />
              <path
                fill="#659896"
                d="M-13.03,388.92c0.01-0.05,0.02-0.11,0.02-0.16C-13.02,388.81-13.02,388.87-13.03,388.92
					c0.1-0.07,0.21-0.14,0.32-0.21c0.17-0.89,0.33-1.71,0.38-1.86c-0.01-0.06,0.01-0.17,0.06-0.34c0.1-0.37,0.2-0.74,0.3-1.13
					c0.12-0.51,0.22-0.96,0.32-1.3c0.81-3.41,1.38-6.74,1.38-6.74c0.31-0.39,0.82-0.93,1.43-1.54c0.14-0.16,0.3-0.33,0.46-0.51
					l-0.19-1.34c-1.6,1.08-3.16,1.79-4.35,1.3c-3.87-1.59-1.88,6.59-1.48,13.93c0.3,0.09,0.52,0.23,0.69,0.4
					C-13.54,389.36-13.32,389.14-13.03,388.92z"
              />
              <path
                fill="#3E6869"
                d="M-13.03,388.92c0.01-0.05,0.02-0.11,0.02-0.16C-13.02,388.81-13.02,388.87-13.03,388.92
					c0.1-0.07,0.21-0.14,0.32-0.21c0.17-0.89,0.33-1.71,0.38-1.86c-0.01-0.06,0.01-0.17,0.06-0.34c0.1-0.37,0.2-0.74,0.3-1.13
					c0.12-0.51,0.22-0.96,0.32-1.3c0.81-3.41,1.38-6.74,1.38-6.74c0.31-0.39,0.82-0.93,1.43-1.54c0.14-0.16,0.3-0.33,0.46-0.51
					l-0.19-1.34c-1.6,1.08-3.16,1.79-4.35,1.3c-3.87-1.59-1.88,6.59-1.48,13.93c0.3,0.09,0.52,0.23,0.69,0.4
					C-13.54,389.36-13.32,389.14-13.03,388.92z"
              />
              <polygon fill="#161616" points="-8.38,375.35 -8.38,375.35 -8.58,373.96 -8.58,373.96 -8.39,375.3 				" />
              <path
                fill="#659896"
                d="M88.79,366.89c-5.63-33.77-11.52-38.69-17.04-42.76c0,0-4.38-2.66-18.54-7.38c-0.01,0-0.01,0-0.02-0.01
					c0,0-0.05,0.99-0.19,2.6c-0.46,5.14-1.94,16.66-6.21,23.4c0,0-10.61-23.33-21.08-32.12c-0.49-0.41-0.99-0.8-1.48-1.14
					c-0.02,0-0.05,0.01-0.07,0.01c-0.35,0.08-0.78,0.13-1.31,0.16c0,0,0,0,0,0c-0.88,0.08-1.85,0.14-2.9,0.18
					c-1.41,0.05-2.95,0.09-4.56,0.14c-8.86,0.28-20.1,1.18-25.19,9.87c-7.09,12.11-3.27,11.05-13.09,27.41
					c-2.52,4.21-4.57,8.05-6.2,11.35c-0.86,1-2.23,2.79-3.84,5.54c0,0,0,0,0,0c-2.24,4.51-4.69,13.13-2.56,18.76
					c0.07,0.1,0.13,0.24,0.2,0.43c0.03,0.08,0.05,0.16,0.08,0.23c0.23,0.49,0.49,0.96,0.8,1.39c3.03,4.19,6.13,14.87,9.38,18.02
					c0.99-0.67,1.88-6.55,2.55-7.5c1.29-4.22,3.67-5.59,4.7-5.93c1.64-0.54,2.69-0.57,3.37-0.35c-0.4-7.34-2.39-15.52,1.48-13.93
					c1.19,0.49,4.27,0.07,5.87-1.01l-2.49-15.18c-0.26-1.62-0.43-1.73,0.71-1.26l12.07-0.46l2.25-2.93c0.58-0.84,0.97-1.3,2.1,2.34
					c0.1,0.32,0.2,0.67,0.31,1.05l3.63,0.36c0.03,0.36,0.06,0.71,0.1,1.05l7.42,2.15c1.11,0.45,1.07,0.96,1.11,2.45l8.46,69.32
					c0.05,1.51-0.72,0.69-1.85,0L4.16,421.8c0.35,6.24,2.09,17.89,5.35,23.01c3.31,1.5,7.47,3.27,12.12,4.45
					c6.23,1.55,14.33,1.93,23.96,0.66c-0.11,0.02,0.5,1.39,0.37,1.41c1.95-0.08,3.22-1.56,5.28-1.78l23.67-0.77l3.65-0.12
					c0.32-5.52-5.69-36.77-5.69-36.77c15.5,11.81,18.59-4.42,19.19-9.23C93.17,393.82,94,398.13,88.79,366.89z"
              />
              <path
                fill="#3E6869"
                d="M11.61,358.17v1.04c-1.05-9.2,1.88-23.9,1.22-22.8c-0.82,1.36-2.18,10.91-3.27,5.73
					c-1.09-5.18-1.91-6.82-1.91-6.82c0,9.27-1.97,13.59-0.09,21.42c0,0,0,0,0,0.01c0.1,0.32,0.67,2,0.78,2.39L11.61,358.17z"
              />
              <polygon fill="#161616" points="11.79,358.19 11.79,358.19 11.61,358.17 				" />
              <path
                fill="#659896"
                d="M-7.64,405.88c-3.17,0.68-6.45-0.81-8.31-3.04c-1.37-1.65-1.9-2.96-2.09-3.58
					c-0.53-1.53-0.61-3.08-0.1-4.39c1.24-3.18,2.8-3.72,3.68-3.74c0.01-0.05,0.02-0.11,0.04-0.16c0.02-0.06,0.04-0.12,0.07-0.18
					c-0.01-0.53-0.03-1.06-0.06-1.6c-0.68-0.22-1.74-0.19-3.37,0.35c-1.03,0.34-3.41,1.7-4.7,5.93c-0.67,0.95-1.56,6.83-2.55,7.5
					c-3.24-3.15-6.34-13.83-9.38-18.02c-0.31-0.43-0.58-0.9-0.8-1.39c-0.03-0.08-0.05-0.16-0.08-0.23c-0.07-0.19-0.13-0.33-0.2-0.43
					c-2.13-5.63,0.31-14.25,2.56-18.76c0,0,0,0,0,0c-1.7,2.89-3.67,6.84-5.6,12.08c-1.52,4.13-3.6,13.29-0.84,20.8
					c0.1,0.26,0.2,0.53,0.31,0.78c0.01,0.01,0.01,0.03,0.02,0.04c2.15,4.93,6.14,7.68,9.94,8.9c2.25,0.72,7.07,2.07,11.55,2.58
					c2.69,0.27,5.04,0.25,6.81-0.23c0.45-0.13,0.84-0.27,1.18-0.43c0.83-0.4,1.45-0.95,1.81-1.69
					C-7.58,406.48-7.62,406.06-7.64,405.88z"
              />
              <path
                fill="#3E6869"
                d="M-7.5,406.1c-3.17,0.68-6.63-0.95-8.49-3.18c-1.37-1.65-1.9-2.96-2.09-3.58
					c-0.53-1.53-0.61-3.08-0.1-4.39c1.24-3.18,2.8-3.72,3.68-3.74c0.01-0.05,0.02-0.11,0.04-0.16c0.02-0.06,0.04-0.12,0.07-0.18
					c-0.01-0.53-0.03-1.06-0.06-1.6c-0.68-0.22-1.74-0.19-3.37,0.35c-1.03,0.34-3.41,1.7-4.7,5.93c-0.67,0.95-1.56,6.83-2.55,7.5
					c-3.24-3.15-6.34-13.83-9.38-18.02c-0.31-0.43-0.58-0.9-0.8-1.39c-0.03-0.08-0.05-0.16-0.08-0.23c-0.07-0.19-0.13-0.33-0.2-0.43
					c-2.13-5.63,0.31-14.25,2.56-18.76c0,0,0,0,0,0c-1.7,2.89-3.67,6.84-5.6,12.08c-1.52,4.13-3.6,13.29-0.84,20.8
					c0.1,0.26,0.2,0.53,0.31,0.78c0.01,0.01,0.01,0.03,0.02,0.04c2.15,4.93,6.14,7.68,9.94,8.9c2.25,0.72,7.11,1.99,11.58,2.5
					c2.69,0.27,4.98,0.27,6.74-0.2c0.45-0.13,0.86-0.27,1.19-0.42c0.83-0.4,1.46-0.9,1.82-1.64C-7.61,406.56-7.48,406.29-7.5,406.1z
					"
              />
              <path
                fill="#659896"
                d="M-13.72,389.59c-0.17-0.16-0.4-0.3-0.69-0.4c0.03,0.54,0.05,1.07,0.06,1.6
					C-14.21,390.41-14.03,390-13.72,389.59z"
              />
              <path
                fill="#41637C"
                d="M38.43,246l1.01,0c0.06,0,0.11,0.01,0.17,0.02l0-0.86l-0.08,0l-0.88,0l-0.19,0L38.43,246z"
              />
              <path
                fill="#41637C"
                d="M40.26,274.3l-0.07-27.51c0-0.37-0.25-0.68-0.58-0.76c-0.05-0.01-0.11-0.02-0.17-0.02l-1.01,0l-0.03,0
					c-0.41,0-0.75,0.35-0.75,0.79l-0.14,27.56c0.58-0.09,1.17-0.14,1.77-0.12C39.61,274.23,39.94,274.26,40.26,274.3z"
              />

              <linearGradient
                id="Card31MSVGID5"
                gradientUnits="userSpaceOnUse"
                x1="76.2713"
                y1="220.9518"
                x2="62.0451"
                y2="229.1654"
                gradientTransform="matrix(0.9997 0.0235 -0.0235 0.9997 -25.3771 33.9441)"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.5156" style={{ stopColor: "#020202", stopOpacity: 0.5156 }} />
                <stop offset="0.7501" style={{ stopColor: "#090909", stopOpacity: 0.7501 }} />
                <stop offset="0.9271" style={{ stopColor: "#161615", stopOpacity: 0.9271 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31MSVGID5)"
                d="M40.26,274.3l-0.07-27.51c0-0.37-0.25-0.68-0.58-0.76c-0.05-0.01-0.11-0.02-0.17-0.02l-1.01,0
					l-0.03,0c-0.41,0-0.75,0.35-0.75,0.79l-0.14,27.56c0.58-0.09,1.17-0.14,1.77-0.12C39.61,274.23,39.94,274.26,40.26,274.3z"
              />
              <g>
                <path
                  fill="#99857D"
                  d="M36.32,292.32c0.21,0.05,0.06,1.92,0.27,1.96c2.64,0.53,4.66,0.19,6.21-0.55c0,0,0.01,0,0.01,0
						c1.58-0.77,2.66-1.95,3.4-3.06c3.07,2.02,10.58,6.93,12.39,8.02c1.52,0.91,2.25,0.57,2.06-0.01
						c-0.12-0.07-11.48-7.63-13.94-9.2c-0.2,0.25-13.61-3.05-16.69-5.08C29.87,286.3,30.38,290.87,36.32,292.32z"
                />
                <path
                  fill="#33190D"
                  d="M29.38,283.67c0-0.08,0.01-0.16,0.01-0.25c-0.04-0.01-0.07-0.01-0.08-0.01
						c-0.18,0.11-0.87,8.9,6.64,10.73c0.21,0.05,0.42,0.1,0.63,0.14c-0.21-0.04-0.05-1.91-0.27-1.96c-5.94-1.45-6.44-6.02-6.31-7.93
						C29.61,284.13,29.38,283.88,29.38,283.67z"
                />
              </g>
              <circle fill="#41637C" cx="39.02" cy="283.87" r="9.65" />
              <linearGradient
                id="Card31MSVGID6"
                gradientUnits="userSpaceOnUse"
                x1="29.3781"
                y1="283.8963"
                x2="48.7237"
                y2="283.8963"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4913" style={{ stopColor: "#020202", stopOpacity: 0.4913 }} />
                <stop offset="0.7387" style={{ stopColor: "#0A0A09", stopOpacity: 0.7387 }} />
                <stop offset="0.931" style={{ stopColor: "#171716", stopOpacity: 0.931 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31MSVGID6)"
                d="M29.38,283.67c-0.04,1.84,0.43,3.56,1.28,5.05c0.23,0.4,0.49,0.79,0.78,1.16
					c0.19,0.24,0.39,0.48,0.61,0.7c0.64,0.67,1.38,1.25,2.19,1.72c0.65,0.37,1.34,0.67,2.08,0.88c0.8,0.23,1.64,0.37,2.51,0.39
					c0.19,0,0.37,0,0.55,0c0.06,0,0.12-0.01,0.17-0.01c0.13-0.01,0.25-0.01,0.38-0.03c0.07-0.01,0.13-0.01,0.2-0.02
					c0.12-0.01,0.23-0.03,0.35-0.04c0.06-0.01,0.13-0.02,0.19-0.03c0.12-0.02,0.24-0.04,0.37-0.07c0.05-0.01,0.11-0.02,0.16-0.03
					c0.17-0.04,0.34-0.08,0.51-0.13c0,0,0,0,0,0c0.17-0.05,0.34-0.1,0.51-0.16c0.04-0.01,0.08-0.03,0.12-0.04
					c0.13-0.05,0.25-0.09,0.38-0.14c0.05-0.02,0.1-0.04,0.15-0.06c0.12-0.05,0.23-0.1,0.35-0.16c0.05-0.02,0.09-0.04,0.14-0.07
					c0.12-0.06,0.24-0.12,0.36-0.19c0.03-0.02,0.07-0.04,0.1-0.06c0.31-0.18,0.61-0.37,0.9-0.58c0.01-0.01,0.03-0.02,0.04-0.03
					c0.13-0.09,0.25-0.19,0.38-0.29c0.03-0.02,0.06-0.05,0.08-0.07c0.11-0.09,0.22-0.18,0.32-0.28c0.03-0.03,0.06-0.05,0.08-0.08
					c0.11-0.1,0.22-0.21,0.32-0.31c0.02-0.02,0.04-0.04,0.06-0.06c0.25-0.26,0.49-0.53,0.71-0.82c-1.51-0.96-2.75-1.75-3.53-2.25
					c-1.04,1.17-2.57,1.89-4.25,1.85c-3.05-0.07-5.46-2.6-5.39-5.65c0.07-3.05,2.6-5.46,5.65-5.39c3.05,0.07,5.46,2.6,5.39,5.65
					c-0.02,0.8-0.21,1.56-0.53,2.24c0.82,0.55,2.05,1.37,3.48,2.31c0.45-0.82,0.79-1.72,0.99-2.66c0.12-0.58,0.2-1.18,0.21-1.79
					c0.12-5.01-3.59-9.22-8.46-9.82c-0.32-0.04-0.65-0.07-0.98-0.07c-0.6-0.01-1.2,0.03-1.77,0.12c-4.44,0.71-7.89,4.45-8.11,9.08h0
					C29.39,283.51,29.38,283.59,29.38,283.67z"
              />
              <path
                fill="#41637C"
                d="M44.04,286.26c0.32-0.68,0.52-1.43,0.53-2.24c0.07-3.05-2.34-5.58-5.39-5.65
					c-3.05-0.07-5.58,2.34-5.65,5.39c-0.07,3.05,2.34,5.58,5.39,5.65c1.69,0.04,3.21-0.68,4.25-1.85
					C43.52,287.18,43.81,286.74,44.04,286.26z M38.99,286.45c-1.41-0.03-2.52-1.2-2.49-2.61c0.03-1.41,1.2-2.52,2.61-2.49
					c1.41,0.03,2.52,1.2,2.49,2.61C41.57,285.37,40.4,286.48,38.99,286.45z"
              />

              <linearGradient
                id="Card31MSVGID7"
                gradientUnits="userSpaceOnUse"
                x1="75.8088"
                y1="248.3691"
                x2="64.7605"
                y2="248.3691"
                gradientTransform="matrix(0.9997 0.0235 -0.0235 0.9997 -25.3771 33.9441)"
              >
                <stop offset="0" style={{ stopColor: "#000000", stopOpacity: 0 }} />
                <stop offset="0.4913" style={{ stopColor: "#020202", stopOpacity: 0.4913 }} />
                <stop offset="0.7387" style={{ stopColor: "#0A0A09", stopOpacity: 0.7387 }} />
                <stop offset="0.931" style={{ stopColor: "#171716", stopOpacity: 0.931 }} />
                <stop offset="1" style={{ stopColor: "#1E1E1C" }} />
              </linearGradient>
              <path
                fill="url(#Card31MSVGID7)"
                d="M44.04,286.26c0.32-0.68,0.52-1.43,0.53-2.24c0.07-3.05-2.34-5.58-5.39-5.65
					c-3.05-0.07-5.58,2.34-5.65,5.39c-0.07,3.05,2.34,5.58,5.39,5.65c1.69,0.04,3.21-0.68,4.25-1.85
					C43.52,287.18,43.81,286.74,44.04,286.26z M38.99,286.45c-1.41-0.03-2.52-1.2-2.49-2.61c0.03-1.41,1.2-2.52,2.61-2.49
					c1.41,0.03,2.52,1.2,2.49,2.61C41.57,285.37,40.4,286.48,38.99,286.45z"
              />
              <path
                fill="#161616"
                d="M41.6,283.96c0.03-1.41-1.08-2.58-2.49-2.61c-1.41-0.03-2.58,1.08-2.61,2.49
					c-0.03,1.41,1.08,2.58,2.49,2.61C40.4,286.48,41.57,285.37,41.6,283.96z"
              />
              <path
                fill="#41637C"
                d="M60.27,298.45c0.13,0.08,0.25,0.15,0.37,0.22l0,0C60.52,298.61,60.4,298.54,60.27,298.45z"
              />
              <path
                fill="#41637C"
                d="M56.73,296.2c-0.36-0.23-0.73-0.47-1.11-0.71C55.99,295.73,56.36,295.97,56.73,296.2z"
              />
              <path
                fill="#41637C"
                d="M60.27,298.45c-0.35-0.22-0.71-0.45-1.08-0.69C59.56,298,59.92,298.23,60.27,298.45z"
              />
              <path fill="#41637C" d="M57.99,297c-0.06-0.04-0.11-0.07-0.17-0.11C57.88,296.93,57.93,296.97,57.99,297z" />
              <path
                fill="#41637C"
                d="M46.7,289.82L46.7,289.82L46.7,289.82c2.47,1.57,5.64,3.59,8.63,5.49
					C52.34,293.41,49.17,291.39,46.7,289.82z"
              />
              <path
                fill="#33190D"
                d="M24.03,258.09c6.01-12.83,14.29-12.87,14.29-12.87l0.33-0.05c-0.01,0-0.13-0.07-0.14-0.07
					c-0.05-0.03-0.1-0.05-0.16-0.07c-0.89-0.22-2.4-0.02-3.45,0.23c-1.49,0.37-3.37,1.14-5.31,2.49
					C24.94,251.58,24.03,258.09,24.03,258.09z"
              />
              <polygon fill="#41637C" points="43.17,287.57 46.7,289.82 47.52,288.57 44.04,286.26 				" />
              <path
                fill="#41637C"
                d="M44.04,286.26c-0.23,0.48-0.52,0.92-0.87,1.31c0.78,0.49,2.02,1.28,3.53,2.25l0,0
					c2.47,1.57,5.64,3.59,8.63,5.49c0.1,0.06,0.19,0.12,0.29,0.19c0.37,0.24,0.74,0.47,1.11,0.71c0.37,0.24,0.73,0.47,1.09,0.69
					c0.06,0.04,0.11,0.07,0.17,0.11c0.41,0.26,0.81,0.52,1.2,0.76c0.37,0.24,0.74,0.47,1.08,0.69c0.13,0.08,0.25,0.15,0.37,0.22l0,0
					c2.48,1.38,3.28-0.26,1.22-1.3c-1.19-0.6-1.98-1.15-4.98-2.91c-2.99-1.75-6.57-4.06-9.36-5.89
					C46.09,287.63,44.86,286.82,44.04,286.26z"
              />
              <path
                fill="none"
                d="M-4.58,404.35c0.01,0.15-0.02-0.05,0.32-0.31l-0.02-0.14C-4.48,404.08-4.59,404.25-4.58,404.35z"
              />
              <path
                fill="none"
                d="M-5.03,398.69l-0.02-0.16c-0.13,0.03-0.2,0.05-0.2,0.05C-5.41,398.65-5.3,398.68-5.03,398.69z"
              />
              <path
                fill="none"
                d="M-8.85,375.8c-0.86,0.95-1.43,1.54-1.43,1.54c0.1,1.04-0.57,3.92-1.38,6.74c0,0,0,0,0,0
					c-0.1,0.43-0.21,0.87-0.32,1.3c-0.24,1.06-0.51,2.35-0.74,3.33c0.23-0.14,0.5-0.28,0.81-0.41c0,0,0.15-0.05,0.4-0.14
					c0.8-0.77,3.68-3.52,4.38-3.87l0.06,0.25l-0.31-2.14l0.01,0l-0.49-3.38l-0.53-3.67C-8.54,375.5-8.7,375.65-8.85,375.8
					C-8.85,375.8-8.85,375.8-8.85,375.8z"
              />
              <path
                fill="none"
                d="M-5.96,392.2l0-0.03c-0.18,0.11-0.47,0.27-0.91,0.51C-6.29,392.36-6.05,392.24-5.96,392.2z"
              />
              <path
                fill="#161616"
                d="M-3.01,402.54c-0.66,0.15-0.98,1.07-1.27,1.35l0.02,0.14c0.22-0.17,1.01,0.4,1.66,0.25
					c1.66-0.37,5.09-1.22,6.37-1.49c1.27-0.27,5.19-2.81,5.19-2.81l-0.11-0.06c0,0-3.5,0.03-5.22,0.64S-1.9,402.3-3.01,402.54z"
              />
              <path
                fill="#161616"
                d="M5.48,424.43L5.48,424.43l22.83,10.88c1.13,0.69,2.05-0.01,2.01-1.52l-7.09-71.11
					c-0.05-1.49-1.03-3.14-2.13-3.59l-9.31-0.91l0,0l-0.18-0.02l-3.73-0.36l-4.66-0.46l-12.42-1.21c-1.14-0.47-1.92,0.55-1.66,2.16
					l2.27,15.66l0,0l0.2,1.39l0,0l0.53,3.67l0.49,3.38l-0.01,0l0.31,2.14l0.19,2.9c2.29-0.81,5.28-1.85,6.57-2.31
					c2.4-0.88,4.16-1.14,5.23,0c0.6,0.5,0.8,0.91,0.28,1.23c0,0-0.52,0.46-0.53,0.46c-0.84,1.01-2.65,0.77-4.73,1.74
					c-2.73,1.27-5.9,2.23-5.9,2.23s-0.24,1.27-0.46,1.39l0,0c0.06-0.02-0.3,0-0.3,0l0.2,0.31c0.01,0,0.24,0.08,0.24,0.08l0.18,1.24
					c0.22-0.01,0.81,0.74,1.07,0.72c0,0,7.26-1.04,10.74-1.56c2.87-0.43,4.07-0.97,4.84-0.15c0.77,0.55,0.7,1.23,0.03,1.46
					c-0.15,0.05-0.21,0.16-0.39,0.22c-0.78,0.52-1.66,0.46-2.99,0.72c-1.76,0.36-7.46,1.19-10.79,1.92
					c-0.74,0.16-0.83,0.6-1.22,0.68c-0.1,0.02-0.71,0.67-0.78,0.69l0.14,0.41c0.08,0,0.65,0.11,0.75,0.11
					c1.03-0.02,3.32-0.14,4.82-0.44c2.16-0.43,9.47-2.62,11.29-2.59c0.85,0.01,1.02,0.75,1.1,1.42c0.37,0.56,0,0.86-0.48,1.09
					c-0.34,0.17-0.17-0.12-0.62,0.04c-0.04,0.02-0.7,0.37-0.74,0.39c-1.19,0.51-2.56,1.16-2.56,1.16s0.76,0.14,1.04,0.62
					c0.41,0.36,0.77,0.64,0.42,0.91c-0.16,0.12-0.39,0.19-0.6,0.33c-0.41,0.33-1.22,0.6-2.11,1.1c-1.72,0.96-3.62,1.1-5.25,1.82
					c-1.32,0.58-3.59,0.99-5.23,1.32c-0.11,0.02-0.29,0.04-0.39,0.06l0.82,5.17l0.04-0.01l1.05,7.28c0.19,1.21,0.92,2.41,1.78,2.94
					l2.14,1.02l0,0L5.48,424.43z"
              />
              <path
                fill="#AB7D62"
                d="M-6.49,393.46c0.22,0.01,0.48,0,0.78-0.01l-0.14-0.97c0,0,0,0-0.01,0l0.13,0.94
					C-6.02,393.45-6.27,393.46-6.49,393.46z"
              />
              <path
                fill="#D7BFB1"
                d="M1.91,405.44c1.64-0.72,3.73-1.16,5.45-2.11c0.88-0.49,1.52-0.81,1.93-1.13
					c0.08-0.06,1.5-0.55,0.47-1.68c-0.29-0.48-0.92-0.59-0.92-0.59l0.11,0.06c0,0-4.69,1.81-5.97,2.09c-1.27,0.27-4.34,1.07-6,1.44
					c-0.66,0.15-1.03,0.35-1.25,0.52c-0.34,0.26-0.3,0.47-0.32,0.31c-0.01-0.1,0.1-0.27,0.3-0.46c0.3-0.28,0.8-0.59,1.47-0.74
					c1.11-0.25,5.02-1.14,6.74-1.76s4.92-1.47,4.92-1.47s1.39-0.31,2.61-0.76c2.54-0.95,1.44-1.59,1.53-2.17
					c0.09-0.58-0.59-1.21-1.44-1.22c-1.82-0.02-9.38,1.92-11.54,2.36c-1.5,0.3-3.74,0.54-4.78,0.56c-0.1,0-0.19,0-0.26,0
					c-0.27-0.01-0.38-0.04-0.23-0.11c0,0,0.08-0.02,0.2-0.05c0.07-0.02,0.15-0.03,0.25-0.06c0.4-0.09,1.02-0.23,1.76-0.39
					c3.32-0.73,8.92-1.92,10.68-2.27c1.33-0.27,2.57-0.65,3.35-1.16c1.62-0.86,0.41-1.96,0.4-2.22c-0.59-0.76-2.52-0.84-5.39-0.41
					c-3.48,0.52-10.99,1.41-10.99,1.41c-0.26,0.01-0.5,0.02-0.73,0.03c-0.29,0.01-0.55,0.01-0.78,0.01
					c-1.63,0.05-1.51-0.15-0.39-0.78c0.44-0.24,0.72-0.41,0.91-0.51c0.22-0.13,0.29-0.17,0.29-0.17s3.23-1.69,5.96-2.96
					c2.09-0.97,4.61-1.57,5.44-2.58c0.69-0.4,0.17-1.66-0.18-1.78c-1.08-1.14-3.73-1.28-6.14-0.4c-1.28,0.47-3.73,1.34-6.02,2.15
					l-0.45-1.89l-0.06-0.25c-0.7,0.35-3.58,3.11-4.38,3.87c-0.25,0.09-0.4,0.14-0.4,0.14c-0.31,0.13-0.58,0.27-0.81,0.41
					c0,0,0,0,0,0c-0.11,0.07-0.22,0.14-0.32,0.21c0,0,0,0,0,0c-0.29,0.21-0.51,0.44-0.68,0.67c-0.32,0.41-0.49,0.82-0.63,1.2
					c-0.02,0.06-0.04,0.12-0.07,0.18c-0.02,0.05-0.03,0.11-0.04,0.16c-0.88,0.02-2.44,0.56-3.68,3.74c-0.51,1.31-0.43,2.87,0.1,4.39
					c-0.08-0.26-0.1-0.41-0.1-0.41s0.58,3.42,4.42,5.89c3.83,2.46,6.2,1.21,6.13,1.12c-0.02-0.03-0.02-0.03,0,0
					c0.04,0.05,0.08,0.1,0.12,0.15c0.59,0.73,1.65,1.33,2.92,1.16c0.2-0.03,0.44-0.07,0.71-0.12c0.1-0.02,0.2-0.04,0.31-0.06
					C-1.87,406.67,0.59,406.02,1.91,405.44z"
              />
              <path
                fill="#AC7D61"
                d="M-6.49,393.46c0.22-0.01,0.47-0.02,0.76-0.03l-0.13-0.94l-0.04-0.31c0,0,0,0-0.06,0.02
					c-0.09,0.04-0.32,0.15-0.91,0.48C-8,393.31-8.12,393.51-6.49,393.46z"
              />
            </g>
            <path
              fill="#3E6869"
              d="M69.46,406.65l-11.76-20.97c1.57-0.1,4.31-0.64,4.49-0.76c0.62-0.34,1.38-0.42,1.94-0.89
				c2.33-1.98,0.55-2.5,0.55-2.5s4.24-0.61,1.9-2.87c-2.33-2.25-4.55-4.45-6.61-6.18c-2.07-1.73-3.37-2.67-4.15-3.7
				c-0.77-1.03-1.52-2.54-3.17-2.27c0,0-0.34-0.7-1.08-1.19s-2.06-0.54-3.15,0.12c-1.1,0.66-4.86,2.66-7.07,3.56
				c-2.22,0.9-3.19,1.67-4.68,2.71c-1.49,1.04-3.06,1.78-4.69,2.41c-0.84,0.32-1.8,0.61-2.48,0.97c-0.55,0.26-0.97,0.54-0.89,0.88
				c0.01,0.05,0.02,0.1,0.04,0.15c0,0.1,0.02,0.21,0.06,0.33c0,0,0.74,2.74,4.23,1.59c2.21-0.73,7.55-2.98,7.55-2.98l0.05,0.56
				c0-0.01,0.01-0.01,0.01-0.02c-0.02,0.09-0.05,0.19-0.07,0.3c-0.14,0.66,0.06,1.34,0.53,2.06c0,0-1.04,0.6-1.65,1.35
				c-0.31,0.38-0.47,1.07-0.38,1.71c0,0.06,0,0.12,0.01,0.18c0.16,1.6,1.51,1.45,2.46,1.98c0.95,0.53,2.12,1.31,2.94,1.55
				c0.71,0.21,1.2-0.43,1.48-1.16c0.18,0.09,0.34,0.17,0.48,0.23c0,0-0.01,0.01-0.01,0.01l0.34,0.13c0.1,0.04,0.16,0.06,0.16,0.06
				s-0.48,1.04,0.88,1.86c1.36,0.82,2.64,1.4,3.3,1.42c0.66,0.02,1.87-0.76,1.87-0.76s0.91-0.69,2.16-0.93
				c0.99,0.69,6.29,9.23,8.82,13C67.93,404.64,69.46,406.65,69.46,406.65z"
            />
            <g>
              <path
                fill="#AB7D62"
                d="M45.05,373.17c0.51-0.34,1.73-1.33,3.01-2.15c0.89-0.57,1.88-0.99,2.24-1.27
					c0.88-0.66,1.7-2.95,1.16-2.67c-0.54,0.28-0.85-0.17-0.74-0.41c0,0-1.4,0.73-2.82,1.67c-1.42,0.94-3.11,2.22-4.01,2.71
					c-0.88,0.48-2.38,1.31-3.32,0.25c-0.03-0.05-0.07-0.11-0.1-0.17c-0.45,0.39-1.05,0.97-1.28,1.42c-0.22,0.43-1.16-0.29-1.33,1.06
					c-0.01,0.06-0.02,0.12-0.02,0.18c-0.11,1.61,1.25,1.68,2.09,2.37c0.84,0.68,1.87,1.64,2.64,2.02c0.66,0.33,1.26-0.23,1.65-0.9
					C43.78,376.95,44.18,373.75,45.05,373.17z"
              />
              <path
                fill="#D7BFB1"
                d="M33.25,368.85c1.77-0.32,5.74-0.87,7.5-1.43c1.76-0.57,5.83-3.18,6.87-3.7c1.03-0.52,1.73-0.7,1.13-0.3
					c-0.6,0.4-1.27,0.75-3.65,1.96c-2.36,1.2-4.33,2.53-4.77,3.17c-0.04,0.09-0.08,0.18-0.12,0.28c-0.25,0.63-0.16,1.33,0.18,2.12
					c0,0-1.12,0.41-1.86,1.05c-0.37,0.33-0.64,0.98-0.66,1.62c0.17-1.35,1.11-0.63,1.33-1.06c0.23-0.45,0.84-1.03,1.28-1.42
					c0.03,0.06,0.06,0.11,0.1,0.17c0.93,1.06,2.33,0.93,3.2,0.45c0.9-0.49,3.03-1.69,4.45-2.64c1.42-0.94,2.08-1.89,2.08-1.89
					c-0.11,0.24-0.33,0.64,0.21,0.36c0.54-0.28-0.07,0.25-0.95,0.91c-0.36,0.27-1.17,0.8-2.06,1.37c-1.28,0.82-2.73,1.72-3.24,2.06
					c-0.87,0.58-1.32,0.74-1.84,1.84c0,0,0-0.01,0-0.02c-0.09,0.29-0.16,0.69-0.07,1.14c-0.39-0.66-0.8-0.91-1.26-0.52
					c-0.53,0.46-0.53,1.89,0.05,2.35c0.58,0.46,0.84,1.32,1.26,1.13c0.39-0.18,0.53-1.22,0.31-2.09c0.01,0.02,0.02,0.04,0.04,0.06
					c0.44,0.62,1,1.12,1.45,1.47c0.16,0.12,0.3,0.23,0.43,0.31c0.66-0.85,2.47-1.62,4.13-2.53c1.67-0.91,2.67-2.24,2.67-2.24
					c-0.42,1.44-2.22,2.42-3.57,3.16c-1.35,0.74-2.45,1.46-2.76,1.9c0,0-0.65,0.95,0.56,1.98c1.2,1.03,2.37,1.82,3.02,1.96
					c0.64,0.13,1.97-0.44,1.97-0.44s0.27-0.1,0.44-0.46c0.17-0.36,0.66-0.27,0.66-0.27s0.29,0.97,1.08-0.77c0,0,0.43,1.07,1.52,1.15
					c1.09,0.08,1.68-0.56,2.15-0.97c0.47-0.41,0.6,2.13,2.56,3.2c1.96,1.07,3.23,1.85,3.23,1.85s-0.01,0.01-0.03,0.01
					c0.67-0.23,1.34-0.53,1.97-0.9c2.63-1.56,3.9-4.29,3.9-4.29s-0.75-1.41-2.67-4.02c-1.92-2.61-3.74-5.15-5.48-7.21
					c-1.75-2.05-2.88-3.2-3.47-4.34c-0.59-1.14-1.07-2.76-2.75-2.76c0,0-0.22-0.74-0.87-1.36c-0.65-0.61-1.94-0.88-3.13-0.41
					c-1.19,0.47-5.24,1.81-7.57,2.33c-2.33,0.52-3.42,1.11-5.06,1.89c-1.64,0.77-3.32,1.25-5.03,1.59c-0.88,0.18-1.88,0.3-2.61,0.54
					c0.24-0.07-0.96,1.34-1.01,0.88c-0.02,0.1-0.02,0.21,0.01,0.33C29.25,369.37,32.15,369.05,33.25,368.85z"
              />
              <path fill="#D7BFB1" d="M45.13,377.89l-0.15-0.09C45.07,377.86,45.13,377.89,45.13,377.89z" />
              <path
                fill="#AB7D62"
                d="M59.37,382.44c-1.96-1.07-1.67-4.04-2.14-3.63c-0.47,0.41-2,1.56-3.09,1.48
					c-1.09-0.08-1.37-1.72-1.37-1.72c-0.79,1.73-2.47,2.35-2.47,2.35s0.97-0.36,0.8,0.01c-0.17,0.36-0.44,0.46-0.44,0.46
					s1.76,0.74,3.2,0.89c0.94,0.1,1.62,0.74,2.37,1.63c0.75,0.89,1.53,1.87,1.53,1.87c2.34,0.3,4.27-0.54,4.52-0.66
					c0.02-0.01,0.03-0.01,0.03-0.01S61.32,383.51,59.37,382.44z"
              />
              <path
                fill="#AB7D62"
                d="M47.89,375.99c1.35-0.74,3.16-1.72,3.57-3.16c0,0-1,1.33-2.67,2.24c-1.66,0.91-3.47,1.68-4.13,2.53
					c0,0-0.01,0.01-0.01,0.01l0.32,0.18l0.15,0.09C45.44,377.45,46.53,376.73,47.89,375.99z"
              />
              <path
                fill="#AB7D62"
                d="M32.41,369.68c2.3-0.35,7.94-1.68,7.94-1.68l-0.04,0.56c0-0.01,0.01-0.01,0.01-0.02
					c0.44-0.64,2.41-1.96,4.77-3.17c2.38-1.22,3.06-1.56,3.65-1.96c0.6-0.4-0.1-0.22-1.13,0.3c-1.03,0.52-5.48,2.73-7.23,3.3
					c-1.76,0.57-5.37,1.14-7.14,1.46c-1.1,0.2-3.99,0.89-4.73-1.07C28.5,367.4,28.78,370.23,32.41,369.68z"
              />
              <path
                fill="#E7E5D8"
                d="M29.75,367.98c1.06-0.01,2.36-0.67,2.07-1.42c-0.3-0.75-0.63-0.83-1.54-0.64
					c-0.26,0.06-0.53,0.11-0.77,0.18c-0.59,0.16-1.04,0.37-1.03,0.72c0,0.05,0.01,0.1,0.01,0.16
					C28.55,367.45,28.8,367.99,29.75,367.98z"
              />
            </g>
            <path fill="#3E6869" d="M66.38,377.1c0,0,3.62-0.58,1.85,2.96L66.38,377.1z" />
            <path
              fill="#AB7D62"
              d="M74.08,288.25c0.76,0,1.14-0.52,0.79-0.55c-1.02-0.09-1.78,0.2-2.13,0.61
				C72.39,288.72,73.33,288.25,74.08,288.25z"
            />
            <path
              fill="#AB7D62"
              d="M73.05,289.01c-0.74,0.08-1.88-0.09-1.8-1.45C71.19,288.27,71.23,289.94,73.05,289.01z"
            />
            <path
              fill="#AB7D62"
              d="M68.18,293.6c0.02-0.25,0.1-0.51,0.17-0.75c0.39-1.26,1.83-3.9,1.83-3.9c0.02-0.1-2.74,4.28-3.52,7.43
				c-0.63,2.53,0.32-0.79,1.1-1.57c0.01,0.01,0.02,0.01,0.03,0.02c0,0,1.94,2.74,4.36,3.73c0,0,1.13-0.98-0.33-2.12
				c-0.5-0.4-0.61-0.52-1.49-1.12c-0.58-0.31-1.31-0.77-1.26-1.11c0-0.02-0.01,0.02,0,0c0.53-0.01,1.17-0.14,1.77-0.32
				c0.21-0.06,0.42-0.13,0.62-0.22c0,0,2.96-1.04,1.63-2.79c-0.02-0.02-0.04-0.05-0.06-0.07C73.02,290.75,70.5,292.74,68.18,293.6z"
            />
            <path
              fill="#AB7D62"
              d="M55.29,304.94c-5.75-1.92-7.41-2.83-8.91-6.08c0,0,2.39,5.07,3.97,7.91c1.58,2.83,2.76,4.96,2.67,7.96
				c0,0,1.63-7.02,5.16-7.28c5.05-0.37,9.7-0.72,9.7-0.72c-0.1-0.01-0.21-0.02-0.31-0.04C63.07,306.16,60.91,306.81,55.29,304.94z"
            />
            <path
              fill="#AB7D62"
              d="M64.2,276.52c-0.29,0.12-0.61,0.49-1.22,0.52C62.97,277.05,63.98,277.32,64.2,276.52z"
            />
            <path
              fill="#AB7D62"
              d="M63.56,276.5c0.38-0.19,0.43-0.27,0.43-0.27c-0.67,0.09-1.05-0.06-1.16-0.15
				C62.71,276,63.04,276.76,63.56,276.5z"
            />
            <path fill="#AB7D62" d="M64.73,275.33" />
            <g>
              <path fill="none" d="M70.85,274.4v0.05C70.87,274.42,70.87,274.41,70.85,274.4z" />
              <path
                fill="#D7BFB1"
                d="M68.29,274.93c0,0.03-0.01,0.05-0.01,0.08c0,0,0,0,0,0l0,0C68.28,274.98,68.28,274.95,68.29,274.93z"
              />
              <path
                fill="#D7BFB1"
                d="M69.97,271.27c-0.42-0.37-2.08,2.74-2.07,3.4c0,0-1.07,1.93-1.06,2.78c0,0.07-0.14,0.35-0.13,0.4
					c-0.92,0.03-0.63,0.94-0.64,1.05c-0.56,4.55,3.59,8,5.29,1.67C72.93,274.68,70.93,272.11,69.97,271.27z M70.85,274.4
					c0.03,0.01,0.03,0.02,0,0.05V274.4z"
              />
            </g>
            <path
              fill="#D7BFB1"
              d="M66.83,275.58c0-0.02,0-0.06,0-0.11c0,0-0.03-0.01-0.06-0.01c-1.01,0-2.05,0.65-2.47,0.82
				c0,0,0.19-0.03,0.49-0.09c0.46-0.08,1.33-0.26,2.04-0.39C66.83,275.69,66.83,275.69,66.83,275.58z"
            />
            <path
              fill="#4C2B1A"
              d="M66.83,275.47c-0.02,0-0.04-0.01-0.06-0.01c-0.99,0.3-1.88,0.6-2.31,0.78c0,0,0.19-0.03,0.49-0.09
				c0.46-0.08,1.17-0.21,1.88-0.35C66.83,275.69,66.83,275.58,66.83,275.47z"
            />
            <path
              fill="#4C2B1A"
              d="M69.18,269.44c-2.13-0.43-3.83,1.7-5.11,3.2c-0.27,0.32-0.48,0.56-0.65,0.76
				c0.1-0.01,0.19-0.01,0.28-0.02c1.16-0.92,3.16-2.38,4.31-2.55c0.8-0.12,1.46,0.13,1.96,0.45c0.3,0.06,0.45,0.35,0.59,0.49
				c0.3,0.29,0.37,0.48,0.38,0.55c0-0.02,0.07-0.09,0.11-0.08C71.41,271.62,71.22,269.84,69.18,269.44z"
            />
            <path
              fill="#4C2B1A"
              d="M71.63,277.32c0.01-0.01,0.03-0.02,0.04-0.04c-0.03-0.09-0.05-0.17-0.08-0.24
				c-0.09,0.11-0.18,0.21-0.25,0.29C71.44,277.33,71.54,277.33,71.63,277.32z"
            />
            <path
              fill="#161616"
              d="M72.18,271.13c2.27-12.25-1.7-11.3-2.09-11.18c-0.02,0.01-0.03,0.01-0.03,0.01
				c1.77,4.1,2.1,7.94,2.14,9.87c-0.03,0.57-0.08,1.15-0.14,1.72c-0.1,0.31-0.27,0.69-0.47,0.76c-0.05,0.02-0.1,0.02-0.15,0.02
				c-0.03,0-0.05,0-0.08,0c-0.02,0-0.05,0-0.07-0.01c-0.02,0-0.04-0.01-0.05-0.01c-0.08-0.02-0.15-0.06-0.2-0.08
				c-0.02-0.01-0.03-0.01-0.04-0.01c-0.01,0-0.01,0-0.01,0.01c0,0,0-0.01,0-0.02c0-0.04-0.02-0.12-0.06-0.23
				c0-0.01-0.01-0.02-0.01-0.03c-0.01-0.02-0.02-0.04-0.03-0.06c-0.01-0.02-0.02-0.04-0.04-0.06c-0.01-0.02-0.02-0.04-0.04-0.06
				c-0.01-0.01-0.1-0.12-0.11-0.13c-0.02-0.02-0.05-0.05-0.08-0.07c-0.15-0.13-0.36-0.24-0.66-0.3c-0.09-0.02-0.18-0.04-0.28-0.05
				c-0.89-0.09-1.87,0.77-2.59,1.45c-0.15,0.14-0.24,0.32-0.29,0.52c-0.53,0.03-1.63,0.09-3.1,0.17c-0.09,0.01-0.19,0.01-0.28,0.02
				c-0.21,0.01-0.42,0.02-0.64,0.04c-0.34,0.61-0.7,1.24-1.08,1.87c0.68,0,1.38,0.01,2.1,0.02c0.34,0,0.65,0.01,0.93,0.02
				c0.04,0,0.09,0,0.13,0c0.97,0.03,1.57,0.08,1.91,0.12c0.02,0,0.04,0.01,0.06,0.01c0,0.11,0,0.22,0,0.33
				c0,0.21-0.02,0.43-0.04,0.65c-0.01,0.12-0.03,0.26-0.04,0.41c-0.16,2.09-0.05,7.39,2.03,7.03c2.24-0.39,2.37-5.97,2.52-7.01
				c0.05-0.37,0.17-0.2,0.3,0.16c0.03,0.07,0.05,0.15,0.08,0.24c0.21,0.7,0.43,1.75,0.43,1.75s0.01-0.03,0.04-0.13
				c0.06-0.23,0.18-0.83,0.34-2.24c0.03-0.23,0.05-0.48,0.08-0.75c0.08-0.78,0.16-1.74,0.25-2.94c0.02-0.23,0.02-0.42,0.02-0.6
				C72.84,270.72,72.18,271.13,72.18,271.13z M70.31,280.39c-1.12,5.99-2.33,2.43-2.3-2.12c0-0.3,0.01-0.59,0.02-0.86
				c0-0.1,0.01-0.19,0.02-0.29c0.04-0.8,0.12-1.5,0.22-2.11c0.37-2.16,1.04-3.08,1.46-3.23C70.92,271.34,71.43,274.39,70.31,280.39z
				"
            />
            <path
              fill="#4C2B1A"
              d="M68.82,277.85l0.03,0c0,0,0.01-0.04,0.03-0.11c0.02-0.08,0.06-0.21,0.09-0.36
				c0.1-0.49,0.18-1.27-0.09-2.01c0,0,0.95-0.32,1.89-0.87c0.49-0.29-0.88,0.05-2.49,0.51c-0.1,0.6-0.18,1.3-0.22,2.11
				c0.02,0.11,0.04,0.22,0.07,0.33C68.36,277.57,68.59,277.7,68.82,277.85z"
            />
            <polygon
              fill="#D7BFB1"
              points="47.52,288.57 62.16,297.54 63.42,298.3 62.97,299.14 61.94,299.25 60.64,298.68 46.7,289.82 			
				"
            />
            <path
              fill="#41637C"
              d="M44.04,286.26c-0.23,0.48-0.52,0.92-0.87,1.31c0.78,0.49,2.02,1.28,3.53,2.25l0,0
				c2.47,1.57,5.64,3.59,8.63,5.49c0.1,0.06,0.19,0.12,0.29,0.19c0.37,0.24,0.74,0.47,1.11,0.71c0.37,0.24,0.73,0.47,1.09,0.69
				c0.06,0.04,0.11,0.07,0.17,0.11c0.41,0.26,0.81,0.52,1.2,0.76c0.37,0.24,0.74,0.47,1.08,0.69c0.13,0.08,0.25,0.15,0.37,0.22l0,0
				c2.48,1.38,3.28-0.26,1.22-1.3c-1.19-0.6-1.98-1.15-4.98-2.91c-2.99-1.75-6.57-4.06-9.36-5.89
				C46.09,287.63,44.86,286.82,44.04,286.26z"
            />
            <path
              fill="#33190D"
              d="M25.28,295.02c0.52,1.81,0.95,3.65,1.22,5.45c0.05,0.29,0.09,0.56,0.13,0.81l0,0c0,0,0.25,0.94,0.29,2.23
				c0.1-0.02,2.34-0.39,4.66-1.48c0-0.24,0-0.49-0.01-0.74c-0.35-6.36-8.62-15.74-11.62-20.02c0.11,0.26,0.23,0.51,0.35,0.76
				C22.75,286.91,24.3,291.41,25.28,295.02z"
            />
            <path
              fill="#3E6869"
              d="M24.16,309.5c-0.29,0.06-0.62,0.11-1.02,0.14c-0.09,0.01-0.19,0.02-0.29,0.02
				c-0.73,0.07-1.53,0.12-2.37,0.16c-0.18,0.01-0.36,0.02-0.52,0.02c0,0-0.01,0-0.01,0c-1.41,0.05-2.95,0.09-4.56,0.14c0,0,0,0,0,0
				c4.22,1.58,7.69,1.61,10.32,0.66c-0.49-0.41-0.99-0.8-1.48-1.14C24.21,309.49,24.18,309.49,24.16,309.5z"
            />
            <path
              fill="#E8E6D9"
              d="M4.32,384.4c-0.71,0.15-1.26,0.35-1.65,0.51c-0.38,0.16-0.53,0.62-0.33,0.97
				c0.27,0.47,0.62,1.09,0.82,1.39c0.33,0.53,1.78-0.34,2.57-0.81c0,0,0.01-0.01,0.02-0.01c0.52-0.32,0.4-1.27-0.2-1.77
				C5.25,384.43,4.83,384.29,4.32,384.4z"
            />
            <path
              fill="#E8E6D9"
              d="M8.39,392.94c-0.15,0.52,0.19,1.2,0.4,1.79c0.18,0.49,1.32,0.2,2.2-0.09c0.18-0.06,0.35-0.12,0.5-0.17
				c0.68-0.23,0.76-1.5-0.1-2.05c-0.23-0.15-0.5-0.26-0.85-0.26C8.97,392.17,8.54,392.42,8.39,392.94z"
            />
            <path
              fill="#E8E6D9"
              d="M11.61,396.37c-1.56,0.19-1.95,0.49-2.04,1.02c-0.09,0.53,0.33,1.17,0.62,1.73
				c0.17,0.33,0.76,0.21,1.39-0.02c0.45-0.17,0.92-0.39,1.26-0.56c0.48-0.23,0.51-1,0.15-1.56
				C12.73,396.59,12.26,396.29,11.61,396.37z"
            />
            <path
              fill="#E8E6D9"
              d="M8.58,400.31c-1.16,0.39-1.41,0.68-1.39,1.1c0.02,0.42,0.44,0.84,0.74,1.22
				c0.22,0.27,0.82-0.06,1.36-0.44c0.21-0.15,0.41-0.3,0.57-0.42c0.34-0.26,0.22-0.89-0.18-1.26
				C9.42,400.28,9.04,400.16,8.58,400.31z"
            />
            <path
              fill="#AC7D61"
              d="M-7.5,406.2c0.02-0.01,0.06-0.15,0.04-0.18c-0.54-0.83-1.54-1.51-2.31-2.48
				c-0.77-0.97-0.62-2.17-1.18-2.64c-0.36-0.3-0.79-0.76-1.09-1.37c-1.06-0.41-2.43-0.74-3.75-0.49c-1.88,0.34-2.19,0.19-2.19,0.19
				s0.02,0.15,0.1,0.41c0.19,0.63,0.71,1.93,2.09,3.58C-13.94,405.45-9.44,407.02-7.5,406.2z"
            />
            <path fill="#E7E5D8" d="M30.28,366.01c-0.26,0.06-0.53,0.11-0.77,0.18c-0.59,0.16-1.04,0.37-1.03,0.72" />
          </g>
          <g>
            <g>
              <polygon fill="none" points="164.09,381.84 164.09,381.84 164.09,381.84 				" />
              <path
                fill="none"
                d="M120.94,349.94c0,0,0.01,0.02,0.03,0.05c0-0.02-0.01-0.04-0.03-0.07
					C120.94,349.93,120.94,349.94,120.94,349.94z"
              />
              <path
                fill="#E6D6CF"
                d="M168.39,400.01c-0.48,0.29-1.08,0.62-1.57,0.92C167.37,400.63,167.9,400.36,168.39,400.01z"
              />
              <path
                fill="#E6D6CF"
                d="M165.32,374.3c-0.13,0.4-2.17,2.29-2.17,2.29c-2.27,2.51-2.7,4.77-3.58,8.99
					c-0.88,4.22-2.76,6.85-1.46,9.49c1.3,2.64,1.15,3.36-2.1,4.57c-1.44,0.54-2.42,2.25-3.35,3.02c1.02,0.99,2.09,2.12,3,3.26
					c0.29-0.17,1.14-0.23,1.52-0.44c3.09-1.73,7.56-4.13,9.91-5.31c2.35-1.18,2.01-1.92,3.93-3.71c1.92-1.79,2.46-2.87,2.92-3.15
					s0.32-2.19,0.48-2.75s0,0,0.15-0.56l0.84-1.73c0,0-0.02,0.06-0.06,0.16c0.05-0.12,0.1-0.23,0.15-0.34
					c0.28-0.61-1.11-2.22-2.78-0.17c-1.67,2.06-1.61,3.17-2.56,3.89c-0.94,0.72-2.39,2-2.72,2.28c-0.17,0.14-1.04,0.34-2.06,0.56
					c-0.23-0.11-1.13-0.53-1.94-0.58c-0.47-0.03-0.99,0.02-1.35-0.87c-0.13-0.3-0.18-0.68-0.1-1.13c0.22-1.22-0.28-3.5,0.44-5.45
					c0.05-0.12,0.09-0.25,0.13-0.38c0.04,0.02,1.47,0.18,1.51,0.21c-0.04-0.04-0.46-0.5-0.46-0.5l-0.31-0.81l0,0l0.77-1.1
					c0,0,0.66,0.04,1.34,0.22c0.68,0.19,1.06,0.99,1.71,1.3c0.65,0.31,1.26-0.27,1.26-0.27s-0.03,0.15-0.12,0.33
					c0.13-0.21,0.16-0.45,0.07-0.6c-0.25-0.38-0.79-0.95-1.28-1.28s-0.85-1.05-1.58-1.41c-0.46-0.23-0.83-0.41-1.29-0.49
					c-0.04,0-0.07,0.01-0.11,0.01c0,0,0.01-0.01,0.01-0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.15-0.26,1.02-1.52,1.31-2.07
					c0,0.68,0.6,0.72,1.1,0.98c1.04,0.54,2.82,0.85,2.96-0.16c0,0,0,0.03-0.01,0.06c0.41-0.95-0.62-1.35-1.23-1.47
					c-0.65-0.12-0.73-0.77-1.45-0.85c-0.2-0.02-0.4-0.05-0.64-0.09c0.21-0.32,0.44-0.62,0.69-0.91c0.94-1.06,2.75-4,1.28-4.78
					c-0.94-0.5-2.17,0.83-2.61,1.22C165.02,374.59,165.48,373.79,165.32,374.3z"
              />
              <path
                fill="#E6D6CF"
                d="M150.76,398.63c-0.2,0.45-0.52,1-0.82,1.5c0.02,0.01,0.03,0.03,0.05,0.04
					C150.26,399.73,150.54,399.19,150.76,398.63z"
              />
              <path
                fill="#E6D6CF"
                d="M159.31,379.98c0.91-0.45,1.25-0.68,1.67-2.05c-0.55,0.12-0.96,0.34-1.3,0.61
					C158.58,379.93,158.76,380.58,159.31,379.98z"
              />
              <path fill="#E6D6CF" d="M166.25,385.74c-0.87-0.48-1.69-1.2-1.69-1.2S165.35,385.27,166.25,385.74z" />
              <path
                fill="#E6D6CF"
                d="M165.25,386.54c0,0-0.01,0.14-0.09,0.27c0.16-0.2,0.22-0.44,0.16-0.55c-0.14-0.25-0.76-0.85-1.17-1.2
					c-0.13-0.11-0.27-0.22-0.41-0.33l-0.13,0.03C164.16,385.35,165.1,386.37,165.25,386.54z"
              />
              <path
                fill="#E6D6CF"
                d="M164.07,387.03c0.25,0.1,0.47,0.11,0.65,0.07C164.55,387.12,164.34,387.11,164.07,387.03z"
              />
              <path
                fill="#AC7D61"
                d="M159.78,378.4c0.01-0.02,0.03-0.03,0.04-0.04C159.82,378.36,159.81,378.37,159.78,378.4z"
              />
              <path
                fill="#AC7D61"
                d="M159.76,378.43c0.01-0.01,0.02-0.02,0.03-0.03C159.77,378.41,159.76,378.42,159.76,378.43z"
              />
              <path
                fill="#AC7D61"
                d="M167.33,384.96c-0.65-0.31-1.18-1.08-1.86-1.27s-2.1-0.15-2.1-0.15l-0.34,1.36l0,0l-0.4,1.36
					c0,0,0.02,0.02,0.05,0.06c0.12,0.13,0.49,0.45,1.39,0.71c0.28,0.08,0.48,0.09,0.65,0.07c0.25-0.04,0.38-0.16,0.45-0.29
					c0.08-0.14,0.09-0.27,0.09-0.27c-0.15-0.17-1.1-1.19-1.65-1.77l0.13-0.03l0.82-0.19c0,0,0.82,0.72,1.69,1.2
					c0.29,0.16,0.59,0.29,0.86,0.36c0.67,0.17,1-0.18,1.15-0.47c0.09-0.18,0.12-0.33,0.12-0.33S167.98,385.27,167.33,384.96z"
              />
              <path
                fill="#AC7D61"
                d="M164.51,394.8c-0.1,0.01-0.19,0.04-0.29,0.07C164.72,394.76,165.08,394.73,164.51,394.8z"
              />
              <path
                fill="#AC7D61"
                d="M138.09,406.92c4.04-3.27,8.2-6.44,10.61-7.74C146.43,400.34,142.49,403.32,138.09,406.92z"
              />
              <path
                fill="#E6D6CF"
                d="M238.33,414.94c0,0.03,0,0.06,0.01,0.08C238.33,414.99,238.33,414.96,238.33,414.94z"
              />
              <path
                fill="#E6D6CF"
                d="M238.27,414.7C238.27,414.7,238.27,414.7,238.27,414.7C238.27,414.7,238.27,414.7,238.27,414.7z"
              />
              <path
                fill="#E6D6CF"
                d="M238.3,414.8c0,0.02,0.01,0.03,0.01,0.05C238.31,414.83,238.31,414.82,238.3,414.8z"
              />
              <path
                fill="#E6D6CF"
                d="M233.61,420.93c-2.47,1.4-7.36,3.89-9.96,5.05l0,0.01C226.24,424.83,231.14,422.34,233.61,420.93
					c2.47-1.4,3.71-3.96,4.46-5.08c0.09-0.13,0.15-0.26,0.19-0.39c-0.04,0.12-0.11,0.25-0.19,0.38
					C237.32,416.96,236.07,419.52,233.61,420.93z"
              />
              <path
                fill="#E6D6CF"
                d="M238.34,415.08c0,0.05-0.01,0.11-0.02,0.16C238.33,415.19,238.33,415.14,238.34,415.08z"
              />
              <path
                fill="#E6D6CF"
                d="M238.31,415.27c-0.01,0.05-0.02,0.1-0.04,0.16C238.29,415.38,238.3,415.32,238.31,415.27z"
              />
              <path
                fill="#E6D6CF"
                d="M238.15,414.47C238.15,414.47,238.15,414.47,238.15,414.47c0.33-0.39,0.73-0.9,1.2-1.5
					c0.31-0.4,0.46-0.74,0.51-1.03c-0.05,0.29-0.2,0.63-0.51,1.02C238.89,413.56,238.48,414.07,238.15,414.47z"
              />
              <path
                fill="#E6D6CF"
                d="M222.14,426.39c-1.68,0.42-8.46,2.6-10.3,2.91c-1.84,0.31-2.62,0.22-4.71,1.02s-2.19,0.87-2.99,1.48
					c-0.3,0.22-1.08,0.87-1.96,1.6c0,0,0,0,0,0.01c0.88-0.72,1.66-1.37,1.96-1.6c0.81-0.61,0.91-0.69,2.99-1.48
					c2.08-0.79,2.86-0.71,4.71-1.02C213.69,429,220.46,426.82,222.14,426.39c0.64-0.15,1.13-0.32,1.54-0.51l0-0.01
					C223.28,426.06,222.79,426.23,222.14,426.39z"
              />
              <path
                fill="#E6D6CF"
                d="M200.09,422.03c0.15,0.07,0.27,0.21,0.36,0.42c0.18,0.41,0.34,0.81,0.49,1.22
					c2.01-0.22,5.32-1.13,7.81-6.44c3.17-6.75,16.46-3.72,19.45-5.2c2.58-1.27,5.05-2.74,9.24-4.68c-1.41,0.18-3.44,0.93-4.1,1.07
					c-1.01,0.22-3.88,1.34-3.88,1.34s-4.91,0.64-7.53,0.68c-2.19,0.03-5.17,0.52-5.81,0.45c-0.02,0.07-0.02,0.12-0.02,0.12
					s-0.6,0.04,0-1.33c0.6-1.37,1.74-2.57,2.97-3.34c1.23-0.77,1.65-0.91,3.38-1.74s1.47-2.45,1.61-2.92c0,0,0.01,0.01,0.01,0.01
					c-0.37-0.57-1.08-0.76-1.47-0.51c-0.83,0.52-2.51,1.22-3.89,1.78c-1.39,0.57-2.68,1.25-4.06,2.26
					c-1.38,1.01-3.54,1.34-5.09,2.38c-1.55,1.04-2.26,1.73-3.56,3.02c-1.3,1.29-2.92,1.94-3.85,4.44c-0.94,2.49-1.52,2.81-2.38,3.55
					c-0.26,0.22-0.76,0.62-1.39,1.11C198.96,420.18,199.57,420.9,200.09,422.03z"
              />
              <path
                fill="#E6D6CF"
                d="M239.29,410.68c0.41-0.59,0.59-1.07,0.63-1.46C239.88,409.61,239.69,410.08,239.29,410.68
					C239.28,410.67,239.28,410.68,239.29,410.68z"
              />
              <path
                fill="#AC7D61"
                d="M239.29,410.68C239.28,410.68,239.28,410.67,239.29,410.68c0.41-0.59,0.59-1.07,0.63-1.46
					c0.13-1.17-1.01-1.49-1.01-1.49c-0.45-0.58-1.12-0.71-1.85-0.62c-0.01,0-0.01,0.01-0.02,0.01c-4.2,1.93-6.54,2.63-9.12,3.91
					c-2.99,1.47-16.83-1.33-20,5.42c-2.49,5.3-4.97,7-6.98,7.23c0.39,1.08,0.07,2.51,0.28,3.55c0.23,1.13-0.02,2.45,0.04,3.49
					c0.06,1.18,1.02,1.69,0.94,2.69c0.88-0.72,1.66-1.37,1.96-1.6c0.81-0.61,0.91-0.69,2.99-1.48s2.86-0.71,4.71-1.02
					c1.84-0.31,8.62-2.49,10.3-2.91c0.64-0.16,1.13-0.33,1.54-0.52l0,0.01l-0.04,0.09c0,0,0,0,0,0c2.6-1.15,7.49-3.64,9.96-5.05
					c2.47-1.4,3.72-3.97,4.46-5.09c0.09-0.13,0.15-0.26,0.19-0.38c0-0.01,0.01-0.02,0.01-0.02c0.02-0.05,0.03-0.11,0.04-0.16
					c0-0.01,0-0.02,0.01-0.02c0.01-0.06,0.01-0.11,0.02-0.16c0-0.02,0-0.04,0-0.06c0-0.03,0-0.06-0.01-0.08
					c0-0.03-0.01-0.06-0.01-0.09c0-0.02-0.01-0.03-0.01-0.05c-0.01-0.03-0.02-0.07-0.03-0.1c0,0,0,0,0,0
					c-0.03-0.08-0.07-0.16-0.12-0.23c0,0,0,0,0,0c0.32-0.4,0.73-0.91,1.2-1.51c0.31-0.39,0.46-0.73,0.51-1.02
					C240.02,411.08,239.31,410.69,239.29,410.68z"
              />
              <path
                fill="#E6D6CF"
                d="M93.68,402.53c3.08-0.2,7.67-1.42,11.47-2.06c-1.04-3.46-3.65-12.2-1.73-14.98
					c-0.08,0.05,5.18-3.68,9.21-2.34c-0.29-0.01,2.81,0.3,4.25,0.46c2.64,0.29,4.47-1.95,4.8-4.69c0.41-3.34-0.67-4.07,0.52-5.33
					c0.42-0.45,0.91-0.9,1.06-1.43c-0.03,0.07-0.07,0.14-0.12,0.21c0,0-1.11-3.84-0.95-4.03c0.95-1.15,1.49-2.08,1.61-2.3
					c-0.08-0.13-0.18-0.27-0.31-0.42c-0.91-1.1-0.88-2.42-0.72-3.25c0.35-1.81,2.2-2.2,3.06-2.24c0.02,0,0.05-0.01,0.07-0.02
					c0.98-0.7,0.84-1.65-0.95-4.04c-1.72-2.29-3.45-5.2-3.86-5.89c-0.06-0.1-0.07-0.21-0.03-0.32l-0.03-1.78l1.13-1.24l0,0
					c-0.54-1.24-1.5-2.54-1.25-3.28c0.05-0.16,0.09-0.32,0.1-0.5c0,0.02-0.01,0.03-0.01,0.03c-0.2-0.2-0.42-0.4-0.67-0.52
					c0,0,0,0,0,0c-0.38,0.34-0.65,0.52-0.71,0.57c-0.01,0.01-0.02,0-0.02,0c-0.05-0.06-0.27-0.3-0.77-0.31c0,0,0,0,0,0
					c-0.2,0.14-0.42,0.32-0.66,0.57c0,0,0.09-0.22,0.25-0.52c-0.31,0.06-0.68,0.2-1.14,0.47c-1.37,0.79-3.52,1.09-5.16,1.22
					c-0.41,0.03-0.79,0.06-1.11,0.08c-0.11,0.02-0.2,0.04-0.37,0.05c-0.03,0-0.05,0.01-0.08,0.01c-0.03,0.01-0.07,0.02-0.1,0.03
					c0,0-0.01,0-0.01,0c-0.01,0-0.01,0.01-0.02,0.01c-0.22,0.09-0.47,0.27-0.55,0.45c-0.42,0.72-1.2,2.15-1.81,3.74
					c-0.28,0.76-0.51,1.55-0.65,2.32c-0.12,0.68-0.34,1.28-0.6,1.81c-0.03,0.05-0.05,0.11-0.08,0.16c-0.03,0.05-0.06,0.1-0.08,0.15
					c-1.07,1.93-2.9,2.92-2.9,2.92l0-6.67c-0.54,0.37-1.08,0.7-1.59,0.97c0,0,0.05-0.11,0.1-0.26c0,0,0,0-0.01,0
					c0,0,0.03-0.07,0.07-0.17c0.14-0.38,0.29-0.88,0.11-0.88c-0.13,0-0.48,0.3-0.93,0.72c-0.14,0.13-1.35,1.26-1.53,1.42
					c-0.15,0.13-0.29,0.26-0.43,0.38c0,0,0.07-0.12,0.14-0.29c-0.02,0.01,0.02-0.07,0.09-0.23c0.05-0.16,0.08-0.32,0.05-0.45
					c-0.12-0.06-0.15-0.15-0.28-0.17c-0.09-0.02-0.28,0.02-0.37,0.02c-0.17,0.05-0.39,0.14-0.65,0.28
					c-0.13,0.07-0.25,0.12-0.35,0.16c-0.02,0.01-0.03,0.03-0.05,0.04c-0.33,0.28-0.81-0.11-0.57-0.48c0.01-0.01,0.01-0.02,0.02-0.02
					c0.02-0.03,0.04-0.06,0.05-0.1c0.2-0.78,0.77-2.11,0.62-3.34c0,0-1.16,0.14-2.37,3.31c-1.21,3.18-3.87,15.59-6.45,21.69
					c-1.53,3.61-5.49,7.2-14.74,5.77c0,0,0,0,0,0c-0.22,8.97-4.56,13.92-6.48,15.68C69.6,395.12,75.77,402.84,93.68,402.53z"
              />
              <path
                fill="#E6D6CF"
                d="M125.18,360.49c0.05-0.02,0.09-0.04,0.13-0.06c-0.23,0.1-0.45,0.2-0.64,0.3
					C124.83,360.65,125,360.58,125.18,360.49z"
              />
              <path fill="#AC7D61" d="M103.9,368.98c0-0.09,0-0.13,0-0.13S103.9,368.89,103.9,368.98z" />
              <path fill="#AC7D61" d="M123.01,347.48l0.09,0.06C123.1,347.55,123.07,347.52,123.01,347.48z" />
              <path
                fill="#AC7D61"
                d="M143.33,474.92c0.03-0.04,0.06-0.09,0.09-0.14c-0.75-0.26-1.51-0.56-2.29-0.89
					C142.54,474.56,143.33,474.92,143.33,474.92z"
              />
              <path
                fill="#AC7D61"
                d="M132.8,469.77c-2.83-1.51-6.05-3.27-9.83-5.24C126.56,466.52,129.9,468.29,132.8,469.77z"
              />
              <path
                fill="#AC7D61"
                d="M43.17,542.69c0.28,0.27,0.57,0.52,0.87,0.78c-0.28-0.35-0.55-0.7-0.79-1.07
					C43.2,542.59,43.17,542.69,43.17,542.69z"
              />
              <path
                fill="#AC7D61"
                d="M50.66,476.34c0.03,0.19,0.07,0.38,0.1,0.57c-1.24-9.21-3-16.14-4.56-23.3
					C47.6,460.91,49.22,468.58,50.66,476.34z"
              />
              <path
                fill="#AC7D61"
                d="M51.37,482.11c1.29,17.95-4.18,43.83-6.83,55.05C47.31,526.28,53.18,500.37,51.37,482.11z"
              />
              <path
                fill="#AC7D61"
                d="M43.14,434.46c-0.27-3.13-0.46-6.52-0.53-10.27C42.55,427.39,42.75,430.83,43.14,434.46z"
              />
              <path
                fill="#AC7D61"
                d="M79.61,553.58c9.55,0.38,19.26-0.22,27.49-1.07C99.65,553.21,89.6,553.86,79.61,553.58z"
              />
              <path
                fill="#AC7D61"
                d="M112.42,483.34c1.5,15.2,5.02,24.68,4.53,35.26c-0.74,15.91-6.25,18.85-0.49,32.87
					c5.89-0.89,12.14-2.14,12.14-2.14s-11-38.18-8.96-86.57c-0.51-0.3-0.99-0.6-1.44-0.91C118.2,461.86,110.3,461.83,112.42,483.34z
					"
              />
              <path
                fill="#AC7D61"
                d="M144.05,475c-0.09-0.03-0.17-0.06-0.26-0.09C143.88,474.94,143.96,474.97,144.05,475z"
              />
              <path
                fill="#4D2C1B"
                d="M200.93,423.67c-0.15-0.4-0.31-0.81-0.49-1.22c-0.09-0.21-0.21-0.35-0.36-0.42
					c-1.98-1.01-10.63,8.1-21.5,20.98c-11.67,13.82-15.31,4.01-17.15,2.17c-1.84-1.84-5.04-2.65-4.73-1.73
					c0.31,0.92-3.95-1.71-3.95,2.28s-3.51-2.12-3.51-2.12s0.48,9.97-7.68,4.06c-6.36-4.6-12.19-16.35-12.19-16.35
					c-4.7-10.13-18.75-32.23-31.94-31.54c-1.2,0.06-2.36,0.1-3.47,0.12c-17.91,0.31-24.35-4.8-25.8-6.24
					c-0.24-0.23-0.34-0.37-0.34-0.37c-13.86,3.59-25.4,14.41-25.21,29.73c0,0.39,0.01,0.77,0.02,1.16
					c0.07,3.75,0.26,7.14,0.53,10.27c0.65,7.42,1.79,13.36,3.06,19.14c1.57,7.17,3.32,14.09,4.56,23.3c0.21,1.57,0.41,3.2,0.59,4.91
					c0.01,0.1,0.02,0.19,0.03,0.29c0.77,20.98-4.39,44.34-6.83,55.05c-0.74,3.24-1.37,5.13-1.37,5.13c0.03,0.04,0.05,0.08,0.08,0.12
					c0.24,0.37,0.51,0.73,0.79,1.07c5.98,7.28,20.72,9.71,35.57,10.11c10,0.27,20.04-0.37,27.49-1.07c7.19-0.68,11.97-1.4,11.97-1.4
					c-0.02-0.04-0.03-0.08-0.05-0.12c-5.76-14.02-0.66-16.58,0.08-32.49c0.49-10.58-2.8-20.21-4.3-35.41
					c-2.12-21.5,3.39-21.23,3.39-21.23c0.45,0.31,0.93,0.61,1.44,0.91c0.36,0.21,0.74,0.42,1.13,0.62c0.76,0.39,1.49,0.77,2.2,1.14
					c3.78,1.98,6.99,3.73,9.83,5.24c3.17,1.69,5.87,3.08,8.34,4.12c0.78,0.33,1.54,0.63,2.29,0.89c0.12,0.04,0.24,0.08,0.37,0.13
					c0.09,0.03,0.17,0.06,0.26,0.09c5.11,1.67,9.65,1.63,16.32-0.48c10.23-3.24,17.86-14.06,26.98-22.15
					c9.95-8.83,13.76-13.53,13.76-13.53s0.81-2.18,1.07-5.43c0,0,0,0,0-0.01C202.4,430.69,202.24,427.24,200.93,423.67z"
              />
              <path
                fill="#4D2C1B"
                d="M140.89,434.27c0.51-2.65,3.18-6.87,4.12-9.61c1.52-4.46,4.02-8.88,5.75-12.51
					c1.64-3.46,2.33-6.47,2.61-8.16c0.15-0.92-0.61-1.03-0.61-1.03c-0.3-0.35,0.26-1.1,0.04-1.41c-0.55-0.8-0.91-1.46-1.44-1.91
					c-0.02-0.01-1.4,0.51-1.42,0.49c-0.76-0.64-1.24-0.95-1.24-0.95c-2.41,1.3-6.57,4.47-10.61,7.74
					c-5.74,4.64-11.25,9.49-11.25,9.49c0.02,0.02-0.02,0.91,0,0.93c0.6,0.61,0.91,1.39,1.73,2.3c2.33,2.6,4.85,7.51,7.2,10.36
					C138.03,432.74,140.89,434.27,140.89,434.27z"
              />
              <path
                fill="#A0948F"
                d="M93.95,399.92c1.12-0.07,2.29-0.17,3.5-0.3c-0.73-7.79,2.48-11.97,5.96-14.11
					c1.51-0.94,3.07-1.49,4.38-1.79c2.08-0.47,3.39-0.59,4.56-0.56c0.12,0,0.23,0.01,0.35,0.01c1.24,0.07,2.38,0.3,4.19,0.42
					c1.2,0.08,2.08-0.31,2.62-0.66c0.39-0.25,0.6-0.49,0.6-0.49c-0.02,0.01-0.03,0.01-0.05,0.02c-5.84,2.78-3.48-8.84-3.76-15.26
					c-0.26-5.83-0.98-7.67-2.44-11.48c-1.45-3.79-3.59-8.5-2.37-11.14c-0.18,0.01-0.34,0.02-0.5,0.03c-0.11,0.02-0.2,0.04-0.37,0.05
					c-0.03,0-0.05,0.01-0.08,0.01c-0.03,0.01-0.07,0.02-0.1,0.03c0,0-0.01,0-0.01,0c-0.01,0-0.01,0.01-0.02,0.01
					c-0.22,0.09-0.47,0.27-0.55,0.45c-0.42,0.72-1.2,2.15-1.81,3.74c-0.28,0.76-0.51,1.55-0.65,2.32c-0.12,0.68-0.34,1.28-0.6,1.81
					c-0.03,0.05-0.05,0.11-0.08,0.16c-0.03,0.05-0.06,0.1-0.08,0.15c-1.07,1.93-2.9,2.92-2.9,2.92l0-6.67
					c-0.54,0.37-1.08,0.7-1.59,0.97c0,0,0.05-0.11,0.1-0.26c0,0,0,0-0.01,0c0,0,0.03-0.07,0.07-0.17c0.14-0.38,0.29-0.88,0.11-0.88
					c-0.28,0-1.68,1.49-2.9,2.52c0,0,0.07-0.12,0.14-0.29c-0.02,0.01-0.03,0.03-0.05,0.04c0,0,0.06-0.11,0.13-0.27
					c0.15-0.49,0.12-1.04-1.26-0.32c-2.15,1.12,0-1.49-0.28-3.74c0,0-1.16,0.14-2.37,3.31c-1.21,3.18-3.87,15.59-6.45,21.69
					c-1.53,3.63-5.52,7.23-14.86,5.75c0.03,0.71,0.07,1.41-0.01,2.07c-0.54,4.59-2.41,8.96-4.3,11.36c-0.78,1-1.52,1.76-2.07,2.26
					C69.57,395.1,75.99,400.24,93.95,399.92z"
              />
              <path
                fill="#6D5B51"
                d="M239.02,408.04c-0.16,0.62-0.97,1.32-0.97,1.32s-0.78-0.24-0.46-0.87c0.35-0.68,0.66-0.91,0.66-0.91
					s-1.15,0.87-0.9,1.47c0.25,0.6,0.46,0.51,0.46,0.51s-0.53,0.77-1.31,1.28c-0.79,0.52-0.98,0.3-0.98,0.3s-0.22,0.58-1.8,1.64
					c-0.88,0.6-1.37,0.95-1.87,1.23c-0.28,0.09-0.45-0.18-0.45-0.18s0.13,0.34-0.27,0.52c0,0-0.1,0.06-0.69,0.26
					c-1.38,0.48-4.77,1.57-6.34,2.03c-0.87,0.25-1.23,0.12-1.35,0.02c0.01,0.02,0.02,0.05,0.03,0.08c0.02,0.05,0.05,0.12,0.09,0.19
					c0.22,0.22,0.47,0.5,0.73,0.84c-0.1-0.36-0.13-0.7,0.08-0.74c0.45-0.08,4.07-1.23,5.78-1.78c1.71-0.54,2.29-0.64,2.86-1.18
					s3.08-2.04,3.87-2.65c0.8-0.61,1.57-1.29,2.18-1.92S239.13,407.62,239.02,408.04z"
              />
              <path
                fill="#6D5B51"
                d="M239.88,408.86c0,0-0.02,0.9-0.65,1.69c-0.33,0.41-0.6,0.62-0.77,0.73c-0.12-0.11-0.4-0.41-0.17-0.87
					c0.29-0.58,1.04-1.66,1.04-1.66s-0.81,1.13-1.21,1.66c-0.38,0.5,0,0.68,0.02,1.15c-0.12,0.28-0.41,0.92-1.03,2.09
					c-0.06,0.11-0.13,0.22-0.2,0.32c-0.28,0.19-0.46-0.23-0.49-0.02c-0.02,0.18-0.03,0.47-0.06,0.75c-0.63,0.71-1.37,1.23-2.09,1.87
					c-0.14,0.12-0.28,0.24-0.42,0.35c-0.17,0.08-0.27,0.02-0.33-0.06c0.03,0.11,0.03,0.23-0.01,0.32c-0.84,0.61-1.74,1.08-2.45,1.37
					c-0.93,0.38-2.66,1.08-3.97,1.73c-0.97,0.48-1.62,0.27-1.89,0.13c0.12,0.25,0.24,0.51,0.36,0.78l0.25,0.43c0,0,0-0.34,2.32-1.46
					c2.32-1.12,3.15-1.45,4.6-2.18c1.45-0.73,3.36-2.62,3.89-3.42c0.53-0.8,1.21-2.1,1.47-2.58c0.26-0.49,1.19-1.28,1.19-1.28
					s0,0,0-0.01c0-0.01,0.02-0.03,0.04-0.07C239.48,410.35,239.99,409.46,239.88,408.86z"
              />
              <path
                fill="#341A0D"
                d="M71.96,551.58c18.69-0.89,24.94-5.57,2.53-9.02c-31.91-4.92-22.57-7.95-12.33-12.64
					c10.41-4.76-7.97-2.83-1.48-21.36c3.97-11.35-1.55-23.86-6.23-36.2c-3.05-8.03-5.9-15.94-8.11-22.68
					c-2.24-12.84-3.95-28.59,1.67-38.41C55,399.05,61.48,397.3,67.8,393.31c0,0-1.35,0.23-3.54,1.1
					c-4.06,1.61-11.01,5.43-17.74,14.14c-0.21,0.28-0.41,0.57-0.61,0.88c-0.82,1.29-1.47,2.82-1.96,4.54
					c-0.96,3.38-1.33,7.49-1.31,11.9c0.01,2.15,0.12,4.38,0.3,6.63c0.11,1.36,0.24,2.72,0.39,4.09c0.23,2.06,0.27,4.49,1.31,9.33
					c0.08,0.36,0.16,0.74,0.24,1.12c0.32,1.76,0.64,3.45,0.98,5.07c0.14,0.69,0.28,1.36,0.42,2.02c0.18,0.83,0.35,1.63,0.53,2.39
					c0.34,1.77,0.66,3.6,1.03,5.46c2.81,14.37,3.31,21.17,3.48,28.5c0.39,16.78-8.16,52.23-8.16,52.23s1.1,2.16,3.73,3.45
					C51.91,548.61,61.07,552.1,71.96,551.58z"
              />
              <path
                fill="#7B5743"
                d="M118.2,461.86L118.2,461.86L118.2,461.86c0,0-5.51-0.27-3.39,21.23c1.5,15.2,4.79,24.83,4.3,35.41
					c-0.66,14.11-4.73,17.72-1.64,28.13c0.09,0.21,0.13,0.33,0.13,0.33s4.54-16.15,0.11-55.37
					C115.28,470.24,118.66,464.33,118.2,461.86z"
              />
              <path
                fill="#6D5B51"
                d="M238.85,413.46c-0.1-0.17-0.36-0.63-0.21-0.89c0.18-0.33,0.97-1.1,0.97-1.1
					c-0.35,0.32-0.82,0.66-1.05,0.99c-0.08,0.11-0.12,0.33-0.1,0.43c0.08,0.32,0.23,0.58,0.29,0.68c-0.63,0.67-0.97,1.22-1.88,2.23
					c-0.21,0.23-0.45,0.5-0.72,0.79c-1.23,1.34-2.91,3.13-3.34,3.59c-0.52,0.57-6.26,3.67-6.26,3.67l0,0c0,0,5.46-2.64,6.57-3.41
					c0.56-0.39,3.53-3.95,4.19-4.96l0.52-0.64c-0.28,0.4-0.43,0.74-0.28,0.97c0.14,0.2,0.26,0.32,0.31,0.36c0,0,0,0,0,0
					c0.01,0.01,0.02,0.02,0.02,0.02l0.06-0.14c0,0,0,0,0,0c0,0,0,0,0,0c-0.03-0.02-0.3-0.19-0.25-0.54c0.04-0.31,0.23-0.72,0.3-0.85
					l1.21-1.49l0.16-0.19l0,0c0,0,0,0,0,0c-0.01,0.01-0.01,0.01-0.02,0.02C239.15,413.15,238.99,413.31,238.85,413.46z"
              />
            </g>
            <path
              fill="#AC7D61"
              d="M120.42,348.55c-0.18,0.21-0.33,0.38-0.45,0.51c-0.36,0.34-0.52,0.48-0.9,0.99
				c-0.28,0.37,0.38-0.35,0.81-0.56c0.09,0.02,0.21,0.04,0.38,0.08c0.46,0.1,0.63,0.25,0.69,0.35c0.02,0.03,0.03,0.05,0.03,0.07
				c0.01,0.02,0.01,0.03,0.01,0.03s0.01-0.01,0.01-0.01c0.04-0.06,0.43-0.69,0.34-1.91l0.39-0.61c0.65-0.33,1.11-0.12,1.3-0.01
				l-0.88-0.64l0,0C122.13,346.84,121.29,347.54,120.42,348.55z"
            />
            <path
              fill="#AC7D61"
              d="M118.87,350.42c-0.08,0.47-1.17,1.33-1.17,1.33C118.68,351.4,118.8,351.18,118.87,350.42z"
            />
            <path
              fill="#AC7D61"
              d="M118.29,350.42c0,0-0.59,0.39-1.06,0.35C117.23,350.78,117.86,351.36,118.29,350.42z"
            />
            <path
              fill="#AC7D61"
              d="M123.83,360.3c-1.24,0.22-1.15-0.35-1.46-1.37s-0.84-3.4-0.97-3.4c-0.13,0,0.92,3.25,1.4,6.38
				c0.01,0.07,0.02,0.14,0.03,0.21c0,0,0-0.54,1.83-1.44c0.19-0.09,0.41-0.19,0.64-0.3c0.1-0.04,0.19-0.08,0.29-0.13
				c0.17-0.07,0.22-0.12,0.2-0.14C125.7,360.03,124.77,360.14,123.83,360.3z"
            />
            <path
              fill="#AC7D61"
              d="M121.77,368.84c-0.22-0.19,0.76-3.1,0.5-2.47c-0.55,1.34-1.65,3.76-1.84,4.47
				c-0.19,0.72,0.28-1.12,0.94-1.25c1.37,0.45,1.76,2.77,1.76,2.77c0.05-0.07,0.08-0.14,0.12-0.21c0.56-1.11-0.56-2.61-1-3.13
				c0.23-0.16,0.49-0.35,0.76-0.59c0.93-0.84,1.37-1.6,0.81-2.45c0,0-0.01,0.02-0.03,0.05c-0.12,0.22-0.66,1.15-1.61,2.3
				C122.02,368.53,121.88,368.7,121.77,368.84z"
            />
            <path
              fill="#715845"
              d="M112.62,383.14c-4.03-1.34-6.04-3.22-7.38-7.51c-1.16-3.73-1.32-6.09-1.34-6.65
				c0.03,1.3,0.26,11.93-0.62,16.59c0,0,0.05-0.03,0.13-0.09c0.64-0.42,3.69-2.23,8.34-2.34
				C112.04,383.14,112.33,383.14,112.62,383.14z"
            />
            <path
              fill="#715845"
              d="M102.68,359.96c-0.74-1.4-0.25-1.74,0.1-2.5s-0.03-0.95-0.03-0.95c-0.73,1.16-1.75,0.81-2.22,1.36
				c-0.47,0.55,0.85,2.64,1.54,3.46C102.76,362.15,103.42,361.37,102.68,359.96z"
            />
            <path
              fill="#715845"
              d="M101.76,351.01c-0.14-0.23-0.33-0.51-0.57-0.76c-0.09,0.08-1.47-0.98-1.47-0.98s-0.99,0.49-1.02,0.5
				c-0.03,0.01-0.74,0.85-0.74,0.85s-0.46,0.22-0.41,0c-0.55,0.8-0.78,1.89-1,2.75c0,0,0.65-1.47,1.57-2.24
				c-0.11,0.04,1-0.64,1-0.64s0.11,0.16,0,0.19c0.09,0,0.18,0,0.28,0.02c0.13,0.03,0.26,0.07,0.38,0.13c-0.01-0.05,0.49,0,0.49,0
				l0.19,0.1c-0.08,0.08-0.16,0.15-0.24,0.23c0.58,0.58,0.79,1.57,0.05,2.48c-1.04,1.26-1.41,1.81-1.64,2.78
				c-0.23,0.97,0.47,1.87,0.47,1.87s0.14-0.75,0.38-1.65c0.24-0.9,1.33,0.33,2.38-0.79C102.91,354.73,102.19,351.71,101.76,351.01z"
            />
            <path fill="#715845" d="M101.02,363.77c0,0-3.72-4.01-4.02-7.51C97.01,356.26,97.43,362.46,101.02,363.77z" />
            <path
              fill="#141616"
              d="M89.37,372.23c2.58-6.1,5.23-18.52,6.45-21.69c1.21-3.18,2.37-3.31,2.37-3.31
				c0.16,1.25-0.44,2.62-0.63,3.39c-0.13,0.52-0.06,0.77,0.57,0.51c0.1-0.04,0.21-0.1,0.35-0.16c0.26-0.14,0.48-0.23,0.65-0.28
				c0.44-0.14,0.61-0.04,0.66,0.15c0.03,0.13,0,0.29-0.05,0.45c-0.03,0.08-0.06,0.16-0.09,0.23c-0.07,0.17-0.14,0.29-0.14,0.29
				c0.24-0.2,0.48-0.42,0.73-0.64c0.34-0.31,0.67-0.62,0.97-0.9c0.59-0.55,1.05-0.97,1.2-0.97c0.17,0,0.02,0.5-0.11,0.88
				c-0.02,0.06-0.04,0.12-0.06,0.17c-0.06,0.15-0.1,0.26-0.1,0.26c0.51-0.26,1.05-0.6,1.59-0.97l0,6.67c0,0,1.83-0.98,2.9-2.92
				c0.03-0.05,0.06-0.1,0.08-0.15c0.03-0.05,0.06-0.11,0.08-0.16c0.23-0.47,0.41-0.99,0.51-1.56c0.15-0.85,0.42-1.73,0.74-2.57
				c0.61-1.59,1.39-3.02,1.81-3.74c0.07-0.19,0.32-0.36,0.55-0.45c0.01,0,0.01-0.01,0.02-0.01c0,0,0.01,0,0.01,0
				c0.04-0.01,0.07-0.02,0.1-0.03c0.03-0.01,0.06-0.01,0.08-0.01c0.17-0.01,0.27-0.03,0.37-0.05c0.12-0.03,0.26-0.06,0.52-0.08
				c0.04-0.07,0.07-0.14,0.11-0.21c2.08-12.07,0.36-16.63,0.36-16.63c1.88,3.93,1.45,10.18,1.07,13.36
				c3.75-10.73-1.55-20.52-1.55-20.52c5.85,8.4,2.56,21.29,2.56,21.29c6.12-16.99-6.21-26.49-6.21-26.49
				c7.67,5.02,9.5,15.07,9.5,15.07c-0.74-13.04-12.07-18.27-14.39-19.21c-11.41-1.9-23.15,1.15-23.15,1.15s-1.88,0.15-3.8,1.23
				l0.23-0.33l0.14-0.2c0,0-7.84,2.73-13.97,9.2c0,0,3.14-2.55,6.97-4.47c0.4-0.2,0.81-0.4,1.23-0.58
				c-4.01,2.48-9.07,5.85-12.46,13.1c-1.49,3.18-2.13,5.98-2.26,8.4c0,0.01,0,0.01,0,0.02c0,0.05,0,0.1,0,0.14
				c-0.08,1.79,0.12,3.36,0.47,4.7c0.93,4.29,3.39,9.85,8.2,16.09c0,0-2.19-3.85-3.57-8.35c0-0.01-0.01-0.03-0.01-0.04
				c0.13,0.18,0.26,0.35,0.38,0.52c0.97,1.32,1.74,2.45,1.74,2.45s1.21,1.58,4.1,5.78c2.9,4.2,4.83,7.75,4.83,7.75
				c2.77,5.8,2.41,10.19,2.46,10.2c0.05,0.01,0.1,0.01,0.15,0.02C83.88,379.43,87.84,375.84,89.37,372.23z"
            />
            <path
              fill="#141616"
              d="M111.48,344.55c0,0,4.62-0.07,6.9-1.69c2.28-1.62,3.01-4.05,3.01-4.05l1.04-13c0,0-3.77-5.79-4.14-6.9
				c-0.37-1.11-2.97-3.04-2.97-3.04l-7.36-2.97L111.48,344.55z"
            />
            <path
              fill="#4D2C1B"
              d="M79.79,312.36c0,0,11.74-3.04,23.15-1.15c2.31,0.94,13.65,6.17,14.39,19.21c0,0-1.83-10.05-9.5-15.07
				c0,0,12.33,9.5,6.21,26.49c0,0,3.29-12.88-2.56-21.29c0,0,5.3,9.79,1.55,20.52c0.38-3.18,0.81-9.43-1.07-13.36
				c0,0,1.72,4.57-0.36,16.63c-0.04,0.07-0.07,0.14-0.11,0.21c-0.26,0.02-0.39,0.05-0.52,0.08c0.33-0.02,0.7-0.04,1.11-0.08
				c1.64-0.13,3.83-0.49,5.28-1.12c0.68-0.29,0.69-0.36,1.02-0.57c-0.16,0.3-0.25,0.52-0.25,0.52c0.24-0.24,0.46-0.42,0.66-0.57
				c0.57,0.01,0.78,0.32,0.78,0.32s0.29-0.18,0.73-0.58c0.25,0.12,0.46,0.32,0.67,0.52c0,0,0-0.01,0.01-0.03
				c0.03-0.14,0.16-0.72,0.03-1.2c1.66-1.83,4-5.73,2.68-12.38c-0.6-3.02-1.6-5.59-2.91-7.76c3.31,4.07,3.72,7.89,3.77,8.78
				c-0.38-12.52-12.69-17.09-15.34-17.93c-2-0.73-4.09-1.25-6.19-1.6c-11.41-1.9-23.15,1.15-23.15,1.15s-1.79,0.15-3.66,1.16
				l-0.23,0.33C77.91,312.51,79.79,312.36,79.79,312.36z"
            />
            <path
              fill="#AC7D61"
              d="M166.61,379.86c-0.56,0.03-0.87-0.25-0.87-0.93c-0.29,0.55-1.51,2.66-1.66,2.91c0,0,0,0,0,0
				c-0.01,0.02-0.01,0.03-0.01,0.03c0.04,0,0.07-0.01,0.11-0.01c1.32-0.14,2.06-0.56,2.06-0.56c2.69,1.01,3.14-0.32,3.21-0.63
				c0.01-0.04,0.01-0.06,0.01-0.06C168.81,381,167.17,379.83,166.61,379.86z"
            />
            <path
              fill="#AC7D61"
              d="M162.44,395.76c0.03,0.44,0.93-0.58,1.78-0.88c-0.3,0.07-0.65,0.17-0.88,0.3
				c-0.62,0.34,0.15-0.49,0.56-0.71c0.4-0.22-0.62,0.4-1.05-0.12c-0.23-0.28-0.43-0.59-0.57-0.82c-0.13-0.21-0.2-0.36-0.2-0.36
				c0.01,0.01,0.01,0.03,0.01,0.04C162.44,394.09,162.41,395.27,162.44,395.76z"
            />
            <path
              fill="#AC7D61"
              d="M97.42,399.8c13.2-0.7,25.46,22.05,30.15,32.18c0,0,7.17,12.66,13.54,17.26c8.17,5.9,8.29-4.49,8.29-4.49
				s2.46,2.15,2.76,6.14c0.31,3.99,0,6.45,0,6.45s1.54-6.76,1.54-10.75s1.54-1.23,1.84,2.46c0.31,3.69,1.54-3.69,1.23-4.61
				c-0.31-0.92,1.84,0,3.69,1.84c1.84,1.84,8.24,12.55,19.91-1.27c10.87-12.88,17.73-23.99,19.71-22.98
				c-0.52-1.14-1.12-1.85-1.71-2.31c-0.09-0.07-0.19-0.14-0.28-0.2c-2.4-1.58-5.99,0.76-7.38,1.64
				c-25.53,16.11-36.02,20.89-37.82,21.67c-1.35-0.19-4.51-1.5-10.35-8.26c8.45-12.3,15.1-25.36,15.1-25.36
				c-0.13-0.35-0.3-0.71-0.5-1.08c0.28-0.42,0.55-0.8,0.72-1.01c0.37-0.46,1.35-1.31,2.15-1.95c0.28-0.22,0.92-0.67,1.12-0.82
				c0.77-0.56,2.79-1.54,3.41-1.94c0.21-0.13,0.42-0.27,0.63-0.4c0.42-0.27,0.85-0.54,1.32-0.87c0.1-0.07,0.21-0.14,0.33-0.22
				c0.49-0.3,1.1-0.63,1.57-0.92c0.23-0.14,0.43-0.27,0.57-0.38c0.45-0.37,1-1.11,1.61-1.97c0.06-0.09,0.12-0.17,0.18-0.26
				c0.68-0.96,2.35-2.63,2.91-3.31c0.01-0.01,0.01-0.02,0.02-0.02c0.54-0.67,0.6-1.08,0.79-2.51c0.15-1.19,0.68-2.64,0.86-3.12
				c0.04-0.1,0.06-0.16,0.06-0.16l-0.84,1.73c-0.31-0.22-0.53-0.43-0.37-0.96s0.46-1.14,0.9-1.48c0,0-0.93,0.74-1.11,1.55
				c-0.19,0.8,0.59,0.9,0.43,1.45s-0.31,2.38-0.77,2.66s-1.02,1.3-2.94,3.09c-1.92,1.79-1.67,2.51-4.02,3.68
				c-2.35,1.18-6.93,3.62-10.02,5.35c-0.38,0.21-0.71,0.4-1,0.57c-0.9-1.14-1.97-2.27-3-3.26c0.93-0.77,2.16-1.62,3.6-2.15
				c3.25-1.21,4.35-2.75,3.05-5.4c-1.3-2.64,0.19-5.15,1.07-9.37c0.88-4.22,0.88-6.54,3.16-9.05c0,0,1.48-2.09,1.78-2.39
				c0.3-0.3,1.41,0.48,2.77-1.32c0.09-0.12-1.69,1.62-2.6,0.82c0,0-2.78,2.43-4.03,3.68c-0.17,0.17-0.32,0.32-0.47,0.46
				c-0.89,0.85-1.23,1.09-1.67,2.05c0,0,0.1-0.96,0.37-1.44c0.02-0.04,0.05-0.08,0.07-0.11c-0.04,0.05-0.11,0.12-0.19,0.22
				c-0.38,0.45-1.14,1.39-1.92,2.73c-0.32,0.55-0.65,1.04-0.99,1.49c-0.83,1.1-1.73,1.95-2.81,2.77c-1.53,1.16-1.86,1.3-1.86,3.99
				s-0.64,7.46-1.22,9c-0.21,0.56-0.5,1.09-0.77,1.54c0.73,0.62,1.71,1.54,2.76,2.79c0,0-9.51,16.42-12.08,29.67
				c0,0-9.77-12.13-13.77-16.15c-0.04,0.04-0.09,0.08-0.13,0.12c-12.8-12.94-21.92-18.02-21.92-18.02c-0.2,0.04-0.4,0.07-0.6,0.1
				c-3.8,0.65-7.23,1.04-10.31,1.25C95.06,399.9,96.22,399.86,97.42,399.8z"
            />
            <path
              fill="#AC7D61"
              d="M222.25,404.12c-1.73,0.83-2.65-0.71-2.65-0.71s0.34,1.72-0.89,2.48c-1.23,0.77-2.45,2.25-3.05,3.62
				c-0.6,1.37-0.93,1.41-2.66,1.43c-0.38,0-0.66,0-0.88-0.01c0.14,0.02,0.31,0.04,0.52,0.08c1.33,0.23-0.58,0.46-1.88,1.02
				c0.67-0.23,5.32-1.01,5.32-1.01s0.01-0.05,0.02-0.12c0.02-0.07,0.05-0.18,0.09-0.3c0.08-0.21,0.21-0.48,0.43-0.77
				c0.09-0.12,0.19-0.24,0.32-0.36c0.18-0.17,0.42-0.35,0.7-0.53c0.92-0.59,2.26-1.23,3.35-2.02c0.34-0.25,0.64-0.46,0.9-0.66
				c0.82-0.63,1.29-1.1,1.81-1.91c0.69-1.06,1.07-1.92,0.35-2.65c0,0-0.01-0.01-0.01-0.01
				C223.91,402.16,223.98,403.29,222.25,404.12z"
            />
          </g>
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="64.32" y="122.01" fill="none" width="280.03" height="182.74" />
        <text transform="matrix(1 0 0 1 64.3243 135.7889)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="19px">
            We aim to reduce{" "}
          </tspan>
          <tspan x="0" y="29" fontFamily={font.condensed} fontSize="24px">
            CO2 EMISSIONS
          </tspan>
          <tspan x="166.1" y="29" fontFamily={font.serif} fontSize="19px">
            {" "}
          </tspan>
          <tspan x="0" y="58" fontFamily={font.serif} fontSize="19px">
            per vehicle produced.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 29.0416 72)" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
          promised
        </text>
      </motion.g>
    </svg>
  );
};
