import React from "react";
import styled, { StyledComponentPropsWithRef } from "styled-components/macro";
import { useResize } from "#hooks";

const Container = styled.div`
  position: fixed;
`;

export const FixedContainer: React.FC<StyledComponentPropsWithRef<"div">> = React.forwardRef((props, ref) => {
  const { scrollBarWidth } = useResize();
  return <Container {...props} style={{ right: `${scrollBarWidth}px` }} ref={ref} />;
});
