import { bp, Color, fontCondensed } from "#shared/theme";
import htmr from "htmr";
import React, { FC } from "react";
import styled, { css } from "styled-components/macro";
import { ImageAuthorProps } from "../Image/ImageAuthor";
import { Wrapper } from "../Wrapper/Wrapper";

const Container = styled.div<{ maxWidth: number }>`
  display: flex;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

const ImageWrapper = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}%;
  padding-top: ${({ height }) => height}%;
  position: relative;
  & + & {
    margin-left: 2px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ImageCaption = styled.div<{ isCaptionFullWidth: boolean }>`
  width: 100%;
  margin-top: 20px;
  font-size: 13px;
  line-height: 1.46;
  letter-spacing: 0.05em;
  ${fontCondensed}
  a {
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid ${Color.Green};
  }
  span {
    text-transform: none;
  }
  @media ${bp.m} {
    ${({ isCaptionFullWidth }) =>
      !isCaptionFullWidth &&
      css`
        width: 50%;
      `};
  }
  @media ${bp.d} {
    margin-top: 30px;
  }
`;

interface ImageGallery {
  maxWidth: number;
  isCaptionFullWidth: boolean;
  items: GalleryItem[];
  caption: ImageAuthorProps;
}

type GalleryItem = {
  width: number;
  height: number;
  src: string;
};

export const ImageGallery: FC<ImageGallery> = ({ maxWidth, items, caption, isCaptionFullWidth }) => (
  <Wrapper>
    <Container maxWidth={maxWidth}>
      {items.map((item: GalleryItem, key: number) => (
        <ImageWrapper key={key} width={item.width} height={item.height}>
          <Image src={item.src} />
        </ImageWrapper>
      ))}
    </Container>

    <Container maxWidth={maxWidth}>
      <ImageCaption isCaptionFullWidth={isCaptionFullWidth}>{htmr(caption.desc)}</ImageCaption>
    </Container>
  </Wrapper>
);
