import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { ChartProps } from "#interfaces";
import { Earnings } from "./Earnings";
import { EBITMargin } from "./EBITMargin";
import { Sales } from "./Sales";

const CHART_COUNT = 3;

export const EBITMarginChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  const maxValue = Math.max(Number(labels?.sales?.currentValue), Number(labels?.earnings?.currentValue));
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <Sales labels={labels?.sales} currentRate={Number(labels?.sales?.currentValue) / maxValue} {...chartProps} />
      <Earnings
        labels={labels?.earnings}
        currentRate={Number(labels?.earnings?.currentValue) / maxValue}
        {...chartProps}
      />
      <EBITMargin labels={labels?.margin} {...chartProps} />

      <line fill="none" stroke="#000000" x1="142" y1="328.5" x2="178" y2="328.5" />
      <line fill="none" stroke="#000000" x1="163" y1="266.5" x2="178" y2="266.5" />
      <line fill="none" stroke="#000000" x1="142" y1="266.5" x2="156.875" y2="266.5" />
    </SvgContainer>
  );
};
