import React, { useEffect } from "react";
import { triggerAnalyticsPageView } from "#helpers";
import { useHistory } from "react-router";

export const AnalyticsPageHandler: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const path = `${location.pathname}${location.hash}`;

      triggerAnalyticsPageView(path);
    });

    return unlisten;
  }, [history]);

  return null;
};
