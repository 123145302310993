import { bp, clearBtn, Color, defTransition, expander, IconKeys } from "#shared/theme";
import { rgba } from "polished";
// import { rgba } from "polished";
import React, { ChangeEvent, MouseEvent, useRef, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import * as screenfullDefault from "screenfull";
import { Screenfull } from "screenfull";
import styled, { css } from "styled-components/macro";
import { ButtonCircle, ButtonCircleSize } from "../ButtonCircle/ButtonCircle";
import { Icon, IconSize } from "../Icon/Icon";

const screenfull = screenfullDefault as Screenfull;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  @media ${bp.m} {
  }
  @media ${bp.t} {
  }
`;
const PlayerWrapper = styled.div<{ proportions: number }>`
  padding-bottom: ${({ proportions }) => proportions}%;
  height: 0;
`;
const StyledPlayer = styled(ReactPlayer)`
  ${expander}
  z-index: 2;
  video {
    object-fit: cover;
    object-position: center;
  }
`;
const Controls = styled.div<{ isPlaying: boolean }>`
  ${expander}
  z-index: 5;
  display: flex;
  align-items: center;
  transition: opacity ${defTransition};
  ${({ isPlaying }) =>
    isPlaying &&
    css`
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    `}
`;
const ControlButton = styled.div`
  margin: 0 auto;
`;
const ControlExtra = styled.div`
  flex: 1 0 0%;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;
const Button = styled.button`
  ${clearBtn}
  flex: 0 0 34px;
  height: 34px;
  margin: 0 10px;
  fill: ${Color.Green};
  position: relative;
`;

const Bottom = styled.div`
  position: absolute;
  left: 50px;
  right: 50px;
  bottom: 25px;
  display: flex;
  align-items: center;
`;

const Timeline = styled.div`
  position: relative;
  flex: 1 0 0%;
`;
const Timer = styled.div`
  color: ${Color.White};
  font-size: 13px;
  padding-right: 10px;
`;
const Progress = styled.progress`
  width: 100%;
  display: block;
  margin: auto 0;
  padding: 0;
  border: 0 none;
  height: 4px;
  background: ${rgba(Color.Green, 0.3)};
  border-radius: 4px;
  overflow: hidden;
  &::-moz-progress-bar {
    background: ${Color.Green};
    border-radius: 4px;
  }
  &::-webkit-progress-bar {
    background: transparent;
  }
  &::-webkit-progress-value {
    background: ${Color.Green};
  }
`;
const ProgressRange = styled.input`
  ${expander}
  margin: auto 0;
  width: 100%;
  height: 4px;
  background-color: transparent;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background: transparent;
    border: 0;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  &::-webkit-slider-thumb {
    margin-top: 0px;
    width: 0px;
    height: 4px;
    background: transparent;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  &::-moz-range-track {
    background: ${rgba(Color.Green, 0.3)};
    border: 0;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 0px;
    height: 4px;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
`;
const Time = styled.time``;

function pad(s: number) {
  return ("0" + s).slice(-2);
}
function format(seconds: number) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

const Duration: React.FC<{ seconds: number }> = ({ seconds }) => {
  return <Time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</Time>;
};

const playbackInitialValue = {
  loaded: 0,
  loadedSeconds: 0,
  played: 0,
  playedSeconds: 0,
};
interface PlaybackState {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
}

export interface VideoPlayerProps {
  src: string;
  volume?: number;
  poster?: string;
  width?: string;
  height?: string;
  proportions?: number;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  volume = 1,
  poster,
  width = "",
  height = "",
  proportions = (9 / 16) * 100,
}) => {
  const playerRef = useRef<ReactPlayerProps>(null);
  const [initialPlay, setInitialPlay] = useState<boolean>(false);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);
  const [isFullscreen, setFullscreen] = useState<boolean>(screenfull.isFullscreen);
  const [duration, setDuration] = useState<number>(0);
  const [playbackState, setPlaybackState] = useState<PlaybackState>(playbackInitialValue);
  const handleClick = (): void => {
    if (!initialPlay) {
      setInitialPlay(true);
    }
    setPlaying(!isPlaying);
  };
  const handleClickMute = () => {
    setMuted(!muted);
  };
  const handleClickFullscreen = () => {
    const videoInstance = playerRef.current?.getInternalPlayer();
    screenfull.request(videoInstance).then(() => {
      setFullscreen(true);
      const volumeChangeHandler = () => {
        setMuted(!muted);
      };
      const fullscreenChangeHandler = () => {
        if (!screenfull.isFullscreen) {
          setFullscreen(false);
          screenfull.off("change", fullscreenChangeHandler);
          videoInstance.removeEventListener("volumechange", volumeChangeHandler);
        }
      };
      videoInstance.addEventListener("volumechange", volumeChangeHandler);
      screenfull.on("change", fullscreenChangeHandler);
    });
  };
  const handlePlay = () => {
    if (isPlaying) return;
    setPlaying(true);
  };
  const handlePause = () => {
    if (!isPlaying) return;
    setPlaying(false);
  };
  const handleSeekMouseDown = () => {
    setSeeking(true);
  };
  const handleSeekChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlaybackState({
      ...playbackState,
      played: parseFloat(e.target.value),
    });
  };
  const handleSeekMouseUp = (e: MouseEvent<HTMLInputElement>) => {
    setSeeking(false);
    const val = parseFloat((e.target as HTMLInputElement).value);
    setPlaybackState({
      ...playbackState,
      played: val,
    });
    playerRef.current?.seekTo(val);
  };
  const handleProgress = (state: PlaybackState) => {
    if (!seeking) {
      setPlaybackState(state);
    }
  };
  const handleDuration = (duration: number) => {
    setDuration(duration);
  };
  return (
    <Container>
      <PlayerWrapper proportions={proportions}>
        <StyledPlayer
          ref={playerRef as any}
          url={src}
          width={width}
          height={height}
          controls={isFullscreen || screenfull.isFullscreen}
          volume={volume}
          preload="auto"
          playing={isPlaying}
          muted={muted}
          config={{ file: { attributes: { poster } } }}
          progressInterval={40}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onPlay={handlePlay}
          onPause={handlePause}
        />
      </PlayerWrapper>
      <Controls isPlaying={isPlaying}>
        {initialPlay && <ControlExtra />}
        <ControlButton>
          <ButtonCircle onClick={handleClick} size={ButtonCircleSize.Large}>
            <Icon iconKey={isPlaying ? IconKeys.SvgPause : IconKeys.SvgPlay} />
          </ButtonCircle>
        </ControlButton>
        {initialPlay && (
          <ControlExtra>
            <Button onClick={handleClickMute}>
              <Icon size={IconSize.Middle} iconKey={muted ? IconKeys.SvgSoundOff : IconKeys.SvgSoundOn} />
            </Button>
            {screenfull.isEnabled && (
              <Button onClick={handleClickFullscreen}>
                <Icon size={IconSize.Middle} iconKey={IconKeys.SvgFullscreen} />
              </Button>
            )}
          </ControlExtra>
        )}
        <Bottom>
          <Timer>
            <Duration seconds={playbackState.playedSeconds} /> /
            <Duration seconds={duration} />
          </Timer>
          <Timeline>
            <Progress max={1} value={playbackState.played} />
            <ProgressRange
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={playbackState.played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />
          </Timeline>
        </Bottom>
      </Controls>
    </Container>
  );
};
