import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { ChartProps } from "#interfaces";
import { LeftKPI } from "./LeftKPI";
import { MainKPI } from "./MainKPI";
import { RightKPI1 } from "./RightKPI1";
import { RightKPI2 } from "./RightKPI2";

const CHART_COUNT = 4;

export const DeliveriesChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <MainKPI labels={labels?.mainKPI} {...chartProps} />
      <LeftKPI
        labels={labels?.leftKPI}
        currentRate={Number(labels?.leftKPI?.rate)}
        prevRate={Number(labels?.rightKPI_1?.rate) + Number(labels?.rightKPI_2?.rate)}
        {...chartProps}
      />
      <RightKPI1 labels={labels?.rightKPI_1} currentRate={Number(labels?.rightKPI_1?.rate)} {...chartProps} />
      <RightKPI2
        labels={labels?.rightKPI_2}
        currentRate={Number(labels?.rightKPI_2?.rate)}
        prevRate={Number(labels?.rightKPI_1?.rate)}
        {...chartProps}
      />
      <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" d="M142,328.5h36" />
    </SvgContainer>
  );
};
