import { svg } from "#mocks";
import { Color, fontCondensed } from "#shared/theme";
import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";

interface StyledTextProps {
  withLines: boolean;
}

const StyledText = styled.div<StyledTextProps>`
  h1,
  h2,
  h3,
  h4 {
    ${fontCondensed}
  }
  h1 {
    font-size: 30px;
    line-height: 1.13;
    margin-bottom: 56px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  h3 {
    ${({ withLines }): FlattenSimpleInterpolation | false =>
      withLines &&
      css`
        position: relative;
        padding-top: 6px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          width: 32px;
          background-color: ${Color.Black};
        }
      `}
  }
  h3,
  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 2px;
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
    & + a[download] {
      margin-top: 28px;
    }
  }
  p {
    font-size: 18px;
    line-height: 1.33;
    & + p {
      margin-top: 10px;
    }
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
  }
  a {
    font-weight: bold;
    border-bottom: 1px solid ${Color.Green};
  }
  a[download] {
    font-weight: normal;
    border-bottom: none;
    font-size: 18px;
    line-height: 1.33;
    display: flex;
    width: fit-content;
    align-items: center;
    color: ${Color.GrayDark};
    &:before {
      content: "";
      margin-right: 16px;
      margin-left: 6px;
      background-image: url(${svg.arrowDownload});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
    }
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
    & + a {
      margin-top: 28px;
    }
  }
  ul,
  ol {
    font-size: 18px;
    line-height: 1.33;
    padding-left: 18px;
  }
`;

export interface TextProps {
  text: string;
  className?: string;
  withLines?: boolean;
}

export const Text: React.FC<TextProps> = ({ text, withLines = false, className }) => {
  return <StyledText dangerouslySetInnerHTML={{ __html: text }} withLines={withLines} className={className} />;
};
