import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import {
  bp,
  colsDesktop,
  colsMobile,
  grid,
  gridColumnWidth,
  gridColumns,
  gridColumnStart,
  gridRowStart,
} from "#shared/theme";

interface GridProps {
  columns?: number[];
  fullHeight?: boolean;
}

export const Grid = styled.div<GridProps>`
  width: 100%;
  ${grid}
  ${({ columns = [colsMobile, colsDesktop] }) => css`
    ${gridColumns(columns[0])}
    @media ${bp.m} {
      ${gridColumns(columns[1])}
    }
  `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

interface GridProps {
  left?: number;
  width?: number;
  top?: number;
}

export const Col = styled.div<GridProps>`
  ${({ left = 0 }): FlattenSimpleInterpolation => gridColumnStart(left)}
  ${({ width = 1 }): FlattenSimpleInterpolation => gridColumnWidth(width)}
  ${({ top = 1 }): FlattenSimpleInterpolation => gridRowStart(top)}
`;
