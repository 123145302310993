import React, { useEffect, useState } from "react";
import { Grid, Wrapper, VCPTurbo, VCPContainer, VCPItems } from "#ui";
import { SlideshowProvider } from "#providers";
import { Item1 } from "./Item1";
import { Item2 } from "./Item2";
import { Item3 } from "./Item3";
import { Item4 } from "./Item4";
import { Item5 } from "./Item5";
import { Item6 } from "./Item6";
import { VCPItemProps } from "#interfaces";

export const VCPChartMobile: React.FC<Pick<VCPItemProps, "labels">> = ({ labels }) => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      <Wrapper>
        <Grid fullHeight>
          <VCPContainer isVisible={isMounted}>
            <VCPTurbo viewBox="0 0 40 272" xmlns="http://www.w3.org/2000/svg">
              <g>
                <text transform="matrix(1 0 0 1 2.441406e-04 269.1016)" fontSize="14">
                  Input
                </text>
                <text transform="matrix(1 0 0 1 1.9436 12.2773)" fontSize="14" fill="#FFFFFF">
                  Output
                </text>
              </g>
            </VCPTurbo>
            <SlideshowProvider length={6} defaultIndex={-1}>
              <VCPItems>
                <Item1 slideIndex={1} labels={labels?.item1} />
                <Item2 slideIndex={2} labels={labels?.item2} />
                <Item3 slideIndex={3} labels={labels?.item3} />
                <Item4 slideIndex={4} labels={labels?.item4} />
                <Item5 slideIndex={5} labels={labels?.item5} />
                <Item6 slideIndex={6} labels={labels?.item6} />
              </VCPItems>
            </SlideshowProvider>
          </VCPContainer>
        </Grid>
      </Wrapper>
    </>
  );
};
