import getCurve from "./getCurve";
const lightsVert = `attribute vec4 aLight;
uniform float uTime;
uniform float uLoopZ;
uniform float uTravelLength;
uniform float uCurveAmplitude;
uniform float uCurveFrequency;
uniform float uDissipateProgress;
varying float vFade;

attribute vec3 aEnd;
attribute vec3 aC0;
attribute vec3 aC1;
vec3 cubicBezier(vec3 p0, vec3 c0, vec3 c1, vec3 p1, float t) {
    float tn = 1.0 - t;

    return tn * tn * tn * p0 + 3.0 * tn * tn * t * c0 + 3.0 * tn * t * t * c1 + t * t * t * p1;
}
	#include <fog_pars_vertex>
${getCurve}
uniform float uPosZ;
varying float vProgress;
void main(){
	vec3 transformed = position;
	vec4 worldPos = modelMatrix* vec4(position, 1.);
	float height = 10.;
	float time = uTime;
	// time = 0.;
	float offsetZ = aLight.y;
	float speed = aLight.z;
	float repeating = mod(time * uLoopZ * speed + offsetZ , uLoopZ);
	worldPos.z = worldPos.z -repeating ;
	// worldPos.z += uTime;
	float progress = -(worldPos.z - uPosZ - uLoopZ/2.) / uLoopZ;
	float wholeProgress = repeating / (uLoopZ);
	progress *= progress;

	worldPos.xy *=aLight.a;
	worldPos.x += aLight.x;
	worldPos.xy += getCurve(worldPos.z, time);

	// worldPos.xyz += uDissipateProgress * cubicBezier(vec3(0.),aC0, aC1, aEnd, progress) ;
	// progress * 10.;
	// worldPos.y += 100.;

	vec4 mvPosition = viewMatrix* worldPos;
	gl_Position = projectionMatrix * mvPosition;
	vFade = 1.-(position.y / 10. + 0.5);
	vProgress = wholeProgress;
		#include <fog_vertex>

}`;
export default lightsVert;
