import { isIos } from "#constants";
import { bp } from "#shared/theme";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Burger } from "../Burger/Burger";
import { ButtonCircle } from "../ButtonCircle/ButtonCircle";

export const CloseButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 34px;
  margin: 25px auto;
  @media ${bp.m} {
    margin: 30px auto;
  }
`;

const Container = styled(({ forceLandscape, ...restProps }) => <CloseButtonContainer {...restProps} />)`
  ${({ forceLandscape }) =>
    forceLandscape &&
    css`
      @media (orientation: landscape) {
        left: 0;
        top: 0;
        right: auto;
        margin: auto 20px;
      }
    `}
`;

interface ModalCloseButtonProps {
  onClick: () => void;
}
export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClick }) => {
  return (
    <Container forceLandscape={isIos}>
      <ButtonCircle onClick={onClick}>
        <Burger isActive />
      </ButtonCircle>
    </Container>
  );
};
