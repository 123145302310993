import React, { FC } from "react";
import { Wrapper } from "../Wrapper/Wrapper";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { ImageWrapperAlign, ImageWrapperPage } from "../InterviewImage/InterviewImage";

interface InterviewVideo {
  src: string;
  poster: string;
  page: ImageWrapperPage;
  align: ImageWrapperAlign;
  proportions?: number;
}

export const InterviewVideo: FC<InterviewVideo> = ({ src, poster, proportions }) => (
  <Wrapper>
    <VideoPlayer src={src} poster={poster} proportions={proportions} />
  </Wrapper>
);
