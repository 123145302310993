import { rootElement } from "#helpers";
import { bp } from "#shared/theme";
import { ScrollTrigger } from "#shared/ScrollTrigger";
import { FixedContainer, SvgContainer } from "#ui";
import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { ChartWrapper } from "../ChartContainer/ChartContainer";

gsap.registerPlugin(ScrollTrigger);

const SvgScrollContainer = styled(FixedContainer)<{ isInitiated: boolean }>`
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
`;

const ObjectsSvg = styled.svg`
  height: 1920px;
  width: 1920px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${bp.t} {
    height: 3840px;
    width: 3840px;
  }
`;

const Circle = styled.circle`
  opacity: 0;
`;

const ChartWrapperStyled = styled(ChartWrapper)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  @media ${bp.m} {
    opacity: 1;
  }
`;

export const SvgAnimation: React.FC<{ startPathname: string; tourPathname: string }> = ({
  startPathname,
  tourPathname,
}) => {
  const { pathname } = useLocation();
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const timelineRef = useRef<GSAPTimeline | null>(null);
  const timelineInnerRef = useRef<GSAPTimeline | null>(null);
  const timelineSetRef = useRef<GSAPTimeline | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const objectsRef = useRef<SVGSVGElement>(null);
  const linesRef = useRef<SVGGElement>(null);
  const circleRef = useRef<SVGCircleElement>(null);
  const circleRef1 = useRef<SVGCircleElement>(null);
  const circleRef2 = useRef<SVGCircleElement>(null);
  const circleRef3 = useRef<SVGCircleElement>(null);
  const circleRef4 = useRef<SVGCircleElement>(null);
  const circleRef5 = useRef<SVGCircleElement>(null);
  const circleRef6 = useRef<SVGCircleElement>(null);
  const valueRefBlack = useRef<SVGPathElement>(null);
  const valueRefWhite = useRef<SVGPathElement>(null);
  const finalCircleRef = useRef<SVGGElement>(null);
  const finalPartLeftRef = useRef<SVGPathElement>(null);
  const finalPartRightRef = useRef<SVGPathElement>(null);
  function killAllAnims() {
    const st = ScrollTrigger.getById("masterTimeline");

    if (st) st.kill();

    if (timelineRef.current) timelineRef.current.kill();
    timelineRef.current = null;
    if (timelineInnerRef.current) timelineInnerRef.current.kill();
    timelineInnerRef.current = null;
    if (timelineSetRef.current) timelineSetRef.current.kill();
    timelineSetRef.current = null;
  }
  useEffect(() => {
    const valueLength = valueRefBlack.current?.getTotalLength() || 0;
    const finalLeftPartLength = finalPartLeftRef.current?.getTotalLength() || 0;
    const finalRightPartLength = finalPartRightRef.current?.getTotalLength() || 0;
    ScrollTrigger.refresh(true);
    ScrollTrigger.update();

    if (pathname !== startPathname && pathname != tourPathname && isVisible) {
      killAllAnims();
      gsap.to(containerRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.out",
      });
      setIsVisible(false);
      return;
    }
    if (pathname !== startPathname && isInitiated) {
      if (timelineInnerRef.current) timelineInnerRef.current.pause();
      setIsInitiated(false);
      const t1 = gsap.timeline();

      if (pathname != tourPathname) {
        return;
      }
      t1.to(finalCircleRef.current, {
        scale: 1,
        opacity: 1,
        duration: 0.7,
        ease: "power3.inOut",
      })
        .to(
          finalPartLeftRef.current,
          { strokeDasharray: `0 ${finalLeftPartLength}`, duration: 0.7, ease: "power3.inOut" },
          0
        )
        .to(
          finalPartRightRef.current,
          { strokeDasharray: `0 ${finalRightPartLength}`, duration: 0.7, ease: "power3.inOut" },
          0
        );
      t1.to(
        [
          objectsRef.current,
          circleRef1.current,
          circleRef2.current,
          circleRef3.current,
          circleRef4.current,
          circleRef5.current,
          circleRef6.current,
        ],
        { opacity: 0, duration: 0.7, ease: "power3.inOut" },
        0
      );
    }
    if (pathname !== startPathname || isInitiated) return;
    killAllAnims();

    setIsVisible(true);

    const timelineSet = gsap
      .timeline()
      .set([valueRefBlack.current, valueRefWhite.current], { strokeDasharray: `0 ${valueLength}` })
      .set(finalCircleRef.current, { opacity: 0, transformOrigin: "center" })
      .set(objectsRef.current, { opacity: 0 })
      .set(
        [
          circleRef1.current,
          circleRef2.current,
          circleRef3.current,
          circleRef4.current,
          circleRef5.current,
          circleRef6.current,
        ],
        { opacity: 0, scale: 1 }
      );
    timelineSet.set(containerRef.current, { opacity: 1 });
    timelineSetRef.current = timelineSet;
    const tl = gsap
      .timeline({ defaults: { transformOrigin: "center" } })
      .fromTo(objectsRef.current, { opacity: 0 }, { opacity: 1, duration: 0.1 }, 0)
      .fromTo(linesRef.current, { rotate: 0 }, { rotate: 45, duration: 0.7 }, 0)
      .fromTo(
        [
          circleRef1.current,
          circleRef2.current,
          circleRef3.current,
          circleRef4.current,
          circleRef5.current,
          circleRef6.current,
        ],
        { opacity: 0 },
        { opacity: 1, duration: 0.02, stagger: 0.1 },
        0.1
      )
      .fromTo(
        [
          circleRef1.current,
          circleRef2.current,
          circleRef3.current,
          circleRef4.current,
          circleRef5.current,
          circleRef6.current,
        ],
        { scale: 1 },
        { scale: 10, duration: 0.4, stagger: 0.1 },
        0.1
      )
      .to(
        [
          circleRef1.current,
          circleRef2.current,
          circleRef3.current,
          circleRef4.current,
          circleRef5.current,
          circleRef6.current,
        ],
        { opacity: 0, duration: 0.02, stagger: 0.1 },
        0.39
      )
      .fromTo(
        valueRefBlack.current,
        { rotate: 0, scale: 1 },
        {
          rotate: 45,
          scale: 10,
          duration: 0.4,
          transformOrigin: "-100 115",
          strokeDasharray: `${valueLength * 0.7} ${valueLength}`,
        },
        0.3
      )
      .fromTo(
        valueRefWhite.current,
        { rotate: 0, scale: 1 },
        {
          rotate: 45,
          scale: 10,
          duration: 0.4,
          transformOrigin: "-100 115",
          strokeDasharray: `${valueLength} ${valueLength}`,
        },
        0.3
      )
      .to(
        [valueRefBlack.current, valueRefWhite.current],
        { opacity: 0, duration: 0.2, transformOrigin: "-100 115" },
        0.5
      )
      .fromTo([linesRef.current, circleRef.current], { scale: 1 }, { scale: 10, duration: 0.4 }, 0.7)
      .fromTo([linesRef.current, circleRef.current], { opacity: 1 }, { opacity: 0, duration: 0.02 }, 1)
      .fromTo(finalCircleRef.current, { scale: 0 }, { scale: 1, opacity: 1, duration: 0.2 }, 0.71)
      .fromTo(
        finalPartLeftRef.current,
        { strokeDasharray: `${finalLeftPartLength} ${finalLeftPartLength}` },
        { strokeDasharray: `0 ${finalLeftPartLength}`, duration: 0.2 },
        0.73
      )
      .fromTo(
        finalPartRightRef.current,
        { strokeDasharray: `${finalRightPartLength} ${finalRightPartLength}` },
        { strokeDasharray: `0 ${finalRightPartLength}`, duration: 0.2 },
        0.73
      );

    const masterTimeline = gsap
      .timeline({
        scrollTrigger: {
          id: "masterTimeline",
          trigger: "#app-container",
          start: "60% top",
          end: "bottom bottom",
          scrub: 1,
          scroller: rootElement,
        },
      })
      .add(tl);
    timelineRef.current = masterTimeline;
    timelineInnerRef.current = tl;

    // ScrollTrigger.refresh(true);
    const st = ScrollTrigger.getById("masterTimeline");
    if (st) {
      st.disable();
    }
    // ScrollTrigger.update();
    setIsInitiated(true);
  }, [pathname, startPathname, isInitiated, tourPathname, isVisible]);

  useEffect(() => {
    return () => {
      killAllAnims();
    };
  }, []);

  return (
    <SvgScrollContainer isInitiated={isInitiated} ref={containerRef}>
      <ObjectsSvg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 3840 3840">
        <g ref={objectsRef}>
          <g ref={linesRef}>
            <polygon points="1917,-1126 1920,1748 1923,-1126 " />
            <polygon points="-717.913,400 1771.022,1833.987 -717.913,394 " />
            <polygon points="2068.98,2006.013 4557.914,3440 4557.914,3446 " />
            <polygon points="1917,4966 1920,2092 1923,4966 " />
            <polygon points="-717.913,3446 1771.021,2006.013 -717.913,3440 " />
            <polygon points="4557.914,400 2068.98,1833.987 4557.914,394 " />
          </g>
          <circle ref={circleRef} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef1} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef2} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef3} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef4} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef5} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />
          <Circle ref={circleRef6} fill="none" stroke="#000000" cx="1920" cy="1920" r="172" />

          <path
            ref={valueRefWhite}
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="9.9"
            d="M2041.622,1798.378  c67.17,67.17,67.17,176.074,0,243.245"
          />
          <path
            ref={valueRefBlack}
            fill="none"
            stroke="#000000"
            strokeWidth="10"
            d="M2041.622,1798.378  c67.17,67.17,67.17,176.074,0,243.245"
          />
        </g>
      </ObjectsSvg>
      <ChartWrapperStyled>
        <SvgContainer xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 896 544">
          <g ref={finalCircleRef}>
            <path
              fill="none"
              stroke="#020203"
              d="M588.14,371.742C608.199,343.61,620,309.184,620,272  c0-94.993-77.007-172-172-172s-172,77.007-172,172c0,37.184,11.801,71.61,31.86,99.742"
            />
            <path
              ref={finalPartRightRef}
              fill="none"
              stroke="#020203"
              d="M588.14,371.742C556.955,415.479,505.809,444,448,444"
            />
            <path
              ref={finalPartLeftRef}
              fill="none"
              stroke="#020203"
              d="M307.86,371.742C339.045,415.479,390.191,444,448,444"
            />
          </g>
        </SvgContainer>
      </ChartWrapperStyled>
    </SvgScrollContainer>
  );
};
