import { AudioPlayer, AudioPlayerProps } from "#components";
import { useTranslation } from "#hooks";
import { bp, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid, Wrapper } from "#ui";
import React from "react";
import { AudioPlayerProvider } from "react-use-audio-player";
import styled from "styled-components/macro";

const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.p} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(16)}
  }
`;

export const Audio: React.FC<{ source: string }> = ({ source }) => {
  const { t: tHome } = useTranslation("home");
  const audio = tHome(source) as unknown;

  return (
    <Wrapper>
      <Grid>
        <Content>
          <AudioPlayerProvider>
            <AudioPlayer {...(audio as AudioPlayerProps)} />
          </AudioPlayerProvider>
        </Content>
      </Grid>
    </Wrapper>
  );
};
