import { Color } from "#shared/theme";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";

export interface BurgerProps {
  isActive: boolean;
}

export const Burger = styled.div<BurgerProps>`
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.22s;
  width: 16px;
  position: relative;
  ${({ isActive }): FlattenSimpleInterpolation | false =>
    isActive &&
    css`
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(225deg);
    `}
  &,
  &:before,
  &:after {
    height: 2px;
    border-radius: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    background-color: ${Color.Black};
  }
  &:before,
  &:after {
    position: absolute;
    width: 100%;
    display: block;
    content: "";
  }
  &:before {
    transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    top: -6px;
    ${({ isActive }): FlattenSimpleInterpolation | false =>
      isActive &&
      css`
        top: 0;
        transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
        opacity: 0;
      `}
  }
  &:after {
    transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -6px;
    ${({ isActive }): FlattenSimpleInterpolation | false =>
      isActive &&
      css`
        bottom: 0;
        transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      `}
  }
`;
