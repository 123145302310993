export const production = {
  title: {
    headline:
      "To ensure its activities are truly sustainable, the BMW Group looks at the entire value chain. We talked to Patricia Perez Szmak and Jury Witschnig about what that means for the supplier network and production.",
  },
  sections: [
    {
      type: "Image",
      props: {
        align: "Left",
        src: "/data/interview/production/image-production-author.jpg",
        caption: {
          desc: "",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "Mr Witschnig, environmental protection in production sounds like it involves a lot of different factors, right?",
        copytext:
          "<p><strong>Jury Witschnig</strong> Yes. It covers all key resources for production – on both the input and output sides. For example, water – where the focus is naturally on reducing consumption. But we don’t ignore wastewater, either. What can we use again within the cycle? And what is the environmental impact of wastewater that can’t be avoided – how clean is it? We also take an equally comprehensive view of waste, energy, solvents, etc. Our goal is clean production, with minimum impact on the environment.</p>",
      },
    },
    {
      type: "ParallaxQuote",
      props: {
        align: "Right",
        parallaxColor: "Orange",
        copytext:
          "<p>The most impressive aspect is probably the <strong>80% REDUCTION</strong> in <strong>WASTE FROM PRODUCTION</strong>.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "What have we already achieved in production?",
        copytext:
          "<p><strong>Jury Witschnig</strong> In our most recent target period from 2006 to 2020: quite a lot. If we look just at the key figures, the most impressive aspect is probably the 80% reduction in waste from production. But we also made really good progress with water, energy and solvents.</p><p>One milestone is that, since 2020, 100% of the electricity for BMW Group plants worldwide has been sourced from renewable energies – hydroelectric, wind and solar, as well as from biomass power plants. Green power is a very important lever for reducing our plants’ carbon footprint.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1440,
        items: [
          { width: 50, height: 38, src: "/data/interview/production/image-gallery-production-1.jpg" },
          { width: 50, height: 38, src: "/data/interview/production/image-gallery-production-2.jpg" },
        ],
        caption: {
          desc:
            "<p>Jury Witschnig is responsible for environmental protection in production. Patricia Perez Szmak heads the project dedicated to sustainability in the supply chain.</p>",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "The goal is to reduce CO2 emissions per vehicle from production by another 80% by 2030. How will you do that?",
        copytext:
          "<p><strong>Jury Witschnig</strong> We approached the previous goals in several steps. We started by identifying best-practice solutions for technology efficiency in our own network. We looked at production processes in detail and enhanced the technologies. For example, we established a new process in the paint shop. Individual process steps were eliminated and new ones have been developed – water and energy consumption has fallen substantially as a result. That was a real leap in technology that has established itself as part of our process engineering. We are also looking at how our products are designed. We want to understand what we need to do in development so we can produce vehicles even more sustainably later on.</p><p>We had a lot of success with this and are ready to take the next step. We are transferring these concepts to our global production network, developing them further and making them our standard. We know where technology can still leverage potential. That’s why I’m optimistic we can reach the high goals we set ourselves.</p><p>We are currently working on our next big topic: gas consumption. We are specifically looking for further efficiencies and exploring where we can replace natural gas with biogas, hydrogen or renewable electricity.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/production/image-quote-production-1.jpg",
        quote:
          "We know where technology can still leverage potential. That’s why I’m optimistic we can reach the high goals we set ourselves in order to become a clean company.",
        author: "Jury Witschnig",
      },
    },
    {
      type: "Chapter",
      props: {
        headline:
          "A further goal is to reduce CO2 emissions in the supplier network by at least 20% by 2030. Ms Perez Szmak, how will you do that?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> First of all, you need to understand what a 20% reduction in CO2 really means: Expanding electromobility will increase CO2 emissions in the supply chain: in our case, by about a third, if we don’t do anything about it. We don’t just want to stop this increase; we want to reverse it. Instead of a third more CO2, we want to decrease it by 20%. So, the goal of reducing CO2 emissions in the supplier network by at least 20% by 2030 is actually much more ambitious than it appears at first glance.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "And how will we achieve this goal?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> We have a concrete ten-year plan. Together with our partners in the supplier network, we are focusing on two main topics: We want to increase the percentage of renewable energies and, at the same time, use more secondary material – that is, high-quality, recycled material. This is another important step towards a circular economy.</p><p>Expanding the use of renewable energies in our global supplier network is quite challenging – but it is also the most important lever for reducing CO2.</p><p>We reached contractual agreements to this effect with our battery cell suppliers some time ago. As a result, 100% renewable energy is used to produce the battery cells for our fifth-generation eDrive, like the ones used in the BMW iX3. Since the start of the year, we have been sourcing aluminium produced using solar power for our light metal foundry in Landshut. The CO2 reductions from these measures are huge. That is why we are gradually expanding this requirement to all our purchasing activities and making it a major decision criterion. In this way, we will be able to reach over 50% of our CO2 target with our partners.</p>",
      },
    },
    {
      type: "ImageGallery",
      props: {
        maxWidth: 1345,
        items: [
          { width: 65, height: 41, src: "/data/interview/production/image-gallery-1.jpg" },
          { width: 35, height: 41, src: "/data/interview/production/image-gallery-2.jpg" },
        ],
        caption: {
          desc:
            "<p>An important lever for this is the use of renewable energy. New technologies and the use of recycled materials are also central themes.</p>",
        },
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "And what about secondary materials?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> There are two important aspects to secondary materials: First, we want to increase the percentage of recycled material in our vehicles and, second, reintroduce scrap materials produced by certain processes back into the loop. This conserves natural resources and reduces the amount of energy needed for production.</p><p><strong>Jury Witschnig</strong> Closing cycles is actually another topic we have in common. We are already practising this at the press plant: Metal waste from there is returned directly to our suppliers for them to reuse in the material cycle.</p>",
      },
    },
    {
      type: "ImageQuote",
      props: {
        src: "/data/interview/production/image-quote-production-2.jpg",
        quote: "We are getting very positive feedback from the supplier network.",
        author: "Patricia Perez Szmak",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "Ms Perez Szmak, what challenges are you confronting together with the supplier network?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> Respect for human rights and compliance with environmental standards are extremely important to us. We built our requirements for this into our purchasing processes years ago. But supplier networks are global, complex and, above all, dynamic. It is important, but not always easy, to create transparency for these complex goods flows, so components and raw materials can be traced: That is a joint responsibility for everyone involved. We are already working on a large number of standards and technologies to create this kind of data transparency. Founding the Catena-X Automotive Networks alliance has brought us a lot closer to our idea of a cross-company data platform.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        headline: "To wrap up, I have a question for both of you: What has pleasantly surprised you recently?",
        copytext:
          "<p><strong>Patricia Perez Szmak</strong> We are getting very positive feedback from the supplier network – their willingness to tackle the issues is huge. Just like the passion and commitment of our teams. Actively tackling the issues and effecting change motivates me and makes me happy!</p><p><strong>Jury Witschnig</strong> Yes, the enthusiasm of everyone involved has been very positive for me – when colleagues come up with ideas no one has thought of before. And I’m talking about ideas for technical innovations that have brought efficiency improvements in the two-digit percentage range. That is where engineering shows its true genius.</p>",
      },
    },
    {
      type: "Chapter",
      props: {
        isTitle: false,
        copytext: "<p><strong>Thank you for talking to us.</strong></p>",
      },
    },
    {
      type: "ParallaxImage",
      props: {
        isDoubleCaption: true,
        parallaxColor: "Orange",
        items: [
          {
            src: "/data/interview/production/image-parallax-1.jpg",
            caption: {
              headline: "Patricia Perez Szmak",
            },
          },
          {
            src: "/data/interview/production/image-parallax-2.jpg",
            caption: {
              headline: "Jury Witschnig",
            },
          },
        ],
      },
    },
    {
      type: "ScrollTopButton",
    },
    {
      type: "StoryTeaser",
      props: {
        items: [
          {
            storyId: 1,
            link: "/interview/fleet-emissions/",
          },
          {
            storyId: 0,
            link: "/interview/diversity/",
          },
        ],
      },
    },
  ],
};
