import { useEffect } from "react";
import { rootElement } from "#helpers";

const lockPosition = {
  x: 0,
  y: 0,
};

const scrollListener = (ev: Event): void => {
  ev.preventDefault();
  ev.stopPropagation();

  rootElement.scrollTo(lockPosition.x, lockPosition.y);
};

const lockCurrentPosition = (): void => {
  lockPosition.x = rootElement.scrollLeft;
  lockPosition.y = rootElement.scrollTop;
};

export const disableScrolling = (): void => {
  lockCurrentPosition();
  rootElement.addEventListener("scroll", scrollListener);
};

export const enableScrolling = (): void => {
  rootElement.removeEventListener("scroll", scrollListener);
};

export const useWindowScrollLock = (): void => {
  useEffect(() => {
    disableScrolling();
    return enableScrolling;
  }, []);
};
