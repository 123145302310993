import { InfoCardTheme, InfoCardType } from "#interfaces";

export const VCP = {
  intro: {
    main: {
      text:
        "Die Wertschöpfung in der BMW Group ist ein Gesamtprozess, an dem viele unterschiedliche wertschaffende <a href='#' data-line='external'>Faktoren</a> beteiligt sind, die in teils <span>komplexen Wechselbeziehungen ineinandergreifen.</span>",
    },
    modal: {
      title: "BMW GROUP <span>Wertschöpfung</span>",
      text:
        "Diese Faktoren können finanzieller und nicht finanzieller Art sein und sich auf materielle und nicht materielle Werte beziehen. Sie sind darauf ausgerichtet, aus knappen Ressourcen Mehrwert zu schaffen. Die Inputfaktoren bilden dabei die Grundlage, auf der die Gesamtleistung der BMW Group entsteht.",
    },
  },
  chart: {
    item1: {
      title1: "Finanzkapital",
      title2: "Finanzkapital",
      inputLabel1: "Mio. € Gesamtvermögen",
      inputValue1: 216658,
      inputLabel2: "Mio. € Eigenkapital",
      inputValue2: 61520,
      outputLabel1: "Mio. € Dividendensumme (Vorschlag zur Hauptversammlung)",
      outputValue1: 1253,
      outputLabel2: "Mio. € Ergebnis vor Finanzergebnis (EBIT)",
      outputValue2: 4830,
    },
    item2: {
      title1: "Humankapital",
      title2: "Humankapital",
      inputLabel1: "Mitarbeiter zum Jahresende",
      inputValue1: 120726,
      inputLabel2: "Mio. € Aufwendungen für Aus- und Weiterbildung",
      inputValue2: 279,
      outputLabel1: "Mio. € Löhne / Gehälter /Sozialversicherungsbeiträge",
      outputValue1: 12244,
      outputLabel2: "Mitarbeiterzufriedenheit (in %)",
      outputValue2: 82,
    },
    item3: {
      title1: "Intellektuelles Kapital",
      title2: "Intellektuelles Kapital",
      inputLabel1: "umgesetzte Mitarbeiterideen aus Ideenmanagement CRE8",
      inputValue1: 1561,
      inputLabel2: "Mio. € F&E-Leistungen",
      inputValue2: 6279,
      outputLabel1: "Mio. € Erstjahresnutzen aus umgesetzten Mitarbeiterideen (CRE8)",
      outputValue1: 18.2,
      outputLabel2: "F&E-Quote (in %)",
      outputValue2: 6.3,
    },
    item4: {
      title1: "Beziehungskapital",
      title2: "Beziehungskapital",
      inputLabel1: "Anteil produktionsrelevantes Einkaufsvolumen im CDP-Supply-Chain-Programm (in %)",
      inputValue1: 79,
      inputLabel2: "Mrd. € Einkaufsvolumen",
      inputValue2: 55.5,
      outputLabel1: "Anteil Lieferanten an der Wertschöpfung (in %)",
      outputValue1: 60,
      outputLabel2: "Anteil der Lieferanten im CDP-Supply-Chain-Programm mit Bewertung von mindestens B (in %)",
      outputValue2: 35,
    },
    item5: {
      title1: "Produziertes Kapital",
      title2: "Produziertes Kapital",
      inputLabel1: "Anzahl der Werke",
      inputValue1: 31,
      inputLabel2: "davon Anzahl der Werke mit Produktion elektrifizierter Fahrzeuge",
      inputValue2: 13,
      outputLabel1: "Mio. Fahrzeugauslieferungen",
      outputValue1: 2.33,
      outputLabel2: "Auslieferungen elektrifizierter Fahrzeuge",
      outputValue2: 192662,
    },
    item6: {
      title1: "Natürliches Kapital",
      title2: "Natürliches Kapital",
      inputLabel1: "Energieverbrauch je produziertes Fahrzeug (in MWh)",
      inputValue1: 2.12,
      inputLabel2: "Fremdstromanteil aus erneuerbaren Energien (in %)",
      inputValue2: 100,
      outputLabel1:
        "Verbesserung Ressourcenverbrauch und Emissionen der Fahrzeug&shy;produktion seit 2006 (in %, im Durchschnitt)",
      outputValue1: -56.7,
      outputLabel2: "Entwicklung CO2-Emissionen EU-Neuwagenflotte seit 1995 (in %)",
      outputValue2: -53,
    },
  },
  info: [
    {
      type: InfoCardType.Copytext,
      props: {
        category: "Die BMW Group",
        title: "Wertschöpfung",
        theme: InfoCardTheme.Default,
        content: [
          {
            copytext:
              "<p>Die Darstellung orientiert sich am Rahmenkonzept des International Integrated Reporting Council und zeigt vereinfacht die miteinander in Zusammenhang stehenden Input- und Outputressourcen. Sie soll die integrierte Unternehmenssteuerung, die entlang des Wertschöpfungsprozesses alle wesentlichen Aspekte übergreifend berücksichtigt; exemplarisch aufzeigen.</p>",
          },
          {
            headline: "Kapitalarten",
            copytext:
              "<p><strong>Finanzkapital</strong> – beispielhaft stehen das Eigenkapital und das Gesamtvermögen: Eine angemessen hohe Eigenkapitalquote bildet unter anderem eine solide Basis für hohe Ergebnisse und in der Folge stabile Ausschüttungen.</p><p><strong>Humankapital und intellektuelles Kapital</strong> – Einer der zentralen Erfolgsfaktoren der BMW Group. Hier bündeln sich Wissen, Fähigkeiten,Fertigkeiten, Stärken und Talente der Mitarbeiterinnen und Mitarbeiter</p><p><strong>Beziehungskapital</strong> – Basis dafür das Vertrauen, das  Aktionärinnen und Aktionäre, Kundinnen und Kunden, Geschäftspartner und auch die Öffentlichkeit der BMW Group entgegenbringen.</p><p><strong>Produziertes Kapital</strong> – Rückgrat des produzierten Kapitals ist das internationale Produktionsnetzwerk der BMW Group.</p><p><strong>Natürliches Kapital</strong> – umfasst erneuerbare und nicht erneuerbare natürliche Ressourcen, die im Herstellungsprozess genutzt werden.</p>",
          },
        ],
      },
    },
    {
      type: InfoCardType.Pdf,
      props: {
        theme: InfoCardTheme.Gray,
        chapter: "Zusammengefasster Lagebericht",
        headline: "Grundlagen des Konzerns",
        page: 22,
        src: "/data/pdf/de/Grundlagen-des-Konzerns.pdf",
      },
    },
  ],
};
