import { useReducer } from "react";

export enum PopoverStatus {
  Open = "Open",
  Closing = "Closing",
  Close = "Close",
}

interface PopoverContextProps {
  isOpen: boolean;
  isClosing: boolean;
  isClose: boolean;
  open: () => void;
  close: () => void;
  reset: () => void;
}

interface PopoverState {
  status: PopoverStatus;
}

type PopoverAction = { type: PopoverStatus };

type PopoverReducerType = (state: PopoverState, action: PopoverAction) => PopoverState;

const PopoverReducer: PopoverReducerType = (_, action) => {
  return {
    status: action.type,
  };
};

export const usePopover = (defaultStatus = PopoverStatus.Close): PopoverContextProps => {
  const [{ status }, dispatch] = useReducer(PopoverReducer, { status: defaultStatus });
  const value = {
    isOpen: status === PopoverStatus.Open,
    isClose: status === PopoverStatus.Close,
    isClosing: status === PopoverStatus.Closing,
    open: (): void => dispatch({ type: PopoverStatus.Open }),
    close: (): void => dispatch({ type: PopoverStatus.Closing }),
    reset: (): void => dispatch({ type: PopoverStatus.Close }),
  };
  return value;
};
