import { bp, expander, fontCondensed } from "#shared/theme";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { MutableRefObject, RefObject, useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import mergeRefs from "react-merge-refs";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import useMeasure from "react-use-measure";
import { useTranslation } from "#hooks";
import styled from "styled-components/macro";
import { ScrollbarsContext } from "../Scrollbars/Scrollbars";

pdfjs.GlobalWorkerOptions.workerSrc = `/assets/pdf.worker.min.js`;

const Container = styled.div`
  overflow: hidden;
`;
const PdfPageContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  & + & {
    margin-top: 12px;
    @media ${bp.p} {
      margin-top: 20px;
    }
    @media ${bp.d} {
      margin-top: 25px;
    }
  }
`;
const Loading = styled.div`
  ${expander}
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  ${fontCondensed}
`;

const useEffectOnlyOnce = (func: any) => useEffect(func, [func]);
export interface PdfPageProps {
  scrollbarRef?: MutableRefObject<OverlayScrollbarsComponent | null>;
  width: number;
  height: number;
  pageNumber: number;
  page: number;
  setActivePageRef: (ref: RefObject<HTMLDivElement>) => void;
}

export const PdfPage: React.FC<PdfPageProps> = ({
  scrollbarRef,
  width,
  pageNumber,
  height,
  page,
  setActivePageRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageRef, inView] = useInView({
    root: scrollbarRef?.current?.osTarget(),
  });
  useEffectOnlyOnce(() => {
    if (page === pageNumber && height > 0) {
      setActivePageRef(containerRef);
    }
  });
  return (
    <PdfPageContainer ref={mergeRefs([pageRef, containerRef])} height={height}>
      {inView && <Page pageNumber={pageNumber} width={width} loading={""} />}
    </PdfPageContainer>
  );
};
export interface PdfProps {
  src: string;
  page: number;
}

export const Pdf: React.FC<PdfProps> = ({ src, page }) => {
  const pdfDocumentRef = useRef<HTMLDivElement>(null);
  const { scrollbarRef } = useContext(ScrollbarsContext);
  const [numPages, setNumPages] = useState<number>();
  const [ratio, setRatio] = useState<number>(1);
  const [activePageRef, setActivePageRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [containerRef, { width, height }] = useMeasure();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = async (pdf: any): Promise<void> => {
    setNumPages(pdf.numPages);
    const page = await pdf.getPage(1);
    const viewport = await page.getViewport();
    setRatio(viewport.viewBox[2] / viewport.viewBox[3]);
  };
  const { t } = useTranslation("common");
  useEffect(() => {
    if (!activePageRef?.current) return;
    scrollbarRef?.current?.osInstance()?.scroll({ y: activePageRef.current.offsetTop });
  }, [activePageRef, height, scrollbarRef]);
  return (
    <Container ref={mergeRefs([containerRef, pdfDocumentRef])}>
      <Document file={src} onLoadSuccess={onDocumentLoadSuccess} loading={<Loading>{t("pdfLoading")}</Loading>}>
        {Array.from(new Array(numPages), (_, index) => (
          <PdfPage
            key={`page_${index + 1}`}
            setActivePageRef={setActivePageRef}
            width={width}
            height={width / ratio}
            pageNumber={index + 1}
            page={page}
            scrollbarRef={scrollbarRef}
          />
        ))}
      </Document>
    </Container>
  );
};
