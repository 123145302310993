import { Languages } from "./context";

export const htmlLocales: { [k: string]: string } = {
  [Languages.en]: "en",
  [Languages.de]: "de",
};

export const ogLocales: { [k: string]: string } = {
  [Languages.en]: "en_US",
  [Languages.de]: "de_DE",
};
