import { pages } from "./pages";
import { AnalyticsEventTypes } from "#interfaces";

export const home = {
  start: {
    headline: "Über die ökonomische Leistung der BMW Group und ihren ökologischen und gesellschaftlichen Beitrag",
    title: "DER BMW GROUP<br>BERICHT 2020",
    slideshow: [
      {
        type: "Slider",
        theme: "BlueBold",
        headline: "Denn Glaubwürdigkeit entsteht durch Transparenz.",
        image: {
          src: "/data/teaser-1.png",
        },
        link: {
          href: "/wertschoepfung/",
          label: "Wie die BMW Group<br/>Werte schafft",
        },
      },
      {
        type: "Slider",
        theme: "Blue",
        headline: "Ausgewählte Kennzahlen unseres Geschäftsjahres 2020",
        image: {
          src: "/data/teaser-2.png",
        },
        link: {
          href: "/home/intro/#guided-tour-2020",
          label: "Guided Tour 2020",
        },
      },
      {
        type: "Slider",
        theme: "GrayLightest",
        headline: "Der BMW Group Bericht 2020 im PDF",
        image: {
          src: "",
        },
        bgImage: {
          srcMobile: "/data/teaser-4-portrait.jpg",
          src: "/data/teaser-4-portrait.jpg",
        },
        link: {
          href: "/data/pdf/de/BMW-Group-Bericht-2020-DE.pdf",
          label: "Download",
          download: true,
          analyticsEvent: AnalyticsEventTypes.FullReportPDFDownload,
        },
      },
    ],
  },
  hero: {
    headline: "<small>WIR MACHEN NICHT<br/>NACHHALTIGKEIT BEI BMW.</small>WIR MACHEN BMW NACHHALTIG.",
    author: "OLIVER ZIPSE",
  },
  entry: {
    copytext:
      "<p>Die BMW Group sieht in der Verbindung von Fahrfreude und der Verantwortung für nachhaltiges Handeln ein klares Differenzie&shy;rungs&shy;merkmal.</p><p>Nachhaltigkeit ist fest im Kern der Unternehmens&shy;strategie verankert. Von Anfang an beziehen wir ökonomische, aber auch ökologische und soziale Effekte in unsere Aktivitäten und Planungen ein.</p>",
    image: {
      src: "/data/zipse.jpg",
    },
    caption: {
      headline: "OLIVER ZIPSE",
      desc: "VORSITZENDER DES VORSTANDS DER BMW AG",
    },
  },
  values: {
    copytext:
      '<p>Unsere Entscheidungen treffen wir <a href="/wertschoepfung/" data-line="external" target="_blank" rel="noreferrer">INTEGRIERT</a>. Unsere Kundinnen und Kunden können sicher sein: Sie erleben hoch innovative und emotionale Produkte. Dafür steht die BMW Group als nachhaltig denkendes und handelndes Unternehmen.',
    image: {
      src: "/data/teaser-1.png",
    },
    caption:
      '<p>Erfahren Sie mehr darüber</p><p><a href="/wertschoepfung/" target="_blank">Wie die BMW Group Werte schafft</a></p>',
    link: {
      type: "external",
      to: pages.list.interactiveValueCreationProcess.path,
      analyticsEvent: AnalyticsEventTypes.GoToVCPFromIntro,
    },
    heading: {
      title: "BMW Group",
      headline: "Der Wertschöpfungsprozess der BMW Group",
    },
    footing: {
      copytext: "Wie die BMW Group Werte schafft",
    },
    closeBtn: {
      analyticsEvent: AnalyticsEventTypes.CloseVCPModalFromIntro,
    },
  },
  quote1: {
    headline:
      "TECHNOLOGIE IST FÜR UNS DER SCHLÜSSEL, UNSERER ÖKOLOGISCHEN UND GESELLSCHAFTLICHEN <strong>Verantwortung</strong> GERECHT ZU WERDEN UND ALS UNTERNEHMEN ERFOLGREICH UND PROFITABEL ZU SEIN.",
    author: "OLIVER ZIPSE",
  },
  goals: {
    copytext:
      '<p>Unser konsequent nachhaltiges Handeln sichert uns einen Wettbewerbsvorteil.</p><p>2012 hatten wir zehn strategische <a href="#" data-line="pdf">Nachhaltigkeitsziele bis 2020</a> formuliert. Mission erfüllt: Wir haben die CO2-Emissionen unserer EU-Neuwagenflotte von 1995 bis 2020 mehr als halbiert. Unsere Werke weltweit beziehen ausschließlich Grünstrom. Unser Frauenanteil in Führungsfunktionen liegt über unserem Zielkorridor.</p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Ziele 2020",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewGoals2020,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Zusammengefasster Lagebericht",
            headline: "Grundlagen des Konzerns",
            page: 12,
            src: "/data/pdf/de/Grundlagen-des-Konzerns.pdf",
          },
        },
      ],
    },
  },
  strategy: {
    copytext:
      '<p>Wir sind den Zielen des Pariser Klimaabkommens verpflichtet. Dafür haben wir uns konkrete, wissenschafts&shy;basierte <a href="#" data-line="pdf">ZIELE FÜR 2030</a> gesetzt:</p><p>Um mindestens ein Drittel sollen die CO2-Emissionen je Fahrzeug über den Lebenszyklus sinken. Das geschieht durch Innovation, nicht Kompensation.</p><p>Im Einzelnen reduzieren wir die CO2-Emissionen in der Produktion um 80%, in der Nutzungsphase um mehr als 40% und in der Lieferkette um mindestens 20%. Hier leiten wir sogar eine Trendumkehr ein und senken die Emissionen. In der Lieferkette würde die Zunahme der E-Mobilität ohne Gegenmaßnahmen sogar zu einem Anstieg der CO2-Emissionen um rund ein Drittel führen.</p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Strategische Ziele",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewStrategicGoals,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Zusammengefasster Lagebericht",
            headline: "Grundlagen des Konzerns",
            page: 16,
            src: "/data/pdf/de/Grundlagen-des-Konzerns.pdf",
          },
        },
      ],
    },
  },
  audio_ceo: {
    photo: { src: "/data/zipse-audio.jpg" },
    src: "/data/audio/oliver_zipse_DE.mp3",
    name: "OLIVER ZIPSE",
    copytext: "ZUR STRATEGISCHEN AUSRICHTUNG DER BMW GROUP",
    analyticsEventPlay: AnalyticsEventTypes.AudioCEOPlay,
    analyticsEventPause: AnalyticsEventTypes.AudioCEOPause,
    analyticsEventFinish: AnalyticsEventTypes.AudioCEOFinish,
  },
  results: {
    head: {
      headline: "DIE WICHTIGSTEN ZIELE<br><strong>DER BMW GROUP</strong>",
    },
    list: [
      {
        headline: {
          size: "Default",
          copytext: "Strategie&shy;ziele",
        },
        list: [
          {
            value: "> 10%",
            caption: "EBT-Marge im Konzern",
          },
          {
            value: "↑ 8-10%",
            caption: "EBIT-Marge im Segment Automobile",
          },
          {
            value: "≥ 40%",
            caption: "Return on Capital Employed im Segment Automobile",
          },
        ],
      },
      {
        headline: {
          size: "Large",
          copytext: "2025",
        },
        list: [
          {
            value: "↑ ≥ 25%",
            caption: "Mindestanteil von elektrifizierten Automobilen an den Gesamtauslieferungen",
          },
          {
            value: "↑ 22%",
            caption: "Anteil von Frauen in Führungsfunktionen in der BMW Group",
          },
        ],
      },
      {
        headline: {
          size: "Large",
          copytext: "2030",
        },
        list: [
          {
            value: "↓ 80%",
            caption: "Reduzierung CO2-Emissionen je Fahrzeug in der Produktion (Bezugsjahr 2019)",
          },
          {
            value: "↓ > 40%",
            caption:
              "Reduzierung CO2-Emissionen in der Nutzungsphase des Fahrzeugs je gefahrenem Kilometer (Bezugsjahr 2019)",
          },
          {
            value: "↓ ≥ 20%",
            caption: "Reduzierung CO2-Emissionen in der Lieferkette (Bezugsjahr 2019)",
          },
        ],
      },
    ],
  },
  dummy2: {
    copytext:
      "<p>Weit voraus zu denken und die Komplexität in unserer Industrie zu beherrschen – das zeichnet die BMW Group aus. So sind und bleiben wir erfolgreich.</p><p><cite>Ihr Oliver Zipse<small>VORSITZENDER DES VORSTANDS DER BMW AG</small></cite></p>",
  },
  quote2: {
    headline:
      "WIR HABEN IM ABGELAUFENEN GESCHÄFTSJAHR BEWIESEN, DASS WIR HERAUSFORDERNDE SITUATIONEN BEHERRSCHEN – UND GLEICHZEITIG DIE <strong>RICHTIGEN WEICHEN FÜR DIE ZUKUNFT STELLEN</strong> KÖNNEN.",
    author: "NICOLAS PETER",
  },
  about: {
    copytext:
      "<p>Das Thema Nachhaltigkeit haben wir noch stärker in die Unternehmens&shy;entscheidungen integriert. Auch in der Transparenz gegenüber unseren Stakeholdern gehen wir einen Schritt weiter. Unser erster integrierter Konzernbericht verbindet Finanzbericht und Nachhaltigkeits&shy;bericht.</p>",
    image: {
      src: "/data/peter.jpg",
    },
    caption: {
      headline: "NICOLAS PETER",
      desc: "MITGLIED DES VORSTANDS DER BMW AG, FINANZEN",
    },
  },
  dummy3: {
    copytext:
      '<p>Wir legen transparent und nach international anerkannten Standards dar, wie Nachhaltig&shy;keits&shy;aktivitäten unsere <a href="#" data-line="pdf">WERTSCHÖPFUNG</a> beeinflussen.</p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Wie die BMW Group Werte schafft",
      type: "pdf",
      analyticsEvent: AnalyticsEventTypes.IntroModalPdfPreviewHowTheBMWGroupCreatesValue,
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "Zusammengefasster Lagebericht",
            headline: "Grundlagen des Konzerns",
            page: 22,
            src: "/data/pdf/de/Grundlagen-des-Konzerns.pdf",
          },
        },
      ],
    },
  },
  audio_cfo: {
    photo: { src: "/data/peter-audio.jpg" },
    src: "/data/audio/nicolas_peter_DE.mp3",
    name: "NICOLAS PETER",
    copytext: "ZUM INTEGRIERTEN BERICHT",
    analyticsEventPlay: AnalyticsEventTypes.AudioCFOPlay,
    analyticsEventPause: AnalyticsEventTypes.AudioCFOPause,
    analyticsEventFinish: AnalyticsEventTypes.AudioCFOFinish,
  },
  cfo_text1: {
    copytext:
      "Wir haben 2020 unsere Fähigkeit bewiesen, unter hoch volatilen Bedingungen schnell und flexibel zu handeln. Im ersten Halbjahr haben wir mit einem konsequenten Kostenmanagement die Voraussetzungen geschaffen, um die Markterholung des zweiten Halbjahres ergebniswirksam zu nutzen. Dabei haben wir uns gegenüber unseren Stakeholdern als zuverlässiger Partner erwiesen.",
  },
  cfo_text2: {
    copytext:
      "<p>Unsere Perspektive ist immer langfristig, und unsere Entscheidungen zielen darauf ab, nachhaltig Werte zu schaffen – auf der Basis von Profitabilität und durch Wachstum.</p><p>Für das Geschäftsjahr 2021 gehen wir trotz des volatilen und durch die Pandemie belasteten Umfelds von einem Aufwärtstrend bei der Geschäftsentwicklung aus.</p><p><cite>Ihr Nicolas Peter<small>MITGLIED DES VORSTANDS DER BMW AG, FINANZEN</small></cite><p>",
  },
  guidedtour: {
    headline: "Guided Tour",
    subheadline: "Ausgewählte<br>Kennzahlen 2020",
  },
  gesagtgetan: {
    head: {
      headline: "NACHHALTIGKEITSZIELE 2020 ERREICHT.<br><strong>DREI BEISPIELE:</strong>",
      // link: {
      //   label: "<strong>ZIELE-GRAFIK</strong> <small>AUS DEM PDF-BERICHT</small>",
      //   href: "#",
      // },
    },
  },
};
