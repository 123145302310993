import { TextColorThemeTypes, ThemeKeys } from "#constants";
import { Color } from "#shared/theme";
import React, { CSSProperties } from "react";
import styled, { css, ThemeProvider } from "styled-components/macro";
import theme from "styled-theming";
import { Header, HeaderProps } from "../Header/Header";
import { ShareLinks } from "../ShareLinks/ShareLinks";

const Body = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${theme(ThemeKeys.textColor, {
    [TextColorThemeTypes.Light]: css`
      color: ${Color.White};
    `,
  })}
`;

interface RootProps {
  style?: CSSProperties;
  withHeader?: boolean;
  headerProps?: HeaderProps;
  theme?: TextColorThemeTypes;
}

export const Root: React.FC<RootProps> = ({
  children,
  style,
  withHeader,
  headerProps,
  theme = TextColorThemeTypes.Default,
}) => {
  return (
    <ThemeProvider
      theme={{
        [ThemeKeys.textColor]: theme,
      }}
    >
      {withHeader && <Header {...headerProps} />}
      <ShareLinks />
      <Body {...{ style }}>{children}</Body>
    </ThemeProvider>
  );
};
