export function bindAll(that, items) {
  for (let i = 0; i < items.length; i++) {
    let key = items[i];
    that[key] = that[key].bind(that);
  }
}

export function clamp(x, min, max) {
  if (x < min) return min;
  if (x > max) return max;
  return x;
}

export function mix(val0, val1, t) {
  return val0 * (1 - t) + val1 * t;
}
