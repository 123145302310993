import { Spacer } from "#ui";
import React from "react";
import { About } from "./About";
import { Audio } from "./Audio";
import { Dummy } from "./Dummy";
import { Dummy3 } from "./Dummy3";
import { Entry } from "./Entry";
import { GesagtGetan } from "./GesagtGetan";
import { Goals } from "./Goals";
import { GuidedTour } from "./GuidedTour";
import { Hero } from "./Hero";
import { Quote1 } from "./Quote1";
import { Quote2 } from "./Quote2";
import { Results } from "./Results";
import { Strategy } from "./Strategy";
import { Values } from "./Values";

export const Intro: React.FC = () => {
  return (
    <>
      <Hero id="ceo" />
      <Spacer size={50} />
      <Entry />
      <Spacer size={15} />
      <Values />
      <Spacer size={75} />
      <Quote1 />
      <Spacer size={75} />
      <Goals />
      <Spacer />
      <GesagtGetan />
      <Spacer />
      <Strategy />
      <Spacer />
      <Audio source="audio_ceo" />
      <Spacer />
      <Results />
      <Spacer size={125} />
      <Dummy source="dummy2" />
      <Spacer size={50} />
      <Quote2 id="cfo" />
      <Spacer size={125} />
      <About />
      <Spacer size={15} />
      <Dummy3 />
      <Spacer size={75} />
      <Audio source="audio_cfo" />
      <Spacer size={75} />
      <Dummy source="cfo_text1" />
      <Spacer size={75} />
      <Dummy source="cfo_text2" />
      <Spacer size={75} />
      <GuidedTour id="guided-tour-2020" />
    </>
  );
};
