import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card32M: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 530">
      <g id="BG">
        <rect x="152.72" fill="#1EBEE4" width="156.43" height="419.01" />
        <rect y="85" width="290.68" height="7" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="landscape">
          <g>
            <path
              fill="#E0DAC7"
              d="M170.28,369.45c-7.08-0.79-8.01-6.11-15.07-4.53c-5.6,1.25-8.18-2.82-13.01,0.03
				c-0.82,0.48-1.44,1.06-1.76,1.38c1.07,0.36,1.67,1.04,1.96,1.48c1.28-0.21,2.41,0.19,2.41,0.19c1.13-1.19,2.74-1.07,2.74-1.07
				c-2.2,0.36-2.68,1.73-2.68,1.73c-2.98-1.37-5.24,1.13-5.24,1.13c0.68-1.19,1.61-1.72,2.51-1.92c-1.78-1.25-3.9-1.88-6.89-1.09
				c-1.25,0.42-2.86,1.14-4.99,2.32c-3.53,1.96-13.62,3.04-21.04,3.24l71.55-1.03C180.77,371.31,181.19,370.67,170.28,369.45z"
            />
            <path
              fill="#E0DAC7"
              d="M291.75,305.53c-2.27-3.34-7.16-7.32-12.7-4.8c-1.06,0.52-2.24,1.27-3.49,2.36
				c0.1,0.02,0.19,0.05,0.29,0.08c1.53,0.48,2.25,1.52,2.25,1.52s4.77-0.38,6.27,3.67c0,0-2.4-3.79-6.55-3.05
				c0,0-1.84-2.98-6.72-1.3c0,0,2.02-1.43,4.32-0.95c0.43-0.59,1.4-1.72,3.01-2.74c-2.65-3.42-5.78-4.46-8.01-4.81
				c-6.04-0.95-12.5,1.09-17.78,4.2c-10.56,6.24-20.08,6.82-31.38,10.24l85.27-1.14C298.31,308.65,294.95,303.58,291.75,305.53z"
            />
            <g>
              <path
                fill="#BED4D3"
                d="M182.74,394.82c0.17,0.07,0.85,0.13,1.04,0.14c0.43,0.01,0.24,0.87,0.66,0.99
					c2.13,0.59,2.15,2.05,1.93,3.58c0.58-0.35,1.72-0.25,2.18,0.25c0.46,0.5,0.87,1.49,0.97,2.16c-0.08-0.45,0.39-1.03,0.84-1.14
					c0.45-0.1,0.83,0.3,0.96,0.74c0.62-0.54,1.4-0.09,1.37,0.73c0.53-0.11,0.96,0.07,1.21,0.55c0.26,0.5,0.35,1.41,0.42,1.95
					c1.05-0.17,2.05-0.35,2.66,0.6c0.13,0.2,0.47,0.7,0.55,0.92c0.27-0.28,0.62-0.43,1-0.41c0.55,0.03,1.06,0.85,0.81,1.34
					c0.25-0.21,0.77-0.69,1.1-0.73c0.33-0.03,0.47,0.2,0.42,0.52c0.1-0.3,0.64-0.23,0.89-0.04c0.28,0.21,0.58,0.67,0.86,0.88
					c0.78-1.03,1.66-1.58,2.85-1.06c1.23,0.55,1.2,1.58,1.6,2.88c0.19-0.26,0.21-0.9,0.48-0.73c0.21,0.13-0.01,0.51,0.1,0.73
					c0.33,0.66,1.81-0.43,2,1.37c0.11-0.01,0.63-0.08,0.74-0.1c0.65-0.01,1.3,0.61,1.56,0.86c0.48-1.39,2.34-2.67,3.76-3.03
					c-0.29-1.45-0.23-3.04,0.95-3.92c1.18-0.89,2.3-0.43,3.62,0.26c0.67-1.32,2.92-2.03,4.17-1.24c0.66-1.98,2.48-3.7,4.52-4.13
					s3.41,0.83,4.81,2.37c0.57,0.63,1.3,1.04,2.08,1.35c0.39,0.16,0.66,0.6,1.08,0.69c0.96,0.22,1.03,1.04,1.53,1.89
					c0.18,0.3,1.21-0.62,1.48-0.39c0.06,0.05,1.3,1.13,1.37,1.17c1.24-3.4,4.53-3.91,4.86-4.57c0.35-0.69,0.77-1.64,0.98-2.37
					c0.77-2.66,3.19-3.39,5.92-3.98c2.73-0.59,3.65-0.38,5.48,1.72c0.3,0.35,1.25,0.57,1.68,0.69c1.39-0.84,3.38-0.69,5.01-0.35
					c0.5,0.1,0.66,0.2,1.13,0.39c0.67,0.24,1.01-0.02,1.57,0.41c0.23,0.18,0.07,0.84,0.29,1.05c0.47,0.42-0.35,0.03,0.04,0.51
					c-6.07,3.11-12.13,9.43-12.13,9.43c5.77-3.94,13.19-5.72,16.08-6.87c1.05-0.39,2.72-1.05,3.87-1.42
					c10.9-3.52,20.99-5.06,29.89-3.95c-21.08-16.7-32.64-33.14-14.21-44.17c26.78-16.03-14.53-27.29-12.4-28.7
					c-8.47,1.11-16.24,7.07-28.06,17.08c-24.89,21.08-46.11,41.77-68.49,52.01c0.01,0.01-0.56,0.46-0.55,0.47
					C182.47,394.38,182.48,394.71,182.74,394.82z"
              />
              <path
                fill="#97B9B8"
                d="M153.59,386.68c6.9,0.49,10.14,6.06-0.52,12.58s-12.83,10.42-4.93,13.98c7.9,3.55,2.49,7.7,3.95,11.13
					c1.1,2.6,9.05,5.76,13.62,6.3c1.03,0,2.3-0.96,3.32-0.96c1.49-0.37,2.77-0.63,3.84-0.93c0.04-0.05,0.48-0.21,0.53-0.25
					c0.31-0.26,0.61-0.47,0.93-0.72c0.31-0.24,0.17-0.55,0.57-0.52c0.21,0.01,0.59,0.3,0.78,0.21c0.21-0.09,0.56-0.53,0.65-0.73
					c0.24-0.6,0.66-0.68,1.28-0.47c0.16-0.52,0.56-0.8,0.99-1.13c0.43-0.33,0.57-0.61,1.1-0.47c0.6,0.15,0.51,0.28,0.77,0.84
					c0.2,0.43,0.54,1.01,0.62,1.48c0.31-0.03,1.26-0.09,1.64-0.09c-0.03-0.18,0.12-0.85,0.15-1.02c0.04-0.28,0.15-0.72,0.09-1.01
					c-0.13-0.62-0.4-0.4-0.51-1.02c-0.07-0.38,0.51-0.62,0.6-0.82c0.16-0.36-0.47-1.27-0.68-1.61c-0.21-0.34-0.03-0.49-0.16-0.86
					c-0.23-0.67-0.5-1.16-0.24-1.83c0.05-0.12,0.43-0.27,0.42-0.4c-0.01-0.26-0.13-0.8-0.29-1c-0.51-0.67-0.67-0.98,0.05-1.41
					s1.61-0.83,1.61,1.32c0,0.36-0.1,2.47-0.09,4.35c0.07-0.04,0.72-0.09,0.8-0.09h0.52l0.23-0.31c-0.01-0.55,0.26-0.74,0.76-0.97
					c0.5-0.24,1.13,0.15,1.55,0.51c0.18-0.79,1.01-1.79,1.81-1.87c0.8-0.08,1.42,0.47,2.1,0.9c0.14,0.09,1,0.36,1.15,0.3
					c0.1-0.04,0.14-0.68,0.19-0.77c0.38-0.63,0.84-1.06,1.32-1.62c0.47-0.56,0.45-0.7,1.18-0.81c0.73-0.11,1.34,0.3,1.64,0.97
					c0.49-0.37,1.21-0.65,1.81-0.78c0.6-0.13,1.08-0.57,1.44-0.08c0.29,0.39,0.38,1.12,0.7,1.48c1.03-0.44,2.24-0.9,3.27-0.47
					c0.63,0.26,1.16,0.74,1.79,0.99c0.79,0.3,1.62,0.35,2.46,0.38c0.34,0.01,0.97-0.11,1.28,0.05c0.17-0.53,0.46-1.35,0.58-1.9
					c0.2-0.94,0.65-1.64,1.58-1.92c0.3-0.09,1-0.28,1.31-0.23c-0.72-0.77-1-2.04-1.03-3.1c-0.02-0.51-0.77-0.96-0.61-1.44
					c-0.26-0.25-0.65-0.41-1.3-0.4c-0.11,0.01-0.23,0.02-0.34,0.04c-0.19-1.8-1.37-0.61-1.69-1.27c-0.11-0.22-0.1-0.52-0.31-0.64
					c-0.27-0.17-0.59,0.12-0.78,0.37c-0.41-1.3-0.13-2.21-1.36-2.75c-1.19-0.53-2.24-0.07-3.03,0.96c-0.28-0.21-0.56-0.42-0.84-0.63
					c-0.25-0.19-0.7-0.32-0.8-0.02c0.05-0.32-0.33-0.58-0.65-0.55c-0.33,0.03-0.6,0.26-0.85,0.47c0.25-0.49-0.24-1.12-0.79-1.15
					c-0.38-0.02-0.73,0.17-1,0.45c-0.08-0.22-0.2-0.43-0.32-0.63c-0.6-0.95-1.64-1.22-2.69-1.05c-0.07-0.54-0.04-1.06-0.3-1.56
					c-0.25-0.48-0.78-0.85-1.3-0.74c0.03-0.82-1.14-1.37-1.75-0.83c-0.13-0.44-0.63-0.74-1.08-0.64c-0.45,0.1-0.77,0.59-0.7,1.04
					c-0.1-0.67-0.3-1.37-0.76-1.87c-0.46-0.5-1.27-0.74-1.85-0.39c0.22-1.53,0.11-3.38-2.02-3.97c-0.41-0.11-0.65-0.64-1.08-0.64
					c-0.19,0-0.38,0.06-0.55-0.02c-0.26-0.11-0.26-0.48-0.47-0.67c-0.01-0.01-0.03-0.02-0.04-0.04c-1.18-1.09-1.55-0.21-2.04-0.88
					c-0.4-0.55-0.52-0.98-0.97-1.49c-1.5-1.72-3.83-1.05-5.15-0.28c-0.31-0.01-0.58-0.19-0.87-0.3c-0.62-0.22-1.37,0-1.77,0.52
					c-1.19-0.77-1.55-1.24-2.64-1.64c-0.96-0.35-2.76-0.23-3.54,0.43c-0.54-0.64-1.47-0.89-2.27-0.67
					c-3.91-1.74-7.4-2.78-10.98-2.78c-1.55,0-3.08,0.23-4.6,0.67C150.08,386.73,151.9,386.56,153.59,386.68z"
              />
              <path
                fill="#659896"
                d="M95.06,429.25c14.24,0,44.75,0.03,70.79,0.04c-4.57-0.54-11.95-2.75-13.05-5.35
					c-1.45-3.43,3.95-7.26-3.95-10.81c-7.9-3.55-5.13-6.91,5.53-13.43c10.66-6.52,6.11-12.54-0.79-13.03
					c-1.69-0.12-3.5,0.05-5.6,0.63c-5.57,1.54-13.11,5.99-25.4,15.8c-17.42,13.9-41.54,26.18-41.54,26.18l14.29,0.02
					C95.15,429.27,95.06,429.25,95.06,429.25z"
              />
              <path
                fill="#97B9B8"
                d="M243.83,407.67c0.65-0.89,1.56-1.61,2.59-2c1.66-0.64,3.61-0.45,5.11,0.48c-0.92-1.42-2.73-2.2-4.39-1.9
					c-0.05-1.61,0.93-3.2,2.39-3.87c1.46-0.67,3.31-0.38,4.5,0.7c2.47-1.62,5.96-1.53,8.34,0.23c-0.64-1.32-1.86-2.35-3.28-2.75
					c0.36-0.47,0.81-0.85,1.31-1.15c-0.43-0.13-0.74-0.52-1.04-0.87c-1.83-2.1-4.86-3.1-7.59-2.5c-2.73,0.59-5.06,2.76-5.83,5.42
					c-0.21,0.74-0.32,1.52-0.67,2.2c-0.33,0.66-3.63,0.1-4.87,3.5c0.23,0.13,0.5,0.21,0.75,0.31
					C242.25,405.87,243.2,406.67,243.83,407.67z"
              />
              <path
                fill="#659896"
                d="M289.48,353.12c-18.43,11.03-5.77,27.13,15.3,43.83c-8.9-1.12-18.92-0.55-29.82,2.98
					c-1.15,0.37-2.25,0.75-3.3,1.14c-2.88,1.15-8.23,3.54-14,7.49c0,0,6.76-5.35,12.84-8.46c-0.39-0.48-0.82-0.94-1.29-1.36
					c-0.25-0.22-0.5-0.43-0.77-0.62c-0.55-0.4-1.15-0.73-1.78-0.98c-0.47-0.19-0.96-0.35-1.45-0.45c-1.62-0.34-3.42-0.12-4.82,0.72
					c-0.5,0.3-0.94,0.68-1.31,1.15c1.42,0.4,2.64,1.43,3.28,2.75c-2.38-1.75-5.86-1.85-8.34-0.23c-1.19-1.09-3.04-1.38-4.5-0.7
					c-1.46,0.67-2.45,2.27-2.39,3.87c1.66-0.31,3.47,0.48,4.39,1.9c-1.51-0.94-3.46-1.12-5.11-0.48c-1.03,0.4-1.94,1.11-2.59,2
					c-0.63-1-1.58-1.8-2.68-2.21c-0.25-0.09-0.52-0.17-0.75-0.31c-0.07-0.04-0.14-0.09-0.2-0.14c-0.26-0.23-0.41-0.56-0.59-0.86
					c-0.51-0.85-1.37-1.47-2.33-1.69c-0.41-0.09-0.84-0.12-1.24-0.27c-0.78-0.32-1.26-1.09-1.83-1.72c-1.4-1.55-3.65-2.28-5.69-1.85
					s-3.8,2-4.46,3.99c-1.25-0.79-3.13-0.27-3.8,1.05c-1.31-0.68-3.01-0.55-4.19,0.34c-1.18,0.89-1.79,2.48-1.51,3.93
					c-1.43,0.37-2.59,1.53-3.07,2.91c-0.16,0.48-0.25,0.98-0.23,1.48c0.03,1.06,0.5,2.07,1.23,2.85c0.64,0.1,1.24,0.48,1.51,1.07
					c0.01,0.01,0.03,0.01,0.04,0.02c0.24-0.05,0.78-0.21,1.02-0.28l0.77-1.27l0.02-0.37l4.97-4.82l8.33-0.05l0.51-0.04l3.78-0.09
					h0.58l3.81,0.31l0.78-0.35l3.56,0.13l0.93,0.11l3.21,0.26l0.57-0.22l3.28,0.21l-0.63,2.8c0.01,0,0.38,0.09,0.38,0.09
					c-0.01,0.03-0.38-0.12-0.38-0.09c-0.02,0.1,0.4-0.01,0.38,0.09c-0.68-0.12-1.79,0.08-1.74,0.77c-0.17,0,0.05,0.29-0.11,0.35
					c-0.01,0,0.01,0,0,0c-0.53,0.19-0.8,0.74-0.92,1.3c-0.01,0.05-0.51,0.42-0.52,0.48c-0.02,0.13,0.24,0.22,0.18,0.33
					c-0.09,0.16-0.34,0.27-0.47,0.38c-0.34,0.29-0.12,0.34-0.31,0.74c-0.07-0.07,0,0-0.07-0.06c-0.1-0.08-0.06-0.34-0.18-0.41
					c-0.47-0.27-1.24-0.56-1.78-0.47c-0.52,0.08-1.35,0.51-1.7,0.9c-0.13,0.14-0.82,0.32-0.91,0.48c-0.02,0.03,0.13,0.39,0.11,0.42
					c-0.06,0.11,0,0-0.04,0.11c-0.04,0.12-0.09,0.5-0.11,0.62c-1.03-0.02-2.12,0.8-1.95,1.82c-0.24,0.05-0.38,0.02-0.6,0.13
					c-0.53,0.27-0.27,0.57-0.55,1.1c-0.45-0.54-1.05-0.97-1.73-1.12c-0.24-0.05-0.84-0.09-1.08-0.05c-0.43,0.05-1.38,0.59-1.66,0.91
					c-0.09,0.1-0.26,0.47-0.33,0.58c-0.18,0.33-0.61,0.29-0.49,0.66c-0.17-0.07,0.18,0.27,0,0.31c-0.12,0.03,0.01,0.21-0.09,0.27
					c-0.27,0.16-0.6,0.18-0.64,0.49c-0.63,0.31-1.16,0.02-1.71,0.46c-0.13,0.11-0.49,0.35-0.61,0.46c-0.09,0.08,0.01,0.66-0.07,0.74
					c-0.11,0.11-0.02,0.36-0.13,0.47c-0.04,0.04-0.48-0.04-0.51,0c-0.84,0.23-0.56-0.13-1.48,0.07c-1.33,0.29-1.5,0.57-3.01,0.79
					c-0.01-1.83-1.07-2.83-1.17-3.66c-0.37-3.05-1.58-5.45-2.17-8.46c-0.03,0.21-0.5,1.1-0.51,1.31c-0.12,2.15-0.57,2.93-0.91,4.59
					c-0.34,1.63-1.42,3.89-0.84,5.43c0.22,0.57-0.16,1,0,1.68c-0.24,0.02-0.58-0.45-0.83-0.42c-1.65,0.15-2.57,0.14-4.43,0.22
					c-0.11,0-0.47,0.52-0.58,0.52c-1.61,0.06-2.95-0.06-4.74-0.05c-0.03,0-0.22,0.22-0.25,0.22c-0.08,0-0.11-0.03-0.19-0.03
					c-1.67-0.01-3.3-0.09-5.01-0.19c-0.11-0.01-0.25-0.19-0.36-0.19c-1.71-0.1-3.34-0.34-5.01-0.49c-0.11-0.01-0.58,0.04-0.69,0.03
					c-1.63-0.15-3.17-0.25-4.69-0.41c-3.21-0.34-5.22-0.3-7.43-0.41c0-0.99-0.74-3.13-0.75-5.22c-0.01-1.88-0.02-3.58-0.02-3.94
					c0-2.14-1.74-2.16-2.46-1.73s-0.91,1.53-0.4,2.2c0.16,0.21,0.38,0.4,0.39,0.66c0.01,0.13-0.04,0.25-0.09,0.37
					c-0.26,0.66-0.54,1.39-0.31,2.07c0.13,0.37,0.4,0.68,0.61,1.01s0.35,0.76,0.2,1.12c-0.09,0.21-0.27,0.56-0.2,0.94
					c0.11,0.63,0.62,0.66,0.74,1.28c0.06,0.28-0.05,0.57-0.09,0.85c-0.03,0.18-0.02,0.36,0.01,0.54c-0.38,0-0.73,0.01-1.04,0.04
					c-0.08-0.47-0.18-0.95-0.38-1.38c-0.26-0.56-0.72-1.05-1.32-1.2c-0.53-0.13-1.11,0.03-1.54,0.36c-0.43,0.33-0.72,0.83-0.88,1.35
					c-0.62-0.21-1.37,0.13-1.61,0.74c-0.08,0.21-0.14,0.47-0.34,0.55c-0.19,0.08-0.41-0.03-0.62-0.05c-0.39-0.03-0.7,0.3-1.02,0.55
					c-0.32,0.25-0.69,0.42-0.99,0.68c-0.05,0.04-0.09,0.08-0.14,0.13c-1.06,0.31-2.24,0.64-3.73,1.02
					c-1.32,0.33-2.88,0.69-4.83,1.09c-0.61,0.12,103.52,7.81,103.52,7.81l77.85-7.18v-71.01c-8.12-5.76-34.57-24.45-42.23-28.61
					c-9.08-4.94-15.79-7.5-23.3-6.91c-0.29,0.02-0.48,0.07-0.58,0.13C277.24,326.03,316.26,337.09,289.48,353.12z"
              />
              <path fill="#42637C" d="M230.51,425.59c0.08-0.08,0.17-0.16,0.26-0.24h-0.26V425.59z" />
              <linearGradient
                id="Card32MSVGID1"
                gradientUnits="userSpaceOnUse"
                x1="230.6346"
                y1="415.6183"
                x2="230.6346"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID1)" d="M230.51,425.59c0.08-0.08,0.17-0.16,0.26-0.24h-0.26V425.59z" />
              <path
                fill="#42637C"
                d="M246.31,408.78v-0.02l-4.22,0.02v-0.02l-4.26,0.02v-0.02l-4.26,0.02v-0.02l-4.17,0.02v-0.02l-9.71,0.05
					l-4.81,5.45h-0.12l-0.11,2.29c0.1-0.03,0.34,0.05,0.44,0.03c0.66-0.12,0.59-0.12,1.1,0.33c0.5,0.45,0.86,1.01,0.82,1.68
					c-0.01,0.11,0.66,0.27,0.74,0.35c0.09,0.09,0.31,0.29,0.44,0.27c0.6-0.07,0.63-0.13,0.9,0.41c0.24,0.48-0.11,0.68-0.15,1.21
					l-3.41-0.47l-0.74,0.27l-24-0.08l-5.8,0.53h-0.62c-0.08,0-0.16,0.02-0.23,0.06c0.01,2.1,0.02,4.43,0.03,5.41
					c2.21,0.11,5.08,0.43,8.3,0.77v-0.01c1.52,0.16,3.11,0.33,4.74,0.48v0.01c0.11,0.01,0.22,0.02,0.33,0.03
					c1.67,0.15,3.39,0.29,5.1,0.4c0.11,0.01,0.22,0.01,0.33,0.02c1.72,0.1,3.43,0.16,5.1,0.17c0.08,0,0.16,0,0.24,0
					c0.03,0,0.06,0,0.09,0c1.79,0,3.49-0.03,5.1-0.1c0.11,0,0.22-0.01,0.33-0.01c1.86-0.08,3.61-0.19,5.26-0.35
					c0.25-0.02,0.49-0.05,0.73-0.07c-0.16-0.67-0.14-1.44-0.14-2.06c0-1.68,0.62-3.75,0.95-5.38c0.34-1.66,0.54-2.42,0.67-4.57
					c0.01-0.21,0.06-0.42,0.09-0.63c0.59,3.01,1.19,5,1.56,8.05c0.1,0.83,0.22,2.35,0.23,4.17c1.51-0.22,2.93-0.48,4.26-0.77
					c0.92-0.2,1.8-0.42,2.65-0.65c0.04-0.04,0.07-0.08,0.11-0.12c0.11-0.11,0.22-0.23,0.33-0.34v-0.24h0.26
					c0.13-0.11,0.25-0.22,0.38-0.33c0.55-0.44,1.14-0.82,1.77-1.13c0.04-0.32,0.24-0.59,0.52-0.75c0.1-0.06,0.21-0.1,0.33-0.13
					c0.18-0.04,0.36-0.03,0.54,0.03c-0.12-0.37-0.05-0.75,0.14-1.08c0.06-0.12,0.14-0.23,0.23-0.33c0.29-0.32,0.69-0.55,1.11-0.61
					c0.25-0.03,0.49-0.02,0.73,0.03c0.68,0.14,1.3,0.58,1.76,1.12c0.28-0.53,0.74-0.95,1.27-1.22c0.22-0.11,0.45-0.19,0.69-0.24
					c-0.16-1.02,0.75-2.07,1.78-2.05c0.02-0.13,0.05-0.25,0.1-0.37h-2.55v-0.33h2.69c0.02-0.03,0.03-0.06,0.05-0.09
					c0.09-0.16,0.2-0.31,0.33-0.44c0.36-0.38,0.83-0.66,1.35-0.74c0.54-0.09,1.1,0.03,1.58,0.31c0.12,0.07,0.23,0.14,0.33,0.23
					c0.07,0.06,0.15,0.13,0.21,0.2c0.19-0.4,0.46-0.76,0.8-1.05c0.14-0.12,0.29-0.23,0.38-0.38c0.06-0.11,0.09-0.24,0.11-0.37
					c0.01-0.05,0.02-0.1,0.03-0.15c0.12-0.55,0.56-1.02,1.09-1.21l0.04-0.01c0.16-0.05,0.32-0.08,0.48-0.09
					c-0.05-0.69,0.65-1.32,1.33-1.21c0.01-0.1,0.03-0.19,0.05-0.29v-0.09v-3.81L246.31,408.78z M197.2,426.42h-4.74v-0.01h4.74
					V426.42z M197.2,424.28h-4.74v-0.01h4.74V424.28z M202.63,426.42h-5.1v-0.01h5.1V426.42z M202.63,424.28h-5.1v-0.01h5.1V424.28z
					"
              />
              <linearGradient
                id="Card32MSVGID2"
                gradientUnits="userSpaceOnUse"
                x1="240.9143"
                y1="415.6183"
                x2="240.9143"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID2)"
                d="M239.54,415.62v5.34c0.22-0.11,0.45-0.19,0.69-0.24c-0.16-1.02,0.75-2.07,1.78-2.05
					c0.02-0.13,0.05-0.25,0.1-0.37h-2.55v-0.33h2.69c0.02-0.03,0.03-0.06,0.05-0.09v-2.25H239.54z"
              />
              <linearGradient
                id="Card32MSVGID3"
                gradientUnits="userSpaceOnUse"
                x1="244.0868"
                y1="415.6183"
                x2="244.0868"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID3)"
                d="M242.62,415.62v1.81c0.36-0.38,0.83-0.66,1.35-0.74c0.54-0.09,1.1,0.03,1.58,0.31v-1.37H242.62z"
              />
              <linearGradient
                id="Card32MSVGID4"
                gradientUnits="userSpaceOnUse"
                x1="246.6312"
                y1="415.6183"
                x2="246.6312"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID4)"
                d="M245.88,415.62v1.6c0.07,0.06,0.15,0.13,0.21,0.2c0.19-0.4,0.46-0.76,0.8-1.05
					c0.14-0.12,0.29-0.23,0.38-0.38c0.06-0.11,0.09-0.24,0.11-0.37H245.88z"
              />
              <linearGradient
                id="Card32MSVGID5"
                gradientUnits="userSpaceOnUse"
                x1="235.1384"
                y1="415.6183"
                x2="235.1384"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID5)"
                d="M236.51,415.62h-2.75v2.34h2.73v0.33h-2.73v3.34h0.91c0.29-0.32,0.69-0.55,1.11-0.61
					c0.25-0.03,0.49-0.02,0.73,0.03V415.62z"
              />
              <linearGradient
                id="Card32MSVGID6"
                gradientUnits="userSpaceOnUse"
                x1="228.799"
                y1="415.6183"
                x2="228.799"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID6)"
                d="M227.44,425.35v-0.33h2.74v-3.07h-2.74v-0.33h2.74v-3.34h-2.74v-0.33h2.74v-2.34h-2.76v11.08
					c0.92-0.2,1.8-0.42,2.65-0.65c0.04-0.04,0.07-0.08,0.11-0.12v-0.58H227.44z"
              />
              <linearGradient
                id="Card32MSVGID7"
                gradientUnits="userSpaceOnUse"
                x1="231.9716"
                y1="415.6183"
                x2="231.9716"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID7)"
                d="M230.51,421.95v3.07h0.64c0.55-0.44,1.14-0.82,1.77-1.13c0.04-0.32,0.24-0.59,0.52-0.75v-1.18
					H230.51z"
              />
              <linearGradient
                id="Card32MSVGID8"
                gradientUnits="userSpaceOnUse"
                x1="234.1017"
                y1="415.6183"
                x2="234.1017"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32MSVGID8)"
                d="M233.77,423.01c0.18-0.04,0.36-0.03,0.54,0.03c-0.12-0.37-0.05-0.75,0.14-1.08h-0.67V423.01z"
              />
              <linearGradient
                id="Card32MSVGID9"
                gradientUnits="userSpaceOnUse"
                x1="231.9716"
                y1="415.6183"
                x2="231.9716"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="230.51" y="415.62" fill="url(#Card32MSVGID9)" width="2.93" height="2.34" />
              <linearGradient
                id="Card32MSVGID10"
                gradientUnits="userSpaceOnUse"
                x1="231.9716"
                y1="415.6183"
                x2="231.9716"
                y2="428.101"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="230.51" y="418.29" fill="url(#Card32MSVGID10)" width="2.93" height="3.34" />
              <linearGradient
                id="Card32MSVGID11"
                gradientUnits="userSpaceOnUse"
                x1="194.8288"
                y1="422.3634"
                x2="194.8288"
                y2="429.2512"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID11)" d="M192.46,426.76v0.57c1.52,0.16,3.11,0.33,4.74,0.48v-1.04H192.46z" />
              <linearGradient
                id="Card32MSVGID12"
                gradientUnits="userSpaceOnUse"
                x1="200.0825"
                y1="422.3566"
                x2="200.0825"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="197.53" y="422.36" fill="url(#Card32MSVGID12)" width="5.1" height="1.92" />
              <linearGradient
                id="Card32MSVGID13"
                gradientUnits="userSpaceOnUse"
                x1="205.5139"
                y1="422.3566"
                x2="205.5139"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="202.96" y="424.61" fill="url(#Card32MSVGID13)" width="5.1" height="1.81" />
              <linearGradient
                id="Card32MSVGID14"
                gradientUnits="userSpaceOnUse"
                x1="210.9453"
                y1="422.3566"
                x2="210.9453"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="208.39" y="424.61" fill="url(#Card32MSVGID14)" width="5.1" height="1.81" />
              <linearGradient
                id="Card32MSVGID15"
                gradientUnits="userSpaceOnUse"
                x1="205.5139"
                y1="422.3566"
                x2="205.5139"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="202.96" y="422.36" fill="url(#Card32MSVGID15)" width="5.1" height="1.92" />
              <linearGradient
                id="Card32MSVGID16"
                gradientUnits="userSpaceOnUse"
                x1="200.0825"
                y1="422.3566"
                x2="200.0825"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="197.53" y="424.61" fill="url(#Card32MSVGID16)" width="5.1" height="1.81" />
              <linearGradient
                id="Card32MSVGID17"
                gradientUnits="userSpaceOnUse"
                x1="194.8288"
                y1="422.3566"
                x2="194.8288"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="192.46" y="424.61" fill="url(#Card32MSVGID17)" width="4.74" height="1.81" />
              <linearGradient
                id="Card32MSVGID18"
                gradientUnits="userSpaceOnUse"
                x1="200.0802"
                y1="422.3634"
                x2="200.0802"
                y2="429.2512"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID18)" d="M197.53,426.76v1.07c1.67,0.15,3.39,0.29,5.1,0.4v-1.47H197.53z" />
              <linearGradient
                id="Card32MSVGID19"
                gradientUnits="userSpaceOnUse"
                x1="205.5139"
                y1="422.3634"
                x2="205.5139"
                y2="429.2512"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID19)" d="M202.96,426.76v1.49c1.72,0.1,3.43,0.16,5.1,0.17v-1.65H202.96z" />
              <linearGradient
                id="Card32MSVGID20"
                gradientUnits="userSpaceOnUse"
                x1="210.9453"
                y1="422.3632"
                x2="210.9453"
                y2="429.2509"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID20)" d="M208.39,426.76v1.65c1.79,0,3.49-0.03,5.1-0.1v-1.56H208.39z" />
              <linearGradient
                id="Card32MSVGID21"
                gradientUnits="userSpaceOnUse"
                x1="194.8311"
                y1="422.3566"
                x2="194.8311"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="192.46" y="422.36" fill="url(#Card32MSVGID21)" width="4.74" height="1.92" />
              <linearGradient
                id="Card32MSVGID22"
                gradientUnits="userSpaceOnUse"
                x1="216.454"
                y1="422.3566"
                x2="216.454"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="213.83" y="422.36" fill="url(#Card32MSVGID22)" width="5.26" height="1.92" />
              <linearGradient
                id="Card32MSVGID23"
                gradientUnits="userSpaceOnUse"
                x1="210.9453"
                y1="422.3566"
                x2="210.9453"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="208.39" y="422.36" fill="url(#Card32MSVGID23)" width="5.1" height="1.92" />
              <linearGradient
                id="Card32MSVGID24"
                gradientUnits="userSpaceOnUse"
                x1="216.454"
                y1="422.3566"
                x2="216.454"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="213.83" y="424.61" fill="url(#Card32MSVGID24)" width="5.26" height="1.81" />
              <linearGradient
                id="Card32MSVGID25"
                gradientUnits="userSpaceOnUse"
                x1="216.454"
                y1="422.3566"
                x2="216.454"
                y2="429.2443"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32MSVGID25)" d="M213.83,426.75v1.54c1.86-0.08,3.61-0.19,5.26-0.35v-1.2H213.83z" />
              <path
                fill="#BED4D3"
                d="M210.28,420.09c0.65-0.33,1.54-0.05,1.88,0.6c0.28-0.22,0.59-0.4,0.95-0.44
					c0.63-0.08,1.47,0.5,1.27,0.79l0.39,0l4.99,0.01c0.04-0.53,0.08-1.09-0.16-1.57c-0.27-0.54-0.89-0.88-1.49-0.81
					c-0.12,0.01-0.27,0.04-0.36-0.05c-0.08-0.08-0.07-0.21-0.07-0.33c0.04-0.67-0.24-1.36-0.75-1.8c-0.5-0.45-1.22-0.65-1.88-0.53
					c-0.1,0.02-0.2,0.04-0.3,0.07c-0.24,0.07-0.47,0.15-0.72,0.21c-0.01,0-0.02,0.01-0.03,0.01c0-0.01-0.01-0.02-0.01-0.02
					c-0.27-0.59-0.87-0.97-1.51-1.07c-0.31-0.05-0.62-0.03-0.92,0.06c-0.92,0.27-1.6,1.14-1.8,2.08c-0.12,0.55-0.09,1.14-0.27,1.67
					C209.96,419.21,210.3,419.59,210.28,420.09z"
              />
              <path
                fill="#659896"
                d="M214.37,421.05C214.37,421.05,214.37,421.05,214.37,421.05c0.2-0.29-0.64-0.88-1.27-0.8
					c-0.35,0.05-0.67,0.22-0.95,0.44c-0.33-0.65-1.23-0.94-1.88-0.6c0.02-0.5-0.32-0.88-0.78-1.12c-0.31-0.16-0.67-0.25-1.01-0.27
					c-0.84-0.03-1.72,0.19-2.5-0.11c-0.64-0.24-1.12-0.8-1.75-1.06c-1.04-0.43-2.21,0.02-3.24,0.46c-0.32-0.36-0.36-0.9-0.65-1.29
					c-0.36-0.49-1.06-0.64-1.65-0.51c-0.6,0.13-1.11,0.49-1.6,0.86c-0.3-0.67-1.12-1-1.84-0.9s-1.36,0.57-1.83,1.13
					c-0.47,0.56-0.82,1.22-1.19,1.84c-0.06,0.09-0.12,0.19-0.22,0.23c-0.16,0.06-0.32-0.04-0.46-0.13
					c-0.68-0.44-1.48-0.75-2.28-0.67c-0.8,0.08-1.59,0.63-1.77,1.41c-0.42-0.36-1.06-0.45-1.56-0.21c-0.5,0.24-0.97,0.72-0.96,1.28
					v0.02L214.37,421.05z"
              />
              <path fill="#42637C" d="M239.55,418.29h2.55c0.04-0.11,0.09-0.22,0.14-0.33h-2.69V418.29z" />
              <path
                fill="#AEE7F5"
                d="M353.09,452.07v-4.01c0-1.84,0,0,0-1.84v-0.07c0,0-0.02-10.66,0-17.41c0,0-40.52,1.37-47.27,1.16
					c-29.83,0-64.64,0.53-73.12,0.53c-24.53,0-17.76-1.46-28.27-1.46c-7.87,0-8.04,0.93-9.86,0.93c-1.01,0-3.98-1.18-8.56-1.18
					c-5.55,0-17.37-0.38-33.4-0.38c-10.17,0-21.23,0.32-29.41,0.32c-8.64,0-17.37-1.2-19.84-1.57L88.67,427
					c0,0,28.31,3.73,56.57,38.28c21.8,26.64,31.94,34.72,38.36,39.19c59.71,41.58,140.61,22.87,169.49,13.99v-62.64
					C353.09,452.07,353.09,455.83,353.09,452.07z"
              />
              <circle fill="#FFFFFF" cx="189.76" cy="339.99" r="13.8" />
            </g>
            <path
              display="none"
              opacity="0.3"
              d="M215.96,424.25h-5.07h-23.25c0.25,0,0.45,0.2,0.45,0.45v5.5c0.37,0.04,0.75,0.08,1.13,0.12
				v-0.55h4.6v1.01c0.11,0.01,0.21,0.02,0.32,0.03v-1.04h4.95v1.43c0.11,0.01,0.21,0.01,0.32,0.02v-1.44h4.95v1.61
				c0.08,0,0.16,0,0.24,0c0.03,0,0.06,0,0.08,0v-1.61h4.95v1.51c0.11,0,0.21-0.01,0.32-0.01v-1.5h5.1v1.16
				c0.24-0.02,0.47-0.05,0.71-0.07c-0.15-0.65-0.14-1.4-0.14-2c0-1.36,0.42-3,0.75-4.42C216.28,424.32,216.13,424.25,215.96,424.25z
				 M193.83,429.44h-4.6v-1.76h4.6V429.44z M189.22,427.36v-1.87h4.6v1.87H189.22z M199.1,429.44h-4.95v-1.76h4.95V429.44z
				 M194.15,427.36v-1.87h4.95v1.87H194.15z M204.38,429.44h-4.95v-1.76h4.95V429.44z M199.42,427.36v-1.87h4.95v1.87H199.42z
				 M209.65,429.44h-4.95v-1.76h4.95V429.44z M204.7,427.36v-1.87h4.95v1.87H204.7z M209.97,429.44v-1.76h5.1v1.76H209.97z
				 M215.07,427.36h-5.1v-1.87h5.1V427.36z"
            />
            <path opacity="0.3" d="M184.84,422.28v4.31c1.54,0.1,3.37,0.28,5.37,0.49v-4.8H184.84z" />
            <path
              opacity="0.3"
              d="M225.08,414.27h0.02v12.89c0.8-0.14,1.58-0.29,2.32-0.46v-11.08h2.76v2.34h-2.74v0.33h2.74v3.34h-2.74
				v0.33h2.74v3.07h-2.74v0.33h2.74v0.58c0.11-0.11,0.22-0.23,0.33-0.34v-0.24h0.26c0.13-0.11,0.25-0.22,0.38-0.33h-0.64v-3.07h2.93
				v1.18c0.1-0.06,0.21-0.1,0.33-0.13v-1.05h0.67c0.06-0.12,0.14-0.23,0.23-0.33h-0.91v-3.34h2.73v-0.33h-2.73v-2.34h2.75v5.43
				c0.68,0.14,1.3,0.58,1.76,1.12c0.28-0.53,0.74-0.95,1.27-1.22v-5.34h2.76v2.25c0.09-0.16,0.2-0.31,0.33-0.44v-1.81h2.93V417
				c0.12,0.07,0.23,0.14,0.33,0.23v-1.6h1.5c0.01-0.05,0.02-0.1,0.03-0.15c0.16-0.73,0.86-1.3,1.61-1.31
				c-0.05-0.69,0.65-1.32,1.33-1.21c0.01-0.05,0.01-0.09,0.02-0.14l-0.01-4.06v0l0,0l-4.05,5.22v0.26l-0.02,0l-0.01-5.48v0l0,0
				l-4.19,5.39v0.09l-0.02,0l-0.01-5.48v0l0,0l-4.23,5.45v0.03l-0.02,0l-0.01-5.48v0l0,0l-4.23,5.45v0.03l-0.02,0l-0.01-5.48v0l0,0
				l-4.14,5.34v0.15l-0.02,0l-0.01-5.48v0l0,0L225.08,414.27z M233.44,421.63h-2.93v-3.34h2.93V421.63z M233.44,417.96h-2.93v-2.34
				h2.93V417.96z"
            />
          </g>
          <path
            fill="#1EBDE4"
            d="M232.5,445.35c-5.37,0-12.23,0-18.8,0s-1.7,2.35,4.9,2.35c0.3,0,0.53,0,0.71,0c0.01,0,0.15,0,0.53,0
			c0.57,0,1.67,0,3.71,0C235.48,447.69,237.87,445.35,232.5,445.35z"
          />
          <path
            fill="#1EBDE4"
            d="M239.68,458.11c8.54,0,90.66,0,105.78,0v-3.76c-14.73,0-93.81,0-104.39,0
			C229.42,454.35,230.28,458.11,239.68,458.11z"
          />
          <path
            fill="#1EBDE4"
            d="M271.57,450.34c7.85,0,61.84,0,73.9,0v-1.84c-11.65,0-62.87,0-72.58,0
			C261.91,448.49,262.72,450.34,271.57,450.34z"
          />
          <path
            fill="#1EBDE4"
            d="M164.09,430.38c1.95-0.39,3.51-0.76,4.83-1.09c-1.02,0-2.05,0-3.08,0c-26.04-0.01-56.55-0.04-70.79-0.04
			c0,0,0.1,0.02,0.28,0.04c0.11,0.02,0.24,0.04,0.41,0.06c2.47,0.37,11.2,1.57,19.84,1.57c8.18,0,19.07,1.14,29.24,1.14
			c16.03,0,28.38,0,33.92,0c4.57,0,11.02,1.45,6.52,1.45c-2,0-4.23,0.09-7.24,0.09c-7.59,0-7.31,2.08,0.56,2.08
			c10.51,0,26.6,0,51.13,0c8.47,0,5.36,2.05,2.25,2.05c-3.24,0-8.18,0.14-10.94,0.14c-4.38,0-8.97,1.31-6.2,2
			c1.66,0.41,8.85,0.36,12.24,0.36c4.66,0,36.53,0,66.36,0c13.49,0.43,7.84,2.22,1.22,2.22c-7.54,0-9.84,0.36-12.56,0.36
			c-2.72,0-6.49,2.66,3.77,2.66c5.17,0,20.86,0,35.16,0c5.15,0.51,3.9,2.8-2.37,2.94h26.82v-17.41l-76.56-0.63
			C268.91,430.38,163.49,430.5,164.09,430.38z M201.09,433.55c-5.09,0-6.71-0.59,1.16-0.59
			C208.95,432.95,206.18,433.55,201.09,433.55z M209.52,434.59c-2.02,0-2.66-0.37,0.46-0.37
			C212.64,434.22,211.54,434.59,209.52,434.59z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g>
          <g>
            <path
              fill="none"
              d="M165.1,440.82c0,0.13-0.01,0.26-0.01,0.4c0.27-0.32,0.55-0.66,0.85-0.99c-0.26,0.07-0.54,0.14-0.83,0.22
				C165.11,440.57,165.11,440.69,165.1,440.82z"
            />
            <path
              fill="none"
              d="M165.49,511.24c-0.47-0.29-0.94-0.58-1.39-0.88c0.72,0.57,1.46,1.14,2.22,1.71
				C166.03,511.79,165.75,511.51,165.49,511.24z"
            />
            <path
              fill="none"
              d="M170.46,444.6c0.67,1.17,1.35,2.49,1.54,2.86c0.92,0.57,1.76,0.87,2.57,1.12c-1.23-2.23-3.11-3.12-3.43-5.25
				C171.15,443.34,170.94,443.81,170.46,444.6z"
            />
            <path
              fill="none"
              d="M106.77,341.04c-0.14,1.29-0.28,2.63-0.42,4.01C106.53,343.85,106.67,342.68,106.77,341.04z"
            />
            <path
              fill="none"
              d="M116.56,471.85c-4.24-0.63-8.29-1.17-11.52-1.94C107.58,470.64,111.17,471.3,116.56,471.85z"
            />
            <path
              fill="none"
              d="M136.55,477.56c1.07,0.85,2.08,1.81,3.15,2.9C138.87,479.35,137.8,478.4,136.55,477.56z"
            />
            <path
              fill="#1EBDE4"
              d="M224.6,511.08c9.89,1.34,19.63,2.44,28.9,3.26c-10.82-1.33-23.92-3.75-33.24-8.1
				c-11.41-5.32-8.35-14.4-4.43-20.67c0,0-0.02,0.02-0.05,0.06c-1.73,2.92-5.1,6.27-8.33,11.17
				C202.75,503.93,209.45,508.24,224.6,511.08z"
            />
            <path
              fill="#659896"
              d="M81.72,446.39c9.58,1.81,9.76,12.56,16.82,19.73c1.41,1.43,2.83,2.7,6.51,3.78
				c3.22,0.78,7.28,1.32,11.52,1.94c0.32,0.03,0.64,0.06,0.97,0.1c10.73,1.03,15.23,2.61,19.01,5.62c1.25,0.83,2.32,1.79,3.15,2.9
				c0.12,0.12,0.24,0.25,0.37,0.37c3.49,3.58,8.08,16.82,24.02,29.51c0.46,0.3,0.92,0.59,1.39,0.88c-15-15.77,7.13-35.01-5.56-46.33
				c-1.06-1.25-1.53-2.94-1.76-4.59c0-0.03-0.05-0.03-0.06,0c-0.08,0.7-0.14,1.41-0.18,2.12c0,0.03-0.04,0.04-0.05,0.01
				c-0.6-0.98-0.9-2.1-1.18-3.22c-0.01-0.03-0.05-0.03-0.06,0c-0.08,0.78-0.12,1.56-0.11,2.35c0,0.03-0.05,0.04-0.05,0.01
				c-0.54-1.89-1.19-2.86-1.33-2.79c-0.06,0.03,0.4,1.12,0.41,1.92c0,0.03-0.03,0.04-0.05,0.02c-0.53-0.48-0.82-1.37-0.96-1.96
				c-0.01-0.03-0.06-0.03-0.06,0.01c-0.02,0.79,0.08,1.58,0.29,2.35c0.01,0.03-0.03,0.05-0.05,0.03c-0.4-0.52-0.73-1.08-0.99-1.68
				c-0.01-0.03-0.05-0.02-0.05,0.01c-0.03,0.61,0.06,1.21,0.25,1.79c-2.32-0.99-5.12-1.88-8.51-2.65
				c-11.23-2.55-18.9-6.13-23.86-10.2c-0.29-0.51-0.45-1.11-0.53-1.69c-0.14-1.01-0.08-2.05-0.33-3.03c-0.01-0.04-0.06-0.04-0.07,0
				c-0.23,0.82-0.37,1.66-0.44,2.51c0,0.04-0.06,0.05-0.07,0.01c-0.23-0.8-0.41-1.62-0.51-2.44c-0.01-0.04-0.07-0.04-0.07,0
				l-0.11,1.19c0,0.04-0.06,0.05-0.07,0.01c-0.39-0.95-0.57-1.98-0.54-3c0-0.04-0.06-0.05-0.07-0.01c-0.26,0.9-0.33,1.86-0.21,2.79
				c0.01,0.04-0.06,0.06-0.07,0.02c-0.27-0.72-0.49-1.47-0.65-2.23c-0.01-0.04-0.07-0.04-0.07,0.01l0.04,1.22
				c0,0.04-0.05,0.05-0.07,0.02c-0.46-0.67-0.72-1.48-0.76-2.29c0-0.04-0.06-0.05-0.07-0.01c-0.13,0.58-0.14,1.18-0.07,1.77
				c-2.98-4.47-3.49-9.21-2.47-13.64c3.72-16.19-14.01-16.42-5.77-16.22c1.97,0.05,3.86,0.17,5.67,0.34
				c6,0.77,10.52,1.86,13.73,2.86c1.31,0.44,2.56,0.91,3.77,1.41c0.01-0.02,0.01-0.04,0.02-0.07c1.71,0.7,2.51,1.18,2.51,1.18
				c-0.35-1.83-0.95-3.25-1.67-4.35c1.36-5.8,1.99-12.1,1.86-16.97c-0.26-9.24-0.99-22.37-4.39-36.52c-2.13-8.86-2.23-15.43-3-19.85
				c-0.46-2.65-0.46-4.5-0.92-1.85c-0.46,2.65-3.4,6.4-3.23,15.35c0.12,6.39-0.94,8.93-1.66,13.85c-0.24-2.77-0.52-5.3-0.85-7.47
				c-2.48-16.42-3.24-16.6-5.24-28.74c-2.52-15.31-1.79-17.96-2.9-19.22c-1.31,2.32-3.68,11.35-5.48,27.67
				c-0.1,1.64-0.23,2.82-0.42,4.01c-1.27,13.05-2.16,30.18-1.99,51.62c-0.18,0.01-0.36,0.02-0.54,0.03c0,0.02,0-0.02,0,0
				c-0.86,0.04-1.78,0.28-2.77,0.59c-7.02,2.21-6.18,10.41-6.18,10.41s-3.86,0.07-5.14,5.09c0,0-5.96-2.41-11.39,2.7
				c-3.1,2.92-3.53,8-3.53,8s-4.86,1.5-6.28,9.63c-5.89,6.95-11.75,14.88-20.18,22.29c-0.41,0.36-1.31,1.54,2.52,0.63
				C72.39,449.58,73.99,444.93,81.72,446.39z"
            />
            <path
              fill="#E0DAC7"
              d="M182.69,462.01c0.02,0.07,0.05,0.14,0.07,0.22c0-0.18,0-0.31-0.09-0.4c0,0.01-0.01,0.02-0.01,0.02
				C182.65,461.91,182.67,461.96,182.69,462.01z"
            />
            <path
              fill="#E0DAC7"
              d="M182.91,462.61c-0.01-0.01-0.02-0.02-0.04-0.03c0,0.01,0.01,0.02,0.01,0.02
				C182.89,462.61,182.9,462.61,182.91,462.61z"
            />
            <path
              fill="#E0DAC7"
              d="M182.39,462.91c0.02-0.01,0.03-0.02,0.04-0.03c0.01-0.02,0.02-0.03,0.03-0.05
				c-0.04,0-0.07-0.01-0.11-0.02C182.37,462.84,182.38,462.88,182.39,462.91z"
            />
            <polygon fill="#E0DAC7" points="182.41,462.43 182.41,462.43 182.41,462.43 			" />
            <path
              fill="#E0DAC7"
              d="M182.08,461.93c-0.05-0.03-0.1-0.05-0.16-0.08c-0.03-0.02-0.05-0.04-0.08-0.06
				c0.01,0.04,0.02,0.07,0.04,0.1C181.94,461.96,182.02,461.97,182.08,461.93z"
            />
            <path
              fill="#1C3B4A"
              d="M130.29,441.13c0,0-0.23-1.09-0.73-2.37c-0.49-1.24-1.22-2.65-2.25-3.41c-2.12-1.57,0.01,0.68,0.67,3.4
				c0.09,0.38,0.16,0.76,0.18,1.14L130.29,441.13z"
            />
            <path
              fill="#42637C"
              d="M156.19,439.95c0.35,2.5-0.47,3.9-0.74,5.62c-0.23,0.93-0.24,1.85,0.11,2.69c0,0,0,0,0,0.01
				c1.36,0.62,3.86,1.79,6.4,3.09c0.11,0.93,0.2,1.51,0.49,2.15c0.02,0.04,0.04,0.09,0.06,0.13c0.23,0.46,0.87,0.29,1.3,0.11
				c0.05-0.02,0.09-0.05,0.13-0.07c0.16-0.12,0.26-0.3,0.25-0.5c-0.01-0.19-0.02-0.41-0.01-0.64c0-0.4,0.03-0.86,0.06-1.37
				c0.63-0.26,1.24-0.67,1.91-1.26c0.01,0.01,0.01,0.01,0.01,0.01c0.41-0.35,0.77-0.66,1.08-0.92c0.03-0.04,0.06-0.08,0.1-0.11
				c0.51-0.48,1.17-1.57,2.13-1.8c1.11-0.27,2.49,0.37,2.48,0.36c0.01,0.01,0.03,0.02,0.04,0.02c-0.19-0.37-0.87-1.7-1.54-2.86
				c0.48-0.79,0.69-1.27,0.69-1.27c0.32,2.13,2.2,3.02,3.43,5.25c1.02,0.32,2,0.56,3.04,1.2c0.04,0.02,0.08,0.03,0.11,0.04
				c-1.54-2.4-2.54-8.97-4.54-11.78c-0.51-0.72-1.09-1.19-1.75-1.28c-0.84-0.11-1.71,0.21-2.57,0.77c-0.04-0.02-0.09-0.04-0.14-0.05
				c-0.95-0.21-2.23-0.13-3.58,0.01c-0.02-2.06-0.18-2.67-0.67-3.38c0.01-0.01,0.01-0.02,0.01-0.02c-0.07-0.08-0.13-0.16-0.19-0.23
				c-0.07-0.09-0.14-0.19-0.22-0.29c-0.01-0.02-0.03-0.03-0.04-0.05c-0.38-0.49-0.72-0.93-1.38-1.41c-0.05,0.09-0.1,0.19-0.15,0.29
				c-0.11,0.3-0.24,0.67-0.35,1.24c-0.22,1.06-0.1,1.88-0.1,1.88s-0.26-0.99,0.21-2.61c-0.06,0.16-0.75,2.11-0.5,3.14
				c0,0-0.14-0.19-0.21-0.61c-0.03,0.39-0.01,0.65-0.01,0.65s-0.18,0-0.47-0.03c0-0.25,0.01-1.11,0.28-1.9c0,0-0.4,0.7-0.39,1.89
				c-0.13-0.01-0.28-0.03-0.44-0.06c-1.01-0.17-2.51-0.65-3.26-1.95c-0.89-0.95-0.54-2.58,0.6-3.04c0.42-0.17,0.68-0.42,0.84-0.72
				c-1.47-0.32-2.6,0.1-3.06,2.17c-0.22,0.97-0.3,1.61-0.29,2.21C155.39,435.87,155.77,436.89,156.19,439.95z M165.09,441.22
				c0-0.13,0.01-0.27,0.01-0.4c0-0.13,0-0.25,0.01-0.37c0.29-0.08,0.57-0.15,0.83-0.22C165.65,440.56,165.36,440.89,165.09,441.22z
				 M169.71,438.31c0.59,0.4,0.59,0.58,0.71,0.98c0.12,0.4,0.32,0.84,0.3,1.05c-0.01,0.14-0.11,0.3-0.17,0.39
				c0.01,0.23-0.04,0.43-0.18,0.63c0,0-0.42,0.52-0.46,0.33s0.21-0.56,0.15-0.83c-0.06-0.25-0.42-1.48-0.37-1.21
				c0.04,0.27,0.25,0.83,0.21,1.12c-0.03,0.18-0.04,0.52-0.07,0.82c-0.02,0.18-0.04,0.36-0.08,0.49c-0.1,0.35-0.34,0.14-0.36,0.05
				c-0.03-0.17,0-0.09-0.02-0.53c-0.02-0.44-0.21-1.96-0.21-1.96s0.08,0.71,0.1,1.25c0.02,0.54-0.07,1.01-0.17,1.09
				c-0.08,0.06-0.17,0.12-0.22-0.07c0-0.01,0-0.02,0-0.03c-0.05-0.31-0.01-0.82-0.05-1.06c-0.03-0.18-0.07-0.73-0.11-1.09
				c-0.02-0.31-0.05-0.47-0.15-0.29c-0.15,0.27-0.12,0.79-0.12,1.23s-0.4,0.71-0.35,0.35c0.04-0.35,0-0.65-0.17-1.06
				s0-0.96,0.04-1.29s0.46-1.04,0.96-1C169.27,437.73,169.12,437.91,169.71,438.31z"
            />
            <path
              fill="#AC7D61"
              d="M177.81,449.91c-0.01-0.02-0.02-0.04-0.03-0.05c0,0-0.02-0.01-0.06-0.02c-0.03-0.01-0.06-0.03-0.11-0.04
				C177.68,449.82,177.74,449.86,177.81,449.91z"
            />
            <path
              fill="#AC7D61"
              d="M183.36,452.28C183.37,452.28,183.37,452.29,183.36,452.28c0-0.03,0-0.06,0.01-0.1
				C183.36,452.22,183.37,452.25,183.36,452.28z"
            />
            <path
              fill="#1EBDE4"
              d="M164.21,453.19c0.01,0.2-0.09,0.38-0.25,0.5c0.06,0.17,0.12,0.29,0.18,0.38
				c0.12,0.19,0.22,0.22,0.31,0.42c0.09,0.2,0.23,0.48,0.48,0.56c0.47,0.15,0.79,0.11,0.57,0.27c-0.21,0.16-0.55,0.17-1.01,0.05
				c-0.46-0.11-0.57-0.23-0.75-0.15c-0.33,0.24-0.38,0.41-0.48,0.76c-0.15,0.54-0.16,0.8-0.39,1.33c-0.07,0.16-0.09,0.46-0.3,0.43
				c-0.29-0.06-0.04-0.49-0.01-0.64c0.15-0.62,0.17-0.8,0.32-1.42c-0.37,0.17-0.41,1.21-1.05,2.05c-0.08,0.04-0.27,0-0.28-0.14
				c-0.02-0.14,0.14-0.52,0.19-0.66c0.29-0.79,0.29-0.75,0.58-1.53c-0.32,0.55-0.5,0.68-0.82,1.24c-0.14,0.25-0.28,0.59-0.51,0.45
				c-0.15-0.18,0.04-0.46,0.14-0.62c0.34-0.59,0.29-0.51,0.63-1.1c-0.44,0.53-0.91,1.27-1.12,0.88c-0.08-0.24,0.83-0.93,1.25-1.72
				c0.13-0.25,0.2-0.51,0.36-0.75c0.06-0.09,0.14-0.18,0.23-0.26c-0.3-0.64-0.38-1.22-0.49-2.15c-2.54-1.3-5.04-2.47-6.4-3.09
				c0,0,0,0,0-0.01c-7.42-3.38-13.15-5.62-19.36-7.84c-2.91-1.04-5.08-1.53-6.65-1.67c0.5,1.28,0.73,2.37,0.73,2.37l-2.13-1.24
				c-0.02-0.38-0.08-0.77-0.18-1.14c-1,0.1-1.53,0.43-1.68,0.84c-0.01,0-0.01-0.01-0.01-0.01c-0.49,0.67-0.26,2.84,3.69,5.8
				c2.96,2.22,9.46,5.41,18.41,9.24c6.27,1.34,11.57,2.63,15.61,4.96c1.96,1.13,3.84,2.4,5.65,3.6c0.12,0.05,0.24,0.09,0.37,0.14
				c6.88,2.64,9.27,2.62,9.33,1.6c0.02-0.11,0.01-0.25-0.04-0.42c-1.09-3.67-8.51-8.37-14.73-11.75c-0.13-0.07-0.25-0.14-0.38-0.21
				C164.19,452.78,164.19,452.99,164.21,453.19z"
            />
            <path
              fill="#AC7D61"
              d="M162.23,453.78c-0.15,0.24-0.23,0.5-0.36,0.75c-0.42,0.79-1.32,1.48-1.25,1.72
				c0.21,0.39,0.67-0.35,1.12-0.88c-0.34,0.59-0.29,0.51-0.63,1.1c-0.09,0.16-0.29,0.44-0.14,0.62c0.23,0.14,0.37-0.2,0.51-0.45
				c0.32-0.55,0.5-0.68,0.82-1.24c-0.29,0.79-0.29,0.75-0.58,1.53c-0.05,0.14-0.21,0.52-0.19,0.66c0.02,0.14,0.2,0.18,0.28,0.14
				c0.64-0.84,0.68-1.88,1.05-2.05c-0.15,0.62-0.17,0.8-0.32,1.42c-0.04,0.15-0.28,0.58,0.01,0.64c0.21,0.03,0.24-0.27,0.3-0.43
				c0.23-0.53,0.24-0.78,0.39-1.33c0.09-0.35,0.14-0.51,0.48-0.76c0.17-0.08,0.29,0.04,0.75,0.15c0.46,0.12,0.81,0.11,1.01-0.05
				c0.21-0.16-0.11-0.12-0.57-0.27c-0.25-0.08-0.39-0.36-0.48-0.56c-0.09-0.21-0.19-0.24-0.31-0.42c-0.06-0.09-0.12-0.2-0.18-0.38
				c-0.04,0.03-0.08,0.05-0.13,0.07c-0.43,0.18-1.07,0.35-1.3-0.11c-0.02-0.04-0.04-0.09-0.06-0.13
				C162.37,453.59,162.29,453.68,162.23,453.78z"
            />
            <path
              fill="#AC7D61"
              d="M164.51,427.91c-0.06,0.11-0.12,0.21-0.17,0.31c-0.13-0.22-0.3-0.23-0.41-0.11
				c-0.09,0.11-0.17,0.7-0.01,1.19c-0.01,0.07-0.02,0.15-0.03,0.23c-0.07,1.03-0.52,1.52-0.95,2.14c0.85,0.04,1.16-0.26,1.31-0.96
				c0.1-0.44,0.34-0.8,0.4-1.24c0.06-0.44-0.01-0.58,0.16-1.37c0.06-0.28,0.08-0.48,0.07-0.65
				C164.8,427.61,164.67,427.77,164.51,427.91z"
            />
            <path
              fill="#AC7D61"
              d="M168.91,437.7c-0.5-0.04-0.92,0.67-0.96,1s-0.21,0.87-0.04,1.29s0.21,0.71,0.17,1.06
				c-0.04,0.35,0.35,0.08,0.35-0.35s-0.02-0.96,0.12-1.23c0.1-0.18,0.13-0.02,0.15,0.29c0.04,0.35,0.07,0.9,0.11,1.09
				c0.05,0.24,0,0.76,0.05,1.06c0,0.01,0,0.02,0,0.03c0.06,0.19,0.14,0.13,0.22,0.07c0.1-0.08,0.19-0.55,0.17-1.09
				c-0.02-0.54-0.1-1.25-0.1-1.25s0.19,1.52,0.21,1.96c0.02,0.44-0.01,0.36,0.02,0.53c0.02,0.1,0.25,0.31,0.36-0.05
				c0.04-0.13,0.06-0.31,0.08-0.49c0.03-0.31,0.04-0.64,0.07-0.82c0.04-0.29-0.17-0.85-0.21-1.12c-0.04-0.27,0.32,0.96,0.37,1.21
				c0.06,0.27-0.19,0.65-0.15,0.83s0.46-0.33,0.46-0.33c0.14-0.2,0.19-0.4,0.18-0.63c0.06-0.09,0.15-0.25,0.17-0.39
				c0.02-0.21-0.17-0.64-0.3-1.05c-0.12-0.4-0.12-0.58-0.71-0.98S169.27,437.73,168.91,437.7z"
            />
            <path
              fill="#4D2C1B"
              d="M157.87,431.02c-1.14,0.46-1.49,2.09-0.6,3.04c0.75,1.3,2.25,1.77,3.26,1.95
				c0.16,0.03,0.31,0.05,0.44,0.06c-0.01-1.19,0.39-1.89,0.39-1.89c-0.28,0.79-0.29,1.64-0.28,1.9c0.29,0.03,0.47,0.03,0.47,0.03
				s-0.01-0.26,0.01-0.65c0.07,0.42,0.21,0.61,0.21,0.61c-0.25-1.03,0.44-2.98,0.5-3.14c-0.47,1.61-0.21,2.61-0.21,2.61
				s-0.11-0.82,0.1-1.88c0.12-0.58,0.24-0.95,0.35-1.24c0.05-0.1,0.1-0.2,0.15-0.29c0.09-0.17,0.19-0.32,0.29-0.46
				c0.43-0.62,0.88-1.11,0.95-2.14c0.01-0.08,0.01-0.15,0.03-0.23c-0.16-0.48-0.09-1.07,0.01-1.19c0.11-0.13,0.28-0.11,0.41,0.11
				c0.05-0.1,0.11-0.2,0.17-0.31c0.17-0.14,0.29-0.3,0.39-0.46c0.38-0.65,0.22-1.4,0.04-1.85c-0.01-0.03-0.02-0.06-0.03-0.09
				c0,0-0.41-1.14-1.58-1.51c-0.42-0.13-0.65-0.23-1.29-0.22c-2.56,0.01-3.51,1.91-3.19,4.71l0,0c0,0.01,0,0.03,0.01,0.04
				c0.02,0.13,0.03,0.27,0.06,0.4c0.05,0.48,0.02,0.96-0.2,1.36C158.55,430.6,158.28,430.85,157.87,431.02z"
            />
            <path
              fill="#E0DAC7"
              d="M294.89,516.12c-6.56,0.15-14.13,0-22.38-0.42c-1.57-0.15-3.21-0.28-4.93-0.38
				c-3.16-0.18-6.23-0.36-9.19-0.56c-1.62-0.13-3.25-0.27-4.89-0.42c-9.27-0.83-19.01-1.92-28.9-3.26
				c-15.15-2.84-21.84-7.15-17.15-14.27c3.23-4.9,6.61-8.25,8.33-11.17c1.37-2.31,1.7-4.35,0.11-6.68
				c-2.61-4.97-9.46-11.42-14.85-13.39c-4.39-1.6-5.58-1.19-7.05-1.69c-0.25-0.16-0.49-0.32-0.74-0.49c0,0.01,0,0.02,0.01,0.03
				c-7.7-4.92-10.79-8.22-11.53-9.08c-0.54-0.83-1.11-1.69-1.94-2.65c-0.7-0.81-1.35-1.37-1.97-1.78c-0.06-0.04-0.13-0.08-0.19-0.12
				c-1.04-0.63-2.01-0.88-3.04-1.2c-0.81-0.25-1.65-0.55-2.57-1.12c-0.01-0.01-0.03-0.01-0.04-0.02c0.01,0.01-1.37-0.63-2.48-0.36
				c-0.96,0.23-1.62,1.33-2.13,1.8c-0.04,0.04-0.07,0.07-0.1,0.11c-0.31,0.27-0.67,0.57-1.08,0.92c0,0-0.01-0.01-0.01-0.01
				c-0.66,0.59-1.28,1-1.91,1.26c-0.04,0.51-0.06,0.97-0.06,1.37c0.13,0.07,0.25,0.14,0.38,0.21c6.22,3.38,13.01,7.85,14.27,10.78
				c0.07,0.16,0.06,1.23,0.04,1.34c-0.07,1.02-2,1.08-8.88-1.56c-0.12-0.05-0.24-0.09-0.37-0.14c-1.81-1.2-3.7-2.46-5.65-3.6
				c-4.03-2.33-9.34-3.63-15.61-4.96c-8.94-3.82-15.45-7.02-18.41-9.24c-3.95-2.96-4.17-5.13-3.69-5.8c0,0,0.01,0,0.01,0.01
				c0.15-0.42,0.68-0.75,1.68-0.84c-0.66-2.71-2.79-4.96-0.67-3.4c1.02,0.76,1.76,2.17,2.25,3.41c1.57,0.14,3.74,0.63,6.65,1.67
				c6.21,2.22,11.95,4.46,19.36,7.84c-0.35-0.84-0.35-1.76-0.11-2.69c0.27-1.73,1.09-3.13,0.74-5.62c-0.43-3.06-0.81-4.08-0.83-5.27
				c-6.32-5.81-13.32-12.37-23.62-16.66c-1.21-0.5-2.47-0.98-3.77-1.41c-3.21-0.99-7.73-2.08-13.73-2.86
				c-1.81-0.18-3.7-0.3-5.67-0.34c-8.25-0.2,9.49,0.02,5.77,16.22c-1.02,4.43-0.5,9.18,2.47,13.64c-0.07-0.59-0.06-1.19,0.07-1.77
				c0.01-0.04,0.07-0.04,0.07,0.01c0.04,0.81,0.3,1.62,0.76,2.29c0.02,0.03,0.07,0.01,0.07-0.02l-0.04-1.22
				c0-0.04,0.06-0.05,0.07-0.01c0.16,0.76,0.38,1.5,0.65,2.23c0.02,0.04,0.08,0.03,0.07-0.02c-0.12-0.93-0.05-1.89,0.21-2.79
				c0.01-0.04,0.07-0.03,0.07,0.01c-0.04,1.02,0.15,2.06,0.54,3c0.01,0.04,0.07,0.03,0.07-0.01l0.11-1.19c0-0.04,0.07-0.04,0.07,0
				c0.11,0.83,0.28,1.64,0.51,2.44c0.01,0.04,0.07,0.03,0.07-0.01c0.07-0.85,0.22-1.69,0.44-2.51c0.01-0.04,0.06-0.04,0.07,0
				c0.25,0.98,0.2,2.02,0.33,3.03c0.08,0.58,0.24,1.18,0.53,1.69c4.96,4.07,12.63,7.65,23.86,10.2c3.38,0.77,6.19,1.66,8.51,2.65
				c-0.19-0.58-0.28-1.18-0.25-1.79c0-0.03,0.04-0.04,0.05-0.01c0.26,0.6,0.59,1.16,0.99,1.68c0.02,0.02,0.06,0,0.05-0.03
				c-0.21-0.76-0.31-1.55-0.29-2.35c0-0.03,0.05-0.04,0.06-0.01c0.14,0.6,0.44,1.49,0.96,1.96c0.02,0.02,0.05,0,0.05-0.02
				c-0.02-0.8-0.48-1.89-0.41-1.92c0.14-0.06,0.79,0.9,1.33,2.79c0.01,0.03,0.05,0.02,0.05-0.01c-0.01-0.78,0.03-1.57,0.11-2.35
				c0-0.03,0.05-0.03,0.06,0c0.28,1.11,0.58,2.24,1.18,3.22c0.01,0.02,0.05,0.01,0.05-0.01c0.03-0.71,0.09-1.42,0.18-2.12
				c0-0.03,0.05-0.03,0.06,0c0.24,1.65,0.7,3.34,1.76,4.59c12.69,11.32-9.44,30.57,5.56,46.33c0.26,0.28,0.54,0.55,0.83,0.83
				c3.79,2.82,8.17,5.59,13.26,8.2l-0.11-0.19c6.24,3.24,36.98,15.71,83.3,15.71c71.89,0,89.6-13.35,93.96-19.68
				C351.34,516.05,315.63,515.65,294.89,516.12z"
            />
            <path
              opacity="0.3"
              d="M138.19,439.81c-0.74,0.13-0.46,1.08-0.13,1.31l3.35,1.23c0,0,3.07,2.18,4.3,3.07
				c1.23,0.89,10.78,11.08,10.95,11.19c0.17,0.11,1.59,0.42,1.78,0.58c0.37,0.31,7.14,7.2,7.48,8.04c0.33,0.84-0.61,0.56-0.33,1.23
				s1.56,0.33,2.68,0.17c1.12-0.17,0.95-1,0.11-1s-1.28-1.34-1.28-1.34l-17.91-16.85l-5.36-4.69L138.19,439.81z"
            />
            <path
              fill="#42637C"
              d="M147.6,445.39c-0.39-0.38-0.67-0.82-0.95-1.66c-0.28-0.84-1.63-3.85-2.82-5.06s-2.69-1.25-4.04-1.03
				c-1.35,0.22-3.21,0.77-1.6,2.17s6.25,4.17,7.48,5.01c1.23,0.84,2,1.62,2.77,2.4c0.77,0.78,8.82,8.39,10.38,9.93
				c1.56,1.54,8.74,8.23,8.74,8.23s-0.79,0.16-0.52,0.52s0.62,0.2,1.31,0.08c0.69-0.12,1.35-0.3,1.06-0.63
				c-0.29-0.33-0.91,0.09-1.07-0.15C168.34,465.19,148.66,446.42,147.6,445.39z"
            />
          </g>
          <path
            opacity="0.3"
            d="M121.82,405.3c2.57,1.53,3.57,4.48,3.66,5.73c0,0,1.5-0.11,3.28,0.6c0.54-4.31,0.89-10.4,0.5-18.53
			c-0.41-8.43-3.16-13.74-3.16-18.8c0-5.06,2.31-9.16,1.47-15.43c-0.83-6.15-1.64-17.34-0.98-20.38c0.01-0.06,0.02-0.11,0.04-0.16
			c0,0-0.02,0.05-0.05,0.13c-0.08,0.24-0.28,0.8-0.54,1.52c-0.44,1.2-1.05,2.85-1.64,4.18c-0.47,1.07-0.85,2.77-1.12,4.83
			c-0.23,1.79-0.37,3.85-0.39,6.03c-0.01,0.51,0,1.02,0,1.54c0.01,0.45,0.02,0.9,0.03,1.35c0.12,3.4-0.55,5.55-1.26,8.25
			c-0.17,0.64-0.34,1.32-0.5,2.04C122.19,379.3,122.44,394.41,121.82,405.3z"
          />
          <path
            opacity="0.3"
            d="M166.4,461.06c2.57,1.64,5,3.36,7.29,4.67c3.08,1.26,4.73,0.33,5.08-0.24c0.22-0.33,0.05-1.68,0.07-1.95
			C178.48,464.84,173.22,463.67,166.4,461.06z"
          />
          <path
            opacity="0.3"
            d="M130.29,441.13l-2.13-1.24l-1.24,1.21c-0.32-0.39-0.6-0.75-0.65-1.14c-0.02-0.13,0-0.25,0.04-0.37
			c-0.01,0-0.01-0.01-0.01-0.01c-0.08,0.11-0.14,0.27-0.17,0.47c-1.58,1.23,1.47,7.54,10.52,11.81c1.59,0.5,3.29,0.96,5.07,1.35
			c3.69,0.79,8.1,1.72,11.25,2.45c-11.36-4.7-20.91-9.67-24.58-13.18L130.29,441.13z"
          />
          <g>
            <path
              opacity="0.3"
              d="M132.38,418.22c-0.35-1.4-0.9-4.48-3.57-5.77c-4.41-2.12-8.25,1.67-7.65,0.34
				c0.44-0.97,1.17-1.62,1.53-1.9c0.13-0.1,0.14-0.26,0.08-0.41c-0.61-1.57-1.99-3.89-5.55-3.87c-3.88,0.02-5.92,3.96-5.92,3.96
				c0.57-2.5,1.74-3.96,2.2-4.46c0,0.01,0,0.02,0.01,0.02c0.84-0.87,1.97-1.53,3.51-1.72c1.3-0.17,2.42-0.05,3.38,0.24
				c-0.02-10.37-1.22-23.42-2.28-33.73c-1.72-16.83-4.76-35.47-5.44-40.72c-1.08-8.35-0.55-14.31-0.32-16.71c0-0.01,0-0.02,0-0.03
				c-0.01-0.01-0.02-0.01-0.04-0.02c-0.03-0.01-0.06-0.03-0.1-0.03c-1.36,2.47-3.81,12-5.62,29.19c-0.04,0.39-0.08,0.79-0.12,1.2
				c-1.34,13.2-2.29,30.73-2.11,52.87c8.24-0.47,8.98,4.77,9.04,4.94c0.04,0.11-1.72-2.4-5.22-3.17c-1.85-0.41-4.47-0.35-6.84,1.19
				c-4.18,2.72-3.79,7.95-3.79,7.95c2.76,0.35,4.29,1.67,5.16,2.5c0.08,0.07-0.02,0.19-0.11,0.13c-2.22-1.49-4.49-1.59-5.7-1.5
				c-6.11,0.63-7.31,6.26-7.47,7.19c-9.31,3.92-16.17,11.33-18.88,14.83c-1.26,1.63-3.55,4.36-6.17,7.4
				c-4.24,4.92-9.32,10.64-12.22,13.74c-0.96,1.03-1.68,1.77-2.05,2.1c-1.2,1.07-2.19,1.82-2.15,2.15c0,0.04,0.03,0.08,0.07,0.11
				c0.22,0.14,0.93,0.1,2.37-0.16c0.5-0.09,1.13-0.24,1.85-0.44c20.22-6.25,21.91-10.66,29.47-9.24
				c9.58,1.81,9.76,12.56,16.82,19.73c2.43,2.47,4.9,4.47,19,5.82c-20.34-8.94-13.44-18.43-17.25-26.22
				c-3.82-7.79-4.68-11.09,3.74-16.97c8.43-5.88,5.45-14.85-0.15-15.32c-0.07-0.01-0.15-0.01-0.22-0.02c3.33-0.07,4.91,0,4.91,0
				c10.41,0.28,19.38,3.2,22.58,4.36c0.23,0.08,0.44,0.16,0.61,0.23c0.42,0.16,0.64,0.25,0.64,0.25
				C132.39,418.25,132.39,418.23,132.38,418.22z"
            />
          </g>
          <path
            opacity="0.3"
            d="M108.57,413.43C108.57,413.43,108.57,413.43,108.57,413.43C108.57,413.43,108.57,413.43,108.57,413.43z"
          />
          <path
            opacity="0.3"
            d="M108.57,413.42C108.57,413.42,108.57,413.43,108.57,413.42C108.57,413.42,108.57,413.42,108.57,413.42z"
          />
          <path opacity="0.3" d="M108.56,413.45C108.56,413.45,108.56,413.45,108.56,413.45L108.56,413.45z" />
          <path opacity="0.3" d="M108.56,413.45c0,0,0-0.01,0.01-0.02C108.56,413.44,108.56,413.45,108.56,413.45z" />
          <path
            opacity="0.3"
            d="M155.56,436.26c0.04,0.22,0.08,0.43,0.12,0.64c0.12,0.58,0.28,1.14,0.45,1.67
			c0.84,2.64,0.34,4.97-0.18,6.85c-0.5,1.81-0.3,2.05-0.15,2.87c-0.09-0.04-0.18-0.07-0.26-0.1c-0.04-0.01-0.07-0.03-0.11-0.04
			c0,0-0.01,0.01-0.02,0.04c-0.15,0.28-0.94,2.07,1.4,3.75c1.66,1.19,3.64,1.48,5.61,1.49c-0.27-0.62-0.35-1.2-0.46-2.1
			c-0.5-0.27-1.03-0.55-1.57-0.83l1.61,0.74c0,0,0.21,2.61,0.99,2.66c0.13,0.01,0.25,0.01,0.36,0c-0.33,0.07-0.67,0.04-0.82-0.27
			c-0.02-0.04-0.04-0.09-0.06-0.13c-0.09,0.08-0.17,0.17-0.23,0.26c-0.15,0.24-0.23,0.5-0.36,0.75c-0.42,0.79-1.32,1.48-1.25,1.72
			c0.04,0.07,0.09,0.1,0.14,0.11c-0.18,0.32-0.2,0.5-0.08,0.59c0.19,0.14,0.38,0.4,0.6,0.58c0.22,0.18,0.42,0.5,0.76,0.38
			c0.35-0.12,0.79-0.02,0.95-0.34c0.16-0.32,0.61-1.72,0.82-1.91c0.2-0.19,1.13-0.02,1.44-0.1c0.31-0.08,0.44-0.18,0.25-0.23
			c0.06-0.04,0.07-0.05,0.05-0.05c-0.05,0.02-0.34,0.15-0.68,0.03c-0.25-0.08-0.49-0.46-0.58-0.66c-0.09-0.21-0.22-0.34-0.33-0.53
			c-0.05-0.09-0.08-0.21-0.1-0.38c-0.01,0.01-0.03,0.01-0.04,0.02c-0.01,0-0.02,0.01-0.02,0.01c0.21-0.15,0.33-0.42,0.37-0.86
			c0.01-0.05,0.01-0.14,0.02-0.25c0,0.19,0,0.37,0.01,0.52c0,0.07-0.01,0.13-0.03,0.2c0.45-0.03,0.89-0.06,1.33-0.09
			c0.58,0.3,1.29,0.69,2,1.12c0.02,0.01,0.04,0.02,0.05,0.02c0.06,0,0.07-0.08,0.19-0.19c0.63-0.68,5.28-5.46,9.96-4.4
			c0.03,0.01,0.05,0.01,0.08,0.02c0,0,0,0,0,0c0,0-0.03-0.02-0.07-0.05c-0.17-0.11-0.66-0.41-1.28-0.7
			c-0.05-0.39-0.25-1.74-0.89-4.27c-1.7-6.72-2.79-7.51-3.51-7.85c-0.68-0.31-1.72,0.13-2.78,0.9c-0.01-0.01-0.03-0.03-0.04-0.04
			c-0.06-0.06-0.11-0.1-0.15-0.12c-0.11-0.07-0.15-0.03-0.15-0.03s-0.13-0.03-0.19,0.02c-0.01,0.01-0.01,0.01-0.02,0.02
			c0,0-0.01,0-0.02-0.01c-0.1-0.03-0.54-0.15-1.59-0.11c-1.11,0.05-1.96,0.15-1.96,0.15l0.01,2.65c0,0,1.01-0.28,1.83-0.43
			c-0.27,0.32-0.52,0.63-0.73,0.93c-0.59,0.85-1.05,1.58-1.05,1.58l-0.66,8.5c-0.09,0.04-0.18,0.07-0.27,0.1
			c0.14-2.62,0.36-7.29,0.46-9.28c0.13-2.69,0.02-4.52-0.48-6.22c-0.46-1.56-1.41-2.73-1.75-3.11c-0.01-0.01-0.02-0.02-0.03-0.04
			c0.01-0.01,0.01-0.03,0.02-0.04c0.05-0.1,0.1-0.2,0.15-0.29c0.09-0.17,0.19-0.32,0.29-0.46c0.43-0.62,0.88-1.11,0.95-2.14
			c0.01-0.08,0.01-0.15,0.03-0.23c-0.16-0.48-0.09-1.07,0.01-1.19c0.11-0.13,0.28-0.11,0.41,0.11c0.05-0.1,0.11-0.2,0.17-0.31
			c0.17-0.14,0.29-0.3,0.39-0.46c0.38-0.65,0.22-1.4,0.04-1.85c-0.01-0.03-0.02-0.06-0.03-0.09c0,0-0.41-1.14-1.58-1.51
			c-0.42-0.13-4.4,0.04-4.42,4.93c0.05,0.48,0.02,0.96-0.2,1.36c-0.07,0.12-0.15,0.24-0.25,0.34c-1.14-0.11-1.64-0.34-2.12,0.29
			c-0.15,0.2-0.28,0.41-0.4,0.64c-0.36,0.72-0.52,1.57-0.55,2.47C155.37,434.76,155.43,435.52,155.56,436.26z M161.5,443.23
			c0.37-1.07,0.62-2.1,0.75-3.14l0.01,0.29c0,0-0.33,2.89-0.35,3.19c-0.03,0.67,0.07,2.45,0.13,3.49c0.02,0.18,0.04,0.37,0.05,0.56
			c0.02,0.47-0.01,0.11-0.05-0.56C161.85,445.39,161.17,444.2,161.5,443.23z M171.46,444.38c0.16,0.33,0.4,0.71,0.76,1.14
			c0.23,0.27,0.45,0.54,0.64,0.8c0.72,0.93,1.25,1.72,1.64,2.19c-0.05-0.01-0.09-0.01-0.14-0.02c0,0-0.03,0-0.07,0.01
			c-0.49,0.05-3.6,0.45-6.07,2.34c0.62-1,1.77-2.12,3.83-3.27c0.03-0.02,0.02-0.05-0.03-0.09c-0.01-0.01-0.02-0.01-0.02-0.02
			c-0.17-0.11-0.64-0.28-1.18-0.37c-0.32-0.05-0.66-0.08-0.97-0.06c-0.12,0.01-0.23,0.02-0.33,0.04c-0.35,0.07-0.59,0.2-0.87,0.45
			c-0.17,0.15-0.36,0.34-0.6,0.59c0.06-0.07,0.12-0.14,0.19-0.2c0.01-0.02,0.03-0.04,0.04-0.06c1.1-1.56,2-2.9,2.54-3.87
			c0.1-0.19,0.19-0.36,0.27-0.51c0.02-0.04,0.04-0.09,0.06-0.13C171.15,443.34,171.15,443.74,171.46,444.38z M170.72,440.35
			c0,0.03-0.01,0.07-0.02,0.1c0.01-0.05,0.02-0.09,0.02-0.13C170.72,440.33,170.72,440.34,170.72,440.35z M169.2,440
			c-0.02-0.2-0.04-0.34-0.04-0.34s0,0,0,0C169.16,439.67,169.18,439.81,169.2,440z M169.26,440.91c0.02,0.47-0.05,0.89-0.13,1.05
			c-0.01,0.02-0.03,0.04-0.04,0.05c-0.02,0.02-0.05,0.04-0.07,0.05c0.02-0.01,0.05-0.03,0.07-0.05c0.01-0.01,0.03-0.03,0.04-0.05
			C169.22,441.8,169.28,441.38,169.26,440.91z M168.87,441.94c0.01,0.03,0.02,0.05,0.03,0.07
			C168.89,442,168.88,441.97,168.87,441.94z M169,442.06c-0.01,0-0.02,0-0.03,0C168.98,442.06,168.99,442.06,169,442.06z
			 M167.89,439.94c0.01,0.02,0.01,0.04,0.02,0.06c0.02,0.04,0.03,0.07,0.04,0.11c-0.01-0.04-0.03-0.07-0.04-0.11
			C167.9,439.97,167.9,439.95,167.89,439.94L167.89,439.94z M169.37,441.98c0,0.05,0,0.09,0.01,0.12
			C169.37,442.05,169.37,442.02,169.37,441.98z M170.15,441.6c-0.1,0.09-0.2,0.17-0.23,0.12
			C169.96,441.77,170.06,441.7,170.15,441.6z M170.03,441.2c-0.02,0.07-0.04,0.13-0.06,0.2
			C169.98,441.33,170.01,441.27,170.03,441.2z M169.91,441.62C169.91,441.62,169.91,441.62,169.91,441.62
			C169.91,441.62,169.91,441.62,169.91,441.62z M170.05,441.08c0.01-0.07,0.02-0.14,0-0.21
			C170.07,440.94,170.07,441.01,170.05,441.08z M169.68,439.66c0.02,0.14,0.08,0.34,0.14,0.55
			C169.76,440,169.7,439.79,169.68,439.66c0-0.02,0-0.03,0-0.03C169.68,439.63,169.68,439.64,169.68,439.66z M170.57,440.7
			C170.57,440.7,170.57,440.69,170.57,440.7c0.04-0.07,0.07-0.12,0.09-0.17C170.63,440.59,170.6,440.65,170.57,440.7z
			 M170.57,439.74c0.05,0.13,0.09,0.26,0.12,0.37C170.64,439.97,170.6,439.85,170.57,439.74z M170.69,440.46
			c-0.01,0.02-0.02,0.05-0.03,0.07C170.67,440.5,170.68,440.48,170.69,440.46z M169.39,442.14c0.01,0.03,0.02,0.06,0.05,0.08
			C169.41,442.2,169.39,442.17,169.39,442.14z M169.37,441.62C169.37,441.62,169.37,441.62,169.37,441.62
			C169.37,441.62,169.37,441.61,169.37,441.62C169.37,441.61,169.37,441.62,169.37,441.62
			C169.37,441.62,169.37,441.62,169.37,441.62z M167.78,439.92c-0.03,0-0.07,0-0.1,0c-0.01,0-0.03,0-0.04,0
			C167.68,439.92,167.73,439.92,167.78,439.92z M169.65,438.27c-0.09-0.06-0.16-0.11-0.22-0.17c0,0,0,0-0.01-0.01
			C169.48,438.14,169.56,438.2,169.65,438.27z M165.35,450.55c-0.23,0.17-0.45,0.3-0.66,0.41
			C164.82,450.89,165.05,450.75,165.35,450.55z"
          />
          <g>
            <path
              fill="none"
              d="M184.14,461.19c-0.04,0.06-0.08,0.13-0.13,0.19c0.12-0.1,0.21-0.17,0.25-0.27c-0.01,0-0.03-0.01-0.04-0.01
				C184.18,461.13,184.16,461.16,184.14,461.19z"
            />
            <path fill="none" d="M187.14,462.72c0.01,0,0.02,0,0.02,0C187.15,462.72,187.14,462.72,187.14,462.72z" />
            <path
              fill="none"
              d="M183.24,461.52c0.02,0.01,0.03,0.01,0.05,0.01c0.02,0,0.04,0,0.06-0.01c-0.02-0.03-0.04-0.06-0.05-0.1
				C183.29,461.46,183.27,461.49,183.24,461.52z"
            />
            <path
              fill="none"
              d="M187.09,462.73c0.01,0,0.03-0.01,0.04-0.01c-0.02,0-0.05,0.01-0.07,0.02
				C187.07,462.74,187.08,462.73,187.09,462.73z"
            />
            <path
              fill="none"
              d="M183.81,460.66c0,0,0.01-0.01,0.02-0.02c-0.01-0.05-0.02-0.1-0.03-0.15c0-0.03,0-0.06,0-0.1
				c-0.02,0.03-0.05,0.06-0.06,0.09C183.72,460.56,183.75,460.63,183.81,460.66z"
            />
            <path
              fill="none"
              d="M183.8,461.74c0-0.01,0-0.03,0-0.05c0,0.01-0.01,0.01-0.01,0.02C183.79,461.72,183.79,461.73,183.8,461.74z"
            />
            <polygon fill="none" points="183.63,461.23 183.63,461.23 183.63,461.23 			" />
            <path
              fill="#4D2C1B"
              d="M187,453.75c0.02-0.03,0.03-0.06,0.05-0.09c0,0,0,0,0,0c-0.03,0.02-0.05,0.03-0.08,0.05
				C186.98,453.72,186.99,453.74,187,453.75z"
            />
            <path
              fill="#4D2C1B"
              d="M186.08,454.51c0.27-0.7,0.73-0.99,0.86-0.85c0.03-0.01,0.07-0.02,0.1-0.02c-0.01-0.07,0-0.13,0.05-0.19
				c0.32-0.42,0.83-0.62,0.83-0.62s0,0,0,0.01c0.69-0.45,1.26-0.62,1.81-0.65c0,0,0-0.01,0-0.01s-0.19-0.29-0.32-0.59
				c-0.49,0.26-1.27,0.42-2.41,0.43c-0.27,0-0.51,0-0.75-0.01c0,0.02,0,0.04,0,0.05c-0.03,0.28-0.11,0.4-0.18,0.44
				c0,0.03,0.01,0.06,0.01,0.09c0,0.35-0.03,0.92-0.05,1.42c-0.1-0.01-0.2-0.04-0.26-0.1c-0.06-0.06-0.11-0.09-0.15-0.1
				c0.01,0.37-0.06,0.78-0.24,1.23c0.23,0.05,0.43,0.09,0.61,0.11C185.98,454.92,186,454.72,186.08,454.51z"
            />
            <path
              fill="#AC7D61"
              d="M196.48,464.34c0.03-0.89-0.56-0.73-0.91-1.03c-0.34-0.29-0.19-0.4-1.03-1.01
				c-0.37,0.49-0.91,0.89-1.78,1.09c-0.36,0.08-0.68,0.12-0.96,0.11c1.26,0.47,2.62,0.86,2.89,1.04c0.41,0.27,0.69,2.01,1.16,2.71
				c0.48,0.7,0.57,0.44,0.84,0.12c0.18-0.21,0.41-0.27,0.43-0.78S196.45,465.59,196.48,464.34z"
            />
            <path
              fill="#AC7D61"
              d="M184.23,451.9C184.23,451.9,184.23,451.91,184.23,451.9c0-0.03,0-0.06,0.01-0.1
				C184.23,451.84,184.23,451.87,184.23,451.9z"
            />
            <path
              fill="#AC7D61"
              d="M186.03,454.01c0.02-0.5,0.05-1.07,0.05-1.42c0-0.03,0-0.06-0.01-0.09c0.08-0.05,0.16-0.16,0.18-0.44
				c0-0.02,0-0.04,0-0.05c-0.27-0.01-0.53-0.03-0.77-0.06c0.03,0.2,0.11,0.4,0.18,0.59c-0.12-0.13-0.22-0.28-0.3-0.44
				c0,0.16,0.03,0.32,0.11,0.46c-0.19-0.24-0.43-0.43-0.7-0.57c0,0.04,0.01,0.08,0.01,0.11c-0.12-0.06-0.22-0.13-0.31-0.22
				c0.03,0.08,0.04,0.16,0.01,0.24c-0.05-0.09-0.11-0.17-0.18-0.25c0,0.05,0,0.1,0,0.15c0.12,0.17,0.24,0.34,0.3,0.46
				c0.11,0.23,0.23,0.24,0.32,0.3c0.09,0.06,0.23,0.68,0.41,0.87c0.12,0.12,0.18,0.11,0.27,0.14c0.04,0.02,0.09,0.04,0.15,0.1
				C185.83,453.97,185.93,454,186.03,454.01z"
            />
            <path
              fill="#AC7D61"
              d="M193.35,454.82c0.06,0.39,0.22,0.73,0.35,0.95l0.01-0.01c0,0,0.27,0.42,0.82,1.15
				c0.21-0.43,0.21-0.66-0.12-1.26c-0.37-0.68-1.29-2.05-1.8-2.69c-0.01-0.01-0.02-0.03-0.03-0.04c-0.04-0.05-0.08-0.1-0.11-0.14
				c0,0,0,0,0,0c-0.18-0.18-0.39-0.31-0.64-0.37c-0.16-0.03-0.31-0.06-0.44-0.09C191.8,453.3,193.25,454.13,193.35,454.82z"
            />
            <path
              fill="#AC7D61"
              d="M187.93,452.84c0.08,0.13,1.56,2.57,2.97,1.78c1.45-0.81-1.04-2.43-1.04-2.43c0.09-0.01,0.19-0.01,0.3,0
				c-0.14-0.01-0.28-0.01-0.42,0C189.19,452.22,188.62,452.39,187.93,452.84z"
            />
            <path
              fill="#AC7D61"
              d="M189.58,459.96c-0.13-1.48-0.28-1.07-0.78-1.76c-0.23-0.24-0.42-0.48-0.59-0.72
				c-0.1-0.79-0.34-1.62-0.53-2.24c0-0.01,0.01-0.03,0.01-0.04c0.13-0.64-0.57-1.16-0.64-1.54c-0.02,0.03-0.04,0.06-0.05,0.09
				c-0.01-0.02-0.02-0.03-0.02-0.05c0.02-0.01,0.05-0.03,0.08-0.05c0-0.01,0-0.01,0-0.02c-0.03,0-0.07,0.01-0.1,0.02
				c-0.13-0.14-0.59,0.15-0.86,0.85c-0.08,0.21-0.1,0.41-0.09,0.63c0.02,0.38,0.14,0.79,0.23,1.26c-0.21,0.04-0.39,0.23-0.47,0.68
				c-0.06,0.33,0,0.85,0.14,1.46c-0.33,0.4-0.85,0.93-1.29,1.37c0.12,0.07,0.24,0.15,0.35,0.23c0.04,0.03,0.09,0.07,0.11,0.12
				c0.06,0.12-0.02,0.27-0.1,0.38c-0.14,0.19-0.32,0.38-0.55,0.41c-0.06,0.01-0.12,0-0.17,0.03c-0.01,0.01-0.02,0.02-0.03,0.03
				c0.01,0,0.03,0.01,0.04,0.01c-0.03,0.1-0.13,0.17-0.25,0.27c-0.07,0.1-0.14,0.21-0.21,0.31c0,0.02,0,0.03,0,0.05
				c0.03,0.05,0.06,0.11,0.09,0.16c0.03,0,0.05-0.01,0.08-0.05c0.13-0.18,0.43-0.42,0.77-0.5c0.34-0.08,0.36-0.23,0.58-0.54
				l-0.04-0.02c0.07-0.05,0.14-0.1,0.22-0.16c0.19-0.15,0.53-0.27,0.9-0.4c0.34,0.92,0.76,1.85,1.18,2.57c-0.06,0-0.1,0.01-0.1,0.01
				c-0.07-0.01-0.15-0.06-0.24-0.09c-0.02-0.01-0.05-0.01-0.06-0.02c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.02,0c0,0,0,0-0.01,0
				c-0.01,0-0.03,0-0.04,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.02,0.01c-0.05,0.04-0.04,0.14-0.01,0.2
				c-0.07-0.02-0.21-0.01-0.23,0.07c0,0.02-0.02,0.03-0.01,0.05c0.01,0.08-0.05,0.08-0.04,0.11c-0.05,0.04-0.03,0.12-0.05,0.17
				c-0.03,0.06-0.11,0.1-0.11,0.18c0.01-0.03,0.01-0.05,0-0.08c-0.01,0.03-0.01,0.07-0.01,0.11c-0.02,0.24-0.06,0.13,0.39,0.31
				c0.48,0.18,1.09,0.33,1.65,0.43c0.54,0.1,0.87,0.01,0.54-0.68c-0.08-0.35-0.21-0.99-0.26-1.43l0,0.01c0,0-1.2-1.32-1.67-2.23
				c0-0.01-0.01-0.01-0.01-0.02c0.07-0.03,0.14-0.07,0.2-0.1c0.07,0.09,0.14,0.19,0.2,0.3c0.49,0.77,1.07,1.09,1.3,1.56
				c0,0,0.64,0.77,1.52,1.23C189.72,462.18,189.66,460.86,189.58,459.96z"
            />
            <path
              fill="#4D2C1B"
              d="M187.4,459.83c-0.07,0.04-0.13,0.07-0.2,0.1c0,0.01,0.01,0.01,0.01,0.02c0.46,0.91,1.67,2.23,1.67,2.23
				l0-0.01l0.02-0.48c-0.23-0.48-0.81-0.79-1.3-1.56C187.54,460.02,187.47,459.93,187.4,459.83z"
            />
            <path
              fill="#4D2C1B"
              d="M184.49,452.12c0.02-0.08,0.02-0.17-0.01-0.24c0.09,0.09,0.2,0.16,0.31,0.22c0-0.04-0.01-0.08-0.01-0.11
				c0.27,0.14,0.51,0.33,0.7,0.57c-0.07-0.14-0.11-0.3-0.11-0.46c0.08,0.16,0.18,0.31,0.3,0.44c-0.08-0.19-0.15-0.39-0.18-0.59
				c-0.61-0.07-1.1-0.17-1.5-0.31c0.03,0.06,0.06,0.12,0.08,0.18c0.06,0.16,0.1,0.32,0.16,0.47c-0.02-0.13-0.02-0.26-0.01-0.39
				c0-0.03,0-0.06,0.01-0.1c0,0.03,0,0.07,0,0.1c0.01,0.11,0.04,0.22,0.09,0.31c0-0.06,0-0.13-0.01-0.19c0-0.05,0-0.1,0-0.15
				C184.38,451.95,184.44,452.03,184.49,452.12z"
            />
            <path
              fill="#659896"
              d="M185.5,451.95c0.24,0.03,0.49,0.04,0.77,0.06c0.24,0.01,0.49,0.02,0.75,0.01
				c1.14-0.01,1.92-0.17,2.41-0.43c1.18-0.61,0.73-1.75-0.35-2.5c-0.05-0.78-0.2-1.57-0.56-1.81c-0.77-0.51-2.23-0.5-3.25-0.48
				c-1.04,0.03-1.37,1.06-1.46,1.96c-0.22,0.12-0.4,0.25-0.55,0.35c-0.84,0.56-1.13,1.87,0.75,2.52
				C184.39,451.78,184.89,451.88,185.5,451.95z"
            />
            <path
              fill="#1EBDE4"
              d="M194.54,462.3c0.46-0.6,0.65-1.34,0.84-2c0.35-1.2,0.06-2.17-0.63-3.09c-0.08-0.1-0.15-0.2-0.22-0.3
				c-0.54-0.73-0.82-1.15-0.82-1.15l-0.01,0.01c-0.13-0.22-0.29-0.56-0.35-0.95c-0.1-0.69-1.55-1.52-1.97-2.47c0,0,0-0.01,0-0.01
				c0,0-0.67-0.13-1.22-0.15c-0.11,0-0.21,0-0.3,0c0,0,2.49,1.62,1.04,2.43c-1.41,0.79-2.89-1.65-2.97-1.78c0,0,0-0.01,0-0.01
				s-0.52,0.2-0.83,0.62c-0.04,0.06-0.06,0.12-0.05,0.19c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0.07,0.38,0.77,0.89,0.64,1.54
				c0,0.01-0.01,0.03-0.01,0.04c0.18,0.62,0.42,1.45,0.53,2.24c0.17,0.23,0.37,0.48,0.59,0.72c0.5,0.69,0.66,0.28,0.78,1.76
				c0.08,0.9,0.13,2.22,0.83,2.97c0.31,0.34,0.75,0.56,1.39,0.57c0.28,0.01,0.6-0.03,0.96-0.11
				C193.63,463.19,194.17,462.79,194.54,462.3z"
            />
            <path
              fill="#1EBDE4"
              d="M183.78,461.72c0-0.01,0.01-0.01,0.01-0.02c0.07-0.1,0.14-0.21,0.21-0.31c0.04-0.06,0.08-0.13,0.13-0.19
				c0.02-0.03,0.05-0.06,0.08-0.09c0.01-0.01,0.02-0.02,0.03-0.03c0.05-0.03,0.11-0.02,0.17-0.03c0.23-0.03,0.41-0.22,0.55-0.41
				c0.08-0.11,0.16-0.25,0.1-0.38c-0.02-0.05-0.07-0.09-0.11-0.12c-0.11-0.09-0.23-0.16-0.35-0.23c-0.08-0.05-0.16-0.09-0.24-0.13
				c-0.06-0.03-0.12-0.05-0.18-0.05c-0.07,0.01-0.12,0.05-0.17,0.1c-0.09,0.09-0.15,0.21-0.18,0.33c-0.01,0.04-0.02,0.08-0.02,0.12
				c0.02,0.01,0.04,0.03,0.03,0.06c-0.01,0.02-0.02,0.04-0.03,0.06c0,0.03,0,0.06,0,0.1c0.01,0.05,0.02,0.1,0.03,0.15
				c0,0.01,0,0.02,0,0.03c0,0,0,0,0,0c0.07,0.02,0.16,0.01,0.22,0.06c0.05,0.04,0.07,0.1,0.05,0.16s-0.08,0.1-0.14,0.1
				c0.08,0.01,0.12,0.11,0.09,0.18c-0.03,0.07-0.12,0.11-0.2,0.11c-0.08,0-0.16-0.03-0.23-0.06c0,0,0,0,0,0
				c0.04,0.13,0.01,0.28-0.08,0.38c-0.01,0.01-0.02,0.02-0.03,0.02c-0.02,0-0.04,0-0.05-0.01c-0.04-0.02-0.08-0.06-0.1-0.1
				c-0.02,0.01-0.04,0.01-0.06,0.01c-0.02,0-0.04-0.01-0.05-0.01c-0.03,0.04-0.05,0.07-0.08,0.11c-0.05-0.03-0.1-0.09-0.11-0.15
				c-0.01-0.04,0-0.08,0.02-0.11c-0.05-0.05-0.1-0.1-0.16-0.11c-0.11-0.03-0.23,0.05-0.29,0.15c-0.06,0.1-0.06,0.22-0.07,0.34
				c-0.01,0.11-0.01,0.21-0.02,0.32c-0.01,0.15-0.02,0.31,0.04,0.46c0.05,0.13,0.15,0.24,0.25,0.34c0.14,0.13,0.27,0.24,0.44,0.35
				c0.19,0.12,0.33,0.21,0.54,0.31c0.15-0.24,0.27-0.49,0.36-0.75c0.03-0.09,0.06-0.19,0.05-0.29c-0.01-0.08-0.05-0.14-0.09-0.21
				c-0.07-0.12-0.15-0.25-0.22-0.37c-0.03-0.05-0.06-0.11-0.09-0.16C183.79,461.73,183.79,461.72,183.78,461.72z"
            />
            <path
              fill="#AC7D61"
              d="M183.05,461.48c0.01,0.06,0.06,0.11,0.11,0.15c0.03-0.04,0.05-0.07,0.08-0.11
				c0.02-0.03,0.05-0.06,0.07-0.09c0.01,0.04,0.03,0.07,0.05,0.1c0.03,0.04,0.06,0.08,0.1,0.1c0.02,0.01,0.03,0.02,0.05,0.01
				c0.01,0,0.02-0.01,0.03-0.02c0.09-0.1,0.12-0.25,0.08-0.38c0,0,0,0,0,0c0,0,0,0,0,0c0.07,0.03,0.15,0.06,0.23,0.06
				c0.08,0,0.16-0.04,0.2-0.11c0.03-0.07-0.01-0.18-0.09-0.18c0.06,0,0.12-0.04,0.14-0.1s0-0.13-0.05-0.16
				c-0.06-0.05-0.15-0.04-0.22-0.06c0,0,0,0,0,0c0-0.01,0-0.02,0-0.03c-0.01,0.01-0.01,0.01-0.02,0.02
				c-0.06-0.03-0.09-0.09-0.07-0.17c0.01-0.04,0.03-0.07,0.06-0.09c0.01-0.02,0.03-0.04,0.03-0.06c0.01-0.03,0-0.05-0.03-0.06
				c-0.03-0.02-0.07-0.02-0.09-0.02c-0.1,0.01-0.15,0.11-0.17,0.2c-0.03,0.09-0.04,0.18-0.03,0.27c0,0.02,0,0.04-0.01,0.06
				c-0.01,0.03-0.04,0.04-0.06,0.06c-0.05,0.04-0.06,0.12-0.02,0.17c-0.05-0.02-0.1,0.05-0.11,0.1c-0.01,0.06-0.02,0.12-0.06,0.16
				c-0.03,0.03-0.08,0.02-0.12,0.04c-0.02,0.01-0.04,0.03-0.05,0.05C183.05,461.4,183.04,461.44,183.05,461.48z"
            />
            <path
              opacity="0.3"
              d="M196.55,465.03c0.08,0.35,0.21,0.63,0.33,0.86C196.81,465.61,196.7,465.32,196.55,465.03z"
            />
            <path
              opacity="0.3"
              d="M195.52,466.52c0.29,0.62,0.59,1.24,0.91,1.1c0.1-0.05-0.36-0.39-0.42-0.81c-0.06-0.42-0.3-1.49-0.49-1.87
				c-0.54-1.07-0.17-1.39-0.34-1.35c-0.47,0.1-1.06-0.6-1.25-0.7c0.06-0.05,0.12-0.09,0.17-0.14c0.3-0.27,0.55-0.58,0.73-0.93
				c0.04-0.07,0.07-0.14,0.1-0.21c0.51-1.05,0.69-2.17,0.56-2.82c-0.12-0.6-0.25-0.89-0.58-1.36c-0.05-0.07-0.11-0.15-0.17-0.23
				c-0.14-0.2-0.18-0.3-0.18-0.38c0.01-0.17,0.2-0.22,0.02-0.76c-0.08-0.24-0.55-1.03-1.02-1.76c-0.36-0.55-0.71-1.06-0.88-1.24
				c0,0-0.01-0.01-0.01-0.01c-0.18-0.19-0.67-0.36-1.18-0.49c-0.55-0.14-1.14-0.24-1.38-0.26c-0.04,0-0.07-0.01-0.11-0.01
				c-0.18-0.02-0.29-0.06-0.36-0.1c-0.23-0.14-0.07-0.37-0.35-0.54c0,0,0,0,0,0c0.08-0.04,0.12-0.06,0.12-0.06
				c0.46-1.06-0.35-2.3-0.35-2.3c-0.14,0.3-0.5,0.51-0.5,0.51c0.32-0.3,0.27-0.62,0.27-0.62c-0.18-1.56-0.66-1.43-2.16-1.53
				c-1.5-0.1-0.59-0.63-1.26-0.68c-0.91-0.06-1.26,0.72-1.38,1.5c-0.12,0.78-0.09,1.74-0.09,1.74s-0.82,0.97-0.44,1.22
				c0,0,0.01,0,0.01,0.01c0.14,0.09,0.32,0.17,0.49,0.22c0.08,0.02,0.16,0.05,0.22,0.06c0.02,0.01,0.04,0.01,0.06,0.01c0,0,0,0,0,0
				c0.01,0.07,0.04,0.15,0.09,0.23c0.08,0.14,0.2,0.29,0.38,0.4c0.29,0.19,0.42,0.28,0.5,0.61c0.08,0.34,0.52,0.51,0.53,0.81
				c0.01,0.11,0.11,0.16,0.25,0.2c0.09,0.02,0.2,0.04,0.29,0.07c0.27-0.39,0.58-0.52,0.65-0.36c-0.48,0.19-0.74,0.89-0.42,2.01
				c0.32,1.12,0,1.97,0.16,2.24s0.32,0.51,0.32,0.51s-0.32-0.16-0.69,0.32c-0.32,0.41-1.1,1.02-1.51,1.29
				c-0.07,0.04-0.13,0.08-0.17,0.1c-0.32,0.16-0.38,0.55-0.59,0.58c-0.22,0.03-0.35,0.16-0.42,0.38c-0.07,0.22-0.04,0.12-0.19,0.27
				c-0.04,0.04-0.08,0.06-0.13,0.07c-0.01,0-0.02,0.01-0.03,0.01c-0.07,0.02-0.14,0.03-0.2,0.1c-0.06,0.06-0.11,0.16-0.14,0.33
				c-0.11,0.53-0.13,0.97-0.13,0.97s0.04,0.04,0.11,0.1c0,0,0,0,0,0c0.19,0.17,0.6,0.5,0.87,0.56c0,0,0.32-0.46,0.36-0.75
				c0.02-0.14,0.03-0.28,0-0.41c-0.02-0.13-0.08-0.26-0.18-0.39c-0.02-0.03-0.04-0.05-0.05-0.07c-0.1-0.15,0.05-0.04,0.05-0.04
				s0.01-0.06,0.13-0.14c0.04-0.03,0.09-0.06,0.15-0.09c0.24-0.12,0.53-0.22,0.7-0.34c0.02-0.01,0.04-0.02,0.05-0.04
				c0.06-0.05,0.13-0.13,0.19-0.21c0.08-0.11,0.15-0.22,0.15-0.22s0.41-0.32,0.97-0.53c0.04-0.01,0.07-0.03,0.11-0.04
				c0.17-0.06,0.34-0.12,0.51-0.19c-0.1,0.04-0.21,0.08-0.31,0.12c0.26,0.46,0.55,0.93,0.81,1.35c0.59,0.98,0.72,1.47,0.74,1.66
				c-0.24-0.22-0.66-0.61-0.92-1.02c-0.01-0.01-0.02-0.03-0.03-0.04c0.18,0.4,0.3,0.57,0.35,0.64c0.02,0.03,0.04,0.04,0.04,0.04
				c0.42,0.3,0.39,0.33,0.57,0.45c0,0.02,0,0.03,0,0.03s-1.27,0.05-1.6,0.3c0-0.01,0-0.03,0-0.04c0-0.03,0-0.05,0.01-0.08
				c-0.19,0.05-0.35,0.11-0.49,0.16c-0.86,0.3-1.12,0.69-0.46,1.18c-0.35,0.07-0.83-0.15-0.91-0.36c-0.06-0.16,0.08-0.4-0.28-0.52
				c-0.36-0.12-1.15-0.35-1.69,0.23c0,0,0,0,0,0.53c0,1.16,4.23,1.16,6.39,0.86c5.94-0.56,5.8,2,6.89,1.92
				c-0.05-0.06-0.1-0.13-0.16-0.21C195.74,467.06,195.62,466.81,195.52,466.52z M187.99,456.38c0.03,0.14,0.07,0.29,0.1,0.44
				c0.17,0.3,0.37,0.62,0.62,0.93c1.17,1.44,0.86,1.32,0.83,1.84c0,0.04,0,0.09,0,0.14c0,0.1,0.01,0.25,0.02,0.42
				c-0.02-0.31-0.02-0.54-0.02-0.61c0-0.02,0-0.03,0-0.03c0,0,0,0,0,0c-0.45-0.48-0.87-0.96-1.26-1.35
				c-0.01-0.15-0.02-0.31-0.05-0.48C188.17,457.36,188.08,456.87,187.99,456.38z M187.96,459.37c-0.03,0.04-0.06,0.08-0.1,0.12
				c-0.04,0.04-0.08,0.08-0.13,0.11c0.04-0.04,0.09-0.07,0.13-0.11C187.9,459.46,187.93,459.41,187.96,459.37z M189.05,462.95
				c-0.08-0.34-0.15-0.69-0.16-0.96c-0.01-0.11,0-0.21,0.01-0.29c0,0,0.01,0.01,0.02,0.02c0.01,0.01,0.01,0.01,0.02,0.02
				c0,0,0.01,0.01,0.01,0.01c0.15,0.18,0.63,0.69,1.21,1.06C189.78,462.86,189.41,462.9,189.05,462.95z M187,460.02
				c-0.02,0.01-0.04,0.02-0.06,0.02C186.97,460.04,186.98,460.03,187,460.02z M188.6,464.28c-0.11-0.02-0.21-0.04-0.32-0.06
				c0.66,0.1,0.96,0.11,0.99-0.18C189.28,464.32,188.99,464.35,188.6,464.28z M190.42,462.93
				C190.41,462.93,190.41,462.93,190.42,462.93c-0.01-0.01-0.01-0.01-0.02-0.02c-0.01-0.01-0.02-0.03-0.03-0.04
				c0.23,0.26,0.53,0.46,0.91,0.54c0.21,0.05,0.41,0.07,0.6,0.09C191.21,463.5,190.74,463.28,190.42,462.93z M193.8,464.19
				c-0.53-0.18-1.21-0.4-1.88-0.65C192.37,463.67,193.17,463.95,193.8,464.19z M194.73,464.57c-0.01-0.01-0.02-0.02-0.04-0.03
				C194.71,464.54,194.72,464.55,194.73,464.57z"
            />
            <path
              opacity="0.3"
              d="M186.3,457.31c-0.05,0.04-0.1,0.1-0.14,0.16c-0.21,0.29-0.27,0.63-0.22,1.01
				c0.16-0.19,0.26-0.35,0.28-0.45C186.28,457.77,186.3,457.53,186.3,457.31z"
            />
          </g>
        </g>
        <g id="father">
          <g>
            <path
              fill="#D6BDAF"
              d="M355.77,440.74c1.31-2.75,5.71-7.34,7.24-4.98c-0.58-1.38-0.77-0.59-1.92-4.91
				c-1.13-4.26-3.96-8.52-6.54-8.71c0.2,6.54,0.36,13.19,0.48,19.87C355.31,441.6,355.56,441.19,355.77,440.74z"
            />
            <path
              fill="#1EBDE4"
              d="M336.72,418.3c4.02,2.77,8.89,18.42,14.32,29.5c0.24,0.5,0.46,0.95,0.65,1.38
				c0.76-3.91,2.23-5.49,3.35-7.17c-0.12-6.68-0.28-13.33-0.48-19.87c-1.06-34.41-3.17-66-5.5-85.85
				c-2.94-25.03-10.03-43.43-14.52-53.09c-3.26-7.01-11.03-5.52-10.8,2.89c0.2,7.21,2.4,51.04,1.07,128.35
				C330.31,414.22,334.25,415.53,336.72,418.3z"
            />
            <path
              fill="#1EBDE4"
              d="M341.14,475.66c-2.5-2.56-4.47-4.02-5.72-4.92c-0.11,0.36-0.22,0.71-0.33,1.07
				c-0.9,2.95-1.62,6.21-2.18,9.33c-0.51,5.53-0.82,10.35-1,14.49c-0.27,10.65,3,30.65,3.47,37.24c0.05,0.77,0.14,1.69,0.24,2.72
				c7.1,0,13.13,0,17.38,0c1.32-16.68,2.01-35.63,2.21-55.26C350.26,485,345.35,479.97,341.14,475.66z"
            />
            <path
              fill="#D6BDAF"
              d="M280.79,380.74l0.05-0.02c-0.23-1.02-0.47-2.1-0.72-3.26c-1.44-6.74-0.38-3.27-3.01-5.88
				c-0.17,0.46-0.34,0.93-0.5,1.42c-0.85,2.6-1.24,4.36-1.36,5.62c0.07-0.11,0.14-0.15,0.23-0.08c0,0,0.35-0.07,0.69,0.46
				c0.34,0.53,0.92,3.01,0.92,3.01s-1.18-0.81-1.77-1.38c0,0,0-0.01-0.01-0.01c0.35,1.66,1.35,2,0.74,4.95
				c-0.65,3.11-0.47,6.31-0.11,9.27c0.52-1.79,1.64-5.1,2.89-7.65C280.59,383.64,281.32,383.85,280.79,380.74z"
            />
            <path
              fill="#D6BDAF"
              d="M318.67,390.9c-0.29,0.91-0.57,1.79-0.82,2.63C318.15,392.67,318.42,391.79,318.67,390.9z"
            />
            <path
              fill="#4D2C1C"
              d="M277.1,382.02c0,0-0.58-2.48-0.92-3.01c-0.34-0.53-0.69-0.46-0.69-0.46c-0.08-0.07-0.16-0.03-0.23,0.08
				c-0.23,0.38-0.31,1.62,0.07,2c0,0,0,0.01,0.01,0.01C275.92,381.21,277.1,382.02,277.1,382.02z"
            />
            <path
              fill="#D6BDAF"
              d="M314.37,408.05c0.02-0.62,0.07-1.27,0.15-1.98c-8.61,5.13-17.83,3.68-22.31-5.54
				c-1.86-3.83-2.71-7.52-3.26-10.45c0-0.01,0-0.03,0-0.04c0.12-4.66-0.33-7.13-2.4-8.17c-2.27-1.13-3.77,1.98-3.42,5.1
				c0.35,3.13-0.45,5.86,0,8.61c0.38,2.31,1.93,2.68,2.91,2.1c0.06,0.37,0.11,0.8,0.16,1.28c1.55,15.25-1.85,13.58-1.85,13.58
				s-0.04,0.01-0.12,0.03c0.92,0.14,1.95,0.18,3.1,0.1c0.98-0.07,1.75,0.48,2.35,1.37c1.66-0.31,17.53-3.24,23.03-4.65
				C313.64,409.15,314.19,408.45,314.37,408.05z M284.85,394.49c-0.38,0.81-0.9,0.13-0.54-1.22s0-1.62-0.31-2.3
				s-0.07-0.88-0.07-0.88c0.6,1.02,1.25,0.62,1.62,1.09C285.9,391.66,285.23,393.68,284.85,394.49z M285.49,396.27
				c0,0,2.08-3.97,1.94-7.2C287.43,389.07,287.76,394.8,285.49,396.27z M285.29,384.13c-0.71,0.28-1.11,1.68-0.29,2.76
				s1.11,1.56,1.36,2.44c0.25,0.88-0.13,1.75-0.13,1.75s-0.17-0.68-0.42-1.49c-0.25-0.81-0.85,0.4-1.66-0.55
				c-0.81-0.95-0.62-3.78-0.41-4.45c0.21-0.67,0.65-1.75,1.78-1.47c1.13,0.28,1.55,2.03,1.92,3.25
				C287.45,386.37,286.28,383.73,285.29,384.13z"
            />
            <path
              fill="#4D2C1C"
              d="M275.22,359.87c0,0,0.25-0.24,0.73-0.66C275.32,359.57,275.22,359.87,275.22,359.87z"
            />
            <path
              fill="#4D2C1C"
              d="M286.19,398.97c-0.05-0.48-0.1-0.91-0.16-1.28c-0.98,0.59-2.53,0.21-2.91-2.1
				c-0.45-2.76,0.35-5.49,0-8.61c-0.35-3.13,1.15-6.24,3.42-5.1c2.07,1.04,2.52,3.5,2.4,8.17c0,0.01,0,0.03,0,0.04
				c0.55,2.93,1.4,6.61,3.26,10.45c4.48,9.21,13.7,10.66,22.31,5.54c0.03-0.02,0.06-0.04,0.09-0.05c0,0,0.99-5.07,3.24-12.48
				c0.26-0.85,0.53-1.73,0.82-2.63c0.18-0.57,0.38-1.16,0.57-1.75c1.98-5.94,3.76-12.38,3.74-16.29c0.48-5.27-0.17-9.24-1.09-11.69
				c-1.22-3.26-0.61-1.02-0.61-1.02s0.14,3.44,0.3,5.68c-0.58-1.79-1.47-3.67-3.13-5.69c-0.86-1.55-1.89-3.08-3.08-4.27
				c-3.48-3.48-2.64-3.37-1.45-0.11c-1.77-1.43-3.69-2.57-5.45-2.63c-5.88-0.21-9.53-2.26-13.22-2.04c-1.27-0.1-3.54,0.31-5.68,1.14
				c-0.02-0.05-0.03-0.07-0.03-0.07c-3.02,1.66-6.34,1.93-9.5,3.99c-1.95,1.27-3.33,2.39-4.1,3.06c0.77-0.44,2.33-0.96,5.49-1.12
				c-0.6,0.03-1.17,0.16-1.7,0.37c-2.04,0.64-3.69,1.98-4.7,4.05c0,0,0.36-0.59,1.54-1.26c-0.16,0.25-0.3,0.51-0.44,0.77
				c-0.31,0.35-0.61,0.76-0.89,1.24c-0.52,0.67-0.93,1.42-1.16,2.24c-0.95,3.49-1.44,3.42,0.38,2.23c0.02,1.02,0.28,2.09,0.87,3.08
				c1.28,2.19,0.95,1.52,0.61-0.63c0.16,0.26,0.33,0.5,0.53,0.72c0.23,0.26,0.44,0.48,0.64,0.67c2.63,2.6,1.57-0.87,3.01,5.88
				c0.25,1.16,0.49,2.24,0.72,3.26l-0.05,0.02c0.53,3.1-0.2,2.9-1.94,6.46c-1.25,2.55-2.37,5.86-2.89,7.65
				c-0.08,0.26-0.14,0.49-0.19,0.68c-0.08,0.31-0.02,1.17,0.07,1.47c0.11,0.34,0.28,0.93,0.51,1.93c0.45,1.97,0.35,1.88,0.35,3.85
				c0,1.97-0.73,6.68,3.94,9.01c1.94,0.97,3.21,0.85,3.58,0.78c0.08-0.01,0.12-0.03,0.12-0.03S287.74,414.22,286.19,398.97z"
            />
            <path
              fill="#4D2C1C"
              d="M285.53,383.12c-1.13-0.28-1.56,0.8-1.78,1.47c-0.21,0.67-0.4,3.5,0.41,4.45
				c0.81,0.95,1.41-0.26,1.66,0.55c0.25,0.81,0.42,1.49,0.42,1.49s0.38-0.87,0.13-1.75c-0.25-0.88-0.55-1.35-1.36-2.44
				s-0.41-2.48,0.29-2.76c0.99-0.4,2.15,2.24,2.15,2.24C287.08,385.15,286.65,383.39,285.53,383.12z"
            />
            <path
              fill="#4D2C1C"
              d="M285.53,391.19c-0.37-0.47-1.01-0.07-1.62-1.09c0,0-0.23,0.2,0.07,0.88s0.67,0.95,0.31,2.3
				s0.16,2.02,0.54,1.22C285.23,393.68,285.9,391.66,285.53,391.19z"
            />
            <path
              fill="#4D2C1C"
              d="M285.49,396.27c2.26-1.47,1.94-7.2,1.94-7.2C287.58,392.3,285.49,396.27,285.49,396.27z"
            />
            <path
              fill="#42637C"
              d="M363.01,435.75c-1.54-2.36-5.94,2.23-7.24,4.98c-0.21,0.45-0.46,0.86-0.73,1.27
				c-1.12,1.68-2.59,3.26-3.35,7.17c-0.19-0.42-0.4-0.88-0.65-1.38c-5.43-11.08-10.3-26.73-14.32-29.5
				c-2.47-2.77-6.42-4.08-11.92-3.87c-1.98,0.08-4.17,0.35-6.56,0.82c-1.7-2.34-3.46-5.21-3.81-7.41c0,0.05-0.02,0.12-0.06,0.21
				c-0.17,0.4-0.73,1.1-1.66,1.34c-5.51,1.41-21.37,4.35-23.03,4.65c-0.09,0.02-0.14,0.03-0.14,0.03s2.51,3.79-2.24,5.84
				c-0.39,0.13-0.8,0.28-1.23,0.44c-2.32,0.88-4.37,1.96-6.17,3.21c-0.62,0.33-1.14,0.61-1.58,0.87
				c-8.15,4.75-14.67,10.39-13.36,22.64c1.35,12.63-2.22,12.84-2.22,40.33c0,7.67-9.62,25.38-9.51,48.19c5.25,0,11.36,0,17.95,0
				c1.8-6.25,4.42-14.33,7.51-20.85c3.35-7.1,5.42-11.4,6.38-32.35c0-0.02,0-0.02,0-0.02c0-0.02,0-0.03,0-0.05
				c0.01-0.22,0.02-0.44,0.03-0.66c0.03-0.68,0.07-1.36,0.13-2.06c0.24-6.62-0.49-13.69-0.54-22.23c-0.06-9.73,2.62-0.2,2.65,4.67
				c0.02,2.45,0.38,1.79,0.94-0.29c1.01-4.35,1.92-7.81,2.13-9.36c0.07-0.5,0.01-0.03-0.15,1.18c0.04-0.16,0.08-0.32,0.12-0.47
				c0.56-2.22,0.54-1.46,0.19,1.03c0.28-1.41,0.44-2.16,0.44-2.16s-1.22,7.15-2.55,16.39c-0.43,3.79-0.88,7.96-1.29,12.16
				c-0.17,6.23,0.58,12.39,4.08,17.39c3.5,5.01,15.63,7.53,21.4,9.67c0.51,0.19,0.47,0.88-0.05,1.06
				c-14.72,4.95-23.12-3.32-23.04,1.42c0.38,0.85,5.23,4.25,14.71,4.74c1.55,0.08-11.15,4.17-17.46,6.53
				c-5.09,1.43-8.47,1.91-10.58,5.96c-0.02-0.02-0.04-0.05-0.07-0.07c-0.02,0.06-0.04,0.12-0.06,0.18c-0.04,0.1-0.07,0.21-0.11,0.31
				c0.01,0,0.02,0.01,0.03,0.01c0,0.01-0.01,0.01-0.01,0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.04,0.1-0.07,0.21-0.11,0.31
				c-0.11,0.24-0.21,0.5-0.31,0.76c-0.21,0.56-0.39,1.12-0.54,1.67c-0.64,1.86-1.2,3.57-1.69,5.12c19.22,0,42.06,0,59.44,0
				c-0.29-12.26-4.15-16.06-4.24-23.07c-0.04-3.09-0.15-5.57-0.35-7.51l-0.72,4.15c0.31-1.8,0.55-3.38,0.65-4.72
				c-0.36-2.93-0.98-4.47-1.96-4.89c-1.18-0.23-2.98,0.17-5.59,1.31c-8.31,3.61-18.35,1.72-10.66,1.1
				c7.68-0.63,16.78-5.17,18.66-13.96c1.88-8.78,6.9-21.01,8.31-27.75c0,0,0.04,0.04,0.08,0.16c1.67-8.26,1.51-14.87,1.3-17.94
				c-0.01-0.14,0.2-0.17,0.22-0.03c2.69,13.14-0.49,20.23-3.06,28.31c1.25,0.9,3.22,2.36,5.72,4.92c4.21,4.31,9.12,9.34,14.06,4.67
				c0.24-0.22,0.48-0.47,0.71-0.74c1.26-1.43,2.18-2.98,2.82-4.54c0.06,0.12,0.09,0.18,0.09,0.18c2.37-6.28,3.8-10.98,4.57-17.66
				c0.77-6.68,0.96-11.01,1.54-14.94S364.55,438.11,363.01,435.75z"
            />
            <path
              fill="#141616"
              d="M275.02,530.47c0.15-0.55,0.32-1.11,0.54-1.67c0.1-0.27,0.2-0.52,0.31-0.76c0.04-0.1,0.07-0.21,0.11-0.31
				c0.01,0,0.02,0.01,0.03,0.01c0-0.01,0.01-0.01,0.01-0.02c-0.01,0-0.02-0.01-0.03-0.01c0.04-0.1,0.07-0.21,0.11-0.31
				c0.02-0.06,0.04-0.12,0.06-0.18c0.02,0.02,0.04,0.05,0.07,0.07c2.12-4.05,5.5-4.53,10.58-5.96c6.31-2.37,19.01-6.45,17.46-6.53
				c-9.48-0.49-14.33-3.89-14.71-4.74c-0.08-4.74,8.32,3.53,23.04-1.42c0.51-0.17,0.55-0.87,0.05-1.06
				c-5.77-2.14-17.89-4.66-21.4-9.67c-3.5-5.01-4.25-11.16-4.08-17.39c0.41-4.2,0.86-8.37,1.29-12.16
				c1.33-9.24,2.55-16.39,2.55-16.39s-0.17,0.75-0.44,2.16c0.35-2.49,0.37-3.25-0.19-1.03c-0.04,0.16-0.08,0.31-0.12,0.47
				c0.16-1.21,0.22-1.68,0.15-1.18c-0.21,1.55-1.12,5.01-2.13,9.36c-0.56,2.08-0.92,2.74-0.94,0.29c-0.03-4.87-2.72-14.4-2.65-4.67
				c0.05,8.53,0.78,15.6,0.54,22.23c-0.05,0.69-0.1,1.38-0.13,2.06c-0.01,0.22-0.02,0.44-0.03,0.66c0,0.02,0,0.03,0,0.05
				c0,0,0,0.01,0,0.02c-0.96,20.95-3.03,25.25-6.38,32.35c-3.08,6.53-5.7,14.61-7.51,20.85c0.72,0,1.44,0,2.16,0
				C273.82,534.05,274.38,532.34,275.02,530.47z"
            />
            <path
              fill="#141616"
              d="M335.38,532.87c-0.47-6.59-3.73-26.59-3.47-37.24c0.18-4.14,0.49-8.96,1-14.49
				c0.56-3.12,1.28-6.38,2.18-9.33c0.11-0.36,0.22-0.72,0.33-1.07c2.57-8.09,5.75-15.17,3.06-28.31c-0.03-0.14-0.23-0.11-0.22,0.03
				c0.22,3.07,0.37,9.68-1.3,17.94c-0.04-0.12-0.08-0.16-0.08-0.16c-1.41,6.74-6.43,18.97-8.31,27.75
				c-1.88,8.78-10.98,13.33-18.66,13.96c-7.68,0.63,2.35,2.51,10.66-1.1c2.62-1.14,4.41-1.54,5.59-1.31
				c0.98,0.42,1.6,1.97,1.96,4.89c-0.1,1.34-0.34,2.92-0.65,4.72l0.72-4.15c0.21,1.94,0.31,4.42,0.35,7.51
				c0.08,7.01,3.95,10.81,4.24,23.07c0.97,0,1.91,0,2.84,0C335.51,534.56,335.43,533.64,335.38,532.87z"
            />
          </g>
          <path
            opacity="0.3"
            d="M365.13,439.77c-0.26-2.23-2.11-4.02-2.11-4.02c0.64,2.36,1.41,4.06-1.15,9.57
			c-2.56,5.5-4.86,11.01-4.61,16.9c0,0,0.33,2.11,0.3,4.79c-0.86-6.45-3.29-12.43-5.82-18.07c0.97-4.66,2.95-5.92,4.04-8.21
			c1.31-2.75,5.71-7.34,7.24-4.98c-0.58-1.38-0.77-0.59-1.92-4.91c-1.11-4.16-3.82-8.32-6.35-8.69c-0.14-5.44-0.29-10.52-0.44-15.09
			c-1.09-32.28-3.08-55.78-5.54-73.16c-0.42-2.99-0.86-5.81-1.31-8.46c-0.18-1.06-0.36-2.09-0.55-3.09
			c-3.59-19.68-7.71-29.37-10.72-35.49c-0.84-1.7-1.59-3.13-2.22-4.42c-1.88-3.85-5.08-3.54-4.73-0.18
			c1.17,11.42,3.72,64.33,2.52,132.87c-1.93-0.61-4.02-0.78-6.19-0.72c-2.12,0.06-4.32,0.35-6.51,0.69
			c-0.27,0.04-0.53,0.08-0.8,0.13c0,0-0.08-0.09-0.21-0.26c-0.43-0.54-1.45-1.9-2.36-3.77c-0.46-0.95-0.89-2.02-1.2-3.19
			c0.1-1.4,0.52-4.36,2.09-9.83c2.41-8.42,6-17.2,6.35-23.26c0.26-4.54,0.56-7.63-0.37-11.51c-0.17-0.7-0.38-1.43-0.63-2.19
			c-0.2-0.61-0.43-1.23-0.7-1.9c0,0,0,0.07,0.01,0.19c0.01,0.12,0.02,0.3,0.03,0.52c0.06,1.12,0.18,3.39,0.29,5.43
			c0,0-0.46-1.45-1.75-3.48c-0.01-0.02-0.03-0.05-0.05-0.07c-0.38-0.6-0.84-1.25-1.38-1.93c-0.03-0.04-0.06-0.08-0.09-0.12
			c-2.46-3.06-3.36-3.06-5.7-4.08c-2.34-1.02,0.12,0.36,2.19,2.73c2.07,2.37-26.75-4.44-29.64,14.28c-1.02,6.61-0.55,9.18-0.55,9.18
			c0.02-0.01,0.04-0.01,0.07-0.02c1.34-0.38,3.69,0.89,3.95,5.87c0.14,2.68-0.5,2.94-1.21,7.14c-0.45,2.67-1.81,3-2.76,2.88
			c-0.15-0.02-0.28-0.05-0.4-0.08c0,0,0.53,0.6,1.72,0.3c-0.12,0.28,0.85,15.54-5.92,13.37c-0.12-0.04-0.24-0.08-0.36-0.13
			c0,0,0.66,0.61,2.35,1.03c0.44,0.11,0.94,0.2,1.52,0.27c1.02,0.12,2.27,0.17,3.79,0.06c0.13-0.01,0.25-0.02,0.36-0.03
			c0.1,0.02,0.2,0.05,0.31,0.09c0.28,0.09,0.59,0.23,0.86,0.42c0.19,0.13,0.36,0.29,0.5,0.48c0.1,0.13,0.17,0.27,0.23,0.43
			c0.02,0.07,0.04,0.14,0.06,0.21c0.01,0.05,0.02,0.11,0.03,0.17l0.05-0.01l0.51-0.11c0.92,2.24,2.95,3.63-0.33,5.05
			c0,0,3.98-0.81,8.54-0.93c0.27-0.01,0.33,0.37,0.07,0.45c-3.29,0.95-10.19,2.24-19.01,10.27c-10.21,9.3-1.75,28.21-5.25,39.37
			c-3.5,11.15-3.72,17.28-3.72,24.06c0,6.01-7.13,22.89-11.52,43.02c29.31,0,77.32,0,94.41,0c1.22-15.47,1.76-35.24,1.89-55.38
			c-0.89,0.87-1.78,1.43-2.67,1.72c1.6-0.68,3.19-2.04,4.61-4.12c0.54-0.78,1.05-1.66,1.53-2.64c0.1-0.2,0.19-0.39,0.28-0.6
			c0.73-1.6,1.38-3.44,1.92-5.54c0.17-0.67,0.33-1.37,0.48-2.09c1.36-6.63,1.95-10.25,2.36-13.51c0.17-1.35,0.31-2.63,0.46-4.05
			C364.81,444.53,365.38,442,365.13,439.77z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <rect x="10" y="122.01" fill="none" width="280.05" height="182.74" />
        <text transform="matrix(1 0 0 1 103.2347 135.7889)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="19px">
            Seit 2020 beziehen wir{" "}
          </tspan>
          <tspan x="-54.86" y="29" fontFamily={font.serif} fontSize="19px">
            den Strom für alle Werke der
          </tspan>
          <tspan x="-42.83" y="58" fontFamily={font.serif} fontSize="19px">
            BMW Group zu{" "}
          </tspan>
          <tspan x="93.88" y="58" fontFamily={font.condensed} fontSize="24px">
            100%{" "}
          </tspan>
          <tspan x="158.76" y="58" fontFamily={font.serif} fontSize="19px">
            aus{" "}
          </tspan>
          <tspan x="16.6" y="87" fontFamily={font.condensed} fontSize="24px">
            ERNEUERBAREN{" "}
          </tspan>
          <tspan x="73.86" y="116" fontFamily={font.condensed} fontSize="24px">
            ENERGIEN.
          </tspan>
        </text>
        <text transform="matrix(1 0 0 1 181.1949 72)">
          <tspan x="0" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            g
          </tspan>
          <tspan x="24.95" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            e
          </tspan>
          <tspan x="48.48" y="0" fontFamily={font.condensed} fontSize="50px" letterSpacing="-1">
            tan
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
