const lightsFrag = `varying float vFade;
uniform float uTime;
uniform float uOpacity;
varying float vProgress;

#include <common>
#include <fog_pars_fragment>
void main(){
	vec3 color = vec3(54.,	194.,	236.	)/255.;
	color = vec3(0., 191.,231.)/255.;
	color *= 0.8;
	// float fade = sin(uTime) * 0.5 + 0.5;
	float opacity = 1.-smoothstep(0., 1., vProgress) ;
	gl_FragColor = vec4(color,vFade * (opacity * uOpacity ));
	#ifdef USE_FOG
		#ifdef FOG_EXP2
			float fogFactor = 1.0 - exp( - fogDensity * fogDensity * fogDepth * fogDepth );
		#else
			float fogFactor = smoothstep( 0., 150. ,fogDepth );
		#endif
		gl_FragColor.a = mix( gl_FragColor.a, 0., fogFactor );
	// gl_FragColor.rgb = vec3(1.);
	#endif
	if(gl_FragColor.a < 0.05) {
		discard;
	}
}`;

export default lightsFrag;
