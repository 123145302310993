import React, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components/macro";
import { nanoid } from "nanoid";
import { Color, defTransition } from "#shared/theme";

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

export const Label = styled.span`
  font-weight: bold;
  font-size: 13px;
  margin-left: 17px;
`;
export const Input = styled.input`
  font-weight: bold;
  visibility: hidden;
  position: absolute;
`;

const Toggler = styled.span`
  display: block;
  height: 20px;
  flex: 0 0 40px;
  border-radius: 20px;
  background-color: #505153;
  border: 1px solid #d7d7d7;
  position: relative;
  transition: background-color ${defTransition};
  ${Input}:checked + & {
    background-color: ${Color.Green};
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 2px;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: ${Color.Green};
    transition: background-color ${defTransition}, transform ${defTransition};
  }
  ${Input}:checked + &::after {
    background-color: #505153;
    transform: translateX(20px);
  }
`;

interface CheckboxProps {
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  labelEnabled?: string;
  labelDisabled?: string;
}

export const Checkbox: FC<CheckboxProps> = ({ defaultChecked = false, onChange, labelEnabled, labelDisabled }) => {
  const [checked, setChecked] = useState(defaultChecked);
  const name = nanoid();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange?.(e.target.checked);
  };
  return (
    <Container htmlFor={name}>
      <Input name={name} onChange={handleChange} type="checkbox" id={name} checked={checked} />
      <Toggler />
      <Label>{checked ? labelEnabled : labelDisabled}</Label>
    </Container>
  );
};
