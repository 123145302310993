import { motion } from "framer-motion";
import React from "react";
import { font } from "#shared/theme";
import { CardProps } from "../../CardProps";
import { variantsLeft, variantsRight, variantsText } from "../../motionVariants";

export const Card32: React.FC<CardProps> = ({ motionProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 341">
      <g id="BG">
        <rect y="133.5" width="306.5" height="8" />
        <rect x="414.81" fill="#1EBDE4" width="160.15" height="228" />
      </g>
      <motion.g {...motionProps} variants={variantsRight} id="right">
        <g id="landscape">
          <g>
            <path
              fill="#E0DAC7"
              d="M446.71,177.83c-7.87-0.88-8.9-6.79-16.75-5.04c-6.22,1.39-9.09-3.13-14.47,0.03
				c-0.91,0.53-1.6,1.17-1.96,1.54c1.19,0.4,1.86,1.15,2.18,1.64c1.43-0.24,2.67,0.21,2.67,0.21c1.26-1.32,3.04-1.19,3.04-1.19
				c-2.45,0.4-2.98,1.92-2.98,1.92c-3.31-1.52-5.82,1.26-5.82,1.26c0.75-1.32,1.8-1.91,2.79-2.14c-1.98-1.39-4.34-2.09-7.66-1.21
				c-1.39,0.47-3.18,1.27-5.55,2.58c-3.92,2.18-15.14,3.37-23.39,3.6l79.55-1.14C458.38,179.9,458.84,179.18,446.71,177.83z"
            />
            <path
              fill="#E0DAC7"
              d="M581.77,106.76c-2.53-3.72-7.96-8.13-14.13-5.34c-1.18,0.58-2.49,1.42-3.88,2.62
				c0.11,0.03,0.21,0.05,0.32,0.09c1.71,0.54,2.5,1.69,2.5,1.69s5.31-0.43,6.97,4.08c0,0-2.67-4.22-7.29-3.39
				c0,0-2.05-3.31-7.48-1.45c0,0,2.25-1.59,4.81-1.06c0.48-0.65,1.56-1.91,3.35-3.04c-2.94-3.8-6.43-4.96-8.91-5.35
				c-6.71-1.06-13.9,1.21-19.77,4.67c-11.75,6.94-22.33,7.59-34.89,11.39l94.8-1.27C589.07,110.23,585.33,104.59,581.77,106.76z"
            />
            <g>
              <path
                fill="#BED4D3"
                d="M460.57,206.03c0.19,0.08,0.95,0.15,1.16,0.15c0.48,0.01,0.27,0.97,0.73,1.1
					c2.37,0.66,2.39,2.28,2.15,3.98c0.65-0.39,1.91-0.28,2.42,0.27c0.52,0.56,0.97,1.66,1.08,2.41c-0.09-0.51,0.43-1.15,0.93-1.26
					s0.92,0.33,1.07,0.82c0.68-0.6,1.55-0.1,1.52,0.81c0.58-0.13,1.07,0.08,1.34,0.61c0.29,0.56,0.39,1.57,0.47,2.16
					c1.17-0.19,2.28-0.39,2.96,0.67c0.14,0.22,0.52,0.78,0.61,1.02c0.3-0.31,0.69-0.48,1.11-0.46c0.61,0.03,1.17,0.95,0.9,1.49
					c0.28-0.24,0.86-0.77,1.22-0.81c0.36-0.04,0.52,0.22,0.47,0.58c0.11-0.33,0.71-0.25,0.99-0.05c0.31,0.23,0.65,0.74,0.96,0.97
					c0.87-1.15,1.85-1.76,3.17-1.17c1.36,0.61,1.33,1.75,1.78,3.2c0.21-0.29,0.23-1,0.53-0.81c0.23,0.14-0.01,0.56,0.11,0.81
					c0.36,0.74,2.01-0.48,2.22,1.52c0.13-0.01,0.69-0.09,0.82-0.11c0.72-0.02,1.45,0.68,1.74,0.96c0.53-1.54,2.6-2.97,4.18-3.37
					c-0.32-1.61-0.26-3.37,1.06-4.36s2.56-0.48,4.02,0.28c0.75-1.47,3.24-2.26,4.63-1.38c0.73-2.2,2.76-4.11,5.03-4.59
					c2.27-0.48,3.8,0.92,5.35,2.64c0.63,0.7,1.44,1.15,2.32,1.5c0.44,0.18,0.74,0.67,1.2,0.77c1.07,0.24,1.14,1.16,1.71,2.1
					c0.2,0.33,1.35-0.69,1.64-0.44c0.07,0.06,1.45,1.25,1.53,1.3c1.37-3.78,5.03-4.34,5.4-5.08c0.39-0.76,0.85-1.82,1.09-2.64
					c0.86-2.96,3.55-3.77,6.59-4.43c3.04-0.66,4.06-0.42,6.09,1.91c0.33,0.38,1.39,0.63,1.87,0.77c1.55-0.94,3.76-0.77,5.57-0.39
					c0.55,0.11,0.74,0.22,1.26,0.44c0.74,0.27,1.12-0.02,1.75,0.46c0.26,0.2,0.07,0.93,0.33,1.17c0.52,0.46-0.39,0.03,0.04,0.57
					c-6.75,3.46-13.49,10.48-13.49,10.48c6.42-4.38,14.67-6.36,17.87-7.64c1.17-0.43,3.03-1.17,4.31-1.58
					c12.12-3.92,23.33-5.63,33.23-4.39c-23.43-18.57-36.29-36.85-15.8-49.11c29.78-17.82-16.16-30.34-13.79-31.9
					c-9.41,1.23-18.05,7.86-31.19,18.99c-27.68,23.44-51.26,46.44-76.15,57.83c0.01,0.01-0.63,0.51-0.61,0.52
					C460.27,205.54,460.28,205.91,460.57,206.03z"
              />
              <path
                fill="#97B9B8"
                d="M428.16,196.99c7.67,0.54,11.28,6.74-0.57,13.98s-14.26,11.59-5.48,15.54
					c8.78,3.95,2.77,8.56,4.39,12.37c1.23,2.89,10.07,6.4,15.14,7.01c1.15,0,2.55-1.07,3.69-1.07c1.65-0.42,3.08-0.7,4.27-1.04
					c0.05-0.05,0.54-0.24,0.59-0.28c0.34-0.29,0.68-0.53,1.04-0.8c0.35-0.27,0.19-0.61,0.63-0.58c0.23,0.02,0.65,0.33,0.86,0.24
					c0.23-0.1,0.63-0.59,0.72-0.82c0.27-0.67,0.74-0.76,1.42-0.52c0.17-0.58,0.62-0.89,1.1-1.26c0.48-0.37,0.63-0.67,1.22-0.53
					c0.66,0.17,0.56,0.31,0.85,0.93c0.23,0.48,0.61,1.12,0.69,1.65c0.35-0.03,1.4-0.1,1.83-0.1c-0.03-0.2,0.13-0.94,0.16-1.14
					c0.05-0.32,0.16-0.81,0.1-1.12c-0.14-0.69-0.45-0.44-0.57-1.14c-0.07-0.42,0.57-0.69,0.67-0.91c0.17-0.4-0.52-1.41-0.75-1.79
					c-0.23-0.37-0.04-0.54-0.18-0.95c-0.26-0.75-0.56-1.29-0.26-2.03c0.05-0.13,0.47-0.3,0.47-0.45c-0.01-0.29-0.15-0.89-0.32-1.12
					c-0.57-0.74-0.75-1.09,0.06-1.56c0.81-0.47,1.79-0.92,1.79,1.46c0,0.4-0.11,2.74-0.1,4.83c0.08-0.04,0.8-0.1,0.89-0.1h0.58
					l0.26-0.34c-0.01-0.62,0.29-0.82,0.84-1.08c0.56-0.26,1.26,0.17,1.72,0.57c0.2-0.87,1.13-1.99,2.02-2.08
					c0.89-0.09,1.58,0.52,2.33,1.01c0.16,0.1,1.11,0.4,1.28,0.34c0.11-0.04,0.15-0.75,0.21-0.85c0.42-0.7,0.94-1.17,1.46-1.8
					c0.53-0.62,0.5-0.78,1.31-0.9c0.81-0.12,1.49,0.34,1.83,1.08c0.54-0.41,1.35-0.72,2.01-0.87c0.66-0.15,1.2-0.64,1.6-0.09
					c0.32,0.43,0.42,1.24,0.78,1.65c1.15-0.49,2.49-1,3.64-0.52c0.7,0.29,1.28,0.83,2,1.1c0.87,0.33,1.8,0.39,2.74,0.43
					c0.38,0.02,1.08-0.12,1.42,0.06c0.19-0.59,0.51-1.5,0.64-2.12c0.22-1.05,0.72-1.83,1.75-2.13c0.33-0.1,1.12-0.31,1.45-0.26
					c-0.81-0.86-1.11-2.26-1.15-3.44c-0.02-0.56-0.86-1.07-0.68-1.6c-0.29-0.28-0.72-0.46-1.45-0.44c-0.13,0.01-0.26,0.03-0.38,0.04
					c-0.22-2-1.52-0.67-1.88-1.41c-0.12-0.24-0.11-0.57-0.34-0.72c-0.3-0.19-0.66,0.13-0.87,0.41c-0.45-1.45-0.15-2.46-1.51-3.06
					c-1.32-0.59-2.49-0.08-3.37,1.07c-0.31-0.23-0.62-0.47-0.93-0.7c-0.28-0.21-0.78-0.36-0.89-0.03c0.05-0.36-0.37-0.65-0.73-0.61
					c-0.36,0.04-0.66,0.28-0.94,0.52c0.28-0.55-0.27-1.24-0.88-1.27c-0.42-0.02-0.82,0.19-1.11,0.5c-0.09-0.24-0.22-0.48-0.36-0.7
					c-0.67-1.06-1.82-1.36-2.99-1.17c-0.08-0.6-0.05-1.18-0.33-1.74c-0.27-0.53-0.87-0.95-1.45-0.82c0.03-0.91-1.26-1.52-1.95-0.92
					c-0.14-0.49-0.7-0.82-1.2-0.71c-0.5,0.11-0.86,0.66-0.78,1.16c-0.12-0.75-0.33-1.52-0.84-2.07c-0.52-0.56-1.41-0.82-2.06-0.43
					c0.24-1.7,0.12-3.75-2.25-4.41c-0.46-0.13-0.72-0.71-1.2-0.71c-0.21,0-0.43,0.06-0.62-0.02c-0.29-0.12-0.29-0.53-0.52-0.74
					c-0.01-0.01-0.03-0.03-0.04-0.04c-1.31-1.21-1.72-0.24-2.27-0.98c-0.44-0.61-0.58-1.09-1.07-1.65
					c-1.67-1.91-4.25-1.17-5.73-0.32c-0.34-0.01-0.65-0.22-0.97-0.33c-0.69-0.24-1.52,0-1.97,0.58c-1.32-0.86-1.72-1.38-2.93-1.82
					c-1.07-0.39-3.07-0.25-3.94,0.48c-0.6-0.71-1.63-0.99-2.52-0.74c-4.34-1.94-8.23-3.09-12.21-3.09c-1.72,0-3.42,0.26-5.11,0.74
					C424.26,197.04,426.29,196.85,428.16,196.99z"
              />
              <path
                fill="#659896"
                d="M363.09,244.32c15.83,0,49.75,0.03,78.7,0.04c-5.08-0.6-13.28-3.05-14.51-5.94
					c-1.62-3.81,4.39-8.07-4.39-12.02c-8.78-3.95-5.71-7.68,6.15-14.93c11.85-7.24,6.79-13.95-0.88-14.49
					c-1.87-0.13-3.9,0.05-6.23,0.69c-6.2,1.71-14.58,6.66-28.24,17.56c-19.36,15.45-46.19,29.11-46.19,29.11l15.89,0.02
					C363.19,244.34,363.09,244.32,363.09,244.32z"
              />
              <path
                fill="#97B9B8"
                d="M528.49,220.33c0.73-0.99,1.73-1.79,2.88-2.23c1.84-0.71,4.01-0.5,5.68,0.54
					c-1.02-1.58-3.03-2.45-4.88-2.11c-0.06-1.79,1.04-3.56,2.66-4.31c1.62-0.75,3.68-0.43,5,0.78c2.75-1.8,6.62-1.7,9.27,0.25
					c-0.71-1.47-2.07-2.62-3.65-3.06c0.41-0.52,0.9-0.94,1.46-1.28c-0.47-0.14-0.83-0.58-1.16-0.97c-2.03-2.33-5.4-3.44-8.44-2.78
					c-3.04,0.66-5.63,3.06-6.49,6.03c-0.24,0.82-0.36,1.69-0.74,2.45c-0.37,0.73-4.04,0.11-5.42,3.89c0.26,0.15,0.55,0.24,0.83,0.34
					C526.73,218.33,527.79,219.22,528.49,220.33z"
              />
              <path
                fill="#659896"
                d="M579.25,159.68c-20.49,12.27-6.42,30.17,17.02,48.73c-9.89-1.24-21.04-0.61-33.15,3.31
					c-1.28,0.41-2.5,0.84-3.67,1.27c-3.2,1.28-9.15,3.94-15.56,8.32c0,0,7.52-5.94,14.27-9.4c-0.43-0.54-0.91-1.05-1.43-1.51
					c-0.28-0.25-0.56-0.48-0.86-0.69c-0.61-0.44-1.28-0.81-1.98-1.09c-0.52-0.21-1.06-0.38-1.62-0.5c-1.8-0.37-3.81-0.14-5.35,0.8
					c-0.55,0.34-1.05,0.76-1.46,1.28c1.57,0.44,2.94,1.59,3.65,3.06c-2.65-1.95-6.52-2.05-9.27-0.25c-1.32-1.21-3.38-1.53-5-0.78
					c-1.62,0.75-2.72,2.52-2.66,4.31c1.85-0.34,3.86,0.53,4.88,2.11c-1.68-1.04-3.84-1.25-5.68-0.54c-1.15,0.44-2.15,1.23-2.88,2.23
					c-0.7-1.11-1.75-2-2.98-2.45c-0.28-0.1-0.58-0.19-0.83-0.34c-0.08-0.05-0.15-0.1-0.22-0.16c-0.29-0.26-0.45-0.63-0.65-0.96
					c-0.56-0.94-1.52-1.64-2.59-1.88c-0.46-0.1-0.94-0.13-1.37-0.3c-0.87-0.35-1.4-1.22-2.03-1.91c-1.56-1.72-4.05-2.53-6.32-2.05
					c-2.27,0.48-4.23,2.23-4.96,4.43c-1.39-0.88-3.48-0.3-4.23,1.17c-1.46-0.76-3.35-0.61-4.66,0.38c-1.32,0.99-1.99,2.75-1.68,4.37
					c-1.59,0.41-2.88,1.7-3.41,3.24c-0.18,0.53-0.27,1.09-0.26,1.65c0.04,1.18,0.56,2.31,1.36,3.16c0.71,0.11,1.38,0.53,1.68,1.18
					c0.01,0.01,0.03,0.01,0.04,0.02c0.27-0.06,0.86-0.23,1.13-0.31l0.86-1.41l0.02-0.41l5.52-5.36l9.26-0.06l0.57-0.04l4.2-0.1h0.65
					l4.23,0.35l0.87-0.39l3.96,0.14l1.04,0.12l3.57,0.28l0.64-0.24l3.65,0.23l-0.7,3.11c0.01,0,0.42,0.1,0.43,0.1
					c-0.01,0.03-0.42-0.13-0.43-0.1c-0.02,0.11,0.44-0.01,0.43,0.1c-0.76-0.13-1.99,0.09-1.93,0.85c-0.18,0,0.05,0.33-0.12,0.39
					c-0.01,0,0.01,0,0,0c-0.59,0.22-0.88,0.83-1.02,1.44c-0.01,0.06-0.57,0.47-0.58,0.53c-0.03,0.14,0.27,0.24,0.2,0.37
					c-0.1,0.18-0.37,0.3-0.53,0.43c-0.38,0.32-0.13,0.37-0.34,0.82c-0.07-0.08,0,0-0.08-0.07c-0.11-0.09-0.07-0.38-0.2-0.45
					c-0.53-0.3-1.37-0.62-1.97-0.52c-0.58,0.09-1.5,0.57-1.89,1c-0.14,0.15-0.91,0.36-1.01,0.53c-0.02,0.03,0.14,0.43,0.12,0.46
					c-0.06,0.12,0,0-0.04,0.13c-0.05,0.14-0.1,0.55-0.12,0.69c-1.15-0.02-2.36,0.89-2.17,2.02c-0.27,0.06-0.43,0.02-0.67,0.14
					c-0.59,0.3-0.3,0.64-0.61,1.22c-0.5-0.6-1.17-1.08-1.92-1.24c-0.26-0.06-0.93-0.1-1.2-0.06c-0.47,0.06-1.53,0.66-1.85,1.02
					c-0.1,0.11-0.29,0.52-0.37,0.65c-0.2,0.37-0.68,0.32-0.55,0.73c-0.19-0.07,0.2,0.3,0,0.35c-0.13,0.03,0.01,0.24-0.1,0.3
					c-0.3,0.18-0.67,0.2-0.71,0.55c-0.7,0.34-1.29,0.02-1.9,0.51c-0.15,0.12-0.54,0.39-0.68,0.51c-0.1,0.09,0.01,0.74-0.08,0.83
					c-0.13,0.12-0.02,0.4-0.14,0.53c-0.04,0.04-0.53-0.04-0.57,0c-0.94,0.26-0.62-0.14-1.65,0.08c-1.48,0.32-1.67,0.64-3.35,0.88
					c-0.01-2.03-1.19-3.14-1.3-4.07c-0.41-3.39-1.76-6.06-2.42-9.41c-0.03,0.23-0.56,1.23-0.57,1.46c-0.14,2.39-0.63,3.25-1.02,5.1
					c-0.38,1.82-1.58,4.32-0.93,6.03c0.24,0.64-0.17,1.12,0,1.87c-0.27,0.03-0.65-0.5-0.92-0.47c-1.83,0.17-2.86,0.16-4.93,0.24
					c-0.12,0.01-0.52,0.57-0.64,0.58c-1.8,0.07-3.28-0.06-5.27-0.06c-0.03,0-0.24,0.24-0.27,0.24c-0.09,0-0.12-0.03-0.21-0.03
					c-1.86-0.01-3.67-0.1-5.58-0.21c-0.12-0.01-0.27-0.21-0.4-0.21c-1.91-0.11-3.71-0.38-5.58-0.55c-0.12-0.01-0.64,0.04-0.76,0.03
					c-1.81-0.17-3.52-0.28-5.21-0.46c-3.57-0.38-5.8-0.33-8.26-0.46c-0.01-1.1-0.82-3.47-0.83-5.8c-0.01-2.09-0.02-3.98-0.02-4.38
					c0-2.38-1.93-2.4-2.74-1.92c-0.81,0.47-1.01,1.7-0.44,2.44c0.18,0.23,0.42,0.44,0.44,0.74c0.01,0.14-0.05,0.28-0.1,0.41
					c-0.29,0.74-0.6,1.55-0.34,2.3c0.14,0.42,0.45,0.75,0.68,1.13s0.39,0.85,0.22,1.25c-0.1,0.23-0.3,0.62-0.23,1.04
					c0.12,0.7,0.69,0.73,0.83,1.42c0.06,0.31-0.06,0.63-0.1,0.95c-0.03,0.2-0.02,0.4,0.01,0.59c-0.43,0-0.81,0.02-1.16,0.04
					c-0.08-0.53-0.2-1.05-0.42-1.53c-0.29-0.62-0.8-1.17-1.47-1.33c-0.59-0.15-1.23,0.03-1.71,0.4c-0.48,0.37-0.8,0.92-0.97,1.5
					c-0.68-0.24-1.52,0.15-1.79,0.82c-0.09,0.23-0.15,0.52-0.38,0.62c-0.21,0.09-0.45-0.04-0.69-0.05
					c-0.44-0.03-0.78,0.34-1.13,0.61c-0.35,0.27-0.76,0.47-1.1,0.76c-0.05,0.04-0.1,0.09-0.15,0.14c-1.18,0.34-2.49,0.71-4.14,1.13
					c-1.47,0.37-3.2,0.77-5.37,1.21c-0.68,0.14,115.1,8.69,115.1,8.69l86.55-7.99v-78.95c-9.03-6.41-38.44-27.18-46.95-31.8
					c-10.1-5.49-17.56-8.34-25.9-7.68c-0.32,0.03-0.53,0.08-0.64,0.15C565.63,129.55,609.02,141.85,579.25,159.68z"
              />
              <path fill="#42637C" d="M513.68,240.25c0.09-0.09,0.19-0.18,0.28-0.27h-0.28V240.25z" />
              <linearGradient
                id="Card32SVGID1"
                gradientUnits="userSpaceOnUse"
                x1="513.8203"
                y1="229.16"
                x2="513.8203"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID1)" d="M513.68,240.25c0.09-0.09,0.19-0.18,0.28-0.27h-0.28V240.25z" />
              <path
                fill="#42637C"
                d="M531.25,221.56v-0.02l-4.69,0.02v-0.02l-4.74,0.02v-0.02l-4.74,0.02v-0.02l-4.64,0.02v-0.02l-10.8,0.05
					l-5.35,6.06h-0.13l-0.12,2.55c0.11-0.03,0.38,0.05,0.49,0.03c0.74-0.13,0.66-0.13,1.22,0.37c0.56,0.5,0.96,1.12,0.91,1.87
					c-0.01,0.13,0.73,0.3,0.82,0.39c0.1,0.1,0.35,0.32,0.49,0.3c0.67-0.08,0.7-0.14,1,0.46c0.26,0.53-0.12,0.75-0.17,1.34
					l-3.79-0.52l-0.83,0.3l-26.68-0.09l-6.44,0.58h-0.69c-0.09,0-0.18,0.03-0.25,0.07c0.01,2.33,0.02,4.92,0.03,6.02
					c2.45,0.13,5.65,0.48,9.22,0.85v-0.02c1.69,0.18,3.46,0.36,5.27,0.53v0.02c0.12,0.01,0.24,0.02,0.37,0.03
					c1.86,0.17,3.77,0.32,5.67,0.44c0.12,0.01,0.24,0.01,0.37,0.02c1.91,0.11,3.81,0.18,5.67,0.18c0.09,0,0.18,0,0.27,0
					c0.03,0,0.06,0,0.1,0c1.99,0,3.88-0.04,5.67-0.11c0.12,0,0.25-0.01,0.37-0.01c2.07-0.09,4.01-0.22,5.84-0.39
					c0.27-0.03,0.54-0.05,0.81-0.08c-0.17-0.75-0.16-1.6-0.16-2.29c-0.01-1.87,0.68-4.17,1.06-5.98c0.38-1.84,0.61-2.69,0.74-5.09
					c0.01-0.23,0.07-0.47,0.1-0.7c0.66,3.35,1.32,5.56,1.73,8.95c0.11,0.92,0.25,2.61,0.25,4.64c1.68-0.25,3.25-0.53,4.73-0.85
					c1.03-0.22,2.01-0.47,2.94-0.72c0.04-0.04,0.08-0.09,0.12-0.13c0.12-0.13,0.24-0.25,0.37-0.37v-0.27h0.28
					c0.14-0.13,0.28-0.25,0.43-0.37c0.61-0.49,1.27-0.92,1.97-1.26c0.04-0.35,0.27-0.66,0.58-0.83c0.11-0.07,0.24-0.12,0.37-0.14
					c0.2-0.04,0.4-0.04,0.6,0.03c-0.13-0.41-0.05-0.83,0.15-1.2c0.07-0.13,0.16-0.25,0.26-0.37c0.32-0.36,0.76-0.61,1.24-0.68
					c0.27-0.03,0.54-0.02,0.81,0.04c0.75,0.16,1.45,0.65,1.95,1.25c0.31-0.58,0.82-1.06,1.41-1.36c0.24-0.12,0.5-0.22,0.76-0.27
					c-0.18-1.13,0.83-2.3,1.98-2.28c0.02-0.14,0.06-0.28,0.11-0.42h-2.83v-0.37h2.99c0.02-0.03,0.04-0.06,0.06-0.1
					c0.1-0.18,0.23-0.34,0.37-0.49c0.4-0.43,0.93-0.73,1.5-0.83c0.6-0.1,1.23,0.04,1.75,0.34c0.13,0.07,0.25,0.16,0.37,0.25
					c0.08,0.07,0.16,0.14,0.24,0.22c0.21-0.44,0.51-0.85,0.89-1.16c0.15-0.13,0.32-0.25,0.42-0.43c0.07-0.12,0.1-0.27,0.12-0.41
					c0.01-0.06,0.02-0.11,0.03-0.17c0.14-0.62,0.62-1.13,1.21-1.35l0.04-0.01c0.17-0.06,0.35-0.09,0.54-0.1
					c-0.06-0.77,0.73-1.47,1.48-1.34c0.02-0.11,0.03-0.22,0.05-0.32v-0.1v-4.24L531.25,221.56z M476.65,241.17h-5.27v-0.02h5.27
					V241.17z M476.65,238.79h-5.27v-0.02h5.27V238.79z M482.69,241.17h-5.67v-0.02h5.67V241.17z M482.69,238.79h-5.67v-0.02h5.67
					V238.79z"
              />
              <linearGradient
                id="Card32SVGID2"
                gradientUnits="userSpaceOnUse"
                x1="525.2493"
                y1="229.16"
                x2="525.2493"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID2)"
                d="M523.72,229.16v5.93c0.24-0.12,0.5-0.22,0.76-0.27c-0.18-1.13,0.83-2.3,1.98-2.28
					c0.02-0.14,0.06-0.28,0.11-0.42h-2.83v-0.37h2.99c0.02-0.03,0.04-0.06,0.06-0.1v-2.51H523.72z"
              />
              <linearGradient
                id="Card32SVGID3"
                gradientUnits="userSpaceOnUse"
                x1="528.7766"
                y1="229.16"
                x2="528.7766"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID3)"
                d="M527.15,229.16v2.01c0.4-0.43,0.93-0.73,1.5-0.83c0.6-0.1,1.23,0.04,1.75,0.34v-1.53H527.15z"
              />
              <linearGradient
                id="Card32SVGID4"
                gradientUnits="userSpaceOnUse"
                x1="531.6054"
                y1="229.16"
                x2="531.6054"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID4)"
                d="M530.77,229.16v1.78c0.08,0.07,0.16,0.14,0.24,0.22c0.21-0.44,0.51-0.85,0.89-1.16
					c0.15-0.13,0.32-0.25,0.42-0.43c0.07-0.12,0.1-0.27,0.12-0.41H530.77z"
              />
              <linearGradient
                id="Card32SVGID5"
                gradientUnits="userSpaceOnUse"
                x1="518.8277"
                y1="229.16"
                x2="518.8277"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID5)"
                d="M520.35,229.16h-3.05v2.6h3.04v0.37h-3.04v3.71h1.01c0.32-0.36,0.76-0.61,1.24-0.68
					c0.27-0.03,0.54-0.02,0.81,0.04V229.16z"
              />
              <linearGradient
                id="Card32SVGID6"
                gradientUnits="userSpaceOnUse"
                x1="511.7795"
                y1="229.16"
                x2="511.7795"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID6)"
                d="M510.26,239.98v-0.37h3.05v-3.41h-3.05v-0.37h3.05v-3.71h-3.05v-0.37h3.05v-2.6h-3.06v12.32
					c1.03-0.22,2.01-0.47,2.94-0.72c0.04-0.04,0.08-0.09,0.12-0.13v-0.64H510.26z"
              />
              <linearGradient
                id="Card32SVGID7"
                gradientUnits="userSpaceOnUse"
                x1="515.3068"
                y1="229.16"
                x2="515.3068"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID7)"
                d="M513.68,236.2v3.41h0.71c0.61-0.49,1.27-0.92,1.97-1.26c0.04-0.35,0.27-0.66,0.58-0.83v-1.31
					H513.68z"
              />
              <linearGradient
                id="Card32SVGID8"
                gradientUnits="userSpaceOnUse"
                x1="517.675"
                y1="229.16"
                x2="517.675"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path
                fill="url(#Card32SVGID8)"
                d="M517.3,237.37c0.2-0.04,0.4-0.04,0.6,0.03c-0.13-0.41-0.05-0.83,0.15-1.2h-0.75V237.37z"
              />
              <linearGradient
                id="Card32SVGID9"
                gradientUnits="userSpaceOnUse"
                x1="515.3068"
                y1="229.16"
                x2="515.3068"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="513.68" y="229.16" fill="url(#Card32SVGID9)" width="3.26" height="2.6" />
              <linearGradient
                id="Card32SVGID10"
                gradientUnits="userSpaceOnUse"
                x1="515.3068"
                y1="229.16"
                x2="515.3068"
                y2="243.0384"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0507" style={{ stopColor: "#E9F8FC" }} />
                <stop offset="0.1867" style={{ stopColor: "#B5E8F5" }} />
                <stop offset="0.3247" style={{ stopColor: "#88D9EE" }} />
                <stop offset="0.4621" style={{ stopColor: "#63CEE9" }} />
                <stop offset="0.5987" style={{ stopColor: "#46C5E5" }} />
                <stop offset="0.7343" style={{ stopColor: "#31BEE2" }} />
                <stop offset="0.8686" style={{ stopColor: "#25BAE1" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="513.68" y="232.13" fill="url(#Card32SVGID10)" width="3.26" height="3.71" />
              <linearGradient
                id="Card32SVGID11"
                gradientUnits="userSpaceOnUse"
                x1="474.0111"
                y1="236.6593"
                x2="474.0111"
                y2="244.3171"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID11)" d="M471.38,241.55v0.63c1.69,0.18,3.46,0.36,5.27,0.53v-1.16H471.38z" />
              <linearGradient
                id="Card32SVGID12"
                gradientUnits="userSpaceOnUse"
                x1="479.8522"
                y1="236.6517"
                x2="479.8522"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="477.02" y="236.65" fill="url(#Card32SVGID12)" width="5.67" height="2.14" />
              <linearGradient
                id="Card32SVGID13"
                gradientUnits="userSpaceOnUse"
                x1="485.8909"
                y1="236.6517"
                x2="485.8909"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="483.05" y="239.16" fill="url(#Card32SVGID13)" width="5.67" height="2.02" />
              <linearGradient
                id="Card32SVGID14"
                gradientUnits="userSpaceOnUse"
                x1="491.9296"
                y1="236.6517"
                x2="491.9296"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="489.09" y="239.16" fill="url(#Card32SVGID14)" width="5.67" height="2.02" />
              <linearGradient
                id="Card32SVGID15"
                gradientUnits="userSpaceOnUse"
                x1="485.8909"
                y1="236.6517"
                x2="485.8909"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="483.05" y="236.65" fill="url(#Card32SVGID15)" width="5.67" height="2.14" />
              <linearGradient
                id="Card32SVGID16"
                gradientUnits="userSpaceOnUse"
                x1="479.8522"
                y1="236.6517"
                x2="479.8522"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="477.02" y="239.16" fill="url(#Card32SVGID16)" width="5.67" height="2.02" />
              <linearGradient
                id="Card32SVGID17"
                gradientUnits="userSpaceOnUse"
                x1="474.0111"
                y1="236.6517"
                x2="474.0111"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="471.38" y="239.16" fill="url(#Card32SVGID17)" width="5.27" height="2.02" />
              <linearGradient
                id="Card32SVGID18"
                gradientUnits="userSpaceOnUse"
                x1="479.8497"
                y1="236.6593"
                x2="479.8497"
                y2="244.3171"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID18)" d="M477.01,241.55v1.19c1.86,0.17,3.77,0.32,5.67,0.44v-1.63H477.01z" />
              <linearGradient
                id="Card32SVGID19"
                gradientUnits="userSpaceOnUse"
                x1="485.8909"
                y1="236.6593"
                x2="485.8909"
                y2="244.3171"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID19)" d="M483.05,241.55v1.65c1.91,0.11,3.81,0.18,5.67,0.18v-1.84H483.05z" />
              <linearGradient
                id="Card32SVGID20"
                gradientUnits="userSpaceOnUse"
                x1="491.9296"
                y1="236.659"
                x2="491.9296"
                y2="244.3168"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID20)" d="M489.09,241.55v1.84c1.99,0,3.88-0.04,5.67-0.11v-1.73H489.09z" />
              <linearGradient
                id="Card32SVGID21"
                gradientUnits="userSpaceOnUse"
                x1="474.0137"
                y1="236.6517"
                x2="474.0137"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="471.38" y="236.65" fill="url(#Card32SVGID21)" width="5.27" height="2.14" />
              <linearGradient
                id="Card32SVGID22"
                gradientUnits="userSpaceOnUse"
                x1="498.0542"
                y1="236.6517"
                x2="498.0542"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="495.13" y="236.65" fill="url(#Card32SVGID22)" width="5.84" height="2.14" />
              <linearGradient
                id="Card32SVGID23"
                gradientUnits="userSpaceOnUse"
                x1="491.9296"
                y1="236.6517"
                x2="491.9296"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="489.09" y="236.65" fill="url(#Card32SVGID23)" width="5.67" height="2.14" />
              <linearGradient
                id="Card32SVGID24"
                gradientUnits="userSpaceOnUse"
                x1="498.0542"
                y1="236.6517"
                x2="498.0542"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <rect x="495.13" y="239.16" fill="url(#Card32SVGID24)" width="5.84" height="2.02" />
              <linearGradient
                id="Card32SVGID25"
                gradientUnits="userSpaceOnUse"
                x1="498.0542"
                y1="236.6517"
                x2="498.0542"
                y2="244.3095"
              >
                <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                <stop offset="0.0188" style={{ stopColor: "#EDF9FC" }} />
                <stop offset="0.0684" style={{ stopColor: "#C3ECF7" }} />
                <stop offset="0.1237" style={{ stopColor: "#9CE0F1" }} />
                <stop offset="0.1834" style={{ stopColor: "#7AD5EC" }} />
                <stop offset="0.249" style={{ stopColor: "#5ECCE9" }} />
                <stop offset="0.3224" style={{ stopColor: "#48C5E5" }} />
                <stop offset="0.407" style={{ stopColor: "#36C0E3" }} />
                <stop offset="0.51" style={{ stopColor: "#2ABCE1" }} />
                <stop offset="0.6505" style={{ stopColor: "#23BAE0" }} />
                <stop offset="1" style={{ stopColor: "#21B9E0" }} />
              </linearGradient>
              <path fill="url(#Card32SVGID25)" d="M495.13,241.54v1.72c2.07-0.09,4.01-0.22,5.84-0.39v-1.33H495.13z" />
              <path
                fill="#BED4D3"
                d="M491.19,234.13c0.72-0.37,1.72-0.05,2.09,0.67c0.31-0.25,0.66-0.44,1.05-0.49
					c0.7-0.09,1.64,0.56,1.41,0.88l0.43,0l5.54,0.01c0.05-0.59,0.09-1.21-0.17-1.74c-0.3-0.6-0.99-0.98-1.66-0.9
					c-0.14,0.02-0.3,0.04-0.4-0.05c-0.09-0.09-0.08-0.24-0.07-0.36c0.04-0.75-0.27-1.51-0.83-2.01c-0.56-0.5-1.35-0.72-2.09-0.59
					c-0.11,0.02-0.22,0.05-0.33,0.08c-0.27,0.08-0.53,0.17-0.8,0.23c-0.01,0-0.02,0.01-0.03,0.01c0-0.01-0.01-0.02-0.01-0.03
					c-0.3-0.65-0.96-1.07-1.68-1.18c-0.34-0.05-0.69-0.04-1.02,0.06c-1.03,0.31-1.78,1.27-2.01,2.32c-0.13,0.61-0.1,1.27-0.3,1.85
					C490.83,233.15,491.21,233.58,491.19,234.13z"
              />
              <path
                fill="#659896"
                d="M495.74,235.2C495.74,235.2,495.74,235.19,495.74,235.2c0.23-0.33-0.71-0.98-1.41-0.89
					c-0.39,0.05-0.75,0.25-1.05,0.49c-0.37-0.72-1.37-1.04-2.09-0.67c0.02-0.56-0.36-0.98-0.86-1.25c-0.34-0.18-0.75-0.28-1.12-0.3
					c-0.93-0.04-1.91,0.21-2.78-0.12c-0.71-0.27-1.24-0.89-1.95-1.18c-1.15-0.48-2.46,0.02-3.6,0.51c-0.36-0.4-0.4-1-0.72-1.44
					c-0.4-0.55-1.18-0.71-1.84-0.56c-0.66,0.15-1.24,0.55-1.78,0.96c-0.34-0.74-1.24-1.11-2.05-1c-0.81,0.12-1.51,0.64-2.03,1.26
					c-0.53,0.62-0.91,1.35-1.33,2.05c-0.06,0.1-0.13,0.21-0.24,0.26c-0.17,0.07-0.36-0.04-0.51-0.14c-0.75-0.49-1.64-0.83-2.53-0.74
					c-0.89,0.09-1.77,0.7-1.97,1.57c-0.47-0.4-1.17-0.5-1.73-0.23c-0.56,0.26-1.08,0.8-1.07,1.42v0.02L495.74,235.2z"
              />
              <path fill="#42637C" d="M523.73,232.13h2.83c0.04-0.13,0.1-0.25,0.16-0.37h-2.99V232.13z" />
              <path
                fill="#AEE7F5"
                d="M649.97,269.69v-4.46c0-2.05,0,0,0-2.05v-0.08c0,0-0.03-11.85,0-19.35c0,0-45.05,1.52-52.55,1.28
					c-33.16,0-71.87,0.59-81.29,0.59c-27.28,0-19.74-1.62-31.43-1.62c-8.75,0-8.93,1.03-10.96,1.03c-1.12,0-4.43-1.31-9.51-1.31
					c-6.17,0-19.31-0.42-37.14-0.42c-11.31,0-23.61,0.36-32.7,0.36c-9.61,0-19.31-1.34-22.06-1.75l-16.34-0.09
					c0,0,31.47,4.15,62.9,42.56c24.23,29.62,35.51,38.6,42.65,43.57c66.39,46.22,156.34,25.42,188.44,15.56v-69.64
					C649.97,269.69,649.97,273.87,649.97,269.69z"
              />
              <circle fill="#FFFFFF" cx="468.38" cy="145.08" r="15.35" />
            </g>
            <path
              display="none"
              opacity="0.3"
              d="M497.51,238.76h-5.64h-25.85c0.27,0,0.5,0.22,0.5,0.5v6.11c0.41,0.04,0.83,0.09,1.26,0.13
				v-0.61h5.12v1.13c0.12,0.01,0.24,0.02,0.36,0.03v-1.16h5.51v1.58c0.12,0.01,0.24,0.01,0.36,0.02v-1.61h5.51v1.79
				c0.09,0,0.17,0,0.26,0c0.03,0,0.06,0,0.09,0v-1.79h5.51v1.68c0.12,0,0.24-0.01,0.36-0.01v-1.67h5.67v1.29
				c0.26-0.02,0.53-0.05,0.79-0.08c-0.17-0.73-0.15-1.56-0.15-2.22c0-1.52,0.46-3.33,0.83-4.92
				C497.86,238.84,497.7,238.76,497.51,238.76z M472.9,244.53h-5.12v-1.96h5.12V244.53z M467.78,242.22v-2.08h5.12v2.08H467.78z
				 M478.76,244.53h-5.51v-1.96h5.51V244.53z M473.25,242.22v-2.08h5.51v2.08H473.25z M484.63,244.53h-5.51v-1.96h5.51V244.53z
				 M479.12,242.22v-2.08h5.51v2.08H479.12z M490.49,244.53h-5.51v-1.96h5.51V244.53z M484.98,242.22v-2.08h5.51v2.08H484.98z
				 M490.84,244.53v-1.96h5.67v1.96H490.84z M496.52,242.22h-5.67v-2.08h5.67V242.22z"
            />
            <path opacity="0.3" d="M462.9,236.56v4.8c1.72,0.11,3.74,0.31,5.97,0.55v-5.34H462.9z" />
            <path
              opacity="0.3"
              d="M507.65,227.66h0.02v14.33c0.89-0.16,1.75-0.33,2.58-0.51v-12.32h3.06v2.6h-3.05v0.37h3.05v3.71h-3.05
				v0.37h3.05v3.41h-3.05v0.37h3.05v0.64c0.12-0.13,0.24-0.25,0.37-0.37v-0.27h0.28c0.14-0.13,0.28-0.25,0.43-0.37h-0.71v-3.41h3.26
				v1.31c0.11-0.07,0.24-0.12,0.37-0.14v-1.17h0.75c0.07-0.13,0.16-0.25,0.26-0.37h-1.01v-3.71h3.04v-0.37h-3.04v-2.6h3.05v6.04
				c0.75,0.16,1.45,0.65,1.95,1.25c0.31-0.58,0.82-1.06,1.41-1.36v-5.93h3.06v2.51c0.1-0.18,0.23-0.34,0.37-0.49v-2.01h3.26v1.53
				c0.13,0.07,0.25,0.16,0.37,0.25v-1.78h1.67c0.01-0.06,0.02-0.11,0.03-0.17c0.18-0.81,0.96-1.44,1.78-1.46
				c-0.06-0.77,0.73-1.47,1.48-1.34c0.01-0.05,0.02-0.1,0.02-0.15l-0.01-4.51v0l0,0l-4.51,5.8v0.29l-0.03,0l-0.01-6.09v0l0,0
				l-4.65,5.99v0.1l-0.03,0l-0.01-6.09v0l0,0l-4.7,6.06v0.04l-0.03,0l-0.01-6.09v0l0,0l-4.7,6.06v0.04l-0.03,0l-0.01-6.09v0l0,0
				l-4.6,5.93v0.16l-0.03,0l-0.01-6.09v0l0,0L507.65,227.66z M516.94,235.84h-3.26v-3.71h3.26V235.84z M516.94,231.77h-3.26v-2.6
				h3.26V231.77z"
            />
          </g>
          <path
            fill="#1EBDE4"
            d="M515.89,262.21c-5.97,0-13.6,0-20.9,0s-1.89,2.61,5.45,2.61c0.33,0,0.59,0,0.79,0c0.01,0,0.17,0,0.58,0
			c0.63,0,1.86,0,4.12,0C519.21,264.82,521.87,262.21,515.89,262.21z"
          />
          <path
            fill="#1EBDE4"
            d="M523.88,276.4c9.49,0,100.8,0,117.61,0v-4.18c-16.38,0-104.3,0-116.06,0
			C512.47,272.22,513.42,276.4,523.88,276.4z"
          />
          <path
            fill="#1EBDE4"
            d="M559.33,267.76c8.73,0,68.75,0,82.16,0v-2.05c-12.95,0-69.9,0-80.7,0
			C548.59,265.71,549.49,267.76,559.33,267.76z"
          />
          <path
            fill="#1EBDE4"
            d="M439.84,245.57c2.17-0.44,3.91-0.84,5.37-1.21c-1.13,0-2.27,0-3.42,0c-28.95-0.02-62.87-0.04-78.7-0.04
			c0,0,0.11,0.02,0.31,0.05c0.12,0.02,0.27,0.04,0.46,0.07c2.74,0.41,12.45,1.75,22.06,1.75c9.1,0,21.2,1.27,32.51,1.27
			c17.82,0,31.55,0,37.72,0c5.08,0,12.26,1.61,7.25,1.61c-2.23,0-4.7,0.1-8.05,0.1c-8.44,0-8.13,2.31,0.62,2.31
			c11.69,0,29.57,0,56.85,0c9.42,0,5.96,2.28,2.5,2.28c-3.6,0-9.09,0.16-12.17,0.16c-4.87,0-9.97,1.46-6.9,2.23
			c1.84,0.46,9.84,0.4,13.61,0.4c5.18,0,40.61,0,73.77,0c15,0.48,8.72,2.46,1.36,2.46c-8.38,0-10.94,0.4-13.97,0.4
			c-3.03,0-7.22,2.96,4.19,2.96c5.74,0,23.19,0,39.1,0c5.72,0.56,4.33,3.12-2.64,3.27h29.82v-19.35l-85.12-0.7
			C556.37,245.57,439.16,245.71,439.84,245.57z M480.97,249.09c-5.66,0-7.46-0.66,1.29-0.66
			C489.72,248.43,486.63,249.09,480.97,249.09z M490.35,250.25c-2.24,0-2.95-0.41,0.51-0.41
			C493.81,249.84,492.59,250.25,490.35,250.25z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsLeft} id="left">
        <g>
          <g>
            <path
              fill="none"
              d="M440.96,257.18c0,0.15-0.01,0.29-0.01,0.44c0.3-0.36,0.62-0.73,0.94-1.1c-0.29,0.07-0.6,0.16-0.92,0.25
				C440.97,256.9,440.97,257.04,440.96,257.18z"
            />
            <path
              fill="none"
              d="M441.39,335.47c-0.52-0.32-1.04-0.65-1.55-0.98c0.8,0.63,1.62,1.27,2.47,1.9
				C441.99,336.08,441.68,335.78,441.39,335.47z"
            />
            <path
              fill="none"
              d="M446.92,261.39c0.75,1.3,1.5,2.77,1.72,3.18c1.03,0.64,1.96,0.97,2.86,1.25c-1.37-2.48-3.45-3.47-3.81-5.84
				C447.69,259.98,447.45,260.51,446.92,261.39z"
            />
            <path
              fill="none"
              d="M376.1,146.24c-0.16,1.44-0.31,2.92-0.46,4.46C375.84,149.37,375.99,148.07,376.1,146.24z"
            />
            <path fill="none" d="M387,291.68c-4.71-0.7-9.22-1.3-12.8-2.16C377,290.34,381,291.06,387,291.68z" />
            <path
              fill="none"
              d="M409.21,298.03c1.19,0.94,2.31,2.01,3.51,3.23C411.79,300.02,410.6,298.96,409.21,298.03z"
            />
            <path
              fill="#1EBDE4"
              d="M507.11,335.3c10.99,1.49,21.83,2.71,32.13,3.63c-12.03-1.48-26.59-4.17-36.95-9.01
				c-12.68-5.92-9.28-16.02-4.93-22.98c0,0-0.02,0.03-0.06,0.07c-1.92,3.25-5.67,6.97-9.26,12.42
				C482.82,327.35,490.26,332.14,507.11,335.3z"
            />
            <path
              fill="#659896"
              d="M348.25,263.37c10.65,2.01,10.85,13.96,18.7,21.93c1.56,1.59,3.14,3,7.23,4.21
				c3.58,0.87,8.1,1.46,12.8,2.16c0.35,0.04,0.71,0.07,1.08,0.11c11.93,1.14,16.93,2.9,21.14,6.25c1.39,0.93,2.58,1.99,3.51,3.23
				c0.13,0.14,0.27,0.27,0.41,0.42c3.87,3.98,8.99,18.7,26.71,32.82c0.51,0.33,1.03,0.66,1.55,0.98
				c-16.68-17.53,7.92-38.93-6.18-51.52c-1.18-1.39-1.7-3.26-1.96-5.1c-0.01-0.04-0.06-0.04-0.06,0c-0.09,0.78-0.16,1.57-0.19,2.36
				c0,0.03-0.04,0.04-0.06,0.02c-0.67-1.09-1-2.34-1.31-3.58c-0.01-0.03-0.06-0.03-0.06,0c-0.09,0.87-0.13,1.74-0.12,2.61
				c0,0.03-0.05,0.05-0.06,0.02c-0.6-2.1-1.32-3.17-1.48-3.11c-0.07,0.03,0.44,1.25,0.46,2.14c0,0.03-0.03,0.04-0.06,0.02
				c-0.59-0.53-0.92-1.52-1.07-2.18c-0.01-0.04-0.06-0.03-0.06,0.01c-0.02,0.88,0.09,1.76,0.33,2.61c0.01,0.03-0.03,0.06-0.05,0.03
				c-0.44-0.57-0.81-1.2-1.1-1.87c-0.01-0.03-0.06-0.02-0.06,0.01c-0.04,0.68,0.07,1.35,0.28,1.99c-2.58-1.1-5.7-2.09-9.46-2.94
				c-12.49-2.83-21.01-6.82-26.53-11.34c-0.32-0.57-0.5-1.23-0.59-1.88c-0.15-1.12-0.09-2.28-0.37-3.37c-0.01-0.04-0.07-0.04-0.08,0
				c-0.25,0.91-0.42,1.85-0.49,2.79c0,0.05-0.07,0.05-0.08,0.01c-0.26-0.89-0.45-1.8-0.57-2.72c-0.01-0.05-0.08-0.05-0.08,0
				l-0.12,1.32c0,0.04-0.06,0.05-0.08,0.01c-0.43-1.05-0.64-2.2-0.6-3.34c0-0.05-0.07-0.06-0.08-0.01c-0.28,1-0.36,2.07-0.23,3.1
				c0.01,0.05-0.06,0.06-0.08,0.02c-0.31-0.8-0.55-1.63-0.72-2.48c-0.01-0.05-0.08-0.04-0.08,0.01l0.04,1.35
				c0,0.04-0.05,0.06-0.08,0.03c-0.51-0.75-0.81-1.64-0.85-2.55c0-0.05-0.07-0.05-0.08-0.01c-0.14,0.65-0.16,1.31-0.08,1.97
				c-3.31-4.97-3.88-10.24-2.75-15.17c4.14-18-15.58-18.25-6.41-18.03c2.19,0.05,4.29,0.18,6.31,0.38
				c6.67,0.86,11.7,2.07,15.27,3.17c1.45,0.48,2.85,1.01,4.19,1.57c0.01-0.02,0.01-0.05,0.02-0.07c1.9,0.78,2.79,1.31,2.79,1.31
				c-0.39-2.04-1.05-3.62-1.86-4.84c1.51-6.45,2.22-13.46,2.06-18.87c-0.29-10.28-1.1-24.87-4.88-40.61
				c-2.37-9.85-2.48-17.16-3.34-22.07c-0.51-2.94-0.51-5-1.03-2.06c-0.51,2.94-3.78,7.11-3.6,17.07c0.13,7.1-1.04,9.93-1.85,15.4
				c-0.27-3.07-0.58-5.89-0.95-8.3c-2.76-18.26-3.6-18.45-5.82-31.95c-2.8-17.03-1.98-19.97-3.23-21.37
				c-1.46,2.58-4.09,12.62-6.09,30.76c-0.11,1.83-0.26,3.13-0.46,4.46c-1.41,14.51-2.4,33.55-2.21,57.4c-0.2,0.01-0.4,0.02-0.6,0.04
				c0,0.02,0-0.02,0,0c-0.95,0.05-1.98,0.31-3.08,0.66c-7.81,2.46-6.87,11.58-6.87,11.58s-4.29,0.08-5.72,5.66
				c0,0-6.63-2.68-12.67,3c-3.45,3.25-3.92,8.89-3.92,8.89s-5.41,1.66-6.98,10.71c-6.55,7.72-13.07,16.54-22.44,24.78
				c-0.45,0.4-1.46,1.71,2.81,0.7C337.89,266.92,339.66,261.75,348.25,263.37z"
            />
            <path
              fill="#E0DAC7"
              d="M460.51,280.74c0.03,0.08,0.05,0.16,0.08,0.24c-0.01-0.2,0-0.34-0.1-0.44c0,0.01-0.01,0.02-0.01,0.03
				C460.48,280.62,460.49,280.68,460.51,280.74z"
            />
            <path
              fill="#E0DAC7"
              d="M460.76,281.41c-0.01-0.01-0.03-0.02-0.04-0.03c0,0.01,0.01,0.02,0.01,0.03
				C460.74,281.4,460.75,281.41,460.76,281.41z"
            />
            <path
              fill="#E0DAC7"
              d="M460.19,281.74c0.02-0.01,0.03-0.02,0.05-0.04c0.02-0.02,0.03-0.04,0.04-0.06
				c-0.04,0-0.08-0.01-0.12-0.03C460.16,281.66,460.18,281.7,460.19,281.74z"
            />
            <polygon fill="#E0DAC7" points="460.2,281.2 460.2,281.2 460.2,281.2 			" />
            <path
              fill="#E0DAC7"
              d="M459.84,280.65c-0.06-0.03-0.12-0.06-0.17-0.09c-0.03-0.02-0.06-0.04-0.09-0.06
				c0.01,0.04,0.02,0.08,0.04,0.11C459.68,280.68,459.77,280.7,459.84,280.65z"
            />
            <path
              fill="#1C3B4A"
              d="M402.25,257.52c0,0-0.26-1.22-0.81-2.64c-0.54-1.38-1.36-2.95-2.5-3.79c-2.35-1.74,0.01,0.76,0.75,3.78
				c0.1,0.42,0.17,0.84,0.2,1.27L402.25,257.52z"
            />
            <path
              fill="#42637C"
              d="M431.06,256.22c0.39,2.77-0.52,4.33-0.83,6.25c-0.26,1.04-0.26,2.06,0.13,2.99c0,0,0,0,0,0.01
				c1.51,0.69,4.29,1.99,7.11,3.44c0.13,1.03,0.22,1.68,0.55,2.39c0.02,0.05,0.05,0.1,0.07,0.15c0.26,0.51,0.97,0.33,1.45,0.12
				c0.05-0.02,0.1-0.05,0.15-0.08c0.18-0.13,0.29-0.33,0.28-0.55c-0.01-0.21-0.02-0.45-0.01-0.72c0-0.44,0.03-0.96,0.07-1.52
				c0.7-0.29,1.38-0.75,2.12-1.4c0.01,0.01,0.01,0.01,0.01,0.01c0.46-0.39,0.86-0.73,1.2-1.03c0.03-0.04,0.07-0.08,0.11-0.13
				c0.57-0.53,1.3-1.75,2.37-2c1.23-0.3,2.77,0.41,2.76,0.4c0.01,0.01,0.03,0.02,0.04,0.03c-0.21-0.41-0.97-1.89-1.72-3.18
				c0.53-0.88,0.77-1.41,0.77-1.41c0.36,2.37,2.44,3.36,3.81,5.84c1.14,0.35,2.22,0.62,3.38,1.33c0.05,0.02,0.09,0.04,0.12,0.05
				c-1.71-2.67-2.83-9.98-5.04-13.1c-0.57-0.8-1.21-1.33-1.95-1.43c-0.94-0.13-1.91,0.23-2.86,0.86c-0.05-0.03-0.1-0.05-0.16-0.06
				c-1.06-0.23-2.48-0.15-3.98,0.01c-0.02-2.3-0.2-2.97-0.75-3.75c0.01-0.01,0.01-0.02,0.01-0.02c-0.07-0.09-0.14-0.17-0.21-0.26
				c-0.08-0.1-0.16-0.21-0.25-0.32c-0.01-0.02-0.03-0.03-0.04-0.05c-0.42-0.55-0.8-1.03-1.53-1.56c-0.06,0.1-0.11,0.21-0.17,0.33
				c-0.12,0.33-0.26,0.74-0.39,1.38c-0.24,1.18-0.11,2.09-0.11,2.09s-0.29-1.1,0.24-2.9c-0.06,0.18-0.83,2.35-0.55,3.49
				c0,0-0.15-0.21-0.23-0.68c-0.03,0.43-0.01,0.72-0.01,0.72s-0.2,0-0.52-0.03c0-0.28,0.01-1.23,0.32-2.11c0,0-0.44,0.77-0.43,2.1
				c-0.15-0.02-0.31-0.04-0.49-0.07c-1.12-0.19-2.8-0.72-3.63-2.16c-0.99-1.06-0.6-2.87,0.66-3.38c0.46-0.19,0.76-0.47,0.93-0.8
				c-1.64-0.36-2.89,0.12-3.4,2.42c-0.24,1.08-0.33,1.79-0.32,2.46C430.16,251.68,430.58,252.81,431.06,256.22z M440.95,257.62
				c0-0.15,0.01-0.3,0.01-0.44c0-0.14,0.01-0.28,0.01-0.41c0.32-0.09,0.63-0.17,0.92-0.25
				C441.57,256.89,441.25,257.26,440.95,257.62z M446.08,254.39c0.66,0.45,0.66,0.64,0.79,1.09c0.13,0.45,0.35,0.94,0.33,1.17
				c-0.02,0.15-0.12,0.33-0.18,0.43c0.01,0.26-0.05,0.48-0.2,0.7c0,0-0.46,0.58-0.51,0.37c-0.05-0.21,0.23-0.63,0.16-0.93
				c-0.06-0.27-0.46-1.64-0.42-1.34s0.28,0.93,0.23,1.25c-0.03,0.2-0.04,0.57-0.07,0.92c-0.02,0.2-0.05,0.4-0.09,0.54
				c-0.12,0.39-0.38,0.16-0.4,0.05c-0.03-0.18,0-0.1-0.02-0.59c-0.02-0.49-0.23-2.18-0.23-2.18s0.09,0.79,0.12,1.39
				c0.02,0.6-0.08,1.12-0.19,1.22c-0.09,0.07-0.19,0.13-0.25-0.07c0-0.01,0-0.02,0-0.03c-0.06-0.34-0.01-0.91-0.06-1.18
				c-0.04-0.2-0.08-0.81-0.12-1.21c-0.02-0.35-0.05-0.52-0.16-0.32c-0.16,0.3-0.14,0.88-0.14,1.37c0,0.49-0.44,0.79-0.39,0.39
				c0.05-0.39,0-0.72-0.19-1.18c-0.19-0.46,0-1.07,0.05-1.44c0.05-0.37,0.51-1.16,1.07-1.11
				C445.59,253.75,445.43,253.94,446.08,254.39z"
            />
            <path
              fill="#AC7D61"
              d="M455.09,267.28c-0.01-0.02-0.02-0.04-0.03-0.06c0,0-0.02-0.01-0.06-0.03c-0.03-0.01-0.07-0.03-0.12-0.05
				C454.95,267.19,455.02,267.24,455.09,267.28z"
            />
            <path
              fill="#AC7D61"
              d="M461.26,269.92C461.27,269.93,461.27,269.93,461.26,269.92c0-0.03,0-0.07,0.01-0.11
				C461.27,269.85,461.27,269.89,461.26,269.92z"
            />
            <path
              fill="#1EBDE4"
              d="M439.96,270.93c0.01,0.22-0.09,0.42-0.28,0.55c0.06,0.19,0.14,0.32,0.2,0.42
				c0.13,0.21,0.25,0.24,0.35,0.47c0.09,0.22,0.25,0.53,0.53,0.63c0.52,0.17,0.88,0.12,0.64,0.3c-0.23,0.18-0.61,0.19-1.13,0.06
				c-0.51-0.13-0.64-0.26-0.83-0.17c-0.37,0.27-0.43,0.45-0.53,0.84c-0.16,0.6-0.18,0.89-0.44,1.48c-0.08,0.18-0.1,0.51-0.34,0.48
				c-0.32-0.07-0.05-0.55-0.01-0.71c0.17-0.69,0.18-0.89,0.36-1.58c-0.41,0.19-0.46,1.35-1.17,2.28c-0.09,0.04-0.3,0-0.31-0.15
				c-0.02-0.15,0.16-0.58,0.22-0.73c0.32-0.87,0.32-0.83,0.64-1.7c-0.35,0.62-0.56,0.76-0.91,1.38c-0.16,0.28-0.31,0.65-0.57,0.5
				c-0.17-0.2,0.05-0.51,0.15-0.69c0.38-0.66,0.32-0.57,0.69-1.22c-0.49,0.59-1.01,1.41-1.24,0.98c-0.09-0.27,0.92-1.03,1.39-1.91
				c0.15-0.27,0.23-0.57,0.4-0.83c0.07-0.1,0.15-0.21,0.25-0.29c-0.33-0.71-0.42-1.36-0.55-2.39c-2.82-1.45-5.61-2.75-7.11-3.44
				c0,0,0,0,0-0.01c-8.25-3.76-14.62-6.25-21.53-8.72c-3.23-1.15-5.65-1.7-7.39-1.86c0.56,1.42,0.81,2.64,0.81,2.64l-2.37-1.38
				c-0.02-0.43-0.09-0.85-0.2-1.27c-1.11,0.11-1.7,0.47-1.87,0.94c-0.01,0-0.02-0.01-0.02-0.01c-0.54,0.74-0.29,3.16,4.1,6.45
				c3.29,2.46,10.52,6.02,20.46,10.27c6.97,1.49,12.87,2.93,17.35,5.52c2.18,1.26,4.27,2.67,6.29,4c0.14,0.05,0.27,0.1,0.41,0.16
				c7.65,2.94,10.3,2.92,10.38,1.78c0.02-0.13,0.01-0.28-0.04-0.47c-1.22-4.08-9.46-9.3-16.37-13.06c-0.14-0.08-0.28-0.15-0.42-0.23
				C439.95,270.47,439.95,270.72,439.96,270.93z"
            />
            <path
              fill="#AC7D61"
              d="M437.77,271.58c-0.17,0.27-0.25,0.56-0.4,0.83c-0.47,0.88-1.47,1.64-1.39,1.91
				c0.24,0.43,0.75-0.39,1.24-0.98c-0.38,0.66-0.32,0.57-0.69,1.22c-0.1,0.18-0.32,0.49-0.15,0.69c0.26,0.16,0.41-0.22,0.57-0.5
				c0.35-0.62,0.56-0.76,0.91-1.38c-0.32,0.87-0.32,0.83-0.64,1.7c-0.06,0.15-0.23,0.58-0.22,0.73c0.02,0.15,0.23,0.19,0.31,0.15
				c0.72-0.93,0.76-2.09,1.17-2.28c-0.17,0.69-0.18,0.89-0.36,1.58c-0.04,0.16-0.32,0.64,0.01,0.71c0.24,0.03,0.26-0.3,0.34-0.48
				c0.26-0.59,0.27-0.87,0.44-1.48c0.1-0.39,0.16-0.57,0.53-0.84c0.19-0.09,0.32,0.04,0.83,0.17c0.51,0.13,0.9,0.12,1.13-0.06
				c0.24-0.18-0.12-0.13-0.64-0.3c-0.28-0.09-0.44-0.4-0.53-0.63c-0.1-0.23-0.22-0.27-0.35-0.47c-0.06-0.1-0.14-0.23-0.2-0.42
				c-0.04,0.03-0.09,0.06-0.15,0.08c-0.48,0.2-1.19,0.39-1.45-0.12c-0.02-0.05-0.05-0.1-0.07-0.15
				C437.92,271.38,437.84,271.48,437.77,271.58z"
            />
            <path
              fill="#AC7D61"
              d="M440.3,242.83c-0.07,0.12-0.13,0.24-0.19,0.34c-0.15-0.24-0.34-0.26-0.45-0.12
				c-0.1,0.12-0.18,0.78-0.01,1.32c-0.01,0.08-0.02,0.16-0.03,0.25c-0.08,1.15-0.58,1.69-1.05,2.38c0.94,0.05,1.29-0.29,1.46-1.07
				c0.11-0.49,0.38-0.89,0.45-1.38c0.06-0.49-0.02-0.65,0.18-1.52c0.07-0.31,0.09-0.53,0.08-0.72
				C440.63,242.49,440.49,242.67,440.3,242.83z"
            />
            <path
              fill="#AC7D61"
              d="M445.19,253.71c-0.55-0.05-1.02,0.74-1.07,1.11c-0.05,0.37-0.23,0.97-0.05,1.44
				c0.19,0.46,0.23,0.79,0.19,1.18c-0.05,0.39,0.39,0.09,0.39-0.39c0-0.49-0.02-1.07,0.14-1.37c0.11-0.2,0.14-0.03,0.16,0.32
				c0.04,0.39,0.08,1.01,0.12,1.21c0.05,0.27,0,0.84,0.06,1.18c0,0.01,0,0.02,0,0.03c0.06,0.21,0.16,0.15,0.25,0.07
				c0.12-0.09,0.21-0.61,0.19-1.22c-0.02-0.6-0.12-1.39-0.12-1.39s0.21,1.69,0.23,2.18c0.02,0.49-0.01,0.4,0.02,0.59
				c0.02,0.11,0.28,0.34,0.4-0.05c0.04-0.15,0.07-0.34,0.09-0.54c0.03-0.34,0.04-0.71,0.07-0.92c0.05-0.32-0.19-0.95-0.23-1.25
				s0.35,1.07,0.42,1.34c0.07,0.3-0.21,0.72-0.16,0.93c0.05,0.21,0.51-0.37,0.51-0.37c0.15-0.22,0.21-0.45,0.2-0.7
				c0.07-0.1,0.17-0.27,0.18-0.43c0.02-0.23-0.19-0.72-0.33-1.17c-0.13-0.45-0.13-0.64-0.79-1.09
				C445.43,253.94,445.59,253.75,445.19,253.71z"
            />
            <path
              fill="#4D2C1B"
              d="M432.92,246.28c-1.27,0.52-1.65,2.33-0.66,3.38c0.83,1.44,2.5,1.97,3.63,2.16
				c0.18,0.03,0.34,0.05,0.49,0.07c-0.01-1.32,0.43-2.1,0.43-2.1c-0.31,0.88-0.32,1.83-0.32,2.11c0.32,0.03,0.52,0.03,0.52,0.03
				s-0.02-0.29,0.01-0.72c0.08,0.47,0.23,0.68,0.23,0.68c-0.28-1.14,0.49-3.31,0.55-3.49c-0.53,1.79-0.24,2.9-0.24,2.9
				s-0.13-0.91,0.11-2.09c0.13-0.64,0.27-1.05,0.39-1.38c0.05-0.12,0.11-0.22,0.17-0.33c0.1-0.18,0.21-0.35,0.32-0.51
				c0.48-0.69,0.97-1.23,1.05-2.38c0.01-0.09,0.02-0.17,0.03-0.25c-0.18-0.54-0.1-1.19,0.01-1.32c0.12-0.14,0.31-0.12,0.45,0.12
				c0.06-0.11,0.12-0.22,0.19-0.34c0.19-0.16,0.33-0.33,0.43-0.51c0.42-0.72,0.25-1.55,0.05-2.05c-0.01-0.03-0.02-0.06-0.03-0.1
				c0,0-0.46-1.27-1.76-1.68c-0.47-0.15-0.73-0.25-1.43-0.25c-2.84,0.01-3.9,2.12-3.55,5.23l0,0c0,0.02,0.01,0.03,0.01,0.05
				c0.02,0.15,0.04,0.3,0.06,0.45c0.05,0.53,0.02,1.07-0.22,1.52C433.67,245.81,433.38,246.09,432.92,246.28z"
            />
            <path
              fill="#E0DAC7"
              d="M585.26,340.9c-7.3,0.17-15.71,0-24.88-0.47c-1.74-0.17-3.57-0.31-5.48-0.42
				c-3.52-0.2-6.93-0.41-10.22-0.62c-1.8-0.14-3.61-0.3-5.44-0.46c-10.3-0.92-21.14-2.14-32.13-3.63
				c-16.84-3.16-24.28-7.95-19.07-15.86c3.59-5.45,7.34-9.18,9.26-12.42c1.52-2.57,1.89-4.84,0.12-7.43
				c-2.9-5.52-10.52-12.7-16.51-14.89c-4.88-1.78-6.2-1.32-7.84-1.88c-0.27-0.18-0.55-0.36-0.83-0.54c0,0.01,0,0.02,0.01,0.03
				c-8.56-5.47-11.99-9.13-12.82-10.09c-0.6-0.92-1.23-1.88-2.15-2.95c-0.78-0.9-1.5-1.53-2.19-1.98c-0.07-0.05-0.14-0.09-0.21-0.13
				c-1.15-0.71-2.24-0.98-3.38-1.33c-0.9-0.28-1.83-0.61-2.86-1.25c-0.01-0.01-0.03-0.02-0.04-0.03c0.01,0.01-1.53-0.7-2.76-0.4
				c-1.07,0.26-1.8,1.47-2.37,2c-0.04,0.04-0.08,0.08-0.11,0.13c-0.35,0.29-0.74,0.63-1.2,1.03c0,0-0.01-0.01-0.01-0.01
				c-0.74,0.66-1.42,1.11-2.12,1.4c-0.04,0.56-0.06,1.08-0.07,1.52c0.14,0.08,0.28,0.15,0.42,0.23c6.91,3.75,14.46,8.73,15.87,11.99
				c0.08,0.18,0.07,1.37,0.05,1.49c-0.07,1.14-2.22,1.21-9.87-1.73c-0.14-0.05-0.27-0.1-0.41-0.16c-2.01-1.33-4.11-2.74-6.29-4
				c-4.48-2.59-10.38-4.03-17.35-5.52c-9.94-4.25-17.18-7.81-20.46-10.27c-4.39-3.29-4.64-5.71-4.1-6.45c0,0,0.01,0,0.02,0.01
				c0.17-0.46,0.76-0.83,1.87-0.94c-0.74-3.02-3.1-5.52-0.75-3.78c1.14,0.84,1.96,2.41,2.5,3.79c1.74,0.16,4.16,0.7,7.39,1.86
				c6.9,2.47,13.28,4.96,21.53,8.72c-0.39-0.93-0.39-1.96-0.13-2.99c0.3-1.92,1.21-3.48,0.83-6.25c-0.47-3.4-0.9-4.54-0.92-5.86
				c-7.03-6.46-14.81-13.76-26.26-18.52c-1.35-0.56-2.74-1.08-4.19-1.57c-3.57-1.11-8.59-2.31-15.27-3.17
				c-2.02-0.2-4.11-0.33-6.31-0.38c-9.17-0.22,10.55,0.03,6.41,18.03c-1.13,4.93-0.56,10.2,2.75,15.17
				c-0.08-0.66-0.06-1.32,0.08-1.97c0.01-0.05,0.08-0.04,0.08,0.01c0.04,0.9,0.34,1.8,0.85,2.55c0.02,0.03,0.08,0.02,0.08-0.03
				l-0.04-1.35c0-0.05,0.07-0.06,0.08-0.01c0.18,0.84,0.42,1.67,0.72,2.48c0.02,0.04,0.08,0.03,0.08-0.02
				c-0.13-1.04-0.05-2.1,0.23-3.1c0.01-0.05,0.08-0.04,0.08,0.01c-0.04,1.14,0.16,2.29,0.6,3.34c0.02,0.04,0.08,0.03,0.08-0.01
				l0.12-1.32c0-0.05,0.07-0.05,0.08,0c0.12,0.92,0.31,1.83,0.57,2.72c0.01,0.04,0.08,0.04,0.08-0.01c0.08-0.94,0.24-1.88,0.49-2.79
				c0.01-0.04,0.07-0.04,0.08,0c0.28,1.09,0.22,2.25,0.37,3.37c0.09,0.65,0.27,1.32,0.59,1.88c5.52,4.52,14.04,8.5,26.53,11.34
				c3.76,0.85,6.88,1.84,9.46,2.94c-0.21-0.64-0.31-1.31-0.28-1.99c0-0.03,0.05-0.04,0.06-0.01c0.29,0.67,0.66,1.29,1.1,1.87
				c0.02,0.03,0.06,0.01,0.05-0.03c-0.24-0.85-0.35-1.73-0.33-2.61c0-0.04,0.05-0.04,0.06-0.01c0.16,0.67,0.48,1.65,1.07,2.18
				c0.02,0.02,0.06,0,0.06-0.02c-0.02-0.89-0.53-2.11-0.46-2.14c0.16-0.07,0.88,1,1.48,3.11c0.01,0.03,0.06,0.02,0.06-0.02
				c-0.01-0.87,0.03-1.74,0.12-2.61c0-0.04,0.05-0.04,0.06,0c0.31,1.24,0.65,2.49,1.31,3.58c0.02,0.03,0.06,0.02,0.06-0.02
				c0.04-0.79,0.1-1.58,0.19-2.36c0-0.04,0.06-0.04,0.06,0c0.26,1.83,0.78,3.71,1.96,5.1c14.1,12.59-10.5,33.98,6.18,51.52
				c0.29,0.31,0.6,0.61,0.92,0.92c4.22,3.14,9.09,6.22,14.74,9.12l-0.12-0.21c6.93,3.61,41.11,17.47,92.61,17.47
				c79.92,0,99.62-14.85,104.46-21.88C648.02,340.82,608.32,340.37,585.26,340.9z"
            />
            <path
              opacity="0.3"
              d="M411.04,256.05c-0.82,0.15-0.51,1.2-0.14,1.45l3.72,1.37c0,0,3.41,2.42,4.78,3.41s11.98,12.32,12.17,12.44
				c0.19,0.12,1.77,0.47,1.98,0.65c0.41,0.35,7.94,8,8.31,8.94c0.37,0.93-0.68,0.62-0.37,1.37s1.74,0.37,2.98,0.19
				c1.24-0.19,1.05-1.12,0.12-1.12s-1.43-1.49-1.43-1.49l-19.92-18.74l-5.96-5.21L411.04,256.05z"
            />
            <path
              fill="#42637C"
              d="M421.5,262.26c-0.43-0.42-0.74-0.91-1.06-1.85s-1.81-4.28-3.14-5.63c-1.33-1.35-2.99-1.39-4.49-1.15
				c-1.51,0.25-3.57,0.86-1.78,2.42c1.79,1.56,6.94,4.64,8.32,5.56c1.37,0.93,2.23,1.8,3.08,2.67c0.86,0.87,9.81,9.33,11.55,11.04
				c1.74,1.71,9.71,9.15,9.71,9.15s-0.88,0.18-0.58,0.58s0.69,0.22,1.46,0.09c0.77-0.14,1.5-0.34,1.18-0.7
				c-0.33-0.37-1.01,0.09-1.19-0.16C444.56,284.28,422.68,263.41,421.5,262.26z"
            />
          </g>
          <path
            opacity="0.3"
            d="M392.84,217.68c2.85,1.7,3.97,4.98,4.07,6.37c0,0,1.67-0.12,3.65,0.67c0.6-4.79,0.99-11.56,0.55-20.6
			c-0.46-9.37-3.51-15.27-3.51-20.9s2.57-10.18,1.64-17.15c-0.92-6.84-1.82-19.28-1.09-22.65c0.01-0.06,0.03-0.12,0.04-0.18
			c0,0-0.02,0.05-0.05,0.15c-0.09,0.27-0.31,0.89-0.6,1.69c-0.48,1.34-1.17,3.17-1.82,4.65c-0.52,1.19-0.95,3.08-1.24,5.36
			c-0.26,1.99-0.41,4.28-0.44,6.7c-0.01,0.56-0.01,1.13,0,1.71c0.01,0.5,0.02,1,0.04,1.5c0.14,3.78-0.61,6.17-1.4,9.18
			c-0.19,0.71-0.37,1.46-0.55,2.27C393.25,188.78,393.53,205.58,392.84,217.68z"
          />
          <path
            opacity="0.3"
            d="M442.4,279.68c2.85,1.82,5.56,3.74,8.11,5.19c3.43,1.4,5.26,0.37,5.65-0.26c0.25-0.36,0.06-1.87,0.08-2.17
			C455.83,283.88,449.99,282.58,442.4,279.68z"
          />
          <path
            opacity="0.3"
            d="M402.25,257.52l-2.37-1.38l-1.38,1.35c-0.35-0.43-0.67-0.83-0.73-1.27c-0.02-0.14,0-0.28,0.04-0.41
			c-0.01,0-0.02-0.01-0.02-0.01c-0.09,0.13-0.16,0.3-0.19,0.53c-1.75,1.37,1.63,8.38,11.7,13.14c1.77,0.55,3.66,1.07,5.64,1.5
			c4.1,0.88,9.01,1.91,12.5,2.72c-12.63-5.22-23.25-10.76-27.33-14.65L402.25,257.52z"
          />
          <g>
            <path
              opacity="0.3"
              d="M404.58,232.05c-0.38-1.56-1-4.98-3.97-6.41c-4.9-2.36-9.17,1.86-8.5,0.38c0.49-1.08,1.3-1.81,1.7-2.12
				c0.14-0.11,0.15-0.29,0.09-0.46c-0.68-1.75-2.22-4.32-6.17-4.3c-4.32,0.03-6.59,4.4-6.59,4.4c0.64-2.78,1.94-4.4,2.44-4.96
				c0,0.01,0.01,0.02,0.01,0.03c0.93-0.97,2.19-1.7,3.91-1.91c1.44-0.18,2.69-0.06,3.75,0.27c-0.02-11.53-1.36-26.04-2.53-37.51
				c-1.91-18.71-5.29-39.44-6.05-45.28c-1.2-9.28-0.61-15.91-0.36-18.57c0-0.01,0-0.02,0-0.03c-0.01-0.01-0.03-0.01-0.04-0.02
				c-0.04-0.02-0.07-0.03-0.11-0.04c-1.51,2.74-4.24,13.34-6.25,32.46c-0.04,0.44-0.09,0.88-0.14,1.33
				c-1.49,14.67-2.54,34.17-2.35,58.78c9.16-0.53,9.99,5.3,10.05,5.49c0.04,0.12-1.92-2.67-5.81-3.53c-2.06-0.45-4.97-0.39-7.6,1.32
				c-4.65,3.02-4.21,8.84-4.21,8.84c3.07,0.38,4.77,1.86,5.73,2.78c0.08,0.08-0.02,0.21-0.12,0.14c-2.47-1.65-4.99-1.77-6.34-1.67
				c-6.79,0.7-8.13,6.96-8.31,8c-10.35,4.36-17.98,12.59-20.99,16.49c-1.4,1.81-3.95,4.85-6.86,8.22
				c-4.71,5.47-10.37,11.83-13.59,15.28c-1.07,1.15-1.87,1.97-2.28,2.34c-1.34,1.19-2.43,2.02-2.39,2.39
				c0.01,0.05,0.03,0.09,0.08,0.12c0.24,0.16,1.03,0.11,2.63-0.18c0.55-0.1,1.25-0.27,2.05-0.49c22.48-6.94,24.36-11.85,32.77-10.27
				c10.65,2.01,10.85,13.96,18.7,21.93c2.7,2.74,5.44,4.97,21.12,6.48c-22.61-9.94-14.94-20.49-19.18-29.15
				c-4.24-8.66-5.21-12.33,4.16-18.87c9.37-6.54,6.06-16.51-0.16-17.03c-0.08-0.01-0.16-0.01-0.24-0.02c3.7-0.08,5.46,0,5.46,0
				c11.57,0.32,21.55,3.56,25.1,4.85c0.26,0.09,0.48,0.18,0.67,0.25c0.46,0.18,0.71,0.28,0.71,0.28
				C404.59,232.08,404.59,232.07,404.58,232.05z"
            />
          </g>
          <path
            opacity="0.3"
            d="M378.11,226.73C378.11,226.73,378.11,226.73,378.11,226.73C378.11,226.73,378.11,226.73,378.11,226.73z"
          />
          <path
            opacity="0.3"
            d="M378.11,226.72C378.11,226.72,378.11,226.72,378.11,226.72C378.11,226.72,378.11,226.72,378.11,226.72z"
          />
          <path opacity="0.3" d="M378.1,226.75C378.1,226.75,378.09,226.75,378.1,226.75L378.1,226.75z" />
          <path opacity="0.3" d="M378.1,226.75c0,0,0-0.01,0.01-0.02C378.1,226.74,378.1,226.75,378.1,226.75z" />
          <path
            opacity="0.3"
            d="M430.35,252.11c0.04,0.24,0.09,0.48,0.14,0.72c0.14,0.64,0.31,1.27,0.5,1.86c0.94,2.93,0.37,5.52-0.2,7.62
			c-0.55,2.02-0.34,2.28-0.16,3.19c-0.1-0.04-0.2-0.08-0.29-0.12c-0.04-0.02-0.08-0.03-0.12-0.05c0,0-0.01,0.02-0.02,0.04
			c-0.16,0.31-1.05,2.31,1.56,4.17c1.85,1.32,4.05,1.65,6.24,1.65c-0.3-0.68-0.39-1.33-0.51-2.33c-0.56-0.3-1.15-0.61-1.75-0.92
			l1.79,0.82c0,0,0.23,2.9,1.1,2.96c0.15,0.01,0.28,0.01,0.4,0c-0.37,0.08-0.74,0.05-0.92-0.3c-0.02-0.05-0.05-0.1-0.07-0.15
			c-0.1,0.09-0.19,0.19-0.25,0.29c-0.17,0.27-0.25,0.56-0.4,0.83c-0.47,0.88-1.47,1.64-1.39,1.91c0.04,0.08,0.1,0.12,0.16,0.12
			c-0.2,0.35-0.22,0.56-0.09,0.66c0.21,0.16,0.42,0.44,0.67,0.64c0.25,0.2,0.46,0.55,0.85,0.42c0.38-0.13,0.88-0.02,1.05-0.37
			c0.18-0.35,0.68-1.91,0.91-2.13c0.23-0.22,1.25-0.02,1.6-0.11c0.34-0.09,0.49-0.2,0.28-0.26c0.07-0.05,0.08-0.06,0.06-0.05
			c-0.06,0.02-0.38,0.16-0.76,0.04c-0.28-0.09-0.55-0.51-0.64-0.74c-0.1-0.23-0.24-0.38-0.37-0.59c-0.06-0.1-0.09-0.23-0.11-0.42
			c-0.01,0.01-0.03,0.02-0.04,0.02c-0.01,0-0.02,0.01-0.03,0.01c0.24-0.17,0.37-0.46,0.41-0.95c0.01-0.06,0.01-0.15,0.02-0.27
			c0,0.21,0,0.41,0.02,0.58c0,0.08-0.01,0.15-0.03,0.22c0.5-0.03,0.99-0.07,1.47-0.1c0.64,0.34,1.43,0.77,2.22,1.24
			c0.02,0.01,0.04,0.02,0.06,0.02c0.06,0,0.08-0.08,0.21-0.21c0.7-0.75,5.87-6.07,11.07-4.89c0.03,0.01,0.06,0.01,0.09,0.02
			c0,0,0,0,0,0c0,0-0.03-0.02-0.08-0.05c-0.19-0.12-0.73-0.46-1.43-0.78c-0.06-0.43-0.28-1.93-0.99-4.75
			c-1.89-7.47-3.1-8.35-3.9-8.72c-0.75-0.35-1.91,0.15-3.09,1c-0.02-0.02-0.03-0.03-0.04-0.04c-0.07-0.06-0.12-0.11-0.17-0.13
			c-0.12-0.07-0.17-0.03-0.17-0.03s-0.15-0.03-0.21,0.02c-0.01,0.01-0.01,0.01-0.02,0.02c0,0-0.01,0-0.02-0.01
			c-0.11-0.03-0.6-0.17-1.77-0.12c-1.24,0.05-2.17,0.17-2.17,0.17l0.01,2.95c0,0,1.12-0.32,2.04-0.47c-0.3,0.35-0.58,0.7-0.81,1.03
			c-0.66,0.94-1.16,1.76-1.16,1.76l-0.73,9.45c-0.1,0.04-0.2,0.08-0.3,0.11c0.16-2.91,0.41-8.1,0.51-10.32
			c0.14-2.99,0.03-5.02-0.53-6.92c-0.51-1.74-1.57-3.03-1.94-3.46c-0.01-0.01-0.02-0.03-0.04-0.04c0.01-0.02,0.01-0.03,0.02-0.05
			c0.05-0.12,0.11-0.22,0.17-0.33c0.1-0.18,0.21-0.35,0.32-0.51c0.48-0.69,0.97-1.23,1.05-2.38c0.01-0.09,0.02-0.17,0.03-0.25
			c-0.18-0.54-0.1-1.19,0.01-1.32c0.12-0.14,0.31-0.12,0.45,0.12c0.06-0.11,0.12-0.22,0.19-0.34c0.19-0.16,0.33-0.33,0.43-0.51
			c0.42-0.72,0.25-1.55,0.05-2.05c-0.01-0.03-0.02-0.06-0.03-0.1c0,0-0.46-1.27-1.76-1.68c-0.47-0.15-4.89,0.04-4.91,5.48
			c0.05,0.53,0.02,1.07-0.22,1.52c-0.07,0.14-0.16,0.26-0.28,0.38c-1.27-0.12-1.82-0.38-2.36,0.32c-0.17,0.22-0.31,0.46-0.44,0.71
			c-0.4,0.8-0.58,1.75-0.61,2.75C430.14,250.45,430.21,251.29,430.35,252.11z M436.96,259.86c0.41-1.19,0.69-2.33,0.84-3.49
			l0.01,0.32c0,0-0.37,3.21-0.38,3.54c-0.04,0.75,0.07,2.73,0.15,3.88c0.03,0.2,0.05,0.41,0.06,0.62c0.03,0.52-0.01,0.13-0.06-0.62
			C437.34,262.27,436.59,260.94,436.96,259.86z M448.03,261.13c0.18,0.36,0.44,0.79,0.85,1.27c0.26,0.3,0.5,0.6,0.72,0.89
			c0.8,1.03,1.38,1.91,1.82,2.44c-0.05-0.01-0.1-0.01-0.15-0.02c0,0-0.03,0-0.08,0.01c-0.55,0.06-4,0.5-6.75,2.6
			c0.69-1.11,1.97-2.35,4.26-3.64c0.04-0.02,0.02-0.06-0.03-0.1c-0.01-0.01-0.02-0.01-0.03-0.02c-0.19-0.13-0.71-0.31-1.31-0.41
			c-0.35-0.06-0.73-0.09-1.08-0.07c-0.14,0.01-0.26,0.02-0.37,0.05c-0.39,0.07-0.66,0.22-0.97,0.5c-0.19,0.17-0.4,0.38-0.66,0.65
			c0.07-0.08,0.14-0.15,0.21-0.23c0.02-0.02,0.03-0.05,0.05-0.07c1.22-1.73,2.22-3.23,2.82-4.3c0.11-0.21,0.21-0.4,0.3-0.57
			c0.02-0.05,0.05-0.1,0.07-0.14C447.69,259.98,447.69,260.42,448.03,261.13z M447.2,256.65c0,0.04-0.01,0.08-0.03,0.12
			c0.02-0.05,0.02-0.1,0.03-0.15C447.2,256.63,447.2,256.64,447.2,256.65z M445.51,256.27c-0.02-0.22-0.04-0.38-0.04-0.38s0,0,0,0
			C445.47,255.9,445.49,256.05,445.51,256.27z M445.59,257.28c0.02,0.53-0.05,0.99-0.15,1.16c-0.01,0.02-0.03,0.04-0.04,0.05
			c-0.03,0.02-0.05,0.04-0.08,0.05c0.03-0.01,0.05-0.03,0.08-0.05c0.01-0.01,0.03-0.03,0.04-0.05
			C445.53,258.27,445.61,257.8,445.59,257.28z M445.15,258.43c0.01,0.03,0.02,0.06,0.03,0.08
			C445.17,258.49,445.16,258.46,445.15,258.43z M445.29,258.56c-0.01,0-0.02,0-0.03,0C445.27,258.56,445.28,258.56,445.29,258.56z
			 M444.06,256.2c0.01,0.02,0.01,0.04,0.02,0.06c0.02,0.04,0.03,0.08,0.05,0.12c-0.01-0.04-0.03-0.08-0.05-0.12
			C444.07,256.24,444.07,256.22,444.06,256.2L444.06,256.2z M445.71,258.46c0,0.05,0,0.1,0.01,0.14
			C445.71,258.54,445.71,258.52,445.71,258.46z M446.58,258.05c-0.11,0.1-0.22,0.19-0.26,0.14
			C446.36,258.24,446.47,258.15,446.58,258.05z M446.43,257.6c-0.02,0.07-0.05,0.15-0.07,0.22
			C446.39,257.75,446.41,257.68,446.43,257.6z M446.3,258.07C446.3,258.07,446.3,258.07,446.3,258.07
			C446.3,258.07,446.3,258.07,446.3,258.07z M446.46,257.47c0.01-0.08,0.02-0.16,0-0.23C446.48,257.31,446.48,257.39,446.46,257.47z
			 M446.05,255.89c0.02,0.15,0.09,0.38,0.15,0.62C446.14,256.27,446.07,256.04,446.05,255.89c0-0.02,0-0.03,0-0.04
			C446.05,255.86,446.05,255.87,446.05,255.89z M447.04,257.05c0,0,0-0.01,0-0.01c0.04-0.06,0.08-0.12,0.1-0.18
			C447.11,256.93,447.07,256.99,447.04,257.05z M447.04,255.98c0.05,0.15,0.1,0.29,0.13,0.41
			C447.12,256.24,447.08,256.1,447.04,255.98z M447.17,256.77c-0.01,0.03-0.02,0.05-0.03,0.08
			C447.16,256.83,447.17,256.8,447.17,256.77z M445.72,258.65c0.01,0.03,0.03,0.06,0.06,0.09
			C445.75,258.71,445.73,258.68,445.72,258.65z M445.7,258.07C445.7,258.07,445.7,258.07,445.7,258.07
			C445.7,258.06,445.7,258.06,445.7,258.07C445.7,258.06,445.7,258.06,445.7,258.07C445.7,258.07,445.7,258.07,445.7,258.07z
			 M443.94,256.18c-0.04,0-0.08,0-0.12,0c-0.01,0-0.03,0-0.05,0C443.83,256.18,443.88,256.18,443.94,256.18z M446.01,254.34
			c-0.1-0.06-0.18-0.12-0.24-0.19c0,0,0,0-0.01-0.01C445.83,254.2,445.91,254.27,446.01,254.34z M441.23,268
			c-0.26,0.18-0.5,0.34-0.74,0.45C440.64,268.37,440.9,268.22,441.23,268z"
          />
          <g>
            <path
              fill="none"
              d="M462.12,279.83c-0.05,0.07-0.09,0.14-0.14,0.21c0.13-0.11,0.24-0.19,0.28-0.3c-0.02,0-0.03-0.01-0.05-0.01
				C462.18,279.76,462.15,279.8,462.12,279.83z"
            />
            <path fill="none" d="M465.46,281.53c0.01,0,0.02,0,0.03,0C465.48,281.53,465.47,281.53,465.46,281.53z" />
            <path
              fill="none"
              d="M461.13,280.19c0.02,0.01,0.04,0.01,0.06,0.01c0.02,0,0.05,0,0.07-0.01c-0.02-0.03-0.04-0.07-0.05-0.11
				C461.18,280.13,461.16,280.16,461.13,280.19z"
            />
            <path
              fill="none"
              d="M465.41,281.54c0.01-0.01,0.03-0.01,0.05-0.01c-0.03,0-0.05,0.01-0.08,0.02
				C465.39,281.55,465.39,281.54,465.41,281.54z"
            />
            <path
              fill="none"
              d="M461.76,279.24c0.01-0.01,0.01-0.01,0.02-0.02c-0.01-0.05-0.02-0.11-0.03-0.16c0-0.04,0-0.07,0-0.11
				c-0.02,0.03-0.05,0.06-0.06,0.11C461.66,279.13,461.69,279.2,461.76,279.24z"
            />
            <path
              fill="none"
              d="M461.75,280.44c0-0.02,0-0.03,0-0.05c-0.01,0.01-0.01,0.02-0.02,0.02
				C461.74,280.42,461.74,280.43,461.75,280.44z"
            />
            <polygon fill="none" points="461.56,279.87 461.56,279.87 461.56,279.87 			" />
            <path
              fill="#4D2C1B"
              d="M465.3,271.56c0.02-0.03,0.04-0.07,0.06-0.1c0,0,0,0,0,0c-0.03,0.02-0.06,0.03-0.09,0.05
				C465.28,271.52,465.29,271.54,465.3,271.56z"
            />
            <path
              fill="#4D2C1B"
              d="M464.29,272.4c0.3-0.77,0.81-1.1,0.96-0.94c0.04-0.01,0.08-0.02,0.11-0.02c-0.01-0.08,0-0.15,0.05-0.21
				c0.35-0.46,0.93-0.68,0.93-0.68s0,0,0,0.01c0.76-0.5,1.4-0.69,2.01-0.73c0-0.01,0-0.01,0-0.01s-0.22-0.33-0.35-0.65
				c-0.55,0.29-1.41,0.47-2.68,0.48c-0.3,0-0.57,0-0.84-0.02c0,0.02,0,0.04,0,0.06c-0.03,0.31-0.12,0.44-0.21,0.49
				c0,0.03,0.01,0.06,0.01,0.1c0,0.39-0.03,1.03-0.06,1.58c-0.11-0.02-0.22-0.05-0.29-0.12c-0.07-0.07-0.12-0.09-0.17-0.11
				c0.01,0.42-0.06,0.87-0.26,1.36c0.25,0.05,0.47,0.1,0.68,0.12C464.17,272.86,464.2,272.63,464.29,272.4z"
            />
            <path
              fill="#AC7D61"
              d="M475.85,283.33c0.03-0.99-0.63-0.81-1.02-1.15c-0.38-0.33-0.21-0.44-1.15-1.12
				c-0.41,0.54-1.02,0.99-1.97,1.21c-0.4,0.09-0.76,0.13-1.07,0.12c1.4,0.52,2.92,0.95,3.22,1.15c0.46,0.3,0.77,2.24,1.29,3.01
				c0.53,0.77,0.63,0.49,0.93,0.13c0.2-0.24,0.46-0.3,0.48-0.86C476.6,285.27,475.81,284.71,475.85,283.33z"
            />
            <path
              fill="#AC7D61"
              d="M462.22,269.5C462.23,269.5,462.23,269.51,462.22,269.5c0-0.03,0-0.07,0.01-0.11
				C462.22,269.43,462.23,269.46,462.22,269.5z"
            />
            <path
              fill="#AC7D61"
              d="M464.23,271.84c0.03-0.55,0.06-1.19,0.06-1.58c0-0.03-0.01-0.07-0.01-0.1c0.09-0.05,0.17-0.18,0.21-0.49
				c0-0.02,0-0.04,0-0.06c-0.3-0.01-0.59-0.03-0.85-0.06c0.03,0.22,0.12,0.44,0.2,0.66c-0.13-0.15-0.25-0.32-0.34-0.49
				c0,0.18,0.04,0.36,0.12,0.51c-0.21-0.26-0.48-0.48-0.78-0.63c0.01,0.04,0.01,0.08,0.02,0.13c-0.13-0.06-0.25-0.15-0.35-0.25
				c0.03,0.09,0.04,0.18,0.01,0.27c-0.06-0.1-0.13-0.19-0.2-0.27c0,0.06,0,0.11,0.01,0.17c0.13,0.19,0.27,0.38,0.33,0.52
				c0.12,0.25,0.25,0.27,0.36,0.34c0.1,0.07,0.25,0.76,0.46,0.96c0.14,0.14,0.21,0.12,0.3,0.16c0.05,0.02,0.1,0.05,0.17,0.11
				C464.01,271.8,464.12,271.83,464.23,271.84z"
            />
            <path
              fill="#AC7D61"
              d="M472.37,272.75c0.06,0.43,0.24,0.81,0.39,1.06l0.01-0.01c0,0,0.3,0.47,0.91,1.28
				c0.23-0.48,0.23-0.73-0.13-1.4c-0.41-0.75-1.44-2.28-2-2.99c-0.01-0.01-0.02-0.03-0.03-0.04c-0.05-0.06-0.09-0.11-0.13-0.15
				c0,0,0,0,0,0.01c-0.2-0.2-0.43-0.35-0.71-0.41c-0.18-0.04-0.34-0.07-0.49-0.1C470.65,271.05,472.26,271.97,472.37,272.75z"
            />
            <path
              fill="#AC7D61"
              d="M466.34,270.54c0.09,0.15,1.73,2.86,3.3,1.98c1.61-0.91-1.16-2.7-1.16-2.7c0.1-0.01,0.22-0.01,0.34,0
				c-0.15-0.01-0.31-0.01-0.47,0C467.75,269.85,467.11,270.05,466.34,270.54z"
            />
            <path
              fill="#AC7D61"
              d="M468.18,278.46c-0.14-1.65-0.32-1.19-0.87-1.96c-0.25-0.27-0.47-0.54-0.66-0.8
				c-0.11-0.88-0.38-1.81-0.59-2.49c0-0.02,0.01-0.03,0.01-0.04c0.15-0.72-0.63-1.29-0.71-1.71c-0.02,0.03-0.04,0.07-0.06,0.1
				c-0.01-0.02-0.02-0.04-0.03-0.05c0.03-0.02,0.06-0.03,0.09-0.05c0-0.01,0-0.01,0-0.02c-0.04,0-0.07,0.01-0.11,0.02
				c-0.15-0.16-0.66,0.17-0.96,0.94c-0.09,0.23-0.11,0.46-0.1,0.7c0.02,0.42,0.16,0.87,0.26,1.4c-0.24,0.05-0.43,0.25-0.53,0.76
				c-0.07,0.37,0,0.95,0.15,1.63c-0.37,0.45-0.95,1.04-1.44,1.52c0.14,0.08,0.27,0.16,0.39,0.26c0.05,0.04,0.1,0.08,0.13,0.13
				c0.07,0.14-0.02,0.3-0.11,0.42c-0.16,0.21-0.35,0.43-0.61,0.46c-0.06,0.01-0.13,0.01-0.18,0.03c-0.01,0.01-0.02,0.02-0.03,0.03
				c0.02,0,0.03,0.01,0.05,0.01c-0.04,0.11-0.14,0.19-0.28,0.3c-0.08,0.12-0.16,0.23-0.23,0.35c0,0.02,0,0.03,0,0.05
				c0.03,0.06,0.07,0.12,0.1,0.18c0.03,0,0.06-0.01,0.09-0.05c0.14-0.2,0.48-0.46,0.86-0.55c0.38-0.09,0.4-0.25,0.65-0.6l-0.04-0.02
				c0.08-0.05,0.16-0.11,0.24-0.18c0.21-0.17,0.59-0.3,1-0.44c0.37,1.02,0.84,2.06,1.32,2.86c-0.07,0-0.11,0.01-0.11,0.01
				c-0.07-0.01-0.17-0.06-0.26-0.09c-0.03-0.01-0.05-0.01-0.07-0.02c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.02,0-0.03,0c0,0,0,0-0.01,0
				c-0.02,0-0.03,0-0.05,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.02c-0.05,0.04-0.04,0.16-0.01,0.22
				c-0.08-0.02-0.24-0.01-0.26,0.08c0,0.02-0.02,0.04-0.01,0.05c0.02,0.09-0.05,0.09-0.05,0.12c-0.06,0.04-0.03,0.13-0.06,0.18
				c-0.03,0.07-0.12,0.11-0.13,0.2c0.01-0.03,0.01-0.06,0-0.08c-0.01,0.04-0.01,0.08-0.01,0.12c-0.02,0.27-0.07,0.15,0.44,0.34
				c0.53,0.2,1.21,0.36,1.83,0.47c0.6,0.11,0.97,0.01,0.6-0.75c-0.09-0.39-0.24-1.1-0.29-1.59l0,0.01c0,0-1.34-1.46-1.85-2.48
				c0-0.01-0.01-0.02-0.01-0.02c0.08-0.04,0.15-0.07,0.22-0.11c0.08,0.11,0.15,0.21,0.22,0.33c0.54,0.86,1.19,1.21,1.44,1.74
				c0,0,0.71,0.86,1.68,1.37C468.33,280.93,468.26,279.45,468.18,278.46z"
            />
            <path
              fill="#4D2C1B"
              d="M465.75,278.32c-0.07,0.04-0.15,0.08-0.22,0.11c0,0.01,0.01,0.02,0.01,0.02
				c0.51,1.01,1.85,2.48,1.85,2.48l0-0.01l0.03-0.53c-0.26-0.53-0.9-0.88-1.44-1.74C465.9,278.53,465.83,278.42,465.75,278.32z"
            />
            <path
              fill="#4D2C1B"
              d="M462.52,269.75c0.03-0.09,0.02-0.19-0.01-0.27c0.1,0.1,0.22,0.18,0.35,0.25
				c-0.01-0.04-0.01-0.08-0.02-0.13c0.3,0.15,0.57,0.37,0.78,0.63c-0.08-0.16-0.12-0.34-0.12-0.51c0.09,0.18,0.21,0.35,0.34,0.49
				c-0.08-0.21-0.17-0.43-0.2-0.66c-0.68-0.07-1.23-0.19-1.67-0.35c0.03,0.07,0.06,0.13,0.09,0.2c0.06,0.17,0.12,0.35,0.18,0.53
				c-0.02-0.15-0.02-0.29-0.01-0.44c0-0.04,0-0.07,0.01-0.11c0,0.04,0,0.08,0,0.11c0.01,0.12,0.04,0.24,0.1,0.35
				c0-0.07,0-0.14-0.01-0.21c0-0.06,0-0.11-0.01-0.17C462.39,269.56,462.46,269.65,462.52,269.75z"
            />
            <path
              fill="#659896"
              d="M463.63,269.55c0.26,0.03,0.55,0.05,0.85,0.06c0.26,0.01,0.54,0.02,0.84,0.02
				c1.27-0.01,2.13-0.19,2.68-0.48c1.31-0.68,0.81-1.95-0.38-2.78c-0.06-0.87-0.22-1.74-0.63-2.01c-0.86-0.57-2.47-0.56-3.61-0.53
				c-1.15,0.03-1.53,1.17-1.63,2.18c-0.24,0.14-0.44,0.28-0.62,0.39c-0.93,0.62-1.25,2.08,0.83,2.8
				C462.41,269.36,462.96,269.48,463.63,269.55z"
            />
            <path
              fill="#1EBDE4"
              d="M473.69,281.06c0.51-0.67,0.72-1.49,0.93-2.22c0.39-1.33,0.07-2.42-0.7-3.43
				c-0.09-0.12-0.17-0.22-0.25-0.33c-0.61-0.81-0.91-1.28-0.91-1.28l-0.01,0.01c-0.15-0.25-0.33-0.63-0.39-1.06
				c-0.11-0.77-1.72-1.69-2.19-2.75c0,0,0-0.01-0.01-0.01c0,0-0.75-0.15-1.35-0.17c-0.12,0-0.23,0-0.34,0c0,0,2.77,1.8,1.16,2.7
				c-1.57,0.88-3.21-1.83-3.3-1.98c0,0,0-0.01,0-0.01s-0.57,0.22-0.93,0.68c-0.05,0.07-0.06,0.14-0.05,0.21c0,0.01,0,0.01,0,0.02
				c0,0,0,0,0,0c0.08,0.42,0.86,0.99,0.71,1.71c0,0.02-0.01,0.03-0.01,0.04c0.2,0.69,0.47,1.61,0.59,2.49
				c0.19,0.26,0.41,0.53,0.66,0.8c0.55,0.76,0.73,0.31,0.87,1.96c0.09,1,0.15,2.47,0.93,3.3c0.35,0.37,0.84,0.62,1.54,0.64
				c0.31,0.01,0.67-0.03,1.07-0.12C472.67,282.05,473.28,281.61,473.69,281.06z"
            />
            <path
              fill="#1EBDE4"
              d="M461.73,280.41c0.01-0.01,0.01-0.02,0.02-0.02c0.08-0.12,0.16-0.23,0.23-0.35
				c0.05-0.07,0.09-0.14,0.14-0.21c0.02-0.04,0.05-0.07,0.09-0.1c0.01-0.01,0.02-0.02,0.03-0.03c0.06-0.03,0.12-0.03,0.18-0.03
				c0.26-0.03,0.46-0.25,0.61-0.46c0.09-0.12,0.18-0.28,0.11-0.42c-0.03-0.06-0.08-0.1-0.13-0.13c-0.12-0.1-0.26-0.18-0.39-0.26
				c-0.09-0.05-0.17-0.1-0.26-0.14c-0.06-0.03-0.13-0.06-0.2-0.05c-0.07,0.01-0.13,0.06-0.18,0.11c-0.1,0.1-0.17,0.23-0.2,0.36
				c-0.01,0.04-0.02,0.09-0.02,0.13c0.03,0.01,0.04,0.04,0.03,0.07c-0.01,0.02-0.02,0.05-0.04,0.07c0,0.04,0,0.07,0,0.11
				c0.01,0.05,0.02,0.11,0.03,0.16c0,0.01,0,0.02,0.01,0.03c0,0,0,0,0,0c0.08,0.03,0.18,0.01,0.25,0.07
				c0.05,0.04,0.08,0.12,0.06,0.18s-0.09,0.11-0.15,0.11c0.09,0.01,0.14,0.12,0.1,0.2c-0.04,0.08-0.13,0.12-0.22,0.13
				c-0.09,0-0.17-0.03-0.26-0.06c0,0,0,0,0,0c0.05,0.14,0.01,0.31-0.09,0.42c-0.01,0.01-0.02,0.02-0.04,0.03
				c-0.02,0.01-0.04,0-0.06-0.01c-0.05-0.03-0.09-0.06-0.12-0.11c-0.02,0.01-0.04,0.01-0.07,0.01c-0.02,0-0.04-0.01-0.06-0.01
				c-0.03,0.04-0.06,0.08-0.09,0.12c-0.06-0.04-0.11-0.1-0.13-0.16c-0.01-0.04,0-0.09,0.02-0.12c-0.05-0.06-0.11-0.11-0.18-0.13
				c-0.13-0.03-0.26,0.05-0.32,0.17c-0.06,0.11-0.07,0.25-0.08,0.38c-0.01,0.12-0.01,0.24-0.02,0.36c-0.01,0.17-0.02,0.35,0.04,0.51
				c0.05,0.15,0.16,0.27,0.28,0.38c0.16,0.15,0.3,0.27,0.49,0.39c0.21,0.14,0.36,0.23,0.6,0.34c0.17-0.26,0.3-0.54,0.41-0.83
				c0.04-0.1,0.07-0.21,0.05-0.32c-0.01-0.08-0.06-0.16-0.1-0.23c-0.08-0.14-0.16-0.27-0.24-0.41c-0.03-0.06-0.07-0.12-0.1-0.18
				C461.74,280.43,461.74,280.42,461.73,280.41z"
            />
            <path
              fill="#AC7D61"
              d="M460.91,280.15c0.02,0.07,0.07,0.13,0.13,0.16c0.03-0.04,0.06-0.08,0.09-0.12
				c0.03-0.03,0.05-0.07,0.08-0.1c0.01,0.04,0.03,0.08,0.05,0.11c0.03,0.04,0.07,0.08,0.12,0.11c0.02,0.01,0.04,0.02,0.06,0.01
				c0.02,0,0.03-0.02,0.04-0.03c0.1-0.11,0.14-0.28,0.09-0.42c0,0,0,0,0,0c0,0,0,0,0,0c0.08,0.03,0.17,0.06,0.26,0.06
				c0.09,0,0.18-0.04,0.22-0.13c0.04-0.08-0.01-0.2-0.1-0.2c0.07,0,0.13-0.05,0.15-0.11s0-0.14-0.06-0.18
				c-0.07-0.05-0.17-0.04-0.25-0.07c0,0,0,0,0,0c0-0.01,0-0.02-0.01-0.03c-0.01,0.01-0.01,0.01-0.02,0.02
				c-0.07-0.03-0.1-0.1-0.08-0.18c0.01-0.04,0.04-0.07,0.06-0.11c0.02-0.02,0.03-0.04,0.04-0.07c0.01-0.03,0-0.06-0.03-0.07
				c-0.03-0.02-0.08-0.02-0.11-0.02c-0.11,0.02-0.16,0.13-0.19,0.22c-0.03,0.1-0.04,0.2-0.04,0.3c0,0.02,0,0.04-0.01,0.06
				c-0.01,0.03-0.05,0.04-0.07,0.06c-0.06,0.05-0.07,0.14-0.03,0.19c-0.06-0.02-0.11,0.05-0.12,0.11c-0.01,0.06-0.02,0.13-0.07,0.17
				c-0.04,0.03-0.09,0.03-0.13,0.05c-0.03,0.01-0.04,0.04-0.06,0.06C460.91,280.06,460.91,280.11,460.91,280.15z"
            />
            <path
              opacity="0.3"
              d="M475.92,284.1c0.09,0.39,0.23,0.7,0.37,0.95C476.22,284.75,476.09,284.42,475.92,284.1z"
            />
            <path
              opacity="0.3"
              d="M474.78,285.76c0.32,0.69,0.66,1.38,1.01,1.23c0.12-0.05-0.4-0.43-0.47-0.9
				c-0.07-0.47-0.33-1.66-0.54-2.08c-0.6-1.19-0.19-1.54-0.37-1.5c-0.53,0.11-1.18-0.67-1.39-0.78c0.07-0.05,0.13-0.1,0.19-0.16
				c0.34-0.3,0.61-0.65,0.81-1.03c0.04-0.08,0.08-0.15,0.12-0.23c0.56-1.17,0.76-2.41,0.62-3.14c-0.13-0.66-0.28-0.99-0.64-1.51
				c-0.06-0.08-0.12-0.17-0.18-0.26c-0.16-0.22-0.2-0.34-0.2-0.43c0.01-0.19,0.22-0.24,0.02-0.84c-0.09-0.26-0.61-1.15-1.13-1.95
				c-0.4-0.61-0.79-1.18-0.98-1.38c0,0-0.01-0.01-0.01-0.01c-0.2-0.21-0.74-0.4-1.31-0.55c-0.62-0.16-1.26-0.27-1.54-0.29
				c-0.04,0-0.08-0.01-0.12-0.01c-0.2-0.03-0.32-0.07-0.4-0.12c-0.25-0.16-0.07-0.42-0.39-0.6c0,0,0,0,0,0
				c0.09-0.04,0.14-0.07,0.14-0.07c0.51-1.18-0.39-2.56-0.39-2.56c-0.16,0.33-0.55,0.57-0.55,0.57c0.35-0.33,0.3-0.69,0.3-0.69
				c-0.2-1.73-0.74-1.59-2.4-1.7c-1.67-0.11-0.66-0.7-1.4-0.76c-1.01-0.07-1.4,0.8-1.53,1.67s-0.1,1.93-0.1,1.93
				s-0.91,1.08-0.49,1.36c0,0,0.01,0,0.01,0.01c0.16,0.1,0.36,0.18,0.55,0.24c0.09,0.03,0.17,0.05,0.25,0.07
				c0.02,0.01,0.05,0.01,0.07,0.02c0,0,0,0,0,0c0.02,0.08,0.05,0.16,0.1,0.26c0.09,0.16,0.22,0.33,0.42,0.45
				c0.33,0.21,0.46,0.31,0.55,0.68c0.09,0.38,0.57,0.56,0.59,0.9c0.01,0.13,0.13,0.18,0.27,0.22c0.1,0.03,0.22,0.05,0.32,0.08
				c0.3-0.44,0.64-0.58,0.72-0.4c-0.53,0.21-0.83,0.98-0.47,2.23c0.36,1.25,0,2.19,0.18,2.49c0.18,0.3,0.36,0.56,0.36,0.56
				s-0.36-0.18-0.77,0.36c-0.36,0.46-1.22,1.14-1.68,1.43c-0.07,0.05-0.14,0.09-0.19,0.11c-0.36,0.18-0.42,0.61-0.66,0.64
				c-0.24,0.03-0.39,0.18-0.47,0.43c-0.07,0.25-0.04,0.13-0.21,0.3c-0.04,0.04-0.09,0.06-0.14,0.08c-0.01,0-0.02,0.01-0.03,0.01
				c-0.08,0.02-0.16,0.04-0.23,0.11c-0.06,0.06-0.12,0.17-0.16,0.37c-0.12,0.59-0.14,1.08-0.14,1.08s0.04,0.04,0.12,0.11
				c0,0,0,0,0,0c0.21,0.19,0.66,0.55,0.97,0.62c0,0,0.35-0.51,0.41-0.83c0.03-0.16,0.03-0.31,0-0.45c-0.03-0.15-0.09-0.29-0.2-0.43
				c-0.03-0.03-0.05-0.06-0.06-0.08c-0.11-0.16,0.05-0.05,0.05-0.05s0.01-0.06,0.14-0.16c0.04-0.03,0.1-0.06,0.17-0.1
				c0.27-0.13,0.58-0.24,0.77-0.37c0.02-0.01,0.04-0.03,0.06-0.04c0.07-0.06,0.14-0.15,0.21-0.23c0.09-0.13,0.16-0.25,0.16-0.25
				s0.45-0.35,1.08-0.59c0.04-0.02,0.08-0.03,0.12-0.04c0.19-0.07,0.38-0.14,0.57-0.21c-0.12,0.05-0.23,0.09-0.35,0.13
				c0.29,0.52,0.61,1.03,0.9,1.5c0.65,1.09,0.8,1.63,0.82,1.84c-0.27-0.24-0.73-0.68-1.02-1.13c-0.01-0.02-0.02-0.03-0.03-0.05
				c0.21,0.44,0.33,0.64,0.39,0.72c0.03,0.03,0.04,0.05,0.04,0.05c0.47,0.33,0.43,0.36,0.63,0.5c0,0.02,0,0.03,0,0.03
				s-1.41,0.06-1.78,0.34c0-0.01,0-0.03,0-0.04c0-0.03,0.01-0.06,0.01-0.09c-0.21,0.06-0.39,0.12-0.55,0.17
				c-0.96,0.34-1.25,0.77-0.51,1.31c-0.39,0.08-0.92-0.17-1.01-0.4c-0.07-0.18,0.09-0.44-0.31-0.57c-0.4-0.13-1.28-0.39-1.88,0.26
				c0,0,0,0,0,0.59c0,1.29,4.7,1.29,7.11,0.96c6.6-0.62,6.44,2.22,7.66,2.13c-0.05-0.06-0.11-0.14-0.18-0.24
				C475.02,286.35,474.9,286.07,474.78,285.76z M466.41,274.48c0.04,0.16,0.08,0.32,0.11,0.48c0.19,0.34,0.41,0.69,0.69,1.04
				c1.3,1.6,0.95,1.47,0.92,2.05c0,0.05,0,0.1,0,0.15c0.01,0.11,0.01,0.28,0.02,0.47c-0.03-0.35-0.03-0.6-0.03-0.68
				c0-0.02,0-0.03,0-0.03c0,0,0,0-0.01-0.01c-0.5-0.54-0.97-1.06-1.41-1.5c-0.01-0.17-0.02-0.34-0.05-0.53
				C466.61,275.57,466.51,275.02,466.41,274.48z M466.37,277.81c-0.03,0.05-0.07,0.09-0.11,0.14c-0.04,0.04-0.09,0.09-0.14,0.13
				c0.05-0.04,0.1-0.08,0.14-0.13C466.3,277.9,466.34,277.85,466.37,277.81z M467.59,281.79c-0.09-0.38-0.17-0.76-0.18-1.07
				c-0.01-0.12,0-0.23,0.02-0.33c0,0,0.01,0.01,0.02,0.03c0.01,0.01,0.01,0.02,0.02,0.03c0,0,0.01,0.01,0.01,0.01
				c0.17,0.19,0.7,0.77,1.35,1.18C468.4,281.68,467.98,281.73,467.59,281.79z M465.31,278.53c-0.02,0.01-0.04,0.02-0.07,0.03
				C465.27,278.55,465.29,278.54,465.31,278.53z M467.09,283.26c-0.12-0.02-0.24-0.04-0.36-0.07c0.73,0.11,1.07,0.12,1.11-0.2
				C467.84,283.31,467.52,283.34,467.09,283.26z M469.1,281.76C469.1,281.76,469.1,281.76,469.1,281.76
				c-0.01-0.01-0.02-0.01-0.02-0.02c-0.01-0.01-0.02-0.03-0.03-0.04c0.26,0.29,0.59,0.51,1.01,0.6c0.23,0.05,0.45,0.08,0.67,0.09
				C469.99,282.4,469.47,282.15,469.1,281.76z M472.87,283.16c-0.59-0.2-1.35-0.44-2.09-0.72
				C471.27,282.58,472.17,282.9,472.87,283.16z M473.9,283.58c-0.01-0.01-0.03-0.03-0.04-0.04
				C473.88,283.55,473.89,283.57,473.9,283.58z"
            />
            <path
              opacity="0.3"
              d="M464.53,275.51c-0.05,0.05-0.11,0.11-0.16,0.17c-0.24,0.32-0.3,0.7-0.24,1.12
				c0.17-0.22,0.29-0.39,0.31-0.5C464.5,276.02,464.53,275.76,464.53,275.51z"
            />
          </g>
        </g>
        <g id="father">
          <g>
            <path
              fill="#D6BDAF"
              d="M652.95,257.09c1.45-3.06,6.34-8.16,8.05-5.54c-0.64-1.53-0.85-0.66-2.13-5.46
				c-1.26-4.74-4.4-9.47-7.27-9.68c0.22,7.27,0.4,14.66,0.54,22.09C652.43,258.05,652.71,257.59,652.95,257.09z"
            />
            <path
              fill="#1EBDE4"
              d="M631.77,232.15c4.47,3.08,9.88,20.47,15.92,32.79c0.27,0.55,0.51,1.06,0.72,1.53
				c0.85-4.35,2.48-6.11,3.72-7.98c-0.13-7.43-0.31-14.82-0.54-22.09c-1.17-38.26-3.52-73.38-6.12-95.45
				c-3.27-27.83-11.15-48.29-16.15-59.03c-3.63-7.8-12.26-6.13-12.01,3.22c0.22,8.01,2.67,56.75,1.19,142.7
				C624.63,227.61,629.02,229.06,631.77,232.15z"
            />
            <path
              fill="#1EBDE4"
              d="M636.68,295.91c-2.78-2.84-4.97-4.47-6.36-5.47c-0.13,0.39-0.25,0.79-0.37,1.19
				c-1,3.28-1.8,6.91-2.42,10.37c-0.57,6.15-0.92,11.51-1.11,16.12c-0.3,11.84,3.33,34.07,3.86,41.4c0.06,0.86,0.15,1.88,0.26,3.02
				c7.9,0,14.6,0,19.32,0c1.47-18.54,2.23-39.62,2.46-61.44C646.82,306.3,641.36,300.71,636.68,295.91z"
            />
            <path
              fill="#D6BDAF"
              d="M569.59,190.38l0.05-0.02c-0.25-1.13-0.53-2.33-0.8-3.62c-1.6-7.5-0.42-3.64-3.34-6.53
				c-0.19,0.51-0.38,1.04-0.56,1.58c-0.95,2.89-1.38,4.85-1.51,6.25c0.07-0.12,0.16-0.17,0.25-0.09c0,0,0.39-0.08,0.77,0.51
				c0.38,0.59,1.03,3.35,1.03,3.35s-1.31-0.9-1.97-1.53c0,0-0.01-0.01-0.01-0.01c0.38,1.85,1.5,2.22,0.82,5.51
				c-0.72,3.46-0.52,7.01-0.12,10.31c0.58-1.99,1.83-5.67,3.22-8.51C569.36,193.61,570.18,193.83,569.59,190.38z"
            />
            <path
              fill="#D6BDAF"
              d="M611.7,201.68c-0.32,1.01-0.63,1.98-0.92,2.93C611.12,203.65,611.43,202.67,611.7,201.68z"
            />
            <path
              fill="#4D2C1C"
              d="M565.48,191.8c0,0-0.64-2.76-1.03-3.35c-0.38-0.59-0.77-0.51-0.77-0.51c-0.09-0.08-0.18-0.03-0.25,0.09
				c-0.26,0.43-0.34,1.8,0.08,2.22c0,0,0.01,0.01,0.01,0.01C564.17,190.9,565.48,191.8,565.48,191.8z"
            />
            <path
              fill="#D6BDAF"
              d="M606.91,220.75c0.02-0.68,0.07-1.42,0.16-2.2c-9.58,5.7-19.83,4.09-24.81-6.16
				c-2.07-4.26-3.01-8.36-3.63-11.61c0-0.01,0-0.03,0-0.04c0.14-5.18-0.36-7.93-2.66-9.08c-2.52-1.26-4.19,2.2-3.8,5.67
				c0.39,3.47-0.5,6.51,0,9.58c0.42,2.57,2.15,2.98,3.24,2.33c0.06,0.41,0.12,0.88,0.18,1.42c1.72,16.95-2.06,15.09-2.06,15.09
				s-0.05,0.01-0.13,0.03c1.03,0.15,2.17,0.2,3.45,0.11c1.09-0.08,1.94,0.53,2.61,1.52c1.84-0.34,19.49-3.6,25.61-5.17
				C606.11,221.97,606.72,221.19,606.91,220.75z M574.09,205.66c-0.42,0.9-1,0.14-0.6-1.35s0-1.8-0.35-2.55
				c-0.34-0.75-0.08-0.98-0.08-0.98c0.67,1.13,1.38,0.68,1.8,1.21C575.27,202.52,574.52,204.77,574.09,205.66z M574.81,207.65
				c0,0,2.32-4.41,2.15-8.01C576.97,199.64,577.33,206.02,574.81,207.65z M574.59,194.15c-0.78,0.32-1.23,1.87-0.33,3.07
				s1.24,1.73,1.52,2.71s-0.14,1.95-0.14,1.95s-0.19-0.75-0.46-1.65c-0.27-0.9-0.95,0.44-1.85-0.61c-0.9-1.06-0.69-4.2-0.46-4.95
				s0.72-1.94,1.98-1.64s1.73,2.26,2.14,3.61C576.99,196.64,575.69,193.71,574.59,194.15z"
            />
            <path fill="#4D2C1C" d="M563.39,167.18c0,0,0.27-0.27,0.81-0.74C563.5,166.84,563.39,167.18,563.39,167.18z" />
            <path
              fill="#4D2C1C"
              d="M575.59,210.65c-0.05-0.54-0.11-1.01-0.18-1.42c-1.09,0.65-2.82,0.24-3.24-2.33
				c-0.5-3.07,0.39-6.1,0-9.58c-0.39-3.47,1.28-6.93,3.8-5.67c2.3,1.15,2.8,3.89,2.66,9.08c0,0.01,0,0.03,0,0.04
				c0.61,3.26,1.55,7.35,3.63,11.61c4.98,10.24,15.23,11.86,24.81,6.16c0.03-0.02,0.07-0.04,0.1-0.06c0,0,1.1-5.64,3.61-13.88
				c0.29-0.94,0.59-1.92,0.92-2.93c0.2-0.64,0.42-1.29,0.64-1.95c2.2-6.6,4.18-13.77,4.16-18.12c0.53-5.86-0.19-10.28-1.21-13
				c-1.36-3.62-0.68-1.13-0.68-1.13s0.15,3.82,0.33,6.32c-0.64-2-1.64-4.08-3.48-6.33c-0.96-1.73-2.1-3.43-3.42-4.74
				c-3.87-3.87-2.94-3.75-1.61-0.12c-1.97-1.59-4.1-2.86-6.05-2.92c-6.54-0.23-10.6-2.51-14.7-2.27c-1.41-0.11-3.93,0.35-6.32,1.27
				c-0.02-0.05-0.03-0.08-0.03-0.08c-3.36,1.84-7.04,2.14-10.57,4.44c-2.17,1.41-3.7,2.65-4.55,3.4c0.85-0.49,2.59-1.06,6.11-1.24
				c-0.67,0.03-1.3,0.17-1.89,0.41c-2.27,0.71-4.1,2.21-5.22,4.5c0,0,0.4-0.65,1.71-1.4c-0.17,0.28-0.34,0.56-0.49,0.86
				c-0.34,0.39-0.68,0.85-0.99,1.38c-0.58,0.74-1.04,1.57-1.29,2.5c-1.06,3.88-1.6,3.8,0.42,2.48c0.02,1.14,0.32,2.32,0.96,3.43
				c1.42,2.43,1.06,1.69,0.68-0.7c0.17,0.29,0.37,0.56,0.59,0.8c0.26,0.29,0.49,0.54,0.71,0.75c2.92,2.9,1.74-0.97,3.34,6.53
				c0.28,1.29,0.55,2.49,0.8,3.62l-0.05,0.02c0.59,3.45-0.22,3.23-2.16,7.18c-1.39,2.84-2.63,6.51-3.22,8.51
				c-0.08,0.29-0.16,0.55-0.21,0.76c-0.09,0.34-0.03,1.3,0.08,1.63c0.12,0.38,0.31,1.04,0.57,2.15c0.51,2.19,0.39,2.09,0.39,4.28
				c0,2.19-0.81,7.43,4.38,10.02c2.16,1.08,3.57,0.95,3.98,0.87c0.08-0.02,0.13-0.03,0.13-0.03S577.31,227.61,575.59,210.65z"
            />
            <path
              fill="#4D2C1C"
              d="M574.85,193.02c-1.25-0.31-1.74,0.89-1.98,1.64s-0.44,3.9,0.46,4.95c0.9,1.06,1.57-0.29,1.85,0.61
				c0.27,0.9,0.46,1.65,0.46,1.65s0.42-0.97,0.14-1.95s-0.61-1.5-1.52-2.71s-0.46-2.76,0.33-3.07c1.1-0.44,2.4,2.49,2.4,2.49
				C576.58,195.29,576.1,193.33,574.85,193.02z"
            />
            <path
              fill="#4D2C1C"
              d="M574.86,202c-0.41-0.53-1.13-0.08-1.8-1.21c0,0-0.26,0.22,0.08,0.98c0.34,0.75,0.74,1.05,0.35,2.55
				s0.18,2.25,0.6,1.35C574.52,204.77,575.27,202.52,574.86,202z"
            />
            <path
              fill="#4D2C1C"
              d="M574.81,207.65c2.52-1.63,2.15-8.01,2.15-8.01C577.13,203.24,574.81,207.65,574.81,207.65z"
            />
            <path
              fill="#42637C"
              d="M661,251.55c-1.71-2.62-6.6,2.48-8.05,5.54c-0.24,0.5-0.52,0.96-0.82,1.41
				c-1.24,1.87-2.88,3.62-3.72,7.98c-0.21-0.47-0.45-0.98-0.72-1.53c-6.04-12.32-11.46-29.72-15.92-32.79
				c-2.75-3.08-7.13-4.54-13.26-4.31c-2.21,0.08-4.64,0.39-7.3,0.91c-1.89-2.6-3.85-5.79-4.24-8.24c0,0.06-0.02,0.14-0.06,0.24
				c-0.19,0.44-0.81,1.22-1.85,1.49c-6.12,1.57-23.76,4.83-25.61,5.17c-0.1,0.02-0.16,0.03-0.16,0.03s2.79,4.22-2.49,6.5
				c-0.43,0.15-0.89,0.31-1.36,0.49c-2.58,0.98-4.86,2.18-6.86,3.57c-0.69,0.36-1.26,0.68-1.76,0.97
				c-9.06,5.29-16.31,11.56-14.85,25.17c1.5,14.04-2.47,14.28-2.47,44.84c0,8.53-10.7,28.22-10.57,53.57c5.83,0,12.63,0,19.96,0
				c2.01-6.94,4.92-15.93,8.35-23.18c3.73-7.89,6.02-12.67,7.09-35.97c0-0.02,0-0.03,0-0.03c0-0.02,0-0.04,0-0.06
				c0.01-0.24,0.02-0.49,0.03-0.73c0.03-0.75,0.08-1.52,0.14-2.29c0.26-7.36-0.54-15.22-0.6-24.71c-0.07-10.82,2.92-0.22,2.95,5.19
				c0.02,2.73,0.42,1.99,1.04-0.32c1.12-4.84,2.14-8.68,2.37-10.41c0.08-0.56,0.01-0.04-0.17,1.31c0.04-0.18,0.09-0.35,0.13-0.52
				c0.62-2.47,0.6-1.62,0.21,1.15c0.31-1.57,0.49-2.4,0.49-2.4s-1.35,7.95-2.83,18.22c-0.48,4.21-0.98,8.85-1.43,13.52
				c-0.18,6.92,0.65,13.77,4.54,19.34c3.9,5.57,17.38,8.37,23.79,10.76c0.56,0.21,0.52,0.98-0.05,1.17
				c-16.37,5.5-25.71-3.69-25.62,1.58c0.42,0.94,5.82,4.73,16.36,5.27c1.72,0.09-12.39,4.63-19.41,7.26
				c-5.66,1.59-9.41,2.12-11.77,6.63c-0.02-0.03-0.05-0.05-0.07-0.08c-0.02,0.07-0.05,0.13-0.07,0.2c-0.04,0.12-0.08,0.23-0.12,0.35
				c0.01,0,0.02,0.01,0.03,0.01c0,0.01-0.01,0.01-0.01,0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.04,0.12-0.08,0.23-0.12,0.35
				c-0.12,0.27-0.23,0.55-0.35,0.85c-0.23,0.62-0.43,1.24-0.6,1.86c-0.71,2.07-1.33,3.97-1.88,5.69c21.36,0,46.77,0,66.08,0
				c-0.32-13.64-4.62-17.85-4.71-25.65c-0.04-3.43-0.16-6.2-0.39-8.35l-0.8,4.62c0.35-2,0.62-3.76,0.73-5.25
				c-0.4-3.25-1.09-4.98-2.18-5.44c-1.32-0.26-3.31,0.19-6.22,1.45c-9.24,4.01-20.4,1.92-11.85,1.22
				c8.54-0.7,18.65-5.75,20.75-15.52s7.67-23.36,9.24-30.86c0,0,0.04,0.04,0.09,0.18c1.85-9.18,1.68-16.54,1.44-19.95
				c-0.01-0.16,0.22-0.19,0.25-0.03c2.99,14.61-0.54,22.49-3.4,31.48c1.38,1,3.58,2.63,6.36,5.47c4.68,4.8,10.14,10.39,15.64,5.2
				c0.26-0.25,0.53-0.52,0.79-0.82c1.4-1.59,2.42-3.31,3.14-5.04c0.07,0.13,0.1,0.2,0.1,0.2c2.63-6.98,4.23-12.21,5.08-19.64
				c0.85-7.43,1.07-12.24,1.71-16.61C663.78,254.83,662.71,254.17,661,251.55z"
            />
            <path
              fill="#141616"
              d="M563.17,356.86c0.16-0.61,0.36-1.24,0.6-1.86c0.11-0.3,0.23-0.58,0.35-0.85
				c0.04-0.11,0.08-0.23,0.12-0.35c0.01,0,0.02,0.01,0.03,0.01c0-0.01,0.01-0.01,0.01-0.02c-0.01,0-0.02-0.01-0.03-0.01
				c0.04-0.12,0.08-0.23,0.12-0.35c0.02-0.07,0.05-0.13,0.07-0.2c0.02,0.03,0.05,0.05,0.07,0.08c2.35-4.5,6.11-5.03,11.77-6.63
				c7.02-2.63,21.13-7.17,19.41-7.26c-10.54-0.55-15.94-4.33-16.36-5.27c-0.09-5.27,9.25,3.92,25.62-1.58
				c0.57-0.19,0.61-0.96,0.05-1.17c-6.41-2.38-19.89-5.19-23.79-10.76c-3.89-5.56-4.72-12.41-4.54-19.34
				c0.46-4.67,0.96-9.3,1.43-13.52c1.48-10.27,2.83-18.22,2.83-18.22s-0.19,0.83-0.49,2.4c0.38-2.77,0.41-3.62-0.21-1.15
				c-0.04,0.17-0.09,0.35-0.13,0.52c0.17-1.35,0.25-1.87,0.17-1.31c-0.24,1.73-1.25,5.57-2.37,10.41c-0.62,2.31-1.03,3.04-1.04,0.32
				c-0.03-5.41-3.02-16.01-2.95-5.19c0.06,9.49,0.87,17.35,0.6,24.71c-0.06,0.77-0.11,1.53-0.14,2.29
				c-0.01,0.25-0.02,0.49-0.03,0.73c0,0.02,0,0.04,0,0.06c0,0,0,0.01,0,0.03c-1.07,23.3-3.36,28.08-7.09,35.97
				c-3.43,7.26-6.34,16.24-8.35,23.18c0.8,0,1.6,0,2.41,0C561.84,360.83,562.46,358.93,563.17,356.86z"
            />
            <path
              fill="#141616"
              d="M630.27,359.52c-0.52-7.33-4.15-29.56-3.86-41.4c0.2-4.6,0.54-9.96,1.11-16.12
				c0.62-3.47,1.42-7.09,2.42-10.37c0.12-0.4,0.25-0.8,0.37-1.19c2.86-8.99,6.39-16.87,3.4-31.48c-0.03-0.15-0.26-0.13-0.25,0.03
				c0.24,3.41,0.41,10.77-1.44,19.95c-0.05-0.13-0.09-0.18-0.09-0.18c-1.57,7.5-7.15,21.09-9.24,30.86s-12.2,14.82-20.75,15.52
				c-8.54,0.7,2.62,2.79,11.85-1.22c2.91-1.26,4.9-1.71,6.22-1.45c1.09,0.47,1.78,2.19,2.18,5.44c-0.11,1.49-0.38,3.25-0.73,5.25
				l0.8-4.62c0.23,2.16,0.35,4.92,0.39,8.35c0.09,7.8,4.39,12.02,4.71,25.65c1.07,0,2.13,0,3.16,0
				C630.42,361.4,630.33,360.38,630.27,359.52z"
            />
          </g>
          <path
            opacity="0.3"
            d="M663.35,256.01c-0.28-2.48-2.35-4.46-2.35-4.46c0.71,2.62,1.56,4.52-1.28,10.64s-5.41,12.24-5.12,18.79
			c0,0,0.37,2.35,0.33,5.32c-0.95-7.18-3.66-13.81-6.47-20.09c1.07-5.19,3.28-6.58,4.49-9.13c1.45-3.06,6.34-8.16,8.05-5.54
			c-0.64-1.53-0.85-0.66-2.13-5.46c-1.23-4.62-4.25-9.25-7.06-9.66c-0.15-6.05-0.32-11.69-0.49-16.78
			c-1.21-35.89-3.42-62.01-6.16-81.34c-0.47-3.33-0.96-6.46-1.46-9.4c-0.2-1.18-0.4-2.32-0.61-3.44
			c-3.99-21.88-8.58-32.65-11.92-39.46c-0.93-1.89-1.77-3.48-2.46-4.91c-2.09-4.28-5.65-3.94-5.26-0.19
			c1.31,12.7,4.13,71.52,2.8,147.72c-2.15-0.67-4.47-0.87-6.88-0.8c-2.36,0.07-4.8,0.39-7.24,0.76c-0.3,0.05-0.59,0.09-0.89,0.14
			c0,0-0.09-0.1-0.23-0.28c-0.48-0.6-1.62-2.11-2.63-4.19c-0.51-1.05-0.99-2.25-1.33-3.54c0.11-1.56,0.58-4.85,2.32-10.93
			c2.68-9.36,6.67-19.13,7.06-25.86c0.29-5.05,0.63-8.48-0.41-12.79c-0.19-0.78-0.42-1.59-0.7-2.44c-0.23-0.67-0.48-1.37-0.78-2.11
			c0,0,0,0.07,0.01,0.21c0.01,0.13,0.02,0.33,0.03,0.57c0.06,1.25,0.2,3.77,0.32,6.04c0,0-0.51-1.61-1.94-3.87
			c-0.02-0.03-0.03-0.05-0.05-0.08c-0.43-0.67-0.93-1.39-1.53-2.14c-0.03-0.04-0.07-0.09-0.1-0.13c-2.74-3.4-3.74-3.4-6.34-4.54
			c-2.6-1.13,0.14,0.4,2.44,3.04s-29.74-4.94-32.95,15.88c-1.13,7.35-0.61,10.2-0.61,10.2c0.02-0.01,0.05-0.01,0.07-0.02
			c1.49-0.43,4.11,0.99,4.39,6.53c0.15,2.98-0.55,3.26-1.34,7.94c-0.5,2.97-2.01,3.33-3.07,3.2c-0.16-0.02-0.31-0.05-0.45-0.09
			c0,0,0.59,0.67,1.91,0.34c-0.14,0.31,0.95,17.28-6.58,14.86c-0.13-0.04-0.27-0.09-0.4-0.14c0,0,0.73,0.68,2.61,1.15
			c0.48,0.12,1.05,0.23,1.69,0.3c1.13,0.14,2.53,0.19,4.22,0.06c0.14-0.01,0.27-0.02,0.4-0.03c0.11,0.02,0.22,0.06,0.35,0.1
			c0.31,0.1,0.65,0.25,0.96,0.47c0.21,0.15,0.4,0.32,0.55,0.53c0.11,0.14,0.19,0.3,0.25,0.48c0.03,0.07,0.05,0.15,0.07,0.23
			c0.01,0.06,0.02,0.12,0.03,0.18l0.06-0.01l0.57-0.12c1.03,2.49,3.28,4.04-0.36,5.61c0,0,4.42-0.9,9.5-1.03
			c0.3-0.01,0.37,0.42,0.08,0.5c-3.66,1.05-11.33,2.49-21.14,11.42c-11.35,10.34-1.95,31.37-5.84,43.77
			c-3.89,12.4-4.13,19.21-4.13,26.75c0,6.68-7.93,25.45-12.81,47.83c32.59,0,85.97,0,104.97,0c1.36-17.2,1.96-39.17,2.1-61.57
			c-0.99,0.97-1.98,1.59-2.97,1.91c1.78-0.75,3.54-2.27,5.13-4.58c0.6-0.87,1.16-1.85,1.7-2.94c0.11-0.22,0.21-0.44,0.31-0.66
			c0.81-1.78,1.53-3.83,2.13-6.16c0.19-0.75,0.37-1.52,0.54-2.33c1.51-7.38,2.17-11.39,2.62-15.02c0.19-1.5,0.34-2.93,0.51-4.5
			C662.99,261.31,663.64,258.49,663.35,256.01z"
          />
        </g>
      </motion.g>
      <motion.g {...motionProps} variants={variantsText} id="text_x5F_de">
        <text
          transform="matrix(1 0 0 1 244.1748 116.5576)"
          fontFamily={font.condensed}
          fontSize="68.3887px"
          letterSpacing="-1"
        >
          getan
        </text>
        <text transform="matrix(1 0 0 1 95.9609 175.3672)">
          <tspan x="0" y="0" fontFamily={font.serif} fontSize="13px">
            Seit 2020 beziehen wir den Strom für
          </tspan>
          <tspan x="-8.92" y="19" fontFamily={font.serif} fontSize="13px">
            alle Werke der BMW Group zu
          </tspan>
          <tspan x="167.53" y="19" fontFamily={font.condensed} fontSize="15.8124px">
            {" "}
            100%
          </tspan>
          <tspan x="-1.66" y="38" fontFamily={font.serif} fontSize="13px">
            aus{" "}
          </tspan>
          <tspan x="20.85" y="38" fontFamily={font.condensed} fontSize="15.8124px">
            ERNEUERBAREN ENERGIEN.
          </tspan>
        </text>
      </motion.g>
    </svg>
  );
};
