import { css, FlattenSimpleInterpolation } from "styled-components/macro";

export const colsMobile = 12;
export const colsDesktop = 24;

export const grid = css`
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row dense;
  grid-gap: 0;
`;
export const gridColumns = (cols: number): FlattenSimpleInterpolation => css`
  // prettier-ignore
  -ms-grid-columns: (1fr)[${cols - 1}] 1fr;
  grid-template-columns: repeat(${cols}, 1fr);
`;
export const gridRows = (rows: number): FlattenSimpleInterpolation => css`
  // prettier-ignore
  -ms-grid-rows: (1fr)[${rows - 1}] 1fr;
  grid-template-rows: repeat(${rows}, 1fr);
`;
export const gridColumnStart = (left: number): FlattenSimpleInterpolation => css`
  -ms-grid-column: ${(left - 1) * 2 + 1};
  grid-column-start: ${left};
`;
export const gridColumnWidth = (width: number): FlattenSimpleInterpolation => css`
  -ms-grid-column-span: ${(width - 1) * 2 + 1};
  grid-column-end: span ${width};
`;
export const gridRowStart = (top: number): FlattenSimpleInterpolation => css`
  -ms-grid-row: ${(top - 1) * 2 + 1};
  grid-row-start: ${top};
`;
export const gridRowEnd = (height: number): FlattenSimpleInterpolation => css`
  -ms-grid-row-span: ${(height - 1) * 2 + 1};
  grid-row-end: span ${height};
`;
