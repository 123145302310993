import { rootElement } from "#helpers";
import { useEffect, useState } from "react";

const defaultSize = {
  width: 0,
  height: 0,
  scrollBarWidth: 0,
};

const getSize = () => ({
  width: rootElement.clientWidth,
  height: window.innerHeight,
  scrollBarWidth: rootElement.offsetWidth - rootElement.clientWidth,
});
interface Size {
  width: number;
  height: number;
  scrollBarWidth: number;
}
export const useResize = (): Size => {
  const isWindowClient = typeof window === "object";
  const [size, setSize] = useState(isWindowClient ? getSize() : defaultSize);
  useEffect(() => {
    const setWindowSize = () => {
      setSize(getSize());
    };
    if (isWindowClient) {
      window.addEventListener("resize", setWindowSize);
      return () => window.removeEventListener("resize", setWindowSize);
    }
  }, [isWindowClient, setSize]);
  return size;
};
