import { useTranslation } from "#hooks";
import { Grid, Quotation, QuotationProps, Wrapper } from "#ui";
import React from "react";

export const Quote1: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const quotation = tHome("quote1") as unknown;
  return (
    <Wrapper>
      <Grid>
        <Quotation {...(quotation as QuotationProps)} />
      </Grid>
    </Wrapper>
  );
};
