import { useFullscreen, useTranslation } from "#hooks";
import { AnalyticsEventTypes } from "#interfaces";
import { bp, Color, expander, fontCondensed, IconKeys } from "#shared/theme";
import { LinkIconSmall, Pdf, Scrollbars } from "#ui";
import htmr from "htmr";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useModalContainer } from "src/providers";
import styled from "styled-components/macro";
import { Fullscreen } from "../Fullscreen/Fullscreen";

const Container = styled.div`
  overflow: hidden;
  flex: 1 0 0%;
  display: flex;
  width: 262px;
`;
const Aside = styled.div`
  padding: 30px 15px 120px;
  flex: 0 0 262px;
  font-size: 13px;
  line-height: 1.23;
  padding-right: 10px;
  @media ${bp.m} {
    flex: 0 0 200px;
  }
`;
const Title = styled.h2`
  font-size: inherit;
  ${fontCondensed}
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Type = styled(Title)`
  margin: 10px 0 20px;
`;
const Headline = styled.h3`
  font-size: inherit;
  font-weight: bold;
  line-height: 1.5;
  margin: 10px 0;
`;
const Chapter = styled(Headline)``;
// const Pages = styled.div``;
const Main = styled.div<{ isOpen: boolean }>`
  flex: 1 0 0%;
  overflow: hidden;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  @media ${bp.m} {
    display: block;
    position: relative;
  }
`;
const MainInner = styled.div`
  ${expander}
  display: flex;
  background-color: ${Color.GrayLighten};
`;
const Content = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  width: 100%;
  background-color: ${Color.GrayLighter};
  padding: 0 5px;
`;
const Footer = styled.div`
  margin-top: 30px;
`;

const Item = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export interface PdfViewerProps {
  src: string;
  chapter: string;
  headline: string;
  page: number;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ src, chapter, headline, page }) => {
  const { t } = useTranslation("common");
  const isDesktop = useMediaQuery({ query: bp.m });
  const handle = useFullscreen();
  const { isOpenCompleted } = useModalContainer();
  const { enter, active } = handle;
  return (
    <Container>
      <Aside>
        <Title>{htmr(t("pdf_title"))}</Title>
        <Type as={"div"}>{t("pdf_type")}</Type>
        <Chapter>{chapter}</Chapter>
        <Headline>{headline}</Headline>
        <Footer>
          <Item>
            <LinkIconSmall
              href={t("fullPdfReport")}
              download
              iconKey={IconKeys.SvgDownload}
              analyticsEvent={AnalyticsEventTypes.FullReportPDFDownload}
            >
              {t("Download")}
            </LinkIconSmall>
          </Item>
          <Item>
            <LinkIconSmall onClick={enter} iconKey={IconKeys.SvgFullscreen}>
              {t("Fullscreen")}
            </LinkIconSmall>
          </Item>
        </Footer>
      </Aside>
      <Main isOpen={active}>
        <Fullscreen {...handle}>
          {(isDesktop || active) && isOpenCompleted && (
            <MainInner>
              <Scrollbars thickOffset>
                <Content>
                  <Pdf src={src} page={page} />
                </Content>
              </Scrollbars>
            </MainInner>
          )}
        </Fullscreen>
      </Main>
    </Container>
  );
};
