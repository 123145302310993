import { chartMobileItemDuration } from "#constants";
import { useChartAnimationMobile, useNumberFormat } from "#hooks";
import { ChartPropsMobile } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";

export const LeftKPI: React.FC<ChartPropsMobile> = ({ labels, ...props }) => {
  const currentPathRef = useRef<SVGLineElement>(null);
  const prevPathRef = useRef<SVGLineElement>(null);
  const negativePathRef = useRef<SVGLineElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPolylineElement>(null);
  const topLineRef = useRef<SVGLineElement>(null);
  const pathLength = useChartAnimationMobile({
    ...props,
    currentValue: Number(labels?.currentValue),
    prevValue: Number(labels?.prevValue),
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    negativePathRef,
    topLineRef,
    animationDuration: chartMobileItemDuration,
    animationDelay: chartMobileItemDuration,
  });
  return (
    <g fill="none">
      <line
        ref={negativePathRef}
        opacity=".3"
        stroke={Color.BlackLight}
        strokeWidth="9"
        x1="167.5"
        y1="322"
        x2="167.5"
        y2="322"
      />
      <line ref={prevPathRef} stroke={Color.BlackLight} strokeWidth="9" x1="167.5" y1="322" x2="167.5" y2="322" />
      <line ref={currentPathRef} stroke={Color.White} strokeWidth="9" x1="167.5" y1="322" x2="167.5" y2="322" />
      <line ref={topLineRef} stroke={Color.White} strokeWidth="9" x1="167.5" y1="322" x2="167.5" y2="323" />
      <polyline
        ref={lineRef}
        stroke={Color.White}
        strokeMiterlimit="9"
        points="284,160 210,160 172,322 163,322"
        strokeDashoffset={pathLength}
        strokeDasharray={pathLength}
      />

      <SvgText ref={contentRef} alignEnd>
        <SvgTextSmall>
          <text transform="matrix(1 0 0 1 284 152.7695)" opacity="0.5" fontSize="10">
            {labels?.diff}
          </text>
          <text fill={Color.BlackLight} transform="matrix(1 0 0 1 284 110.7695)" fontSize="10">
            <tspan x="0" y="0">
              {labels?.label1}
            </tspan>
            <tspan x="0" y="12">
              {labels?.label2}
            </tspan>
          </text>
        </SvgTextSmall>
        <text transform="matrix(1 0 0 1 284 140.5234)" fontSize="16">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
