import React, { FC } from "react";
import styled from "styled-components/macro";
import { Wrapper } from "../Wrapper/Wrapper";
import { Card, CardProps } from "../Card/Card";
import { bp } from "#shared/theme";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const WrapperCard = styled.div`
  width: 50%;
  position: relative;
  padding-top: 54%;
  @media ${bp.t} {
    width: 28%;
    padding-top: 34%;
    &:last-child {
      width: 16%;
    }
  }
`;

interface FooterTeaser {
  items: CardProps[];
}

export const FooterTeaser: FC<FooterTeaser> = ({ items }) => (
  <Wrapper>
    <Container>
      {items.map((teaser: CardProps, i: number) => (
        <WrapperCard key={i}>
          <Card {...teaser} />
        </WrapperCard>
      ))}
    </Container>
  </Wrapper>
);
