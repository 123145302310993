import React from "react";
import styled from "styled-components/macro";
import { Color, font, bp } from "#shared/theme";
import { useTranslation } from "#hooks";
import { Link } from "#ui";
import { createAnalyticsEventHandler } from "#helpers";
import { AnalyticsEventTypes } from "#interfaces";

const Container = styled.footer`
  z-index: 1;
  background-color: ${Color.White};
  padding: 15px 20px 20px;
  color: ${Color.Black};
  font-family: ${font.condensed};
  letter-spacing: 0.018em;
  position: relative;
  box-shadow: 0 100px 0 100px ${Color.White};
  @media ${bp.m} {
    padding: 28px;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ItemLinkLabel = styled.div<{ isSmall?: boolean }>`
  border-bottom: 1px solid ${Color.Black};
  font-size: ${({ isSmall }): string => (isSmall ? "10px" : "12px")};
  margin: ${({ isSmall }): string => (isSmall ? "8px 4px" : "8px")};
  line-height: ${({ isSmall }): string => (isSmall ? "1.43" : "1.67")};
  @media ${bp.m} {
    font-size: ${({ isSmall }): string => (isSmall ? "12px" : "14px")};
  }
`;

export interface FooterProps {
  disableFirstLine?: boolean;
}

interface LinkItem {
  label: string;
  href: string;
  external?: boolean;
  download?: boolean;
  analyticsEvent?: AnalyticsEventTypes;
}

export const Footer: React.FC<FooterProps> = ({ disableFirstLine }) => {
  const { t } = useTranslation("footer");
  const topItems = t("topLinks") as LinkItem[];
  const bottomItems = t("bottomLinks") as LinkItem[];
  return (
    <Container>
      {!disableFirstLine && !!topItems.length && (
        <Links>
          {topItems.map((item) =>
            item.external || item.download ? (
              <a
                href={item.href}
                key={item.label}
                download={item.download}
                target="_blank"
                rel="noreferrer"
                onClick={item.analyticsEvent ? createAnalyticsEventHandler(item.analyticsEvent) : undefined}
              >
                <ItemLinkLabel>{item.label}</ItemLinkLabel>
              </a>
            ) : (
              <Link
                to={item.href}
                key={item.label}
                onClick={item.analyticsEvent ? createAnalyticsEventHandler(item.analyticsEvent) : undefined}
              >
                <ItemLinkLabel>{item.label}</ItemLinkLabel>
              </Link>
            )
          )}
        </Links>
      )}
      {bottomItems.length && (
        <Links>
          {bottomItems.map((item) =>
            item.external ? (
              <a
                href={item.href}
                key={item.label}
                target="_blank"
                rel="noreferrer"
                onClick={item.analyticsEvent ? createAnalyticsEventHandler(item.analyticsEvent) : undefined}
              >
                <ItemLinkLabel isSmall>{item.label}</ItemLinkLabel>
              </a>
            ) : (
              <Link
                to={item.href}
                key={item.label}
                onClick={item.analyticsEvent ? createAnalyticsEventHandler(item.analyticsEvent) : undefined}
              >
                <ItemLinkLabel isSmall>{item.label}</ItemLinkLabel>
              </Link>
            )
          )}
        </Links>
      )}
    </Container>
  );
};
