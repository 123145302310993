import snoise from "./noise";
const getHeight = `

${snoise}

#define mountHeight 20.;
float nsnoise(vec2 val){
	return snoise(val) * 0.5 + 0.5;
}
float getHeight(vec2 uv, float offset){
	
	float fadeNoise = smoothstep(0., 1., uWorldZInUV);
	float offsetWave = 0.;
	float offsetWorld = -0.;
	float val = (sin( (uv.y  + uWorldZInUV+ offsetWorld + uOffset0) * 20. + offsetWave  ) * 0.25 + 0.25) 
	* (sin( (uv.y  + uWorldZInUV+ offsetWorld + uOffset1) * 10.  + offsetWave ) * 0.2 + 0.6) 
	+ cos( uv.x * 10. + offsetWave - uOffset2 ) * 0.25 + 0.25;
	val *=  smoothstep(0., 0.3 ,uv.x) * smoothstep(1., 1. -0.3 ,uv.x);
return val * mountHeight;
	// return step(0.5,val);
}`;
export default getHeight;
