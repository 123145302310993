import React, { Fragment } from "react";
import { Root } from "#components";
import { useTranslation } from "#hooks";
import { bp, Color, IconKeys } from "#shared/theme";
import {
  LinkIconSmall,
  LinkIconSmallProps,
  MainContent,
  Spacer,
  Text,
  Wrapper,
  Footnotes,
  FootnoteProps,
  Table,
  TableProps,
} from "#ui";
import styled from "styled-components";

const headerProps = {
  withNav: true,
};

interface SectionProps {
  copytext: string;
  table: TableProps;
  footnotes: FootnoteProps[];
  links: LinkIconSmallProps[];
}
const Main = styled(MainContent)`
  background-color: ${Color.GrayLightest};
`;
const TableText = styled(Text)`
  h2 {
    font-size: 30px;
    font-weight: normal;
    @media ${bp.p} {
      max-width: 50%;
    }
  }
`;
const Links = styled.div`
  margin-top: 20px;
  display: flex;
`;
const LinksItem = styled.div`
  & + & {
    margin-left: 20px;
  }
`;

const renderLinks = (props: LinkIconSmallProps, key: number) => (
  <LinksItem key={key}>
    <LinkIconSmall {...props} download iconKey={IconKeys.SvgDownload} />
  </LinksItem>
);

const renderSections = ({ copytext, links, table, footnotes }: SectionProps, key: number) => {
  return (
    <Fragment key={key}>
      <Wrapper>
        {key !== 0 && copytext && <Spacer size={25} />}
        {copytext && <TableText text={copytext} />}
        {links && <Links>{links.map(renderLinks)}</Links>}
      </Wrapper>
      <Table {...table} />
      {footnotes && <Footnotes list={footnotes} />}
      <Spacer size={25} />
    </Fragment>
  );
};

export const FinancialStatements: React.FC = () => {
  const { t } = useTranslation("financialStatements");
  const sections: SectionProps[] = t("sections");

  return (
    <Root withHeader headerProps={headerProps}>
      <Main>{sections.map(renderSections)}</Main>
    </Root>
  );
};
